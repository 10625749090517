import React, { useEffect, useState } from 'react'
import MenuBar from '../../_components/MenuBar';
import { Avatar, Box, Theme, Typography } from '@mui/material';

/**
 * Components
 */
import TargetCard from './_components/TargetCard';
import MontlyHistoryCard from './_components/MontlyHistoryCard';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import { useParams } from 'react-router-dom';
import LoadingScreen from '../../_components/LoadingScreen';
import { generateStyle } from '../../_utils/DefaultStyle';

export interface IAdvisorDataHistory {
    amount: number,
    closing_count: number,
    enquiry_count: number,
    month_label: string,
    on_target: false,
    showing_count: number
}
interface IAdvisorReport {
    name: string;
    profile_picture: string;
    monthly_target: number;
    yearly_target: number;
    monthly_on_track_minimum: number;
    yearly_on_track_minimum: number;
    this_month_commission: number;
    this_month_on_track: boolean;
    this_month_closing_count: number;
    this_month_showing_count: number;
    this_month_enquiry_count: number;
    this_year_closing_count: number;
    this_year_showing_count: number;
    this_year_enquiry_count: number;
    this_year_commission: number;
    this_year_on_track: boolean;
    history_commissions: IAdvisorDataHistory[];
}

const initialData: IAdvisorReport = {
    name: 'Loading Name..',
    profile_picture: '',
    monthly_target: 0,
    yearly_target: 0,
    monthly_on_track_minimum: 0,
    yearly_on_track_minimum: 0,
    this_month_closing_count: 0,
    this_month_commission: 0,
    this_month_enquiry_count: 0,
    this_month_on_track: false,
    this_month_showing_count: 0,
    this_year_closing_count: 0,
    this_year_commission: 0,
    this_year_enquiry_count: 0,
    this_year_on_track: false,
    this_year_showing_count: 0,
    history_commissions: [],
};

const TargetScoringDashboard = () => {
    const { Root, classes } = useStyles()
    const [advisorReport, setAdvisorReport] = useState<IAdvisorReport>(initialData)
    const [loading, setLoading] = useState(true)
    const params = useParams<{ id: string }>()

    useEffect(() => {
        DefaultAxios.get(
            params.id ?
                `${process.env.REACT_APP_API_URL}/captain-pm-dashboard/detail` :
                `${process.env.REACT_APP_API_URL}/pm-dashboard`,
            params.id ?
                { params: { id: params.id } }
                : {}
        )
            .then(res => {
                setAdvisorReport(res.data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setLoading(false)
            })
    }, [params.id])

    return (
        <MenuBar
            title={params.id ? 'Detail' : 'My Performance'}
            backButton={!!params.id}
        >
            <Root>
                <LoadingScreen
                    open={loading}
                    fullScreen
                ></LoadingScreen>
                <Box className={classes.header} >
                    <Avatar alt={advisorReport.name} src={advisorReport.profile_picture} sx={{ width: 64, height: 64 }} />
                    <Typography variant='h6' color="white" sx={{ mt: 2 }}>{advisorReport.name}</Typography>
                    <Typography variant='body2' color="white">Property Manager</Typography>
                </Box>
                <Box className={`${classes.content} ${classes.firstContent}`}>
                    <TargetCard
                        type='monthly'
                        advisorData={{
                            activity_count: advisorReport.this_month_showing_count,
                            closing_count: advisorReport.this_month_closing_count,
                            enquiry_count: advisorReport.this_month_enquiry_count,
                            commission: advisorReport.this_month_commission,
                            on_track: advisorReport.this_month_on_track,
                            target: advisorReport.monthly_target,
                        }}
                    />
                    <TargetCard
                        type='yearly'
                        advisorData={{
                            activity_count: advisorReport.this_year_showing_count,
                            closing_count: advisorReport.this_year_closing_count,
                            enquiry_count: advisorReport.this_year_enquiry_count,
                            commission: advisorReport.this_year_commission,
                            on_track: advisorReport.this_year_on_track,
                            target: advisorReport.yearly_target,
                        }}
                    />
                </Box>
                <Box className={classes.content}>
                    <Typography variant='h5' sx={{ mt: 2 }}>
                        <strong>
                            History Target Bulanan
                        </strong>
                    </Typography>
                </Box>
                <Box className={classes.content}>
                    {
                        advisorReport.history_commissions && advisorReport.history_commissions.length ?
                            advisorReport.history_commissions.map((history, idx) => (
                                <MontlyHistoryCard data={history} key={idx} />
                            ))
                            : <Typography>Advisor belum memiliki history bulanan</Typography>
                    }
                </Box>
            </Root>
        </MenuBar>
    )
}

const useStyles = generateStyle((theme: Theme) =>
({
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: 0,
        width: '100%',
        padding: 30,
        backgroundColor: '#009be5',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '-8px',
            width: 'calc(100% + 16px)',
            paddingBottom: 80,
            borderBottomRightRadius: 15,
            borderBottomLeftRadius: 15,
            marginTop: '-8px'
        }
    },
    content: {
        paddingLeft: 12,
        paddingRight: 12
    },
    firstContent: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '-40px'
        }
    }
}), "TargetScoringDashboard"
)


export default TargetScoringDashboard
import { Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import MenuBar from '../../_components/MenuBar'
import DateRangePicker, { OnDatesChange } from '../../_components/DateRangePicker'
import DefaultAxios from '../../_utils/DefaultAxios'

/**
 * Icons
 */
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PaidIcon from '@mui/icons-material/Paid';
import { generalErrorHandler, numberToCurrency } from '../../_utils/Helper'
import { format } from 'date-fns'
import LoadingScreen from '../../_components/LoadingScreen'
import { generateStyle } from '../../_utils/DefaultStyle'

type Props = {}

interface ICollection {
    total_active_ticket: number
    total_rent_bill: number
    total_utility_bill: number
    total_other_bill: number
    total_bill: number
}

const initialCollection = {
    total_active_ticket: 0,
    total_rent_bill: 0,
    total_utility_bill: 0,
    total_other_bill: 0,
    total_bill: 0,
}

const useStyles = generateStyle((theme) => ({
    date: {
        display: 'flex',
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        padding: '16px 0px 8px 8px',
        marginBottom: 20,

        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    datePickerContainer: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 220,
        }
    },
    title: {
        fontWeight: '500'
    },
    red: {
        color: theme.palette.error.main
    }
}), "Dashboard_Collection")

const DashboardCollection = (props: Props) => {
    const { Root, classes } = useStyles()
    const [state, setState] = useState<ICollection>(initialCollection)
    const [isLoading, setIsLoading] = useState(false)
    const [date, setDate] = useState<{ start_date: null | Date, end_date: null | Date }>({
        start_date: null,
        end_date: null
    })

    const handleChangeDate = ({ startDate, endDate }: OnDatesChange) => {
        setDate({
            start_date: startDate,
            end_date: endDate
        })
    }

    const fetchData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/ticket-collection-bill-dashboard`, {
            params: {
                date: `${date.start_date ? format(date.start_date, 'dd/MM/yyyy') : ''} - ${date.end_date ? format(date.end_date, 'dd/MM/yyyy') : ''}`
            }
        })
            .then(res => res.data)
            .then(res => setState(res))
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchData()

        // eslint-disable-next-line
    }, [])

    return (
        <MenuBar
            title='Dashboard Collection'
            backButton={false}
        >
            <LoadingScreen fullScreen open={isLoading} />
            <Root>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container sx={{ display: 'flex', alignItems: 'center' }} spacing={3}>
                            <Grid item md={6} xs={12}>
                                <Typography fontWeight={"600"} variant='h5' sx={{ marginRight: 'auto' }}>Ticket Bill Report</Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <div className={classes.date}>
                                    <div style={{ marginLeft: 'auto' }} className={classes.datePickerContainer}>
                                        <DateRangePicker
                                            startDate={date.start_date}
                                            endDate={date.end_date}
                                            onDatesChange={handleChangeDate}
                                            onFinished={() => fetchData()}
                                            format="dd/MM/yyyy"
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={3} xs={12}>
                                <Paper variant='outlined' sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <ConfirmationNumberIcon color='primary' sx={{ mb: 2, fontSize: 48 }} />
                                    <Typography textAlign="center" variant='h6' fontWeight={'400'}>Total Active Ticket</Typography>
                                    <Typography textAlign="center" variant='h5' fontWeight={'700'} className={classes.red}>{state.total_active_ticket}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Paper variant='outlined' sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <PaidIcon color='primary' sx={{ mb: 2, fontSize: 48 }} />
                                    <Typography textAlign="center" variant='h6' fontWeight={'400'}>Total Bill</Typography>
                                    <Typography textAlign="center" variant='h5' fontWeight={'700'} className={classes.red}>{numberToCurrency(state.total_bill, 'Rp. ')}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item md={2.5} xs={12} sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                                <Paper variant='outlined' sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start' }}>
                                    <Typography textAlign="center" variant='h6' fontWeight={'400'}>Rent Bill</Typography>
                                    <Typography fontSize={20} fontWeight={'700'}>{numberToCurrency(state.total_rent_bill, 'Rp. ')}</Typography>
                                </Paper>
                                <Paper variant='outlined' sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start' }}>
                                    <Typography textAlign="center" variant='h6' fontWeight={'400'}>Utility Bill</Typography>
                                    <Typography fontSize={20} fontWeight={'700'}>{numberToCurrency(state.total_utility_bill, 'Rp. ')}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item md={2.5} xs={12} sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                                <Paper variant='outlined' sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start' }}>
                                    <Typography textAlign="center" variant='h6' fontWeight={'400'}>Other Bill</Typography>
                                    <Typography fontSize={20} fontWeight={'700'}>{numberToCurrency(state.total_other_bill, 'Rp. ')}</Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Root>
        </MenuBar>
    )
}

export default DashboardCollection
import React, { Fragment, useState, useEffect } from 'react';
import { Grid, Button, ButtonGroup } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import ImageCard from './_components/ImageCard';
import LoadingScreen from '../../_components/LoadingScreen';
import ImageForm from './_components/ImageForm';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';


interface Props {
    projectDeveloperId: string;
}

export interface IState {
    id: number | null;
    caption: string;
    alt: string;
    thumbnail: string;
    file: File | null;
    is_primary: number;
}

const useStyles = generateStyle(() =>
({
    submitButton: {
        float: 'right'
    }
}), "ProjectDevImageForm"
);

const ProjectDeveloperImageForm = (props: Props) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/project-developer/${props.projectDeveloperId}/image`;
    const { Root, classes } = useStyles();

    const [formStates, setFormStates] = useState<IState[]>([]);
    const [imageStates, setImageStates] = useState<IState[]>([]);
    const [mode, setMode] = useState<'add' | 'edit'>('add');
    const [isLoading, setIsLoading] = useState(false);

    const defaultState = {
        id: null,
        caption: '',
        alt: '',
        thumbnail: '',
        file: null,
        is_primary: 0
    };

    useEffect(() => {
        loadImages();
        // eslint-disable-next-line
    }, []);

    const loadImages = () => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL)
            .then(res => {
                const imageGallery = res.data;
                const newImageStates = [];

                for (let key in imageGallery) {
                    newImageStates.push({
                        id: imageGallery[key].id,
                        caption: imageGallery[key].caption,
                        alt: imageGallery[key].alt,
                        thumbnail: imageGallery[key].image,
                        file: null,
                        is_primary: +imageGallery[key].is_primary
                    })
                }

                setImageStates(newImageStates);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleNewData = () => {
        if (!formStates.length) {
            setMode('add');
            setFormStates([
                {
                    ...defaultState
                }
            ])
        }
    }

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const oldStates: any[] = formStates.slice();
        const target = event.target;

        if (target.name === 'file') {
            if (target.files && target.files[0]) {
                const file = target.files[0];
                oldStates[index]['thumbnail'] = URL.createObjectURL(file);
                oldStates[index]['file'] = file;
            }
        } else {
            oldStates[index][target.name] = target.value;
        }
        setFormStates(oldStates);
    }

    const formAction = (action: string) => {
        const oldState = formStates.slice();
        switch (action) {
            case 'add':
                oldState.push({ ...defaultState });
                break;
            case 'remove':
                oldState.splice(oldState.length - 1, 1);
                break;
            default:
                break;
        }
        setFormStates(oldState);
    }

    const renderForms = () => {
        const formsEl = formStates.map((state, key) => {

            return <Fragment key={key}>
                <ImageForm form={state} onChange={handleChanged} index={key} />
                <Grid item xs={12}>
                    <hr />
                </Grid>
            </Fragment>
        });

        const actionButtons = (
            <>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    {
                        mode === 'add'
                            ? <ButtonGroup disableElevation variant="contained">
                                <Button color="primary" onClick={() => formAction('add')}>+</Button>
                                <Button color="secondary" onClick={() => formAction('remove')}>-</Button>
                            </ButtonGroup>
                            : null
                    }
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.submitButton}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </>
        )

        return (
            <>
                {formsEl}
                {formStates.length ? actionButtons : null}
            </>
        );
    }

    const handleEditImage = (imageState: IState) => {
        setMode('edit');
        setFormStates([
            {
                ...imageState
            }
        ])
    }

    const handleDeleteImage = (imageState: IState) => {
        renderWarningButton("Apakah anda yakin ingin menghapus image ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/project-developer/image/delete`, { id: imageState.id, projectDeveloperId: props.projectDeveloperId })
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(res => {
                                    loadImages();
                                })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handlePrimaryImage = (imageState: IState) => {
        if (imageState.is_primary === 0) {
            DefaultAxios
                .post(`${API_URL}/switch-primary`, { id: imageState.id })
                .then(res => {
                    loadImages();
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
        }
    }

    const renderImages = () => {
        return imageStates.map((imageState, key) => {
            return <Fragment key={key}>
                <Grid item xs={6} md={4} lg={3}>
                    <ImageCard
                        imageState={imageState}
                        onEditImage={handleEditImage}
                        onDeleteImage={handleDeleteImage}
                        onPrimaryImage={handlePrimaryImage}
                    />
                </Grid>
            </Fragment>
        });
    }

    const checkValidation = () => {
        let isValid = true;

        formStates.map(formState => {
            if (!formState.file && mode === 'add') {
                isValid = false;
            }

            if (!formState.caption) {
                isValid = false;
            }
            return true;
        });

        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            Swal.fire({
                title: "Error",
                text: 'Please fill caption and file on every form',
                icon: 'error'
            });
            return;
        }

        let url;
        const fd = new FormData();

        if (mode === 'add') {
            for (let i in formStates) {
                for (let key in formStates[i]) {
                    let value = formStates[i][key as keyof IState];
                    if (value) {
                        if (key === 'file') {
                            fd.append('image[]', value as File);
                        } else {
                            fd.append(`${key}[]`, String(value));
                        }
                    }
                }
            }

            url = API_URL;
        } else {
            for (let i in formStates) {
                for (let key in formStates[i]) {
                    let value = formStates[i][key as keyof IState];
                    if (value) {
                        if (key === 'file') {
                            fd.append('image', value as File);
                        } else {
                            fd.append(`${key}`, String(value));
                        }
                    }
                }
            }

            url = process.env.REACT_APP_API_URL + '/project-developer/image/update';
        }

        setIsLoading(true);
        DefaultAxios.post(url, fd)
            .then(res => {
                Swal.fire({
                    title: "Submit image berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadImages();
                        setFormStates([]);
                    })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNewData}
                    >
                        Add Image
                    </Button>
                    <hr />
                </Grid>

                {renderForms()}

                <Grid item xs={12}>
                    <hr />
                </Grid>

                {renderImages()}
            </Grid>
        </Root>
    );
}

export default ProjectDeveloperImageForm;
import React, { useState, useEffect } from 'react'
import { Button, Grid, Table, TableCell, TableRow, Typography } from '@mui/material'
import VisuallyHiddenInput from '../../_components/_form/VisuallyHiddenInput'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../_utils/Helper'
import LoadingScreen from '../../_components/LoadingScreen'

type Props = {}

interface IUserList {
    id: string
    name: string
}

const BulkUpdateExtendPIC = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [userList, setUserList] = useState<IUserList[]>([]);
    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setIsLoading(false)
            const fd = new FormData()
            fd.append('file', e.target.files[0])
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-bulk-change-pic`, fd)
                .then(res => {
                    renderSuccessButton('Berhasil Update Extend PIC!')
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                    e.target.value = ''
                })
        }
    }

    useEffect(() => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/tenant-relation-list`)
            .then(res => {
                setUserList(res.data);
            })
    }, [])

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container>
                <Grid container xs={12}>
                    <Grid item xs={1}>
                    <Button component="label" variant="contained">
                        Upload CSV
                        <VisuallyHiddenInput
                            type="file"
                            multiple={false}
                            accept=".csv"
                            onChange={handleChangeFile}
                        />
                    </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1'>
                            Note: Upload file CSV dengan 2 Kolom diisi (Kode Closing dan PIC ID-nya)
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Table>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                        </TableRow>
                        {userList.map((user) => {
                            return (
                                <TableRow>
                                    <TableCell>{user.id}</TableCell>
                                    <TableCell>{user.name}</TableCell>
                                </TableRow>
                            )
                        })}
                    </Table>
                </Grid>
            </Grid>
        </>
    )
}

export default BulkUpdateExtendPIC
import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'

interface ILandlord {
    id: string,
    name: string,
    units: string,
    pic_id: string
}

interface IProps {
    data: ILandlord[]
}

const LandlordTable = (props: IProps) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nama</TableCell>
                        <TableCell>Units</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.data && props.data.length ?
                            props.data.map((landlord) => (
                                <TableRow>
                                    <TableCell>
                                        {
                                            landlord.name ?
                                                <Link
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => { window.open(`/landlord/${landlord.id}`) }}
                                                >
                                                    {landlord.name}
                                                </Link>
                                                : '-'
                                        }
                                    </TableCell>
                                    <TableCell>{landlord.units || '-'}</TableCell>
                                </TableRow>
                            )) :
                            <TableRow>
                                <TableCell colSpan={2}>No Landlord Data</TableCell>
                            </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default LandlordTable
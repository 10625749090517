import React, { useState, useEffect } from 'react';
import { Button, Theme, Link } from '@mui/material';
import { Link as RouterLink, useParams } from "react-router-dom";

// Components
import DataTable from '../../_components/_dataTable/DataTable';
import AgentOfficeForm from './components/AgentOfficeForm';
import AgentOfficeDetail from './components/AgentOfficeDetail';

// Icons
import AddIcon from '@mui/icons-material/Add';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    office_id?: string
}
interface IParams extends Record<string, string | undefined> {
    office_id?: string
}


const useStyles = generateStyle((theme: Theme) => ({
    addButton: {
        marginBottom: '20px'
    },
}), "AgentOffice_List"
)

const AgentOfficeList = (props: IProps) => {
    const { Root, classes } = useStyles();
    const DT_API_URL = process.env.REACT_APP_API_URL + '/agent-office';
    const params = useParams<IParams>()
    // const navigate =  useNavigate()
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [selectedId, setSelectedId] = useState<string>('');
    const [isAdd, setIsAdd] = useState(true);
    const [modalState, setModalState] = useState(false);
    const [modalDashboardState, setModalDashboardState] = useState(false);
    const handleOpen = () => setModalState(true);
    const handleClose = () => setModalState(false);
    const handleOpenDashboard = () => setModalDashboardState(true);
    const handleCloseDashboard = () => {
        setModalDashboardState(false)
        window.history.pushState([], 'Agent Office', `/agent-office/`);
    };

    const openModalCreate = () => {
        setSelectedId('');
        setIsAdd(true);
        handleOpen();
    }

    // const openModalEdit = (data: any) => {
    //     setSelectedId(data.id);
    //     setIsAdd(false);
    //     handleOpen();
    // }

    const submitSuccess = () => {
        handleClose();
        setReDrawDatatable(new Date().getTime());
    }

    const handleCloseDashboardEdited = () => {
        handleCloseDashboard();
        setReDrawDatatable(new Date().getTime());
    }

    useEffect(() => {
        if (typeof params.office_id !== "undefined") {
            setSelectedId(params.office_id);
            handleOpenDashboard();
        }
    }, [params]);

    return (
        <Root>
            <AgentOfficeForm
                open={modalState}
                onClose={handleClose}
                mode={isAdd ? 'add' : 'edit'}
                onSubmitSuccess={submitSuccess}
                officeId={selectedId}
            />

            <AgentOfficeDetail
                open={modalDashboardState}
                onClose={handleCloseDashboard}
                onCloseEdited={handleCloseDashboardEdited}
                officeId={selectedId}
            />

            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={openModalCreate}
            >
                Add Office
            </Button>
            <DataTable
                reDraw={reDrawDatatable}
                url={DT_API_URL}
                columns={[
                    {
                        name: 'name', label: 'Name', filterable: true, render: (data: any, row: any) => {
                            return (
                                <Link
                                    style={{ cursor: "pointer" }}
                                    component={RouterLink}
                                    to={`/agent-office/${row.id}`}
                                >
                                    {data}
                                </Link>
                            );
                        }
                    },
                    { name: 'phone', label: 'Phone', filterable: true },
                    { name: 'address', label: 'Address', filterable: true },
                ]}
            />
        </Root>
    );
}

export default AgentOfficeList;
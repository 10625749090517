import React, { useEffect, useState } from 'react';
import { TableContainer, Dialog, DialogContent, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import moment from 'moment';

// Components
import DefaultAxios from '../../../_utils/DefaultAxios';
import LoadingScreen from '../../../_components/LoadingScreen';

interface Props {
    agentId?: string;
}

const AgentLogs = ({ agentId }: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/agent/' + agentId + '/logs';
    const [isLoading, setIsLoading] = useState(false);
    const [logs, setLogs] = useState<any[]>([]);
    const [selectedLog, setSelectedLog] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    useEffect(() => {
        setIsLoading(true);
        DefaultAxios.get(API_URL)
            .then(res => {
                setLogs(res.data);
            })
            .finally(() => {
                setIsLoading(false);
            })
        // eslint-disable-next-line
    }, [agentId]);

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
            >
                <DialogContent>
                    <div>
                        <pre>
                            {selectedLog ? JSON.stringify(selectedLog, null, 2) : 'Tidak Ada  Log'}
                        </pre>
                    </div>
                </DialogContent>
            </Dialog>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Tanggal</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Action</TableCell>
                            </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            logs.length
                                ? logs.map(log => (
                                    <TableRow key={log.id}>
                                        <TableCell>
                                            <p>{moment(log.created_at).format("DD MMMM YYYY")}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{log.user_full_name}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{log.type ? log.type : '-'}</p>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedLog(JSON.parse(log.log));
                                                    handleModalOpen();
                                                }}
                                            >
                                                See Log
                                        </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                                : <TableRow>
                                    <TableCell colSpan={3}>Tidak ada Log</TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default AgentLogs;
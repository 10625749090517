import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

/**
 * Components
 */
import TextArea from '../../../_components/_form/TextArea'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/**
 * Utils 
 */
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper'

interface IProps {
    open: boolean
    onClose: (refresh: boolean) => void
    id: number,
    type: string
}

const ModalKeyUnit = (props: IProps) => {
    const [state, setState] = useState({
        id: props.id,
        comment: ''
    })

    const handleToggle = () => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit/switch-entrusted-key`, state)
            .then(res => res.data)
            .then(res => {
                if (res) {
                    Swal.fire("Success", `Key Unit ${props.type === 'Entrust' ? 'Entrusted': 'Removed'}`, 'success')
                    props.onClose(true)
                }
            })
            .catch(err => generalErrorHandler(err))
    }

    useEffect(() => {
        if (!props.open) {
            return setState({
                id: 0,
                comment: ''
            })
        }

        setState({
            id: props.id,
            comment: ''
        })
    }, [props.open, props.id])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle >
                <IconButton
                    sx={{left: -10, p: 'relative'}}
                    onClick={() => props.onClose(false)}
                >
                    <ArrowBackIcon />
                </IconButton>
                {props.type} Key Unit
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextArea
                            name='reason'
                            onChange={(e) => setState(prev => ({ ...prev, comment: e.target.value }))}
                            placeholder='Place comment here'
                            value={state.comment}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', marginTop: 10, justifyContent: 'flex-end' }}>
                        <Button
                            variant='contained'
                            onClick={handleToggle}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ModalKeyUnit
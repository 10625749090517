import React, { Fragment, useState, useEffect } from 'react';
import { Link, IconButton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import UnitModalDetail from '../_unit/UnitModalDetail';
// import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Icon
 */
import VisibilityIcon from '@mui/icons-material/Visibility';
import { dateFormat, numberToCurrency } from '../../_utils/Helper';

interface IParams extends Record<string, string | undefined> {
    id?: string
}

const DashboardUnitAdvisorCaptain = () => {
    // const classes = useStyles();
    const API_URL = `${process.env.REACT_APP_API_URL}/assigned-unit-list`;
    const navigate =  useNavigate();
    const params = useParams<IParams>()
    const [detailOpen, setDetailOpen] = useState(false);
    const handleCloseDetail = () => {
        setDetailOpen(false)
        window.history.pushState([], 'Advisor Captain Portofolio', `/assigned-unit-list`);
    };
    const handleDetailOpen = () => setDetailOpen(true);

    useEffect(() => {
        if (typeof params.id !== "undefined") {
            handleDetailOpen();
        }
    }, [params]);

    return (
        <Fragment>
            {/* <LoadingScreen open={isLoading} fullScreen /> */}


            <UnitModalDetail 
                open={detailOpen}
                onModalClose={handleCloseDetail} 
            />

            {/* <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => navigate('/unit/add')}
            >
                Add Unit
            </Button> */}

            <DataTable
                url={API_URL}
                columns={[
                    {
                        name: 'apartment_name',
                        label: 'Apartemen',
                        filterable: true,
                        type: 'string',
                        style: { minWidth: '200px' },
                    },
                    {
                        name: 'code',
                        label: 'Unit Code',
                        filterable: true,
                        type: 'string',
                        style: { minWidth: '150px' },
                        render: (data: string, row: any) => {
                            return (
                                <>
                                    <Link
                                        onClick={() => navigate(`/assigned-unit-list/${row.code}`)}
                                        component="button"
                                    >
                                        {data}
                                    </Link>
                                    <IconButton color="primary" onClick={() => window.open(`${process.env.REACT_APP_MAIN_URL}/${row.code}`)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </>
                            )
                        }
                    },
                    // {
                    //     name: 'bedroom',
                    //     label: 'Type',
                    //     filterable: true,
                    //     defaultOption: '',
                    //     options: [
                    //         {
                    //             key: '',
                    //             value: 'All'
                    //         },
                    //         {
                    //             key: '0',
                    //             value: 'Studio'
                    //         },
                    //         {
                    //             key: '1',
                    //             value: '1BR'
                    //         },
                    //         {
                    //             key: '2',
                    //             value: '2BR'
                    //         },
                    //         {
                    //             key: '3',
                    //             value: '3BR+'
                    //         },
                    //     ],
                    //     render: (data: number) => {
                    //         return data > 0 ? data + ' BR' : 'Studio';
                    //     }
                    // },
                    {
                        name: 'furnished',
                        label: 'Furnish',
                        filterable: true,
                        type: 'string',
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: 'non',
                                value: 'Non'
                            },
                            {
                                key: 'semi',
                                value: 'Semi'
                            },
                            {
                                key: 'full',
                                value: 'Full'
                            },
                        ],
                    },
                    {
                        name: 'category',
                        label: 'Category',
                        filterable: true,
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '0',
                                value: 'Reguler'
                            },
                            {
                                key: '2',
                                value: 'Verified'
                            },
                            {
                                key: '1',
                                value: 'Star Listing'
                            }
                        ],
                        render: (data: number) => {
                            switch (data) {
                                case 0:
                                    return 'Reguler';
                                case 1:
                                    return 'Star Listing';
                                case 2:
                                    return 'Verified';
                                case 3:
                                    return 'SLOH'
                                default:
                                    return 'N/A';
                            }
                        }
                    },
                    // {
                    //     name: 'basic_price_12',
                    //     label: 'Harga Base',
                    //     filterable: true,
                    //     type: 'number',
                    //     style: { minWidth: '200px' },
                    //     render: (data: string) => {
                    //         return numberToCurrency(data, 'Rp');
                    //     }
                    // },
                    {
                        name: 'price_per_12_months',
                        label: 'Harga Web',
                        filterable: true,
                        type: 'number',
                        style: { minWidth: '200px' },
                        render: (data: string) => {
                            return numberToCurrency(data, 'Rp');
                        }
                    },
                    // {
                    //     name: 'price_per_12_months',
                    //     label: 'Potensi Margin',
                    //     filterable: true,
                    //     style: { minWidth: '200px' },
                    //     type: 'number',
                    //     render: (data: string, row: any) => {
                    //         let a = row.price_per_12_months
                    //         let b = row.basic_price_12
                    //         let total = a - b
                    //         let percentage = Math.floor(total / a * 100)
                    //         return `${numberToCurrency(total, 'Rp')} (${percentage}%)`
                    //         // return numberToCurrency(total, 'Rp');
                    //     }
                    // },
                    {
                        name: 'price_per_1_month',
                        label: 'Support Bulanan',
                        filterable: true,
                        type: 'string',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '0',
                                value: 'No'
                            },
                            {
                                key: '1',
                                value: 'Yes'
                            },
                        ],
                        render: (data: number) => {
                            return data > 0 ? 'YES' : 'NO';
                        }
                    },
                    // {
                    //     name: 'price_per_3_months',
                    //     label: 'Support 3 Bulan',
                    //     filterable: true,
                    //     type: 'string',
                    //     options: [
                    //         {
                    //             key: '',
                    //             value: 'All'
                    //         },
                    //         {
                    //             key: '0',
                    //             value: 'No'
                    //         },
                    //         {
                    //             key: '1',
                    //             value: 'Yes'
                    //         },
                    //     ],
                    //     render: (data: number) => {
                    //         return data > 0 ? 'YES' : 'NO';
                    //     }
                    // },
                    // {
                    //     name: 'price_per_6_months',
                    //     label: 'Support 6 Bulan',
                    //     filterable: true,
                    //     type: 'string',
                    //     options: [
                    //         {
                    //             key: '',
                    //             value: 'All'
                    //         },
                    //         {
                    //             key: '0',
                    //             value: 'No'
                    //         },
                    //         {
                    //             key: '1',
                    //             value: 'Yes'
                    //         },
                    //     ],
                    //     render: (data: number) => {
                    //         return data > 0 ? 'YES' : 'NO';
                    //     }
                    // },
                    {
                        name: 'full_name',
                        label: 'PIC',
                        filterable: true,
                        type: 'string',
                        style: { minWidth: '200px' },
                    },
                    {
                        name: 'assigned_to_advisor_at',
                        label: 'Waktu Assign',
                        filterable: true,
                        type: 'date',
                        render: (data: string) => {
                            return data ? dateFormat(data, 'DD MMM YYYY') : '-';
                        }
                    },
                ]}
            />
        </Fragment>
    );
}

export default DashboardUnitAdvisorCaptain;
import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { Button, CircularProgress, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme } from '@mui/material'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, numberToCurrency } from '../../_utils/Helper'
import { generateStyle } from '../../_utils/DefaultStyle'

interface TopApartmentClosingItem {
    apartment_name: string
    count: number
    total_revenue_jendela: string
    total_gmv_transaction: string
}

interface MonthYearOption {
    display: string
    month: number
    year: number
}

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const defaultMonth = {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    display: `${monthNames[new Date().getMonth()]} ${new Date().getFullYear()}`
}

const TopApartmentClosing = () => {
    const API_URL = `${process.env.REACT_APP_API_URL}/top-closing-apartment`
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(false)

    const [selectedFilter, setSelectedFilter] = useState<'noc' | 'revenue'>('noc');
    const [selectedMonth, setSelectedMonth] = useState<MonthYearOption>(defaultMonth)
    const [items, setItems] = useState<TopApartmentClosingItem[]>([])

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [selectedMonth, selectedFilter])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(API_URL, {
            params: {
                month: selectedMonth.month,
                year: selectedMonth.year,
                sort: selectedFilter,
            }
        })
            .then(res => res.data)
            .then(data => {
                setItems(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        const selected = dateYearOptions.filter(dateYear => dateYear.display === value)[0]

        if (selected) {
            setSelectedMonth(selected)
        } else {
            setSelectedMonth(defaultMonth)
        }
    }

    const exportData = () => {
        setIsLoading(true)

        DefaultAxios.post(`${API_URL}/export`, {
            month: selectedMonth.month,
            year: selectedMonth.year,
            sort: selectedFilter,
        })
            .then(res => {
                window.open(`${API_URL}/export-file?code=${res.data}`, '_blank');
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const dateYearOptions = useMemo(() => {
        const options: MonthYearOption[] = []

        const today = new Date()

        for (var i = 0; i <= 5; i += 1) {
            const currentDate = new Date(today.getFullYear(), today.getMonth() - i, 1)

            options.push({
                display: `${monthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`,
                month: currentDate.getMonth() + 1,
                year: currentDate.getFullYear(),
            })
        }

        return options
    }, [])

    return (
        <Root>
            <div className={classes.headerContainer}>
                <div className={classes.headerLeft}>
                    <div className={classes.headerFilter}>
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label="Filter"
                            name="filter"
                            value={selectedFilter}
                            onChange={(e) => setSelectedFilter(e.target.value as 'noc' | 'revenue')}
                        >
                            <MenuItem value="noc">Highest NoC</MenuItem>
                            <MenuItem value="revenue">Highest Revenue</MenuItem>
                        </TextField>
                    </div>
                </div>
                <div className={classes.headerRight}>
                    <div className={classes.headerButton}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={exportData}
                        >
                            Export
                        </Button>
                    </div>
                    <div className={classes.headerFilter}>
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label="Select Month to Display"
                            name="selectedMonth"
                            value={selectedMonth.display}
                            onChange={handleSelect}
                        >
                            {
                                dateYearOptions.map(dateYear => <MenuItem key={dateYear.display} value={dateYear.display}>{dateYear.display}</MenuItem>)
                            }
                        </TextField>
                    </div>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <span>
                                    Nama Apartment
                                </span>
                            </TableCell>
                            <TableCell align='right'>
                                <span>
                                    NoC
                                </span>
                            </TableCell>
                            <TableCell align='right'>
                                <span>
                                    Revenue
                                </span>
                            </TableCell>
                            <TableCell align='right'>
                                <span>
                                    GMV
                                </span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isLoading ?
                                <TableRow>
                                    <TableCell colSpan={4} align='center'>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                                :
                                items.length > 0 ?
                                    <>
                                        {
                                            items.map((item, index) =>
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {item.apartment_name}
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        {item.count}
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        {numberToCurrency(item.total_revenue_jendela, 'Rp')}
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        {numberToCurrency(item.total_gmv_transaction, 'Rp')}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                        <TableRow>
                                            <TableCell align='left' style={{ fontWeight: '500' }}>
                                                Total
                                            </TableCell>
                                            <TableCell align='right' style={{ fontWeight: '500' }}>
                                                {numberToCurrency(items.reduce((prev, next) => prev + next.count, 0))}
                                            </TableCell>
                                            <TableCell align='right' style={{ fontWeight: '500' }}>
                                                {numberToCurrency(items.reduce((prev, next) => prev + Number(next.total_revenue_jendela), 0), 'Rp')}
                                            </TableCell>
                                            <TableCell align='right' style={{ fontWeight: '500' }}>
                                                {numberToCurrency(items.reduce((prev, next) => prev + Number(next.total_gmv_transaction), 0), 'Rp')}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                    :
                                    <TableRow>
                                        <TableCell colSpan={4} align='center'>
                                            Tidak ada data
                                        </TableCell>
                                    </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    headerContainer: {
        display: 'flex',
    },
    headerLeft: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    headerRight: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    headerButton: {

    },
    headerFilter: {
        maxWidth: '100%',
        width: 240,
        margin: '16px 0px'
    },
}), "TopApartmentClosing")

export default TopApartmentClosing

import { BottomNavigation, BottomNavigationAction, Button, Grid, Link, TextField, Theme } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DefaultAxios from '../../_utils/DefaultAxios'
import LoadingScreen from '../LoadingScreen'
import TextEditor from './TextEditor'
import Swal from 'sweetalert2';
import { cleanObject, generalErrorHandler } from '../../_utils/Helper'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation'
import { generateStyle } from '../../_utils/DefaultStyle'

interface IProps {
    url: string
    buttonGroupOne?: {
        key: string
        default: string
        list: {
            id: string
            label: string
        }[]
    }
    buttonGroupTwo?: {
        key: string
        default: string
        list: {
            id: string
            label: string
        }[]
    }
    buttonGroupThree?: {
        key: string
        default: string
        list: {
            id: string
            label: string
        }[]
    }
    withPrimaryImage?: boolean
    withoutTitle?: boolean
    withoutMetaTitle?: boolean
    withoutMetaDescription?: boolean
    customArticleParams?: string
    extraParams?: {
        [key: string]: string
    }
    articleNotRequired?: boolean
}

interface IButtonState {
    [key: string]: string
}

interface IState {
    title: string
    meta_title: string
    meta_desc: string
    article: string
    primary_image: {
        url: string
        file: File | null
    },
}

const ArticleForm = (props: IProps) => {
    const { Root, classes } = useStyles()

    const [isLoading, setIsLoading] = useState(false)

    const [buttonState, setButtonState] = useState<IButtonState>({})

    const [state, setState] = useState<IState>({
        title: '',
        meta_title: '',
        meta_desc: '',
        article: '',
        primary_image: {
            url: '',
            file: null
        },
    })
    const [error, setError] = useState<IValidationErrors>({})

    const validationRules: IValidationRules = useMemo(() => ({
        title: props.withoutTitle ? '' : 'required',
        meta_title: props.withoutMetaTitle ? '' : 'required',
        meta_desc: props.withoutMetaDescription ? '' : 'required',
        article: props.articleNotRequired ? '' : 'required',
    }), [props.withoutTitle, props.withoutMetaTitle, props.withoutMetaDescription, props.articleNotRequired])

    const fetchData = useCallback(() => {
        setIsLoading(true)

        DefaultAxios.get(props.url, { params: { ...buttonState, ...props.extraParams } })
            .then(res => res.data)
            .then(res => {
                const cleanedObject = cleanObject(res)
                setState({
                    ...initialState,
                    ...cleanedObject,
                    article: props.customArticleParams ? cleanedObject[props.customArticleParams] : cleanedObject.article,
                    primary_image: {
                        url: res.primary_image || '',
                        file: null,
                    }
                })
            })
            .catch(err => {
                if (err?.response?.status === 404) {
                    setState({
                        ...initialState,
                    })
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [buttonState, props.url, props.extraParams, props.customArticleParams])

    useEffect(() => {
        const newState: IButtonState = {}
        if (props.buttonGroupTwo) {
            newState[props.buttonGroupTwo.key] = props.buttonGroupTwo.default
        }

        if (props.buttonGroupOne) {
            newState[props.buttonGroupOne.key] = props.buttonGroupOne.default
        }

        if (props.buttonGroupThree) {
            newState[props.buttonGroupThree.key] = props.buttonGroupThree.default
        }

        setButtonState(newState)
    }, [props.buttonGroupTwo, props.buttonGroupOne, props.buttonGroupThree])

    useEffect(() => {
        setError({})
        if (props.url && Object.keys(buttonState).length > 0) {
            fetchData()
        }
    }, [buttonState, props.url, fetchData, props.extraParams])

    const handleButtonStateChange = (newValue: string, type?: string) => {
        if (typeof type === 'undefined') return

        setButtonState(prev => ({
            ...prev,
            [type]: newValue
        }))
    }

    const handleInputChangedData = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name
        let value: string | { file: File | null, url: string } = e.target.value

        if (name === 'primary_image') {
            if (e.target.files && e.target.files.length > 0) {
                value = {
                    file: e.target.files[0],
                    url: '',
                }
            }
        }

        setState(prev => ({
            ...prev,
            [name]: value
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const name = e.target.name
        const value = e.target.value

        if (name in state) {
            const { isValid, errors } = validateData({ [name]: value }, validationRules, validationAlias)

            if (!isValid) {
                setError(prev => ({
                    ...prev,
                    ...errors,
                }))
            } else {
                setError(prev => ({
                    ...prev,
                    [name]: '',
                }))
            }
        }
    }

    const handleEditorChanged = (name: string, value: string) => {
        setState(prev => ({
            ...prev,
            [name]: value
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleDeleteAsset = () => {
        setIsLoading(true);
        DefaultAxios.delete(`${props.url}/file/delete`, { params: buttonState })
            .then(res => {
                setState(prev => ({
                    ...prev,
                    primary_image: {
                        url: '',
                        file: null
                    }
                }))
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const submit = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })

        const { isValid, errors } = validateData(state, validationRules, validationAlias)
        setError(errors)

        if (isValid) {
            setIsLoading(true)
            const formData = new FormData()

            for (let key in buttonState) {
                formData.append(key, buttonState[key as keyof IButtonState])
            }

            for (let key in state) {
                if (key === 'primary_image' && !props.withPrimaryImage) continue
                if (key === 'title' && props.withoutTitle) continue
                if (key === 'meta_title' && props.withoutMetaTitle) continue
                if (key === 'meta_desc' && props.withoutMetaDescription) continue
                if (props.customArticleParams && key === props.customArticleParams) continue

                const currentValue = state[key as keyof IState]
                if (key === 'primary_image' && typeof currentValue !== 'string' && currentValue.file) {
                    formData.append(key, currentValue.file)
                } else if (typeof currentValue === 'string') {
                    if (key === 'article') {
                        formData.append(props.customArticleParams || key, currentValue)
                    } else {
                        formData.append(key, currentValue)
                    }
                }
            }

            for (const key in props.extraParams) {
                formData.append(key, props.extraParams[key])
            }

            DefaultAxios.post(`${props.url}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => res.data)
                .then(res => {
                    Swal.fire({
                        title: "Article Submitted",
                        icon: 'success',
                        onAfterClose: () => {
                            fetchData()
                        },
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <Root>
            <Grid container spacing={2}>
                <LoadingScreen open={isLoading} fullScreen />
                {
                    props.buttonGroupOne &&
                    <Grid item xs={12}>
                        <BottomNavigation
                            value={buttonState[props.buttonGroupOne.key]}
                            onChange={(e, newValue) => handleButtonStateChange(newValue, props.buttonGroupOne?.key)}
                            showLabels
                            className={classes.filter}
                        >
                            {
                                props.buttonGroupOne.list.map(item =>
                                    <BottomNavigationAction
                                        key={item.id}
                                        classes={{
                                            root: classes.filterButton,
                                            label: classes.filterButtonLabel,
                                            selected: classes.filterButtonSelected
                                        }}
                                        value={item.id}
                                        label={item.label}
                                    />
                                )
                            }
                        </BottomNavigation>
                    </Grid>
                }
                {
                    props.buttonGroupTwo &&
                    <Grid item xs={12} sm={6} md={2}>
                        <BottomNavigation
                            value={buttonState[props.buttonGroupTwo.key]}
                            onChange={(e, newValue) => handleButtonStateChange(newValue, props.buttonGroupTwo?.key)}
                            showLabels
                            className={classes.filter}
                        >
                            {
                                props.buttonGroupTwo.list.map(item =>
                                    <BottomNavigationAction
                                        key={item.id}
                                        classes={{
                                            root: classes.filterButton,
                                            label: classes.filterButtonLabel,
                                            selected: classes.filterButtonSelected
                                        }}
                                        value={item.id}
                                        label={item.label}
                                    />
                                )
                            }
                        </BottomNavigation>
                    </Grid>
                }
                {
                    props.buttonGroupThree &&
                    <Grid item xs={12} sm={6} md={2}>
                        <BottomNavigation
                            value={buttonState[props.buttonGroupThree.key]}
                            onChange={(e, newValue) => handleButtonStateChange(newValue, props.buttonGroupThree?.key)}
                            showLabels
                            className={classes.filter}
                        >
                            {
                                props.buttonGroupThree.list.map(item =>
                                    <BottomNavigationAction
                                        key={item.id}
                                        classes={{
                                            root: classes.filterButton,
                                            label: classes.filterButtonLabel,
                                            selected: classes.filterButtonSelected
                                        }}
                                        value={item.id}
                                        label={item.label}
                                    />
                                )
                            }
                        </BottomNavigation>
                    </Grid>
                }
                {
                    props.withPrimaryImage &&
                    <Grid item xs={12} sm={12}>
                        <h3>Upload Gambar Utama</h3>
                        {
                            state.primary_image.url ?
                                <ul className={classes.fileList}>
                                    <li>
                                        <img src={state.primary_image.url} alt={state.primary_image.url} height="200px" />
                                        <div className={classes.fileDetail}>
                                            <Link
                                                component="button"
                                                color="secondary"
                                                onClick={() => handleDeleteAsset()}
                                            >
                                                Delete
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                                : null
                        }
                        <input type="file" name="primary_image" onChange={handleInputChangedData} style={{ marginBottom: '10px' }} />
                    </Grid>
                }

                {
                    props.withoutTitle ?
                        null
                        :
                        <Grid item xs={12} sm={12}>
                            <TextField
                                label="Title"
                                name="title"
                                variant="outlined"
                                value={state.title}
                                onChange={handleInputChangedData}
                                onBlur={handleBlur}
                                error={!!error.title}
                                helperText={error.title}
                                fullWidth
                                placeholder=""
                            >
                            </TextField>
                        </Grid>
                }
                {
                    props.withoutMetaTitle ?
                        null
                        :
                        <Grid item xs={12} sm={12}>
                            <TextField
                                label="Meta Title"
                                name="meta_title"
                                variant="outlined"
                                value={state.meta_title}
                                onChange={handleInputChangedData}
                                onBlur={handleBlur}
                                error={!!error.meta_title}
                                helperText={error.meta_title}
                                fullWidth
                                placeholder=""
                            >
                            </TextField>
                        </Grid>
                }
                {
                    props.withoutMetaDescription ?
                        null
                        :
                        <Grid item xs={12} sm={12}>
                            <TextField
                                label="Meta Description"
                                name="meta_desc"
                                variant="outlined"
                                value={state.meta_desc}
                                onChange={handleInputChangedData}
                                onBlur={handleBlur}
                                error={!!error.meta_desc}
                                helperText={error.meta_desc}
                                fullWidth
                                placeholder=""
                            >
                            </TextField>
                        </Grid>
                }

                <Grid item xs={12} sm={12}>
                    {/* <h4 className={classes.title}>Notes</h4> */}
                    <Grid item xs={12}>
                        <h3>Article</h3>
                        <TextEditor
                            name="article"
                            value={state.article}
                            onChange={handleEditorChanged}
                        />
                        {
                            error.article &&
                            <span className={classes.articleError}>{error.article}</span>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.gridSubmit}>
                    <Button variant="contained" color="primary" onClick={submit} className={classes.submit_button}>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Root>
    )
}

const initialState = {
    title: '',
    meta_title: '',
    meta_desc: '',
    article: '',
    primary_image: {
        url: '',
        file: null
    },
}

const useStyles = () => generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        marginBottom: '5px',
        flex: 1,
    },
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
        // fontSize: '0.8125rem',
        // border: '1px solid #006db3',
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
    gridSubmit: {
        textAlign: 'right',
        marginBottom: '10px'
    },
    submit_button: {
        marginLeft: '8px'
    },
    date: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    fileList: {
        listStyle: 'none',
        padding: 0,
        '& li': {
            display: 'flex'
        }
    },
    filePreview: {
        width: '65px',
        height: '65px',
        overflow: 'hidden',
        float: 'left',
        '& img': {
            width: '100%'
        }
    },
    fileDetail: {
        width: '100%',
        marginLeft: '10px',
        '& p': {
            margin: 0
        }
    },
    articleError: {
        color: theme.palette.error.main,
        marginTop: 8,
        marginLeft: 4,
        display: 'block',
    }
}), "Article_Form"
)()

const validationAlias: IValidationAlias = {
    title: 'Article Title',
    meta_title: 'Meta Title',
    meta_desc: 'Meta Description',
    article: 'Article Content',
}

export default ArticleForm

import React, { useReducer, useEffect, useState } from 'react';
import {
    Grid,
    TextField,
    Switch,
    Button,
    Theme,
    Dialog,
    DialogContent
} from '@mui/material';
import moment from 'moment';
import Swal from 'sweetalert2';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete';
import TextArea from '../../../_components/_form/TextArea';
import LoadingScreen from '../../../_components/LoadingScreen';
import MuiDatePicker from '../../../_components/MuiDatePicker';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import { currencyToNumber, dateFormat, generalErrorHandler, numberToCurrency } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface IState {
    id: string;
    unit_id: string;
    unit_label: string;
    booking_id: string;
    ota: string;
    name: string;
    phone: string;
    check_in: Date | null;
    check_out: Date | null;
    rates: string[];
    is_pay_on_spot: boolean;
    is_same_rate: boolean;
    notes: string;
    upload_file: File | null;
    ktp_url: string | null;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IErrorState {
    unit_id: string;
    booking_id: string;
    ota: string;
    name: string;
    phone: string;
    check_in: string;
    check_out: string;
    rates: string[];
}

const useStyles = generateStyle((theme: Theme) => ({
    formContainer: {
        padding: theme.spacing(2)
    },
    borderBottomLine: {
        borderBottom: '1px solid #eee'
    },
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
}), "BookingForm"
);

const BookingForm = ({ open, onClose, booking_id }: { open: boolean, onClose: Function, booking_id: number }) => {
    const API_URL = process.env.REACT_APP_API_URL + '/stay360-booking';
    const { Root, classes } = useStyles();

    const initialState = {
        id: '',
        unit_id: '',
        unit_label: '',
        booking_id: '',
        ota: '',
        name: '',
        phone: '',
        check_in: null,
        check_out: null,
        rates: [],
        is_pay_on_spot: false,
        is_same_rate: false,
        notes: '',
        upload_file: null,
        ktp_url: null
    };

    const initialErrorState = {
        unit_id: '',
        booking_id: '',
        ota: '',
        name: '',
        phone: '',
        check_in: '',
        check_out: '',
        rates: []
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (booking_id !== 0) {
            loadData();
        } else {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        checkRates();
        // eslint-disable-next-line
    }, [inputState.check_in, inputState.check_out, inputState.is_same_rate]);

    const loadData = () => {
        setIsLoading(true);

        DefaultAxios.get(API_URL + '/' + booking_id)
            .then(res => {
                setInputState({ name: '', value: res.data, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index?: number) => {
        const target = e.target;
        const name = target.name;
        let value: any = target.value;
        const newState: any = { ...inputState };

        if (name === 'is_same_rate' || name === 'is_pay_on_spot') {
            newState[name] = target.checked;
        } else if (name === 'rates' && typeof index !== 'undefined') {
            const newRates = newState.rates.slice();
            newRates[index] = String(currencyToNumber(value));
            newState.rates = newRates;
        } else if (name === 'upload_file') {
            if (target.files && target.files[0]) {
                newState[name] = target.files[0];
            } else {
                newState[name] = null;
            }
        } else {
            newState[name] = value;
        }

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    // TODO: might break
    const handleDateChanged = (name: string, date: Date | null) => {
        setInputState({ name, value: date, type: 'SET_ITEM' });
        // handleTerminDates();
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: e.target.value, type: 'SET_ITEM' });
    }

    const checkRates = () => {
        if (inputState.check_in && inputState.check_out) {
            const newState = { ...inputState };
            const oldRates = newState.rates.slice();
            const newRates = [];
            let diffDays;
            if (!inputState.is_same_rate) {
                const momentCheckIn = moment(inputState.check_in).startOf('day');
                const momentCheckOut = moment(inputState.check_out).startOf('day');
                diffDays = momentCheckOut.diff(momentCheckIn, 'days');
            } else {
                diffDays = 1;
            }

            for (let i = 0; i < diffDays; i++) {
                if (typeof oldRates[i] !== 'undefined') {
                    newRates.push(oldRates[i]);
                } else {
                    newRates.push('');
                }
            }

            newState.rates = newRates;
            setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
        }
    }

    const checkValidation = () => {
        let isValid = true;
        const newErrorState: any = { ...initialErrorState };

        if (!inputState.name) {
            newErrorState.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.phone) {
            newErrorState.phone = 'No HP wajib diisi';
            isValid = false;
        }

        if (!inputState.unit_id) {
            newErrorState.unit_id = 'Nomor Unit wajib diisi';
            isValid = false;
        }

        if (!inputState.ota) {
            newErrorState.ota = 'OTA wajib diisi';
            isValid = false;
        }

        if (!inputState.booking_id) {
            newErrorState.booking_id = 'Booking ID wajib diisi';
            isValid = false;
        }

        if (!inputState.check_in) {
            newErrorState.check_in = 'Check In wajib diisi';
            isValid = false;
        } else if (inputState.check_out && moment(inputState.check_in).isAfter(moment(inputState.check_out))) {
            newErrorState.check_in = 'Check In tidak boleh lebih besar dari Check Out';
            isValid = false;
        }

        if (!inputState.check_out) {
            newErrorState.check_out = 'Check Out wajib diisi';
            isValid = false;
        } else if (inputState.check_in && moment(inputState.check_out).isBefore(moment(inputState.check_in))) {
            newErrorState.check_out = 'Check Out tidak boleh lebih kecil dari Check In';
            isValid = false;
        }

        const newRatesError = [];
        for (let i in inputState.rates) {
            if (!inputState.rates[i] || +inputState.rates[i] === 0) {
                newRatesError.push('Rates wajib diisi');
                isValid = false;
            } else {
                newRatesError.push('');
            }
        }
        newErrorState.rates = newRatesError;

        setErrorState({ name: '', value: newErrorState, type: 'REPLACE_STATE' });
        return isValid;
    }

    const mapInputState = (state: IState) => {
        const data: any = { ...state };

        data.check_in = dateFormat(state.check_in || '', 'YYYY-MM-DD');
        data.check_out = dateFormat(state.check_out || '', 'YYYY-MM-DD');

        return data;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;
        const fd = new FormData();

        const data = mapInputState(inputState);

        for (let [key, value] of Object.entries(data)) {
            if (value) {
                if (key === 'upload_file') {
                    fd.append(key, value as File);
                } else if (key === 'rates') {
                    for (let rate of value as string[]) {
                        fd.append('rates[]', rate);
                    }
                } else {
                    fd.append(key, String(value));
                }
            }
        }

        if (booking_id !== 0) {
            axios = DefaultAxios.post(API_URL + '/' + booking_id, fd);
            fd.append('_method', 'patch');
        } else {
            axios = DefaultAxios.post(API_URL, fd);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: res.data.message,
                    icon: 'success',
                    onAfterClose: () => {
                        //
                    },
                    timer: 1000
                }).then(() => {
                    onClose()
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Dialog open={open} onClose={() => onClose()} fullWidth={true} maxWidth="xl">
            <Root>
                <DialogContent>
                    <Grid container spacing={3} className={classes.formContainer}>
                        <LoadingScreen open={isLoading} fullScreen />
                        <Grid item xs={12}>
                            <h1 className={classes.borderBottomLine}>Stay360 Booking</h1>
                        </Grid>

                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                label="Nomor Unit"
                                name="unit_id"
                                initialQuery={inputState.unit_label}
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/stay360-unit`}
                                errorText={errorState.unit_id}
                                disabled={booking_id !== 0}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Nama"
                                name="name"
                                value={inputState.name}
                                onChange={handleChange}
                                error={!!errorState.name}
                                helperText={errorState.name}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="No HP"
                                name="phone"
                                placeholder="62818xxxx"
                                value={inputState.phone}
                                onChange={handleChange}
                                error={!!errorState.phone}
                                helperText={errorState.phone}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <AsyncAutoComplete
                                label="OTA"
                                name="ota"
                                initialQuery={inputState.ota}
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/stay360-ota`}
                                errorText={errorState.ota}
                            />
                        </Grid>

                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Booking ID"
                                name="booking_id"
                                value={inputState.booking_id}
                                onChange={handleChange}
                                error={!!errorState.booking_id}
                                helperText={errorState.booking_id}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <MuiDatePicker
                                label="Check In"
                                value={inputState.check_in}
                                onChange={(date: any) => handleDateChanged('check_in', date)}
                                error={!!errorState.check_in}
                                helperText={errorState.check_in}
                                maxDate={inputState.check_out ? moment(inputState.check_out).subtract(1, 'day').toDate() : null}
                                disabled={booking_id !== 0}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <MuiDatePicker
                                label="Check Out"
                                value={inputState.check_out}
                                onChange={(date: any) => handleDateChanged('check_out', date)}
                                error={!!errorState.check_out}
                                helperText={errorState.check_out}
                                minDate={inputState.check_in ? moment(inputState.check_in).add(1, 'day').toDate() : null}
                                disabled={booking_id !== 0}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.switchContainer}>
                            Bayar Ditempat
                            <Switch
                                color="primary"
                                name="is_pay_on_spot"
                                value={true}
                                checked={inputState.is_pay_on_spot}
                                onChange={(e) => handleChange(e)}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.switchContainer}>
                            Rate Sama Semua Hari
                            <Switch
                                color="primary"
                                name="is_same_rate"
                                value={true}
                                checked={inputState.is_same_rate}
                                onChange={(e) => handleChange(e)}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </Grid>

                        {inputState.rates.map((rate, key) =>
                            <Grid item xs={12} key={key}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="rates"
                                    value={rate ? numberToCurrency(rate) : ''}
                                    onChange={(e: any) => handleChange(e, key)}
                                    error={!!errorState.rates[key]}
                                    helperText={errorState.rates[key]}
                                    placeholder={`500,000`}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <h3>Upload KTP</h3>
                            <br />
                            {
                                inputState.ktp_url
                                    ? <>
                                        <img src={inputState.ktp_url} width="100" alt={inputState.name} />
                                        <br />
                                    </>
                                    : null
                            }
                            <input type="file" name="upload_file" onChange={handleChange} style={{ marginBottom: '10px' }} />
                        </Grid>

                        <Grid item xs={12}>
                            <TextArea
                                label="Notes"
                                name="notes"
                                value={inputState.notes || ''}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Root>
        </Dialog >
    );
}

export default BookingForm;
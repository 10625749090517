import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import TextArea from '../../_components/_form/TextArea';
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

const MapFileForm = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/apartment-map-file';

    const [state, setState] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        DefaultAxios.get(API_URL)
            .then(res => {
                setState(res.data);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const handleSubmit = () => {
        if (!state) {
            Swal.fire({
                title: "Map file harus diisi",
                icon: 'warning'
            });

            return;
        }

        setIsLoading(true);
        DefaultAxios.post(API_URL, { text: JSON.stringify(state) })
            .then(res => {
                Swal.fire({
                    title: "Map file Submitted",
                    icon: 'success',
                    timer: 1000
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextArea 
                                label="Map File Text"
                                name="state"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                rowsMax={30}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <pre>
                        {state}
                    </pre>
                </Grid>
            </Grid>
        </>
    )
}

export default MapFileForm;
import React, { useState, useReducer, useEffect } from 'react';
import { Container, Box, Paper, Dialog, AppBar, Slide, Toolbar, Typography, IconButton, Theme, Grid, Button } from '@mui/material';
import Swal from 'sweetalert2';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Components
 */
import LoadingScreen from '../../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    open: boolean;
    handleSubmit: Function
    handleCloseModal: Function
}

interface IState {
    advisor_id: string;
    advisor_label: string;
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    container: {
        backgroundColor: "#f3f3f3",
    },
    submit_button: {
        marginLeft: '8px'
    },
    headLabel: {
        fontWeight: "bold",
        padding: theme.spacing(0, 1),
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
        margin: theme.spacing(3, 1),
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
    paper_label: {
        position: "absolute",
        marginTop: "-45px",
        padding: "1px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)"
    },
    paper_label_text: {
        backgroundColor: "#0094ff",
        fontSize: "1.5em",
        padding: "13px",
        borderRadius: "4px",
        color: "white"
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'right',
        marginBottom: '10px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }

}), "TransferModal"
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const TransferModal = ({ open, handleSubmit, handleCloseModal }: Props) => {
    const { Root, classes } = useStyles();
    const [loading, setLoading] = useState(false);
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                id: "",
                advisor_id: "",
                advisor_label: "",
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    useEffect(() => {
        setInputState({ name: 'reset', value: null, type: 'RESET_ITEM' });
    }, [open])

    const [inputState, setInputState] = useReducer(inputReducer, {
        advisor_id: "",
        advisor_label: ""
    });

    const handleAutocompleteAdvisor = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.advisor_id = value.id.toString();
        newState.advisor_label = value.label;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'RESET_ITEM' });
    }

    const submitForm = () => {
        if (!inputState.advisor_id) {
            Swal.fire({
                title: "Error",
                html: "Harap pilih Advisornya dari Dropdown",
                icon: 'error',
            })
            return false;
        }

        setLoading(true)
        handleSubmit(inputState.advisor_id)
    }

    return (
        <Dialog open={open} onClose={() => handleCloseModal(false)} fullWidth maxWidth="md" closeAfterTransition TransitionComponent={Transition}>
            <Root>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => handleCloseModal(false)} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h6" className={classes.title}>Transfer Kunci</Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <LoadingScreen open={loading} fullScreen />
                <Container maxWidth={false} className={classes.container}>
                    <Box>
                        <Paper className={classes.paper}>
                            <div className={classes.root}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <AsyncAutoComplete
                                            label="Advisor"
                                            name="advisor_label"
                                            onChange={handleAutocompleteAdvisor}
                                            onInputChange={handleAutocompleteInputChanged}
                                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user/active-staff`}
                                            placeholder="Kosongkan bila ingin un-assign advisor pada unit ini"
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridSubmit}>
                                        <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>

                        </Paper>
                    </Box>
                </Container>
            </Root>
        </Dialog>
    );
}

export default TransferModal;
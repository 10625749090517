import React, { useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { Grid, Theme, ButtonGroup, Button } from '@mui/material';

// Components
import ProjectDeveloperDetailForm from './ProjectDeveloperDetailForm';
import ProjectDeveloperImageForm from './ProjectDeveloperImageForm';
import ProjectDeveloper360PhotoForm from './ProjectDeveloper360PhotoForm';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) =>
({
    borderBottomLine: {
        borderBottom: '1px solid #eee'
    }
}), "Project_DevForm"
);

const ProjectDeveloperForm = () => {
    const { Root, classes } = useStyles();
    const location = useLocation();
    const params: any = useParams();
    const navigate = useNavigate();

    const [type, setType] = useState(typeof params.type !== 'undefined' ? params.type : 'details');

    const renderForm = () => {
        if (params.id) {
            if (type === 'details') {
                return <ProjectDeveloperDetailForm
                    projectDeveloperId={params.id}
                    mode="edit"
                />
            } else if (type === 'image') {
                return <ProjectDeveloperImageForm
                    projectDeveloperId={params.id}
                />
            } else if (type === '360-photo') {
                return <ProjectDeveloper360PhotoForm
                    projectDeveloperId={params.id}
                />
            }
        } else {
            return <ProjectDeveloperDetailForm
                projectDeveloperId={null}
                mode="add"
            />
        }
    }

    const handleNavigation = (route: string) => {
        if (route !== type) {
            if (route === 'details') {
                navigate(`/project-developer/edit/${params.id}`);
            } else {
                navigate(`/project-developer/edit/${params.id}/${route}`);
            }
            setType(route);
        }
    }

    return (
        <Root>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h1 className={classes.borderBottomLine}>Project Developer - {location.pathname === '/project-developer/add' ? 'Add' : 'EDIT'}</h1>
                </Grid>

                {
                    location.pathname !== '/project-developer/add'
                        ? <Grid item xs={12}>
                            <ButtonGroup
                                color="primary"
                                aria-label="outlined primary button group"
                                fullWidth
                            >
                                <Button
                                    variant={type === 'details' ? 'contained' : 'outlined'}
                                    onClick={() => handleNavigation('details')}
                                >
                                    Details
                                </Button>
                                <Button
                                    variant={type === 'image' ? 'contained' : 'outlined'}
                                    onClick={() => handleNavigation('image')}
                                >
                                    Images
                                </Button>
                                <Button
                                    variant={type === '360-photo' ? 'contained' : 'outlined'}
                                    onClick={() => handleNavigation('360-photo')}
                                >
                                    360 Photo
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        : null
                }

                <Grid item xs={12}>
                    {renderForm()}
                </Grid>
            </Grid>
        </Root>
    );
}

export default ProjectDeveloperForm;
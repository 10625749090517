import React, { useState, useEffect } from "react";
import {
    Grid,
    Card,
    CardContent,
    Button,
    BottomNavigation,
    BottomNavigationAction,
    Typography,
    Checkbox,
    Slide,
    Theme
} from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Icon
 */
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';

/**
 * Components
 */
import TransferModal from "./TransferModal";
import MainBottomNavigation from "./MainBottomNavigation";

/**
 * Interface
 */
import { IUnit } from '../UnitKeyManageList';
import { generalErrorHandler, renderWarningButton } from "../../../_utils/Helper";
import { generateStyle } from "../../../_utils/DefaultStyle";

interface IProps {
    changeMenu: Function
}

const useStyles = generateStyle((theme: Theme) =>
({
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    card_action: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    cardContainer: {
        "& .MuiPaper-root": {
            marginBottom: theme.spacing(2)
        },
        paddingBottom: theme.spacing(6)
    }
}), "ActiveKeylist"
);

const ActiveKeyList = ({ changeMenu }: IProps) => {
    const { Root, classes } = useStyles();
    const [loading, setLoading] = useState(false);
    const [units, setUnits] = useState<Array<IUnit>>([]);
    const [bottomAction, setBottomAction] = useState(0);
    const [showTransferModal, setShowTransferModal] = useState(false);

    useEffect(() => {
        getActiveKeyData();
    }, []);

    const getActiveKeyData = () => {
        setBottomAction(0);
        setLoading(true);
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/unit-key-manage`)
            .then(res => {
                const newDatas: Array<IUnit> = res.data;
                newDatas.map(data => {
                    data.is_checked = false;
                    return true;
                })

                setLoading(false);
                setUnits([...newDatas]);
            })
    }

    const handleCheckBox = (id: string) => {
        const newUnits = units;
        let bottomAction = 0;

        for (let index = 0; index < newUnits.length; index++) {
            if (id === newUnits[index].id) {
                newUnits[index].is_checked = !newUnits[index].is_checked;
            }

            if (newUnits[index].is_checked === true) {
                bottomAction = 1;
            }
        }
        setUnits([...newUnits]);
        setBottomAction(bottomAction);
    }

    const submitReturnRequest = () => {
        renderWarningButton('Apakah anda yakin ingin mengembalikan kunci?')
            .then((result) => {
                if (result.value) {
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-key-manage/request-return`, { ids: getCheckedUnit() })
                        .then(res => {
                            Swal.fire({
                                title: "Request berhasil dikirim",
                                icon: 'success',
                                timer: 1000
                            })
                            getActiveKeyData();
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                }
            })
    }

    const submitTransferRequest = (advisor_id: string) => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-key-manage/request-transfer`, {
            transfer_to: advisor_id,
            ids: getCheckedUnit(),
        })
            .then(res => {
                Swal.fire({
                    title: "Request Disubmit",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        setShowTransferModal(false);
                        getActiveKeyData();
                    })
            })
            .catch(error => {
                generalErrorHandler(error);
            });
    }

    const getCheckedUnit = () => {
        const ids = [];
        for (let index = 0; index < units.length; index++) {
            if (units[index].is_checked) {
                ids.push(units[index].id);
            }
        }
        return ids;
    }

    const UnitCard = ({ id, code, apartment_name, detail, is_checked }: { id: string, code: string, apartment_name: string, detail: string, is_checked: boolean }) => {
        return (
            <Card className={classes.card} key={id}>
                <CardContent>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid>
                            <Typography variant="h5">
                                {code}
                            </Typography>
                            <Typography variant="subtitle1">
                                {apartment_name}
                            </Typography>
                            <Typography variant="subtitle2">
                                {detail}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Checkbox onClick={() => { handleCheckBox(id) }} checked={is_checked} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }

    return (
        <Root>

            <TransferModal
                open={showTransferModal}
                handleSubmit={submitTransferRequest}
                handleCloseModal={() => setShowTransferModal(false)}
            />

            {!loading &&
                <Grid className={classes.cardContainer}>
                    {units.length > 0 ?
                        <>
                            {units.map((data: IUnit) => {
                                return (
                                    <UnitCard
                                        id={data.id}
                                        code={data.code}
                                        apartment_name={data.apartment_name}
                                        detail={data.detail}
                                        is_checked={data.is_checked}
                                        key={data.id}
                                    />
                                )
                            })}
                        </>
                        :
                        <Grid container justifyContent="center" direction="column" alignItems="center" >
                            <img src="https://jendela360.com/assets/images/svg/key-warning.svg" alt="no-key-waring" />
                            <Typography variant="subtitle2" style={{ marginTop: "10px" }}>
                                Tidak ada kunci yang dipegang
                            </Typography>

                            <Button variant="contained" color="primary" style={{ marginTop: "10px" }}>Pinjam Kunci</Button>
                        </Grid>
                    }
                </Grid>
            }

            <MainBottomNavigation menuValue={bottomAction} changeMenu={changeMenu} />

            <Slide direction="up" in={bottomAction === 1} mountOnEnter unmountOnExit>
                <BottomNavigation
                    showLabels
                    style={{
                        width: '100%',
                        position: 'fixed',
                        bottom: 0,
                    }}
                >
                    <BottomNavigationAction label="Return" icon={<AssignmentReturnedIcon />} onClick={submitReturnRequest} />
                    <BottomNavigationAction label="Transfer" icon={<SyncAltIcon />} onClick={() => {
                        setShowTransferModal(true);
                    }} />
                </BottomNavigation>
            </Slide>
        </Root>
    );
}

export default ActiveKeyList;
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface Props {
    name: string;
    value: string;
    onChange: Function;
}

const TextEditor = (props: Props) => {
    return (
        <Editor
            tinymceScriptSrc='https://d2jgpj59n9tcev.cloudfront.net/tinymce/tinymce.min.js'
            licenseKey='gpl'
            value={props.value}
            init={{
                height: 500,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help code wordcount'
                ],
                toolbar:
                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat code link | help',
                invalid_elements:
                    'script'
            }}
            onEditorChange={(value) => props.onChange(props.name, value)}
        />
    );
}

export default TextEditor;
import { Grid, TextField, MenuItem, Button } from '@mui/material'
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'

/* 
* Components
*/
import TextArea from '../../../_components/_form/TextArea'
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'

/* 
* Utils
*/
import DefaultAxios from '../../../_utils/DefaultAxios'
import { format } from 'date-fns'

/* 
* Interfaces
*/
import { IDefaultState, IDetail, KCPOption } from '../JhsOrderForm'
import { generalErrorHandler, queryParamsToParams, renderQuestionButton, renderSuccessButton } from '../../../_utils/Helper'
import { useNavigate } from 'react-router-dom'
import LoadingScreen from '../../../_components/LoadingScreen'

interface IProps {
    setFormChanged: Dispatch<SetStateAction<boolean>>
    isEdit: boolean
    editData: any
}
interface IState extends IDefaultState {
    closing_id: string,
    package_duration: string
}

const initialState = {
    closing_id: '',
    package_duration: '',
    unit_id: '',
    key_collection_point_option: '' as KCPOption,
    key_collection_point: '',
    notes: '',
    activity: 'essential_pack',
    request_date: null,
    request_time: null
}

const initialDetail = {
    start_date: "",
    end_date: "",
    unit_code: "",
    apartment_id: "",
    apartment_name: "",
    bedroom: null,
    tower: "",
    tenant_name: "",
    floor_level: "",
    unit_number: "",
    owner_name: "",
    customer_name: ""
}

const PerawatanEssensialForm = (props: IProps) => {
    const navigate = useNavigate()
    const [state, setState] = useState<IState>(initialState)
    const [detail, setDetail] = useState<IDetail>(initialDetail)
    const [loading, setLoading] = useState(false)
    const [queryParams, setQueryParams] = useState<any>({})


    const handleChange = (e: any) => {
        props.setFormChanged(true)
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const fetchData = (id: string) => {
        setLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/activity/closing`, { params: { id } })
            .then(res => {
                setDetail(res.data)
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => setLoading(false))
    }

    const handleChangeClosing = (e: any, value: any) => {
        setState(prev => {
            fetchData(value.id)
            return {
                ...prev,
                closing_id: value.id
            }
        })
    }

    const gotoPrevious = () => {
        if (queryParams && queryParams.ticket_id) {
            return navigate(`/ticket/list/${queryParams.ticket_id}`)
        }

        const queryString = localStorage.getItem('queryParams')
        navigate(queryString ? `/jhs-order?${queryString}` : '/jhs-order')
    }

    const handleSubmit = () => {
        renderQuestionButton('Pastikan data yang anda masukkan Benar.', 'Apakah anda yakin ingin submit?')
            .then(res => {
                if (res.value) {
                    DefaultAxios[props.isEdit ? 'patch' : 'post'](`${process.env.REACT_APP_API_URL}/activity${props.isEdit ? `/${props.editData.id}/jhs` : ''}`, {
                        ...state,
                        request_date: format(new Date(detail.start_date), 'yyyy-MM-dd'),
                        ...(
                            queryParams ?
                                queryParams :
                                {}
                        ),
                    })
                        .then(res => {
                            if (res) {
                                renderSuccessButton('Submit Success!', () => {
                                    if (props.isEdit) {
                                        return navigate(`/jhs-order/${props.editData.id ? props.editData.id : ''}`)
                                    }

                                    gotoPrevious()
                                })
                            }
                        })
                        .catch(err => generalErrorHandler(err))
                }
                if (res.dismiss) {
                    return null
                }
            })
            .catch(err => generalErrorHandler(err))
    }

    const assignExistData = (prev: any, current: any) => {
        let result: any = {}
        for (let key in prev) {
            if (key === 'bedroom') {
                result[key] = current[key] ?? null
            } else {
                result[key] = !!current[key] ? current[key] : null
            }
        }
        result['activity'] = 'essential_pack'
        return result
    }

    useEffect(() => {
        setQueryParams(queryParamsToParams())

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (props.isEdit) {
            setState(prev => assignExistData(prev, props.editData))
            setDetail(prev => assignExistData(prev, props.editData))
        }
    }, [props.editData, props.isEdit])

    return (
        <>
            {
                loading &&
                <LoadingScreen open={loading} fullScreen />
            }
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        url={`${process.env.REACT_APP_API_URL}/admin/autocomplete/closing`}
                        label="Closing Code"
                        name="closing_code"
                        initialQuery={props.isEdit ? props.editData.closing_code || '' : ''}
                        onChange={handleChangeClosing}
                        clearable
                        onClear={() => [setState(prev => ({ ...prev, closing_id: '' })), setDetail(initialDetail)]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Unit Code"
                        value={detail.unit_code}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Apartment Name"
                        value={detail.apartment_name}
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Unit Detail"
                        value={`Tw. ${detail.tower || ''} / ${detail.floor_level} / ${detail.unit_number}`}
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Unit Type"
                        fullWidth
                        disabled
                        value={detail.bedroom === null ? '' : detail.bedroom ? `${detail.bedroom}BR` : 'Studio'}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Tenant Name"
                        fullWidth
                        disabled
                        value={detail.tenant_name || detail.customer_name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Check-in Date"
                        fullWidth
                        disabled
                        value={detail.start_date}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        label="Select Package"
                        fullWidth
                        name="package_duration"
                        value={state.package_duration}
                        onChange={handleChange}
                    >
                        <MenuItem value="" disabled>-- Select Duration --</MenuItem>
                        <MenuItem value="6">6 Bulan</MenuItem>
                        <MenuItem value="12">1 Tahun</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextArea
                        label="Notes"
                        name='notes'
                        value={state.notes}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {props.isEdit ? 'Update Order' : 'Submit'}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default PerawatanEssensialForm
import React from 'react';
import { TextField, styled, FormControl, InputLabel, TextFieldProps } from '@mui/material';

const FCND = styled(FormControl)(({ hiddenLabel, theme }) => ({
    marginTop: hiddenLabel ? 0 : 24,
    '& .MuiFormLabel-root': {
        position: 'absolute',
        left: -12,
        top: -16,
        fontWeight: 600,
        fontSize: 18,
    }
}));

interface INewDesign {
    isGrey?: boolean;
}

const TFND3 = styled((props: TextFieldProps & INewDesign) => <TextField {...props} />)(({ InputProps, isGrey, disabled, theme }) => ({
    '& .MuiInputBase-root': {
        paddingLeft: InputProps?.startAdornment ? theme.spacing(8) : theme.spacing(1),
        paddingRight: InputProps?.endAdornment ? theme.spacing(8) : theme.spacing(1),
        position: 'relative',
        overflow: 'hidden',
        "& > svg": {
            color: disabled || isGrey ? theme.palette.grey[600] : '#fff',
        }
    },
    '& .MuiInputAdornment-positionStart': {
        position: 'absolute',
        left: 0,
        height: '100%',
        minWidth: theme.spacing(7),
        backgroundColor: disabled || isGrey ? theme.palette.grey[200] : theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 300ms ease-in',
        '& .MuiIconButton-root': {
            margin: 0,
            color: disabled || isGrey ? theme.palette.grey[600] : '#fff',
            transition: 'all 300ms ease-in',
        },
    },
    '& .MuiInputAdornment-positionEnd': {
        position: 'absolute',
        right: 0,
        height: '100%',
        minWidth: theme.spacing(7),
        backgroundColor: disabled || isGrey ? theme.palette.grey[100] : theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 300ms ease-in',
        '& .MuiIconButton-root': {
            margin: 0,
            color: disabled || isGrey ? theme.palette.grey[600] : '#fff',
            transition: 'all 300ms ease-in',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#fff',
        maxHeight: '100%',
        '& .MuiTypography-root': {
            color: disabled || isGrey ? theme.palette.grey[600] : '#fff',
        }
    },
    '& .MuiOutlinedInput-root': {
        background: disabled ? theme.palette.grey[200] : isGrey ? '#fff' : "#DEF4FD",
        '& > p': {
            color: isGrey ? '#fff' : '#DEF4FD',
        },
        '& fieldset': {
            borderColor: isGrey ? '#e0e0e0' : '#00a2ff',
        },
        '&:hover fieldset': {
            borderColor: disabled || isGrey ? '#e0e0e0' : '#00a2ff',
        },
        '&.Mui-focused fieldset': {
            borderWidth: 2,
            borderColor: disabled || isGrey ? '#e0e0e0' : '#00a2ff',
        },
    },
}));

const TextFieldND3 = (props: TextFieldProps & INewDesign) => {
    return (
        <FCND variant="outlined" fullWidth hiddenLabel={!props.label}>
            <InputLabel shrink htmlFor={props.name}>{props.label}</InputLabel>
            <TFND3
                {...props}
                label=""
                isGrey={!props.value}
                id={props.name}
                variant="outlined"
            />
        </FCND>
    );
};

export default TextFieldND3;

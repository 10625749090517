import React, { useState, useReducer, useEffect } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, convertIndonesiaPhoneNumber } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

export interface IState {
    old_password: string;
    password: string;
    password_confirmation: string;
}

export interface IProfileState {
    name: string;
    phone_number: string;
}

export interface IAction {
    name: string,
    value: any,
    type: string
}

export const useStyles = generateStyle(theme => ({
    borderBottomLine: {
        borderBottom: '1px solid #eee'
    }
}), "ProfileSetting")

export const API_URL = `${process.env.REACT_APP_API_URL}/profile/change-password`;
export const API_URL_Profile = `${process.env.REACT_APP_API_URL}/profile`;

const ProfileSetting = () => {

    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(false);

    const inputReducerProfile = (state: IProfileState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                phone_number: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IProfileState };
        }

        return { ...state };
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                old_password: '',
                password: '',
                password_confirmation: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputStateProfile, setInputStateProfile] = useReducer(inputReducerProfile, {
        name: '',
        phone_number: '',
    });

    const [inputState, setInputState] = useReducer(inputReducer, {
        old_password: '',
        password: '',
        password_confirmation: '',
    });

    const handleInputChangedProfile = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            if (target.name === 'phone_number') {
                setInputStateProfile({ name: target.name, value: convertIndonesiaPhoneNumber(value), type: 'SET_ITEM' });
            } else {
                setInputStateProfile({ name: target.name, value: value, type: 'SET_ITEM' });
            }
        }
    }

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
        }
    }

    const submitFormProfile = () => {
        let axios;
        axios = DefaultAxios.put(`${API_URL_Profile}`, inputStateProfile);

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Profile Updated",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        window.location.reload();
                    });
            })
            .finally(() => {
                setIsLoading(false);
            })
            .catch(error => {
                generalErrorHandler(error);
                setIsLoading(false);
            });
    }

    const submitForm = () => {
        let axios;
        axios = DefaultAxios.post(`${API_URL}`, inputState);

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Password Changed",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        window.location.reload();
                    });
            })
            .finally(() => {
                setIsLoading(false);
            })
            .catch(error => {
                generalErrorHandler(error);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        setIsLoading(true);
        DefaultAxios
            .get(`${API_URL_Profile}`)
            .then(res => {
                const data = res.data;
                setInputStateProfile({ name: '', value: data, type: 'REPLACE_STATE' });
            })
            .finally(() => {
                setIsLoading(false);
            })
            .catch(error => {
                generalErrorHandler(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Root>
            <Grid container spacing={3}>
                <LoadingScreen open={isLoading} fullScreen />

                <Grid item xs={12}>
                    <h1 className={classes.borderBottomLine}>Basic Setting</h1>
                </Grid>

                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Name"
                                type="text"
                                name="name"
                                disabled
                                value={`${inputStateProfile.name}`}
                                onChange={handleInputChangedProfile}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Phone Number"
                                type="text"
                                name="phone_number"
                                value={inputStateProfile.phone_number}
                                onChange={handleInputChangedProfile}
                                placeholder="628xxxxxxxxxx"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={submitFormProfile}
                            >
                                Submit
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 8 }}>
                    <h1 className={classes.borderBottomLine}>Password Setting</h1>
                </Grid>

                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Old Password"
                                type="password"
                                name="old_password"
                                value={inputState.old_password}
                                onChange={handleInputChanged}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Password"
                                type="password"
                                name="password"
                                value={inputState.password}
                                onChange={handleInputChanged}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Confirm Password"
                                type="password"
                                name="password_confirmation"
                                value={inputState.password_confirmation}
                                onChange={handleInputChanged}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={submitForm}
                            >
                                Submit
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Root>
    );
}

export default ProfileSetting;
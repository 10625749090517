import React, { useState } from 'react';
import { Grid, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Button, Link } from '@mui/material';

// Components
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import { dateFormat, generalErrorHandler, numberToCurrency } from '../../_utils/Helper';
import DefaultAxios from '../../_utils/DefaultAxios';
import BookingDetail from '../_calendarBooking/_components/BookingDetail';
import DateRangePicker, { DateRange } from '../../_components/DateRangePicker';
import { format } from 'date-fns';

const formatDate = (date: DateRange) => {
    if (!date) return ''

    return format(date, 'dd/MM/yyyy')
}

const Stay360BookingReport = () => {
    const initialDateFilter = {
        check_in_start: null,
        check_in_end: null
    };

    const [isLoading, setIsLoading] = useState(false);
    const [dateFilter, setDateFilter] = useState<{ check_in_start: DateRange, check_in_end: DateRange }>(initialDateFilter);
    const [rows, setRows] = useState<any[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [bookingID, setBookingID] = useState(0);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/stay360-booking/report`, { params: { check_in_start: formatDate(dateFilter.check_in_start), check_in_end: formatDate(dateFilter.check_in_end) } })
            .then(res => {
                setRows(res.data);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleDateChanged = (dates: { startDate: DateRange, endDate: DateRange }) => {
        let newFilter = {
            check_in_start: dates.startDate,
            check_in_end: dates.endDate
        }
        setDateFilter(newFilter);
    }

    const handleExport = () => {
        setIsLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/stay360-booking/export`, { check_in_start: formatDate(dateFilter.check_in_start), check_in_end: formatDate(dateFilter.check_in_end) })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `REPORT STAY360 ${dateFormat(dateFilter.check_in_start || '', 'DD MMM yyyy')} - ${dateFormat(dateFilter.check_in_end || '', 'DD MMM yyyy')}.csv`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const openDetail = (bookingID: any) => {
        setBookingID(bookingID);
        handleModalOpen();
    }

    return (
        <Grid container spacing={3}>
            <BookingDetail booking_id={bookingID} open={modalOpen} onClose={handleModalClose} />
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={8}>
                <h1 style={{ display: 'inline-block' }}>Booking Report</h1>
                <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: '25px' }}
                    onClick={handleExport}
                >
                    Export
                </Button>
            </Grid>
            <Grid item xs={4}>
                <Typography>Filter</Typography>
                <DateRangePicker
                    startDate={dateFilter.check_in_start}
                    endDate={dateFilter.check_in_end}
                    onDatesChange={handleDateChanged}
                    onFinished={loadData}
                    fullWidth
                />
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nama Tamu</TableCell>
                                <TableCell>Lokasi</TableCell>
                                <TableCell>OTA</TableCell>
                                <TableCell>Check In</TableCell>
                                <TableCell>Check Out</TableCell>
                                <TableCell align="center">Room Night</TableCell>
                                <TableCell>Rate</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, key) => (
                                <TableRow key={key}>
                                    <TableCell>
                                        {
                                            row.notes
                                                ? <Tooltip title={row.notes} arrow>
                                                    <span>
                                                        {row.name}
                                                        <br />
                                                        <Link style={{ cursor: 'pointer' }} href={`https://wa.me/${row.phone}`}>{row.phone}</Link>
                                                    </span>
                                                </Tooltip>
                                                : <span>
                                                    {row.name}
                                                    <br />
                                                    <Link style={{ cursor: 'pointer' }} href={`https://wa.me/${row.phone}`}>{row.phone}</Link>
                                                </span>
                                        }
                                    </TableCell>
                                    <TableCell>{row.location} {row.unit_label}</TableCell>
                                    <TableCell>
                                        {row.ota}
                                        <br />
                                        <Link 
                                            onClick={() => openDetail(row.id)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {row.booking_id}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{dateFormat(row.check_in, 'DD MMM yyyy')}</TableCell>
                                    <TableCell>{dateFormat(row.check_out, 'DD MMM yyyy')}</TableCell>
                                    <TableCell align="center">{row.room_night}</TableCell>
                                    <TableCell>{numberToCurrency(row.first_rate)}</TableCell>
                                    <TableCell>{numberToCurrency(row.total_rate)}</TableCell>
                                </TableRow>
                            ))}
                            {
                                rows.length 
                                ? <TableRow>
                                    <TableCell colSpan={6}></TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>{numberToCurrency(rows.reduce((a, b) => (parseInt(a) + parseInt(b.total_rate)), 0))}</TableCell>
                                </TableRow>
                                : null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}

export default Stay360BookingReport;
import React, { useState } from 'react'

/**
 * Components
 */
import { Button, IconButton, Modal, Paper, TextField, Theme, useTheme } from '@mui/material';

/**
 * Utils
 */
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, PickersDay, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers';
import styled from '@emotion/styled';
import { endOfDay, endOfMonth, endOfWeek, format, isSameDay, isWithinInterval, setDefaultOptions, startOfDay, startOfMonth, startOfWeek, subDays, subMonths, subWeeks } from 'date-fns';

/**
 * Icons
 */
import { Close } from '@mui/icons-material';
import { generateStyle } from '../_utils/DefaultStyle';

export type DateRange = Date | null
export type OnDatesChange = { startDate: DateRange, endDate: DateRange }

interface DateRangePickerProps {
    startDate: DateRange
    endDate: DateRange
    format?: string
    onDatesChange: ({ startDate, endDate }: OnDatesChange) => void
    onFinished: () => void
    dateMaxWidth?: number
    fullWidth?: boolean
}

setDefaultOptions({
    weekStartsOn: 1,
})

const DateRangePicker = (props: DateRangePickerProps) => {
    const { Root: ModalRoot, classes: modalClasses } = useStyles()
    const { Root, classes } = useInputStyle()
    const theme = useTheme<Theme>()

    const [isOpen, setIsOpen] = useState(false)
    const [displayedCalendar, setDisplayedCalendar] = useState<'start' | 'end'>('start')

    const handleOpen = (type: 'start' | 'end') => {
        setDisplayedCalendar(type)
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
        props.onFinished()
    }

    const renderWeekPickerDay = (
        date: Date,
        selectedDates: Array<Date | null>,
        pickersDayProps: PickersDayProps<Date>,
    ) => {
        if (!props.startDate) {
            return <PickersDay {...pickersDayProps} />;
        }

        const start = startOfDay(props.startDate);
        const end = props.endDate ? endOfDay(props.endDate) : start;

        const dayIsBetween = isWithinInterval(date, { start, end });
        const isFirstDay = isSameDay(date, start);
        const isLastDay = isSameDay(date, end);

        return (
            <CustomPickersDay
                {...pickersDayProps}
                disableMargin
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
            />
        );
    };

    return (
        <Root>
            <div style={{ display: 'inline-block', width: props.fullWidth ? '100%' : undefined }}>
                <div
                    className={classes.inputContainer}
                    style={{
                        borderColor: isOpen ? theme.palette.primary.main : '',
                    }}
                >
                    <span
                        className={`${classes.input} ${props.startDate ? 'filled' : ''}`}
                        onClick={() => handleOpen('start')}
                        style={{
                            color: isOpen && displayedCalendar === 'start' ? theme.palette.primary.main : undefined,
                            fontWeight: isOpen && displayedCalendar === 'start' ? '500' : '400',
                            maxWidth: props.dateMaxWidth,
                        }}
                    >
                        {props.startDate ? format(props.startDate, props.format || 'dd/MM/yyyy') : 'Start'}
                    </span>
                    <span>-</span>
                    <span
                        className={`${classes.input} ${props.endDate ? 'filled' : ''}`}
                        onClick={() => handleOpen('end')}
                        style={{
                            color: isOpen && displayedCalendar === 'end' ? theme.palette.primary.main : undefined,
                            fontWeight: isOpen && displayedCalendar === 'end' ? '500' : '400',
                            maxWidth: props.dateMaxWidth,
                        }}
                    >
                        {props.endDate ? format(props.endDate, props.format || 'dd/MM/yyyy') : 'End'}
                    </span>
                    {
                        (props.startDate || props.endDate) &&
                        <IconButton
                            color="default"
                            onClick={() => {
                                props.onDatesChange({ startDate: null, endDate: null })
                                handleOpen('start')
                            }}
                            size='small'
                        >
                            <Close />
                        </IconButton>
                    }
                </div>
            </div>
            <Modal
                open={isOpen}
                onClose={handleClose}
            >
                <ModalRoot>
                    <Paper className={modalClasses.paper}>
                        <div className={modalClasses.calendarContainer}>
                            <div className={modalClasses.buttonsContainer}>
                                <Button color={displayedCalendar === 'start' ? undefined : 'inherit'} onClick={() => setDisplayedCalendar('start')}>
                                    Start Date
                                </Button>
                                <span>-</span>
                                <Button color={displayedCalendar === 'end' ? undefined : 'inherit'} onClick={() => setDisplayedCalendar('end')}>
                                    End Date
                                </Button>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                {
                                    displayedCalendar === 'start' ?
                                        <StaticDatePicker
                                            maxDate={props.endDate || undefined}
                                            displayStaticWrapperAs="desktop"
                                            value={props.startDate}
                                            inputFormat={props.format ? props.format : "dd/MM/yyyy"}
                                            onChange={newValue => {
                                                let endDate = props.endDate

                                                if (newValue && endDate) {
                                                    if (newValue.getTime() > endDate.getTime()) {
                                                        endDate = newValue
                                                    }
                                                }

                                                props.onDatesChange({ startDate: newValue, endDate })
                                                setDisplayedCalendar('end')
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            renderDay={renderWeekPickerDay}
                                        />
                                        :
                                        <StaticDatePicker
                                            minDate={props.startDate || undefined}
                                            displayStaticWrapperAs="desktop"
                                            value={props.endDate}
                                            inputFormat={props.format ? props.format : "dd/MM/yyyy"}
                                            onChange={newValue => {
                                                let startDate = props.startDate

                                                if (newValue && startDate) {
                                                    if (startDate.getTime() > newValue.getTime()) {
                                                        startDate = newValue
                                                    }
                                                }

                                                props.onDatesChange({ startDate, endDate: newValue })
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            renderDay={renderWeekPickerDay}
                                        />
                                }
                            </LocalizationProvider>
                            <div className={modalClasses.footer}>
                                <Button variant='contained' onClick={handleClose}>
                                    Done
                                </Button>
                            </div>
                        </div>
                        <div className={modalClasses.presetContainer}>
                            <p className={modalClasses.presetTitle}>
                                DATE PRESETS
                            </p>
                            <div className={modalClasses.optionsContainer}>
                                {
                                    datePresets.map((preset, index) =>
                                        <div
                                            key={index}
                                            className={modalClasses.presetButton}
                                            onClick={() => {
                                                props.onDatesChange({ startDate: preset.from, endDate: preset.to })
                                            }}
                                        >
                                            <p className={`${modalClasses.presetText} ${(props.startDate === preset.from && props.endDate === preset.to) ? classes.presetTextActive : ''}`}>
                                                {preset.label}
                                            </p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </Paper>
                </ModalRoot>
            </Modal>
        </Root>
    )
}

export default DateRangePicker

type CustomPickerDayProps = PickersDayProps<Date> & {
    theme?: Theme
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
    '&:not(.Mui-selected)': {
        border: 'none',
    }
})) as React.ComponentType<CustomPickerDayProps>;

const useStyles = generateStyle((theme: Theme) => ({
    paper: {
        width: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            maxWidth: 500,
            flexDirection: 'row',
            alignItems: 'flex-start',
        }
    },
    calendarContainer: {
        borderBottom: '1px solid #e0e0e0',
        [theme.breakpoints.up('md')]: {
            borderRight: '1px solid #e0e0e0',
            borderBottom: 'none',
        }
    },
    presetContainer: {
        width: '100%',
        paddingBottom: 20,
        [theme.breakpoints.up('md')]: {
            paddingBottom: 0,
        }
    },
    presetTitle: {
        fontSize: 14,
        fontWeight: '500',
        padding: 16,
        paddingTop: 20,
        paddingBottom: 20,
        margin: 0,
    },
    optionsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        gridTemplateRows: 'repeat(4, minmax(0, 1fr))',
        gridAutoFlow: 'column',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    },
    presetButton: {
        padding: '4px 16px 6px',
        cursor: 'pointer',
        transition: 'color 150ms linear',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    presetText: {
        fontSize: 14,
        margin: 0,
        fontWeight: '500',
    },
    presetTextActive: {
        color: theme.palette.primary.main,
    },
    buttonsContainer: {
        paddingLeft: 16,
        paddingTop: 16,
    },
    footer: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}), "DateRange_Picker_Modal")

const useInputStyle = generateStyle(theme => ({
    inputContainer: {
        display: 'flex',
        borderRadius: 4,
        alignItems: 'center',
        border: '1px solid #0000003b',
    },
    input: {
        fontSize: 15,
        lineHeight: '18px',
        letterSpacing: .2,
        padding: '10px 7px 10px',
        color: '#959595',
        cursor: 'pointer',
        width: '100%',
        overflow: 'hidden',
        textAlign: 'left',
        '&.filled': {
            color: '#484848',
        }
    }
}), "DateRangePicker_Input")

const datePresets = [
    {
        label: 'Maximum',
        from: null,
        to: null,
    },
    {
        label: 'Today',
        from: new Date(),
        to: new Date(),
    },
    {
        label: 'Yesterday',
        from: subDays(new Date(), 1),
        to: subDays(new Date(), 1),
    },
    {
        label: 'Last 7 days',
        from: subDays(new Date(), 7),
        to: subDays(new Date(), 1),
    },
    {
        label: 'Last 14 days',
        from: subDays(new Date(), 14),
        to: subDays(new Date(), 1),
    },
    {
        label: 'Last 30 days',
        from: subDays(new Date(), 30),
        to: subDays(new Date(), 1),
    },
    {
        label: 'This week',
        from: startOfWeek(new Date()),
        to: endOfWeek(new Date()),
    },
    {
        label: 'Last week',
        from: startOfWeek(subWeeks(new Date(), 1)),
        to: endOfWeek(subWeeks(new Date(), 1)),
    },
    {
        label: 'This month',
        from: startOfMonth(new Date()),
        to: endOfMonth(new Date()),
    },
    {
        label: 'Last month',
        from: startOfMonth(subMonths(new Date(), 1)),
        to: endOfMonth(subMonths(new Date(), 1)),
    },
]
import React, { useState, useReducer, useEffect } from 'react';
import { Button, IconButton, Dialog, DialogContent, DialogTitle, Grid, TextField, MenuItem, DialogActions } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';

// Icons
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IState {
    id: string;
    name: string;
    type: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IErrorState {
    name: string;
    type: string;
}

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex'
    },
    addButton: {
        marginBottom: '20px'
    }
}), "Stay360Property");

const Stay360Property = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/stay360-property';
    const { Root, classes } = useStyles();

    const types = [
        {
            key: 'rumah',
            value: 'Rumah'
        },
        {
            key: 'hostel',
            value: 'Hostel'
        }
    ];

    const columns = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true
        },
        {
            name: 'type',
            label: 'Type',
            type: 'string',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...types
            ],
            defaultOption: '',
            filterable: true
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        <IconButton
                            color="primary"
                            component="span"
                            onClick={() => handleEdit(row.id)}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </div>
                );
            }
        }
    ];

    const initialState = {
        id: '',
        name: '',
        type: ''
    };

    const initialErrorState = {
        name: '',
        type: ''
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    useEffect(() => {
        if (!modalOpen) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [modalOpen]);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        setInputState({ name: target.name, value: target.value, type: 'SET_ITEM' });
    }

    const handleEdit = (id: string) => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL + '/' + id)
            .then(res => {
                setInputState({ name: '', value: res.data, type: 'REPLACE_STATE' });
                handleModalOpen();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const checkValidation = () => {
        let isValid = true;
        const newErrorState = { ...initialErrorState };

        if (!inputState.name) {
            newErrorState.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.type) {
            newErrorState.type = 'Type wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', value: newErrorState, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.patch(API_URL + '/' + inputState.id, inputState);
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Property submitted",
                    icon: 'success',
                    onAfterClose: () => {
                        setReDrawDatatable(new Date().getTime());
                        handleModalClose();
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={handleModalOpen}
            >
                Add New Property
            </Button>
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                fullWidth
                maxWidth="md"
            >
                <Root>
                    <DialogTitle>Property Form</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Nama"
                                    name="name"
                                    value={inputState.name}
                                    onChange={handleChange}
                                    error={!!errorState.name}
                                    helperText={errorState.name}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    select
                                    variant="outlined"
                                    label="Type"
                                    name="type"
                                    value={inputState.type}
                                    onChange={handleChange}
                                    error={!!errorState.type}
                                    helperText={errorState.type}
                                >
                                    {types.map(type => <MenuItem value={type.key} key={type.key}>{type.value}</MenuItem>)}
                                </TextField>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Root>
            </Dialog>
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Root>
    );
}

export default Stay360Property;
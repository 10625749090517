import React from 'react';
import { Dialog, Slide, Grid, Typography, Button } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

// Icons
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
  open: boolean;
  onClose: () => void;
  name: string;
  phoneNumber: string;
}

const useStyles = generateStyle(() => ({
  paper: {
    width: '100%',
    margin: 0,
    position: 'fixed',
    bottom: 0,
    left: 0,
    textAlign: 'center',
    borderRadius: '10px 10px 0 0',
    padding: '25px 10px',
    '& a': {
      padding: 13
    }
  },
  gridName: {
    '& p': {
      fontSize: 18,
      fontWeight: 'bolder'
    }
  }
}), "CallButton_Modal"
)

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CallButtonModal = (props: Props) => {
  const { Root, classes } = useStyles();

  const phoneNumber = props.phoneNumber.replace(/[^0-9]/, '');

  const renderContact = (data: any, type: string) => {
    let regex = /^08/;
    if (data.match(regex)) {
      data = data.replace(regex, "628")
    }
    let phone = data;
    let link = '';

    if (type === 'wa') {
      link = 'https://api.whatsapp.com/send?phone=' + phone;
    } else {
      link = 'tel:+' + phone;
    }
    return (`${link}`)
  }

  return <Dialog
    classes={{
      paper: classes.paper
    }}
    open={props.open}
    onClose={props.onClose}
    TransitionComponent={Transition}
  >
    <Root>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.gridName}>
          <Typography>Hubungi {props.name}</Typography>
        </Grid>
        <Grid item xs>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CallIcon />}
            fullWidth
            href={renderContact(phoneNumber, 'call')}
          >
            Call
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<WhatsAppIcon />}
            fullWidth
            href={renderContact(phoneNumber, 'wa')}
          >
            Whatsapp
          </Button>
        </Grid>
      </Grid>
    </Root>
  </Dialog>
};

export default CallButtonModal;
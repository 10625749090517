import React, { useEffect, useState } from 'react'
import { Grid, TextField, MenuItem, Theme, Card, CardContent } from '@mui/material'
import { useParams } from 'react-router-dom'

/**
 * Components
*/
import ActivityForm from './_components/ActivityForm'
import MaintenanceForm from './_components/MaintenanceForm'
import QualityControlListingForm from './_components/QualityControlListingForm'
import FixingCleaningForm from './_components/FixingCleaningForm'
import FotoUnitForm from './_components/FotoUnitForm'
import PerawatanEssensialForm from './_components/PerawatanEssensialForm'

/**
 * Utils
*/
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, queryParamsToParams, renderWarningButton } from '../../_utils/Helper'
import { generateStyle } from '../../_utils/DefaultStyle'


/* 
* Interfaces
*/
type IRequestType = { label: string, value: string, key?: string }

type IOrderType = 'fixing_cleaning' | 'photo' | 'essential_pack'

export type KCPOption = 'Kantor Jendela360' | 'Others' | ''

export interface IDefaultState {
    id?: number,
    activity: IOrderType | string,
    request_date?: string | null,
    request_time?: string | null,
    key_collection_point_option: KCPOption,
    key_collection_point: string | null,
    notes: string | null
}

export interface IDetail {
    start_date: string,
    end_date: string,
    unit_code: string,
    apartment_id: string,
    apartment_name: string,
    bedroom: number | null,
    tower: string,
    floor_level: string,
    unit_number: string,
    owner_name: string,
    customer_name: string,
    tenant_name: string
}

interface IEditData extends IDefaultState, IDetail {
    id: number
}

export const initialRequestType: IRequestType[] = [
    { label: "Fixing and Cleaning", value: 'fixing_cleaning', key: 'fixing_cleaning' },
    { label: "Foto Unit", value: 'photo', key: 'photo' },
    { label: "Paket Perawatan Essential", value: 'essential_pack', key: 'essential_pack' },
    { label: "Handover Checkin", value: "9", key: '9' },
    { label: "Handover Checkout", value: '2', key: '2' },
    { label: "QC Checkin", value: 'quality-control', key: 'quality-control' },
    { label: "QC Listing", value: "quality-control-listing", key: 'quality-control-listing' },
    { label: "Maintenance", value: "3", key: '3' }
]

const initialEditData = {
    id: 0,
    start_date: '',
    end_date: '',
    unit_code: '',
    apartment_id: "",
    apartment_name: '',
    bedroom: null,
    tower: '',
    floor_level: '',
    unit_number: '',
    owner_name: '',
    tenant_name: '',
    customer_name: '',
    activity: 'fixing_cleaning',
    request_date: null,
    request_time: null,
    key_collection_point_option: '' as KCPOption,
    key_collection_point: null,
    notes: ''
}

const JhsOrderForm = () => {
    const { Root, classes } = useStyles()
    const params = useParams<{ id: string }>()
    const [requestType, setRequestType] = useState<IRequestType>(initialRequestType[0])
    const [formChanged, setFormChanged] = useState(false)
    const [editData, setEditData] = useState<IEditData>(initialEditData)

    const handleChangeRequestType = (request: IRequestType) => {
        if (formChanged && !queryParamsToParams()?.ticket_id) {
            renderWarningButton("Data yang anda inputkan akan hilang.")
                .then((res) => {
                    if (res.value) {
                        setRequestType(request)
                    }
                })
        } else {
            setRequestType(request)
        }
    }

    const fetchData = (id: number | string) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/activity/${id}`)
            .then((res: any) => {
                const data = res.data.activity
                setRequestType({ label: data.activity_name, value: data.activity })
                setEditData({
                    ...data,
                    key_collection_point_option: data.key_collection_point === 'Kantor Jendela360' ? 'Kantor Jendela360' : 'Others',
                    key_collection_point: data.key_collection_point === 'Kantor Jendela360' ? '' : data.key_collection_point,
                })
            })
            .catch(err => generalErrorHandler(err))
    }

    const fetchDataTicket = (id: number | string) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/ticket/${id}`)
            .then(res => {
                let data = res.data
                delete data.pic_id
                delete data.pic_name
                setEditData(data)
            })
            .catch(err => generalErrorHandler(err))
    }

    useEffect(() => {
        if (params.id) {
            fetchData(params.id)
        }
        // eslint-disable-next-line
    }, [])

    const renderFormOrderDetail = (requestType: IRequestType) => {
        switch (requestType.value) {
            case 'fixing_cleaning':
                return (
                    <FixingCleaningForm
                        setFormChanged={setFormChanged}
                        isEdit={!!params.id}
                        editData={editData}
                    />)
            case 'photo':
                return (
                    <FotoUnitForm
                        setFormChanged={setFormChanged}
                        isEdit={!!params.id}
                        editData={editData}
                    />)
            case 'essential_pack':
                return (
                    <PerawatanEssensialForm
                        setFormChanged={setFormChanged}
                        isEdit={!!params.id}
                        editData={editData}
                    />)
            case '9':
                return (
                    <ActivityForm
                        key="9"
                        activity={requestType.value}
                        setFormChanged={setFormChanged}
                        isEdit={!!params.id}
                        editData={editData}
                    />)
            case '2':
                return (
                    <ActivityForm
                        key="2"
                        activity={requestType.value}
                        setFormChanged={setFormChanged}
                        isEdit={!!params.id}
                        editData={editData}
                    />)
            case 'quality-control':
                return (
                    <ActivityForm
                        key="quality-control"
                        activity={requestType.value}
                        setFormChanged={setFormChanged}
                        isEdit={!!params.id}
                        editData={editData}
                    />)
            case 'quality-control-listing':
                return (
                    <QualityControlListingForm
                        key="quality-control-listing"
                        activity={requestType.value}
                        setFormChanged={setFormChanged}
                        isEdit={!!params.id}
                        editData={editData}
                    />)
            case '3':
                return (
                    <MaintenanceForm
                        setFormChanged={setFormChanged}
                        isEdit={!!params.id}
                        editData={editData}
                    />
                )
            default:
                return null
        }
    }

    useEffect(() => {

        document.body.style.backgroundColor = '#eee';
        document.body.style.paddingRight = "0";
        let timeout: any;

        return () => {
            clearTimeout(timeout);
            document.body.style.backgroundColor = '#fff';
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setFormChanged(false)
    }, [requestType.value])

    useEffect(() => {
        if (queryParamsToParams()?.ticket_id) {
            const ticket_id = queryParamsToParams().ticket_id
            fetchDataTicket(ticket_id)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Root>
            <Card className={classes.root}>
                <CardContent>
                    <Grid container sx={{ mb: 2 }}>
                        <h2>Order {requestType.label} </h2>
                        <Grid item xs={12}>
                            {!params.id &&
                                <TextField
                                    select
                                    name="request_type"
                                    label="Request Type"
                                    value={requestType.value}
                                >
                                    {
                                        initialRequestType
                                            .filter(item => item.value !== '9' && item.value !== '2' && item.value !== 'quality-control')
                                            .filter(item => {
                                                if (queryParamsToParams()?.ticket_id) {
                                                    return item.value === 'fixing_cleaning' || item.value === '3'
                                                }

                                                return item
                                            })
                                            .map((requestTypeOption) => (
                                                <MenuItem
                                                    key={requestTypeOption.value}
                                                    value={requestTypeOption.value}
                                                    onClick={() => handleChangeRequestType(requestTypeOption)}
                                                >
                                                    {requestTypeOption.label}
                                                </MenuItem>
                                            ))
                                    }
                                </TextField>
                            }
                        </Grid>
                    </Grid>
                    {renderFormOrderDetail(requestType)}
                </CardContent>
            </Card>
        </Root>
    )
}

export default JhsOrderForm

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        maxWidth: '800px',
        '& div.Mui-disabled': {
            backgroundColor: '#eee'
        },
        [theme.breakpoints.down('xs')]: {
            margin: 0,
            marginBottom: 0,
            boxShadow: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '70px',
            boxShadow: '0px 3px 15px #0000001A',
        }
    },
}), "JHSOrder_Form"
)
import * as React from "react";
import { LineChart, lineElementClasses } from "@mui/x-charts/LineChart";
import { useEffect, useRef, useState } from "react";
import { BreakdownItem, DataBreakdown } from "../EnquiryBreakdown";
import { numberToCurrency } from '../../../_utils/Helper';

interface EnquiryChartProps {
	dataChart: BreakdownItem[]
}



const EnquiryChart: React.FC<EnquiryChartProps> = ({ dataChart }) => {
	const [chartWidth, setChartWidth] = useState<number>(0);
	const [bottomMargin, setBottomMargin] = useState<number>(130);
	const [hoverData, setHoverData] = useState<{ total: number, avg: number } | null>(null);
	const chartContainerRef = useRef<HTMLDivElement>(null);


	const labels = Object.keys(dataChart[0].data) as Array<keyof DataBreakdown>;


	const colors = [
		"#ff8a65",
		"#4caf50",
		"#ffc107",
		"#f44336",
		"#009688",
		"#ffeb3b",
		"#795548",
		"#2196f3",
		"#9c27b0",
		"#8bc34a",
		"#cddc39",
		"#e91e63",
		"#673ab7",
		"#00bcd4",
		"#03a9f4",
		"#3f51b5",
		"#ff9800",
		"#ff5722",
		"#607d8b",
		"#000000",
	];


	const leadValueAvg = dataChart.map((item) => item.data.lead_value_avg);
	const leadValueTotal = dataChart.map((item) => item.data.lead_value_total);

	const series = labels
		.filter(label => label !== 'lead_value_avg' && label !== 'lead_value_total')
		.map((label, index) => {
			return {
				data: dataChart.map((item) => item.data[label]),
				label,
				area: true,
				showMark: false,
				color: colors[index % colors.length],
			};
		});

	const totalSeries = {
		data: dataChart.map((items) =>
			labels
				.filter(label => label !== 'lead_value_avg' && label !== 'lead_value_total')
				.reduce((sum, label) => sum + items.data[label], 0)
		),
		label: "Total",
		area: true,
		showMark: false,
		color: "#009BE5",
	}

	const completeSeries = [totalSeries, ...series];

	const months = dataChart.map((item) => item.breakdown);

	const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
		const mouseX = event.nativeEvent.offsetX;
		const index = Math.floor(mouseX / (chartWidth / dataChart.length));
		if (index >= 0 && index < dataChart.length) {
			const total = Number(leadValueTotal[index + 1]);
			const avg = Number(leadValueAvg[index + 1]);
			setHoverData({ total, avg });
		} else {
			setHoverData(null);
		}
	};

	const handleMouseLeave = () => {
		setHoverData(null);
	};

	useEffect(() => {
		const updateChartWidth = () => {
			if (chartContainerRef.current) {
				setChartWidth(chartContainerRef.current.offsetWidth);
			}
		};

		const updateBottomMargin = () => {
			const screenWidth = window.innerWidth;
			if (screenWidth === 1366) {
				setBottomMargin(140);
			} else if (screenWidth === 1920) {
				setBottomMargin(120);
			}
			else if (screenWidth <= 768) {
				setBottomMargin(170);
			}
			else {
				setBottomMargin(100);
			}
		};

		updateChartWidth();
		updateBottomMargin();
		window.addEventListener("resize", updateChartWidth);
		window.addEventListener("resize", updateBottomMargin);

		return () => {
			window.removeEventListener("resize", updateChartWidth);
			window.removeEventListener("resize", updateBottomMargin);
		};
	}, []);


	return (
		<div ref={chartContainerRef} style={{ width: "100%", height: "100%", position: 'relative' }} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
			<LineChart
				width={chartWidth}
				height={550}
				margin={{ bottom: bottomMargin }}
				slotProps={{
					legend: {
						position: { horizontal: "middle", vertical: "bottom" }
					}
				}}
				series={completeSeries}
				xAxis={[{
					scaleType: "point",
					data: months,
					valueFormatter: val => {
						if (!val.includes(',')) return val

						const valArray = val.split(',')
						return `${valArray[0]},\n${valArray[1].trim()}`
					}
				}]}
				yAxis={[{ scaleType: "linear" }]}
				sx={{
					[`& .${lineElementClasses.root}`]: {
						display: "none",
					},
				}}
			/>
			{hoverData && (
				<div
					style={{
						position: "absolute",
						top: '20px',
						right: 20,
						backgroundColor: "rgba(255, 255, 255, 0.8)",
						padding: "5px 10px",
						borderRadius: "4px",
						boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
					}}
				>
					<strong>Total Lead Value:</strong> Rp {numberToCurrency(hoverData.total)}<br />
					<strong>Avg. Lead Value:</strong> Rp {numberToCurrency(hoverData.avg)}
				</div>
			)}
		</div>
	);
}

export default EnquiryChart;

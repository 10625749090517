import { Button, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import LoadingScreen from '../../_components/LoadingScreen'
import MuiDatePicker from '../../_components/MuiDatePicker'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, inputNumber } from '../../_utils/Helper'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData, validateOne } from '../../_utils/Validation'
import Swal from 'sweetalert2';

interface IBookingForm {
    name: string
    email: string
    address: string
    ktp_number: string
    start_date: Date | null
    duration: string
    file: null | File
}

interface IParams extends Record<string, string | undefined> {
    id: string
}

const BookingForm = () => {
    const params = useParams<IParams>()

    const [state, setState] = useState<IBookingForm>({
        name: '',
        email: '',
        address: '',
        ktp_number: '',
        start_date: null,
        file: null,
        duration: '',
    })

    const [error, setError] = useState<IValidationErrors>({})

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/bookings/${params.id}`)
            .then(res => {
                setState({
                    name: res.data.name || '',
                    email: res.data.account?.email || '',
                    address: res.data.address || '',
                    ktp_number: res.data.ktp_number || '',
                    start_date: new Date(res.data.start_date),
                    file: null,
                    duration: res.data.duration.toString()
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [params.id])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: ['ktp_number', 'duration'].includes(name) ? inputNumber(value) : value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        const { isValid, errors } = validateOne(name, value, validationRules, validationAlias)

        if (isValid) {
            setError(prev => ({
                ...prev,
                [name]: '',
            }))
        } else {
            setError(prev => ({
                ...prev,
                ...errors,
            }))
        }
    }

    const onChangeDate = (date: Date | null) => {
        setState(prev => ({
            ...prev,
            start_date: date,
        }))

        setError(prev => ({
            ...prev,
            start_date: '',
        }))
    }

    const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, files } = e.target

        if (files && files.length > 0) {
            setState(prev => ({
                ...prev,
                [name]: files[0]
            }))
        }
    }

    const submit = () => {
        const { isValid, errors } = validateData(state, validationRules, validationAlias)

        if (isValid) {
            const formData = new FormData()

            for (const [key, value] of Object.entries(state)) {
                switch (key) {
                    case 'start_date':
                        const date = value as Date
                        formData.append(key, `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`)
                        break
                    case 'file':
                        if (value) formData.append('payment_proof_filepath', value)
                        break
                    default:
                        formData.append(key, value)
                        break
                }
            }

            formData.append('_method', 'PATCH');

            setIsLoading(true);
            DefaultAxios
                .post(`${process.env.REACT_APP_API_URL}/bookings/${params.id}`, formData)
                .then(() => {
                    Swal.fire({
                        title: "Booking Updated",
                        icon: 'success',
                        timer: 1000
                    })
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        } else {
            setError(errors)
        }
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        name="name"
                        label="Name"
                        value={state.name}
                        error={!!error.name}
                        helperText={error.name}
                        onChange={onChange}
                        onBlur={onBlur}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="email"
                        label="Email"
                        value={state.email}
                        error={!!error.email}
                        helperText={error.email}
                        onChange={onChange}
                        onBlur={onBlur}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="address"
                        label="Address"
                        value={state.address}
                        error={!!error.address}
                        helperText={error.address}
                        onChange={onChange}
                        onBlur={onBlur}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="ktp_number"
                        label="KTP Number"
                        value={inputNumber(state.ktp_number)}
                        error={!!error.ktp_number}
                        helperText={error.ktp_number}
                        onChange={onChange}
                        onBlur={onBlur}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <MuiDatePicker
                        margin="none"
                        label="Start Date"
                        value={state.start_date}
                        onChange={onChangeDate}
                        error={!!error.start_date}
                        helperText={error.start_date}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="duration"
                        label="Duration (Months)"
                        value={inputNumber(state.duration)}
                        error={!!error.duration}
                        helperText={error.duration}
                        onChange={onChange}
                        onBlur={onBlur}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <h5 style={{ marginTop: 0 }}>
                        Bukti Pembayaran
                    </h5>
                    <input type="file" name="file" id="file" onChange={onChangeFile} />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default BookingForm

const validationRules: IValidationRules = {
    name: 'required',
    email: 'required|email',
    address: 'required',
    ktp_number: 'required|number',
    start_date: 'required',
    duration: 'required|number',
}

const validationAlias: IValidationAlias = {
    name: 'Name',
    email: 'Email',
    address: 'Address',
    ktp_number: 'KTP',
    start_date: 'Start Date',
    duration: 'Duration',
}
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Switch, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { generateStyle } from '../../../../_utils/DefaultStyle'

/**
 * Components
 */
import TextFieldND2 from '../../../../_components/_formElements/_newDesign2/TextFieldND2';
import LoadingScreen from '../../../../_components/LoadingScreen';

/**
 * Utils
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../../_components/_form/AsyncAutoComplete';
import { validateData } from '../../../../_utils/Validation';
import DefaultAxios from '../../../../_utils/DefaultAxios';
import { generalErrorHandler, inputNumber, renderSuccessButton } from '../../../../_utils/Helper';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

type Props = {
    open: boolean
    onClose: () => void
    onSuccess: (id: number, label: string) => void
    requesterData?: RequesterData
}

export type RequesterData = null | {
    name?: string
    phone?: string
    email?: string
}

const useStyles = generateStyle((theme) => ({
    header: {
        backgroundColor: '#F3F5F7',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center",
        fontSize: 22
    },
    switchContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    smallSubtitle: {
        fontSize: 14,
        color: "#484848"
    },
    footer: {
        border: '1px 0px 0px solid #DDDDDD',
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}), "Add_Landlord_Modal")

interface IState {
    name: string,
    add_pic: boolean,
    phone: string,
    email: string,
    pic_name: string,
    pic_id: number | null
}

const initialState = {
    name: '',
    add_pic: false,
    phone: '',
    email: '',
    pic_name: '',
    pic_id: null
}

const aliases = {
    name: 'Nama',
    phone: 'No. Telepon',
    email: 'Email',
    pic_id: 'PIC'
}

const AddLandlordModal = (props: Props) => {
    const { Root, classes } = useStyles()
    const API_URL = process.env.REACT_APP_API_URL + '/landlord';
    const [inputState, setInputState] = useState<IState>(initialState)
    const [errorState, setErrorState] = useState<Partial<{ [x: string]: string }>>({})
    const [isLoading, setIsLoading] = useState(false)

    const rules = useMemo(() => ({
        name: 'required',
        email: 'email',
        ...(!inputState.add_pic ? { phone: 'required' } : { pic_id: 'required' }),
    }), [inputState.add_pic])

    const submitLandlord = () => {
        const { errors, isValid } = validateData(inputState, rules, aliases,)
        if (!isValid) {
            setErrorState(errors)
            return
        }

        setIsLoading(true)
        DefaultAxios.post(API_URL, {
            ...inputState,
            is_also_pic: inputState.add_pic ? 0 : 1
        })
            .then(res => res.data)
            .then(data => {
                renderSuccessButton('Success Add Landlord')
                props.onSuccess(data.id, data.label)
                props.onClose()
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputState(prev => ({
            ...prev,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.name === 'phone' ? inputNumber(e.target.value) : e.target.value
        }))

        setErrorState(prev => ({
            ...prev,
            [e.target.name]: ''
        }))
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        const extraData = {
            pic_id: value.id,
            pic_name: value.name
        }

        setInputState(prev => ({
            ...prev,
            ...extraData
        }));

        setErrorState(prev => ({
            ...prev,
            pic_id: ''
        }))
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        const extraData = {
            pic_id: null,
            pic_name: ''
        }

        setInputState(prev => ({
            ...prev,
            ...extraData
        }));
    }

    useEffect(() => {
        if (props.open && props.requesterData) {
            setInputState(prev => ({
                ...prev,
                name: props.requesterData?.name || prev.name,
                email: props.requesterData?.email || prev.email,
                phone: props.requesterData?.phone || prev.phone,
            }))
        }
    }, [props.open, props.requesterData])

    useEffect(() => {
        if (!props.open) {
            setInputState(initialState)
            setErrorState({})
        }
    }, [props.open])

    useEffect(() => {
        setInputState(prev => ({
            ...prev,
            phone: '',
            pic_id: null,
            pic_name: '',
        }))
    }, [inputState.add_pic])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: {
                    borderRadius: 20
                }
            }}
        >
            <Root>
                <LoadingScreen open={isLoading} fullScreen />
                <DialogTitle className={classes.header}>
                    Add Landlord
                    <IconButton
                        onClick={props.onClose}
                        size='medium'
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container rowSpacing={2} sx={{ mt: 0, mb: 2 }}>
                        <Grid item xs={12}>
                            <TextFieldND2
                                name='name'
                                label="Nama Landlord"
                                placeholder='Masukkan nama Landlord'
                                value={inputState.name}
                                onChange={handleChange}
                                error={!!errorState.name}
                                helperText={errorState.name || ''}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.switchContainer} style={{ paddingBottom: 0 }}>
                            <Typography className={classes.smallSubtitle}>
                                Add PIC For Landlord
                            </Typography>
                            <Switch
                                color="primary"
                                name="add_pic"
                                checked={inputState.add_pic}
                                onChange={handleChange}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </Grid>
                        {
                            inputState.add_pic ?
                                <Grid item xs={12}>
                                    <AsyncAutoComplete
                                        label="PIC"
                                        name="pic_id"
                                        initialQuery={inputState.pic_name}
                                        onChange={handleAutocomplete}
                                        onInputChange={handleAutocompleteInputChanged}
                                        url={`${process.env.REACT_APP_API_URL}/admin/autocomplete/pic-landlord`}
                                        placeholder="Masukkan PIC"
                                        // errorText={errorState.pic_id}
                                        newDesign={2}
                                        errorText={errorState.pic_id || ''}
                                        clearable
                                        onClear={() => handleAutocompleteInputChanged('', '')}
                                    />
                                </Grid> :
                                <Grid item xs={12}>
                                    <TextFieldND2
                                        name='phone'
                                        label="No. Telepon"
                                        value={inputNumber(inputState.phone)}
                                        placeholder='Masukkkan No. Telepon'
                                        onChange={handleChange}
                                        error={!!errorState.phone}
                                        helperText={errorState.phone || ''}
                                        InputProps={{
                                            startAdornment: <PhoneInTalkIcon />
                                        }}
                                    />
                                </Grid>
                        }
                        <Grid item xs={12}>
                            <TextFieldND2
                                name='email'
                                label="Email"
                                value={inputState.email}
                                placeholder='Masukkkan Email'
                                onChange={handleChange}
                                error={!!errorState.email}
                                helperText={errorState.email || ''}
                                InputProps={{
                                    startAdornment: <MailOutlineIcon />
                                }}

                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ borderTop: '1px solid #ddd', padding: '20px 40px' }}>
                    <Grid container>
                        <Grid item xs={12} className={classes.footer}>
                            <Button style={{ textTransform: 'capitalize' }} color='info' onClick={props.onClose}>
                                Batalkan
                            </Button>
                            <Button style={{ textTransform: 'capitalize' }} variant='contained' onClick={submitLandlord}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Root>
        </Dialog >
    )
}

export default AddLandlordModal
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Theme, Dialog, Slide, Toolbar, IconButton, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
// import Swal from 'sweetalert2';
import moment from 'moment';

import DataTable from '../../_components/_dataTable/DataTable';
import ProvinceForm from './ProvinceForm';
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
// import DefaultAxios from '../../_utils/DefaultAxios';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useContext } from 'react';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';
// import DeleteIcon from '@mui/icons-material/Delete';

interface IProps {
    province_id?: string
}
interface IParams extends Record<string, string | undefined> {
    province_id?: string
}

const useStyles = generateStyle((theme: Theme) =>
    ({
        actionContainer: {
            display: 'flex'
        },
        appBar: {
            position: 'relative'
        },
        addButton: {
            marginBottom: '20px'
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        notes_list: {
            maxWidth: '500px',
            lineBreak: 'anywhere',
            whiteSpace: 'pre-line'
        }
    }), "ProvinceList"
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProvinceList = (props: IProps) => {
    const permissions = useContext(PermissionContext)
    const {Root, classes} = useStyles();
    const DT_API_URL = process.env.REACT_APP_API_URL + '/province';

    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [selectedId, setSelectedId] = useState<string>('');
    const [modalState, setModalState] = useState(false);
    const isLoading = false;
    const params = useParams<IParams>()
    const navigate =  useNavigate();
    const handleOpen = () => setModalState(true);
    const handleClose = () => {
        setSelectedId('');
        setModalState(false)
        window.history.pushState([], 'Province', `/province/`);
    }

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Province Form
                </Typography>
            </Toolbar>
        )
    }

    const openModalCreate = () => {
        setSelectedId('');
        handleOpen();
    }


    const submitSuccess = () => {
        handleClose();
        setSelectedId('');
        navigate(`/province/`)
        setReDrawDatatable(new Date().getTime());
    }

    useEffect(() => {
        if (params.province_id && typeof params.province_id === 'string') {
            setSelectedId(params.province_id)
            handleOpen()
        }
    }, [params]);

    // const handleDelete = (id: string) => {
    //     Swal.fire({
    //         title: 'Apakah Anda Yakin?',
    //         text: "Apakah anda yakin ingin menghapus data ini?",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Ya'
    //     }).then((result) => {
    //         if (result.value) {
    //             setIsLoading(true);
    //             DefaultAxios
    //                 .delete(`${DT_API_URL}/${id}`)
    //                 .then(res => {
    //                     setReDrawDatatable(new Date().getTime());
    //                 })
    //                 .catch(error => {
    //                     generalErrorHandler(error)
    //                 })
    //                 .finally(() => {
    //                     setIsLoading(false);
    //                 })
    //         }
    //     })
    // }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                open={modalState}
                onClose={handleClose}
                closeAfterTransition
                TransitionComponent={Transition}
                fullWidth
                maxWidth="lg"
            >
                <FormToolbar></FormToolbar>
                <ProvinceForm
                    onSubmitSuccess={submitSuccess}
                    provinceId={selectedId}
                />
            </Dialog>
            {
                permissions['province.manage'] ?
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.addButton}
                        startIcon={<AddIcon />}
                        onClick={openModalCreate}
                    >
                        Add Province
                    </Button>
                    : null
            }
            <DataTable
                reDraw={reDrawDatatable}
                url={DT_API_URL}
                columns={[
                    { name: 'name', label: 'Name', sortable: true },
                    {
                        name: 'updated_at',
                        label: 'Last modified',
                        type: 'date',
                        sortable: true,
                        // defaultOption: '23/10/2020 - 23/10/2020',
                        render: (data: any, row: any) => {
                            return moment(data).format('DD MMM YYYY')
                        }
                    },
                    {
                        name: 'EXTRA',
                        label: 'Action',
                        sortable: false,
                        render: (row: any) => {
                            return (
                                permissions['province.manage'] ?
                                    <div className={classes.actionContainer}>
                                        <IconButton
                                            color="primary"
                                            component="span"
                                            onClick={() => navigate(`/province/${row.id}`)}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        {/* <IconButton
                                            color="secondary"
                                            component="span"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton> */}
                                    </div>
                                    : <> </>
                            );
                        }
                    }
                ]}
            />
        </Root>
    );
}

export default ProvinceList;
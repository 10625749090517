import React, { useState } from 'react';
import { Button, Link } from '@mui/material';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import HolidayForm from './HolidayForm';

/**
 * Utils
 */
import { dateFormat } from '../../_utils/Helper';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme) => ({
    addButton: {
        marginBottom: '20px'
    },
}), "Holiday_list")

const HolidayList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/holiday';
    const { Root, classes } = useStyles();

    const columns = [
        {
            name: 'start_date',
            label: 'Date',
            type: 'date',
            filterable: true,
            sortable: false,
            render: (data: any, row: any) => {
                if (data === row.end_date) {
                    return dateFormat(data, 'DD MMM YYYY');
                } else {
                    return dateFormat(data, 'DD MMM YYYY') + ' - ' + dateFormat(row.end_date, 'DD MMM YYYY');
                }
            }
        },
        {
            name: 'label',
            label: 'Label',
            type: 'string',
            filterable: true,
            sortable: false,
            render: (data: any, row: any) => {
                return (
                    <Link
                        onClick={() => { openModalEdit(row.id) }}
                        style={{ cursor: 'pointer' }}
                    >
                        {data}
                    </Link>
                );
            }
        }
    ];

    const [selectedId, setSelectedId] = useState<string>('');
    const [modalState, setModalState] = useState(false);
    const [reDrawDT, setReDrawDT] = useState(new Date().getTime());

    const handleOpen = () => setModalState(true);
    const handleClose = () => setModalState(false);

    const openModalCreate = () => {
        setSelectedId('');
        handleOpen();
    }

    const openModalEdit = (id: string) => {
        setSelectedId(id);
        handleOpen();
    }

    const submitSuccess = () => {
        handleClose();
        setReDrawDT(new Date().getTime());
    }

    return (
        <Root>
            <HolidayForm
                open={modalState}
                onClose={handleClose}
                onSubmitSuccess={submitSuccess}
                holidayId={selectedId}
            />

            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={openModalCreate}
            >
                Add Holiday
            </Button>

            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDT}
            />
        </Root>
    );
}

export default HolidayList;
import React, { useState, useEffect, useReducer } from 'react';
import { Button, Theme, Tooltip, IconButton, Grid } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import RoleForm from './RoleForm';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

// Icons
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { generateStyle } from '../../_utils/DefaultStyle';

export interface IOption {
    key: string;
    value: string;
}

export interface IState {
    id: string;
    name: string;
    slug: string;
    permissions: string
}

interface IAction {
    name: string,
    value: any,
    type: string
}

export interface IErrorState {
    name: string;
    slug: string;
    permissions: string
}


const useStyles = generateStyle((theme: Theme) =>
({
    buttonContainer: {
        marginBottom: theme.spacing(3)
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    addButton: {
        marginBottom: '20px'
    },
    importButton: {
        float: 'right'
    },
    errorContainer: {
        maxHeight: '200px',
        overflowY: 'auto'
    }
}), "RoleList"
);

const RoleList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/role';
    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const columns = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true
        },
        {
            name: 'slug',
            label: 'Slug',
            type: 'string',
            filterable: true
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        <Tooltip title="Edit">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handleEdit(row.id)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
        },
    ];

    const initialState = {
        id: '',
        name: '',
        slug: '',
        permissions: '',
    };

    const initialErrorState = {
        name: '',
        slug: '',
        permissions: '',
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    useEffect(() => {
        if (!modalOpen) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [modalOpen]);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            // return;
        }

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.patch(`${API_URL}/${inputState.id}`, inputState)
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        handleModalClose();
                        setReDrawDatatable(new Date().getTime());
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleEdit = (id: string) => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${id}`)
            .then(res => {
                const newState = { ...initialState, ...res.data, permissions: res.data.permissions ? JSON.stringify(res.data.permissions) : '' };
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                handleModalOpen();
            })
            .catch(err => {
                console.log(err)
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <RoleForm
                open={modalOpen}
                onClose={handleModalClose}
                state={inputState}
                errorState={errorState}
                onChange={handleChange}
                onSubmit={handleSubmit}
            />

            <Grid container justifyContent="space-between" className={classes.buttonContainer}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.addButton}
                        startIcon={<AddIcon />}
                        onClick={handleModalOpen}
                    >
                        Add Role
                    </Button>
                </Grid>
            </Grid>

            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Root>
    );
}

export default RoleList;
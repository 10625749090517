import React, { useContext } from 'react';

/**
 * Components
 */
import Dashboard from './Dashboard';
import DashboardPM from "./_dashboardPM/DashboardPM";
import MenuBar from '../_components/MenuBar';
import DashboardTR from './_dashboardTR/DashboardTR';
import DashboardListingAdmin from './_dashboardListingAdmin/DashboardListingAdmin';
import { PermissionContext } from '../_contexts/PermissionContext';
import DashboardKunciCS from './_dashboardKunciCS/DashboardKunciCS';
import DashboardPMCaptain from './_targetScoringDashboard/DashboardPMCaptain';
import DashboardJHS from './_dashboardJHS/DashboardJHS';
import DashboardJHSCoordinator from './_dashboardJHSCoordinator/DashboardJHSCoordinator';
import DashboardOperationManager from './_dashboardOperationManager/DashboardOperationManager';
import DashboardListingAcquisitionManager from './_dashboardListingAcquisitionManager/DashboardListingAcquisitionManager';
import DashboardCollection from './_dashboardCollection/DashboardCollection';

export default function DashboardRoot() {
    const permissions = useContext(PermissionContext)

    switch (permissions['dashboard-type']) {
        case 'advisor-captain':
            return <DashboardPMCaptain />
        case 'advisor':
            return <DashboardPM />
        case 'tenant-relation-manager':
            return <DashboardTR onlyManager permissions={permissions} />
        case 'tenant-relation':
            return <DashboardTR permissions={permissions} />
        case 'listing-admin':
            return <DashboardListingAdmin permissions={permissions} />
        case 'customer-service':
            return <DashboardKunciCS />
        case 'jhs-admin':
            return <DashboardJHS />
        case 'jhs-coordinator':
            return <DashboardJHSCoordinator />
        case 'operation-manager':
            return <DashboardOperationManager />
        case 'listing-acquisition-manager':
            return <DashboardListingAcquisitionManager />
        case 'collection':
            return <DashboardCollection />
        default:
            return (
                <MenuBar title="Dashboard">
                    <Dashboard />
                </MenuBar>
            )
    }
}
import React, { useState, useEffect, useContext } from 'react';

/**
 * Components
 */
import { Button, Theme } from '@mui/material';
import DataTable from '../../_components/_dataTable/DataTable';
import ActivityModalDetail from './_components/ActivityModalDetail';
import { generateStyle } from '../../_utils/DefaultStyle';

/**
 * Icons
 */
import ShowingFormModal from './_components/ShowingFormModal';

/**
 * Utils
 */
import { PermissionContext } from '../../_contexts/PermissionContext';
import { useParams } from "react-router-dom";
import { addWeeks, format, subWeeks } from 'date-fns';

interface IProps {
    id?: string
}
interface IParams extends Record<string, string | undefined> {
    id?: string
}

const ActivityShowingList = (props: IProps) => {
    const { Root, classes } = useStyles()
    const permissions = useContext(PermissionContext)
    const [selectedId, setSelectedId] = useState<string>('');
    const [modalDetailState, setModalDetailState] = useState(false);
    const [modalAddState, setModalAddState] = useState(false)
    const params = useParams<IParams>()
    const handleOpenDetail = () => setModalDetailState(true);
    const dateEnd = addWeeks(new Date(), 1)
    const dateStart = subWeeks(new Date(), 1)
    const [redrawCounter, setRedrawCounter] = useState(0);
    const [parentQueryString, setParentQueryString] = useState('')

    const handleCloseDetail = (redraw?: boolean) => {
        setModalDetailState(false)
        window.history.pushState([], 'Activity Showing List', `/activity-showing${parentQueryString ? `?${parentQueryString}` : ''}`);

        if (redraw) {
            setRedrawCounter(redrawCounter + 1);
        }
    };

    useEffect(() => {
        if (typeof params.id !== "undefined") {
            setSelectedId(params.id);
            handleOpenDetail();
        }
    }, [params]);

    return (
        <Root>
            {
                permissions['activity.create-showing'] ?
                    <>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => setModalAddState(true)}
                            sx={{ mb: 2 }}
                        >
                            Add Showing
                        </Button>
                        <ShowingFormModal
                            isOpen={modalAddState}
                            onClose={() => setModalAddState(false)}
                            onFinished={() => {
                                setRedrawCounter(prev => prev + 1)
                            }}
                        />
                    </>
                    : null
            }
            <ActivityModalDetail
                open={modalDetailState}
                onClose={(redraw?: boolean) => handleCloseDetail(redraw)}
                id={Number(selectedId)}
            />
            <DataTable
                reDraw={redrawCounter}
                url={`${process.env.REACT_APP_API_URL}/activity/showing-only`}
                columns={[
                    {
                        name: 'datetime',
                        label: 'Tanggal',
                        type: 'date',
                        filterable: true,
                        defaultOption: `${format(dateStart, 'yyyy-MM-dd')} - ${format(dateEnd, 'yyyy-MM-dd')}`,
                        render: (data: any, row: any) => {
                            return format(new Date(data), 'dd MMM yyyy HH:mm')
                        }
                    },
                    {
                        name: 'code',
                        label: 'Code',
                        filterable: true,
                        render: (data: any, row: any) => {
                            return (
                                <span
                                    className={classes.link}
                                    onClick={() => {
                                        window.history.pushState([], 'Activity Showing Detail', `/activity-showing/${row.id}`);
                                        setSelectedId(row.id);
                                        handleOpenDetail();
                                    }}
                                >
                                    {data ? data : '[KLIK]'}
                                </span>
                            );
                        }
                    },
                    {
                        name: 'verified_at',
                        label: 'Done',
                        options: [
                            { key: '', value: 'All' },
                            { key: '1', value: 'YES' },
                            { key: '0', value: 'NO' }
                        ],
                        filterable: true,
                        render: (data: number) => {
                            return data ? 'YES' : 'NO';
                        },
                    },
                    { name: 'pic_name', label: 'PIC Name', filterable: true },
                    { name: 'created_by_name', label: 'Created By', filterable: true },
                    { name: 'customer_name', label: 'Customer Name', filterable: true },
                    { name: 'apartment_name', label: 'Apartment Name', filterable: true },
                    { name: 'unit_codes', label: 'Unit', filterable: true },
                ]}
                setParentQueryString={setParentQueryString}
            />
        </Root>
    );
}

const useStyles = generateStyle((theme: Theme) => ({
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.dark,
        }
    },
}), "ActivityShowinglist")

export default ActivityShowingList;
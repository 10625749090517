
import React from 'react';
import {
    Grid,
    Paper,
    Typography,
    Divider,
    Theme,
    Box,
} from '@mui/material';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    title?: string
    titleContent?: React.ReactElement
    content: Array<any>
    additionalContent?: React.ReactElement
    apartmentDetail?: boolean
}

const useStyles = generateStyle((theme: Theme) => ({
    title: {
        fontWeight: 600
    },
    content: {
        marginTop: theme.spacing(2),
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    box: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    h6_title: {
        color: theme.palette.primary.main,
    },
    body: {
        wordBreak: 'break-word',
    },
}), "Modal_Section"
);

const ModalSection = ({
    xs,
    md,
    title,
    content,
    titleContent,
    additionalContent,
    apartmentDetail
}: IProps) => {
    const { Root, classes } = useStyles();

    const generateList = (array: Array<Array<string>>) => {
        return array.map((data: Array<string> | undefined, idx: number) => {
            if (typeof data === "undefined" || data === null) {
                return null;
            } else {
                return (
                    <Box className={classes.box} key={idx}>
                        {
                            data[0] !== '--hidden--' ?
                                <Grid>
                                    <Typography variant="body1" className={classes.title}>
                                        {data[0]}
                                    </Typography>
                                </Grid>
                                : null
                        }
                        {
                            data[1] !== '--hidden--' ?
                                <Grid style={{ marginTop: "7px" }}>
                                    <Typography variant="body2" className={classes.body}>
                                        {typeof data[1] === 'string' || data[1] === null ? (data[1] ? data[1] : "-") : data[1]}
                                    </Typography>
                                </Grid>
                                : null
                        }
                    </Box>
                )
            }
        }
        )
    }

    return (
        <Grid item md={md ? md : 12} xs={xs ? xs : 12}>
            <Root>
                {!apartmentDetail ?
                    <Paper className={classes.paper}>
                        {titleContent ?
                            titleContent
                            :
                            <Typography variant="h6" className={classes.h6_title}>
                                {title}
                            </Typography>
                        }

                        <Divider></Divider>

                        <Box className={classes.content}>
                            {(typeof content === "object") ?
                                generateList(content)
                                :
                                content
                            }
                        </Box>
                        {additionalContent}
                    </Paper> :
                    <div>
                        <Box className={classes.content}>
                            {(typeof content === "object") ?
                                generateList(content)
                                :
                                content
                            }
                            <Box className={classes.additionalContentContainer}>
                                {additionalContent}
                            </Box>
                        </Box>
                    </div>}
            </Root>
        </Grid>
    );
}

export default ModalSection;
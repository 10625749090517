import React, { useState, useEffect, useContext, Fragment, useMemo } from 'react';
import {
    Chip,
    Grid,
    BottomNavigation,
    BottomNavigationAction,
    Theme,
    Link,
    Button,
    FormControlLabel,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    MenuItem,
    Tooltip,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Typography
} from '@mui/material';

// import moment from 'moment';
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { PermissionContext } from '../../_contexts/PermissionContext';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import EnquiryModalDetail from './EnquiryModalDetail';
import LoadingScreen from '../../_components/LoadingScreen';
import WhatsappPreviewModal from '../../_components/_modal/WhatsappPreviewModal';

/**
 * Icons
*/
import AddIcon from '@mui/icons-material/Add';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { WhatsApp } from '@mui/icons-material';

/**
 * Utils
 */
import { sourceLists, mtsLists } from '../../_utils/ConstData';
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertIndonesiaPhoneNumber, generalErrorHandler, inputNumber, numberToCurrency, queryParamsToParams, renderErrorButton, renderWarningButton } from '../../_utils/Helper';
import { format, subWeeks } from 'date-fns';
import Swal from 'sweetalert2';
import { useSnackbar } from 'notistack';
import { IChats } from '../../_components/WhatsappPreview';
import { generateStyle } from '../../_utils/DefaultStyle';
// import { numberToCurrency } from '../../_utils/Helper';

interface IProps {
    enquiry_id?: string
}
interface IParams extends Record<string, string | undefined> {
    enquiry_id?: string
}

interface SearchedItem {
    apartment_name: null | string
    advisor_name: null | string
    advisor_phone: null | string
    customer_name: null | string
    unit_code: null | string
    placement_type: null | string
    created_at: null | string
}

const useStyles = generateStyle((theme: Theme) => ({
    addButton: {
        marginBottom: '20px'
    },
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        margin: '5px',
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
}), "Enquiry_list"
)

const initialChatsData = {
    chats: [],
    code: '',
    created_at: ''
}

const summaryData = ['closing', 'follow_up', 'showing', 'failed', 'cold', 'score_agent', 'score_client', 'lead_value', 'lead_value_avg']

const EnquiryList = (props: IProps) => {
    const permissions = useContext(PermissionContext)
    const navigate = useNavigate();
    const { Root, classes } = useStyles();
    const [searchValue, setSearchValue] = useState('')
    const [searchedItems, setSearchedItems] = useState<SearchedItem[]>([])
    // const [valueAdvisor, setValueAdvisor] = React.useState(0);
    // const [valueDirectEnquiry, setValueDirectEnquiry] = React.useState(0);
    // const [isRefCode, setIsRefCode] = React.useState(0);
    const [isBuy, setIsBuy] = useState(0);
    const [isListingLead, setIsListingLead] = useState(1);
    const [isMarketplace, setIsMarketplace] = useState(0);
    const [isPrioritizedArea, setIsPrioritizedArea] = useState(0);
    const params = useParams<IParams>()
    const [selectedId, setSelectedId] = useState<string>('');
    const [modalDashboardState, setModalDashboardState] = useState(false);
    const [moreDetail, setMoreDetail] = useState(false);
    const handleOpenDashboard = () => setModalDashboardState(true);
    const [chatsData, setChatsData] = useState<{
        chats: IChats[],
        code: string | null,
        created_at: string
    }>(initialChatsData)
    const [showPercentage, setShowPercentage] = useState(false)
    const [showBreakdown, setShowBreakdown] = useState(false)

    const breakdownFilter = useMemo(() => ({
        is_breakdown: showBreakdown ? "1" : "0",
    }), [showBreakdown])

    const defaultInformation = { total: 0, percentage: 0 }
    const initialState = {
        'closing': defaultInformation,
        'follow_up': defaultInformation,
        'showing': defaultInformation,
        'failed': defaultInformation,
        'cold': defaultInformation,
        'score_agent': defaultInformation,
        'score_client': defaultInformation,
        'lead_value': defaultInformation,
        'lead_value_avg': defaultInformation
    }
    const [enquiryListInformation, setEnquiryListInformation] = useState(initialState)

    const [reDraw, setReDraw] = useState(new Date().getTime())

    const dateEnd = new Date()
    const dateStart = subWeeks(new Date(), 2)

    const { enqueueSnackbar } = useSnackbar()

    const handleCloseDashboard = () => {
        setModalDashboardState(false)
        window.history.pushState([], 'Enquiry List', `/enquiry/`);
    };

    useEffect(() => {
        if (typeof params.enquiry_id !== "undefined") {
            setSelectedId(params.enquiry_id);
            handleOpenDashboard();
        }
    }, [params]);

    const [filter, setFilter] = useState({
        overdue: '',
        advisor: '',
        campaign: '',
        marketplace: '',
        is_listing_lead: '1',
        is_prioritzed_area: '',
        budget: '',
        filter_pintu_enq: ''
    });

    const specialFilter = (name: string, value: string) => {
        setFilter(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const renderScoreSummary = () => {
        const currencyScoreList = ['lead_value', 'lead_value_avg']
        const capitalizeFirstLetter = (string: string) => {
            return string.replace(/_/g, " ").toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
        }



        return (
            <>
                {summaryData.map((status, idx) => {
                    return (
                        <Fragment key={status}>
                            <Typography>
                                {capitalizeFirstLetter(status)}: {currencyScoreList.includes(status) ?
                                    enquiryListInformation[status as keyof typeof initialState].total ?
                                        numberToCurrency(enquiryListInformation[status as keyof typeof initialState].total, 'Rp. ') :
                                        '-' :
                                    enquiryListInformation[status as keyof typeof initialState].total} {['score_agent', 'score_client', 'lead_value', 'lead_value_avg'].includes(status) || !showPercentage ? "" : `(${enquiryListInformation[status as keyof typeof initialState].percentage}%)`}
                            </Typography>
                            {
                                idx !== summaryData.length - 1 ?
                                    <Typography style={{ marginRight: '10px', marginLeft: "10px" }}>
                                        |
                                    </Typography> :
                                    null
                            }
                        </Fragment>
                    )
                })}
            </>
        )
    }

    const [selectedFailId, setSelectedFailId] = useState<null | number>(null)
    const [failReason, setFailReason] = useState('')
    const [isFormLoading, setIsFormLoading] = useState(false)

    const optionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setFailReason(value)
    }

    const handleSubmitFail = () => {
        setIsFormLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/enquiry/change-status`, {
            id: selectedFailId,
            status: 'fail',
            failed_reason: failReason,
        })
            .then(res => res.data)
            .then(_ => {
                Swal.fire({
                    title: "Enquiry berhasil ditandai Fail",
                    icon: 'success',
                    onAfterClose: () => {
                        setSelectedFailId(null)
                        setFailReason('')
                        setReDraw(new Date().getTime())
                    },
                    timer: 1000
                })
            })
            .catch(generalErrorHandler)
            .finally(() => setIsFormLoading(false))

    }

    const handleExport = () => {
        setIsFormLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/enquiry/export-failed`, queryParamsToParams())
            .then(res => {
                window.open(`${process.env.REACT_APP_API_URL}/enquiry/export-failed-file?code=${res.data}`, '_blank');
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setIsFormLoading(false);
            })
    }

    const [parentQueryString, setParentQueryString] = useState('')

    const onChangeStatus = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
        const newStatus = e.target.value

        if (newStatus === 'fail') {
            setSelectedFailId(id)
        } else {
            renderWarningButton('Apakah anda yakin ingin mengganti status ini?')
                .then(({ value }) => {
                    if (value) {
                        setIsFormLoading(true)

                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/enquiry/change-status`, {
                            id,
                            status: newStatus,
                        })
                            .then(res => res.data)
                            .then(_ => {
                                setReDraw(new Date().getTime())
                                enqueueSnackbar('Berhasil mengganti status', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
                            })
                            .catch(e => {
                                generalErrorHandler(e)
                                enqueueSnackbar('Gagal mengganti status', { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
                            })
                            .finally(() => setIsFormLoading(false))
                    }
                })
        }
    }

    const handleSearch = () => {
        if (searchValue) {
            setIsFormLoading(true);
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/enquiry/search-by-phone`, { phone: searchValue })
                .then(res => res.data)
                .then(data => {
                    setSearchedItems(data)
                })
                .catch(err => {
                    if (err.response.status === 404) {
                        renderErrorButton('Tidak menemukan customer dengan nomor HP tersebut')
                    } else {
                        generalErrorHandler(err)
                    }
                })
                .finally(() => { setIsFormLoading(false); });
        }
    }

    const handleWhatsappPreview = (row: any) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/lead`, { params: { id: row.id } })
            .then(res => res.data)
            .then(res => {
                setChatsData({
                    chats: res,
                    code: row.code,
                    created_at: row.created_at
                })
            })
            .catch(err => generalErrorHandler(err))
    }

    const handleWhatsappPreviewOwner = (row: any) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/lead-owner`, { params: { id: row.id } })
            .then(res => res.data)
            .then(res => {
                setChatsData({
                    chats: res,
                    code: row.code,
                    created_at: row.created_at
                })
            })
            .catch(err => generalErrorHandler(err))
    }


    return (
        <Root>
            <EnquiryModalDetail
                open={modalDashboardState}
                onClose={handleCloseDashboard}
                enquiry_id={selectedId}
                permissions={permissions}
            />

            <LoadingScreen open={isFormLoading} />

            <Dialog
                open={searchedItems.length > 0}
                onClose={() => setSearchedItems([])}
                fullWidth
                maxWidth="lg"
            >
                <Root>
                    <DialogTitle>Lead Detail</DialogTitle>
                    <DialogContent>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Tanggal
                                        </TableCell>
                                        <TableCell>
                                            Name
                                        </TableCell>
                                        <TableCell>
                                            Apartemen
                                        </TableCell>
                                        <TableCell>
                                            Unit
                                        </TableCell>
                                        <TableCell>
                                            PM
                                        </TableCell>
                                        <TableCell>
                                            PM Phone
                                        </TableCell>
                                        <TableCell>
                                            Placement Type
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        searchedItems.map((item, index) =>
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {item.created_at ? format(new Date(item.created_at), 'dd-MM-yyyy HH:mm:ss') : '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {item.customer_name || '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {item.apartment_name || '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {item.unit_code || '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {item.advisor_name || '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        item.advisor_phone ?
                                                            <>
                                                                <span style={{ marginRight: 4 }}>
                                                                    {62 + item.advisor_phone}
                                                                </span>
                                                                <IconButton
                                                                    href={`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(62 + item.advisor_phone)}`}
                                                                    target="_blank"
                                                                    rel='noopener noreferrer'
                                                                >
                                                                    <WhatsApp color='success' />
                                                                </IconButton>
                                                            </>
                                                            :
                                                            '-'
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {item.placement_type || '-'}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </Root>
            </Dialog>

            <Dialog
                open={selectedFailId !== null}
                onClose={() => {
                    setSelectedFailId(null)
                    setFailReason('')
                }}
                maxWidth={'xs'}
                fullWidth
            >
                <Root>
                    <DialogTitle>Tandai sebagai Fail</DialogTitle>
                    <DialogContent>
                        <TextField
                            select
                            variant="outlined"
                            onChange={optionChange}
                            name={'failReason'}
                            value={failReason}
                            size="small"
                            style={{ width: "100%" }}
                            label="Alasan gagal"
                        >
                            {failReasons.map(failReason => <MenuItem key={failReason.key} value={failReason.key}>{failReason.value}</MenuItem>)}
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setSelectedFailId(null)
                            setFailReason('')
                        }} color="secondary" autoFocus>
                            Batal
                        </Button>
                        <Button disabled={failReason === ''} onClick={handleSubmitFail} color="primary" autoFocus>
                            Konfirm
                        </Button>
                    </DialogActions>
                </Root>
            </Dialog>

            {
                permissions['enquiry.create'] &&
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.addButton}
                    startIcon={<AddIcon />}
                    onClick={() => navigate('/enquiry/create')}

                >
                    Add Enquiry
                </Button>
            }

            {
                permissions['enquiry.manage'] &&
                <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    style={{ float: 'right' }}
                    onClick={handleExport}
                >
                    Export Failed Enquiry
                </Button>
            }

            <Grid container spacing={3} style={{ marginBottom: '12px' }}>
                <Grid item xs={9} sm={4} md={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Search"
                        value={inputNumber(searchValue)}
                        placeholder="Input phone here"
                        onChange={(e) => setSearchValue(inputNumber(e.target.value))}
                    />
                </Grid>
                <Grid item xs={2} sm={4} md={6} className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>

            {permissions['enquiry.see-breakdown'] && showBreakdown &&
                <Grid container>
                    <Grid item xs={12} display="flex" flexDirection="row" justifyContent="center">
                        {renderScoreSummary()}
                    </Grid>
                </Grid>
            }

            <Grid container>
                <Grid item xs={12} style={{ marginTop: "5px" }}>
                    {/* <BottomNavigation
                    value={valueAdvisor}
                    onChange={(event, newValue) => {
                        setValueAdvisor(newValue);
                    }}
                    showLabels
                    className={classes.filter}
                >
                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('advisor', '')} label="All" />
                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('advisor', '1')} label="Advisor" />
                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('advisor', '2')} label="Wings / Fire Wings" />
                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('advisor', '3')} label="Others" />
                </BottomNavigation> */}

                    <BottomNavigation
                        value={isBuy}
                        onChange={(event, newValue) => {
                            setIsBuy(newValue);
                        }}
                        showLabels
                        className={classes.filter}
                    >
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_buy', '')} label="All" />
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_buy', '0')} label="Sewa" />
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_buy', '1')} label="Jual" />
                    </BottomNavigation>

                    <BottomNavigation
                        value={isPrioritizedArea}
                        onChange={(event, newValue) => {
                            setIsPrioritizedArea(newValue);
                        }}
                        showLabels
                        className={classes.filter}
                    >
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_prioritized_area', '')} label="All" />
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_prioritized_area', '1')} label="Priority Area" />
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_prioritized_area', '0')} label="Non Priority Area" />
                    </BottomNavigation>

                    {/* <BottomNavigation
                    value={valueDirectEnquiry}
                    onChange={(event, newValue) => {
                        setValueDirectEnquiry(newValue);
                    }}
                    showLabels
                    className={classes.filter}
                >
                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_direct_enquiry', '')} label="All" />
                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_direct_enquiry', '1')} label="Direct Enquiry" />
                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_direct_enquiry', '0')} label="Non Direct Enquiry" />
                </BottomNavigation>

                <BottomNavigation
                    value={isRefCode}
                    onChange={(event, newValue) => {
                        setIsRefCode(newValue);
                    }}
                    showLabels
                    className={classes.filter}
                >
                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_ref_code', '')} label="All" />
                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_ref_code', '1')} label="Enquiry With Ref Code" />
                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_ref_code', '0')} label="Enquiry Without Ref Code" />
                </BottomNavigation> */}

                    <BottomNavigation
                        value={isMarketplace}
                        onChange={(event, newValue) => {
                            setIsMarketplace(newValue);
                        }}
                        showLabels
                        className={classes.filter}
                    >
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_marketplace', '')} label="All" />
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_marketplace', '1')} label="Marketplace" />
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_marketplace', '0')} label="Non Marketplace" />
                    </BottomNavigation>

                    <BottomNavigation
                        value={isListingLead}
                        onChange={(event, newValue) => {
                            setIsListingLead(newValue);
                        }}
                        showLabels
                        className={classes.filter}
                    >
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_listing_lead', '')} label="All" />
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_listing_lead', '1')} label="Apartment" />
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => specialFilter('is_listing_lead', '0')} label="Non Apartment" />
                    </BottomNavigation>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end">
                {
                    permissions['enquiry.see-utm-detail'] &&
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={moreDetail}
                                    onChange={() => { setMoreDetail(!moreDetail) }}
                                    color="primary"
                                />
                            }
                            label="More Detail"
                        />
                    </Grid>
                }
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showBreakdown}
                                onChange={() => {
                                    setShowBreakdown(prev => !prev)
                                }}
                                color="primary"
                            />
                        }
                        label="Show Summary"
                    />
                </Grid>
            </Grid>

            <DataTable
                reDraw={reDraw}
                url={`${process.env.REACT_APP_API_URL}/enquiry`}
                setParentQueryString={setParentQueryString}
                customParams={filter}
                columns={[
                    {
                        name: 'created_at',
                        label: 'Date',
                        type: 'date',
                        filterable: true,
                        sortable: true,
                        defaultOption: `${format(dateStart, 'yyyy-MM-dd')} - ${format(dateEnd, 'yyyy-MM-dd')}`,
                        render: (data: any, row: any) => {
                            return format(new Date(data), 'dd MMM yyyy HH:mm:ss')
                        }
                    },
                    {
                        name: 'code',
                        label: 'Code',
                        type: 'string',
                        filterable: true,
                        style: { minWidth: '200px' },
                        render: (data: any, row: any) => {
                            return (
                                <Link
                                    style={{ cursor: "pointer" }}
                                    component={RouterLink}
                                    to={`/enquiry/${row.id}`}
                                >
                                    {row.code}
                                </Link>
                            );
                        }
                    },
                    ...(
                        permissions['whatsapp.manage'] ?
                            [{
                                name: 'wa_id',
                                label: 'Whatsapp Preview',
                                type: 'string',
                                filterable: true,
                                options: [
                                    {
                                        key: '',
                                        value: 'All',
                                    },
                                    {
                                        key: 1,
                                        value: 'Yes',
                                    },
                                    {
                                        key: 0,
                                        value: 'No',
                                    },
                                ],
                                render: (data: any, row: any) => {
                                    if (data) {
                                        return (
                                            <>
                                                <Tooltip title="Whatsapp Client">
                                                    <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreview(row)} color='primary'>
                                                        <WhatsAppIcon />
                                                    </IconButton>
                                                </Tooltip>

                                                {row.unit_code &&
                                                    <Tooltip title="Whatsapp Owner">
                                                        <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreviewOwner(row)} color='primary'>
                                                            <WhatsAppIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </>
                                        )

                                    } else {
                                        return '-'
                                    }
                                }
                            }]
                            : []
                    ),
                    ...(
                        permissions['whatsapp.manage'] ?
                            [{
                                name: 'response_time',
                                label: 'Response Time',
                                type: 'string',
                                filterable: true,
                                options: [
                                    {
                                        key: '',
                                        value: 'All',
                                    },
                                    {
                                        key: 3,
                                        value: 'Bad (>= 15 min.)',
                                    },
                                    {
                                        key: 2,
                                        value: 'OK (10 - 15 min.)',
                                    },
                                    {
                                        key: 1,
                                        value: 'Good (< 10 min.)',
                                    },
                                ],
                                render: (data: any, row: any) => {
                                    let color: "primary" | "success" | "warning" | "error" = "primary";

                                    if (data) {
                                        if (data < 600) {
                                            color = "success";
                                        } else if (data < 900) {
                                            color = "warning";
                                        } else if (data >= 900) {
                                            color = "error";
                                        } else {
                                            return "-";
                                        }

                                        const time = data < 60 ? `${data} sec` : `${Math.floor(data / 60)} min`;

                                        return <Chip
                                            color={color}
                                            label={time}
                                        />;
                                    } else {
                                        return "-";
                                    }
                                }
                            },
                            {
                                name: 'objective_score',
                                label: 'Objective Score Client',
                                type: 'string',
                                defaultOption: '',
                                options: [
                                    {
                                        key: '',
                                        value: 'All',
                                    },
                                    {
                                        key: 0,
                                        value: 'Bad  (<60)',
                                    },
                                    {
                                        key: 1,
                                        value: 'OK  (60-69)',
                                    },
                                    {
                                        key: 2,
                                        value: 'Good  (>=70)',
                                    },
                                ],
                                filterable: true,
                                render: (data: any) => {
                                    if (data === null) return '-'

                                    let color: "primary" | "success" | "warning" | "error" = "primary";

                                    if (data >= 70) {
                                        color = "success";
                                    } else if (data >= 60) {
                                        color = "warning";
                                    } else {
                                        color = "error";
                                    }

                                    return <Chip
                                        color={color}
                                        label={data}
                                    />;
                                }
                            },
                            {
                                name: 'objective_score_agent',
                                label: 'Objective Score Agent',
                                type: 'string',
                                defaultOption: '',
                                options: [
                                    {
                                        key: '',
                                        value: 'All',
                                    },
                                    {
                                        key: 0,
                                        value: 'Bad  (<60)',
                                    },
                                    {
                                        key: 1,
                                        value: 'OK  (60-69)',
                                    },
                                    {
                                        key: 2,
                                        value: 'Good  (>=70)',
                                    },
                                ],
                                filterable: true,
                                render: (data: any) => {
                                    if (data === null) return '-'

                                    let color: "primary" | "success" | "warning" | "error" = "primary";

                                    if (data >= 70) {
                                        color = "success";
                                    } else if (data >= 60) {
                                        color = "warning";
                                    } else {
                                        color = "error";
                                    }

                                    return <Chip
                                        color={color}
                                        label={data}
                                    />;
                                }
                            },
                            ]
                            : []
                    ),

                    ...(
                        parentQueryString.includes('status=fail')
                            ? [
                                {
                                    name: 'failed_reason_label',
                                    label: 'Failed Reason',
                                    filterable: true,
                                    type: 'string',
                                    defaultOption: '',
                                    options: failReasons,
                                    render: (data: any) => {
                                        return data || '-';
                                    },
                                    style: {
                                        minWidth: '200px'
                                    },
                                },
                            ]
                            : []
                    ),
                    ...(
                        +isMarketplace === 1
                            ? [
                                {
                                    name: 'marketplace_from',
                                    label: 'Marketplace',
                                    filterable: true,
                                    type: 'string',
                                    render: (data: any) => {
                                        return data || '-';
                                    }
                                },
                            ]
                            : []
                    ),
                    { name: 'customer_name', label: 'Client Name', filterable: true, },
                    {
                        name: 'status',
                        label: 'Status',
                        filterable: true,
                        type: 'string',
                        defaultOption: '',
                        options: statusOptions,
                        style: {
                            minWidth: '200px'
                        },
                        render: (data: any, row: any) => {
                            if (!permissions['enquiry.change-status'] || ['closing', 'fail'].includes(data)) return row.status_label
                            return (
                                <>
                                    <TextField
                                        select
                                        value={data}
                                        onChange={e => onChangeStatus(e, row.id)}
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem key={option.key} value={option.key}>
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </>
                            )
                        },
                        onOptionChange: (value, setOptionQuery) => {
                            if (value !== 'fail') {
                                setOptionQuery({ type: 'SET_ITEM', name: 'failed_reason', value: '' })
                            }
                        },
                    },
                    {
                        name: 'source_label',
                        label: 'Source',
                        filterable: true,
                        type: 'string',
                        defaultOption: '',
                        options: [
                            { key: '', value: 'All' },
                            ...sourceLists,
                        ],
                        render: (data: any) => {
                            return data || '-';
                        },
                    },
                    {
                        name: 'mts_label',
                        label: 'Manual Tracking Source',
                        filterable: true,
                        type: 'string',
                        defaultOption: '',
                        options: [
                            { key: '', value: 'All' },
                            ...mtsLists,
                        ],
                        render: (data: any) => {
                            return data || '-';
                        },
                    },
                    {
                        name: 'page_source',
                        label: 'Page Source',
                        type: 'string',
                        filterable: true,
                        options: [
                            {
                                key: '',
                                value: 'All',
                            },
                            {
                                key: 0,
                                value: 'Direct Website',
                            },
                            {
                                key: 1,
                                value: 'SLP',
                            },
                        ],
                        render: (data: number, row: any) => (data === 0 ? 'Direct Website' : 'SLP')
                    },
                    ...(
                        parentQueryString.includes('status=fail')
                            ? [
                                {
                                    name: 'failed_reason_label',
                                    label: 'Failed Reason',
                                    filterable: true,
                                    type: 'string',
                                    defaultOption: '',
                                    options: failReasons,
                                    render: (data: any) => {
                                        return data || '-';
                                    },
                                    style: {
                                        minWidth: '200px'
                                    },
                                },
                            ]
                            : []
                    ),
                    {
                        name: 'area_name',
                        label: 'Area',
                        filterable: true,
                        type: 'string'
                    },
                    { name: 'district_name', label: 'District', filterable: true, type: 'string' },
                    ...(
                        +isMarketplace === 1
                            ? [
                                {
                                    name: 'marketplace_from',
                                    label: 'Marketplace',
                                    filterable: true,
                                    type: 'string',
                                    render: (data: any) => {
                                        return data || '-';
                                    }
                                },
                            ]
                            : []
                    ),
                    { name: 'apartment_name', label: 'Apartment', filterable: true, style: { minWidth: '270px' } },
                    { name: 'unit_code', label: 'Kode Unit', filterable: true, type: 'string' },
                    { name: 'estimated_rent_period', label: 'Durasi (Bulan)', filterable: true },
                    { name: 'member_name', label: 'Member', filterable: true, style: { minWidth: '180px' } },
                    { name: 'placement_type', label: 'Placement', filterable: true, style: { minWidth: '180px' } },
                    ...(
                        moreDetail ? [
                            {
                                name: 'utm_source',
                                label: 'UTM Source',
                                type: 'string',
                                filterable: true
                            },
                            {
                                name: 'utm_medium',
                                label: 'UTM Medium',
                                type: 'string',
                                filterable: true
                            },
                            {
                                name: 'utm_campaign',
                                label: 'UTM Campaign',
                                type: 'string',
                                filterable: true
                            },
                            {
                                name: 'utm_content',
                                label: 'UTM Content',
                                type: 'string',
                                filterable: true
                            },
                            {
                                name: 'keyword',
                                label: 'Keyword',
                                type: 'string',
                                filterable: true
                            },
                            {
                                name: 'adsetname',
                                label: 'Adsetname',
                                type: 'string',
                                filterable: true
                            },
                            {
                                name: 'adgroupid',
                                label: 'Adgroupid',
                                type: 'string',
                                filterable: true
                            },
                            {
                                name: 'creative',
                                label: 'Creative',
                                type: 'string',
                                filterable: true
                            },
                            {
                                name: 'lead_value',
                                label: 'Lead Value',
                                type: 'number',
                                filterable: true,
                                style: { minWidth: 120 },
                                render: (data: number | string) => {
                                    return data ? numberToCurrency(data, 'Rp. ') : '-'
                                }
                            },
                        ] : []
                    ),
                    // {
                    //     name: 'status',
                    //     label: 'Status',
                    //     type: 'string',
                    //     filterable: true,
                    //     sortable: true,
                    //     options: [
                    //         {
                    //             key: '',
                    //             value: 'All'
                    //         },
                    //         {
                    //             key: '1',
                    //             value: 'Unqualified'
                    //         },
                    //         {
                    //             key: '2',
                    //             value: 'Belum Dikerjakan'
                    //         },
                    //         {
                    //             key: '3',
                    //             value: 'Proses Cari Unit'
                    //         },
                    //         {
                    //             key: '4',
                    //             value: 'Sudah Ada Unit'
                    //         },
                    //         {
                    //             key: '5',
                    //             value: 'Tunggu Showing'
                    //         },
                    //         {
                    //             key: '6',
                    //             value: 'Follow Up'
                    //         },
                    //         {
                    //             key: '7',
                    //             value: 'Jadwal Masuk Masih Lama'
                    //         },
                    //         {
                    //             key: '8',
                    //             value: 'Sukses'
                    //         },
                    //         {
                    //             key: '9',
                    //             value: 'Gagal'
                    //         },
                    //     ]
                    // },
                    // {
                    //     name: 'listing_type_id',
                    //     label: 'Type',
                    //     type: 'string',
                    //     filterable: true,
                    //     sortable: true,
                    //     render: (data: any, row: any) => {
                    //         let return_text = "";
                    //         switch (data) {
                    //             case 0:
                    //                 return_text = "Apartemen";
                    //                 break;
                    //             case 2:
                    //                 return_text = "Rumah";
                    //                 break;
                    //             case 3:
                    //                 return_text = "Tanah";
                    //                 break;
                    //             case 4:
                    //                 return_text = "Ruko";
                    //                 break;
                    //             case 5:
                    //                 return_text = "Kantor";
                    //                 break;
                    //             case 6:
                    //                 return_text = "Gudang";
                    //                 break;
                    //             case 7:
                    //                 return_text = "Ruang Usaha";
                    //                 break;
                    //         }
                    //         return return_text;
                    //     },
                    //     options: [
                    //         {
                    //             key: '',
                    //             value: 'All'
                    //         },
                    //         {
                    //             key: '0',
                    //             value: 'Apartment'
                    //         },
                    //         {
                    //             key: '2',
                    //             value: 'Rumah'
                    //         },
                    //         {
                    //             key: '3',
                    //             value: 'Tanah'
                    //         },
                    //         {
                    //             key: '4',
                    //             value: 'Ruko'
                    //         },
                    //         {
                    //             key: '5',
                    //             value: 'Kantor'
                    //         },
                    //         {
                    //             key: '6',
                    //             value: 'Gudang'
                    //         },
                    //     ]
                    // },
                    // ...(
                    //     permissions['enquiry.see-utm-detail']
                    //         ? [
                    //             {
                    //                 name: 'utm_source', label: 'UTM Source', filterable: true
                    //             }
                    //         ]
                    //         : []
                    // ),
                ]}
                responseCallback={(res: any, seeTotal: boolean) => {
                    if (res?.meta?.current_page === 1) {
                        const checkNominalPercentage = (nominal: number, total: number) => {
                            return (total && nominal) ? (nominal / total * 100).toFixed(2) : 0
                        }
                        const data: any = {};
                        summaryData.forEach((status) => {
                            data[status as keyof typeof initialState] = {
                                total: res[status as keyof typeof initialState] || 0,
                                percentage: checkNominalPercentage(res[status as keyof typeof initialState], res.total)
                            }
                        })
                        setEnquiryListInformation(data);
                        setShowPercentage(seeTotal)
                    }
                }}
                customParamsFromFirstPage={breakdownFilter}
            />

            <WhatsappPreviewModal
                open={!!chatsData.chats.length}
                chats={chatsData.chats}
                code={chatsData.code}
                created_at={chatsData.created_at}
                onClose={() => {
                    setChatsData(initialChatsData)
                }}
            />
        </Root >
    );
}

export default EnquiryList;

const statusOptions = [
    {
        key: '',
        value: 'All'
    },
    {
        key: 'greeting',
        value: 'Greeting'
    },
    {
        key: 'kyc',
        value: 'KYC'
    },
    {
        key: 'showing',
        value: 'Showing'
    },
    {
        key: 'fu',
        value: 'Followup'
    },
    {
        key: 'closing',
        value: 'Closing'
    },
    {
        key: 'fail',
        value: 'Failed'
    },
    {
        key: 'cold',
        value: 'Cold'
    },
]

const failReasons = [
    {
        key: '',
        value: 'Pilih alasan gagal'
    },
    {
        key: 'short_duration',
        value: 'Durasi Pendek',
    },
    {
        key: 'low_budget',
        value: 'Budget Rendah',
    },
    {
        key: 'client_no_response',
        value: 'Klien tidak ada Respon',
    },
    {
        key: 'cancel_search_unit',
        value: 'Cancel Cari Unit',
    },
    {
        key: 'closing_other_agent',
        value: 'Closing dengan agent lain',
    },
    {
        key: 'duplicate',
        value: 'Duplicate Leads',
    },
    {
        key: 'invalid_number',
        value: 'Nomor Invalid',
    },
]
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { addDays } from 'date-fns';
import Swal from 'sweetalert2';

/**
 * Components
 */
import TerminModalForm from './TerminModalForm';
import LoadingScreen from '../../../_components/LoadingScreen';

/**
 * Utils
 */
import { convertNumber, generalErrorHandler, renderWarningButton } from '../../../_utils/Helper';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
  fetchDetail: Function;
  closing_id: string;
  closing_code: string;
}

interface ITermin {
  id: number;
  code: string;
  closing_id: string;
  amount: number;
  termin_period: string;
  start_date: string;
  end_date: string;
  paid_date: string;
}

const useStyles = generateStyle(theme => ({
  table: {
    minWidth: '100%',
  },
}), "TerminList");

const TerminList = (props: Props) => {
  const { Root, classes } = useStyles();

  const [termins, setTermins] = useState<Array<ITermin>>([]);
  const [terminId, setTerminId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialTermin = {
    start_date: new Date(),
    end_date: new Date(),
    amount: '',
    basic_price: '',
  };

  const [termin, setTermin] = useState(initialTermin);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = (fetch: boolean) => {
    setTerminId('');
    setModalOpen(false);
    if (fetch) {
      props.fetchDetail();
      loadTermins();
    }
  }

  useEffect(() => {
    loadTermins();
    // eslint-disable-next-line
  }, [props.closing_id]);

  const isEditable = () => {
    return termins.filter(item => item.code).length < 2;
  }

  const loadTermins = () => {
    DefaultAxios.get(`${process.env.REACT_APP_API_URL}/new-closing/${props.closing_id}/termin`)
      .then(res => {
        const response_data: Array<ITermin> = res.data;
        setTermins(response_data);

        setTermin({
          start_date: addDays(new Date(response_data[response_data.length - 1].end_date), 1),
          end_date: addDays(new Date(response_data[response_data.length - 1].end_date), 2),
          amount: '',
          basic_price: '',
        });
      })
  }

  const handleEdit = (terminData: any) => {
    handleModalOpen();
    setTerminId(String(terminData.id));
    setTermin({
      start_date: new Date(terminData.start_date),
      end_date: new Date(terminData.end_date),
      amount: String(terminData.amount),
      basic_price: String(terminData.basic_price),
    });
  }

  const handleDelete = (id: number) => {
    renderWarningButton('Apakah anda yakin ingin menghapus termin ini?')
      .then((result) => {
        if (result.value) {
          setIsLoading(true);
          DefaultAxios
            .delete(`${process.env.REACT_APP_API_URL}/new-closing/${props.closing_id}/termin/${id}`)
            .then(() => {
              Swal.fire({
                title: "Berhasil menghapus",
                icon: 'success',
                onAfterClose: loadTermins,
                timer: 1000
              })
              props.fetchDetail();
            })
            .catch(error => {
              generalErrorHandler(error)
            })
            .finally(() => {
              setIsLoading(false);
            })
        }
      })
  }

  return (
    <Root style={{ width: '100%' }}>
      <LoadingScreen open={isLoading} fullScreen />
      <TerminModalForm
        open={modalOpen}
        onClose={handleModalClose}
        step={termins.length + 1}
        closingId={props.closing_id}
        terminId={terminId}
        termin={termin}
        minDate={typeof termins[termins.length - 1] !== 'undefined' ? addDays(new Date(termins[termins.length - 1].end_date), 1) : new Date()}
      />

      <Grid container style={{ width: '100%' }}>
        <Grid item style={{ width: '100%' }}>
          {
            isEditable() &&
            <Button
              variant="contained"
              color="primary"
              style={{ marginBottom: '20px', float: 'right' }}
              onClick={handleModalOpen}
            >
              Add Termin
            </Button>
          }

          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Periode Termin</TableCell>
                  <TableCell>Tanggal Pembayaran</TableCell>
                  <TableCell>Jumlah Pembayaran</TableCell>
                  <TableCell>Kode Closing</TableCell>
                  <TableCell>Action</TableCell>
                  {/* <TableCell>Paid</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {termins.map((row, key) => (
                  <TableRow key={row.start_date}>
                    <TableCell>{row.termin_period}</TableCell>
                    <TableCell>{row.paid_date !== null ? row.paid_date : "-"}</TableCell>
                    <TableCell>{convertNumber(row.amount.toString())}</TableCell>
                    <TableCell>
                      {row.code !== props.closing_code ?
                        <Link to={{ pathname: `/closing/${row.code}` }}>
                          {row.code}
                        </Link>
                        :
                        row.code === null ? '-' : row.code
                      }
                    </TableCell>
                    <TableCell>
                      {
                        (key !== 0 && row.code === null && key === termins.length - 1 && isEditable()) &&
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginRight: '10px' }}
                            onClick={() => handleEdit(row)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => handleDelete(row.id)}
                          >
                            Delete
                          </Button>
                        </>
                      }
                    </TableCell>
                    {/* <TableCell>
                      {row.code !== null ? 'Yes' : 'No'}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Root>
  );
}

export default TerminList;
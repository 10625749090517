import React from 'react';
import { Button, Typography, Table, TableContainer, TableBody, TableCell, TableRow, Paper } from '@mui/material';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

const ButtonAndTitle = ({ title, url }: { title: string, url: string }) => {
    const handleDownload = (url: string) => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/tools/download-report-tr/${url}`, { responseType: 'blob' })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${title.replace('Download', '').trim()}.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                generalErrorHandler(err);
            });
    }

    return (
        <TableRow>
            <TableCell style={{ width: "10%" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDownload(url)}
                >
                    Download
                </Button>
            </TableCell >
            <TableCell align="left" style={{ width: "90%" }}>
                <Typography variant="h5">
                    {title}
                </Typography>
            </TableCell>

        </TableRow>
    )
}

const DownloadReportTR = () => {
    return (
        <>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableBody>
                        <ButtonAndTitle
                            title="Active Tenant"
                            url="active-tenant"
                        />
                        <ButtonAndTitle
                            title="Expired Closing Bulan ini"
                            url="expire-this-month"
                        />
                        <ButtonAndTitle
                            title="Pelunasan Bulan Ini"
                            url="payment-this-month"
                        />
                        <ButtonAndTitle
                            title="Pelunasan Bulan Sebelumnya"
                            url="payment-last-month"
                        />

                        <ButtonAndTitle
                            title="Deposit Bulan Ini"
                            url="deposit-this-month"
                        />

                        <ButtonAndTitle
                            title="Checkout Bulan Ini"
                            url="checkout-this-month"
                        />

                        <ButtonAndTitle
                            title="Checkout Bulan Sebelumnya"
                            url="checkout-last-month"
                        />

                        <ButtonAndTitle
                            title="Tiket Deposit Bulan Ini"
                            url="ticket-deposit-this-month"
                        />

                        <ButtonAndTitle
                            title="Tiket Deposit Bulan Sebelumnya"
                            url="ticket-deposit-last-month"
                        />

                        <ButtonAndTitle
                            title="Reimburse dan Checkout Bulan Sebelumnya"
                            url="reimburse-checkout-last-month"
                        />

                        <ButtonAndTitle
                            title="Reimburse dan Checkout Bulan Ini"
                            url="reimburse-checkout-this-month"
                        />

                        <ButtonAndTitle
                            title="Ticket Done tipe Greeting dan Lainnya Bulan Sebelumnya"
                            url="finished-ticket-greeting-and-other-last-month"
                        />

                        <ButtonAndTitle
                            title="Ticket Done tipe Greeting dan Lainnya Bulan Ini"
                            url="finished-ticket-greeting-and-other-this-month"
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default DownloadReportTR;
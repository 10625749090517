
import React from 'react';
import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Toolbar,
    Typography,
    Theme,
    Slide,
    AppBarProps,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    open: boolean
    title: string | React.ReactNode
    isLoading: boolean
    appBarProps?: AppBarProps
    onCloseModal: Function
    showEditButton?: boolean
    editButtonLink?: string | Function
    customRightButton?: React.ReactNode
    children: React.ReactElement
    stickyHeader?: boolean
    apartmentDetail?: boolean
    /**
     * Show loading as overlay instead of replacing the page content
     */
    useOverlayLoading?: boolean
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        backgroundColor: "#f3f3f3",
    },
}), "Modal_Detail"
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalDetail = ({
    title,
    open,
    isLoading,
    appBarProps,
    onCloseModal,
    showEditButton,
    editButtonLink,
    children,
    stickyHeader,
    apartmentDetail,
    customRightButton,
    useOverlayLoading,
}: IProps) => {
    const { Root, classes } = useStyles();
    const navigate = useNavigate();

    return (
        <Dialog
            fullScreen={apartmentDetail ? false : true}
            maxWidth={apartmentDetail ? "lg" : undefined}
            TransitionComponent={Transition}
            open={open}
            onClose={() => onCloseModal()}
        >
            <Root>
                <AppBar className={stickyHeader ? '' : classes.appBar} {...appBarProps}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => onCloseModal()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                        {showEditButton &&
                            (
                                typeof editButtonLink === "string" ?
                                    <Button autoFocus color="inherit" variant='outlined' onClick={() => navigate(editButtonLink ? editButtonLink : "")}>
                                        Edit
                                    </Button>
                                    :
                                    typeof editButtonLink === "function" &&
                                    <Button autoFocus color="inherit" variant="outlined" onClick={() => editButtonLink()}>
                                        Edit
                                    </Button>
                            )
                        }
                        {customRightButton}
                    </Toolbar>
                </AppBar>


                {(isLoading && !useOverlayLoading) ?
                    <LoadingScreen open={isLoading} />
                    :
                    <>
                        {
                            (isLoading && useOverlayLoading) ?
                                <LoadingScreen open={isLoading} fullScreen />
                                : null
                        }
                        <DialogContent style={{ backgroundColor: "#f5f7fb" }} sx={{ mt: stickyHeader ? 8 : 0 }}>
                            <Grid container spacing={3}>
                                {children}
                            </Grid>
                        </DialogContent>
                    </>
                }
            </Root>
        </Dialog>
    );
}

export default ModalDetail;
import React, { useContext, useReducer, useState } from 'react';
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import {
    Grid,
    Typography,
    TextField,
    Button,
    Theme,
    Switch,
} from '@mui/material';
import Swal from 'sweetalert2';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    devider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    title: {
        marginLeft: theme.spacing(2),
        marginBottom: '5px',
        flex: 1,
    },
    gridSubmit: {
        textAlign: 'left',
        marginBottom: '10px'
    },
    submit_button: {
        marginRight: '8px'
    },
    keyLogTable: {
        width: '100%',
        borderCollapse: 'collapse',
        '& th, td': {
            padding: '8px',
            border: '1px solid #ddd'
        },
        '& img': {
            cursor: 'pointer'
        }
    },
}), "UnitKeyLogsList"
)

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IState {
    unit_code: string
}

interface IKeyLogState {
    is_entrusted_key: boolean;
    key_holder: string;
    apartment_name: string;
    unit_name: string;
    logs: IKeyLog[];
}

interface IKeyLog {
    date: string;
    description: string;
    id: number;
    key_manager_name: string;
    name: string;
    status: number;
}

interface IStateData {
    apartment_code: string,
    apartment_name: string
    unit_number: string,
    floor: string,
    tower: string,
}

interface IErrorState {
    unit_code: string
}

interface IErrorStateData {
    apartment_code: string,
    apartment_name: string
    unit_number: string,
    floor: string,
    tower: string,
}

interface IErrorAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const UnitKeyLogsList = () => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const [loading, setLoading] = useState(false);
    // const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const keyLogStatus = [
        'Dikembalikan',
        'Dipinjam',
        'Ditransfer',
        'Direlease',
        'Custom'
    ];

    const initialKeyLogState: IKeyLogState = {
        is_entrusted_key: false,
        key_holder: '',
        apartment_name: '',
        unit_name: '',
        logs: []
    }
    const [keyLogState, setKeyLogState] = useState<IKeyLogState>(initialKeyLogState);
    // const API_URL = process.env.REACT_APP_API_URL + '/unit-key';
    const [apiTable, setApiTable] = useState(`${process.env.REACT_APP_API_URL}/unit?code=null&floor_level=null&tower=null&unit_number=null`);
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                unit_code: ''
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }
        return { ...state };
    };

    const inputReducerData = (stateData: IStateData, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...stateData,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                apartment_code: '',
                apartment_name: '',
                unit_number: '',
                floor: '',
                tower: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newStateData = action.value;
            return { ...newStateData as IStateData };
        }
        return { ...stateData };
    };

    const errorReducer = (stateData: IErrorStateData, action: IErrorAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...stateData,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                apartment_code: '',
                apartment_name: '',
                unit_number: '',
                floor: '',
                tower: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newStateData = action.value;
            return { ...newStateData as IErrorStateData };
        }

        return { ...stateData };
    };

    const errorReducerUnit = (state: IErrorState, action: IErrorAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                unit_code: ''
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        unit_code: ''
    });

    const [inputStateData, setInputStateData] = useReducer(inputReducerData, {
        apartment_code: '',
        apartment_name: '',
        unit_number: '',
        floor: '',
        tower: '',
    });

    const [errorState, setErrorState] = useReducer(errorReducerUnit, {
        unit_code: ''
    });

    const [errorStateData, setErrorStateData] = useReducer(errorReducer, {
        apartment_code: '',
        apartment_name: '',
        unit_number: '',
        floor: '',
        tower: '',
    });

    const handleInputChanged = (e: any) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;
        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleInputChangedData = (e: any) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;
        setInputStateData({ name, value, type: 'SET_ITEM' });
    }

    const handleAutocompleteApartment = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputStateData };
        newState.apartment_code = value.id.toString();
        newState.apartment_name = value.label;

        setInputStateData({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputStateData({ name, value: null, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        let newError = { ...errorStateData };

        if (!inputStateData.apartment_code) {
            newError.apartment_code = 'Nama Apartemen wajib diisi';
            isValid = false;
        } else {
            newError.apartment_code = '';
        }

        if (!inputStateData.floor) {
            newError.floor = 'Lantai wajib diisi';
            isValid = false;
        } else {
            newError.floor = '';
        }

        setErrorStateData({ type: 'REPLACE_STATE', name: '', value: newError });
        return isValid;
    }

    const checkValidationUnit = () => {
        let isValid = true;
        let newError = { ...errorState };

        if (!inputState.unit_code) {
            newError.unit_code = 'Kode Unit wajib diisi';
            isValid = false;
        } else {
            newError.unit_code = '';
        }

        setErrorState({ type: 'REPLACE_STATE', name: '', value: newError });
        return isValid;
    }

    const submitForm = () => {
        if (!checkValidation()) {
            return;
        }
        setLoading(true);
        setApiTable(`${process.env.REACT_APP_API_URL}/unit?apartment_name=${inputStateData.apartment_name}&floor_level=${inputStateData.floor}&tower=${inputStateData.tower}&unit_number=${inputStateData.unit_number}`)
        // setReDrawDatatable(new Date().getTime());
        setTimeout(() => {
            setLoading(false);
        }, 3500)
    }

    const loadFormUnit = () => {
        let axios;

        axios = DefaultAxios.get(`${process.env.REACT_APP_API_URL}/unit-key-logs`, { params: { unit_code: inputState.unit_code } })
        setLoading(true);
        axios
            .then(res => {
                setKeyLogState(res.data);
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const submitFormUnit = () => {
        if (!checkValidationUnit()) {
            return;
        }

        loadFormUnit();
    }

    const handleToggleEntrustestedKey = () => {
        renderWarningButton('Apakah anda yakin ingin mengganti status titipkan kunci?')
            .then((result) => {
                if (result.value) {
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/unit/switch-entrusted-key`, { unit_code: inputState.unit_code })
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil mengganti status",
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: loadFormUnit
                            });
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                }
            })
    }

    return (
        <Root>
            <LoadingScreen open={loading} fullScreen />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold" }}>
                        Silahkan isi form berikut untuk melihat data Apartemen
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <AsyncAutoComplete
                        label="Nama Apartemen"
                        name="apartment_code"
                        initialQuery={inputStateData.apartment_name}
                        onChange={handleAutocompleteApartment}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/apartment`}
                        errorText={errorStateData.apartment_code}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        error={!!errorStateData.floor}
                        label="Lantai"
                        name="floor"
                        variant="outlined"
                        value={inputStateData.floor}
                        onChange={handleInputChangedData}
                        fullWidth
                        helperText={errorStateData.floor}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Tower"
                        name="tower"
                        variant="outlined"
                        value={inputStateData.tower}
                        onChange={handleInputChangedData}
                        fullWidth
                        placeholder=""
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Nomor Unit"
                        name="unit_number"
                        variant="outlined"
                        value={inputStateData.unit_number}
                        onChange={handleInputChangedData}
                        fullWidth
                        placeholder=""
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} className={classes.gridSubmit}>
                    <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                        Submit
                    </Button>
                </Grid>

                <DataTable
                    url={apiTable}
                    // reDraw={reDrawDatatable}
                    columns={[
                        { name: 'code', label: 'Kode Unit' },
                        { name: 'apartment_name', label: 'Apartment Name' },
                        { name: 'pic_name', label: 'PIC Name' },
                        { name: 'tower', label: 'Tower' },
                        { name: 'unit_number', label: 'Nomor Unit' },
                        {
                            name: 'is_available',
                            label: 'Status',
                            render: (data: number, row: any, name: string) => {
                                return (
                                    (data === 1 ? 'Available' : 'Unavailable')
                                )
                            }
                        }

                    ]}
                />
            </Grid>

            <hr className={classes.devider}></hr>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold" }}>
                        Silahkan isi form berikut untuk melihat data key log
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Kode Unit"
                        name="unit_code"
                        variant="outlined"
                        value={inputState.unit_code}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={errorState.unit_code}
                        error={!!errorState.unit_code}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} className={classes.gridSubmit}>
                    <Button variant="contained" color="primary" onClick={submitFormUnit} className={classes.submit_button}>
                        Submit
                    </Button>
                </Grid>

                {
                    keyLogState.apartment_name && permissions['unit.switch-key']
                        ? <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                Toggle Titipkan kunci
                            </Typography>
                            <Switch
                                checked={!!keyLogState.is_entrusted_key}
                                color="secondary"
                                onChange={handleToggleEntrustestedKey}
                            />
                        </Grid>
                        : null
                }

                {
                    keyLogState.apartment_name ?
                        <>
                            <Grid item xs={4} md={3} lg={2}>
                                <Grid container justifyContent="space-between">
                                    <Grid><Typography variant="subtitle2" style={{ fontWeight: "bold" }}>Apartemen</Typography></Grid>
                                    <Grid><Typography variant="subtitle2">:</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} md={9} lg={10}>
                                <Typography variant="subtitle2">
                                    {keyLogState.apartment_name}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} md={3} lg={2}>
                                <Grid container justifyContent="space-between">
                                    <Grid><Typography variant="subtitle2" style={{ fontWeight: "bold" }}>Unit</Typography></Grid>
                                    <Grid><Typography variant="subtitle2">:</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} md={9} lg={10}>
                                <Typography variant="subtitle2">
                                    {keyLogState.unit_name}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} md={3} lg={2}>
                                <Grid container justifyContent="space-between">
                                    <Grid><Typography variant="subtitle2" style={{ fontWeight: "bold" }}>Kunci Dititipkan</Typography></Grid>
                                    <Grid><Typography variant="subtitle2">:</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} md={9} lg={10}>
                                <Typography variant="subtitle2">
                                    {keyLogState.is_entrusted_key ? `Ya` : `Tidak`}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} md={3} lg={2}>
                                <Grid container justifyContent="space-between">
                                    <Grid><Typography variant="subtitle2" style={{ fontWeight: "bold" }}>Key Holder</Typography></Grid>
                                    <Grid><Typography variant="subtitle2">:</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} md={9} lg={10}>
                                <Typography variant="subtitle2">
                                    {keyLogState.key_holder}
                                </Typography>
                            </Grid>
                            {
                                !keyLogState.logs.length ?
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="space-between">
                                            <Grid><Typography variant="subtitle2" style={{ fontWeight: "bold" }}>Tidak ada data key log</Typography></Grid>
                                        </Grid>
                                    </Grid>
                                    : null
                            }
                        </>
                        : null
                }

                <table className={classes.keyLogTable}>
                    <thead>
                        <tr>
                            <th>Tanggal</th>
                            <th>Nama</th>
                            <th>Key Manager</th>
                            <th>Action</th>
                            <th>Keterangan</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            keyLogState.logs.length
                                ? keyLogState.logs.map(item => {
                                    return <tr key={item.id}>
                                        <td>{item.date}</td>
                                        <td>{item.name}</td>
                                        <td>{item.key_manager_name}</td>
                                        <td>{keyLogStatus[item.status]}</td>
                                        <td>{item.description}</td>
                                    </tr>
                                })
                                : null
                        }
                    </tbody>
                </table>

            </Grid>
        </Root>
    )
}

export default UnitKeyLogsList;
import React, { useReducer, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import LoadingScreen from '../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import {
    BottomNavigation,
    BottomNavigationAction,
    Grid,
    TextField,
    Button,
    Theme,
    Link,
} from '@mui/material';
// Utils
import TextEditor from '../../_components/_form/TextEditor';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        marginBottom: '5px',
        flex: 1,
    },
    location: {
        marginBottom: theme.spacing(3),
    },
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        marginBottom: theme.spacing(2),
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
        // fontSize: '0.8125rem',
        // border: '1px solid #006db3',
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
    gridSubmit: {
        textAlign: 'right',
        marginBottom: '10px'
    },
    submit_button: {
        marginLeft: '8px'
    },
    date: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    fileList: {
        listStyle: 'none',
        padding: 0,
        '& li': {
            display: 'flex'
        }
    },
    filePreview: {
        width: '65px',
        height: '65px',
        overflow: 'hidden',
        float: 'left',
        '& img': {
            width: '100%'
        }
    },
    fileDetail: {
        width: '100%',
        marginLeft: '10px',
        '& p': {
            margin: 0
        }
    }
}), "LinstingLocationArticle_Form"
)

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IState {
    id: string;
    lang: string,
    sold_type: string,
    listing_type: string,
    location_type: string,
    location_id: string,
    location_name: string
}

interface IStateData {
    title: string,
    meta_title: string,
    meta_desc: string,
    article: string
    primary_image: {
        url: string;
        file: File | null;
    }
}

const ListingLocationArticleForm = () => {

    const { Root, classes } = useStyles();
    const [listingType, setListingType] = React.useState(0);
    const [locationType, setLocationType] = React.useState(0);
    const [lang, setLang] = React.useState(0);
    const [soldType, setSoldType] = React.useState(0);
    const [loading, setLoading] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL + '/location-listing-article';
    const params: any = useParams();
    const [flagListing, setFlagListing] = useState({
        listing_type: '1',
    });
    const [flagLocation, setFlagLocation] = useState({
        location_type: '',
    });

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                id: '',
                lang: 'id',
                sold_type: 'sell',
                listing_type: '1',
                location_type: 'province',
                location_id: '',
                location_name: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }
        return { ...state };
    };

    const inputReducerData = (stateData: IStateData, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...stateData,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                title: '',
                meta_title: '',
                meta_desc: '',
                article: '',
                primary_image: {
                    url: '',
                    file: null
                },
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newStateData = action.value;
            return {
                title: newStateData.title || '',
                meta_title: newStateData.meta_title || '',
                meta_desc: newStateData.meta_desc || '',
                article: newStateData.article || '',
                primary_image: {
                    url: newStateData.primary_image || '',
                    file: newStateData.file || null
                },
            };
        }
        return { ...stateData };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        id: '',
        lang: 'id',
        sold_type: 'rent',
        listing_type: '1',
        location_type: 'province',
        location_id: '',
        location_name: '',
    });

    const [inputStateData, setInputStateData] = useReducer(inputReducerData, {
        title: '',
        meta_title: '',
        meta_desc: '',
        article: '',
        primary_image: {
            url: '',
            file: null
        },
    });

    const tabValue = (name: string, value: string) => {
        setFlagListing({ listing_type: value });
        setInputState({ name, value: value, type: 'SET_ITEM' });
    }

    const tabValueLocation = (name: string, value: string) => {
        setFlagLocation({ location_type: value });
        setInputState({ name, value: value, type: 'SET_ITEM' });
    }

    // const handleInputChanged = (e: any) => {
    //     const target = e.target;
    //     let name = target.name;
    //     let value: any = target.value;
    //     setInputState({ name, value, type: 'SET_ITEM' });
    // }

    const handleInputChangedData = (e: any) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;
        if (name === 'primary_image') {
            if (target.files && target.files[0]) {
                value = { file: target.files[0], url: '' };
            } else {
                value = null;
            }
            name = 'primary_image';
        }
        setInputStateData({ name, value, type: 'SET_ITEM' });
    }

    const handleDeleteAsset = () => {
        setLoading(true);
        DefaultAxios.delete(`${API_URL}/file/delete`, {
            params: {
                lang: inputState.lang,
                listing_type: inputState.listing_type,
                sold_type: inputState.sold_type,
                location_type: inputState.location_type,
                location_id: inputState.location_id,
            }
        })
            .then(res => {
                setInputStateData({ name: 'primary_image', value: '', type: 'SET_ITEM' })
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleEditorChanged = (name: string, value: string) => {
        setInputStateData({ name, value, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const handleAutocompleteLocation = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.location_id = value.id.toString();
        newState.location_name = value.label;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const submitForm = () => {
        let axios;
        const fd = new FormData();

        for (let key in inputState) {
            fd.append(key, String(inputState[key as keyof IState]));
        }
        for (let key in inputStateData) {
            if (key === 'primary_image') {
                if (inputStateData.primary_image.file) {
                    fd.append('primary_image', inputStateData.primary_image.file as File)
                }
            } else {
                fd.append(key, String(inputStateData[key as keyof IStateData]));
            }
        }

        if (params.id) {
            axios = DefaultAxios.patch(`${API_URL}/${params.id}`, {
                ...inputState,
            });
        } else {
            axios = DefaultAxios.post(`${API_URL}`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        }
        setLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Listing Submitted",
                    icon: 'success',
                    onAfterClose: () => {
                        fetchData()
                    },
                    timer: 1000
                });
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setLoading(false);
            })

    }

    const fetchData = () => {
        setLoading(true);
        const dataSend = {
            lang: inputState.lang,
            location_type: inputState.location_type,
            location_id: inputState.location_id,
            listing_type: inputState.listing_type,
            sold_type: inputState.sold_type
        };
        DefaultAxios
            .get(`${API_URL}`, {
                params: dataSend,
            })
            .then(res => {
                const data = res.data;
                setInputStateData({ name: '', value: data, type: 'REPLACE_STATE' });
            })
            .finally(() => {
                setLoading(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        if (inputState.location_id !== '') {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (inputState.location_id !== '') {
            fetchData()
        } else {
            setInputStateData({ name: '', value: '', type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flagListing.listing_type, inputState.location_id])

    useEffect(() => {
        setInputState({ name: 'location_name', value: '', type: 'SET_ITEM' });
        setInputState({ name: 'location_id', value: '', type: 'SET_ITEM' });
    }, [flagLocation.location_type])

    return (
        <Root>
            <Grid container spacing={2} className={classes.location}>
                <LoadingScreen open={loading} fullScreen />
                <Grid item xs={12}>
                    {
                        params.id === undefined ?
                            <>
                                <BottomNavigation
                                    value={locationType}
                                    onChange={(event, newValue) => {
                                        setLocationType(newValue);
                                    }}
                                    showLabels
                                    className={classes.filter}
                                >
                                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValueLocation('location_type', 'province')} label="Province" />
                                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValueLocation('location_type', 'area')} label="City" />
                                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValueLocation('location_type', 'district')} label="District" />
                                </BottomNavigation>
                            </>
                            : null
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    {
                        inputState.location_type === 'province' ?
                            <AsyncAutoComplete
                                label="Select Province"
                                name="location"
                                initialQuery={inputState.location_name}
                                onChange={handleAutocompleteLocation}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/province-all`}
                                placeholder="ex: DKI Jakarta"
                            />
                            : inputState.location_type === 'area' ?
                                <AsyncAutoComplete
                                    label="Select City"
                                    name="location"
                                    initialQuery={inputState.location_name}
                                    onChange={handleAutocompleteLocation}
                                    onInputChange={handleAutocompleteInputChanged}
                                    url={`${process.env.REACT_APP_API_URL}/autocomplete/city-all`}
                                    placeholder="ex: Jakarta Barat"
                                />
                                : <AsyncAutoComplete
                                    label="Select District"
                                    name="location"
                                    initialQuery={inputState.location_name}
                                    onChange={handleAutocompleteLocation}
                                    onInputChange={handleAutocompleteInputChanged}
                                    url={`${process.env.REACT_APP_API_URL}/autocomplete/district-all`}
                                    placeholder="ex: Tanjung Duren"
                                />
                    }
                </Grid>
            </Grid>

            {
                params.id === undefined ?
                    <>
                        <BottomNavigation
                            value={listingType}
                            onChange={(event, newValue) => {
                                setListingType(newValue);
                            }}
                            showLabels
                            className={classes.filter}
                        >
                            <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('listing_type', '1')} label="Apartemen" />
                            <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('listing_type', '2')} label="Rumah" />
                            <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('listing_type', '3')} label="Tanah" />
                            <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('listing_type', '4')} label="Ruko" />
                            <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('listing_type', '5')} label="Kantor" />
                            <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('listing_type', '6')} label="Gudang" />
                            <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('listing_type', '7')} label="Ruang Usaha" />
                        </BottomNavigation>
                    </>
                    : null
            }

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={2}>
                    <BottomNavigation
                        value={lang}
                        onChange={(event, newValue) => {
                            setLang(newValue);
                        }}
                        showLabels
                        className={classes.filter}
                    >
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('lang', 'id')} label="Indonesia" />
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('lang', 'en')} label="Inggris" />
                    </BottomNavigation>
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                    <BottomNavigation
                        value={soldType}
                        onChange={(event, newValue) => {
                            setSoldType(newValue);
                        }}
                        showLabels
                        className={classes.filter}
                    >
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('sold_type', 'rent')} label="Sewa" />
                        <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('sold_type', 'sell')} label="Jual" />
                    </BottomNavigation>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <h3>Upload Gambar Utama</h3>
                    {
                        inputStateData.primary_image.url ?
                            <ul className={classes.fileList}>
                                <li>
                                    <img src={inputStateData.primary_image.url} alt={inputStateData.primary_image.url} height="200px" />
                                    <div className={classes.fileDetail}>
                                        <Link
                                            component="button"
                                            color="secondary"
                                            onClick={() => handleDeleteAsset()}
                                        >
                                            Delete
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                            : null
                    }
                    <input type="file" name="primary_image" onChange={handleInputChangedData} style={{ marginBottom: '10px' }} />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <TextField
                        label="Title"
                        name="title"
                        variant="outlined"
                        value={inputStateData.title}
                        onChange={handleInputChangedData}
                        fullWidth
                        placeholder=""
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        label="Meta Title"
                        name="meta_title"
                        variant="outlined"
                        value={inputStateData.meta_title}
                        onChange={handleInputChangedData}
                        fullWidth
                        placeholder=""
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        label="Meta Description"
                        name="meta_desc"
                        variant="outlined"
                        value={inputStateData.meta_desc}
                        onChange={handleInputChangedData}
                        fullWidth
                        placeholder=""
                    >
                    </TextField>
                </Grid>

                <Grid item xs={12} sm={12}>
                    {/* <h4 className={classes.title}>Notes</h4> */}
                    <Grid item xs={12}>
                        <h3>Article</h3>
                        <TextEditor
                            name="article"
                            value={inputStateData.article}
                            onChange={handleEditorChanged}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.gridSubmit}>
                    <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Root>
    )
}

export default ListingLocationArticleForm;
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import ModalSection from '../../../_components/_modalDetail/ModalSection'
import { Box, Button, Checkbox, Dialog, Grid, IconButton, Slide, TextField, Theme, Typography } from '@mui/material'

/**
 * Utils
*/
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../../_utils/Helper'
import { TransitionProps } from '@mui/material/transitions'
import DefaultAxios from '../../../_utils/DefaultAxios'

/**
 * Icons
 */
import { Check, Close, Delete, Edit } from '@mui/icons-material'
import { generateStyle } from '../../../_utils/DefaultStyle'

interface DetailListProps {
    ticketId: string
    initialList?: TicketDetailItem[]
    setIsLoading: (value: boolean) => void
    isTicketDone: boolean
}

export type TicketDetailItem = {
    id: number
    status: number
    detail: string
}

const DetailList = (props: DetailListProps) => {
    const { Root, classes } = useStyles()

    const [details, setDetails] = useState<TicketDetailItem[]>([])
    const [newDetail, setNewDetail] = useState('')

    const [selectedEditData, setSelectedEditData] = useState<null | { detail: string, index: number }>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
        if (props.initialList) {
            setDetails(props.initialList)
        } else if (props.ticketId) {
            loadData()
        }
        // eslint-disable-next-line
    }, [props.initialList, props.ticketId])

    const loadData = () => {
        props.setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/ticket/ticket-detail`, { params: { ticket_id: props.ticketId } })
            .then(res => res.data)
            .then(data => {
                setDetails(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                props.setIsLoading(false)
            })
    }

    const handleCheckBox = (id: number, isPreviouslyChecked: boolean) => {
        renderWarningButton(`Apakah anda yakin ingin menandai detail sebagai ${isPreviouslyChecked ? 'belum' : 'sudah'} selesai?`)
            .then((result) => {
                if (result.value) {
                    props.setIsLoading(true)

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/ticket/ticket-detail/${id}/toggle`)
                        .then(res => res.data)
                        .then(data => {
                            renderToastSuccess(isPreviouslyChecked ? 'Detail tidak lagi ditandai sebagai selesai' : 'Berhasil mark done detail')
                            loadData()
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            props.setIsLoading(false)
                        })
                }
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { value } = e.target

        setSelectedEditData(prev => prev === null ? null : ({
            ...prev,
            detail: value,
        }))
    }

    const handleSubmitNew = () => {
        props.setIsLoading(true)
        setIsModalOpen(false)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/ticket/ticket-detail`, {
            ticket_id: props.ticketId,
            detail: newDetail,
        })
            .then(res => res.data)
            .then(data => {
                renderToastSuccess('Berhasil menambahkan detail')
                loadData()
                setNewDetail('')
            })
            .catch(err => {
                setIsModalOpen(true)
                generalErrorHandler(err)
            })
            .finally(() => {
                props.setIsLoading(false)
            })
    }

    const handleSubmitEdit = (id: number) => {
        props.setIsLoading(true)

        DefaultAxios.put(`${process.env.REACT_APP_API_URL}/ticket/ticket-detail/${id}`, {
            detail: selectedEditData?.detail,
        })
            .then(res => res.data)
            .then(data => {
                renderToastSuccess('Berhasil mengubah detail')
                loadData()
                setSelectedEditData(null)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                props.setIsLoading(false)
            })
    }

    const handleDelete = (id: number) => {
        renderWarningButton('Apakah anda yakin ingin menghapus detail ini?')
            .then((result) => {
                if (result.value) {
                    props.setIsLoading(true)

                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/ticket/ticket-detail/${id}`)
                        .then(res => res.data)
                        .then(data => {
                            renderToastSuccess('Berhasil menghapus detail')
                            loadData()
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            props.setIsLoading(false)
                        })
                }
            })
    }

    return (
        <Root>
            <Dialog
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                closeAfterTransition
                TransitionComponent={Transition}
                fullWidth
                maxWidth="xs"
            >
                <Root>
                    <Box sx={{ p: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    Form Detail
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Detail"
                                    value={newDetail}
                                    onChange={e => setNewDetail(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'}>
                                <Button
                                    variant='contained'
                                    onClick={handleSubmitNew}
                                    disabled={newDetail === ''}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Root>
            </Dialog>

            <ModalSection
                content={[]}
                title=''
                xs={12}
                md={12}
                additionalContent={
                    <>
                        {
                            details.length > 0 ?
                                details.map((detail, index) =>
                                    <Grid container key={index} sx={{ mb: 1.5 }} spacing={2}>
                                        <Grid item xs={props.isTicketDone ? 12 : 11} display={'flex'} alignItems={'center'}>
                                            <Checkbox
                                                sx={{ mr: 1 }}
                                                checked={detail.status === 1}
                                                onChange={() => handleCheckBox(detail.id, detail.status === 1)}
                                                disabled={index === selectedEditData?.index}
                                            />
                                            {
                                                index === selectedEditData?.index ?
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder="Detail"
                                                        value={selectedEditData?.detail || ''}
                                                        onChange={e => handleChange(e, index)}
                                                        fullWidth
                                                        size='small'
                                                    />
                                                    :
                                                    <Typography>
                                                        {detail.detail}
                                                    </Typography>
                                            }
                                        </Grid>
                                        {
                                            props.isTicketDone ?
                                                null
                                                :
                                                <Grid item xs={1}>
                                                    {
                                                        index === selectedEditData?.index ?
                                                            <>
                                                                <IconButton onClick={() => handleSubmitEdit(detail.id)} color='primary'>
                                                                    <Check />
                                                                </IconButton>
                                                                <IconButton onClick={() => setSelectedEditData(null)} color='warning'>
                                                                    <Close />
                                                                </IconButton>
                                                            </>
                                                            :
                                                            <>
                                                                <IconButton onClick={() => setSelectedEditData({ detail: detail.detail, index })} color='warning'>
                                                                    <Edit />
                                                                </IconButton>
                                                                <IconButton onClick={() => handleDelete(detail.id)} color='error'>
                                                                    <Delete />
                                                                </IconButton>
                                                            </>
                                                    }
                                                </Grid>
                                        }
                                    </Grid>
                                )
                                :
                                <Typography variant='body2'>No data available</Typography>
                        }
                    </>
                }
                titleContent={
                    <Grid container>
                        <Grid item xs={12} className={classes.todoHeader}>
                            <Typography variant='h6' className={classes.h6_header}>Details</Typography>
                            {
                                props.isTicketDone ?
                                    null
                                    :
                                    <div>
                                        <Button
                                            size="small"
                                            variant='contained'
                                            onClick={() => setIsModalOpen(true)}
                                        >
                                            Add
                                        </Button>
                                    </div>
                            }
                        </Grid>
                    </Grid>
                }
            />
        </Root>
    )
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = generateStyle((theme: Theme) =>
({
    todoHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 10
    },
    h6_header: {
        color: theme.palette.primary.main,
    },
}), "Detail_List"
)

export default DetailList

import { Dialog, DialogTitle, DialogContent, Grid, Button } from '@mui/material'
import React, { useState } from 'react'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper'
import FailReasonModal from './FailReasonModal'

/* 
* Interfaces
*/
interface IProps {
    open: boolean,
    onClose: () => void,
    id: number,
    statusChanged: () => void
}

export type DashboardLeadItemType = '' | 'greeting' | 'fu' | 'showing' | 'kyc' | 'closing' | 'fail'

const typeBackgroundColor = (type: DashboardLeadItemType) => {
    switch (type) {
        case 'closing': return '#3BA8C5'
        case 'showing': return '#343434'
        case 'fail': return '#EF8181'
        case 'fu': return '#035AA6'
        case 'greeting': return '#A6CE9E'
        case 'kyc': return '#F0A456'
        default: return '#A6CE9E'
    }
}

const StatusModal = (props: IProps) => {
    const [reasonModal, setReasonModal] = useState(false)

    const handleSubmitFinal = (value: string, failReason?: string) => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/enquiry/change-status`, {
            id: props.id,
            status: value,
            ...(failReason ? { failed_reason: failReason } : null)
        })
            .then(res => {
                if (res) {
                    props.statusChanged()
                    props.onClose()
                }
            })
            .catch(err => generalErrorHandler(err))
    }

    const handleSubmit = (value: string) => {
        if (value === 'fail') {
            setReasonModal(true)
        } else {
            handleSubmitFinal(value)
        }
    }

    const createButtonStatus = (title: string, label: string, value: string) => {
        return (
            <Button
                sx={{ mb: 1 }}
                fullWidth
                variant="outlined"
                key={value}
                onClick={() => handleSubmit(value)}
                startIcon={
                    <div style={{ width: '15px', height: '15px', background: typeBackgroundColor(value as DashboardLeadItemType) }}>
                    </div>
                }
            >
                {title}
            </Button>
        )
    }
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>
                Set Status Client
            </DialogTitle>
            <DialogContent>
                <Grid>
                    {createButtonStatus('KYC', 'Client has bought the property through us', 'kyc')}
                    {createButtonStatus('SHOWING', 'Done Showing', 'showing')}
                    {createButtonStatus('FOLLOW UP', 'Booking Fee has been paid', 'fu')}
                    {createButtonStatus('FAILED', 'Response with showing plan', 'fail')}
                </Grid>
            </DialogContent>
            {
                reasonModal &&
                <FailReasonModal
                    open={reasonModal}
                    onClose={(reason) => {
                        if (!!reason) {
                            handleSubmitFinal('fail', reason)
                        }

                        setReasonModal(false)
                    }}
                />
            }
        </Dialog>
    )
}

export default StatusModal
import { Button, Dialog, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { dateFormat } from '../../../_utils/Helper';

interface Props {
    closingId: string;
    logs: any[];
}

const LogList = (props: Props) => {

    const [selectedLog, setSelectedLog] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const renderLogs = () => {
        if (props.logs.length > 0) {
            return props.logs.map((log: any, key: number) => {
                return <TableRow>
                    <TableCell>{dateFormat(log.created_at, 'DD-MM-YYYY HH:mm:ss')}</TableCell>
                    <TableCell>{log.full_name}</TableCell>
                    <TableCell>{log.type}</TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                                setSelectedLog(JSON.parse(log.log));
                                handleModalOpen();
                            }}
                        >
                            See Log
                        </Button>
                    </TableCell>
                </TableRow>
            });
        } else {
            return <TableRow>
                <TableCell colSpan={4}>There is no logs yet</TableCell>
            </TableRow>
        }
    }

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
            >
                <DialogContent>
                    <div>
                        <pre>
                            {selectedLog ? JSON.stringify(selectedLog, null, 2) : 'Tidak Ada  Log'}
                        </pre>
                    </div>
                </DialogContent>
            </Dialog>
            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Tanggal
                            </TableCell>
                            <TableCell>
                                User
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderLogs()}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default LogList;
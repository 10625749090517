import React, { useEffect, useState, useReducer } from 'react';
import { Grid, Theme, Card, CardContent, List, Button, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Dialog, AppBar, Toolbar, Typography, Slide, ListSubheader } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import HandoverUnitForm from './HandoverUnitForm';

// Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderQuestionButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    selectedId: number;
    onFinish: (closeModal?: boolean) => void;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IState {
    unit: any,
    items: any[]
}

const useStyles = generateStyle((theme: Theme) => ({
    card: {
        width: '100%',
        backgroundColor: '#0e9bff',
        color: '#ffffff',
        textAlign: 'center' as const
    },
    noPadding: {
        padding: 0
    },
    pullRight: {
        float: 'right' as const
    },
    plusIcon: {
        position: 'relative' as const,
        top: '7px',
        color: '#0e9bff',
        width: '20px'
    },
    categoryLink: {
        textDecoration: 'none' as const,
        color: '#333333'
    },
    listPadding: {
        paddingBottom: '25px'
    },
    listItemRoot: {
        padding: '10px 0'
    },
    listItemIconRoot: {
        padding: 0,
        marginRight: '-22px'
    },
    listItemSecondaryActionRoot: {
        fontSize: '11px'
    },
    addButton: {
        float: 'right',
        // fontWeight: 'lighter',
        fontSize: '14px',
        color: '#0e9bff',
        cursor: 'pointer'
    },
    finishButton: {
        marginBottom: '30px'
    },
    gridContainer: {
        marginTop: theme.spacing(3)
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    appBar: {
        position: 'relative'
    },
    listSubheader: {
        backgroundColor: 'white',
        padding: 0
    }
}), "HandeoverUnit_Detail"
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HandoverUnitDetail = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/unit-closing-breakdown/' + props.selectedId
    const { Root, classes } = useStyles();

    const [loading, setLoading] = useState(true);
    const [modalState, setModalState] = useState(false);
    const [itemId, setItemId] = useState<number | null>(null);

    const handleOpen = () => setModalState(true);
    const handleClose = () => setModalState(false);

    // State
    const stateReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                unit: {},
                items: []
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    }

    const [state, setState] = useReducer(stateReducer, {
        unit: {},
        items: []
    });

    const getUnitDetail = () => {
        DefaultAxios
            .get(API_URL)
            .then(res => {
                const data = res.data;
                setState({ type: 'REPLACE_STATE', value: data, name: '' });
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(() => {
        getUnitDetail();
        // eslint-disable-next-line
    }, []);

    const getItemList = () => {
        if (state.items.length) {
            return state.items.filter(item => +item.type !== 2).map((item: any, key: number) => {
                return (
                    <ListItem key={key} button divider className={classes.listItemRoot} onClick={() => openFormModal(item.id)}>
                        <ListItemText primary={item.caption} />
                        <ListItemSecondaryAction className={classes.listItemSecondaryActionRoot}>
                            {+item.type === 0 ? 'Keadaan' : 'Kerusakan'}
                            <IconButton edge="end" className={classes.listItemIconRoot}>
                                <ChevronRightIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            });
        } else {
            return (
                <ListItem button divider className={classes.listItemRoot}>
                    <ListItemText primary="Item tidak ditemukan" />
                </ListItem>
            );
        }
    }

    const getTagihanList = () => {
        if (state.items.length) {
            return state.items.filter(item => +item.type === 2).map((item: any, key: number) => {
                return (
                    <ListItem key={key} button divider className={classes.listItemRoot} onClick={() => openFormModal(item.id)}>
                        <ListItemText primary={item.caption} />
                        <ListItemSecondaryAction className={classes.listItemSecondaryActionRoot}>
                            <IconButton edge="end" className={classes.listItemIconRoot}>
                                <ChevronRightIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            });
        } else {
            return (
                <ListItem button divider className={classes.listItemRoot}>
                    <ListItemText primary="Tagihan tidak ditemukan" />
                </ListItem>
            );
        }
    }

    const openFormModal = (id: number | null = null) => {
        setItemId(id);
        handleOpen();
    }

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {itemId ? 'Edit' : 'Create'} Item
                </Typography>
            </Toolbar>
        )
    }

    const submitSuccessHandler = () => {
        props.onFinish(false);
        getUnitDetail();
        handleClose();
    }

    const handleFinish = () => {
        renderQuestionButton()
            .then(result => {
                if (result.value) {
                    setLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/done`)
                        .then(res => {
                            Swal.fire({
                                title: "Status berhasil di update",
                                icon: 'success',
                                onAfterClose: props.onFinish,
                                timer: 1000
                            });
                        })
                        .catch(error => {
                            generalErrorHandler(error);
                        })
                        .finally(() => {
                            setLoading(false);
                        })
                }
            })
    }

    return (
        <Root>
            <Grid container spacing={0} className={classes.gridContainer}>
                <LoadingScreen open={loading} fullScreen />

                <Dialog
                    fullScreen
                    open={modalState}
                    onClose={handleClose}
                    closeAfterTransition
                    TransitionComponent={Transition}
                >
                    <Root>
                        <AppBar className={classes.appBar}>
                            <FormToolbar />
                        </AppBar>
                        <HandoverUnitForm
                            itemId={itemId}
                            unitClosingBreakdownId={props.selectedId}
                            onSubmitSuccess={submitSuccessHandler}
                        />
                    </Root>
                </Dialog>

                <Grid item xs />
                <Grid container item xs={10} md={6}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <CardContent>
                                Detail Unit
                                <h3>{state.unit.apartment_name} - {state.unit.bedroom > 0 ? `${state.unit.bedroom}BR` : 'Studio'}</h3>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        Tower
                                        <br />
                                        {state.unit.tower || '-'}
                                    </Grid>
                                    <Grid item xs={4}>
                                        Lantai
                                        <br />
                                        {state.unit.floor_level || '-'}
                                    </Grid>
                                    <Grid item xs={4}>
                                        No. Unit
                                        <br />
                                        {state.unit.unit_number || '-'}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <h3>Item<AddCircleIcon className={classes.plusIcon} style={{ opacity: 0 }} /></h3>
                    </Grid>
                    <Grid item xs={6}>
                        <h3 className={classes.addButton} onClick={() => openFormModal()}><AddCircleIcon className={classes.plusIcon} />Tambah Item</h3>
                    </Grid>
                    <Grid item xs={12}>
                        <List component="nav" className={classes.listPadding} subheader={<li />}>
                            <ListSubheader className={classes.listSubheader}>
                                <h3>Tagihan</h3>
                            </ListSubheader>
                            {getTagihanList()}

                            <ListSubheader className={classes.listSubheader}>
                                <h3>Item</h3>
                            </ListSubheader>
                            {getItemList()}
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleFinish}
                            disabled={!state.items.length || +state.unit.status === 1}
                            className={classes.finishButton}
                        >
                            Selesai
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs />
            </Grid>
        </Root>
    );
}

export default HandoverUnitDetail;
import React, { useState, useReducer, useEffect } from 'react';
import {
    Button,
    Grid,
    Paper,
    Tab,
    Tabs,
    Theme,
} from '@mui/material';
import moment from 'moment';

/**
 * Components
 */
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';
import EnquiryMemberForm from './_components/EnquiryMemberForm';
import EnquiryDetailEdit from './_components/EnquiryDetailEdit';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import {
    generalErrorHandler,
    // numberToCurrency
} from '../../_utils/Helper';
import NewCommentList from '../../_components/NewCommentList';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    open: boolean,
    onClose: Function,
    enquiry_id: string,
    permissions: any
}

interface IState {
    id: string
    code: string
    apartment_name: string,
    created_at: string,
    created_by: string,
    customer_email: string,
    customer_name: string,
    customer_phone: string,
    source: string,
    estimated_budget_dollar: string,
    estimated_budget_rupiah: string,
    estimated_check_in: string,
    estimated_rent_period: string,
    keterangan: string,
    member_name: string,
    status: string,
    unit_code: string,
    source_label: string,
    utm_source: string | null,
    utm_medium: string | null,
    utm_campaign: string | null,
    utm_content: string | null,
    ref_code_info: string | null,
    placement_type: string | null
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        width: '100%'
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '500px',
        backgroundColor: '#eee',
        padding: '10px',
        width: '100%'
    },
}), "EnquiryModal_Detail"
)

const EnquiryDetail = (props: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = `${process.env.REACT_APP_API_URL}/enquiry`;
    const [modalEdit, setModalEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalMemberState, setModalMemberState] = useState(false);
    const [footer, setFooter] = useState<any>(null);
    const [isFooterLoading, setIsFooterLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const handleOpenMember = () => setModalMemberState(true);

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                id: '',
                code: '',
                source_label: '',
                apartment_name: '',
                created_at: '',
                created_by: '',
                customer_email: '',
                customer_name: '',
                customer_phone: '',
                source: '',
                estimated_budget_dollar: '',
                estimated_budget_rupiah: '',
                estimated_check_in: '',
                estimated_rent_period: '',
                keterangan: '',
                member_name: '',
                status: '',
                unit_code: '',
                utm_source: null,
                utm_medium: null,
                utm_campaign: null,
                utm_content: null,
                ref_code_info: null,
                placement_type: null
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        id: '',
        code: '',
        apartment_name: '',
        created_at: '',
        created_by: '',
        customer_email: '',
        customer_name: '',
        customer_phone: '',
        source: '',
        estimated_budget_dollar: '',
        estimated_budget_rupiah: '',
        estimated_check_in: '',
        estimated_rent_period: '',
        keterangan: '',
        member_name: '',
        status: '',
        source_label: '',
        unit_code: '',
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_content: null,
        ref_code_info: null,
        placement_type: null
    });

    const loadData = () => {
        setLoading(true);
        DefaultAxios
            .get(`${API_URL}/${props.enquiry_id}`)
            .then(res => {
                const data = res.data;
                setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleCloseMember = () => {
        setModalMemberState(false)
    };

    useEffect(() => {
        if (props.enquiry_id) {
            loadData();
        } else {
            setModalEdit(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.enquiry_id]);

    useEffect(() => {
        loadFooter();
        // eslint-disable-next-line
    }, [activeTab, props.enquiry_id]);

    const loadFooter = () => {
        let url: string = '';

        switch (activeTab) {
            case 0:
                url = `${process.env.REACT_APP_API_URL}/comment/enquiry/${props.enquiry_id}`;
                break;
            default:
                break;
        }

        if (!url || !props.enquiry_id) {
            return;
        }

        setIsFooterLoading(true);
        DefaultAxios
            .get(url)
            .then(res => {
                switch (activeTab) {
                    case 0:
                        setFooter(
                            <NewCommentList
                                id={props.enquiry_id}
                                type="enquiry"
                            />
                        )
                        break;
                    default:
                        break;
                }
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    }

    // const handleReply = (value: string, mentionData: any) => {
    //     setIsFooterLoading(true);
    //     DefaultAxios
    //         .post(`${process.env.REACT_APP_API_URL}/enquiry/${props.enquiry_id}/comment`, { value, mentionData })
    //         .then(res => {
    //             setTimeout(loadFooter, 100);
    //         })
    //         .catch(err => {
    //             generalErrorHandler(err);
    //         })
    //         .finally(() => {
    //             setIsFooterLoading(false);
    //         })
    // }

    return (
        <>
            <ModalDetail
                title={`Enquiry - ${inputState.code}`}
                isLoading={loading}
                open={props.open}
                onCloseModal={() => props.onClose()}
                showEditButton={true}
                editButtonLink={() => setModalEdit(true)}
            >
                <>
                    <EnquiryDetailEdit
                        open={modalEdit}
                        onClose={() => {
                            setModalEdit(false);
                            loadData()
                        }}
                        enquiryId={props.enquiry_id}
                    />

                    <EnquiryMemberForm
                        enquiryId={props.enquiry_id}
                        open={modalMemberState}
                        handleCloseModal={handleCloseMember}
                    />

                    <ModalSection
                        xs={12}
                        md={6}
                        title='Informasi Dasar'
                        content={[
                            ['Nama', inputState.customer_name],
                            ['HP', inputState.customer_phone],
                            ['Email', inputState.customer_email],
                            ['Source', inputState.source_label],
                            ['Nama Apartemen', inputState.apartment_name],
                            ['Unit', inputState.unit_code],
                            ['Estimasi Check In', inputState.estimated_check_in],
                            ['Estimasi Sewa', `${inputState.estimated_rent_period} bulan`],
                        ]}
                    />

                    <Grid item xs={12} md={6}>
                        <ModalSection
                            title='Informasi Lainnya'
                            content={[
                                [(
                                    <>
                                        Assigned Property Manager {props.permissions['enquiry.change-member'] &&
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => { handleOpenMember() }}
                                            >
                                                Ganti
                                            </Button>
                                        }
                                    </>

                                ), inputState.member_name],
                                ['Created By', `${inputState.created_by} at ${moment(inputState.created_at).format('DD MMM YYYY')}`],
                                ['Placement Type', inputState.placement_type],
                                // ['UTM Source', inputState.utm_source],
                                // ['UTM Medium', inputState.utm_medium],
                                // ['UTM Campaign', inputState.utm_campaign],
                                // ['UTM Content', inputState.utm_content],
                            ]}
                        />

                        <ModalSection
                            title='Notes'
                            content={[
                                ['', inputState.keterangan]
                            ]}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Root>
                            <Paper className={classes.paper}>
                                <LoadingScreen open={isFooterLoading} />
                                <Tabs
                                    value={activeTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleTab}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab label="Comment" value={0} />
                                </Tabs>
                                <div className={classes.footer}>
                                    {footer}
                                </div>
                            </Paper>
                        </Root>
                    </Grid>
                </>
            </ModalDetail>
        </>
    );
}

export default EnquiryDetail;
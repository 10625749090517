import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, ButtonGroup, Grid, IconButton, TextField, Theme } from '@mui/material'
import Swal from 'sweetalert2'

import ButtonUpload from '../../_components/ButtonUpload'
import LoadingScreen from '../../_components/LoadingScreen'
import PhotoPreview from '../../_components/PhotoPreview'
import TextEditor from '../../_components/_form/TextEditor'

import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, getBase64, resizeAndResetOrientationImage } from '../../_utils/Helper'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation'

import CancelIcon from '@mui/icons-material/Cancel';
import { generateStyle } from '../../_utils/DefaultStyle'

interface IProps {
    id: string
}

interface IState {
    lang: 'en' | 'id'
    articles: IArticle[]
    articles_en: IArticle[]
}

interface IArticle {
    id?: number
    image: File | null
    title: string
    article: string
    image_url: string
}

interface IArticleReponse {
    id: number
    image: string
    title: string
    article: string
}

interface IMultipleError {
    [key: number]: IValidationErrors
}

const ArticleDistrictForm = (props: IProps) => {
    const { Root, classes } = useStyles()

    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<IState>({
        lang: 'id',
        articles: [],
        articles_en: [],
    })
    const [errors, setErrors] = useState<IMultipleError>({})
    const activeArticleType = useMemo(() => state.lang === 'en' ? 'articles_en' : 'articles', [state.lang])

    const validationRules: IValidationRules = {
        title: 'required',
        article: 'required'
    }

    const validationAlias: IValidationAlias = {
        title: 'Title',
        article: 'Article Content',
        image: 'Image'
    }

    const changeLang = (lang: 'id' | 'en') => {
        setState(prev => ({
            ...prev,
            lang,
        }))
    }

    const fetchData = useCallback((isSubmitted?: boolean) => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/district/${props.id}/article?lang=${state.lang}`)
            .then(res => res.data)
            .then((data: IArticleReponse[]) => {
                const selectedArticles = state.lang === 'en' ? 'articles_en' : 'articles'

                setState(prev => ({
                    ...prev,
                    [selectedArticles]: [
                        ...data.map(val => ({
                            ...val,
                            image_url: val.image,
                            image: null,
                        })),
                        ...prev[selectedArticles].filter(val => isSubmitted ? false : !val.id),
                    ]
                }))
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [props.id, state.lang])

    useEffect(() => {
        if (props.id) {
            fetchData()
        }
    }, [fetchData, props.id])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value, files } = e.target

        if (files && files.length > 0) {
            if (files[0].size >= 5000000) {
                Swal.fire({
                    title: 'Error!',
                    text: `Failed: Max uploaded file is 5MB`,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            } else {
                resizeAndResetOrientationImage(files[0])
                    .then((file: File) => {
                        getBase64(file)
                            .then(base64 => {
                                setState(prev => ({
                                    ...prev,
                                    [activeArticleType]: prev[activeArticleType].map((val, idx) => ({
                                        ...val,
                                        image: index === idx ? file : val.image,
                                        image_url: index === idx ? base64 as string : val.image_url
                                    }))
                                }))
                            })

                        setErrors(prev => ({
                            ...prev,
                            [index]: {
                                ...prev[index],
                                image: '',
                            }
                        }))
                    })
            }
        } else {
            setState(prev => ({
                ...prev,
                [activeArticleType]: prev[activeArticleType].map((val, idx) => ({
                    ...val,
                    [name]: index === idx ? value : val[name as keyof IArticle]
                }))
            }))
        }

        setErrors(prev => ({
            ...prev,
            [index]: {
                ...prev[index],
                [name]: '',
            }
        }))
    }

    const handleEditorChange = (value: string, index: number) => {
        setState(prev => ({
            ...prev,
            [activeArticleType]: prev[activeArticleType].map((val, idx) => ({
                ...val,
                article: index === idx ? value : val.article,
            }))
        }))

        setErrors(prev => ({
            ...prev,
            [index]: {
                ...prev[index],
                article: '',
            }
        }))
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>, index: number) => {
        const { name, value } = e.target

        const { errors } = validateData({ [name]: value }, validationRules, validationAlias)

        setErrors(prev => ({
            ...prev,
            [index]: errors
        }))
    }

    const handleRemoveImage = (index: number) => {
        setState(prev => ({
            ...prev,
            [activeArticleType]: prev[activeArticleType].map((val, idx) => ({
                ...val,
                image: index === idx ? null : val.image,
                image_url: index === idx ? '' : val.image_url
            }))
        }))
    }

    const addArticle = () => {
        const currentData = state[activeArticleType]
        let isTotalValid = true

        currentData.forEach((data, index) => {
            const { isValid, errors } = validateData(data, validationRules, validationAlias)
            if (!isValid) isTotalValid = false

            setErrors(prev => ({
                ...prev,
                [index]: errors
            }))
        })

        if (isTotalValid) {
            setState(prev => ({
                ...prev,
                [activeArticleType]: [
                    ...prev[activeArticleType],
                    {
                        image: null,
                        article: '',
                        title: '',
                        image_url: '',
                    }
                ]
            }))
        } else {
            window.scrollTo({
                top: 0,
            })
        }
    }

    const deleteArticle = (article: IArticle, index: number) => {
        if (article.id) {
            setIsLoading(true)
            DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/district/${props.id}/article/${article.id}`)
                .then(res => res.data)
                .then(data => {
                    fetchData()
                })
                .catch(error => {
                    generalErrorHandler(error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            const newArticles: IArticle[] = []
            state[activeArticleType].forEach((oldArticle, inIndex) => {
                if (index !== inIndex) newArticles.push(oldArticle)
            })

            setState(prev => ({
                ...prev,
                [activeArticleType]: newArticles
            }))
        }

        setErrors(prev => ({
            ...prev,
            [index]: {}
        }))
    }

    const submit = () => {
        const currentData = state[activeArticleType]
        let isTotalValid = true

        currentData.forEach((data, index) => {
            const { isValid, errors } = validateData(data, validationRules, validationAlias)
            if (!isValid) isTotalValid = false

            setErrors(prev => ({
                ...prev,
                [index]: errors
            }))
        })

        if (isTotalValid) {
            setIsLoading(true)

            const formData = new FormData()
            formData.append('lang', state.lang)
            state[activeArticleType].forEach((value, index) => {
                formData.append(`params[${index}][title]`, value.title)
                formData.append(`params[${index}][article]`, value.article)
                if (value.image) {
                    formData.append(`params[${index}][image]`, value.image)
                }
                if (value.id) {
                    formData.append(`params[${index}][id]`, value.id.toString())
                }
            })

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/district/${props.id}/article`, formData)
                .then(res => res.data)
                .then(data => {
                    Swal.fire({
                        title: "Article district berhasil disimpan",
                        icon: 'success',
                        timer: 1000
                    })
                        .then(() => {
                            fetchData(true)
                        })
                })
                .catch(error => {
                    generalErrorHandler(error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            window.scrollTo({
                top: 0,
            })
        }
    }

    const renderForm = () => {
        const articles = state.lang === 'id' ? state.articles : state.articles_en

        return (
            <>
                {
                    articles.map((article, index) =>
                        <Grid container key={`${index}_${state.lang}`} spacing={3}>
                            <Grid item xs={6}>
                                <div className={classes.imageContainer}>
                                    <span className="label">
                                        Primary Image
                                    </span>
                                    {
                                        article.image_url ?
                                            <PhotoPreview
                                                index={index}
                                                onRemoveFile={handleRemoveImage}
                                                src={article.image_url}
                                                style={{
                                                    margin: 0,
                                                }}
                                            />
                                            :
                                            <ButtonUpload
                                                onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>, index)}
                                                style={{
                                                    margin: 0,
                                                }}
                                            />
                                    }
                                    {
                                        (errors[index]?.image) &&
                                        <span className="error">
                                            Please choose the image
                                        </span>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex' }}>
                                <IconButton
                                    color="secondary"
                                    style={{ marginLeft: 'auto', marginBottom: 'auto' }}
                                    onClick={() => deleteArticle(article, index)}
                                >
                                    <CancelIcon fontSize="large" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Title"
                                    label="Title"
                                    name="title"
                                    value={article.title}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, index)}
                                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleBlur(e, index)}
                                    error={!!errors[index]?.title}
                                    helperText={errors[index]?.title}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.imageContainer}>
                                    <span className="label">
                                        Article
                                    </span>
                                    <TextEditor
                                        name="article"
                                        value={article.article}
                                        onChange={(name: string, value: string) => handleEditorChange(value, index)}
                                    />
                                    {
                                        (errors[index]?.article) &&
                                        <span className="error">
                                            {errors[index]?.article}
                                        </span>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    )
                }
            </>
        )
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ButtonGroup
                        color="primary"
                        aria-label="outlined primary button group"
                        style={{
                            marginBottom: 16,
                        }}
                    >
                        <Button
                            variant={state.lang === 'id' ? 'contained' : 'outlined'}
                            onClick={() => changeLang('id')}
                        >
                            Indonesia
                        </Button>
                        <Button
                            variant={state.lang === 'en' ? 'contained' : 'outlined'}
                            onClick={() => changeLang('en')}
                        >
                            English
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <div className={classes.listContainer}>
                {renderForm()}
            </div>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid
                    item
                    xs={12}
                >
                    <div className={classes.flexCenter}>
                        <Button
                            variant="contained"
                            onClick={addArticle}

                        >
                            Add Article
                        </Button>
                    </div>
                </Grid>
            </Grid>
            {
                state[activeArticleType].length > 0 &&
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={12}
                    >
                        <div className={classes.submitContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submit}
                            >
                                Submit
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            }
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > .label': {
            fontSize: 16,
            color: '#484848',
            marginBottom: 12,
        },
        '& > .error': {
            color: theme.palette.error.main,
            marginTop: 12,
        }
    },
    line: {
        display: 'block',
        width: '100%',
        height: 1,
        backgroundColor: '#cacaca',
        margin: '16px 0px 16px',
    },
    metaContainer: {
        '& .title': {
            fontSize: 16,
            fontWeight: '500',
        },
        '& .subtitle': {
            fontWeight: '500',
        }
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    listContainer: {
        '& > *': {
            paddingBottom: 16,
            paddingTop: 16,
            borderBottom: '2px solid #cacaca',
        },
        '& > *:first-of-type': {
            paddingTop: 0,
        },
        '& > *:last-child': {
            borderBottom: 'none',
        }
    }
}), "ArticleDistrictForm"
)

export default ArticleDistrictForm

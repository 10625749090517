import React, { useState, useEffect } from 'react';
import { Theme, Grid, AppBar, Toolbar, IconButton, Typography, Dialog } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from '@mui/icons-material/Add';

// Components
import LoadingScreen from '../../../_components/LoadingScreen';
import defaultPhoto from '../../../Assets/Images/png/default-photo.png';
import AdvisorCommentForm from './AdvisorCommentForm';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import { dateFormat } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    onSubmitSuccess: () => void,
    unitId: string
    unitCode: string
    onCloseForm: (data: any) => void;
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: "relative",
        overflow: 'hidden'
    },
    header: {
        flexGrow: 1
    },
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        margin: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(5),
        },
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'right',
        bottom: '4px',
        right: 0,
        position: 'sticky',
        padding: '20px',
        // width: '100%'
    },
    title: {
        marginBottom: theme.spacing(1)
    },
    descText: {
        fontStyle: "italic",
        color: "#00000066",
        fontWeight: 500,
        margin: `${theme.spacing(1)}px 0`,
        display: 'block',
        whiteSpace: 'pre-line'
    },
    imageComment: {
        display: 'inline-block',
        width: '32px',
        borderRadius: '50%'
    },
    commentBox: {
        marginBottom: theme.spacing(2)
    },
    submit_button: {
        // width: '100%',
        // borderRadius: '50%',
        backgroundColor: '#009BE5',
        color: '#fff'
    },
}), "AdvisorComment_list"
)

const AdvisorCommentList = (props: Props) => {
    const { Root, classes } = useStyles();
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [openForm, setOpenForm] = useState(false)

    const handleOpenForm = () => {
        setOpenForm(true)
    }

    const handleCloseForm = () => {
        setOpenForm(false)
    }

    const submitSuccess = () => {
        props.onSubmitSuccess()
        handleCloseForm()
    }

    const renderComments = () => {
        if (data) {
            return data.map((comment, key: number) =>
                <Grid key={key} container className={classes.commentBox}>
                    <Grid item xs={2}>
                        <img className={classes.imageComment} src={comment.photo ? comment.photo : defaultPhoto} alt={comment.created_by} />
                    </Grid>

                    <Grid item xs={10}>
                        <label>
                            <span style={{ fontWeight: 500 }}>{comment.created_by}</span>
                            <span> &bull; {dateFormat(comment.created_at, 'DD MMM')}</span>
                        </label>
                        <Typography variant="caption" className={classes.descText} style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: `"${comment.comment}"` }}>
                        </Typography>
                    </Grid>
                </Grid>
            )
        }
    }

    useEffect(() => {
        setLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/comment-list?type=unit&data_id=${props.unitId}`)
            .then(res => {
                setData(res.data);
                setLoading(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openForm]);

    return (
        <Root>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.onCloseForm}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.header}>
                        Comments
                    </Typography>
                </Toolbar>
            </AppBar>
            <LoadingScreen open={loading} fullScreen />
            <Dialog fullScreen open={openForm} onClose={handleCloseForm}>
                <AdvisorCommentForm
                    onSubmitSuccess={submitSuccess}
                    unitId={props.unitCode}
                    onCloseForm={props.onCloseForm} />
            </Dialog>

            <Grid className={classes.root} >
                {renderComments()}
            </Grid>

            <Grid item xs={12} className={classes.gridSubmit}>
                <IconButton onClick={handleOpenForm} edge="start" color="inherit" aria-label="add" className={classes.submit_button}>
                    <AddIcon />
                </IconButton>
                {/* <Button
                    variant="contained"
                    color="primary"
                    // size="large"
                    className={classes.submit_button}
                // startIcon={<AddIcon />}
                // onClick={() => navigate('/activity/add')}
                >
                    <AddIcon />
                </Button> */}
                {/* <Button variant="contained" size="large" color="primary" className={classes.submit_button}>
                    Simpan
                </Button> */}
            </Grid>
        </Root>
    );
}

export default AdvisorCommentList;
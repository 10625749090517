import React from 'react'
import { Paper, Theme, Box, Chip, Typography } from '@mui/material'
import { numberToCurrency } from '../../../_utils/Helper'
import { IAdvisorDataHistory } from '../TargetScoringDashboard'
import { generateStyle } from '../../../_utils/DefaultStyle'

interface IProps {
    data: IAdvisorDataHistory
}

const MontlyHistoryCard = (props: IProps) => {
    const { Root, classes } = useStyles()

    const getRevenueDetail = (showing: number, enquiry: number, closing: number) => {
        let initialResult = {
            closingPercentage: '0%',
            showingPercentage: '0%'
        }
        if (showing === 0) return initialResult
        const showingPercentage = Math.floor((enquiry / showing) * 100) + '%'
        const closingPercentage = Math.floor((closing / enquiry) * 100) + '%'

        return {
            closingPercentage,
            showingPercentage
        }
    }

    return (
        <Root>
            <Paper sx={{ borderRadius: 'none', boxShadow: 'none' }} className={classes.card}>
                <Box className={`${classes.box}`}>
                    <Box>
                        <Typography>
                            {props.data.month_label}
                        </Typography>
                        <Typography>
                            <strong>
                                {numberToCurrency(props.data.amount, 'Rp. ')}
                            </strong>
                        </Typography>
                        <Box className={classes.revenueDetail}>
                            <Typography variant='body2'>E= {props.data.enquiry_count || 0}</Typography>
                            <Typography variant='body2'>S= {props.data.showing_count} ({getRevenueDetail(props.data.showing_count || 0, props.data.enquiry_count || 0, props.data.closing_count || 0).showingPercentage})</Typography>
                            <Typography variant='body2'>C= {props.data.closing_count} ({getRevenueDetail(props.data.showing_count || 0, props.data.enquiry_count || 0, props.data.closing_count || 0).closingPercentage})</Typography>
                        </Box>
                    </Box>
                    <Chip
                        {...(
                            props.data.on_target ? {
                                label: 'On Track',
                                color: 'success'
                            } : {
                                label: 'Off Track',
                                color: 'error'
                            }
                        )}
                    />
                </Box>
            </Paper>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) =>
({
    card: {
        marginTop: 15,
        padding: '15px 10px',
        borderBottomStyle: 'solid',
        borderBottomWidth: 3,
        borderColor: '#eaeaea',
    },
    box: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 20
    },
    revenueDetail: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px'
    }
}), "MonthlyHistoryCard"
)

export default MontlyHistoryCard
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'

/**
 * Utils
 */
import { generalErrorHandler, isMobile, renderSuccessButton } from '../../../_utils/Helper'
import { debounce } from 'lodash'
import { Key } from '../DashboardKunciCS'
import DefaultAxios from '../../../_utils/DefaultAxios'

interface SingleReturnFormProps {
    data: null | Key
    onClose: () => void
    onFinish: () => void
    isLoading: boolean
    setIsLoading: (isLoading: boolean) => void
}

interface State {
    image: null | File
}

const SingleReturnForm = (props: SingleReturnFormProps) => {
    const [isScreenMobile, setIsScreenMobile] = useState(false)

    const [state, setState] = useState<State>({
        image: null,
    })

    useEffect(() => {
        const handleResize = debounce(() => {
            setIsScreenMobile(isMobile())
        }, 250)

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        if (!props.data) {
            setState({
                image: null,
            })
        }
    }, [props.data])

    const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, files} = e.target

        if (files && files.length > 0) {
            setState(prev => ({
                ...prev,
                [name]: files[0]
            }))
        }
    }

    const onSubmit = () => {
        props.setIsLoading(true)

        const fd = new FormData()

        fd.append('id', props.data?.id.toString() || '')

        if (state.image) {
            fd.append('image', state.image)
        }

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-key/return-single`, fd)
            .then(() => {
                renderSuccessButton('Berhasil Return Kunci', () => {
                    props.onFinish()
                    props.onClose()
                })
            })
            .catch(generalErrorHandler)
            .finally(() => {
                props.setIsLoading(false)
            })
    }

    return (
        <Dialog
            open={props.data !== null}
            onClose={props.onClose}
            maxWidth="sm"
            fullScreen={isScreenMobile}
            fullWidth
        >
            <DialogTitle>Single Return Form - {props.data?.code || ''}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{marginTop: 4}}>
                    <Grid item xs={12}>
                        <h5 style={{marginTop: 0}}>
                            Upload File
                        </h5>
                        <input type="file" name="image" onChange={onChangeFile} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant='contained'
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant='contained'
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SingleReturnForm

import React from 'react'

/**
 * Components
 */
import { Box, Button, IconButton, Typography } from '@mui/material'
import DialogND from '../../../_components/_newDesignComponents/_modal/DialogND'
import { JHSScheduleSchedule } from './JHSScheduleCard'

/**
 * Utils
 */
import { format } from 'date-fns'

/**
 * Icons
 */
import { AlarmOutlined, Close } from '@mui/icons-material'
import { generateStyle } from '../../../_utils/DefaultStyle'

interface JHSScheduleDeleteModalProps {
    isOpen: boolean
    onClose: () => void
    data: JHSScheduleSchedule | null
    confirmDelete: (data: JHSScheduleSchedule) => void
}

const JHSScheduleDeleteModal = (props: JHSScheduleDeleteModalProps) => {
    const { Root, classes } = useStyles()

    return (
        <DialogND
            open={props.isOpen}
            onClose={props.onClose}
        >
            <Root>
                <Box className={classes.root}>
                    <Box display='flex' alignItems='center' justifyContent='space-between' sx={{ mb: 5 }}>
                        <Typography variant='subtitle1' fontWeight={500}>
                            Are you sure want to delete?
                        </Typography>
                        <IconButton onClick={props.onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box>
                        <Typography variant='subtitle2' sx={{ mb: 1.25 }} lineHeight={1.2}>
                            {props.data?.apartment_name || '-'}
                        </Typography>
                        <Typography variant='caption' sx={{ mb: 1.25 }} color='#484848' lineHeight={1.2} display='block'>
                            {props.data?.unit_code ? props.data?.unit_code + ((props.data?.work_code || props.data?.meeting_type) ? ' - ' : '') : ''}{props.data?.work_code || ''}{(props.data?.unit_code || props.data?.work_code) && (props.data?.meeting_type) ? <span style={{ fontWeight: 500 }}>{' - Ketemu ' + (props.data?.meeting_type === 1 ? 'Owner' : 'Tenant')}</span> : ''}
                        </Typography>
                        {
                            props.data?.requester_name ?
                                <Typography variant='caption' sx={{ mb: 1.25 }} color='#484848' lineHeight={1.2}>
                                    Ordered by: {props.data?.requester_name}
                                </Typography>
                                : null
                        }
                        <Box sx={{ pr: 1.25, pt: 1.25 }} display='flex' alignItems='center'>
                            <AlarmOutlined sx={{ fontSize: 14 }} htmlColor={props.data?.time ? '#0080FE' : '#484848'} />
                            <Typography variant='caption' color='#484848' lineHeight={1.2} sx={{ pl: 0.6 }}>
                                {props.data?.time ? format(new Date(`${props.data?.date || format(new Date(), 'yyy-MM-dd')} ${props.data?.time}`), 'HH:mm') : '-'}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 5 }} display='flex' alignItems='center' justifyContent='space-between'>
                        <Button
                            variant='text'
                            fullWidth
                            onClick={props.onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            fullWidth
                            onClick={() => props.data ? props.confirmDelete(props.data) : {}}
                        >
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Root>
        </DialogND>
    )
}

const useStyles = generateStyle(() => ({
    root: {
        padding: 30,
        paddingBottom: 20,
        minWidth: 360,
    },
}), "JHSScheduleDelete_Modal")

export default JHSScheduleDeleteModal

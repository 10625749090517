import React, { useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { Grid, Theme, ButtonGroup, Button } from '@mui/material';

import DeveloperPropertyDetailForm from './DeveloperPropertyDetailForm';
import DeveloperPropertyImageForm from './DeveloperPropertyImageForm';
import DeveloperProperty360PhotoForm from './DeveloperProperty360PhotoForm';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) => ({
    borderBottomLine: {
        borderBottom: '1px solid #eee'
    }
}), "Property_form"
);

const PropertyForm = () => {
    const { Root, classes } = useStyles();
    const location = useLocation();
    const params: any = useParams();
    const navigate = useNavigate();

    const [type, setType] = useState(typeof params.type !== 'undefined' ? params.type : 'details');

    const renderForm = () => {
        if (params.property_id) {
            if (type === 'details') {
                return <DeveloperPropertyDetailForm
                    developerPropertyId={params.property_id}
                    developerProjectId={params.project_id}
                    mode="edit"
                />
            } else if (type === 'image') {
                return <DeveloperPropertyImageForm
                    developerPropertyId={params.property_id}
                    developerProjectId={params.project_id}
                />
            } else if (type === '360-photo') {
                return <DeveloperProperty360PhotoForm
                    developerPropertyId={params.property_id}
                    developerProjectId={params.project_id}
                />
            }
        } else {
            return <DeveloperPropertyDetailForm
                developerPropertyId={null}
                developerProjectId={params.project_id}
                mode="add"
            />
        }
    }

    const handleNavigation = (route: string) => {
        if (route !== type) {
            if (route === 'details') {
                navigate(`/developer/${params.project_id}/property/edit/${params.property_id}`);
            } else {
                navigate(`/developer/${params.project_id}/property/edit/${params.property_id}/${route}`);
            }
            setType(route);
        }
    }

    return (
        <Root>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h1 className={classes.borderBottomLine}>Developer Property - {location.pathname === `/developer/${params.project_id}/property/add` ? 'Add' : 'EDIT'}</h1>
                </Grid>

                {
                    location.pathname !== `/developer/${params.project_id}/property/add`
                        ? <Grid item xs={12}>
                            <ButtonGroup
                                color="primary"
                                aria-label="outlined primary button group"
                                fullWidth
                            >
                                <Button
                                    variant={type === 'details' ? 'contained' : 'outlined'}
                                    onClick={() => handleNavigation('details')}
                                >
                                    Details
                                </Button>
                                <Button
                                    variant={type === 'image' ? 'contained' : 'outlined'}
                                    onClick={() => handleNavigation('image')}
                                >
                                    Images
                                </Button>
                                <Button
                                    variant={type === '360-photo' ? 'contained' : 'outlined'}
                                    onClick={() => handleNavigation('360-photo')}
                                >
                                    360 Photo
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        : null
                }

                {renderForm()}
            </Grid>
        </Root>
    );
}

export default PropertyForm;
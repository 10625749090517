import React from 'react'
import MenuBar from '../../_components/MenuBar'
import DashboardListingAdminMain from './_components/DashboardListingAdminMain'

interface IProps {
    permissions: any
}

const DashboardListingAdmin = (props: IProps) => {
    return (
        <MenuBar title='Dashboard Listing Admin'>
            <DashboardListingAdminMain />
        </MenuBar>
    )
}

export default DashboardListingAdmin

import React, { useState } from 'react'

/**
 * Components
 */
import { Box, Dialog, DialogContent, IconButton, Paper, Typography } from '@mui/material'
import ImagePreview from '../../../_components/ImagePreview'

/**
 * Utils
 */
import { openExternalLink } from '../../../_utils/Helper'

/**
 * Icons
 */
import { PictureAsPdf } from '@mui/icons-material'

interface ListingTicketInfoModalProps {
    isOpen: boolean
    onClose: () => void
    data: {
        file: string
        reason: string
    }
}

const ListingTicketInfoModal = (props: ListingTicketInfoModalProps) => {
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)

    return (
        <>
            <ImagePreview
                image={isPreviewOpen ? props.data.file || '' : undefined}
                onClose={() => {
                    setIsPreviewOpen(false)
                }}
            />
            <Dialog
                open={props.isOpen}
                fullWidth
                maxWidth='xs'
                onClose={props.onClose}
            >
                <DialogContent>
                    {
                        props.data.file ?
                            props.data.file.includes('.pdf') ?
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <IconButton
                                        onClick={() => openExternalLink(props.data.file)}
                                        sx={{
                                            fontSize: 200,
                                        }}
                                    >
                                        <PictureAsPdf fontSize='inherit' color='error' />
                                    </IconButton>
                                </Box>
                                :
                                <img
                                    src={props.data.file}
                                    alt="proof"
                                    style={{
                                        width: '100%',
                                        maxHeight: 300,
                                        cursor: 'pointer',
                                        objectFit: 'contain',
                                        objectPosition: 'center',
                                    }}
                                    onClick={() => {
                                        setIsPreviewOpen(true)
                                    }}
                                />
                            : null
                    }
                    <Paper sx={{ p: 1, mt: 2 }}>
                        <Typography variant='subtitle2'>
                            Reason:
                        </Typography>
                        <Typography sx={{ overflowWrap: 'break-word' }}>
                            {props.data.reason || '-'}
                        </Typography>
                    </Paper>
                </DialogContent>
            </Dialog>
        </>
    )
}


export default ListingTicketInfoModal

import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material'
import React, { useState } from 'react'
import { generalErrorHandler } from '../../../_utils/Helper'

/* 
* Utils
*/
import DefaultAxios from '../../../_utils/DefaultAxios'
import TextArea from '../../../_components/_form/TextArea'

/* 
* Interfaces
*/
interface IProps {
    open: boolean,
    onClose: () => void,
    id: number,
    noteChanged: () => void,
    initialNote: string
}


const NoteModal = (props: IProps) => {
    const [notes, setNotes] = useState(props.initialNote || '')

    const handleSubmit = () => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/enquiry/change-notes`, {
            id: props.id,
            notes: notes
        })
            .then(res => {
                if (res) {
                    props.noteChanged()
                    props.onClose()
                }
            })
            .catch(err => generalErrorHandler(err))
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>
                Change Note
            </DialogTitle>
            <DialogContent>
                <TextArea
                    value={notes}
                    name="note"
                    rows={3}
                    onChange={(e: any) => setNotes(e.target.value)}
                />
                <Button
                    variant="contained"
                    sx={{mt: 2}}
                    fullWidth
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </DialogContent>
        </Dialog>
    )
}

export default NoteModal
import { Box, Grid, IconButton, Theme, Typography, TableHead, TableRow, Button, TableCell } from '@mui/material'
import React, { useEffect, useState, useMemo } from 'react'

/**
 * Components
 */
import SimpleDataTable, { IColumn } from '../../_components/_dataTable/SimpleDataTable'
import FormHouseRules from './_components/FormHouseRules'
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import SectionCard from './_components/SectionCard'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertIndonesiaPhoneNumber, convertNumber, generalErrorHandler, renderSuccessButton } from '../../_utils/Helper';
import { isNumber } from 'lodash'
import { format } from 'date-fns'

/**
 * Icons
 */
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LoadingScreen from '../../_components/LoadingScreen'
import ParkingModal from './_components/ParkingModal'
import { generateStyle } from '../../_utils/DefaultStyle';

type Props = {
    open: boolean,
    onClose: () => void
    id: string
    apartmentName: string
}

interface IRenderData {
    name: string,
    label: string,
    tableColumn?: IColumn[]
    tableData?: any[]
    render?: (data: any) => any
}

const initialDataTable = {
    facilities_information: null,
    bm_information: null,
    house_rules: null,
    internets: null,
    recommended_agents: null,
    other_contacts: null,
    monthly_billings: null,
    nearest_public_facilities: null,
    nearest_transportations: null,
    price_lists: null,
    tenant_commercials: null,
    towers: null,
    parking: null,
}

const ProductKnowledge = (props: Props) => {
    const [parkingValue, setParkingValue] = useState('')
    const [parkingModal, setParkingModal] = useState(false)
    const [redrawTable, setRedrawTable] = useState(new Date().getTime())
    const [id, setId] = useState('')

    const handleDowload = (type: string) => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/apartment-product-knowledge/${props.id}/${type === 'all' ? '' : type}`, {
            responseType: 'blob'
        })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${type}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleUpload = (type: string) => {
        let input = document.createElement('input')
        input.type = 'file'
        input.multiple = false
        input.accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        input.onchange = (e: any) => handleUploadFile(e, type)
        input.click()
    }

    const handleUploadFile = (e: any, type: string) => {
        if (e.target.files.length) {
            setIsLoading(true)
            let fd = new FormData()
            fd.append('file', e.target.files[0])

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/apartment-product-knowledge/${props.id}/${type === 'all' ? '' : type}`, fd)
                .then(res => {
                    renderSuccessButton('Upload Success!')
                    loadData()
                })
                .catch(err => {
                    generalErrorHandler(err, { render400ErrorList: true })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const handleSubmitParking = (value: string) => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/apartment-product-knowledge/parking-member-registration`, {
            id: props.id,
            parking_member_registration: value
        })
            .then(res => {
                renderSuccessButton('Parking Member Registration Submitted!')
                loadData()
                setParkingModal(false)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const renderBilling = (data: any) => {
        let text = data.name
        switch (true) {
            case /ipl/i.test(text):
            case /fee/i.test(text):
                return `${convertNumber(data.billing, 'Rp.')} / m2`
            case /listrik/i.test(text):
                return data.billing
            case /air/i.test(text):
                return data.billing
            default:
                return `${convertNumber(data.billing, 'Rp.')} / bulan`
        }
    }

    // const [productKnowledge, setProductKnowledge] = useState(initialProductKnowLedge)
    const [dataTable, setDataTable] = useState<{ [key: string]: any }>(initialDataTable)
    const { Root, classes } = useStyles()
    const [changeRules, setChangeRules] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const productKnewledge = useMemo(() => ({
        house_rules: {
            name: 'house_rules',
            label: 'House Rules',
            tableData: dataTable['house_rules'] || [],
            render: (data: any) => {
                return (
                    <SectionCard
                        label='House Rules'
                        columnData={[]}
                        tableData={[]}
                        redraw={redrawTable}
                        name='house_rules'
                        customHeader={
                            <div>
                                <IconButton color='primary' size="large" onClick={() => setChangeRules(true)}>
                                    <EditIcon fontSize='large' />
                                </IconButton>
                            </div>
                        }
                        customContent={
                            <Box className={classes.content}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {data && data.length ?
                                            <ul>
                                                {data.map((list: { rule: string }, idx: number) => {
                                                    return <li key={idx}>{list.rule}</li>
                                                })}
                                            </ul>
                                            :
                                            <span>Tidak ada rules</span>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    />
                )
            }
        },
        towers: {
            name: 'towers',
            label: 'Detail Tower',
            tableColumn: [
                { name: 'tower', label: 'Nama', style: { textAlign: 'center' } },
                { name: 'highest_floor', label: 'Lantai tertinggi', style: { textAlign: 'center' } },
                { name: 'unit_count', label: 'Jumlah Unit', style: { textAlign: 'center' } },
                { name: 'finished_development', label: 'Tahun Selesai pembangunan', style: { textAlign: 'center' } },
            ],
            tableData: dataTable['towers'] || []
        },
        price_lists: {
            name: 'price_lists',
            label: 'Harga Pasaran',
            tableData: dataTable['price_lists'] || [],
            render: (data: any) => {
                return (
                    <SectionCard
                        redraw={redrawTable}
                        onDownload={handleDowload}
                        onUpload={handleUpload}
                        dashOnEmpty
                        disableButton
                        label='Harga Pasaran'
                        name="price_lists"
                        columnData={[]}
                        tableData={[]}
                        customContent={
                            <>
                                <Box className={classes.content}>
                                    <Grid container spacing={1} rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <SimpleDataTable
                                                customHeader={
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell rowSpan={3} align='center'>Tipe Unit</TableCell>
                                                            <TableCell rowSpan={3} align='center'>Luas (m2)</TableCell>
                                                            <TableCell colSpan={4} align="center">Market Price</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={2} align='center'>Full Furnished</TableCell>
                                                            <TableCell colSpan={2} align="center">Semi Furnished / Unfurnished</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align='center'>Harga Terendah</TableCell>
                                                            <TableCell align='center'>Harga Tertinggi</TableCell>
                                                            <TableCell align='center'>Harga Terendah</TableCell>
                                                            <TableCell align='center'>Harga Tertinggi</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                }
                                                columns={[
                                                    { name: 'unit_type', label: 'Tipe Unit' },
                                                    { name: 'area', label: 'Luas (m2)' },
                                                    { name: 'lowest_price_full_furnish', label: 'Harge Terendah', render: (row: any) => row && isNumber(row) ? convertNumber(row.toString()) : row },
                                                    { name: 'highest_price_full_furnish', label: 'Harga Tertinggi', render: (row: any) => row && isNumber(row) ? convertNumber(row.toString()) : row },
                                                    { name: 'lowest_price_non_full_furnish', label: 'Harge Terendah', render: (row: any) => row && isNumber(row) ? convertNumber(row.toString()) : row },
                                                    { name: 'highest_price_non_full_furnish', label: 'Harga Tertinggi', render: (row: any) => row && isNumber(row) ? convertNumber(row.toString()) : row },
                                                ]}
                                                customData={data ? data : []}
                                                reDraw={redrawTable}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        }
                    />
                )
            },
        },
        facilities_information: {
            name: 'facilities_information',
            label: 'Fasilitas Apartemen',
            tableColumn: [
                { name: 'name', label: 'Nama', style: { textAlign: 'center' } },
                { name: 'location', label: 'Lokasi', style: { textAlign: 'center' } },
                { name: 'EXTRA', label: 'Jam Operasional', render: (row: any) => `${format(new Date(`1970-01-01T${row.start_time}`), 'HH:mm')} - ${format(new Date(`1970-01-01T${row.end_time}`), 'HH:mm')}`, style: { textAlign: 'center' } },
                { name: 'notes', label: 'Catatan', style: { textAlign: 'center' } },
            ],
            tableData: dataTable['facilities_information'] || []
        },
        bm_information: {
            name: 'bm_information',
            label: 'Building Management',
            tableColumn: [
                { name: 'description', label: 'Deskripsi', style: { textAlign: 'center' } },
                { name: 'location', label: 'Lokasi', style: { textAlign: 'center' } },
                { name: 'patokan', label: 'Patokan', style: { textAlign: 'center' } },
                { name: 'phone', label: 'No. Telepon', style: { textAlign: 'center' } },
                { name: 'EXTRA', label: 'Jam Operasional', render: (row: any) => `${format(new Date(`1970-01-01T${row.start_time}`), 'HH:mm')} - ${format(new Date(`1970-01-01T${row.end_time}`), 'HH:mm')}`, style: { textAlign: 'center' } },
            ],
            tableData: dataTable['bm_information'] || []
        },
        tenant_commercials: {
            name: 'tenant_commercials',
            label: 'Tenant Komersial',
            tableColumn: [
                { name: 'name', label: 'Tenant Komersial', style: { textAlign: 'center' } },
                { name: 'description', label: 'Deskripsi', style: { textAlign: 'center' } },
                { name: 'location', label: 'Lokasi', style: { textAlign: 'center' } },
                { name: 'phone', label: 'Phone', style: { textAlign: 'center' } },
            ],
            tableData: dataTable['tenant_commercials'] || []
        },
        monthly_billings: {
            name: 'monthly_billings',
            label: 'Tagihan Bulanan',
            tableColumn: [
                { name: 'name', label: 'Deskripsi', style: { textAlign: 'center' } },
                { name: 'type', label: 'Tipe', style: { textAlign: 'center' } },
                { name: 'EXTRA', label: 'Biaya', style: { textAlign: 'center' }, render: (data: any) => renderBilling(data) },
                { name: 'start_period', label: 'Periode Tagihan', render: (value: any, row: any) => (row.start_period || row.end_period) ? `${row.start_period} - ${row.end_period}` : '-', style: { textAlign: 'center' } },
                { name: 'billing_start_date', label: 'Tanggal Terbit Tagihan', style: { textAlign: 'center' } },
                { name: 'billing_end_date', label: 'Tanggal Jatuh Tempo', style: { textAlign: 'center' } },
                { name: 'payment_method', label: 'Cara Pembayaran', style: { textAlign: 'center' } },
                { name: 'notes', label: 'Notes', style: { textAlign: 'center' } },
            ],
            tableData: dataTable['monthly_billings'] || []
        },
        internets: {
            name: 'internets',
            label: 'Internet',
            tableColumn: [
                { name: 'name', label: 'Provider', style: { textAlign: 'center' } },
                { name: 'phone', label: 'Nomor Telepon', style: { textAlign: 'center' } },
                { name: 'office_location', label: 'Lokasi Kantor', style: { textAlign: 'center' } },
                { name: 'patokan', label: 'Patokan', style: { textAlign: 'center' } },
            ],
            tableData: dataTable['internets'] || []
        },
        recommended_agents: {
            name: 'recommended_agents',
            label: 'Agent Rekomendasi',
            tableColumn: [
                { name: 'name', label: 'Nama Agent', style: { textAlign: 'center' } },
                { name: 'type', label: 'Tipe Agent', style: { textAlign: 'center' } },
                { name: 'office', label: 'Nama Kantor', style: { textAlign: 'center' } },
                {
                    name: 'phone',
                    label: 'No. HP',
                    style: { textAlign: 'center' },
                    render: (data: any) => data ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <span>{data}</span>
                            <IconButton
                                size='small'
                                color='success'
                                href={`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(data)}`}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <WhatsAppIcon />
                            </IconButton>
                        </div>
                        : '-'
                },
            ],
            tableData: dataTable['recommended_agents'] || []
        },
        other_contacts: {
            name: 'other_contacts',
            label: 'Kontak Lainnya',
            tableColumn: [
                { name: 'name', label: 'Nama', style: { textAlign: 'center' } },
                { name: 'phone', label: 'No. HP', style: { textAlign: 'center' } },
                { name: 'notes', label: 'Catatan', style: { textAlign: 'center' } },
            ],
            tableData: dataTable['other_contacts'] || []
        },
        // car_parkings: {},
        // bike_parkings: {},
        parking: {
            name: 'parking',
            label: 'Parkir',
            tableData: dataTable['parking'] || {},
            render: (data: any) => {
                return (
                    <SectionCard
                        redraw={redrawTable}
                        columnData={[]}
                        tableData={[]}
                        label='Parking'
                        name="parking"
                        customHeader={
                            <>
                            </>
                        }
                        customContent={
                            <>
                                <Box className={classes.content}>
                                    <Grid container spacing={1} rowSpacing={2}>
                                        <Grid item xs={4}>
                                            <div className={`${classes.titleContainer}`} style={{ justifyContent: "flex-start", alignItems: 'center' }}>
                                                <Typography variant='h6' sx={{ fontWeight: '400' }}>Lokasi Daftar : {parkingValue ? parkingValue : '-'}</Typography>
                                                <div style={{ marginLeft: '10px' }}>
                                                    <IconButton color='primary' title='Change Parking' onClick={() => setParkingModal(true)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className={classes.titleContainer}>
                                                <Typography variant='body1' sx={{ mb: 1 }}>Mobil</Typography>
                                                {/* <div>
                                                    <IconButton color='primary' title='Export Data' onClick={() => handleDowload("car_parkings")}>
                                                        <UploadIcon />
                                                    </IconButton>
                                                    <IconButton color='primary' title='Import Data' onClick={() => handleUpload("car_parkings")}>
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </div> */}
                                            </div>
                                            <SimpleDataTable
                                                columns={[
                                                    { name: 'name', label: 'Kendaraan Mobil', columnStyle: { width: '25%' }, style: { textAlign: 'center' } },
                                                    { name: 'studio_price', label: 'Studio', columnStyle: { width: '15%' }, style: { textAlign: 'center' }, render: (row: any) => isNumber(row) ? row === 0 ? 'Free' : convertNumber(row.toString()) : '-' },
                                                    { name: '1br_price', label: '1BR', columnStyle: { width: '15%' }, style: { textAlign: 'center' }, render: (row: any) => isNumber(row) ? row === 0 ? 'Free' : convertNumber(row.toString()) : '-' },
                                                    { name: '2br_price', label: '2BR', columnStyle: { width: '15%' }, style: { textAlign: 'center' }, render: (row: any) => isNumber(row) ? row === 0 ? 'Free' : convertNumber(row.toString()) : '-' },
                                                    { name: '3br_price', label: '3BR', columnStyle: { width: '15%' }, style: { textAlign: 'center' }, render: (row: any) => isNumber(row) ? row === 0 ? 'Free' : convertNumber(row.toString()) : '-' },
                                                    { name: '4br_price', label: '4BR', columnStyle: { width: '15%' }, style: { textAlign: 'center' }, render: (row: any) => isNumber(row) ? row === 0 ? 'Free' : convertNumber(row.toString()) : '-' },
                                                ]}
                                                customData={data?.car_parkings ? data?.car_parkings : []}
                                                reDraw={redrawTable}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className={classes.titleContainer}>
                                                <Typography variant='body1' sx={{ mb: 1 }}>Motor</Typography>
                                                {/* <div>
                                                    <IconButton color='primary' title='Export Data' onClick={() => handleDowload("bike_parkings")}>
                                                        <UploadIcon />
                                                    </IconButton>
                                                    <IconButton color='primary' title='Import Data' onClick={() => handleUpload("bike_parkings")}>
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </div> */}
                                            </div>
                                            <SimpleDataTable
                                                columns={[
                                                    { name: 'name', label: 'Kendaraan Motor', columnStyle: { width: '25%' }, style: { textAlign: 'center' } },
                                                    { name: 'studio_price', label: 'Studio', columnStyle: { width: '15%' }, style: { textAlign: 'center' }, render: (row: any) => isNumber(row) ? row === 0 ? 'Free' : convertNumber(row.toString()) : '-' },
                                                    { name: '1br_price', label: '1BR', columnStyle: { width: '15%' }, style: { textAlign: 'center' }, render: (row: any) => isNumber(row) ? row === 0 ? 'Free' : convertNumber(row.toString()) : '-' },
                                                    { name: '2br_price', label: '2BR', columnStyle: { width: '15%' }, style: { textAlign: 'center' }, render: (row: any) => isNumber(row) ? row === 0 ? 'Free' : convertNumber(row.toString()) : '-' },
                                                    { name: '3br_price', label: '3BR', columnStyle: { width: '15%' }, style: { textAlign: 'center' }, render: (row: any) => isNumber(row) ? row === 0 ? 'Free' : convertNumber(row.toString()) : '-' },
                                                    { name: '4br_price', label: '4BR', columnStyle: { width: '15%' }, style: { textAlign: 'center' }, render: (row: any) => isNumber(row) ? row === 0 ? 'Free' : convertNumber(row.toString()) : '-' },
                                                ]}
                                                customData={data?.bike_parkings ? data?.bike_parkings : []}
                                                reDraw={redrawTable}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        }
                    />
                )
            }
        },
        nearest_transportations: {
            name: 'nearest_transportations',
            label: 'Public Transportation',
            tableColumn: [
                { name: 'type', label: 'Public Transportation', style: { textAlign: 'center' } },
                { name: 'name', label: 'Nama', style: { textAlign: 'center' } },
                { name: 'distance', label: 'Jarak', style: { textAlign: 'center' }, render: (data: any) => data ? <>{data} KM</> : '-' },
                { name: 'EXTRA', label: 'Lokasi', render: (row: any) => (row.latitude && row.longitude) ? <a href={`https://maps.google.com/?q=${row.latitude},${row.longitude}`}>Open Maps</a> : '-', style: { textAlign: 'center' } },
            ],
            tableData: dataTable['nearest_transportations'] || []
        },
        nearest_public_facilities: {
            name: 'nearest_public_facilities',
            label: 'Public Facilities',
            tableColumn: [],
            tableData: dataTable['nearest_public_facilities'] || [],
            render: (data: any) => (
                <SectionCard
                    columnData={[]}
                    label='Public Facilities'
                    name='nearest_public_facilities'
                    redraw={redrawTable}
                    tableData={[]}
                    onDownload={handleDowload}
                    onUpload={handleUpload}
                    dashOnEmpty
                    disableButton
                    customContent={
                        <Grid container sx={{ mt: 1 }}>
                            {
                                Object.entries(data).length ?
                                    Object.entries(data).map(([name, data]) => (
                                        <Grid key={name} item xs={12} sx={{ mb: 2 }}>
                                            <SimpleDataTable
                                                columns={[
                                                    { name: 'name', label: name, style: { textAlign: 'center' } },
                                                    { name: 'distance', label: 'Jarak', columnStyle: { width: '30%' }, style: { textAlign: 'center' }, render: (data: any) => data ? <>{data} KM</> : '-' },
                                                    { name: 'EXTRA', label: 'Lokasi', columnStyle: { width: '30%' }, render: (row: any) => (row.latitude && row.longitude) ? <a href={`https://maps.google.com/?q=${row.latitude},${row.longitude}`}>Open Maps</a> : '-', style: { textAlign: 'center' } },
                                                ]}
                                                customData={data as any}
                                                reDraw={redrawTable}
                                            />
                                        </Grid>
                                    )) :
                                    <Grid item xs={12} >
                                        Informasi Fasilitas belum tersedia
                                    </Grid>
                            }
                        </Grid>
                    }
                />
            )
        },
    }
        // eslint-disable-next-line
    ), [props.id, dataTable, parkingValue, redrawTable])

    const assignDataToState = (data: any) => {
        let newDataTable = { ...dataTable }
        Object.entries(data).forEach(([name, value]) => {
            if (newDataTable[name] !== undefined) {
                if (name === 'nearest_public_facilities') {
                    let result: { [key: string]: any } = {}
                    let valueRes: any[] = [...value as any[]]
                    valueRes?.forEach((item: any) => {
                        const { type, ...rest } = item;
                        if (!result[type]) {
                            result[type] = [];
                        }
                        result[type].push(rest);
                    })
                    newDataTable[name] = result
                } else {
                    newDataTable[name] = value || []
                }

                // }
            } else {
                switch (name) {
                    case 'car_parkings':
                    case 'bike_parkings':
                        newDataTable['parking'] = {
                            ...newDataTable['parking'],
                            [name]: value
                        }
                        break
                    case 'parking_member_registration':
                        setParkingValue(value as string || '')
                        break
                }
            }
        })

        setDataTable(newDataTable)
    }

    const loadData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/apartment-product-knowledge`, { params: { id: props.id } })
            .then(res => {
                assignDataToState(res.data)
            })
            .catch((err) => {
                generalErrorHandler(err)
                props.onClose()
            })
            .finally(() => {
                setIsLoading(false)
                setRedrawTable(new Date().getTime())
            })
    }

    useEffect(() => {
        if (!props.open) {
            setDataTable(initialDataTable)
            setParkingValue('')
            setId('')
        } else {
            if (props.id) {
                loadData()
            }

            if (props.id) {
                setId(id)
            }
        }

        // eslint-disable-next-line
    }, [props.open, props.id])

    const renderTableContent = (data: IRenderData) => {
        if (typeof data.render !== 'undefined') {
            return data.render(data?.tableData)
        }

        return (
            <SectionCard
                key={data.name}
                redraw={redrawTable}
                columnData={data.tableColumn ? data.tableColumn : []}
                tableData={data.tableData ? data.tableData : []}
                label={data.label}
                name={data.name}
                onDownload={handleDowload}
                onUpload={handleUpload}
                dashOnEmpty
                disableButton
            />
        )
    }

    const renderContent = () => {
        return (
            <>
                {renderTableContent(productKnewledge.house_rules)}
                <Grid item xs={12}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 5 }}>
                        <Button
                            variant='contained'
                            startIcon={<UploadIcon fontSize="large" />}
                            onClick={() => handleDowload("all")}
                        >
                            Export All Data
                        </Button>
                        <Button
                            variant='contained'
                            startIcon={<DownloadIcon fontSize="large" />}
                            onClick={() => handleUpload("all")}
                        >
                            Import All Data
                        </Button>
                    </div>
                </Grid>
                {
                    Object.entries(productKnewledge).map(([name, data]) => {
                        return renderTableContent(data)
                    }).filter((_, idx) => idx > 0)
                }
            </>
        )
    }

    return (
        <ModalDetail
            open={props.open}
            title={`Apartemen ${props.apartmentName}`}
            onCloseModal={props.onClose}
            isLoading={false}
        >
            <Root>
                <LoadingScreen fullScreen open={isLoading} />
                <Grid container spacing={2}>
                    {renderContent()}
                </Grid>

                <ParkingModal
                    open={parkingModal}
                    initialState={parkingValue}
                    onClose={() => setParkingModal(false)}
                    onSubmit={(value: string) => handleSubmitParking(value)}
                />

                <FormHouseRules
                    open={changeRules}
                    id={props.id}
                    onClose={(refresh: boolean) => {
                        setChangeRules(false)

                        if (refresh) {
                            loadData()
                        }
                    }}
                    rules={
                        productKnewledge.house_rules.tableData
                            ? productKnewledge.house_rules.tableData.map((rule: any) => rule.rule)
                            : []
                    }
                />
            </Root>
        </ModalDetail>
    )
}

export default ProductKnowledge

const useStyles = generateStyle((theme: Theme) => ({
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    box: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    content: {
        marginTop: theme.spacing(2),
    },
}), "ProductKnowledge")




import React, { useMemo } from 'react'

/**
 * Components
 */
import { Divider, Theme } from '@mui/material'
import defaultImage from '../../../Assets/Images/png/avatar.png'

/**
 * Utils
 */
import { FunnelReportItem } from '../FunnelReport'
import { generateStyle } from '../../../_utils/DefaultStyle'

/**
 * Icons
 */
import TargetIcon from '../../../_components/_icons/TargetIcon'
import SandClockIcon from '../../../_components/_icons/SandClockIcon'

interface FunnelReportCardProps extends FunnelReportItem {

}

const FunnelReportCard = (props: FunnelReportCardProps) => {
    const { Root, classes } = useStyles()

    const colors = {
        success: { main: '#2E7D32', light: '#E2F8E3' },
        warning: { main: '#ED6C02', light: '#FFECDC' },
        error: { main: '#D32F2F', light: '#FFE7E7' }
    }

    const renderContent = () => {
        return (
            <>
                <div className={classes.cluster_row}>
                    <span className={classes.cluster_text}>
                        Total Enquiry
                    </span>
                    <span className={classes.cluster_text}>
                        {props.clusters.enquiry_report}
                    </span>
                </div>
                <div className={classes.cluster_row}>
                    <span className={classes.cluster_text}>
                        Greeting
                    </span>
                    <span className={classes.cluster_text}>
                        {props.clusters.greeting_report}
                    </span>
                </div>
                <div className={classes.cluster_row}>
                    <span className={classes.cluster_text}>
                        KYC
                    </span>
                    <span className={classes.cluster_text}>
                        {props.clusters.kyc_report}
                    </span>
                </div>
                <div className={classes.cluster_row}>
                    <span className={classes.cluster_text}>
                        Showing
                    </span>
                    <span className={classes.cluster_text}>
                        {props.clusters.showing_report}
                    </span>
                </div >
                <div className={classes.cluster_row}>
                    <span className={classes.cluster_text}>
                        Cold
                    </span>
                    <span className={classes.cluster_text}>
                        {props.clusters.cold_report}
                    </span>
                </div >
                <div className={classes.cluster_row}>
                    <span className={classes.cluster_text}>
                        Follow Up
                    </span>
                    <span className={classes.cluster_text}>
                        {props.clusters.fu_report}
                    </span>
                </div >
            </>
        )
    }

    const score = useMemo(() => {
        return {
            osa_score: props.clusters.osa_score,
            response_time_score: props.clusters.response_time_score
        }
    }, [props.clusters])

    const generateScoreComponent = (score: {
        osa_score: number,
        response_time_score: number
    }) => {
        const osaColor: keyof typeof colors = score.osa_score >= 75 ? "success" : "error"
        const responseColor: keyof typeof colors = score.response_time_score >= 70 ? "success" : score.response_time_score >= 60 ? "warning" : "error"
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                <div className={classes.chips} style={{ backgroundColor: colors[osaColor].light, color: colors[osaColor].main }}>
                    <TargetIcon height={12} />
                    <span>{score.osa_score > 100 ? '100' : score.osa_score || 'N/A'}</span>
                </div>
                <div className={classes.chips} style={{ backgroundColor: colors[responseColor].light, color: colors[responseColor].main }}>
                    <SandClockIcon height={12} />
                    <span>{score.response_time_score > 100 ? '100' : score.response_time_score || 'N/A'}</span>
                </div>
            </div>
        )
    }

    return (
        <Root>
            <div className={classes.root} style={{ paddingBottom: 4 }}>
                <div className={classes.cluster_row}>
                    <span className={classes.name}>
                        <img src={props.avatar_url || defaultImage} alt="profile" className={classes.avatar} />
                        <div>
                            <div>
                                {props.name}
                            </div>
                            {generateScoreComponent(score)}
                        </div>
                    </span>
                </div>
                <Divider className={classes.divider} />
                <div className="">
                    {
                        renderContent()
                    }
                </div>
            </div>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) =>
({
    root: {
        borderRadius: 20,
        boxShadow: '3px 3px 10px #52575d1a',
        padding: '21px 23px 33px',
        backgroundColor: 'white',
        alignSelf: 'start',
    },
    name: {
        color: '#3D3D3D',
        fontSize: 20,
        fontWeight: 700,
        display: 'flex',
        gap: 10,
        alignItems: 'center'
    },
    score: {
        color: '#3D3D3D',
        fontSize: 20,
        fontWeight: 700,
        textAlign: 'center'
    },
    divider: {
        borderColor: '#EBF2F7',
        marginTop: 13,
        marginBottom: 11,
    },
    cluster_row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 14,
    },
    cluster_text: {
        color: '#3D3D3D',
        fontSize: 14,
    },
    total: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#035AA633',
        padding: '10px 14px',
        borderRadius: 10,
    },
    total_text: {
        color: '#3D3D3D',
        fontSize: 16,
        fontWeight: 500,
    },
    showMoreContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContentL: 'center',
        marginTop: 8,
        cursor: 'pointer',
        opacity: 0.6,
    },
    showMoreText: {
        color: theme.palette.primary.main,
        fontSize: 12,
    },
    showMoreIcon: {
        color: theme.palette.primary.main,
        fontSize: 16,
    },
    more: {
        transform: 'rotate(-90deg)',
    },
    less: {
        transform: 'rotate(90deg)',
    },
    avatar: {
        width: '40px',
        height: '40px',
        borderRadius: '50%'
    },
    clickable: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
    chips: {
        padding: "4px 8px",
        fontSize: 14,
        fontWeight: '700',
        borderRadius: '200px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 3
    }
}), "FunnelReportCard"
)

export default FunnelReportCard
import { IconButton, TextField } from '@mui/material'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import React from 'react'

/* 
* Icons
*/
import ClearIcon from '@mui/icons-material/Clear';

/* 
* Interfaces
*/
interface IDateTimeProps {
    value: any,
    onChange: (value: any) => void
}

export const CustomDatePicker = ({ value, onChange }: IDateTimeProps) => {

    const handleClearDate = () => {
        onChange(null)
    }
    return (
        <DatePicker
            value={value}
            inputFormat='dd-MM-YYY'
            onChange={onChange}
            disableMaskedInput
            InputProps={{
                style: { flexDirection: 'row-reverse', paddingLeft: 1},
                startAdornment: (
                    <IconButton onClick={handleClearDate}>
                        <ClearIcon />
                    </IconButton>
                )
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    fullWidth
                    onKeyDown={(e: any) => e.preventDefault()}
                    label="Request Work Date"
                />}
        />
    )
}

export const CustomTimePicker = ({value, onChange}: IDateTimeProps) => {
    const handleClearTime = () => {
        onChange(null)
    }
    return (
        <TimePicker
            ampm={false}
            value={value}
            onChange={onChange}
            InputProps={{
                style: { flexDirection: 'row-reverse', paddingLeft: 1},
                startAdornment: (
                    <IconButton onClick={handleClearTime}>
                        <ClearIcon />
                    </IconButton>
                )
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    fullWidth
                    onKeyDown={(e: any) => e.preventDefault()}
                    label="Request Work Time"
                />
            }
        />
    )
}
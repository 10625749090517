import React, { useState, useEffect } from "react";
import {
    Theme,
    Grid,
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    Box,
    Divider,
    Button,
    Dialog,
    Chip,
} from "@mui/material";

//Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import BathtubIcon from "@mui/icons-material/Bathtub";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import EditIcon from "@mui/icons-material/Edit";
import PermMediaIcon from '@mui/icons-material/PermMedia';

//Components
// import ImageSlider from "../../_components/_cards/_imageSlider/ImageSlider";
import LoadingCard from "../../_components/_cards/LoadingCard";
import LoadingScreen from "../../_components/LoadingScreen";
import AdvisorPriceForm from './_components/AdvisorPriceForm';
import AdvisorNotesForm from './_components/AdvisorNotesForm';
import AdvisorReportForm from './_components/AdvisorReportForm';
import AdvisorCommentList from './_components/AdvisorCommentList';
import defaultPhoto from '../../Assets/Images/png/default-photo.png';

//Utils
import InfoTable from "../_listingList/InfoTable";
import DefaultAxios from "../../_utils/DefaultAxios";
import { differenceInDays } from 'date-fns';

// Interfaces
// import ImagePreview from "../../_components/_cards/_imageSlider/ImagePreview";
import AdvisorFileList from "./_components/AdvisorFileList";
import { convertIndonesiaPhoneNumber, dateFormat, IFacilities, parseFacility } from "../../_utils/Helper";
import { generateStyle } from "../../_utils/DefaultStyle";

const useStyles = generateStyle((theme: Theme) =>
({
    listContainer: {
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    appBar: {
        position: "relative",
        overflow: 'hidden'
    },
    modalContainer: {
        position: "relative",
        overflowX: 'hidden',
    },
    modalContent: {
        padding: theme.spacing(1)
    },
    cardTitle: {
        lineHeight: "1.5rem",
        marginTop: 8,
        marginBottom: 8
    },
    pill: {
        borderRadius: "1em",
        padding: "6px 13px",
        fontWeight: 400,
        marginRight: 6,
        marginBottom: 7,
        display: "flex",
        flexFlow: "row",
        justifyItems: "center",
        alignItems: "center"
    },
    greenP: {
        background: "#EBFBEE",
        color: "#144738"
    },
    blueP: {
        background: "#EAF0FC",
        color: "#698ABD"
    },
    container: {
        display: "flex",
        flexFlow: "row",
        flexWrap: "wrap"
    },
    iconContainer: {
        background: "#EBFBEE",
        borderRadius: "20%",
        display: "flex",
        padding: 5
    },
    addressText: {
        color: "#00000099",
        margin: `${theme.spacing(2)}px 0`
    },
    containerSB: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: 'sticky',
        bottom: 0,
        left: 0,
        width: '100%',
        background: '#fff'
    },
    header: {
        flexGrow: 1
    },
    descText: {
        fontStyle: "italic",
        color: "#00000066",
        fontWeight: 500,
        margin: `${theme.spacing(1)}px 0`,
        display: 'block',
        whiteSpace: 'pre-line'
    },
    textWithIcon: {
        display: "flex",
        alignItems: "center"
    },
    margin1: {
        margin: `${theme.spacing(1)}px 0`
    },
    margin2: {
        margin: `${theme.spacing(2)}px 0`
    },
    button: {
        width: "45%",
        textTransform: "none",
        margin: `${theme.spacing(2)}px 0`,
        padding: `${theme.spacing(1)}px 0`
    },
    ribbon: {
        position: "absolute",
        transform: "rotate(45deg)",
        fontSize: 10,
        letterSpacing: 0.4,
        zIndex: 1000,
        backgroundColor: "#CF3636",
        width: "200px",
        height: "1.1rem",
        overflow: "hidden",
        color: "white",
        right: "-4.9rem",
        top: "0.9rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 500,
    },
    priceContainer: {
        display: 'flex',
        padding: '1.5rem 0',
        '&.green': {
            backgroundColor: '#f5fdf6',
        },
        '&.gray': {
            backgroundColor: '#f7f7f7',
        },
        '& .column': {
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            '&.fullwidth': {
                width: '100%',
            },
            '&.b-right': {
                borderRight: '1px solid #d9d9d9'
            },
            '&.b-left': {
                borderLeft: '1px solid #d9d9d9'
            },
            '& .price': {
                fontSize: 20,
                fontWeight: 700,
                marginBottom: '0.2rem',
                '&.sell-price': {
                    fontSize: 22
                }
            },
            '& .label': {
                color: '#033A11',
                fontWeight: 400,
            },
        }
    },

    bottom: {
        margin: '0.5rem 0 0.25rem 0',
        '& .contentTwo': {
            margin: 0,
            fontSize: 14,
            color: '#00000099'
        },
        '& .contentOne': {
            margin: 0,
            fontSize: 16,
            color: '#00000099'
        }
    },
    step: {
        display: 'flex',
    },
    img_container: {
        width: "100%",
        height: "158px",
    },
    priceBox: {
        marginBottom: theme.spacing(3)
    },
    commentRoot: {
        paddingBottom: '20px',
        borderBottom: '1px solid #aaa',
        margin: '10px 0'
    },
    commentSection: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    imageComment: {
        display: 'inline-block',
        width: '32px',
        borderRadius: '50%'
    },
    commentBox: {
        marginBottom: theme.spacing(2)
    }
}), "MyUnit_Detail"
);

interface Props {
    onClose: (data: any) => void;
    code: string;
    is_market?: boolean;
    fetchData?: Function;
    permissions: any
}

interface IListingPrice {
    value: string;
    duration: string;
}

interface IListingDetail {
    id: string;
    name: string;
    code: string;
    bedroom: number;
    bathroom: number;
    carport: number;
    landlord_name: string;
    landlord_email: string;
    landlord_phone: string;
    address: string | null
    commission: string;
    last_reported_by_advisor_at: string;
    assigned_to_advisor_at: string
    sell_commission: string | null
    rent_commission: string | null
    apartment_name: null | string;
    floor_level: null | number | string;
    total_floor: null | number;
    tower: null | string;
    furnished: null | string;
    electric_watt: number;
    unit_area: string | null
    view_direction: string;
    direction: string;
    certificate: string;
    building_finished: number;
    listing_type_id: string;
    number: string;
    is_hot: number;
    prices: IListingPrice[];
    display_rent_price: number;
    display_sell_price: number;
    display_currency: string;
    basic_price_1: number
    basic_price_3: number
    basic_price_6: number
    basic_price_12: number
    basic_price_sell: number
    selling_price: number;
    price_per_12_months: number;
    price_per_6_months: number;
    price_per_3_months: number;
    price_per_1_month: number;
    daily_price: string;
    comments: any[]
    pic_listing: {
        name: string;
        phone: string;
        email: string;
    }
    keterangan: string
    image: any[]
    facilities: {
        unit_id: number
        facility_slug: keyof IFacilities
        description: string
    }[]
    is_entrusted_key: number;
    key_holder_name: string;
}

const MyUnitDetail = (props: Props) => {
    const { Root, classes } = useStyles();
    const [modalOpen, setModalOpen] = useState<false | 'price' | 'notes' | 'report' | 'comments' | 'files'>(false)
    const [stateFetchData, setStateFetchData] = useState(false)
    const [priceFor, setPriceFor] = useState('')

    const handleOpenPriceWeb = (data: any) => {
        setPriceFor('web')
        setModalOpen('price')
    }

    const handleOpenPriceBase = (data: any) => {
        setPriceFor('base')
        setModalOpen('price')
    }

    const handleCloseReport = () => {
        setModalOpen(false)
        setStateFetchData(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    const submitSuccess = () => {
        handleCloseReport();
    }

    const [loading, setLoading] = useState(true);
    const [loadingScreenOpen, setLoadingScreenOpen] = useState(false);
    const [data, setData] = useState<IListingDetail>({} as IListingDetail);
    // const [isPreviewOpen, setIsPreviewOpen] = useState(false)

    // const openPreview = () => {
    //     setIsPreviewOpen(true)
    // }

    // const closePreview = () => {
    //     setIsPreviewOpen(false)
    // }

    const loadListing = () => {
        setLoadingScreenOpen(true)
        DefaultAxios.get(
            `${process.env.REACT_APP_API_URL}/my-unit/${props.code}`
        ).then(res => {
            setData(res.data);
            setLoading(false);
            setLoadingScreenOpen(false)
        });
    }

    useEffect(() => {
        loadListing();
        // eslint-disable-next-line
    }, [modalOpen]);

    const furnished = () => {
        switch (data.furnished) {
            case 'non':
                return 'Non Furnished'
            case 'semi':
                return 'Semi Furnished'
            case 'full':
                return 'Full Furnished'
        }
    }

    const renderUnitDetails = (details: any) => {
        return details.map((detail: any, idx: number) => {
            if (!detail[1]) {
                return null
            }
            return (
                <Box
                    className={classes.textWithIcon}
                    style={{ marginRight: 14 }}
                    key={idx}
                >
                    {detail[0]}
                    <Typography
                        variant="caption"
                        style={{ marginLeft: 8, fontWeight: "bold" }}
                    >{`${detail[1] || 0} ${detail[2]}`}</Typography>
                </Box>
            );
        });
    };

    const processNumber = (price: number | null | undefined, is_dollar: number) => {
        return price ? (is_dollar ? '$ ' : "Rp. ") + price.toLocaleString() : '-'
    }

    const renderDayEmpty = () => {
        // let day
        var result = differenceInDays(
            new Date(),
            new Date(data.assigned_to_advisor_at)
        )

        return (
            <Typography variant="caption">
                Lama Kosong : {result} Hari (sejak diassign)
            </Typography>
        )
    }

    const renderComments = () => {
        return data.comments.map((comment, key: number) =>
            <Grid key={key} container className={classes.commentBox}>
                <Grid item xs={2}>
                    <img className={classes.imageComment} src={comment.photo ? comment.photo : defaultPhoto} alt={comment.created_by} />
                </Grid>

                <Grid item xs={10}>
                    <label>
                        <span style={{ fontWeight: 500 }}>{comment.created_by}</span>
                        <span> &bull; {dateFormat(comment.created_at, 'DD MMM')}</span>
                    </label>
                    {/* <div className={classes.descText} style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: comment.comment }}></div> */}
                    <Typography variant="caption" className={classes.descText} style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: `"${comment.comment}"` }}>
                        {/* {`"${data.keterangan}"`} */}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    useEffect(() => {
        if (stateFetchData === true && props.fetchData) {
            props.fetchData(true)
        }
        // eslint-disable-next-line
    }, [stateFetchData, props.fetchData])

    return (
        <Root>
            <LoadingScreen open={loadingScreenOpen} fullScreen />
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.header}>
                        {data.code}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setModalOpen('files')}
                        aria-label="files"
                    >
                        <PermMediaIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box className={classes.modalContainer}>
                <Dialog fullScreen open={modalOpen === 'price'} onClose={handleCloseModal}>
                    <AdvisorPriceForm
                        onSubmitSuccess={submitSuccess}
                        unitId={props.code}
                        priceFor={priceFor}
                        onCloseForm={handleCloseModal} />
                </Dialog>
                <Dialog fullScreen open={modalOpen === 'notes'} onClose={handleCloseModal}>
                    <AdvisorNotesForm
                        onSubmitSuccess={submitSuccess}
                        unitId={props.code}
                        onCloseForm={handleCloseModal} />
                </Dialog>
                <Dialog fullScreen open={modalOpen === 'report'} onClose={handleCloseModal}>
                    <AdvisorReportForm
                        onSubmitSuccess={submitSuccess}
                        unitId={data.id}
                        onCloseForm={handleCloseModal} />
                </Dialog>
                <Dialog fullScreen open={modalOpen === 'comments'} onClose={handleCloseModal}>
                    <AdvisorCommentList
                        onSubmitSuccess={submitSuccess}
                        unitId={data.id}
                        unitCode={data.code}
                        onCloseForm={handleCloseModal} />
                </Dialog>
                <Dialog fullScreen open={modalOpen === 'files'} onClose={handleCloseModal}>
                    <AdvisorFileList
                        unitId={data.id}
                        unitCode={data.code}
                        onClose={handleCloseModal}
                        permissions={props.permissions}
                    />
                </Dialog>
                {loading ? (
                    <LoadingCard />
                ) : (
                    <>
                        {/* {data.is_hot + '' === '1' ? <div className={classes.ribbon}>HOT</div> : ''} */}
                        {/* <ImageSlider img={data.image} index={true} openPreview={openPreview} />
                            {
                                isPreviewOpen ?
                                    <ImagePreview
                                        img={data.image}
                                        index={true}
                                        close={closePreview}
                                    />
                                    : null
                            } */}

                        <Box className={classes.modalContent}>
                            <Typography variant="h6" className={classes.cardTitle}>
                                {data.name}
                            </Typography>

                            <Box
                                className={classes.container}
                                style={{ alignItems: "center" }}
                            >
                                <Typography variant="subtitle2">
                                    Luas Bangunan:
                                    <strong>
                                        {` ${data.unit_area ? parseInt(data.unit_area) : 0
                                            }m`}
                                        <sup>2</sup>
                                    </strong>
                                </Typography>
                            </Box>

                            <Typography variant="subtitle2" className={classes.addressText}>
                                {data.address}
                            </Typography>

                            {differenceInDays(new Date(), new Date(data.last_reported_by_advisor_at)) < 14 ?
                                <>
                                    <div className={classes.priceBox}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography variant="h6" className={classes.cardTitle}>
                                                    Web Price
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{ alignSelf: 'center', textAlign: 'right' }}>
                                                <div onClick={handleOpenPriceWeb} style={{ color: '#2FBDF2', cursor: 'pointer' }}>
                                                    <EditIcon style={{ verticalAlign: 'text-bottom' }} fontSize={'small'} />
                                                    <span>Edit</span>
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <div className={`${classes.priceContainer} gray`}>
                                            <div className="column fullwidth">
                                                <span className="price sell-price">{processNumber(data.selling_price, data.display_currency === "USD" ? 1 : 0)}</span>
                                                <span className="label">selling price</span>
                                            </div>
                                        </div>

                                        <div className={`${classes.priceContainer} green`}>
                                            <div className="column b-right">
                                                <span className="price">{processNumber(data.price_per_12_months, data.display_currency === "USD" ? 1 : 0)}</span>
                                                <span className="label">per tahun</span>
                                            </div>
                                            <div className="column b-left">
                                                <span className="price">{processNumber(data.price_per_6_months, data.display_currency === "USD" ? 1 : 0)}</span>
                                                <span className="label">per 6 bulan</span>
                                            </div>
                                        </div>

                                        <div className={`${classes.priceContainer} gray`}>
                                            <div className="column b-right">
                                                <span className="price">{processNumber(data.price_per_3_months, data.display_currency === "USD" ? 1 : 0)}</span>
                                                <span className="label">per 3 bulan</span>
                                            </div>
                                            <div className="column b-left">
                                                <span className="price">{processNumber(data.price_per_1_month, data.display_currency === "USD" ? 1 : 0)}</span>
                                                <span className="label">per bulan</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.priceBox}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography variant="h6" className={classes.cardTitle}>
                                                    Base Price
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{ alignSelf: 'center', textAlign: 'right' }}>
                                                <div onClick={handleOpenPriceBase} style={{ color: '#2FBDF2', cursor: 'pointer' }}>
                                                    <EditIcon style={{ verticalAlign: 'text-bottom' }} fontSize={'small'} />
                                                    <span>Edit</span>
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <div className={`${classes.priceContainer} gray`}>
                                            <div className="column fullwidth">
                                                <span className="price sell-price">{processNumber(data.basic_price_sell, data.display_currency === "USD" ? 1 : 0)}</span>
                                                <span className="label">selling price</span>
                                            </div>
                                        </div>

                                        <div className={`${classes.priceContainer} green`}>
                                            <div className="column b-right">
                                                <span className="price">{processNumber(data.basic_price_12, data.display_currency === "USD" ? 1 : 0)}</span>
                                                <span className="label">per tahun</span>
                                            </div>
                                            <div className="column b-left">
                                                <span className="price">{processNumber(data.basic_price_6, data.display_currency === "USD" ? 1 : 0)}</span>
                                                <span className="label">per 6 bulan</span>
                                            </div>
                                        </div>

                                        <div className={`${classes.priceContainer} gray`}>
                                            <div className="column b-right">
                                                <span className="price">{processNumber(data.basic_price_3, data.display_currency === "USD" ? 1 : 0)}</span>
                                                <span className="label">per 3 bulan</span>
                                            </div>
                                            <div className="column b-left">
                                                <span className="price">{processNumber(data.basic_price_1, data.display_currency === "USD" ? 1 : 0)}</span>
                                                <span className="label">per bulan</span>
                                            </div>
                                        </div>
                                    </div>


                                    <Box
                                        className={`${classes.container} ${classes.margin2}`}
                                    >
                                        {renderUnitDetails([
                                            [
                                                <LocalHotelIcon
                                                    style={{ fontSize: "20px", opacity: "0.6" }}
                                                />,
                                                data.bedroom === 0 ? 'Studio' : data.bedroom,
                                                data.bedroom === 0 ? '' : "K Tidur"
                                            ],
                                            [
                                                <BathtubIcon
                                                    style={{ fontSize: "20px", opacity: "0.6" }}
                                                />,
                                                data.bathroom,
                                                "K Mandi"
                                            ],
                                            [
                                                <DirectionsCarIcon
                                                    style={{ fontSize: "20px", opacity: "0.6" }}
                                                />,
                                                data.carport,
                                                "Mobil"
                                            ]
                                        ])}
                                    </Box>

                                    {renderDayEmpty()}

                                    <Divider />

                                    <Typography
                                        variant="h6"
                                        style={{ fontWeight: 600 }}
                                        className={classes.margin2}
                                    >
                                        Informasi Properti
                                    </Typography>

                                    <InfoTable
                                        data={[
                                            ["ID Listing", data.code, true],
                                            ["Nama Apartemen", data.name || null],
                                            ["Tower", data.tower || null],
                                            ["Lantai", data.floor_level || null],
                                            // ["Nomor", data.number || "-"],
                                            // ["Jumlah Lantai", data.total_floor || "-"],
                                            ["Furnish", furnished() || "-"],
                                            ["Hadap", data.view_direction || "-"],
                                            [
                                                "Titip Kunci",
                                                <Chip color={data.is_entrusted_key === 1 ? 'primary' : 'secondary'} size="small" label={data.is_entrusted_key === 1 ? 'Ya' : 'Tidak'} />
                                            ],
                                            [
                                                "Pemegang Kunci",
                                                data.is_entrusted_key === 1 ? data.key_holder_name : null
                                            ],
                                            [
                                                "Fasilitas",
                                                data.facilities.reduce((prev, current, currIndex) => prev + `${parseFacility(current.facility_slug)}${currIndex !== (data.facilities.length - 1) ? ', ' : ''}`, '')
                                            ]
                                        ]}
                                    />

                                    <Divider />

                                    <Typography
                                        variant="h6"
                                        style={{ fontWeight: 600 }}
                                        className={classes.margin2}
                                    >
                                        Informasi Pemilik
                                    </Typography>

                                    <InfoTable
                                        data={[
                                            ["Nama", data.landlord_name],
                                            ["Nomor Handphone", data.landlord_phone],
                                            ["Email", data.landlord_email],
                                        ]}
                                    />

                                    <Divider />

                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="h6"
                                                style={{ fontWeight: 600 }}
                                                className={classes.margin2}
                                            >
                                                Catatan
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ alignSelf: 'center', textAlign: 'right' }}>
                                            <div onClick={() => setModalOpen('notes')} style={{ color: '#2FBDF2' }}>
                                                <EditIcon style={{ verticalAlign: 'text-bottom' }} fontSize={'small'} />
                                                <span>Edit</span>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Typography variant="caption" className={classes.descText} style={{ wordBreak: 'break-word' }}>
                                        {data.keterangan ? `"${data.keterangan}"` : " - "}
                                    </Typography>

                                    <Divider />

                                    <Typography
                                        variant="h6"
                                        style={{ fontWeight: 600 }}
                                        className={classes.margin2}
                                    >
                                        Comments
                                    </Typography>
                                    {renderComments()}
                                    <Typography onClick={() => setModalOpen('comments')} style={{ cursor: 'pointer', marginBottom: 8 }} color="primary" variant="subtitle2">
                                        Lihat atau tambahkan komentar
                                    </Typography>

                                    <Box className={classes.containerSB}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<CallIcon />}
                                            className={classes.button}
                                            href={
                                                data.landlord_phone ?
                                                    "tel:+" + convertIndonesiaPhoneNumber(data.landlord_phone.replace(/[^0-9]/, ""))
                                                    : "tel:+" + data.landlord_phone
                                            }
                                        >
                                            Call
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            startIcon={<WhatsAppIcon />}
                                            className={classes.button}
                                            href={
                                                data.landlord_phone ?
                                                    "https://api.whatsapp.com/send?phone=+" +
                                                    convertIndonesiaPhoneNumber(data.landlord_phone.replace(/[^0-9]/, ""))
                                                    : "https://api.whatsapp.com/send?phone=+" + data.landlord_phone
                                            }
                                        >
                                            WhatsApp
                                        </Button>
                                    </Box>

                                </>
                                :
                                <>
                                    <br />
                                    <Box className={classes.container}>
                                        <Button
                                            size="large"
                                            color="secondary"
                                            variant="contained"
                                            fullWidth
                                            onClick={() => setModalOpen('report')}
                                        >
                                            Isi Laporan
                                        </Button>
                                    </Box>
                                    <Typography variant="h5" style={{ textAlign: "center" }}>
                                        Isi Laporan untuk melihat Informasi lebih lanjut
                                    </Typography>
                                </>
                            }
                        </Box>
                    </>
                )}
            </Box>
        </Root>
    );
};

export default MyUnitDetail;

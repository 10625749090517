import React, { useState, useReducer } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import LoadingScreen from '../../../_components/LoadingScreen';
import DefaultAxios from '../../../_utils/DefaultAxios';
import Swal from 'sweetalert2';
import { generalErrorHandler } from '../../../_utils/Helper';

interface IProp {
    closing_id: string;
    extendStatus: number;
    detail: any;
    open: boolean;
    onChange: any;
    onClose?: any;
}

interface IState {
    file: {
        file: File | null
    }
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const DepositModal = (props: IProp) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [isLoading, setIsLoading] = useState(false);

    const initialState = {
        file: {
            file: null
        }
    }

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let value: any = target.value;
        let name = target.name;
        if (name === 'file') {
            const oldFile = { ...inputState.file };
            if (target.files && target.files[0]) {
                oldFile.file = target.files[0];
            } else {
                oldFile.file = null;
            }
            value = oldFile;
            name = 'file';
        }
        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleSubmit = (id: any) => {
        const fd = new FormData();

        for (let key in inputState) {
            if (key === 'file') {
                if (inputState.file.file) {
                    fd.append('file', inputState.file.file);
                }
            }
        }

        let url = "";
        switch (props.extendStatus) {
            case 11:
                url = `${API_URL}/new-closing/file/${id}/deposit-request`;
                break;
            case 12:
                url = `${API_URL}/new-closing/file/${id}/deposit-confirm`;
                break;
            default:
                Swal.fire({
                    title: "Kesalahan Tipe Form, Hubungi Admin",
                    icon: 'error',
                    showCloseButton: true,
                })
                return false;
        }

        if (id) {
            setIsLoading(true);
            DefaultAxios.post(`${url}`, fd).then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    showCloseButton: true,
                    onClose: () => {
                        props.onChange(props.extendStatus === 11 ? 12 : 10)
                    }
                })
            })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false)
                    props.onClose();
                })
        }
    };

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Upload File</DialogTitle>
                <DialogContent>
                    <input type="file" name="file" onChange={handleChanged} style={{ marginBottom: '10px' }} />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleSubmit(props.closing_id)
                        }}
                        color="primary"
                    >
                        Submit
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DepositModal;
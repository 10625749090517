import {
    Button,
    FormControlLabel, MenuItem, TextField,
    InputAdornment,
    Grid,
    Theme,
    CardContent,
    Card,
    // Checkbox, 
    // Typography,
    // Box,
    // FormGroup,
} from "@mui/material";
import TextArea from '../../_components/_form/TextArea';
import Switch from "@mui/material/Switch";
import Autocomplete from '@mui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { currencyToNumber, numberToCurrency } from "../../_utils/Helper";
import { PermissionContext } from "../../_contexts/PermissionContext";
import { generateStyle } from "../../_utils/DefaultStyle";

const SwalContainer = withReactContent(Swal);

const useStyles = generateStyle((theme: Theme) =>
({
    card: {
        maxWidth: '800px',
        marginBottom: '70px',
        boxShadow: '0px 3px 15px #0000001A',
        '& .Mui-disabled': {
            backgroundColor: '#eee'
        }
    },
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        margin: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(5),
        },
    },
    container: {
        // padding: '0 0.5rem',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(10),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabSpacing: {
        marginRight: '1rem',
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(1)
    },
    formContainer: {
        marginTop: theme.spacing(3),
        display: "flex"
    },
    inputText: {
        flexGrow: 1
    },
    buttonSubmit: {
        fontSize: "14px",
        margin: theme.spacing(3)
        // flexGrow: 1,
        // borderRadius: 25
    },
    buttonText: {
        color: '#033A11',
        fontSize: 14,
        textAlign: 'left',
        whiteSpace: 'nowrap'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    appBar: {
        position: 'relative'
    },
    switch: {
        marginTop: "20px",
        marginLeft: theme.spacing(1)
    }
}), "EditListingSubmission"
);

const electricsList = [450, 900, 1300, 2200, 3300, 3500, 4400, 5500, 6600, 7600, 7700, 8000, 9500, 10000,
    10600, 11000, 12700, 13200, 16500, 17600, 19000, 22000, 23000, 24000, 30500, 33000, 38100, 41500, 47500, 53000, 61000, 66000,
    76000, 82500, 85000, 95000, 0
];

interface IParams extends Record<string, string | undefined> {
    rent_id?: string
}
interface IState {
    name: string | null,
    email: string | null,
    phone: string | null,
    whatsapp: string | null,
    apartment_name: string | null,
    listing_name: string | null,
    description: string | null,
    address: string | null,
    price_per_12_months: any | null,
    price_per_6_months: any | null,
    price_per_3_months: any | null,
    price_per_1_month: any | null,
    selling_price: any | null,
    basic_price_1: any | null,
    basic_price_3: any | null,
    basic_price_6: any | null,
    basic_price_12: any | null,
    basic_price_sell: any | null,
    bedroom: number | null,
    bathroom: number | null,
    unit_number: string | null,
    view: string | null,
    floor: string | null,
    total_floor: number | null,
    area: string | null,
    tower: string | null,
    listing_type: number | null,
    latitude: number | null,
    longitude: number | null,
    ownership_type: number | null,
    unit_for: string | null,
    furnish: string | null,
    source: string | null,
    know_from: string | null,
    status: number | null,
    facilities: any,
    is_dollar: string | undefined,
    is_paid_listing: string | undefined,
    land_area: any | null,
    maid_room: number | null,
    property_area: any | null,
    request_unit_type: number | null,
    district_id: number | null,
    unit: any,
    electric_watt: number | null,
    payment_proof_image: {
        url: string;
        file: File | null;
    }
}

export interface ImageState {
    id: number | null;
    asset_url: string;
}

interface IStateError {
    name?: string;
    email?: string;
    phone?: string;
    whatsapp?: string;
    apartment_name?: string;
    listing_name?: string;
    description?: string;
    price_per_12_months?: string;
    price_per_6_months?: string;
    price_per_3_months?: string;
    price_per_1_month?: string;
    basic_price_1?: string;
    basic_price_3?: string;
    basic_price_6?: string;
    basic_price_12?: string;
    basic_price_sell?: string;
    selling_price?: string;
    bedroom?: number;
    bathroom?: number;
    unit_number?: string;
    view?: string;
    floor?: string;
    total_floor: number,
    area?: string;
    tower?: string;
    listing_type?: number;
    ownership_type?: number;
    unit_for?: string;
    furnish?: string;
    is_dollar?: string;
    is_paid_listing?: string;
    land_area?: string;
    maid_room?: number;
    property_area?: string;
    request_unit_type?: number;
    electric_watt: number;
}

interface IStateErrorAction {
    name: string;
    value: object | Date | null | string | number | Array<IActionValue>;
}

interface IActionValue {
    name: string;
    value: string;
}

interface IListObject {
    id: number,
    label: string
}



const EditListingSubmission = () => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();

    const convertDataToNumber = [
        'price_per_12_months', 'price_per_6_months', 'price_per_3_months', 'price_per_1_month', 'selling_price', 'basic_price_1', 'basic_price_3', 'basic_price_6', 'basic_price_12', 'basic_price_sell'
        // 'land_area', 'property_area'
    ];

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const isDisabled = false;

    const navigate = useNavigate();

    const params = useParams<IParams>()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [open, setOpen] = useState(true);

    // // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const [optionFacilities, setOptionFacilities] = useState([
    //     "ac",
    //     "air-panas",
    //     "access-card",
    //     "kulkas",
    //     "mesin-cuci",
    //     "tv",
    // ]);

    // const facilities = [
    //     { value: 'ac', label: 'AC' },
    //     { value: 'air-panas', label: 'Air Panas' },
    //     { value: 'dining-set', label: 'Dining Set' },
    //     { value: 'listrik', label: 'Listrik' },
    //     { value: 'tempat-tidur', label: 'Tempat Tidur' },
    //     { value: 'access-card', label: 'Access Card' },
    //     { value: 'dapur', label: 'Dapur' },
    //     { value: 'kulkas', label: 'Kulkas' },
    //     { value: 'mesin-cuci', label: 'Mesin Cuci' },
    //     { value: 'tv', label: 'TV' }
    // ];


    const [apartmentList, setApartmentList] = useState<Array<IListObject>>([])

    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState<IState>({
        name: "",
        email: null,
        phone: "",
        whatsapp: "",
        apartment_name: "",
        listing_name: "",
        description: "",
        address: null,
        price_per_12_months: "",
        price_per_6_months: "",
        price_per_3_months: "",
        price_per_1_month: "",
        selling_price: "",
        basic_price_1: "",
        basic_price_3: "",
        basic_price_6: "",
        basic_price_12: "",
        basic_price_sell: "",
        bedroom: 0,
        bathroom: 0,
        unit_number: null,
        view: null,
        floor: null,
        total_floor: 0,
        area: null,
        tower: null,
        listing_type: 0,
        ownership_type: 0,
        unit_for: "sewa",
        furnish: "non",
        source: "",
        know_from: "",
        status: null,
        facilities: [],
        unit: [],
        is_dollar: "0",
        is_paid_listing: "0",
        land_area: null,
        maid_room: null,
        property_area: null,
        request_unit_type: 0,
        district_id: 0,
        latitude: null,
        longitude: null,
        electric_watt: 0,
        payment_proof_image: {
            url: '',
            file: null
        }
    });

    const renderElectric = electricsList.map((element, idx) => (<option key={idx} value={element}>{element === 0 ? 'Lainnya' : element}</option>))

    useEffect(() => {

        document.body.style.backgroundColor = '#eee';
        let timeout: any;

        return () => {
            clearTimeout(timeout);
            document.body.style.backgroundColor = '#fff';
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setIsLoading(true);

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/autocomplete/apartment/complete`)
            .then(res => res.data)
            .then(res => {
                setApartmentList(res)
            })

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/rent/${params.rent_id}`)
            .then(res => {
                const imageGallery = res.data.rent_submission_assets;
                const newImageStates = [];

                for (let key in imageGallery) {
                    newImageStates.push({
                        id: imageGallery[key].id,
                        asset_url: imageGallery[key].asset_url,
                    })
                }

                setState({
                    ...state,
                    name: res.data.name,
                    email: res.data.email,
                    phone: res.data.phone,
                    whatsapp: res.data.whatsapp,
                    apartment_name: res.data.apartment_name,
                    listing_name: res.data.listing_name,
                    description: res.data.description,
                    address: res.data.address,
                    listing_type: res.data.listing_type,
                    price_per_12_months: res.data.price_per_12_months,
                    price_per_6_months: res.data.price_per_6_months,
                    price_per_3_months: res.data.price_per_3_months,
                    price_per_1_month: res.data.price_per_1_month,
                    selling_price: res.data.selling_price,
                    basic_price_1: res.data.basic_price_1,
                    basic_price_3: res.data.basic_price_3,
                    basic_price_6: res.data.basic_price_6,
                    basic_price_12: res.data.basic_price_12,
                    basic_price_sell: res.data.basic_price_sell,
                    bedroom: res.data.bedroom !== "" ? res.data.bedroom ?? 0 : 0,
                    bathroom: res.data.bathroom !== "" ? res.data.bathroom ?? 0 : 0,
                    maid_room: res.data.maid_room !== "" ? res.data.maid_room ?? 0 : 0,
                    unit_number: res.data.unit_number ?? "",
                    view: res.data.view ?? "",
                    floor: res.data.floor,
                    total_floor: res.data.total_floor,
                    area: res.data.area,
                    tower: res.data.tower,
                    ownership_type: res.data.ownership_type ?? 0,
                    unit_for: res.data.unit_for ?? "sewa",
                    furnish: res.data.furnish ?? "non",
                    source: res.data.source,
                    know_from: res.data.know_from,
                    status: res.data.status,
                    facilities: res.data.facilities === null || res.data.facilities === "" ? [] : res.data.facilities.split(","),
                    unit: res.data.unit,
                    is_dollar: (res.data.is_dollar === 0 ? "0" : "1"),
                    is_paid_listing: (res.data.is_paid_listing === 0 ? "0" : "1"),
                    land_area: res.data.land_area,
                    property_area: res.data.property_area,
                    district_id: res.data.district_id,
                    request_unit_type: res.data.request_unit_type,
                    latitude: res.data.latitude,
                    longitude: res.data.longitude,
                    electric_watt: res.data.electric_watt,
                    payment_proof_image: {
                        url: res.data.payment_proof_image_url || '',
                        file: null
                    }
                })
            })
            .catch(() => {
                enqueueSnackbar('Terjadi kesalahan pada server, harap hubungi admin', { variant: 'error' })
            })
            .finally(() => {
                setIsLoading(false)
            })
        // eslint-disable-next-line
    }, [])

    const errorReducer = (state: IStateError, action: IStateErrorAction) => {
        if (action.name === "reset") {
            return {
                name: "",
            }
        } else if (action.name === "list_of_errors") {
            let newState: any = {};

            const list_errors = (action.value as Array<IActionValue>);
            list_errors.map(data => {
                newState[data.name] = data.value;
                return true;
            });

            return { ...newState };
        } else {
            return {
                ...state,
                [action.name]: action.value
            };
        }
    };

    const [errorText, setError] = useReducer(errorReducer, {});

    const checkPriceError = (state: IState) => {
        let valid = true
        let list_of_errors = []

        if (state.listing_type === 0) {

            if (state.basic_price_1 > state.price_per_1_month) {
                valid = false;
                list_of_errors.push({ name: "basic_price_1", value: 'Harga basic tidak boleh lebih besar dari harga sewa' })
                list_of_errors.push({ name: "price_per_1_month", value: 'Harga sewa tidak boleh lebih kecil dari harga basic' })
            }

            if (state.basic_price_3 > state.price_per_3_months) {
                valid = false;
                list_of_errors.push({ name: "basic_price_3", value: 'Harga basic tidak boleh lebih besar dari harga sewa' })
                list_of_errors.push({ name: "price_per_3_months", value: 'Harga sewa tidak boleh lebih kecil dari harga basic' })
            }

            if (state.basic_price_6 > state.price_per_6_months) {
                valid = false;
                list_of_errors.push({ name: "basic_price_6", value: 'Harga basic tidak boleh lebih besar dari harga sewa' })
                list_of_errors.push({ name: "price_per_6_months", value: 'Harga sewa tidak boleh lebih kecil dari harga basic' })
            }
            if (state.basic_price_12 > state.price_per_12_months) {
                valid = false;
                list_of_errors.push({ name: "basic_price_12", value: 'Harga basic tidak boleh lebih besar dari harga sewa' })
                list_of_errors.push({ name: "price_per_12_months", value: 'Harga sewa tidak boleh lebih kecil dari harga basic' })
            }
            if (state.basic_price_sell > state.selling_price) {
                valid = false;
                list_of_errors.push({ name: "basic_price_sell", value: 'Harga basic tidak boleh lebih besar dari harga jual' })
                list_of_errors.push({ name: "selling_price", value: 'Harga jual tidak boleh lebih kecil dari harga basic' })
            }
        }
        if (!valid && list_of_errors.length) {
            return setError({ name: "list_of_errors", value: list_of_errors });
        }

        setError({ name: "reset", value: "" });
    }

    const handleChange = (name: keyof typeof state) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setError({ name: name, value: '' })
        if (convertDataToNumber.includes(name)) {
            setState({
                ...state,
                [name]: currencyToNumber(event.target.value as string)
            });
        } else if (name === 'payment_proof_image') {
            const newState = { ...state };
            if (event.target.files && event.target.files[0]) {
                newState.payment_proof_image.file = event.target.files[0];
            } else {
                newState.payment_proof_image.file = null;
            }

            setState(newState);
        } else if (name === 'property_area' || name === 'land_area') {
            setState({
                ...state,
                [name]: event.target.value.replace(/[^\d.]/g, "")
            })
        } else {
            setState({
                ...state,
                [name]: event.target.value
            });
        }
    };

    const handleChangeDollar = (event: React.ChangeEvent<HTMLInputElement>) => {
        let dollar = event.target.checked === true ? "1" : "0";
        setState({ ...state, is_dollar: dollar });
    };

    const handleChangePaid = (event: React.ChangeEvent<HTMLInputElement>) => {
        let paid = event.target.checked === true ? "1" : "0";
        // eslint-disable-next-line
        setState({ ...state, ['is_paid_listing']: paid });
    };

    // const handleChangeFacilities = (e: any) => {
    //     const target = e.target
    //     const tmp = state.facilities.slice();
    //     if (target.checked) {
    //         tmp.push(target.value);
    //     } else {
    //         const index = tmp.indexOf(target.value);
    //         if (index > -1) {
    //             tmp.splice(index, 1);
    //         }
    //     }
    //     setState((prev) => ({ ...prev, facilities: tmp }))
    // }

    const handleChangeNumber = (name: keyof typeof state) => (
        event: React.ChangeEvent<{ value: any }>
    ) => {
        if (!event.target.value) {
            setState({
                ...state,
                [name]: ''
            });
        } else {
            if (event.target.value.match(/([0-9])+/g)) {
                let value = event.target.value.match(/([0-9])+/g)[0];
                setState({ ...state, [name]: value });
            }
        }
    };

    const formValidate = (submit: boolean) => {
        let valid = true;
        // setIsLoading(true);
        setError({ name: "reset", value: "" });
        const list_of_errors = [];

        if (state.name === "") {
            valid = false;
            list_of_errors.push({ name: "name", value: "Field harus diisi!" });
        }

        if (state.email === "") {
            valid = false;
            list_of_errors.push({ name: "email", value: "Field harus diisi!" });
        }

        if (state.phone === "") {
            valid = false;
            list_of_errors.push({ name: "phone_number", value: "Field harus diisi!" });
        }

        if (state.listing_type === 0) {
            if (state.apartment_name === "") {
                valid = false;
                list_of_errors.push({ name: "apartment_name", value: "Field harus diisi!" });
            }

            if (state.basic_price_1 > state.price_per_1_month) {
                valid = false;
                list_of_errors.push({ name: "basic_price_1", value: 'Harga basic tidak boleh lebih besar dari harga sewa' })
                list_of_errors.push({ name: "price_per_1_month", value: 'Harga sewa tidak boleh lebih kecil dari harga basic' })
            }

            if (state.basic_price_3 > state.price_per_3_months) {
                valid = false;
                list_of_errors.push({ name: "basic_price_3", value: 'Harga basic tidak boleh lebih besar dari harga sewa' })
                list_of_errors.push({ name: "price_per_3_months", value: 'Harga sewa tidak boleh lebih kecil dari harga basic' })
            }

            if (state.basic_price_6 > state.price_per_6_months) {
                valid = false;
                list_of_errors.push({ name: "basic_price_6", value: 'Harga basic tidak boleh lebih besar dari harga sewa' })
                list_of_errors.push({ name: "price_per_6_months", value: 'Harga sewa tidak boleh lebih kecil dari harga basic' })
            }
            if (state.basic_price_12 > state.price_per_12_months) {
                valid = false;
                list_of_errors.push({ name: "basic_price_12", value: 'Harga basic tidak boleh lebih besar dari harga sewa' })
                list_of_errors.push({ name: "price_per_12_months", value: 'Harga sewa tidak boleh lebih kecil dari harga basic' })
            }
            if (state.basic_price_sell > state.selling_price) {
                valid = false;
                list_of_errors.push({ name: "basic_price_sell", value: 'Harga basic tidak boleh lebih besar dari harga jual' })
                list_of_errors.push({ name: "selling_price", value: 'Harga jual tidak boleh lebih kecil dari harga basic' })
            }
        }

        if (state.listing_type !== 0) {
            if (state.listing_name === "") {
                valid = false;
                list_of_errors.push({ name: "listing_name", value: "Field harus diisi!" });
            }
            if (state.description === "") {
                valid = false;
                list_of_errors.push({ name: "description", value: "Field harus diisi!" });
            }
        }

        // if (state.price_for_1_year === "" || state.price_for_6_months === "" || state.selling_price === "") {
        //     valid = false;
        //     list_of_errors.push({ name: "price_for_1_year", value: "Salah satu harga harus diisi!" });
        // }

        if (state.unit_for === "") {
            valid = false;
            list_of_errors.push({ name: "unit_for", value: "Field harus diisi!" });
        }

        if (state.unit_for !== "" && state.unit_for === "jual" && state.selling_price === "") {
            valid = false;
            list_of_errors.push({ name: "selling_price", value: "Field harus diisi!" });
        }

        // if (state.unit_for !== "" && state.unit_for === "sewa" && state.price_for_6_months === "") {
        //     valid = false;
        //     list_of_errors.push({ name: "price_for_6_months", value: "Salah Satu Field harus diisi!" });
        // }

        // if (state.floor === null || state.floor === "") {
        //     valid = false;
        //     list_of_errors.push({ name: "floor", value: "Field harus diisi!" });
        // }

        // if (state.view === "") {
        //     valid = false;
        //     list_of_errors.push({ name: "view", value: "Field harus diisi!" });
        // }

        // if (state.area === null) {
        //     valid = false;
        //     list_of_errors.push({ name: "area", value: "Field harus diisi!" });
        // }

        // if (state.unit_number === "") {
        //     valid = false;
        //     list_of_errors.push({ name: "unit_number", value: "Field harus diisi!" });
        // }

        if (state.bedroom === null) {
            valid = false;
            list_of_errors.push({ name: "bedroom", value: "Field harus diisi!" });
        }

        if (!valid && submit) {
            var errorJoin = [];
            // eslint-disable-next-line
            for (let [key, value] of Object.entries(list_of_errors)) {
                errorJoin.push(Object.values(value).join(" "))
            }
            SwalContainer.fire({
                title: "Error",
                html: "Data tidak valid pada: <ul><li style='text-align:left'>" + errorJoin.join("</li><li style='text-align:left'>") + "</li></ul>",
                icon: 'error',
            })
        }

        if (valid) {
            // setIsLoading(false)

            if (submit) {
                const fd = new FormData();
                fd.append('_method', 'put');

                for (let [key, value] of Object.entries(state)) {
                    if (value !== null) {
                        if (key === 'payment_proof_image') {
                            if (value.file) {
                                fd.append(key, value.file);
                            }
                        } else if (key === 'facilities') {
                            for (let facility of value) {
                                fd.append('facilities[]', facility);
                            }
                        } else {
                            fd.append(key, value);
                        }
                    }
                }

                const promiseAxios = new Promise(resolve => {
                    resolve(DefaultAxios.post(`${process.env.REACT_APP_API_URL}/rent/${params.rent_id}`, fd));
                });

                setIsLoading(true);
                promiseAxios
                    .then(res => {
                        setState((prevState) => ({
                            ...prevState
                        }))
                        window.scrollTo(0, 0)

                        if (true) {
                            enqueueSnackbar('Data berhasil diupdate', { variant: 'success' })
                            navigate({
                                pathname: `/listing-submission/${params.rent_id}`
                                // state: { closeModal: true }
                            })
                            // navigate(`/listing-submission`)
                        } else {
                            enqueueSnackbar('Data berhasil diubah.', { variant: 'success' })
                        }
                    })
                    .catch(error => {
                        if (typeof error.response.status === 'undefined') {
                            enqueueSnackbar('Error pada script, harap hubungi admin', { variant: 'error' })
                        } else if (error.response.status === 422) {
                            let errorJoin: any = [];
                            // eslint-disable-next-line
                            for (let [key, value] of Object.entries(error.response.data.errors)) {
                                setError({ name: key.toLowerCase(), value: value as string });
                                let valobj = new Array(value);
                                var text = valobj.join(', ');
                                errorJoin.push(text as string)
                            }
                            SwalContainer.fire({
                                title: "Error",
                                html: "Data tidak valid pada: <ul><li style='text-align:left'>" + errorJoin.join("</li><li style='text-align:left'>") + "</li></ul>",
                                icon: 'error',
                            })
                        } else if (error.response.status === 500) {
                            enqueueSnackbar('Terjadi kesalahan pada server, harap hubungi admin', { variant: 'error' })
                        }
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            }

            return valid


        } else {
            setError({ name: "list_of_errors", value: list_of_errors })
            setIsLoading(false)
        }
    }

    // const handleFacilities = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     var newState = state.facilities
    //     if (state.facilities.includes(event.target.name) === false) {
    //         newState.push(event.target.name)
    //     } else {
    //         const index = newState.indexOf(event.target.name);
    //         if (index > -1) {
    //             newState.splice(index, 1);
    //         }
    //     }
    //     setState({ ...state, facilities: newState })
    // }

    // const renderOptions = () => {
    //     var rows: any = [];
    //     // eslint-disable-next-line
    //     for (let [key, value] of Object.entries(optionFacilities)) {
    //         rows.push(<FormControlLabel
    //             control={<Checkbox disabled={isDisabled} checked={state.facilities.includes(value)} onChange={handleFacilities} name={value} />}
    //             label={makeTitle(value)}
    //         />)
    //     }
    //     return rows;
    // }

    // const makeTitle = (slug: string) => {
    //     var words = slug.split('-');

    //     for (var i = 0; i < words.length; i++) {
    //         var word = words[i];
    //         words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    //     }

    //     return words.join(' ');
    // }

    const handleApartmentChange = (e: any, newValue: string | null) => {
        setState({
            ...state,
            apartment_name: newValue
        })
    }

    useEffect(() => {
        checkPriceError(state)
    }, [state])

    return (
        <Root>
            <Card className={classes.card}>
                <CardContent>
                    <Grid className={classes.root}>
                        <LoadingScreen open={isLoading} fullScreen />

                        {/* <Box style={{ display: "flex", flexFlow: "column" }}>
                <div className={classes.container}>
                    <ListItem button onClick={handleExpand}>
                        <ListItemIcon>
                            <VisibilityIcon />
                        </ListItemIcon>
                        <ListItemText primary="Click here to Show Images" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto">
                        <Box className={classes.formContainer}>
                            {renderImages()}
                        </Box>
                    </Collapse>

                </div>
            </Box> */}

                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    className={classes.inputText}
                                    error={!!errorText.name}
                                    helperText={errorText.name}
                                    label="Name"
                                    name="name"
                                    variant="outlined"
                                    value={state.name}
                                    disabled={isDisabled}
                                    onChange={handleChange("name")}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    className={classes.inputText}
                                    error={!!errorText.email}
                                    helperText={errorText.email}
                                    label="Email"
                                    name="email"
                                    variant="outlined"
                                    value={state.email}
                                    disabled={isDisabled}
                                    onChange={handleChange("email")}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    className={classes.inputText}
                                    error={!!errorText.phone}
                                    helperText={errorText.phone}
                                    label="Phone"
                                    name="phone"
                                    variant="outlined"
                                    value={state.phone}
                                    disabled={isDisabled}
                                    onChange={handleChangeNumber("phone")}
                                />
                            </Grid>
                            {
                                state.listing_type === 0 ?
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={apartmentList.map((option) => option.label)}
                                            fullWidth
                                            disabled={isDisabled}
                                            renderInput=
                                            {
                                                params => <TextField {...params}
                                                    label="Apartment Name"
                                                    variant="outlined"
                                                    name="apartment_name"
                                                    error={!!errorText.apartment_name}
                                                    helperText={errorText.apartment_name}
                                                />
                                            }
                                            value={state.apartment_name}
                                            onChange={handleApartmentChange}
                                        />
                                    </Grid>
                                    :
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.inputText}
                                            error={!!errorText.listing_name}
                                            helperText={errorText.listing_name}
                                            label="Listing Name"
                                            name="listing_name"
                                            variant="outlined"
                                            value={state.listing_name}
                                            fullWidth
                                            disabled={isDisabled}
                                            onChange={handleChange("listing_name")}
                                        />
                                    </Grid>
                            }

                            <Grid item xs={12}>
                                <TextArea
                                    label="Description"
                                    name="description"
                                    value={state.description}
                                    onChange={handleChange("description")}
                                />
                            </Grid>

                            {
                                state.listing_type !== 0 ?
                                    <Grid item xs={12}>
                                        <TextArea
                                            label="Address"
                                            name="address"
                                            value={state.address}
                                            onChange={handleChange("address")}
                                        />
                                    </Grid>
                                    : null
                            }

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    className={classes.inputText}
                                    error={!!errorText.unit_for}
                                    helperText={errorText.unit_for}
                                    label="Unit For"
                                    name="unit_for"
                                    variant="outlined"
                                    select
                                    value={state.unit_for}
                                    disabled={isDisabled}
                                    onChange={handleChange("unit_for")}
                                >
                                    <MenuItem value="sewa">Sewa</MenuItem>
                                    <MenuItem value="jual">Jual</MenuItem>
                                    <MenuItem value="sewa-jual">Sewa & Jual</MenuItem>
                                </TextField>
                            </Grid>

                            {
                                state.listing_type === 0 ?
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            className={classes.inputText}
                                            error={!!errorText.request_unit_type}
                                            helperText={errorText.request_unit_type}
                                            label="Unit Type"
                                            name="request_unit_type"
                                            variant="outlined"
                                            select
                                            value={state.request_unit_type}
                                            disabled={isDisabled}
                                            onChange={handleChange("request_unit_type")}
                                        >
                                            <MenuItem value="0">Reguler</MenuItem>
                                            <MenuItem value="1">Star Listing</MenuItem>
                                        </TextField>
                                    </Grid>
                                    : <></>
                            }

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    className={classes.inputText}
                                    error={!!errorText.ownership_type}
                                    helperText={errorText.ownership_type}
                                    label="Ownership"
                                    name="ownership_type"
                                    variant="outlined"
                                    select
                                    value={state.ownership_type}
                                    disabled={isDisabled}
                                    onChange={handleChange("ownership_type")}
                                >
                                    <MenuItem value="1">Owner</MenuItem>
                                    <MenuItem value="0">Agent</MenuItem>
                                </TextField>
                            </Grid>

                            {(state.listing_type === 0) &&
                                <>

                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            className={classes.inputText}
                                            error={!!errorText.tower}
                                            helperText={errorText.tower}
                                            label="Tower"
                                            name="tower"
                                            variant="outlined"
                                            value={state.tower}
                                            disabled={isDisabled}
                                            onChange={handleChange("tower")}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            className={classes.inputText}
                                            error={!!errorText.floor}
                                            helperText={errorText.floor}
                                            label="Floor"
                                            name="floor"
                                            variant="outlined"
                                            value={state.floor}
                                            disabled={isDisabled}
                                            onChange={handleChange("floor")}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            className={classes.inputText}
                                            error={!!errorText.unit_number}
                                            helperText={errorText.unit_number}
                                            label="Unit Number"
                                            name="unit_number"
                                            variant="outlined"
                                            value={state.unit_number}
                                            disabled={isDisabled}
                                            onChange={handleChange("unit_number")}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>

                                </>
                            }

                            {
                                state.listing_type !== 3 ?
                                    <>
                                        {(state.listing_type !== 0) &&
                                            <>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label="Electrical Watt"
                                                        name="electric_watt"
                                                        variant="outlined"
                                                        value={state.electric_watt}
                                                        onChange={handleChange("electric_watt")}
                                                        fullWidth
                                                        disabled={isDisabled}
                                                        error={errorText.electric_watt ? true : false}
                                                        helperText={errorText.electric_watt}
                                                        select
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: !!state.electric_watt
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        {renderElectric}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        fullWidth
                                                        className={classes.inputText}
                                                        error={!!errorText.total_floor}
                                                        helperText={errorText.total_floor}
                                                        label="Total Floor"
                                                        name="total_floor"
                                                        variant="outlined"
                                                        value={state.total_floor}
                                                        disabled={isDisabled}
                                                        onChange={handleChange("total_floor")}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </Grid>
                                            </>
                                        }

                                        {
                                            state.listing_type === 0 || state.listing_type === 2 ?
                                                <Grid item xs={4}>
                                                    <TextField
                                                        fullWidth
                                                        className={classes.inputText}
                                                        error={!!errorText.bedroom}
                                                        helperText={errorText.bedroom}
                                                        label="Bedroom"
                                                        name="bedroom"
                                                        variant="outlined"
                                                        type="number"
                                                        value={state.bedroom}
                                                        disabled={isDisabled}
                                                        onChange={handleChange("bedroom")}
                                                        InputLabelProps={{ shrink: true }}
                                                        select
                                                    >
                                                        <MenuItem value="0">Studio</MenuItem>
                                                        <MenuItem value="1">1BR</MenuItem>
                                                        <MenuItem value="2">2BR</MenuItem>
                                                        <MenuItem value="3">3BR</MenuItem>
                                                        <MenuItem value="4">4BR</MenuItem>
                                                        <MenuItem value="5">5BR</MenuItem>
                                                        <MenuItem value="6">6BR</MenuItem>
                                                        <MenuItem value="7">7BR</MenuItem>
                                                        <MenuItem value="8">8BR</MenuItem>
                                                        <MenuItem value="9">9BR</MenuItem>
                                                        <MenuItem value="10">10BR</MenuItem>

                                                    </TextField>
                                                </Grid>
                                                : null
                                        }

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                className={classes.inputText}
                                                error={!!errorText.bathroom}
                                                helperText={errorText.bathroom}
                                                label="Bathroom"
                                                name="bathroom"
                                                variant="outlined"
                                                type="number"
                                                value={state.bathroom}
                                                disabled={isDisabled}
                                                onChange={handleChange("bathroom")}
                                                InputLabelProps={{ shrink: true }}
                                                select
                                            >
                                                <MenuItem value="0">None</MenuItem>
                                                <MenuItem value="1">1BA</MenuItem>
                                                <MenuItem value="2">2BA</MenuItem>
                                                <MenuItem value="3">3BA</MenuItem>
                                                <MenuItem value="4">4BA</MenuItem>
                                                <MenuItem value="5">5BA</MenuItem>
                                                <MenuItem value="6">6BA</MenuItem>
                                                <MenuItem value="7">7BA</MenuItem>
                                                <MenuItem value="8">8BA</MenuItem>
                                                <MenuItem value="9">9BA</MenuItem>
                                                <MenuItem value="10">10BA</MenuItem>
                                            </TextField>
                                        </Grid>
                                    </>
                                    : null
                            }


                            {state.listing_type !== 3 ?
                                <>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            className={classes.inputText}
                                            error={!!errorText.maid_room}
                                            helperText={errorText.maid_room}
                                            label="Maid Room"
                                            name="maid_room"
                                            variant="outlined"
                                            value={state.maid_room as number}
                                            disabled={isDisabled}
                                            onChange={handleChange("maid_room")}
                                            InputLabelProps={{ shrink: true }}
                                            select
                                        >
                                            <MenuItem value={'0'}>None</MenuItem>
                                            <MenuItem value={'1'}>1 MR</MenuItem>
                                            <MenuItem value={'2'}>2 MR</MenuItem>
                                            <MenuItem value={'3'}>3 MR</MenuItem>
                                            <MenuItem value={'4'}>4 MR</MenuItem>
                                            <MenuItem value={'5'}>5 MR</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            className={classes.inputText}
                                            error={!!errorText.property_area}
                                            helperText={errorText.property_area}
                                            label="Property Area"
                                            name="property_area"
                                            variant="outlined"
                                            value={state.property_area as number}
                                            disabled={isDisabled}
                                            onChange={handleChange("property_area")}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">m2</InputAdornment>,
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    {
                                        state.listing_type !== 0 ?
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    className={classes.inputText}
                                                    error={!!errorText.land_area}
                                                    helperText={errorText.land_area}
                                                    label="Land Area"
                                                    name="land_area"
                                                    variant="outlined"
                                                    value={state.land_area as number}
                                                    disabled={isDisabled}
                                                    onChange={handleChange("land_area")}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">m2</InputAdornment>,
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                            : null
                                    }
                                </>
                                : <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        className={classes.inputText}
                                        error={!!errorText.land_area}
                                        helperText={errorText.land_area}
                                        label="Land Area"
                                        name="land_area"
                                        variant="outlined"
                                        value={state.land_area as number}
                                        disabled={isDisabled}
                                        onChange={handleChange("land_area")}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">m2</InputAdornment>,
                                        }}
                                        // inputProps={{
                                        //     step: 0.01
                                        // }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            }

                            {
                                state.listing_type !== 0 ?
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            className={classes.inputText}
                                            error={!!errorText.latitude}
                                            helperText={errorText.latitude}
                                            label="Latitude"
                                            name="latitude"
                                            variant="outlined"
                                            value={state.latitude}
                                            disabled={isDisabled}
                                            onChange={handleChange("latitude")}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    : <></>
                            }

                            {
                                state.listing_type !== 0 ?
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            className={classes.inputText}
                                            error={!!errorText.longitude}
                                            helperText={errorText.longitude}
                                            label="Longitude"
                                            name="longitude"
                                            variant="outlined"
                                            value={state.longitude}
                                            disabled={isDisabled}
                                            onChange={handleChange("longitude")}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    : <></>
                            }

                            {/* <Grid item xs={12}>
                    <TextField
                        className={classes.inputText}
                        error={!!errorText.address}
                        helperText={errorText.area}
                        label="Area"
                        name="area"
                        variant="outlined"
                        value={state.area}
                        disabled={isDisabled}
                        onChange={handleChange("area")}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid> */}

                            {
                                state.listing_type === 0 || state.listing_type === 2 ?
                                    <>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                className={classes.inputText}
                                                error={!!errorText.furnish}
                                                helperText={errorText.furnish}
                                                label="Condition"
                                                name="furnish"
                                                variant="outlined"
                                                select
                                                value={state.furnish}
                                                disabled={isDisabled}
                                                onChange={handleChange("furnish")}
                                            >
                                                <MenuItem value="full">Full</MenuItem>
                                                <MenuItem value="semi">Semi</MenuItem>
                                                <MenuItem value="non">Non</MenuItem>
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                className={classes.inputText}
                                                error={!!errorText.view}
                                                helperText={errorText.view}
                                                label="View"
                                                name="view"
                                                variant="outlined"
                                                value={state.view}
                                                disabled={isDisabled}
                                                onChange={handleChange("view")}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>

                                        {/* <Grid item xs={12}>
                                        <Typography variant="subtitle2">Facilities</Typography>
                                        <Box className={classes.formContainer}>
                                            {
                                                renderOptions
                                            }
                                        </Box>
                                    </Grid> */}
                                    </>
                                    : null
                            }


                            <Grid container>
                                <Grid item xs={6} sm={2}>
                                    <FormControlLabel
                                        control={<Switch
                                            name="is_dollar"
                                            disabled={isDisabled}
                                            checked={state.is_dollar === "0" ? false : true}
                                            onChange={handleChangeDollar}
                                            color="secondary"
                                        />}
                                        label="Dollar"
                                        className={classes.switch}
                                    />
                                </Grid>

                                {
                                    !permissions['submission.change-paid-listing'] ?
                                        null
                                        : <Grid item xs={6} sm={6}>
                                            <FormControlLabel
                                                control={<Switch
                                                    name="is_paid_listing"
                                                    disabled={isDisabled}
                                                    checked={state.is_paid_listing === "0" ? false : true}
                                                    onChange={handleChangePaid}
                                                    color="secondary"
                                                />}
                                                label="Paid Listing"
                                                style={{ marginTop: "20px" }}
                                            />
                                        </Grid>
                                }

                            </Grid>

                            {
                                (state.unit_for === 'sewa' || state.unit_for === 'sewa-jual') ?
                                    <>
                                        <Grid item xs={state.listing_type === 0 ? 6 : 12}>
                                            <TextField
                                                fullWidth
                                                className={classes.inputText}
                                                error={!!errorText.price_per_1_month}
                                                helperText={errorText.price_per_1_month}
                                                label="Price per 1 Month"
                                                name="price_per_1_month"
                                                variant="outlined"
                                                // type="number"
                                                value={state.price_per_1_month ? numberToCurrency(state.price_per_1_month) : 0}
                                                disabled={isDisabled}
                                                onChange={handleChange("price_per_1_month")}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">{state.is_dollar === "0" ? 'Rp' : '$'}</InputAdornment>,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        {
                                            state.listing_type === 0 ?
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        className={classes.inputText}
                                                        error={!!errorText.basic_price_1}
                                                        helperText={errorText.basic_price_1}
                                                        label="Basic Price per 1 Month"
                                                        name="basic_price_1"
                                                        variant="outlined"
                                                        // type="number"
                                                        value={state.basic_price_1 ? numberToCurrency(state.basic_price_1) : 0}
                                                        disabled={isDisabled}
                                                        onChange={handleChange("basic_price_1")}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">{state.is_dollar === "0" ? 'Rp' : '$'}</InputAdornment>,
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </Grid>
                                                : null
                                        }

                                        <Grid item xs={state.listing_type === 0 ? 6 : 12}>
                                            <TextField
                                                fullWidth
                                                className={classes.inputText}
                                                error={!!errorText.price_per_3_months}
                                                helperText={errorText.price_per_3_months}
                                                label="Price per 3 Months"
                                                name="price_per_3_months"
                                                variant="outlined"
                                                // type="number"
                                                value={state.price_per_3_months ? numberToCurrency(state.price_per_3_months) : 0}
                                                disabled={isDisabled}
                                                onChange={handleChange("price_per_3_months")}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">{state.is_dollar === "0" ? 'Rp' : '$'}</InputAdornment>,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        {
                                            state.listing_type === 0 ?
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        className={classes.inputText}
                                                        error={!!errorText.basic_price_3}
                                                        helperText={errorText.basic_price_3}
                                                        label="Basic Price per 3 Months"
                                                        name="basic_price_3"
                                                        variant="outlined"
                                                        // type="number"
                                                        value={state.basic_price_3 ? numberToCurrency(state.basic_price_3) : 0}
                                                        disabled={isDisabled}
                                                        onChange={handleChange("basic_price_3")}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">{state.is_dollar === "0" ? 'Rp' : '$'}</InputAdornment>,
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </Grid>
                                                : null
                                        }
                                        <Grid item xs={state.listing_type === 0 ? 6 : 12}>
                                            <TextField
                                                fullWidth
                                                tabIndex={3}
                                                className={classes.inputText}
                                                error={!!errorText.price_per_6_months}
                                                helperText={errorText.price_per_6_months}
                                                label="Price per 6 Months"
                                                name="price_per_6_months"
                                                variant="outlined"
                                                // type="number"
                                                value={state.price_per_6_months ? numberToCurrency(state.price_per_6_months) : 0}
                                                disabled={isDisabled}
                                                onChange={handleChange("price_per_6_months")}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">{state.is_dollar === "0" ? 'Rp' : '$'}</InputAdornment>,
                                                    tabIndex: 3
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        {
                                            state.listing_type === 0 ?
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        className={classes.inputText}
                                                        error={!!errorText.basic_price_6}
                                                        helperText={errorText.basic_price_6}
                                                        label="Basic Price per 6 Months"
                                                        name="basic_price_6"
                                                        variant="outlined"
                                                        // type="number"
                                                        value={state.basic_price_6 ? numberToCurrency(state.basic_price_6) : 0}
                                                        disabled={isDisabled}
                                                        onChange={handleChange("basic_price_6")}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">{state.is_dollar === "0" ? 'Rp' : '$'}</InputAdornment>,
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </Grid>
                                                : null
                                        }
                                        <Grid item xs={state.listing_type === 0 ? 6 : 12}>
                                            <TextField
                                                fullWidth
                                                tabIndex={1}
                                                className={classes.inputText}
                                                error={!!errorText.price_per_12_months}
                                                helperText={errorText.price_per_12_months}
                                                label="Price per Year"
                                                name="price_per_12_months"
                                                variant="outlined"
                                                // type="number"
                                                value={state.price_per_12_months ? numberToCurrency(state.price_per_12_months) : 0}
                                                disabled={isDisabled}
                                                onChange={handleChange("price_per_12_months")}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">{state.is_dollar === "0" ? 'Rp' : '$'}</InputAdornment>,
                                                    tabIndex: 1
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        {
                                            state.listing_type === 0 ?
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        tabIndex={2}
                                                        className={classes.inputText}
                                                        error={!!errorText.basic_price_12}
                                                        helperText={errorText.basic_price_12}
                                                        label="Basic Price per Year"
                                                        name="basic_price_12"
                                                        variant="outlined"
                                                        // type="number"
                                                        value={state.basic_price_12 ? numberToCurrency(state.basic_price_12) : 0}
                                                        disabled={isDisabled}
                                                        onChange={handleChange("basic_price_12")}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">{state.is_dollar === "0" ? 'Rp' : '$'}</InputAdornment>,
                                                            tabIndex: 2
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </Grid>
                                                : null
                                        }
                                    </>
                                    : <></>
                            }

                            {
                                (state.unit_for === 'jual' || state.unit_for === 'sewa-jual') ?
                                    <>
                                        <Grid item xs={state.listing_type === 0 ? 6 : 12}>
                                            <TextField
                                                fullWidth
                                                className={classes.inputText}
                                                error={!!errorText.selling_price}
                                                helperText={errorText.selling_price}
                                                label="Selling Price"
                                                name="selling_price"
                                                variant="outlined"
                                                // type="number"
                                                value={state.selling_price ? numberToCurrency(state.selling_price) : 0}
                                                disabled={isDisabled}
                                                onChange={handleChange("selling_price")}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">{state.is_dollar === "0" ? 'Rp' : '$'}</InputAdornment>,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        {
                                            state.listing_type === 0 ?
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        className={classes.inputText}
                                                        error={!!errorText.basic_price_sell}
                                                        helperText={errorText.basic_price_sell}
                                                        label="Basic Selling Price"
                                                        name="basic_price_sell"
                                                        variant="outlined"
                                                        // type="number"
                                                        value={state.basic_price_sell ? numberToCurrency(state.basic_price_sell) : 0}
                                                        disabled={isDisabled}
                                                        onChange={handleChange("basic_price_sell")}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">{state.is_dollar === "0" ? 'Rp' : '$'}</InputAdornment>,
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </Grid>
                                                : null
                                        }
                                    </>

                                    : <></>
                            }

                            {/* <Grid item xs={12}>
                            {renderOptions}
                        </Grid> */}

                            {/* {
                            state.listing_type === 0 ? (
                                <>
                                    <Grid item xs={12} style={{ paddingBottom: 0 }}>
                                        <h2>Facilities</h2>
                                    </Grid>

                                    <Grid item xs={5} style={{ paddingTop: 0 }}>
                                        <FormGroup>
                                            {
                                                facilities.map((item, key) => {
                                                    if (key < 5) {
                                                        return (
                                                            <FormControlLabel
                                                                key={key}
                                                                control={
                                                                    <Checkbox
                                                                        checked={state.facilities.includes(item.value)}
                                                                        onChange={handleChangeFacilities}
                                                                        name="facilities"
                                                                        value={item.value}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={item.label}
                                                            />
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={5} style={{ paddingTop: 0 }}>
                                        <FormGroup>
                                            {
                                                facilities.map((item, key) => {
                                                    if (key >= 5) {
                                                        return (
                                                            <FormControlLabel
                                                                key={key}
                                                                control={
                                                                    <Checkbox
                                                                        checked={state.facilities.includes(item.value)}
                                                                        onChange={handleChangeFacilities}
                                                                        name="facilities"
                                                                        value={item.value}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={item.label}
                                                            />
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                        </FormGroup>
                                    </Grid>
                                </>
                            ) : null
                        } */}

                            <Grid item xs={12}>
                                <h3>Upload Bukti Pembayaran</h3>
                                {
                                    state.payment_proof_image.url
                                        ? <img src={state.payment_proof_image.url} alt={state.payment_proof_image.url} height="200px" />
                                        : null
                                }
                                <br />
                                <input type="file" name="payment_proof_image" onChange={handleChange('payment_proof_image')} style={{ marginBottom: '10px' }} disabled={isDisabled} />
                            </Grid>

                            {/* Additional data */}

                            {isDisabled ?
                                <>
                                    {/* <Grid item xs={6}>
                            <TextField
                                fullWidth
                                className={classes.inputText}
                                error={!!errorText.source}
                                helperText={errorText.source}
                                label="Source"
                                name="source"
                                variant="outlined"
                                value={state.source === "1" ? "Facebook" : "Website"}
                                disabled={isDisabled}
                            />
                        </Grid> */}
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            className={classes.inputText}
                                            error={!!errorText.know_from}
                                            helperText={errorText.know_from}
                                            label="Know From"
                                            name="know_from"
                                            variant="outlined"
                                            value={state.know_from}
                                            disabled={isDisabled}
                                        />
                                    </Grid>
                                </>
                                :
                                <Grid container spacing={2} justifyContent="flex-end">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.buttonSubmit}
                                        onClick={() => {
                                            let submit = true
                                            formValidate(submit)
                                        }}
                                    >
                                        <span style={{ padding: "6px 0" }}>Submit</span>
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Root>
    );
};

export default EditListingSubmission;

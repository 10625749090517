import React, { useState, useReducer, useEffect } from 'react';
import {
    Theme,
    Grid,
    TextField,
    Button,
    Slide,
    Dialog,
    Toolbar,
    Typography,
    AppBar,
    IconButton
} from '@mui/material';
import TextArea from '../../../_components/_form/TextArea';
import { TransitionProps } from '@mui/material/transitions';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';

// Icon
import CloseIcon from '@mui/icons-material/Close';
import { generalErrorHandler, convertIndonesiaPhoneNumber } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    open: boolean;
    onClose: () => void,
    mode: "add" | "edit",
    onSubmitSuccess: () => void,
    officeId?: string
}

interface IState {
    name: string,
    phone: string,
    address: string,
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

interface IErrorState {
    name: string;
    phone: string;
    address: string
}

interface IErrorAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = generateStyle((theme: Theme) => ({
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    paper_label: {
        position: "absolute",
        marginTop: "-45px",
        padding: "1px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)"
    },
    paper_label_text: {
        backgroundColor: "#0094ff",
        fontSize: "1.5em",
        padding: "13px",
        borderRadius: "4px",
        color: "white"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        paddingTop: '10px'
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "AgentOffice_Form"
)

const AgentOfficeForm = (props: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = `${process.env.REACT_APP_API_URL}/agent-office`;

    const [loading, setLoading] = useState(false);

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Agent Office Form
                </Typography>
            </Toolbar>
        )
    }

    // Error State
    const errorReducer = (state: IErrorState, action: IErrorAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                phone: '',
                address: ''
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [errorState, setErrorState] = useReducer(errorReducer, {
        name: '',
        phone: '',
        address: ''
    });

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                phone: '',
                address: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        name: '',
        phone: '',
        address: '',
    });

    useEffect(() => {
        if (typeof props.officeId !== 'undefined' && props.officeId !== '') {
            DefaultAxios
                .get(`${API_URL}/${props.officeId}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                })
        } else {
            setInputState({ name: '', value: '', type: 'RESET_ITEM' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.officeId]);

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            if (target.name === 'phone') {
                setInputState({ name: target.name, value: convertIndonesiaPhoneNumber(value), type: 'SET_ITEM' });
            } else {
                setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
            }
        }
    }

    const checkValidation = () => {
        let isValid = true;
        let newError = { ...errorState };

        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        } else {
            newError.name = '';
        }

        if (!inputState.phone) {
            newError.phone = 'No Hp wajib diisi';
            isValid = false;
        } else {
            newError.phone = '';
        }

        if (!inputState.address) {
            newError.address = 'address wajib diisi';
            isValid = false;
        } else {
            newError.address = '';
        }

        setErrorState({ type: 'REPLACE_STATE', name: '', value: newError });
        return isValid;
    }

    const submitForm = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;

        if (props.mode === 'edit') {
            axios = DefaultAxios.patch(`${API_URL}/${props.officeId}`, inputState)
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Office Submitted",
                    icon: 'success',
                    onAfterClose: props.onSubmitSuccess,
                    timer: 1000
                });
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            closeAfterTransition
            TransitionComponent={Transition}
            fullWidth
            maxWidth="lg"
        >
            <Root>

                <AppBar className={classes.appBar}>
                    <FormToolbar></FormToolbar>
                </AppBar>
                <Grid className={classes.root}>
                    <LoadingScreen open={loading} fullScreen />
                    <Grid item xs={12} className={classes.grid}>
                        <TextField
                            error={!!errorState.name}
                            label="Nama"
                            variant="outlined"
                            name="name"
                            value={inputState.name}
                            onChange={handleInputChanged}
                            fullWidth
                            helperText={errorState.name}
                        />
                        <TextField
                            error={!!errorState.phone}
                            label="Phone"
                            variant="outlined"
                            name="phone"
                            value={inputState.phone}
                            onChange={handleInputChanged}
                            fullWidth
                            helperText={errorState.phone}
                            type="number"
                            placeholder="628xxxxxxxxxx"
                        />
                        <Grid item xs={12}>
                            <TextArea
                                error={!!errorState.address}
                                label="Address"
                                name="address"
                                value={inputState.address}
                                onChange={handleInputChanged}
                                helperText={errorState.address}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.gridSubmit}>
                        <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Root>
        </Dialog>
    );
}

export default AgentOfficeForm;
import React, { useState, useReducer, useEffect, useContext } from 'react';
import { Paper, Grid, Tabs, Tab, Theme } from '@mui/material';
// Components
import ModalDetail from '../../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../../_components/_modalDetail/ModalSection';
import TransactionHistory from '../../_closing/_components/TransactionHistory';
import AgentLogs from './AgentLogs';
// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import moment from 'moment';
import BankTable from '../../../_components/_dataTable/BankTable';
import { PermissionContext } from '../../../_contexts/PermissionContext';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    open: boolean;
    onClose: () => void,
    agentId?: string
}

interface IState {
    name: string,
    phone: string,
    address: string,
    agent_office_id: string;
    agent_office_name: string;
    bank_type: string
    bank_branch: string
    bank_behalf_name: string
    bank_number: string
    created_at: string
    updated_at: string
    created_user_full_name: string
    modified_user_full_name: string
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
    },
    paperTab: {
        margin: "0"
    },
}), "AgentModal_Detail"
)

const AgentDetail = (props: Props) => {
    const permissions = useContext(PermissionContext)
    const API_URL = `${process.env.REACT_APP_API_URL}/agent`;
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(0);
    const { Root, classes } = useStyles();
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                phone: '',
                email: '',
                notes: '',
                address: '',
                agent_office_id: '',
                agent_office_name: '',
                bank_behalf_name: '',
                bank_number: '',
                bank_type: '',
                bank_branch: '',
                created_at: '',
                updated_at: '',
                created_user_full_name: '',
                modified_user_full_name: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        name: '',
        phone: '',
        email: '',
        notes: '',
        address: '',
        agent_office_id: '',
        agent_office_name: '',
        bank_type: '',
        bank_branch: '',
        bank_behalf_name: '',
        bank_number: '',
        created_at: '',
        updated_at: '',
        created_user_full_name: '',
        modified_user_full_name: '',
    });

    const loadData = () => {
        setLoading(true)
        DefaultAxios
            .get(`${API_URL}/${props.agentId}`)
            .then(res => {
                const data = res.data;
                setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                setLoading(false)
            })
    }

    useEffect(() => {
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.agentId]);

    return (
        <>
            <ModalDetail
                open={props.open}
                title="Agent Detail"
                onCloseModal={props.onClose}
                isLoading={loading}
                showEditButton={true}
                editButtonLink={`/agent/edit/${props.agentId}`}
            >
                <>
                    <Grid item md={6} xs={12}>
                        <ModalSection
                            title="Informasi Dasar"
                            content={[
                                ['Name', inputState.name],
                                ['Phone', inputState.phone],
                                ['Email', inputState.email],
                                ['Address', inputState.address],
                                ['Office', inputState.agent_office_name],
                                ['Created At', moment(inputState.created_at).format('DD MMM YYYY HH:mm:ss') + ` - by ${inputState.created_user_full_name}`],
                                ['Updated At', moment(inputState.updated_at).format('DD MMM YYYY HH:mm:ss') + ` - by ${inputState.modified_user_full_name}`],
                            ]}
                        />
                    </Grid>


                    {/* <Grid item md={6} xs={12}>
                        <ModalSection
                            title="Informasi Bank"
                            content={[
                                ['Nama Bank', inputState.bank_type],
                                ['Nomor Rekening', inputState.bank_number],
                                ['Nama Pemilik Rekening Bank', inputState.bank_behalf_name],
                                ['KCP Bank', inputState.bank_branch],
                            ]}
                        />
                    </Grid> */}

                    <Grid item xs={12}>
                        <Root>
                            <Paper className={classes.root}>
                                <div className={classes.paperTab}>
                                    <Tabs
                                        style={{ backgroundColor: 'white' }}
                                        textColor="primary"
                                        value={value} onChange={handleChangeTab}
                                        aria-label="simple tabs example"
                                    >
                                        <Tab label="Logs" value={0} {...a11yProps(0)} />
                                        {
                                            permissions['agent.bank-manage'] &&
                                            <Tab label="Bank" value={1} {...a11yProps(1)} />
                                        }
                                    </Tabs>
                                </div>
                                <TabPanel value={value} index={0}>
                                    <br></br>
                                    <AgentLogs
                                        agentId={props.agentId}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <br></br>
                                    <BankTable
                                        id={props.agentId?.toString() || ''}
                                        component="agent"
                                        overWriteValue={{
                                            is_verified: 1,
                                        }}
                                        withoutVerify
                                        withoutEdit
                                    />
                                </TabPanel>
                            </Paper>
                        </Root>
                    </Grid>

                    <ModalSection
                        title="Transaction History"
                        content={[]}
                        additionalContent={
                            permissions['closing.view'] &&
                            <div style={{ marginTop: 25 }}>
                                <TransactionHistory
                                    permissions={permissions}
                                    personType={'agent'}
                                    agentId={props.agentId}
                                />
                            </div>
                        }
                    />
                </>
            </ModalDetail >
        </>
    );
}

export default AgentDetail;
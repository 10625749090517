import React, { useState, useReducer, useEffect } from 'react';
import { Theme, Grid, TextField, Button, Typography, MenuItem, FormControlLabel, Switch } from '@mui/material';
import Swal from 'sweetalert2';
import { useParams, useNavigate } from "react-router-dom";
import Nationalities from '../../_data/nationalities.json'

/**
 * Components
 */
import MuiDatePicker from '../../_components/MuiDatePicker';
import LoadingScreen from '../../_components/LoadingScreen';
import TextArea from '../../_components/_form/TextArea';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertIndonesiaPhoneNumber, dateFormat, generalErrorHandler } from '../../_utils/Helper';
import AsyncAutoComplete from '../../_components/_form/AsyncAutoComplete';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IParams extends Record<string, string | undefined> {
    landlord_id?: string
}

interface IState {
    name: string,
    phone: string,
    is_also_pic: number,
    pic_id: string,
    pic_landlord_id: string,
    pic_landlord_name: string,
    pic_landlord_phone: string,
    alternative_contact: string;
    email: string,
    birth_date: Date | null,
    religion: string,
    notes: string
    bank_type: string,
    bank_number: string,
    bank_behalf_name: string,
    bank_branch: string,
    address: string,
    ktp_number: string,
    npwp_number: string,
    kk_number: string
    nik: string,
    nationality: string
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

interface IProps {
    modal?: boolean
    id?: number
    submitModal?: () => void
}

const useStyles = generateStyle((theme: Theme) => ({
    rootContainer: {
        maxWidth: '750px',
        marginBottom: '70px',
        boxShadow: '0px 3px 15px #0000001A',
        '& .Mui-disabled': {
            backgroundColor: '#eee'
        }
    },
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        margin: theme.spacing(2),
        marginTop: theme.spacing(0)
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'right',
        marginBottom: '10px'
    }
}), "LandlordForm_Main"
)

const LandlordFormMain = (props: IProps) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/landlord';
    const params = useParams<IParams>()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // Error State
    // const errorReducer = (state: IErrorState, action: IErrorAction) => {
    //     if (action.type === 'SET_ITEM') {
    //         return {
    //             ...state,
    //             [action.name]: action.value
    //         }
    //     } else if (action.type === 'RESET_ITEM') {
    //         return {
    //             name: '',
    //             nickname: '',
    //             phone: '',
    //             alternative_contact: '',
    //             email: '',
    //             bank_type: '',
    //             bank_number: '',
    //             bank_behalf_name: '',
    //             bank_branch: '',
    //             address: '',
    //             ktp_number: '',
    //             npwp_number: '',
    //             kk_number: '',
    //             nik: '',
    //         }
    //     } else if (action.type === 'REPLACE_STATE') {
    //         const newState = action.value;
    //         return { ...newState as IErrorState };
    //     }

    //     return { ...state };
    // };

    const errorState = {
        name: '',
        phone: '',
        alternative_contact: '',
        pic_id: '',
        pic_landlord_name: '',
        email: '',
        bank_type: '',
        bank_number: '',
        bank_behalf_name: '',
        bank_branch: '',
        address: '',
        ktp_number: '',
        npwp_number: '',
        kk_number: '',
        nik: '',
        nationality: '',
    };

    // const [errorState, setErrorState] = useReducer(errorReducer, {
    //     name: '',
    //     nickname: '',
    //     phone: '',
    //     alternative_contact: '',
    //     email: '',
    //     bank_type: '',
    //     bank_number: '',
    //     bank_behalf_name: '',
    //     bank_branch: '',
    //     address: '',
    //     ktp_number: '',
    //     npwp_number: '',
    //     kk_number: '',
    //     nik: '',
    // });

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                phone: '',
                is_also_pic: 0,
                pic_id: '',
                pic_landlord_id: '',
                pic_landlord_name: '',
                pic_landlord_phone: '',
                alternative_contact: '',
                email: '',
                birth_date: null,
                religion: '',
                notes: '',
                bank_type: '',
                bank_number: '',
                bank_behalf_name: '',
                bank_branch: '',
                address: '',
                ktp_number: '',
                npwp_number: '',
                kk_number: '',
                nik: '',
                nationality: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        name: '',
        phone: '',
        is_also_pic: 0,
        pic_id: '',
        pic_landlord_id: '',
        pic_landlord_name: '',
        pic_landlord_phone: '',
        alternative_contact: '',
        email: '',
        birth_date: null,
        religion: '',
        notes: '',
        bank_type: '',
        bank_number: '',
        bank_behalf_name: '',
        bank_branch: '',
        address: '',
        ktp_number: '',
        npwp_number: '',
        kk_number: '',
        nik: '',
        nationality: '',
    });

    useEffect(() => {
        if (props.modal && props.id !== 0) {
            setLoading(true);
            DefaultAxios
                .get(`${API_URL}/${props.id}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                    setLoading(false);
                })
        }
        else if (typeof params.landlord_id !== "undefined") {
            setLoading(true);
            DefaultAxios
                .get(`${API_URL}/${params.landlord_id}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                    setLoading(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;

            if (target.name === 'phone') {
                setInputState({ name: target.name, value: convertIndonesiaPhoneNumber(value), type: 'SET_ITEM' });
            } else {
                setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
            }
        }
    }

    const handleAutoComplete = (value: any) => {
        let newState = { ...inputState }
        newState.pic_id = value.id
        newState.pic_landlord_name = value.label
        setInputState({ name: '', value: newState as IState, type: 'REPLACE_STATE' })
    }

    const handleChangeAlsoPIC = (e: any) => {
        let newState = { ...inputState }
        if (!e.target.checked) {
            newState.phone = ''
        }
        newState.pic_id = ''
        newState.pic_landlord_name = ''
        newState.is_also_pic = e.target.checked
        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' })
    }

    // TODO: might break
    const handleDateChanged = (name: string, date: Date | null) => {
        setInputState({ name, value: date, type: 'SET_ITEM' });
    }

    const submitForm = (force: boolean = false) => {
        let axios;
        let payload: any = { ...inputState };

        if (payload.birth_date) {
            payload.birth_date = dateFormat(payload.birth_date, 'YYYY-MM-DD');
        }

        payload.force_phone = force;

        if (props.modal && props.id !== 0) {
            axios = DefaultAxios.patch(`${API_URL}/${props.id}`, payload)
        } else if (typeof params.landlord_id !== "undefined") {
            axios = DefaultAxios.patch(`${API_URL}/${params.landlord_id}`, payload)
        } else {
            axios = DefaultAxios.post(`${API_URL}`, payload);
        }

        setLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: `${typeof params.landlord_id !== "undefined" || props.modal ? 'Landlord Updated' : 'Landlord Submitted'}`,
                    icon: 'success',
                    onAfterClose: () => {
                        if (props.modal) {
                            props.submitModal && props.submitModal()
                        } else {
                            navigate(`${typeof params.landlord_id !== "undefined" ? `/landlord/${params.landlord_id}` : '/landlord'}`, {
                                state: { reload: true }
                            })
                        }
                    },
                    timer: 1000
                });
            })
            .catch(error => {
                let errorData = error.response.data;

                if (error.response.status === 430) {
                    let error_text = ``;

                    if (typeof errorData.errors !== 'undefined') {
                        const error_lists = errorData.errors;
                        for (var k in error_lists) {
                            error_text += `<p>${error_lists[k]}</p>`;
                        }
                    }

                    error_text += '<h3>Harap Cek datanya terlebih dahulu. Lanjutkan bila datanya memang belum ada</h3>';
                    error_text += '<h3>Lanjutkan?</h3>';

                    Swal.fire({
                        title: "Validasi Error",
                        html: error_text,
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ya'
                    })
                        .then((result) => {
                            if (result.value) {
                                submitForm(true);
                            }
                        })
                } else {
                    generalErrorHandler(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        document.body.style.backgroundColor = '#eee';
        let timeout: any;
        return () => {
            clearTimeout(timeout);
            document.body.style.backgroundColor = '#fff';
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Root>
            <Grid container spacing={2}>
                <LoadingScreen open={loading} fullScreen />
                <Grid item xs={12}>
                    <Typography variant="h4">
                        Informasi Dasar
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        error={!!errorState.name}
                        label="Name"
                        variant="outlined"
                        name="name"
                        value={inputState.name}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={errorState.name}
                        placeholder="Name according to ID card / proof of ownership"
                    />
                </Grid>

                {
                    params.landlord_id || props.id ?
                        null
                        : <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={!!inputState.is_also_pic}
                                        onChange={handleChangeAlsoPIC}
                                        name="pic_id"
                                    />
                                }
                                label="Also create PIC Landlord"
                            />
                        </Grid>
                }

                {
                    !inputState.is_also_pic ?
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                errorText={''}
                                label='PIC'
                                name='pic_id'
                                clearable
                                onClear={() => handleAutoComplete({ id: null, label: '' })}
                                onInputChange={() => handleAutoComplete({ id: null, label: '' })}
                                url={`${process.env.REACT_APP_API_URL}/admin/autocomplete/pic-landlord`}
                                initialQuery={inputState.pic_landlord_name ? inputState.pic_landlord_name : ''}
                                onChange={(name: string, value: any) => handleAutoComplete(value)}
                            />
                        </Grid>
                        : null
                }

                {
                    inputState.is_also_pic ?
                        <Grid item xs={6}>
                            <TextField
                                error={!!errorState.phone}
                                label="Phone Number"
                                variant="outlined"
                                name="phone"
                                value={inputState.phone}
                                onChange={handleInputChanged}
                                fullWidth
                                helperText={errorState.phone}
                                type="text"
                                placeholder="628xxxxxxxxxx"
                            />
                        </Grid>
                        : null
                }

                <Grid item xs={inputState.is_also_pic ? 6 : 12}>
                    <TextField
                        error={!!errorState.email}
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={inputState.email}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={errorState.email}
                    />
                </Grid>

                {params.landlord_id &&
                    <Grid item xs={12}>
                        <TextField
                            error={!!errorState.phone}
                            label="Phone Number"
                            variant="outlined"
                            name="phone"
                            value={inputState.phone}
                            onChange={handleInputChanged}
                            fullWidth
                            helperText={errorState.phone}
                            type="text"
                            placeholder="628xxxxxxxxxx"
                        />
                    </Grid>
                }

                <Grid item xs={12}>
                    <TextArea
                        label="Alternative Contact Number"
                        name="alternative_contact"
                        value={inputState.alternative_contact}
                        onChange={handleInputChanged}
                    // placeholder="628xxxxxxxxxx"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextArea
                        label="Alamat"
                        name="address"
                        value={inputState.address}
                        onChange={handleInputChanged}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MuiDatePicker
                        label="Tanggal Lahir"
                        value={inputState.birth_date}
                        onChange={(date: any) => handleDateChanged('birth_date', date)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Agama"
                        variant="outlined"
                        name="religion"
                        value={inputState.religion}
                        onChange={handleInputChanged}
                        placeholder="C: Islam"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="KTP Number"
                        variant="outlined"
                        name="ktp_number"
                        value={inputState.ktp_number}
                        onChange={handleInputChanged}
                        fullWidth
                        type="number"
                        placeholder="16 digit numbers: 3314800000000000"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="NPWP Number"
                        variant="outlined"
                        name="npwp_number"
                        value={inputState.npwp_number}
                        onChange={handleInputChanged}
                        fullWidth
                        type="number"
                        placeholder="15 digit numbers: 523829200000000"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="KK Number"
                        variant="outlined"
                        name="kk_number"
                        value={inputState.kk_number}
                        onChange={handleInputChanged}
                        fullWidth
                        type="number"
                        placeholder="16 digit numbers: 9138221200000000"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        variant="outlined"
                        onChange={handleInputChanged}
                        label="Nationality"
                        name="nationality"
                        value={inputState.nationality}
                        fullWidth
                    >
                        {
                            Nationalities.map(nationality =>
                                <MenuItem key={nationality} value={nationality}>{nationality}</MenuItem>
                            )
                        }
                    </TextField>
                </Grid>

                {/* <Grid item xs={12}>
                    <Typography variant="h4">
                        Informasi Bank
                    </Typography>
                </Grid>

                <Grid item xs={2}>
                    <TextField
                        multiline
                        label="Nama Bank"
                        variant="outlined"
                        name="bank_type"
                        placeholder="C: BCA"
                        value={inputState.bank_type}
                        onChange={handleInputChanged}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        multiline
                        label="Nomor Rekening Bank"
                        variant="outlined"
                        name="bank_number"
                        value={inputState.bank_number}
                        onChange={handleInputChanged}
                        placeholder="C: 2063388360"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        label="Nama Pemilik Rekening Bank"
                        variant="outlined"
                        name="bank_behalf_name"
                        placeholder="C: Vidi Vici Digital"
                        value={inputState.bank_behalf_name}
                        onChange={handleInputChanged}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        label="Bank KCP"
                        variant="outlined"
                        name="bank_branch"
                        placeholder="C: Kemanggisan"
                        value={inputState.bank_branch}
                        onChange={handleInputChanged}
                        fullWidth
                    />
                </Grid>*/}
                <Grid item xs={12} className={classes.gridSubmit}>
                    <Button variant="contained" color="primary" onClick={() => submitForm()} className={classes.submit_button}>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Root>
    );
}

export default LandlordFormMain;
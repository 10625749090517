import React from 'react'
import { Button, ButtonProps, CircularProgress } from '@mui/material'

interface ButtonWithLoadingProps {
    isLoading?: boolean
}

const ButtonWithLoading = (props: ButtonWithLoadingProps & ButtonProps) => {
    return (
        <Button
            {...props}
            disabled={props.isLoading || props.disabled}
        >
            {
                props.isLoading ?
                    <CircularProgress size={props.size ? SIZES[props.size] : 24} />
                    : props.children
            }
        </Button>
    )
}

const SIZES = {
    'large': 26,
    'medium': 24,
    'small': 23,
}

export default ButtonWithLoading

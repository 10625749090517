import React, { useState, useReducer, useEffect, useCallback, useRef } from "react";
import {
    Theme,
    Grid,
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Chip,
    InputAdornment,
} from "@mui/material";

/**
 * Icons
*/
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { HourglassBottom, Send } from "@mui/icons-material";
import PricePerIcon from "../../_components/_icons/PricePerIcon";

/**
 * Components
 */
import MuiDatePicker from "../../_components/MuiDatePicker";
// import ImageSlider from "../../_components/_cards/_imageSlider/ImageSlider";
import LoadingScreen from "../../_components/LoadingScreen";
import AdvisorCommentList from '../_myUnit/_components/AdvisorCommentList';
import defaultPhoto from '../../Assets/Images/png/default-photo.png';

/**
 * Utils
 */
import InfoTable from "../_listingList/InfoTable";
import DefaultAxios from "../../_utils/DefaultAxios";
import { format } from 'date-fns';
import scrollbarClasses from '../../Assets/CSS/scrollbar.module.css';
import { debounce } from "lodash";
import { convertIndonesiaPhoneNumber, convertNumber, dateFormat, generalErrorHandler, IFacilities, parseFacility, renderToastSuccess } from "../../_utils/Helper";
import { generateStyle } from "../../_utils/DefaultStyle";


interface Props {
    onClose: (data: any) => void;
    fetchDetail: (unitId: string, track?: boolean) => false | undefined
    data: IUnitAdvisorDetailData
}

export interface IUnitAdvisorDetailData {
    address: string
    apartment_name: string
    assigned_to_advisor_at?: string
    is_dollar: number
    bathroom: number
    bedroom: number
    maid_room: number
    code: string
    comments: {
        created_by: string
        created_at: string
        comment: string
        photo?: string
    }[]
    facilities: {
        unit_id: number
        facility_slug: keyof IFacilities
        description: string
    }[]
    floor_level: number
    furnished: string
    unit_number: string
    id: number
    image: string[]
    keterangan: string
    landlord_type: string
    landlord_email: string
    landlord_name: string
    landlord_phone: string
    is_owner: number
    last_reported_by_advisor_at: null
    name: string
    price_per_1_month?: number | null
    price_per_3_months?: number | null
    price_per_6_months?: number | null
    price_per_12_months?: number | null
    selling_price?: string
    basic_price_1: number | null
    basic_price_3: number | null
    basic_price_6: number | null
    basic_price_12: number | null
    basic_price_sell: number | null
    service_charge: string
    slug: string
    tower: string
    unit_area: number
    view_direction: string
    is_entrusted_key: number;
    key_holder_name: string;
    updated_at: string;
    is_available: number;
    rent_date: string;
    previous_closing: null | {
        basic_price: number
        created_at: string
        duration: number
    }
    recent_showing: {
        member_name: string | null
        datetime: string | null
    }[]
}

interface IAvailabilityState {
    available_date: Date | null;
    comment: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const UnitAdvisorDetailModalNew = ({ data, ...props }: Props) => {
    const { Root, classes } = useStyles();
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)
    const [modalOpen, setModalOpen] = useState<false | 'comments' | 'availability'>(false)
    // const [isPreviewOpen, setIsPreviewOpen] = useState(false)

    const [availabilityState, setAvailabilityState] = useReducer(inputAvailabilityReducer, initialAvailabilityState);

    const [isLoading, setIsLoading] = useState(false)

    const [newComment, setNewComment] = useState('')
    const [isSendCommentLoading, setIsSentCommentLoading] = useState(false)
    const [completeComments, setCompleteComments] = useState<null | {
        created_by: string;
        created_at: string;
        comment: string;
        photo?: string | undefined;
    }[]>(null)

    // const openPreview = () => {
    //     setIsPreviewOpen(true)
    // }

    // const closePreview = () => {
    //     setIsPreviewOpen(false)
    // }

    const fetchAllComments = useCallback((id: number) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/comment/unit/${id}`)
            .then(res => res.data)
            .then(data => {
                setCompleteComments(data.comments.map((dt: any) => ({ ...dt, created_by: dt.name, photo: dt.avatar })))
            })
            .catch(error => {
                generalErrorHandler(error)
            })
    }, [])

    // eslint-disable-next-line
    const loadCompleteComments = useCallback(debounce(() => {
        if (scrollContainerRef && scrollContainerRef.current) {
            if ((scrollContainerRef.current.offsetHeight + scrollContainerRef.current.scrollTop) >= (scrollContainerRef.current.scrollHeight - 10) && completeComments === null) {
                fetchAllComments(data.id)
            }
        }
    }, 300), [data.id, completeComments, scrollContainerRef, scrollContainerRef.current, fetchAllComments])

    useEffect(() => {
        const scrollContainerRefCopy = scrollContainerRef
        if (scrollContainerRefCopy && scrollContainerRefCopy.current) {
            if (data && data.id) {
                scrollContainerRefCopy.current.addEventListener('scroll', loadCompleteComments)
            } else {
                setCompleteComments(null)
            }
        }

        return () => {
            if (scrollContainerRefCopy && scrollContainerRefCopy.current) {
                if (data && data.id) {
                    scrollContainerRefCopy.current.removeEventListener('scroll', loadCompleteComments)
                }
            }
        }
    }, [data, scrollContainerRef, loadCompleteComments, completeComments])

    const onSubmitComment = () => {
        setIsSentCommentLoading(true);

        DefaultAxios.patch(`${process.env.REACT_APP_API_URL}/my-unit/${data.code}?update_comment=true`, { comment: newComment })
            .then(res => {
                setNewComment('')

                renderToastSuccess('Comment Submited')
                fetchAllComments(data.id)

            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsSentCommentLoading(false)
            })
    }

    const submitSuccess = () => {
        loadListing()
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    const loadListing = () => {
        props.fetchDetail(data.id.toString())
    }

    const furnished = () => {
        switch (data.furnished) {
            case 'non':
                return 'Non Furnished'
            case 'semi':
                return 'Semi Furnished'
            case 'full':
                return 'Full Furnished'
        }
    }

    const renderComments = () => {
        return (completeComments !== null ? completeComments : data.comments).map((comment, index: number) =>
            <Grid key={index} container className={classes.commentBox} style={{ borderTop: index === 0 ? '' : 'solid 1px #DADADA' }}>
                <Grid item sx={{ mr: 2 }}>
                    <img className={classes.imageComment} src={comment.photo ? comment.photo : defaultPhoto} alt={comment.created_by} />
                </Grid>

                <Grid item xs={10}>
                    <label style={{ marginBottom: 4, display: 'block' }}>
                        <span style={{ fontWeight: 700, fontSize: 14, color: "#484848" }}>{comment.created_by}</span>
                        <span style={{ fontSize: 14, color: '#A5A5A5' }}> &bull; {dateFormat(comment.created_at, 'DD MMM')}</span>
                    </label>
                    {/* <div className={classes.descText} style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: comment.comment }}></div> */}
                    <Typography variant="caption" className={classes.descText} style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: `${comment.comment}` }}>
                        {/* {`"${data.keterangan}"`} */}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAvailabilityState({ name: e.target.name, value: e.target.value, type: 'SET_ITEM' });
    }

    // TODO: might break, check again later
    const handleDateChanged = (name: string, date: Date | null) => {
        setAvailabilityState({ name, value: date, type: 'SET_ITEM' });
    }

    const processNumber = (price: number | null | undefined, is_dollar: number) => {
        return price ? (is_dollar ? '$ ' : "Rp. ") + price.toLocaleString() : '-'
    }

    const processBasicNumber = (price: number | null, is_dollar: number) => {
        return price ? `(${(is_dollar ? '$ ' : "Rp. ")} ${price.toLocaleString()})` : '-';
    }

    const handleSubmit = () => {
        setIsLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/unit-search-agent/change-available-date`, { unit_id: data.id, available_date: dateFormat(availabilityState.available_date || '', 'MM/DD/YYYY'), comment: availabilityState.comment })
            .then(() => {
                props.fetchDetail(data.id.toString());
                handleCloseModal();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const renderWebPrice = (
        <>
            {
                (data.price_per_12_months && data.price_per_12_months > 0) ?
                    <div className="price-card">
                        <span className="label"><PricePerIcon style={{ marginRight: 5 }} />Per Tahun</span>
                        <span className="price">{processNumber(data.price_per_12_months, data.is_dollar)}</span>
                        <span className="price-smaller">{processBasicNumber(data.basic_price_12, data.is_dollar)}</span>
                    </div>
                    : null
            }
            {
                (data.price_per_6_months && data.price_per_6_months > 0) ?
                    <div className="price-card">
                        <span className="label"><PricePerIcon style={{ marginRight: 5 }} />Per 6 Bulan</span>
                        <span className="price">{processNumber(data.price_per_6_months, data.is_dollar)}</span>
                        <span className="price-smaller">{processBasicNumber(data.basic_price_6, data.is_dollar)}</span>
                    </div>
                    : null
            }
            {
                (data.price_per_3_months && data.price_per_3_months > 0) ?
                    <div className="price-card">
                        <span className="label"><PricePerIcon style={{ marginRight: 5 }} />Per 3 Bulan</span>
                        <span className="price">{processNumber(data.price_per_3_months, data.is_dollar)}</span>
                        <span className="price-smaller">{processBasicNumber(data.basic_price_3, data.is_dollar)}</span>
                    </div>
                    : null
            }
            {
                (data.price_per_1_month && data.price_per_1_month > 0) ?
                    <div className="price-card">
                        <span className="label"><PricePerIcon style={{ marginRight: 5 }} />Per Bulan</span>
                        <span className="price">{processNumber(data.price_per_1_month, data.is_dollar)}</span>
                        <span className="price-smaller">{processBasicNumber(data.basic_price_1, data.is_dollar)}</span>
                    </div>
                    : null
            }
        </>
    )

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.header}>
                        {data.code}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Box className={classes.modalContainer} ref={scrollContainerRef}>
                <Dialog
                    fullScreen
                    open={modalOpen === 'comments'}
                    onClose={handleCloseModal}
                >
                    <AdvisorCommentList
                        onSubmitSuccess={submitSuccess}
                        unitId={data.id.toString()}
                        unitCode={data.code}
                        onCloseForm={handleCloseModal}
                    />
                </Dialog>

                <Dialog
                    open={modalOpen === 'availability'}
                    onClose={handleCloseModal}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>Request Unavailable</DialogTitle>
                    <DialogContent>
                        <Root>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <MuiDatePicker
                                        label="Available Date"
                                        value={availabilityState.available_date}
                                        onChange={(date: any) => handleDateChanged('available_date', date)}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        variant="outlined"
                                        label="Comment"
                                        name="comment"
                                        value={availabilityState.comment}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Root>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                handleSubmit()
                            }}
                            color="primary"
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* {data.is_hot + '' === '1' ? <div className={classes.ribbon}>HOT</div> : ''} */}
                {/* <ImageSlider img={data.image} index={true} openPreview={openPreview} /> */}
                {/* {
                    isPreviewOpen ?
                        <ImagePreview
                            img={data.image}
                            index={true}
                            close={closePreview}
                        />
                        : null
                } */}

                <Box className={classes.modalContent}>
                    <Typography variant="h6" className={classes.cardTitle} style={{ fontSize: 16, marginBottom: 8, }}>
                        {data.name}
                    </Typography>

                    <Typography variant="subtitle2" className={classes.addressText} style={{ fontSize: 12, color: '#484848', fontWeight: '400', marginBottom: 16, }}>
                        {data.address}
                    </Typography>


                    <Box className={classes.container} style={{ marginBottom: 8 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            aria-label="close"
                            href={`https://jendela360.com/${data.code}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={e => e.stopPropagation()}
                        >
                            <VisibilityIcon fontSize="small" />&nbsp;<span style={{ fontSize: 12, textTransform: 'capitalize' }}>View Unit</span>
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setModalOpen('availability')}
                            style={{ fontSize: 12, textTransform: 'capitalize', color: '#34516C', borderColor: '#34516C', marginLeft: 10 }}
                        >
                            Request Unavailable
                        </Button>
                    </Box >

                    <div className={classes.priceBox}>
                        <Grid container style={{ paddingLeft: 8, paddingRight: 8 }}>
                            <Grid item xs={6}>
                                <Typography variant="h6" className={classes.cardTitle} style={{ fontSize: 16 }}>
                                    Web Price
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* <div className={`${classes.priceContainer} gray`}>
                            <div className="column fullwidth">
                            <span className="price sell-price">{processNumber(data.selling_price, data.is_dollar)}</span>
                                <span className="small-label">({processNumber(data.basic_price_sell, data.is_dollar)})</span>
                                <span className="label">selling price</span>
                            </div>
                        </div> */}

                        <div className={`${classes.priceContainer} ${scrollbarClasses['no-scrollbar-all']}`}>
                            {renderWebPrice}
                        </div>

                        <Grid container style={{ paddingLeft: 8, paddingRight: 8 }}>
                            <Grid item xs={6}>
                                <Typography variant="h6" className={classes.cardTitle} style={{ fontSize: 16 }}>
                                    Service Charge
                                </Typography>
                            </Grid>
                        </Grid>

                        <div className={`${classes.priceContainer}`}>
                            {
                                data.service_charge ?
                                    <div className="price-card">
                                        <span className="label"><PricePerIcon style={{ marginRight: 5 }} />Service Charge</span>
                                        <span className="price">{data.service_charge}</span>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>

                    {/* {
                        data.assigned_to_advisor_at &&
                        renderDayEmpty()
                    } */}

                    {/* <Divider /> */}

                    <Typography
                        variant="h6"
                        style={{ fontWeight: 600, fontSize: 16, marginBottom: 4 }}
                        className={classes.margin2}
                    >
                        Informasi Properti
                    </Typography>

                    <InfoTable
                        data={[
                            ["Kode Unit", data.code],
                            ["Bedroom", data.bedroom === 0 ? 'Studio' : `${data.bedroom} BR`],
                            ["Bathroom", data.bathroom ? `${data.bathroom} Kamar` : `-`],
                            ["Maid Room", data.maid_room ? `${data.maid_room} Kamar` : `-`],
                            ["Tower / Lantai / Nomor", `${data.tower} / ${data.floor_level} / ${data.unit_number}` || null],
                            ["Furnish", furnished() || "-"],
                            ["Hadap", data.view_direction || "-"],
                            ["Unit Area",
                                <>
                                    {` ${data.unit_area || 0}m`}
                                    <sup>2</sup>
                                </>],
                            [
                                "Available",
                                <Chip color={data.is_available === 1 ? 'primary' : 'secondary'} size="small" label={data.is_available === 1 ? 'Ya' : 'Tidak'} />
                            ],
                            data.is_available === 0 ? [
                                "Available Date",
                                data.rent_date ? format(new Date(data.rent_date), "dd MMMM yyyy") : "No Information"
                            ] : [],
                            [
                                "Titip Kunci",
                                <Chip color={data.is_entrusted_key === 1 ? 'primary' : 'secondary'} size="small" label={data.is_entrusted_key === 1 ? 'Ya' : 'Tidak'} />
                            ],
                            [
                                "Pemegang Kunci",
                                data.is_entrusted_key === 1 ? data.key_holder_name : null
                            ],
                            [
                                "Fasilitas",
                                data.facilities.reduce((prev, current, currIndex) => prev + `${parseFacility(current.facility_slug)}${currIndex !== (data.facilities.length - 1) ? ', ' : ''}`, '')
                            ],
                            [
                                "Update Terakhir",
                                format(new Date(data.updated_at), "dd MMMM yyyy")
                            ]
                        ]}
                    />

                    {/* <Divider /> */}

                    <div style={{ backgroundColor: '#ECF1F5', marginLeft: -8, marginRight: -8, paddingLeft: 8, paddingRight: 8, paddingBottom: 2, paddingTop: 16, }}>
                        <Typography
                            variant="h6"
                            style={{ fontWeight: 600, fontSize: 16, marginBottom: 4, }}
                            className={classes.margin2}
                        >
                            Informasi {data.is_owner ? 'Pemilik' : 'Agent'}
                        </Typography>

                        <InfoTable
                            data={[
                                ["Unit Landlord / Agent", data.landlord_type],
                                ["Nama", data.landlord_name],
                                ["Nomor Handphone", data.landlord_phone],
                                ["Email", data.landlord_email],
                            ]}
                        />
                    </div>

                    <Grid container style={{ marginTop: 16 }}>
                        <Grid item xs={6}>
                            <Typography
                                variant="h6"
                                style={{ fontWeight: 600, fontSize: 16, marginBottom: 4, }}
                                className={classes.margin2}
                            >
                                Catatan
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography variant="caption" className={classes.descText} style={{ wordBreak: 'break-word', marginBottom: 16, fontSize: 14 }}>
                        {data.keterangan ? `"${data.keterangan}"` : " - "}
                    </Typography>

                    <Grid container style={{ marginTop: 16 }}>
                        <Grid item xs={6}>
                            <Typography
                                variant="h6"
                                style={{ fontWeight: 600, fontSize: 16, marginBottom: 16, }}
                                className={classes.margin2}
                            >
                                Last Closing
                            </Typography>
                        </Grid>
                    </Grid>

                    <div className={classes.lastClosingContainer}>
                        {
                            data.previous_closing ?
                                <>
                                    <div className="price-title">Harga Deal Owner</div>
                                    <div className="price-label">{convertNumber(data.previous_closing.basic_price.toString(), 'Rp ')}</div>
                                    <div className="bottom-container">
                                        <div style={{ width: '40%' }}>
                                            <div className="date-label">Tanggal</div>
                                            <div className="date-value">
                                                {format(new Date(data.previous_closing.created_at), 'dd MMMM yyyy')}
                                            </div>
                                        </div>
                                        <div style={{ width: '40%' }}>
                                            <div className="date-label">Tenor</div>
                                            <div className="date-value">
                                                {data.previous_closing.duration} Bulan
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <span className="empty-closing">Belum ada closing</span>
                        }
                    </div>

                    <Grid container style={{ marginTop: 16 }}>
                        <Grid item xs={6}>
                            <Typography
                                variant="h6"
                                style={{ fontWeight: 600, fontSize: 16, marginBottom: 16, }}
                                className={classes.margin2}
                            >
                                Recent Showing
                            </Typography>
                        </Grid>
                    </Grid>

                    <div className={classes.recentShowingContainer}>
                        {
                            data.recent_showing.length > 0 ?
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    {
                                        data.recent_showing.map((shw, index) =>
                                            <div key={index} className="row" style={{ borderTop: index === 0 ? '' : '1px solid #DADADA', }}>
                                                <div style={{ color: '#252525', fontSize: 14 }}>
                                                    {shw.datetime ? format(new Date(shw.datetime), 'dd MMMM yyyy') : '-'}
                                                </div>
                                                <div style={{ color: '#252525', fontSize: 14 }}>
                                                    {shw.member_name || '-'}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                :
                                <span className="empty-showing">Belum ada showing</span>
                        }
                    </div>

                    <Typography
                        variant="h6"
                        style={{ fontWeight: 600, fontSize: 16, marginBottom: 16, marginTop: 26 }}
                        className={classes.margin2}
                    >
                        Comments
                    </Typography>

                    <div style={{ marginBottom: 16 }}>
                        <TextField
                            name="comment"
                            value={newComment}
                            onChange={e => setNewComment(e.target.value)}
                            variant="outlined"
                            fullWidth
                            multiline
                            maxRows={3}
                            size="small"
                            placeholder="Comment"
                            disabled={isSendCommentLoading}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="submit comment"
                                            onClick={onSubmitComment}
                                            edge="end"
                                        >
                                            {isSendCommentLoading ? <HourglassBottom /> : <Send />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                    {renderComments()}
                    {/* <Typography onClick={() => setModalOpen('comments')} style={{ cursor: 'pointer' }} color="primary" variant="subtitle2">
                        Lihat atau tambahkan komentar
                    </Typography> */}

                    <Box className={classes.containerSB}>
                        {/* <Button
                            variant="contained"
                            color="primary"
                            startIcon={<CallIcon />}
                            className={classes.button}
                            href={
                                data.landlord_phone ?
                                    "tel:+" + convertIndonesiaPhoneNumber(data.landlord_phone.replace(/[^0-9]/, ""))
                                    : "tel:+" + data.landlord_phone
                            }
                        >
                            Call
                        </Button> */}

                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<WhatsAppIcon />}
                            className={classes.button}
                            size="large"
                            href={`https://wa.me/+${convertIndonesiaPhoneNumber(data.landlord_phone.replace(/[^0-9]/, ""))}`}
                            target="_blank"
                        >
                            WhatsApp
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Root>
    );
};

const useStyles = generateStyle((theme: Theme) =>
({
    listContainer: {
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    appBar: {
        position: "relative",
        overflow: 'hidden'
    },
    modalContainer: {
        position: "relative",
        overflowX: 'hidden',
    },
    modalContent: {
        padding: theme.spacing(1)
    },
    cardTitle: {
        lineHeight: "1.5rem",
        marginTop: 8,
        marginBottom: 8
    },
    pill: {
        borderRadius: "1em",
        padding: "6px 13px",
        fontWeight: 400,
        marginRight: 6,
        marginBottom: 7,
        display: "flex",
        flexFlow: "row",
        justifyItems: "center",
        alignItems: "center"
    },
    greenP: {
        background: "#EBFBEE",
        color: "#144738"
    },
    blueP: {
        background: "#EAF0FC",
        color: "#698ABD"
    },
    container: {
        display: "flex",
        flexFlow: "row",
        flexWrap: "wrap"
    },
    iconContainer: {
        background: "#EBFBEE",
        borderRadius: "20%",
        display: "flex",
        padding: 5
    },
    addressText: {
        color: "#00000099",
        margin: `${theme.spacing(2)}px 0`
    },
    containerSB: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: 'sticky',
        bottom: 8,
        left: 0,
        right: 0,
        width: '100%',
        background: '#fff',
        padding: '8px 16px',
        borderRadius: 20,
        boxShadow: '0px 0px 0px 0px #0000001A, 0px 2px 5px 0px #0000001A, 0px 10px 10px 0px #00000017, 0px 22px 13px 0px #0000000D, 0px 39px 16px 0px #00000003, 0px 61px 17px 0px #00000000',
    },
    header: {
        flexGrow: 1
    },
    descText: {
        // fontStyle: "italic",
        color: "#484848",
        fontWeight: 400,
        fontSize: 14,
        margin: `${theme.spacing(1)}px 0`,
        display: 'block',
        whiteSpace: 'pre-line'
    },
    textWithIcon: {
        display: "flex",
        alignItems: "center"
    },
    margin1: {
        margin: `${theme.spacing(1)}px 0`
    },
    margin2: {
        margin: `${theme.spacing(2)}px 0`
    },
    button: {
        width: "100%",
        textTransform: "none",
        borderRadius: 20,
        paddingTop: 12,
        paddingBottom: 12,
        fontSize: 18,
        backgroundColor: '#29A71A',
    },
    ribbon: {
        position: "absolute",
        transform: "rotate(45deg)",
        fontSize: 10,
        letterSpacing: 0.4,
        zIndex: 1000,
        backgroundColor: "#CF3636",
        width: "200px",
        height: "1.1rem",
        overflow: "hidden",
        color: "white",
        right: "-4.9rem",
        top: "0.9rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 500,
    },
    priceContainer: {
        display: 'flex',
        flexDirection: 'row',
        // padding: '1.5rem 0',
        paddingTop: 8,
        paddingBottom: 8,
        overflow: 'auto',
        '& .price-card': {
            '&:first-of-type': {
                marginLeft: 8,
            },
            '&:last-child': {
                marginRight: 8,
            },
            backgroundColor: '#ECF1F5',
            marginLeft: 10,
            borderRadius: 16,
            minWidth: '40%',
            display: 'flex',
            padding: 20,
            alignItems: 'flex-start',
            flexDirection: 'column',
            '& .price': {
                fontSize: 16,
                fontWeight: 700,
                color: '#252525',
                marginBottom: 2,
            },
            '& .price-smaller': {
                fontSize: 12,
                fontWeight: 400,
                color: '#252525',
            },
            '& .label': {
                color: '#484848',
                fontSize: 12,
                fontWeight: 400,
                marginBottom: 4,
            },
        }
    },

    bottom: {
        margin: '0.5rem 0 0.25rem 0',
        '& .contentTwo': {
            margin: 0,
            fontSize: 14,
            color: '#00000099'
        },
        '& .contentOne': {
            margin: 0,
            fontSize: 16,
            color: '#00000099'
        }
    },
    step: {
        display: 'flex',
    },
    img_container: {
        width: "100%",
        height: "158px",
    },
    priceBox: {
        marginBottom: theme.spacing(3),
        marginLeft: -8,
        marginRight: -8,
    },
    commentRoot: {
        paddingBottom: '20px',
        borderBottom: '1px solid #aaa',
        margin: '10px 0'
    },
    commentSection: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    imageComment: {
        display: 'inline-block',
        width: '32px',
        borderRadius: '50%'
    },
    commentBox: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    lastClosingContainer: {
        padding: 20,
        borderRadius: 16,
        backgroundColor: '#ECF1F5',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > .price-title': {
            alignSelf: 'flex-start',
            color: '#252525',
            fontSize: 12,
            marginBottom: 5,
        },
        '& > .price-label': {
            alignSelf: 'flex-start',
            color: '#252525',
            fontSize: 26,
            fontWeight: '600',
            marginBottom: 16,
        },
        '& > .bottom-container': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            width: '100%',
            '& .date-label': {
                color: '#252525',
                fontSize: 12,
                marginBottom: 10,
            },
            '& .date-value': {
                color: 'white',
                fontSize: 12,
                fontWeight: '600',
                padding: 10,
                backgroundColor: '#252525',
                textAlign: 'center',
                borderRadius: 12,
            }
        },
        '& > .empty-closing': {
            color: '#252525',
            textAlign: 'center',
            fontSize: 14,
        },
    },
    recentShowingContainer: {
        padding: '5px 20px',
        borderRadius: 16,
        backgroundColor: '#ECF1F5',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .row': {
            paddingTop: 15,
            paddingBottom: 15,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        '& .empty-showing': {
            color: '#252525',
            textAlign: 'center',
            fontSize: 14,
            paddingTop: 15,
            paddingBottom: 15,
        },
    },
}), "UnitAdvisorDetailModalNew"
);

// Input State
const inputAvailabilityReducer = (state: IAvailabilityState, action: IAction) => {
    if (action.type === 'SET_ITEM') {
        return {
            ...state,
            [action.name]: action.value
        }
    } else if (action.type === 'RESET_ITEM') {
        return {
            ...initialAvailabilityState
        }
    } else if (action.type === 'REPLACE_STATE') {
        const newState = action.value;
        return { ...newState as IAvailabilityState };
    }

    return { ...state };
};

const initialAvailabilityState = {
    available_date: null,
    comment: ''
};

export default UnitAdvisorDetailModalNew;

import React, { useState } from 'react';
import {
    Button,
    Theme,
    Link,
    Switch
} from '@mui/material';
import { Link as RouterLink, useNavigate } from "react-router-dom";

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import DefaultAxios from '../../_utils/DefaultAxios';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';

/**
 * Utils
 */
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    bank_id?: string
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        boxShadow: '0px 0px 0px #0000001A',
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    }
}), "BankOther_List"
)



const BankOthers = (props: IProps) => {
    const { Root, classes } = useStyles();
    const DT_API_URL = process.env.REACT_APP_API_URL + '/bank-other';
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleVerified = (data: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengubah status verified?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/bank-other/toggle-verified`, { id: data.id })
                        .then(res => {
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +data.id) element['is_verified'] = data.is_verified ? 0 : 1;
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => navigate('/bank-others/add')}
            >
                Add Bank
            </Button>
            <DataTable
                url={DT_API_URL}
                columns={[
                    {
                        name: 'behalf_name', label: 'Atas Nama', filterable: true, render: (data: any, row: any) => {
                            return (
                                <Link
                                    style={{ cursor: "pointer" }}
                                    component={RouterLink}
                                    to={`/bank-others/edit/${row.id}`}
                                // onClick={() => { openModalDetail(row); console.log(row) }
                                // }
                                >
                                    {data}
                                </Link>
                            );
                        }
                    },
                    { name: 'number', label: 'Nomor Rekening', filterable: true },
                    {
                        name: 'type', label: 'Tipe', filterable: true, render: (data: any, row: any) => {
                            return (
                                data === 'deposit_jendela' ? 'Deposit Jendela' : data === 'main_jendela' ? 'Bank Utama Jendela' : data === 'cobroke_bank' ? 'Bank Cobroke' : 'Lainnya'
                            );
                        }
                    },
                    {
                        name: 'description', label: 'Deskripsi', filterable: true, render: (data: any, row: any) => {
                            return (
                                data ? data : '-'
                            );
                        }
                    },
                    {
                        name: 'is_verified',
                        label: 'Verified',
                        filterable: true,
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '1',
                                value: 'Yes'
                            },
                            {
                                key: '0',
                                value: 'No'
                            }
                        ],
                        render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                            return (
                                <Switch
                                    checked={data === 1}
                                    onChange={() => handleVerified(row, setRows, rows)}
                                    color="secondary"
                                />
                            );
                        }
                    }
                ]}
            />
        </Root>
    );
}

export default BankOthers;
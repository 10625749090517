import { Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'

/**
 * Components
 */
import DataTable from '../../../_components/_dataTable/DataTable'
import AssignPICListingModal from '../../_unit/_components/_modals/AssignPICListingModal'

/**
 * Utils
 */
import moment from 'moment'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { numberToCurrency } from '../../../_utils/Helper'

/**
 * Icons
 */
import { Edit } from '@mui/icons-material'

const DashboardListingAdminMain = () => {
    const [redrawDatatable, setReDrawDatatable] = useState(0)
    const [editPICState, setEditPICState] = useState({
        unitId: 0,
        picId: 0,
        picName: '',
    })

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h6' sx={{ mb: 1 }}>
                        Units
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        reDraw={redrawDatatable}
                        url={`${process.env.REACT_APP_API_URL}/listing-admin-dashboard`}
                        columns={[
                            {
                                name: 'end_date',
                                label: 'Tanggal Checkout',
                                type: 'date',
                                filterable: true,
                                sortable: false,
                                defaultOption: `${format(startOfMonth(new Date()), 'yyyy-MM-dd')} - ${format(endOfMonth(new Date()), 'yyyy-MM-dd')}`,
                                render: (data: any, row: any) => {
                                    return moment(data).format('DD MMM YYYY')
                                }
                            },
                            {
                                name: 'unit_code',
                                label: 'Kode Unit',
                                type: 'string',
                                filterable: true,
                                sortable: false,
                                render: (data: string, row: any) => {
                                    return (
                                        <>
                                            <a href={`/unit/${data}`} id={`unit-link-${data}`} target="_blank" rel='noopener noreferrer' style={{ display: 'none' }}>unit</a>
                                            <Chip variant="outlined" label={data} onClick={() => {
                                                const a = document.getElementById(`unit-link-${data}`)
                                                a?.click()
                                            }} style={{ cursor: "pointer" }} />
                                        </>
                                    )
                                }
                            },
                            {
                                name: 'code',
                                label: 'Kode Closing',
                                type: 'string',
                                filterable: true,
                                sortable: false,
                            },
                            {
                                name: 'termin_code',
                                label: 'Kode Termin',
                                type: 'string',
                                filterable: true,
                                sortable: false,
                            },
                            {
                                name: 'gmv_transaction',
                                label: 'Harga Transaksi',
                                filterable: true,
                                sortable: false,
                                type: 'number',
                                render: (data: string, row: any) => {
                                    if (data) {
                                        return numberToCurrency(data, 'Rp');
                                    } else {
                                        return "-";
                                    }
                                }
                            },
                            {
                                name: 'basic_price',
                                label: 'Harga Basic Price',
                                filterable: true,
                                sortable: false,
                                type: 'number',
                                render: (data: string, row: any) => {
                                    if (data) {
                                        return numberToCurrency(data, 'Rp');
                                    } else {
                                        return "-";
                                    }
                                }
                            },
                            {
                                name: 'duration',
                                label: 'Masa Sewa',
                                filterable: true,
                                sortable: false,
                                type: 'number',
                            },
                            {
                                name: 'extend_status',
                                label: 'Status Extend',
                                type: 'string',
                                filterable: true,
                                sortable: false,
                                options: [
                                    {
                                        key: '',
                                        value: 'All'
                                    },
                                    {
                                        key: 0,
                                        value: 'Sedang Sewa'
                                    },
                                    {
                                        key: 21,
                                        value: 'Checkout',
                                        type: 'parent'
                                    },
                                    {
                                        key: 7,
                                        value: 'Tunggu Saber selesai Checkout'
                                    },
                                    {
                                        key: 8,
                                        value: 'Sudah Checkout'
                                    },
                                    {
                                        key: 11,
                                        value: 'Processing Deposit'
                                    },
                                    {
                                        key: 12,
                                        value: 'Menunggu Konfirm Finance'
                                    },
                                    {
                                        key: 10,
                                        value: 'Settled (Deposit Dikembalikan)'
                                    },
                                ],
                                render: (data: any, row: any) => {
                                    return row.extend_status_label
                                }
                            },
                            {
                                name: 'extend_pic_name',
                                label: 'Nama PIC Extend',
                                type: 'string',
                                filterable: true,
                                sortable: false,
                                render: (data: string, row: any) => {
                                    return (
                                        <>
                                            <a href={`https://wa.me/${row.extend_pic_phone}`} target="_blank" rel='noopener noreferrer' id={`pic-phone-${row.extend_pic_phone}`} style={{ display: 'none' }}>call</a>
                                            <Chip variant="outlined" label={data} onClick={() => {
                                                const a = document.getElementById(`pic-phone-${row.extend_pic_phone}`)
                                                a?.click()
                                            }} style={{ cursor: "pointer" }} />
                                        </>
                                    )
                                }
                            },
                            {
                                name: 'pic_listing_name',
                                label: 'PIC',
                                filterable: true,
                                width: "200",
                                render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                                    return (
                                        <>
                                            {data || '-'}
                                            <Tooltip title="Change PIC">
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => {
                                                        setEditPICState({
                                                            unitId: row.unit_id,
                                                            picName: row.pic_listing_name,
                                                            picId: row.pic_listing_id,
                                                        })
                                                    }}
                                                    size="small"
                                                >
                                                    <Edit style={{ fontSize: "inherit" }} />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )
                                }
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            <AssignPICListingModal
                unitId={editPICState.unitId}
                open={editPICState.unitId !== 0}
                picId={editPICState.picId}
                name={editPICState.picName}
                onClose={() => {
                    setEditPICState({
                        unitId: 0,
                        picId: 0,
                        picName: '',
                    })
                }}
                callBackSubmitSuccess={() => {
                    setReDrawDatatable(prev => prev + 1)
                }}
            />
        </>
    )
}

export default DashboardListingAdminMain
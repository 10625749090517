import React, { useReducer, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import moment from 'moment';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../_components/LoadingScreen';

import {
    // BottomNavigation,
    // BottomNavigationAction,
    Grid,
    TextField,
    Button,
    Theme,
    Card,
    CardContent,
    ButtonGroup,
    Divider,
} from '@mui/material';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) => ({
        root: {
            maxWidth: '800px',
            marginBottom: '70px',
            boxShadow: '0px 3px 15px #0000001A',
            '& .Mui-disabled': {
                backgroundColor: '#eee'
            }
        },
        appBar: {
            position: 'relative'
        },
        addButton: {
            marginBottom: '20px'
        },
        title: {
            marginBottom: '5px',
            flex: 1,
        },
        filter: {
            width: 'auto',
            height: '30px',
            display: 'inline-flex',
            borderRadius: '4px',
            margin: '5px',
            fontSize: '0.8125rem',
            backgroundColor: '#009be5',
        },
        filterButton: {
            '&$selected': {
                color: 'red',
            },
            flex: 'none',
            color: '#fff',
            fontSize: '0.8125rem',
            padding: '6px 10px',
            minWidth: '50px',
            whiteSpace: 'nowrap'
            // fontSize: '0.8125rem',
            // border: '1px solid #006db3',
        },
        filterButtonSelected: {
            color: '#fff',
            borderRadius: '4px',
            backgroundColor: '#006db3',
            fontSize: '0.8125rem',
        },
        filterButtonLabel: {
            fontSize: '0.8125rem',
        },
        gridSubmit: {
            textAlign: 'right',
            marginBottom: '10px'
        },
        submit_button: {
            marginLeft: '8px'
        },
        date: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
    }), "Activity_Form"
)

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IState {
    id: string;
    activity: string;
    pic_id: string;
    pic_name: string;
    notes: string;
    client_name: string;
    client_phone: string;
    apartment_name: string;
    apartment_id: string;
    unit_name: string;
    unit_id: string;
    closing_id: string;
    closing_name: string;
    closing_label: string;
    enquiry_name: string;
    enquiry_id: string;
    enquiry_label: string;
    date: string;
}

const ActivityForm = () => {

    const { Root, classes } = useStyles();
    const [valueStatus, setValueStatus] = React.useState(1);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL + '/activity';
    const params: any = useParams();
    const initialState = {
        id: '',
        activity: '3',
        pic_id: '',
        pic_name: '',
        notes: '',
        client_name: '',
        client_phone: '',
        apartment_name: '',
        apartment_id: '',
        unit_name: '',
        unit_id: '',
        closing_id: '',
        closing_name: '',
        closing_label: '',
        enquiry_name: '',
        enquiry_id: '',
        enquiry_label: '',
        date: moment().format('YYYY-MM-DD HH:mm'),
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    const tabValue = (name: string, value: string) => {
        setInputState({ name, value: value, type: 'SET_ITEM' });
    }

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
        }
    }

    const handleAutocompletePic = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.pic_id = value.id.toString();
        newState.pic_name = value.label;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });

    }

    const handleAutocompleteClosing = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.closing_id = value.id.toString();
        newState.closing_name = value.label;
        newState.closing_label = value.label;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteEnquiry = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.enquiry_id = value.id.toString();
        newState.enquiry_name = value.label;
        newState.enquiry_label = value.label;
        newState.client_name = value.customer_name;
        newState.client_phone = value.customer_phone;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteApartment = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.apartment_id = value.id.toString();
        newState.apartment_name = value.label;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteUnits = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.unit_id = value.id.toString();
        newState.unit_name = value.label;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const handleDateChanged = (name: string, date: Date | null) => {
        setInputState({ name, value: date, type: 'SET_ITEM' });
    }

    const submitForm = () => {
        let axios;
        if (params.id) {
            axios = DefaultAxios.patch(`${API_URL}/${params.id}`, {
                ...inputState,
                date: moment(inputState.date).format('YYYY-MM-DD HH:mm'),
            });
        } else {
            axios = DefaultAxios.post(`${API_URL}`, {
                ...inputState,
                date: moment(inputState.date).format('YYYY-MM-DD HH:mm'),
            });
        }

        setLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Activity Submitted",
                    icon: 'success',
                    onAfterClose: () => {
                        navigate('/activity', {
                            state: { reload: true }
                        })
                    },
                    timer: 1000
                });
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setLoading(false);
            })

    }

    useEffect(() => {
        if (params.id) {
            setLoading(true);
            DefaultAxios
                .get(`${API_URL}/${params.id}`)
                .then(res => {
                    const data = res.data.activity;
                    data.date = moment(data.datetime, 'YYYY-MM-DD HH:mm');
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                })
                .finally(() => {
                    setLoading(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        document.body.style.backgroundColor = '#eee';
        let timeout: any;

        return () => {
            clearTimeout(timeout);
            document.body.style.backgroundColor = '#fff';
        }
        // eslint-disable-next-line
    }, [])


    return (
        <Root>
            <LoadingScreen open={loading} fullScreen />
            <Card className={classes.root}>
                <CardContent>

                    {
                        params.id === undefined ?
                            <>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <h1 className={classes.title}>Activity Type</h1>
                                    </Grid>
                                    <Divider />
                                    <Grid item xs={12} sx={{ mt: 2, mb: 4 }}>
                                        <ButtonGroup
                                            fullWidth
                                        >
                                            {/* <Button
                                                variant={valueStatus === 0 ? 'contained' : 'outlined'}
                                                onClick={() => {
                                                    setValueStatus(0)
                                                    tabValue('activity', '1')
                                                }
                                                }
                                            >
                                                Showing
                                            </Button> */}
                                            <Button
                                                variant={valueStatus === 1 ? 'contained' : 'outlined'}
                                                onClick={() => {
                                                    setValueStatus(1)
                                                    tabValue('activity', '3')
                                                }
                                                }
                                            >
                                                Maintenance
                                            </Button>
                                            {/* <Button
                                                variant={valueStatus === 2 ? 'contained': 'outlined'}
                                                onClick={() => {
                                                    setValueStatus(2)
                                                    tabValue('activity', '7')}
                                                }
                                            >
                                                Visit Owner
                                            </Button>
                                            <Button
                                                variant={valueStatus === 3 ? 'contained': 'outlined'}
                                                onClick={() => {
                                                    setValueStatus(3)
                                                    tabValue('activity', 'quality-control-listing')}
                                                }
                                            >
                                                QC Listing
                                            </Button> */}
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>

                                {/* <BottomNavigation
                                    value={valueStatus}
                                    onChange={(event, newValue) => {
                                        console.log(newValue)
                                        setValueStatus(newValue);
                                    }}
                                    style={{ marginBottom: '30px' }}
                                    showLabels
                                    className={classes.filter}
                                > */}
                                {/* <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('activity', '1')} label="Showing" /> */}
                                {/* <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('activity', '6')} label="Check Out" /> */}
                                {/* <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('activity', '2')} label="Hand Over" /> */}
                                {/* <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('activity', '3')} label="Maintenance" /> */}
                                {/* <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('activity', '4')} label="Cleaning" /> */}
                                {/* <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('activity', '5')} label="Deliver Document" /> */}
                                {/* <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('activity', '7')} label="Visit Owner" />
                                    <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('activity', 'quality-control-listing')} label="QC Listing" />
                                </BottomNavigation> */}
                            </>
                            : null
                    }



                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/* TODO: might break */}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    ampm={false}
                                    // variant="inline"
                                    label="Tanggal"
                                    inputFormat="dd/MM/yyyy HH:mm"
                                    value={inputState.date}
                                    // inputVariant='outlined'
                                    // onChange={newDate => setDate(newDate)}
                                    onChange={(date: any) => handleDateChanged('date', date)}
                                    renderInput={(props) => (
                                        <TextField
                                            {...props}
                                            variant="outlined"
                                            fullWidth
                                            label={props.label}
                                            // KeyboardButtonProps={{
                                            //     'aria-label': 'change date',
                                            // }}
                                            placeholder={props.placeholder ? props.placeholder : "dd/MM/yyyy HH:mm"}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>


                        {
                            (inputState.activity === '6' || inputState.activity === '2') && !params.id ?
                                <Grid item xs={12} sm={12}>
                                    <AsyncAutoComplete
                                        label="Closing"
                                        name="closing_id"
                                        initialQuery={inputState.closing_label}
                                        onChange={handleAutocompleteClosing}
                                        onInputChange={handleAutocompleteInputChanged}
                                        url={`${process.env.REACT_APP_API_URL}/autocomplete/closing`}
                                        placeholder="Closing"
                                        disabled={!!params.id}
                                    />
                                </Grid>
                                : null
                        }

                        {
                            (inputState.activity === '5' || inputState.activity === '1') && !params.id ?
                                <>
                                    <Grid item xs={12} sm={12}>
                                        <AsyncAutoComplete
                                            label="Enquiry"
                                            name="enquiry_id"
                                            initialQuery={inputState.enquiry_label}
                                            onChange={handleAutocompleteEnquiry}
                                            onInputChange={handleAutocompleteInputChanged}
                                            url={`${process.env.REACT_APP_API_URL}/admin/autocomplete/enquiry/activity`}
                                            placeholder="Enquiry"
                                            disabled={!!params.id}
                                        />
                                    </Grid>

                                </>
                                : null
                        }

                        {
                            (inputState.activity !== '6' && inputState.activity !== '2') && !params.id ?
                                <>
                                    {/* {
                                inputState.activity !== '1' ?
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Client Name"
                                                name="client_name"
                                                variant="outlined"
                                                value={inputState.client_name}
                                                onChange={handleInputChanged}
                                                fullWidth
                                                placeholder={inputState.activity === '4' ? 'Tidak usah diisi kalau tidak ada informasinya (c: star listing)' : ''}
                                                disabled={!!params.id}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Client Phone"
                                                name="client_phone"
                                                variant="outlined"
                                                value={inputState.client_phone}
                                                onChange={handleInputChanged}
                                                type="number"
                                                fullWidth
                                                placeholder={inputState.activity === '4' ? 'Tidak usah diisi kalau tidak ada informasinya (c: star listing)' : ''}
                                                disabled={!!params.id}
                                            >
                                            </TextField>
                                        </Grid>
                                    </> : null
                            } */}

                                    {
                                        !params.id
                                            ? <Grid item xs={12} sm={12}>
                                                <AsyncAutoComplete
                                                    label="Apartments"
                                                    name="apartment_id"
                                                    initialQuery={inputState.apartment_name}
                                                    onChange={handleAutocompleteApartment}
                                                    onInputChange={handleAutocompleteInputChanged}
                                                    url={`${process.env.REACT_APP_API_URL}/autocomplete/apartment`}
                                                    placeholder="Apartments"
                                                    disabled={!!params.id}
                                                />
                                            </Grid>
                                            : null
                                    }

                                    {(inputState.apartment_id !== '' || null || undefined) && !params.id ?
                                        <Grid item xs={12} sm={12}>
                                            <AsyncAutoComplete
                                                label="Units"
                                                name="unit_id"
                                                initialQuery={inputState.unit_name}
                                                onChange={handleAutocompleteUnits}
                                                onInputChange={handleAutocompleteInputChanged}
                                                url={`${process.env.REACT_APP_API_URL}/autocomplete/unit?show_all=1&apartment_ids=${inputState.apartment_id}`} // Show all
                                                placeholder="Units"
                                                disabled={!!params.id}
                                            />
                                        </Grid>
                                        : null
                                    }

                                </>
                                : null
                        }

                        <Grid item xs={12} sm={12}>
                            <AsyncAutoComplete
                                label="PIC Jendela"
                                name="pic_id"
                                initialQuery={inputState.pic_name}
                                onChange={handleAutocompletePic}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}${inputState.activity === 'quality-control' ? '/autocomplete/user/field-assistant' : '/autocomplete/user/active-staff'}`}
                                placeholder="PIC Jendela"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {/* <h4 className={classes.title}>Notes</h4> */}
                            <TextField
                                label="Notes"
                                name="notes"
                                value={inputState.notes}
                                onChange={handleInputChanged}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.gridSubmit}>
                            <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>


        </Root>
    )
}

export default ActivityForm;
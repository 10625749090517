import React from 'react'
import { Grid } from '@mui/material'

/**
 * Components
 */
import MenuBar from '../../_components/MenuBar'
import TodoListTable from '../_ticketSystem/_components/TodoListTable'
import GeneralPerformance from '../_generalPerformance/GeneralPerformance'
import TRPerformance from '../_trPerformance/TRPerformance'
interface IProps {
    permissions: any
    onlyManager?: boolean
}

const DashboardTR = (props: IProps) => {
    return (
        <MenuBar
            title='Dashboard Tenant Relation'
            backButton={false}
        >
            <Grid container spacing={3} sx={{ mb: 2 }}>
                {
                    props.onlyManager ?
                        <Grid item xs={12}>
                            <GeneralPerformance />
                        </Grid>
                        : null
                }
                <Grid item xs={12}>
                    <TRPerformance />
                </Grid>
            </Grid>
            <Grid container direction="column" spacing={2}>
                <Grid item xs={6}>
                    <TodoListTable
                        type='dashboard'
                        isAdmin={props.onlyManager}
                    />
                </Grid>
            </Grid>
        </MenuBar>
    )
}

export default DashboardTR

import React, { useEffect, useState } from 'react'
import { Theme } from '@mui/material'
import { useNavigate } from 'react-router'

// Components
import MenuBar from '../../_components/MenuBar'
import LoadingScreen from '../../_components/LoadingScreen'

// Utils
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import { generateStyle } from '../../_utils/DefaultStyle'

interface IMemberInfo {
    [key: string]: {
        "rented": number;
        "<=20": number;
        ">20": number;
        ">40": number;
        ">60": number;
        "total": number;
        assigned_advisor_id: number;
    }
}

const DashboardPMLead = () => {
    const { Root, classes } = useStyles()
    const [myName, setMyName] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const [list, setList] = useState<IMemberInfo>({});

    useEffect(() => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/jpm-dashboard-leader`)
            .then(res => res.data)
            .then((data: IMemberInfo) => {
                const newList: IMemberInfo = {};

                for (let [name, d] of Object.entries(data)) {
                    newList[name] = {
                        ...d,
                        "total": d["<=20"] + d[">20"] + d[">40"] + d[">60"]
                    }
                }

                setList(newList);
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsLoading(false)
            })

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/profile`)
            .then(res => res.data)
            .then(data => {
                const { name } = data

                if (name) {
                    setMyName(name)
                }
            })
    }, []);

    const renderContent = (show: 'odd' | 'even' | null = null) => {
        return Object.keys(list).map((name: string, index: number) => {
            const data = list[name];

            return show === null ||
                (show === 'odd' && index % 2 !== 0) ||
                (show === 'even' && index % 2 === 0)
                ? <div key={name} style={{ display: 'contents' }} onClick={() => navigate(`/dashboard-pm/${data.assigned_advisor_id}`)}>
                    <p className="content name">
                        {name.split(' ')[0]}
                    </p>
                    <div className="content">
                        <div className="count-container">
                            <p className="count">
                                {data["total"]}
                            </p>
                            <div className="indicator blue" />
                        </div>
                        <p className="percentage">
                            100%
                        </p>
                    </div>
                    <div className="content">
                        <div className="count-container">
                            <p className="count">
                                {data["<=20"]}
                            </p>
                            <div className="indicator green" />
                        </div>
                        <p className="percentage">
                            {data['<=20'] === 0 ? 0 : (data["<=20"] / data["total"] * 100).toFixed(0)}%
                        </p>
                    </div>
                    <div className="content">
                        <div className="count-container">
                            <p className="count">
                                {data[">20"]}
                            </p>
                            <div className="indicator yellow" />
                        </div>
                        <p className="percentage">
                            {data['>20'] === 0 ? 0 : (data[">20"] / data["total"] * 100).toFixed(0)}%
                        </p>
                    </div>
                    <div className="content">
                        <div className="count-container">
                            <p className="count">
                                {data[">40"]}
                            </p>
                            <div className="indicator orange" />
                        </div>
                        <p className="percentage">
                            {data['>40'] === 0 ? 0 : (data[">40"] / data["total"] * 100).toFixed(0)}%
                        </p>
                    </div>
                    <div className="content last">
                        <div className="count-container">
                            <p className="count">
                                {data[">60"]}
                            </p>
                            <div className="indicator red" />
                        </div>
                        <p className="percentage">
                            {data['>60'] === 0 ? 0 : (data[">60"] / data["total"] * 100).toFixed(0)}%
                        </p>
                    </div>
                    <div className="spacer" />
                </div>
                : false
        })
    }

    return (
        <MenuBar title={myName ? `${myName}'s member` : 'Dashboard PM Lead'} >
            <Root>
                <LoadingScreen open={isLoading} fullScreen />
                <div className={classes.root}>
                    <h1 className="title">
                        Jumlah unit vacant member anda
                    </h1>
                    <div className="container">
                        <div className="grid desktop">
                            <div />
                            <p className="header">
                                All Unit
                            </p>
                            <p className="header">
                                ≤20 D
                            </p>
                            <p className="header">
                                &gt;20 D
                            </p>
                            <p className="header">
                                &gt;40 D
                            </p>
                            <p className="header">
                                &gt;60 D
                            </p>
                            {renderContent('even')}
                            {/* {renderContent(tempList.slice(0,Math.ceil(tempList.length / 2)))} */}
                        </div>
                        <div className="grid desktop">
                            <div />
                            <p className="header">
                                All Unit
                            </p>
                            <p className="header">
                                ≤20 D
                            </p>
                            <p className="header">
                                &gt;20 D
                            </p>
                            <p className="header">
                                &gt;40 D
                            </p>
                            <p className="header">
                                &gt;60 D
                            </p>
                            {renderContent('odd')}
                            {/* {renderContent(tempList.slice(Math.ceil(tempList.length / 2)))} */}
                        </div>
                        <div className="grid mobile">
                            <div />
                            <p className="header">
                                All Unit
                            </p>
                            <p className="header">
                                ≤20 D
                            </p>
                            <p className="header">
                                &gt;20 D
                            </p>
                            <p className="header">
                                &gt;40 D
                            </p>
                            <p className="header">
                                &gt;60 D
                            </p>
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </Root>
        </MenuBar>
    )
}

export default DashboardPMLead

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        padding: '6px 8px',
        color: '#484848',

        [theme.breakpoints.up('md')]: {
            padding: '12px 0px 0px 28px',
        },

        '& .title': {
            fontSize: 16,
            lineHeight: '19px',
            marginBottom: 25,
            marginTop: 0,

            [theme.breakpoints.up('md')]: {
                fontSize: 17,
                lineHeight: '20px',
            },
        },

        '& .container': {
            display: 'flex',
            alignItems: 'flex-start',

            [theme.breakpoints.up('md')]: {
                '& .grid:first-of-type': {
                    marginRight: 16,
                },
            },

            '& .grid': {
                width: '100%',
                display: 'grid',
                cursor: 'pointer',
                gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',

                '&.desktop': {
                    display: 'none',
                },

                [theme.breakpoints.up('md')]: {
                    maxWidth: 345,

                    '&.mobile': {
                        display: 'none',
                    },

                    '&.desktop': {
                        display: 'grid',
                    },
                },

                '& .header': {
                    fontSize: 12,
                    lineHeight: '14px',
                    fontWeight: '600',
                    marginBottom: 7,
                    marginTop: 0,
                },

                '& .content': {
                    padding: '16px 7px 11px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    borderTop: '1px solid #48484833',
                    borderBottom: '1px solid #48484833',
                    borderLeft: '1px solid #4848480D',
                    borderRadius: 3,

                    '&.name': {
                        margin: 0,
                        fontSize: 13,
                        lineHeight: '15px',
                        wordBreak: 'break-word',
                        borderLeft: '1px solid #48484833',
                    },

                    '&.last': {
                        borderRight: '1px solid #48484833',
                    },

                    '& .count-container': {
                        display: 'flex',
                        alignItems: 'center',

                        '& .count': {
                            margin: 0,
                            marginRight: 4,
                            marginBottom: 4,
                            fontSize: 17,
                            lineHeight: '20px',
                            fontWeight: '600',
                        },

                        '& .indicator': {
                            width: 10,
                            height: 10,
                            borderRadius: 3,

                            '&.blue': {
                                backgroundColor: '#CFEEFA'
                            },

                            '&.green': {
                                backgroundColor: '#C7F4AD',
                            },

                            '&.yellow': {
                                backgroundColor: '#FEDA31'
                            },

                            '&.orange': {
                                backgroundColor: '#FDB485',
                            },

                            '&.red': {
                                backgroundColor: '#FCB3B3',
                            }
                        },
                    },

                    '& .percentage': {
                        margin: 0,
                        fontSize: 11,
                        lineHeight: '13px',
                    },
                },

                '& .spacer': {
                    height: 16,
                    gridColumn: 'span 6 / span 6',
                },
            },
        },
    },
}), "DashboardPM_Lead"
)
import React from "react";
import {
  Theme,
  Paper,
  Typography,
  Box,
  Divider
} from "@mui/material";

//icons
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import BathtubIcon from '@mui/icons-material/Bathtub';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneIcon from "@mui/icons-material/Phone";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//component
import ImageSlider from "./_imageSlider/ImageSlider";
//utils
import { convertNumber } from "../../_utils/Helper";
import { generateStyle } from "../../_utils/DefaultStyle";

const useStyles = generateStyle((theme: Theme) => ({
  cardContainer: {
    display: "flex",
    flexFlow: "column",
    width: "100%",
    marginBottom: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(1.5),
    boxShadow: '12px 10px 30px #d9d9d9'
  },
  cardTitle: {
    lineHeight: "1.5rem",
    marginTop: 8,
    marginBottom: 8
  },
  pill: {
    borderRadius: "1em",
    padding: "6px 13px",
    background: "#f7f7f7",
    color: "#000000CC",
    marginRight: 6,
    marginBottom: 7,
    display: "flex",
    flexFlow: "row",
    justifyItems: "center",
    alignItems: "center"
  },
  greenP: {
    background: "#EBFBEE",
    color: "#144738"
  },
  blueP: {
    background: "#EAF0FC",
    color: "#698ABD"
  },
  container: {
    display: "flex",
    flexFlow: "row",
    flexWrap: "wrap"
  },
  iconContainer: {
    background: "#EBFBEE",
    borderRadius: "20%",
    display: "flex",
    padding: 5,
    boxShadow: '7px 7px 15px #d9d9d9',
  },
  iconContainerOutlined: {
    border: "1px solid #033a11",
    borderRadius: '30%',
    display: "flex",
    padding: 6,
  },
  addressText: {
    color: "#00000099",
    margin: `${theme.spacing(2)}px 0`
  },
  containerSB: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: `${theme.spacing(1)}px 0`
  },
  descText: {
    fontStyle: 'italic',
    color: "#00000066",
    fontWeight: 400,
    margin: `${theme.spacing(1)}px 0`
  },
  textWithIcon: {
    display: 'flex',
    alignItems: 'center'
  },
  margin1: {
    margin: `${theme.spacing(1)}px 0`
  },
  margin2: {
    margin: `${theme.spacing(2)}px 0`
  },
  idTag: {
    fontWeight: 500,
    color: 'white',
    backgroundColor: '#033A11',
    borderBottomLeftRadius: 5,
    padding: '1px 5px 1px 9px',
    zIndex: 30
  },
  ribbon: {
    position: 'absolute',
    transform: 'rotate(45deg)',
    fontSize: 10,
    letterSpacing: 0.4,
    zIndex: 1000,
    backgroundColor: '#CF3636',
    width: '200px',
    height: '1.1rem',
    overflow: 'hidden',
    color: 'white',
    right: '-4.9rem',
    top: '0.9rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500
  },
  userPicture: {
    width: 40,
    height: 40,
    background: '#00000026',
    borderRadius: '50%',
    marginRight: 8
  },
  disabledCard: {
    filter: 'brightness(0.8)'
  },
}), "Unit_Card"
);

interface Props {
  data: any;
  onClick: (data: any) => void;
  onPhoneClick: () => void;
  is_market?: boolean
  isOnlySell?: boolean
}

const UnitCard = (props: Props) => {
  const { Root, classes } = useStyles();

  const getDiffDay = (date1: Date) => {
    const diffTime = Math.abs((new Date()).getTime() - date1.getTime());
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays === 0) {
      return 'Hari ini'
    }
    return diffDays.toString() + ' hari'
  }

  const renderUnitDetails = (details: any) => {
    return (details.map((detail: any, idx: number) => {
      if (!detail[1]) return null
      return (
        <Box className={classes.textWithIcon} style={{ marginRight: 14 }} key={idx}>
          {detail[0]}
          <Typography variant="caption" style={{ marginLeft: 8, fontWeight: 'bold' }}>{`${detail[1] || 0} ${detail[2]}`}</Typography>
        </Box>
      )
    }))
  }

  const handleClick = () => {
    props.onClick(props.data)
  }

  const price_to_display = () => {
    let number = '-';
    if (props.isOnlySell) {
      number = convertNumber(props.data.display_sell_price, '', true);
    } else if (props.data.display_rent_price) {
      number = convertNumber(props.data.display_rent_price, '', true);
    } else if (props.data.display_sell_price) {
      number = convertNumber(props.data.display_sell_price, '', true);
    }

    return number;
  }

  return (
    <Root>
      <Paper className={`${classes.cardContainer} ${typeof props.data.is_active !== "undefined" ? (props.data.is_active ? '' : classes.disabledCard) : ""}`}>
        {props.data.is_hot + '' === '1' ? (<div className={classes.ribbon}>HOT</div>) : ''}
        {/* { props.is_market ? '' : (
        <div style={{display: 'flex', flexFlow: 'row-reverse', }}>
          <Typography variant="subtitle2" className={classes.idTag}>{props.data.code}</Typography>
        </div>
      )} */}
        <div onClick={handleClick}>
          <ImageSlider img={props.data.photos} />
        </div>
        <Typography variant="h6" className={classes.cardTitle} onClick={handleClick}>
          {props.data.name}
        </Typography>
        <Box className={classes.container} onClick={handleClick}>
          {
            props.data.display_rent_price > 1
              ? <Typography
                variant="caption"
                className={`${classes.pill} ${classes.greenP}`}
              >
                Disewa
              </Typography>
              : null
          }
          {
            props.data.display_sell_price > 0
              ? <Typography
                variant="caption"
                className={`${classes.pill} ${classes.greenP}`}
              >
                Dijual
              </Typography>
              : null
          }
          <Typography
            variant="caption"
            className={`${classes.pill} ${classes.blueP}`}
          >
            {props.data.listing_type}
          </Typography>
          <Box className={classes.pill}>
            <Typography variant="caption">
              LB
              <strong>
                {` ${parseInt(props.data.property_area || 0)}m`}
                <sup>2</sup>
              </strong>
            </Typography>
            <div
              style={{
                height: "1em",
                width: 1,
                background: "#D3D3D3",
                margin: "0 6px"
              }}
            ></div>
            {props.data.listing_type !== 'Apartemen' &&
              (
                <Typography variant="caption">
                  LT
                  <strong>
                    {` ${parseInt(props.data.land_area || 0)}m`}
                    <sup>2</sup>
                  </strong>
                </Typography>
              )
            }
          </Box>
        </Box>
        <Typography variant="subtitle2" className={classes.addressText} onClick={handleClick}>
          {props.data.location}
        </Typography>
        <Box
          className={classes.container}
          style={{ justifyContent: "space-between", alignItems: 'center' }}
        >
          <Box onClick={handleClick}>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, marginBottom: -6 }}
            >{`Rp ${price_to_display()}`}</Typography>
            <Typography variant="caption" style={{ color: '#05544E', textDecoration: 'underline' }} onClick={handleClick}> Tap untuk lihat harga lainnya </Typography>
          </Box>
          {
            props.is_market ? '' : (
              <div className={classes.iconContainer} onClick={props.onPhoneClick}>
                <PhoneIcon style={{ fontSize: 30 }} />
              </div>
            )
          }
        </Box>
        <Box className={`${classes.container} ${classes.margin2}`} style={{ color: '#033A11' }} onClick={handleClick}>
          {renderUnitDetails([
            [<LocalHotelIcon style={{ fontSize: '20px', opacity: '0.6' }} />, props.data.bedroom === 0 ? 'Studio' : props.data.bedroom, props.data.bedroom === 0 ? '' : 'K Tidur'],
            [<BathtubIcon style={{ fontSize: '20px', opacity: '0.6' }} />, props.data.bathroom, 'K Mandi'],
            [<DirectionsCarIcon style={{ fontSize: '20px', opacity: '0.6' }} />, props.data.carport, 'Mobil']
          ])}
        </Box>
        {props.is_market ? '' : (
          <Typography variant='caption' className={classes.descText} onClick={handleClick}>
            {`“${props.data.notes}”`}
          </Typography>
        )}
        <Box className={classes.containerSB} onClick={handleClick}>
          <Typography variant="caption" style={{ color: '#0000004D' }}>
            {`ID Listing : ${props.data.code}`}
          </Typography>
          <Box className={classes.textWithIcon} style={{ color: '#0000004D' }}>
            <AccessTimeIcon style={{ fontSize: '14px' }} />
            <Typography variant="caption" style={{ marginLeft: 5 }}>{getDiffDay(new Date(props.data.created_at))}</Typography>
          </Box>
        </Box>

        {
          props.is_market ? (
            <>
              <Divider />
              <Box className={classes.containerSB}>
                <Box className={classes.textWithIcon}>
                  <AccountCircleIcon style={{ color: '#00000026', width: 50, height: 50, marginRight: 8 }} />
                  <Box>
                    <Typography variant='subtitle1' style={{ lineHeight: 1.5 }}>{props.data.agent_name}</Typography>
                    <Typography variant='caption' style={{ lineHeight: 1 }}>{props.data.agent_email}</Typography>
                  </Box>
                </Box>
                <div className={classes.iconContainerOutlined} onClick={props.onPhoneClick}>
                  <PhoneIcon style={{ fontSize: 30 }} />
                </div>
              </Box>
            </>
          ) : ''
        }
      </Paper>
    </Root>
  );
};

export default UnitCard;

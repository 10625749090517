import React, { useState, useReducer, useEffect, useRef, SyntheticEvent, useContext } from 'react'
import {
    Box,
    Typography,
    TextField,
    Divider,
    InputAdornment,
    Button,
    IconButton,
    Avatar,
    Theme,
} from "@mui/material";
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Context
 */
import { PermissionContext } from "../../_contexts/PermissionContext";

/**
 * Icon
 */
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, resizeAndResetOrientationImage } from '../../_utils/Helper';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) =>
({
    container: {
        padding: '0 0.5rem',
        display: 'flex',
        flexDirection: 'column'
    },
    photoContainer: {
        marginTop: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        '& .photo-icon': {
            height: 72,
            width: 72,
            borderRadius: 72,
            margin: '0 1.25rem 0 0',
            color: '#D9D9D9'
        },
        '& .photo': {
            height: 72,
            width: 72,
            borderRadius: 72,
            margin: '0 1.25rem 0 0'
        },
        '& .file-container': {
            display: 'flex',
            flexDirection: 'column',
            '& .upload-button': {
                color: '#033A11',
                fontSize: 16
            },
            '& .detail': {
                color: '#9b9b9b'
            }
        }
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(1)
    },
    formContainer: {
        marginTop: theme.spacing(3),
        display: "flex"
    },
    inputText: {
        flexGrow: 1
    },
    buttonSubmit: {
        fontSize: "14px",
        flexGrow: 1,
        borderRadius: 25
    },
    buttonText: {
        color: '#033A11',
        fontSize: 14,
        textAlign: 'left',
        whiteSpace: 'nowrap'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    appBar: {
        position: 'relative'
    },
}), "UserModal"
);

interface IProps {
    user_id?: string | null;
    is_edit?: boolean;
    permissions: any;
    successUrl?: '/agent-list'
}

interface IPhoto {
    url: string
    id: number
    file: File | null
}

interface IRole {
    id: string,
    slug: string,
    name: string
}

interface IState {
    photo: IPhoto
    name: string | null;
    nickname: string | null;
    // password: string;
    new_password: string;
    phone_number: string | null;
    phone_number_alternate: string | null;
    email: string | null;
    notification_email: string | null;
    new_password_confirmation: string;
    pintu_user_id: string | null;
    profile_description: string | null;
    role_user_id: string | null;
    area_id: string;
    area_label: string;
    primary_bank_type_id: string
    primary_bank_type_id_label: string
    primary_bank_behalf_name: string
    primary_bank_number: string
}

interface IStateError {
    name?: string;
    // password?: string;
    new_password?: string;
    phone_number?: string;
    phone_number_alternate?: string;
    email?: string;
    notification_email?: string;
    new_password_confirmation?: string;
    pintu_user_id?: string;
    profile_description?: string;
    role_user_id?: string;
}

interface IStateErrorAction {
    name: string;
    value: object | Date | null | string | number | Array<IActionValue>;
}

interface IActionValue {
    name: string;
    value: string;
}

const UserModal = (props: IProps) => {
    const { Root, classes } = useStyles();
    const permissions = useContext(PermissionContext)

    const uploadInput = useRef<HTMLInputElement>(null)

    const user_id = props.user_id

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [roleList, setRoleList] = useState<Array<IRole>>([]);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/role-list`)
            .then(response => {
                const data = response.data;
                setRoleList(data);
            })
            .catch(error => {
                // setIsLoading(false)
                alert('Kesalahan server, harap hubungi admin')
            });

        if (props.is_edit) {
            setIsLoading(true);

            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/user/${user_id}`)
                .then(res => {
                    setState({
                        ...state,
                        photo: {
                            id: Math.floor(Math.random() * Math.floor(9999)),
                            url: res.data.filepath || '',
                            file: null
                        },
                        name: res.data.name,
                        nickname: res.data.nickname,
                        // password: "",
                        new_password: "",
                        phone_number: res.data.phone_number,
                        phone_number_alternate: res.data.phone_number_alternate,
                        email: res.data.email,
                        notification_email: res.data.notification_email,
                        profile_description: res.data.profile_description,
                        pintu_user_id: res.data.pintu_user_id,
                        role_user_id: res.data.role_user_id,
                        new_password_confirmation: "",
                        area_id: res.data.area_id,
                        area_label: res.data.area_label,
                        primary_bank_behalf_name: res.data.primary_bank_behalf_name,
                        primary_bank_number: res.data.primary_bank_number,
                        primary_bank_type_id_label: res.data.primary_bank_type_name,
                        primary_bank_type_id: res.data.primary_bank_type_id,
                    })
                    setUserRole(res.data.role_user_id)
                })
                .catch(() => {
                    enqueueSnackbar('Terjadi kesalahan pada server, harap hubungi admin', { variant: 'error' })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
        // eslint-disable-next-line
    }, [])

    const [visible, setVisible] = useState({
        // password: false,
        new_password: false,
        new_password_confirmation: false,
    })

    const [state, setState] = useState<IState>({
        photo: {
            id: -1,
            url: '',
            file: null
        },
        name: "",
        nickname: '',
        // password: "",
        new_password: "",
        phone_number: "",
        phone_number_alternate: "",
        email: "",
        notification_email: "",
        new_password_confirmation: "",
        pintu_user_id: "",
        profile_description: "",
        role_user_id: "",
        area_id: '',
        area_label: '',
        primary_bank_type_id: '',
        primary_bank_type_id_label: '',
        primary_bank_behalf_name: '',
        primary_bank_number: '',
    });

    const errorReducer = (state: IStateError, action: IStateErrorAction) => {
        if (action.name === "reset") {
            return {
                name: "",
                new_password: "",
                new_password_confirmation: "",
                phone_number: "",
                phone_number_alternate: "",
                email: "",
                notification_email: "",
                profile_description: "",
                pintu_user_id: "",
                role_user_id: "",
            }
        } else if (action.name === "list_of_errors") {
            let newState: any = {};

            const list_errors = (action.value as Array<IActionValue>);
            list_errors.map(data => {
                newState[data.name] = data.value;
                return true;
            });

            return { ...newState };
        } else {
            return {
                ...state,
                [action.name]: action.value
            };
        }
    };

    const [errorText, setError] = useReducer(errorReducer, {});

    const [userRole, setUserRole] = React.useState(state.role_user_id);

    const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {

        setUserRole(event.target.value);
        setState({
            ...state,
            role_user_id: event.target.value
        });

    };

    // const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
    //     setState(prevState => ({
    //         ...prevState,
    //         [name]: value.id
    //     }));
    // }

    // const handleAutocompleteInputChanged = (e: any, name: string) => {
    //     setState(prevState => ({
    //         ...prevState,
    //         [name]: ''
    //     }));
    // }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            primary_bank_type_id: String(value.id),
            primary_bank_type_id_label: value.label
        }))
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            primary_bank_type_id: ''
        }))
    }

    const handleChange = (name: keyof typeof state) => (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setError({ name: name, value: "" });
        setState({
            ...state,
            [name]: event.target.value
        });
    };
    const handleChangeNumber = (name: keyof typeof state) => (
        event: React.ChangeEvent<{ value: any }>
    ) => {
        if (!event.target.value) {
            setState({
                ...state,
                [name]: ''
            });
        } else {
            if (event.target.value.match(/([0-9])+/g)) {
                let value = event.target.value.match(/([0-9])+/g)[0];
                setState({ ...state, [name]: value });
            }
        }
    };
    const handleVisiblePass = (name: keyof typeof visible) => () => {
        setVisible({
            ...visible,
            [name]: !(visible[name])
        })
    }

    const handleUpload = (e: SyntheticEvent) => {
        setIsLoading(true)
        const target = e.target as HTMLInputElement
        const files = target.files as FileList
        if (target && files) {
            if (files[0].size >= 5000000) {
                // enqueueSnackbar('Ukuran file maksimum adalah 5MB', { variant: 'error' })
            } else {
                resizeAndResetOrientationImage(files[0])
                    .then((file: File) => {
                        const url = process.env.REACT_APP_API_URL + '/user/upload/' + user_id
                        let formData = new FormData()
                        formData.append('image', file)
                        DefaultAxios.post(url, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                            .then(res => {
                                let newPhoto: IPhoto = {
                                    id: Math.floor(Math.random() * Math.floor(9999)),
                                    url: URL.createObjectURL(file),
                                    file: file
                                }
                                localStorage.setItem('user_photo', URL.createObjectURL(file))
                                setState((prevState) => ({
                                    ...prevState,
                                    photo: newPhoto
                                }))
                                enqueueSnackbar('Foto berhasil diupdate', { variant: 'success' })
                            })
                            .catch(err => {
                                enqueueSnackbar('Kesalahan server, harap hubungi admin', { variant: 'error' })
                            })
                            .finally(() => {
                                setIsLoading(false)
                            })
                    })
            }
        }
    }

    const formValidate = () => {
        let valid = true;
        setIsLoading(true);
        setError({ name: "reset", value: "" });
        const list_of_errors = [];

        if (state.name === "") {
            valid = false;
            list_of_errors.push({ name: "name", value: "Field harus diisi!" });
        }

        // if (state.password !== "" && props.is_edit) {
        //     // if (state.new_password === "") {
        //     //     valid = false;
        //     //     list_of_errors.push({ name: "new_password", value: "Isi dengan password baru!" });
        //     // }

        //     if (state.new_password_confirmation === "") {
        //         valid = false;
        //         list_of_errors.push({ name: "new_password_confirmation", value: "Isi dengan password baru!" });
        //     } else if (state.new_password !== "" && !(state.new_password === state.new_password_confirmation)) {
        //         valid = false;
        //         list_of_errors.push({ name: "new_password_confirmation", value: "Password tidak sama dengan password baru!" });
        //     }
        // }

        if (state.new_password === "" && !props.is_edit) {
            valid = false;
            list_of_errors.push({ name: "new_password", value: "Isi Password-nya!" });
        }

        if (state.new_password !== "") {
            // if (state.password === "" && props.is_edit) {
            //     valid = false;
            //     list_of_errors.push({ name: "password", value: "Isi dengan password saat ini!" });
            // }

            if (!(state.new_password === state.new_password_confirmation)) {
                valid = false;
                list_of_errors.push({ name: "new_password_confirmation", value: "Password tidak sama dengan password baru!" });
            } else if (state.new_password.length < 7) {
                valid = false;
                list_of_errors.push({ name: "new_password_confirmation", value: "Password minimal 7 karakter" });
            }
        }


        if (state.phone_number === "") {
            valid = false;
            list_of_errors.push({ name: "phone_number", value: "Field harus diisi!" });
        }

        // if (state.phone_number_alternate === "") {
        //     valid = false;
        //     list_of_errors.push({ name: "phone_number_alternate", value: "Field harus diisi!" });
        // }

        if (state.email === "") {
            valid = false;
            list_of_errors.push({ name: "email", value: "Field harus diisi!" });
        }

        // if (state.notification_email === "") {
        //     valid = false;
        //     list_of_errors.push({ name: "notification_email", value: "Field harus diisi!" });
        // }

        if (valid) {
            const promiseAxios = new Promise(resolve => {
                if (props.is_edit) {
                    resolve(DefaultAxios.put(`${process.env.REACT_APP_API_URL}/user/${user_id}`, state));
                } else {
                    const postAxios = DefaultAxios.post;
                    resolve(postAxios(`${process.env.REACT_APP_API_URL}/user`, state));
                }
            });

            promiseAxios
                .then(res => {
                    Swal.fire({
                        title: 'Submit berhasil',
                        icon: 'success',
                        timer: 1000,
                        onAfterClose: () => {
                            window.location.href = props.successUrl || '/user-list';
                        }
                    })
                })
                .catch(error => {
                    if (typeof error.response.status === 'undefined') {
                        // enqueueSnackbar('Error pada script, harap hubungi admin', { variant: 'error' })
                    } else {
                        switch (error.response.status) {
                            case 422:
                                for (let [key, value] of Object.entries(error.response.data.errors)) {
                                    setError({ name: key.toLowerCase(), value: value as string });
                                }
                                break
                            default:
                                generalErrorHandler(error)
                                break
                        }
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            setError({ name: "list_of_errors", value: list_of_errors })
            setIsLoading(false)
        }
    }


    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Box style={{ display: "flex", flexFlow: "column" }}>
                {props.is_edit &&
                    <>
                        <div className={classes.container}>
                            <div className={classes.photoContainer}>
                                {
                                    state.photo.url !== '' ?
                                        <Avatar alt="profile picture" src={state.photo.url || ''} className="photo" /> :
                                        <Avatar className="photo">{localStorage.getItem('user_name') && localStorage.getItem('user_name')![0].toUpperCase()}</Avatar>
                                }
                                <div className="file-container">
                                    <span className="upload-button" onClick={() => uploadInput.current!.click()}>Pilih Foto</span>
                                    {
                                        state.photo.file &&
                                        <span className="detail">{state.photo.file.name}</span>
                                    }
                                </div>
                                <input
                                    hidden
                                    type="file"
                                    name="upload[]"
                                    ref={uploadInput}
                                    value=""
                                    accept="image/x-png,image/jpeg"
                                    onChange={handleUpload}
                                />
                            </div>
                        </div>
                        <Divider style={{ marginTop: 20 }} />
                    </>
                }
                <div className={classes.container}>
                    <Typography
                        variant="subtitle2"
                        style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: "#000000",
                            marginTop: "1.5rem",
                            marginBottom: 8,
                            marginLeft: 4
                        }}
                    >
                        Nama & Email
                    </Typography>
                    <Box className={classes.formContainer}>
                        <TextField
                            className={classes.inputText}
                            error={!!errorText.name}
                            helperText={errorText.name}
                            label="Name"
                            name="name"
                            variant="outlined"
                            value={state.name}
                            onChange={handleChange("name")}
                        />
                    </Box>
                    <Box className={classes.formContainer}>
                        <TextField
                            className={classes.inputText}
                            error={!!errorText.nickname}
                            helperText={errorText.nickname}
                            label="Nickname"
                            name="nickname"
                            variant="outlined"
                            value={state.nickname}
                            onChange={handleChange("nickname")}
                        />
                    </Box>
                    <Box className={classes.formContainer}>
                        <TextField
                            className={classes.inputText}
                            error={!!errorText.phone_number}
                            helperText={errorText.phone_number}
                            label="Nomor Handphone"
                            name="phone"
                            variant="outlined"
                            autoComplete='no'
                            value={state.phone_number}
                            onChange={handleChangeNumber("phone_number")}
                        />
                    </Box>
                    <Box className={classes.formContainer}>
                        <TextField
                            className={classes.inputText}
                            error={!!errorText.email}
                            helperText={errorText.email}
                            label="Email"
                            name="email"
                            variant="outlined"
                            value={state.email}
                            autoComplete='no'
                            onChange={handleChange("email")}
                        />
                    </Box>
                    {/* <Box className={classes.formContainer}>
                        <TextField
                            className={classes.inputText}
                            // error={!!errorText.notification_email}
                            // helperText={errorText.notification_email}
                            label="Notification Email"
                            name="notification_email"
                            variant="outlined"
                            value={state.notification_email}
                            autoComplete='no'
                            onChange={handleChange("notification_email")}
                        />
                    </Box> */}
                    {/* <Box className={classes.formContainer}>
                        <AsyncAutoComplete 
                            label="Placement"
                            name="area_id"
                            initialQuery={state.area_label}
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/area`}
                        />
                    </Box> */}
                    {/* <Box className={classes.formContainer}> */}
                    <Typography
                        variant="subtitle2"
                        style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: "#000000",
                            marginTop: "1.5rem",
                            marginBottom: 8,
                            marginLeft: 4
                        }}
                    >
                        Bank Info
                    </Typography>
                    <Box className={classes.formContainer}>
                        <AsyncAutoComplete
                            url={process.env.REACT_APP_API_URL + '/admin/autocomplete/bank-type'}
                            initialQuery={state.primary_bank_type_id_label}
                            label="Bank"
                            name="primary_bank_type_id"
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            errorText={errorText.primary_bank_type_id}
                        />
                    </Box>
                    <Box className={classes.formContainer}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Atas Nama"
                            name="primary_bank_behalf_name"
                            value={state.primary_bank_behalf_name}
                            onChange={handleChange('primary_bank_behalf_name')}
                            error={!!errorText.primary_bank_behalf_name}
                            helperText={errorText.primary_bank_behalf_name}
                        />
                    </Box>
                    <Box className={classes.formContainer}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="number"
                            label="Nomor Rekening"
                            name="primary_bank_number"
                            value={state.primary_bank_number}
                            onChange={handleChange('primary_bank_number')}
                            error={!!errorText.primary_bank_number}
                            helperText={errorText.primary_bank_number}
                        />
                    </Box>
                    {
                        (permissions['user.manage'] || permissions['user.hr-manage'] || permissions['user.supervisor-manage'] || permissions['user.operation-manager-manage'] || permissions['user.pm-manage']) &&
                        <>
                            <Typography
                                variant="subtitle2"
                                style={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    color: "#000000",
                                    marginTop: "1.5rem",
                                    marginBottom: 8,
                                    marginLeft: 4
                                }}
                            >
                                Role
                            </Typography>
                            <Box className={classes.formContainer}>
                                <TextField
                                    className={classes.inputText}
                                    label="Role"
                                    name="role_user_id"
                                    variant="outlined"
                                    value={userRole}
                                    onChange={handleChangeRole}
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    <option aria-label="None" value="">Role</option>
                                    {roleList.map(data => {
                                        if (+data.id === 4) {
                                            return null;
                                        }

                                        return (
                                            <option value={data.id} key={data.id}>{data.name}</option>
                                        )
                                    })}
                                </TextField>
                            </Box>
                        </>
                    }

                </div>
                <Divider style={{ marginTop: 24 }} />
                <div className={classes.container}>
                    <Typography
                        variant="subtitle2"
                        style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: "#000000",
                            marginTop: "1.5rem",
                            marginBottom: 8,
                            marginLeft: 4
                        }}
                    >
                        {props.is_edit ? 'Change Password' : 'Password'}
                    </Typography>

                    <Typography
                        variant="caption"
                        style={{
                            color: "#707070B3",
                            marginBottom: 8,
                            marginLeft: 4
                        }}
                    >
                        {props.is_edit &&
                            <span>Jika ingin mengubah password ketik yang baru.<br /> Jika tidak biarkan kosong.</span>
                        }
                    </Typography>
                    {/* {props.is_edit &&
                        <Box className={classes.formContainer}>

                            <TextField
                                className={classes.inputText}
                                error={!!errorText.password}
                                helperText={errorText.password}
                                label="Current Password"
                                name="password"
                                variant="outlined"
                                value={state.password}
                                autoComplete='new-password'
                                onChange={handleChange("password")}
                                type={visible.password ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleVisiblePass('password')}
                                            >
                                                {visible.password ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    } */}
                    <Box className={classes.formContainer}>
                        <TextField
                            className={classes.inputText}
                            error={!!errorText.new_password}
                            helperText={errorText.new_password}
                            label="New Password"
                            name="new_password"
                            variant="outlined"
                            autoComplete='new-password'
                            value={state.new_password}
                            onChange={handleChange("new_password")}
                            type={visible.new_password ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleVisiblePass('new_password')}
                                        >
                                            {visible.new_password ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box className={classes.formContainer}>
                        <TextField
                            className={classes.inputText}
                            error={!!errorText.new_password_confirmation}
                            helperText={errorText.new_password_confirmation}
                            label="Confirm Password"
                            name="new_password_confirmation"
                            variant="outlined"
                            value={state.new_password_confirmation}
                            autoComplete='new-password'
                            type={visible.new_password_confirmation ? 'text' : 'password'}
                            onChange={handleChange("new_password_confirmation")}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleVisiblePass('new_password_confirmation')}
                                        >
                                            {visible.new_password_confirmation ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonSubmit}
                        style={{ marginTop: 48, marginBottom: 24 }}
                        onClick={formValidate}
                    >
                        <span style={{ padding: "6px 0" }}>Simpan perubahan</span>
                    </Button>
                </div>
            </Box>
        </Root>
    );
};

export default UserModal;

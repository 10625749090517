import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Box, Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import UpdateApartmentPerformanceModal from './UpdateApartmentPerformanceModal'
import LoadingScreen from '../../_components/LoadingScreen'

/**
 * Utils
 */
import { download, generalErrorHandler, renderToastSuccess } from '../../_utils/Helper'
import DefaultAxios from '../../_utils/DefaultAxios'
import { format } from 'date-fns'
import { generateStyle } from '../../_utils/DefaultStyle'

interface ApartmentPerformanceListProps {

}

interface Data {
    apartment_id: number
    apartment_name: string
    closing_rate_all_time: string
    closing_rate_avg_3: string
    closing_rate_this_month: string
    enquiry_all_time: string
    enquiry_avg_3: string
    enquiry_full_month: string
    enquiry_this_month: string
    showing_rate_all_time: string
    showing_rate_avg_3: string
    showing_rate_this_month: string
    traffic_all_time: string
    traffic_avg_3: string
    traffic_full_month: string
    traffic_this_month: string
}

const ApartmentPerformanceList = (props: ApartmentPerformanceListProps) => {
    const {Root, classes} = useStyles()

    const [isLoading, setIsLoading] = useState(true)
    const [isDownloadLoading, setIsDownloadLoading] = useState(false)
    const [data, setData] = useState<Data[]>([])
    const [lastUpdate, setLastUpdate] = useState<Date | null>(null)

    const [isProcessModalOpen, setIsProcessModalOpen] = useState(false)

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/apartment-performance`)
            .then(res => res.data)
            .then((data: any) => {
                setLastUpdate(data.last_update ? new Date(data.last_update) : null)
                setData(data.datas)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleExport = () => {
        setIsDownloadLoading(true)

        download(
            `${process.env.REACT_APP_API_URL}/apartment-performance/export`,
            'apartment-performance.xlsx',
            () => {
                renderToastSuccess('Berhasil export data')
                setIsDownloadLoading(false);
            },
            err => {
                generalErrorHandler(err)
                setIsDownloadLoading(false);
            }
        )
    }

    return (
        <Root>
            <LoadingScreen open={isDownloadLoading} fullScreen />
            <UpdateApartmentPerformanceModal
                isOpen={isProcessModalOpen}
                onClose={() => setIsProcessModalOpen(false)}
                onFinish={fetchData}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    Last Updated: {lastUpdate ? format(lastUpdate, 'dd MMMM yyyy') : '-'}
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'flex-end'}
                    justifyContent={'flex-end'}
                >
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => setIsProcessModalOpen(true)}
                    >
                        Update Data Performance
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleExport}
                        sx={{
                            ml: 2,
                        }}
                    >
                        Export
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 172px)' }}>
                        <Table sx={{ borderCollapse: 'separate' }}>
                            <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                <TableRow>
                                    {HEADER_FIRST_ROW.map((header, index) =>
                                        <TableCell
                                            key={index}
                                            align="center"
                                            sx={{
                                                borderLeft: index < 2 ? undefined : '1px solid rgba(224, 224, 224, 1)',
                                                borderRight: index === 0 ? '1px solid rgba(224, 224, 224, 1)' : undefined,
                                                minWidth: 164,
                                            }}
                                            className={index === 0 ? classes.firstColumn : undefined}
                                            {...header.props}
                                        >
                                            {header.label}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow>
                                    {HEADER_SECOND_ROW.map((header, index) =>
                                        <TableCell
                                            key={index}
                                            align='center'
                                            sx={{
                                                borderLeft: (header === 'All Time High' && index !== 0) ? '1px solid rgba(224, 224, 224, 1)' : undefined,
                                                minWidth: 123,
                                            }}
                                        >
                                            {header}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    isLoading ?
                                        <TableRow>
                                            <TableCell colSpan={15}>
                                                <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                >
                                                    <CircularProgress
                                                        size={16}
                                                        sx={{
                                                            mr: 1,
                                                        }}
                                                    />
                                                    Loading data...
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        :
                                        data.length ?
                                            data.map((row, index) =>
                                                <TableRow key={index}>
                                                    <TableCell
                                                        sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                                                        className={classes.firstColumn}
                                                    >
                                                        {row.apartment_name || '-'}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {row.traffic_all_time || '-'}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {row.traffic_avg_3 || '-'}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {row.traffic_this_month || '-'}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {row.traffic_full_month || '-'}
                                                    </TableCell>
                                                    <TableCell sx={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }} align='center'>
                                                        {row.enquiry_all_time || '-'}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {row.enquiry_avg_3 || '-'}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {row.enquiry_this_month || '-'}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {row.enquiry_full_month || '-'}
                                                    </TableCell>
                                                    <TableCell sx={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }} align='center'>
                                                        {row.showing_rate_all_time ? `${row.showing_rate_all_time}%` : '-'}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {row.showing_rate_avg_3 ? `${row.showing_rate_avg_3}%` : '-'}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {row.showing_rate_this_month ? `${row.showing_rate_this_month}%` : '-'}
                                                    </TableCell>
                                                    <TableCell sx={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }} align='center'>
                                                        {row.closing_rate_all_time ? `${row.closing_rate_all_time}%` : '-'}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {row.closing_rate_avg_3 ? `${row.closing_rate_avg_3}%` : '-'}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {row.closing_rate_this_month ? `${row.closing_rate_this_month}%` : '-'}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            :
                                            <TableRow>
                                                <TableCell colSpan={15}>
                                                    Belum ada data performance
                                                </TableCell>
                                            </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Root>
    )
}

const useStyles = generateStyle(() =>
    ({
        firstColumn: {
            position: 'sticky',
            left: 0,
            background: 'white',
        }
    }), "Apartment_Performance_list"
)

const HEADER_FIRST_ROW = [
    {
        label: 'Nama Apartement',
        props: {
            rowSpan: 2,
        },

    },
    {
        label: 'Traffic',
        props: {
            colSpan: 4,
        },

    },
    {
        label: 'Leads',
        props: {
            colSpan: 4,
        },

    },
    {
        label: 'Showing Rate',
        props: {
            colSpan: 3,
        },

    },
    {
        label: 'Closing Rate',
        props: {
            colSpan: 3,
        },

    },
]

const HEADER_SECOND_ROW = ['All Time High', 'Avg. 3 month', 'Month to Date', 'Full Month', 'All Time High', 'Avg. 3 month', 'Month to Date', 'Full Month', 'All Time High', 'Avg. 3 month', 'This Month', 'All Time High', 'Avg. 3 month', 'This Month']

export default ApartmentPerformanceList

import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

/**
 * Components
*/

/**
 * Utils
*/
import { generateStyle } from '../../_utils/DefaultStyle'
import TRPerformanceBillTable from './_components/TRPerformanceBillTable'
import TRPerformanceActiveTicketTable from './_components/TRPerformanceActiveTicketTable'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import LoadingScreen from '../../_components/LoadingScreen'

type Props = {}

export interface ITRPerformance {
    active_tickets: {
        tickets_1: {
            Greeting: number,
            Renewal: number,
            Lainnya: number
        },
        tickets_2: {
            Greeting: number,
            Renewal: number,
            Lainnya: number
        },
        tickets_3: {
            Greeting: number,
            Renewal: number,
            Lainnya: number
        },
        tickets_4: {
            Greeting: number,
            Renewal: number,
            Lainnya: number
        }
    },
    unpaid_jhs: {
        tenant: {
            total: number,
            billing_nominal: number
        },
        owner: {
            total: number,
            billing_nominal: number
        },
        deposit: {
            total: number,
            billing_nominal: number
        }
    }
}

const initialState = {
    active_tickets: {
        tickets_1: {
            Greeting: 0,
            Renewal: 0,
            Lainnya: 0
        },
        tickets_2: {
            Greeting: 0,
            Renewal: 0,
            Lainnya: 0
        },
        tickets_3: {
            Greeting: 0,
            Renewal: 0,
            Lainnya: 0
        },
        tickets_4: {
            Greeting: 0,
            Renewal: 0,
            Lainnya: 0
        }
    },
    unpaid_jhs: {
        tenant: {
            total: 0,
            billing_nominal: 0
        },
        owner: {
            total: 0,
            billing_nominal: 0
        },
        deposit: {
            total: 0,
            billing_nominal: 0
        }
    }
}

const useStyles = generateStyle(theme => ({
    title: {
        fontSize: 20,
        fontWeight: '700'
    },
}), 'TRPerformance')

const TRPerformance = (props: Props) => {
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<ITRPerformance>(initialState)

    const fetchData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/tr-performance`)
            .then(res => res.data)
            .then(res => {
                setState(res)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Root>
            <LoadingScreen open={isLoading} />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography className={classes.title}>Active Ticket</Typography>
                    <Grid container sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <TRPerformanceActiveTicketTable
                                data={state.active_tickets}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.title}>Unpaid Bill JHS</Typography>
                    <Grid container sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <TRPerformanceBillTable
                                data={state.unpaid_jhs}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Root>
    )
}

export default TRPerformance
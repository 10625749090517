import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

const UnitDownloadImage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [unitCode, setUnitCode] = useState('');

    const handleAutocompleteChange = (name: string, value: IAutoCompleteOption) => {
        setUnitCode(value.label);
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setUnitCode('');
    }

    const handleDownload = (type: 'original' | 'whatsapp') => {
        if (!unitCode) {
            return;
        }

        setIsLoading(true);
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/unit-download-image/${type}/${unitCode}`, { responseType: 'blob' })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `ORIGINAL_IMAGE_UNIT_${unitCode}.zip`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={2} style={{display: 'flex', flexDirection: 'column'}}>
                <Grid item xs={12} md={3}>
                    <AsyncAutoComplete
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/unit`}
                        label="Unit Code"
                        name="unit_code"
                        onChange={handleAutocompleteChange}
                        onInputChange={handleAutocompleteInputChanged}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDownload('original')}
                        fullWidth
                    >
                        Download Original Photo
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default UnitDownloadImage;
import React from 'react';
import classes from '../Assets/CSS/com.module.css';
import { RotateLeft, RotateRight } from '@mui/icons-material';

export interface PhotoPreviewProps {
    src: string,
    onRemoveFile: (idx: number) => void,
    index: number
    style?: React.CSSProperties
    onRotate?: (idx: number, clockwise: boolean) => void
    disableDelete?: boolean
}

export interface PhotoPreviewState {
    prevHeight: number,
    src: string
}

class PhotoPreview extends React.Component<PhotoPreviewProps, PhotoPreviewState> {
    imgPrev = React.createRef<HTMLDivElement>();

    constructor(props: PhotoPreviewProps) {
        super(props)
        this.state = {
            prevHeight: 0,
            src: this.props.src
        }
    }

    componentDidMount() {
        if (this.imgPrev.current) {
            this.setState({ prevHeight: this.imgPrev.current.offsetWidth - 4 });
        }
        window.addEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        if (this.imgPrev.current) {
            this.setState({ prevHeight: this.imgPrev.current.offsetWidth - 4 });
        }
    }

    render() {

        return (
            <div className={classes.phPrev} ref={this.imgPrev} style={{ backgroundImage: `url(${this.state.src})`, height: this.state.prevHeight, ...this.props.style }}>
                {
                    !this.props.disableDelete &&
                        <button disabled={this.props.disableDelete} onClick={this.props.onRemoveFile.bind(this, this.props.index)}>
                            <span></span>
                            <span></span>
                        </button>
                }
                {
                    this.props.onRotate &&
                    <div className={classes.rotateContainer}>
                        <span onClick={this.props.onRotate.bind(this, this.props.index, false)}>
                            <RotateLeft fontSize='small' />
                        </span>
                        <span onClick={this.props.onRotate.bind(this, this.props.index, true)}>
                            <RotateRight fontSize='small' />
                        </span>
                    </div>
                }
            </div>
        )
    }
}

export default PhotoPreview;
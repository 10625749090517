import React, { useState } from 'react'

/**
 * Components
 */
import ProductKnowledge from './ProductKnowledge'
import { Grid } from '@mui/material'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete'


const ProductKnowledgeList = () => {
    const [pk, setPk] = useState({
        id: '',
        name: '',
    })

    const handleAutocompleteApartment = (_: string, value: IAutoCompleteOption) => {
        setPk({
            id: value.id.toString(),
            name: value.label,
        })
    }

    const handleAutocompleteInputChanged = (_: any, name: string) => {
        setPk({
            id: '',
            name: '',
        })
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                    <AsyncAutoComplete
                        name="apartment_id"
                        initialQuery={pk.name}
                        onChange={handleAutocompleteApartment}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/apartment/product-knowledge`}
                        placeholder="Pilih Apartment"
                    />
                </Grid>
            </Grid>

            <ProductKnowledge
                open={!!pk.id}
                onClose={() => setPk({
                    id: '',
                    name: ''
                })}
                apartmentName={pk.name}
                id={pk.id}
            />
        </>
    )
}

export default ProductKnowledgeList

import React from 'react'

/**
 * Utils
 */
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

interface DndCardProps {
    id: string
    children: React.ReactNode
}

const DndCard = (props: DndCardProps) => {
    const sortable = useSortable({ id: props.id })
    const {
        attributes,
        listeners,
        // isDragging,
        setNodeRef,
        transform,
        transition,
    } = sortable

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...props}
            {...attributes}
            {...listeners}
        >
            {props.children}
        </div>
    )
}

export default DndCard

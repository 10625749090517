import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Theme,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Divider,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions
} from "@mui/material";
import { useSnackbar } from 'notistack';

//Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import BathtubIcon from "@mui/icons-material/Bathtub";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import InfoIcon from '@mui/icons-material/Info';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

//Components
import ImageSlider from "../../_components/_cards/_imageSlider/ImageSlider";
import LoadingCard from "../../_components/_cards/LoadingCard";
import LoadingScreen from "../../_components/LoadingScreen";

//Utils
import InfoTable from "./InfoTable";
import DefaultAxios from "../../_utils/DefaultAxios";

// Interfaces
import { IListing } from "./ListingList";
import ImagePreview from "../../_components/_cards/_imageSlider/ImagePreview";
import { generateStyle } from "../../_utils/DefaultStyle";

const useStyles = generateStyle((theme: Theme) =>
({
  listContainer: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  appBar: {
    position: "relative",
    overflow: 'hidden'
  },
  modalContainer: {
    position: "relative",
    overflowX: 'hidden',
  },
  modalContent: {
    padding: theme.spacing(1)
  },
  cardTitle: {
    lineHeight: "1.5rem",
    marginTop: 8,
    marginBottom: 8
  },
  pill: {
    borderRadius: "1em",
    padding: "6px 13px",
    fontWeight: 400,
    marginRight: 6,
    marginBottom: 7,
    display: "flex",
    flexFlow: "row",
    justifyItems: "center",
    alignItems: "center"
  },
  greenP: {
    background: "#EBFBEE",
    color: "#144738"
  },
  blueP: {
    background: "#EAF0FC",
    color: "#698ABD"
  },
  container: {
    display: "flex",
    flexFlow: "row",
    flexWrap: "wrap"
  },
  iconContainer: {
    background: "#EBFBEE",
    borderRadius: "20%",
    display: "flex",
    padding: 5
  },
  addressText: {
    color: "#00000099",
    margin: `${theme.spacing(2)}px 0`
  },
  containerSB: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: `${theme.spacing(1)}px 0`
  },
  descText: {
    fontStyle: "italic",
    color: "#00000066",
    fontWeight: 500,
    margin: `${theme.spacing(1)}px 0`,
    display: 'block'
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center"
  },
  margin1: {
    margin: `${theme.spacing(1)}px 0`
  },
  margin2: {
    margin: `${theme.spacing(2)}px 0`
  },
  button: {
    width: "40%",
    textTransform: "none",
    margin: `${theme.spacing(2)}px 0`,
    padding: `${theme.spacing(1)}px 0`
  },
  ribbon: {
    position: "absolute",
    transform: "rotate(45deg)",
    fontSize: 10,
    letterSpacing: 0.4,
    zIndex: 1000,
    backgroundColor: "#CF3636",
    width: "200px",
    height: "1.1rem",
    overflow: "hidden",
    color: "white",
    right: "-4.9rem",
    top: "0.9rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
  },
  priceContainer: {
    display: 'flex',
    padding: '1.5rem 0',
    '&.green': {
      backgroundColor: '#f5fdf6',
    },
    '&.gray': {
      backgroundColor: '#f7f7f7',
    },
    '& .column': {
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '&.fullwidth': {
        width: '100%',
      },
      '&.b-right': {
        borderRight: '1px solid #d9d9d9'
      },
      '&.b-left': {
        borderLeft: '1px solid #d9d9d9'
      },
      '& .price': {
        fontSize: 20,
        fontWeight: 700,
        marginBottom: '0.2rem',
        '&.sell-price': {
          fontSize: 22
        }
      },
      '& .label': {
        color: '#033A11',
        fontWeight: 400,
      },
    }
  },
  publish: {
    '& .platform-container': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
      '& .check-icon': {
        marginRight: '0.5rem',
        '&.active': {

        },
        '&.inactive': {
          color: '#CF3636'
        }
      },
      '& .platform': {
        fontSize: 16,
      },
      '& .link-container': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '0.5rem',
        padding: '0 0.2rem',
        '&.active': {

        },
        '&.inactive': {
          backgroundColor: '#EBFCEF'
        },
        '& .link-icon': {
          transform: 'rotate(-45deg)',
          fontSize: '14px',
          marginRight: '0.2rem',
        },
        '& .link': {
          fontSize: 12,
          color: '#033A11',
          textDecoration: 'none'
        }
      }
    },
  },
  step: {
    display: 'flex',
  },
  img_container: {
    width: "100%",
    height: "158px",
  },
}), "Listing_Modal"
);

interface Props {
  onClose: (data: any) => void;
  code: string;
  is_market?: boolean;
}

interface IListingPrice {
  value: string;
  duration: string;
}

interface IListingDetail extends IListing {
  id: string;
  owner_email: string;
  commission: string;
  sell_commission: string | null
  rent_commission: string | null
  apartment_name: null | string;
  floor: null | number | string;
  total_floor: null | number;
  tower: null | string;
  furnish: null | string;
  electric_watt: number;
  view: string;
  direction: string;
  certificate: string;
  building_finished: number;
  listing_type_id: string;
  number: string;
  is_hot: number;
  prices: IListingPrice[];
  display_rent_price: number;
  display_sell_price: number;
  display_currency: string;
  sell_price: string;
  price_per_year: string;
  price_per_6_months: string;
  price_per_3_months: string;
  price_per_1_month: string;
  daily_price: string;
  pic_listing: {
    name: string;
    phone: string;
    email: string;
  }
}

interface IPublish {
  active: boolean;
  title: string;
  url: string;
  type: string;
  status: number;
}

const availableMarketplace = [
  {
    type: 'jendela360',
    title: 'Jendela360'
  },
  {
    type: 'rumah123',
    title: 'Rumah123'
  }
];

// const publish = [
//   {
//     active: true,
//     title: 'Jendela360',
//     url: 'https://www.google.com'
//   },
//   {
//     active: true,
//     title: 'Mitula',
//     url: 'https://www.google.com'
//   },
//   {
//     active: true,
//     title: 'Trovit',
//     url: 'https://www.google.com'
//   },
//   {
//     active: true,
//     title: 'Rumah123',
//     url: 'https://www.google.com'
//   },
//   {
//     active: true,
//     title: 'Rumah.com',
//     url: 'https://www.google.com'
//   },
//   {
//     active: true,
//     title: 'OLX',
//     url: 'https://www.google.com'
//   },
//   {
//     active: false,
//     title: 'Rumah Dijual',
//     url: 'https://www.google.com'
//   },
//   {
//     active: true,
//     title: 'Mamikos',
//     url: 'https://www.google.com'
//   },
// ]

const ListingModal = (props: Props) => {
  const { Root, classes } = useStyles();

  const [openInfo, setOpenInfo] = useState<boolean>(false)

  const [infoData, setInfoData] = useState<IPublish>({} as IPublish)

  const [publish, setPublish] = useState<IPublish[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const openInfoDialog = (data: IPublish) => {
    setInfoData(data)
    setOpenInfo(true)
  }

  const closeInfo = () => {
    setInfoData({} as IPublish)
    setOpenInfo(false)
  }

  const [loading, setLoading] = useState(true);
  const [loadingScreenOpen, setLoadingScreenOpen] = useState(false);
  const [data, setData] = useState<IListingDetail>({} as IListingDetail);
  const ownerData = [
    ["Nama", data.landlord_name],
    ["Sebagai", "Pemilik"],
    ["Nomor Handphone", data.landlord_phone],
    ["Email", data.owner_email],
    ["Komisi Jual", data.sell_commission ? data.sell_commission + `%` : null],
    ["Komisi Sewa", data.rent_commission ? data.rent_commission + `%` : null]
  ]
  const [agentData, setAgentData] = useState<any[][]>([])

  const [isPreviewOpen, setIsPreviewOpen] = useState(false)

  const openPreview = () => {
    setIsPreviewOpen(true)
  }

  const closePreview = () => {
    setIsPreviewOpen(false)
  }

  const loadListing = () => {
    DefaultAxios.get(
      `${process.env.REACT_APP_API_URL}/${props.is_market ? 'listing-market' : 'listing'}/code/${props.code}`
    ).then(res => {
      const marketplace: IPublish[] = props.is_market ? [] : res.data.marketplace.slice();
      let newPublish: IPublish[] = [];

      availableMarketplace.map(item => {
        const found = marketplace.find(m => m.type === item.type);
        if (found) {
          newPublish.push({ ...found, title: item.title, active: true });
        } else {
          newPublish.push({ ...item, active: false, url: '', status: 0 });
        }
        return true;
      });

      setPublish(newPublish);
      setData(res.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    loadListing();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (typeof data.pic_listing !== 'undefined') {
      setAgentData(
        [["Nama", data.pic_listing.name],
        ["Nomor Handphone", data.pic_listing.phone, true],
        ["Email", data.pic_listing.email, true]]
      )
    }
  }, [data]);

  const getListingType = (type: string) => {
    switch (type) {
      case '1': return 'Apartemen'
      case '2': return 'Rumah'
      case '3': return 'Tanah'
      case '4': return 'Ruko'
      case '5': return 'Kantor'
      case '6': return 'Gudang'
      default: return type
    }
  }

  const furnished = () => {
    switch (data.furnish) {
      case 'non':
        return 'Non Furnished'
      case 'semi':
        return 'Semi Furnished'
      case 'full':
        return 'Full Furnished'
    }
  }

  const renderUnitDetails = (details: any) => {
    return details.map((detail: any, idx: number) => {
      if (!detail[1]) {
        return null
      }
      return (
        <Box
          className={classes.textWithIcon}
          style={{ marginRight: 14 }}
          key={idx}
        >
          {detail[0]}
          <Typography
            variant="caption"
            style={{ marginLeft: 8, fontWeight: "bold" }}
          >{`${detail[1] || 0} ${detail[2]}`}</Typography>
        </Box>
      );
    });
  };

  const convertPrice = (price: string) => {
    return parseInt(price.split('.')[0]).toLocaleString()
  }

  const renderPublish = publish.map((element, idx) => {
    let icon1 = null;
    let icon2 = null;
    let link = null;

    if (element.active) {
      if (+element.status === 1 || +element.status === 3) { // Success or success with file upload fail
        icon1 = <CheckCircleIcon color="primary" className="check-icon" />;
        icon2 = <LinkIcon color="primary" className="link-icon" />;
        link = <a href={element.url} className="link">Lihat iklan <i style={{ color: '#808080' }}>({element.url})</i></a>;
      } else if (+element.status === 0) { // Still process
        icon1 = <InfoIcon color="primary" className="check-icon" />;
        icon2 = null;
        link = <span className="link">On Process</span>;
      } else if (+element.status === 2) { // Fail
        icon1 = <ErrorOutlineIcon color="primary" className="check-icon inactive" />;
        icon2 = null;
        link = <span className="link">Publish Failed</span>;
      }
    } else {
      icon1 = <CancelIcon className="check-icon inactive" />;
      icon2 = <LinkOffIcon color="primary" className="link-icon" />;
      link = <span onClick={() => openInfoDialog(element)} className="link">Publish Sekarang</span>;
    }

    return (
      <div className="platform-container" key={idx}>
        {icon1}
        <span className="platform">{element.title} </span>
        <div className={`link-container ${!element.active && 'inactive'}`}>
          {icon2}
          {link}
        </div>
      </div>
    );
  })

  const onPublish = () => {
    const body = {
      type: infoData.type,
      listing_id: data.id
    };

    closeInfo();
    setLoadingScreenOpen(true);
    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/autopost`, body)
      .then(res => {
        loadListing();
        enqueueSnackbar('Silahkan tunggu beberapa saat sampai publish berhasil', { variant: 'success' });
      })
      .catch(error => {
        if (error.response.status === 400) {
          enqueueSnackbar(error.response.data, { variant: 'error' });
        } else {
          enqueueSnackbar('Kesalahan server, harap hubungi admin', { variant: 'error' });
        }
      })
      .finally(() => {
        setLoadingScreenOpen(false);
      })
  }

  return (
    <Root>
      <LoadingScreen open={loadingScreenOpen} fullScreen />
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Grid container justifyContent="space-between">
            <Grid>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.onClose}
                aria-label="close"
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid>
              <Grid container justifyContent="flex-end">
                {props.is_market ? (
                  ""
                ) : (
                  <Link to={`/listing/preview-edit/${data.id}`}>
                    <IconButton
                      edge="end"
                      color="inherit"
                      onClick={props.onClose}
                      aria-label="close"
                    >
                      <EditIcon style={{ color: "white" }} />
                    </IconButton>
                  </Link>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box className={classes.modalContainer}>
        {loading ? (
          <LoadingCard />
        ) : (
          <>
            {data.is_hot + '' === '1' ? <div className={classes.ribbon}>HOT</div> : ''}
            <ImageSlider img={data.photos} index={true} openPreview={openPreview} />
            {
              isPreviewOpen ?
                <ImagePreview
                  img={data.photos}
                  index={true}
                  close={closePreview}
                />
                : null
            }
            <Box className={classes.modalContent}>
              <Typography variant="h6" className={classes.cardTitle}>
                {data.name}
              </Typography>
              <Box className={classes.container}>
                {data.display_rent_price > 1 && (
                  <Typography
                    variant="caption"
                    className={`${classes.pill} ${classes.greenP}`}
                  >
                    Disewa
                  </Typography>
                )}
                {data.display_sell_price > 0 && (
                  <Typography
                    variant="caption"
                    className={`${classes.pill} ${classes.greenP}`}
                  >
                    Dijual
                  </Typography>
                )}
                <Typography
                  variant="caption"
                  className={`${classes.pill} ${classes.blueP}`}
                >
                  {getListingType(data.listing_type_id + '')}
                </Typography>
              </Box>

              <Box
                className={classes.container}
                style={{ alignItems: "center" }}
              >
                <Typography variant="subtitle2">
                  Luas Bangunan
                  <strong>
                    {` ${data.property_area ? parseInt(data.property_area) : 0
                      }m`}
                    <sup>2</sup>
                  </strong>
                </Typography>
                <div
                  style={{
                    height: "1em",
                    width: 1,
                    background: "#D3D3D3",
                    margin: "0 6px"
                  }}
                ></div>
                <Typography variant="subtitle2">
                  Luas Tanah
                  <strong>
                    {` ${data.land_area ? parseInt(data.land_area) : 0}m`}
                    <sup>2</sup>
                  </strong>
                </Typography>
              </Box>

              <Typography variant="subtitle2" className={classes.addressText}>
                {data.location}
              </Typography>

              {
                data.sell_price && data.sell_price !== '' &&
                <div className={`${classes.priceContainer} gray`}>
                  <div className="column fullwidth">
                    <span className="price sell-price">{data.sell_price ? (data.display_currency === "USD" ? "$ " : "Rp. ") + convertPrice(data.sell_price) : '-'}</span>
                    <span className="label">selling price</span>
                  </div>
                </div>
              }

              {
                ((data.price_per_year && data.price_per_year !== '') || (data.price_per_6_months && data.price_per_6_months !== '') || (data.price_per_3_months && data.price_per_3_months !== '') || (data.price_per_1_month && data.price_per_1_month !== '') || (data.daily_price && data.daily_price !== '')) &&
                <>
                  <div className={`${classes.priceContainer} green`}>
                    <div className="column b-right">
                      <span className="price">{data.price_per_year ? (data.display_currency === "USD" ? "$ " : "Rp. ") + convertPrice(data.price_per_year) : '-'}</span>
                      <span className="label">per tahun</span>
                    </div>
                    <div className="column b-left">
                      <span className="price">{data.price_per_6_months ? (data.display_currency === "USD" ? "$ " : "Rp. ") + convertPrice(data.price_per_6_months) : '-'}</span>
                      <span className="label">per 6 bulan</span>
                    </div>
                  </div>

                  <div className={`${classes.priceContainer} gray`}>
                    <div className="column b-left">
                      <span className="price">{data.price_per_3_months ? (data.display_currency === "USD" ? "$ " : "Rp. ") + convertPrice(data.price_per_3_months) : '-'}</span>
                      <span className="label">per 3 bulan</span>
                    </div>
                    <div className="column b-right">
                      <span className="price">{data.price_per_1_month ? (data.display_currency === "USD" ? "$ " : "Rp. ") + convertPrice(data.price_per_1_month) : '-'}</span>
                      <span className="label">per bulan</span>
                    </div>
                    {/* <div className="column b-left">
                        <span className="price">{data.daily_price ? (data.display_currency === "USD" ? "$ " : "Rp. ") + convertPrice(data.daily_price) : '-'}</span>
                        <span className="label">per hari</span>
                      </div> */}
                  </div>
                </>
              }

              <Box
                className={`${classes.container} ${classes.margin2}`}
                style={{ color: "#033A11" }}
              >
                {renderUnitDetails([
                  [
                    <LocalHotelIcon
                      style={{ fontSize: "20px", opacity: "0.6" }}
                    />,
                    data.bedroom === 0 ? 'Studio' : data.bedroom,
                    data.bedroom === 0 ? '' : "K Tidur"
                  ],
                  [
                    <BathtubIcon
                      style={{ fontSize: "20px", opacity: "0.6" }}
                    />,
                    data.bathroom,
                    "K Mandi"
                  ],
                  [
                    <DirectionsCarIcon
                      style={{ fontSize: "20px", opacity: "0.6" }}
                    />,
                    data.carport,
                    "Mobil"
                  ]
                ])}
              </Box>

              <Typography variant="caption">
                Tanggal Listing :{" "}
                {new Date(data.created_at).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                  year: "numeric"
                })}
              </Typography>

              <Divider />

              <Typography
                variant="h6"
                color="primary"
                style={{ fontWeight: 600 }}
                className={classes.margin2}
              >
                Informasi Properti
              </Typography>

              <InfoTable
                data={[
                  ["ID Properti", data.code, true],
                  ["Nama Apartemen", data.apartment_name || null],
                  ["Tower", data.tower || null],
                  ["Lantai", data.floor || null],
                  ["Nomor", data.number || "-"],
                  ["Sertifikat", data.certificate || "-"],
                  ["Tahun Dibangun", data.building_finished || "-"],
                  ["Jumlah Lantai", data.total_floor || "-"],
                  ["Interior", furnished() || "-"],
                  [
                    "Daya Listrik",
                    data.electric_watt ? `${data.electric_watt} Watt` : "-"
                  ],
                  ["Hadap", data.view || "-"],
                  ["Arah", data.direction || "-"],
                  [
                    "Fasilitas",
                    "Swimming Pool, AC, Water Heater, balkon, mesin cuci"
                  ]
                ]}
              />

              <Divider />

              <Typography
                variant="h6"
                color="primary"
                style={{ fontWeight: 600 }}
                className={classes.margin2}
              >
                {props.is_market ? 'Informasi Agent Listing' : 'Informasi Pemilik'}
              </Typography>

              <InfoTable
                data={props.is_market ? agentData : ownerData}
              />
              {
                props.is_market ? '' : data.notes ? (
                  <>
                    <Divider />
                    <Typography
                      variant="h6"
                      color="primary"
                      style={{ fontWeight: 600 }}
                      className={classes.margin2}
                    >
                      Catatan Pribadi
                    </Typography>
                    <Typography variant="caption" className={classes.descText} style={{ wordBreak: 'break-word' }}>
                      {`"${data.notes}"`}
                    </Typography>
                  </>
                ) : null
              }

              <Divider />

              {!props.is_market &&
                <>
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ fontWeight: 600 }}
                    className={classes.margin2}
                  >
                    Listing ini sudah dipublish ke
                  </Typography>

                  <div className={classes.publish}>
                    {renderPublish}
                  </div>
                </>
              }

              <Box className={classes.containerSB} style={{ marginTop: '2rem' }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CallIcon />}
                  className={classes.button}
                  href={"tel:+" + (props.is_market ? data.pic_listing.phone.replace(/[^0-9]/, "") : data.landlord_phone.replace(/[^0-9]/, ""))}
                >
                  Call
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<WhatsAppIcon />}
                  className={classes.button}
                  href={
                    "https://api.whatsapp.com/send?phone=+" +
                    (props.is_market ? data.pic_listing.phone.replace(/[^0-9]/, "") : data.landlord_phone.replace(/[^0-9]/, ""))
                  }
                >
                  WhatsApp
                </Button>
              </Box>
            </Box>
          </>
        )}
        <Dialog
          open={openInfo}
          onClose={closeInfo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <img src={`https://pintu-cdn.sgp1.cdn.digitaloceanspaces.com/assets/img/marketplace.png`} alt="pintu properti logo" className={classes.img_container} />
            <DialogTitle id="alert-dialog-title" style={{ padding: 0, marginBottom: 20 }}>Publish Iklan ke {infoData.title}</DialogTitle>
            <DialogContentText id="alert-dialog-description">
              Jika anda memilih Publish maka iklan anda akan otomatis di publish dalam max 1x24 jam.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeInfo} color="primary">
              Batal
            </Button>
            <Button onClick={onPublish} color="primary">
              Ya, Publish
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Root>
  );
};

export default ListingModal;

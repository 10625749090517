import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
    Paper,
    Theme,
    Grid,
    Tabs,
    Tab,
    IconButton,
    Tooltip,
    Button,
    Link,
    Chip,
    Typography
} from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";

/**
 * Components
 */
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';
import NewCommentList from '../../_components/NewCommentList';
import LogList from '../_unit/_components/LogList';
import ChangePICModal from './_components/ChangePICModal';
import ChangePhoneNumberModal from './_components/ChangePhoneNumberModal';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { dateFormat, generalErrorHandler, numberToCurrency, renderWarningButton } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';
import DOMPurify from 'dompurify';
import { IUnit } from '../_unit/UnitModalDetail';

/**
 * Icons
 */
import { AssignmentInd, Edit, PersonAdd, Visibility } from '@mui/icons-material';

export interface ImageState {
    id: number | null;
    asset_url: string;
}

interface Props {
    open: boolean
    onClose: (isRefresh?: boolean) => void
    onEdit: (data: any) => void
    rent_id?: string
    submissionCode?: string
    parentQueryString: string,
}

interface IParams extends Record<string, string | undefined> {
    rent_id?: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    container: {
        backgroundColor: "#f3f3f3",
        // height: '100%'
    },
    bottom_button: {
        marginBottom: '16px',
        justifyContent: 'flex-end'
    },
    edit_button: {
        // backgroundColor: '#fff',
        color: '#fff'
    },
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        // float: 'right'
        color: '#f50057'

    },
    listTitle: {
        fontWeight: 600
    },
    formContainer: {
        marginTop: theme.spacing(3),
        display: "flex"
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(2, 2),
        marginTop: theme.spacing(5),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3, 2),
        }
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    paperTab: {
        margin: "0"
    },
    paper: {
        // marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "97%",
        color: theme.palette.text.secondary,
    },
    paperFooter: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    paper_label: {
        position: "absolute",
        marginTop: "-45px",
        padding: "1px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)"
    },
    paper_label_text: {
        backgroundColor: "#0094ff",
        fontSize: "1.5em",
        padding: "13px",
        borderRadius: "4px",
        color: "white"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        paddingTop: '10px'
    },
    listBox: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    box: {
        padding: theme.spacing(2)
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    imageBox: {
        display: "flex",
        flexFlow: "column",
        marginTop: '15px'
    },
    head_title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    head_title_top: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1)
    },
    footer: {
        display: 'flex',
        minHeight: '500px',
        backgroundColor: '#eee',
        padding: '10px',
    },
    body: {
        wordBreak: 'break-word',
    },
    fileListTable: {
        '& > table': {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'table',
            },
        },
        '& > .mobile-list': {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
            '& > *': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            },
            '& > *:last-child': {
                borderBottom: 'none',
            },
        },
        '& > table > tbody > tr > td:first-of-type': {
            width: 75,
            '& > *': {
                objectFit: 'cover',
                cursor: 'pointer',
            }
        },
        '& .file-name': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.dark,
            }
        }
    },
    button: {
        margin: theme.spacing(1),
    },
    'button-list': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > button': {
            marginRight: 16,
            paddingRight: 12,
            paddingLeft: 12,
            marginBottom: 16,
        },

        '& > button:last-child': {
            marginRight: 0,
        },

        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',

            '& > button': {
                marginBottom: 0,
            }
        }
    },
    actionButtonContainer: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
        overflow: 'auto',
        margin: '0 0',
        '& button, a': {
            marginLeft: '10px'
        }
    },
}), "RentApartmentDetail"
)

interface IUnitExtends extends IUnit {
    apartment_name: string
    submission_code: string,
    submission_infos: {
        status: number
        pic_name: string
        apartment_name: string
        unit_for: number
        ownership_type: number
        requester_name: string
        requester_phone: string
        requester_email: string
        submission_notes: string

    } | null
    submission_status_label: string
    submission_failed_information: string
}

const initialState: IUnitExtends = {
    id: 0,
    is_owner: 0,
    name: "",
    code: "",
    category: 0,
    tower: "",
    floor_level: 0,
    unit_number: "",
    unit_area: 0,
    view_direction: "",
    furnished: "",
    bedroom: 0,
    bathroom: 0,
    maid_room: 0,
    is_available: 0,
    is_available_sell: 0,
    is_special_price: 0,
    is_entrusted_key: 0,
    is_active: 0,
    available_date: "",
    signing_date: "",
    published_date: "",
    price_per_1_month: 0,
    basic_price_1: 0,
    price_per_3_months: 0,
    basic_price_3: 0,
    price_per_6_months: 0,
    basic_price_6: 0,
    price_per_12_months: 0,
    basic_price_12: 0,
    selling_price: 0,
    basic_price_sell: 0,
    landlord_id: 0,
    service_charge: 0,
    is_agreement_sent: 0,
    is_agreement_finished: 0,
    file_list: [],
    seekmi_agent_name: "",
    inventory_status: 0,
    pic_name: "",
    pic_email: "",
    pic_handphone: "",
    pic_landlord_id: '',
    pic_landlord_name: '',
    pic_landlord_phone: '',
    assigned_advisor_label: "",
    pic_listing_name_label: "",
    current_holder: "",
    created_at: "",
    created_by_label: "",
    is_requested_change_availability: 0,
    updated_at: "",
    keterangan: "",
    vacant_days: "",
    is_priority_sort: 0,
    closing_histories: [],
    request_deleted_by: null,
    deleted_reason: null,
    deleted_by: null,
    submission_code: '',
    apartment_name: '',
    submission_infos: {
        apartment_name: '',
        pic_name: '',
        ownership_type: 0,
        requester_email: '',
        requester_name: '',
        requester_phone: '',
        submission_notes: '',
        unit_for: 0,
        status: 0
    },
    submission_status_label: '',
    submission_failed_information: '',
}


const RentApartmentDetail = (props: Props) => {
    const navigate = useNavigate();
    const { Root, classes } = useStyles();
    const permissions = useContext(PermissionContext)
    const [detail, setDetail] = useState<IUnitExtends>(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdited, setIsEdited] = useState(false)
    const params = useParams<IParams>()

    const submissionCode = useMemo(() => props.submissionCode || params.submission_code, [props.submissionCode, params.submission_code])

    const [value, setValue] = useState(0);
    const [currency, setCurrency] = useState('Rp');
    const [picModal, setPicModal] = useState(false)

    const [selectedIdForPhoneNumber, setSelectedIdForPhoneNumber] = useState('')

    const submissionStatusChipColor = useMemo(() => {
        switch (detail.submission_status_label) {
            case 'New Submission': return 'default'
            case 'Listed': return 'success'
            case 'Rejected': return 'secondary'
            case 'On Process': return 'default'
            case 'Cold': return 'primary'
            default: return 'default'
        }
    }, [detail.submission_status_label])

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/unit/detail-code`, {
                params: { code: submissionCode }
            })
            .then(res => {
                const imageGallery = res.data.rent_submission_assets;
                const newImageStates = [];
                for (let key in imageGallery) {
                    newImageStates.push({
                        id: imageGallery[key].id,
                        asset_url: imageGallery[key].asset_url,
                    })
                }
                setDetail(res.data);
                setCurrency(res.data.is_dollar ? '$' : 'Rp');
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (submissionCode) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionCode]);

    useEffect(() => {
        if (props.open) {
            setIsEdited(false)
        }
    }, [props.open])

    const renderLogs = () => {
        return (
            <LogList
                API_URL={process.env.REACT_APP_API_URL + '/unit/' + detail.id + '/log'}
            />
        )
    }

    const handleApproveSubmission = () => {
        renderWarningButton('Changing to Approved | Do you want to proceed ?').then((res) => {
            if (res.value) {
                setIsLoading(true)
                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/rent-apartment/approve`, {
                    id: detail.id
                })
                    .then(res => res.data)
                    .then(res => {
                        window.open(`/unit/${res.code}`, '_blank')
                        loadData()
                    })
                    .catch(generalErrorHandler)
                    .finally(() => {
                        setIsLoading(false)

                    })
            }
        })
    }

    return (
        <>
            <ModalDetail
                open={props.open}
                isLoading={isLoading}
                title={(
                    <>
                        [{detail.submission_code}]
                        {
                            detail.code ?
                                <IconButton style={{ color: 'white', marginLeft: 6, verticalAlign: 'sub', padding: 0 }} href={`${process.env.REACT_APP_MAIN_URL}/${detail.code}`} target="_blank">
                                    <Visibility />
                                </IconButton>
                                : null
                        }
                    </>
                )}
                showEditButton
                editButtonLink={() => navigate(`/listing-submission-apartment/edit/${detail.id}`)}
                onCloseModal={() => props.onClose(isEdited)}
                stickyHeader
            >
                <>
                    <ModalSection
                        xs={12}
                        md={6}
                        title="Informasi Dasar"
                        content={[
                            [
                                'Submission Status',
                                <>
                                    <Chip
                                        label={detail.submission_status_label}
                                        color={submissionStatusChipColor}
                                    />
                                    {
                                        detail.submission_status_label === 'Rejected' ?
                                            <Typography variant="body2" sx={{ wordBreak: 'break-word', mt: '7px' }}>
                                                Reason: {detail.submission_failed_information || '-'}
                                            </Typography>
                                            : null
                                    }
                                </>
                            ],
                            ...(detail.submission_infos?.status === 1 ? [['Unit Code',
                                <>
                                    <Link
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => { window.open(`/unit/${detail.code}`, '_blank') }}
                                    >
                                        {detail.code}
                                    </Link>
                                </>
                            ]] : []),
                            // ['Available Date', detail.available_date],
                            // ['Signing Date', detail.signing_date],
                            // ['Published Date', detail.published_date ? dateFormat(detail.published_date, 'DD MMM YYYY') : "-"],
                            ['Title', detail.name],
                            ['Apartment Name', detail.apartment_name],
                            ['Tower / Floor / Unit Number', `${detail.tower} / ${detail.floor_level} / ${detail.unit_number}`],
                            ['Unit View', detail.view_direction],
                            ['Unit Area', `${detail.unit_area} m2`],
                            ['Furnished', detail.furnished],
                            ['Bedroom / Bathroom / Maidroom', `${detail.bedroom ? `${detail.bedroom} Bedroom` : 'Studio'} / ${detail.bathroom} Bathroom / ${detail.maid_room} Maidroom`],
                            ['Created Date', `${dateFormat(detail.created_at, 'DD MMM YYYY HH:mm:ss')} by ${detail.created_by_label}`],
                            ['Last Modified Date', dateFormat(detail.updated_at, 'DD MMM YYYY HH:mm:ss')],
                            ['Keterangan', detail.keterangan ? (<Grid item xs={6} sm={8} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail.keterangan) }} />) : '-'],
                        ]}
                    />

                    <Grid item xs={12} md={6}>
                        <ModalSection
                            title="Informasi Harga"
                            content={[
                                // ['Sewa Harian', detail.price_per_day ? numberToCurrency(detail.price_per_day, `${currency}.`) : `${currency}. -`],
                                ['Sewa 12 Bulan', detail.price_per_12_months ? `Basic Price: ${numberToCurrency(detail.basic_price_12, `${currency}.`)} | Web Price: ${numberToCurrency(detail.price_per_12_months, `${currency}.`)}` : `${currency}. -`],
                                ['Sewa 6 Bulan', detail.price_per_6_months ? `Basic Price: ${numberToCurrency(detail.basic_price_6, `${currency}.`)} | Web Price: ${numberToCurrency(detail.price_per_6_months, `${currency}.`)}` : `${currency}. -`],
                                ['Sewa 3 Bulan', detail.price_per_3_months ? `Basic Price: ${numberToCurrency(detail.basic_price_3, `${currency}.`)} | Web Price: ${numberToCurrency(detail.price_per_3_months, `${currency}.`)}` : `${currency}. -`],
                                ['Sewa Bulanan', detail.price_per_1_month ? `Basic Price: ${numberToCurrency(detail.basic_price_1, `${currency}.`)} | Web Price: ${numberToCurrency(detail.price_per_1_month, `${currency}.`)}` : `${currency}. -`],
                                ['Harga Jual', detail.selling_price ? `Basic Price: ${numberToCurrency(detail.basic_price_sell, `${currency}.`)} | Web Price: ${numberToCurrency(detail.selling_price, `${currency}.`)}` : `${currency}. -`],
                                ['Service Charge', detail.service_charge]
                            ]}
                        />
                        <ModalSection
                            title="Informasi Submission"
                            content={[
                                [
                                    <>
                                        Assigned PIC
                                        {
                                            permissions['submission.admin'] ?
                                                <Tooltip title={`${detail.submission_infos?.pic_name !== null ? 'Ganti' : 'Assign'} PIC`}>
                                                    <IconButton color="primary" onClick={() => { setPicModal(true) }}>
                                                        {detail.submission_infos?.pic_name !== null ?
                                                            <AssignmentInd />
                                                            :
                                                            <PersonAdd />
                                                        }
                                                    </IconButton>
                                                </Tooltip>
                                                : null
                                        }
                                    </>
                                    , detail.submission_infos?.pic_name],
                                ['Requested Apartment', detail.submission_infos?.apartment_name],
                                ["Listing Sewa / Jual",
                                    detail.submission_infos ?
                                        detail.submission_infos.unit_for === 0 ?
                                            "Sewa" :
                                            detail.submission_infos.unit_for === 1 ? 'Jual' : 'Sewa dan Jual'
                                        : '-'],
                                ['Ownership', detail.submission_infos ?
                                    detail.submission_infos.ownership_type === 0 ? "Agent" : "Owner" :
                                    "-"
                                ],
                                ['Name', detail.submission_infos?.requester_name],
                                [
                                    <>
                                        Phone
                                        {
                                            (permissions['submission.change-phone'] && detail.submission_infos?.requester_phone) ?
                                                <IconButton
                                                    color='primary'
                                                    onClick={() => {
                                                        setSelectedIdForPhoneNumber(detail.id.toString())
                                                    }}
                                                >
                                                    <Edit />
                                                </IconButton>
                                                : null
                                        }
                                    </>
                                    , detail.submission_infos?.requester_phone
                                ],
                                ['Email', detail.submission_infos?.requester_email],
                                ['Notes', detail.submission_infos?.submission_notes || '-'],
                            ]}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Root>
                            <div className={classes.actionButtonContainer}>
                                {
                                    ((detail.submission_infos && [1].includes(detail.submission_infos.status)) || detail.code) ?
                                        null :
                                        <Grid>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={handleApproveSubmission}
                                            >
                                                Approve
                                            </Button>
                                        </Grid>
                                }
                            </div>
                        </Root>
                    </Grid>

                    <Grid item xs={12}>
                        <Root>
                            <Paper className={classes.root}>
                                <div className={classes.paperTab}>
                                    <Tabs
                                        style={{ backgroundColor: 'white' }}
                                        textColor="primary"
                                        value={value} onChange={(e, newValue) => setValue(newValue)}
                                        aria-label="simple tabs example"
                                    >
                                        <Tab label="Comments" value={0} {...a11yProps(0)} />
                                        {permissions['detail.see-log'] &&
                                            <Tab label="Logs" value={5} {...a11yProps(5)} />
                                        }
                                    </Tabs>
                                </div>

                                <TabPanel value={value} index={0}>
                                    <br></br>
                                    <NewCommentList
                                        id={detail.id}
                                        type="unit"
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={5}>
                                    <br></br>
                                    {renderLogs()}
                                </TabPanel>
                            </Paper>
                        </Root>
                    </Grid>
                </>
            </ModalDetail>
            <ChangePICModal
                open={picModal}
                id={detail.id}
                initialPICName={detail.submission_infos?.pic_name ? detail.submission_infos?.pic_name : ''}
                onClose={(refresh: boolean) => {
                    setPicModal(false)

                    if (refresh) {
                        setIsEdited(true)
                        loadData()
                    }
                }}
            />
            <ChangePhoneNumberModal
                open={selectedIdForPhoneNumber !== ''}
                unitId={selectedIdForPhoneNumber}
                onClose={() => {
                    setSelectedIdForPhoneNumber('')
                }}
                onSuccess={() => {
                    loadData()
                }}
            />
        </>
    );
}

const a11yProps = (index: any) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

export default RentApartmentDetail;
import React from 'react';
import { createRoot } from 'react-dom/client'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'date-fns';
import { pink } from '@mui/material/colors';
import { initializeNotification } from './_utils/DefaultNotification';


if (process.env.REACT_APP_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_PUBLIC_SENTRY_DSN,
        environment: process.env.REACT_APP_ENV,
        release: process.env.REACT_APP_SENTRY_RELEASE,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        autoSessionTracking: true,
        tracesSampleRate: 1.0,
        beforeSend(event, hint) {
            // Check if it is an exception, and if so, show the report dialog
            // if (event.exception) {
            //   Sentry.showReportDialog({ eventId: event.event_id });
            // }
            return event;
        },
    });
    if (localStorage.getItem('user_id')) {
        Sentry.setUser({
            id: localStorage.getItem('user_id') ?? undefined
        })
    }
}

initializeNotification()

const theme = createTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: '#009be5',
            dark: '#006db3',
            contrastText: '#fff',
        },
        secondary: pink,
        background: {
            default: '#fff'
        }
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    }
                },
            }
        }
    },
});

const container = document.getElementById('root')

const root = createRoot(container!)
root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <App />
            </LocalizationProvider >
        </ThemeProvider>
    </StyledEngineProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

import React, { useState, useEffect } from 'react';
import {
    Button,
    Theme,
    Link,
    // Switch
} from '@mui/material';
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
// import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import AgentDetail from './components/AgentModalDetail';

/**
 * Utils
 */
// import DefaultAxios from '../../_utils/DefaultAxios';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import { generateStyle } from '../../_utils/DefaultStyle';

// const SwalContainer = withReactContent(Swal)

interface IProps {
    agent_id?: string
}
interface IParams extends Record<string, string | undefined> {
    agent_id?: string
}

const useStyles = generateStyle((theme: Theme) => ({
    addButton: {
        marginBottom: '20px'
    },
}), "Agent_List"
)

const AgentList = (props: IProps) => {
    const { Root, classes } = useStyles();
    const DT_API_URL = process.env.REACT_APP_API_URL + '/agent';
    const params = useParams<IParams>()
    const navigate = useNavigate();
    const [selectedId, setSelectedId] = useState<string>('');
    const [modalDashboardState, setModalDashboardState] = useState(false);
    const handleOpenDashboard = () => setModalDashboardState(true);
    const handleCloseDashboard = () => {
        setModalDashboardState(false)
        window.history.pushState([], 'Agent', `/agent`);
    };

    useEffect(() => {
        if (typeof params.agent_id !== "undefined") {
            setSelectedId(params.agent_id);
            handleOpenDashboard();
        }
    }, [params]);

    // const toggleActive = (id: string, setRows: Function, rows: any[]) => {
    //     DefaultAxios
    //         .post(`${DT_API_URL}/toggle-active`, { id })
    //         .then(res => {
    //             const checked = res.data;
    //             const newRows = rows.map((element: any) => {
    //                 if (element.id === id) element['is_active'] = checked ? 1 : 0
    //                 return element
    //             })
    //             setRows(newRows);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //             if (err.response && err.response.status === 403) {
    //                 SwalContainer.fire({
    //                     title: "Error",
    //                     html: "Anda tidak memiliki akses",
    //                     icon: 'error',
    //                 })
    //             } else {
    //                 SwalContainer.fire({
    //                     title: "Error",
    //                     html: "Kesalahan server, harap hubungi admin",
    //                     icon: 'error',
    //                 })
    //             }
    //         })
    // }

    return (
        <Root>
            <AgentDetail
                open={modalDashboardState}
                onClose={handleCloseDashboard}
                agentId={selectedId}
            />

            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => navigate('/agent/add')}
            >
                Add Agent
            </Button>
            <DataTable
                // reDraw={reDrawDatatable}
                url={DT_API_URL}
                columns={[
                    {
                        name: 'name', label: 'Name', filterable: true, render: (data: any, row: any) => {
                            return (
                                <Link
                                    style={{ cursor: "pointer" }}
                                    component={RouterLink}
                                    to={`/agent/${row.id}`}
                                >
                                    {data}
                                </Link>
                            );
                        }
                    },
                    { name: 'agent_office_name', label: 'Kantor Agency', filterable: true },
                    { name: 'phone', label: 'Phone', filterable: true },
                    { name: 'email', label: 'Email', filterable: true },
                    // {
                    //     name: 'is_active',
                    //     label: 'Active Status',
                    //     filterable: true,
                    //     defaultOption: '',
                    //     options: [
                    //         {
                    //             key: '',
                    //             value: 'All'
                    //         },
                    //         {
                    //             key: '1',
                    //             value: 'Active'
                    //         },
                    //         {
                    //             key: '0',
                    //             value: 'In-Active'
                    //         }
                    //     ],
                    //     render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
                    //         return (
                    //             <Switch
                    //                 checked={+data === 1}
                    //                 onChange={() => toggleActive(row.id, setRows, rows)}
                    //                 color="secondary"
                    //             />
                    //         );
                    //     }
                    // }
                ]}
            />
        </Root>
    );
}

export default AgentList;
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
/**
 * Table
 */
import DataTable from '../../_components/_dataTable/DataTable';
import { Switch, Button, Dialog, AppBar, Theme, Toolbar, IconButton, Typography, Slide, Tooltip, Link } from '@mui/material';

/**
 * Transition
 */
import { TransitionProps } from '@mui/material/transitions';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import GroupIcon from '@mui/icons-material/Group';


/**
 * Advisor Placement
 */
import AdvisorPlacementModal from './_components/AdvisorPlacementModal';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { PermissionContext } from '../../_contexts/PermissionContext';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';
import ApartmentModalDetail from './ApartmentModalDetail';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        display: 'inline-flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    actionContainer: {
        display: 'flex',
        '& > *': {
            marginLeft: 16,
        },
        '& > *:first-of-type': {
            marginLeft: 0,
        }
    },
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center'
    }
}), "ApartmentList"
);

interface IParams extends Record<string, string | undefined> {
    id?: string
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ApartmentList() {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const navigate = useNavigate()
    const params = useParams<IParams>()

    /**
     * 1 = Apartment Detail
     * 2 = Advisor Placement
     */
    const [modalState, setModalState] = useState(0);
    const [apartmentID, setApartmentID] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const handleCloseAdvisor = () => {
        setReDrawDatatable(new Date().getTime());
        setModalState(0);
    }

    useEffect(() => {
        if (typeof params.id !== "undefined") {
            setModalState(1);
        }
    }, [params]);

    const handleCloseDetail = () => {
        setModalState(0);
        window.history.pushState([], 'Apartment List', `/apartment`);
    };

    const showAdvisorPlacementModal = (id: number) => {
        setApartmentID(Number(id));
        setModalState(2);
    }

    const handlePublishSwitch = (data: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengubah status publish?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/apartment/toggle-active`, { id: data.id })
                        .then(res => {
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +data.id) element['is_active'] = data.is_active ? 0 : 1;
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleJendelaPaySwitch = (apartment: any, setRows: Function, rows: any[]) => {
        setIsLoading(true);
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/apartment/toggle-jendelapay`, { id: apartment.id })
            .then(res => {
                renderToastSuccess(`Berhasil ${apartment.is_support_jendelapay ? 'menonaktifkan' : 'mengaktifkan'} Jendela Pay untuk Apartemen ini`);
                const newRows = rows.map((element: any) => {
                    if (+element.id === +apartment.id) element['is_support_jendelapay'] = apartment.is_support_jendelapay ? 0 : 1;
                    return element
                })
                setRows(newRows);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <ApartmentModalDetail
                permissions={permissions}
                onCloseModal={handleCloseDetail}
                open={modalState === 1}
            />

            <Dialog fullScreen open={modalState === 2} onClose={handleCloseAdvisor} TransitionComponent={Transition}>
                <Root>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleCloseAdvisor} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Advisor Placement
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <AdvisorPlacementModal
                        apartment_id={apartmentID}
                    />
                </Root>
            </Dialog>
            {
                !!permissions['apartment.create'] &&
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.addButton}
                    startIcon={<AddIcon />}
                    onClick={() => navigate('/apartment/add')}

                >
                    Add Apartment
                </Button>
            }
            <DataTable
                url={`${process.env.REACT_APP_API_URL}/apartment`}
                reDraw={reDrawDatatable}
                columns={[
                    {
                        name: 'name',
                        label: 'Name',
                        filterable: true,
                        render: (data: string, row: any) => {
                            return (
                                <div>
                                    <Link
                                        onClick={() => navigate(`/apartment/${row.id}`)}
                                        component="button"
                                    >
                                        {data}
                                    </Link>
                                </div>
                            )
                        }
                    },
                    {
                        name: 'area',
                        label: 'Area',
                        filterable: true
                    },
                    ...(
                        permissions['apartment.see-advisor-list']
                            ? [
                                {
                                    name: 'advisor_name',
                                    label: 'Assigned Property Manager',
                                    filterable: true,
                                    render: (data: string, row: any) => {
                                        let text: any = "";
                                        if (data) {
                                            if (data.includes(",")) {
                                                let new_data = data.split(",");

                                                text = new_data.map((advisor_data, key) => {
                                                    if (advisor_data.includes('IS_NOT_ACTIVE')) {
                                                        let advisor_name = advisor_data.replace('IS_NOT_ACTIVE', '');

                                                        return (
                                                            <span style={{ textDecoration: "line-through", color: "red" }}>
                                                                {advisor_name}{key + 1 === new_data.length ? "" : ","}
                                                            </span>
                                                        )
                                                    } else {
                                                        return (
                                                            <span>{advisor_data}{key + 1 === new_data.length ? "" : ","}</span>
                                                        )
                                                    }
                                                })
                                            } else {
                                                if (data.includes('IS_NOT_ACTIVE')) {
                                                    const advisor_name = data.replace('IS_NOT_ACTIVE', '');
                                                    text = (
                                                        <span style={{ textDecoration: "line-through", color: "red" }}>
                                                            {advisor_name}
                                                        </span>
                                                    )
                                                } else {
                                                    text = <span>{data}</span>
                                                }
                                            }
                                        } else {
                                            text = "-";
                                        }

                                        return (
                                            <>
                                                {text}
                                                <Tooltip title="Edit Advisor Placement">
                                                    <IconButton
                                                        color="primary"
                                                        component="span"
                                                        onClick={() => showAdvisorPlacementModal(row.id)}
                                                    >
                                                        <GroupIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        );
                                    }
                                }
                            ]
                            : []
                    ),
                    {
                        name: 'is_support_jendelapay',
                        label: 'Jendela Pay',
                        filterable: true,
                        defaultOption: '',
                        options: [
                            { key: '', value: 'All' },
                            { key: '1', value: 'Yes' },
                            { key: '0', value: 'No' }
                        ],
                        render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => (
                            permissions['apartment.toggle-jendelapay']
                                ? <Switch
                                    checked={data === 1}
                                    onChange={() => handleJendelaPaySwitch(row, setRows, rows)}
                                    color="secondary"
                                />
                                : (data === 1 ? 'Yes' : 'No')
                        )
                    },
                    {
                        name: 'is_active',
                        label: 'Published',
                        filterable: true,
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '1',
                                value: 'Published'
                            },
                            {
                                key: '0',
                                value: 'Unpublished'
                            }
                        ],
                        render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                            return (
                                permissions['apartment.switch-published']
                                    ? <Switch
                                        checked={data === 1}
                                        onChange={() => handlePublishSwitch(row, setRows, rows)}
                                        color="secondary"
                                    />
                                    : (data === 1 ? 'Yes' : 'No')
                            );
                        }
                    },
                ]}
            />
        </Root>
    )
}
import React, { useState } from 'react';
import { Dialog, Slide, AppBar, Toolbar, IconButton, Typography, Button, Theme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';

// Sortable
import { SortableHandle } from 'react-sortable-hoc';

// Datatable
import DataTable from '../../_components/_dataTable/DataTable';

// Form
import DetailListingInventoryForm from './DetailListingInventoryForm';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import HeightIcon from '@mui/icons-material/Height';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { numberToCurrency, renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    listingInventoryId: number | null;
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    addButton: {
        margin: '20px 0',
        width: '150px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        padding: theme.spacing(3, 2),
    },
}), "DetailListing_Inventory"
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DragHandle = SortableHandle(() => <HeightIcon />);

const DetailListingInventory = (props: Props) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/listing-inventory/unit-list/${props.listingInventoryId}`;
    const { Root, classes } = useStyles();

    const [selectedId, setSelectedId] = useState<null | number>(null);
    const [isAdd, setIsAdd] = useState(true);
    const [modalState, setModalState] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => setModalState(true);
    const handleClose = () => setModalState(false);

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Listing Inventory
                </Typography>
            </Toolbar>
        )
    }

    const openModalCreate = () => {
        setSelectedId(null);
        setIsAdd(true);
        handleOpen();
    }

    const openModalEdit = (data: any) => {
        setSelectedId(data.id);
        setIsAdd(false);
        handleOpen();
    }

    const submitSuccess = () => {
        handleClose();
        setReDrawDatatable(new Date().getTime());
    }

    const deleteInventory = (id: number) => {
        renderWarningButton("You won't be able to revert this!")
            .then((result) => {
                if (result.value) {
                    setLoading(true);
                    DefaultAxios
                        .delete(`${process.env.REACT_APP_API_URL}/unit-listing-inventory/${id}`)
                        .then(_ => {
                            Swal.fire({
                                title: "Listing Inventory Deleted",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(res => {
                                    setReDrawDatatable(new Date().getTime());
                                })
                        })
                        .catch(error => {
                            if (+error.response.status === 422) {
                                Swal.fire({
                                    title: error.response.data,
                                    icon: 'error'
                                });
                            } else {
                                Swal.fire({
                                    title: 'Oops.. Something went wrong!!',
                                    icon: 'error'
                                });
                            }
                        })
                        .finally(() => {
                            setLoading(false);
                        })
                }
            })
    }

    const handleSortTable = (oldIndex: number, newIndex: number) => {
        if (oldIndex !== newIndex) {
            DefaultAxios
                .post(`${process.env.REACT_APP_API_URL}/unit-listing-inventory/sort-inventory`, { oldIndex, newIndex, unitId: props.listingInventoryId })
                .then(() => {
                    // setReDrawDatatable(new Date().getTime());
                })
                .catch(error => {
                    if (+error.response.status === 422) {
                        Swal.fire({
                            title: error.response.data,
                            icon: 'error'
                        });
                    } else {
                        Swal.fire({
                            title: 'Oops.. Something went wrong!!',
                            icon: 'error'
                        });
                    }
                })
        }
    }

    return (
        <Root>
            <div className={classes.root}>
                <LoadingScreen open={loading} fullScreen />
                <Dialog
                    fullScreen
                    open={modalState}
                    onClose={handleClose}
                    closeAfterTransition
                    TransitionComponent={Transition}
                >
                    <Root>
                        <AppBar className={classes.appBar}>
                            <FormToolbar></FormToolbar>
                        </AppBar>
                        <DetailListingInventoryForm
                            mode={isAdd ? 'add' : 'edit'}
                            apiUrl={API_URL}
                            onSubmitSuccess={submitSuccess}
                            detailListingId={selectedId}
                        />
                    </Root>
                </Dialog>

                <Dialog
                    open={!!previewImage}
                    onClose={() => { setPreviewImage(undefined) }}
                    scroll="body"
                    maxWidth={false}
                >
                    <img src={previewImage} alt="preview" />
                </Dialog>

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.addButton}
                    startIcon={<AddIcon />}
                    onClick={openModalCreate}
                >
                    Add Listing Inventory
                </Button>
                <DataTable
                    sorting={false}
                    reDraw={reDrawDatatable}
                    url={API_URL}
                    onSort={handleSortTable}
                    columns={[
                        { name: 'name', label: 'Nama', type: 'string', filterable: true },
                        {
                            name: 'price', label: 'Harga', type: 'string', filterable: true, render: (data: number) => {
                                return numberToCurrency(data, 'Rp.');
                            }
                        },
                        { name: 'qty', label: 'Jumlah', type: 'string', filterable: true },
                        {
                            name: 'total', label: 'Total', type: 'string', filterable: true, render: (data: number) => {
                                return numberToCurrency(data, 'Rp.');
                            }
                        },
                        {
                            name: 'filepath', label: 'Gambar', type: 'string', filterable: false, render: (data: string) => {
                                return data
                                    ? <img
                                        alt="preview"
                                        src={data}
                                        width="100"
                                        height="auto"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => { setPreviewImage(data) }}
                                    />
                                    : null;
                            }
                        },
                        { name: 'notes', label: 'Keterangan', type: 'string', filterable: true },
                        {
                            name: 'EXTRA',
                            label: '',
                            filterable: false,
                            style: { minWidth: '200px' },
                            render: (row: any) => {
                                return (
                                    <div>
                                        <IconButton
                                            color="primary"
                                            component="span"
                                            onClick={() => openModalEdit(row)}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            component="span"
                                            onClick={() => deleteInventory(row.id)}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            color="default"
                                            component="span"
                                        >
                                            <DragHandle />
                                        </IconButton>
                                    </div>
                                );
                            }
                        }
                    ]}
                />
            </div>
        </Root>
    );
}

export default DetailListingInventory;
import React from 'react';

import { Slide, IconButton, Typography, Theme, Toolbar, AppBar, Dialog, PaperProps } from '@mui/material';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
// import Paper from '@mui/material/Paper';

//Transition
import { TransitionProps } from '@mui/material/transitions';
import { generateStyle } from '../../_utils/DefaultStyle';

//Utils
// import { isMobile } from '../../_utils/Helper';

interface IProps {
    title: string;
    isOpen: boolean;
    handleOpen?: Function;
    handleClose: Function;
    children: React.ReactNode;
    closeButton?: React.ReactNode
    withoutTransition?: boolean
    mobileOnly?: boolean
    zIndex?: number
    resetMargin?: boolean
    isNotFullScreen?: boolean
    PaperProps?: Partial<PaperProps>
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative'
    },
    paper_root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        margin: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(5),
        },
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "50%"
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
}), "ModalContainer"
);

export default function ItemModal(props: IProps) {
    const { Root, classes } = useStyles();

    const handleClose = () => {
        props.handleClose();
    };

    const customStyle = () => {
        if (props.resetMargin) {
            return {
                margin: 0
            }
        } else {
            return {}
        }
    }

    if (true) {
        return (
            <Dialog
                fullScreen={!props.isNotFullScreen}
                open={props.isOpen}
                onClose={handleClose}
                closeAfterTransition
                TransitionComponent={Transition}
                transitionDuration={props.withoutTransition ? 0 : 300}
                PaperProps={{
                    style: {
                        // backgroundColor: '#f0f0f0',
                        ...props.PaperProps?.style,
                    },
                }}
                style={{
                    zIndex: props.zIndex || 1300
                }}
            >
                <Root>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                {
                                    props.closeButton ?
                                        props.closeButton
                                        :
                                        <CloseIcon />
                                }
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {props.title}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paper_root} style={{ ...customStyle() }}>
                        {props.children}
                    </div>
                    {/* <Paper }> */}
                    {/* </Paper> */}
                </Root>
            </Dialog>
        )
    } else {
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={props.isOpen}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Root>
                        <Fade in={props.isOpen}>
                            <div className={classes.paper}>
                                <h2 id="transition-modal-title">{props.title}</h2>

                                {props.children}
                            </div>
                        </Fade>
                    </Root>
                </Modal>
            </div>
        );
    }
}
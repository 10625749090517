import React from 'react';
import { IErrorState } from '../../ClosingForm';
import { Grid, Collapse, InputAdornment } from '@mui/material';
import { numberToCurrency } from '../../../../_utils/Helper';
import MTOForm, { IMTO } from '../MTOForm';
import { generateStyle } from '../../../../_utils/DefaultStyle';
import TextFieldND3 from '../../../../_components/_formElements/_newDesign2/TextFieldND3';
import ButtonRadioND2 from '../../../../_components/_formElements/_newDesign2/ButtonRadioND2';

interface IProps {
    payment_type: string;
    gmv_transaction: string;
    basic_price: string;
    payment_to_financing_service: string;
    totalTransaction: string;
    totalPaymentWithFinancing: string;
    handleChanged: Function;
    errorState: IErrorState
    payment_per_month_mto: IMTO[];
    setParentMtoState: Function;
}

export const useStyles = generateStyle(() => ({
    inputWithAdornment: {
        backgroundColor: '#F8F8F8',
        '& .MuiInputBase-input': {
            backgroundColor: '#FFFFFF',
        },
        '& .MuiInputAdornment-positionStart + .MuiInputBase-input': {
            paddingLeft: '10px',
            marginLeft: '20px'
        },
        '& .MuiInputAdornment-positionStart > p': {
            paddingLeft: '10px'
        }
    },
    collapse: {
        width: '100%',
        '& .MuiCollapse-wrapper': {
            paddingTop: '12px',
            paddingBottom: '12px'
        }
    }
}), "PaymentTypeAndTransactionForm"
);

// const DarkerDisabledTextField = styled(TextFieldND2)({
//     "& .MuiInputBase-root.Mui-disabled": {
//         backgroundColor: '#eee'
//     }
// })

const PaymentTypeAndRevenueTransactionForm = ({
    payment_type,
    gmv_transaction,
    basic_price,
    payment_to_financing_service,
    totalTransaction,
    totalPaymentWithFinancing,
    handleChanged,
    errorState,
    payment_per_month_mto,
    setParentMtoState
}: IProps) => {
    const { Root, classes } = useStyles();

    return (
        <>
            <Grid item xs={12}>
                <ButtonRadioND2
                    label="Metode Pembayaran"
                    name="payment_type"
                    value={payment_type}
                    onChange={(name, value) => {
                        const e = {
                            target: {
                                name, value
                            }
                        }

                        handleChanged(e)
                    }}
                    error={errorState.payment_type}
                    options={[
                        { value: 'cash', label: 'Cash' },
                        { value: 'monthly-owner', label: 'Monthly to Owner' },
                        { value: 'jendelapay', label: 'Jendelapay' },
                        // { value: 'installment-blibli', label: 'Installment Blibli' },
                        // { value: 'installment-modalku', label: 'Installment Modalku' },
                        // { value: 'mix-cash-blibli', label: 'Mix Cash & Installment Blibli' },
                        // { value: 'mix-cash-modalku', label: 'Mix Cash & Installment Modalku' },
                        // { value: 'jendelapay', label: 'JendelaPay' }
                    ]}
                />
            </Grid>

            {payment_type === 'monthly-owner' &&
                <Grid item xs={12}>
                    <Root>
                        <Collapse in={payment_type === 'monthly-owner'} className={classes.collapse}>
                            <MTOForm
                                mtos={payment_per_month_mto}
                                setParentMtoState={setParentMtoState}
                            />
                        </Collapse>
                    </Root>
                </Grid>
            }

            {
                ["mix-cash-blibli", "mix-cash-modalku"].includes(payment_type) &&
                <Grid item xs={12}>
                    <Root>
                        <Collapse in={["mix-cash-blibli", "mix-cash-modalku"].includes(payment_type)} className={classes.collapse}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <TextFieldND3
                                        fullWidth
                                        variant="outlined"
                                        label="Cash"
                                        name="gmv_transaction"
                                        value={numberToCurrency(Number(gmv_transaction))}
                                        onChange={(e) => handleChanged(e)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextFieldND3
                                        fullWidth
                                        variant="outlined"
                                        label={payment_type === 'mix-cash-blibli' ? "Installment Blibli" : "Installment Modalku"}
                                        name="payment_to_financing_service"
                                        value={numberToCurrency(Number(payment_to_financing_service))}
                                        onChange={(e) => handleChanged(e)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Root>
                </Grid>
            }

            <Grid item xs={12} md={4}>
                <Root>
                    <TextFieldND3
                        fullWidth
                        variant="outlined"
                        label={["termin", "mix-cash-blibli", "mix-cash-modalku"].includes(payment_type) ? "Total Amount" : "Transaction Amount"}
                        name="gmv_transaction"
                        value={totalTransaction}
                        onChange={(e) => handleChanged(e)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                        }}
                        disabled={["termin", "mix-cash-blibli", "mix-cash-modalku"].includes(payment_type)}
                    />
                </Root>
            </Grid>

            {
                ["installment-blibli", "installment-modalku", "mix-cash-blibli", "mix-cash-modalku"].includes(payment_type) &&
                <Grid item xs={12}>
                    <Root>
                        <Collapse in={["installment-blibli", "installment-modalku", "mix-cash-blibli", "mix-cash-modalku"].includes(payment_type)} className={classes.collapse}>
                            <TextFieldND3
                                fullWidth
                                variant="outlined"
                                label="Nilai Financing"
                                value={totalPaymentWithFinancing}
                                className={undefined}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                }}
                                disabled
                            />
                        </Collapse>
                    </Root>
                </Grid>
            }
        </>
    )
}

export default PaymentTypeAndRevenueTransactionForm;
import React, { useState } from 'react'
import { Grid, Button, TextField, Typography } from '@mui/material'
/**
 * Components
*/
import LoadingScreen from '../../_components/LoadingScreen'
import SliderConfirmation from '../../_components/SliderConfirmation'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../_utils/Helper'

type ICustomer = { name: string, phone: string }

interface IDetail {
    main_customer: ICustomer,
    old_customer: ICustomer
}

const initialDetail = {
    main_customer: {
        name: '',
        phone: ''
    },
    old_customer: {
        name: '',
        phone: ''
    }
}

const CustomerMerge = () => {
    const [state, setState] = useState({
        main_customer_phone: '',
        old_customer_phone: ''
    })
    const [isLoading, setIsLoading] = useState(false)
    const [confirmation, setConfirmation] = useState(false)
    const [detail, setDetail] = useState(initialDetail)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value.replace(/[^\d]/g, "")
        }))

    }

    const handleOpenPrompt = (res: any) => {
        setDetail(res)
        setConfirmation(true)
    }

    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/check-tenant`, {
            params: state
        })
            .then(res => res.data)
            .then(res => handleOpenPrompt(res))
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    const handleConfirm = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/merge-tenant`, state)
            .then(res => res.data)
            .then(res => renderSuccessButton('Tenants Merged!'))
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    const generateCustomBodySlider = (detail: IDetail) => {
        let styles: { [name: string]: React.CSSProperties }
        styles = {
            p: {
                marginTop: 5,
                marginBottom: 5,
            },
            p2: {
                display: 'flex',
                width: '100%'
            },
            span1: {
                width: '40%',
                fontWeight: '700'
            },
            span2: {
                width: '60%',
                fontWeight: '700'

            }
        }

        return (
            <div>
                <p style={styles.p}>Anda akan memindahkan data customer ini</p>
                <p style={styles.p2}>
                    <span style={styles.span1}>Data Utama</span>
                    <span style={styles.span2}>: {detail.main_customer.name} - {detail.main_customer.phone}</span>
                </p>
                <p style={styles.p2}>
                    <span style={styles.span1}>Data yang dipindah</span>
                    <span style={styles.span2}>: {detail.old_customer.name} - {detail.old_customer.phone}</span>
                </p>
                <div style={styles.div}>
                    <p>Data yang akan dipindah</p>
                    <ul>
                        <li>Closing</li>
                        <li>File Tenant</li>
                        <li>Bank Tenant</li>
                    </ul>
                </div>
                <p style={styles.p}>Konfirmasi dengan menarik slider ke kanan</p>
            </div>
        )
    }

    return (
        <>
            <LoadingScreen open={isLoading} />
            <Grid container>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{ marginBottom: "10px" }}>
                                No. HP Tenant yang akan dipindah
                            </Typography>
                            <TextField
                                name="old_customer_phone"
                                value={state.old_customer_phone}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{ marginBottom: "10px" }}>
                                No. HP Tenant Utama
                            </Typography>
                            <TextField
                                name="main_customer_phone"
                                value={state.main_customer_phone}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                size='medium'
                                fullWidth
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <SliderConfirmation
                open={confirmation}
                onClose={() => setConfirmation(false)}
                onSubmit={handleConfirm}
                customBody={generateCustomBodySlider(detail)}
            />
        </>
    )
}

export default CustomerMerge
import React, { useState, useReducer, useEffect } from 'react';
import { Theme, Grid, TextField, Button } from '@mui/material';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertIndonesiaPhoneNumber, generalErrorHandler, renderToastSuccess } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    onSubmitSuccess: () => void,
    noteId: string
}

interface IState {
    apartment_id: string;
    apartment_name: string;
    title: string;
    notes: string;
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

interface IErrorState {
    apartment_id: string;
    apartment_name: string;
    title: string;
    notes: string;
}

interface IErrorAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) => ({
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
        margin: theme.spacing(0, 1),
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'right',
        marginBottom: '10px'
    }
}), "ApartmentNotes_Form"
)

const ApartmentNotesForm = (props: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/apartment-notes';

    const [loading, setLoading] = useState(false);

    // Error State
    const errorReducer = (state: IErrorState, action: IErrorAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                apartment_id: '',
                apartment_name: '',
                title: '',
                notes: ''
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [errorState, setErrorState] = useReducer(errorReducer, {
        apartment_id: '',
        apartment_name: '',
        title: '',
        notes: ''
    });

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                apartment_id: '',
                apartment_name: '',
                title: '',
                notes: ''
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        apartment_id: '',
        apartment_name: '',
        title: '',
        notes: ''
    });

    const checkValidation = () => {
        let isValid = true;
        let newError = { ...errorState };
        if (!inputState.title) {
            newError.title = 'Field wajib diisi';
            isValid = false;
        } else {
            newError.title = '';
        }
        if (!inputState.notes) {
            newError.notes = 'Field wajib diisi';
            isValid = false;
        } else {
            newError.notes = '';
        }
        setErrorState({ type: 'REPLACE_STATE', name: '', value: newError });
        return isValid;
    }

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;

            if (target.name === 'phone') {
                setInputState({ name: target.name, value: convertIndonesiaPhoneNumber(value), type: 'SET_ITEM' });
            } else if (target.name === 'notes') {
                setInputState({ name: target.name, value: value.replace(/[^\d]/g, ''), type: 'SET_ITEM' });
            } else {
                setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
            }
        }
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const handleAutocompleteApartment = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.apartment_id = value.id.toString();
        newState.apartment_name = value.label;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const submitForm = () => {

        if (!checkValidation()) {
            return;
        }

        let axios;

        if (props.noteId) {
            axios = DefaultAxios.patch(`${API_URL}/${props.noteId}`, inputState)
            setLoading(true);
            axios
                .then(res => {
                    renderToastSuccess('Notes updated');
                    props.onSubmitSuccess();
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        } else {
            axios = DefaultAxios.post(`${API_URL}`, inputState);
            setLoading(true);
            axios
                .then(res => {
                    renderToastSuccess('Notes submitted');
                    props.onSubmitSuccess()
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        if (props.noteId) {
            setLoading(true);
            DefaultAxios
                .get(`${API_URL}/${props.noteId}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                    setLoading(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Root>
            <div className={classes.root}>
                <LoadingScreen open={loading} fullScreen />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            label="Apartment"
                            name="apartment_id"
                            initialQuery={inputState.apartment_name}
                            onChange={handleAutocompleteApartment}
                            onInputChange={handleAutocompleteInputChanged}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/apartment`}
                            placeholder="Apartments"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={!!errorState.title}
                            label="Title"
                            variant="outlined"
                            name="title"
                            value={inputState.title}
                            onChange={handleInputChanged}
                            fullWidth
                            helperText={errorState.title}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Notes"
                            name="notes"
                            value={inputState.notes}
                            onChange={handleInputChanged}
                            variant="outlined"
                            fullWidth
                            error={!!errorState.notes}
                            helperText={errorState.notes}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.gridSubmit}>
                        <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Root>
    );
}

export default ApartmentNotesForm;
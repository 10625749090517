import React, { useEffect, useState } from 'react';
import { Grid, Divider, Button, TextField, InputAdornment, Theme, MenuItem } from '@mui/material';
import DefaultAxios from '../../../_utils/DefaultAxios';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete';
import RoomIcon from '@mui/icons-material/Room';
import GoogleMapReact from 'google-map-react';

// Interface
import { IFormState, IMapDataObject } from '../_interfaces/ListingAdminInterface';
import { IListPlace } from '../../_interfaces/FormInterfaces';
import { numberToCurrency } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface IProps {
    render_province: boolean;
    states: IFormState,
    handleSubmit: Function,
    setInputValue: Function,
    handleInputChange: Function,
}

interface setMapData {
    mapApiLoaded: boolean
    mapInstances: google.maps.Map | null
    mapApi: null | object
    address: string
    open: boolean
}

interface IError {
    [key: string]: string
}

let cache_cities: Array<Array<IListPlace>> = []; //NOTE: cache_cities itu isinya array dari data yang udah di fetch, makanya interface Arraynya dua kali
let cache_districts: Array<Array<IListPlace>> = []; //NOTE: ini juga sama

const useStyles = generateStyle((theme: Theme) =>
({
    title: {
        color: '#033a11',
        fontWeight: 500
    },
    hotListingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1rem',
        '& h2': {
            color: '#363636',
            fontSize: 16,
            fontWeight: 500
        }
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    markerContainer: {
        display: 'flex',
        position: 'absolute',
        left: 0,
        right: 0,
        top: '50%',
        margin: 'auto',
        width: 'fit-content',
        transform: 'translateY(-50px)',
        zIndex: 1
    },
    marker: {
        // display: "block",
        // position: "absolute",
        zIndex: 1,
        // marginTop: "150px",
        // marginLeft: "190px",
        // width: "50px",
        // height: "50px",
        fontSize: 50
    },
    submit_btn: {
        borderRadius: "40px",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    twoInput: {
        display: 'flex'
    },
    twoInputLeft: {
        marginRight: '0.5rem'
    },
    twoInputRight: {
        marginLeft: '0.5rem'
    },
    budgetContainer: {
        display: 'flex',
        '& .shrink': {
            marginRight: '1rem',
            width: 75,
            maxWidth: 75,
            minWidth: 75,
        },
        '& .grow': {
            flexGrow: 1
        }
    },
    checkBoxContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
}), "FormComponents"
);

// const certificateList = ['Sertifikat Hak Milik', 'Hak Guna Bangunan', 'Hak Pakai', 'Hak Sewa', 'PPJB', 'Lainnya (Girk, Adat, dll)'];

const furnishList = [
    {
        value: 'non',
        label: 'Non Furnished'
    },
    {
        value: 'semi',
        label: 'Semi Furnished'
    },
    {
        value: 'full',
        label: 'Full Furnished'
    },
]

const directionList = ['Utara', 'Selatan', 'Timur', 'Barat', 'Timur Laut', 'Tenggara', 'Barat Laut', 'Barat Daya'];

const electricsList = [450, 1300, 2200, 3300, 3500, 4400, 5500, 6600, 7600, 7700, 8000, 9500, 10000,
    10600, 11000, 12700, 13200, 16500, 17600, 19000, 22000, 23000, 24000, 30500, 33000, 38100, 41500, 47500, 53000, 61000, 66000,
    76000, 82500, 85000, 95000, 0
];

export default function Formf(props: IProps) {
    const { Root, classes } = useStyles();

    // Error State
    const [errorList, setErrorList] = useState<any>({});

    // Change
    const changeInput = (e: any) => {
        props.handleInputChange(e);

        let newErrorList = errorList;

        delete newErrorList[e.target.name];
        setErrorList(newErrorList);
    }

    //List States
    const [areaAutoCompleteLoading, setAACL] = useState(props.render_province);
    const [provinceList, setProvinceList] = useState<Array<IListPlace>>([]);
    const [cityList, setCityList] = useState<Array<IListPlace>>([]);
    const [districtList, setDistrictList] = useState<Array<IListPlace>>([]);
    const certificateList = ['Sertifikat Hak Milik', 'Hak Guna Bangunan', 'Hak Pakai', 'Hak Sewa', 'PPJB', 'Lainnya (Girk, Adat, dll)'];


    const handleProvinceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setInputValue({ name: 'province_id', value: e.target.value });

        let newErrorList = errorList;

        delete newErrorList[e.target.name];

        if (e.target.value === "") {
            resetCityList();
        } else {
            let province_id: number = Number(e.target.value); //Explicitly set as number so Typescript will shut up

            if (province_id in cache_cities) {
                setCityList(cache_cities[province_id]);
            } else {
                resetCityList();
                setAACL(true);

                DefaultAxios.get(`${process.env.REACT_APP_API_URL}/admin/autocomplete/listing-form/area`, { params: { province_id: province_id } })
                    .then(res => {
                        let list_of_cities = res.data;
                        cache_cities[province_id] = list_of_cities;
                        reCenterMap('province', (province_id - 1)); // minus 1 to make it the same as the array key
                        setCityList(list_of_cities);
                        setAACL(false);
                    });
            }
        }
    }

    const resetCityList = () => {
        props.setInputValue({ name: 'city_id', value: "" });
        setCityList([]);
    }

    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setInputValue({ name: 'city_id', value: e.target.value });

        let newErrorList = errorList;

        delete newErrorList[e.target.name];

        if (e.target.value === "") {
            resetDistrictList();
        } else {
            let city_id: number = Number(e.target.value); // Explicitly set as number so Typescript will shut up

            if (city_id in cache_districts) {
                setDistrictList(cache_districts[city_id]);
                reCenterMap('city', city_id);
            } else {
                resetDistrictList();
                setAACL(true);

                DefaultAxios.get(`${process.env.REACT_APP_API_URL}/admin/autocomplete/listing-form/district`, { params: { city_id: city_id } })
                    .then(res => {
                        let list_of_cities = res.data;
                        cache_districts[city_id] = list_of_cities;

                        setDistrictList(list_of_cities);
                        reCenterMap('city', city_id);
                        setAACL(false);
                    });
            }
        }
    }

    const resetDistrictList = () => {
        props.setInputValue({ name: 'district_id', value: "" });
        setDistrictList([]);
    }

    const handleDistrictChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setInputValue({ name: e.target.name, value: e.target.value });

        let newErrorList = errorList;

        delete newErrorList[e.target.name];

        reCenterMap('district', Number(e.target.value));
    }

    const changeMap = (positionData: IMapDataObject) => {
        if (positionData.lat !== 0 && positionData.lng !== 0) {
            props.setInputValue({
                name: 'map',
                value: positionData
            });
        }
    }

    const reCenterMap = (type: string, id_to_search: number) => {
        if (type === 'province') {
            changeMap({ lat: Number(provinceList[id_to_search].latitude), lng: Number(provinceList[id_to_search].longitude) })
        } else if (type === 'city') {
            for (let index = 0; index < cityList.length; ++index) {
                if (cityList[index].id === id_to_search) {
                    changeMap({ lat: Number(cityList[index].latitude), lng: Number(cityList[index].longitude) })
                    break;
                }
            }
        } else if (type === 'district') {
            for (let index = 0; index < districtList.length; ++index) {
                if (districtList[index].id === id_to_search) {
                    changeMap({ lat: Number(districtList[index].latitude), lng: Number(districtList[index].longitude) })
                    break;
                }
            }
        } else if (type === 'reset_form') {
            changeMap({ lat: props.states.latitude, lng: props.states.longitude })
        }
    }

    useEffect(() => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/listing/form-data`)
            .then(res => {
                setProvinceList(res.data.provinces);
                setAACL(false);

                // if (props.render_province === false) {
                const province_id = Number(props.states.province_id);

                DefaultAxios.get(`${process.env.REACT_APP_API_URL}/admin/autocomplete/listing-form/area`, { params: { province_id: province_id } })
                    .then(res => {
                        let list_of_cities = res.data;
                        cache_cities[province_id] = list_of_cities;
                        setCityList(list_of_cities);
                        return true;
                    }).then(res => {
                        const city_id = Number(props.states.city_id);

                        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/admin/autocomplete/listing-form/district`, { params: { city_id: city_id } })
                            .then(res => {
                                let list_of_cities = res.data;
                                cache_districts[city_id] = list_of_cities;

                                setDistrictList(list_of_cities);
                            })
                            .then(res => {
                                reCenterMap('direct', 1);
                            })
                    })
                // }
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.states.province_id]);

    // Map State
    const [mapData, setMapData] = useState<setMapData>({
        mapApiLoaded: false,
        mapInstances: null,
        mapApi: null,
        address: '',
        open: true
    });

    useEffect(() => {
        if (mapData.mapInstances) {
            mapData.mapInstances!.addListener('dragend', () => {
                changeMap({
                    lat: mapData.mapInstances!.getCenter().lat(),
                    lng: mapData.mapInstances!.getCenter().lng()
                })
            })
            mapData.mapInstances!.addListener('zoom_changed', () => {
                changeMap({
                    lat: mapData.mapInstances!.getCenter().lat(),
                    lng: mapData.mapInstances!.getCenter().lng()
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapData.mapInstances])

    const handleApiLoaded = (map: google.maps.Map, maps: object) => {
        setMapData({
            ...mapData,
            mapApiLoaded: true,
            mapInstances: map,
            mapApi: maps
        })
    }

    const handleAutocompleteLandlord = (name: string, value: IAutoCompleteOption) => {
        // props.states({ name, value: value.id, type: 'SET_ITEM' });
        props.setInputValue({ name: 'landlord_id', value: value.id, type: 'SET_ITEM' });
    }
    const handleAutocompletePic = (name: string, value: IAutoCompleteOption) => {
        // props.states({ name, value: value.id, type: 'SET_ITEM' });
        props.setInputValue({ name: 'pic', value: value.id, type: 'SET_ITEM' });
        props.setInputValue({ name: 'pic_name', value: value.label, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        // props.states({ name, value: null, type: 'SET_ITEM' });
        props.setInputValue({ name, value: null, type: 'SET_ITEM' });
    }

    const renderFurnish = furnishList.map((element, idx) => (<option key={idx} value={element.value}>{element.label}</option>))

    const renderDirection = directionList.map((element, idx) => (<option key={idx} value={element}>{element}</option>))

    const renderElectric = electricsList.map((element, idx) => (<option key={idx} value={element}>{element === 0 ? 'Lainnya' : element}</option>))

    const validateData = () => {
        let listOfErrors: IError = {};

        if (Object.keys(listOfErrors).length === 0 && listOfErrors.constructor === Object) {
            props.handleSubmit();
        } else {
            window.scrollTo(0, 0);
            setErrorList(listOfErrors);
        }
    }

    const renderViewLand = () => {
        switch (parseInt(props.states.listing_type_id)) {
            case 2:
                return (
                    <>
                        <TextField
                            label="Luas Tanah"
                            name="land_area"
                            value={props.states.land_area}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.land_area ? true : false}
                            helperText={errorList.land_area}
                        />

                        <TextField
                            label="Luas Bangunan"
                            name="property_area"
                            value={props.states.property_area}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.property_area ? true : false}
                            helperText={errorList.property_area}
                        />

                        <div className={classes.twoInput}>
                            <TextField
                                label="Kamar Tidur"
                                name="bedroom"
                                variant="outlined"
                                value={props.states.bedroom}
                                onChange={changeInput}
                                fullWidth
                                error={errorList.bedroom ? true : false}
                                helperText={errorList.bedroom}
                                type="number"
                                className={classes.twoInputLeft}
                                select
                            >
                                <MenuItem value="0">Studio</MenuItem>
                                <MenuItem value="1">1BR</MenuItem>
                                <MenuItem value="2">2BR</MenuItem>
                                <MenuItem value="3">3BR</MenuItem>
                                <MenuItem value="4">4BR</MenuItem>
                                <MenuItem value="5">5BR</MenuItem>
                                <MenuItem value="6">6BR</MenuItem>
                                <MenuItem value="7">7BR</MenuItem>
                                <MenuItem value="8">8BR</MenuItem>
                                <MenuItem value="9">9BR</MenuItem>
                                <MenuItem value="10">10BR</MenuItem>
                            </TextField>
                            <TextField
                                label="Kamar Mandi"
                                name="bathroom"
                                variant="outlined"
                                value={props.states.bathroom}
                                onChange={changeInput}
                                fullWidth
                                error={errorList.bathroom ? true : false}
                                helperText={errorList.bathroom}
                                type="number"
                                className={classes.twoInputRight}
                                select
                            >
                                <MenuItem value="0">None</MenuItem>
                                <MenuItem value="1">1BA</MenuItem>
                                <MenuItem value="2">2BA</MenuItem>
                                <MenuItem value="3">3BA</MenuItem>
                                <MenuItem value="4">4BA</MenuItem>
                                <MenuItem value="5">5BA</MenuItem>
                                <MenuItem value="6">6BA</MenuItem>
                                <MenuItem value="7">7BA</MenuItem>
                                <MenuItem value="8">8BA</MenuItem>
                                <MenuItem value="9">9BA</MenuItem>
                                <MenuItem value="10">10BA</MenuItem>
                            </TextField>
                        </div>

                        <TextField
                            label="Carport"
                            name="carport"
                            value={props.states.carport}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Mobil</InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            type="number"
                            error={errorList.carport ? true : false}
                            helperText={errorList.carport}
                        />
                    </>
                )
            case 3:
                return (
                    <>
                        <TextField
                            label="Luas Tanah"
                            name="land_area"
                            value={props.states.land_area}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.land_area ? true : false}
                            helperText={errorList.land_area}
                        />
                    </>
                )
            case 6:
                return (
                    <>
                        <TextField
                            label="Luas Tanah"
                            name="land_area"
                            value={props.states.land_area}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.land_area ? true : false}
                            helperText={errorList.land_area}
                        />

                        <TextField
                            label="Luas Bangunan"
                            name="property_area"
                            value={props.states.property_area}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.property_area ? true : false}
                            helperText={errorList.property_area}
                        />
                    </>
                )
            default:
                return (
                    <>
                        <TextField
                            label="Luas Tanah"
                            name="land_area"
                            value={props.states.land_area}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.land_area ? true : false}
                            helperText={errorList.land_area}
                        />

                        <TextField
                            label="Luas Bangunan"
                            name="property_area"
                            value={props.states.property_area}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.property_area ? true : false}
                            helperText={errorList.property_area}
                        />

                        <TextField
                            label="Kamar Mandi"
                            name="bathroom"
                            variant="outlined"
                            value={props.states.bathroom}
                            onChange={changeInput}
                            type="number"
                            fullWidth
                            error={errorList.bathroom ? true : false}
                            helperText={errorList.bathroom}
                        >
                        </TextField>
                    </>
                )
        }
    }

    const renderViewPrice = () => {
        return (
            <>
                <div className={classes.budgetContainer}>
                    <TextField
                        className="shrink"
                        name="display_currency"
                        value={props.states.display_currency}
                        variant="outlined"
                        onChange={changeInput}
                        select={true}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="IDR">IDR</option>
                        <option value="USD">USD</option>
                    </TextField>
                    <TextField
                        className="grow"
                        label="Harga Jual"
                        name="sell_price"
                        value={props.states.sell_price ? numberToCurrency(props.states.sell_price) : ''}
                        variant="outlined"
                        onChange={changeInput}
                        error={errorList.sell_price ? true : false}
                        helperText={errorList.sell_price ? errorList.sell_price : 'Kosongkan jika tidak dijual'}
                    />
                </div>
                <div className={classes.budgetContainer}>
                    <TextField
                        className="shrink"
                        name="display_currency"
                        value={props.states.display_currency}
                        variant="outlined"
                        onChange={changeInput}
                        select={true}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="IDR">IDR</option>
                        <option value="USD">USD</option>
                    </TextField>
                    <TextField
                        className="grow"
                        label="Harga Sewa per Hari"
                        name="daily_price"
                        value={props.states.daily_price ? numberToCurrency(props.states.daily_price) : ''}
                        variant="outlined"
                        onChange={changeInput}
                        error={errorList.daily_price ? true : false}
                        helperText={errorList.daily_price ? errorList.daily_price : 'Kosongkan jika tidak disewakan per hari'}
                    />
                </div>
                <div className={classes.budgetContainer}>
                    <TextField
                        className="shrink"
                        name="display_currency"
                        value={props.states.display_currency}
                        variant="outlined"
                        onChange={changeInput}
                        select={true}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="IDR">IDR</option>
                        <option value="USD">USD</option>
                    </TextField>
                    <TextField
                        className="grow"
                        label="Harga Sewa per Bulan"
                        name="price_per_1_month"
                        value={props.states.price_per_1_month ? numberToCurrency(props.states.price_per_1_month) : ''}
                        variant="outlined"
                        onChange={changeInput}
                        error={errorList.price_per_1_month ? true : false}
                        helperText={errorList.price_per_1_month ? errorList.price_per_1_month : 'Kosongkan jika tidak disewakan per bulan'}
                    />
                </div>
                <div className={classes.budgetContainer}>
                    <TextField
                        className="shrink"
                        name="display_currency"
                        value={props.states.display_currency}
                        variant="outlined"
                        onChange={changeInput}
                        select={true}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="IDR">IDR</option>
                        <option value="USD">USD</option>
                    </TextField>
                    <TextField
                        className="grow"
                        label="Harga Sewa per 3 Bulan"
                        name="price_per_3_months"
                        value={props.states.price_per_3_months ? numberToCurrency(props.states.price_per_3_months) : ''}
                        variant="outlined"
                        onChange={changeInput}
                        error={errorList.price_per_3_months ? true : false}
                        helperText={errorList.price_per_3_months ? errorList.price_per_3_months : 'Kosongkan jika tidak disewakan per 6 bulan'}
                    />
                </div>
                <div className={classes.budgetContainer}>
                    <TextField
                        className="shrink"
                        name="display_currency"
                        value={props.states.display_currency}
                        variant="outlined"
                        onChange={changeInput}
                        select={true}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="IDR">IDR</option>
                        <option value="USD">USD</option>
                    </TextField>
                    <TextField
                        className="grow"
                        label="Harga Sewa per 6 Bulan"
                        name="price_per_6_months"
                        value={props.states.price_per_6_months ? numberToCurrency(props.states.price_per_6_months) : ''}
                        variant="outlined"
                        onChange={changeInput}
                        error={errorList.price_per_6_months ? true : false}
                        helperText={errorList.price_per_6_months ? errorList.price_per_6_months : 'Kosongkan jika tidak disewakan per 6 bulan'}
                    />
                </div>
                <div className={classes.budgetContainer}>
                    <TextField
                        className="shrink"
                        name="display_currency"
                        value={props.states.display_currency}
                        variant="outlined"
                        onChange={changeInput}
                        select={true}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="IDR">IDR</option>
                        <option value="USD">USD</option>
                    </TextField>
                    <TextField
                        className="grow"
                        label="Harga Sewa per Tahun"
                        name="price_per_year"
                        value={props.states.price_per_year ? numberToCurrency(props.states.price_per_year) : ''}
                        variant="outlined"
                        onChange={changeInput}
                        error={errorList.price_per_year ? true : false}
                        helperText={errorList.price_per_year ? errorList.price_per_year : 'Kosongkan jika tidak disewakan per tahun'}
                    />
                </div>
            </>
        )
    }

    const renderViewAdditional = () => {
        return (
            <>
                {
                    parseInt(props.states.listing_type_id) !== 3 &&
                    <>
                        <h4 className={classes.title}>Informasi Tambahan</h4>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Tahun Dibangun"
                                    name="building_finished"
                                    value={props.states.building_finished}
                                    onChange={changeInput}
                                    variant="outlined"
                                    fullWidth
                                    error={errorList.building_finished ? true : false}
                                    helperText={errorList.building_finished}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Jumlah Lantai"
                                    name="total_floor"
                                    value={props.states.total_floor}
                                    onChange={changeInput}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    error={errorList.total_floor ? true : false}
                                    helperText={errorList.floor}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Interior"
                                    name="furnish"
                                    variant="outlined"
                                    value={props.states.furnish}
                                    onChange={changeInput}
                                    fullWidth
                                    error={errorList.furnish ? true : false}
                                    helperText={errorList.furnish}
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: !!props.states.furnish
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {renderFurnish}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Daya Listrik"
                                    name="electric_watt"
                                    variant="outlined"
                                    value={props.states.electric_watt}
                                    onChange={changeInput}
                                    fullWidth
                                    error={errorList.electric_watt ? true : false}
                                    helperText={errorList.electric_watt}
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: !!props.states.electric_watt
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {renderElectric}
                                </TextField>
                            </Grid>
                        </Grid>

                        {parseInt(props.states.listing_type_id) !== 3 &&

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Hadap"
                                        name="view"
                                        variant="outlined"
                                        value={props.states.view}
                                        onChange={changeInput}
                                        fullWidth
                                        error={errorList.view ? true : false}
                                        helperText={errorList.view}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Arah"
                                        name="direction"
                                        variant="outlined"
                                        value={props.states.direction}
                                        onChange={changeInput}
                                        fullWidth
                                        error={errorList.direction ? true : false}
                                        helperText={errorList.direction}
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: !!props.states.direction
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {renderDirection}
                                    </TextField>
                                </Grid>
                            </Grid>
                        }
                    </>
                }

                <TextField
                    label="Catatan Pribadi"
                    name="notes"
                    value={props.states.notes}
                    onChange={changeInput}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    error={errorList.notes ? true : false}
                    helperText={errorList.notes ? errorList.notes : 'Tulisan ini tidak akan ditampilkan di iklan listing dan hanya bisa dilihat oleh anda'}
                    style={{ marginTop: '1.5rem' }}
                />
            </>
        )
    }

    const renderCertificates = certificateList.map((element, idx) => (<option key={idx} value={element}>{element}</option>))

    return (
        <Root>
            <h4 className={classes.title}>Informasi Umum</h4>

            <TextField
                label="Jenis Properti"
                name="listing_type_id"
                value={props.states.listing_type_id}
                onChange={changeInput}
                error={errorList.listing_type_id ? true : false}
                helperText={errorList.listing_type_id}
                variant="outlined"
                select
                fullWidth
                SelectProps={{
                    native: true,
                }}
                InputLabelProps={{
                    shrink: true
                }}
            >
                <option value="" key="default" disabled>Pilih Jenis Properti</option>
                <option value="2">Rumah</option>
                <option value="3">Tanah</option>
                <option value="4">Ruko</option>
                <option value="5">Kantor</option>
                <option value="6">Gudang</option>
                <option value="7">Ruang Usaha</option>
            </TextField>

            <TextField
                label="Nama"
                name="name"
                variant="outlined"
                value={props.states.name}
                onChange={changeInput}
                fullWidth
                error={errorList.name ? true : false}
                helperText={errorList.name}
            />

            <TextField
                label="Deskripsi"
                name="description"
                variant="outlined"
                value={props.states.description}
                onChange={changeInput}
                multiline
                rows={3}
                fullWidth
            />

            <AsyncAutoComplete
                key={1}
                label="Landlord"
                name="landlord_id"
                initialQuery={props.states.landlord_name}
                onChange={handleAutocompleteLandlord}
                onInputChange={handleAutocompleteInputChanged}
                url={`${process.env.REACT_APP_API_URL}/autocomplete/landlord`}
                placeholder="Isi Nama / Email / Nomor Telepon"
            />

            <AsyncAutoComplete
                label="PIC Jendela"
                name="pic"
                initialQuery={props.states.pic_name}
                onChange={handleAutocompletePic}
                onInputChange={handleAutocompleteInputChanged}
                url={`${process.env.REACT_APP_API_URL}/autocomplete/user`}
                placeholder="PIC Jendela"
            />

            <TextField
                label="Sertifikat"
                name="certificate"
                variant="outlined"
                value={props.states.certificate}
                onChange={changeInput}
                fullWidth
                error={errorList.certificate ? true : false}
                helperText={errorList.certificate}
                select
                SelectProps={{
                    native: true,
                }}
                InputLabelProps={{
                    shrink: !!props.states.certificate
                }}
            >
                <option aria-label="None" value="" />
                {renderCertificates}
            </TextField>


            <Divider className={classes.divider}></Divider>

            <h4 className={classes.title}>Lokasi Properti</h4>

            <TextField
                label="Provinsi"
                name="province_id"
                value={props.states.province_id}
                onChange={handleProvinceChange}
                disabled={areaAutoCompleteLoading ? true : false}
                error={errorList.province_id ? true : false}
                helperText={errorList.province_id}
                variant="outlined"
                select
                fullWidth
                SelectProps={{
                    native: true,
                }}
                InputLabelProps={{
                    shrink: true
                }}
            >
                <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                {provinceList.map(data => {
                    return (
                        <option value={data.id} key={data.id}>{data.label}</option>
                    )
                })}
            </TextField>

            <TextField
                label="Kota"
                name="city_id"
                value={props.states.city_id}
                onChange={handleCityChange}
                disabled={areaAutoCompleteLoading ? true : (props.states.province_id ? false : true)}
                error={errorList.city_id ? true : false}
                helperText={errorList.city_id}
                variant="outlined"
                select
                fullWidth
                SelectProps={{
                    native: true,
                }}
                InputLabelProps={{
                    shrink: true
                }}
            >
                <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                {cityList.map(data => {
                    return (
                        <option value={data.id} key={data.id}>{data.label}</option>
                    )
                })}
            </TextField>

            <TextField
                label="Area"
                name="district_id"
                value={props.states.district_id}
                onChange={handleDistrictChange}
                disabled={areaAutoCompleteLoading ? true : (props.states.city_id ? false : true)}
                error={errorList.district_id ? true : false}
                helperText={errorList.district_id}
                variant="outlined"
                select
                fullWidth
                SelectProps={{
                    native: true,
                }}
                InputLabelProps={{
                    shrink: true
                }}
            >
                <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                {districtList.map(data => {
                    return (
                        <option value={data.id} key={data.id}>{data.label}</option>
                    )
                })}
            </TextField>

            <TextField
                label="Alamat Detail"
                name="address"
                variant="outlined"
                value={props.states.address}
                onChange={changeInput}
                multiline
                rows={3}
                fullWidth
                error={errorList.address ? true : false}
                helperText={errorList.address ? errorList.address : 'Masukkan nama jalan dan nomor'}
            />

            <Grid>
                <div style={{ height: '400px', width: '100%', maxWidth: 400, marginBottom: "32px", position: 'relative', marginTop: '1rem' }}>
                    <div className={classes.markerContainer}>
                        <RoomIcon className={classes.marker} />
                    </div>

                    {process.env.REACT_APP_ENV === 'production' ?
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: process.env!.REACT_APP_GOOGLE_MAP_API_KEY!,
                                libraries: ['places', 'geometry'],
                            }}
                            defaultZoom={11}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                            options={{ fullscreenControl: false }}
                            center={{ lat: props.states.latitude, lng: props.states.longitude }}
                        ></GoogleMapReact>
                        :
                        <GoogleMapReact
                            center={{ lat: props.states.latitude, lng: props.states.longitude }}
                            defaultZoom={11}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                            options={{ fullscreenControl: false }}
                        >
                        </GoogleMapReact>
                    }
                </div>
            </Grid>

            <h4 className={classes.title}>Luas & Dimensi</h4>
            {renderViewLand()}

            <h4 className={classes.title}>Harga Jual & Sewa</h4>
            {renderViewPrice()}

            {renderViewAdditional()}

            <Button
                variant="contained"
                className={classes.submit_btn}
                color="primary"
                onClick={validateData}
                fullWidth
                style={{ marginTop: '2rem', textTransform: 'initial' }}
            >
                Simpan
            </Button>

        </Root>
    )
}
import React, { useState, useEffect } from 'react';
import { Button, Theme, Typography } from '@mui/material/';

/**
 * Table
 */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

/**
 * Form
 */
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';

/**
 * Loading
 */
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IListAdvisor {
    id: number;
    user_id: number;
    name: string;
    is_paused: number;
    round_robin_flag: number;
    role_id: number;
}

interface ISelectAdvisor {
    value: number;
    label: string;
}

const useStyles = generateStyle((theme: Theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
    },
}), "EnquirySell_Placement");

export default function EnquirySellPlacement() {
    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [listAdvisor, setListAdvisor] = useState<Array<IListAdvisor>>([]);
    const [selectAdvisors, setSelectAdvisors] = useState<Array<ISelectAdvisor>>([]);
    const [selectedAdvisor, setSelectedAdvisor] = useState(0);
    const [addFormState, setAddFormState] = useState(false);

    const addAdvisor = () => {
        if (selectedAdvisor === 0) {
            alert('No Advisor Selected');
        } else {
            renderWarningButton('Apakah anda yakin ingin menambah advisor ini?')
                .then((result) => {
                    if (result.value) {
                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/apartment-advisor-sell`, { user_id: selectedAdvisor })
                            .then(res => {
                                loadData();
                            })
                    }
                });
        }
    }

    const deleteAdvisor = (id: number) => {
        renderWarningButton("Apakah anda yakin ingin menghapus advisor ini?")
            .then((result) => {
                if (result.value) {
                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/apartment-advisor-sell/${id}`)
                        .then(res => {
                            loadData();
                        })
                }
            });
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/apartment-advisor-sell`)
            .then(res => {
                setListAdvisor(res.data.lists as Array<IListAdvisor>)
                setSelectAdvisors(res.data.advisors as Array<ISelectAdvisor>)
                setSelectedAdvisor(0)
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Root>
            <div className={classes.content}>
                <LoadingScreen open={isLoading} fullScreen />
                <Typography variant="h4">
                    Enquiry Sell Placement
                </Typography>

                <br></br>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { setAddFormState(!addFormState) }}
                >
                    Tambah Advisor
                </Button>

                <br></br>

                {addFormState &&
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <br></br>
                        <Autocomplete
                            id="combo-box-demo"
                            options={selectAdvisors}
                            getOptionLabel={(option) => option.label}
                            style={{ width: 300 }}
                            onChange={(e: any, option: any) => {
                                if (option === null) {
                                    setSelectedAdvisor(0)
                                } else {
                                    setSelectedAdvisor(option.value)
                                }
                            }}
                            onInputChange={(e: any, option: any) => {
                            }}
                            renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
                        />

                        <br></br>

                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: "5px" }}
                            disabled={selectedAdvisor === 0}
                            onClick={addAdvisor}
                        >
                            Submit
                        </Button>
                    </div>
                }

                <br></br>
                <br></br>

                <Typography variant="subtitle2">
                    <span role="img" aria-label="notice">⚠️</span> : Advisor ini di-pause enquiry-nya. Hubungin Admin untuk diaktifkan kembali
                </Typography>

                <Typography variant="subtitle2">
                    <span role="img" aria-label="star">★</span> : Advisor ini adalah Advisor Jendela (Diprioritaskan)
                </Typography>

                <br></br>




                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nama</TableCell>
                                <TableCell>Direct Enquiry Flag</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listAdvisor.map((row) => (
                                <TableRow key={row.user_id}>
                                    <TableCell component="th" scope="row">
                                        {(row.is_paused === 1) && `⚠️`}
                                        {([18, 24].includes(row.role_id)) && `★`}

                                        {(row.is_paused) ? <span style={{ color: "red" }}> {row.name}</span> : row.name}
                                    </TableCell>
                                    <TableCell>
                                        {row.round_robin_flag === 0 ? 'NO' : 'YES'}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => deleteAdvisor(row.id)}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Root>
    )
}
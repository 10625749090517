import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/**
 * Components
 */
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';


import './ApartmentModalDetail.css'
import { Grid, Typography } from '@mui/material';

interface Props {
    open: boolean;
    permissions: any;
    onCloseModal: () => void
}

interface IApartment {
    id: string
    name: string
    developer: string
    total_unit: number
    code: string
    area_name: string
    address: string
    service_charge: string
    total_listing: number
    total_listing_star: number
    towers: string

}

interface IParams extends Record<string, string | undefined> {
    id?: string
}

// interface ChartData {
//     date: string
//     label: string
//     value: number
// }

const ApartmentModalDetail = ({ permissions, open, onCloseModal }: Props) => {
    const params = useParams<IParams>();

    const [apartment, setApartment] = useState<IApartment>({
        id: "",
        name: "",
        developer: "",
        total_unit: 0,
        code: "",
        area_name: "",
        service_charge: "",
        total_listing: 0,
        total_listing_star: 0,
        towers: "",
        address: "",
    });
    const [isLoading, setIsLoading] = useState(false);


    // const [chartData, setChartData] = useState<ChartData[]>([])

    useEffect(() => {
        if (params.id) {
            // loadChart(params.id)
            loadApartment();
        }
        // eslint-disable-next-line
    }, [params.id]);

    const loadApartment = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/apartment/${params.id}`)
            .then(res => {
                setApartment(res.data);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    // const loadChart = (id: string) => {
    //     DefaultAxios.get(`${process.env.REACT_APP_API_URL}/apartment/session-chart?id=${id}`)
    //         .then(res => res.data)
    //         .then(data => {
    //             setChartData(data)
    //         })
    //         .catch(error => generalErrorHandler(error))
    // }

    return (
        <ModalDetail
            open={open}
            title="Detail"
            onCloseModal={onCloseModal}
            isLoading={isLoading}
            apartmentDetail={true}
            showEditButton={permissions['apartment.see-detail'] ? true : false}
            editButtonLink={`/apartment/edit/${apartment.id}`}
        >
            <>
                <ModalSection
                    xs={12}
                    md={12}
                    title="Informasi Dasar"
                    apartmentDetail={true}
                    content={[]}
                    additionalContent={
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography fontSize={16} fontWeight="bold">Name</Typography>
                                <Typography fontSize={14}>{apartment.name}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography fontSize={16} fontWeight="bold">Developer</Typography>
                                <Typography fontSize={14}>{apartment.developer}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography fontSize={16} fontWeight="bold">Total Unit</Typography>
                                <Typography fontSize={14}>{apartment.total_unit}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography fontSize={16} fontWeight="bold">Area</Typography>
                                <Typography fontSize={14}>{apartment.area_name}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography fontSize={16} fontWeight="bold">Service Charge</Typography>
                                <Typography fontSize={14}>{apartment.service_charge}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography fontSize={16} fontWeight="bold">Total Listing</Typography>
                                <Typography fontSize={14}>{apartment.total_listing}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography fontSize={16} fontWeight="bold">Code</Typography>
                                <Typography fontSize={14}>{apartment.code}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography fontSize={16} fontWeight="bold">Tower</Typography>
                                <Typography fontSize={14} style={{ wordWrap: "break-word" }}>
                                    {apartment.towers}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography fontSize={16} fontWeight="bold">Total Star Listing</Typography>
                                <Typography fontSize={14}>{apartment.total_listing_star}</Typography>
                            </Grid>
                            <Grid item xs={12} md={12} paddingBottom={{ xs: 2.5, md: 2.5 }}>
                                <Typography fontSize={16} fontWeight="bold">Address</Typography>
                                <Typography fontSize={14}>{apartment.address}</Typography>
                            </Grid>
                        </Grid>
                    }
                />
            </>
        </ModalDetail>
    );
}

export default ApartmentModalDetail;
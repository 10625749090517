import React from "react"
import { Theme } from "@mui/material"
import { generateStyle } from "../../../_utils/DefaultStyle"

const useStyles = generateStyle((theme: Theme) =>
({
  badge: {
    backgroundColor: '#EBFCEF',
    border: '1px solid #E1F4E6',
    color: '#033A11',
    fontSize: 10,
    borderRadius: '1rem',
    padding: '2px 6px',
    marginLeft: 5
  }
}), "CustomBadge"
)

interface IProps {
  amount: number
}

const CustomBadge = (props: IProps) => {
  const { Root, classes } = useStyles()

  return (
    <Root>
      <span className={classes.badge}>
        {props.amount}
      </span>
    </Root>
  )
}

export default CustomBadge

import React, { useEffect, useReducer, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Grid,
    Typography,
    MenuItem,
    TextField,
    Switch
} from '@mui/material';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import MuiDatePicker from '../../_components/MuiDatePicker';
import TextArea from '../../_components/_form/TextArea';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import { sourceLists, marketplaceFromOptions } from '../../_utils/ConstData';
import DefaultAxios from '../../_utils/DefaultAxios';
import { currencyToNumber, dateFormat, generalErrorHandler, isValidEmail } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IState {
    member_id: string;
    is_buy: boolean;
    customer_name: string;
    customer_phone: string;
    customer_email: string;
    apartment_name_label: string;
    apartment_id: string;
    unit_code_label: string;
    unit_id: string;
    source: string;
    marketplace_from: string;
    marketplace_from_other: string;
    enquiry_phone: string;
    estimated_check_in: Date | null;
    estimated_rent_period: string;
    estimated_budget_rupiah: string;
    keterangan: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IErrorState {
    source: string;
    member_id: string;
    customer_name: string;
    customer_phone: string;
    customer_email: string;
    enquiry_phone: string;
    marketplace_from: string;
    marketplace_from_other: string;
}

const useStyles = generateStyle(() => ({
    root: {
        maxWidth: '750px',
        marginBottom: '70px',
        boxShadow: '0px 3px 15px #0000001A',
        '& .Mui-disabled': {
            backgroundColor: '#eee'
        }
    },
    title: {
        fontSize: '34px',
        marginLeft: "10px",
        borderBottom: '1px solid #0000004D'
    },
    subtitle: {
        fontSize: '20px'
    },
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px"
    },
}), "EnquiryAdd_Form"
);



const EnquiryAddForm = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/enquiry';
    const { Root, classes } = useStyles();
    const navigate = useNavigate();

    const initialState = {
        member_id: '',
        is_buy: false,
        customer_name: '',
        customer_phone: '',
        customer_email: '',
        apartment_name_label: '',
        apartment_id: '',
        unit_code_label: '',
        unit_id: '',
        source: 'whatsapp',
        marketplace_from: '',
        marketplace_from_other: '',
        enquiry_phone: '',
        estimated_check_in: null,
        estimated_rent_period: '',
        estimated_budget_rupiah: '',
        keterangan: ''
    };

    const initialErrorState = {
        member_id: '',
        customer_name: '',
        customer_phone: '',
        customer_email: '',
        enquiry_phone: '',
        marketplace_from: '',
        marketplace_from_other: '',
        source: ''
    }

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [isLoading, setIsLoading] = useState(false);
    const [advisorNeeded, setAdvisorNeeded] = useState(false);
    const [isWings, setIsWings] = useState(false);

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    useEffect(() => {
        document.body.style.backgroundColor = '#eee';

        return () => {
            document.body.style.backgroundColor = 'unset';
        }
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (name === 'is_buy') {
            value = value === 'true';
        } else if (name === 'estimated_budget_rupiah') {
            value = value ? String(currencyToNumber(value)) : '';
        } else if (name === 'estimated_rent_period' || name === 'customer_phone') {
            value = value.replace(/\D+/g, '');
        }

        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleAutoCompleteChange = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChange = (e: any, name: string) => {
        setInputState({ name, value: '', type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        // if (advisorNeeded && !inputState.member_id) {
        //     newError.member_id = 'Advisor name wajib diisi';
        //     isValid = false;
        // }

        if (!inputState.customer_name) {
            newError.customer_name = 'Customer name wajib diisi';
            isValid = false;
        }

        if (!inputState.customer_phone) {
            newError.customer_phone = 'Customer phone wajib diisi';
            isValid = false;
        } else if (inputState.customer_phone.length < 10) {
            newError.customer_phone = 'Customer phone minimal 10 angka';
            isValid = false;
        }

        if (inputState.customer_email && !isValidEmail(inputState.customer_email)) {
            newError.customer_email = 'Format customer email salah';
            isValid = false;
        }

        if (inputState.source === 'marketplace') {
            if (!inputState.marketplace_from) {
                newError.marketplace_from = 'Marketplace from wajib diisi apabila enquiry dari "Marketplace"';
                isValid = false;
            } else if (inputState.marketplace_from === 'other' && !inputState.marketplace_from_other) {
                newError.marketplace_from_other = 'Marketplace from other wajib diisi apabila marketplace dari "Lainnya"';
                isValid = false;
            }
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });

        return isValid;
    }

    const mapInput = () => {
        const data: any = { ...inputState };

        if (!advisorNeeded) {
            data.member_id = null;
        }

        if (inputState.estimated_check_in) {
            data.estimated_check_in = dateFormat(inputState.estimated_check_in, 'YYYY-MM-DD');
        }

        if (inputState.source === 'marketplace') {
            if (inputState.marketplace_from === 'other') {
                data.marketplace_from = data.marketplace_from_other;
            }
        } else {
            data.marketplace_from = '';
        }

        return data;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        setIsLoading(true);
        DefaultAxios.post(API_URL, mapInput())
            .then(res => {
                Swal.fire({
                    title: 'Submit berhasil',
                    icon: 'success',
                    timer: 1000,
                    onAfterClose: () => {
                        navigate('/enquiry');
                    }
                })
            })
            .catch(err => {
                if (err.response.status === 417) {
                    Swal.fire({
                        title: "Info",
                        html: err.response.data.message,
                        icon: 'info',
                    });
                    setAdvisorNeeded(true);
                } else {
                    generalErrorHandler(err);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        const oldState: IState = { ...inputState };
        oldState[name as 'apartment_id' | 'unit_id'] = value.id.toString();
        setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    return (
        <Root>
            <Card className={classes.root}>
                <LoadingScreen open={isLoading} fullScreen />

                <Typography className={classes.title}>
                    Enquiry Form
                </Typography>

                <CardContent>
                    {/* <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Looking For</FormLabel>
                            <RadioGroup row name="is_buy" value={inputState.is_buy} onChange={handleChange}>
                                <FormControlLabel value={false} control={<Radio />} label="Rent" />
                                <FormControlLabel value={true} control={<Radio />} label="Buy" />
                            </RadioGroup>
                        </FormControl>
                    </Grid> */}

                    <Grid item xs={12} className={classes.switchContainer}>
                        <Typography className={classes.subtitle}>
                            Enquiry Wings
                        </Typography>
                        <Switch
                            color="primary"
                            value={true}
                            checked={isWings}
                            onChange={() => {
                                setIsWings(!isWings);
                            }}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Customer Name"
                                name="customer_name"
                                value={inputState.customer_name}
                                onChange={handleChange}
                                error={!!errorState.customer_name}
                                helperText={errorState.customer_name}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Customer Phone"
                                name="customer_phone"
                                value={inputState.customer_phone}
                                onChange={handleChange}
                                error={!!errorState.customer_phone}
                                helperText={errorState.customer_phone}
                                placeholder="Start with 08 eg. 081234567890 / Country Code eg. 6281234567890"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Customer Email (Opsional)"
                                name="customer_email"
                                value={inputState.customer_email}
                                onChange={handleChange}
                                error={!!errorState.customer_email}
                                helperText={errorState.customer_email}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                label="Apartment"
                                name="apartment_id"
                                initialQuery={inputState.apartment_name_label}
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/apartment`}
                                placeholder="Apartment Name"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                label="Unit"
                                name="unit_id"
                                initialQuery={inputState.apartment_name_label}
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/unit?apartment_ids=${inputState.apartment_id}`}
                                placeholder="Unit Code"
                            />
                        </Grid>

                        {
                            (isWings || advisorNeeded) &&
                            <Grid item xs={12}>
                                <AsyncAutoComplete
                                    url={`${process.env.REACT_APP_API_URL}/autocomplete/user/${isWings ? 'wings' : 'advisor'}`}
                                    label={isWings ? "Agent Wings" : "Property Manager"}
                                    name="member_id"
                                    onChange={handleAutoCompleteChange}
                                    onInputChange={handleAutocompleteInputChange}
                                    errorText={errorState.member_id}
                                />
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                label="Source"
                                name="source"
                                value={inputState.source}
                                onChange={handleChange}
                                error={!!errorState.source}
                                helperText={errorState.source}
                            >
                                {(sourceLists).map(sourceList => <MenuItem value={sourceList.key} key={sourceList.key}>{sourceList.value}</MenuItem>)}
                            </TextField>
                        </Grid>

                        {
                            inputState.source === 'marketplace' && // Marketplace
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    label="Marketplace From"
                                    name="marketplace_from"
                                    value={inputState.marketplace_from}
                                    onChange={handleChange}
                                    error={!!errorState.marketplace_from}
                                    helperText={errorState.marketplace_from}
                                >
                                    {marketplaceFromOptions.map(item => <MenuItem value={item.value} key={item.value} >{item.label}</MenuItem>)}
                                </TextField>
                            </Grid>
                        }

                        {
                            (inputState.source === 'marketplace' && inputState.marketplace_from === 'other') && // Other Marketplace
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Marketplace Other"
                                    name="marketplace_from_other"
                                    value={inputState.marketplace_from_other}
                                    onChange={handleChange}
                                    error={!!errorState.marketplace_from_other}
                                    helperText={errorState.marketplace_from_other}
                                />
                            </Grid>
                        }

                        <Grid item xs={6}>
                            <MuiDatePicker
                                label="Estimated Check In"
                                value={inputState.estimated_check_in}
                                onChange={(date: Date | null) => setInputState({ name: 'estimated_check_in', value: date, type: 'SET_ITEM' })}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Estimated Rent Period"
                                name="estimated_rent_period"
                                value={inputState.estimated_rent_period}
                                onChange={handleChange}
                            />
                        </Grid>

                        {/* <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                variant="outlined"
                                label="Estimated Budget"
                                name="estimated_budget_rupiah"
                                value={inputState.estimated_budget_rupiah ? numberToCurrency(inputState.estimated_budget_rupiah) : ''}
                                onChange={handleChange}
                            />
                        </Grid> */}

                        <Grid item xs={12}>
                            <TextArea
                                label="Note"
                                name="keterangan"
                                value={inputState.keterangan}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Root>
    );
}

export default EnquiryAddForm;
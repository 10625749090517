import OneSignal from "react-onesignal"
import DefaultAxios from "./DefaultAxios";
import { _getCookie, generalErrorHandler, setBrowserId } from "./Helper";
import axios from "axios";

export const initializeNotification = () => {
    if (process.env.REACT_APP_ONESIGNAL_APP_ID) {
        OneSignal.init({
            appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
            allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production',
            serviceWorkerParam: { scope: '/push/onesignal/' },
            serviceWorkerPath: '/push/onesignal/'
        })
            .then(() => {
                const identifier_id = setBrowserId()
                OneSignal.login(identifier_id)

                OneSignal.Notifications.requestPermission()
                    .catch(err => {
                        console.error(err)
                    })

                OneSignal.Notifications.setDefaultUrl(`${window.location.protocol + '//' + window.location.host}/notification`)

                OneSignal.Notifications.addEventListener('click', notification => {
                    console.log(notification)
                })
            })
            .catch(err => {
                console.error(err)
            })
    }
}

export const subscribeNotification = async (jwt_token: string) => {
    let identifier_id = _getCookie('browser_id')

    if (!identifier_id) {
        identifier_id = setBrowserId()
    }

    return axios.post(`${process.env.REACT_APP_API_URL}/subscriptions`, {
        identifier_id,
    }, {
        headers: {
            'Authorization': `Bearer ${jwt_token}`,
        }
    })
}

export const unSubscibeNotification = () => {
    const identifier_id = _getCookie('browser_id')

    if (identifier_id) {
        OneSignal.logout()

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/subscriptions/delete`, {
            identifier_id,
        })
            .catch(generalErrorHandler)
    }
}
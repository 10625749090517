import React, { Dispatch, SetStateAction } from 'react'
import { Grid, Button, Card, CardContent, IconButton, Typography, TextField, MenuItem } from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PhotoPreview from '../../../_components/PhotoPreview';

/* 
* Icons and Style
*/
import DeleteIcon from '@mui/icons-material/Delete';
import { IOrderJobs } from './FixingCleaningForm';
import { useParams } from 'react-router-dom';
import { generalErrorHandler, renderQuestionButton, renderSuccessButton, renderToastSuccess } from '../../../_utils/Helper';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generateStyle } from '../../../_utils/DefaultStyle';

/* 
* Interfaces
*/

interface IProps {
    orderJobs: IOrderJobs[] | null,
    setOrderJobs: Dispatch<SetStateAction<IOrderJobs[]>>
    setFormChanged: Dispatch<SetStateAction<boolean>>
}

const typesOption = [
    { label: 'Cleaning', value: 'jhs_cleaning' },
    { label: 'Fixing', value: 'jhs_fixing' },
    { label: 'AC', value: 'jhs_ac' },
]

const JobDetail = (props: IProps) => {
    const params = useParams<{ id: string }>()
    const { Root, classes } = useStyles()

    const handleAddJob = () => {
        props.setFormChanged(true)
        const oldData = Array.from(props.orderJobs as IOrderJobs[])
        oldData.push({
            id: null,
            type: '',
            done_at: null,
            photos: [],
            photos_api: [],
            description: ''
        })
        props.setOrderJobs(oldData)
    }

    const handleChangeDescription = (index: number, value: string) => {
        props.setFormChanged(true)
        const oldData = Array.from(props.orderJobs as IOrderJobs[])
        oldData[index].description = value
        props.setOrderJobs(oldData)
    }

    const handleChangeType = (index: number, value: string) => {
        props.setFormChanged(true)
        const oldData = Array.from(props.orderJobs as IOrderJobs[])
        oldData[index].type = value
        props.setOrderJobs(oldData)
    }

    const handleDeleteImageId = (id: number, index: number) => {
        renderQuestionButton('Proses yang telah dilakukan tidak bisa dibatalkan')
            .then(res => {
                if (res.value) {
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/delete-photo`, { id })
                        .then(res => {
                            handleDeleteImage(id, index)
                            renderToastSuccess('Image Deleted!')
                        })
                        .catch(err => generalErrorHandler(err))
                }

                if (res.dismiss) {
                    return null
                }
            })
            .catch(err => generalErrorHandler(err))
    }

    const handleDeleteImage = (id: number, index: number) => {
        const oldData = Array.from(props.orderJobs as IOrderJobs[])
        oldData[index].photos_api = oldData[index].photos_api.filter((photo) => photo.id !== id)
        props.setOrderJobs(oldData)
    }

    const handleRemovePhotoPreview = (id: number, index: number) => {
        const oldData = Array.from(props.orderJobs as IOrderJobs[])
        oldData[index].photos = oldData[index].photos?.filter((photo, idx) => idx !== id) || []
        props.setOrderJobs(oldData)
    }

    const handleChangeFile = (index: number, file: File[], id: number | null) => {
        const oldData = Array.from(props.orderJobs as IOrderJobs[])
        oldData[index].photos = oldData[index].photos.concat(Array.from(file))
        props.setOrderJobs(oldData)
    }

    const handleDeleteCard = (index: number, id: number | null) => {
        const processDeleteCard = (index: number) => {
            const oldData = Array.from(props.orderJobs as IOrderJobs[])
            const newData = oldData.filter((data, idx) => idx !== index)
            props.setOrderJobs(newData)
        }
        if (params.id && id !== null) {
            renderQuestionButton('This will delete data from server, this process cannot be undone')
                .then(res => {
                    if (res.value) {
                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/delete-job`, { id })
                            .then(res => {
                                renderSuccessButton('Job Detail Deleted!')
                                    .then(res => processDeleteCard(index))
                            })
                            .catch(err => generalErrorHandler(err))
                    }
                })
        } else {
            processDeleteCard(index)
        }
    }


    return (
        <Root>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {
                        props.orderJobs !== null &&
                        props.orderJobs.length > 0 &&
                        props.orderJobs?.map((job, index) => (
                            <Card variant="outlined" sx={{ mb: 1 }} key={index} className={classes.card}>
                                <CardContent>
                                    {
                                        job.done_at ?
                                            null :
                                            <IconButton
                                                className={classes.delete}
                                                onClick={() => handleDeleteCard(index, job.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                    }
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} md={3} sx={{ pt: 2 }}>
                                            <TextField
                                                select
                                                label="Type"
                                                name='type'
                                                disabled={!!job.done_at}
                                                size='small'
                                                onChange={(e) => handleChangeType(index, e.target.value)}
                                                value={job.type}
                                                defaultValue=""
                                                fullWidth
                                            >
                                                <MenuItem disabled value="">-- Pilih Tipe Job --</MenuItem>
                                                {
                                                    typesOption.map((item) => (
                                                        <MenuItem value={item.value}>{item.label}</MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                minRows={3}
                                                rows={3}
                                                name="description"
                                                label="Job Description"
                                                disabled={!!job.done_at}
                                                value={job.description}
                                                onChange={(e: any) => handleChangeDescription(index, e.target.value)}
                                            />
                                        </Grid>
                                        {
                                            job.photos_api && job.photos_api.length > 0 &&
                                            <Grid item xs={12} className={classes.photoContainer}>
                                                {
                                                    job.photos_api.map((photo) => (
                                                        <PhotoPreview
                                                            key={photo.id}
                                                            src={photo.photo_url as string}
                                                            style={{ maxHeight: '80px', maxWidth: '80px', margin: 0 }}
                                                            onRemoveFile={(id) => handleDeleteImageId(id, index)}
                                                            index={photo.id}
                                                            disableDelete={!!job.done_at}
                                                        />
                                                    ))
                                                }
                                            </Grid>

                                        }
                                        {
                                            job.photos && job.photos.length > 0 && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography>Uploaded Images:  {job.photos.length}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.photoContainer}>
                                                        {
                                                            job.photos.map((photo: File, idx: number) => (
                                                                <PhotoPreview
                                                                    key={photo.name}
                                                                    src={URL.createObjectURL(photo)}
                                                                    style={{ maxHeight: '80px', maxWidth: '80px', margin: 0 }}
                                                                    onRemoveFile={(id) => handleRemovePhotoPreview(id, index)}
                                                                    index={idx}
                                                                />
                                                            ))
                                                        }
                                                    </Grid>
                                                </>
                                            )
                                        }
                                        <Grid item xs={12}>
                                            <Button
                                                disabled={!!job.done_at}
                                                startIcon={<FileUploadIcon />}
                                                variant="outlined"
                                                component="label"
                                                onChange={(e: any) => handleChangeFile(index, e.target.files, job.id)}
                                            >
                                                Upload Image
                                                <input
                                                    type="file"
                                                    multiple
                                                    accept='image/*'
                                                    hidden
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))
                    }

                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        onClick={handleAddJob}
                    >
                        Add {
                            props.orderJobs &&
                                props.orderJobs.length > 0 ? 'More' : 'Job'}
                    </Button>
                </Grid>
            </Grid>
        </Root>
    )
}

export default JobDetail

const useStyles = generateStyle(theme => ({
    card: {
        position: 'relative'
    },
    delete: {
        position: "absolute",
        right: 10,
    },
    photoContainer: {
        marginTop: 15,
        display: 'flex',
        flexWrap: 'wrap',
        gap: 10,
    }
}), "JobDetail")
import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
    Button,
    IconButton,
    TextField,
    Tooltip,
    Chip,
    Paper, Tabs, Tab, Theme,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

/**
 * Components
 */
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';
import FileList from './_components/FileList';
import CancelReasonModal from './_components/CancelReasonModal';
import SetUnpaidModal from './_components/SetUnpaidModal';
import PaymentModal from './_components/PaymentModal';
import ImagePreview from '../../_components/ImagePreview';
import JobDetailList, { IJob } from './_components/JobDetailList';
import StaffFormModal from './_components/StaffFormModal';
import JobLogList from './_components/JobLogsList';
import LoadingScreen from '../../_components/LoadingScreen';
import NewCommentList, { IComment, IMentionList } from '../../_components/NewCommentList';
import ScheduleList, { ISchedule } from './_components/ScheduleList';
import NotesModal from './_components/NotesModal';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { dateFormat, generalErrorHandler, numberToCurrency, openExternalLink, renderQuestionButton, renderSuccessButton, renderWarningButton } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';

/**
 * Icon
 */
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

interface IState {
    id: string,
    code: string,
    billing_nominal: string | null,
    invoice_code: string | null,
    billing_invoice_file: string | null,
    payment_invoice_file: string | null,
    activity: string,
    status: string,
    closing_id: string,
    unit_id: string,
    datetime: string,
    pic_name: string,
    work_type: string,
    work_type_label: string,
    paid_by: string,
    request_date: string,
    request_time: string,
    key_collection_point: string,
    package_duration: string,
    notes: string,
    requester_id: string,
    assigner_id: string,
    assigned_id: string,
    done_by: string,
    canceled_by: string,
    created_at: string,
    updated_at: string,
    closing_code: string,
    unit_code: string,
    apartment_name: string,
    bedroom: number,
    tower: string,
    floor_level: string,
    unit_number: string,
    start_date: string,
    activity_name: string,
    status_label: string,
    requester_name: string,
    requester_phone: string,
    requester_division: string,
    assigner_name: string,
    assigned_name: string,
    owner_name: string,
    tenant_name: string,
    work_code: string,
    jobs: IJob[]
    schedules: ISchedule[]
    billing_invoice_file_url: string,
    payment_invoice_file_url: string,
}

const useStyles = generateStyle((theme: Theme) =>
({
    footer: {
        display: 'flex',
        minHeight: '500px',
        backgroundColor: '#eee',
        padding: '10px',
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    actionButtonContainer: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
        overflow: 'auto',
        margin: '0 0',
        '& button, a': {
            marginLeft: '10px'
        }
    },
    actionContainer: {
        display: 'flex'
    },
}), "JHSOrderModal_Detail"
)

const JhsOrderModalDetail = () => {
    const { Root, classes } = useStyles();
    const params = useParams<"id">()
    const navigate = useNavigate()
    const permissions = useContext(PermissionContext)
    const [loading, setLoading] = useState(false);

    const [imagePreview, setImagePreview] = useState('')

    const [isCodeEdit, setIsCodeEdit] = useState(false)
    const [isBillingEdit, setIsBillingEdit] = useState(false)
    const [isInvoiceCodeEdit, setIsInvoiceCodeEdit] = useState(false)
    // const [showSelfie, setShowSelfie] = useState(false);

    const [codeState, setCodeState] = useState('')
    const [billingState, setBillingState] = useState('')
    const [invoiceCodeState, setInvoiceCodeState] = useState('')

    const [staffModal, setStaffModal] = useState(false)
    const [cancelReasonModal, setCancelReasonModal] = useState(false)
    const [noteModal, setNoteModal] = useState<string | number>('')
    const [paymentModal, setPaymentModal] = useState(false)

    const [unpaidModal, setUnpaidModal] = useState(false)
    const [unpaidEditData, setUnpaidEditData] = useState<null | {
        amount: string
        invoiceCode?: string | null
    }>(null)

    const [activeTab, setActiveTab] = useState(0);
    const [footer, setFooter] = useState<any>(null);
    const [isFooterLoading, setIsFooterLoading] = useState(false);
    const [comment, setComment] = useState<{
        comments: IComment[],
        mentions: IMentionList[]
    }>({
        comments: [],
        mentions: []
    })
    const [state, setState] = useState<IState>({
        id: '',
        code: '',
        billing_nominal: null,
        invoice_code: null,
        billing_invoice_file: null,
        payment_invoice_file: null,
        activity: '',
        status: '',
        closing_id: '',
        datetime: '',
        unit_id: '',
        pic_name: '',
        work_type: '',
        work_type_label: '',
        paid_by: '',
        request_date: '',
        request_time: '',
        key_collection_point: '',
        package_duration: '',
        notes: '',
        requester_id: '',
        assigner_id: '',
        assigned_id: '',
        done_by: '',
        canceled_by: '',
        created_at: '',
        updated_at: '',
        closing_code: '',
        unit_code: '',
        apartment_name: '',
        bedroom: 0,
        tower: '',
        floor_level: '',
        unit_number: '',
        start_date: '',
        activity_name: '',
        status_label: '',
        requester_name: '',
        requester_phone: '',
        requester_division: '',
        assigner_name: '',
        assigned_name: '',
        owner_name: '',
        tenant_name: '',
        work_code: '',
        jobs: [],
        schedules: [],
        billing_invoice_file_url: '',
        payment_invoice_file_url: '',
    });

    const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    }

    useEffect(() => {
        if (typeof params.id !== "undefined") {
            setActiveTab(0)
            loadData();
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [params]);

    const handleClose = () => {
        navigate(-1)
    }

    const loadData = () => {
        setLoading(true);
        if (params.id) {
            DefaultAxios
                .get(`${process.env.REACT_APP_API_URL}/activity/${params.id}`)
                .then(res => {
                    const data = res.data;
                    setState(data.activity);
                    setComment(data.comments)
                    setCodeState(data.activity.work_code || '')
                    setBillingState(data.activity.billing_nominal)
                    setInvoiceCodeState(data.activity.invoice_code)
                })
                .catch((err) => {
                    generalErrorHandler(err);
                    handleClose()
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const handleActivity = (type: string, payment?: File) => {
        let axios: Promise<AxiosResponse<any>>;

        setLoading(true)
        if (payment instanceof File) {
            let fd = new FormData()
            fd.append('id', String(params.id) || '')
            fd.append('payment_invoice_file', payment)
            axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/${type === 'done' ? 'done' : 'cancel-activity'}`, fd)
        } else {
            axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/${type === 'done' ? 'done' : 'cancel-activity'}`, {
                id: params.id
            })
        }
        axios
            .then(res => {
                renderSuccessButton(type === 'done' ? 'Berhasil menyelesaikan order' : 'Berhasil membatalkan order')
                setPaymentModal(false)
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setLoading(false)
                loadData()
            })
    }

    const handleStatus = (type: string) => {
        switch (type) {
            case 'done':
                if (state.activity === 'fixing_cleaning' && state.paid_by !== 'office') {
                    setPaymentModal(true)
                } else {
                    renderWarningButton(`Apakah anda yakin ingin ${type === 'done' ? 'menyelesaikan' : 'membatalkan'} order ini?`)
                        .then((result) => {
                            if (result.value) {
                                handleActivity('done')
                            }
                        });
                }
                break;
            case 'cancel':
                setCancelReasonModal(true)
                break;
            case 'set_unpaid':
                setUnpaidModal(true)
                break;
            default:
                return
        }
    }

    const handleEditCode = () => {
        if (codeState) {

            const data = {
                id: state.id,
                code: codeState
            }
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/add-code`, data)
                .then(res => {
                    renderSuccessButton('Berhasil mengganti code')
                    loadData()
                    setIsCodeEdit(false)
                })
                .catch(error => {
                    generalErrorHandler(error)
                })
                .finally(() => {
                    loadData()
                })
        } else {
            setIsCodeEdit(false)
        }
    }

    const handleChangeBilling = () => {
        const data = {
            id: state.id,
            billing_nominal: billingState.toString().replace(/\D+/g, '')
        }
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/change-billing-nominal`, data)
            .then(res => {
                renderSuccessButton('Berhasil mengganti billing')
                loadData()
                setIsBillingEdit(false)
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                loadData()
            })
    }

    const handleChangeInvoiceCode = () => {
        if (invoiceCodeState) {

            const data = {
                id: state.id,
                invoice_code: invoiceCodeState
            }
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/change-invoice-code`, data)
                .then(res => {
                    renderSuccessButton('Berhasil mengganti Invoice Code')
                    loadData()
                    setIsInvoiceCodeEdit(false)
                })
                .catch(error => {
                    generalErrorHandler(error)
                })
                .finally(() => {
                    loadData()
                })
        } else {
            setIsInvoiceCodeEdit(false)
        }
    }

    const handleStaff = () => {
        setStaffModal(true)
    }

    const getStatus = (status: string) => {
        let color: 'default' | 'primary' | 'success' | 'info' | 'error' = "default";
        switch (status) {
            case 'On Process':
                color = 'default';
                break;
            case 'Done':
                color = 'success';
                break;
            case 'Canceled':
                color = 'error';
                break;
            case 'Unpaid':
                color = 'error'
                break;

        }

        return (
            <Chip style={{ marginRight: 12 }} color={color} size='small' label={status} />
        )
    }

    const getPaidBy = (paid: string) => {
        let label = ""
        switch (paid) {
            case 'owner':
                label = 'Owner';
                break;
            case 'tenant':
                label = 'Tenant';
                break;
            case 'deposit':
                label = 'Deposit';
                break;
            case 'office':
                label = 'Jendela360';
                break;
        }
        return (
            <span>{label}</span>
        )
    }

    // const handleReply = (value: string, mentionData: any) => {
    //     setIsFooterLoading(true);
    //     DefaultAxios
    //         .post(`${process.env.REACT_APP_API_URL}/activity/comment/${state.id}`, { value, mentionData })
    //         .then(res => {
    //             setTimeout(loadFooter, 100);
    //         })
    //         .catch(err => {
    //             generalErrorHandler(err);
    //         })
    //         .finally(() => {
    //             setIsFooterLoading(false);
    //         })
    // }

    const handleUploadFile = (fd: FormData) => {
        setIsFooterLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/activity/file/${state.id}`, fd)
            .then(res => {
                renderSuccessButton("Upload berhasil")
                loadFooter();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    const handleMarkDone = (note: string) => {
        renderQuestionButton('Apakah anda yakin ingin menyelesaikan job?')
            .then(res => {
                if (res.dismiss) return

                setLoading(true)
                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/done-job`, {
                    id: noteModal,
                    done_notes: note
                })
                    .then(res => {
                        renderSuccessButton('Mark Done Success')
                        setNoteModal('')
                        loadData()
                    })
                    .catch(generalErrorHandler)
                    .finally(() => {
                        setLoading(false)
                    })
            })
    }

    const loadFooter = () => {
        let url: string = '';

        switch (activeTab) {
            case 0:
                // url = `${process.env.REACT_APP_API_URL}/activity/comment/${state.id}`;
                setFooter(
                    <NewCommentList
                        id={state.id}
                        type='activity'
                        warnAttachment
                        initialData={comment}
                    />
                )
                setIsFooterLoading(false);
                break;
            case 2:
                url = `${process.env.REACT_APP_API_URL}/activity/file/${state.id}`;
                break;
            case 3:
                url = `${process.env.REACT_APP_API_URL}/activity/log`;
                break;
            default:
                break;
        }

        if (!url || !state.id) {
            return;
        }

        setIsFooterLoading(true);
        DefaultAxios
            .get(url, {
                params: {
                    id: state.id
                }
            })
            .then(res => {
                switch (activeTab) {
                    // case 0:
                    //     setFooter(
                    //         <CommentList
                    //             comments={res.data.comments}
                    //             users={res.data.users}
                    //             onReply={handleReply}
                    //         />
                    //     )
                    //     break;
                    case 2:
                        setFooter(
                            <FileList
                                closing_id={state.id}
                                permissions={permissions}
                                files={res.data}
                                onUploadFile={handleUploadFile}
                                onDeleteFile={() => loadFooter()}
                            />
                        )
                        break;
                    case 3:
                        setFooter(
                            <JobLogList
                                id={state.id}
                                logs={res.data}
                            />
                        )
                        break;
                    default:
                        break;
                }
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    const renderStatusAction = (status: string) => {
        const cancelButton = permissions['order-jhs.cancel'] ? <Button variant="contained" color="secondary" size="small" onClick={() => handleStatus('cancel')}>Cancel</Button> : null
        const setUnpaidButton = permissions['order-jhs.set-unpaid'] ? <Button variant="contained" color="warning" size="small" onClick={() => handleStatus('set_unpaid')} style={{ marginRight: 8 }}>Set Unpaid</Button> : null
        const doneButton = <Button variant="contained" color="primary" size="small" onClick={() => handleStatus('done')} style={{ marginRight: 8 }}>Done</Button>

        switch (status) {
            case 'new':
                return cancelButton
            case 'on_process':
                return [
                    state.paid_by === 'office' ? doneButton : setUnpaidButton,
                    cancelButton
                ]
            case 'unpaid':
                return [doneButton, cancelButton]
            default:
                return <></>
        }
    }

    const handleSeeBilling = (data: string) => {
        if (data.endsWith('.pdf')) {
            openExternalLink(data)
        } else {
            setImagePreview(data)
        }
    }

    const handleGeneratePDF = () => {
        setLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/activity/generate-pdf-jhs`, {
            params: { id: state.id },
            responseType: 'blob'
        })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'jhs-order.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        loadFooter();
        // eslint-disable-next-line
    }, [activeTab, state.id, state.jobs, comment]);

    useEffect(() => {
        setActiveTab(0)
    }, [])

    return (
        <>
            <StaffFormModal
                data={state}
                open={staffModal}
                onClose={(refetch: boolean) => {
                    setStaffModal(false)
                    if (refetch) {
                        loadData()
                    }
                }}
            />

            <ImagePreview
                image={imagePreview}
                onClose={() => {
                    setImagePreview('')
                }}
            />
            <PaymentModal
                loading={loading}
                open={paymentModal}
                onClose={() => setPaymentModal(false)}
                onSubmitPayment={(payment: any) => {
                    handleActivity('done', payment)
                }}
            />
            <SetUnpaidModal
                id={state.id}
                open={unpaidModal}
                editData={unpaidEditData}
                onClose={(refresh: boolean) => {
                    setUnpaidModal(false)
                    setUnpaidEditData(null)

                    if (refresh) {
                        loadData()
                    }
                }}
            />
            <ModalDetail
                open={typeof params.id !== 'undefined'}
                isLoading={loading}
                onCloseModal={handleClose}
                title={`JHS Order - ${state.code}`}
                showEditButton={true}
                editButtonLink={`/jhs-order/edit/${params.id}`}
            >
                <>
                    <ModalSection
                        title="Basic Information"
                        xs={12}
                        md={6}
                        content={[
                            ['Request Type', `${state.activity_name}`],
                            ['Status',
                                state.activity === 'fixing_cleaning' ?
                                    <>
                                        <span style={{ marginRight: 8 }}>{getStatus(state.status_label)}</span>
                                        {
                                            renderStatusAction(state.status)
                                        }
                                    </> :
                                    state.status_label === 'On Process' ?
                                        <>
                                            <span style={{ marginRight: 8 }}>{getStatus(state.status_label)}</span>
                                            <Button variant="contained" color="primary" size="small" onClick={() => handleStatus('done')} style={{ marginRight: 8 }}>Done</Button>
                                            {
                                                permissions['order-jhs.cancel'] ?
                                                    <Button variant="contained" color="secondary" size="small" onClick={() => handleStatus('cancel')}>Cancel</Button>
                                                    : null
                                            }
                                        </> :
                                        state.status_label ? getStatus(state.status_label) : `-`
                            ],
                            ...(state.billing_nominal !== null ?
                                !isBillingEdit ? [[
                                    <span>
                                        Billing Nominal
                                        {
                                            state.status === 'unpaid' && permissions['order-jhs.set-unpaid'] ?
                                                <IconButton
                                                    color='warning'
                                                    onClick={() => {
                                                        setUnpaidEditData({
                                                            amount: state.billing_nominal!,
                                                            invoiceCode: state.invoice_code,
                                                        })
                                                        setUnpaidModal(true)
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                : null
                                        }

                                    </span>, <>
                                        {state.billing_nominal !== null ? numberToCurrency(state.billing_nominal, 'Rp. ') : '-'}
                                        {/* <IconButton color="warning" size="small" onClick={() => { setIsBillingEdit(true) }}>
                                            <EditIcon />
                                        </IconButton> */}
                                    </>]] : [[
                                        <span>
                                            Billing Nominal
                                            {
                                                state.status === 'unpaid' && permissions['order-jhs.set-unpaid'] ?
                                                    <IconButton
                                                        color='warning'
                                                        onClick={() => {
                                                            setUnpaidEditData({
                                                                amount: state.billing_nominal!,
                                                                invoiceCode: state.invoice_code,
                                                            })
                                                            setUnpaidModal(true)
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    : null
                                            }
                                        </span>, <>
                                            <TextField
                                                name="billingState"
                                                InputProps={{
                                                    startAdornment: (<span style={{ paddingRight: 5, width: '35px' }}>Rp. </span>)
                                                }}
                                                value={numberToCurrency(billingState)}
                                                onChange={(e) => setBillingState(e.target.value)}
                                                variant="standard"
                                            />
                                            <IconButton color='primary' size="small" onClick={handleChangeBilling}>
                                                <CheckIcon />
                                            </IconButton>
                                        </>]] :
                                []
                            ),


                            ...(state.invoice_code ?
                                !isInvoiceCodeEdit ? [[
                                    <span>
                                        Invoice Code
                                        {
                                            state.billing_invoice_file_url ?
                                                <Button size='small' sx={{ textTransform: 'capitalize', ml: 1 }} variant='contained' color='primary' onClick={() => handleSeeBilling(state.billing_invoice_file_url)}>
                                                    Lihat Invoice
                                                </Button>
                                                : null
                                        }
                                        {
                                            state.payment_invoice_file_url ?
                                                <Button size='small' sx={{ textTransform: 'capitalize', ml: 1 }} variant='contained' color='primary' onClick={() => handleSeeBilling(state.payment_invoice_file_url)}>
                                                    Lihat Bukti Bayar
                                                </Button> :
                                                null
                                        }
                                    </span>, <>
                                        {state.invoice_code ? state.invoice_code : '-'}
                                        <IconButton color='warning' size="small" onClick={() => { setIsInvoiceCodeEdit(true) }}>
                                            <EditIcon />
                                        </IconButton>
                                    </>]] : [[
                                        <span>
                                            Invoice Code
                                            {
                                                state.billing_invoice_file_url ?
                                                    <Button size='small' sx={{ textTransform: 'capitalize', ml: 1 }} variant='contained' color='primary' onClick={() => handleSeeBilling(state.billing_invoice_file_url)}>
                                                        Lihat Invoice
                                                    </Button>
                                                    : null
                                            }
                                            {
                                                state.payment_invoice_file_url ?
                                                    <Button size='small' sx={{ textTransform: 'capitalize', ml: 1 }} variant='contained' color='primary' onClick={() => handleSeeBilling(state.payment_invoice_file_url)}>
                                                        Lihat Bukti Bayar
                                                    </Button> :
                                                    null
                                            }
                                        </span>, <>
                                            <TextField
                                                name="invoiceCodeState"
                                                value={invoiceCodeState}
                                                onChange={(e) => setInvoiceCodeState(e.target.value)}
                                                variant="standard"
                                            />
                                            <IconButton color='primary' size="small" onClick={handleChangeInvoiceCode}>
                                                <CheckIcon />
                                            </IconButton>
                                        </>]] :
                                []
                            ),

                            !isCodeEdit ? ['Work Code', <>
                                {state.work_code ? state.work_code : '-'}
                                <IconButton color='warning' size="small" onClick={() => { setIsCodeEdit(true) }}>
                                    <EditIcon />
                                </IconButton>
                            </>] : ['Work Code', <>
                                <TextField
                                    name="codeState"
                                    value={codeState}
                                    onChange={(e) => setCodeState(e.target.value)}
                                    variant="standard"
                                />
                                <IconButton color='primary' size="small" onClick={handleEditCode}>
                                    <CheckIcon />
                                </IconButton>
                            </>],
                            // ['Closing Code', `${state.closing_code || '-'}`],
                            // ['Unit Code', `${state.unit_code || '-'}`],
                            // ['Apartment', `${state.apartment_name || '-'}`],
                            ['Unit Details', `${state.apartment_name || '-'} | ${state.unit_code || '-'} | ${state.bedroom === 0 ? 'Studio' : `${state.bedroom}BR`} | Tw. ${state.tower}/${state.floor_level}/${state.unit_number}`],
                            // ['Unit Type', `${state.bedroom || '-'}BR`],
                            ...(
                                ['9', '2', 'quality-control', 'quality-control-listing', '3'].includes(state.activity) ?
                                    [
                                        ['Working Date & Time', `${state.datetime || '-'}`],
                                    ]
                                    : []
                            ),
                            ...(
                                ['fixing_cleaning', 'photo'].includes(state.activity) ?
                                    [
                                        ['Request Work Date', `${state.request_date ? dateFormat(state.request_date, 'DD/MM/YYYY') : '-'}`],
                                        ['Request Work Time', `${state.request_time ? state.request_time.slice(0, state.request_time.length - 3) : '-'}`],
                                    ] : []
                            ),
                            state.activity_name === 'Fixing and Cleaning' ? ['Type of Work', `${state.work_type_label || '-'}`] : [],
                            state.activity_name === 'Fixing and Cleaning' ? ['Paid By', state.paid_by ? getPaidBy(state.paid_by) : '-'] : [],
                            (state.activity_name === 'Fixing and Cleaning' || state.activity_name === 'Paket Perawatan Essential') && state.paid_by !== 'office' ? [`${state.activity_name !== 'Paket Perawatan Essential' ? 'Owner/Tenant Name' : 'Tenant Name'}`, `${state.paid_by === 'owner' ? state.owner_name || '' : state.tenant_name || ''}`] : [],
                            state.activity_name === 'Paket Perawatan Essential' ? ['Check-in Date', `${state.start_date ? dateFormat(state.start_date, 'DD-MM-YYYY') : '-'}`] : [],
                            state.activity_name === 'Paket Perawatan Essential' ? ['Selected Package', `${state.package_duration || '-'} Bulan`] : []
                        ]}
                    />

                    <ModalSection
                        title="Other Information"
                        xs={12}
                        md={6}
                        content={[
                            ...[
                                state.closing_code ?
                                    ['Closing Code',
                                        <a href={`/closing/${state.closing_code}`} target="_blank" rel="noopener noreferrer">{state.closing_code}</a>
                                    ]
                                    :
                                    []
                            ],
                            ['Order Date', `${dateFormat(state.created_at, 'DD/MM/YYYY HH:mm')}`],
                            ['Requester', `${state.requester_name || '-'}`],
                            ['Requester Division', `${state.requester_division || '-'}`],
                            ['No HP Requester',
                                state.requester_phone &&
                                <>
                                    {state.requester_phone}
                                    <IconButton href={`https://wa.me/62${state.requester_phone}`} size="small" target="_blank" rel="noopener noreferrer">
                                        <WhatsAppIcon />
                                    </IconButton>
                                </>
                            ],
                            [(
                                <>
                                    JHS Staff
                                    <Tooltip title={`JHS Staff`}>
                                        <IconButton color="primary" onClick={handleStaff}>
                                            <PersonAddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ), state.pic_name || '-'],
                            ...[
                                state.activity_name === 'Paket Perawatan Essential' ? []
                                    : ['Key Collection Point', `${state.key_collection_point || '-'}`]
                            ],
                            ['Notes', `${state.notes || '-'}`]
                        ]}
                    />
                    {state.activity === 'fixing_cleaning' &&
                        <Grid item xs={12}>
                            <Root>
                                <Paper className={classes.paper}>
                                    <JobDetailList
                                        id={state.id}
                                        permissions={permissions}
                                        jobs={state.jobs}
                                        onDeleteFile={() => {
                                            loadData()
                                        }}
                                        onMarkedDone={(id) => setNoteModal(id)}
                                    />
                                </Paper>
                            </Root>
                        </Grid>
                    }
                    {
                        state.activity === 'fixing_cleaning' &&
                        <Grid item xs={12}>
                            <Root>
                                <Paper className={classes.paper}>
                                    <ScheduleList
                                        id={params.id ? Number(params.id) : 0}
                                        data={state.schedules}
                                        jobList={state.jobs}
                                        loadData={loadData}
                                        permissions={permissions}
                                    />
                                </Paper>
                            </Root>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {
                            permissions['order-jhs.generate-pdf'] ?
                                <Button
                                    variant="contained"
                                    sx={{ float: 'right' }}
                                    onClick={handleGeneratePDF}
                                >
                                    Generate PDF
                                </Button> : null
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Root>
                            <Paper className={classes.paper}>
                                <LoadingScreen open={isFooterLoading} />

                                <Tabs
                                    value={activeTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleTab}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab label="Comment" value={0} />
                                    {/* <Tab label="Files" value={2} /> */}
                                    {permissions['activity.see-log'] &&
                                        <Tab label="Log List" value={3} />
                                    }
                                </Tabs>
                                <div className={classes.footer}>
                                    {footer}
                                </div>
                            </Paper>
                        </Root>
                    </Grid>

                    <CancelReasonModal
                        id={params.id ? Number(params.id) : 0}
                        open={cancelReasonModal}
                        onClose={(refresh: boolean) => {
                            setCancelReasonModal(false)
                            if (refresh) {
                                loadData()
                            }
                        }}
                    />

                    <NotesModal
                        onClose={() => setNoteModal('')}
                        onSubmit={handleMarkDone}
                        open={!!noteModal}
                    />
                </>
            </ModalDetail>
        </>
    );
}

export default JhsOrderModalDetail;
import React from "react";

import { Button, Theme } from "@mui/material";
import { generateStyle } from "../../_utils/DefaultStyle";

const useStyles = generateStyle((theme: Theme) => ({
    button: {
        fontWeight: "normal",
        marginRight: '0.5rem',
        marginTop: 10,
        marginBottom: 10,
        textTransform: 'none',
        padding: '8px 10px'
    },
    buttonInActiveType2: {
        marginBottom: 0,
        fontSize: 14,
        color: "#34516C",
        borderColor: "#C4D4E3",
        borderRadius: 32,
        paddingBottom: 3,
        paddingTop: 5,
        paddingLeft: 10,
        paddingRight: 10,
        minWidth: 0,
    },
    buttonActiveType2: {
        marginBottom: 0,
        fontSize: 14,
        fontWeight: 'bold',
        color: "#0080FE",
        borderColor: "#0080FE",
        borderRadius: 32,
        paddingBottom: 3,
        paddingTop: 5,
        paddingLeft: 10,
        paddingRight: 10,
        minWidth: 0,
    },
}), "ButtonRadio"
);

interface ButtonRadioProps {
    current: string
    value: string
    label: string
    name: string
    onClick: (name: string, value: any) => void
    radioStyle?: 2
    radioWith?: string
}

const ButtonRadio = (props: ButtonRadioProps) => {
    const { Root, classes } = useStyles();
    if (props.current !== props.value) {
        return (
            <Root>
                <Button
                    variant="outlined"
                    size="large"
                    className={`${classes.button} ${props.radioStyle === 2 ? classes.buttonInActiveType2 : ''}`}
                    value={props.value}
                    name={props.name}
                    onClick={() => props.onClick(props.name, props.value)}
                    style={{
                        width: props.radioStyle === 2 && props.radioWith ? props.radioWith : undefined,
                    }}
                >
                    {props.label}
                </Button>
            </Root>
        );
    } else {
        return (
            <Root>
                <Button
                    variant={props.radioStyle === 2 ? 'outlined' : 'contained'}
                    size="large"
                    className={`${classes.button} ${props.radioStyle === 2 ? classes.buttonActiveType2 : ''}`}
                    value={props.value}
                    name={props.name}
                    onClick={() => props.onClick(props.name, props.value)}
                    color="primary"
                    style={{
                        backgroundColor: props.radioStyle === 2 ? "#E5F4FF" : undefined,
                        width: props.radioStyle === 2 && props.radioWith ? props.radioWith : undefined,
                    }}
                >
                    {props.label}
                </Button>
            </Root>
        );
    }
};

export default ButtonRadio;

import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'

/**
 * Components
 */
import MuiDatePicker from '../../../_components/MuiDatePicker'
import TextArea from '../../../_components/_form/TextArea'
import LoadingScreen from '../../../_components/LoadingScreen'

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, renderErrorButton, renderSuccessButton } from '../../../_utils/Helper'
import { format, isValid } from 'date-fns'
import { ITodoList } from './TodoListTable'
import MuiTimePicker from '../../../_components/MuiTimePicker'

interface IState {
    comment: string,
    due_date: Date | null
    due_time: Date | null
}

const initialState = {
    comment: '',
    due_date: new Date(),
    due_time: null
}

interface IProps {
    open: boolean,
    onClose: (refresh: boolean) => void
    defaultState: ITodoList | null
}

const ChangeDueDateModal = (props: IProps) => {
    const [state, setState] = useState<IState>(initialState)
    const [isLoading, setIsLoading] = useState(false)

    const handleChangeDate = (date: Date | null, name: string) => {
        setState(prev => ({
            ...prev,
            [name]: date
        }))
    }

    const handleChangeReason = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(prev => ({
            ...prev,
            comment: e.target.value
        }))
    }

    const validateDateTime = (date: Date | null, time: Date | null) => {
        let errors: string[] = []
        if (date !== null) {
            if (!isValid(date)) {
                errors.push('Date')
            }
        }

        if (time !== null) {
            if (!isValid(time)) {
                errors.push('Time')
            }
        }

        if (errors.length) {
            renderErrorButton(`${errors.join(' & ')} Tidak Valid`, 'Error Data')
        }

        return !errors.length
    }

    const handleSubmit = () => {
        const isValid = validateDateTime(state.due_date, state.due_time)
        if (isValid) {
            setIsLoading(true)
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/ticket-todolist/change-due-date`, {
                ...state,
                id: props.defaultState?.id,
                due_date: state.due_date ? format(state.due_date as Date, 'yyyy-MM-dd') : null,
                due_time: state.due_time ? format(new Date(state.due_time as Date), 'HH:mm') : null,
            })
                .then(res => res.data)
                .then(res => res && [renderSuccessButton('Due date Changed!'), props.onClose(true)])
                .catch(generalErrorHandler)
                .finally(() => setIsLoading(false))
        }
    }

    useEffect(() => {
        if (props.open) {
            if (props.defaultState) {
                setState(prev => ({
                    ...prev,
                    due_date: new Date(props.defaultState ? props.defaultState.due_date : ''),
                    due_time: props.defaultState?.due_time ? new Date(`${props.defaultState.due_date}T${props.defaultState.due_time}`) : null,

                }))
            }
        } else {
            setState(initialState)
        }

    }, [props.defaultState, props.open])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
        >
            <LoadingScreen open={isLoading} />
            <DialogTitle>
                Change Due Date
            </DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <MuiDatePicker
                            label='New Due Date'
                            value={state.due_date}
                            clearable
                            fullWidth
                            onChange={(date: Date | null) => handleChangeDate(date, 'due_date')}
                            onClear={() => handleChangeDate(null, 'due_date')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MuiTimePicker
                            label='New Due Time'
                            value={state.due_time}
                            clearable
                            fullWidth
                            onChange={(date: Date | null) => handleChangeDate(date, 'due_time')}
                            onClear={() => handleChangeDate(null, 'due_time')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextArea
                            name='reason'
                            label='Change Due Date Reason'
                            onChange={handleChangeReason}
                            value={state.comment}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            onClick={handleSubmit}
                            sx={{ float: 'right' }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ChangeDueDateModal
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import DefaultAxios from '../../_utils/DefaultAxios';
import { AutocompleteRenderInputParams, CircularProgress, IconButton } from '@mui/material';

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from '@mui/lab/Autocomplete';
import TextFieldND from '../_newDesignComponents/_form/TextFieldND';
import TextFieldND2 from '../_formElements/_newDesign2/TextFieldND2';

export interface IAutoCompleteOption {
    label: string;
    id: number;
    [key: string]: any;
}

interface Props {
    label?: string;
    url: string;
    name: string;
    initialQuery?: string; //optional
    onChange: (name: string, option: IAutoCompleteOption) => void;
    onInputChange?: (e: any, name: string) => void;
    onClear?: () => void;
    disabled?: boolean;
    errorText?: string;
    size?: 'small' | 'medium';
    iconSearch?: boolean;
    placeholder?: string;
    param?: string
    key?: number
    icon?: any;
    // options?: any;
    // getOptionLabel?: any;
    extraParams?: {
        [key: string]: any
    },
    /**
     * clerable false need onClear props
     */
    clearable?: boolean
    withoutQuery?: boolean
    newDesign?: boolean | 2
}

let timeout: any;
const debounce = (func: () => void, wait: number = 1000) => {
    return function () {
        clearTimeout(timeout);
        timeout = setTimeout(func, wait);
    }
}

export default function AsyncAutoComplete(props: Props) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<IAutoCompleteOption[]>([]);
    const [query, setQuery] = useState("");
    const loading = open && options.length === 0;
    const url_to_fetch = props.url;

    // Fetching Data
    useEffect(() => {
        if (!open) {
            setOptions([]);
        } else {
            loadData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, query, url_to_fetch]);

    useEffect(() => {
        if (typeof props.initialQuery !== 'undefined') {
            setQuery(props.initialQuery);
        }
    }, [props.initialQuery]);

    const loadData = debounce(() => {
        let param = (props.param && props.param !== '') ? props.param : 'search'
        DefaultAxios.get(url_to_fetch, { params: { [param]: query, ...props.extraParams } })
            .then(res => {
                setOptions(res.data);
            })
    })

    const clearQuery = () => {
        props.onClear && props.onClear()
        setQuery('')
    }

    const renderInput = (params: AutocompleteRenderInputParams) => {
        const Input = props.newDesign ? props.newDesign === 2 ? TextFieldND2 : TextFieldND : TextField

        return (
            <Input
                {...params}
                label={props.label}
                fullWidth
                variant="outlined"
                placeholder={props.placeholder}
                autoComplete='off'
                onChange={(e) => {
                    if (props.onInputChange) {
                        props.onInputChange(e, props.name);
                    }
                    setQuery(e.target.value);
                }}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: (props.iconSearch || props.icon) ? (
                        <React.Fragment>
                            {props.iconSearch ? <SearchIcon style={{ color: "#9F9F9F" }} /> : (props.icon ? props.icon : null)}
                        </React.Fragment>
                    ) : undefined,
                    ...(props.clearable && !loading && query !== '' && !props.disabled ?
                        {
                            style: { paddingRight: 9 },
                            endAdornment: (
                                <IconButton
                                    onClick={clearQuery}
                                    size='small'
                                >
                                    <ClearIcon />
                                </IconButton>
                            )
                        } : {}
                    )
                }}
                error={!!props.errorText}
                helperText={props.errorText}
            />
        )
    }

    return (
        <Autocomplete
            // multiple
            size={props.size}
            disabled={props.disabled}
            style={{ flexGrow: 1 }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionLabel={option => option.label}
            options={options}
            loading={loading}
            inputValue={query}
            filterOptions={options => options}
            onChange={(e: any, value: any) => {
                props.onChange(props.name, value);
                if (props.withoutQuery !== undefined && props.withoutQuery) {
                    setQuery('')
                } else {
                    setQuery(value.label);
                }
            }}
            value={null}
            popupIcon={loading ? <CircularProgress color="inherit" size={20} /> : null}
            renderInput={renderInput}
        />
    )
}
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Switch, TextField, Theme } from '@mui/material'
import React from 'react'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete'
import { numberToCurrency } from '../../../_utils/Helper'
import { IValidationErrors } from '../../../_utils/Validation'
import { StatementFormState } from '../StatementList'
import { generateStyle } from '../../../_utils/DefaultStyle'

interface StatementAssignFormProps {
    isOpen: boolean
    onClose: () => void
    state: StatementFormState
    setState: React.Dispatch<React.SetStateAction<StatementFormState>>
    error: IValidationErrors
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleAutocompleteChange: (name: string, value: IAutoCompleteOption) => void
    handleAutocompleteInputChange: (e: any, name: string) => void
    handleSubmit: () => void
}

const StatementAssignForm = ({
    state,
    setState,
    error,
    handleChange,
    handleAutocompleteChange,
    handleAutocompleteInputChange,
    handleSubmit,
    ...props
}: StatementAssignFormProps) => {
    const { Root, classes } = useStyles()

    return (
        <Dialog
            open={props.isOpen}
            // open={true}
            onClose={props.onClose}
            fullWidth
            maxWidth="md"
        >
            <Root>
                <DialogTitle>Form Assign</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className={classes.switchContainer}>
                                <div className={classes.switchLabelContainer}>
                                    <span className={classes.switchLabel}>Transaksi Lainnya</span>
                                </div>
                                <Switch
                                    checked={!!state.is_other}
                                    onChange={() => setState(prev => ({ ...prev, is_other: prev.is_other === 1 ? 0 : 1 }))}
                                />
                            </div>
                        </Grid>
                        {
                            state.type === 'DB' && state.is_other === 0 &&
                            <Grid item xs={12}>
                                <TextField
                                    label="Jenis Transaksi"
                                    name="transaction_type"
                                    value={state.transaction_type}
                                    onChange={handleChange}
                                    variant="outlined"
                                    select
                                    fullWidth
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={!!error.transaction_type}
                                    helperText={error.transaction_type}
                                >
                                    <option value="" key="default" disabled>Pilih Jenis Transaksi</option>
                                    <option value="rent_owner">Sewa Owner</option>
                                    <option value="deposit_owner">Deposit Owner</option>
                                    <option value="deposit_jendela">Deposit Jendela</option>
                                    <option value="commission_jendela">Komisi Jendela</option>
                                    <option value="commission_cobroke">Komisi Cobroke</option>
                                </TextField>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Tipe"
                                name="type"
                                value={state.type === "CR" ? "Credit" : "Debit"}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Tanggal"
                                name="date"
                                value={state.date}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Keterangan"
                                name="trailer"
                                value={state.trailer}
                                disabled
                                multiline
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Nominal"
                                name="amount"
                                value={numberToCurrency(state.amount, 'Rp')}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                url={process.env.REACT_APP_API_URL + '/admin/autocomplete/closing'}
                                label="Pilih Transaksi"
                                name="closing_id"
                                initialQuery={state.closing_label}
                                onChange={handleAutocompleteChange}
                                onInputChange={handleAutocompleteInputChange}
                                errorText={error.closing_id}
                            />
                        </Grid>

                        {
                            state.is_other ?
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Notes"
                                        name="description"
                                        value={state.description}
                                        multiline
                                        onChange={handleChange}
                                        error={!!error.description}
                                        helperText={error.description}
                                    />
                                </Grid>
                                : null}


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Root>
        </Dialog>
    )
}

const useStyles = generateStyle((theme: Theme) =>
({
    switchContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    switchLabelContainer: {

    },
    switchLabel: {

    },
}), "StatementAssignForm"
);

export default StatementAssignForm

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Theme, Dialog, Slide, Switch, Toolbar, IconButton, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import DataTable from '../../_components/_dataTable/DataTable';
import ApartmentReviewForm from './ApartmentReviewForm';
import LoadingScreen from '../../_components/LoadingScreen';
import moment from 'moment';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    review_id?: string
}
interface IParams extends Record<string, string | undefined> {
    review_id?: string
}

const useStyles = generateStyle((theme: Theme) => ({
    actionContainer: {
        display: 'flex'
    },
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    notes_list: {
        maxWidth: '500px',
        lineBreak: 'anywhere',
        whiteSpace: 'pre-line'
    }
}), "ApartmentReview_List"
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ApartmentReviewList = (props: IProps) => {
    const { Root, classes } = useStyles();
    const DT_API_URL = process.env.REACT_APP_API_URL + '/apartment-review';

    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [selectedId, setSelectedId] = useState<string>('');
    const [modalState, setModalState] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams<IParams>()
    const navigate = useNavigate();
    const handleOpen = () => setModalState(true);
    const handleClose = () => {
        setSelectedId('');
        setModalState(false)
        window.history.pushState([], 'Apartment Review', `/apartment-review/`);
    }

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Apartment Review Form
                </Typography>
            </Toolbar>
        )
    }

    // const openModalCreate = () => {
    //     setSelectedId('');
    //     handleOpen();
    // }


    const submitSuccess = () => {
        handleClose();
        setSelectedId('');
        navigate(`/apartment-review/`)
        // window.history.pushState([], 'Apartment Notes', `/apartment-notes/`);
        setReDrawDatatable(new Date().getTime());
    }

    useEffect(() => {
        if (params.review_id && typeof params.review_id === 'string') {
            setSelectedId(params.review_id)
            handleOpen()
        }
    }, [params]);

    const handleApproval = (e: React.ChangeEvent<{ checked: boolean }>, columnName: string, setRows: Function, rows: any[], id: string) => {
        let checked = e.target.checked
        let data = checked ? 1 : 0

        // const data = {
        //   [columnName]: checked ? 1 : 0,
        //   id: id
        // }
        renderWarningButton()
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.put(`${DT_API_URL}/${id}/toggle`, { approval: data })
                        .then(res => {
                            const newRows = rows.map((element: any) => {
                                if (element.id === id) element[columnName] = checked ? 1 : 0
                                return element
                            })
                            setRows(newRows)
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })

    }

    const handleDelete = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin menghapus data ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${DT_API_URL}/${id}`)
                        .then(res => {
                            setReDrawDatatable(new Date().getTime());
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                open={modalState}
                onClose={handleClose}
                closeAfterTransition
                TransitionComponent={Transition}
                fullWidth
                maxWidth="lg"
            >
                <FormToolbar></FormToolbar>
                <ApartmentReviewForm
                    onSubmitSuccess={submitSuccess}
                    reviewId={selectedId}
                />
            </Dialog>
            <DataTable
                reDraw={reDrawDatatable}
                url={DT_API_URL}
                columns={[
                    {
                        name: 'apartment_name',
                        label: 'Apartment Name',
                        filterable: true,
                        style: { minWidth: '200px' }
                    },
                    {
                        name: 'name',
                        label: 'Reviewer Name',
                        filterable: true,
                        style: { minWidth: '200px' }
                    },
                    {
                        name: 'rate',
                        label: 'Rating',
                        filterable: true,
                        type: 'string',
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '1',
                                value: '1'
                            },
                            {
                                key: '2',
                                value: '2'
                            },
                            {
                                key: '3',
                                value: '3'
                            },
                            {
                                key: '4',
                                value: '4'
                            },
                            {
                                key: '5',
                                value: '5'
                            },
                            {
                                key: '6',
                                value: '6'
                            },
                            {
                                key: '7',
                                value: '7'
                            },
                            {
                                key: '8',
                                value: '8'
                            },
                            {
                                key: '9',
                                value: '9'
                            },
                            {
                                key: '10',
                                value: '10'
                            },
                        ],
                        render: (data: number) => {
                            return data;
                        }
                    },
                    {
                        name: 'review',
                        label: 'Review',
                        filterable: true,
                        style: { minWidth: '500px' },
                        render: (data: string, row: any) => {
                            return (<div className={classes.notes_list}>{data}</div>);
                        }
                    },
                    {
                        name: 'date',
                        label: 'Date',
                        type: 'date',
                        filterable: true,
                        style: { minWidth: '200px' },
                        render: (data: any, row: any) => {
                            return (
                                `${moment(data).format("DD MMMM YYYY")}`
                            )
                        }
                    },
                    {
                        name: 'approval',
                        label: 'Approval',
                        filterable: true,
                        type: 'string',
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '0',
                                value: 'Unapprove'
                            },
                            {
                                key: '1',
                                value: 'Approved'
                            },
                        ],
                        render: (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
                            return (<Switch
                                checked={row[columnName] === 1}
                                onChange={(e) => handleApproval(e, columnName, setRows, rows, row.id)}
                                color="secondary"
                            />)
                        }
                    },
                    {
                        name: 'EXTRA',
                        label: 'Action',
                        sortable: false,
                        render: (row: any) => {
                            return (
                                <div className={classes.actionContainer}>
                                    <IconButton
                                        color="primary"
                                        component="span"
                                        onClick={() => navigate(`/apartment-review/${row.id}`)}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        color="secondary"
                                        component="span"
                                        onClick={() => handleDelete(row.id)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            );
                        }
                    }
                ]}
            />
        </Root>
    );
}

export default ApartmentReviewList;
import React, { useReducer, useEffect, useState } from 'react';

import { Dialog, DialogTitle, Theme, OutlinedInput, FormControl, InputLabel, Grid, Button, DialogContent } from '@mui/material';
import DefaultAxios from '../../_utils/DefaultAxios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AxiosResponse } from 'axios';
import LoadingScreen from '../LoadingScreen';
import { generateStyle } from '../../_utils/DefaultStyle';

const SwalContainer = withReactContent(Swal)

interface IAction {
    name: string;
    value: string | IState;
}

interface IState {
    id: number,
    name: string,
    email: string,
    phone: string,
    address: string,
    // ktp_number: string,
    // npwp_number: string,
    // nik: string,
    bank_number: string,
    bank_type: string,
    bank_behalf_name: string,
    bank_branch: string,
    ktp_file: File | null,
    ktp_url: string,
    ktp_filename: string
}

interface IProps {
    id?: number;
    isOpen: boolean;
    handleClose: Function;
    permissions: any;
}

const useStyles = generateStyle((theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mainModal: {
        padding: "20px",
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    buttonContainer: {
        marginBottom: theme.spacing(2),
        "& button": {
            marginLeft: theme.spacing(1)
        }
    }
}), "Owner_Modal"
);

const initialState = {
    id: 0,
    name: "",
    email: "",
    phone: "",
    address: "",
    // ktp_number: "",
    // npwp_number: "",
    // nik: "",
    bank_number: "",
    bank_type: "",
    bank_behalf_name: "",
    bank_branch: "",
    ktp_file: null,
    ktp_url: '',
    ktp_filename: ''
}

export default function OwnerModal(props: IProps) {
    const { Root, classes } = useStyles();
    const API_URL: string = process.env.REACT_APP_API_URL!;

    const handleClose = () => {
        props.handleClose();
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (['phone', 'bank_number'].includes(action.name)) {
            const phone = action.value as string

            return {
                ...state,
                [action.name]: phone.replace(/[^\d]/g, '')
            }
        } else if (action.name === 'SET_INIT_STATE') {
            const datas = action.value as IState

            return {
                ...state,
                id: typeof props.id !== "undefined" ? props.id : 0,
                name: datas.name,
                phone: datas.phone,
                email: datas.email,
                address: datas.address,
                bank_type: datas.bank_type,
                bank_behalf_name: datas.bank_behalf_name,
                bank_number: datas.bank_number,
                bank_branch: datas.bank_branch,
                ktp_file: null,
                ktp_url: datas.ktp_url,
                ktp_filename: datas.ktp_filename
            }
        } else if (action.name === 'RESET_STATE') {
            return initialState;
        } else {
            return {
                ...state,
                [action.name]: action.value
            }
        }
    }

    const [isLoading, setIsLoading] = useState(false);
    const [inputState, setInputValue] = useReducer(inputReducer, initialState);
    const handleInputChange = (e: any) => {
        const target = e.target;
        let name = target.name;
        let value = target.value;

        if (name === 'ktp_file') {
            if (target && target.files) {
                value = target.files[0];
            } else {
                value = null;
            }
        }

        setInputValue({ name, value })
    }

    useEffect(() => {
        if (typeof props.id !== 'undefined' && props.isOpen) {
            DefaultAxios.get(`${API_URL}/landlord/${props.id}`)
                .then(res => {
                    const response_data = res.data as IState;
                    setInputValue({ name: "SET_INIT_STATE", value: response_data });
                })
        } else {
            setInputValue({ name: 'RESET_STATE', value: "" });
        }
    }, [props.id, API_URL, props.isOpen])

    const onSubmit = () => {
        let axiosResponse: Promise<AxiosResponse> | null = null;

        const fd = new FormData();

        for (let [key, value] of Object.entries(inputState)) {
            if (value) {
                if (key === 'ktp_file') {
                    fd.append(key, value as File);
                } else {
                    fd.append(key, value as string);
                }
            }
        }

        if (typeof props.id !== "undefined") {
            fd.append('_method', 'patch');
            axiosResponse = DefaultAxios.post(`${API_URL}/landlord/${inputState.id}`, fd)
        } else {
            axiosResponse = DefaultAxios.post(`${API_URL}/landlord`, fd)
        }

        setIsLoading(true);
        axiosResponse.then(res => {
            SwalContainer.fire({
                title: "Success",
                html: "Data berhasil ditambahkan",
                icon: 'success',
                onClose: () => {
                    handleClose();
                }
            })
        })
            .catch(error => {
                if (typeof error.response.status === 'undefined') {
                    SwalContainer.fire({
                        title: "Script Error",
                        html: "Error pada script. Harap hubungi Admin",
                        icon: 'error',
                    });
                } else if (error.response.status === 422) {
                    let error_text = `<p>${error.response.data.message}</p>`;

                    if (typeof error.response.data.errors !== 'undefined') {
                        const error_lists = error.response.data.errors;
                        for (var k in error_lists) {
                            error_text += `<p>${error_lists[k]}</p>`;
                        }
                    }

                    SwalContainer.fire({
                        title: "Validasi Error",
                        html: error_text,
                        icon: 'error',
                    });
                } else if (error.response.status === 500) {
                    SwalContainer.fire({
                        title: "Server Error",
                        html: "Error pada server. Harap hubungi Admin",
                        icon: 'error',
                    });
                }

                return false;
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    return (
        <div>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.isOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="lg"
                scroll="body"
            // className={classes.mainModal}
            >
                <Root>
                    <DialogTitle>
                        {typeof props.id !== "undefined" ? `Edit Owner` : `Add Owner`}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>Name</InputLabel>
                                    <OutlinedInput
                                        name="name"
                                        value={inputState.name}
                                        onChange={handleInputChange}
                                    // TODO: might break
                                    // labelWidth={60}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>Phone</InputLabel>
                                    <OutlinedInput
                                        name="phone"
                                        value={inputState.phone}
                                        onChange={handleInputChange}
                                    // TODO: might break
                                    // labelWidth={60}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>Email</InputLabel>
                                    <OutlinedInput
                                        name="email"
                                        value={inputState.email}
                                        onChange={handleInputChange}
                                    // TODO: might break
                                    // labelWidth={60}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>Address</InputLabel>
                                    <OutlinedInput
                                        name="address"
                                        value={inputState.address}
                                        onChange={handleInputChange}
                                    // TODO: might break
                                    // labelWidth={60}
                                    />
                                </FormControl>
                            </Grid>

                            {/* <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>
                                    Nomor KTP
                                    </InputLabel>
                                <OutlinedInput
                                    name="ktp_number"
                                    value={inputState.ktp_number}
                                    onChange={handleInputChange}
                                    labelWidth={60}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>
                                    Nomor NPWP
                                    </InputLabel>
                                <OutlinedInput
                                    name="npwp_number"
                                    value={inputState.npwp_number}
                                    onChange={handleInputChange}
                                    labelWidth={60}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>
                                    Nomor NIK
                                    </InputLabel>
                                <OutlinedInput
                                    name="nik"
                                    value={inputState.nik}
                                    onChange={handleInputChange}
                                    labelWidth={60}
                                />
                            </FormControl>
                        </Grid>
                     */}

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>
                                        Nomor Rekening Bank
                                    </InputLabel>
                                    <OutlinedInput
                                        name="bank_number"
                                        value={inputState.bank_number}
                                        onChange={handleInputChange}
                                    // TODO: might break
                                    // labelWidth={60}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>
                                        Nama Pemilik Rekening Bank
                                    </InputLabel>
                                    <OutlinedInput
                                        name="bank_behalf_name"
                                        value={inputState.bank_behalf_name}
                                        onChange={handleInputChange}
                                    // TODO: might break
                                    // labelWidth={60}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>
                                        Jenis Bank
                                    </InputLabel>
                                    <OutlinedInput
                                        name="bank_type"
                                        value={inputState.bank_type}
                                        onChange={handleInputChange}
                                    // TODO: might break
                                    // labelWidth={60}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>
                                        Cabang Bank
                                    </InputLabel>
                                    <OutlinedInput
                                        name="bank_branch"
                                        value={inputState.bank_branch}
                                        onChange={handleInputChange}
                                    // TODO: might break
                                    // labelWidth={60}
                                    />
                                </FormControl>
                            </Grid>

                            {/* {
                            props.permissions['closing.upload-owner-file']
                                ? <>
                                    <Grid item xs={12}>
                                        <h3>Upload Ktp</h3>
                                    </Grid>
            
                                    <>{
                                        inputState.ktp_url
                                            ? <Grid item xs={12}>
                                                <img src={inputState.ktp_url} alt={inputState.ktp_filename} width="300px" height="150px" />
                                            </Grid>
                                            : null
                                    }</>
            
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>
                                                File Name
                                                </InputLabel>
                                            <OutlinedInput
                                                name="ktp_filename"
                                                value={inputState.ktp_filename}
                                                onChange={handleInputChange}
                                                labelWidth={60}
                                            />
                                        </FormControl>
                                    </Grid>
            
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <OutlinedInput
                                                type="file"
                                                name="ktp_file"
                                                onChange={handleInputChange}
                                                labelWidth={60}
                                            />
                                        </FormControl>
                                    </Grid>
                                </>
                                : null
                        } */}

                            <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
                                <Grid>
                                    <Button variant="contained" color="primary" onClick={onSubmit}>
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button variant="contained" color="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Root>
            </Dialog>
        </div>
    );
}
import { Grid, TextField, Button, FormControlLabel, Switch, MenuItem } from '@mui/material'
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

/* 
* Components
*/
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'
import TextArea from '../../../_components/_form/TextArea'
import LoadingScreen from '../../../_components/LoadingScreen'

/* 
* Utils
*/
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, queryParamsToParams, renderQuestionButton, renderSuccessButton } from '../../../_utils/Helper'
import moment from 'moment'

/* 
* Interfaces
*/
import { IDefaultState, IDetail, KCPOption } from '../JhsOrderForm'

interface IProps {
    setFormChanged: Dispatch<SetStateAction<boolean>>
    isEdit: boolean
    editData: any
}
interface IState extends IDefaultState {
    use_closing: boolean,
    closing_id: string,
    closing_code: string,
    unit_id: string,
    unit_code: string,
    pic_id: string,
    pic_name: string,
    date: string | null,
    apartment_id: string
}

const initialState = {
    use_closing: true,
    closing_id: '',
    closing_code: '',
    unit_id: '',
    unit_code: '',
    pic_id: '',
    apartment_id: '',
    pic_name: '',
    key_collection_point_option: '' as KCPOption,
    key_collection_point: '',
    notes: "",
    activity: '3',
    request_date: null,
    request_time: null,
    date: null
}

const initialDetail = {
    start_date: "",
    end_date: "",
    unit_code: "",
    apartment_id: "",
    apartment_name: "",
    bedroom: null,
    tower: "",
    tenant_name: "",
    floor_level: "",
    unit_number: "",
    owner_name: "",
    customer_name: ""
}

const MaintenanceForm = (props: IProps) => {
    const navigate = useNavigate()
    const [state, setState] = useState<IState>(initialState)
    const [detail, setDetail] = useState<IDetail>(initialDetail)
    const [queryParams, setQueryParams] = useState<any>({})

    const [loading, setLoading] = useState(false)

    const fetchData = (type: string, id: string) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/activity/${type === 'closing' ? 'closing' : 'unit'}`, { params: { id } })
            .then(res => {
                setState(prev => ({ ...prev, apartment_id: res.data.apartment_id }))
                setDetail(res.data)
            })
            .catch(err => generalErrorHandler(err))
    }

    const handleChange = (e: any) => {
        props.setFormChanged(true)
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeUnit = (name: string, value: any) => {
        props.setFormChanged(true)
        setState(prev => {
            fetchData('unit', value.id)
            return {
                ...prev,
                unit_id: value.id
            }
        })
    }

    const handleSwitch = (e: any) => {
        if (e.target.checked) {
            setState({
                ...state,
                use_closing: e.target.checked
            })
        } else {
            setState({
                ...state,
                use_closing: e.target.checked,
                closing_id: ''
            })
        }
    }

    const handleChangeClosing = (name: string, value: any) => {
        setState(prev => {
            fetchData('closing', value.id)
            return {
                ...prev,
                closing_id: value.id,
                closing_code: value.label,
                unit_id: value.unit_id
            }
        })
    }

    const gotoPrevious = () => {
        if (queryParams && queryParams.ticket_id) {
            return navigate(`/ticket/list/${queryParams.ticket_id}`)
        }

        const queryString = localStorage.getItem('queryParams')
        navigate(queryString ? `/jhs-order?${queryString}` : '/jhs-order')
    }


    const handleSubmit = () => {
        renderQuestionButton('Pastikan data yang anda masukkan Benar.', 'Apakah anda yakin ingin submit?')
            .then(res => {
                if (res.value) {
                    setLoading(true)
                    delete state.request_date
                    delete state.request_time
                    DefaultAxios[props.isEdit ? 'patch' : 'post'](`${process.env.REACT_APP_API_URL}/activity${props.isEdit ? `/${props.editData.id}/jhs` : ''}`, {
                        ...state,
                        ...(
                            queryParams ?
                                queryParams :
                                {}
                        ),
                        key_collection_point_option: null,
                        key_collection_point: state.key_collection_point_option === 'Others' ? state.key_collection_point : state.key_collection_point_option,
                        date: state.date ? moment(state.date).format('YYYY-MM-DD HH:mm') : null
                    })
                        .then(res => {
                            if (res) {
                                renderSuccessButton('Submit Success!', () => {
                                    if (props.isEdit) {
                                        return navigate(`/jhs-order/${props.editData.id ? props.editData.id : ''}`)
                                    }
                                    gotoPrevious()
                                })
                            }
                        })
                        .catch(err => generalErrorHandler(err))
                        .finally(() => setLoading(false))
                }
                if (res.dismiss) {
                    return null
                }
            })
            .catch(err => generalErrorHandler(err))

    }

    const assignExistData = (prev: any, current: any) => {
        let result: any = {}
        for (let key in prev) {
            if (key === 'use_closing') {
                result[key] = !!(current['closing_code'])
                if (!!current['closing_code']) {
                    result['closing_code'] = current['closing_code']
                }
            } else if (key === 'bedroom') {
                result[key] = current[key] ?? null
            }
            else {
                result[key] = current[key] ? current[key] : null
            }
        }
        result['activity'] = '3'
        return result
    }

    const handleDateChanged = (value: any) => {
        setState(prev => ({
            ...prev,
            date: value
        }))
    }

    useEffect(() => {
        setQueryParams(queryParamsToParams())

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (props.isEdit || queryParamsToParams()?.ticket_id) {
            setState(prev => assignExistData(prev, props.editData))
            setDetail(prev => assignExistData(prev, props.editData))
        }
    }, [props.editData, props.isEdit])

    useEffect(() => {
        if (props.isEdit) {
        }
        // eslint-disable-next-line
    }, [state.request_date, state.request_time])

    return (
        <>
            {
                loading &&
                <LoadingScreen open={loading} fullScreen />
            }
            <Grid container spacing={2}>
                {
                    queryParamsToParams()?.ticket_id ?
                        null :
                        <Grid item xs={12} sx={{ mt: 1 }}>
                            <FormControlLabel
                                style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 0 }}
                                label="Gunakan Closing"
                                labelPlacement="start"
                                onChange={handleSwitch}
                                checked={state.use_closing}
                                control={<Switch />}
                            />
                        </Grid>
                }
                {
                    state.use_closing &&
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            disabled={queryParamsToParams()?.ticket_id}
                            name='closing_code'
                            url={`${process.env.REACT_APP_API_URL}/admin/autocomplete/closing`}
                            label="Closing Code"
                            onChange={handleChangeClosing}
                            initialQuery={state.closing_code || ''}
                            clearable
                            onClear={() => [setState(prev => ({ ...prev, closing_id: '', closing_code: '', unit_id: '', apartment_id: '' })), setDetail(initialDetail)]}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            name='unit_code'
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/unit`}
                            label="Unit Code"
                            initialQuery={detail.unit_code}
                            disabled={state.use_closing}
                            onChange={handleChangeUnit}
                            clearable={!state.use_closing}
                            onClear={() => [setState(prev => ({ ...prev, unit_id: '', apartment_id: '' })), setDetail(initialDetail)]}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Apartment Name"
                        value={detail.apartment_name}
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Unit Detail"
                        value={`Tw. ${detail.tower || ''} / ${detail.floor_level} / ${detail.unit_number}`}
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Unit Type"
                        fullWidth
                        disabled
                        value={detail.bedroom === null ? '' : detail.bedroom ? `${detail.bedroom}BR` : 'Studio'}
                    />
                </Grid>
                <Grid item xs={12}>
                    {/* TODO: might break */}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            ampm={false}
                            // variant="inline"
                            label="Working Date & Time"
                            inputFormat="dd/MM/yyyy HH:mm"
                            value={state.date}
                            // inputVariant='outlined'
                            // onChange={newDate => setDate(newDate)}
                            onChange={(date: any) => handleDateChanged(date)}
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    variant="outlined"
                                    fullWidth
                                    label={props.label}
                                    // KeyboardButtonProps={{
                                    //     'aria-label': 'change date',
                                    // }}
                                    placeholder={"dd/MM/yyyy HH:mm"}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        label='PIC'
                        name='pic'
                        initialQuery={state.pic_name ? state.pic_name : ''}
                        onChange={(e: any, value: any) => {
                            setState(prev => ({
                                ...prev,
                                pic_id: value.id,
                                pic_name: value.label
                            }))
                        }}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/user/active-staff`}
                        clearable
                        onClear={() => setState(prev => ({ ...prev, pic_id: '' }))}
                    />
                </Grid>
                {
                    state.key_collection_point_option === 'Others' ?
                        <Grid item xs={12}>
                            <TextField
                                label="Key Collection Point"
                                fullWidth
                                name="key_collection_point"
                                placeholder='Lokasi - Nama - No. HP atau Jendela360'
                                value={state.key_collection_point}
                                onChange={handleChange}
                            />
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <TextField
                                select
                                label="Key Collection Point"
                                name="key_collection_point_option"
                                value={state.key_collection_point_option}
                                fullWidth
                                onChange={handleChange}
                            >
                                <MenuItem value="" disabled>-- Select Key Collection Point --</MenuItem>
                                <MenuItem value="Kantor Jendela360">Kantor Jendela360</MenuItem>
                                <MenuItem value="Others">Others</MenuItem>
                            </TextField>
                        </Grid>
                }
                <Grid item xs={12}>
                    <TextArea
                        label="Notes"
                        name='notes'
                        value={state.notes}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {props.isEdit ? 'Update Order' : 'Submit'}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default MaintenanceForm
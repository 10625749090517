import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, Grid, Button } from '@mui/material'
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'
import LoadingScreen from '../../../_components/LoadingScreen'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../../_utils/Helper'


interface IProps {
    open: boolean
    onClose: (refresh: boolean) => void
    id: number,
    initialPICName?: string
}

const ChangePICModal = (props: IProps) => {
    const [pic, setPic] = useState<null | { label: string, id: number }>(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleChangeAutocomplete = (name: string, value: any) => {
        setPic(value)
    }

    const handeChangeInputAutocomplete = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setPic(null)
    }

    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/rent-apartment/change-assigned-pic`, {
            id: props.id,
            assigned_pic_id: pic?.id
        })
            .then(res => res.data)
            .then(res => res && [renderSuccessButton('Assigned PIC Changed'), props.onClose(true)])
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (!props.open) setPic(null)
        // eslint-disable-next-line
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>Change Assigned PIC</DialogTitle>
            <DialogContent>
                <LoadingScreen open={isLoading} />
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            label='PIC Name'
                            name='assigned_pic'
                            initialQuery={props.initialPICName || ''}
                            onChange={handleChangeAutocomplete}
                            onInputChange={handeChangeInputAutocomplete}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user/active-intern-listing`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            style={{ float: 'right' }}
                            onClick={handleSubmit}
                            variant='contained'
                            disabled={pic === null}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ChangePICModal
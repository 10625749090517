import { Card, CardContent, Grid, Theme, Typography } from '@mui/material'
import React from 'react'
import { format } from 'date-fns'
import { UnitManagementListData } from '../UnitManagementList'
import { processNumber } from '../../../_utils/Helper'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { generateStyle } from '../../../_utils/DefaultStyle'

/* 
* Interfaces
*/
interface IProps {
    data: UnitManagementListData
    activeTab: string
    onClick: () => void
    onWaPress: (e: any) => void
}

const Items = (props: IProps) => {
    const { Root, classes } = useStyles()

    const furnished = () => {
        switch (props.data.furnished) {
            case 'non':
                return 'Non Furnished'
            case 'semi':
                return 'Semi Furnished'
            case 'full':
                return 'Full Furnished'
        }
    }

    const renderMonth = () => {
        let rawMonth = props.data.duration / 30.44
        let month = Math.round(rawMonth)

        return month
    }

    const renderDate = () => {
        switch (props.activeTab) {
            case 'new-available':
                return `Available on: ${props.data.available_at ? format(new Date(props.data.available_at), 'dd MMMM yyyy') : '-'}`
            case 'available':
                return `Last Contacted: ${props.data.last_owner_contacted_at ? format(new Date(props.data.last_owner_contacted_at), 'dd MMMM yyyy') : '-'}`
            case 'unavailable':
                return `Available on: ${props.data.rent_date ? format(new Date(props.data.rent_date), 'dd MMMM yyyy') : '-'}`
            case 'rented':
                return props.data.start_date && props.data.end_date ? `${format(new Date(props.data.start_date), 'dd MMM yyyy')} - ${format(new Date(props.data.end_date), 'dd MMM yyyy')} (${renderMonth()} bulan)` : '-'
        }
    }

    return (
        <Root>
            <Card
                onClick={() => { }}
                variant='outlined'
                sx={{ mb: 1 }}
            >
                <CardContent style={{ padding: 12 }} onClick={props.onClick}>
                    <Grid container
                        columnSpacing={2}
                        flexDirection='row'
                        display='flex'
                        alignItems='center'
                    >
                        <Grid item xs={6}>
                            <Grid container alignItems='center'>
                                <Grid sx={{ marginRight: 2 }}>
                                    <Typography fontWeight={'bold'} fontSize={18}>{props.data.code}</Typography>
                                </Grid>
                                {
                                    props.data.category ?
                                        <Grid item className={classes.starContainer}>
                                            <Typography fontWeight={'bold'} variant="subtitle2">★ Star</Typography>
                                        </Grid>
                                        : null
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                props.activeTab !== 'rented' ?
                                    <Typography sx={{ textAlign: 'right' }} fontWeight={'bold'} fontSize={18}>{processNumber(props.data.basic_price_12, props.data.is_dollar)}</Typography>
                                    :
                                    <div className={classes.rentedStatus}>
                                        <span className={classes.statusText}>{props.data.extend_status_label}</span>
                                    </div>
                            }
                        </Grid>
                    </Grid>
                    <Grid>
                        <Typography fontSize={12}>{props.data.apartment_name}</Typography>
                        <Typography fontSize={12}>{props.data.tower}/ {props.data.floor_level}/ {props.data.unit_number}</Typography>
                        <Typography fontSize={12}>{furnished()}</Typography>
                    </Grid>
                    <Grid container alignItems='flex-end'>
                        <Grid item xs={6}>
                            {
                                props.activeTab === 'rented' ?
                                    <Typography fontSize={10}>{props.data.customer_name}</Typography>
                                    : null
                            }
                            <Typography fontSize={10}>{renderDate()}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent='right'>
                                <div className={classes.buttonWa} onClick={props.onWaPress}>
                                    <WhatsAppIcon
                                        fontSize='medium'
                                        style={{ color: '#29A71A' }}
                                    />
                                </div>
                                <div className={classes.buttonEye} onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(`${process.env.REACT_APP_MAIN_URL}/${props.data.code}`)
                                }}>
                                    <VisibilityIcon
                                        fontSize='medium'
                                        style={{ color: '#00AEEF' }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card >
        </Root>
    )
}

export default Items

const useStyles = generateStyle((theme: Theme) =>
({
    code: {
        fontWeight: 'bold',
        fontSize: 18
    },
    starContainer: {
        borderRadius: 4,
        backgroundColor: '#FEDA31',
        padding: '0px 5px'
    },
    buttonWa: {
        padding: 8,
        backgroundColor: '#ECFFEA',
        border: '1px solid #29A71A',
        borderRadius: 8,
        marginRight: 12,
        display: 'flex',
    },
    buttonEye: {
        padding: 8,
        backgroundColor: '#DAF5FF',
        border: '1px solid #00AEEF',
        borderRadius: 8,
        display: 'flex',
    },
    rentedStatus: {
        padding: 5,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        marginRight: -20,
        backgroundColor: '#035AA6',
        display: 'flex',
        float: 'right'
    },
    statusText: {
        paddingLeft: 5,
        paddingRight: 10,
        fontSize: 10,
        color: '#fff'
    }
}), "Items"
)

import React, { useReducer, useEffect, useState, useMemo } from 'react';
import { Theme, TextField, Button, Dialog, DialogTitle, IconButton, DialogContent, Grid, DialogActions, TableContainer, Paper, Table, TableHead, TableCell, TableBody, TableRow } from '@mui/material';
import Chip from '@mui/material/Chip';
import Swal from 'sweetalert2';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';

/**
 * Assets
 */
import pdfPng from '../../../Assets/Images/png/doc.png';
import docPng from '../../../Assets/Images/png/doc.png';
import videoPng from '../../../Assets/Images/png/video.png';

import DeleteIcon from '@mui/icons-material/Delete';
import { renderWarningButton } from '../../../_utils/Helper';

/**
 * Icons
 */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { generateStyle } from '../../../_utils/DefaultStyle';
import { format } from 'date-fns';

interface Props {
    closing_id: string;
    permissions: any;
    files: IFile[];
    onUploadFile?: Function;
    onDeleteFile?: Function;
    isSmall?: boolean
}

export interface IFile {
    id: string;
    created_at: string;
    filepath: string;
    name: string;
    type: string;
}

interface ISearchState {
    search: string;
    type: string;
}

interface IFormState {
    name: string;
    type: string;
    file: File | null;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        width: '100%',
        '& .MuiTextField-root': {
            backgroundColor: '#fff'
        }
    },
    button: {
        margin: theme.spacing(1),
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    inputContainer: {
        display: 'flex',
        '& div:nth-of-type(1)': {
            flex: '4'
        },
        '& div:nth-of-type(2)': {
            flex: '2'
        },
        '& div:nth-of-type(3)': {
            flex: '4'
        },
        '& div + div': {
            marginLeft: '10px'
        }
    },
    buttonContainer: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '16px',
    },
    buttonContainerDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    buttonContainerMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    fileContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '10px',
    },
    thumbnail: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '25%',
        marginTop: '10px',
        '& img': {
            alignSelf: 'center',
            cursor: 'pointer'
        },
        '& span': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: '10px'
        }
    },
    fileListTable: {
        '& > table': {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'table',
            },
        },
        '& > .mobile-list': {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
            '& > *': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            },
            '& > *:last-child': {
                borderBottom: 'none',
            },
        },
        '& > table > tbody > tr > td:first-of-type': {
            width: 75,
            '& > *': {
                objectFit: 'cover',
                cursor: 'pointer',
            }
        },
        '& .file-name': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.dark,
            }
        }
    },
    fileCard: {
        display: 'flex',
        padding: 8,
        '& > img': {
            marginRight: 12,
        },
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
                marginBottom: 8,
            },
            '& > *:last-child': {
                marginBottom: 0,
            },
            '& > .date': {
                fontSize: 12,
            },
            '& > div > button': {
                margin: 0,
            }
        }
    },
}), "File_List"
);

const initialSearchState = {
    search: '',
    type: ''
};

const initialFormState = {
    name: '',
    type: '',
    file: null
};

const fileTypes = [
    {
        value: 'faktur',
        label: 'Faktur'
    },
    {
        value: 'job_done',
        label: 'Foto Job Selesai'
    },
    {
        value: 'other',
        label: 'Other'
    }
];

const FileList = (props: Props) => {
    const { files, onUploadFile } = props;
    const { Root, classes } = useStyles();

    const IMAGE_SIZE = useMemo(() => props.isSmall ? '50' : '75', [props.isSmall])

    /**
     * Input State
     */
    const searchReducer = (state: ISearchState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialSearchState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as ISearchState };
        }

        return { ...state };
    };

    const formReducer = (state: IFormState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialFormState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IFormState };
        }

        return { ...state };
    };

    const [filteredFiles, setFilteredFiles] = useState(files.slice());
    const [modalOpen, setModalOpen] = useState(false);
    const [searchState, setSearchState] = useReducer(searchReducer, initialSearchState);
    const [formState, setFormState] = useReducer(formReducer, initialFormState);
    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);

    useEffect(() => {
        setFilteredFiles(files.slice());
        setFormState({ name: '', value: initialFormState, type: 'REPLACE_STATE' });
        // eslint-disable-next-line
    }, [files]);

    useEffect(() => {
        if (modalOpen) {
            setFormState({ type: 'RESET_ITEM', name: '', value: null })
        }
    }, [modalOpen])

    const handleModalOpen = () => {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
    }

    const getLabel = (value: string) => {
        for (let index = 0; index < fileTypes.length; index++) {
            if (fileTypes[index].value === value) {
                return fileTypes[index].label;
            }
        }
    }

    const renderDeleteButton = (file: IFile, size?: 'small' | 'medium' | 'large') => {
        return (
            <Grid item>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    startIcon={<DeleteIcon />}
                    onClick={() => deleteFile(file.id, file.type)}
                    size={size}
                >
                    Delete
                </Button>
            </Grid>
        )
    }

    const deleteFile = (id: string, type: string) => {
        if (!props.onDeleteFile) return

        renderWarningButton("Apakah anda yakin ingin menghapus file ini?")
            .then((result) => {
                if (result.value) {
                    let API_URL = `${process.env.REACT_APP_API_URL}/activity/file/${id}`;
                    DefaultAxios
                        .delete(API_URL)
                        .then(() => {
                            Swal.fire({
                                title: "File berhasil dihapus",
                                icon: 'success'
                            }).then(() => {
                                if (props.onDeleteFile) props.onDeleteFile();
                            });
                        })
                        .catch((res) => {
                            Swal.fire({
                                title: "Error",
                                text: res.data.message,
                                icon: 'error'
                            });
                        })
                }
            })
    }

    useEffect(() => {
        let newFilteredFiles = files.slice();

        if (searchState.search) {
            newFilteredFiles = newFilteredFiles.filter(file => {
                return file.name.toLowerCase().indexOf(searchState.search) > -1;
            });
        }

        if (searchState.type) {
            newFilteredFiles = newFilteredFiles.filter(file => {
                return file.type === searchState.type;
            });
        }

        setFilteredFiles(newFilteredFiles);
        // eslint-disable-next-line
    }, [searchState]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        let name = e.target.name;
        let value: any = e.target.value;

        if (type === 'search') {
            setSearchState({ name, value, type: 'SET_ITEM' });
        } else {
            if (name === 'file') {
                const file = e.target.files;
                if (file && file[0]) {
                    value = file[0];
                }
            }
            setFormState({ name, value, type: 'SET_ITEM' });
        }
    }

    const handlePreviewFile = (src: string, isImage: boolean) => {
        if (isImage) {
            setPreviewImage(src);
        } else {
            window.open(src);
        }
    }

    const handleUpload = () => {
        if (!onUploadFile) return

        let isValid = true;
        Object.keys(formState).map(key => {
            // if (key === 'name') {
            //     if (formState.type === 'other' && formState.name === "") {
            //         isValid = false;
            //     } 
            // } else {
            if (!formState[key as keyof IFormState]) {
                isValid = false;
            }
            // }
            return true;
        })

        if (!isValid) {
            Swal.fire({
                title: "Error",
                text: 'Please fill all inputs',
                icon: 'error'
            });
            return;
        }

        const fd = new FormData();

        for (const [key, value] of Object.entries(formState)) {
            if (key === 'file') {
                fd.append(key, value as File);
            } else {
                fd.append(key, String(value));
            }
        }

        handleModalClose();
        onUploadFile(fd);
    }

    const renderFiles = () => {
        return filteredFiles.map((file, key) => {
            let src = '';
            let isImage = false;
            let filepath = file.filepath;

            let explode = filepath.split('?');
            const match = /(\.\w+$)/g.exec(explode[0]);

            if (match) {
                if (match[1] === '.pdf' || match[1] === '.PDF') {
                    src = pdfPng;
                } else if (match[1] === '.doc' || match[1] === '.docx') {
                    src = docPng;
                } else if (['.flv', '.mp4', '.m3u8', '.ts', '.3gp', '.mkv', '.mov', '.avi', '.wmv'].includes(match[1])) {
                    src = videoPng;
                } else {
                    src = filepath;
                    isImage = true;
                }
            }

            return (
                <TableRow key={file.id}>
                    <TableCell>
                        <img height={IMAGE_SIZE} width={IMAGE_SIZE} src={src} alt={file.name} onClick={() => handlePreviewFile(filepath, isImage)} />
                    </TableCell>
                    <TableCell>
                        <span
                            className="file-name"
                            onClick={() => handlePreviewFile(filepath, isImage)}
                        >
                            {file.name}
                        </span>
                    </TableCell>
                    {
                        !props.isSmall &&
                        <>
                            <TableCell>
                                <Chip size="small" label={getLabel(file.type)} color="primary" />
                            </TableCell>
                            <TableCell>
                                {format(new Date(file.created_at), 'dd/MM/yyyy HH:mm:ss')}
                            </TableCell>
                            {
                                props.onDeleteFile &&
                                <TableCell align="right">
                                    {renderDeleteButton(file)}
                                </TableCell>
                            }
                        </>
                    }
                </TableRow>
            )
        })
    }

    const renderFilesMobile = () => {
        return filteredFiles.map((file, key) => {
            let src = '';
            let isImage = false;
            let filepath = file.filepath;

            let explode = filepath.split('?');
            const match = /(\.\w+$)/g.exec(explode[0]);

            if (match) {
                if (match[1] === '.pdf' || match[1] === '.PDF') {
                    src = pdfPng;
                } else if (match[1] === '.doc' || match[1] === '.docx') {
                    src = docPng;
                } else {
                    src = filepath;
                    isImage = true;
                }
            }

            const actionButton = renderDeleteButton(file, 'small')

            return (
                <div key={file.id} className={classes.fileCard}>
                    <img height={IMAGE_SIZE} width={IMAGE_SIZE} src={src} alt={file.name} onClick={() => handlePreviewFile(filepath, isImage)} />
                    <div>
                        <span
                            className="file-name"
                            onClick={() => handlePreviewFile(filepath, isImage)}
                        >
                            {file.type !== "other" && file.type !== "owner_other" ? getLabel(file.type) : file.name}
                        </span>
                        {
                            !props.isSmall &&
                            <>
                                <span className="date">
                                    {format(new Date(file.created_at), 'dd/MM/yyyy HH:mm:ss')}
                                </span>
                                {
                                    actionButton
                                }
                            </>
                        }
                    </div>
                </div>
            )
        })
    }

    return (
        <Root>
            <div className={classes.root}>
                <Dialog
                    open={!!previewImage}
                    onClose={() => { setPreviewImage(undefined) }}
                    scroll="body"
                    maxWidth={false}
                >
                    <img src={previewImage} width="100%" alt="preview" />
                </Dialog>
                <Dialog
                    open={modalOpen}
                    onClose={handleModalClose}
                >
                    <Root>
                        <DialogTitle>
                            <IconButton
                                sx={{ left: -10 }}
                                onClick={handleModalClose}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            Upload file
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        select
                                        name="type"
                                        value={formState.type}
                                        onChange={(e: any) => handleChange(e, 'form')}
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                    >
                                        <option aria-label="None" value="" disabled>-- Type --</option>
                                        {
                                            fileTypes.map((type) =>
                                                <option aria-label='Type' value={type.value} key={type.value}>{type.label}</option>
                                            )
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Nama"
                                        name="name"
                                        value={formState.name}
                                        onChange={(e: any) => handleChange(e, 'form')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="file"
                                        name="file"
                                        onChange={(e: any) => handleChange(e, 'form')}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleUpload} color="primary">
                                Upload
                            </Button>
                        </DialogActions>
                    </Root>
                </Dialog>
                {/* <div className={classes.inputContainer}> */}
                {/* <TextField
                    size="small"
                    variant="outlined"
                    name="search"
                    value={searchState.search}
                    onChange={(e: any) => handleChange(e, 'search')}
                    placeholder="Search here"
                /> */}
                {/* <TextField
                    select
                    size="small"
                    variant="outlined"
                    name="type"
                    value={searchState.type}
                    onChange={(e: any) => handleChange(e, 'search')}
                    SelectProps={{
                        native: true,
                    }}
                >
                    <option value="">-- Type --</option>
                    {
                        fileTypes.map(fileType => {
                            return <option value={fileType.value} key={fileType.value}>{fileType.label}</option>
                        })
                    }
                </TextField> */}
                {/* <div className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleModalOpen}
                    >
                        Upload File
                    </Button>
                </div> */}
                {/* </div> */}

                <TableContainer component={Paper} className={classes.fileListTable} elevation={0}>
                    {
                        props.onUploadFile &&
                        <>
                            <div className={`${classes.buttonContainer} ${classes.buttonContainerDesktop}`}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleModalOpen}
                                >
                                    Upload File
                                </Button>
                            </div>
                            <div className={`${classes.buttonContainer} ${classes.buttonContainerMobile}`}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleModalOpen}
                                    size="small"
                                >
                                    Upload File
                                </Button>
                            </div>
                        </>
                    }
                    <div className="mobile-list">
                        {
                            filteredFiles.length > 0 ?
                                renderFilesMobile()
                                :
                                <p>There is no file yet</p>
                        }
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    File
                                </TableCell>
                                <TableCell>
                                    Nama
                                </TableCell>
                                {
                                    !props.isSmall &&
                                    <>
                                        <TableCell>
                                            Type
                                        </TableCell>
                                        <TableCell>
                                            Created At
                                        </TableCell>
                                        {
                                            props.onDeleteFile &&
                                            <TableCell align="right">
                                                Action
                                            </TableCell>
                                        }
                                    </>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                filteredFiles.length > 0 ?
                                    renderFiles()
                                    :
                                    <TableRow>
                                        <TableCell colSpan={props.onDeleteFile ? 5 : 4}>There is no file yet</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Root>
    );
}

export default FileList;
import React from 'react'

import { Theme, IconButton } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) =>
({
  container: {
    marginBottom: theme.spacing(2)
  },
  table: {
    width: '100%',
  },
  appBar: {
    position: 'relative',
  },
  copy: {
    marginLeft: '0.5rem'
  },
  copyArea: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: 0,
    width: 0
  }
}), "Info_Table"
);

const InfoTable = (props: any) => {
  const { Root, classes } = useStyles()

  const refs: any = {};

  const copy = (key: number) => {
    const ref = refs[`input${key}`];
    ref.select();
    document.execCommand('copy');
  }

  return (
    <Root>
      <TableContainer className={classes.container}>
        <Table className={classes.table} size="small">
          <TableBody >
            {props.data.map((row: string[], key: number) => {
              if (row[1] !== null) {
                return (
                  <TableRow key={row[0]}>
                    <TableCell align='left' padding='none' style={{ borderBottom: 'none', color: '#00000080', fontWeight: 500, width: 150, maxWidth: 150, verticalAlign: 'baseline' }}>
                      {row[0]}
                    </TableCell>
                    <TableCell align="left" style={{ borderBottom: 'none', fontWeight: 500, verticalAlign: 'baseline' }}>
                      {row[1]}
                      {
                        row[2]
                          ? <>
                            <IconButton size="small" color="primary" onClick={() => copy(key)}>
                              <FileCopyOutlinedIcon
                                fontSize="small"
                                className={classes.copy}
                              />
                            </IconButton>
                            <textarea ref={(input) => refs[`input${key}`] = input} value={row[1]} readOnly className={classes.copyArea} />
                          </>
                          : null}
                    </TableCell>
                  </TableRow>
                )
              } else {
                return null
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Root>
  )
}

export default InfoTable
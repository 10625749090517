import React, { useEffect, useState } from 'react'
import { Grid, Table, TableHead, TableBody, TableCell, TableRow, IconButton } from '@mui/material'
import LoadingScreen from '../../_components/LoadingScreen'
import AsyncAutoComplete from '../../_components/_form/AsyncAutoComplete'

/**
 * Icons
 * 
 */
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderToastSuccess } from '../../_utils/Helper';


interface IUnit {
    id: string,
    label: string
    code: string
}

const PriorityUnitHomepageList = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [units, setUnits] = useState<IUnit[]>([])

    const handleChangeAutocomplete = (name: string, value: any) => {
        handleSave(units.map(unit => unit.id).concat([value.id]))
    }

    const handleSort = (type: string, id: string) => {
        let oldUnits = [...units]
        const currentUnit = units.find((unit) => unit.id === id)
        const currentUnitIndex = units.findIndex((unit) => unit.id === id)
        const newUnitIndex = type === 'up' ? currentUnitIndex - 1 : currentUnitIndex + 1

        switch (type) {
            case 'top':
                if (currentUnit?.id) {
                    oldUnits = oldUnits.filter(unit => unit.id !== id)
                    oldUnits.unshift(currentUnit)
                }
                break;
            case 'up':
                if (currentUnitIndex > -1) {
                    [oldUnits[currentUnitIndex], oldUnits[newUnitIndex]] = [oldUnits[newUnitIndex], oldUnits[currentUnitIndex]]
                }
                break;
            case 'down':
                if (currentUnitIndex > -1) {
                    [oldUnits[currentUnitIndex], oldUnits[newUnitIndex]] = [oldUnits[newUnitIndex], oldUnits[currentUnitIndex]]
                }
                break;
            case 'bottom':
                if (currentUnit?.id) {
                    oldUnits = oldUnits.filter(unit => unit.id !== id)
                    oldUnits.push(currentUnit)
                }
                break
            case 'delete':
                if (currentUnit?.id) {
                    oldUnits = oldUnits.filter(unit => unit.id !== id)
                }
                break
            default:
        }

        handleSave(oldUnits.map((unit) => unit.id))
    }

    const fetchData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/star-listing-homepage`)
            .then((res) => res.data)
            .then(res => {
                if (res) {
                    setUnits(res)
                }
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    const handleSave = (ids: string[]) => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/star-listing-homepage`, {
            ids: ids ? ids : units.map((unit) => unit.id)
        })
            .then((res) => res.data)
            .then(res => {
                if (res) {
                    renderToastSuccess('Update Unit Priority Success')
                    fetchData()
                }
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Grid container spacing={2}>
            <LoadingScreen open={isLoading} fullScreen />

            <Grid item xs={12} md={6}>
                <AsyncAutoComplete
                    label='Kode Unit'
                    name='unit_code'
                    withoutQuery
                    onChange={handleChangeAutocomplete}
                    url={`${process.env.REACT_APP_API_URL}/autocomplete/unit/star-listing?except_ids=${units.map(unit => unit.id).join(',')}`}
                />
            </Grid>
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Kode</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            units.length ?
                                units.map((unit, idx) => (
                                    <TableRow>
                                        <TableCell>{idx + 1}</TableCell>
                                        <TableCell>{unit.label || unit.code}</TableCell>
                                        <TableCell key={unit.id}>
                                            <IconButton
                                                color='primary'
                                                title='Send to Top'
                                                onClick={() => handleSort('top', unit.id)}
                                                disabled={idx === 0}
                                            >
                                                <VerticalAlignTopIcon />
                                            </IconButton>

                                            <IconButton
                                                color='primary'
                                                title='Up'
                                                onClick={() => handleSort('up', unit.id)}
                                                disabled={idx === 0}
                                            >
                                                <ArrowUpwardIcon />
                                            </IconButton>

                                            <IconButton
                                                color='primary'
                                                title='Down'
                                                onClick={() => handleSort('down', unit.id)}
                                                disabled={idx === (units.length - 1)}
                                            >
                                                <ArrowDownwardIcon />
                                            </IconButton>

                                            <IconButton
                                                color='primary'
                                                title='Send to Bottom'
                                                onClick={() => handleSort('bottom', unit.id)}
                                                disabled={idx === (units.length - 1)}
                                            >
                                                <VerticalAlignBottomIcon />
                                            </IconButton>

                                            <IconButton
                                                title='Delete'
                                                onClick={() => handleSort('delete', unit.id)}
                                            >
                                                <DeleteIcon color='error' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )) :
                                <TableRow>
                                    <TableCell colSpan={3} align='center'>Tidak ada Unit Priority yang ditambahkan</TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default PriorityUnitHomepageList
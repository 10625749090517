import React from 'react'

/**
 * Components
 */
import { TextField } from '@mui/material'

/**
 * Utils
 */
import { LocalizationProvider, DateTimePicker as Picker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

interface DateTimePickerProps {
    label: string
    name: string
    format?: string
    placeholder?: string
    value: null | Date
    onChange: (name: string, date: null | Date) => void
    errorText?: string
    minDateTime?: Date
    disabled?: boolean
}

const DateTimePicker = (props: DateTimePickerProps) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Picker
                ampm={false}
                label={props.label}
                inputFormat={props.format || "dd/MM/yyyy HH:mm"}
                value={props.value}
                onChange={date => props.onChange(props.name, date)}
                minDateTime={props.minDateTime}
                disabled={props.disabled}
                renderInput={(inputProps) => (
                    <TextField
                        {...inputProps}
                        variant="outlined"
                        fullWidth
                        label={props.label}
                        placeholder={props.placeholder || "dd/MM/yyyy HH:mm"}
                        error={!!props.errorText}
                        helperText={props.errorText}
                        disabled={props.disabled}
                    />
                )}
            />
        </LocalizationProvider>
    )
}

export default DateTimePicker


import React from 'react';
import { Dialog } from '@mui/material';

interface IProps {
    isPreviewOpen: boolean
    url: string
    onClose: Function
}

const SimplePreview = ({isPreviewOpen, url, onClose}: IProps) => {
        return (
            <Dialog
                    open={isPreviewOpen}
                    onClose={() => onClose()}
                    scroll="body"
                    maxWidth={false}
                >
                    <img
                        src={url}
                        alt="preview"
                        style={{
                            maxHeight: '80vh',
                            maxWidth: '80vw',
                            objectFit: 'scale-down',
                            display: 'block'
                        }}
                    />
                </Dialog>
        )
}

export default SimplePreview;
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * Table
 */
import DataTable from '../../_components/_dataTable/DataTable';
import { Link } from '@mui/material';

/**
 * Components
 */
import BookingDetail from './BookingDetail';
import moment from 'moment';
import { PermissionContext } from '../../_contexts/PermissionContext';

interface IParams extends Record<string, string | undefined> {
    id?: string
}

export const renderStatus = (status: '0' | '1' | '2', date: string) => {
    console.log(status, date)
    switch (status.toString()) {
        case '0':
            const parsedDate = date ? new Date(date) : null
            if (parsedDate && (Date.now() > parsedDate.getTime())) {
                return 'Expired'
            }
            return 'On Process'
        case '1':
            return 'Confirmed'
        case '2':
            return 'Canceled'
    }
}

export default function BookingList() {
    const permissions = useContext(PermissionContext)
    const navigate =  useNavigate()
    const params = useParams<IParams>()

    /**
     * 1 = Apartment Detail
     * 2 = Advisor Placement
     */
    const [modalState, setModalState] = useState(0);

    const [reDrawDataTable, setReDrawDataTable] = useState(0)

    useEffect(() => {
        if (typeof params.id !== "undefined") {
            setModalState(1);
        }
    }, [params]);

    const handleCloseDetail = () => {
        setModalState(0);
        window.history.pushState([], 'Booking List', `/booking`);
        setReDrawDataTable(prev => prev + 1)
    };

    return (
        <>
            <BookingDetail
                permissions={permissions}
                onCloseModal={handleCloseDetail}
                open={modalState === 1}
            />
            
            <DataTable
                url={`${process.env.REACT_APP_API_URL}/bookings`}
                rowCallback={(row: any) => {
                    const parsedDate = row.expired_at ? new Date(row.expired_at) : null
                    if (row.status.toString() === '0' && parsedDate && (Date.now() > parsedDate.getTime())) {
                        return {
                            backgroundColor: '#f78383'
                        }
                    }

                    return null
                }}
                columns={[
                    {
                        name: 'name',
                        label: 'Name',
                        filterable: true,
                        render: (data: string, row: any) => {
                            const parsedDate = row.expired_at ? new Date(row.expired_at) : null
                            const isExpired = row.status.toString() === '0' && parsedDate && (Date.now() > parsedDate.getTime())
                            
                            return (
                                <div>
                                    <Link
                                        onClick={() => navigate(`/booking/${row.id}`)}
                                        component="button"
                                        style={isExpired ? {color: 'white'} : {}}
                                    >
                                        {data}
                                    </Link>
                                </div>
                            )
                        }
                    },
                    {
                        name: 'phone',
                        label: 'Phone',
                        filterable: true
                    },
                    {
                        name: 'email',
                        label: 'Email',
                        filterable: true
                    },
                    {
                        name: 'expired_at',
                        label: 'Expired At',
                        type: 'date',
                        filterable: true,
                        render: (data: string, row: any) => {
                            return (
                                `${moment(data).format("DD MMMM YYYY HH:mm")}`
                            )
                        }
                    },
                    {
                        name: 'status',
                        label: 'Status',
                        filterable: false,
                        render: (data: string, row: any) => {
                            return (
                                <div>
                                    {
                                        renderStatus(data.toString() as ("0" | "1" | "2"), row.expired_at)
                                    }
                                </div>
                            )
                        }
                    },
                ]}
                reDraw={reDrawDataTable}
            />
        </>
    )
}
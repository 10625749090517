import React, { useState, useEffect } from 'react';
import { Paper, Theme, Grid, Slide, Button, Dialog, OutlinedInput, IconButton, Typography, Tabs, Tab } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

/**
 * Components
 */
import NewCommentList, { IComment, IMentionList } from '../../_components/NewCommentList';
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';
import CompleteModal from './_components/CompleteModal';
import LoadingScreen from '../../_components/LoadingScreen';
import TodoListTable, { ITodoList } from './_components/TodoListTable';
import QiscusTemplateModal from '../_closing/_components/Modal/QiscusTemplateModal';
import LogBroadcastList from '../_closing/_components/LogBroadcastList';
import DetailList, { TicketDetailItem } from './_components/DetailList';
import FixingListSection, { FixingItem } from './_components/FixingListSection';


/**
 * Icons
 */
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ArrowBack } from '@mui/icons-material';
import PhoneIcon from '@mui/icons-material/Phone';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertNumber, generalErrorHandler, renderToastSuccess } from '../../_utils/Helper';
import { format } from 'date-fns';
import { IQiscusData } from '../_closing/_components/ClosingModalDetail';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    open: boolean,
    // mode: "add" | "detail",
    ticket_id: string,
    permissions: any
    onClose: Function;
    onReload: () => void
}

interface IState {
    id: string,
    code: string,
    title: string,
    attachment: any[],
    apartment_name: string,
    created_at: string,
    closing_code: string,
    closing_id: string,
    pic_id: string,
    pic_name: string,
    type: string,
    unit_code: string,
    tenant_name: string,
    created_by_name: string,
    status: number,
    customer_id: number,
    pic_landlord_id: number,
    pic_landlord_name: string,
    pic_landlord_phone: string,
    ticket_age: number,
    rent_bill: number,
    water_electricity_bill: number,
    other_bill: number,
    other_bill_notes: string,
    landlord: {
        email: string,
        name: string,
        phone: string
    },
    tenant: {
        email: string,
        name: string,
        phone: string
    },
    agent_id: string
    agent: {
        email: string,
        name: string,
        phone: string
    },
    unit: {
        tower: string,
        floor_level: string,
        unit_number: string
    }
    deposit_age: number
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    container: {
        backgroundColor: "#f3f3f3",
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        marginLeft: 8
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
        marginTop: theme.spacing(5),
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    paperTab: {
        margin: "0"
    },
    paper: {
        height: "100%",
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    paper_label: {
        position: "absolute",
        marginTop: "-45px",
        padding: "1px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)"
    },
    paper_label_text: {
        backgroundColor: "#0094ff",
        fontSize: "1.5em",
        padding: "13px",
        borderRadius: "4px",
        color: "white"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        paddingTop: '10px'
    },
    gridButtonChange: {
        alignSelf: 'center',
        textAlign: 'center',
    },
    memberButtonContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    memberButton: {
        // width: '100%',
        padding: theme.spacing(1),
        backgroundColor: '#008000'

    },
    box: {
        padding: theme.spacing(2)
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    filename: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.dark,
        }
    },
    deleteModalContainer: {
        padding: 24,
    },
    deleteModalTitle: {
        margin: 0,
        fontSize: 20,
        flex: 1,
        left: '-40px',
        position: 'relative',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    deleteModalInput: {
        marginBottom: 32,
    },
    footer: {
        display: 'flex',
        minHeight: '500px',
        backgroundColor: '#eee',
        padding: '10px',
    },
    todoHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 10
    },
    h6_header: {
        color: theme.palette.primary.main,
    },
}), 'TicketSystemDetail'
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TicketDetail = (props: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/ticket';
    const [loading, setLoading] = useState(false);
    const [addTodo, setAddTodo] = useState(0)
    const [todoList, setTodoList] = useState<ITodoList[]>([])
    const [qiscusData, setQiscusData] = useState<IQiscusData | null>(null)

    const [hideComplete, setHideComplete] = useState(true)

    const [completeModal, setCompleteModal] = useState(false)

    const [inputState, setInputState] = useState<IState>({
        id: '',
        code: '',
        title: '',
        attachment: [],
        apartment_name: '',
        created_at: '',
        closing_code: '',
        closing_id: '',
        pic_name: '',
        pic_id: '',
        customer_id: 0,
        pic_landlord_id: 0,
        pic_landlord_name: '',
        pic_landlord_phone: '',
        created_by_name: '',
        type: '',
        unit_code: '',
        tenant_name: '',
        status: 0,
        ticket_age: 0,
        rent_bill: 0,
        water_electricity_bill: 0,
        other_bill: 0,
        other_bill_notes: '',
        landlord: {
            email: '',
            name: '',
            phone: ''
        },
        tenant: {
            email: '',
            name: '',
            phone: ''
        },
        agent_id: '',
        agent: {
            email: '',
            name: '',
            phone: ''
        },
        unit: {
            tower: '',
            floor_level: '',
            unit_number: ''
        },
        deposit_age: 0,
    });

    const [initialDetails, setInitialDetails] = useState<TicketDetailItem[]>([])
    const [initialFixingList, setInitialFixingList] = useState<FixingItem[]>([])
    const [initialTodoList, setInitialTodoList] = useState<ITodoList[]>([])
    const [initialCommentsData, setInitialCommentsData] = useState<{ comments: IComment[], mentions: IMentionList[] }>({
        comments: [],
        mentions: [],
    })

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [deleteReason, setDeleteReason] = useState('')
    const [isFooterLoading, setIsFooterLoading] = useState(false);
    const [footer, setFooter] = useState<any>(null);
    const [activeTab, setActiveTab] = useState(0);

    const loadData = () => {
        setLoading(true);
        DefaultAxios
            .get(`${API_URL}/${props.ticket_id}`)
            .then(res => res.data)
            .then((data: any) => {
                setInputState(data);
                setInitialDetails(data.details)
                setInitialTodoList(data.todo_lists)
                setInitialCommentsData(data.comments)
                setInitialFixingList(data.fixing_lists)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const sendComplete = () => setCompleteModal(true)

    const submitComplete = (state: any) => {
        let axios;

        let fd = new FormData()

        fd.append('comment', state.comment || '')
        if (state.attachments.length) {
            state.attachments.forEach((file: File, index: number) => {
                fd.append(`attachments[${index}]`, file)
            })
        }

        axios = DefaultAxios.post(`${API_URL}/${props.ticket_id}/complete`, fd);
        setLoading(true);
        axios
            .then(res => {
                renderToastSuccess("Ticket Completed")
                setCompleteModal(false)
                loadData();
                props.onReload()
            })
            .catch(error => {
                generalErrorHandler(error);
                setLoading(false);
            });
    }


    // const renderAttachment = () => {
    //     return inputState.attachment.map((file, key) => {
    //         let src = '';
    //         let isImage = false;
    //         let filepath = file.filepath;

    //         let explode = filepath.split('?');
    //         const match = /(\.\w+$)/g.exec(explode[0]);

    //         if (match) {
    //             if (match[1] === '.pdf' || match[1] === '.PDF') {
    //                 src = pdfPng;
    //             } else if (match[1] === '.jpeg' || match[1] === '.jpg' || match[1] === '.png') {
    //                 src = filepath;
    //                 isImage = true;
    //             } else {
    //                 src = docPng;
    //             }
    //         }

    //         return (
    //             <Grid item xs={12} md={4} key={key}>
    //                 <TableRow>
    //                     <TableCell>
    //                         <img onClick={() => handlePreviewFile(filepath, isImage)} height="75" width="75" style={{ marginRight: 8, cursor: "pointer" }} src={src} alt="gambar" />
    //                     </TableCell>
    //                     <TableCell>
    //                         <span
    //                             className={classes.filename}
    //                             style={{ wordBreak: 'break-word' }}
    //                             onClick={() => handlePreviewFile(filepath, isImage)}
    //                         >
    //                             {file.filename}
    //                         </span>
    //                     </TableCell>
    //                 </TableRow>
    //             </Grid>
    //         )
    //     })
    // }

    useEffect(() => {
        if (props.open && props.ticket_id) {
            loadData();
        } else {
            setIsDeleteModalOpen(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, props.ticket_id]);

    useEffect(() => {
        if (inputState.id) loadFooter();
        // eslint-disable-next-line
    }, [props.open, activeTab, props.ticket_id, inputState.id, initialCommentsData]);

    // useEffect(() => {
    //     loadFooter();
    //     // eslint-disable-next-line
    // }, [activeTab, props.ticket_id]);

    // const loadFooter = () => {
    //     let url: string = '';

    //     switch (activeTab) {
    //         case 0:
    //             url = `${API_URL}/${props.ticket_id}/attachment`;
    //             break;
    //         default:
    //             break;
    //     }

    //     if (!url || !props.ticket_id) {
    //         return;
    //     }

    //     setIsFooterLoading(true);
    //     DefaultAxios
    //         .get(url)
    //         .then(res => {
    //             switch (activeTab) {
    //                 case 0:
    //                     setFooter(
    //                         <AttachmentList
    //                             ticketId={props.ticket_id}
    //                             attachments={res.data}
    //                             onUploadAttachment={handleUploadAttachment}
    //                             onDeleteAttachment={loadFooter}
    //                         />
    //                     )
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         })
    //         .catch(err => {
    //             generalErrorHandler(err);
    //         })
    //         .finally(() => {
    //             setIsFooterLoading(false);
    //         })
    // }

    const deleteTicket = () => {
        DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/ticket/${props.ticket_id}`, {
            data: {
                deleted_reason: deleteReason,
            }
        })
            .then(res => res.data)
            .then(() => {
                setIsDeleteModalOpen(false)
                props.onReload()
                props.onClose()

                renderToastSuccess("Ticket deleted");
            })
            .catch(error => {
                generalErrorHandler(error)
            })
    }

    const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    }

    const handleOpenQiscus = (phone: string, type: string, data_id: string) => {
        setQiscusData({
            phone,
            type,
            data_id,
            closing_id: inputState.closing_id
        })
    }

    const renderPhone = (phone: string, type: string, dataId: string) => {
        if (phone && phone !== "-" && phone !== "[UNDEFINED]") {
            return (
                <>
                    HP: {phone}
                    <IconButton href={`tel:${phone}`} size="small" style={{ marginRight: "5px" }}>
                        <PhoneIcon />
                    </IconButton>
                    {
                        type ?
                            <IconButton size='small' onClick={() => handleOpenQiscus(phone, type, dataId)}>
                                <WhatsAppIcon />
                            </IconButton>
                            : null
                    }
                    {/* <IconButton href={`https://wa.me/${phone}`} size="small" target="_blank" rel="noopener noreferrer">
                        <WhatsAppIcon />
                    </IconButton> */}
                </>
            )
        } else {
            return `HP: -`;
        }
    }

    const handleCreateJHSOrder = () => {
        window.location.href = `/jhs-order/add?ticket_id=${props.ticket_id}`
    }

    // const handleUploadAttachment = (fd: FormData) => {
    //     setIsFooterLoading(true);
    //     DefaultAxios
    //         .post(`${API_URL}/${props.ticket_id}/attachment`, fd)
    //         .then(res => {
    //             renderToastSuccess("Upload berhasil");
    //         })
    //         .catch(err => {
    //             generalErrorHandler(err);
    //         })
    //         .finally(() => {
    //             setIsFooterLoading(false);
    //         })
    // }

    const loadFooter = () => {
        let url: string = '';

        switch (activeTab) {
            case 0:
                setFooter(
                    <div style={{ width: '100%' }}>
                        <br></br>
                        <NewCommentList
                            id={inputState.id}
                            type="ticket"
                            warnAttachment
                            initialData={initialCommentsData}
                        />
                    </div>
                )
                setIsFooterLoading(false);
                return;
            case 1:
                url = `${process.env.REACT_APP_API_URL}/qiscus-broadcast/logs`;
                break;
            default:
                break;
        }

        if (!url || !inputState.closing_id) {
            return;
        }

        setIsFooterLoading(true);
        DefaultAxios
            .get(url, {
                params: {
                    closing_id: activeTab === 1 ? inputState.closing_id : null
                }
            })
            .then(res => {
                switch (activeTab) {
                    case 1:
                        setFooter(
                            <LogBroadcastList
                                closingId={inputState.id}
                                logs={res.data}
                            />
                        )
                        break;
                    default:
                        break;
                }
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsFooterLoading(false);
            })
    }

    return (
        <Root>
            <LoadingScreen open={loading} fullScreen />

            <CompleteModal
                open={completeModal}
                onClose={() => setCompleteModal(false)}
                onSubmit={submitComplete}
            />

            <Dialog
                open={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                closeAfterTransition
                TransitionComponent={Transition}
                fullWidth
                maxWidth="sm"
            >
                <Root>
                    <div className={classes.deleteModalContainer}>
                        <div className={classes.titleContainer}>
                            <IconButton
                                onClick={() => setIsDeleteModalOpen(false)}
                                sx={{ zIndex: 999 }}
                            >
                                <ArrowBack />
                            </IconButton>
                            <p className={classes.deleteModalTitle}>
                                Confirm Delete Ticket
                            </p>
                        </div>
                        <OutlinedInput
                            value={deleteReason}
                            onChange={e => setDeleteReason(e.target.value)}
                            fullWidth
                            placeholder="Delete Reason"
                            className={classes.deleteModalInput}
                        />
                        <div className={classes.memberButtonContainer}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                className={classes.delete_button}
                                onClick={() => deleteTicket()}
                                disabled={!deleteReason}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </Root>
            </Dialog>
            <ModalDetail
                open={props.open}
                isLoading={false}
                onCloseModal={props.onClose}
                title={`${inputState.code}${inputState.title ? ` - ${inputState.title}` : ``}`}
                showEditButton={true}
                editButtonLink={`/ticket/edit/${props.ticket_id}`}
            >
                <>
                    <Grid item xs={12} md={6}>
                        <Grid container>
                            <ModalSection
                                title="Basic Information"
                                xs={12}
                                md={12}
                                content={[
                                    ['Created At', inputState.created_at ? `${format(new Date(inputState.created_at), "dd/MM/yyyy - HH:mm")} by ${inputState.created_by_name}` : "-"],
                                    [
                                        inputState.type === 'Deposit' ? 'Deposit Age' : 'Ticket Age',
                                        inputState.type === 'Deposit' ?
                                            inputState.deposit_age === null ? '-' : `${inputState.deposit_age} days`
                                            : `${inputState.ticket_age} days`
                                    ],
                                    ['Type', inputState.type],
                                    ['Status', inputState.status === 0 ? 'On Process' : 'Done'],
                                ]}

                            />
                            {
                                inputState.type === 'Collection' ?
                                    <ModalSection
                                        title="Bills"
                                        xs={12}
                                        md={12}
                                        content={[
                                            ['Rent Bill', inputState.rent_bill !== null ? convertNumber(String(inputState.rent_bill), 'Rp.') : '-'],
                                            ['Utility Bill', inputState.water_electricity_bill !== null ? convertNumber(String(inputState.water_electricity_bill), 'Rp.') : '-'],
                                            ['Other Bill', <>
                                                <Typography variant='body2' style={{ wordBreak: 'break-word' }}>
                                                    {inputState.other_bill !== null ? convertNumber(String(inputState.other_bill), 'Rp.') : '-'}
                                                </Typography>
                                                {
                                                    inputState.other_bill_notes ?
                                                        <Typography variant='body2'>{inputState.other_bill_notes}</Typography> :
                                                        null
                                                }
                                            </>
                                            ],
                                        ]}
                                    />
                                    : null
                            }
                        </Grid>
                    </Grid>

                    <ModalSection
                        title="Other Information"
                        xs={12}
                        md={6}
                        content={[
                            ['Tenant',
                                <>
                                    {inputState.tenant.name} - {renderPhone(inputState.tenant.phone, 'customer', inputState.customer_id?.toString() || '')}
                                </>
                            ],
                            ['Owner',
                                <>
                                    {inputState.pic_landlord_name} - {renderPhone(inputState.pic_landlord_phone, 'pic_landlord', inputState.pic_landlord_id?.toString() || '')}
                                </>
                            ],
                            ['Agent',
                                <>
                                    {inputState.agent?.name || ''} - {renderPhone(inputState.agent?.phone || '', 'agent', inputState.agent_id)}
                                </>
                            ],
                            ['Unit Details', `${inputState.apartment_name} | ${inputState.unit_code} | Tw. ${inputState.unit?.tower} Lt. ${inputState.unit?.floor_level}  No. ${inputState.unit?.unit_number}`],
                            ['Closing Code', inputState.closing_code ? <a href={`/closing/${inputState.closing_code}`} target="_blank" rel="noopener noreferrer">{inputState.closing_code}</a> : "-"],
                            ['PIC', inputState.pic_name],
                        ]}
                    />

                    <Grid container>
                        <Grid item xs={12}>
                            <Root>
                                <div className={classes.memberButtonContainer}>
                                    {
                                        inputState.status === 0 ?
                                            // true ?
                                            <>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={handleCreateJHSOrder}
                                                    sx={{ mr: 1 }}
                                                >
                                                    Create JHS Order
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    className={classes.memberButton}
                                                    onClick={() => { sendComplete() }}
                                                >
                                                    Complete
                                                </Button>
                                                {
                                                    props.permissions['ticket.delete'] &&
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        className={classes.delete_button}
                                                        onClick={() => setIsDeleteModalOpen(true)}
                                                    >
                                                        Delete
                                                    </Button>
                                                }
                                            </>
                                            : null
                                    }
                                </div>
                            </Root>
                        </Grid>

                    </Grid>

                    {
                        inputState.type === 'Deposit' ?
                            <Grid item xs={12}>
                                <FixingListSection
                                    ticketId={inputState.id}
                                    initialList={initialFixingList}
                                    setIsLoading={setLoading}
                                />
                            </Grid>
                            : null
                    }

                    <Grid item xs={12}>
                        <DetailList
                            ticketId={inputState.id}
                            initialList={initialDetails}
                            setIsLoading={setLoading}
                            isTicketDone={inputState.status === 1}
                        />
                    </Grid>

                    <ModalSection
                        content={[]}
                        title=''
                        xs={12}
                        md={12}
                        additionalContent={
                            <TodoListTable
                                type="detail"
                                addTodo={addTodo}
                                hideComplete={hideComplete}
                                ticketId={inputState.id}
                                callBackTodo={(todo: ITodoList[]) => setTodoList(todo)}
                                initialList={initialTodoList}
                            />
                        }
                        titleContent={
                            <Root>
                                <Grid container>
                                    <Grid item xs={12} className={classes.todoHeader}>
                                        <Typography variant='h6' className={classes.h6_header}>To-do List</Typography>
                                        <div>
                                            <Button
                                                size="small"
                                                variant='contained'
                                                sx={{ mr: 1 }}
                                                onClick={() => setHideComplete(!hideComplete)}
                                            >
                                                {hideComplete ? 'Show ' : 'Hide '}
                                                Complete Items
                                                {hideComplete ? ` (${todoList.reduce((total, todo) => total + (todo.completed_at ? 1 : 0), 0)})` : ''}
                                            </Button>
                                            <Button
                                                size="small"
                                                variant='contained'
                                                onClick={() => setAddTodo(new Date().getTime())}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Root>
                        }
                    />

                    <Grid item xs={12}>
                        <Root>
                            <Paper className={classes.paper}>
                                <LoadingScreen open={isFooterLoading} />
                                <Tabs
                                    value={activeTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleTab}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab label="Comment" value={0} />
                                    {props.permissions['logs-broadcast.see-logs'] && <Tab label="Logs Broadcast" value={1} />}
                                </Tabs>
                                <div className={classes.footer}>
                                    {footer}
                                </div>
                            </Paper>
                        </Root>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <LoadingScreen open={isFooterLoading} />
                            <Tabs
                                value={activeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleTab}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label="Attachment" value={0} />
                            </Tabs>
                            <div className={classes.footer}>
                                {footer}
                            </div>
                        </Paper>
                    </Grid> */}
                    {/* <ModalSection
                        title="Attachment"
                        xs={12}
                        md={12}
                        content={[]}
                        additionalContent={
                            <Grid container spacing={2}>
                                {
                                    inputState.attachment ?
                                        renderAttachment() : null
                                }
                            </Grid>
                        }
                    /> */}


                </>
            </ModalDetail>

            <QiscusTemplateModal
                open={qiscusData !== null}
                onClose={() => setQiscusData(null)}
                onSubmitSuccess={() => setQiscusData(null)}
                data={qiscusData}

            />
        </Root>
    );
}

export default TicketDetail;
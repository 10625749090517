import React, { useEffect, useRef, useState } from 'react'

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen'
import { Button, Grid, TextField, Typography } from '@mui/material'

/**
 * Utils
 */
import { getBase64, renderErrorButton } from '../../_utils/Helper'
import { createWorker } from 'tesseract.js'


const ScanKTP = () => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const ocrWorker = useRef<Tesseract.Worker | null>(null)

    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<{ file: null | File, base64: string, ocr: string }>({
        file: null,
        base64: '',
        ocr: '',
    })

    useEffect(() => {
        // Prep the ocr worker
        prepOcrWorker()

        return () => {
            // Terminate the ocr worker
            if (ocrWorker.current) {
                ocrWorker.current.terminate()
            }
        }
    }, [])

    /**
     * Prepare the OCR worker to be used
     */
    const prepOcrWorker = async () => {
        const worker = await createWorker('eng')
        ocrWorker.current = worker
    }

    /**
     * Handle ktp image choosen by user
     * @param e input event
     */
    const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target

        if (files && files.length) {
            // Get the base64 of the file
            getBase64(files[0])
                .then(async (base64) => {
                    setState(prev => ({
                        ...prev,
                        file: files[0],
                        base64: base64 as string,
                    }))

                    // Get the ocr result from the file
                    onInterpret(files[0])
                })
                .catch(() => {
                    renderErrorButton('Gagal upload gambar')
                })
        }
    }

    /**
     * Get text from ktp image and display it on textarea
     * @param file ktp image
     */
    const onInterpret = async (file: File) => {
        setIsLoading(true)

        // Check if ocrworker is already prepped
        // re-prep if it's not yet
        if (!ocrWorker.current) {
            await prepOcrWorker()
            await onInterpret(file)
        }

        // Use OCR to get the text
        const result = await ocrWorker.current!.recognize(file)

        setState(prev => ({
            ...prev,
            ocr: result.data.text,
        }))

        setIsLoading(false)
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                Scan KTP
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => {
                                    inputRef.current?.click()
                                }}
                                variant='contained'
                                size='small'
                            >
                                Upload Gambar
                            </Button>
                            <input ref={inputRef} type="file" onChange={onUpload} style={{ display: 'none' }} accept='image/*' />
                        </Grid>
                        {
                            state.base64 ?
                                <Grid item xs={12}>
                                    <img src={state.base64} alt='ktp' style={{ width: '100%', height: 'auto', borderRadius: 8 }} />
                                </Grid>
                                : null
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                Text
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant='outlined'
                                value={state.ocr}
                                fullWidth
                                minRows={10}
                                multiline
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ScanKTP

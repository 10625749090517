import React, { useState, useEffect } from 'react';
import {
    Dialog,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
} from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import TextArea from '../../../../_components/_form/TextArea';
import ButtonWithLoading from '../../../../_components/_form/ButtonWithLoading';

/**
 * Utils
 */
import DefaultAxios from '../../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../../_utils/Helper';

interface IProps {
    id: number;
    open: boolean;
    isActive: number;
    isOwner: number;
    onClose: Function;
    callBackSubmitSuccess?: Function;
    setIsLoading: Function;
    isLoading?: boolean
}

const SwitchPublishModal = ({ id, open, isActive, onClose, isOwner, setIsLoading, callBackSubmitSuccess, isLoading }: IProps) => {
    useEffect(() => {
        setPublishComment('');
        // eslint-disable-next-line
    }, [open]);

    const [publishComment, setPublishComment] = useState('');

    const handlePublishSubmit = () => {
        if (!publishComment) {
            Swal.fire({
                title: 'Validasi Error',
                text: "Silahkan isi komentar",
                icon: 'warning',
            });
            return;
        }

        setIsLoading(true);
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit/switch-active`, { id: id, comment: publishComment })
            .then(res => {
                if (typeof callBackSubmitSuccess !== "undefined") {
                    callBackSubmitSuccess();
                }
                onClose();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={() => onClose()}
        >
            <DialogTitle>{isActive ? 'Unpublish' : 'Publish'} Unit</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextArea
                            label="Comment"
                            name="comment"
                            value={publishComment}
                            onChange={(e) => setPublishComment(e.target.value)}
                            disabled={isLoading}
                        />
                    </Grid>
                    {(isOwner && isActive === 0) ?
                        <Grid item xs={12}>
                            <Typography>
                                NOTE: Owner akan dikirimkan email dan akan dinotifikasi bahwa Unitnya sudah di-publish
                            </Typography>
                        </Grid>
                        :
                        ""
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWithLoading onClick={handlePublishSubmit} color="primary" isLoading={isLoading}>
                    Submit
                </ButtonWithLoading>
            </DialogActions>
        </Dialog>
    )
}

export default SwitchPublishModal;
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Grid, Button, CircularProgress, ButtonGroup } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../LoadingScreen';
import ImagePreview from '../../ImagePreview';
import ImageEditModal from '../../../_pages/_unit/_components/_modals/ImageEditModal';
import ImageUploaderModal, { IUploadedImage } from '../../../_pages/_unit/_components/_modals/ImageUploaderModal';
import UnitImagePreviewCard from '../../../_pages/_unit/_components/_cards/UnitImagePreviewCard';
import DeleteAllImageModal from '../../_modal/DeleteAllImageModal';
import DeleteQuestionModal from '../../_modal/DeleteQuestionModal';
import DnDContainer from '../../_dragAndDrop/DndContainer';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generateStyle } from '../../../_utils/DefaultStyle';
import { generalErrorHandler, renderSuccessButton, renderToastSuccess, renderWarningButton } from '../../../_utils/Helper';

// Icons
import { Check } from '@mui/icons-material';

interface Props {
    API_URL: string;
    type: string;
    withoutAdd?: boolean;
    /**
     * the main data id, could be apartment id, unit id, etc
     */
    dataId?: string
}

export interface IState {
    id: string;
    caption: string;
    alt: string;
    thumbnail: string;
    file: File | null;
    sort: number;
    room_type: string;
    other: string;
    is_primary?: number
}

interface IStringObject {
    [key: string]: string
}

const useStyles = generateStyle((theme) => ({
    imageContainer: {
        marginTop: "30px",
        marginBottom: "30px"
    },
    buttonContainer: {
        marginBottom: "20px"
    },
    submitButton: {
        float: 'right'
    },
    progressContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        zIndex: 1,
    },
    progressIcon: {
        marginRight: 16,
    },
    tableTitle: {
        fontSize: 22,
        marginBottom: 10,
        marginTop: 12,
        fontWeight: 'bold'
    },
    buttonActionPosition: {
        '& span.MuiButton-startIcon': {
            margin: 0
        }
    }
}), "Page_Form"
);

export const roomTypeOptions: IStringObject = {
    "bedroom": 'Bedroom',
    "bedroom-1": 'Bedroom 1',
    "bedroom-2": 'Bedroom 2',
    "bedroom-3": 'Bedroom 3',
    "living-room": 'Living Room',
    "kitchen": 'Kitchen',
    "dining-room": 'Dining Room',
    "bathroom": 'Bathroom',
    "bathroom-2": 'Bathroom 2',
    "bathroom-3": 'Bathroom 3',
    "maid-room": 'Maid Room',
    "balcony": 'Balcony',
    "private-lift": 'Private Lift',
}

const ImageFormPage = ({ API_URL, type, withoutAdd, dataId }: Props) => {
    const { Root, classes } = useStyles();

    const [needUploadImageStates, setNeedUploadImageStates] = useState<IState[]>([])
    // Temp state when sorting image using the drag component
    const [originalImageStates, setOriginalImageStates] = useState<IState[]>([]);
    const [imageStates, setImageStates] = useState<IState[]>([]);
    const [selectedImageState, setSelectedImageState] = useState<IState | null>(null)
    const [editImage, setEditImage] = useState<{ data: IState | null, index: number }>({ data: null, index: 0 })

    const [mode, setMode] = useState<'' | 'add' | 'edit' | 'preview'>('');
    const [isLoading, setIsLoading] = useState(false);
    const [deleteAllImageModal, setDeleteAllImageModal] = useState(false)

    const [isUploadLoading, setIsUploadLoading] = useState(false)
    const [finishedUploadCount, setFinishedUploadCount] = useState(0)
    const [imageUpload, setImageUpload] = useState(false)
    const [activeSorting, setActiveSorting] = useState('')
    const [deleteQuestionModal, setDeleteQuestionModal] = useState(false)



    useEffect(() => {
        loadImages();
        // eslint-disable-next-line
    }, []);

    const loadImages = () => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL)
            .then(res => {
                const imageGallery = res.data;
                const newImageStates = [];
                for (let key in imageGallery) {
                    let roomType: string = imageGallery[key].room_type || 'bedroom'
                    let other = '';

                    if (!Object.keys(roomTypeOptions).includes(roomType) && type === 'unit') {
                        other = roomType;
                        roomType = 'other';
                    }

                    newImageStates.push({
                        id: imageGallery[key].id,
                        caption: imageGallery[key].caption,
                        alt: imageGallery[key].alt,
                        thumbnail: imageGallery[key].image,
                        file: null,
                        sort: imageGallery[key].sort,
                        room_type: roomType,
                        other: other,
                        is_primary: imageGallery[key].is_primary
                    })
                }

                setImageStates(newImageStates);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleNewData = () => {
        setImageUpload(true)
        setMode('add');
    }

    const handleDeleteImage = (imageState: IState) => {
        setIsLoading(true);
        DefaultAxios
            .delete(`${API_URL}/${imageState.id}`)
            .then(() => {
                Swal.fire({
                    title: "Berhasil menghapus",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadImages();
                    })
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const checkValidation = (data: IState[]) => {
        let isValid = true;

        data.map(formState => {
            if (!formState.file && mode === 'add') {
                isValid = false;
            }

            if (!formState.caption) {
                isValid = false;
            }

            // if (formState.room_type === 'other' && formState.other === '' && type === 'unit') {
            //     isValid = false
            // }
            return true;
        });

        return isValid;
    }

    const handleSubmit = async (imageStatesData: IState[]) => {
        if (!checkValidation(imageStatesData)) {
            Swal.fire({
                title: "Error",
                text: `Please fill ${type === 'unit' ? 'room type, ' : ''}caption and file on every form`,
                icon: 'error'
            });
            return;
        }

        let url;
        setNeedUploadImageStates(imageStatesData)

        if (mode === 'add') {
            const activeData = imageStatesData

            url = API_URL;
            setIsUploadLoading(true)

            let failedIndex: number[] = []
            let failedFileName: string[] = []

            for (let i in activeData) {
                const fd = new FormData();

                for (let key in activeData[i]) {
                    let value = activeData[i][key as keyof IState];
                    if (value) {
                        if (key === 'file') {
                            fd.append(`param[0][image]`, value as File);
                        } else {
                            fd.append(`param[0][${key}]`, String(value));
                        }
                    }
                }

                try {
                    const { data } = await DefaultAxios.post(url, fd)
                    if (!data) throw new Error('upload failed')
                } catch (err) {
                    failedIndex.push(Number(i))
                    const fileName = activeData[i].file?.name
                    if (fileName) {
                        failedFileName.push(fileName)
                    }
                }

                setFinishedUploadCount(prev => prev + 1)
            }

            setIsUploadLoading(false);

            setNeedUploadImageStates(prev => {
                return prev.filter((val, index) => failedIndex.includes(index))
            })

            if (failedFileName.length > 0) {
                Swal.fire({
                    title: "Submit Image gagal",
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Retry',
                    cancelButtonText: 'Close',
                    html:
                        '<div>' +
                        '<p>File yang gagal disubmit:</p>' +
                        failedFileName.reduce((acc, fileName) => {
                            return acc + `<p>${fileName}</p>`
                        }, '') +
                        '</div>'
                })
                    .then((res) => {
                        if (res.value) {
                            handleSubmit(activeData.filter((val, index) => failedIndex.includes(index)))
                        }
                    })
            } else {
                Swal.fire({
                    title: "Submit image berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        setNeedUploadImageStates([]);
                    })
            }

            loadImages();
            setFinishedUploadCount(0)
        } else {
            const fd = new FormData();


            for (let i in imageStatesData) {
                for (let key in imageStatesData[i]) {
                    let value = imageStatesData[i][key as keyof IState];
                    if (value) {
                        if (key === 'file') {
                            fd.append('image', value as File);
                        } else {
                            fd.append(`${key}`, String(value));
                        }
                    }
                }
            }

            url = API_URL + '/' + imageStatesData[0].id;
            fd.append('_method', 'PATCH');

            setIsLoading(true);
            DefaultAxios.post(url, fd)
                .then(res => {
                    Swal.fire({
                        title: "Submit image berhasil",
                        icon: 'success',
                        timer: 1000
                    })
                        .then(res => {
                            loadImages();
                            setNeedUploadImageStates([]);
                        })
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }

    }

    const handleSortImage = (imageId: string, dir: 'up' | 'down', key: number) => {
        setIsLoading(true);
        DefaultAxios
            .post(API_URL + '/' + imageId + '/sort-image', { dir })
            .then(res => {
                renderToastSuccess('Sort Berhasil Diubah')
                loadImages();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleRefreshWatermark = () => {
        renderWarningButton('Apakah anda yakin ingin me-refresh watermark semua gambar dari unit ini?')
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/refresh-watermark`)
                        .then(() => {
                            Swal.fire({
                                title: "Silahkan menunggu beberapa saat sampai semua gambar berhasil di proses",
                                icon: 'success',
                                timer: 1000
                            })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleDeleteAllImage = () => {
        setIsLoading(true)
        DefaultAxios.post(`${API_URL}/delete-all`)
            .then(res => res.data)
            .then(res => {
                if (res) {
                    Swal.fire("Success", "All images were deleted!", 'success')
                }

                loadImages()
                setMode('')
                setNeedUploadImageStates([])
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => setIsLoading(false))
    }

    const getCoverImage: IState | null = useMemo(() => {
        if (imageStates.length) {
            const coverImage = imageStates.find((value) => value.is_primary)
            if (coverImage) {
                return coverImage
            } else {
                return imageStates[0]
            }
        }

        return null
    }, [imageStates])

    const renderCover = () => {
        if (!getCoverImage) return null
        return (
            <Grid item xs={12} sx={{ mb: type === 'apartment' ? 2 : 0 }}>
                <p className={classes.tableTitle}>Foto Sampul</p>
                <Grid container spacing={3}>
                    <Grid item xs={12 / 3}>
                        <UnitImagePreviewCard
                            index={0}
                            caption={getCoverImage.caption}
                            onChange={(name) => handleChangeImage(name, getCoverImage)}
                            onPreview={(src: string) => {
                                setMode('preview')
                                setSelectedImageState(getCoverImage)
                            }}
                            src={getCoverImage.thumbnail}
                            type={getCoverImage.room_type}
                            containerStyle={{
                                aspectRatio: '2/1'
                            }}
                            disableCoverOption
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const changeImageCover = (imageState: IState) => {
        setIsLoading(true)

        const params = type === 'apartment' ? {
            id: imageState.id
        } : undefined

        const endpoint = type === 'apartment' ? `${process.env.REACT_APP_API_URL}/apartment/set-primary-image` : `${process.env.REACT_APP_API_URL}/unit/${imageState.id}/image/set-primary`

        DefaultAxios.post(endpoint, params)
            .then(res => res.data)
            .then(res => {
                renderSuccessButton('Berhasil ubah foto sampul!')
                loadImages()
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleChangeImage = (name: string, imageState: IState) => {
        switch (name) {
            case 'edit':
                setMode('edit')
                setSelectedImageState(imageState)
                break;
            case 'delete':
                setDeleteQuestionModal(true)
                setSelectedImageState(imageState)
                break;
            case 'sampul':
                changeImageCover(imageState)
                break;
            default:
                return
        }
    }

    /**
     * Handle card movement state
     * @param oldIndex card's index before moved
     * @param newIndex card's index after moved
     * @returns
     */
    const moveCard = useCallback((oldIndex: number, newIndex: number) => {
        setImageStates(prev => {
            const its = [...prev]
            const spliced = its.splice(oldIndex, 1)
            its.splice(newIndex, 0, spliced[0])
            return its
        })
    }, [])

    const renderTable = (title: string, data: IState[]) => {
        return (
            data.length ?
                <Grid item xs={12}>
                    {/* Hide the title and sort button for apartment */}
                    {
                        type !== "apartment" ?
                            <>
                                <p className={classes.tableTitle}>{title}</p>
                                <ButtonGroup sx={{ mb: 2 }}>
                                    <Button variant={activeSorting === title ? 'contained' : 'outlined'} onClick={() => setActiveSorting(title)}>
                                        Edit Position
                                    </Button>
                                    {
                                        activeSorting === title ?
                                            <>
                                                <Button className={classes.buttonActionPosition} onClick={() => setActiveSorting('')} variant='outlined' size='small' startIcon={<Check />}></Button>
                                            </> :
                                            null
                                    }
                                </ButtonGroup>
                            </>
                            :
                            <ButtonGroup sx={{ ml: 'auto', mb: 2 }}>
                                {
                                    activeSorting === 'Bedroom' ?
                                        <>
                                            <Button
                                                variant={'text'}
                                                color='error'
                                                onClick={() => {
                                                    setImageStates(originalImageStates)
                                                    setActiveSorting('')
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </> :
                                        null
                                }
                                <Button
                                    variant={activeSorting === 'Bedroom' ? 'contained' : 'outlined'}
                                    onClick={() => {
                                        setOriginalImageStates(imageStates)
                                        setActiveSorting('Bedroom')
                                    }}
                                    style={{ borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
                                >
                                    Edit Position
                                </Button>
                                {
                                    activeSorting === 'Bedroom' ?
                                        <Button
                                            className={classes.buttonActionPosition}
                                            onClick={() => {
                                                submitDragSort()
                                            }}
                                            variant='outlined'
                                            size='small'
                                            startIcon={<Check />}
                                        />
                                        : null
                                }
                            </ButtonGroup>
                    }
                    {/* Use drag sorting for apartment */}
                    {
                        (activeSorting === title && type === 'apartment') ?
                            <DnDContainer
                                items={data}
                                getItemId={item => item.id}
                                onMove={moveCard}
                                renderItem={(item: IState, index) => (
                                    <UnitImagePreviewCard
                                        caption={item.caption}
                                        index={index + 1}
                                        onChange={(name: string) => handleChangeImage(name, item)}
                                        onPreview={(src: string) => {
                                            setMode('preview')
                                            setSelectedImageState(item)
                                        }}
                                        src={item.thumbnail}
                                        type={item.room_type}
                                        sorting={activeSorting === title}
                                        onSort={(arrow) => handleSortImage(item.id, arrow, index)}
                                        disablePrev
                                        disableNext
                                        dragable
                                    />
                                )}
                                renderContainer={children => (
                                    <Grid container spacing={3} sx={{ mb: 5 }}>
                                        {children}
                                    </Grid>
                                )}
                                renderItemContainer={(children, index) => (
                                    <Grid item xs={12 / 5} key={index}>
                                        {children}
                                    </Grid>
                                )}
                            />
                            :
                            <Grid container spacing={3} sx={{ mb: 5 }}>
                                {data.map((imageState, key) => (
                                    <Grid item xs={12 / 5}>
                                        <UnitImagePreviewCard
                                            caption={imageState.caption}
                                            index={key + 1}
                                            onChange={(name: string) => handleChangeImage(name, imageState)}
                                            onPreview={(src: string) => {
                                                setMode('preview')
                                                setSelectedImageState(imageState)
                                            }}
                                            src={imageState.thumbnail}
                                            type={imageState.room_type}
                                            sorting={activeSorting === title}
                                            onSort={(arrow) => handleSortImage(imageState.id, arrow, key)}
                                            disablePrev={key === 0}
                                            disableNext={key === data.length - 1}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                    }
                </Grid>
                : null

        )
    }

    const renderData = () => {
        const roomTypes = [
            { type: "bedroom", title: "Bedroom" },
            { type: "bedroom-1", title: "Bedroom 1" },
            { type: "bedroom-2", title: "Bedroom 2" },
            { type: "bedroom-3", title: "Bedroom 3" },
            { type: "living-room", title: "Living Room" },
            { type: "kitchen", title: "Kitchen" },
            { type: "dining-room", title: "Dining Room" },
            { type: "bathroom", title: "Bathroom" },
            { type: "bathroom-2", title: "Bathroom 2" },
            { type: "bathroom-3", title: "Bathroom 3" },
            { type: "maid-room", title: "Maid Room" },
            { type: "balcony", title: "Balcony" },
            { type: "private-lift", title: "Private Lift" },
            { type: "other", title: "Other" },
        ]

        return (
            <>
                {renderCover()}
                {
                    roomTypes.map((roomType, key) =>
                        <>
                            {renderTable(roomType.title, imageStates.filter(data => data.room_type === roomType.type))}
                        </>
                    )
                }
            </>
        )
    }

    // Submit the sorted image by drag to API
    const submitDragSort = () => {
        setIsLoading(true)

        const fd = new FormData()

        fd.append('id', dataId!)
        imageStates.forEach(image => {
            fd.append('images[]', image.id)
        })

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/apartment/resort-image`, fd)
            .then(res => res.data)
            .then(data => {
                renderToastSuccess('Sort Berhasil Diubah')
                setActiveSorting('')
                loadImages();
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <LoadingScreen open={isUploadLoading} fullScreen>
                <div className={classes.progressContainer}>
                    <CircularProgress className={classes.progressIcon} />
                    <p>Uploading...{finishedUploadCount + 1}/{needUploadImageStates.length}</p>
                </div>
            </LoadingScreen>
            <ImageUploaderModal
                onClose={() => setImageUpload(false)}
                onSubmit={(galleries: IUploadedImage[]) => {
                    handleSubmit(galleries.map((gallery) => ({
                        file: gallery.file,
                        caption: gallery.caption,
                        room_type: gallery.type,
                        alt: '',
                        id: '',
                        other: '',
                        sort: 0,
                        thumbnail: '',
                        is_primary: 0
                    })))
                }}
                open={imageUpload && mode === 'add'}
                type={type}
            />
            <Grid item xs={12} className={classes.buttonContainer} sx={{ display: 'flex' }}>
                {
                    withoutAdd === true ? null
                        : <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '15px' }}
                            onClick={handleNewData}
                        >
                            Add Image
                        </Button>
                }
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: '15px' }}
                    onClick={handleRefreshWatermark}
                >
                    Refresh watermark
                </Button>
                {
                    imageStates && imageStates.length > 0 && type !== 'apartment' ?
                        <Button
                            variant='contained'
                            color="error"
                            onClick={() => {
                                setDeleteAllImageModal(true)
                            }}
                        >
                            Delete All Image
                        </Button>
                        : null
                }
            </Grid>

            {renderData()}

            <DeleteQuestionModal
                open={deleteQuestionModal}
                onSubmit={value => {
                    if (value) {
                        setDeleteQuestionModal(false)
                        if (selectedImageState) {
                            handleDeleteImage(selectedImageState)
                        }
                    } else {
                        setDeleteQuestionModal(false)
                    }
                }}
            />

            <DeleteAllImageModal
                open={deleteAllImageModal}
                onSubmit={(result) => {
                    if (result) {
                        handleDeleteAllImage()
                    }
                    setDeleteAllImageModal(false)
                }}
            />

            {
                selectedImageState && mode === 'edit' ?
                    <ImageEditModal
                        open={selectedImageState !== null}
                        data={{
                            caption: selectedImageState.caption,
                            file: selectedImageState.file as File,
                            type: selectedImageState.room_type,
                            thumbnail: selectedImageState.thumbnail
                        }}
                        index={editImage.index}
                        onClose={() => {
                            setEditImage({ data: null, index: 0 })
                            setMode('')
                        }}
                        onSubmit={(newImage) => {
                            handleSubmit([{
                                ...selectedImageState,
                                caption: newImage.caption,
                                room_type: newImage.type
                            }])
                        }}
                        type={type}
                    />
                    : null
            }

            {
                mode === 'preview' &&
                <ImagePreview
                    image={selectedImageState ? selectedImageState.thumbnail : ''}
                    onClose={() => setSelectedImageState(null)}
                />
            }
        </Root>
    );
}

export default ImageFormPage;

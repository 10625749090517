import React, { Fragment } from 'react'
import Table from '@mui/material/Table'
import { Slide, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, styled } from '@mui/material';
import { generateStyle } from '../../_utils/DefaultStyle';


const StyledTableCell = styled(TableCell)({
  [`&.MuiTableCell-head`]: {
    backgroundColor: '#FAFAFA',
    color: ' #505050',
    fontSize: '16px',
    fontWeight: '600,'
  },
  [`&.MuiTableCell-body`]: {
    fontSize: '15px',
  }
})

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  }
}))


const useStyles = generateStyle(theme => ({
  table: {
    minWidth: 700,
  },
  links: {
    color: '#00AEEF',
    cursor: 'pointer',
    fontWeight: 500
  }
}), "AptTableTableSUmmary");


const AptTableSummary = (props: any) => {
  const { Root, classes } = useStyles();
  const tableHead = ['Session', 'Demand', 'Availability', 'Unit Needed'];
  const tableData = ['session', 'demand', 'available']


  const renderRows = (start: number, limit: number) => {
    let rows = [<Fragment key={16}></Fragment>]
    let idx = 0;
    for (let i in props.data) {
      idx++;
      if (idx >= start && idx <= limit) {
        rows.push(
          <StyledTableRow key={idx}>
            <StyledTableCell align="left">{idx}</StyledTableCell>
            <StyledTableCell component="th" scope="row">
              <div onClick={() => props.openDetail(i)} className={classes.links}>
                {i}
              </div>
            </StyledTableCell>
            {tableData.map(val => {
              return (
                <StyledTableCell align="center" key={val}>{renderValue(props.data[i], val)}</StyledTableCell>)
            })}
            <StyledTableCell align="center" style={{ fontWeight: 600 }}>{renderValue(props.data[i], 'unit_needed')}</StyledTableCell>
          </StyledTableRow>
        )
      }
    }
    return rows
  }

  const renderValue = (data: any, req: string) => {
    let val = 0;
    if (req === 'session') {
      return data['total_session'];
    } else if (req !== 'unit_needed') {
      for (let i in data) {
        if (i !== 'total_session') {
          val += Number(data[i][req])
        }
      }
      return val;
    } else {
      let unit = '';
      for (let i in data) {
        if (data[i].demand > data[i].available) {
          if (unit === '') { unit = i }
          else { unit = unit + ', ' + (i) }
        }
      }
      if (unit === '') unit = 'None';
      return unit
    }
  }


  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit style={{ transitionDelay: '200ms' }} timeout={300}>
      <Root>
        <TableContainer component={Paper} style={{ marginTop: '40px', border: '1px solid #E8E8E8' }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell>Apartment Name</StyledTableCell>
                {tableHead.map((title, idx) => {
                  return (
                    <StyledTableCell align="center" key={idx}>{title}</StyledTableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderRows(6, 15)}
            </TableBody>
          </Table>
        </TableContainer>
      </Root>
    </Slide>
  )
}

export default AptTableSummary;
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useState } from 'react'
import TextArea from '../../../../_components/_form/TextArea'
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../../../_utils/Helper'
import LoadingScreen from '../../../../_components/LoadingScreen'

type Props = {
    open: boolean,
    onClose: (refresh: boolean) => void
    id: string | number
}

interface IState {
    deleted_reason: string
}

const initialState = {
    deleted_reason: '',
}

const ReasonModal = (props: Props) => {
    const [state, setState] = useState<IState>(initialState)
    const [isLoading, setIsLoading] = useState(false)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-request-delete`, {
            id: props.id,
            deleted_reason: state.deleted_reason
        })
            .then(res => {
                renderSuccessButton('Request Delete Success!')
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>Alasan Delete</DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <TextArea
                            label='Reason'
                            name='deleted_reason'
                            onChange={handleChange}
                            value={state.deleted_reason}
                            rows={3}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            disabled={!state.deleted_reason}
                            variant='contained'
                            onClick={handleSubmit}
                            sx={{ float: 'right' }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ReasonModal
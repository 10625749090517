import React, { useReducer, Fragment, useEffect, useState } from 'react';
import { Card, CardContent, Grid, Typography, TextField, InputAdornment, Button, Switch } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// Components
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../_components/LoadingScreen';
import TextArea from '../../_components/_form/TextArea';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { currencyToNumber, generalErrorHandler, numberToCurrency } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IState {
    agent_id: string;
    agent_name: string;
    gmv_transaction: string;
    revenue_transaction: string;
    owner_referral_commission: string;
    client_referral_commission: string;
    notes: string;
    is_cobroke: boolean;
}

interface IErrorState {
    agent_id: string;
    gmv_transaction: string;
    revenue_transaction: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const useStyles = generateStyle(() => ({
    root: {
        maxWidth: '750px',
        '& .Mui-disabled': {
            backgroundColor: '#eee'
        }
    },
    title: {
        fontSize: '34px',
        borderBottom: '1px solid #0000004D'
    },
    subtitle: {
        fontSize: '20px'
    },
    inputWithAdornment: {
        backgroundColor: '#F8F8F8',
        '& .MuiInputBase-input': {
            backgroundColor: '#FFFFFF',
        },
        '& .MuiInputAdornment-positionStart + .MuiInputBase-input': {
            paddingLeft: '10px',
            marginLeft: '20px'
        },
        '& .MuiInputAdornment-positionStart > p': {
            paddingLeft: '10px'
        }
    },
    infoText: {
        color: '#000000',
        fontSize: '14px',
        opacity: '40%'
    },
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
}), "ClosingReferral_Form"
);

const ClosingReferralForm = () => {
    const { Root, classes } = useStyles();
    const params: any = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const initialState: IState = {
        agent_id: '',
        agent_name: '',
        gmv_transaction: '',
        revenue_transaction: '',
        owner_referral_commission: '',
        client_referral_commission: '',
        notes: '',
        is_cobroke: false
    };

    const initialErrorState: IErrorState = {
        agent_id: '',
        gmv_transaction: '',
        revenue_transaction: ''
    };

    const currencyTypes = [
        'gmv_transaction',
        'revenue_transaction',
        'owner_referral_commission',
        'client_referral_commission'
    ];

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    // Error State
    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialErrorState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.body.style.backgroundColor = '#eee';

        return () => {
            document.body.style.backgroundColor = '#fff';
        }
    }, []);

    useEffect(() => {
        setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
        setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        // eslint-disable-next-line
    }, [location]);

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        let value = target.value;

        if (currencyTypes.includes(target.name)) {
            value = numberToCurrency(value);
        }

        setInputState({ name: target.name, value, type: 'SET_ITEM' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const handleCobrokeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputState({ name: 'is_cobroke', value: e.target.checked, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...errorState };

        if (!inputState.agent_id) {
            newError.agent_id = 'Agent wajib diisi';
            isValid = false;
        } else {
            newError.agent_id = '';
        }

        if (!inputState.gmv_transaction) {
            newError.gmv_transaction = 'Transaction Amount wajib diisi';
            isValid = false;
        } else {
            newError.gmv_transaction = '';
        }

        if (params.type === 'wings' && !inputState.revenue_transaction) {
            newError.revenue_transaction = 'Revenue Referral Wings wajib diisi';
            isValid = false;
        } else {
            newError.revenue_transaction = '';
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        const data: any = { ...inputState };

        for (let key in data) {
            if (currencyTypes.includes(key)) {
                data[key] = Number(currencyToNumber(data[key]));
            }
        }

        let url = '';
        if (params.type === 'wings') {
            url = `${process.env.REACT_APP_API_URL}/new-closing/wings`;
        } else {
            url = `${process.env.REACT_APP_API_URL}/new-closing/referral`;
        }

        setIsLoading(true);
        DefaultAxios
            .post(url, data)
            .then(res => {
                Swal.fire({
                    title: "Closing Referral Submitted",
                    icon: 'success',
                    onAfterClose: () => {
                        navigate('/closing');
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <Card className={classes.root}>
                <LoadingScreen open={isLoading} fullScreen />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>{params.type === 'wings' ? 'Add Closing Wings' : 'Add Closing Referral Pintu'}</Typography>
                        </Grid>
                        {
                            params.type !== 'wings'
                                ? <Grid item xs={12} className={classes.switchContainer}>
                                    <Typography className={classes.subtitle}>
                                        Cobroke
                                        <br />
                                        <Typography component="span" className={classes.infoText}>Aktifkan apabila transaksi ini transaksi cobroke</Typography>
                                    </Typography>
                                    <Switch
                                        color="primary"
                                        value={true}
                                        checked={inputState.is_cobroke}
                                        onChange={handleCobrokeSwitch}
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                </Grid>
                                : null
                        }
                        <Grid item xs={12}>
                            <Typography className={classes.subtitle}>
                                Informasi Agent
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                label="Agent"
                                name="agent_id"
                                initialQuery={inputState.agent_name}
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/agent`}
                                placeholder="Cari dengan ketik nama atau nomor handphone agent"
                                errorText={errorState.agent_id}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.subtitle}>
                                Detail Transaksi
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Transaction Amount"
                                name="gmv_transaction"
                                value={inputState.gmv_transaction}
                                onChange={handleChanged}
                                className={classes.inputWithAdornment}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                }}
                                error={!!errorState.gmv_transaction}
                                helperText={errorState.gmv_transaction}
                            />
                        </Grid>
                        {
                            params.type === 'wings'
                                ? <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Revenue Referral Wings"
                                        name="revenue_transaction"
                                        value={inputState.revenue_transaction}
                                        onChange={handleChanged}
                                        className={classes.inputWithAdornment}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                        }}
                                        error={!!errorState.revenue_transaction}
                                        helperText={errorState.revenue_transaction}
                                    />
                                </Grid>
                                : <Fragment>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Revenue Referral Owner"
                                            name="owner_referral_commission"
                                            value={inputState.owner_referral_commission}
                                            onChange={handleChanged}
                                            className={classes.inputWithAdornment}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                            }}
                                            helperText="Kosongkan jika tidak ada"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Revenue Referral Client"
                                            name="client_referral_commission"
                                            value={inputState.client_referral_commission}
                                            onChange={handleChanged}
                                            className={classes.inputWithAdornment}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                            }}
                                            helperText="Kosongkan jika tidak ada"
                                        />
                                    </Grid>
                                </Fragment>
                        }
                        <Grid item xs={12}>
                            <TextArea
                                label="Keterangan Closing"
                                name="notes"
                                value={inputState.notes}
                                onChange={handleChanged}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Root>
    );
}

export default ClosingReferralForm;
import React, { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick';
import { Theme, Typography, IconButton, Dialog } from '@mui/material';
import { Box } from '@mui/material';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImageSlide from './ImageSlide';
import ImagePreview from '../../ImagePreview';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { generateStyle } from '../../../_utils/DefaultStyle';

// ...
const useStyles = generateStyle((theme: Theme) => ({
    imgIndex: {
        color: 'white',
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        width: '100%',
        bottom: 10,
    },
    image: {
        height: '100%',
        width: '100%',
        flexShrink: 0,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: 'transform 200ms ease',
        scrollSnapAlign: 'start',
        objectFit: 'cover'
    },
    imageContainer: {
        display: "flex",
        flexFlow: 'row',
        // width: '100%',
        height: 400,
        overflow: 'scroll',
        scrollbarWidth: 'none',
        scrollSnapType: 'x mandatory',
        scrollSnapPointsX: 'repeat(100%)',
        // width: 'fit-content',
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
            background: 'transparent'
        },
    },
    prevArrow: {
        position: 'absolute',
        left: 10,
        top: '45%',
        // backgroundColor: 'rgba(255,255,255,0.2)'
        backgroundColor: 'white'
    },
    nextArrow: {
        position: 'absolute',
        right: 10,
        top: '45%',
        // backgroundColor: 'rgba(255,255,255,0.2)'
        backgroundColor: 'white'
    }
}), "NewImage_Slider"
);

interface Props {
    open: boolean,
    onClose: Function
    img: string[],
}

const NewImageSlider = (props: Props) => {
    const { Root, classes } = useStyles()
    // const [x, setX] = useState(0)
    const sliderRef = React.useRef<Slider | null>(null);
    const [index, setIndex] = useState(1)
    const imageContainer = useRef<HTMLDivElement>(null)
    const [preview, setPreview] = useState({
        show: false,
        url: ''
    })
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        // Preload images
        const preloadImages = () => {
            const images = props.img.map((url: string) => {
                const img = new Image();
                img.src = url;
                return img;
            });

            Promise.all(images.map(img => new Promise(resolve => img.onload = resolve)))
                .then(() => setImagesLoaded(true))
                .catch(error => console.error('Error preloading images:', error));
        };

        preloadImages();
    }, [props.img]);

    if (!imagesLoaded) {
        // Images are loading, you can show a loading spinner or placeholder
        return <div>Loading...</div>;
    }

    const CustomPrevArrow = () => (
        <IconButton color="primary" onClick={handlePrevClick} className={classes.prevArrow}>
            <ChevronLeftIcon fontSize="large" />
        </IconButton>
    );

    const CustomNextArrow = () => (
        <IconButton color="primary" onClick={handleNextClick} className={classes.nextArrow}>
            <ChevronRightIcon fontSize="large" />
        </IconButton>
    );

    const handlePrevClick = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev(); // Call the slickNext() method
        }
    };

    const handleNextClick = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext(); // Call the slickNext() method
        }
    };

    const settings = {
        // dots: true,
        // infinite: true,
        adaptiveHeight: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (currentSlide: number, nextSlide: number) => {
            setIndex(nextSlide + 1);
        },
    };

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            scroll='body'
            fullWidth
        >
            <Root>
                {imagesLoaded &&
                    props.img.length > 1 ?
                    <div style={{ height: 400, overflow: 'hidden' }}>
                        <Slider {...settings} ref={sliderRef}>
                            {
                                props.img.map((val: string, idx: number) => (
                                    <div key={idx} className={classes.imageContainer} ref={imageContainer} onClick={() => setPreview({
                                        show: true,
                                        url: val
                                    })}>
                                        <ImageSlide url={val} key={idx} idx={idx} currentIdx={index} />
                                        {/* <img src={val} key={idx} /> */}
                                    </div>
                                ))
                            }
                        </Slider>

                        <Box className={classes.imgIndex}>
                            <Typography variant='caption' style={{ backgroundColor: 'rgba(14,14,14,0.8)', marginRight: 10, padding: '0 8px', borderRadius: 4, textAlign: 'center' }}>{`${index} / ${props.img.length || 1}`}<br></br>Click to enlarge</Typography>
                        </Box>

                        <CustomPrevArrow />
                        <CustomNextArrow />

                        {
                            preview.show &&
                            <ImagePreview
                                image={preview.url}
                                onClose={() => setPreview({ show: false, url: '' })}
                            />
                        }
                    </div>
                    :
                    <ImagePreview
                        image={props.img[0]}
                        onClose={() => props.onClose()}
                    />
                }
            </Root>
        </Dialog>
    );
}

export default NewImageSlider;

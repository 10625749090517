import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import {
    Grid,
    Box,
    Button,
    Theme
} from '@mui/material';

/**
 * Components
 */
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';
import DataTable from '../../_components/_dataTable/DataTable';
import DefaultAxios from '../../_utils/DefaultAxios';
import Swal from 'sweetalert2';

/**
 * Utils
 */
import moment from 'moment';
import { numberToCurrency, renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    project_id?: string | null,
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        backgroundColor: "#f3f3f3",
        // padding: theme.spacing(2),
        // height: "100%"
    },
    action: {
        display: 'inline-flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    gridRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        // overflow: 'hidden'
    },
    appBar: {
        position: 'relative'
    },
    dialog: {
        backgroundColor: '#333333'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    box: {
        padding: theme.spacing(2)
    },
    boxCenter: {
        padding: theme.spacing(2),
        textAlign: "center"
    },
    textWithIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(1)
    },
    paper: {
        margin: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
}), "ProDev_ModalDetial"
)

const ModalDetailProject = (props: IProps) => {
    const { Root, classes } = useStyles();
    const params: any = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [developerDetail, setDeveloperDetail] = useState({
        id: "",
        pic_name: "",
        pic_email: "",
        pic_phone: "",
        pic_whatsapp: "",
        name: "",
        developer_name: "",
        price_start_from: "",
        primary_image: ""
    })
    const PROPERTIES_INDEX_URL = `${process.env.REACT_APP_API_URL}/project-developer/${params.project_id}/property`;
    const ENQUIRIES_INDEX_URL = process.env.REACT_APP_API_URL + '/project-developer/' + params.project_id + '/enquiry';
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    // const [reDrawDatatable2, setReDrawDatatable2] = useState(new Date().getTime());
    const reDrawDatatable2 = new Date().getTime();
    const navigate = useNavigate();

    const convertPrice = (price: string) => {
        return parseInt(price.split('.')[0]).toLocaleString()
    }

    useEffect(() => {
        setIsLoading(true);
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/project-developer/details/${params.project_id}`)
            .then(res => {
                let data = res.data
                setDeveloperDetail(data);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [params.project_id])

    const handleClose = () => {
        navigate('/project-developer');
    }

    const handleDelete = (id: string) => (event: React.SyntheticEvent) => {
        event.preventDefault();
        renderWarningButton("Apakah anda yakin ingin menghapus property ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/project-developer/${params.project_id}/property/${id}`)
                        .then(res => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000
                            }).then(res => {
                                setIsLoading(false);
                                setReDrawDatatable(new Date().getTime())
                            })
                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Error. Hubungi admin",
                                icon: 'error',
                                timer: 1000
                            })
                        })
                        .finally(() => {
                            setIsLoading(false);
                            setReDrawDatatable(new Date().getTime())
                        })
                }
            })
    }

    const handleOpenProperty = (id: string) => (event: React.SyntheticEvent) => {
        event.preventDefault();
        navigate(`/developer/${developerDetail.id}/property/edit/${id}`)
        // setReDrawDatatable(new Date().getTime())
    }

    const handleAddProperty = (event: React.SyntheticEvent) => {
        event.preventDefault();
        navigate(`/developer/${developerDetail.id}/property/add`)
    }

    const handleManageCategory = (event: React.SyntheticEvent) => {
        event.preventDefault();
        navigate(`/developer/category/${developerDetail.id}`);
    }

    const renderEditButton = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
        return (
            <>
                <div className={classes.action}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleOpenProperty(row.id)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={handleDelete(row.id)}
                    >
                        Delete
                    </Button>
                </div>
            </>
        )
    }

    const columnsTable = [
        {
            name: 'name',
            label: 'Nama Properti',
            type: 'string',
            filterable: false,
            sortable: false,
        },
        {
            name: 'bedroom',
            label: 'Bedroom',
            type: 'string',
            filterable: false,
            sortable: false,
        },
        {
            name: 'bathroom',
            label: 'Bathroom',
            type: 'string',
            filterable: false,
            sortable: false,
        },
        {
            name: 'price_start_from',
            label: 'Harga Mulai Dari',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (data: any, row: any) => {
                return (`Rp. ${numberToCurrency(data)}`)
            }
        },
        {
            name: 'property_area',
            label: 'Luas Properti',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (data: any, row: any) => {
                return (data !== null ? `${parseFloat(data).toFixed(2)} m2` : "-")
            }
        },
        {
            name: 'land_area',
            label: 'Luas Tanah',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (data: any, row: any) => {
                return (data !== null ? `${parseFloat(data).toFixed(2)} m2` : "-")
            }
        },
        {
            name: 'id',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            style: { width: "100px" },
            render: renderEditButton,
        }
    ]

    const columnsTable2 = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: false,
            sortable: false,
        },
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: false,
            sortable: false,
        },
        {
            name: 'phone',
            label: 'No Hp',
            type: 'string',
            filterable: false,
            sortable: false,
        },
        {
            name: 'message',
            label: 'Message',
            type: 'string',
            filterable: false,
            sortable: false,
        },
        {
            name: 'is_sent',
            label: 'Sent',
            type: 'string',
            filterable: false,
            sortable: false,
        },
        {
            name: 'created_at',
            label: 'Created At',
            type: 'date',
            filterable: false,
            sortable: false,
            render: (data: any, row: any) => {
                return moment(data).format('DD MMM YYYY HH:mm:ss')
            }
        }
    ]

    return (
        <ModalDetail
            open={true}
            title="Project Details"
            isLoading={isLoading}
            onCloseModal={handleClose}
            showEditButton={true}
            editButtonLink={`/project-developer/edit/${developerDetail.id}`}
        >
            <>
                <ModalSection
                    xs={12}
                    md={6}
                    title="Informasi"
                    content={[
                        ['Name', developerDetail.name],
                        ["Developer", developerDetail.developer_name, false],
                        ["Price Start From", 'Rp. ' + convertPrice(developerDetail.price_start_from + ''), false],
                    ]}
                />

                <ModalSection
                    xs={12}
                    md={6}
                    title="PIC Information"
                    content={[
                        ['PIC Name', developerDetail.pic_name],
                        ['PIC Phone', developerDetail.pic_phone],
                        ['PIC Whatsapp', developerDetail.pic_whatsapp],
                        ['PIC Email', developerDetail.pic_email],
                    ]}
                />

                <Grid item xs={12}>
                    <Root>
                        <Box style={{ marginTop: 50 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginBottom: 24 }}
                                onClick={handleAddProperty}
                            >
                                Add Properti
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginBottom: 24, float: 'right' }}
                                onClick={handleManageCategory}
                            >
                                Manage Category
                            </Button>
                            <DataTable
                                reDraw={reDrawDatatable}
                                url={PROPERTIES_INDEX_URL}
                                columns={columnsTable}
                            />
                            <hr />
                            <h3>Enquiry List</h3>
                            <hr />
                            <DataTable
                                reDraw={reDrawDatatable2}
                                url={ENQUIRIES_INDEX_URL}
                                columns={columnsTable2}
                            />
                        </Box>
                    </Root>
                </Grid>
            </>
        </ModalDetail>
    )
}

export default ModalDetailProject;
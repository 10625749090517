import React from 'react'

/* 
* Components
*/
import LandlordFormMain from './LandlordFormMain'

/* 
* Style Icons
*/
import { Card, CardContent, Theme } from '@mui/material'
import { generateStyle } from '../../_utils/DefaultStyle'

const useStyles = generateStyle((theme: Theme) => ({
    rootContainer: {
        maxWidth: '750px',
        marginBottom: '70px',
        boxShadow: '0px 3px 15px #0000001A',
        '& .Mui-disabled': {
            backgroundColor: '#eee'
        }
    },
}), "Landlord_Form"
)

const LandlordForm = () => {
    const { Root, classes } = useStyles()
    return (
        <Root>
            <Card className={classes.rootContainer}>
                <CardContent>
                    <LandlordFormMain />
                </CardContent>
            </Card>
        </Root>
    )
}

export default LandlordForm
import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { Grid, InputAdornment, MenuItem } from '@mui/material'
import TextFieldND from '../../_components/_newDesignComponents/_form/TextFieldND'
import MuiDatePicker from '../../_components/MuiDatePicker'
import DragableHorizontal from '../../_components/DragableHorizontal'
import JHSScheduleCard, { JHSScheduleCardItem } from './_components/JHSScheduleCard'
import LoadingScreen from '../../_components/LoadingScreen'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import { format } from 'date-fns'

/**
 * Icons
 */
import { Search } from '@mui/icons-material'
import { generateStyle } from '../../_utils/DefaultStyle'

interface JHSScheduleProps {

}

type State = {
    search: string
    slug_role: string
    date: Date | null
}

const JHSSchedule = (props: JHSScheduleProps) => {
    const { Root, classes } = useStyles()

    const [isLoading, setIsLoading] = useState(true)
    const [refetch, setRefetch] = useState(0)
    const [state, setState] = useState<State>({
        search: '',
        slug_role: '',
        date: new Date(),
    })

    const [items, setItems] = useState<JHSScheduleCardItem[]>([])

    const filteredItems = useMemo(() => {
        const inputValue = state.search.trim().toLowerCase()
        // Remove special chars
        const removeSpecialChar = inputValue.replace(/[^0-9a-zA-Z']+/gm, ' ');
        // Remove duplicate whitespace
        const escapedValue = removeSpecialChar.replace(/\s{2,}/gm, ' ');

        return items
            .filter((item: JHSScheduleCardItem) => {
                if (item.name.toLowerCase().includes(escapedValue)) {
                    return true
                } else if (item.schedules.filter(item => item.unit_code ? item.unit_code.toLowerCase().includes(escapedValue) : false).length) {
                    return true
                }

                return false
            })
            .filter(item => state.slug_role ? item.role_slug === state.slug_role : true)
            .filter(item => item.name !== 'Unassigned')
    }, [state.search, state.slug_role, items])


    const unAssignedSchedule: JHSScheduleCardItem | null = useMemo(() => {
        const schedule = items.find(item => item.name === 'Unassigned')
        if (schedule) {
            return {
                ...schedule,
                schedules: schedule.schedules.filter(schedule => {
                    if (!state.slug_role) {
                        return true
                    } else {
                        return schedule.division === state.slug_role.replace('-', '_')
                    }
                })
            }
        }

        return null

    }, [items, state.slug_role])


    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [state.date])

    useEffect(() => {
        if (refetch) {
            fetchData()
        }
        // eslint-disable-next-line
    }, [refetch])

    const fetchData = () => {
        setIsLoading(true)
        setState(prev => ({
            ...prev,
            search: '',
        }))

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/schedule-overview-jhs`, {
            params: {
                date: format(state.date || new Date(), 'yyyy-MM-dd')
            }
        })
            .then(res => res.data)
            .then(data => {
                setItems(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const onSelectChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setState(prev => ({
            ...prev,
            slug_role: value === 'all' ? '' : value,
        }))
    }

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target

        setState(prev => ({
            ...prev,
            search: value,
        }))
    }

    const onDateChange = (value: Date | null) => {
        setState(prev => ({
            ...prev,
            date: value,
        }))
    }

    return (
        <Root>
            <div className={classes.root}>
                <LoadingScreen open={isLoading} fullScreen />
                <div className='header'>
                    <Grid container>
                        <Grid item xs={4.5} display='flex' alignItems='center'>
                            {/* <Assignment htmlColor='#484848' sx={{ mr: 1.5 }} />
                        <Typography variant='h6' color='#484848'>
                            Activity Schedule
                        </Typography> */}
                        </Grid>
                        <Grid item xs={3} sx={{ pr: 2.5 }}>
                            <TextFieldND
                                value={state.search}
                                onChange={onSearchChange}
                                fullWidth
                                placeholder='Cari nama / Kode unit'
                                variant='outlined'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start' sx={{ fontSize: 17 }}>
                                            <Search htmlColor='#484848' fontSize='medium' />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ pr: 2.5 }}>
                            <TextFieldND
                                value={state.slug_role || 'all'}
                                onChange={onSelectChanged}
                                fullWidth
                                placeholder='Group'
                                variant='outlined'
                                select
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="jhs-cleaning">Cleaning</MenuItem>
                                <MenuItem value="jhs-fixing">Fixing</MenuItem>
                                <MenuItem value="jhs-ac">AC</MenuItem>
                                <MenuItem value="jhs-photo">Foto</MenuItem>
                            </TextFieldND>
                        </Grid>
                        <Grid item xs={2.5}>
                            <MuiDatePicker
                                onChange={onDateChange}
                                value={state.date}
                                newDesign
                            />
                        </Grid>
                    </Grid>
                </div>
                <DragableHorizontal className="content">
                    {
                        unAssignedSchedule ?
                            <JHSScheduleCard
                                {...unAssignedSchedule}
                                setIsLoading={setIsLoading}
                                setRefetch={setRefetch}
                                fullHeight
                            />
                            : null
                    }
                    {
                        filteredItems.map((item, index) =>
                            <JHSScheduleCard
                                {...item}
                                key={index}
                                setIsLoading={setIsLoading}
                                setRefetch={setRefetch}
                            />
                        )
                    }
                </DragableHorizontal>
            </div >
        </Root>
    )
}

const useStyles = generateStyle(() => ({
    root: {
        margin: -24,
        height: 'calc(100vh - 64px)',
        // backgroundColor: '#F5F7FB',
        '& > .header': {
            // height: 55,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            display: 'flex',
            justifyContent: 'center',
        },
        '& > .content': {
            height: 'calc(100vh - 64px - 62px)',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignContent: 'flex-start',
            overflowY: 'hidden',
            overflowX: 'auto',
        },
    },
}), "JHSSchedule")


export default JHSSchedule

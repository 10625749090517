import React, { useReducer, useEffect } from 'react';
import { Button } from '@mui/material';
import { MentionsInput, Mention } from 'react-mentions'

/**
 * Icon
 */
import defaultPhoto from '../Assets/Images/png/default-photo.png'

/**
 * CSS
 */
import './css/mention-style.css';

/**
 * Utils
 */
import { dateFormat } from '../_utils/Helper';
import { generateStyle } from '../_utils/DefaultStyle';

interface Props {
    comments: IComment[];
    users: {
        id: string;
        display: string;
    }[],
    onReply?: Function;
}

export interface IMentionData {
    id: string;
    display: string;
    index: number;
    plainTextIndex: number;
    type: string;
}

interface IState {
    value: string;
    plainValue: string;
    mentionData: IMentionData[];
}

interface IAction {
    name: string,
    value: any,
    type: string
}

export interface IComment {
    avatar: string;
    comment: string;
    name: string;
    created_at: string;
}

const useStyles = generateStyle(theme => ({
    root: {
        width: '100%',
        backgroundColor: "#eceff1",
        padding: "10px"
    },
    commentRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: "white",
        borderRadius: '3px',
        border: '2px solid #eceff1',
        margin: '10px 0'
    },
    imageContainer: {
        // display: 'inline-block',
        minWidth: '50px',
        '& img': {
            width: '35px',
            borderRadius: '50%'
        }
    },
    commentContainer: {
        '& label': {
            fontWeight: '700',
            fontSize: '13px',
            textTransform: 'capitalize'
        },
        '& span': {
            fontWeight: '400',
            fontSize: '10px',
            color: '#989898',
            marginLeft: '5px'
        }
    },
    commentText: {
        fontSize: "0.900rem",
        whiteSpace: 'pre-wrap'
    }
}), "Comment_List")

const CommentList = (props: Props) => {
    const { comments, users, onReply } = props;
    const { Root, classes } = useStyles();

    const initialState = {
        value: '',
        plainValue: '',
        mentionData: []
    };

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    useEffect(() => {
        setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
        // eslint-disable-next-line
    }, [comments]);

    const handleChange = (e: any, newValue: string, newPlainTextValue: string, mentions: IMentionData[]) => {
        const newState = { ...inputState };

        newState.value = newValue;
        newState.plainValue = newPlainTextValue;
        newState.mentionData = mentions;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleReply = () => {
        if (!onReply) return

        if (!inputState.value.trim()) {
            return;
        }

        const mentionData = [];

        for (let data of inputState.mentionData) {
            mentionData.push({
                name: data.display,
                uid: data.id
            });
        }
        onReply(inputState.plainValue, mentionData);
    }

    const renderComments = () => {
        return comments.map((comment, key: number) =>
            <div key={key} className={classes.commentRoot}>
                <div className={classes.imageContainer}>
                    <img src={comment.avatar ? comment.avatar : defaultPhoto} alt="pic" />
                </div>
                <div className={classes.commentContainer}>
                    <label>
                        {comment.name}
                        <span>{dateFormat(comment.created_at, 'DD MMM YYYY [at] HH mm')}</span>
                    </label>
                    <div className={classes.commentText} dangerouslySetInnerHTML={{ __html: comment.comment }}></div>
                </div>
            </div>
        )
    }

    return (
        <Root>
            <div className={classes.root}>
                {renderComments()}
                {
                    onReply &&
                    <div>
                        <MentionsInput
                            value={inputState.value}
                            onChange={handleChange}
                            markup="@{{__type__||__id__||__display__}}"
                            placeholder="Write comment"
                            className="mentions"
                            style={{ backgroundColor: '#fff' }}
                        >
                            <Mention
                                type="user"
                                trigger="@"
                                data={users}
                                className="mentions__mention"
                            />
                        </MentionsInput>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ float: 'right' }}
                            onClick={handleReply}
                        >
                            Reply
                        </Button>
                    </div>
                }
            </div>
        </Root>
    );
}

export default CommentList;
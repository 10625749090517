import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Theme } from '@mui/material'
import moment from 'moment'
import React, { useMemo } from 'react'
import Chip, { ChipColor } from '../../../../_components/Chip'
import { capitalizeFirstCharacter, numberToCurrency } from '../../../../_utils/Helper'
import { getTransactionType } from '../../../../_utils/ConstData';
import { TransactionDebit } from './TransactionList'
import { generateStyle } from '../../../../_utils/DefaultStyle'

interface DebitDetailModalProps {
    debit: TransactionDebit | null
    onClose: () => void
    onCancel: () => void
    isCancelLoading: boolean
    closingData: any
}

const DebitDetailModal = ({ debit, onClose, onCancel, isCancelLoading, closingData }: DebitDetailModalProps) => {
    const { Root, classes } = useStyles()

    const parsedTransactionType = useMemo(() => {
        if (!debit) return '-'

        let transactionType;

        switch (debit.transaction_type) {
            case 'other_deposit':
                transactionType = debit.other_transaction_type_label || '-'
                break;
            case 'other':
                transactionType = debit.other_transaction_type_label || '-'
                break;
            default:
                transactionType = getTransactionType(debit.transaction_type)
                break;
        }

        return transactionType;

        // eslint-disable-next-line
    }, [debit?.transaction_type, debit?.other_transaction_type_label])

    const renderStatus = useMemo(() => {
        if (!debit?.status) return '-'
        let color: ChipColor = undefined

        switch (debit.status) {
            case 'proposed':
                color = 'default'
                break
            case 'approved':
                color = 'warning'
                break
            case 'processed':
                color = 'primary'
                break
            case 'released':
                color = 'success'
                break
            case 'canceled':
                color = 'secondary'
                break
        }

        return (
            <Chip
                label={capitalizeFirstCharacter(debit.status)}
                color={color}
            />
        )
        // eslint-disable-next-line
    }, [debit?.status])

    return (
        <Dialog fullWidth maxWidth="md" open={!!debit} onClose={onClose}>
            <Root>
                <DialogTitle>
                    {debit?.code} {debit?.is_manual_assign ? '[Manual Assign]' : ''}
                </DialogTitle>
                <DialogContent>
                    {
                        !!debit &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} className={classes.dialogContent}>
                                <span className={classes.label}>
                                    Status
                                </span>
                                <span className={classes.value}>
                                    {renderStatus}
                                </span>
                                <span className={classes.label}>
                                    Tipe
                                </span>
                                <span className={classes.value}>
                                    Debit
                                </span>
                                <span className={classes.label}>
                                    Tanggal
                                </span>
                                <span className={classes.value}>
                                    {moment(debit.date).format("DD MMMM YYYY")}
                                </span>
                                <span className={classes.label}>
                                    Proposed By
                                </span>
                                <span className={classes.value}>
                                    {debit.proposed_by || '-'}
                                </span>
                                <span className={classes.label}>
                                    Deskripsi
                                </span>
                                <span className={classes.value}>
                                    {debit.description || '-'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.dialogContent}>
                                <span className={classes.label}>
                                    Jenis Transaksi
                                </span>
                                <span className={classes.value}>
                                    {parsedTransactionType}
                                </span>
                                <span className={classes.label}>
                                    Bank
                                </span>
                                <span className={classes.value}>
                                    {debit.bank_information}
                                </span>
                                <span className={classes.label}>
                                    Nominal
                                </span>
                                <span className={classes.value}>
                                    {numberToCurrency(debit.amount, 'Rp')}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.dialogContent}>
                                <span className={classes.label}>
                                    Deposit Owner
                                </span>
                                <span className={classes.value}>
                                    {closingData.holded_deposit_owner ? numberToCurrency(closingData.holded_deposit_owner, 'Rp') : '-'}
                                </span>
                                <span className={classes.label}>
                                    Deposit Jendela
                                </span>
                                <span className={classes.value}>
                                    {closingData.holded_deposit_office ? numberToCurrency(closingData.holded_deposit_office, 'Rp') : '-'}
                                </span>
                                <span className={classes.label}>
                                    GMV Transaction
                                </span>
                                <span className={classes.value}>
                                    {closingData.gmv_transaction ? numberToCurrency(closingData.gmv_transaction, 'Rp') : '-'}
                                </span>
                                <span className={classes.label}>
                                    GMV Financing
                                </span>
                                <span className={classes.value}>
                                    {closingData.gmv_financing ? numberToCurrency(closingData.gmv_financing, 'Rp') : '-'}
                                </span>
                                <span className={classes.label}>
                                    Basic Price
                                </span>
                                <span className={classes.value}>
                                    {closingData.basic_price ? numberToCurrency(closingData.basic_price, 'Rp') : '-'}
                                </span>
                                <span className={classes.label}>
                                    Komisi
                                </span>
                                <span className={classes.value}>
                                    {closingData.revenue_jendela ? numberToCurrency(closingData.revenue_jendela, 'Rp') : '-'}
                                </span>
                                <span className={classes.label}>
                                    Komisi Referal
                                </span>
                                <span className={classes.value}>
                                    {closingData.referral_commission ? numberToCurrency(closingData.referral_commission, 'Rp') : '-'}
                                </span>
                                <span className={classes.label}>
                                    Komisi Cobroke
                                </span>
                                <span className={classes.value}>
                                    {closingData.cobroke_fee ? numberToCurrency(closingData.cobroke_fee, 'Rp') : '-'}
                                </span>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    {
                        debit?.status.toLowerCase() === 'proposed' &&
                        <div style={{ alignSelf: 'flex-end', paddingBottom: 16 }}>
                            <Button
                                size='small'
                                color='secondary'
                                variant='contained'
                                onClick={onCancel}
                                disabled={isCancelLoading}
                            >
                                {
                                    isCancelLoading ?
                                        <CircularProgress size={18} />
                                        :
                                        'Cancel'
                                }
                            </Button>
                        </div>
                    }
                </DialogActions>
            </Root>
        </Dialog>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 8,
        fontSize: 16,
    },
    value: {
        marginBottom: 16,
        fontSize: 16,
    },
}), "DebitDetail_Modal")

export default DebitDetailModal

import React, { useState, useReducer, useEffect } from 'react';
import { Theme, Grid, TextField, Button } from '@mui/material';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from "react-router-dom";

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import TextEditor from '../../_components/_form/TextEditor'

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IState {
    email_to: string;
    title: string;
    description: string;
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

interface IErrorState {
    email_to: string;
    title: string;
    description: string;
}

interface IErrorAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) => ({
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        margin: theme.spacing(0, 1),
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'right',
        marginBottom: '10px'
    }
}), "CareerForm"
)

const CareerForm = () => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/career';
    const params: any = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // Error State
    const errorReducer = (state: IErrorState, action: IErrorAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                email_to: '',
                title: '',
                description: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [errorState, setErrorState] = useReducer(errorReducer, {
        email_to: '',
        title: '',
        description: '',
    });

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                email_to: '',
                title: '',
                description: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        email_to: '',
        title: '',
        description: '',
    });

    const checkValidation = () => {
        let isValid = true;
        let newError = { ...errorState };
        if (!inputState.title) {
            newError.title = 'Field wajib diisi';
            isValid = false;
        } else {
            newError.title = '';
        }
        if (!inputState.email_to) {
            newError.email_to = 'Field wajib diisi';
            isValid = false;
        } else {
            newError.email_to = '';
        }
        setErrorState({ type: 'REPLACE_STATE', name: '', value: newError });
        return isValid;
    }

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
        }
    }

    const submitForm = () => {

        if (!checkValidation()) {
            return;
        }

        let axios;

        if (params.career_id) {
            axios = DefaultAxios.patch(`${API_URL}/${params.career_id}`, inputState)
            setLoading(true);
            axios
                .then(res => {
                    Swal.fire({
                        title: "Career Updated",
                        icon: 'success',
                        onAfterClose: () => {
                            navigate(`/career`)
                        },
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        } else {
            axios = DefaultAxios.post(`${API_URL}`, inputState);
            setLoading(true);
            axios
                .then(res => {
                    Swal.fire({
                        title: "Career Submitted",
                        icon: 'success',
                        onAfterClose: () => {
                            navigate(`/career`)
                        },
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        if (params.career_id) {
            setLoading(true);
            DefaultAxios
                .get(`${API_URL}/${params.career_id}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                    setLoading(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log(inputState)


    return (
        <Root>
            <LoadingScreen open={loading} fullScreen />
            <Grid container spacing={2}>

                <Grid item xs={12} sm={12}>
                    <TextField
                        error={!!errorState.title}
                        label="Job Title"
                        variant="outlined"
                        name="title"
                        value={inputState.title}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={errorState.title}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <TextField
                        error={!!errorState.email_to}
                        label="Email to"
                        variant="outlined"
                        name="email_to"
                        value={inputState.email_to}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={errorState.email_to}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    {/* <h4 className={classes.title}>Notes</h4> */}
                    <Grid item xs={12}>
                        <h3>Description</h3>
                        <TextEditor
                            name="description"
                            value={inputState.description}
                            onChange={(name: string, value: string) => setInputState({ name: 'description', value: value, type: 'SET_ITEM' })}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.gridSubmit}>
                    <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                        Submit
                    </Button>
                </Grid>
            </Grid>

        </Root>
    );
}

export default CareerForm;

import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from '@mui/material';

/**
 * Components
 */
import MuiDatePicker from '../../../_components/MuiDatePicker';

/**
 * Utils
 */
import Swal from 'sweetalert2';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../_utils/Helper';

interface IProps {
    open: boolean;
    closingId: string;
    handleClose: Function;
}

const ChangeFirstPaymentDateModal = ({ open, closingId, handleClose }: IProps) => {
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const handleSubmit = () => {
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/new-closing/${closingId}/change-first-payment-date`, { first_payment_date: selectedDate })
            .then(res => {
                Swal.fire({
                    title: "Tanggal Pembayaran Pertama berhasil diubah",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        handleClose();
                    })
            })
            .catch(error => {
                generalErrorHandler(error)
            })
    }

    return (
        <Dialog open={open} onClose={() => handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Change First Payment Date</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Masukkan tanggal pembayaran pertama closing ini.
                </DialogContentText>

                <MuiDatePicker
                    label="Tanggal Pembayaran Pertama"
                    value={selectedDate}
                    disableToolbar
                    onChange={handleDateChange}
                    variant="inline"
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChangeFirstPaymentDateModal
import { Button, Grid, Link, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable'
import LoadingScreen from '../../_components/LoadingScreen'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper'
import { format } from 'date-fns'
import { generateStyle } from '../../_utils/DefaultStyle'

type IUnitDelete = {
    id: string | number
    code: string
    request_deleted_by_label: string
    deleted_reason: string
}

const UnitDeleteList = () => {
    const { Root, classes } = useStyles()
    const API_URL = process.env.REACT_APP_API_URL + '/unit-request-delete';
    const [reDrawDataTable, setReDrawDataTable] = useState(new Date().getTime())
    const [isLoading, setIsLoading] = useState(false)
    const [currentTab, setCurrentTab] = useState(0)

    const requestColumns = [
        {
            name: 'code',
            label: 'Kode',
            filterable: true,
            sortable: true,
            render: (data: string) => (
                <Link
                    target='_blank'
                    component={RouterLink}
                    to={`/unit/${data}`}
                >
                    {data}
                </Link>
            )
        },
        { name: 'request_deleted_by_label', label: 'Requested By', filterable: true, sortable: true },
        { name: 'deleted_reason', label: 'Alasan Delete', filterable: true, sortable: true },
        {
            name: 'EXTRA',
            label: 'Action',
            render: (rows: IUnitDelete) => {
                return (
                    <Grid style={{ display: 'flex', gap: 10 }}>
                        <Button
                            variant='contained'
                            color="primary"
                            onClick={() => handleAction('approve', rows.id)}
                        >
                            Approve
                        </Button>
                        <Button
                            variant='contained'
                            color="secondary"
                            onClick={() => handleAction('reject', rows.id)}
                        >
                            Reject
                        </Button>
                    </Grid>
                )
            }
        },
    ]

    const historyColumns = [
        {
            name: 'deleted_at',
            label: 'Date',
            type: 'date',
            filterable: true,
            sortable: false,
            render: (data: string) => data ? format(new Date(data), 'dd MMM yyyy') : '-',
        },
        {
            name: 'code',
            label: 'Kode',
            filterable: true,
            sortable: false,
        },
        { name: 'deleted_reason', label: 'Alasan Delete', filterable: true, sortable: false },
        { name: 'request_deleted_by_label', label: 'Requested By', filterable: true, sortable: false },
        { name: 'deleted_by_label', label: 'Deleted By', filterable: true, sortable: false },
    ]

    const handleAction = (type: 'approve' | 'reject', id: number | string) => {
        renderWarningButton(`Apakah anda yakin untuk meng-${type} request delete pada unit ini?`)
            .then(res => {
                if (res.value) {
                    setIsLoading(true)
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-request-delete/${type}`, { id: id })
                        .then(res => {
                            renderToastSuccess(type === 'approve' ?
                                'Unit Requested Delete Approved!' :
                                'Unit Requested Delete Rejected!'
                            )
                            setReDrawDataTable(new Date().getTime())
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    const renderTabTable = () => {
        return (
            <div className={classes.tabContainer}>
                <Tabs
                    value={currentTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(e, value) => {
                        setCurrentTab(value)
                    }}
                >
                    <Tab label="Request" />
                    <Tab label="History" />
                </Tabs>
            </div>
        )
    }

    return (
        <Root>
            <Grid container>
                <LoadingScreen open={isLoading} fullScreen />
                <Grid item xs={12}>
                    {
                        currentTab === 0 ?
                            <DataTable
                                columns={requestColumns}
                                url={API_URL}
                                reDraw={reDrawDataTable}
                                tab={renderTabTable()}
                                key={'request'}
                            />
                            :
                            <DataTable
                                columns={historyColumns}
                                url={API_URL + '/history'}
                                reDraw={reDrawDataTable}
                                tab={renderTabTable()}
                                key={'history'}
                            />
                    }
                </Grid>
            </Grid>
        </Root>
    )
}

const useStyles = generateStyle(theme => ({
    tabContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '1px solid #ddd',
        '& .DateInput_input__focused': {
            borderBottom: '0',
        },
        '& .date-container': {
            width: '25%',
            display: 'flex',
            alignItems: 'center',
        },
        '& .extend-status': {
            marginLeft: 'auto',
            padding: '0px 16px',
            borderLeft: '1px solid #ddd',
            fontSize: 12,
            display: 'flex',
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            '& p span': {
                color: 'red'
            }
        },
    },
}), "UnitDeleteList")

export default UnitDeleteList
import React, { useReducer, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    MenuItem,
    Button,
    Link,
    Theme,
    IconButton,
    Dialog,
    Collapse
} from '@mui/material';
/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import UnitAdvisorListCard from "./_components/UnitAdvisorListCard"
import ButtonRadioGroup from '../../_components/_formElements/ButtonRadioGroup';
// import { IMentionList } from '../../_components/_form/MentionTextArea';

/**
 * Icons
 */
import VisibilityIcon from '@mui/icons-material/Visibility';
import UnitAdvisorDetailModalNew, { IUnitAdvisorDetailData } from './UnitAdvisorDetailModalNew';
import { KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, isMobile } from '../../_utils/Helper';
import { format } from 'date-fns';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IState {
    apartment_id: string;
    is_available: string;
    category: string;
    basic_price_1: string;
    is_entrusted_key: string;
    rent_or_sell: string;
    display_price: string;
    floor_level: string;
    bedroom: string;
    bathroom: string;
    maid_room: string;
    furnished: string;
    tower: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const useStyles = generateStyle((theme: Theme) => ({
    hr: {
        margin: '25px 0'
    },
    buttonFilter: {
        marginTop: "20px"
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    label: {
        fontSize: 14,
        color: "#484848",
        marginBottom: 10,
        display: 'block'
    },
}), "UnitAdvisorList"
)

const UnitAdvisorList = () => {
    const API_URL = `${process.env.REACT_APP_API_URL}/unit-search-agent`;
    const { Root, classes } = useStyles();
    const columns = [
        {
            name: 'code',
            label: 'Code',
            filterable: false,
            render: (data: any, row: any) => {
                return (
                    <>
                        <Link
                            href="#"
                            onClick={() => { handleOpenModal(row.id) }}
                        >
                            {data}
                        </Link>
                        <IconButton
                            color="primary"
                            size="small"
                            onClick={() => { window.open(`${process.env.REACT_APP_MAIN_URL}/${data}`) }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            name: 'is_owner',
            label: 'Ownership',
            filterable: false,
            render: (data: any) => {
                return +data === 1 ? 'Owner' : 'Agent';
            }
        },
        {
            name: 'tower',
            label: 'Tower',
            filterable: false
        },
        {
            name: 'floor_level',
            label: 'Floor',
            filterable: false
        },
        {
            name: 'unit_number',
            label: 'No. Unit',
            filterable: false
        },
        {
            name: 'EXTRA',
            label: 'Support Price',
            filterable: false,
            render: (row: any) => {
                let supported_prices = [];
                if (row.price_per_1_month > 0) {
                    supported_prices.push('1');
                }
                if (row.price_per_3_months > 0) {
                    supported_prices.push('3');
                }
                if (row.price_per_6_months > 0) {
                    supported_prices.push('6');
                }
                if (row.price_per_12_months > 0) {
                    supported_prices.push('12');
                }

                return supported_prices.join(',');
            }
        },
        {
            name: 'is_available',
            label: 'Available',
            filterable: false,
            render: (data: any) => {
                return +data === 1 ? 'Yes' : 'No';
            }
        },
        {
            name: 'rent_date',
            label: 'Available Date',
            filterable: false,
            render: (data: any) => {
                return data ? format(new Date(data), 'dd MMMM yyyy') : "-"
            }
        },
        {
            name: 'updated_at',
            label: 'Updated At',
            filterable: false,
            render: (data: any) => {
                return +data === 1 ? 'Yes' : 'No';
            }
        },
        {
            name: 'category',
            label: 'Category',
            filterable: false,
            render: (data: any) => {
                let label = '';
                switch (+data) {
                    case 1:
                        label = 'Star Listing';
                        break;
                    case 3:
                        label = 'Star Listing On Hold';
                        break;
                    case 2:
                        label = 'Verified';
                        break;
                    case 0:
                        label = 'Verified';
                        break;
                    default:
                        break;
                }

                return label;
            }
        },
    ];

    const bedroomOptions = [
        { value: '', label: 'All' },
        { value: '0', label: 'Studio' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3+' },
    ];

    const bathroomMaidroomOptions = [
        { value: '', label: 'All' },
        { value: '0', label: '0' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3+' },
    ];

    const categoryOptions = [
        { value: '', label: 'All' },
        { value: '1', label: 'Star Listing' },
        { value: '0', label: 'Reguler' },
    ];

    const entrustedKeyOptions = [
        { value: '', label: 'All' },
        { value: '1', label: 'Ya' },
        { value: '0', label: 'Tidak' },
    ];

    const furnishedOptions = [
        { value: '', label: 'All' },
        { value: 'full', label: 'Full' },
        { value: 'semi', label: 'Semi' },
        { value: 'non', label: 'Non' },
    ];

    const availableOptions = [
        { value: '', label: 'All' },
        { value: '1', label: 'Ya' },
        { value: '0', label: 'Tidak' },
    ];

    const initialState: IState = {
        apartment_id: '',
        is_available: '',
        category: '',
        basic_price_1: '',
        is_entrusted_key: '',
        rent_or_sell: '0',
        display_price: '',
        floor_level: '',
        bedroom: '',
        bathroom: '',
        maid_room: '',
        furnished: '',
        tower: ''
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [unitId, setUnitId] = useState('');
    const [moreFilter, setMoreFilter] = useState(false);
    const [unit, setUnit] = useState<IUnitAdvisorDetailData>({} as IUnitAdvisorDetailData);
    const [modalState, setModalState] = useState(false);
    const [modalList, setModalList] = useState(false);
    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [openedData, setOpenedData] = useState<any[]>([])

    // useEffect(() => {
    //     loadUsers();
    // }, []);
    const handleOpen = () => setModalState(true);
    const handleClose = () => setModalState(false);

    const handleOpenList = () => setModalList(true);
    const handleCloseList = () => setModalList(false);

    const checkCookie = (cname: string) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";

    }

    const handleOpenModal = (id: string) => {
        fetchDetail(id, true);
        let seen = checkCookie("pm_seen_unit")
        if (!seen.includes(id)) {
            if (openedData) {
                setOpenedData(openedData.concat([id]))
            } else {
                setOpenedData([id])
            }

            var expired = new Date();
            expired.setHours(23, 59, 59, 0);

            document.cookie = `pm_seen_unit=${openedData.concat([id])};expires=${expired};`
        }
    }

    const handleOpenModalList = () => {
        handleOpenList()
    }

    const fetchDetail = (unitId: string, track: boolean = false) => {
        if (!unitId) {
            alert('Harap pilih kode unitnya terlebih dahulu dari dropdown');
            return false;
        }

        DefaultAxios.post(`${API_URL}/detail`, { unitId })
            .then(res => {
                setUnit(res.data);
                handleOpen();
                if (track) {
                    trackActivity({ unitId: unitId });
                }
            })
            .catch(err => {
                generalErrorHandler(err);
            })
    }

    const trackActivity = (param: Object) => {
        DefaultAxios.post(`${API_URL}/track-activity`, param)
    }

    // const loadUsers = () => {
    //     DefaultAxios
    //         .get(`${process.env.REACT_APP_API_URL}/user/mention-list`)
    //         .then(res => {
    //             setUsers(res.data);
    //         })
    // }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputState({ name: e.target.name, value: e.target.value, type: 'SET_ITEM' });
    }

    const handleButtonGroupChange = (name: string, value: string) => {
        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        if (name === 'unit_id') {
            setUnitId(String(value.id));
        } else {
            setInputState({ name, value: value.id, type: 'SET_ITEM' });
        }
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        if (name === 'unit_id') {
            setUnitId('');
        } else {
            setInputState({ name, value: null, type: 'SET_ITEM' });
        }
    }

    useEffect(() => {
        let seen = checkCookie("pm_seen_unit")
        var seenNumber = seen.split(',').map(Number);
        if (seen) {
            setOpenedData(seenNumber)
        }
        // document.cookie = `pm_seen_unit=`
    }, [])

    // console.log(document.cookie)

    return (
        <Root>
            {/* <UnitAdvisorDetailModal 
                open={modalState}
                onClose={handleClose}
                unit={unit}
                comments={unit.comments || []}
                users={users}
                fetchDetail={fetchDetail}
                trackActivity={trackActivity}
            /> */}
            <Dialog
                fullScreen
                open={modalState}
                onClose={handleClose}
            >
                <UnitAdvisorDetailModalNew
                    data={unit}
                    fetchDetail={fetchDetail}
                    onClose={handleClose}
                />
            </Dialog>

            <ModalDetail
                open={modalList}
                title="List Unit Advisor"
                onCloseModal={handleCloseList}
                isLoading={false}
                showEditButton={false}
            >
                <Grid item xs={12}>
                    <UnitAdvisorListCard
                        {...inputState}
                        openedData={openedData}
                        openDetail={(id: string) => { handleOpenModal(id) }}
                    />
                </Grid>
            </ModalDetail>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <span className={classes.label}>Cari Berdasarkan Kode Unit</span>
                </Grid>
                <Grid item xs={9} style={{ paddingTop: 0 }}>
                    <AsyncAutoComplete
                        placeholder="Masukkan Kode Unit"
                        name="unit_id"
                        onChange={handleAutocomplete}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/unit?show_available_only=1`}
                    />
                </Grid>
                <Grid item xs={3} className={classes.buttonContainer} style={{ paddingTop: 0 }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleOpenModal(unitId)}
                        size='large'
                        style={{
                            height: 56,
                            width: 56,
                        }}
                    >
                        <Search fontSize='large' />
                    </Button>
                </Grid>
            </Grid>
            <hr className={classes.hr} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <span className={classes.label}>Cari Apartemen</span>
                    <AsyncAutoComplete
                        name="apartment_id"
                        onChange={handleAutocomplete}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/apartment`}
                        placeholder="Nama Apartment"
                        iconSearch
                    />
                </Grid>

                <Grid item xs={12}>
                    <span className={classes.label} style={{ marginBottom: 0 }}>Bedroom</span>
                    <ButtonRadioGroup
                        name='bedroom'
                        value={inputState.bedroom}
                        onClick={handleButtonGroupChange}
                        options={bedroomOptions.filter(opt => opt.value !== "").map(opt => [opt.value, opt.label])}
                        containerStyle={{
                            marginBottom: 0,
                            marginTop: 0,
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                        radioStyle={2}
                    />
                </Grid>

                <Grid item xs={12}>
                    <span className={classes.label} style={{ marginBottom: 0 }}>Furnish</span>
                    <ButtonRadioGroup
                        name='furnished'
                        value={inputState.furnished}
                        onClick={handleButtonGroupChange}
                        options={furnishedOptions.filter(opt => opt.value !== "").map(opt => [opt.value, opt.label])}
                        containerStyle={{
                            marginBottom: 0,
                            marginTop: 0,
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                        radioStyle={2}
                    />
                </Grid>

                <Grid item xs={12}>
                    <span className={classes.label} style={{ marginBottom: 0 }}>Titip Kunci</span>
                    <ButtonRadioGroup
                        name='is_entrusted_key'
                        value={inputState.is_entrusted_key}
                        onClick={handleButtonGroupChange}
                        options={entrustedKeyOptions.filter(opt => opt.value !== "").map(opt => [opt.value, opt.label])}
                        containerStyle={{
                            marginBottom: 0,
                            marginTop: 0,
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                        radioStyle={2}
                    />
                </Grid>

                <Grid item xs={12}>
                    <span className={classes.label} style={{ marginBottom: 0 }}>Available</span>
                    <ButtonRadioGroup
                        name='is_available'
                        value={inputState.is_available}
                        onClick={handleButtonGroupChange}
                        options={availableOptions.filter(opt => opt.value !== "").map(opt => [opt.value, opt.label])}
                        containerStyle={{
                            marginBottom: 0,
                            marginTop: 0,
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                        radioStyle={2}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Collapse in={moreFilter}>
                        <Grid container spacing={3}>
                            {/* <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Tower"
                                    name="tower"
                                    value={inputState.tower}
                                    onChange={handleChange}
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <span className={classes.label}>Lantai</span>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="floor_level"
                                    value={inputState.floor_level}
                                    onChange={handleChange}
                                    placeholder="Dapat gunakan >,<,>=,<=,=,&"
                                    helperText="*Contoh: >=12"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <span className={classes.label}>Range Harga per Bulan</span>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="display_price"
                                    value={inputState.display_price}
                                    onChange={handleChange}
                                    placeholder="Dapat gunakan >,<,>=,<=,=,&"
                                    helperText="*Contoh: <8.000.000&>2.000.000"
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <span className={classes.label} style={{ marginBottom: 0 }}>Bathroom</span>
                                <ButtonRadioGroup
                                    name='bathroom'
                                    value={inputState.bathroom}
                                    onClick={handleButtonGroupChange}
                                    options={bathroomMaidroomOptions.filter(opt => opt.value !== "" && opt.value !== '0').map(opt => [opt.value, opt.label])}
                                    containerStyle={{
                                        marginBottom: 0,
                                        marginTop: 0,
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                    radioStyle={2}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <span className={classes.label} style={{ marginBottom: 0 }}>Maid Room</span>
                                <ButtonRadioGroup
                                    name='maid_room'
                                    value={inputState.maid_room}
                                    onClick={handleButtonGroupChange}
                                    options={bathroomMaidroomOptions.filter(opt => opt.value !== "").map(opt => [opt.value, opt.label])}
                                    containerStyle={{
                                        marginBottom: 0,
                                        marginTop: 0,
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                    radioStyle={2}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <span className={classes.label}>Category</span>
                                <TextField
                                    fullWidth
                                    select
                                    variant="outlined"
                                    name="category"
                                    value={inputState.category}
                                    onChange={handleChange}
                                >
                                    {categoryOptions.map((item, key) => {
                                        return <MenuItem key={key} value={item.value}>{item.label}</MenuItem>
                                    })}
                                </TextField>
                            </Grid>

                            {/* <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    select
                                    variant="outlined"
                                    label="Rent/Sell"
                                    name="rent_or_sell"
                                    value={inputState.rent_or_sell}
                                    onChange={handleChange}
                                    disabled
                                >
                                    {rentOrSellOptions.map((item, key) => {
                                        return <MenuItem key={key} value={item.value}>{item.label}</MenuItem>
                                    })}
                                </TextField>
                            </Grid> */}
                        </Grid>
                    </Collapse>
                </Grid>

                <Grid container xs={12} justifyContent="center" className={classes.buttonFilter}>
                    <Button size="small" variant="text" color='inherit' onClick={() => setMoreFilter(!moreFilter)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>
                        {moreFilter ? 'Hide Filter' : 'More Filter'}
                        {moreFilter ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </Button>
                </Grid>
            </Grid>
            <div className={classes.hr} />
            {
                !isMobile() ?
                    <DataTable
                        url={API_URL}
                        columns={columns}
                        customParams={inputState}
                        seenId={openedData}
                    />
                    : <div style={{ textAlign: 'center' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleOpenModalList()}
                            fullWidth
                        >
                            Submit
                        </Button>
                    </div>
            }
        </Root>
    );
}

export default UnitAdvisorList;
import {
    Box,
    Grid,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Theme,
} from "@mui/material";
import React, { useState } from 'react';
import TextArea from '../../_components/_form/TextArea';
import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';
import LoadingScreen from '../../_components/LoadingScreen';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from "../../_utils/Helper";
import { generateStyle } from "../../_utils/DefaultStyle";

//Utils

// import { useSnackbar } from 'notistack';

const useStyles = generateStyle((theme: Theme) =>
({
    main_container: {
        margin: theme.spacing(2),
    },
    container: {
        padding: '0 0.5rem',
        display: 'flex',
        flexDirection: 'column',
        // marginBottom: theme.spacing(10),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabSpacing: {
        marginRight: '1rem',
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(1)
    },
    formContainer: {
        marginTop: theme.spacing(2),
        display: "flex"
    },
    inputText: {
        flexGrow: 1
    },
    buttonSubmit: {
        fontSize: "14px",
        margin: theme.spacing(3)
        // flexGrow: 1,
        // borderRadius: 25
    },
    buttonText: {
        color: '#033A11',
        fontSize: 14,
        textAlign: 'left',
        whiteSpace: 'nowrap'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    appBar: {
        position: 'relative'
    },
}), "RentModalFailed"
);

interface IProps {
    rentDetail_id?: string;
    closeModal: Function;
    is_edit?: boolean;
}


const RentModalFailed = (props: IProps) => {
    const { Root, classes } = useStyles();

    const rentDetail_id = props.rentDetail_id

    const [comment, setComment] = React.useState("");
    const [value, setValue] = React.useState('');

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isDisabled, setIsDisabled] = useState<boolean>(true)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        if ((event.target as HTMLInputElement).value === "other") {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    };

    const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment((event.target as HTMLInputElement).value as string);
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Box className={classes.main_container} style={{ display: "flex", flexFlow: "column" }}>
                <div className={classes.container}>
                    <Box>
                        <RadioGroup name="optradio" value={value} onChange={handleChange}>
                            <FormControlLabel value="Tidak mau profit sharing" control={<Radio />} label="Tidak mau profit sharing" />
                            <FormControlLabel value="Tidak bisa dihubungi" control={<Radio />} label="Tidak bisa dihubungi" />
                            <FormControlLabel value="Unit diluar jakarta" control={<Radio />} label="Unit diluar jakarta" />
                            <FormControlLabel value="other" control={<Radio />} label="Lainnya" />
                        </RadioGroup>
                    </Box>
                    {value === 'other' ?
                        <Box className={classes.formContainer}>
                            <TextArea
                                label="Comment"
                                name="email"
                                value={comment}
                                onChange={handleComment}
                            />
                        </Box>
                        : null
                    }
                    <Grid container spacing={2} justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonSubmit}
                            onClick={() => {
                                let data = {
                                    id: rentDetail_id,
                                    optradio: value,
                                    comment: isDisabled ? null : comment,
                                }
                                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/rent/submit/failed`, data).then(res => {
                                    setIsLoading(true);
                                    Swal.fire({
                                        title: "Status Changed",
                                        icon: 'success',
                                        onAfterClose: () => {
                                            props.closeModal()
                                        },
                                        timer: 1000
                                    });
                                })
                                    .catch(error => {
                                        generalErrorHandler(error);
                                    })
                                    .finally(() => {
                                        setIsLoading(false)
                                    })
                            }}
                        >
                            <span style={{ padding: "6px 0" }}>Simpan perubahan</span>
                        </Button>
                    </Grid>
                </div>
            </Box>
        </Root>
    );
};

export default RentModalFailed;

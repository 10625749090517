import React, { SVGProps } from 'react'

const SandClockIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M14.6667 18H13.89V15.4733C13.8909 14.6059 13.6767 13.7518 13.2666 12.9874C12.8565 12.223 12.2633 11.5722 11.54 11.0933L9.89004 10L11.54 8.90667C12.2633 8.42775 12.8565 7.77699 13.2666 7.01262C13.6767 6.24824 13.8909 5.39411 13.89 4.52667V2H14.6667C14.9319 2 15.1863 1.89464 15.3738 1.70711C15.5614 1.51957 15.6667 1.26522 15.6667 1C15.6667 0.734784 15.5614 0.48043 15.3738 0.292893C15.1863 0.105357 14.9319 0 14.6667 0L1.33337 0C1.06816 0 0.813804 0.105357 0.626267 0.292893C0.438731 0.48043 0.333374 0.734784 0.333374 1C0.333374 1.26522 0.438731 1.51957 0.626267 1.70711C0.813804 1.89464 1.06816 2 1.33337 2H2.27004V4.52667C2.26855 5.39422 2.48248 6.24857 2.89265 7.01304C3.30281 7.77752 3.89636 8.42819 4.62004 8.90667L6.27004 10L4.62004 11.0933C3.89945 11.5737 3.30916 12.2252 2.90197 12.9896C2.49478 13.7539 2.28339 14.6073 2.28671 15.4733V18H1.33337C1.06816 18 0.813804 18.1054 0.626267 18.2929C0.438731 18.4804 0.333374 18.7348 0.333374 19C0.333374 19.2652 0.438731 19.5196 0.626267 19.7071C0.813804 19.8946 1.06816 20 1.33337 20H14.6667C14.9319 20 15.1863 19.8946 15.3738 19.7071C15.5614 19.5196 15.6667 19.2652 15.6667 19C15.6667 18.7348 15.5614 18.4804 15.3738 18.2929C15.1863 18.1054 14.9319 18 14.6667 18ZM4.27004 15.4733C4.26949 14.9362 4.40193 14.4074 4.65552 13.9339C4.90911 13.4605 5.27598 13.0572 5.72337 12.76L8.08004 11.2L10.4334 12.76C10.8812 13.057 11.2485 13.4602 11.5027 13.9336C11.7569 14.407 11.8899 14.936 11.89 15.4733V18H4.27004V15.4733Z" fill="currentColor" />
        </svg>

    )
}

export default SandClockIcon
import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, InputAdornment, Theme } from '@mui/material';

// Interface
import { IPageThreeState } from '../_interfaces/ListingInterface';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface IProps {
    states: IPageThreeState,
    handleInputChange: Function,
    changePage: Function,
    initTitle: Function
}

interface IError {
    [key: string]: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    title: {
        color: '#033a11',
        fontWeight: 500
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    marker: {
        display: "block",
        position: "absolute",
        zIndex: 1,
        marginTop: "150px",
        marginLeft: "190px",
        width: "50px",
        height: "50px",
    },
    submit_btn: {
        borderRadius: "40px",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    twoInput: {
        display: 'flex'
    },
    twoInputLeft: {
        marginRight: '0.5rem'
    },
    twoInputRight: {
        marginLeft: '0.5rem'
    },
    withSeparator: {
        borderBottom: '1px solid #0000001F',
        paddingBottom: '1.5rem'
    },
    budgetContainer: {
        display: 'flex',
        '& .shrink': {
            marginRight: '1rem',
            width: 75,
            maxWidth: 75,
            minWidth: 75,
        },
        '& .grow': {
            flexGrow: 1
        }
    },
}), "Page_Two"
);

export default function PageTwo(props: IProps) {
    const { Root, classes } = useStyles();

    // Error State
    const [errorList, setErrorList] = useState<any>({});

    // Change
    const changeInput = (e: any) => {
        props.handleInputChange(e);

        let newErrorList = errorList;

        if (e.target.name === 'price_per_year' || e.target.name === 'price_per_6_months' || e.target.name === 'price_per_3_months' || e.target.name === 'price_per_1_month' || e.target.name === 'daily_price') {
            if (errorList['price_per_year'] === 'Harap isi salah satu data' || errorList['price_per_6_months'] === 'Harap isi salah satu data' || errorList['price_per_3_months'] === 'Harap isi salah satu data' || errorList['price_per_1_month'] === 'Harap isi salah satu data' || errorList['daily_price'] === 'Harap isi salah satu data') {
                delete newErrorList['price_per_year']
                delete newErrorList['price_per_6_months']
                delete newErrorList['price_per_3_months']
                delete newErrorList['price_per_1_month']
                delete newErrorList['daily_price']
            }
        }
        delete newErrorList[e.target.name];
        setErrorList(newErrorList);
    }

    const validateData = () => {
        let listOfErrors: IError = {};
        const propState = (props.states as IPageThreeState);

        for (const key in propState) {
            const data_value = propState[key];

            const except = ['rent_commission', 'sell_commission', 'price_per_year', 'price_per_6_months', 'price_per_3_months', 'price_per_1_month', 'daily_price', 'display_currency', 'sell_price']

            if (except.includes(key)) {
                continue
            }

            if (data_value === "") {
                listOfErrors[key] = 'Harap isi form ini';
            }
        }

        if (propState['sell_price'] !== '' && propState['sell_commission'] === '') {
            listOfErrors['sell_commission'] = 'Harap isi nilai komisinya';
        }

        if (propState['price_per_year'] === '' && propState['price_per_6_months'] === '' && propState['price_per_1_month'] === '' && propState['daily_price'] === '' && propState['sell_price'] === '') {
            listOfErrors['price_per_year'] = 'Harap isi salah satu data'
            listOfErrors['price_per_6_months'] = 'Harap isi salah satu data'
            listOfErrors['price_per_3_months'] = 'Harap isi salah satu data'
            listOfErrors['price_per_1_month'] = 'Harap isi salah satu data'
            listOfErrors['daily_price'] = 'Harap isi salah satu data'
            listOfErrors['sell_price'] = 'Harap isi salah satu data'
        }

        if ((propState['price_per_year'] !== '' || propState['price_per_6_months'] !== '' || propState['price_per_3_months'] !== '' || propState['price_per_1_month'] !== '' || propState['daily_price'] !== '') && propState['rent_commission'] === '') {
            listOfErrors['rent_commission'] = 'Harap isi nilai komisinya';
        }

        window.scrollTo(0, 0);
        if (Object.keys(listOfErrors).length === 0 && listOfErrors.constructor === Object) {
            props.changePage(4);
        } else {
            setErrorList(listOfErrors);
        }
    }

    useEffect(() => {
        props.initTitle()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderView = () => {
        return (
            <>
                <div className={classes.withSeparator}>
                    <TextField
                        label="Judul"
                        name="name"
                        value={props.states.name}
                        onChange={changeInput}
                        variant="outlined"
                        fullWidth
                        error={errorList.name ? true : false}
                        helperText={errorList.name}
                    />

                    <TextField
                        label="Deskripsi"
                        name="description"
                        value={props.states.description}
                        onChange={changeInput}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        error={errorList.description ? true : false}
                        helperText={errorList.description ? errorList.description : 'Max 2000 character'}
                    />
                </div>
                <div className={classes.budgetContainer} style={{ paddingTop: '1.5rem' }}>
                    <TextField
                        className="shrink"
                        name="display_currency"
                        value={props.states.display_currency}
                        variant="outlined"
                        onChange={changeInput}
                        select={true}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="IDR">IDR</option>
                        <option value="USD">USD</option>
                    </TextField>
                    <TextField
                        className="grow"
                        label="Harga Jual"
                        name="sell_price"
                        value={props.states.sell_price}
                        variant="outlined"
                        onChange={changeInput}
                        error={errorList.sell_price ? true : false}
                        helperText={errorList.sell_price ? errorList.sell_price : 'Kosongkan jika tidak dijual'}
                    />
                </div>

                <div className={classes.budgetContainer}>
                    <TextField
                        label="Komisi Jual"
                        name="sell_commission"
                        value={props.states.sell_commission}
                        onChange={changeInput}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        type="text"
                        variant="outlined"
                        fullWidth
                        error={errorList.sell_commission ? true : false}
                        helperText={errorList.sell_commission}
                    />
                </div>

                <div className={classes.budgetContainer}>
                    <TextField
                        className="shrink"
                        name="display_currency"
                        value={props.states.display_currency}
                        variant="outlined"
                        onChange={changeInput}
                        select={true}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="IDR">IDR</option>
                        <option value="USD">USD</option>
                    </TextField>
                    <TextField
                        className="grow"
                        label="Harga Sewa per Tahun"
                        name="price_per_year"
                        value={props.states.price_per_year}
                        variant="outlined"
                        onChange={changeInput}
                        error={errorList.price_per_year ? true : false}
                        helperText={errorList.price_per_year ? errorList.price_per_year : 'Kosongkan jika tidak disewakan per tahun'}
                    />
                </div>
                <div className={classes.budgetContainer}>
                    <TextField
                        className="shrink"
                        name="display_currency"
                        value={props.states.display_currency}
                        variant="outlined"
                        onChange={changeInput}
                        select={true}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="IDR">IDR</option>
                        <option value="USD">USD</option>
                    </TextField>
                    <TextField
                        className="grow"
                        label="Harga Sewa per 6 Bulan"
                        name="price_per_6_months"
                        value={props.states.price_per_6_months}
                        variant="outlined"
                        onChange={changeInput}
                        error={errorList.price_per_6_months ? true : false}
                        helperText={errorList.price_per_6_months ? errorList.price_per_6_months : 'Kosongkan jika tidak disewakan per 6 bulan'}
                    />
                </div>
                <div className={classes.budgetContainer}>
                    <TextField
                        className="shrink"
                        name="display_currency"
                        value={props.states.display_currency}
                        variant="outlined"
                        onChange={changeInput}
                        select={true}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="IDR">IDR</option>
                        <option value="USD">USD</option>
                    </TextField>
                    <TextField
                        className="grow"
                        label="Harga Sewa per 3 Bulan"
                        name="price_per_3_months"
                        value={props.states.price_per_3_months}
                        variant="outlined"
                        onChange={changeInput}
                        error={errorList.price_per_3_months ? true : false}
                        helperText={errorList.price_per_3_months ? errorList.price_per_3_months : 'Kosongkan jika tidak disewakan per 6 bulan'}
                    />
                </div>
                <div className={classes.budgetContainer}>
                    <TextField
                        className="shrink"
                        name="display_currency"
                        value={props.states.display_currency}
                        variant="outlined"
                        onChange={changeInput}
                        select={true}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="IDR">IDR</option>
                        <option value="USD">USD</option>
                    </TextField>
                    <TextField
                        className="grow"
                        label="Harga Sewa per Bulan"
                        name="price_per_1_month"
                        value={props.states.price_per_1_month}
                        variant="outlined"
                        onChange={changeInput}
                        error={errorList.price_per_1_month ? true : false}
                        helperText={errorList.price_per_1_month ? errorList.price_per_1_month : 'Kosongkan jika tidak disewakan per bulan'}
                    />
                </div>
                {/* <div className={classes.budgetContainer}>
                    <TextField
                        className="shrink"
                        name="display_currency"
                        value={props.states.display_currency}
                        variant="outlined"
                        onChange={changeInput}
                        select={true}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="IDR">IDR</option>
                        <option value="USD">USD</option>
                    </TextField>
                    <TextField
                        className="grow"
                        label="Harga Sewa per Hari"
                        name="daily_price"
                        value={props.states.daily_price}
                        variant="outlined"
                        onChange={changeInput}
                        error={errorList.daily_price ? true : false}
                        helperText={errorList.daily_price ? errorList.daily_price : 'Kosongkan jika tidak disewakan per hari'}
                    />
                </div> */}
                <div className={classes.budgetContainer}>
                    <TextField
                        label="Komisi Sewa"
                        name="rent_commission"
                        value={props.states.rent_commission}
                        onChange={changeInput}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        type="text"
                        variant="outlined"
                        fullWidth
                        error={errorList.rent_commission ? true : false}
                        helperText={errorList.rent_commission}
                    />
                </div>
            </>
        )
    }

    return (
        <Root>
            <h4 className={classes.title}>Judul, Deskripsi & Harga</h4>

            {renderView()}

            <Grid container direction="row-reverse">
                <Grid item>
                    <Button
                        variant="contained"
                        className={classes.submit_btn}
                        color="primary"
                        onClick={validateData}
                        style={{ textTransform: 'initial', marginTop: '1.5rem' }}
                    >
                        Lanjutkan
                    </Button>
                </Grid>
            </Grid>
        </Root>
    )
}
import React, { useState } from 'react';
import { Button, Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, styled } from '@mui/material';
// import { toJpeg } from 'html-to-image';
import moment from 'moment';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import MuiDatePicker from '../../_components/MuiDatePicker';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';

/**
 * Assets
 */
import logo from '../../Assets/Images/Logo-Jendela-360-horizontal.png';
import { currencyToNumber, dateFormat, generalErrorHandler, numberToCurrency } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IState {
    date: Date | null;
    period: string;
    price: string;
    deposit: string;
    price_per_month: string;
}

const useStyles = generateStyle(
    theme => ({
        tableRoot: {
            '& td': {
                padding: '0 5px',
                fontSize: '14px',
                borderBottom: 0
            },
            '& div > span': {
                display: 'inline-block',
                verticalAlign: 'middle',
                lineHeight: 'normal'
            },
            '& td:nth-of-type(even) div': {
                textAlign: 'right'
            }
        }
    }), "PaySimulator"
)

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
}))

const PaySimulator = () => {
    const { Root, classes } = useStyles();

    const initialState = {
        date: null,
        period: '',
        price: '',
        deposit: '',
        price_per_month: ''
    }

    const currencyAttributes = [
        'price',
        'deposit'
    ];

    const numberAttributes = [
        'period'
    ];

    const [state, setState] = useState<IState>(initialState);
    const [results, setResults] = useState<string[]>([]);
    const [calculated, setCalculated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleDateChange = (date: Date | null, value?: string | null | undefined) => {
        setCalculated(false);
        setState(prevState => ({
            ...prevState,
            date
        }));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (currencyAttributes.includes(name)) {
            value = currencyToNumber(value);
        } else if (numberAttributes.includes(name)) {
            value = value.replace(/\D/g, '');
        }

        setCalculated(false);
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const calc = () => {
        setIsError(false);
        if (!state.date || !state.period || !state.price || !state.deposit) {
            setIsError(true);
            return;
        }

        setIsLoading(true);
        DefaultAxios.get(process.env.REACT_APP_API_URL + '/jendela-pay-calc', { params: { ...state, date: dateFormat(state.date, 'YYYY-MM-DD') } })
            .then(res => {
                const pricePerMonth = res.data.price_per_month;

                const newResults = [];
                for (let date of res.data.dates) {
                    newResults.push(moment(date, 'YYYY-MM-DD').format('DD MMM YYYY'));
                }

                setResults(newResults);
                setState(prevState => ({
                    ...prevState,
                    price_per_month: String(pricePerMonth)
                }));
                setCalculated(true);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const saveToImage = () => {
        if (calculated) {
            // let node = document.querySelector<HTMLElement>('#result');

            // if (node) {
            //     toJpeg(node, { pixelRatio: 1, backgroundColor: '#FFFFFF' })
            //         .then(function (dataUrl) {
            //             // window.open(dataUrl);
            //             const link = document.createElement('a');
            //             link.href = dataUrl;
            //             link.setAttribute('download', 'PAY_SIMULATOR.jpeg');
            //             document.body.appendChild(link);
            //             link.click();
            //             document.body.removeChild(link);
            //         })
            //         .catch(function (error) {
            //             console.error('oops, something went wrong!', error);
            //         });
            // }
        }
    }

    return (
        <Root>
            <Grid container spacing={3}>
                <LoadingScreen open={isLoading} fullScreen />
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <MuiDatePicker
                                label="Tanggal Checkin"
                                value={state.date}
                                onChange={handleDateChange}
                                error={isError && !state.date}
                                helperText={isError && !state.date ? 'Date wajib diisi' : ''}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Masa Sewa"
                                name="period"
                                value={state.period || ''}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Bulan</InputAdornment>,
                                }}
                                error={isError && !state.period}
                                helperText={isError && !state.period ? 'Period wajib diisi' : ''}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Harga Sewa"
                                name="price"
                                value={state.price ? numberToCurrency(state.price) : ''}
                                onChange={handleChange}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                }}
                                error={isError && !state.price}
                                helperText={isError && !state.price ? 'Harga Sewa wajib diisi' : ''}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Deposit"
                                name="deposit"
                                value={state.deposit ? numberToCurrency(state.deposit) : ''}
                                onChange={handleChange}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                }}
                                error={isError && !state.deposit}
                                helperText={isError && !state.deposit ? 'Deposit wajib diisi' : ''}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveToImage}
                                disabled={!calculated}
                            >
                                Save to Image
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ float: 'right' }}
                                onClick={calc}
                            >
                                Calculate
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                {
                    state.price_per_month &&
                    <Grid item xs={12} md={6}>
                        <div id="result">
                            <TableContainer component={Paper} style={{ borderRadius: '0', marginBottom: '10px' }} className={classes.tableRoot} elevation={0}>
                                <Table>
                                    <TableBody>
                                        <StyledTableRow>
                                            <TableCell colSpan={2} align="center">
                                                <img src={logo} alt="Jendela360" width="50%" style={{ position: 'relative', zIndex: 1099 }} />
                                            </TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell>
                                                <div style={{ height: '26px', lineHeight: '26px' }}>
                                                    <span>Tanggal Checkin</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ height: '26px', lineHeight: '26px', fontWeight: 'bold' }}>
                                                    <span>{moment(state.date).format('DD MMM YYYY')}</span>
                                                </div>
                                            </TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>
                                                <div style={{ height: '28px', lineHeight: '28px' }}>
                                                    <span>Harga Sewa</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ height: '28px', lineHeight: '28px', fontWeight: 'bold' }}>
                                                    <span>{numberToCurrency(state.price, 'Rp')}</span>
                                                </div>
                                            </TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>
                                                <div style={{ height: '26px', lineHeight: '26px' }}>
                                                    <span>Masa Sewa</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ height: '26px', lineHeight: '26px', fontWeight: 'bold' }}>
                                                    <span>{state.period} Bulan</span>
                                                </div>
                                            </TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>
                                                <div style={{ height: '28px', lineHeight: '28px' }}>
                                                    <span>Deposit Sewa</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ height: '28px', lineHeight: '28px', fontWeight: 'bold' }}>
                                                    <span>{numberToCurrency(state.deposit, 'Rp')}</span>
                                                </div>
                                            </TableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TableContainer component={Paper} style={{ borderRadius: '0', marginBottom: '200px' }} className={classes.tableRoot} elevation={0}>
                                <Table>
                                    <TableBody>
                                        <StyledTableRow>
                                            <TableCell colSpan={2} style={{ backgroundColor: '#009be5', color: '#FFFFFF' }}>
                                                <div style={{ height: '28px', lineHeight: '28px', fontWeight: 'bold', textAlign: 'center' }}>
                                                    <span>Jadwal Pembayaran</span>
                                                </div>
                                            </TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell>
                                                <div style={{ height: '26px', lineHeight: '26px' }}>
                                                    <span>Deposit</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ height: '26px', lineHeight: '26px' }}>
                                                    <span>{numberToCurrency(state.deposit, 'Rp')}</span>
                                                </div>
                                            </TableCell>
                                        </StyledTableRow>

                                        {
                                            results.map((result, key) => {
                                                return <StyledTableRow key={key}>
                                                    <TableCell>{result}</TableCell>
                                                    <TableCell>
                                                        <div style={{
                                                            height: key % 2 === 0 ? '26px' : '28px',
                                                            lineHeight: key % 2 === 0 ? '26px' : '28px',
                                                        }}>
                                                            <span>{numberToCurrency(state.price_per_month, 'Rp')}</span>
                                                        </div>
                                                    </TableCell>
                                                </StyledTableRow>
                                            })
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                }
            </Grid>
        </Root>
    );
}

export default PaySimulator;
import React, { useReducer } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

// import * as DefaultNotification from "../_utils/DefaultNotification";

import logo from '../Assets/Images/Logo-Jendela-360-horizontal.png';
import { Theme } from '@mui/material';
import { subscribeNotification } from '../_utils/DefaultNotification';
import { generalErrorHandler } from '../_utils/Helper';
import { generateStyle } from '../_utils/DefaultStyle';

const SwalContainer = withReactContent(Swal)

interface ILoginForm {
  email: string;
  password: string;
  remember: boolean;
}

interface IReducerData {
  field: string;
  value: string | boolean;
}

const useStyles = generateStyle((theme: Theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    // [theme.breakpoints.up('lg')]: {
    //   paddingRight: "300px",
    //   paddingLeft: "300px"
    // },
    [theme.breakpoints.up('sm')]: {
      width: '400px',
      maxWidth: '400px',
      boxShadow: 'none',
    },
  },
  img_container: {
    height: "100px",
    maxWidth: '200px',
    objectFit: 'contain',
  },
  image: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    // backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    padding: '0.75rem 0',
    borderRadius: 25,
    marginTop: '2rem'
  },
}), "SignIn_Side");

export default function SignInSide() {
  const { Root, classes } = useStyles();
  const initialState = {
    showModal: false,
    email: '',
    password: '',
    remember: false
  }

  const reducer = (state: any, { field, value }: IReducerData) => {
    return {
      ...state,
      [field]: value
    }
  }

  const warningText = {
    email: '',
    password: ''
  }

  const [state, handleChange] = useReducer(reducer, initialState);
  const [warningState, changeWarning] = useReducer(reducer, warningText);


  let showFailLoginModal = '';

  const onChangeHandler = (field: string, value: string) => {
    changeWarning({ field: field, value: "" });
    handleChange({ field: field, value: value });
  }

  function onSubmit() {
    let proceed_login = true;
    if (state.email === "") {
      proceed_login = false;
      changeWarning({ field: 'email', value: "Masukkan email anda" });
    }

    if (state.password === "") {
      proceed_login = false;
      changeWarning({ field: 'password', value: "Masukkan password anda" });
    }

    if (proceed_login) {
      let data: ILoginForm = { email: state.email, password: state.password, remember: state.remember };
      axios.post(`${process.env.REACT_APP_API_URL}/login`, data)
        .then(res => {
          localStorage.setItem("jwt_token", res.data.access_token);
          localStorage.setItem("user_id", res.data.user_id); // For notification
          localStorage.setItem("role", res.data.role); // For notification

          subscribeNotification(res.data.access_token)
            .catch(generalErrorHandler)
            .finally(() => {
              window.location.reload();
            })
        })
        .catch(error => {
          SwalContainer.fire({
            title: "Error",
            html: "Email atau Password anda salah",
            icon: 'error',
          })
        });
    }
  }

  if (localStorage.getItem('jwt_token')) {
    // DefaultNotification.subscribe();
    // TODO: update subscrbe
    return (
      <Navigate replace to="/" />
    );
  }

  const onCheckChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ field: 'remember', value: e.target.checked });
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const key = e.key
    if (key === 'Enter') {
      onSubmit()
    }
  }

  return (
    <Root>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        {showFailLoginModal}
        <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square className={classes.container}>
          <div className={classes.paper}>
            <img src={logo} alt="pintu properti logo" className={classes.img_container} />
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={state.email}
                onChange={(e) => { onChangeHandler(e.target.name, e.target.value) }}
                autoFocus
                onKeyDown={handleKeyDown}
              />
              <p>{warningState.email}</p>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={state.password}
                onChange={(e) => { onChangeHandler(e.target.name, e.target.value) }}
                autoComplete="current-password"
                onKeyDown={handleKeyDown}
              />
              <p>{warningState.password}</p>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" checked={state.remember} onChange={onCheckChanged} />}
                label="Remember me"
              />
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onSubmit}
              >
                Sign In
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    </Root>
  );
}
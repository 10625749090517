import React, { useReducer, useEffect, useState } from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Grid, DialogActions } from '@mui/material';
import Swal from 'sweetalert2';

// Asset
import pdfPng from '../../../Assets/Images/png/pdf.png';
import docPng from '../../../Assets/Images/png/doc.png';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    unitClosingBreakdownId: string;
    files: IFile[];
    onUploadFile: Function;
}

interface IFile {
    created_at: string;
    image_url: string;
    type: number;
    caption: string;
    notes: string;
    id: string;
}

interface ISearchState {
    search: string;
    type: string;
}

interface IFormState {
    caption: string;
    type: string;
    notes: string;
    file: File | null;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const useStyles = generateStyle(theme => ({
    root: {
        width: '100%',
        '& .MuiTextField-root': {
            backgroundColor: '#fff'
        }
    },
    inputContainer: {
        display: 'flex',
        '& div:nth-of-type(1)': {
            flex: '4'
        },
        '& div:nth-of-type(2)': {
            flex: '2'
        },
        '& div:nth-of-type(3)': {
            flex: '4'
        },
        '& div + div': {
            marginLeft: '10px'
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    fileContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '10px',
    },
    thumbnail: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '25%',
        marginTop: '10px',
        '& img': {
            alignSelf: 'center',
            cursor: 'pointer'
        },
        '& span': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: '10px'
        }
    }
}), "HandoverList")

const HandoverList = (props: Props) => {
    const { files, onUploadFile } = props;
    const { Root, classes } = useStyles();

    const initialSearchState = {
        search: '',
        type: ''
    };

    const initialFormState = {
        caption: '',
        type: '',
        notes: '',
        file: null
    };

    const fileTypes = [
        'Keadaan',
        'Kerusakan',
        'Tagihan'
    ];

    // Input State
    const searchReducer = (state: ISearchState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialSearchState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as ISearchState };
        }

        return { ...state };
    };

    const formReducer = (state: IFormState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialFormState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IFormState };
        }

        return { ...state };
    };

    const [filteredFiles, setFilteredFiles] = useState(files.slice());
    const [modalOpen, setModalOpen] = useState(false);
    const [searchState, setSearchState] = useReducer(searchReducer, initialSearchState);
    const [formState, setFormState] = useReducer(formReducer, initialFormState);
    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);
    const [selectedFile, setSelectedFile] = useState<IFile>({} as IFile);

    useEffect(() => {
        setFilteredFiles(files.slice());
        setFormState({ name: '', value: initialFormState, type: 'REPLACE_STATE' });
        // eslint-disable-next-line
    }, [files]);

    const handleModalOpen = () => {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
    }

    useEffect(() => {
        let newFilteredFiles = files.slice();

        if (searchState.search) {
            newFilteredFiles = newFilteredFiles.filter(file => {
                return file.image_url.indexOf(searchState.search) > -1;
            });
        }

        if (searchState.type) {
            newFilteredFiles = newFilteredFiles.filter(file => {
                return file.type === +searchState.type;
            });
        }

        setFilteredFiles(newFilteredFiles);
        // eslint-disable-next-line
    }, [searchState]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        let name = e.target.name;
        let value: any = e.target.value;

        if (type === 'search') {
            setSearchState({ name, value, type: 'SET_ITEM' });
        } else {
            if (name === 'file') {
                const file = e.target.files;
                if (file && file[0]) {
                    value = file[0];
                }
            }
            setFormState({ name, value, type: 'SET_ITEM' });
        }
    }

    const handlePreviewFile = (src: string, isImage: boolean, file: IFile) => {
        if (isImage) {
            setPreviewImage(src);
            setSelectedFile(file);
        } else {
            window.open(src);
        }
    }

    const handleUpload = () => {
        let isValid = true;
        Object.keys(formState).map(key => {
            if (key !== 'notes') {
                if (!formState[key as keyof IFormState]) {
                    isValid = false;
                }
            }
            return true;
        })

        if (!isValid) {
            Swal.fire({
                title: "Error",
                text: 'Please fill caption, type, and file',
                icon: 'error'
            });
            return;
        }

        const fd = new FormData();
        fd.append('unit_closing_breakdown_id', props.unitClosingBreakdownId);
        for (const [key, value] of Object.entries(formState)) {
            if (key === 'file') {
                fd.append('image_url', value as File);
            } else {
                fd.append(key, String(value));
            }
        }

        handleModalClose();
        onUploadFile(fd);
    }

    const renderFiles = () => {
        return filteredFiles.map(file => {
            let src = '';
            let isImage = false;
            let filepath = file.image_url;
            let explode = filepath.split('?');
            const match = /(\.\w+$)/g.exec(explode[0]);

            if (match) {
                if (match[1] === '.pdf') {
                    src = pdfPng;
                } else if (match[1] === '.doc' || match[1] === '.docx') {
                    src = docPng;
                } else {
                    src = filepath;
                    isImage = true;
                }
            }

            const fileType = fileTypes[file.type];

            return <div className={classes.thumbnail} key={file.id}>
                <img src={src} alt={file.caption} width="100px" onClick={() => handlePreviewFile(src, isImage, file)} />
                <span>Type: {fileType}</span>
                <span>{file.caption}</span>
            </div>
        })
    }

    return (
        <Root>
        <div className={classes.root}>
            <Dialog
                open={!!previewImage}
                onClose={() => { setPreviewImage(undefined) }}
                scroll="body"
                maxWidth={false}
            >
                <DialogTitle>{selectedFile.caption}</DialogTitle>
                <DialogContent>
                    <img src={previewImage} width="100%" alt="preview" />
                    <p>Type: {fileTypes[selectedFile.type]}</p>
                    <p>Note: {selectedFile.notes}</p>
                </DialogContent>
            </Dialog>
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
            >
                <DialogTitle>Upload file</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Caption"
                                name="caption"
                                value={formState.caption}
                                onChange={(e: any) => handleChange(e, 'form')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Notes"
                                name="notes"
                                value={formState.notes}
                                onChange={(e: any) => handleChange(e, 'form')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                select
                                name="type"
                                value={formState.type}
                                onChange={(e: any) => handleChange(e, 'form')}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value="">-- Type --</option>
                                {
                                    fileTypes.map((fileType, key) => {
                                        return <option value={key} key={key}>{fileType}</option>
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="file"
                                name="file"
                                onChange={(e: any) => handleChange(e, 'form')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpload} color="primary">
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.inputContainer}>
                <TextField
                    size="small"
                    variant="outlined"
                    name="search"
                    value={searchState.search}
                    onChange={(e: any) => handleChange(e, 'search')}
                    placeholder="Search here"
                />
                <TextField
                    select
                    size="small"
                    variant="outlined"
                    name="type"
                    value={searchState.type}
                    onChange={(e: any) => handleChange(e, 'search')}
                    SelectProps={{
                        native: true,
                    }}
                >
                    <option value="">-- Type --</option>
                    {
                        fileTypes.map((fileType, key) => {
                            return <option value={key} key={key}>{fileType}</option>
                        })
                    }
                </TextField>
                <div className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleModalOpen}
                    >
                        Upload File
                    </Button>
                </div>
            </div>
            <div className={classes.fileContainer}>
                {renderFiles()}
            </div>
        </div>
        </Root>
    );
}

export default HandoverList;
import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Dialog, AppBar, Theme, Toolbar, IconButton, Typography, Button, DialogContent, Grid, Paper, Box, DialogTitle, DialogActions } from '@mui/material';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import InfoTable from '../../_pages/_listingList/InfoTable';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import Swal from 'sweetalert2';
import TextArea from '../../_components/_form/TextArea';
import { generalErrorHandler, numberToCurrency, renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    box: {
        padding: theme.spacing(2)
    },
    paper: {
        margin: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
}), "Stay360BookingDetailModal"
)

const Stay360BookingDetailModal = () => {
    const { Root, classes } = useStyles();
    const params: any = useParams();
    const navigate = useNavigate();
    const location: any = useLocation();
    const prevPath = useState(location.state?.prevPath);
    const API_URL = process.env.REACT_APP_API_URL + '/stay360-booking/' + params.id;

    const [isLoading, setIsLoading] = useState(false);
    const [detail, setDetail] = useState<any>({});
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [cancelReason, setCancelReason] = useState('');

    const handleCancelModalOpen = () => setCancelModalOpen(true);
    const handleCancelModalClose = () => setCancelModalOpen(false);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(API_URL)
            .then(res => {
                setDetail(res.data);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleCancel = () => {
        if (!cancelReason.trim()) {
            return;
        }

        renderWarningButton('Apakah anda yakin ingin membatalkan booking ini?')
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/stay360-booking/cancel`, { id: params.id, cancel_reason: cancelReason })
                        .then(res => {
                            Swal.fire({
                                title: "Booking cancelled",
                                icon: 'success',
                                onAfterClose: () => {
                                    setCancelReason('');
                                    handleCancelModalClose();
                                    loadData();
                                },
                                timer: 1000
                            });
                        })
                }
            });
    }

    return (
        <Root>
            <Dialog
                fullScreen
                open={true}
                onClose={() => { typeof prevPath[0] !== 'undefined' ? navigate(`${prevPath[0]}`) : navigate(-1) }}
            >
                <Root>
                    <Dialog
                        open={cancelModalOpen}
                        onClose={handleCancelModalClose}
                        fullWidth
                        maxWidth="md"
                    >
                        <Root>
                            <DialogTitle>Cancel Booking</DialogTitle>
                            <DialogContent>
                                <TextArea
                                    label="Cancel Reason"
                                    name="cancel_reason"
                                    value={cancelReason}
                                    onChange={(e) => setCancelReason(e.target.value)}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="primary"
                                    onClick={handleCancel}
                                >
                                    Submit
                                </Button>
                            </DialogActions>
                        </Root>
                    </Dialog>
                    <LoadingScreen open={isLoading} fullScreen />
                    <AppBar className={classes.appBar} color={detail.cancel_reason ? 'secondary' : 'primary'}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit"
                                onClick={() => { typeof prevPath[0] !== 'undefined' ? navigate(`${prevPath[0]}`) : navigate(-1) }}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Booking Detail
                            </Typography>
                            {!detail.cancel_reason &&
                                <Button autoFocus color="inherit" onClick={() => navigate('/stay360-booking/edit/' + params.id)}>
                                    Edit
                                </Button>
                            }
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Grid item xs={12} sm={6}>
                            <Paper className={classes.paper}>
                                <Box className={classes.box}>
                                    <InfoTable
                                        data={[
                                            ["Booking ID", detail.booking_id, false],
                                            ["OTA", detail.ota, false],
                                            ["Nomor Unit", detail.unit_label, false],
                                            ["Nama", detail.name, false],
                                            ["Phone", detail.phone, false],
                                            ["Check In", detail.check_in, false],
                                            ["Check Out", detail.check_out, false],
                                            ["Rate List", detail.rates?.map((rate: string) => numberToCurrency(rate, 'Rp. ')).join(' - '), false]
                                        ]}
                                    />
                                    {
                                        !detail.cancel_reason &&
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={handleCancelModalOpen}
                                        >
                                            Cancel
                                        </Button>
                                    }
                                </Box>
                            </Paper>
                        </Grid>
                    </DialogContent>
                </Root>
            </Dialog>
        </Root>
    );
}

export default Stay360BookingDetailModal;
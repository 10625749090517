import React, { useEffect, useState } from 'react'
import { Grid, Theme } from '@mui/material'
import moment from 'moment'

/**
 * Components
 */
import FaCard from './_components/ActivityScheduleCard'
import MuiDatePicker from '../../_components/MuiDatePicker'
import LoadingCard from '../../_components/_cards/LoadingCard'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import { generateStyle } from '../../_utils/DefaultStyle'

/**
 * Icons
 */

export interface IActivity {
    activity: string
    apartment_name: string | React.ReactNode
    client_name: string
    created_by_name: string
    id: number
    notes: string
    time: string
    unit_code: string
}
export interface IData {
    activities: IActivity[]
    id: number
    name: string
    photo: string
    phone_number?: string
}

type RequestType = {
    label: string;
    value: string;
    key: string;
    [key: string]: any;
}

export const initialRequestType: RequestType[] = [
    { label: "Fixing and Cleaning", value: 'fixing_cleaning', key: 'fixing_cleaning' },
    { label: "Foto Unit", value: 'photo', key: 'photo' },
    { label: "Paket Perawatan Essensial", value: 'essential_pack', key: 'essential_pack' },
    { label: "Handover Checkin", value: "9", key: '9' },
    { label: "Handover Checkout", value: '2', key: '2' },
    { label: "QC Checkin", value: 'quality-control', key: 'quality-control' },
    { label: "QC listing", value: "quality-control-listing", key: 'quality-control-listing' },
    { label: "Maintenance", value: "3", key: '3' }
]


export default function FaTeamSchedule() {
    const { Root, classes } = useStyles()
    const [date, setDate] = useState<Date | null>(new Date())
    const [isLoading, setIsLoading] = useState(false)
    const [schedules, setSchedules] = useState<IData[]>([])

    const fetchData = (date: Date) => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/schedule-overview`, {
            params: { date: moment(date).format('YYYY-MM-DD') }
        })
            .then(res => res.data)
            .then(res => res ? setSchedules(res) : null)
            .catch(err => generalErrorHandler(err))
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (date) {
            fetchData(date)
        }
    }, [date])

    return (
        <Root>
            <Grid container className={classes.constainer}>
                <Grid item xs={12} md={2.5} className={classes.date} alignSelf={'flex-end'}>
                    <MuiDatePicker
                        label=''
                        onChange={(value: any) => setDate(value)}
                        value={date}
                        clearable
                        onClear={() => setDate(null)}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    {
                        isLoading ?
                            <div className={classes.cardContainer}>
                                <LoadingCard />
                                <LoadingCard />
                                <LoadingCard />
                            </div>
                            :
                            <div className={classes.cardContainer}>
                                {
                                    schedules && schedules.length ?
                                        schedules.map((data) => (
                                            <FaCard key={data.id} data={data} />
                                        ))
                                        : "Tidak ada Schedule Hari ini"
                                }
                            </div>
                    }
                </Grid>
            </Grid>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
        constainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        },
        date: {
            justifySelf: 'flex-end'
        },
        cardContainer: {
            marginTop: 24,
            display: 'grid',
            gap: '1.5rem',
            gridAutoRow: '150px',
            gridTemplateColumns: 'repeat(1,minmax(0,1fr))',
            [theme.breakpoints.up('md')]: {
                gridTemplateColumns: 'repeat(3,minmax(0,1fr))',
            },
        }
    }), "Activity_Schedule")

import React, { useState } from 'react';
import { Grid, Button, InputAdornment, TextField, Theme } from '@mui/material';

// Interface
import { IPageTwoState } from '../_interfaces/ListingInterface';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface IProps {
    listing_type_id: string,
    states: IPageTwoState,
    handleInputChange: Function,
    changePage: Function
}

interface IError {
    [key: string]: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    title: {
        color: '#033a11',
        fontWeight: 500
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    marker: {
        display: "block",
        position: "absolute",
        zIndex: 1,
        marginTop: "150px",
        marginLeft: "190px",
        width: "50px",
        height: "50px",
    },
    submit_btn_container: {
        position: "absolute",
        right: 0,
        bottom: 0,
    },
    submit_btn: {
        borderRadius: "40px",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    twoInput: {
        display: 'flex'
    },
    twoInputLeft: {
        marginRight: '0.5rem'
    },
    twoInputRight: {
        marginLeft: '0.5rem'
    }
}), "Page_Two"
);

export default function PageTwo(props: IProps) {
    const { Root, classes } = useStyles();

    // Error State
    const [errorList, setErrorList] = useState<any>({});

    // Change
    const changeInput = (e: any) => {
        props.handleInputChange(e);

        let newErrorList = errorList;

        delete newErrorList[e.target.name];
        setErrorList(newErrorList);
    }

    const validateData = () => {
        let listOfErrors: IError = {};

        let except = ['property_length', 'property_width'];
        switch (parseInt(props.listing_type_id)) {
            case 1:
                except.push('land_area', 'land_length', 'land_width', 'carport');
                break;
            case 2:
                except.push('land_length', 'land_width');
                break;
            case 3:
                except.push('property_area', 'bedroom', 'bathroom', 'carport');
                break;
            default:
                except.push('land_area', 'land_length', 'land_width', 'bedroom', 'bathroom', 'carport');
                break;
        }

        for (const key in props.states) {
            const data_value = (props.states as IPageTwoState)[key];

            if (except.includes(key)) {
                continue;
            }

            if (data_value === "") {
                listOfErrors[key] = 'Harap isi form ini';
            }
        }

        window.scrollTo(0, 0);
        if (Object.keys(listOfErrors).length === 0 && listOfErrors.constructor === Object) {
            props.changePage(3);
        } else {
            setErrorList(listOfErrors);
        }
    }

    const renderView = () => {
        switch (parseInt(props.listing_type_id)) {
            case 1:
                return (
                    <>
                        <TextField
                            label="Luas Bangunan"
                            name="property_area"
                            value={props.states.property_area}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.property_area ? true : false}
                            helperText={errorList.property_area}
                        />

                        <div className={classes.twoInput}>
                            <TextField
                                label="Kamar Tidur"
                                name="bedroom"
                                variant="outlined"
                                value={props.states.bedroom}
                                onChange={changeInput}
                                fullWidth
                                error={errorList.bedroom ? true : false}
                                helperText={errorList.bedroom}
                                select
                                className={classes.twoInputLeft}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: !!props.states.bedroom
                                }}
                            >
                                <option aria-label="None" value="" />
                                <option value="0">Studio</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </TextField>
                            <TextField
                                label="Kamar Mandi"
                                name="bathroom"
                                variant="outlined"
                                value={props.states.bathroom}
                                onChange={changeInput}
                                fullWidth
                                error={errorList.bathroom ? true : false}
                                helperText={errorList.bathroom}
                                select
                                className={classes.twoInputRight}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: !!props.states.bathroom
                                }}
                            >
                                <option aria-label="None" value="" />
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </TextField>
                        </div>
                    </>
                )
            case 2:
                return (
                    <>
                        <TextField
                            label="Luas Tanah"
                            name="land_area"
                            value={props.states.land_area}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.land_area ? true : false}
                            helperText={errorList.land_area}
                        />

                        <TextField
                            label="Luas Bangunan"
                            name="property_area"
                            value={props.states.property_area}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.property_area ? true : false}
                            helperText={errorList.property_area}
                        />

                        <div className={classes.twoInput}>
                            <TextField
                                label="Kamar Tidur"
                                name="bedroom"
                                variant="outlined"
                                value={props.states.bedroom}
                                onChange={changeInput}
                                fullWidth
                                error={errorList.bedroom ? true : false}
                                helperText={errorList.bedroom}
                                select
                                className={classes.twoInputLeft}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: !!props.states.bedroom
                                }}
                            >
                                <option aria-label="None" value="" />
                                <option value="0">Studio</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </TextField>
                            <TextField
                                label="Kamar Mandi"
                                name="bathroom"
                                variant="outlined"
                                value={props.states.bathroom}
                                onChange={changeInput}
                                fullWidth
                                error={errorList.bathroom ? true : false}
                                helperText={errorList.bathroom}
                                select
                                className={classes.twoInputRight}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: !!props.states.bathroom
                                }}
                            >
                                <option aria-label="None" value="" />
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </TextField>
                        </div>

                        <TextField
                            label="Carport"
                            name="carport"
                            value={props.states.carport}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Mobil</InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.carport ? true : false}
                            helperText={errorList.carport}
                        />
                    </>
                )
            case 3:
                return (
                    <>
                        <TextField
                            label="Luas Tanah"
                            name="land_area"
                            value={props.states.land_area}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.land_area ? true : false}
                            helperText={errorList.land_area}
                        />

                        <TextField
                            label="Panjang Tanah"
                            name="land_length"
                            value={props.states.land_length}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.land_length ? true : false}
                            helperText={errorList.land_length}
                        />

                        <TextField
                            label="Lebar Tanah"
                            name="land_width"
                            value={props.states.land_width}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.land_width ? true : false}
                            helperText={errorList.land_width}
                        />
                    </>
                )
            default:
                return (
                    <>
                        <TextField
                            label="Luas Tanah"
                            name="land_area"
                            value={props.states.land_area}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.land_area ? true : false}
                            helperText={errorList.land_area}
                        />

                        <TextField
                            label="Luas Bangunan"
                            name="property_area"
                            value={props.states.property_area}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.property_area ? true : false}
                            helperText={errorList.property_area}
                        />

                        <TextField
                            label="Kamar Mandi"
                            name="bathroom"
                            variant="outlined"
                            value={props.states.bathroom}
                            onChange={changeInput}
                            fullWidth
                            error={errorList.bathroom ? true : false}
                            helperText={errorList.bathroom}
                            select
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{
                                shrink: !!props.states.bathroom
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </TextField>

                        <TextField
                            label="Panjang Tanah"
                            name="land_length"
                            value={props.states.land_length}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.land_length ? true : false}
                            helperText={errorList.land_length}
                        />

                        <TextField
                            label="Lebar Tanah"
                            name="land_width"
                            value={props.states.land_width}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.land_width ? true : false}
                            helperText={errorList.land_width}
                        />

                        <TextField
                            label="Panjang Bangunan"
                            name="property_length"
                            value={props.states.property_length}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.property_length ? true : false}
                            helperText={errorList.property_length}
                        />

                        <TextField
                            label="Lebar Bangunan"
                            name="property_width"
                            value={props.states.property_width}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.property_width ? true : false}
                            helperText={errorList.property_width}
                        />

                        <TextField
                            label="Carport"
                            name="carport"
                            value={props.states.carport}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Mobil</InputAdornment>,
                            }}
                            variant="outlined"
                            fullWidth
                            error={errorList.carport ? true : false}
                            helperText={errorList.carport}
                        />
                    </>
                )
        }
    }

    return (
        <Root>
            <h4 className={classes.title}>Luas & Dimensi</h4>

            {renderView()}

            <Grid container direction="row-reverse">
                <Grid item className={[1, 3].includes(Number(props.listing_type_id)) ? classes.submit_btn_container : ""}>
                    <Button
                        variant="contained"
                        className={classes.submit_btn}
                        color="primary"
                        onClick={validateData}
                        style={{ textTransform: 'initial', marginTop: '1.5rem' }}
                    >
                        Lanjutkan
                    </Button>
                </Grid>
            </Grid>
        </Root>
    )
}
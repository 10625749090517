import React, { useState } from 'react'

/**
 * Components
 */
import DndCard from './DndCard'
import { closestCenter, DndContext, DragEndEvent, DragOverlay, DragStartEvent, MouseSensor, TouchSensor, UniqueIdentifier, useSensor, useSensors } from '@dnd-kit/core'
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'

interface DnDContainerProps {
    items: any[]
    /**
     * Get the item id
     * @param item item data
     * @returns item id
     */
    getItemId: (item: any) => string
    /**
     * Handle the item movement and save it to state
     * @param oldIndex item's index before it's moved
     * @param newIndex item's index after it's moved
     * @returns 
     */
    onMove: (oldIndex: any, newIndex: any) => void
    /**
     * Render the actual component for the item
     * @param item the item data
     * @param index item's index
     * @returns component render
     */
    renderItem: (item: any, index: number) => React.ReactNode
    /**
     * Render a parent component for each item
     * @param children component from renderItem
     * @param index item's index
     * @returns parent component for the renderItem
     */
    renderItemContainer: (children: React.ReactNode, index: number) => React.ReactNode
    /**
     * Render container component that contain the item list
     * @param children list of items component
     * @returns container for list containing the items
     */
    renderContainer: (children: React.ReactNode) => React.ReactNode
}

const DnDContainer = (props: DnDContainerProps) => {
    // Set the active item id
    const [activeId, setActiveId] = useState<null | UniqueIdentifier>(null)
    // Determine which sensor to use
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

    // Set active item id when drag start
    const handleDragStart = (event: DragStartEvent) => {
        setActiveId(event.active.id)
    }

    // Handle what to do after drag end
    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event

        if (active.id !== over!.id) {
            // Get the old and new index of the dragged item
            const oldIndex = props.items.indexOf(props.items.find(item => props.getItemId(item) === active.id))
            const newIndex = props.items.indexOf(props.items.find(item => props.getItemId(item) === over!.id))
            props.onMove(oldIndex, newIndex)
        }

        setActiveId(null)
    }

    // Reset the active id on drag cancel
    const handleDragCancel = () => {
        setActiveId(null)
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={props.items} strategy={rectSortingStrategy}>
                {
                    props.renderContainer((
                        <>
                            {
                                props.items.map((item, index) => (
                                    props.renderItemContainer((
                                        <>
                                            <DndCard
                                                id={props.getItemId(item)}
                                                key={index}
                                            >
                                                {props.renderItem(item, index)}
                                            </DndCard>
                                        </>
                                    ), index)
                                ))
                            }
                        </>
                    ))
                }
            </SortableContext>

            {/* Render a duplicate item of the currently dragged item */}
            <DragOverlay adjustScale={true}>
                {activeId ? (
                    props.renderItem(props.items.find((val) => props.getItemId(val) === activeId), 1)
                ) : null}
            </DragOverlay>
        </DndContext>
    )
}

export default DnDContainer

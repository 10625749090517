import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Theme,
    Switch,
    Link,
    Grid,
    TextField
} from '@mui/material';

import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import ListingDetailModal from './ListingDetailModal';
import DefaultAxios from '../../_utils/DefaultAxios';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';

/**
 * Utils
 */
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';
import moment from 'moment';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    listing_id?: string
}
interface IParams extends Record<string, string | undefined> {
    listing_id?: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    addButton: {
        marginBottom: '20px'
    },
    actionContainer: {
        display: 'flex'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "ListingList_Admin"
)

const ListingListAdmin = (props: IProps) => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const DT_API_URL = process.env.REACT_APP_API_URL + '/listing-admin';
    const navigate = useNavigate();
    const reDrawDatatable = new Date().getTime();
    // const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [selectedId, setSelectedId] = useState<string>('');
    const [modalDetailState, setModalDetailState] = useState(false);
    const params = useParams<IParams>()
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleOpenDetail = () => setModalDetailState(true);
    const handleCloseDetail = () => {
        setModalDetailState(false)
        window.history.pushState([], 'Listing Admin List', `/listingAdmin/list/`);
    };

    const handleSearch = () => {
        if (searchValue) {
            setIsLoading(true);
            DefaultAxios.get(`${DT_API_URL}/search-data`, { params: { search: searchValue } })
                .then(res => {
                    navigate(`/listingAdmin/list/${res.data}`);
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    useEffect(() => {
        if (typeof params.listing_id !== "undefined") {
            setSelectedId(params.listing_id);
            handleOpenDetail();
        }
    }, [params]);

    const toggleActive = (id: string, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${DT_API_URL}/toggle-active`, { id })
                        .then(res => {
                            const checked = res.data;
                            const newRows = rows.map((element: any) => {
                                if (element.id === id) element['is_active'] = checked ? 1 : 0
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }


    const togglePublish = (id: string, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${DT_API_URL}/toggle-publish`, { id })
                        .then(res => {
                            const checked = res.data;
                            const newRows = rows.map((element: any) => {
                                if (element.id === id) element['is_active'] = checked ? 1 : 0
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    return (
        <Root>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => navigate('/listingAdmin/add')}

            >
                Add New Listing
            </Button>
            <LoadingScreen open={isLoading} fullScreen />

            <ListingDetailModal
                open={modalDetailState}
                onClose={handleCloseDetail}
                listing_id={selectedId}
            />
            {
                permissions['listing.list'] ?
                    <DataTable
                        reDraw={reDrawDatatable}
                        url={DT_API_URL}
                        columns={[
                            {
                                name: 'code', label: 'Kode Unit', filterable: true, render: (data: any, row: any) => {
                                    return (
                                        <Link
                                            style={{ cursor: "pointer" }}
                                            component={RouterLink}
                                            to={`/listingAdmin/list/${row.id}`}
                                        // onClick={() => { openModalDashboard(row); console.log(row) }
                                        // }
                                        >
                                            {data}
                                        </Link>
                                    );
                                }
                            },
                            { name: 'listing_type_name', label: 'Jenis Properti', filterable: true },
                            { name: 'pic_name', label: 'PIC', filterable: true },
                            { name: 'landlord_name', label: 'Nama Landlord', filterable: true },
                            { name: 'landlord_phone', label: 'Hp Landlord', filterable: true },
                            {
                                name: 'is_active',
                                label: 'Active',
                                filterable: true,
                                options: [
                                    {
                                        key: '',
                                        value: 'All'
                                    },
                                    {
                                        key: '1',
                                        value: 'Active'
                                    },
                                    {
                                        key: '0',
                                        value: 'Not Active'
                                    }
                                ],
                                defaultOption: '',
                                render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                                    if (permissions['listing.active']) {
                                        return (
                                            <Switch
                                                checked={+data === 1}
                                                onChange={() => toggleActive(row.id, setRows, rows)}
                                                color="secondary"
                                            />
                                        )
                                    } else {
                                        return data === 1 ? "YES" : "NO";
                                    }
                                }
                            },
                            {
                                name: 'is_published',
                                label: 'Published',
                                filterable: true,
                                options: [
                                    {
                                        key: '',
                                        value: 'All'
                                    },
                                    {
                                        key: '1',
                                        value: 'Published'
                                    },
                                    {
                                        key: '0',
                                        value: 'Unpublish'
                                    }
                                ],
                                defaultOption: '',
                                render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                                    if (permissions['listing.publish']) {
                                        return (
                                            <Switch
                                                checked={+data === 1}
                                                onChange={() => togglePublish(row.id, setRows, rows)}
                                                color="secondary"
                                            />
                                        )
                                    } else {
                                        return data === 1 ? "YES" : "NO";
                                    }
                                }
                            },
                            {
                                name: 'created_at',
                                label: 'Created At',
                                type: 'date',
                                filterable: true,
                                render: (data: any, row: any) => {
                                    return (
                                        data ? `${moment(data).format("DD MMMM YYYY")}` : '-'
                                    )
                                }
                            },
                        ]}
                    />
                    : <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Search"
                                value={searchValue}
                                placeholder="Unit Code"
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
            }
        </Root>
    );
}

export default ListingListAdmin;
import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import LoadingScreen from '../LoadingScreen'
import ButtonWithLoading from '../_form/ButtonWithLoading'
import AsyncAutoComplete, { IAutoCompleteOption } from '../_form/AsyncAutoComplete'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation'
import { generalErrorHandler } from '../../_utils/Helper'

type Props = {
    url: string
    autocompleteUrl: string
    paramName: string
    // Modal title
    title: string
    label: string
    placeholder?: string
    paramAlias?: string
    extraParams?: { [key: string]: any }
    required?: boolean
    open: boolean
    onClose: () => void
    /**
     * need to close the modal from the parrent on this prop
     * @returns void
     */
    onSuccess: () => void
    onError?: (err: any) => void
}

interface State {
    id: string
    label: string
}

const initialState = {
    id: '',
    label: '',
}

const GeneralAutocompleteModal = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<State>(initialState)
    const [error, setError] = useState<IValidationErrors<State>>({})

    useEffect(() => {
        if (!props.open) setState(initialState)
    }, [props.open])

    const validationRules: IValidationRules = useMemo(() => ({
        id: props.required ? 'required' : '',
    }), [props.required])

    const validationAlias: IValidationAlias = useMemo(() => ({
        id: props.paramAlias || props.paramName,
    }), [props.paramName, props.paramAlias])

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            id: String(value.id),
            label: value.label
        }))
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            label: ''
        }))
    }

    const handleSubmit = () => {
        const { errors, isValid } = validateData(state, validationRules, validationAlias)
        setError(errors)

        if (isValid) {
            setIsLoading(true)
            DefaultAxios.post(props.url, {
                ...props.extraParams,
                [props.paramName]: state.id
            })
                .then(res => {
                    props.onSuccess()
                })
                .catch(err => {
                    generalErrorHandler(err)
                    if (props.onError) props.onError(err)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            maxWidth="sm"
            fullWidth
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={props.autocompleteUrl}
                            initialQuery={state.label}
                            label={props.label}
                            name="id"
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            errorText={error.id}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonWithLoading
                            isLoading={isLoading}
                            variant='contained'
                            onClick={handleSubmit}
                            sx={{ float: 'right' }}
                        >
                            Submit
                        </ButtonWithLoading>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default GeneralAutocompleteModal
import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, Grid, TextField, Button } from '@mui/material'

/**
 * Components
 */
import LoadingScreen from '../../../../_components/LoadingScreen'

/**
 * Utils
 */
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { generalErrorHandler, renderToastSuccess } from '../../../../_utils/Helper'
import { validateData } from '../../../../_utils/Validation'

interface IProps {
    open: boolean,
    onClose: (refresh: boolean) => void
    id: number | string
    onSubmitSuccess?: () => void
    onEnquiryExistCallback?: (data: {
        closing_code: string
        customer_id: number
        customer_name: string
    }) => void
}

interface IState {
    name: string,
    phone: string
}

const initialState = { name: '', phone: '' }

const ChangeEnquiryModal = (props: IProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<IState>(initialState)
    const [errorState, setErrorState] = useState<IState>(initialState)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target
        const convertNumberName = ['phone']

        setState(prev => ({
            ...prev,
            [name]: convertNumberName.includes(name) ? value.replace(/[^\d]/g, '') : value
        }))

        setErrorState(prev => ({
            ...prev,
            [name]: ''
        }))
    }

    const validationRules = {
        name: 'required',
        phone: 'required'
    }

    const validationAliases = {
        name: 'Nama',
        phone: "Nomor Telepon"
    }

    const handleSubmit = () => {
        const { isValid, errors } = validateData(state, validationRules, validationAliases)
        if (!isValid) {
            return setErrorState(errors as any)
        }

        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/change-enquiry`, {
            id: props.id,
            ...state
        })
            .then(res => res.data)
            .then(res => {
                renderToastSuccess('Enquiry Code Changed!')
                if (typeof props.onSubmitSuccess !== 'undefined') {
                    props.onSubmitSuccess()
                }
            })
            .catch(err => {
                if (err.response.status === 417) {
                    if (typeof props.onEnquiryExistCallback !== 'undefined') props.onEnquiryExistCallback(JSON.parse(err.response.data.message))
                } else {
                    generalErrorHandler(err)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (!props.open) {
            setState(initialState)
            setErrorState(initialState)
        }

    }, [props.open, props.id])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
        >
            <LoadingScreen open={isLoading} />
            <DialogTitle>
                Change Enquiry
            </DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="name"
                            label="Name"
                            helperText={errorState.name}
                            error={!!errorState.name}
                            value={state.name}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="phone"
                            label="Phone"
                            helperText={errorState.phone}
                            error={!!errorState.phone}
                            value={state.phone}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            onClick={handleSubmit}
                            sx={{ float: 'right' }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ChangeEnquiryModal
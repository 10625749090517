import React, { useState, useReducer } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
    TextField,
    MenuItem
} from '@mui/material';
import LoadingScreen from '../../../../_components/LoadingScreen';
import DefaultAxios from '../../../../_utils/DefaultAxios';
// import { format } from 'date-fns';
import Swal from 'sweetalert2';

import { generalErrorHandler } from '../../../../_utils/Helper';
// import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import PhotoPreview from '../../../../_components/PhotoPreview';

interface IProp {
    closing_id: string;
    listing_information: string;
    owner_name: string;
    tenant_name: string;
    open: boolean;
    onChange: any;
    onClose: (refresh: boolean) => any;
}

interface IState {
    // due_date: string;
    // due_time: string;
    notes: string | null;
    assigned_to: Number | null;
    checkout_reason: string
    checkout_proof_file: File | null
}

// interface IOptions {
//     id: number,
//     label: string
// }

interface IAction {
    name: string,
    value: any,
    type: string
}

const HandOverCheckOutModal = (props: IProp) => {
    const [open, setOpen] = useState(props.open);
    const [isLoading, setIsLoading] = useState(false);
    // const [options, setOptions] = useState<Array<IOptions>>([]);
    // const [due_date, setDate] = useState(new Date());
    // const [due_time, setTime] = useState(new Date());

    const initialState = {
        notes: null,
        assigned_to: null,
        checkout_reason: '',
        checkout_proof_file: null
        // due_date: format(new Date(), "yyyy/MM/dd"),
        // due_time: format(new Date(), "HH:mm"),
    }

    // useEffect(() => {
    //     DefaultAxios.get(process.env.REACT_APP_API_URL + '/autocomplete/user/field-assistant')
    //         .then(res => {
    //             setOptions(res.data);
    //             setIsLoading(false);
    //         })
    // }, []);

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };
    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let value: any = target.value;
        let name = target.name;
        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files, name } = e.target
        if (files && files.length) {
            setInputState({
                name,
                value: files[0],
                type: 'SET_ITEM'
            })
        }
    }

    const handleSubmit = (id: any) => {
        const fd = new FormData();

        for (let key in inputState) {
            if (key === 'checkout_proof_file') {
                if (inputState[key as keyof IState] instanceof File) {
                    fd.append(key, inputState[key as keyof IState] as File);
                }
            } else {
                if (inputState[key as keyof IState]) {
                    fd.append(key, String(inputState[key as keyof IState]));
                }
            }
        }

        fd.append("id", props.closing_id);

        if (id) {
            setIsLoading(true);
            // fd.append('_method', 'PATCH');
            // DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/create-handover-checkout`, fd)
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/change-extend-status-to-checkout`, fd)
                .then(res => {
                    Swal.fire({
                        title: "Submit data berhasil",
                        icon: 'success',
                        timer: 1000
                    });
                    props.onChange(7)
                    handleClose(true)
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    };

    // const handleDateChange = (date: any, val: any) => {
    //     let name = "due_date";
    //     let value = format(date, "yyyy/MM/dd");
    //     setDate(date);
    //     setInputState({ name, value, type: 'SET_ITEM' });
    // }

    // const handleTimeChange = (date: any, val: any) => {
    //     let name = "due_time";
    //     let value = format(date, "HH:mm");
    //     setTime(date);
    //     setInputState({ name, value, type: 'SET_ITEM' });
    // }

    const handleClose = (refresh: boolean) => {
        props.onClose(refresh);
        setOpen(false);
    };

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Checkout</DialogTitle>
                <DialogContent>
                    {/* <Grid container justifyContent="space-around" spacing={2} style={{ marginTop: 0 }}>
                        <Grid item xs={6}>
                            <DatePicker
                                label="Tanggal"
                                value={due_date}
                                onChange={handleDateChange}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        variant="outlined"
                                        fullWidth
                                        label={props.label}
                                        placeholder={props.placeholder ? props.placeholder : "dd/MM/yyyy"}
                                        error={props.error ? props.error : false}
                                        helperText={props.helperText ? props.helperText : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TimePicker
                                label="Time picker"
                                ampm={false}
                                value={due_time}
                                onChange={handleTimeChange}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        variant="outlined"
                                        fullWidth
                                        label={props.label}
                                        placeholder={props.placeholder ? props.placeholder : "dd/MM/yyyy"}
                                        error={props.error ? props.error : false}
                                        helperText={props.helperText ? props.helperText : ''}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid> */}
                    <Grid container justifyContent="flex-start" spacing={2}>
                        <Grid item xs={12}>
                            <p style={{ marginTop: 0 }}>Informasi Tambahan:</p>
                            <p><strong>Unit:</strong> {props.listing_information}</p>
                            <p>
                                <strong>Owner:</strong> {props.owner_name}
                            </p>
                            <p>
                                <strong>Tenant:</strong> {props.tenant_name}
                            </p>
                        </Grid>
                    </Grid>
                    {/* <Grid container justifyContent="flex-start" spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                id="notes"
                                label="Notes"
                                name="notes"
                                type="text"
                                value={inputState.notes}
                                multiline
                                rows={4}
                                onChange={handleChanged}
                                variant="outlined"
                                fullWidth
                                style={{ marginBottom: '10px' }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-start" spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="select"
                                label="Assigned To"
                                name="assigned_to"
                                value={inputState.assigned_to}
                                onChange={handleChanged}
                                variant="outlined"
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                                fullWidth
                                select
                            >
                                {
                                    options.map(data => {
                                        return <MenuItem value={data.id}>{data.label}</MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                    </Grid> */}
                    <Grid container justifyContent="flex-start" spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="select"
                                label="Alasan Checkout"
                                name="checkout_reason"
                                value={inputState.checkout_reason}
                                onChange={handleChanged}
                                variant="outlined"
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                                fullWidth
                                select
                            >
                                <MenuItem value="" disabled>-- Pilih Alasan Checkout --</MenuItem>
                                {
                                    reasonCheckoutList.map(reason => (
                                        <MenuItem key={reason.value} value={reason.value}>{reason.label}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-start" spacing={2}>
                        <Grid item xs={12}>
                            <p><strong>Bukti Checkout</strong></p>
                            {
                                (inputState.checkout_proof_file instanceof File) ?
                                    <PhotoPreview
                                        style={{ marginTop: 10 }}
                                        index={0}
                                        onRemoveFile={() => setInputState({ name: 'checkout_proof_file', value: null, type: 'SET_ITEM' })}
                                        src={URL.createObjectURL(inputState.checkout_proof_file)}
                                    />
                                    : <input style={{ marginTop: 10 }} type='file' accept='image/*' name="checkout_proof_file" onChange={handleChangeFile} />
                            }
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleSubmit(props.closing_id)
                        }}
                        color="primary"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const reasonCheckoutList = [
    { label: "Pindah unit dari Jendela360", value: "move_from_unit" },
    { label: "Pindah unit non-Jendela360", value: "move_from_unit_outside_jendela" },
    { label: "Tidak sewa apartemen lagi", value: "not_renting_apartment" },
    { label: "Gagal bayar termin", value: "fail_termin" },
    { label: "Owner tidak dapat memperpanjang", value: "owner_cannot_extend" },
    { label: "Bypass Owner", value: "bypass_owner" },
    { label: "Batal Sewa", value: "cancel_rent_or_fake_closing" },
    { label: "Tidak ada jawaban", value: "no_answer" },
];

export default HandOverCheckOutModal;
import React from 'react'

import { TextField, TextFieldProps, Theme, styled } from '@mui/material';

const TFND = styled(TextField)(({ theme }: { theme: Theme }) => ({
    '&.MuiTextField-root:has(label)': {
        marginTop: 20,
    },
    // '&.MuiTextField-root:has(label) .MuiOutlinedInput-notchedOutline': {
    //     top: 0,
    // },
    '& .MuiFormLabel-root': {
        marginTop: -13,
        color: '#727272',
        fontSize: 14,
    },
    '& legend': {
        display: 'none',
    },
    '& .MuiAutocomplete-inputRoot': {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '& .MuiInputBase-root': {
        borderRadius: 12,
        paddingLeft: 20,
        backgroundColor: 'white',
        '&:has(.MuiIconButton-root)': {
            paddingRight: 0,
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#D8D8D8',
        top: 0,
    },
    '& .MuiSelect-select': {
        minHeight: 'inherit'
    },
    '& .MuiInputBase-input, & .MuiOutlinedInput-root .MuiAutocomplete-input': {
        fontSize: 14,
        color: '#484848',
        paddingLeft: 0,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 13,
        lineHeight: '17px',
        height: 'auto',
        '&:has(+ .MuiIconButton-root)': {
            paddingRight: 0,
        },
        '&::-webkit-input-placeholder': {
            color: '#727272',
            opacity: 1,
        },
        '&:-moz-placeholder': {
            color: '#727272',
            opacity: 1,
        },
        '&::-moz-placeholder': {
            color: '#727272',
            opacity: 1,
        },
        '&:-ms-input-placeholder': {
            color: '#727272',
            opacity: 1,
        },
        '&::-ms-input-placeholder': {
            color: '#727272',
            opacity: 1,
        },
        '&::placeholder': {
            color: '#727272',
            opacity: 1,
        },
    },
}));

const TextFieldND = (props: TextFieldProps) => (<TFND {...props} InputLabelProps={{ shrink: true, ...props.InputLabelProps }} />)

export default TextFieldND

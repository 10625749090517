import React from 'react';
import { generateStyle } from '../../../_utils/DefaultStyle';
import { Avatar, Card, CardContent, Grid, Theme, Typography } from '@mui/material';
import { DataAllUsers, DataUsers } from '../FunnelReportListing';

interface CardListingProps {
  data: DataUsers[],
  data2: DataAllUsers
}


const CardListing: React.FC<CardListingProps> = ({ data, data2 }) => {
  const { Root, classes } = useStyles();

  const DataItem: React.FC<{ label: string; value: number }> = ({ label, value }) => (
    <Typography variant="body2" component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>{label}</span>
      <span>{value}</span>
    </Typography>
  );

  const UserCard: React.FC<{ user: DataUsers }> = ({ user }) => (
    <Card className={classes.styledCard}>
      <CardContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Avatar className={classes.styledAvatar}>{user.name[0]}</Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h6" component="div" gutterBottom>
              {user.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={2} marginY={{ md: 1, xs: 2 }}>
          <Grid item><DataItem label="New Submission" value={user.new_submission} /></Grid>
          <Grid item><DataItem label="Listed" value={user.listed} /></Grid>
          <Grid item><DataItem label="Rejected" value={user.rejected} /></Grid>
          <Grid item><DataItem label="On Process" value={user.on_process} /></Grid>
          <Grid item><DataItem label="Cold" value={user.cold} /></Grid>
          <Grid item><DataItem label="New Listing" value={user.new_listing} /></Grid>
          <Grid item><DataItem label="New Key" value={user.new_listing_entrusted_key} /></Grid>
          <Grid item><DataItem label="On Process Ticket" value={user.on_process_ticket} /></Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const AllUsersCard: React.FC<{ allData: DataAllUsers }> = ({ allData }) => (
    <Card className={classes.styledCard}>
      <CardContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Avatar className={classes.styledAvatar}>A</Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h6" component="div" gutterBottom>
              All User
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={2} marginY={{ md: 1, xs: 2 }}>
          <Grid item><DataItem label="Total New Submission" value={allData.total_new_submission} /></Grid>
          <Grid item><DataItem label="Total Listed" value={allData.total_listed} /></Grid>
          <Grid item><DataItem label="Total Rejected" value={allData.total_rejected} /></Grid>
          <Grid item><DataItem label="Total On Process" value={allData.total_on_process} /></Grid>
          <Grid item><DataItem label="Total Cold" value={allData.total_cold} /></Grid>
          <Grid item><DataItem label="Total New Listing" value={allData.total_new_listing} /></Grid>
          <Grid item><DataItem label="Total New Key" value={allData.total_new_listing_entrusted_key} /></Grid>
          <Grid item><DataItem label="Total On Process Ticket" value={allData.total_on_process_ticket} /></Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <Root>
      <Grid container spacing={2} >
        <Grid item xs={12} md={4}>
          <AllUsersCard allData={data2} />
        </Grid>
        {data.map((user, index) => (
          <Grid item xs={12} md={4} key={index}>
            <UserCard user={user} />
          </Grid>
        ))}
      </Grid>
    </Root>
  );
}

const useStyles = generateStyle((theme: Theme) => ({
  styledCard: {
    backgroundColor: '#fff',
    color: 'black',
    borderRadius: 15,
    borderColor: "#000",
    border: 1
  },
  styledAvatar: {
    backgroundColor: '#1976d2',
    marginBottom: theme.spacing(1),
  },
  dataItem: {
    marginBottom: theme.spacing(2),
  }
}), "CardListing");

export default CardListing;

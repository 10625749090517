import React from 'react'

import { TextField, TextFieldProps, Theme, styled } from '@mui/material';

const TFND2 = styled(TextField)(({ theme }: { theme: Theme }) => ({
    '& .MuiFormLabel-root': {
        fontSize: 18,
        fontWeight: '600',
    },
    '& .MuiInputBase-root': {
        marginTop: 36,
    },
    '& .MuiInputBase-input': {
        fontSize: 14,
        paddingBottom: 10,
    },
    '& .MuiInput-root .MuiInput-input': {
        padding: 0,
        paddingBottom: 10,
    },
    '& .MuiInputAdornment-root > .MuiTypography-root': {
        fontSize: 14,
        marginBottom: 11,
    },
    '& .MuiSvgIcon-root': {
        paddingBottom: 10,
        marginRight: 5,
        fontSize: 32,
        color: '#727272'
    },
    '& .MuiSelect-icon': {
        paddingTop: 5,
        paddingBottom: 5,
    }
}));

const TextFieldND2 = (props: TextFieldProps) => (<TFND2 {...props} variant='standard' fullWidth InputLabelProps={{ shrink: true, ...props.InputLabelProps }} />)

export default TextFieldND2

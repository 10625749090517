import React from 'react'
import { Button } from '@mui/material'
import { generateStyle } from '../_utils/DefaultStyle'

interface IProps {
  type: string
  navigate: Function
  init?: boolean
  renderBody?: (titleClassName: string, subTitleClassName: string) => React.ReactNode
}

const DataNA = (props: IProps) => {
  const { Root, classes } = useStyles()
  const init = props.init !== undefined ? props.init : true

  return (
    <Root>
      <div className={classes.root}>
        <img src={`https://pintu-cdn.sgp1.cdn.digitaloceanspaces.com/assets/img/dataNA.png`} alt="na" className={classes.image} />
        {
          init ?
            <>
              <h5 className={classes.h5}>Anda Belum Mempunyai {props.type}</h5>
              <p className={classes.p}>Silahkan Tambah {props.type}</p>
              <Button className={classes.button} variant="contained" color="primary" onClick={() => props.navigate()}>
                Add {props.type}
              </Button>
            </>
            :
            <>
              {
                props.renderBody ?
                  props.renderBody(classes.h5, classes.p)
                  :
                  <>
                    <h5 className={classes.h5}>Maaf, properti tidak ditemukan</h5>
                    <p className={classes.p}>Silahkan kurangi filternya</p>
                  </>
              }
            </>
        }
      </div>
    </Root>
  )
}

const useStyles = generateStyle((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '3rem',
  },
  image: {

  },
  h5: {
    fontSize: 16,
    color: '#666666',
    fontWeight: 500,
    marginBottom: 0
  },
  p: {
    fontSize: 14,
    color: '#666666',
    fontWeight: 300,
    marginTop: '0.4rem'
  },
  button: {
    fontWeight: 400,
    textTransform: 'capitalize',
    fontSize: 16,
    padding: '0.5rem 3rem',
    marginTop: '1rem',
    borderRadius: '5rem'
  }
}), "Data_NA"
)

export default DataNA
import { Menu, Theme, styled } from '@mui/material';

const MenuND = styled(Menu)(({ theme }: { theme: Theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 8,
        border: '1px solid #D8D8D8',
    },
    '& .MuiPaper-elevation1': {
        boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.1) 0px 2px 2px 0px rgba(0, 0, 0, 0.09) 0px 5px 3px 0px rgba(0, 0, 0, 0.05) 0px 8px 3px 0px rgba(0, 0, 0, 0.01) 0px 13px 4px 0px rgba(0, 0, 0, 0)',
    },
    '& .MuiList-root': {
        padding: 0,
        '& > li': {
            borderTop: '1px solid #D8D8D8',
            '&:first-of-type': {
                borderTop: 0,
            }
        },
    }
}));

export default MenuND

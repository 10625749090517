import React from 'react';
import { Grid, TextField } from '@mui/material';

// Interfaces
import { IState } from '../ProjectDeveloperImageForm';

interface Props {
    form: IState;
    onChange: Function;
    index: number;
}

const ImageForm = (props: Props) => {
    const { form } = props;

    return (
        <Grid container spacing={3}>
            <Grid item xs={2}>
                Image
            </Grid>
            <Grid item xs={10}>
                <img src={form.thumbnail ? form.thumbnail : "http://jendela.test/assets/admin/img/default-thumbnail.jpg"} width="100" alt={form.alt} />
                <br />
                <input type="file" name="file" onChange={(e) => props.onChange(e, props.index)} />
                <br />
                <i>Required dimension : 780 x 430 or larger with the same ratio</i>
            </Grid>

            <Grid item xs={2}>
                Caption
            </Grid>
            <Grid item xs={10}>
                <TextField
                    fullWidth
                    name="caption"
                    variant="outlined"
                    value={form.caption}
                    onChange={(e) => props.onChange(e, props.index)}
                />
            </Grid>

            <Grid item xs={2}>
                Alt
            </Grid>
            <Grid item xs={10}>
                <TextField
                    fullWidth
                    name="alt"
                    variant="outlined"
                    value={form.alt}
                    onChange={(e) => props.onChange(e, props.index)}
                />
            </Grid>
        </Grid>
    );
}

export default ImageForm;
import React from 'react';
import { Dialog, Theme } from '@mui/material';
import { generateStyle } from '../_utils/DefaultStyle';

interface Props {
    video: string | undefined;
    onClose: () => void;
}

const VideoPreview = (props: Props) => {
    const { Root, classes } = useStyles()

    return (
        <Dialog
            open={!!props.video}
            onClose={props.onClose}
            scroll="body"
            maxWidth={'md'}
            fullWidth
        >
            <Root>
                <div className={`${classes.videoCard}`}>
                    <video src={props.video} width="320" height="180" controls className={classes.video} />
                </div>
            </Root>
        </Dialog>
    );
}



const useStyles = generateStyle((theme: Theme) => ({
    videoCard: {
        width: '100%',
        paddingTop: '56.25%',
        position: 'relative',
    },
    video: {
        position: 'absolute',
        inset: 0,
        width: '100%',
        height: '100%',
    },
}), "Video_Preview")

export default VideoPreview;
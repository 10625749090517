import React, { useState, useReducer, useEffect } from 'react';
import { Container, Dialog, AppBar, Slide, Toolbar, Typography, IconButton, Theme, Grid, Button } from '@mui/material';
import Swal from 'sweetalert2';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

// Components
import LoadingScreen from '../../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    enquiryId: string
    open: boolean;
    handleCloseModal: () => void
}

interface IState {
    id: string,
    name: string,
    member_id: string;
    advisor_name: string;
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative'
    },
    container: {
        backgroundColor: "#f3f3f3",
    },
    submit_button: {
        marginLeft: '8px'
    },
    headLabel: {
        fontWeight: "bold",
        padding: theme.spacing(0, 1),
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
    paper_label: {
        position: "absolute",
        marginTop: "-45px",
        padding: "1px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)"
    },
    paper_label_text: {
        backgroundColor: "#0094ff",
        fontSize: "1.5em",
        padding: "13px",
        borderRadius: "4px",
        color: "white"
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'right',
        marginBottom: '10px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }

}), "EnquiryMember_Form"
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const EnquiryMemberForm = ({ open, enquiryId, handleCloseModal }: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/enquiry';
    const [loading, setLoading] = useState(false);
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                id: '',
                name: '',
                member_id: '',
                advisor_name: '',
                member_name: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        id: '',
        name: '',
        member_id: '',
        advisor_name: '',
        member_name: '',
    });

    useEffect(() => {
        if (open) {
            DefaultAxios
                .get(`${API_URL}/${enquiryId}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleAutocompleteAdvisor = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.member_id = value.id.toString();
        newState.advisor_name = value.label;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const submitForm = () => {

        let axios;
        const data = {
            id: enquiryId,
            member_id: inputState.member_id
        }
        axios = DefaultAxios.post(`${API_URL}/update-member`, data)
        setLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Enquiry Updated",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        setInputState({ name: '', value: data, type: 'RESET_ITEM' });
                        setLoading(false);
                        handleCloseModal()
                    })
            })
            .catch(error => {
                generalErrorHandler(error);
                setLoading(false);
            });
    }

    return (
        <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth="sm" closeAfterTransition TransitionComponent={Transition}>
            <Root>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseModal} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h6" className={classes.title}>Customer Advisor</Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <LoadingScreen open={loading} fullScreen />
                <Container maxWidth={false} className={classes.container}>
                    <div>
                        <Grid container spacing={2} sx={{ my: 1 }}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    className={classes.headLabel}>
                                    Current Advisor: {inputState.member_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AsyncAutoComplete
                                    label="New Advisor"
                                    name="advisor"
                                    initialQuery={inputState.advisor_name}
                                    onChange={handleAutocompleteAdvisor}
                                    onInputChange={handleAutocompleteInputChanged}
                                    url={`${process.env.REACT_APP_API_URL}/autocomplete/user`}
                                    placeholder="Input New Advisor"
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridSubmit}>
                                <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </Root>
        </Dialog>
    );
}

export default EnquiryMemberForm;
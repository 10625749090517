import Axios from 'axios';

const DefaultAxios = Axios;

DefaultAxios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("jwt_token")}`;
DefaultAxios.interceptors.response.use(response => {
    return response;
}, error => {
    if (typeof error.response !== 'undefined') {
        const isLogin = error.response.config.url.match(/login/);
        if (+error.response.status === 401 && !isLogin) { // Invalid token
            localStorage.removeItem('jwt_token');
            window.location.href = '/';
        } else if (+error.response.status === 426) { // Expired token, try to refresh first
            if (!sessionStorage.getItem('prevent_multiple_refresh')) {
                sessionStorage.setItem('prevent_multiple_refresh', '1');
                return DefaultAxios
                    .post(`${process.env.REACT_APP_API_URL}/refresh`, null, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('jwt_token')}`
                        }
                    })
                    .then(res => {
                        localStorage.setItem('jwt_token', res.data);
                        error.config.headers['Authorization'] = `Bearer ${res.data}`
                        DefaultAxios.defaults.headers.common['Authorization'] = `Bearer ${res.data}`;

                        return DefaultAxios.request(error.config);
                    })
                    .finally(() => {
                        sessionStorage.removeItem('prevent_multiple_refresh');
                    })
            } else {
                error.config.headers['Authorization'] = `Bearer ${localStorage.getItem('jwt_token')}`;
                return DefaultAxios.request(error.config);
            }
        } else if (+error.response.status === 403) {
            window.location.href = '/';
        }
    }
    return Promise.reject(error);
})

export default DefaultAxios;

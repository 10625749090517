import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import ButtonWithLoading from '../../../_components/_form/ButtonWithLoading'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete'

/**
 * Utils
 */
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import { generalErrorHandler, renderToastSuccess } from '../../../_utils/Helper'
import DefaultAxios from '../../../_utils/DefaultAxios'

interface ListingTicketPICModalProps {
    isOpen: boolean
    onClose: () => void
    onFinish: () => void
    data: {
        id: string | null
    }
}

type State = {
    pic_id: string
    pic_label: string
}

const ListingTicketPICModal = (props: ListingTicketPICModalProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<State>(INITIAL_STATE)
    const [error, setError] = useState<IValidationErrors<State>>({})

    useEffect(() => {
        if (!props.isOpen) {
            setState(INITIAL_STATE)
            setError({})
        }
    }, [props.isOpen])

    const handleAutocomplete = (name: string, option: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            [`${name}_id`]: option.id.toString(),
            [`${name}_label`]: option.label,
        }))

        setError(prev => ({
            ...prev,
            [`${name}_id`]: '',
        }))
    }

    const handleAutocompleteInput = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            [`${name}_id`]: '',
            [`${name}_label`]: '',
        }))

        setError(prev => ({
            ...prev,
            [`${name}_id`]: '',
        }))
    }

    const onSubmit = () => {
        const { errors, isValid } = validateData(state, VALIDATION_RULES, VALIDATION_ALIAS)
        setError(errors)

        if (isValid) {
            setIsLoading(true)

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/listing-ticket/change-pic`, {
                id: props.data.id,
                pic_id: state.pic_id,
            })
                .then(() => {
                    renderToastSuccess('Berhasil mengubah PIC')
                    props.onClose()
                    props.onFinish()
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <Dialog
            open={props.isOpen}
            fullWidth
            maxWidth='xs'
            onClose={isLoading ? undefined : props.onClose}
        >
            <DialogTitle>Change PIC</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user/active-listing-acquisition`}
                            initialQuery={state.pic_label}
                            label="PIC"
                            name='pic'
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInput}
                            disabled={isLoading}
                            errorText={error.pic_id}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonWithLoading
                            variant='contained'
                            color='primary'
                            sx={{ float: 'right' }}
                            onClick={onSubmit}
                            isLoading={isLoading}
                        >
                            Submit
                        </ButtonWithLoading>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const VALIDATION_RULES: IValidationRules = {
    pic_id: 'required',
}

const VALIDATION_ALIAS: IValidationAlias = {
    pic_id: 'PIC',
}

const INITIAL_STATE: State = {
    pic_id: '',
    pic_label: '',
}

export default ListingTicketPICModal

import { Dialog, DialogContent, DialogTitle, Grid, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { ISearchResponse } from './CustomerPhoneSearch'

interface IProps {
    open: boolean,
    onClose: () => void
    data: ISearchResponse[]
}

const SearchPhoneModal = (props: IProps) => {

    const renderContent = () => {
        return props.data.map((data) => {
            return (
                <TableRow>
                    <TableCell>
                        <Link
                            style={{ cursor: "pointer" }}
                            component={RouterLink}
                            target='_blank'
                            to={`/closing/${data.code}`}
                        >
                            {data.code}
                        </Link>
                    </TableCell>
                    <TableCell>{data.unit_code}</TableCell>
                    <TableCell>{data.customer_name}</TableCell>
                    <TableCell>{data.landlord_name}</TableCell>
                </TableRow>
            )
        })
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Closing List</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Kode Closing</TableCell>
                                    <TableCell>Kode Unit</TableCell>
                                    <TableCell>Nama Tenant</TableCell>
                                    <TableCell>Nama Owner</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderContent()}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default SearchPhoneModal
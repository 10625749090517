import React, { useReducer, useEffect, useState } from 'react';
import { Theme, TextField, Button, Dialog, DialogTitle, DialogContent, Grid, DialogActions, TableContainer, Paper, Table, TableHead, TableCell, TableBody, TableRow } from '@mui/material';
import Chip from '@mui/material/Chip';
import Swal from 'sweetalert2';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AutoComplete from '@mui/lab/Autocomplete';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';

// Asset
import pdfPng from '../../../Assets/Images/png/pdf.png';
import docPng from '../../../Assets/Images/png/doc.png';

import DeleteIcon from '@mui/icons-material/Delete';
import { dateFormat, renderWarningButton } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    closing_id: string;
    permissions: any;
    files: IFile[];
    onUploadFile: Function;
    onDeleteFile: Function;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

interface IFile {
    id: string;
    created_at: string;
    image_url: string;
    name: string;
    room: string;
    type: string;
    notes: string
    submitted_by_label: string
}

interface ISearchState {
    search: string;
    type: string;
}

interface IFormState {
    name: string;
    room: string;
    notes: string
    type: string;
    file: File | null;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const captionsOptions = [
    'Master Bedroom',
    '2nd Bedroom',
    '3rd Bedroom',
    'Living Room',
    'Kitchen',
    'Balcony',
    'Dining Room',
    'Master Bathroom',
    "2nd Bathroom",
    "3rd Bathroom",
    "Living Room 1st Floor",
    "Living Room 2nd Floor",
    "Maid Room"
]

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        width: '100%',
        '& .MuiTextField-root': {
            backgroundColor: '#fff'
        }
    },
    button: {
        margin: theme.spacing(1),
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    inputContainer: {
        display: 'flex',
        '& div:nth-of-type(1)': {
            flex: '4'
        },
        '& div:nth-of-type(2)': {
            flex: '2'
        },
        '& div:nth-of-type(3)': {
            flex: '4'
        },
        '& div + div': {
            marginLeft: '10px'
        }
    },
    buttonContainer: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '16px',
        '& button': {
            marginLeft: 10,
        },
        '& button::last-child': {
            marginLeft: 0,
        },
    },
    buttonContainerDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    buttonContainerMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    fileContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '10px',
    },
    thumbnail: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '25%',
        marginTop: '10px',
        '& img': {
            alignSelf: 'center',
            cursor: 'pointer'
        },
        '& span': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: '10px'
        }
    },
    LaporanCheckInListTable: {
        '& > table': {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'table',
            },
        },
        '& > .mobile-list': {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
            '& > *': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            },
            '& > *:last-child': {
                borderBottom: 'none',
            },
        },
        '& > table > tbody > tr > td:first-of-type': {
            width: 75,
            '& > *': {
                objectFit: 'cover',
                cursor: 'pointer',
            }
        },
        '& .file-name': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.dark,
            }
        }
    },
    fileCard: {
        display: 'flex',
        padding: 8,
        '& > img': {
            marginRight: 12,
        },
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
                marginBottom: 8,
            },
            '& > *:last-child': {
                marginBottom: 0,
            },
            '& > .date': {
                fontSize: 12,
            },
            '& > div > button': {
                margin: 0,
            }
        }
    },
}), "Laporan_CheckinList"
);

const initialSearchState = {
    search: '',
    type: ''
};

const initialFormState = {
    name: '',
    room: '',
    notes: '',
    type: '',
    file: null
};

const LaporanCheckInList = (props: Props) => {
    const { files, onUploadFile } = props;
    const { Root, classes } = useStyles();

    /**
     * Input State
     */
    const searchReducer = (state: ISearchState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialSearchState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as ISearchState };
        }

        return { ...state };
    };

    const formReducer = (state: IFormState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialFormState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IFormState };
        }

        return { ...state };
    };

    const [filteredFiles, setFilteredFiles] = useState(files.slice());
    const [modalOpen, setModalOpen] = useState(false);
    const [searchState, setSearchState] = useReducer(searchReducer, initialSearchState);
    const [formState, setFormState] = useReducer(formReducer, initialFormState);
    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);

    useEffect(() => {
        setFilteredFiles(files.slice());
        setFormState({ name: '', value: initialFormState, type: 'REPLACE_STATE' });
        // eslint-disable-next-line
    }, [files]);

    const handleModalOpen = () => {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
    }

    const handleDownloadPDF = () => {
        let isAllowed = false
        if (props.permissions['closing-checkin-report.download-pdf-report']) {
            isAllowed = true;
        }

        if (!isAllowed) {
            Swal.fire({
                title: "Error",
                text: 'You don\'t have permission to download pdf report',
                icon: 'error'
            })
            return
        }

        props.setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/checkin-report/pdf`, {
            closing_id: props.closing_id
        },
            {
                responseType: 'blob'
            })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'CHECK_IN_REPORT.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .finally(() => {
                props.setIsLoading(false)
            })
    }

    const renderDeleteButton = (file: IFile, size?: 'small' | 'medium' | 'large') => {
        let can_delete = false;
        if (props.permissions['closing-checkin-report.delete']) {
            can_delete = true;
        }

        if (can_delete) {
            return (
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<DeleteIcon />}
                        onClick={() => deleteFile(file.id, file.type)}
                        size={size}
                    >
                        Delete
                    </Button>
                </Grid>
            )
        } else {
            return 'No Action Available';
        }
    }

    const deleteFile = (id: string, type: string) => {
        renderWarningButton("Apakah anda yakin ingin menghapus file ini?")
            .then((result) => {
                if (result.value) {
                    let API_URL = `${process.env.REACT_APP_API_URL}`;
                    let isAllowed = false;

                    if (props.permissions['closing-checkin-report.delete']) {
                        isAllowed = true;
                        API_URL = `${API_URL}/new-closing/checkin-report`;
                    }

                    if (!isAllowed) {
                        Swal.fire({
                            title: "Error",
                            text: 'You don\'t have permission to delete the file',
                            icon: 'error'
                        });
                        return;
                    }

                    DefaultAxios
                        .delete(API_URL, {
                            params: {
                                closing_id: props.closing_id,
                                id: id,
                            }
                        })
                        .then(() => {
                            Swal.fire({
                                title: "File berhasil dihapus",
                                icon: 'success'
                            }).then(() => {
                                props.onDeleteFile();
                            });
                        })
                        .catch((res) => {
                            Swal.fire({
                                title: "Error",
                                text: res.data.message,
                                icon: 'error'
                            });
                        })
                }
            })
    }

    useEffect(() => {
        let newFilteredFiles = files.slice();

        if (searchState.search) {
            newFilteredFiles = newFilteredFiles.filter(file => {
                return file.name.toLowerCase().indexOf(searchState.search) > -1;
            });
        }

        if (searchState.type) {
            newFilteredFiles = newFilteredFiles.filter(file => {
                return file.type === searchState.type;
            });
        }

        setFilteredFiles(newFilteredFiles);
        // eslint-disable-next-line
    }, [searchState]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        let name = e.target.name;
        let value: any = e.target.value;

        if (type === 'search') {
            setSearchState({ name, value, type: 'SET_ITEM' });
        } else {
            if (name === 'file') {
                const file = e.target.files;
                if (file && file[0]) {
                    value = file[0];
                }
            }
            setFormState({ name, value, type: 'SET_ITEM' });
        }
    }

    const handlePreviewFile = (src: string, isImage: boolean) => {
        if (isImage) {
            setPreviewImage(src);
        } else {
            window.open(src);
        }
    }

    const handleUpload = () => {
        let isValid = true;
        Object.keys(formState).map(key => {
            if (key === 'name') {
                if (formState.type === 'other' && formState.name === "") {
                    isValid = false;
                }
            } else {
                if (!formState[key as keyof IFormState]) {
                    isValid = false;
                }
            }
            return true;
        })

        if (!isValid) {
            Swal.fire({
                title: "Error",
                text: 'Please fill all inputs',
                icon: 'error'
            });
            return;
        }

        const fd = new FormData();

        for (const [key, value] of Object.entries(formState)) {
            if (key === 'file') {
                fd.append('image', value as File);
            } else {
                fd.append(key, String(value));
            }
        }

        fd.append('closing_id', props.closing_id)

        handleModalClose();
        onUploadFile(fd);
    }

    const renderFiles = () => {
        return filteredFiles.map((file) => {
            let src = '';
            let isImage = false;
            let filepath = file.image_url;

            let explode = filepath.split('?');
            const match = /(\.\w+$)/g.exec(explode[0]);

            if (match) {
                if (match[1] === '.pdf' || match[1] === '.PDF') {
                    src = pdfPng;
                } else if (match[1] === '.doc' || match[1] === '.docx') {
                    src = docPng;
                } else {
                    src = filepath;
                    isImage = true;
                }
            }

            return (
                <TableRow key={file.id}>
                    <TableCell>
                        <img height="75" width="75" src={src} alt={file.name} onClick={() => handlePreviewFile(filepath, isImage)} />
                    </TableCell>
                    <TableCell>
                        <span
                            className="file-name"
                            onClick={() => handlePreviewFile(filepath, isImage)}
                        >
                            {file.name}
                        </span>
                    </TableCell>
                    <TableCell>
                        {file.room}
                    </TableCell>
                    <TableCell>
                        <Chip size="small" label={file.type} color="primary" />
                    </TableCell>
                    <TableCell>
                        {file.notes || '-'}
                    </TableCell>
                    <TableCell>
                        {file.submitted_by_label}
                    </TableCell>
                    <TableCell>
                        {dateFormat(file.created_at, 'DD/MM/YYYY HH:MM:ss')}
                    </TableCell>
                    <TableCell align="right">
                        {renderDeleteButton(file)}
                    </TableCell>
                </TableRow>
            )
        })
    }

    const renderFilesMobile = () => {
        return filteredFiles.map((file, key) => {
            let src = '';
            let isImage = false;
            let filepath = file.image_url;

            let explode = filepath.split('?');
            const match = /(\.\w+$)/g.exec(explode[0]);

            if (match) {
                if (match[1] === '.pdf' || match[1] === '.PDF') {
                    src = pdfPng;
                } else if (match[1] === '.doc' || match[1] === '.docx') {
                    src = docPng;
                } else {
                    src = filepath;
                    isImage = true;
                }
            }

            const actionButton = renderDeleteButton(file, 'small')

            return (
                <div key={file.id} className={classes.fileCard}>
                    <img height="75" width="75" src={src} alt={file.name} onClick={() => handlePreviewFile(filepath, isImage)} />
                    <div>
                        <span
                            className="file-name"
                            onClick={() => handlePreviewFile(filepath, isImage)}
                        >
                            {file.name}
                        </span>
                        <Chip size="small" label={file.type} color="primary" style={{ alignSelf: 'start' }} />
                        <span>
                            <strong>Notes:</strong> {file.notes || '-'}
                        </span>
                        <span>
                            <strong>By:</strong> {file.submitted_by_label}
                        </span>
                        <span className="date">
                            <strong>At:</strong> {dateFormat(file.created_at, 'DD/MM/YYYY HH:MM')}
                        </span>
                        {
                            actionButton === 'No Action Available' ? null : actionButton
                        }
                    </div>
                </div>
            )
        })
    }

    return (
        <Root>
            <div className={classes.root}>
                <Dialog
                    open={!!previewImage}
                    onClose={() => { setPreviewImage(undefined) }}
                    scroll="body"
                    maxWidth={false}
                >
                    <img src={previewImage} width="100%" alt="preview" />
                </Dialog>
                <Dialog
                    open={modalOpen}
                    onClose={handleModalClose}
                >
                    <DialogTitle>Upload file</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Nama"
                                    name="name"
                                    value={formState.name}
                                    onChange={(e: any) => handleChange(e, 'form')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoComplete
                                    freeSolo
                                    options={captionsOptions}
                                    onChange={(e: React.ChangeEvent<{}>, newValue: string | null) => {
                                        setFormState({ name: 'room', value: newValue, type: 'SET_ITEM' });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            name="room"
                                            variant="outlined"
                                            value={formState.room}
                                            onChange={(e: any) => handleChange(e, 'form')}
                                            placeholder="Max 50 karakter"
                                            label="Room"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Notes"
                                    name="notes"
                                    value={formState.notes}
                                    onChange={(e: any) => handleChange(e, 'form')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    select
                                    name="type"
                                    value={formState.type}
                                    onChange={(e: any) => handleChange(e, 'form')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    <option value="">-- Type --</option>
                                    <option value="Keadaan">Keadaan</option>
                                    <option value="Kerusakan">Kerusakan</option>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="file"
                                    name="file"
                                    onChange={(e: any) => handleChange(e, 'form')}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleUpload} color="primary">
                            Upload
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* <div className={classes.inputContainer}> */}
                {/* <TextField
                    size="small"
                    variant="outlined"
                    name="search"
                    value={searchState.search}
                    onChange={(e: any) => handleChange(e, 'search')}
                    placeholder="Search here"
                /> */}
                {/* <TextField
                    select
                    size="small"
                    variant="outlined"
                    name="type"
                    value={searchState.type}
                    onChange={(e: any) => handleChange(e, 'search')}
                    SelectProps={{
                        native: true,
                    }}
                >
                    <option value="">-- Type --</option>
                    {
                        fileTypes.map(fileType => {
                            return <option value={fileType.value} key={fileType.value}>{fileType.label}</option>
                        })
                    }
                </TextField> */}
                {/* <div className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleModalOpen}
                    >
                        Upload File
                    </Button>
                </div> */}
                {/* </div> */}

                <TableContainer component={Paper} className={classes.LaporanCheckInListTable} elevation={0}>
                    <div className={`${classes.buttonContainer} ${classes.buttonContainerDesktop}`}>
                        {
                            props.permissions['closing-checkin-report.download-pdf-report'] &&
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleDownloadPDF}
                            >
                                <CloudDownloadIcon style={{ marginRight: 8 }} />
                                PDF
                            </Button>
                        }
                        {
                            props.permissions['closing-checkin-report.add'] &&
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleModalOpen}
                            >
                                Upload File
                            </Button>
                        }
                    </div>
                    <div className={`${classes.buttonContainer} ${classes.buttonContainerMobile}`}>
                        {
                            props.permissions['closing-checkin-report.download-pdf-report'] &&
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleDownloadPDF}
                                size="small"
                            >
                                <CloudDownloadIcon fontSize="small" style={{ marginRight: 8 }} />
                                PDF
                            </Button>
                        }
                        {
                            props.permissions['closing-checkin-report.add'] &&
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleModalOpen}
                                size="small"
                            >
                                Upload File
                            </Button>
                        }
                    </div>
                    <div className="mobile-list">
                        {
                            filteredFiles.length > 0 ?
                                renderFilesMobile()
                                :
                                <p>There is no file yet</p>
                        }
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    File
                                </TableCell>
                                <TableCell>
                                    Nama
                                </TableCell>
                                <TableCell>
                                    Ruangan
                                </TableCell>
                                <TableCell>
                                    Type
                                </TableCell>
                                <TableCell>
                                    Notes
                                </TableCell>
                                <TableCell>
                                    Submitted By
                                </TableCell>
                                <TableCell>
                                    Created At
                                </TableCell>
                                <TableCell align="right">
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                filteredFiles.length > 0 ?
                                    renderFiles()
                                    :
                                    <TableRow>
                                        <TableCell colSpan={7}>There is no file yet</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Root>
    );
}

export default LaporanCheckInList;
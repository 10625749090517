import React, { Fragment, useState, useEffect, SyntheticEvent } from 'react';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../LoadingScreen';
import Form from './Form';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { generalErrorHandler, renderWarningButton } from '../../../_utils/Helper';
import { Button, ButtonGroup, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

interface Props {
    API_URL: string;
}

export interface IState {
    id: string;
    name: string;
    url: string;
    sort: number;
}

const VideoForm = ({ API_URL }: Props) => {
    const [formStates, setFormStates] = useState<IState[]>([]);
    const [videoStates, setvideoStates] = useState<IState[]>([]);
    const [mode, setMode] = useState<'add' | 'edit'>('add');
    const [isLoading, setIsLoading] = useState(false);

    const defaultState: IState = {
        id: '',
        name: '',
        url: '',
        sort: 0
    };

    useEffect(() => {
        loadVideos();
        // eslint-disable-next-line
    }, []);

    const loadVideos = () => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL)
            .then(res => {
                const videoGallery = res.data;
                const newvideoStates = [];

                for (let key in videoGallery) {
                    newvideoStates.push({
                        id: videoGallery[key].id,
                        name: videoGallery[key].name,
                        url: videoGallery[key].url,
                        sort: videoGallery[key].sort
                    })
                }

                setvideoStates(newvideoStates);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleNewData = () => {
        setMode('add');
        setFormStates([
            {
                ...defaultState
            }
        ])
        document.querySelectorAll('[type="file"]').forEach(el => {
            (el as HTMLInputElement).value = '';
        })
    }

    const handleChanged = async (e: SyntheticEvent, index: number) => {
        const oldStates: any[] = formStates.slice();
        const target = e.target as HTMLInputElement
        oldStates[index][target.name] = target.value;
        setFormStates(oldStates);
    }

    const handleSelect = (index: number, name: string, value: string | null) => {
        const oldStates: any[] = formStates.slice();
        oldStates[index][name] = value || ''
        setFormStates(oldStates);
    }

    const formAction = (action: string) => {
        const oldState = formStates.slice();
        switch (action) {
            case 'add':
                oldState.push({ ...defaultState });
                break;
            case 'remove':
                oldState.splice(oldState.length - 1, 1);
                break;
            default:
                break;
        }
        setFormStates(oldState);
    }

    const renderForms = () => {
        const formsEl = formStates.map((state, key) => {
            return (
                <Fragment key={key}>
                    <Form form={state} onChange={handleChanged} onSelect={handleSelect} index={key} />
                    <Grid item xs={12}>
                        <hr />
                    </Grid>
                </Fragment>
            )
        });

        const actionButtons = (
            <>
                <Grid item xs={12}>
                    {
                        mode === 'add'
                            ? <ButtonGroup disableElevation variant="contained">
                                <Button color="primary" onClick={() => formAction('add')}>+</Button>
                                <Button color="secondary" onClick={() => formAction('remove')}>-</Button>
                            </ButtonGroup>
                            : null
                    }
                    <Button
                        color="primary"
                        variant="contained"
                        sx={{ float: 'right' }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </>
        )

        return (
            <>
                {formsEl}
                {formStates.length ? actionButtons : null}
            </>
        );
    }

    const handleEditVideo = (videoState: IState) => {
        setMode('edit');
        setFormStates([
            {
                ...videoState
            }
        ])
        document.querySelectorAll('[type="file"]').forEach(el => {
            (el as HTMLInputElement).value = '';
        })
    }

    const handleDeleteVideo = (videoState: IState) => {
        renderWarningButton("Apakah anda yakin ingin menghapus video ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${API_URL}/${videoState.id}`)
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(res => {
                                    loadVideos();
                                })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const checkValidation = () => {
        let isValid = true;

        formStates.map(formState => {
            // if (!formState.file && mode === 'add') {
            //     isValid = false;
            // }

            if (!formState.name) {
                isValid = false;
            }
            return true;
        });

        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            Swal.fire({
                title: "Error",
                text: 'Please fill name and file on every form',
                icon: 'error'
            });
            return;
        }

        let url;
        const fd = new FormData();

        if (mode === 'add') {
            for (let i in formStates) {
                for (let key in formStates[i]) {
                    let value = formStates[i][key as keyof IState];
                    if (value) {
                        fd.append(`param[${i}][${key}]`, String(value));
                    }
                }
            }

            url = API_URL;
        } else {
            for (let i in formStates) {
                for (let key in formStates[i]) {
                    let value = formStates[i][key as keyof IState];
                    if (value) {
                        fd.append(`${key}`, String(value));
                    }
                }
            }

            url = API_URL + '/' + formStates[0].id;
            fd.append('_method', 'PATCH');
        }

        setIsLoading(true);
        DefaultAxios.post(url, fd)
            .then(res => {
                Swal.fire({
                    title: "Submit video berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadVideos();
                        setFormStates([]);
                    })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleSortVideo = (videoId: string, dir: 'up' | 'down', key: number) => {
        setIsLoading(true);
        DefaultAxios
            .post(API_URL + '/' + videoId + '/sort', { dir })
            .then(res => {
                loadVideos();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNewData}
                >
                    Add Video
                </Button>
                <hr />
            </Grid>

            {renderForms()}

            <Grid item xs={12}>
                <hr />
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>URL</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {videoStates.map((videoState, key) => (
                                <TableRow key={videoState.id}>
                                    <TableCell component="th" scope="row">
                                        {videoState.name}
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color="primary"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => window.open(videoState.url)}
                                        >
                                            {videoState.url}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton aria-label="delete" color="primary" onClick={() => handleEditVideo(videoState)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton aria-label="delete" color="secondary" onClick={() => handleDeleteVideo(videoState)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleSortVideo(videoState.id, 'up', key)}
                                            disabled={key === 0}
                                        >
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleSortVideo(videoState.id, 'down', key)}
                                            disabled={key === videoStates.length - 1}
                                        >
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Fragment>
    );
}

export default VideoForm;

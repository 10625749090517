import React, { useEffect, useState } from "react"
import { Paper, Switch, Button, TextField, Grid, MenuItem, InputAdornment, Theme } from '@mui/material';

// Components
import ButtonSelect from "./ButtonSelect"

// Icons
import CloseIcon from '@mui/icons-material/Close';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import BathtubIcon from '@mui/icons-material/Bathtub';

// Utils
import AsyncAutoComplete from "../../../_components/_form/AsyncAutoComplete";
import { numberToCurrency } from "../../../_utils/Helper";
import { generateStyle } from "../../../_utils/DefaultStyle";

const useStyles = generateStyle((theme: Theme) =>
({
  filterCard: {
    zIndex: 1200,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transform: 'translateY(100%)',
    padding: '0.5rem 0.5rem 3rem',
    transition: '0.4s ease-in-out',
    overflowY: 'hidden',
    '& .header': {
      position: 'fixed',
      top: '0.5rem',
      left: 0,
      right: 0,
      overflow: 'hidden',
      borderBottom: '1px solid #E5E5E5',
      zIndex: 1,
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '1rem 0',
      marginBottom: '1rem',
      '& .close-button': {
        position: "absolute",
        left: '1rem'
      },
      '& .header-title': {
        fontSize: 17,
        color: '#000',
        fontWeight: 500
      },
      '& .header-reset': {
        textTransform: 'capitalize',
        position: 'absolute',
        right: '1rem'
      }
    },
    '& .body': {
      marginTop: '4rem',
      overflowY: 'auto',
      height: '100%',
      paddingBottom: '6rem',
      padding: '0 1rem',
      marginBottom: '2.5rem',
      '& .hot-client-toggle': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '0.5rem',
        '& .label-container': {
          '& .label': {
            color: '#363636',
            fontSize: 16,
            fontWeight: 700,
            marginBottom: '0.25rem'
          },
          '& .hint': {
            fontSize: 14,
            color: '#4C4C4C',
            fontWeight: 500
          }
        }
      },
      '& .peruntukan': {
        marginBottom: '2rem',
        '& h2': {
          fontSize: 16
        }
      },
      '& .searchfor': {
        marginBottom: '2rem',
        '& h2': {
          fontSize: 16
        }
      },
      '& .area': {
        marginBottom: '2rem',
        '& h2': {
          fontSize: 16
        }
      },
      '& .room': {
        marginBottom: '2rem',
        '& h2': {
          fontSize: 15
        }
      },
      '& .budget': {
        marginBottom: '2rem',
        '& h2': {
          fontSize: 16
        },
        '& .dash': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& span ': {
            fontSize: 24,
            fontWeight: 100,
            transform: 'scaleX(2)',
            color: '#707070',
            paddingTop: '1rem'
          }
        }
      }
    }
  },
  open: {
    transform: 'translateY(0)',
  },
  applyContainer: {
    position: 'fixed',
    backgroundColor: '#fff',
    overflowY: 'hidden',
    bottom: 0,
    padding: '1rem 0',
    borderTop: '1px solid #E5E5E5',
    left: 0,
    right: 0,
    display: 'flex',
    '& button': {
      width: '90%',
      margin: 'auto',
      textTransform: 'capitalize',
      fontWeight: 500,
      padding: '0.75rem 0',
      borderRadius: '2rem'
    }
  }
}), "Filter_Card"
)

interface IFilterData {
  type: Map<string, boolean>;
  is_hot: boolean;
  only_inactive?: boolean;
  requested_types: Map<string, boolean>;
  price_min: string;
  price_max: string;
  area: string;
  bedroom: number | null;
  bathroom: number | null;
  property_area_min: string;
  property_area_max: string;
  land_area_min: string;
  land_area_max: string;
  location: string
  entity: string
  id: number | null
}

interface IOptions {
  type: object[],
  requested_types: object[]
}

interface IProps {
  open: boolean
  setFilterOpen: Function
  fetchData: Function
  filterData: IFilterData
  setFilterData: Function
  options: IOptions
  setOptions: Function
  onReset: Function
  show_toggle_inactive: boolean
}

const FilterCard = (props: IProps) => {
  const { Root, classes } = useStyles()

  const [changed, setChanged] = useState<boolean>(false)

  const [resetKey, setResetKey] = useState<number>(0)

  /**
   * Change the value of the map that contains selected item
   * @param which indicate which button select
   * @param mode indicate mode for the ButtonSelect : select | radio
   * @param key indicate the key for the map
   * @param value indicate the value for the map
   */
  const handleSelected = (which: keyof IFilterData, mode: string, key: string, value: boolean) => {
    if (mode === 'radio' && (which === 'type' || which === 'requested_types')) {
      const newSelected = props.filterData[which]
      newSelected.clear()
      props.setFilterData({
        ...props.filterData,
        [which]: newSelected.set(key, value)
      })
    } else if (mode === 'select' && (which === 'type' || which === 'requested_types')) {
      props.setFilterData({
        ...props.filterData,
        [which]: props.filterData[which].set(key, value)
      })
    }
    setChanged(true)
  }

  /**
   * Handle change from the numbers
   * @param e event
   */
  const handleNumber = (e: React.ChangeEvent<{ name: string, value: string }>) => {
    let newVal;

    if (!e.target.value) {
      newVal = '';
    } else {
      newVal = numberToCurrency(e.target.value);
    }

    props.setFilterData({
      ...props.filterData,
      [e.target.name]: newVal
    })
    setChanged(true)
  }

  const handleChanged = (e: React.ChangeEvent<{ name: string, value: string }>) => {
    props.setFilterData({
      ...props.filterData,
      [e.target.name]: e.target.value
    })
    setChanged(true)
  }

  /**
   * Handle hot-client toggle
   * @param e event
   */
  const handleHotClientChange = (e: React.ChangeEvent<{ checked: boolean }>) => {
    props.setFilterData({
      ...props.filterData,
      is_hot: e.target.checked
    })
    setChanged(true)
  }

  const handleInactiveChange = (e: React.ChangeEvent<{ checked: boolean }>) => {
    props.setFilterData({
      ...props.filterData,
      only_inactive: e.target.checked
    })
    setChanged(true)
  }

  const handleLocation = (name: string, value: any) => {
    if (value !== '') {
      props.setFilterData({
        ...props.filterData,
        entity: value.entity as string,
        id: value.id as number
      })
    }

    setChanged(true)
  }

  const handleLocationInput = (e: React.ChangeEvent<{ name: string, value: string }>, name: string) => {
    props.setFilterData({
      ...props.filterData,
      location: e.target.value
    })

    if (e.target.value === '') {
      props.setFilterData({
        ...props.filterData,
        entity: '' as string,
        id: null
      })
    }

    setChanged(true)
  }

  /**
   * Close filter card
   */
  const closeFilter = () => {
    if (changed) {
      props.fetchData(true)
      window.scrollTo(0, 0)
    }
    props.setFilterOpen(false)
  }

  /**
   * Handle overflow of the body if the filter card is open
   */
  useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    setChanged(false)
  }, [props.open])

  const reset = () => {
    setChanged(true)
    props.onReset()
    setResetKey(resetKey + 1)
  }

  const isTypeEmpty = () => {
    if (props.filterData.type.size === 0) return true
    let flag = true
    props.filterData.type.forEach((value, key) => {
      if (value) flag = false
    })
    return flag
  }

  return (
    <Root>
      <Paper className={`${classes.filterCard} ${props.open ? classes.open : ''}`}>
        <div className="header">
          <CloseIcon className="close-button" onClick={closeFilter} />
          <span className="header-title">
            Filter
          </span>
          <Button size="small" color="primary" className="header-reset" onClick={reset}>
            Reset
          </Button>
        </div>
        <div className="body">
          <div className="hot-client-toggle">
            <div className="label-container">
              <span className="hint">Hanya tampilkan Listing Hot</span>
            </div>
            <Switch
              checked={props.filterData.is_hot}
              onChange={handleHotClientChange}
            />
          </div>

          {props.show_toggle_inactive &&
            <div className="hot-client-toggle">
              <div className="label-container">
                <span className="hint">Hanya tampilkan Listing aktif</span>
              </div>
              <Switch
                checked={props.filterData.only_inactive}
                onChange={handleInactiveChange}
              />
            </div>
          }

          <div className="peruntukan">
            <h2>Peruntukan</h2>
            <ButtonSelect list={props.options.type} selected={props.filterData.type} handleSelected={handleSelected} type="type" mode="select" />
          </div>
          <div className="searchfor">
            <h2>Tipe Properti</h2>
            <ButtonSelect list={props.options.requested_types} selected={props.filterData.requested_types} handleSelected={handleSelected} type="requested_types" mode="select" />
          </div>
          <div className="area">
            <h2>Area</h2>
            <Grid container spacing={1}>
              <Grid item xs>
                <AsyncAutoComplete
                  key={resetKey}
                  label=""
                  url={process.env.REACT_APP_API_URL + '/autocomplete/location/'}
                  name="location"
                  initialQuery={""}
                  onChange={handleLocation}
                  onInputChange={handleLocationInput}
                  placeholder="Masukkan nama area"
                  param="query"
                />
              </Grid>
            </Grid>
          </div>
          <div className="room">
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <h2>Jumlah Kamar</h2>
              </Grid>
              <Grid item xs={2} className="dash">

              </Grid>
              <Grid item xs={5}>
                <h2>Jumlah Kamar Mandi</h2>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <TextField
                  select
                  variant="outlined"
                  value={props.filterData.bedroom || ''}
                  name="bedroom"
                  onChange={handleChanged}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalHotelIcon style={{ color: '#033A11' }} />
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="">-</MenuItem>
                  <MenuItem value="0">Studio</MenuItem>
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5+</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={2} className="dash"></Grid>
              <Grid item xs={5}>
                <TextField
                  select
                  variant="outlined"
                  value={props.filterData.bathroom || ''}
                  name="bathroom"
                  onChange={handleChanged}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BathtubIcon style={{ color: '#033A11' }} />
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="">-</MenuItem>
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5+</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </div>
          <div className="budget">
            <h2>Luas Tanah</h2>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <TextField
                  label="Minimum"
                  value={props.filterData.land_area_min}
                  onChange={handleNumber}
                  name="land_area_min"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={2} className="dash">
                <span>-</span>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Maximum"
                  value={props.filterData.land_area_max}
                  onChange={handleNumber}
                  name="land_area_max"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div className="budget">
            <h2>Luas Bangunan</h2>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <TextField
                  label="Minimum"
                  value={props.filterData.property_area_min}
                  onChange={handleNumber}
                  name="property_area_min"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={2} className="dash">
                <span>-</span>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Maximum"
                  value={props.filterData.property_area_max}
                  onChange={handleNumber}
                  name="property_area_max"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          {
            !isTypeEmpty() &&
            <div className="budget">
              <h2>Budget</h2>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <TextField
                    label="Minimum"
                    value={props.filterData.price_min}
                    onChange={handleNumber}
                    name="price_min"
                  />
                </Grid>
                <Grid item xs={2} className="dash">
                  <span>-</span>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Maximum"
                    value={props.filterData.price_max}
                    onChange={handleNumber}
                    name="price_max"
                  />
                </Grid>
              </Grid>
            </div>
          }
        </div>
        <div className={classes.applyContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={closeFilter}
          >
            Apply Filter
          </Button>
        </div>
      </Paper>
    </Root>
  )
}

export default FilterCard

import React, { useState, useEffect } from 'react';
import { Dialog, AppBar, Theme, Toolbar, IconButton, Typography, Slide, Button, DialogContent, Grid, DialogTitle, DialogActions } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../../_components/LoadingScreen';
import InfoTable from '../../../_pages/_listingList/InfoTable';
import TextArea from '../../../_components/_form/TextArea';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import { TransitionProps } from '@mui/material/transitions';
import { generalErrorHandler, numberToCurrency, renderWarningButton } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    box: {
        padding: theme.spacing(2)
    },
    paper: {
        margin: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
}), 'BookingDetail'
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Stay360BookingDetailModal = ({ booking_id, open, onClose, onEdit }: { booking_id: number, open: boolean, onClose: Function, onEdit?: Function }) => {
    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [detail, setDetail] = useState<any>({});
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [modalImage, setModalImage] = useState(false);
    const handleOpenImg = () => setModalImage(true);
    const handleCloseImg = () => setModalImage(false);
    const handleCancelModalOpen = () => setCancelModalOpen(true);
    const handleCancelModalClose = () => setCancelModalOpen(false);

    useEffect(() => {
        if (booking_id !== 0 && open) {
            loadData();
        }
        // eslint-disable-next-line
    }, [booking_id, open]);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/stay360-booking/${booking_id}`)
            .then(res => {
                setDetail(res.data);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleCancel = () => {
        if (!cancelReason.trim()) {
            return;
        }

        renderWarningButton("Apakah anda yakin ingin membatalkan booking ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/stay360-booking/cancel`, { id: booking_id, cancel_reason: cancelReason })
                        .then(res => {
                            Swal.fire({
                                title: "Booking cancelled",
                                icon: 'success',
                                onAfterClose: () => {
                                    setCancelReason('');
                                    handleCancelModalClose();
                                    loadData();
                                    onClose();
                                },
                                timer: 1000
                            });
                        })
                }
            });
    }

    return (
        <Root>
            <Dialog
                open={modalImage}
                onClose={handleCloseImg}
                closeAfterTransition
                TransitionComponent={Transition}
                fullWidth
                maxWidth="sm"
            >
                <img src={detail.ktp_url} alt="" style={{ width: "100%" }} />
            </Dialog>
            <Dialog
                open={open}
                onClose={() => onClose()}
                fullWidth
                maxWidth="lg"
            >
                <Dialog
                    open={cancelModalOpen}
                    onClose={handleCancelModalClose}
                    fullWidth
                    maxWidth="md"
                >
                    <Root>
                        <DialogTitle>Cancel Booking</DialogTitle>
                        <DialogContent>
                            <TextArea
                                label="Cancel Reason"
                                name="cancel_reason"
                                value={cancelReason}
                                onChange={(e) => setCancelReason(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                onClick={handleCancel}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </Root>
                </Dialog>
                <LoadingScreen open={isLoading} fullScreen />
                <AppBar className={classes.appBar} color={detail.cancel_reason ? 'secondary' : 'primary'}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit"
                            onClick={() => { onClose() }}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Booking Detail
                        </Typography>
                        {
                            typeof onEdit !== 'undefined'
                                ? <IconButton edge="end" color="inherit"
                                    onClick={() => onEdit()}
                                >
                                    <EditIcon />
                                </IconButton>
                                : null
                        }
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid item xs={12} sm={6}>
                        <InfoTable
                            data={[
                                ["Booking ID", detail.booking_id, false],
                                ["OTA", detail.ota, false],
                                ["Kode Unit", detail.unit_label, false],
                                ["Nama", detail.name, false],
                                ["Phone", detail.phone, false],
                                ["Check In", detail.check_in, false],
                                ["Check Out", detail.check_out, false],
                                ["Created By", detail.created_by_name, false],
                                ["Rate List", detail.rates?.map((rate: string) => numberToCurrency(rate, 'Rp. ')).join(' - '), false],
                                ["KTP", detail.ktp_url ? <img onClick={handleOpenImg} src={detail.ktp_url} style={{ cursor: "pointer", verticalAlign: 'top' }} alt="ktp" width="200px" /> : '-', false],
                                ["Notes", detail.notes || '-', false]
                            ]}
                        />
                        {
                            !detail.cancel_reason &&
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={handleCancelModalOpen}
                            >
                                Cancel Closing
                            </Button>
                        }
                    </Grid>
                </DialogContent>
            </Dialog>
        </Root>
    );
}

export default Stay360BookingDetailModal;
import React from 'react'

type Props = {}

const TotalNOCIcon = (props: Props) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.7968 25.668C15.5488 25.668 16.1585 25.0583 16.1585 24.3062C16.1585 23.5542 15.5488 22.9445 14.7968 22.9445C14.0447 22.9445 13.4351 23.5542 13.4351 24.3062C13.4351 25.0583 14.0447 25.668 14.7968 25.668Z" fill="#ECA73F" />
            <path d="M20 0C8.95469 0 0 8.95469 0 20C0 31.0453 8.95469 40 20 40C31.0453 40 40 31.0453 40 20C40 8.95469 31.0453 0 20 0ZM30.1156 17.1531C29.9789 17.2899 29.8167 17.3984 29.638 17.4724C29.4594 17.5464 29.268 17.5845 29.0746 17.5845C28.8813 17.5845 28.6898 17.5464 28.5112 17.4724C28.3326 17.3984 28.1703 17.2899 28.0336 17.1531L26.1766 15.2961L25.243 16.2336L26.6492 17.6398C26.8491 17.8398 26.9614 18.111 26.9614 18.3937C26.9614 18.6765 26.8491 18.9477 26.6492 19.1477C26.4492 19.3475 26.1781 19.4598 25.8953 19.4598C25.6126 19.4598 25.3414 19.3475 25.1414 19.1477L23.7352 17.7414L19.5703 21.9023C20.1408 23.0369 20.2885 24.3376 19.9869 25.5713C19.6853 26.8049 18.9542 27.8907 17.9246 28.6342C16.895 29.3776 15.6343 29.73 14.3684 29.6282C13.1026 29.5264 11.9144 28.977 11.0168 28.0786C10.1193 27.1802 9.57111 25.9914 9.47056 24.7255C9.37 23.4595 9.72366 22.1992 10.4681 21.1703C11.2126 20.1415 12.2992 19.4114 13.5331 19.1111C14.767 18.8107 16.0675 18.9597 17.2016 19.5312L25.9219 10.8117C26.0684 10.6651 26.2424 10.5489 26.4339 10.4695C26.6254 10.3902 26.8306 10.3494 27.0379 10.3494C27.2452 10.3494 27.4504 10.3902 27.6419 10.4695C27.8334 10.5489 28.0074 10.6651 28.1539 10.8117L28.2914 10.9492C28.438 11.0958 28.5543 11.2698 28.6336 11.4612C28.7129 11.6527 28.7537 11.858 28.7537 12.0652C28.7537 12.2725 28.7129 12.4777 28.6336 12.6692C28.5543 12.8607 28.438 13.0347 28.2914 13.1812L28.2586 13.2141L30.1156 15.0711C30.2524 15.2078 30.3609 15.3701 30.4349 15.5487C30.5089 15.7273 30.547 15.9188 30.547 16.1121C30.547 16.3055 30.5089 16.4969 30.4349 16.6755C30.3609 16.8542 30.2524 17.0164 30.1156 17.1531Z" fill="#ECA73F" />
        </svg>

    )
}

export default TotalNOCIcon
import React from 'react'
import { generateStyle } from '../../../_utils/DefaultStyle'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ITRPerformance } from '../TRPerformance'
import { numberToCurrency } from '../../../_utils/Helper'

type Props = {
    data: ITRPerformance['unpaid_jhs']
}

const useStyles = generateStyle(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
    },
    headerCell: {
        color: '#fff'
    },
    cell: {
        textAlign: 'center'
    }
}), "TRPerformanceBill_Table")

const TRPerformanceBillTable = (props: Props) => {
    const { Root, classes } = useStyles()
    return (
        <Root style={{ width: '100%' }}>
            <TableContainer>
                <Table>
                    <TableHead className={classes.header}>
                        <TableRow>
                            <TableCell className={`${classes.cell} ${classes.headerCell}`}>Detail</TableCell>
                            <TableCell className={`${classes.cell} ${classes.headerCell}`}>Owner</TableCell>
                            <TableCell className={`${classes.cell} ${classes.headerCell}`}>Tenant</TableCell>
                            <TableCell className={`${classes.cell} ${classes.headerCell}`}>Deposit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={`${classes.cell} `}>Total Order</TableCell>
                            <TableCell className={`${classes.cell} `}>{props.data.owner.total}</TableCell>
                            <TableCell className={`${classes.cell} `}>{props.data.tenant.total}</TableCell>
                            <TableCell className={`${classes.cell} `}>{props.data.deposit.total}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={`${classes.cell} `}>Total Tagihan</TableCell>
                            <TableCell className={`${classes.cell} `}>{numberToCurrency(props.data.owner.billing_nominal, 'Rp. ')}</TableCell>
                            <TableCell className={`${classes.cell} `}>{numberToCurrency(props.data.tenant.billing_nominal, 'Rp. ')}</TableCell>
                            <TableCell className={`${classes.cell} `}>{numberToCurrency(props.data.deposit.billing_nominal, 'Rp. ')}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Root>
    )
}

export default TRPerformanceBillTable
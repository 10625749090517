import { Button, Chip, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import LoadingScreen from '../../../_components/LoadingScreen'
import { ITelesalesArea } from '../TelesalesAreaList'
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../../_utils/Helper'

type Props = {
    open: boolean
    type: "advisor-sell" | "telesales"
    onClose: (refresh: boolean) => void
    telesalesData: ITelesalesArea | null
}

const initialTelesales: ITelesalesArea = {
    area_id: '',
    area_name: '',
    id: '',
    name: ''
}

const ChangeAreaModal = (props: Props) => {
    const [telesales, setTelesales] = useState<ITelesalesArea>(initialTelesales)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}${props.type === 'advisor-sell' ?  '/area-advisor-sell' : '/area-telesales'}`, {
            area_id: telesales.area_id.split(','),
            id: telesales.id
        })
            .then(res => res.data)
            .then(res => {
                renderSuccessButton('Berhasil Submit')
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handelDeleteArea = (idx: number) => {
        const telesalesIds = telesales.area_id.split(',')
        const telesalesAreas = telesales.area_name.split(',')
        telesalesIds.splice(idx, 1)
        telesalesAreas.splice(idx, 1)
        setTelesales(prev => ({
            ...prev,
            area_id: telesalesIds.join(','),
            area_name: telesalesAreas.join(',')
        }))
    }

    useEffect(() => {
        if (props.telesalesData !== null) {
            setTelesales(props.telesalesData)
        } else {
            setTelesales(initialTelesales)
        }
    }, [props.telesalesData])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>
                Change {props.type === 'advisor-sell' ? 'Advisor Sell' : 'Telesales'} Area
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <TextField
                            name='name'
                            label="Nama"
                            value={telesales.name}
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            name='area_id'
                            onChange={(name: string, value: any) => {
                                setTelesales(prev => ({
                                    ...prev,
                                    area_id: prev.area_id ? prev.area_id.concat(',', value.id) : String(value.id),
                                    area_name: prev.area_name ? prev.area_name.concat(',', value.label) : value.label
                                }))
                            }}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/area/${props.type === 'advisor-sell' ?  'advisor-sell-not-assigned' : 'telesales-not-assigned'}?exclude_ids=${telesales.area_id}`}
                            withoutQuery
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                        {
                            telesales.area_name ?
                                telesales.area_name.split(',').map((area, idx) => (
                                    <Chip key={area} onDelete={() => handelDeleteArea(idx)} label={area} />
                                ))
                                : null
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            sx={{ float: 'right' }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ChangeAreaModal
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import moment from 'moment';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../../_components/LoadingScreen';
import MuiDatePicker from '../../../_components/MuiDatePicker';

/**
 * Utils
 */
import { sourceLists, marketplaceFromOptions } from '../../../_utils/ConstData';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { currencyToNumber, generalErrorHandler } from '../../../_utils/Helper';
import { IValidationErrors } from '../../../_utils/Validation';
import TextArea from '../../../_components/_form/TextArea';

interface Props {
    open: boolean;
    onClose: () => void;
    enquiryId: string;
}

interface IState {
    customer_name: string,
    customer_phone: string,
    estimated_budget_dollar: string,
    estimated_budget_rupiah: string,
    estimated_check_in: Date | null,
    estimated_rent_period: string
    source: string
    marketplace_from: string
    marketplace_from_other: string
    keterangan: string
}

interface IAction {
    name: string,
    value: any,
    type: string;
}

const EnquiryDetailEdit = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/enquiry';

    const initialState = {
        customer_name: '',
        customer_phone: '',
        estimated_budget_dollar: '',
        estimated_budget_rupiah: '',
        estimated_check_in: null,
        estimated_rent_period: '',
        source: '',
        marketplace_from: '',
        marketplace_from_other: '',
        keterangan: '',
    };

    const currencyAttributes = [
        'estimated_budget_dollar',
        'estimated_budget_rupiah'
    ];

    const numberAttributes = [
        'estimated_rent_period'
    ];

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useState<IValidationErrors>({})

    const [isLoading, setIsLoading] = useState(false);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${API_URL}/${props.enquiryId}`)
            .then(res => {
                const data = res.data;
                setInputState({
                    name: '', value: {
                        ...data,
                        marketplace_from: data.source === 'marketplace' && !marketplaceFromOptions.some(marketplace => marketplace.value === data.marketplace_from) ? 'other' : data.marketplace_from,
                        marketplace_from_other: data.source === 'marketplace' && !marketplaceFromOptions.some(marketplace => marketplace.value === data.marketplace_from) ? data.marketplace_from : data.marketplace_from_other,
                    }, type: 'REPLACE_STATE'
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (props.open) {
            loadData();
        }
        // eslint-disable-next-line
    }, [props.open]);

    const checkValidation = () => {
        const newError: IValidationErrors = {}
        let isValid = true

        if (inputState.source === 'marketplace') {
            if (!inputState.marketplace_from) {
                newError.marketplace_from = 'Marketplace from wajib diisi apabila enquiry dari "Marketplace"';
                isValid = false;
            } else if (inputState.marketplace_from === 'other' && !inputState.marketplace_from_other) {
                newError.marketplace_from_other = 'Marketplace from other wajib diisi apabila marketplace dari "Lainnya"';
                isValid = false;
            }
        }

        setErrorState(newError)

        return isValid
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (currencyAttributes.includes(name)) {
            value = currencyToNumber(value);
        } else if (numberAttributes.includes(name)) {
            value = String(value).replace(/\D+/, '');
        }

        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleDateChange = (date: Date | null, value?: string | null | undefined) => {
        setInputState({ name: 'estimated_check_in', value: date, type: 'SET_ITEM' });
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return
        }

        setIsLoading(true);

        const data: any = { ...inputState };
        data.estimated_check_in = inputState.estimated_check_in ? moment(inputState.estimated_check_in).format('YYYY-MM-DD') : null;
        if (inputState.source === 'marketplace' && inputState.marketplace_from === 'other') data.marketplace_from = data.marketplace_from_other

        DefaultAxios
            .patch(`${API_URL}/${props.enquiryId}`, data)
            .then(res => {
                Swal.fire({
                    title: 'Update berhasil',
                    icon: 'success',
                    timer: 1000,
                    onAfterClose: props.onClose
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth="lg"
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>Edit Enquiry</DialogTitle>
            <DialogContent>
                <Grid container spacing={3} style={{ paddingTop: 8 }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Name"
                            name="customer_name"
                            value={inputState.customer_name}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Phone"
                            name="customer_phone"
                            value={inputState.customer_phone}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <MuiDatePicker
                            label="Estimated Checkin"
                            value={inputState.estimated_check_in}
                            onChange={handleDateChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Estimated Rent Period"
                            name="estimated_rent_period"
                            value={inputState.estimated_rent_period}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Bulan</InputAdornment>,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            label="Source"
                            name="source"
                            value={inputState.source}
                            onChange={handleChange}
                            error={!!errorState.source}
                            helperText={errorState.source}
                        >
                            {(sourceLists).map(sourceList => <MenuItem value={sourceList.key} key={sourceList.key}>{sourceList.value}</MenuItem>)}
                        </TextField>
                    </Grid>

                    {
                        inputState.source === 'marketplace' && // Marketplace
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                label="Marketplace From"
                                name="marketplace_from"
                                value={inputState.marketplace_from}
                                onChange={handleChange}
                                error={!!errorState.marketplace_from}
                                helperText={errorState.marketplace_from}
                            >
                                {marketplaceFromOptions.map(item => <MenuItem value={item.value} key={item.value} >{item.label}</MenuItem>)}
                            </TextField>
                        </Grid>
                    }

                    {
                        (inputState.source === 'marketplace' && inputState.marketplace_from === 'other') && // Other Marketplace
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Marketplace Other"
                                name="marketplace_from_other"
                                value={inputState.marketplace_from_other}
                                onChange={handleChange}
                                error={!!errorState.marketplace_from_other}
                                helperText={errorState.marketplace_from_other}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextArea
                            label="Notes"
                            name="keterangan"
                            value={inputState.keterangan}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EnquiryDetailEdit;
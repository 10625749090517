import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import GeneralModal from '../../../_components/_modal/GeneralModal'
import { Grid, TextField } from '@mui/material'

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, inputNumber, renderToastSuccess } from '../../../_utils/Helper'

interface ChangePhoneNumberModalProps {
    open: boolean
    onClose: () => void
    onSuccess: () => void
    unitId: string
}

const ChangePhoneNumberModal = (props: ChangePhoneNumberModalProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const [phoneNumber, setPhoneNumber] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        if (!props.open) {
            setPhoneNumber('')
            setError('')
        }
    }, [props.open])

    const handleSubmit = () => {
        if (phoneNumber === '') {
            setError('Harap mengisi nomor hp')
        } else {
            setError('')
            setIsLoading(true)

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/rent-apartment/change-phone`, {
                id: props.unitId,
                phone: phoneNumber,
            })
                .then(() => {
                    renderToastSuccess('Berhasil mengubah nomor hp')
                    props.onSuccess()
                    props.onClose()
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <GeneralModal
            {...props}
            title='Change Phone Number'
            maxWidth='xs'
            buttons={[
                {
                    text: 'Submit',
                    color: 'primary',
                    onClick: handleSubmit,
                    isLoading,
                }
            ]}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="New Phone Number"
                        value={inputNumber(phoneNumber)}
                        error={error !== ''}
                        helperText={error}
                        fullWidth
                        onChange={e => {
                            setPhoneNumber(inputNumber(e.target.value))
                            setError('')
                        }}
                        disabled={isLoading}
                    />
                </Grid>
            </Grid>
        </GeneralModal>
    )
}

export default ChangePhoneNumberModal

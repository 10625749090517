import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen'

/**
 * Utils
 */
import { Theme } from '@mui/material'
import { useNavigate } from 'react-router'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import { generateStyle } from '../../_utils/DefaultStyle'

type IQCUnit = {
    activity: string
    activity_id: number
    closing_id: string
    apartment_name: string
    tower: string
    floor_level: number
    unit_number: string
    unit_code: string
}

const QualityControlUnitList = () => {
    const { Root, classes } = useStyles()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)

    const [list, setList] = useState<IQCUnit[]>([])

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/qc-unit`)
            .then(res => res.data)
            .then(data => {
                setList(data)
                setIsLoading(false)
            })
            .catch(err => {
                generalErrorHandler(err)
                setIsLoading(false)
            })
    }

    const openInventory = (activity_id: number) => {
        navigate(`/qc-unit-list/inventory/${activity_id}`, { state: { prevPath: window.location.pathname } })
    }

    const renderCard = (unit: IQCUnit) =>
        <div key={unit.activity_id} onClick={() => openInventory(unit.activity_id)} className={classes.card}>
            <span className="code">
                {unit.unit_code}
            </span>
            <span className="apartment">
                {unit.apartment_name}
            </span>
            <span className="unit">
                {`Tw. ${unit.tower} Lt. ${unit.floor_level} Unit Number ${unit.unit_number}`}
            </span>
        </div>

    return (
        <Root>
            <LoadingScreen fullScreen open={isLoading} />
            <div className={classes.root}>
                {
                    list.length ?
                        list.map(unit =>
                            renderCard(unit)
                        )
                        :
                        !isLoading &&
                        <span className={classes.empty}>
                            Maaf, belum ada data unit
                        </span>
                }
            </div>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) =>
({
    root: {
        padding: 16,
        display: 'grid',
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        gap: 20,
        [theme.breakpoints.up('md')]: {
            maxWidth: 400,
        }
    },
    card: {
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: '3px 5px 10px 0px rgba(0,0,0,0.24)',
        padding: '16px 16px 16px 20px',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        '& > .code': {
            fontSize: 10,
            opacity: 0.7,
            marginBottom: 2,
        },
        '& > .apartment': {
            fontSize: 18,
            fontWeight: '600',
            marginBottom: 6,
        },
        '& > .unit': {
            fontSize: 12,
            opacity: 0.85,
        },
    },
    empty: {
        fontSize: 18,
        opacity: 0.85,
        textAlign: 'center',
    },
}), "QCUnitList"
)

export default QualityControlUnitList

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import {
    Button,
    Theme
} from '@mui/material';
import { addWeeks, format, subWeeks } from 'date-fns';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import ActivityModalDetail from './_components/ActivityModalDetail';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import { generateStyle } from '../../_utils/DefaultStyle';

/**
 * Utils
 */

interface IProps {
    id?: string
}
interface IParams extends Record<string, string | undefined> {
    id?: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.dark,
        }
    },
}), "Dashboard"
)

const ActivityList = (props: IProps) => {
    const { Root, classes } = useStyles();
    const navigate = useNavigate();
    const [selectedId, setSelectedId] = useState<string>('');
    const [modalDetailState, setModalDetailState] = useState(false);
    const params = useParams<IParams>()
    const handleOpenDetail = () => setModalDetailState(true);
    const dateEnd = addWeeks(new Date(), 1)
    const dateStart = subWeeks(new Date(), 1)
    const [redrawCounter, setRedrawCounter] = useState(0);
    const [parentQueryString, setParentQueryString] = useState('')

    const handleCloseDetail = (redraw?: boolean) => {
        setModalDetailState(false)
        window.history.pushState([], 'Activity List', `/activity${parentQueryString ? `?${parentQueryString}` : ''}`);

        if (redraw) {
            setRedrawCounter(redrawCounter + 1);
        }
    };

    useEffect(() => {
        if (typeof params.id !== "undefined") {
            setSelectedId(params.id);
            handleOpenDetail();
        }
    }, [params]);

    return (
        <Root>
            <ActivityModalDetail
                open={modalDetailState}
                onClose={(redraw?: boolean) => handleCloseDetail(redraw)}
                id={Number(selectedId)}
            />
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => navigate('/activity/add')}
            >
                Add Activity
            </Button>
            <DataTable
                reDraw={redrawCounter}
                url={`${process.env.REACT_APP_API_URL}/activity`}
                columns={[
                    {
                        name: 'date',
                        label: 'Tanggal',
                        type: 'date',
                        filterable: true,
                        defaultOption: `${format(dateStart, 'yyyy-MM-dd')} - ${format(dateEnd, 'yyyy-MM-dd')}`,
                        render: (data: any, row: any) => {
                            return format(new Date(data), 'dd MMM yyyy')
                        }
                    },
                    {
                        name: 'code',
                        label: 'Code',
                        filterable: true,
                        render: (data: any, row: any) => {
                            return (
                                <span
                                    className={classes.link}
                                    onClick={() => {
                                        window.history.pushState([], 'Activity Detail', `/activity/${row.id}`);
                                        setSelectedId(row.id);
                                        handleOpenDetail();
                                    }}
                                >
                                    {data ? data : '[KLIK]'}
                                </span>
                            );
                        }
                    },
                    {
                        name: 'time',
                        label: 'Jam',
                        type: 'string',
                        filterable: false,
                        sortable: false,
                        render: (data: any, row: any) => {
                            return (
                                `${row.time.slice(0, row.time.length - 3)}`
                            )
                        }
                    },
                    {
                        name: 'verified_at',
                        label: 'Verified',
                        type: 'string',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '1',
                                value: 'Yes'
                            },
                            {
                                key: '0',
                                value: 'No'
                            },
                        ],
                        defaultOption: '',
                        filterable: true,
                        render: (data: any) => {
                            return !!data ? 'Yes' : 'No';
                        }
                    },
                    {
                        name: 'activity',
                        label: 'Activity',
                        filterable: true,
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '3',
                                value: 'Maintenance'
                            },
                            {
                                key: 'visit_apartment',
                                value: 'Visit Apartment'
                            },
                            {
                                key: 'visit_owner',
                                value: 'Visit Owner'
                            },
                            {
                                key: 'visit_tenant',
                                value: 'Visit Tenant'
                            },
                        ],
                        defaultOption: '',
                        render: (data: any, row: any) => {
                            return (
                                row.activity_name
                            );
                        }
                    },
                    { name: 'apartment', label: 'Apartment', filterable: true },
                    { name: 'unit_code', label: 'Kode Unit', filterable: true },
                    { name: 'pic_name', label: 'PIC Jendela', filterable: true },
                    {
                        name: 'client', label: 'Client', filterable: true, render: (data: any, row: any) => {
                            return data
                        }
                    },
                    { name: 'created_by', label: 'Created by', filterable: true },
                    { name: 'verified_by', label: 'Verified by', filterable: true },
                ]}
                setParentQueryString={setParentQueryString}
            />
        </Root>
    );
}

export default ActivityList;
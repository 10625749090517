import { Button, ButtonGroup, Grid } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ArticleForm from '../../_components/_form/ArticleForm'
import FAQForm from '../../_components/_form/FAQForm'
import UnitGalleryForm from '../_unit/UnitGalleryForm'
import ApartmentBasicInfoForm from './_forms/ApartmentBasicInfoForm'
import ApartmentHighlightForm from './_forms/ApartmentHighlightForm'
import { PermissionContext } from '../../_contexts/PermissionContext';
import SLPForm from '../../_components/_form/SLPForm'
import PopularDestinationsForm from '../../_components/_form/PopularDestinationsForm'

interface IParams extends Record<string, string | undefined> {
    id?: string
    type?: 'basic_info' | 'gallery' | 'highlight' | 'article' | 'faq' | 'slp' | 'popular-destinations'
}

type FormType = 'basic_info' | 'gallery' | 'highlight' | 'article' | 'faq' | 'slp' | 'popular-destinations'

const ApartmentForm = () => {
    const navigate = useNavigate()
    const params = useParams<IParams>()
    const permissions = useContext(PermissionContext)
    const [type, setType] = useState<FormType>(typeof params.type !== 'undefined' ? params.type : 'basic_info')

    useEffect(() => {
        if (!permissions['apartment.see-detail']) {
            navigate('/apartment')
        } else if (typeof params.type !== 'undefined' && !['basic_info', 'gallery', 'highlight', 'article', 'faq', 'slp', 'popular-destinations'].includes(params.type)) {
            navigate('/apartment')
        }
    }, [permissions, navigate, params.type])

    const renderPage = () => {
        if (params.id) {
            switch (type) {
                case 'basic_info':
                    return (
                        <ApartmentBasicInfoForm
                            id={params.id}
                        />
                    )
                case 'gallery':
                    return (
                        <UnitGalleryForm
                            id={params.id}
                            apiUrlImage={process.env.REACT_APP_API_URL + '/apartment/' + params.id + '/image'}
                            apiUrl360={process.env.REACT_APP_API_URL + '/apartment/' + params.id + '/360-photo'}
                            with360Videos={false}
                            type="apartment"
                        />
                    )
                case 'highlight':
                    return (
                        <ApartmentHighlightForm
                            id={params.id}
                        />
                    )
                case 'slp':
                    return (
                        <SLPForm
                            id={+params.id}
                            type={'apartment'}
                        />
                    )
                case 'article':
                    return (
                        <Grid item xs={12}>
                            <ArticleForm
                                url={`${process.env.REACT_APP_API_URL}/apartment-article-main`}
                                buttonGroupOne={{
                                    key: 'type',
                                    default: 'mainid',
                                    list: [
                                        {
                                            id: 'mainid',
                                            label: 'Indonesia',
                                        },
                                        {
                                            id: 'mainen',
                                            label: 'Inggris',
                                        },
                                    ]
                                }}
                                withoutTitle
                                withoutMetaTitle
                                withoutMetaDescription
                                customArticleParams='text'
                                extraParams={{
                                    apartment_id: params.id,
                                }}
                                articleNotRequired
                            />
                        </Grid>
                    )
                case 'faq':
                    return (
                        <FAQForm
                            url={`${process.env.REACT_APP_API_URL}/apartment/${params.id}/faq`}
                        />
                    )
                case 'popular-destinations':
                    return (
                        <PopularDestinationsForm
                            url={`${process.env.REACT_APP_API_URL}/apartment/${params.id}/popular-destination`}
                        />
                    )
                default:
                    return null
            }
        } else {
            return (
                <ApartmentBasicInfoForm
                    id={params.id}
                />
            )
        }
    }

    const handleNavigation = (route: FormType) => {
        if (route !== type) {
            if (params.id) {
                if (route === 'basic_info') {
                    navigate(`/apartment/edit/${params.id}`);
                } else {
                    navigate(`/apartment/edit/${params.id}/${route}`);
                }
                setType(route);
            }
        }
    }

    return (
        <Grid container spacing={3}>
            {
                params.id ?
                    <Grid item xs={12}>
                        <ButtonGroup
                            color="primary"
                            aria-label="outlined primary button group"
                            fullWidth
                        >
                            <Button
                                variant={type === 'basic_info' ? 'contained' : 'outlined'}
                                onClick={() => handleNavigation('basic_info')}
                            >
                                Basic Info
                            </Button>
                            <Button
                                variant={type === 'gallery' ? 'contained' : 'outlined'}
                                onClick={() => handleNavigation('gallery')}
                            >
                                Gallery
                            </Button>
                            <Button
                                variant={type === 'highlight' ? 'contained' : 'outlined'}
                                onClick={() => handleNavigation('highlight')}
                            >
                                Highlight
                            </Button>
                            {
                                permissions['apartment.article'] ?
                                    <Button
                                        variant={type === 'article' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('article')}
                                    >
                                        Article
                                    </Button>
                                    : null
                            }
                            {
                                permissions['slp.manage'] ?
                                    <Button
                                        variant={type === 'slp' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('slp')}
                                    >
                                        SLP
                                    </Button>
                                    : null
                            }
                            <Button
                                variant={type === 'faq' ? 'contained' : 'outlined'}
                                onClick={() => handleNavigation('faq')}
                            >
                                FAQ
                            </Button>
                            {
                                permissions['apartment.manage-destination-popular'] ?
                                    <Button
                                        variant={type === 'popular-destinations' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('popular-destinations')}
                                    >
                                        Destinasi Populer
                                    </Button>
                                    : null
                            }
                            {/* {
                                typeof props.permissions !== 'undefined' && props.permissions['unit.crud-image']
                                    ?
                                    : null
                            } */}
                            {/* <Button 
                                variant={type === 'files' ? 'contained' : 'outlined'}
                                onClick={() => handleNavigation('files')}
                            >
                                Files
                            </Button> */}
                        </ButtonGroup>
                    </Grid>
                    : null
            }
            {renderPage()}
        </Grid>
    )
}

export default ApartmentForm

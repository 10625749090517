import React, { useReducer, useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Button, Card, CardActions, CardContent, Theme, Link } from '@mui/material';
// Components
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    location_id?: string
    is_apartment?: string
}

interface IState {
    location_id: string;
    is_apartment?: string
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    submitButton: {
        marginBottom: '20px'
    },
    cardRoot: {
        textAlign: 'center',
        margin: '0 10rem',
        [theme.breakpoints.down('sm')]: {
            margin: '6rem 0',
            border: 'none'
        },
    },
    cardActRoot: {
        display: 'inherit',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "Stay360PmsHome"
)

const Stay360PmsList = (props?: IProps) => {
    const { Root, classes } = useStyles();
    const [locationId, setLocationId] = useState<any>('')
    const [label, setLabel] = useState<any>('')
    const [isApartment, setIsApartment] = useState<any>('')
    const initialState = {
        location_id: '',
    };
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };
    const [inputState, setInputState] = useReducer(inputReducer, initialState);


    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        console.log(inputState);
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
        setLocationId(value.id)
        setLabel(value.label)
        setIsApartment(value.is_apartment)
    }

    return (
        <Root>
            <Card className={classes.cardRoot} variant="outlined">
                <CardContent>
                    <AsyncAutoComplete
                        // value={bookingData.properties}
                        label="Please Input the Location"
                        name="booking"
                        onChange={handleAutocomplete}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/stay360-property?with_apartment=1`}
                    />
                </CardContent>
                <CardActions className={classes.cardActRoot}>
                    <Link
                        style={{ cursor: "pointer" }}
                        component={RouterLink}
                        to={`/stay360-cico/list/${isApartment}/${locationId}/${label}`}
                    >
                        <Button variant="contained" color="primary" className={classes.submitButton}>
                            Submit
                        </Button>
                    </Link>
                </CardActions>
            </Card>
        </Root>
    );
}

export default Stay360PmsList;
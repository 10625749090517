import React, { useReducer, useState, useEffect, useRef } from 'react';
import { Grid, TextField, Button, InputAdornment, Link, Typography, Switch } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

/**
 * Components
 */
import MuiDatePicker from '../../_components/MuiDatePicker';
import LoadingScreen from '../../_components/LoadingScreen';
import TextEditor from '../../_components/_form/TextEditor';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';

/**
 * Assets
 */
import pdfPng from '../../Assets/Images/png/pdf.png';
import docPng from '../../Assets/Images/png/doc.png';
import { numberToCurrency, generalErrorHandler, isValidEmail, currencyToNumber, dateFormat, renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    projectDeveloperId: string | null;
    mode: 'add' | 'edit';
}

interface IState {
    name: string;
    slug: string;
    type: string;
    province_id: string;
    is_featured: string;
    area_id: string;
    district_id: string;
    address: string;
    developer_name: string;
    launch_date: Date | null;
    certificate: string;
    pic_name: string;
    pic_email: string;
    pic_phone: string;
    pic_whatsapp: string;
    price_start_from: string;
    article_keypoint: string;
    article_text: string;
    promo: string;
    brochure_filepath: {
        name: string;
        file: File | null;
    },
    brochures: {
        id: string;
        caption: string;
        image: string;
    }[],
    banner_desktop_filepath: {
        name: string;
        file: File | null;
    },
    banner_desktops: {
        id: string;
        caption: string;
        image: string;
    }[],
    banner_mobile_filepath: {
        name: string;
        file: File | null;
    },
    banner_mobiles: {
        id: string;
        caption: string;
        image: string;
    }[],
    meta_title: string;
    meta_description: string;
}

interface IErrorState {
    name: string;
    slug: string;
    type: string;
    province_id: string;
    area_id: string;
    district_id: string;
    address: string;
    developer_name: string;
    // launch_date: string;
    certificate: string;
    pic_name: string;
    pic_email: string;
    pic_phone: string;
    pic_whatsapp: string;
    price_start_from: string;
    brochure_caption: string;
    banner_desktop_caption: string;
    banner_mobile_caption: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IListPlace {
    id: number,
    label: string
}

interface IRefs {
    [key: string]: React.RefObject<HTMLInputElement>
}

const useStyles = generateStyle(theme => ({
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    fileList: {
        listStyle: 'none',
        padding: 0,
        '& li': {
            display: 'flex'
        }
    },
    filePreview: {
        width: '65px',
        height: '65px',
        overflow: 'hidden',
        float: 'left',
        '& img': {
            width: '100%'
        }
    },
    fileDetail: {
        width: '100%',
        marginLeft: '10px',
        '& p': {
            margin: 0
        }
    }
}), "ProjectDeveloperDetailForm")

let cache_cities: Array<Array<IListPlace>> = []; //NOTE: cache_cities itu isinya array dari data yang udah di fetch, makanya interface Arraynya dua kali
let cache_districts: Array<Array<IListPlace>> = []; //NOTE: ini juga sama

const ProjectDeveloperDetailForm = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/project-developer';
    const params: any = useParams();
    const navigate = useNavigate();
    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(false);

    // Area State
    const [areaAutoCompleteLoading, setAACL] = useState(false);
    const [provinceList, setProvinceList] = useState<Array<IListPlace>>([]);
    const [cityList, setCityList] = useState<Array<IListPlace>>([]);
    const [districtList, setDistrictList] = useState<Array<IListPlace>>([]);

    const initialState = {
        name: '',
        slug: '',
        type: '',
        province_id: '',
        area_id: '',
        district_id: '',
        address: '',
        developer_name: '',
        launch_date: null,
        certificate: '',
        pic_name: '',
        pic_email: '',
        pic_phone: '',
        pic_whatsapp: '',
        price_start_from: '',
        article_keypoint: '',
        article_text: '',
        is_featured: '0',
        promo: '',
        brochure_filepath: {
            name: '',
            file: null
        },
        brochures: [],
        banner_desktop_filepath: {
            name: '',
            file: null
        },
        banner_desktops: [],
        banner_mobile_filepath: {
            name: '',
            file: null
        },
        banner_mobiles: [],
        meta_title: '',
        meta_description: ''
    };

    const initialErrorState = {
        name: '',
        slug: '',
        type: '',
        province_id: '',
        area_id: '',
        district_id: '',
        address: '',
        developer_name: '',
        // launch_date: '',
        certificate: '',
        pic_name: '',
        pic_email: '',
        pic_phone: '',
        pic_whatsapp: '',
        price_start_from: '',
        brochure_caption: '',
        banner_desktop_caption: '',
        banner_mobile_caption: ''
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    useEffect(() => {
        if (params.id) {
            loadData();
        } else {
            fetchOptions();
        }
        // eslint-disable-next-line
    }, []);

    const fetchOptions = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/project-developer/form-data`)
            .then(response => {
                const data = response.data;
                setIsLoading(false)
                setProvinceList(data.provinces);
            })
            .catch(error => {
                setIsLoading(false)
            })
    }

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${params.id}`)
            .then(res => {
                const data = res.data;
                const newInputState: any = { ...initialState };

                for (let key in data.projectDeveloper) {
                    if (data.projectDeveloper[key]) {
                        if (key === 'launch_date') {
                            newInputState.launch_date = new Date(data.projectDeveloper.launch_date);
                        } else if (key === 'price_start_from') {
                            newInputState.price_start_from = numberToCurrency(data.projectDeveloper.price_start_from);
                        } else {
                            newInputState[key] = data.projectDeveloper[key];
                        }
                    }
                }

                newInputState.brochures = data.brochures;
                newInputState.banner_desktops = data.bannerDesktops;
                newInputState.banner_mobiles = data.bannerMobiles;

                setInputState({ name: '', value: newInputState, type: 'REPLACE_STATE' });
                setProvinceList(data.provinces);
                setCityList(data.cities);
                setDistrictList(data.districts);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const refs: IRefs = {
        name: useRef<HTMLInputElement>(null),
        slug: useRef<HTMLInputElement>(null),
        type: useRef<HTMLInputElement>(null),
        province_id: useRef<HTMLInputElement>(null),
        area_id: useRef<HTMLInputElement>(null),
        district_id: useRef<HTMLInputElement>(null),
        address: useRef<HTMLInputElement>(null),
        developer_name: useRef<HTMLInputElement>(null),
        launch_date: useRef<HTMLInputElement>(null),
        certificate: useRef<HTMLInputElement>(null),
        pic_name: useRef<HTMLInputElement>(null),
        pic_email: useRef<HTMLInputElement>(null),
        pic_phone: useRef<HTMLInputElement>(null),
        pic_whatsapp: useRef<HTMLInputElement>(null),
        price_start_from: useRef<HTMLInputElement>(null),
        brochure_caption: useRef<HTMLInputElement>(null),
        banner_desktop_caption: useRef<HTMLInputElement>(null),
        banner_mobile_caption: useRef<HTMLInputElement>(null)
    }

    const scrollToRef = (ref: React.RefObject<HTMLInputElement>) => {
        window.scrollTo({ top: ref.current!.offsetTop - 64, behavior: 'smooth' })
    }

    /**
     * Resetting City Select
     * @param void
     */
    const resetCityList = () => {
        setInputState({ name: 'area_id', value: '', type: 'SET_ITEM' });
        setCityList([]);
    }

    /**
     * Resetting District Select
     * @param void
     */
    const resetDistrictList = () => {
        setInputState({ name: 'district', value: '', type: 'SET_ITEM' });
        setDistrictList([]);
    }

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let value: any = target.value;
        let name = target.name;
        if (name === 'file') {
            const oldBrochure = { ...inputState.brochure_filepath };
            if (target.files && target.files[0]) {
                oldBrochure.file = target.files[0];
            } else {
                oldBrochure.file = null;
            }
            value = oldBrochure;
            name = 'brochure_filepath';
        } else if (name === 'brochure_caption') {
            const oldBrochure = { ...inputState.brochure_filepath };
            oldBrochure.name = target.value;
            value = oldBrochure;
            name = 'brochure_filepath';
        } else if (name === 'file_banner_desktop') {
            const oldBanner = { ...inputState.banner_desktop_filepath };
            if (target.files && target.files[0]) {
                oldBanner.file = target.files[0];
            } else {
                oldBanner.file = null;
            }
            value = oldBanner;
            name = 'banner_desktop_filepath';
        } else if (name === 'banner_desktop_caption') {
            const oldBanner = { ...inputState.banner_desktop_filepath };
            oldBanner.name = target.value;
            value = oldBanner;
            name = 'banner_desktop_filepath';
        } else if (name === 'file_banner_mobile') {
            const oldBanner = { ...inputState.banner_mobile_filepath };
            if (target.files && target.files[0]) {
                oldBanner.file = target.files[0];
            } else {
                oldBanner.file = null;
            }
            value = oldBanner;
            name = 'banner_mobile_filepath';
        } else if (name === 'banner_mobile_caption') {
            const oldBanner = { ...inputState.banner_mobile_filepath };
            oldBanner.name = target.value;
            value = oldBanner;
            name = 'banner_mobile_filepath';
        } else if (target.name === 'price_start_from') {
            value = numberToCurrency(value);
        }
        setInputState({ name, value, type: 'SET_ITEM' });
    }

    // TODO: might break
    const handleDateChanged = (name: string, date: Date | null) => {
        setInputState({ name, value: date, type: 'SET_ITEM' });
    }

    const handleEditorChanged = (name: string, value: string) => {
        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleProvinceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            resetCityList();
        } else {
            let province_id: number = Number(e.target.value); //Explicitly set as number so Typescript will shut up

            if (province_id in cache_cities) {
                setCityList(cache_cities[province_id]);
            } else {
                resetCityList();
                setAACL(true);

                DefaultAxios.get(`${process.env.REACT_APP_API_URL}/admin/autocomplete/listing-form/area`, { params: { province_id: province_id } })
                    .then(res => {
                        let list_of_cities = res.data;
                        cache_cities[province_id] = list_of_cities;
                        setCityList(list_of_cities);
                        setAACL(false);
                    });
            }
        }

        setInputState({ name: 'province_id', value: e.target.value, type: 'SET_ITEM' });
    }

    /**
     * Handle City Change
     * @param e React.ChangeEvent<HTMLInputElement>
     */
    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            resetDistrictList();
        } else {
            let city_id: number = Number(e.target.value); // Explicitly set as number so Typescript will shut up

            if (city_id in cache_districts) {
                setDistrictList(cache_districts[city_id]);
            } else {
                resetDistrictList();
                setAACL(true);

                DefaultAxios.get(`${process.env.REACT_APP_API_URL}/admin/autocomplete/listing-form/district`, { params: { city_id: city_id } })
                    .then(res => {
                        let list_of_cities = res.data;
                        cache_districts[city_id] = list_of_cities;

                        setDistrictList(list_of_cities);
                        setAACL(false);
                    });
            }
        }

        setInputState({ name: 'area_id', value: e.target.value, type: 'SET_ITEM' });
    }

    /**
     * Handle District Change
     * @param e React.ChangeEvent<HTMLInputElement>
     */
    const handleDistrictChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputState({ name: 'district_id', value: e.target.value, type: 'SET_ITEM' });
    }

    const handleDeleteAsset = (brochureId: string, type: string) => {
        renderWarningButton("Apakah anda yakin ingin menghapus asset ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/${params.id}/delete-asset`, { brochureId, type })
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(res => {
                                    loadData();
                                })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const renderBrochures = () => {
        return inputState.brochures.map(brochure => {
            let src = '';
            const match = /(\.\w+$)/g.exec(brochure.image);

            if (match) {
                if (match[1] === '.pdf') {
                    src = pdfPng;
                } else if (match[1] === '.doc' || match[1] === '.docx') {
                    src = docPng;
                } else {
                    src = brochure.image;
                }
            }

            return (
                <li key={brochure.id}>
                    <div className={classes.filePreview}>
                        <img src={src} alt={brochure.caption} />
                    </div>
                    <div className={classes.fileDetail}>
                        <p>{brochure.caption}</p>
                        <Link
                            component="button"
                            color="secondary"
                            onClick={() => handleDeleteAsset(brochure.id, 'brochure')}
                        >
                            Delete
                        </Link>
                    </div>
                </li>
            );
        });
    }

    const renderBannerDesktop = () => {
        return inputState.banner_desktops.map(banner => {
            let src = '';
            const match = /(\.\w+$)/g.exec(banner.image);

            if (match) {
                if (match[1] === '.pdf') {
                    src = pdfPng;
                } else if (match[1] === '.doc' || match[1] === '.docx') {
                    src = docPng;
                } else {
                    src = banner.image;
                }
            }

            return (
                <li key={banner.id}>
                    <div className={classes.filePreview}>
                        <img src={src} alt={banner.caption} />
                    </div>
                    <div className={classes.fileDetail}>
                        <p>{banner.caption}</p>
                        <Link
                            component="button"
                            color="secondary"
                            onClick={() => handleDeleteAsset(banner.id, 'banner_desktop')}
                        >
                            Delete
                        </Link>
                    </div>
                </li>
            );
        });
    }

    const renderBannerMobile = () => {
        return inputState.banner_mobiles.map(banner => {
            let src = '';
            const match = /(\.\w+$)/g.exec(banner.image);

            if (match) {
                if (match[1] === '.pdf') {
                    src = pdfPng;
                } else if (match[1] === '.doc' || match[1] === '.docx') {
                    src = docPng;
                } else {
                    src = banner.image;
                }
            }

            return (
                <li key={banner.id}>
                    <div className={classes.filePreview}>
                        <img src={src} alt={banner.caption} />
                    </div>
                    <div className={classes.fileDetail}>
                        <p>{banner.caption}</p>
                        <Link
                            component="button"
                            color="secondary"
                            onClick={() => handleDeleteAsset(banner.id, 'banner_mobile')}
                        >
                            Delete
                        </Link>
                    </div>
                </li>
            );
        });
    }

    const checkValidation = () => {
        let isValid = true;
        let firstError = '';
        const newErrorState = { ...errorState };

        if (!inputState.name) {
            newErrorState.name = 'Nama harus diisi';
            isValid = false;
            if (!firstError) firstError = 'name';
        } else {
            newErrorState.name = '';
        }

        if (!inputState.province_id) {
            newErrorState.province_id = 'Provinsi harus diisi';
            isValid = false;
            if (!firstError) firstError = 'province_id';
        } else {
            newErrorState.province_id = '';
        }

        if (!inputState.area_id) {
            newErrorState.area_id = 'Kota harus diisi';
            isValid = false;
            if (!firstError) firstError = 'area_id';
        } else {
            newErrorState.area_id = '';
        }

        if (!inputState.district_id) {
            newErrorState.district_id = 'Area harus diisi';
            isValid = false;
            if (!firstError) firstError = 'district_id';
        } else {
            newErrorState.district_id = '';
        }

        if (!inputState.address) {
            newErrorState.address = 'Alamat harus diisi';
            isValid = false;
            if (!firstError) firstError = 'address';
        } else {
            newErrorState.address = '';
        }

        if (!inputState.developer_name) {
            newErrorState.developer_name = 'Nama Developer harus diisi';
            isValid = false;
            if (!firstError) firstError = 'developer_name';
        } else {
            newErrorState.developer_name = '';
        }

        // if (!inputState.launch_date) {
        //     newErrorState.launch_date = 'Tanggal Launch harus diisi';
        //     isValid = false;
        //     if (!firstError) firstError = 'launch_date';
        // } else {
        //     newErrorState.launch_date = '';
        // }

        if (!inputState.certificate) {
            newErrorState.certificate = 'Sertifikat harus diisi';
            isValid = false;
            if (!firstError) firstError = 'certificate';
        } else {
            newErrorState.certificate = '';
        }

        if (!inputState.pic_name) {
            newErrorState.pic_name = 'PIC Name harus diisi';
            isValid = false;
            if (!firstError) firstError = 'pic_name';
        } else {
            newErrorState.pic_name = '';
        }

        if (!inputState.pic_email) {
            newErrorState.pic_email = 'PIC Email harus diisi';
            isValid = false;
            if (!firstError) firstError = 'pic_email';
        } else if (!isValidEmail(inputState.pic_email)) {
            newErrorState.pic_email = 'Format email salah';
            isValid = false;
            if (!firstError) firstError = 'pic_email';
        } else {
            newErrorState.pic_email = '';
        }

        if (!inputState.pic_phone) {
            newErrorState.pic_phone = 'PIC Phone harus diisi';
            isValid = false;
            if (!firstError) firstError = 'pic_phone';
        } else {
            newErrorState.pic_phone = '';
        }

        if (!inputState.pic_whatsapp) {
            newErrorState.pic_whatsapp = 'PIC Whatsapp harus diisi';
            isValid = false;
            if (!firstError) firstError = 'pic_whatsapp';
        } else {
            newErrorState.pic_whatsapp = '';
        }

        if (!inputState.price_start_from) {
            newErrorState.price_start_from = 'Price Start From harus diisi';
            isValid = false;
            if (!firstError) firstError = 'price_start_from';
        } else {
            newErrorState.price_start_from = '';
        }

        if (inputState.brochure_filepath.file && !inputState.brochure_filepath.name) {
            newErrorState.brochure_caption = 'Brochure Caption harus diisi apabila ingin menambahkan brochure';
            isValid = false;
            if (!firstError) firstError = 'brochure_caption';
        } else {
            newErrorState.brochure_caption = '';
        }

        if (inputState.banner_desktop_filepath.file && !inputState.banner_desktop_filepath.name) {
            newErrorState.banner_desktop_caption = 'Banner Desktop Caption harus diisi apabila ingin menambahkan banner desktop';
            isValid = false;
            if (!firstError) firstError = 'banner_desktop_caption';
        } else {
            newErrorState.banner_desktop_caption = '';
        }

        if (inputState.banner_mobile_filepath.file && !inputState.banner_mobile_filepath.name) {
            newErrorState.banner_mobile_caption = 'Banner Mobile Caption harus diisi apabila ingin menambahkan banner mobile';
            isValid = false;
            if (!firstError) firstError = 'banner_mobile_caption';
        } else {
            newErrorState.banner_mobile_caption = '';
        }

        if (firstError) scrollToRef(refs[firstError]);
        setErrorState({ name: '', value: newErrorState, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { ...inputState };
        newState.is_featured = event.target.checked ? '1' : '0';

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        const fd = new FormData();

        for (let key in inputState) {
            if (key === 'brochure_filepath') {
                if (inputState.brochure_filepath.file) {
                    fd.append('brochure_filepath', inputState.brochure_filepath.file);
                    fd.append('brochure_filename', inputState.brochure_filepath.name);
                }
            } else if (key === 'banner_desktop_filepath') {
                if (inputState.banner_desktop_filepath.file) {
                    fd.append('banner_desktop_filepath', inputState.banner_desktop_filepath.file);
                    fd.append('banner_desktop_filename', inputState.banner_desktop_filepath.name);
                }
            } else if (key === 'banner_mobile_filepath') {
                if (inputState.banner_mobile_filepath.file) {
                    fd.append('banner_mobile_filepath', inputState.banner_mobile_filepath.file);
                    fd.append('banner_mobile_filename', inputState.banner_mobile_filepath.name);
                }
            } else if (key === 'price_start_from') {
                fd.append(key, String(currencyToNumber(inputState[key])));
            } else if (key === 'launch_date') {
                if (inputState.launch_date) {
                    fd.append(key, dateFormat(inputState.launch_date));
                }
            } else {
                fd.append(key, String(inputState[key as keyof IState]));
            }
        }

        let axios;

        if (params.id) {
            axios = DefaultAxios.post(`${API_URL}/${params.id}`, fd);
            fd.append('_method', 'PATCH');
        } else {
            axios = DefaultAxios.post(API_URL, fd);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        if (params.id) {
                            document.querySelectorAll('[type="file"]').forEach(el => {
                                (el as HTMLInputElement).value = '';
                            })
                            loadData();
                        } else {
                            navigate('/project-developer');
                        }
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Nama"
                        name="name"
                        value={inputState.name}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.name}
                        helperText={errorState.name}
                        ref={refs.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Slug"
                        name="slug"
                        value={inputState.slug}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.slug}
                        helperText={errorState.slug}
                        ref={refs.name}
                    />
                </Grid>
                <Grid item xs={12} className={classes.switchContainer}>
                    <Typography>
                        Is Featured
                    </Typography>
                    <Switch
                        color="primary"
                        value={true}
                        checked={inputState.is_featured === '0' ? false : true}
                        onChange={handleSwitch}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Type"
                        name="type"
                        value={inputState.type}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.type}
                        helperText={errorState.type}
                        ref={refs.type}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Provinsi"
                        name="province_id"
                        value={inputState.province_id}
                        onChange={handleProvinceChange}
                        disabled={areaAutoCompleteLoading}
                        error={!!errorState.province_id}
                        helperText={errorState.province_id}
                        variant="outlined"
                        select
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        ref={refs.province_id}
                    >
                        <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                        {provinceList.map(data => {
                            return (
                                <option value={data.id} key={data.id}>{data.label}</option>
                            )
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Kota"
                        name="area_id"
                        value={inputState.area_id}
                        onChange={handleCityChange}
                        disabled={areaAutoCompleteLoading ? true : !inputState.province_id}
                        error={!!errorState.area_id}
                        helperText={errorState.area_id}
                        variant="outlined"
                        select
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        ref={refs.area_id}
                    >
                        <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                        {cityList.map(data => {
                            return (
                                <option value={data.id} key={data.id}>{data.label}</option>
                            )
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Area"
                        name="district_id"
                        value={inputState.district_id}
                        onChange={handleDistrictChange}
                        disabled={areaAutoCompleteLoading ? true : !inputState.area_id}
                        error={!!errorState.district_id}
                        helperText={errorState.district_id}
                        variant="outlined"
                        select
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        ref={refs.district_id}
                    >
                        <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                        {districtList.map(data => {
                            return (
                                <option value={data.id} key={data.id}>{data.label}</option>
                            )
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Alamat"
                        name="address"
                        value={inputState.address}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.address}
                        helperText={errorState.address}
                        ref={refs.address}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Nama Developer"
                        name="developer_name"
                        value={inputState.developer_name}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.developer_name}
                        helperText={errorState.developer_name}
                        ref={refs.developer_name}
                    />
                </Grid>
                <Grid item xs={12} ref={refs.launch_date}>
                    <MuiDatePicker
                        clearable
                        label="Tanggal Launch"
                        value={inputState.launch_date}
                        onChange={(date: any) => handleDateChanged('launch_date', date)}
                    // error={!!errorState.launch_date}
                    // helperText={errorState.launch_date}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        label="Sertifikat"
                        name="certificate"
                        value={inputState.certificate}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.certificate}
                        helperText={errorState.certificate}
                        ref={refs.certificate}
                        SelectProps={{
                            native: true,
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    >
                        <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                        <option value="HGB">HGB</option>
                        <option value="SHM">SHM</option>
                        <option value="Strata Title">Strata Title</option>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="PIC Name"
                        name="pic_name"
                        value={inputState.pic_name}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.pic_name}
                        helperText={errorState.pic_name}
                        ref={refs.pic_name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="PIC Email"
                        name="pic_email"
                        value={inputState.pic_email}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.pic_email}
                        helperText={errorState.pic_email}
                        ref={refs.pic_email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="number"
                        label="PIC Phone"
                        name="pic_phone"
                        value={inputState.pic_phone}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.pic_phone}
                        helperText={errorState.pic_phone}
                        ref={refs.pic_phone}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="number"
                        label="PIC Whatsapp"
                        name="pic_whatsapp"
                        value={inputState.pic_whatsapp}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.pic_whatsapp}
                        helperText={errorState.pic_whatsapp}
                        ref={refs.pic_whatsapp}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Price Start From"
                        name="price_start_from"
                        value={inputState.price_start_from}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.price_start_from}
                        helperText={errorState.price_start_from}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                        }}
                        ref={refs.price_start_from}
                    />
                </Grid>
                <Grid item xs={12}>
                    <h3>Article Keypoint</h3>
                    <TextEditor
                        name="article_keypoint"
                        value={inputState.article_keypoint}
                        onChange={handleEditorChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <h3>Article Text</h3>
                    <TextEditor
                        name="article_text"
                        value={inputState.article_text}
                        onChange={handleEditorChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <h3>Promotion</h3>
                    <TextEditor
                        name="promo"
                        value={inputState.promo}
                        onChange={handleEditorChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <h3>Upload Brochure</h3>
                    {
                        inputState.brochures.length
                            ? <ul className={classes.fileList}>
                                {renderBrochures()}
                            </ul>
                            : null
                    }
                    <input type="file" name="file" onChange={handleChanged} style={{ marginBottom: '10px' }} />
                    <TextField
                        label="Brochure Caption"
                        name="brochure_caption"
                        value={inputState.brochure_filepath.name}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.brochure_caption}
                        helperText={errorState.brochure_caption}
                        ref={refs.brochure_caption}
                    />
                </Grid>
                <Grid item xs={12}>
                    <h3>Upload Banner Desktop</h3>
                    {
                        inputState.banner_desktops.length
                            ? <ul className={classes.fileList}>
                                {renderBannerDesktop()}
                            </ul>
                            : null
                    }
                    <input type="file" name="file_banner_desktop" onChange={handleChanged} style={{ marginBottom: '10px' }} />
                    <TextField
                        label="Banner Desktop Caption"
                        name="banner_desktop_caption"
                        value={inputState.banner_desktop_filepath.name}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.banner_desktop_caption}
                        helperText={errorState.banner_desktop_caption}
                        ref={refs.banner_desktop_caption}
                    />
                </Grid>
                <Grid item xs={12}>
                    <h3>Upload Banner Mobile</h3>
                    {
                        inputState.banner_mobiles.length
                            ? <ul className={classes.fileList}>
                                {renderBannerMobile()}
                            </ul>
                            : null
                    }
                    <input type="file" name="file_banner_mobile" onChange={handleChanged} style={{ marginBottom: '10px' }} />
                    <TextField
                        label="Banner Mobile Caption"
                        name="banner_mobile_caption"
                        value={inputState.banner_mobile_filepath.name}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.banner_mobile_caption}
                        helperText={errorState.banner_mobile_caption}
                        ref={refs.banner_mobile_caption}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Meta Title"
                        name="meta_title"
                        value={inputState.meta_title}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Meta Description"
                        name="meta_description"
                        value={inputState.meta_description}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Root>
    );
}

export default ProjectDeveloperDetailForm;
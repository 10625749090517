import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, Tab, Tabs, TextField, Theme, Typography } from '@mui/material'
import Chip, { ChipColor } from '../../_components/Chip'
import LoadingScreen from '../../_components/LoadingScreen'
import CommentList, { IComment } from '../../_components/CommentList';
import CompactFileList, { IFile } from '../_closing/_components/CompactFileList'
import { VerifyTransactionState } from './VerifyTransactionList'

/**
 * Utils
 */
import moment from 'moment'
import Swal from 'sweetalert2'
import DefaultAxios from '../../_utils/DefaultAxios'
import { capitalizeFirstCharacter, generalErrorHandler, numberToCurrency, renderWarningButton } from '../../_utils/Helper'
import { getTransactionType } from '../../_utils/ConstData';
import { generateStyle } from '../../_utils/DefaultStyle';

interface TransactionDetailProps {
    permissions: any
    isOpen: boolean
    onClose: () => void
    id: number | null
    reDrawTable?: (draw: number) => void
}

const TransactionDetail = (props: TransactionDetailProps) => {
    const { Root, classes } = useStyles()

    const [isLoading, setIsLoading] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL + '/closing-transaction';
    const [state, setState] = useState<VerifyTransactionState>(initialState)

    const [isCancelReasonModalOpen, setIsCancelReasonModalOpen] = useState(false)
    const [canceledReason, setCanceledReason] = useState('')
    const [canceledReasonError, setCanceledReasonError] = useState('')

    const [comments, setComments] = useState<IComment[]>([])
    const [files, setFiles] = useState<IFile[]>([])

    useEffect(() => {
        if (!props.id) {
            setState(initialState)
            setComments([])
            setFiles([])
        } else {
            fetchData()
        }
        // eslint-disable-next-line
    }, [props.id]);

    useEffect(() => {
        if (!isCancelReasonModalOpen) {
            setCanceledReason('')
            setCanceledReasonError('')
        }
        // eslint-disable-next-line
    }, [isCancelReasonModalOpen])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${API_URL}/${props.id}`)
            .then(res => res.data)
            .then(data => {
                setState(data)
                setComments(data.comments)
                setFiles(data.files)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleSubmit = (action: 'approve' | 'process' | 'release') => {
        if (!state.status || !props.id) return

        setIsLoading(true)

        DefaultAxios.post(`${API_URL}/${action}`, {
            id: props.id,
        })
            .then(res => res.data)
            .then(data => {
                Swal.fire({
                    title: "Proses Berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        if (props.reDrawTable) props.reDrawTable(new Date().getTime());
                        props.onClose();
                        // fetchData()
                    },
                    timer: 1000
                })
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleSubmitCancel = () => {
        if (!state.status || !props.id) return
        if (!canceledReason) {
            setCanceledReasonError('Please fill the canceled reason')
            return
        }

        setIsLoading(true)

        DefaultAxios.post(`${API_URL}/cancel`, {
            id: props.id,
            canceled_reason: canceledReason || null,
        })
            .then(res => res.data)
            .then(data => {
                Swal.fire({
                    title: "Proses Berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        setIsCancelReasonModalOpen(false)
                        setCanceledReason('')
                        props.onClose()
                        if (props.reDrawTable) props.reDrawTable(new Date().getTime());
                    },
                    timer: 1000
                })
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleRevert = () => {
        if (state.status !== 'exported' || !props.id) return

        renderWarningButton('Apakah anda yakin ingin me-revert transaksi ini?')
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)

                    DefaultAxios.post(`${API_URL}/revert`, {
                        id: props.id,
                    })
                        .then(res => res.data)
                        .then(data => {
                            Swal.fire({
                                title: "Proses Berhasil",
                                icon: 'success',
                                onAfterClose: () => {
                                    props.onClose()
                                    if (props.reDrawTable) props.reDrawTable(new Date().getTime());
                                },
                                timer: 1000
                            })
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            });
    }

    const [activeTab, setActiveTab] = useState(0)

    const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    }

    const renderStatus = useMemo(() => {
        if (!state.status) return '-'
        let color: ChipColor = undefined

        switch (state.status) {
            case 'proposed':
                color = 'default'
                break
            case 'approved':
                color = 'warning'
                break
            case 'processed':
                color = 'primary'
                break
            case 'released':
                color = 'success'
                break
            case 'canceled':
                color = 'secondary'
                break
        }

        return (
            <Chip
                label={capitalizeFirstCharacter(state.status)}
                color={color}
            />
        )
        // eslint-disable-next-line
    }, [state?.status])

    const parsedTransactionType = useMemo(() => {
        if (!state.transaction_type) return '-'

        switch (state.transaction_type) {
            case 'other':
                return state.other_transaction_type_label || '-'
            case 'other_deposit':
                return 'Lainnya'
            default:
                return getTransactionType(state.transaction_type) || state.transaction_type_label
        }
        // eslint-disable-next-line
    }, [state.transaction_type, state.transaction_type_label])

    // const renderByAndTime = (name: null | string, date: null | string) => {
    //     if (!name) return '-'

    //     return `${name || '-'} at ${date ? moment(date).format("DD MMMM YYYY HH:mm:ss") : '-'}`
    // }

    const renderSubmitButton = (status: string) => {
        switch (status) {
            case 'proposed':
                if (!props.permissions['closing-transaction.approve']) return null
                return (
                    <Button
                        color="primary"
                        onClick={() => handleSubmit('approve')}
                        variant='contained'
                    >
                        Approve
                    </Button>
                )
            case 'approved':
                if (!props.permissions['closing-transaction.process']) return null
                return (
                    <Button
                        color="primary"
                        onClick={() => handleSubmit('process')}
                        variant='contained'
                    >
                        Process
                    </Button>
                )
            // case 'processed':
            //     if (!props.permissions['closing-transaction.release']) return null
            //     return (
            //         <Button
            //             color="primary"
            //             onClick={() => handleSubmit('release')}
            //             variant='contained'
            //         >
            //             Release
            //         </Button>
            //     )
            default:
                return null
        }
    }

    const renderRevertButton = (status: string) => {
        const button = (
            <Button
                color="secondary"
                onClick={handleRevert}
                variant='contained'
            >
                Revert
            </Button>
        )

        switch (status) {
            case 'exported':
                if (!props.permissions['closing-transaction.revert-processed']) return null
                return button
            default:
                return null;
        }
    }

    const footer = useMemo(() => {
        switch (activeTab) {
            case 0:
                return (
                    <CommentList
                        comments={comments}
                        users={[]}
                    // onReply={handleReply}
                    />
                )
            case 1:
                return (
                    <CompactFileList
                        closing_id={state.closing_id}
                        permissions={props.permissions}
                        files={files}
                        isSmall
                        transactionType={parsedTransactionType}
                    // onUploadFile={handleUploadFile}
                    // onDeleteFile={() => loadFooter()}
                    />
                )
            default:
                return null;
        }
    }, [activeTab, state.closing_id, props.permissions, comments, files, parsedTransactionType])

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                open={isCancelReasonModalOpen}
                onClose={() => setIsCancelReasonModalOpen(false)}
                fullWidth
                maxWidth="sm"
            >
                <Root>
                    <DialogTitle>Form Cancel</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Isi Alasan"
                                    name="reason"
                                    value={canceledReason}
                                    multiline
                                    onChange={e => {
                                        const value = e.target.value
                                        setCanceledReason(value)
                                        setCanceledReasonError('')
                                    }}
                                    error={!!canceledReasonError}
                                    helperText={canceledReasonError}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={handleSubmitCancel}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Root>
            </Dialog>
            <Dialog
                open={props.isOpen}
                onClose={props.onClose}
                fullWidth
                maxWidth="lg"
            >
                <Root>
                    <DialogTitle>
                        <div className={classes.header}>
                            <span>
                                {state?.code}
                            </span>
                            <div className={classes.headerButtons}>
                                {renderSubmitButton(state.status)}
                                <Button
                                    color="secondary"
                                    onClick={() => setIsCancelReasonModalOpen(true)}
                                    variant='contained'
                                >
                                    Cancel
                                </Button>
                                {renderRevertButton(state.status)}
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={8}>
                                        <Grid container>
                                            <Grid item xs={12} md={4}>
                                                <Grid container spacing={2} direction='column'>
                                                    <Grid item xs={12}>
                                                        <div className={classes.itemContainer}>
                                                            <span className={classes.label}>
                                                                Status
                                                            </span>
                                                            <span className={classes.value}>
                                                                {renderStatus}
                                                            </span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className={classes.itemContainer}>
                                                            <span className={classes.label}>
                                                                Tipe
                                                            </span>
                                                            <span className={classes.value}>
                                                                Debit
                                                            </span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className={classes.itemContainer}>
                                                            <span className={classes.label}>
                                                                Tanggal Pengajuan
                                                            </span>
                                                            <span className={classes.value}>
                                                                {moment(state.date).format("DD MMMM YYYY")}
                                                            </span>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Grid container spacing={2} direction='column'>
                                                    <Grid item xs={12}>
                                                        <div className={classes.itemContainer}>
                                                            <span className={classes.label}>
                                                                Kode Closing
                                                            </span>
                                                            {
                                                                !!state.closing.code ?
                                                                    <a href={`/closing/${state.closing.code}`} target="_blank" rel='noopener noreferrer' className={classes.value}>
                                                                        {state.closing.code || '-'}
                                                                    </a>
                                                                    :
                                                                    <span className={classes.value}>
                                                                        -
                                                                    </span>
                                                            }
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className={classes.itemContainer}>
                                                            <span className={classes.label}>
                                                                Kode Unit
                                                            </span>
                                                            {
                                                                !!state.unit_code ?
                                                                    <a href={`/unit/${state.unit_code}`} target="_blank" rel='noopener noreferrer' className={classes.value}>
                                                                        {state.unit_code || '-'}
                                                                    </a>
                                                                    :
                                                                    <span className={classes.value}>
                                                                        -
                                                                    </span>
                                                            }
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className={classes.itemContainer}>
                                                            <span className={classes.label}>
                                                                Jenis Transaksi
                                                            </span>
                                                            <span className={classes.value}>
                                                                {parsedTransactionType}
                                                            </span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className={classes.itemContainer}>
                                                            <span className={classes.label}>
                                                                Bank Tujuan
                                                            </span>
                                                            <span className={classes.value}>
                                                                {state.bank_information || '-'}
                                                            </span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className={classes.itemContainer}>
                                                            <span className={classes.label}>
                                                                Nominal
                                                            </span>
                                                            <span className={classes.value}>
                                                                {numberToCurrency(state.amount || 0, 'Rp')}
                                                            </span>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Grid container spacing={2} direction='column'>
                                                    <Grid item xs={12}>
                                                        <div className={classes.itemContainer}>
                                                            <span className={classes.label}>
                                                                Deskripsi
                                                            </span>
                                                            <span className={classes.value}>
                                                                {state.notes || '-'}
                                                            </span>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* <Grid item xs={12} md={4}>
                                            <Grid container spacing={2} direction='column'>
                                                <Grid item xs={12}>
                                                    <div className={classes.itemContainer}>
                                                        <span className={classes.label}>
                                                            Proposed By
                                                        </span>
                                                        <span className={classes.value}>
                                                            {renderByAndTime(state.proposed_by, state.proposed_at)}
                                                        </span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className={classes.itemContainer}>
                                                        <span className={classes.label}>
                                                            Approved By
                                                        </span>
                                                        <span className={classes.value}>
                                                            {renderByAndTime(state.approved_by, state.approved_at)}
                                                        </span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className={classes.itemContainer}>
                                                        <span className={classes.label}>
                                                            Processed By
                                                        </span>
                                                        <span className={classes.value}>
                                                            {renderByAndTime(state.processed_by, state.processed_at)}
                                                        </span>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid> */}
                                            <Grid item xs={12}>
                                                <Divider style={{ marginTop: 12, marginBottom: 12 }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <span>
                                                            <Typography variant='h6'>
                                                                Informasi Transaksi
                                                            </Typography>
                                                        </span>
                                                        <br />
                                                    </Grid>
                                                    <Grid item xs={12} md={3} className={classes.dialogContent} style={{ color: "gray" }}>
                                                        <span className={classes.label}>
                                                            Nama Owner
                                                        </span>
                                                        <span className={classes.value}>
                                                            {state.owner_name_label}
                                                        </span>
                                                        <span className={classes.label}>
                                                            Deposit Owner
                                                        </span>
                                                        <span className={classes.value}>
                                                            {state.closing.holded_deposit_owner ? numberToCurrency(state.closing.holded_deposit_owner, 'Rp') : '-'}
                                                        </span>
                                                        <br />
                                                        <span className={classes.label}>
                                                            Deposit Jendela
                                                        </span>
                                                        <span className={classes.value}>
                                                            {state.closing.holded_deposit_office ? numberToCurrency(state.closing.holded_deposit_office, 'Rp') : '-'}
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={12} md={3} className={classes.dialogContent} style={{ color: "gray" }}>
                                                        <span className={classes.label}>
                                                            GMV Transaction
                                                        </span>
                                                        <span className={classes.value}>
                                                            {state.closing.gmv_transaction ? numberToCurrency(state.closing.gmv_transaction, 'Rp') : '-'}
                                                        </span>
                                                        <br />
                                                        <span className={classes.label}>
                                                            GMV Financing
                                                        </span>
                                                        <span className={classes.value}>
                                                            {state.closing.gmv_financing ? numberToCurrency(state.closing.gmv_financing, 'Rp') : '-'}
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={12} md={3} className={classes.dialogContent} style={{ color: "gray" }}>
                                                        <span className={classes.label}>
                                                            Basic Price
                                                        </span>
                                                        <span className={classes.value}>
                                                            {state.closing.basic_price ? numberToCurrency(state.closing.basic_price, 'Rp') : '-'}
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={12} md={3} className={classes.dialogContent} style={{ color: "gray" }}>
                                                        <span className={classes.label}>
                                                            Komisi
                                                        </span>
                                                        <span className={classes.value}>
                                                            {state.closing.revenue_jendela ? numberToCurrency(state.closing.revenue_jendela, 'Rp') : '-'}
                                                        </span>
                                                        <br />
                                                        <span className={classes.label}>
                                                            Komisi Referal
                                                        </span>
                                                        <span className={classes.value}>
                                                            {state.closing.referral_commission ? numberToCurrency(state.closing.referral_commission, 'Rp') : '-'}
                                                        </span>
                                                        <br />
                                                        <span className={classes.label}>
                                                            Komisi Cobroke
                                                        </span>
                                                        <span className={classes.value}>
                                                            {state.closing.cobroke_fee ? numberToCurrency(state.closing.cobroke_fee, 'Rp') : '-'}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Paper className={classes.paper}>
                                            <Tabs
                                                value={activeTab}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                onChange={handleTab}
                                                variant="fullWidth"
                                                scrollButtons="auto"
                                            >
                                                <Tab label="Comment" value={0} />
                                                <Tab label="Files" value={1} />
                                            </Tabs>
                                            <div className={classes.footer}>
                                                {footer}
                                            </div>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Root>
            </Dialog>
        </Root>
    )
}

export default TransactionDetail

const useStyles = generateStyle((theme: Theme) =>
({
    code: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        transition: '150ms color linear',

        '&:hover': {
            color: theme.palette.primary.dark,
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerButtons: {
        '& > button:last-child': {
            marginLeft: 12,
        }
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 8,
        fontSize: 16,
    },
    value: {
        fontSize: 16,
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 16,
    },
    paper: {
        padding: theme.spacing(2),
        overflow: 'hidden',
    },
    footer: {
        display: 'flex',
        // minHeight: '250px',
        height: '400px',
        // backgroundColor: '#eee',
        padding: '10px',
        overflow: 'auto',
    },
}), "TransactionDetail"
);

const initialState: VerifyTransactionState = {
    owner_name_label: "",
    amount: 0,
    approved_at: null,
    approved_by: null,
    bank_data_id: 0,
    bank_data_type: "",
    bank_information: "",
    canceled_at: null,
    canceled_by: null,
    canceled_reason: null,
    closing_id: '',
    code: "",
    created_at: "",
    created_by: '',
    date: "",
    deleted_at: null,
    id: 0,
    notes: null,
    other_transaction_type_label: null,
    processed_at: null,
    processed_by: null,
    proposed_at: null,
    proposed_by: null,
    released_at: null,
    released_by: null,
    remark1: null,
    remark2: null,
    statement_id: null,
    status: "",
    transaction_type: "",
    transaction_type_label: '',
    type: "DB",
    updated_at: "",
    closing: {
        basic_price: null,
        cobroke_fee: null,
        gmv_transaction: null,
        holded_deposit_office: null,
        holded_deposit_owner: null,
        referral_commission: null,
        revenue_jendela: null,
        gmv_financing: null,
        code: '',
    },
    unit_code: ''
}
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../../_components/LoadingScreen';
import TextArea from '../../../_components/_form/TextArea';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../_utils/Helper';

interface Props {
    closing: any;
    open: boolean;
    onClose?: any;
}

const ResetStatusModal = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState('');

    const handleSubmit = () => {
        if (!reason) {
            return;
        }

        setIsLoading(true);
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/${props.closing.id}/reset-extend-status`, { reason })
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    timer: 1000,
                    onAfterClose: () => {
                        props.onClose(true);
                    }
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog 
                fullWidth
                maxWidth="sm"
                open={props.open} 
                onClose={() => props.onClose(false)} 
                aria-labelledby="form-dialog-title" 
                closeAfterTransition
            >
                <DialogTitle id="form-dialog-title">Reset Status</DialogTitle>
                <DialogContent>
                    <Grid container justifyContent="space-between" spacing={2}>
                        <Grid item xs={12}>
                            <TextArea 
                                label="*Alasan"
                                name="reason"
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ResetStatusModal;
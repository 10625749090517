import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { Typography, DialogContent, Dialog, AppBar, Toolbar, IconButton, Slide, Grid, TextField, Button, Theme } from '@mui/material'
import LoadingScreen from '../../../_components/LoadingScreen'
import PhotoPreview from '../../../_components/PhotoPreview';
import ButtonUpload from '../../../_components/ButtonUpload';
import Swal from 'sweetalert2'

/**
 * Utils
 */
import { TransitionProps } from '@mui/material/transitions';
import { IInventoryItem } from './InventoryRoomModal';
import { IValidationAlias, IValidationErrors, IValidationRules, validateOne, validateData } from '../../../_utils/Validation';
import { generalErrorHandler, getBase64, inputNumber, resizeImage, rotateImageFile } from '../../../_utils/Helper';
import DefaultAxios from '../../../_utils/DefaultAxios';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';
import { generateStyle } from '../../../_utils/DefaultStyle';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    activity: string
    isOpen: boolean
    roomId: number
    roomName: string
    activityId: string
    itemId: number | null
    onClose: () => void
    isMobile: boolean
    isExtraRooms?: boolean
}

const InventoryItemFormModal = (props: IProps) => {
    const { Root, classes } = useStyles()

    const [state, setState] = useState<IInventoryItem>(initialState)
    const [errors, setErrors] = useState<IValidationErrors>({})

    const [isLoading, setIsLoading] = useState(true)

    const rules: IValidationRules = useMemo(() => ({
        name: 'required',
        type: props.activity === '2' ? '' : 'required',
        quantity: props.activity === '2' ? '' : 'required',
        notes: props.activity === '2' ? 'required' : '',
        photos: 'required',
    }), [props.activity])

    useEffect(() => {
        if (props.itemId && props.isOpen) {
            fetchData()
        } else if (props.roomName && props.isOpen && props.isExtraRooms) {
            setState(prev => ({
                ...prev,
                name: props.roomName,
            }))

            setIsLoading(false)
        } else {
            setIsLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.activityId, props.roomId, props.itemId, props.isOpen, props.roomName, props.isExtraRooms])

    useEffect(() => {
        if (!props.isOpen) {
            setState(initialState)
            setErrors({})
        }
    }, [props.isOpen])

    const fetchData = () => {
        if (props.itemId === -1) {
            setState(prev => ({
                ...prev,
                name: props.roomName,
            }))
        } else {
            setIsLoading(true)

            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/activity/${props.activityId}/listing-inventory/${props.roomId}/item/${props.itemId}`)
                .then(res => res.data)
                .then(data => {
                    setState(prev => ({
                        ...prev,
                        name: data.name,
                        type: data.type || '',
                        quantity: data.quantity || 0,
                        notes: data.notes || '',
                        photos: data.photos || [],
                    }))
                    setIsLoading(false)
                })
                .catch(err => {
                    generalErrorHandler(err)
                    setIsLoading(false)
                })
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = e.target
        const isNumber = ['quantity'].includes(name)

        if (files && files.length) {
            setErrors(prev => ({
                ...prev,
                photos: '',
            }))

            Array.from(files).forEach(file => {
                resizeImage(file)
                    .then((file: File) => {
                        getBase64(file)
                            .then(base64 => {
                                setState(prev => ({
                                    ...prev,
                                    photos: [...prev.photos, {
                                        id: Math.random() * -1,
                                        item_id: prev.id,
                                        photo_thumbnail_url: base64 as string,
                                        photo_url: base64 as string,
                                        file,
                                    }]
                                }))
                            })
                    })
                    .catch(err => {
                        Swal.fire({
                            title: 'Error!',
                            text: `Gagal upload file`,
                            icon: 'error',
                            confirmButtonText: 'Close'
                        })
                    })
            })
        } else {
            setState(prev => ({
                ...prev,
                [name]: isNumber ? Number(inputNumber(value)) : value,
            }))

            setErrors(prev => ({
                ...prev,
                [name]: '',
            }))
        }
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        const { isValid, errors } = validateOne(name, value, rules, alias)

        if (isValid) {
            setErrors(prev => ({
                ...prev,
                [name]: '',
            }))
        } else {
            setErrors(prev => ({
                ...prev,
                ...errors,
            }))
        }
    }

    const onRemoveFile = (idx: number) => {
        setState(prev => ({
            ...prev,
            photos: prev.photos.filter((photo, index) => index !== idx)
        }))
    }

    const submit = () => {
        const { isValid, errors } = validateData(state, rules, alias)
        setErrors(errors)

        const sendItem = (roomId: number, onSuccess: () => void) => {
            const fd = new FormData()

            fd.append('name', state.name)
            fd.append('notes', state.notes)

            if (props.activity !== '2') {
                fd.append('type', state.type)
                fd.append('quantity', state.quantity.toString())
            }

            if (state.photos.length) {
                state.photos.forEach(photo => {
                    if (photo.id < 0 && photo.file) { //new photo
                        fd.append('photos[]', photo.file)
                    } else if (photo.id > 0) { //existing photo
                        fd.append('photo_ids[]', photo.id.toString())
                    }
                })
            }

            if (props.itemId && props.itemId !== -1) {
                fd.append('_method', 'PATCH')
            }

            DefaultAxios.request({
                url: (props.itemId && props.itemId !== -1) ?
                    `${process.env.REACT_APP_API_URL}/activity/${props.activityId}/listing-inventory/${roomId}/item/${props.itemId}`
                    :
                    `${process.env.REACT_APP_API_URL}/activity/${props.activityId}/listing-inventory/${roomId}/item`,
                method: 'POST',
                data: fd,
            })
                .then(res => res.data)
                .then(data => {
                    onSuccess()
                })
                .catch(err => {
                    generalErrorHandler(err)
                    setIsLoading(false)
                })
        }

        if (isValid) {
            if (props.itemId === -1) {
                setIsLoading(true)

                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/${props.activityId}/listing-inventory`, { name: props.roomName })
                    .then(res => res.data)
                    .then(data => {
                        const roomApiId = data.room_id

                        sendItem(data.room_id, () => {
                            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/${props.activityId}/listing-inventory/${roomApiId}/finish`)
                                .then(() => {
                                    Swal.fire({
                                        title: 'Sukses',
                                        text: `Sukses ${(props.itemId && props.itemId !== -1) ? 'Mengubah' : 'Menambahkan'} Data`,
                                        icon: 'success',
                                        confirmButtonText: 'Close'
                                    })
                                        .then(() => {
                                            props.onClose()
                                        })
                                    setIsLoading(false)
                                })
                                .catch(err => {
                                    generalErrorHandler(err)
                                    setIsLoading(false)
                                })
                        })
                    })
                    .catch(err => {
                        generalErrorHandler(err)
                        setIsLoading(false)
                    })
            } else {
                setIsLoading(true)

                sendItem(props.roomId, () => {
                    Swal.fire({
                        title: 'Sukses',
                        text: `Sukses ${props.itemId ? 'Mengubah' : 'Menambahkan'} Data`,
                        icon: 'success',
                        confirmButtonText: 'Close'
                    })
                        .then(() => {
                            props.onClose()
                        })
                    setIsLoading(false)
                })
            }
        }
    }

    const onRotate = (idx: number, clockwise: boolean) => {
        const currentFileArray = state.photos.filter((photo, index) => index === idx)
        if (currentFileArray.length) {
            const currentFile = currentFileArray[0]

            if (currentFile.file) {
                rotateImageFile(currentFile.file, clockwise, newFile => {
                    getBase64(newFile)
                        .then(base64 => {
                            setState(prev => ({
                                ...prev,
                                photos: prev.photos.map((photo, index) => {
                                    if (index === idx) {
                                        return {
                                            ...photo,
                                            id: Math.random() * -1,
                                            file: newFile,
                                            photo_url: base64 as string,
                                            photo_thumbnail_url: base64 as string,
                                        }
                                    }

                                    return photo
                                })
                            }))
                        })
                        .catch(err => {
                            generalErrorHandler(err)
                        })
                }, err => {
                    generalErrorHandler(err)
                })
            }
        }
    }

    return (
        <Dialog
            fullScreen={props.isMobile}
            TransitionComponent={Transition}
            open={props.isOpen}
            onClose={props.onClose}
        >
            <Root>
                <LoadingScreen open={isLoading} fullScreen />
                {
                    props.isMobile &&
                    <AppBar className={classes.appBar} color={'primary'}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={props.onClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {(props.itemId && props.itemId !== -1) ? 'Edit Item' : 'Add Item'}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                }
                <DialogContent>
                    {
                        !props.isMobile &&
                        <span
                            style={{
                                fontWeight: '600',
                                fontSize: 18,
                            }}
                        >
                            {(props.itemId && props.itemId !== -1) ? 'Edit Item' : 'Add Item'}
                        </span>
                    }
                    <div className={classes.body}>
                        <Grid container justifyContent="space-between" spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Nama"
                                    name="name"
                                    type="text"
                                    value={state.name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    variant="outlined"
                                    fullWidth
                                    style={{ marginBottom: '10px' }}
                                    error={!!errors.name}
                                    helperText={errors.name || undefined}
                                    disabled={props.itemId === -1 || props.isExtraRooms}
                                />
                            </Grid>
                            {
                                props.activity !== '2' &&
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Merk / Warna"
                                            name="type"
                                            type="text"
                                            value={state.type}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            variant="outlined"
                                            fullWidth
                                            style={{ marginBottom: '10px' }}
                                            error={!!errors.type}
                                            helperText={errors.type || undefined}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Quantity"
                                            name="quantity"
                                            type="text"
                                            value={inputNumber(state.quantity.toString())}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            variant="outlined"
                                            fullWidth
                                            style={{ marginBottom: '10px' }}
                                            error={!!errors.quantity}
                                            helperText={errors.quantity || undefined}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <TextField
                                    label="Notes"
                                    name="notes"
                                    type="text"
                                    value={state.notes}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    variant="outlined"
                                    fullWidth
                                    style={{ marginBottom: '10px' }}
                                    error={!!errors.notes}
                                    helperText={errors.notes || undefined}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    state.photos.map((photo, index) =>
                                        <PhotoPreview
                                            key={photo.id}
                                            index={index}
                                            onRemoveFile={onRemoveFile}
                                            onRotate={photo.file ? onRotate : undefined}
                                            src={photo.photo_thumbnail_url}
                                        />
                                    )
                                }
                                <ButtonUpload
                                    multiple
                                    onChange={e => onChange(e as React.ChangeEvent<HTMLInputElement>)}
                                />
                            </Grid>
                            {
                                errors.photos &&
                                <Grid item xs={12} style={{ color: '#d32f2f' }}>
                                    Harap mengisikan foto
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <Button
                                        onClick={submit}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </Root>
        </Dialog>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    body: {
        padding: 16,
        [theme.breakpoints.up('md')]: {
            maxWidth: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    roomCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        boxShadow: '3px 5px 6px 0px rgba(0,0,0,0.1)',
        borderRadius: 6,
        padding: '4px 8px',
        cursor: 'pointer',

        '& > .title': {
            fontWeight: 500,
        },
    },
    empty: {
        fontSize: 18,
        opacity: 0.85,
        textAlign: 'center',
    },
}), "InventoryItemForm")

const alias: IValidationAlias = {
    name: 'Nama Barang',
    type: 'Merk Barang',
    quantity: 'Quantity',
}

const initialState = {
    id: 0,
    name: '',
    type: '',
    room_id: 0,
    notes: '',
    quantity: 0,
    photos: [],
    is_done: 0,
}

export default InventoryItemFormModal

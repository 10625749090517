import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { Box, Button, CircularProgress, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Card, CardContent } from '@mui/material'
import LoadingScreen from '../../../../_components/LoadingScreen'
import ImageIcon from '@mui/icons-material/Image';
import ImagePreview from '../../../../_components/ImagePreview'
import DepositFormModal from './DepositFormModal'
import DepositTargetModal from './DepositTargetModal'
import ModalDetail from '../../../../_components/_modalDetail/ModalDetail';

/**
 * Utils
 */
import { convertNumber, generalErrorHandler, openExternalLink, renderErrorButton, renderInfoButton, renderSuccessButton, renderToastSuccess, renderWarningButton } from '../../../../_utils/Helper'
import { format } from 'date-fns'
import DefaultAxios from '../../../../_utils/DefaultAxios';
import { generateStyle } from '../../../../_utils/DefaultStyle';

/**
 * Icons
 */
import { Delete, Description, Edit, UploadFile } from '@mui/icons-material';
import SimpleDataTable from '../../../../_components/_dataTable/SimpleDataTable';
import { id } from 'date-fns/locale';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

interface DepositListModalProps {
    isOpen: boolean
    closingCode: string
    closingId: string
    onClose: () => void
    setDepositTransactionRefreshFlag: React.Dispatch<React.SetStateAction<number>>
}

export type DepositItem = {
    id: number
    type: 'fixing_cleaning' | 'utilities' | 'maintenance_fee' | 'maintenance_fee_and_utilities' | 'internet' | 'cutoff' | 'fine' | 'electric_token' | 'other'
    other_description: string
    start_period: string
    end_period: string
    amount: number
    notes: string
    proof_url: string
    has_activity: boolean
    can_request_transaction: boolean
    has_reimburse_client: number
    uploadOnly?: boolean
}

export type IOrder = {
    id: number;
    closing_deposit_id: number;
    created_at: Date;
    work_code: string;
    billing_nominal: number;
    billing_invoice_file: string;
    billing_invoice_file_url: string;
}


export const DEPOSIT_TYPES = [
    {
        value: 'utilities',
        label: 'Utilities',
    },
    {
        value: 'maintenance_fee',
        label: 'Maintenance Fee (IPL)',
    },
    {
        value: 'maintenance_fee_and_utilities',
        label: 'Maintenance Fee (IPL) & Utilities',
    },
    {
        value: 'internet',
        label: 'Internet',
    },
    {
        value: 'cutoff',
        label: 'Biaya Cut-off',
    },
    {
        value: 'fine',
        label: 'Denda',
    },
    {
        value: 'electric_token',
        label: 'Token Listrik',
    },
    {
        value: 'fixing_cleaning',
        label: 'Fixing/Cleaning',
    },
    {
        value: 'other',
        label: 'Lainnya',
    },
]

const DepositListModal = (props: DepositListModalProps) => {
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [isDownloadLoading, setIsDownloadLoading] = useState(false)
    const [canReimburseClient, setCanReimburseClient] = useState(false)
    const [totalDepositTenant, setTotalDepositTenant] = useState(0)

    const [orderOwner, setOrderOwner] = useState<IOrder[]>([])
    const [orderTenant, setOrderTenant] = useState<IOrder[]>([])

    const [formModalState, setFormModalState] = useState<false | DepositItem | 'add'>(false)
    const [targetModalState, setTargetModalState] = useState<false | DepositItem | 'reimburse'>(false)
    const [selectedImage, setSelectedImage] = useState<string | undefined>(undefined)

    const [list, setList] = useState<DepositItem[]>([])
    const tagihanList = useMemo(() => list.filter(item => !['other', 'fixing_cleaning'].includes(item.type)), [list])
    const biayaLainnyaList = useMemo(() => list.filter(item => ['other', 'fixing_cleaning'].includes(item.type)), [list])

    const [holdedDepositOffice, setHoldedDepositOffice] = useState(0)
    const [creditBalance, setCreditBalance] = useState(0)
    const [creditBalanceMinusDB, setCreditBalanceMinusDB] = useState(0)
    const [redraw, setRedraw] = useState(new Date().getTime())

    const handleSeeDetail = (data: string) => {
        if (data.endsWith('.pdf')) {
            openExternalLink(data)
        } else {
            setSelectedImage(data)
        }
    }

    const addToList = (data: IOrder) => {
        if (data.billing_invoice_file_url) {
            setIsLoading(true)
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-deposit/activity`, {
                id: data.id,
                closing_id: props.closingId
            })
                .then(res => res.data)
                .then(res => {
                    renderSuccessButton('Berhasil menambahkan closing deposit')
                    fetchData()
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            renderErrorButton('Invoice tidak ditemukan, Harap input Manual Order ini')
        }
    }

    const orderColumn = [
        {
            name: 'created_at',
            label: 'Tanggal Order',
            columnStyle: { textAlign: 'left', width: 'calc(100% * 0.2)', maxWidth: 'calc(100% * 0.2)' },
            style: { width: 'calc(100% * 0.2)', maxWidth: 'calc(100% * 0.2)' },
            render: (data: any) => {
                if (data) return format(new Date(data), 'dd MMMM yyyy', { locale: id })
                return '-'
            }
        },
        {
            name: 'work_code',
            label: 'Kode',
            columnStyle: { textAlign: 'left', width: 'calc(100% * 0.2)', maxWidth: 'calc(100% * 0.2)' },
            style: { width: 'calc(100% * 0.2)', maxWidth: 'calc(100% * 0.2)' },
        },
        {
            name: 'billing_nominal',
            label: 'Billing',
            columnStyle: { textAlign: 'left', width: 'calc(100% * 0.2)', maxWidth: 'calc(100% * 0.2)' },
            style: { width: 'calc(100% * 0.2)', maxWidth: 'calc(100% * 0.2)' },
            render: (data: any) => {
                if (data) return convertNumber(String(data), 'Rp. ')
                return '-'
            }
        },
        {
            name: 'billing_invoice_file_url',
            label: 'Invoice',
            columnStyle: { textAlign: 'left', width: 'calc(100% * 0.2)', maxWidth: 'calc(100% * 0.2)' },
            style: { width: 'calc(100% * 0.2)', maxWidth: 'calc(100% * 0.2)' },
            render: (data: any) => {
                if (data) return (
                    <IconButton onClick={() => handleSeeDetail(data)}>
                        <ContentPasteSearchIcon />
                    </IconButton>
                )
                return '-'
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            columnStyle: { textAlign: 'left' },
            render: (row: any) => row.closing_deposit_id === null && canReimburseClient ?
                (
                    <Button variant='outlined' onClick={() => addToList(row)}>Add to list</Button>
                ) :
                '-'
        },
    ]

    useEffect(() => {
        if (props.isOpen) {
            fetchData()
        } else {
            setList([])
            setTotalDepositTenant(0)
            setHoldedDepositOffice(0)
            setCreditBalance(0)
            setCreditBalanceMinusDB(0)
        }
        // eslint-disable-next-line
    }, [props.isOpen])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/closing-deposit`, { params: { id: props.closingId } })
            .then(res => res.data)
            .then((data: {
                deposits: DepositItem[],
                total_deposit_tenant: number,
                can_propose_reimburse_client: boolean,
                holded_deposit_office: number,
                credit_balance: number,
                credit_balance_minus_db: number,
                order_owner: IOrder[],
                order_tenant: IOrder[]
            }) => {
                setList(data.deposits)
                setCanReimburseClient(data.can_propose_reimburse_client)
                setTotalDepositTenant(data.total_deposit_tenant)
                setHoldedDepositOffice(data.holded_deposit_office || 0)
                setCreditBalance(data.credit_balance || 0)
                setCreditBalanceMinusDB(data.credit_balance_minus_db || 0)
                setOrderOwner(data.order_owner)
                setOrderTenant(data.order_tenant)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        setRedraw(new Date().getTime())
    }, [orderOwner, orderTenant])

    const generatePDF = () => {
        setIsDownloadLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/closing-deposit/generate-pdf`, { params: { id: props.closingId }, responseType: 'blob' })
            .then(res => res.data)
            .then(data => {
                setIsDownloadLoading(false)
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `Deposit List - ${props.closingCode}.pdf`)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .catch(err => {
                generalErrorHandler(err)
                setIsDownloadLoading(false)
            })
    }

    const handleDelete = (item: DepositItem) => {
        renderWarningButton('Apakah anda yakin ingin menghapus data deposit ini?')
            .then(result => {
                if (result.value) {
                    setIsLoading(true)

                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/closing-deposit/${item.id}`)
                        .then(res => res.data)
                        .then((data: any) => {
                            fetchData()

                            renderToastSuccess(`Berhasil menghapus data deposit`)
                        })
                        .catch(err => {
                            generalErrorHandler(err)
                            setIsLoading(false)
                        })
                }
            })
    }

    const sisaDeposit = useMemo(() => totalDepositTenant - biayaLainnyaList.reduce((prev, curr) => prev + curr.amount, 0) - tagihanList.reduce((prev, curr) => prev + curr.amount, 0), [totalDepositTenant, biayaLainnyaList, tagihanList])

    return (

        <ModalDetail
            open={props.isOpen}
            title={`Deposit List - ${props.closingCode}`}
            appBarProps={{
                color: 'primary',
            }}
            customRightButton={(
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={generatePDF}
                >
                    Generate PDF
                </Button>
            )}
            onCloseModal={() => props.onClose()}
            isLoading={isLoading}
        >
            <Grid item xs={12}>
                <Root>
                    <ImagePreview
                        image={selectedImage}
                        onClose={() => setSelectedImage(undefined)}
                    />
                    <DepositFormModal
                        data={formModalState}
                        closingId={props.closingId}
                        onClose={() => setFormModalState(false)}
                        onSuccess={() => {
                            fetchData()
                            setFormModalState(false)
                        }}
                    />
                    <DepositTargetModal
                        data={targetModalState}
                        closingId={props.closingId}
                        sisaDepositTenant={totalDepositTenant - biayaLainnyaList.reduce((prev, curr) => prev + curr.amount, 0) - tagihanList.reduce((prev, curr) => prev + curr.amount, 0)}
                        onClose={() => setTargetModalState(false)}
                        onSuccess={() => {
                            fetchData()
                            setTargetModalState(false)
                        }}
                        setDepositTransactionRefreshFlag={props.setDepositTransactionRefreshFlag}
                        maxForReimburseClient={creditBalanceMinusDB < 0 ? 0 : creditBalanceMinusDB}
                    />
                    <LoadingScreen fullScreen open={isDownloadLoading} />
                    <div
                        className={`${classes.paddingHorizontal} ${classes.borderBottom}`}
                        style={{
                            paddingBottom: 10,
                            paddingTop: 8,
                        }}
                    >
                        <div className={`${classes.flexRowBetween}`} style={{ marginBottom: 8 }}>
                            <span className={classes.bold}>
                                Total Deposit Tenant
                            </span>
                            <span className={classes.bold}>
                                {convertNumber(totalDepositTenant.toString(), 'Rp. ')} ({convertNumber(holdedDepositOffice.toString(), 'Rp. ')} di Jendela)
                            </span>
                        </div>
                        <div className={`${classes.flexRowBetween}`}>
                            <span className={classes.bold}>
                                CR Deposit
                            </span>
                            <span className={classes.bold}>
                                {convertNumber(creditBalance.toString(), 'Rp. ')}
                            </span>
                        </div>
                    </div>
                    <div
                        className={`${classes.paddingHorizontal} ${classes.flexRowBetween} ${classes.borderBottom}`}
                        style={{
                            paddingBottom: 10,
                            paddingTop: 12,
                        }}
                    >
                        <div
                            className={classes.bold}
                        >
                            Tagihan
                        </div>
                        {
                            canReimburseClient &&
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => setFormModalState('add')}
                            >
                                Tambah Data Baru
                            </Button>
                        }
                    </div>
                    <div className={classes.borderBottom}>
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.extraLeftPaddingForTable}>Tipe</TableCell>
                                        <TableCell>Periode</TableCell>
                                        <TableCell>Nominal</TableCell>
                                        <TableCell>Notes</TableCell>
                                        <TableCell>Bukti</TableCell>
                                        <TableCell align='right' className={classes.extraRightPaddingForTable}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        isLoading ?
                                            <TableRow>
                                                <TableCell colSpan={6} align='center' style={{ borderColor: 'grey' }}>
                                                    <CircularProgress />
                                                </TableCell>
                                            </TableRow>
                                            :
                                            tagihanList.length > 0 ?
                                                <>
                                                    {
                                                        tagihanList.map(tagihan => (
                                                            <TableRow key={tagihan.id}>
                                                                <TableCell className={classes.extraLeftPaddingForTable}>
                                                                    {tagihan.type ? DEPOSIT_TYPES.find(type => type.value === tagihan.type)?.label : '-'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {`${tagihan.start_period ? format(new Date(tagihan.start_period), 'dd MMM yyyy') : ''} - ${tagihan.end_period ? format(new Date(tagihan.end_period), 'dd MMM yyyy') : ''}`}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {tagihan.amount ? convertNumber(tagihan.amount.toString(), 'Rp. ') : '-'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {tagihan.notes || '-'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        tagihan.proof_url ?
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    if (tagihan.proof_url.includes('.pdf')) {
                                                                                        openExternalLink(tagihan.proof_url)
                                                                                    } else {
                                                                                        setSelectedImage(tagihan.proof_url)
                                                                                    }

                                                                                }}
                                                                            >
                                                                                {
                                                                                    tagihan.proof_url.includes('.pdf') ?
                                                                                        <Description fontSize='medium' color='primary' />
                                                                                        :
                                                                                        <ImageIcon fontSize='medium' color='primary' />
                                                                                }
                                                                            </IconButton>
                                                                            :
                                                                            '-'
                                                                    }
                                                                </TableCell>
                                                                <TableCell align='right' className={classes.extraRightPaddingForTable}>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'flex-end',
                                                                    }}
                                                                        className={classes.actionButtonContainer}
                                                                    >
                                                                        <IconButton
                                                                            color={(!canReimburseClient || !tagihan.can_request_transaction) ? 'secondary' : 'warning'}
                                                                            size="small"
                                                                            onClick={() => {
                                                                                if (tagihan.has_activity) {
                                                                                    renderInfoButton('Information', 'Data ini tidak bisa diedit. Bila ingin mengganti, harap rubah di JHS Order-nya')
                                                                                } else {
                                                                                    setFormModalState({ ...tagihan, uploadOnly: (!canReimburseClient || !tagihan.can_request_transaction) })
                                                                                }
                                                                            }}
                                                                        >
                                                                            {
                                                                                (!canReimburseClient || !tagihan.can_request_transaction) ?
                                                                                    <UploadFile />
                                                                                    :
                                                                                    <Edit />
                                                                            }
                                                                        </IconButton>
                                                                        {
                                                                            (tagihan.can_request_transaction && canReimburseClient) &&
                                                                            <IconButton
                                                                                color="error"
                                                                                size="small"
                                                                                onClick={() => handleDelete(tagihan)}
                                                                            >
                                                                                <Delete />
                                                                            </IconButton>
                                                                        }
                                                                        {
                                                                            (tagihan.proof_url && tagihan.can_request_transaction) ?
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    size="small"
                                                                                    onClick={() => setTargetModalState(tagihan)}
                                                                                    style={{ whiteSpace: 'nowrap' }}
                                                                                >
                                                                                    Propose Transaksi
                                                                                </Button>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                    <TableRow>
                                                        <TableCell className={`${classes.extraLeftPaddingForTable} ${classes.bold}`} colSpan={2} style={{ borderColor: 'grey' }}>
                                                            Total Tagihan
                                                        </TableCell>
                                                        <TableCell className={classes.bold} colSpan={4} style={{ borderColor: 'grey' }}>
                                                            {convertNumber(tagihanList.reduce((prev, curr) => prev + curr.amount, 0).toString(), 'Rp. ')}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={6} align='center' style={{ borderColor: 'grey' }}>
                                                        Tidak ada data tagihan
                                                    </TableCell>
                                                </TableRow>
                                    }
                                </TableBody>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className={classes.bold} style={{ borderColor: 'grey', paddingBottom: 15, paddingTop: 15, paddingLeft: 24, fontSize: 16 }}>
                                            Biaya Lainnya
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.extraLeftPaddingForTable}>Tipe</TableCell>
                                        <TableCell>Deskripsi</TableCell>
                                        <TableCell>Nominal</TableCell>
                                        <TableCell>Notes</TableCell>
                                        <TableCell>Bukti</TableCell>
                                        <TableCell align='right' className={classes.extraRightPaddingForTable}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        isLoading ?
                                            <TableRow>
                                                <TableCell colSpan={6} align='center'>
                                                    <CircularProgress />
                                                </TableCell>
                                            </TableRow>
                                            :
                                            biayaLainnyaList.length > 0 ?
                                                <>
                                                    {
                                                        biayaLainnyaList.map(biayaLainnya => (
                                                            <TableRow key={biayaLainnya.id}>
                                                                <TableCell className={classes.extraLeftPaddingForTable}>
                                                                    {biayaLainnya.type ? DEPOSIT_TYPES.find(type => type.value === biayaLainnya.type)?.label : '-'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {biayaLainnya.other_description || '-'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {biayaLainnya.amount ? convertNumber(biayaLainnya.amount.toString(), 'Rp. ') : '-'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {biayaLainnya.notes || '-'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        biayaLainnya.proof_url ?
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    if (biayaLainnya.proof_url.includes('.pdf')) {
                                                                                        openExternalLink(biayaLainnya.proof_url)
                                                                                    } else {
                                                                                        setSelectedImage(biayaLainnya.proof_url)
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {
                                                                                    biayaLainnya.proof_url.includes('.pdf') ?
                                                                                        <Description fontSize='medium' color='primary' />
                                                                                        :
                                                                                        <ImageIcon fontSize='medium' color='primary' />
                                                                                }
                                                                            </IconButton>
                                                                            :
                                                                            '-'
                                                                    }
                                                                </TableCell>
                                                                <TableCell align='right' className={classes.extraRightPaddingForTable}>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'flex-end',
                                                                    }}
                                                                        className={classes.actionButtonContainer}
                                                                    >
                                                                        <IconButton
                                                                            color={(!canReimburseClient || !biayaLainnya.can_request_transaction) ? 'secondary' : 'warning'}
                                                                            size="small"
                                                                            onClick={() => {
                                                                                if (biayaLainnya.has_activity) {
                                                                                    renderInfoButton('Information', 'Data ini tidak bisa diedit. Bila ingin mengganti, harap rubah di JHS Order-nya')
                                                                                } else {
                                                                                    setFormModalState({ ...biayaLainnya, uploadOnly: (!canReimburseClient || !biayaLainnya.can_request_transaction) })
                                                                                }
                                                                            }}
                                                                        >
                                                                            {
                                                                                (!canReimburseClient || !biayaLainnya.can_request_transaction) ?
                                                                                    <UploadFile />
                                                                                    :
                                                                                    <Edit />
                                                                            }
                                                                        </IconButton>
                                                                        {
                                                                            (biayaLainnya.can_request_transaction && canReimburseClient) &&
                                                                            <>
                                                                                <IconButton
                                                                                    color="error"
                                                                                    size="small"
                                                                                    onClick={() => handleDelete(biayaLainnya)}
                                                                                >
                                                                                    <Delete />
                                                                                </IconButton>
                                                                            </>
                                                                        }
                                                                        {
                                                                            (biayaLainnya.proof_url && biayaLainnya.can_request_transaction) ?
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    size="small"
                                                                                    onClick={() => setTargetModalState(biayaLainnya)}
                                                                                    style={{ whiteSpace: 'nowrap' }}
                                                                                >
                                                                                    Propose Transaksi
                                                                                </Button>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                    <TableRow>
                                                        <TableCell className={`${classes.extraLeftPaddingForTable} ${classes.bold}`} colSpan={2}>
                                                            Total Biaya Lainnya
                                                        </TableCell>
                                                        <TableCell className={classes.bold} colSpan={4}>
                                                            {convertNumber(biayaLainnyaList.reduce((prev, curr) => prev + curr.amount, 0).toString(), 'Rp. ')}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={6} align='center'>
                                                        Tidak ada data biaya lainnya
                                                    </TableCell>
                                                </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div
                        className={`${classes.paddingHorizontal}`}
                        style={{
                            paddingTop: 12,
                            marginBottom: 20
                        }}
                    >
                        <Grid container>
                            <Grid item xs={6} md={4}>
                                <div
                                    className={classes.bold}
                                >
                                    Sisa Deposit Tenant
                                </div>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <div
                                    className={classes.bold}
                                >
                                    Rp. {sisaDeposit < 0 ? '-' : ''}{convertNumber(sisaDeposit.toString())}
                                </div>
                            </Grid>
                            {
                                canReimburseClient &&
                                <Grid item xs={6} md={4} style={{ display: 'flex' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => setTargetModalState('reimburse')}
                                        style={{ whiteSpace: 'nowrap', marginLeft: 'auto' }}
                                    >
                                        Reimburse Client
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                        <Grid container>
                            <Grid item xs={6} md={4}>
                                <div
                                    className={classes.bold}
                                >
                                    CR Deposit
                                </div>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <div
                                    className={classes.bold}
                                >
                                    Rp. {creditBalanceMinusDB < 0 ? '-' : ''}{convertNumber(creditBalanceMinusDB.toString())}
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <Card variant='outlined' sx={{ mt: 2 }}>
                        <CardContent>
                            <div >
                                <h3>Order Tenant</h3>
                                <SimpleDataTable
                                    columns={orderColumn}
                                    customData={orderTenant}
                                    reDraw={redraw}
                                />
                            </div>
                            <div >
                                <h3>Order Owner</h3>
                                <SimpleDataTable
                                    columns={[...orderColumn.filter((column) => column.name !== 'EXTRA'), {
                                        name: '',
                                        label: '',
                                    }]}
                                    customData={orderOwner}
                                    reDraw={redraw}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Root>
            </Grid>
        </ModalDetail>
    )
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative',
    },
    borderBottom: {
        borderBottom: '1px solid gray',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    content: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    paddingHorizontal: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    flexRowBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    bold: {
        fontWeight: 500,
    },
    actionButtonContainer: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
        margin: '0 0',
        '& button, a': {
            marginLeft: '10px'
        }
    },
    extraLeftPaddingForTable: {
        paddingLeft: 24,
    },
    extraRightPaddingForTable: {
        paddingRight: 24,
    },
}), "DepositList_Modal"
)

export default DepositListModal

import React, { Fragment, useState, useEffect } from 'react';
import { Grid, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, MenuItem } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import ProjectDeveloperCategoryTable from './ProjectDeveloperCategoryTable';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';

export interface IProperty {
    id: string;
    name: string;
}

export interface ICategory {
    id: string;
    name: string;
    properties: IProperty[];
}

const ProjectDeveloperCategoryList = () => {
    const params: any = useParams();
    const navigate =  useNavigate();
    const API_URL = process.env.REACT_APP_API_URL + '/project-developer/' + params.project_id + '/category';

    const [isLoading, setIsLoading] = useState(false);
    const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
    const [addPropertyModalOpen, setAddPropertyModalOpen] = useState(false);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [addCategoryState, setAddCategoryState] = useState('');
    const [addPropertyState, setAddPropertyState] = useState('');
    const [errorAddCategoryState, setErrorAddCategoryState] = useState('');
    const [errorAddPropertyState, setErrorAddPropertyState] = useState('');
    const [availableProperties, setAvailableProperties] = useState<IProperty[]>([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL)
            .then(res => {
                const categories = res.data.categories;
                for (const [key, value] of Object.entries<any>(categories)) {
                    categories[key]['properties'] = value.properties ? JSON.parse(value.properties) : [];
                }
                setCategories(categories);
                setAvailableProperties(res.data.properties);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const handleBack = () => {
        navigate(`/developer/project/${params.project_id}`);
    }

    const handleAddCategory = () => {
        setAddCategoryModalOpen(true);
    };

    const handleClose = () => {
        setAddCategoryState('');
        setAddPropertyState('');
        setErrorAddCategoryState('');
        setErrorAddPropertyState('');
        setAddCategoryModalOpen(false);
        setAddPropertyModalOpen(false);
    };

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'category') {
            setAddCategoryState(e.target.value);
        } else {
            setAddPropertyState(e.target.value);
        }
    }

    const handlePropertyModal = (categoryId: string) => {
        setSelectedCategory(categoryId);
        setAddPropertyModalOpen(true);
    }

    const handleDeleteProperty = (categoryId: string, propertyId: string) => {
        renderWarningButton('Apakah anda yakin ingin menghapus property ini?')
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios
                    .post(API_URL + '/delete-property', { categoryId, propertyId })
                    .then((res) => {
                        Swal.fire({
                            title: "Berhasil menghapus",
                            icon: 'success',
                            timer: 1000
                        })
                            .then(() => {
                                const oldCategories = categories.slice();

                                const categoryKey = oldCategories.findIndex(category => {
                                    return category.id === categoryId;
                                });

                                oldCategories[categoryKey]['properties'] = res.data;
                                setCategories(oldCategories);
                            })
                    })
                    .catch(error => {
                        generalErrorHandler(error)
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }
        })
    }

    const handleSortCategory = (categoryId: string, dir: 'up' | 'down', key: number) => {
        setIsLoading(true);
        DefaultAxios
            .post(API_URL + '/sort-category', { categoryId, dir })
            .then(res => {
                const oldIndex = key;
                const newIndex = dir === 'up' ? key - 1 : key + 1;

                const oldCategories = categories.slice();

                const tmp = { ...oldCategories[newIndex] };
                oldCategories[newIndex] = { ...oldCategories[oldIndex] };
                oldCategories[oldIndex] = { ...tmp };

                setCategories(oldCategories);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleEditCategory = (categoryId: string, name: string) => {
        setIsLoading(true);
        DefaultAxios
            .put(API_URL + '/' + categoryId, { name })
            .then(res => {
                const oldCategories = categories.slice();

                const categoryKey = oldCategories.findIndex(category => {
                    return category.id === categoryId;
                });

                oldCategories[categoryKey]['name'] = name;
                setCategories(oldCategories);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleDeleteCategory = (categoryId: string) => {
        renderWarningButton('Apakah anda yakin ingin menghapus category ini?')
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios
                    .delete(API_URL + '/' + categoryId)
                    .then((res) => {
                        Swal.fire({
                            title: "Berhasil menghapus",
                            icon: 'success',
                            timer: 1000
                        })
                            .then(() => {
                                const oldCategories = categories.slice();

                                const categoryKey = oldCategories.findIndex(category => {
                                    return category.id === categoryId;
                                });

                                oldCategories.splice(categoryKey, 1);
                                setCategories(oldCategories);
                            })
                    })
                    .catch(error => {
                        generalErrorHandler(error)
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }
        })
    }

    const renderCategoryTable = () => {
        return categories.map((category, key) => {
            return <Grid item xs={12} key={category.id}>
                <ProjectDeveloperCategoryTable
                    category={category}
                    onAddPropertyModalOpen={handlePropertyModal}
                    onDeleteProperty={handleDeleteProperty}
                    isTop={key === 0}
                    isBottom={key === categories.length - 1}
                    onSortCategory={(categoryId: string, dir: 'up' | 'down') => handleSortCategory(categoryId, dir, key)}
                    onEditCategory={handleEditCategory}
                    onDeleteCategory={handleDeleteCategory}
                />
            </Grid>
        });
    }

    const handleSubmitCategory = () => {
        if (!addCategoryState) {
            setErrorAddCategoryState('Nama Category harus diisi');
            return;
        } else {
            setErrorAddCategoryState('');
        }

        setIsLoading(true);
        DefaultAxios
            .post(API_URL, { name: addCategoryState })
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        const oldCategories = categories.slice();

                        oldCategories.push({
                            id: res.data,
                            name: addCategoryState,
                            properties: []
                        });

                        setCategories(oldCategories);
                        handleClose();
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleSubmitProperty = () => {
        if (!addPropertyState) {
            setErrorAddPropertyState('Nama Property harus diisi');
            return;
        } else {
            setErrorAddPropertyState('');
        }

        DefaultAxios
            .post(API_URL + '/submit-property', { categoryId: selectedCategory, propertyId: addPropertyState })
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        const oldCategories = categories.slice();

                        const categoryKey = oldCategories.findIndex(category => {
                            return category.id === selectedCategory;
                        });

                        oldCategories[categoryKey]['properties'].push(res.data);
                        setCategories(oldCategories);
                        handleClose();
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                fullWidth
                open={addCategoryModalOpen} 
                onClose={handleClose} 
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">Add Category</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        fullWidth
                        variant="outlined"
                        label="Nama Category"
                        name="category"
                        value={addCategoryState}
                        onChange={handleChanged}
                        error={!!errorAddCategoryState}
                        helperText={errorAddCategoryState}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmitCategory} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                open={addPropertyModalOpen}
                onClose={handleClose}
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">Add Property</DialogTitle>
                <DialogContent>
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        label="Nama Property"
                        name="property"
                        value={addPropertyState}
                        onChange={handleChanged}
                        error={!!errorAddPropertyState}
                        helperText={errorAddPropertyState}
                    >
                        {availableProperties.map(property => {
                            return <MenuItem key={property.id} value={property.id}>{property.name}</MenuItem>
                        })}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmitProperty} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{ paddingLeft: 0 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBack}
                        style={{ marginRight: '10px' }}
                    >
                        Back to Property
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddCategory}
                    >
                        Add Category
                    </Button>
                </Grid>
                {renderCategoryTable()}
            </Grid>
        </Fragment>
    );
}

export default ProjectDeveloperCategoryList;
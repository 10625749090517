import React from 'react'

/**
 * Components
 */
import { Box, IconButton, Typography } from '@mui/material'

/**
 * Icons
 */
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

interface OptionSwitcherND2Props {
    name: string
    label: string
    value: string | number
    options: {
        value: string | number
        label: string
    }[]
    onChange: (name: string, value: string | number) => void
}

const OptionSwitcherND2 = (props: OptionSwitcherND2Props) => {
    const currentValue = props.options.find(option => option.value === props.value)
    const currentIndex = props.options.findIndex(option => option.value === props.value)

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <Typography
                sx={{
                    marginBottom: 10 / 8,
                }}
                fontSize={14}
                fontWeight={500}
            >
                {props.label}
            </Typography>
            <Box
                sx={{
                    border: '1px solid #DDDDDD',
                    borderRadius: 6 / 4,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <IconButton
                    disabled={currentIndex === 0}
                    onClick={() => {
                        props.onChange(props.name, props.options[currentIndex - 1].value)
                    }}
                >
                    <ChevronLeft />
                </IconButton>
                <Typography>
                    {currentValue?.label || 'Silahkan Pilih'}
                </Typography>
                <IconButton
                    disabled={currentIndex === props.options.length - 1}
                    onClick={() => {
                        props.onChange(props.name, props.options[currentIndex + 1].value)
                    }}
                >
                    <ChevronRight />
                </IconButton>
            </Box>
        </Box>
    )
}

export default OptionSwitcherND2

import React, { Fragment } from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle(theme => ({
  head: {
    background: '#ebebeb',
    color: '#8d8d8d',
    fontSize: 24,
    fontWeight: 'bold',
    border: 'none',
    paddingTop: '28px',
    paddingBottom: '28px',
  },
  links: {
    color: '#00AEEF',
    cursor: 'pointer',
    fontWeight: 500
  },
  unit_text: {
    fontSize: 27,
  },
  unit_name: {
    fontWeight: 500,
    color: '#343434',
    height: '80px'
  },
  unit_pos: {
    color: '#707070'
  },
  entry_text: {
    color: '#ebebeb',
    fontSize: 25,
    fontWeight: 500
  },
  safe: {
    color: '#4BAF92'
  },
  over: {
    color: '#0080FE',
    fontSize: 24,
    fontWeight: 600
  },
  warning: {
    color: '#FF5A5F',
    fontWeight: 600
  }
}), "Demand_Supply_table");

const DemandSupplyTable = (props: any) => {
  const { Root, classes } = useStyles();

  const renderTable = () => {
    let rows = [<Fragment key={'row'}></Fragment>]
    const roomType = ['STUDIO', '1BR', '2BR', '3BR']
    let index = 0;
    for (let i in props.data) {
      index++
      if (index >= props.start && index <= props.limit) {
        rows.push(
          <Fragment key={index}>
            <TableRow>
              <TableCell className={`${classes.unit_text} ${classes.unit_pos}`} align={'left'}>{index}</TableCell>
              <TableCell className={`${classes.unit_text} ${classes.unit_name}`} align={'left'}>{i}</TableCell>
              {roomType.map((val: any) => {
                let apt;
                if (typeof props.data[i][val] === 'undefined') {
                  return <TableCell className={classes.entry_text} align={'center'} key={i + val}>NA</TableCell>
                } else {
                  apt = props.data[i][val]
                  if (apt.demand > apt.available) {
                    return <TableCell className={`${classes.entry_text} ${classes.warning}`} align={'center'} key={i + val}>{apt.demand - apt.available}</TableCell>
                  } else if (apt.available - apt.demand <= 5) {
                    return <TableCell className={`${classes.entry_text} ${classes.safe}`} align={'center'} key={i + val}>Aman</TableCell>
                  } else {
                    return <TableCell className={`${classes.entry_text} ${classes.over}`} align={'center'} key={i + val}>Over</TableCell>
                  }
                }
              })}
            </TableRow>
          </Fragment>
        )
      }
    }
    return rows;
  }
  return (
    <Root>
      <TableContainer >
        <Table>
          <TableHead>
            <TableRow >
              {props.head.map((title: string, idx: number) => {
                return (
                  <TableCell className={classes.head} align={title === 'Nama Apartemen' ? ('left') : ('center')} key={idx}>{title}</TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTable()}
          </TableBody>
        </Table>
      </TableContainer>
    </Root>
  )
}

export default DemandSupplyTable;
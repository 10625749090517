import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import LoadingScreen from '../../../_components/LoadingScreen'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete'
import AsyncMultipleAutoComplete from '../../../_components/_form/AsycMultipleAutoComplete'
import DateTimePicker from '../../../_components/_formElements/DateTimePicker'

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import { generalErrorHandler, renderToastSuccess } from '../../../_utils/Helper'
import { format } from 'date-fns'

interface ShowingFormModalProps {
    isOpen: boolean
    onClose: () => void
    onFinished: () => void
}

interface State {
    pic_name: string
    pic_id: string
    enquiry_name: string
    enquiry_id: string
    datetime: null | Date
    apartment_name: string
    apartment_id: string
    units: {
        id: number
        label: string
    }[]
    notes: string
}

const CURRENT_DATE = new Date()

const ShowingFormModal = (props: ShowingFormModalProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<State>(initialState)
    const [error, setError] = useState<IValidationErrors<State>>({})

    useEffect(() => {
        if (!props.isOpen) {
            setState(initialState)
            setError({})
        }
    }, [props.isOpen])

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        const label = name === 'pic_id' ? 'pic_name' : name === 'enquiry_id' ? 'enquiry_name' : ''

        setState(prev => ({
            ...prev,
            [name]: value.id.toString(),
            [label]: value.label,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        const label = name === 'pic_id' ? 'pic_name' : name === 'enquiry_id' ? 'enquiry_name' : ''

        setState(prev => ({
            ...prev,
            [name]: '',
            [label]: '',
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleMultipleAutocomplete = (name: string, value: IAutoCompleteOption[]) => {
        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const onDateChange = (name: string, value: Date | null) => {
        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const onSubmit = () => {
        const { errors, isValid } = validateData(state, validationRules, validationAlias)
        setError(errors)

        if (isValid) {
            setIsLoading(true)

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/showing`, {
                enquiry_id: state.enquiry_id,
                datetime: format(state.datetime!, 'yyyy-MM-dd HH:mm'),
                apartment_id: state.apartment_id,
                units: state.units.map(unit => unit.id),
                pic_id: state.pic_id,
                notes: state.notes || null,
            })
                .then(() => {
                    renderToastSuccess('Berhasil menambahkan showing')
                    setIsLoading(false)
                    props.onFinished()
                    props.onClose()
                })
                .catch(err => {
                    generalErrorHandler(err)
                    setIsLoading(false)
                })
        }
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={isLoading ? () => { } : props.onClose}
            maxWidth="xs"
            fullWidth
        >
            <LoadingScreen open={isLoading} />
            <DialogTitle>Add Showing</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: -1 }}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={process.env.REACT_APP_API_URL + '/autocomplete/user/advisor'}
                            initialQuery={state.pic_name}
                            label="Agent"
                            name="pic_id"
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            errorText={error.pic_id}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={process.env.REACT_APP_API_URL + '/admin/autocomplete/enquiry'}
                            initialQuery={state.enquiry_name}
                            label="Enquiry"
                            name="enquiry_id"
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            errorText={error.enquiry_id}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimePicker
                            label='Tanggal & Waktu'
                            value={state.datetime}
                            name='datetime'
                            onChange={onDateChange}
                            format='dd MMMM yyyy hh:mm aaaa'
                            minDateTime={CURRENT_DATE}
                            errorText={error.datetime}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={process.env.REACT_APP_API_URL + '/autocomplete/apartment'}
                            initialQuery={state.apartment_name}
                            label="Apartment"
                            name="apartment_id"
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            errorText={error.apartment_id}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AsyncMultipleAutoComplete
                            value={state.units}
                            label="Unit (bisa lebih dari satu)"
                            name="units"
                            onChange={handleMultipleAutocomplete}
                            url={process.env.REACT_APP_API_URL + '/autocomplete/unit?show_available_only=1&apartment_ids=' + state.apartment_id || ''}
                            disabled={!state.apartment_id || isLoading}
                            error={!!error.units}
                            helperText={error.units}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Notes"
                            name="notes"
                            onChange={onChange}
                            value={state.notes}
                            multiline
                            minRows={2}
                            disabled={isLoading}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onSubmit}
                    disabled={isLoading}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const validationRules: IValidationRules = {
    pic_id: 'required',
    enquiry_id: 'required',
    apartment_id: 'required',
    datetime: 'required',
    units: 'required',
}

const validationAlias: IValidationAlias = {
    pic_id: 'Agent',
    enquiry_id: 'Enquiry',
    apartment_id: 'Apartment',
    datetime: 'Tanggal & Waktu',
    units: 'Unit',
}

const initialState: State = {
    pic_name: '',
    pic_id: '',
    enquiry_name: '',
    enquiry_id: '',
    apartment_name: '',
    apartment_id: '',
    datetime: null,
    notes: '',
    units: [],
}

export default ShowingFormModal

import React, { Fragment, useState, useEffect } from 'react';
import { Grid, Button, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, IconButton } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import Photo360Form from './_components/Photo360Form';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    developerPropertyId: string;
    developerProjectId: string;
}

export interface IState {
    id: string;
    caption: string;
    alt: string;
    thumbnail: string;
    file: File | null;
    thumbnail_360: string;
    file_360: File | null;
    hotspot: any[];
}

const useStyles = generateStyle((theme) => ({
    submitButton: {
        float: 'right'
    }
}), "DevProp_360PhotoForm"
);

const DeveloperProperty360PhotoForm = (props: Props) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/project-developer/${props.developerProjectId}/property/${props.developerPropertyId}/image-360`;
    const { Root, classes } = useStyles();

    const defaultState: IState = {
        id: '',
        caption: '',
        alt: '',
        thumbnail: '',
        file: null,
        thumbnail_360: '',
        file_360: null,
        hotspot: []
    };

    const [formState, setFormState] = useState<IState>(defaultState);
    const [imageStates, setImageStates] = useState<IState[]>([]);
    const [mode, setMode] = useState<'add' | 'edit'>('add');
    const [isLoading, setIsLoading] = useState(false);
    const [hotspots, setHotspots] = useState<any[]>([]);
    const [resetForm, setResetForm] = useState(new Date());

    useEffect(() => {
        loadImages();
        // eslint-disable-next-line
    }, []);

    const loadImages = () => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL)
            .then(res => {
                const imageGallery = res.data;
                const newImageStates = [];

                for (let key in imageGallery) {
                    newImageStates.push({
                        id: imageGallery[key].id,
                        caption: imageGallery[key].caption,
                        alt: imageGallery[key].alt,
                        thumbnail: imageGallery[key].thumbnail,
                        file: null,
                        thumbnail_360: imageGallery[key].thumbnail_360,
                        file_360: null,
                        hotspot: imageGallery[key].hotspot ? JSON.parse(imageGallery[key].hotspot) : []
                    })
                }

                setImageStates(newImageStates);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleNewData = () => {
        setResetForm(new Date());
        setMode('add');
        setFormState({
            ...defaultState
        })
    }

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const oldStates: any = { ...formState };
        const target = event.target;

        if (target.name === 'file') {
            if (target.files && target.files[0]) {
                const file = target.files[0];
                oldStates['thumbnail'] = URL.createObjectURL(file);
                oldStates['file'] = file;
            }
        } else if (target.name === 'file_360') {
            if (target.files && target.files[0]) {
                const file = target.files[0];
                oldStates['thumbnail_360'] = URL.createObjectURL(file);
                oldStates['file_360'] = file;
            }
        } else {
            oldStates[target.name] = target.value;
        }
        setFormState(oldStates);
    }

    const renderForms = () => {
        const formsEl = (
            <Fragment>
                <Photo360Form
                    form={formState}
                    images={imageStates}
                    onChange={handleChanged}
                    hotspots={hotspots}
                    setHotspots={setHotspots}
                    resetForm={resetForm}
                />
                <Grid item xs={12}>
                    <hr />
                </Grid>
            </Fragment>
        );

        const actionButtons = (
            <>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.submitButton}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </>
        )

        return (
            <>
                {formsEl}
                {formState ? actionButtons : null}
            </>
        );
    }

    const handleEditImage = (imageState: IState) => {
        setMode('edit');
        setFormState({
            ...imageState
        })
    }

    const handleDeleteImage = (imageState: IState) => {
        renderWarningButton("Apakah anda yakin ingin menghapus photo ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${process.env.REACT_APP_API_URL}/project-developer/${props.developerProjectId}/property/${props.developerPropertyId}/image-360/${imageState.id}`)
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(res => {
                                    loadImages();
                                })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const checkValidation = () => {
        let isValid = true;

        if (!formState.file_360 && mode === 'add') {
            isValid = false;
        }

        if (!formState.caption) {
            isValid = false;
        }

        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            Swal.fire({
                title: "Error",
                text: 'Please fill caption and 360 file',
                icon: 'error'
            });
            return;
        }

        let url;
        const fd = new FormData();

        for (let key in formState) {
            let value = formState[key as keyof IState];
            if (value) {
                if (key === 'file_360') {
                    fd.append('image_360', value as File);
                } else if (key === 'file') {
                    fd.append('image', value as File);
                } else if (key === 'hotspot') {
                    fd.append('hotspot', JSON.stringify(hotspots));
                } else {
                    fd.append(`${key}`, String(value));
                }
            }
        }

        if (mode === 'add') {
            url = API_URL;
        } else {
            fd.append(`_method`, 'put');
            url = `${process.env.REACT_APP_API_URL}/project-developer/${props.developerProjectId}/property/${props.developerPropertyId}/image-360/${formState['id']}`;
        }

        setIsLoading(true);
        DefaultAxios.post(url, fd)
            .then(res => {
                Swal.fire({
                    title: "Submit photo berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadImages();
                        setFormState(defaultState);
                        setResetForm(new Date());
                    })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={12}>
                {
                    mode !== 'add'
                        ? <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNewData}
                        >
                            Add 360
                        </Button>
                        : null
                }
                <hr />
            </Grid>

            {renderForms()}

            <Grid item xs={12}>
                <hr />
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Caption</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {imageStates.map((imageState) => (
                                <TableRow key={imageState.id}>
                                    <TableCell component="th" scope="row">
                                        {imageState.caption}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton aria-label="delete" color="primary" onClick={() => handleEditImage(imageState)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton aria-label="delete" color="secondary" onClick={() => handleDeleteImage(imageState)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Root>
    );
}

export default DeveloperProperty360PhotoForm;
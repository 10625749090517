import React, { useEffect, useMemo, useState } from 'react'
import LoadingScreen from '../../_components/LoadingScreen'
import FilterMonthListing from './_components/FilterMonthListing'
import { generalErrorHandler, getMonth } from '../../_utils/Helper';
import { MonthYearOption } from './EnquiryBreakdown';
import CardListing from './_components/CardListing';
import DefaultAxios from '../../_utils/DefaultAxios';
import { Box } from '@mui/material';

const defaultMonth = {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    display: `${getMonth(new Date().getMonth())} ${new Date().getFullYear()}`,
    values: 0,
};

export interface DataUsers {
    name: string;
    new_submission: number;
    listed: number;
    rejected: number;
    on_process: number;
    cold: number;
    new_listing: number;
    new_listing_entrusted_key: number;
    on_process_ticket: number;
}

export interface DataAllUsers {
    total_cold: number;
    total_listed: number;
    total_new_listing: number;
    total_new_listing_entrusted_key: number;
    total_new_submission: number;
    total_on_process: number;
    total_on_process_ticket: number;
    total_rejected: number;
}

const FunnelReportListing = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [sort, setSort] = useState<string | null>("default");
    const [allUsers, setAllUsers] = useState<DataAllUsers>({
        total_cold: 0,
        total_listed: 0,
        total_new_listing: 0,
        total_new_listing_entrusted_key: 0,
        total_new_submission: 0,
        total_on_process: 0,
        total_on_process_ticket: 0,
        total_rejected: 0,
    });
    const [dataUsers, setDataUsers] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState<MonthYearOption>(defaultMonth);

    useEffect(() => {
        getFunnelReportListing();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonth.values, sort]);

    useEffect(() => {
        const today = new Date();
        const defaultDailyWeekly = {
            display: `${getMonth(today.getMonth())} ${today.getFullYear()}`,
            month: today.getMonth() + 1,
            year: today.getFullYear(),
            values: 0,
        };
        setSelectedMonth(defaultDailyWeekly);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const data = {
        month: selectedMonth.values,
        sort: sort === 'default' ? null : sort
    }

    const getFunnelReportListing = async () => {
        setLoading(true);
        try {
            const response = await DefaultAxios.get(`${process.env.REACT_APP_API_URL}/funnel-report-listing`, { params: data });
            if (response.status === 200) {
                setAllUsers(response.data.all_users);
                setDataUsers(response.data.users);
            }
        } catch (error) {
            generalErrorHandler(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSort = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSort(value);
    };

    const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = e.target.value;
        const selectedOption = monthlyOptions.find(option => option.display === selectedValue);
        if (selectedOption) {
            setSelectedMonth(selectedOption);
        }
    };

    const monthlyOptions = useMemo(() => {
        const options: MonthYearOption[] = [];
        const today = new Date();
        const currentYear = today.getFullYear();

        for (let i = 0; i <= 5; i++) {
            const currentDate = new Date(currentYear, today.getMonth() - i, 1);
            options.push({
                display: `${getMonth(currentDate.getMonth())} ${currentDate.getFullYear()}`,
                month: currentDate.getMonth() + 1,
                year: currentDate.getFullYear(),
                values: i,
            });
        }
        return options;
    }, []);

    return (
        <Box>
            {loading && <LoadingScreen open={loading} />}
            <FilterMonthListing
                selectedMonth={selectedMonth}
                handleMonthChange={handleMonthChange}
                monthlyOptions={monthlyOptions}
                sort={sort}
                handleSort={handleSort}
            />
            <CardListing data={dataUsers} data2={allUsers} />
        </Box>
    );
};

export default FunnelReportListing;

import { Button } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { download, generalErrorHandler, numberToCurrency, queryParamsToParams, renderErrorButton, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';

// Icons
import { IValidationAlias, IValidationErrors, IValidationRules, validateData, validateOne } from '../../_utils/Validation';
import moment from 'moment';
import StatementAssignForm from './_components/StatementAssignForm';
import StatementCancelForm from './_components/StatementCancelForm';

interface IProps {
    isDeposit?: boolean;
}
export interface StatementFormState {
    id: number
    is_other: 0 | 1
    type: string
    date: string
    trailer: string
    amount: string
    description: string
    closing_id: string
    closing_label: string
    reason: string
    transaction_type: string
}

const StatementList = (props: IProps) => {
    const API_URL = process.env.REACT_APP_API_URL + '/bank-account-statement';
    const importRef = useRef<null | HTMLInputElement>(null)

    const [state, setState] = useState<StatementFormState>(initialState)
    const [error, setError] = useState<IValidationErrors>({})

    const [modalState, setModalState] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const validationRules: IValidationRules = useMemo(() => ({
        type: 'required',
        date: 'required',
        // trailer: 'required',
        amount: 'required',
        description: state.is_other ? 'required' : '',
        closing_id: state.is_other ? '' : 'required',
        transaction_type: state.type === 'DB' && state.is_other === 0 ? 'required' : '',
    }), [state.is_other, state.type])

    const columns = [
        {
            name: 'date',
            label: 'Tanggal',
            type: 'date',
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    `${moment(data).format("DD MMMM YYYY")}`
                )
            }
        },
        {
            name: 'trailer',
            label: 'Keterangan',
            type: 'string',
            filterable: true
        },
        {
            name: 'amount_cr',
            label: 'CR',
            type: 'number',
            filterable: true,
            render: (data: number | null, row: any) => (
                data ? numberToCurrency(Math.floor(data), "Rp.") : "-"
            )
        },
        {
            name: 'amount_db',
            label: 'DB',
            type: 'number',
            filterable: true,
            render: (data: number | null, row: any) => (
                data ? numberToCurrency(Math.floor(data), "Rp.") : "-"
            )
        },
        {
            name: 'mapping_type',
            label: 'Jenis Data',
            type: 'string',
            filterable: false
        },
        {
            name: 'code',
            label: 'Kode Mapping',
            type: 'string',
            filterable: true,
            render: (data: string) => (
                data || '-'
            )
        },
        {
            name: 'mapped_at',
            label: 'Action',
            filterable: false,
            render: (data: string, row: any) => (
                !row.mapped_at ?
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        onClick={() => assignStatement(row)}
                    >
                        Assign
                    </Button>
                    :
                    row.mapping_type !== 'DB - Mapped (auto)' &&
                    <Button
                        size='small'
                        variant='contained'
                        color='secondary'
                        onClick={() => cancelStatement(row)}
                    >
                        Cancel
                    </Button>
            )
        }
    ];

    useEffect(() => {
        if (!modalState) {
            setState(initialState)
        }
        // eslint-disable-next-line
    }, [modalState]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAutocompleteChange = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            closing_id: value.id.toString(),
            closing_label: value.label,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAutocompleteInputChange = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            closing_id: '',
            closing_label: '',
        }))
    }

    const assignStatement = (data: any) => {
        setState(prev => ({
            ...prev,
            id: data.id,
            type: data.type,
            date: data.date,
            trailer: data.trailer,
            amount: data.amount,
        }))
        setModalState(1)
    }

    const cancelStatement = (data: any) => {
        setState(prev => ({
            ...prev,
            id: data.id,
            type: data.type,
            date: data.date,
            trailer: data.trailer,
            amount: data.amount,
        }))
        setModalState(2)
    }

    useEffect(() => {
        if (modalState === 0) {
            setState(initialState)
        }
    }, [modalState])

    const submitData = () => {
        setIsLoading(true);
        DefaultAxios.put(`${API_URL}/${state.id}`, {
            ...state,
            is_other: !!state.is_other,
        })
            .then(res => {
                Swal.fire({
                    title: "Statement Assigned",
                    icon: 'success',
                    onAfterClose: () => {
                        setReDrawDatatable(new Date().getTime());
                        setModalState(0);
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleSubmit = () => {

        const { isValid, errors } = validateData(state, validationRules, validationAlias)
        setError(errors)

        if (!isValid) return

        if (state.type === 'DB' && state.is_other && !state.closing_id) {
            renderWarningButton('Anda tidak memilih Closing yang ingin di-assign. Lanjutkan?')
                .then(res => {
                    if (res.value) {
                        submitData()
                    }
                })

        } else {
            submitData()
        }
    }

    const handleSubmitCancel = () => {
        const { isValid, errors } = validateOne('reason', state, { reason: 'required' }, { reason: 'Reason' })
        setError(errors)

        if (!isValid) return

        setIsLoading(true);
        DefaultAxios.delete(`${API_URL}/${state.id}`, {
            params: {
                reason: state.reason,
            }
        })
            .then(res => {
                Swal.fire({
                    title: "Statement Canceled",
                    icon: 'success',
                    onAfterClose: () => {
                        setReDrawDatatable(new Date().getTime());
                        setModalState(0);
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const exportData = () => {
        setIsLoading(true)
        const params = queryParamsToParams();
        params['is_deposit'] = props.isDeposit ? '1' : '0';

        DefaultAxios.post(`${API_URL}/export`, params)
            .then(res => {
                window.open(`${API_URL}/export-file?code=${res.data}`, '_blank');
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const exportUnmapped = () => {
        setIsLoading(true)

        download(`${API_URL}/export-unmapped`, 'statement-unmapped.xlsx', () => {
            setIsLoading(false);
        }, err => {
            generalErrorHandler(err)
            setIsLoading(false)
        })
    }

    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target

        if (files && files[0]) {
            setIsLoading(true)

            const fd = new FormData()
            fd.append('file', files[0])

            DefaultAxios.post(`${API_URL}/import`, fd)
                .then(res => res.data)
                .then(data => {
                    renderToastSuccess('Berhasil import data')
                    setReDrawDatatable(prev => prev + 1)
                })
                .catch((err: any) => {
                    generalErrorHandler(err, { render400ErrorList: true })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            renderErrorButton('Belum ada file yang terpilih')
        }
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <StatementCancelForm
                isOpen={modalState === 2}
                onClose={() => setModalState(0)}
                state={state}
                error={error}
                handleChange={handleChange}
                handleSubmitCancel={handleSubmitCancel}
            />
            <StatementAssignForm
                isOpen={modalState === 1}
                onClose={() => setModalState(0)}
                state={state}
                setState={setState}
                error={error}
                handleChange={handleChange}
                handleAutocompleteChange={handleAutocompleteChange}
                handleAutocompleteInputChange={handleAutocompleteInputChange}
                handleSubmit={handleSubmit}
            />
            <Button
                variant='contained'
                color='primary'
                onClick={() => {
                    setReDrawDatatable(new Date().getTime());
                }}
            >
                Refresh
            </Button>
            <Button
                color="primary"
                variant="contained"
                onClick={exportData}
                style={{ float: 'right' }}
            >
                Export
            </Button>
            <Button
                color="primary"
                variant="contained"
                onClick={exportUnmapped}
                style={{ float: 'right' }}
                sx={{ mr: 1 }}
            >
                Export Unmapped This Month
            </Button>
            <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    importRef.current?.click()
                }}
                style={{ float: 'right' }}
                sx={{ mr: 1 }}
            >
                Import
            </Button>
            <input
                ref={importRef}
                type="file"
                accept='.xls, .xlsx'
                name='importFile'
                onChange={handleFile}
                style={{ width: 0, height: 0, display: 'none' }}
            />
            <br /><br />
            <DataTable
                url={props.isDeposit ? process.env.REACT_APP_API_URL + '/bank-account-statement/deposit' : API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </>
    );
}

export default StatementList;

const initialState: StatementFormState = {
    id: 0,
    is_other: 0,
    type: '',
    date: '',
    trailer: '',
    amount: '',
    description: '',
    closing_id: '',
    closing_label: '',
    reason: '',
    transaction_type: '',
}

const validationAlias: IValidationAlias = {
    type: 'Type',
    date: 'Tanggal',
    trailer: 'Keterangan',
    amount: 'Nominal',
    description: 'Notes',
    closing_id: 'Transaksi Closing',
    transaction_type: 'Jenis Transaksi',
}
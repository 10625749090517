import React, { useEffect, useState } from 'react'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { Dialog, Typography, Divider } from '@mui/material'
import { generalErrorHandler } from '../../../_utils/Helper'
import LoadingScreen from '../../../_components/LoadingScreen'
import { generateStyle } from '../../../_utils/DefaultStyle'

type Props = {
    scheduleId: number
    open: boolean
    onClose: () => void
}

interface IJobs {
    description: string
    done_at: string
    done_by: string
    done_notes: string
    id: number
}

const JHSScheduleJobsSchedule = (props: Props) => {
    const { Root, classes } = useStyles()
    const [jobs, setJobs] = useState<IJobs[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const fetchJobs = (id: number) => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/activity/schedule-job`, {
            params: { id }
        })
            .then(res => {
                setJobs(res.data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false)
                }, 100)
            })
    }

    useEffect(() => {
        if (props.open) {
            if (props.scheduleId) fetchJobs(props.scheduleId)
        } else {
            setTimeout(() => {
                setJobs([])
            }, 300)
        }
    }, [props.scheduleId, props.open])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="xs"
            PaperProps={{
                style: {
                    borderRadius: 15
                }
            }}
            fullWidth
        >
            <Root>
                <LoadingScreen open={isLoading} fullScreen />
                <div className={classes.jobDetailListRoot}>
                    <Typography variant='h5' fontWeight={'700'} sx={{ mb: 1 }}>List Jobs</Typography>
                    <Divider />
                    <div className={classes.jobContainer}>
                        {
                            jobs.length ?
                                jobs.map((data, idx) =>
                                    <div key={idx} className={classes.jobDetailList}>
                                        {data.description}
                                    </div>
                                ) :
                                <div className={classes.jobDetailList}>
                                    Tidak ada Jobs
                                </div>
                        }
                    </div>
                </div>
            </Root>
        </Dialog >
    )
}

const useStyles = generateStyle(theme => ({
    jobDetailListRoot: {
        padding: 20
    },
    jobContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5
    },
    jobDetailList: {
        padding: 8,
        backgroundColor: '#F7F8FA',
        borderRadius: 5,
        border: '1px solid lightgray ',
        textAlign: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center'
    },
}), "JHSScheduleJobsShedule")

export default JHSScheduleJobsSchedule
import { Grid } from '@mui/material'
import React from 'react'
import MenuBar from '../../_components/MenuBar'
import GeneralPerformance from '../_generalPerformance/GeneralPerformance'
import DashboardListingAdminMain from '../_dashboardListingAdmin/_components/DashboardListingAdminMain'

type Props = {}

const DashboardListingAcquisitionManager = (props: Props) => {
    return (
        <MenuBar title='Dashboard Listing Acquisition Manager'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <GeneralPerformance />
                </Grid>
                <Grid item xs={12}>
                    <DashboardListingAdminMain />
                </Grid>
            </Grid>
        </MenuBar>
    )
}

export default DashboardListingAcquisitionManager
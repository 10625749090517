import React, { useState } from 'react'

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable'
import LoadingScreen from '../../_components/LoadingScreen'
import { Switch, IconButton } from '@mui/material'

/**
 * Icons
 */
import VisibilityIcon from '@mui/icons-material/Visibility';

/**
 * Utils
 */
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper'
import DefaultAxios from '../../_utils/DefaultAxios'

interface UnitPhotoChecklistProps {

}

const UnitPhotoChecklist = (props: UnitPhotoChecklistProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const toggleChecklist = (row: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti checklist ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-photo-checklist/toggle-checklist`, { id: row.id })
                        .then(res => {
                            const checked = res.data
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +row.id) element['is_checklisted_photo'] = checked ? 1 : 0
                                return element
                            })
                            setRows(newRows)
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    const columns = [
        {
            name: 'code',
            label: 'Code',
            filterable: true,
            sortable: true,
            render: (data: string, row: any) => {
                return (
                    <div>
                            {data}
                        <IconButton color="primary" href={`${process.env.REACT_APP_MAIN_URL}/${row.code}`} target="_blank">
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                )
            }
        },
        {
            name: 'category',
            label: 'Category',
            filterable: true,
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: '0', value: 'Reguler' },
                { key: '2', value: 'Verified' },
                { key: '1', value: 'Star Listing' }
            ],
            render: (data: number) => {
                switch (data) {
                    case 0:
                        return 'Reguler'
                    case 1:
                        return 'Star Listing'
                    case 2:
                        return 'Verified'
                    case 3:
                        return 'SLOH'
                    default:
                        return 'N/A'
                }
            }
        },
        {
            name: 'apartment_name',
            label: 'Apartment Name',
            filterable: true,
            sortable: true,
            render: (data: string) => data || '-'
        },
        {
            name: 'area_name',
            label: 'Area Name',
            filterable: true,
            sortable: true,
            render: (data: string) => data || '-'
        },
        {
            name: 'pic_name',
            label: 'PIC Name',
            filterable: true,
            sortable: true,
            render: (data: string) => data || '-'
        },
        {
            name: 'is_checklisted_photo',
            label: 'Checklisted',
            filterable: true,
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: '1', value: 'Yes' },
                { key: '0', value: 'No' }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return <Switch
                    checked={data === 1}
                    onChange={() => toggleChecklist(row, setRows, rows)}
                    color="secondary"
                />
            }
        },
    ]

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <DataTable
                columns={columns}
                url={`${process.env.REACT_APP_API_URL}/unit-photo-checklist`}
            />
        </>
    )
}

export default UnitPhotoChecklist

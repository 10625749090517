import React, { Fragment, useEffect, useState } from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import CheckIcon from '@mui/icons-material/Check';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

interface Props {
    id: string;
    component: string
    withoutAction?: boolean
    withoutVerify?: boolean
    withoutEdit?: boolean
    /**
     * Overwrite the table value, currently only overwriting the Verify column
     */
    overWriteValue?: Partial<IData>
}

interface IData {
    id: string
    bank_type_name: string
    bank_type_id: string
    bank_type_label: string
    bank_type_temp: string
    behalf_name: string
    number: string
    is_primary: number
    is_verified: number
}

interface IState {
    id: string;
    bank: string;
    bank_label: string,
    name: string;
    no_rek: string;
    bank_type_temp: string;
}

interface IErrorState {
    bank: string;
    name: string;
    no_rek: string;
}

/**
 * 
 * props nanti bisa d ganti langsung files ny aja tergantung kemauan ny gmna nanti
 */
const BankTable = ({ id, component, withoutAction, withoutEdit, withoutVerify, overWriteValue }: Props) => {
    const initialState = {
        id: '',
        bank: '',
        bank_label: '',
        name: '',
        no_rek: '',
        bank_type_temp: ''
    };

    const initialErrorState = {
        bank: '',
        name: '',
        no_rek: '',
    };

    const [dataList, setData] = useState<IData[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [state, setState] = useState<IState>(initialState);
    const [errorState, setErrorState] = useState<IErrorState>(initialErrorState);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploadLoading, setIsUploadLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);

    const handleModalOpen = (edit?: boolean, idx?: number) => {
        if (edit && idx !== undefined) {
            setState(prev => ({
                ...prev,
                id: dataList[idx].id,
                bank: dataList[idx].bank_type_id,
                bank_label: dataList[idx].bank_type_name,
                name: dataList[idx].behalf_name,
                no_rek: dataList[idx].number,
                bank_type_temp: dataList[idx].bank_type_temp
            }))
        }
        setModalOpen(true)
    };

    const handleModalClose = () => {
        if (!isUploadLoading) {
            setModalOpen(false)
            setState(initialState)
        }
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!modalOpen) {
            resetForm();
        }
        // eslint-disable-next-line
    }, [modalOpen]);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/${component}/${id}/bank`)
            .then(res => {
                setData(res.data);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const resetForm = () => {
        setState(initialState);
        document.querySelectorAll('[type="file"]').forEach(el => {
            (el as HTMLInputElement).value = '';
        });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        setState(prevState => (
            {
                ...prevState,
                [name]: value
            }
        ));
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            bank: String(value.id),
            bank_label: value.label
        }))
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            bank: ''
        }))
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!state.bank) {
            newError.bank = 'Bank wajib diisi';
            isValid = false;
        }

        if (!state.name) {
            newError.name = 'Atas Nama wajib diisi';
            isValid = false;
        }

        if (!state.no_rek) {
            newError.no_rek = 'Nomor Rekening wajib diisi';
            isValid = false;
        }

        setErrorState(newError);
        return isValid;
    }

    const handleVerify = (idBank: string) => {
        renderWarningButton("Apakah anda yakin ingin Verify Bank?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true)
                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/${component}/${id}/bank/${idBank}/verify`)
                    .then(res => {
                        Swal.fire({
                            title: "Bank Verified",
                            icon: 'success',
                            onAfterClose: () => {
                                handleModalClose();
                                loadData();
                            },
                            timer: 1000
                        });
                    })
                    .catch(err => {
                        generalErrorHandler(err);
                    })
                    .finally(() => {
                        setIsLoading(false)
                        setIsUploadLoading(false);
                    })
            }
        });
    }

    const handleSubmit = (idState?: string) => {
        if (!checkValidation()) {
            return;
        }

        const dataLandlord = {
            landlord_id: id,
            bank_type_id: state.bank,
            behalf_name: state.name,
            number: state.no_rek
        }

        const dataCustomer = {
            customer_id: id,
            bank_type_id: state.bank,
            behalf_name: state.name,
            number: state.no_rek
        }

        setIsUploadLoading(true);
        if (idState) {
            DefaultAxios.put(`${process.env.REACT_APP_API_URL}/${component}/${id}/bank/${idState}`, component === 'landlord' ? dataLandlord : dataCustomer)
                .then(res => {
                    Swal.fire({
                        title: "Bank Submitted",
                        icon: 'success',
                        onAfterClose: () => {
                            handleModalClose();
                            loadData();
                        },
                        timer: 1000
                    });
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsUploadLoading(false);
                })
        } else {
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/${component}/${id}/bank`, component === 'landlord' ? dataLandlord : dataCustomer)
                .then(res => {
                    Swal.fire({
                        title: "Bank Submitted",
                        icon: 'success',
                        onAfterClose: () => {
                            handleModalClose();
                            loadData();
                        },
                        timer: 1000
                    });
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsUploadLoading(false);
                })
        }
    }

    const handlePrimary = (bankId: string) => {
        renderWarningButton("Apakah anda yakin mengganti bank utama?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true)
                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/${component}/${id}/bank/${bankId}/toggle-primary`, { is_primary: 1 })
                    .then(res => {
                        Swal.fire({
                            title: "Bank berhasil diinput",
                            icon: 'success',
                            onAfterClose: () => {
                                loadData();
                            },
                            timer: 1000
                        });
                    })
                    .catch(err => {
                        generalErrorHandler(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }
        });
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                color="primary"
                variant="contained"
                style={{ marginBottom: '25px', float: 'right' }}
                onClick={() => handleModalOpen()}
            >
                Add Bank
            </Button>
            <Dialog
                open={!!previewImage}
                onClose={() => { setPreviewImage(undefined) }}
                scroll="body"
                maxWidth={false}
            >
                <img src={previewImage} alt="preview" />
            </Dialog>
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                maxWidth="md"
            >
                <LoadingScreen open={isUploadLoading} />
                <DialogTitle>Tambah Bank</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                url={process.env.REACT_APP_API_URL + '/admin/autocomplete/bank-type'}
                                initialQuery={state.bank_label}
                                label="Bank"
                                name="bank"
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                errorText={errorState.bank}
                            />
                        </Grid>

                        {
                            !!state.id && !!state.bank_type_temp &&
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Bank Type Temp"
                                    name="bank_type_temp"
                                    value={state.bank_type_temp}
                                    disabled
                                />
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Atas Nama"
                                name="name"
                                value={state.name}
                                onChange={handleChange}
                                error={!!errorState.name}
                                helperText={errorState.name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="number"
                                label="Nomor Rekening"
                                name="no_rek"
                                value={state.no_rek}
                                onChange={handleChange}
                                error={!!errorState.no_rek}
                                helperText={errorState.no_rek}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => handleSubmit(state.id)}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nama Bank</TableCell>
                            <TableCell>Atas Nama</TableCell>
                            <TableCell>Nomor Rekening</TableCell>
                            <TableCell>Verify</TableCell>
                            {
                                !withoutAction &&
                                <TableCell>Action</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dataList.length
                                ? dataList.map((data, idx) => (
                                    <TableRow key={data.id}>
                                        <TableCell>
                                            <Grid container style={{ alignItems: 'center' }}>
                                                <Grid style={{ marginRight: 2 }}>
                                                    {data.is_primary === 1 && <CheckIcon color='primary' />}

                                                </Grid>
                                                <Grid>
                                                    {data.bank_type_name || data.bank_type_label}

                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>{data.behalf_name}</TableCell>
                                        <TableCell>{data.number}</TableCell>
                                        <TableCell>{(overWriteValue?.is_verified !== undefined ? overWriteValue.is_verified : data.is_verified) ? 'YES' : 'NO'}</TableCell>
                                        {
                                            !withoutAction &&
                                            <TableCell>
                                                <Grid container>
                                                    {
                                                        !data.is_verified &&
                                                        <>
                                                            {
                                                                !withoutEdit &&
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    size="small"
                                                                    style={{ marginRight: 5 }}
                                                                    onClick={() => handleModalOpen(true, idx)}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            }
                                                            {
                                                                !withoutVerify &&
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    size="small"
                                                                    style={{ marginRight: 5 }}
                                                                    onClick={() => handleVerify(data.id)}
                                                                >
                                                                    Verify
                                                                </Button>
                                                            }
                                                        </>
                                                    }

                                                    {
                                                        data.is_primary !== 1 &&
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            size="small"
                                                            onClick={() => handlePrimary(data.id)}
                                                        >
                                                            Set Primary
                                                        </Button>
                                                    }
                                                </Grid>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))
                                : <TableRow>
                                    <TableCell colSpan={3}>No Data Available</TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    );
}

export default BankTable;
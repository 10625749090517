import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete'

/**
 * Utils
 */
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, renderToastSuccess } from '../../../_utils/Helper'

interface AccountTypeModalProps {
    isOpen: boolean
    selectedData?: any
    onClose: () => void
    onSuccess: () => void
}

interface State {
    type: 'user' | 'bm'
    bm_apartment_id: null | number
    bm_apartment_label: string
}

const AccountTypeModal = (props: AccountTypeModalProps) => {
    const [state, setState] = useState<State>(defaultState)
    const [error, setError] = useState<IValidationErrors>({})
    const [isLoading, setIsLoading] = useState(false)

    const validationRules: IValidationRules = useMemo(() => ({
        bm_apartment_id: state.type === 'bm' ? 'required' : '',
    }), [state.type])
    const validationAlias: IValidationAlias = {
        bm_apartment_id: 'Apartemen',
    }

    useEffect(() => {
        if (props.selectedData?.type) {
            setState(prev => ({
                ...prev,
                type: props.selectedData.type,
            }))
        }

        if (props.selectedData?.bm_apartment_id && props.selectedData?.apartment_name) {
            setState(prev => ({
                ...prev,
                bm_apartment_id: props.selectedData.bm_apartment_id,
                bm_apartment_label: props.selectedData.apartment_name,
            }))
        }

        if (props.selectedData === null) {
            setState(defaultState)
        }
    }, [props.selectedData])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setState(prev => ({
            ...prev,
            type: value as 'user' | 'bm',
        }))
    }

    const handleAutocompleteChange = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            bm_apartment_id: value.id,
            bm_apartment_label: value.label,
        }))

        setError(prev => ({
            ...prev,
            bm_apartment_id: '',
        }))
    }

    const handleAutocompleteInputChange = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            bm_apartment_id: null,
            bm_apartment_label: '',
        }))

        setError(prev => ({
            ...prev,
            bm_apartment_id: '',
        }))
    }

    const onSubmit = () => {
        const { isValid, errors } = validateData(state, validationRules, validationAlias)
        setError(errors)

        if (isValid) {
            setIsLoading(true)

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/account/change-type`, {
                id: props.selectedData.id,
                type: state.type,
                bm_apartment_id: state.bm_apartment_id,
            })
                .then(res => res.data)
                .then(data => {
                    setState(defaultState)
                    renderToastSuccess('Berhasil mengubah type account')
                    props.onSuccess()
                    props.onClose()
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>Form Group</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ marginTop: 0 }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Type"
                            name="type"
                            value={state.type}
                            onChange={handleChange}
                            disabled={isLoading}
                            select
                        >
                            <MenuItem value='user'>User</MenuItem>
                            <MenuItem value='bm'>BM</MenuItem>
                        </TextField>
                    </Grid>
                    {
                        state.type === 'bm' &&
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                url={process.env.REACT_APP_API_URL + '/autocomplete/apartment'}
                                label="Apartment"
                                name="bm_apartment_id"
                                initialQuery={state.bm_apartment_label}
                                onChange={handleAutocompleteChange}
                                onInputChange={handleAutocompleteInputChange}
                                errorText={error.bm_apartment_id}
                                disabled={isLoading}
                            />
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onSubmit}
                    disabled={isLoading}
                >
                    {
                        isLoading ?
                            <CircularProgress color='primary' size={16} />
                            : 'Submit'
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const defaultState: State = {
    type: 'user',
    bm_apartment_id: null,
    bm_apartment_label: '',
}

export default AccountTypeModal

import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
    Button,
    Collapse,
    // Collapse
} from '@mui/material';
import Chip from '@mui/material/Chip';

/**
 * Components
 */
import ModalDetail from '../../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../../_components/_modalDetail/ModalSection';
import SimplePreview from '../../../_components/SimplePreview';
import ActivityLog from './ActivityLog';


/**
 * Utils
 */
import Swal from 'sweetalert2';
import DOMPurify from 'dompurify';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../../_utils/Helper';
import { PermissionContext } from '../../../_contexts/PermissionContext';

interface IProps {
    open: boolean;
    id: number;
    onClose: Function;
}

interface IState {
    code: string
    datetime: string
    checkin_at: string
    latitude: string
    longitude: string
    closing_code: string
    created_at: string
    verified_at: string
    verified_by: string
    activity: string
    activity_name: string
    additional_info: string
    apartment_id: number
    apartment_name: string
    client_name: string
    client_phone: string
    created_by: string
    date: string
    enquiry_id: number
    enquiry_label: string
    id: number
    notes: string
    pic_id: number
    pic_name: string
    selfie: string
    unit_code: string
    units: []
}

const ActivityModalDetail = (props: IProps) => {
    const permissions = useContext(PermissionContext)
    const [loading, setLoading] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showSelfie, setShowSelfie] = useState(false);
    const [modalLogOpen, setModalLogOpen] = useState(false);

    const handleModalLogOpen = () => setModalLogOpen(true);
    const handleModalLogClose = () => setModalLogOpen(false);

    const handleLog = () => {
        handleModalLogOpen();
    }

    const [state, setState] = useState<IState>({
        code: "",
        datetime: "",
        closing_code: "",
        checkin_at: "",
        latitude: "",
        longitude: "",
        created_at: "",
        verified_at: "",
        verified_by: "",
        activity: "",
        activity_name: "",
        additional_info: "",
        apartment_id: 0,
        apartment_name: "",
        client_name: "",
        client_phone: "",
        created_by: "",
        date: "",
        enquiry_id: 0,
        enquiry_label: "",
        id: 0,
        notes: "",
        pic_id: 0,
        pic_name: "",
        selfie: "",
        unit_code: "",
        units: []
    });

    useEffect(() => {
        if (props.open) {
            loadData();
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [props]);

    const loadData = () => {
        setLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/activity/${props.id}`)
            .then(res => {
                const data = res.data;
                setState(data.activity);
            })
            .catch((err) => {
                generalErrorHandler(err);
                props.onClose();
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const renderWa = (data: any) => {
        let regex = /^08/;
        if (data.match(regex)) {
            data = data.replace(regex, "628")
        }
        let phoneNumber = data;
        let link = 'https://api.whatsapp.com/send?phone=' + phoneNumber;
        return <a href={link}>{data}</a>
    }

    const handleDelete = (id: number) => {
        renderWarningButton("Apakah anda yakin ingin menghapus data ini?")
            .then((result: any) => {
                if (result.value) {
                    setLoading(true);
                    DefaultAxios
                        .delete(`${process.env.REACT_APP_API_URL}/activity/${id}`)
                        .then(res => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000
                            }).then(() => {
                                props.onClose(true)
                            })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setLoading(false);
                        })
                }
            })
    }

    const handleVerify = (id: number) => {
        renderWarningButton("Apakah anda yakin ingin verify data ini?")
            .then((result: any) => {
                if (result.value) {
                    setLoading(true);
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/activity/${id}/verify`)
                        .then(res => {
                            renderToastSuccess("Berhasil mem-verify")
                            loadData()
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setLoading(false);
                        })
                }
            })
    }

    return (
        <>
            <ActivityLog
                open={modalLogOpen}
                onClose={handleModalLogClose}
                activityId={props.id}
            />
            <SimplePreview
                isPreviewOpen={isPreviewOpen}
                url={state.selfie}
                onClose={() => setIsPreviewOpen(false)}
            />
            <ModalDetail
                open={props.open}
                isLoading={loading}
                onCloseModal={() => props.onClose()}
                title={`${state.code} - ${state.activity_name}`}
                showEditButton={true}
                editButtonLink={`/activity/edit/${props.id}`}
            >
                <>
                    <ModalSection
                        title="Informasi Dasar"
                        xs={12}
                        md={6}
                        content={[
                            ['Jadwal Activity', `${state.datetime}`],
                            ['PIC', state.pic_name],
                            ['Verified At', !!state.verified_at ? <><Chip color="primary" label="Yes" /> by {state.verified_by} at {state.verified_at} </> : <Chip label="No" />],
                            ['Created At', `${state.created_at} by ${state.created_by}`],
                            state.checkin_at ? ['Check In', state.checkin_at] : [],
                            state.closing_code ? ['Kode Closing', state.closing_code] : [],
                            state.latitude ? ['Posisi CheckIn', <>
                                <Button variant="outlined" color="primary" href={`https://www.google.com/maps/@${state.latitude},${state.longitude}`} target="_blank">Cek Google Maps</Button>
                            </>] : [],
                        ]}
                    />

                    <ModalSection
                        title="Informasi Tambahan"
                        xs={12}
                        md={6}
                        content={[
                            ['Nama Client', state.client_name],
                            ['No HP Client', state.client_phone ? renderWa(state.client_phone) : "-"],
                            ['Apartment', state.apartment_name],
                            ['Unit', state.unit_code],
                            ['Notes', state.notes],
                            ['Additional Info', <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(state.additional_info || '-') }} />],
                        ]}
                    />
                    <ModalSection
                        title="Selfie"
                        xs={12}
                        md={6}
                        content={[
                            ['', state.selfie ?
                                <>
                                    <Button variant="text" color="primary" onClick={() => setShowSelfie(!showSelfie)}>{showSelfie ? 'Hide' : 'Show'} Selfie</Button>
                                    <Collapse in={showSelfie}>
                                        <div onClick={() => setIsPreviewOpen(true)}>
                                            <img
                                                src={state.selfie}
                                                alt="bukti pembayaran"
                                                height="250"
                                                width="250"
                                                style={{
                                                    objectFit: 'cover',
                                                    objectPosition: 'center',
                                                }}
                                            />
                                        </div>
                                    </Collapse>
                                </>
                                :
                                <p>
                                    Tidak ada Selfie untuk Activity ini
                                </p>]
                        ]}
                    />

                    <Grid xs={12} container direction="row-reverse" style={{ marginBottom: "10px" }}>
                        {(permissions['activity.advisor'] || permissions['activity.delete']) &&
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={() => handleDelete(props.id)}
                                >
                                    Delete Activity
                                </Button>
                            </Grid>
                        }

                        {((permissions['activity.verify'] || permissions['activity.verify-collection']) && !state.verified_at) &&
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => handleVerify(props.id)}
                                    style={{ marginRight: '10px' }}
                                >
                                    Verify Activity
                                </Button>
                            </Grid>
                        }

                        {permissions['activity.see-log'] &&
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => handleLog()}
                                    style={{ marginRight: '10px' }}
                                >
                                    Log
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </>
            </ModalDetail>
        </>
    );
}

export default ActivityModalDetail;
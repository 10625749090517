import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, ButtonGroup, Grid } from '@mui/material'

import BasicAreaForm from './_components/BasicAreaForm'
import ArticleAreaForm from './_components/ArticleAreaForm'
import FAQForm from '../../_components/_form/FAQForm'
import SLPForm from '../../_components/_form/SLPForm'
import { PermissionContext } from '../../_contexts/PermissionContext'

interface IParams extends Record<string, string | undefined> {
    id?: string
}

const AreaForm = () => {
    const params = useParams<IParams>()
    const [formType, setFormType] = useState<'basic' | 'article' | 'faq' | 'slp'>('basic')
    const permissions = useContext(PermissionContext)

    const renderForm = () => {
        switch (formType) {
            case 'basic':
                return (
                    <BasicAreaForm
                        id={params.id}
                    />
                )
            case 'article':
                if (!params.id) return null

                return (
                    <ArticleAreaForm
                        id={params.id}
                    />
                )

            case 'slp':
                if (!params.id) return null

                return (
                    <SLPForm
                        id={+params.id}
                        type='area'
                    />
                )
            case 'faq':
                if (!params.id) return null

                return (
                    <FAQForm
                        url={`${process.env.REACT_APP_API_URL}/area/${params.id}/faq`}
                    />
                )
        }
    }

    return (
        <React.Fragment>
            {
                params.id &&
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <ButtonGroup
                            color="primary"
                            aria-label="outlined primary button group"
                            fullWidth
                            style={{
                                marginBottom: 24,
                            }}
                        >
                            <Button
                                variant={formType === 'basic' ? 'contained' : 'outlined'}
                                onClick={() => setFormType('basic')}
                            >
                                Basic Info
                            </Button>
                            <Button
                                variant={formType === 'article' ? 'contained' : 'outlined'}
                                onClick={() => setFormType('article')}
                            >
                                Article
                            </Button>
                            {
                                permissions['slp.manage'] ?
                                    <Button
                                        variant={formType === 'slp' ? 'contained' : 'outlined'}
                                        onClick={() => setFormType('slp')}
                                    >
                                        SLP
                                    </Button>
                                    : null
                            }
                            <Button
                                variant={formType === 'faq' ? 'contained' : 'outlined'}
                                onClick={() => setFormType('faq')}
                            >
                                FAQ
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            }
            {renderForm()}
        </React.Fragment>
    )
}

export default AreaForm

import React from 'react';
import {
    Dialog,
    Button,
    DialogContent,
    DialogTitle,
    DialogActions
} from '@mui/material';

import TextArea from '../../../_components/_form/TextArea';

interface Props {
    open: boolean;
    onClose: () => void;
    comment: string;
    onCommentChange: (e: any) => void;
    onHandleSubmitVoid: () => void;
}

const VoidDocusignModal = (props: Props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>Void Contract</DialogTitle>
            <DialogContent>
                <TextArea
                    name="comment"
                    label="Comment"
                    placeholder="Alasan Kontrak Void"
                    value={props.comment}
                    onChange={props.onCommentChange}
                />
                <span>*Alasan akan dikirimkan juga kepada email tenant dan owner.</span>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={props.onHandleSubmitVoid}
                >
                    Submit
            </Button>
            </DialogActions>
        </Dialog>
    );
}

export default VoidDocusignModal;
import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { useParams, useNavigate } from "react-router-dom";

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import TextArea from '../../_components/_form/TextArea';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertIndonesiaPhoneNumber, generalErrorHandler } from '../../_utils/Helper';
import AsyncAutoComplete from '../../_components/_form/AsyncAutoComplete';

interface IParams extends Record<string, string | undefined> {
    id?: string
}

export interface IState {
    id: string
    name: string
    phone: string
    assigned_pic_id: string
    assigned_pic_label: string
    notes: string
}

interface IProps {
    isEdit?: boolean,
    picId?: string
}

const PICFormMain = (props: IProps) => {
    const params = useParams<IParams>()
    const navigate =  useNavigate();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState<IState>(initialState)


    const submitForm = (isUpdate: boolean = false) => {
        setLoading(true)
        DefaultAxios[isUpdate ? 'patch': 'post'](`${process.env.REACT_APP_API_URL}/pic-landlord/${isUpdate ? params.id : ''}`, state)
            .then(res => res.data)
            .then(res => {
                if (res){
                    Swal.fire("Success", isUpdate ? 'Success Update PIC Landlord': 'Success add PIC Landlord', 'success')
                    navigate('/pic-landlord/')
                }
            })
            .catch((err) => generalErrorHandler(err))
            .finally(() => setLoading(false))
    }

    const handleChangeAutocomplete = (name: string, value: any) => {
        setState(prev => ({
            ...prev,
            assigned_pic_id: value.id,
            assigned_pic_label: value.label
        }))
    }

    const handleChangeAutoCompleteInputChanged = (name: string, value: any) => {
        setState(prev => ({
            ...prev,
            assigned_pic_id: '',
            assigned_pic_label: ''
        }))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name =  e.target.name
        let value = e.target.value

        if (name === 'phone') {
            value = convertIndonesiaPhoneNumber(value)
        }

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const fetchData = () => {
        setLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/pic-landlord/${params.id}`)
            .then(res => res.data)
            .then(res => res && setState(res))
            .catch(err => generalErrorHandler(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (props.isEdit && props.picId) {
            fetchData()
        }

        // eslint-disable-next-line
    },[props.isEdit, props.picId])

    useEffect(() => {
        document.body.style.backgroundColor = '#eee';
        let timeout: any;
        return () => {
            clearTimeout(timeout);
            document.body.style.backgroundColor = '#fff';
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Grid container spacing={2}>
            <LoadingScreen open={loading} fullScreen />
            <Grid item xs={12}>
                <Typography variant="h4">
                    Informasi Dasar
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <TextField 
                    name='name'
                    value={state.name}
                    onChange={handleChange}
                    label="Nama"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12}>
                <TextField 
                    name='phone'
                    value={state.phone}
                    onChange={handleChange}
                    label="No. HP"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12}>
                <AsyncAutoComplete
                    url={`${process.env.REACT_APP_API_URL}/autocomplete/user/tenant-relation`}
                    name='assigned_pic_id'
                    label='Assigned PIC'
                    initialQuery={state.assigned_pic_label ? state.assigned_pic_label : ''}
                    onChange={handleChangeAutocomplete}
                    onInputChange={handleChangeAutoCompleteInputChanged}
                    clearable
                    onClear={() => handleChangeAutoCompleteInputChanged('assigned_pic_id', '')}
                />
            </Grid>

            <Grid item xs={12}>
                <TextArea
                    name='notes'
                    label='Notes'
                    value={state.notes}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={12} style={{display: 'flex', alignItems: 'flex-end'}}>
                <Button variant="contained" color="primary" onClick={() => submitForm(props.isEdit)}>
                    {
                        props.isEdit ? 
                        'Update' :
                        'Submit'
                    }
                </Button>
            </Grid>
        </Grid>
    );
}

export default PICFormMain;

export const initialState = {
    id: '',
    name: '',
    phone: '',
    assigned_pic_id: '',
    assigned_pic_label: '',
    notes: '',
}
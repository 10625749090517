import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Button, TextField, ButtonGroup, Theme } from '@mui/material'

import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import { useNavigate } from 'react-router-dom'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper'
import Swal from 'sweetalert2'
import LoadingScreen from '../../../_components/LoadingScreen'
import TextEditor from '../../../_components/_form/TextEditor'
import { generateStyle } from '../../../_utils/DefaultStyle'

interface IProps {
    id?: string
}

interface IHighlightFormState {
    total_unit: string
    floor: string
    tower: string
    developer: string
    building_finished: string
    building_condition: string
    building_condition_en: string
    condition: string
    condition_en: string
    more_information: string
    more_information_en: string
    facilities: string
    facilities_en: string
    best_apartment_for: string
    best_apartment_for_en: string
}

const ApartmentHighlightForm = (props: IProps) => {
    const navigate = useNavigate()
    const { Root, classes } = useStyles()

    const [isLoading, setIsLoading] = useState(false)
    const [lang, setLang] = useState<'en' | 'id'>('id')

    const [state, setState] = useState<IHighlightFormState>(initialState)
    const [error, setError] = useState<IValidationErrors>({})


    const validationRules: IValidationRules = useMemo(() => ({
        // total_unit: 'required',
        // floor: 'required',
        // tower: 'required',
        // developer: 'required',
        // building_finished: 'required',
        // building_condition: 'required',
        // building_condition_en: 'required',
        // condition: 'required',
        // condition_en: 'required',
        // more_information: 'required',
        // more_information_en: 'required',
        // facilities: 'required',
        // facilities_en: 'required',
        // best_apartment_for: 'required',
        // best_apartment_for_en: 'required',
        // eslint-disable-next-line
    }), [props.id])

    useEffect(() => {
        if (props.id) {
            setIsLoading(true)
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/apartment/${props.id}/highlight`)
                .then(res => res.data)
                .then(data => {
                    setState({
                        ...initialState,
                        ...data,
                        primary_image: null,
                    })
                })
                .catch(error => {
                    generalErrorHandler(error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [props.id])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setState(prev => ({
            ...prev,
            [name]: value
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        const { errors } = validateData({ [name]: value }, validationRules, validationAlias)

        setError(errors)
    }

    const handleEditorChange = (name: string, value: string) => {
        setState(prev => ({
            ...prev,
            [name]: value
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const submit = () => {
        const { isValid, errors } = validateData(state, validationRules, validationAlias)
        setError(errors)

        if (isValid) {
            setIsLoading(true)

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/apartment/${props.id}/highlight`, state)
                .then(res => res.data)
                .then(data => {
                    Swal.fire({
                        title: "Submit Highlight berhasil",
                        icon: 'success',
                        timer: 1000
                    })
                        .then(() => {
                            navigate('/apartment')
                        })
                })
                .catch(error => {
                    generalErrorHandler(error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            window.scrollTo({
                top: 0,
            })
        }
    }

    return (
        <Grid item xs={12}>
            <Root>
                <LoadingScreen open={isLoading} fullScreen />
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            placeholder="Total Unit"
                            label="Total Unit"
                            name="total_unit"
                            value={state.total_unit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!error.total_unit}
                            helperText={error.total_unit}
                            fullWidth
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            placeholder="Lantai"
                            label="Lantai"
                            name="floor"
                            value={state.floor}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!error.floor}
                            helperText={error.floor}
                            fullWidth
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined"
                            placeholder="List Tower"
                            label="List Tower"
                            name="tower"
                            value={state.tower}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!error.tower}
                            helperText={error.tower}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined"
                            placeholder="Pengembang"
                            label="Pengembang"
                            name="developer"
                            value={state.developer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!error.developer}
                            helperText={error.developer}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined"
                            placeholder="Bangunan Selesai"
                            label="Bangunan Selesai"
                            name="building_finished"
                            value={state.building_finished}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!error.building_finished}
                            helperText={error.building_finished}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.highlightContainer}>
                            <ButtonGroup
                                color="primary"
                                style={{
                                    marginBottom: 16,
                                }}
                            >
                                <Button
                                    variant={lang === 'id' ? 'contained' : 'outlined'}
                                    onClick={() => setLang('id')}
                                >
                                    Indonesia
                                </Button>
                                <Button
                                    variant={lang === 'en' ? 'contained' : 'outlined'}
                                    onClick={() => setLang('en')}
                                >
                                    English
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            placeholder="Kondisi Bangunan"
                            label="Kondisi Bangunan"
                            name={lang === 'en' ? 'building_condition_en' : 'building_condition'}
                            value={lang === 'en' ? state.building_condition_en : state.building_condition}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            placeholder="Kondisi"
                            label="Kondisi"
                            name={lang === 'en' ? 'condition_en' : 'condition'}
                            value={lang === 'en' ? state.condition_en : state.condition}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <span className="label">
                            Informasi Tambahan
                        </span>
                        <TextEditor
                            name={lang === 'en' ? 'more_information_en' : 'more_information'}
                            value={lang === 'en' ? state.more_information_en : state.more_information}
                            onChange={handleEditorChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <span className="label">
                            Fasilitas
                        </span>
                        <TextEditor
                            name={lang === 'en' ? 'facilities_en' : 'facilities'}
                            value={lang === 'en' ? state.facilities_en : state.facilities}
                            onChange={handleEditorChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <span className="label">
                            Apartemen ini cocok
                        </span>
                        <TextEditor
                            name={lang === 'en' ? 'best_apartment_for_en' : 'best_apartment_for'}
                            value={lang === 'en' ? state.best_apartment_for_en : state.best_apartment_for}
                            onChange={handleEditorChange}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={4}
                    className={classes.metaContainer}
                >
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submit}
                            disabled={isLoading}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Root>
        </Grid>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > .label': {
            fontSize: 16,
            color: '#484848',
            marginBottom: 12,
        },
        '& > .error': {
            color: theme.palette.error.main,
            marginTop: 12,
        }
    },
    line: {
        display: 'block',
        width: '100%',
        height: 1,
        backgroundColor: '#cacaca',
        margin: '16px 0px 16px',
    },
    metaContainer: {
        '& .title': {
            fontSize: 16,
            fontWeight: '500',
        },
        '& .subtitle': {
            fontWeight: '500',
        }
    },
    highlightContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > .label': {
            fontSize: 16,
            color: '#484848',
            marginBottom: 12,
        },
        '& > .error': {
            color: theme.palette.error.main,
            marginTop: 12,
        }
    }
}), "ApartmentHighlight_Form"
)

const validationAlias: IValidationAlias = {
    // total_unit: 'required',
    // floor: 'required',
    // tower: 'required',
    // developer: 'required',
    // building_finished: 'required',
    // building_condition: 'required',
    // building_condition_en: 'required',
    // condition: 'required',
    // condition_en: 'required',
    // more_information: 'required',
    // more_information_en: 'required',
    // facilities: 'required',
    // facilities_en: 'required',
    // best_apartment_for: 'required',
    // best_apartment_for_en: 'required',
}

const initialState: IHighlightFormState = {
    total_unit: '',
    floor: '',
    tower: '',
    developer: '',
    building_finished: '',
    building_condition: '',
    building_condition_en: '',
    condition: '',
    condition_en: '',
    more_information: '',
    more_information_en: '',
    facilities: '',
    facilities_en: '',
    best_apartment_for: '',
    best_apartment_for_en: '',
}

export default ApartmentHighlightForm

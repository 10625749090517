import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { Button, Theme, Link, Grid, TextField, Divider } from '@mui/material';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LandlordModalDetail from './LandlordModalDetail';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    landlord_id?: string
}
interface IParams extends Record<string, string | undefined> {
    landlord_id?: string
}

interface ILandlord {
    id: number;
    name: string;
    unit_codes: string[];
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    landlordCard: {
        height: '100%',
        borderRadius: 20,
        boxShadow: '3px 3px 10px #52575d1a',
        padding: '22px 33px',
        backgroundColor: 'white',
        alignSelf: 'start',
        cursor: 'pointer'
    },
    detailRoot: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        gap: '20px',

        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        },
    },
    landlordName: {
        color: '#3D3D3D',
        fontSize: 20,
        fontWeight: 700,
    },
    divider: {
        borderColor: '#EBF2F7',
        marginTop: 13,
        marginBottom: 11,
    },
}), "Landlord_list"
)

// const columns = [
//     {
//         name: 'name', label: 'Name', filterable: true, render: (data: any, row: any) => {
//             return (
//                 <Link
//                     style={{ cursor: "pointer" }}
//                     component={RouterLink}
//                     to={`/landlord/${row.id}`}
//                 // onClick={() => { openModalDashboard(row); console.log(row) }
//                 // }
//                 >
//                     {data}
//                 </Link>
//             );
//         }
//     },
//     { name: 'phone', label: 'Phone', filterable: true },
//     { name: 'assigned_pic_name', label: 'Assigned TR', filterable: true },
//     { name: 'entrusted_unit', label: 'Unit Dititipkan', filterable: true },
//     { name: 'entrusted_key', label: 'Kunci Dititipkan', filterable: true },
//     {
//         name: 'created_at',
//         label: 'Created Date',
//         filterable: true,
//         type: 'date',
//         render: (data: any) => {
//             return dateFormat(data, 'DD-MM-YYYY HH:mm:ss');
//         }
//     },
// ]

const columns2 = [
    {
        name: 'name', label: 'Name', filterable: true, render: (data: any, row: any) => {
            return (
                <Link
                    style={{ cursor: "pointer" }}
                    component={RouterLink}
                    to={`/landlord/${row.id}`}
                // onClick={() => { openModalDashboard(row); console.log(row) }
                // }
                >
                    {data}
                </Link>
            );
        }
    },
    // { name: 'phone', label: 'Phone', filterable: true },
    { name: 'pic_landlord_name', label: 'PIC Name', filterable: true, render: ((data: any) => data || '-') },
    { name: 'pic_landlord_phone', label: 'PIC Phone', filterable: true, render: ((data: any) => data || '-') },
]

const LandlordList = (props: IProps) => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const DT_API_URL = process.env.REACT_APP_API_URL + '/landlord';

    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [selectedId, setSelectedId] = useState<string>('');
    const [modalDashboardState, setModalDashboardState] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [landlords, setLandlords] = useState<ILandlord[]>([]);

    const params = useParams<IParams>()
    const navigate = useNavigate();
    const handleOpenDashboard = () => setModalDashboardState(true);
    const handleCloseDashboard = () => {
        setModalDashboardState(false)
        window.history.pushState([], 'Landlord List', `/landlord/`);
    };

    const submitSuccess = () => {
        setReDrawDatatable(new Date().getTime());
    }

    useEffect(() => {
        if (typeof params.landlord_id !== "undefined") {
            setSelectedId(params.landlord_id);
            handleOpenDashboard();
        }
    }, [params]);

    const handleSearch = () => {
        if (searchValue) {
            setLandlords([]);

            setIsLoading(true);
            DefaultAxios.get(`${DT_API_URL}/search-data`, { params: { search: searchValue } })
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setLandlords(res.data);
                    } else {
                        navigate(`/landlord/${res.data}`);
                    }
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <LandlordModalDetail
                open={modalDashboardState}
                onClose={handleCloseDashboard}
                permissions={permissions}
                mode='detail'
                onSubmitSuccess={submitSuccess}
                landlordId={selectedId}
            />

            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => navigate('/landlord/add')}
            >
                Add Landlord
            </Button>
            {
                permissions['landlord.admin']
                    ? <DataTable
                        reDraw={reDrawDatatable}
                        url={DT_API_URL}
                        columns={columns2}
                    />
                    : <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Search"
                                value={searchValue}
                                placeholder="Phone / Email / Unit Code"
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                            >
                                Search
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.detailRoot}>
                                {
                                    landlords.length > 0
                                        ? landlords.map(landlord => {
                                            return <Grid item xs={12} key={landlord.id}>
                                                <div
                                                    className={classes.landlordCard}
                                                    onClick={() => navigate(`/landlord/${landlord.id}`)}
                                                >
                                                    <span className={classes.landlordName}>
                                                        {landlord.name}
                                                    </span>
                                                    <Divider className={classes.divider} />
                                                    {
                                                        landlord.unit_codes.length ?
                                                            landlord.unit_codes.length > 5 ? <span>{landlord.unit_codes.slice(0, 5).join(', ')} {`and ${landlord.unit_codes.length - 5} more`}</span>
                                                                : <span>{landlord.unit_codes.join(', ')}</span>
                                                            : <span>-</span>
                                                    }
                                                </div>
                                            </Grid>
                                        })
                                        : null
                                }
                            </div>
                        </Grid>


                    </Grid>
            }
        </Root>
    );
}

export default LandlordList;
import DefaultAxios from "./DefaultAxios";
import Swal from 'sweetalert2';
import moment from 'moment';
import Cookies from "js-cookie";
import { PAID_BY_OPTIONS } from "./ConstData";

export function converNumberFormat(number_to_convert: string) {
    let converted_number = number_to_convert.toString();
    converted_number = converted_number.replace('.00', '');
    let number = converted_number.replace(/[^\d]/g, '');
    let num = number.replace(/,/gi, "");
    let num2 = num.split(/(?=(?:\d{3})+$)/).join(",");
    return num2;
}

export function isMobile() {
    if (window.matchMedia("only screen and (max-width: 760px)").matches) {
        return true;
    } else {
        return false;
    }
}

export function convertNumber(number: string, prefix?: string, with_decimal?: boolean) {
    let numberString;
    let decimal;

    //With Decimal
    if (with_decimal) {
        number = Number(number).toFixed(2);

        const exploded_number = number.split(".");
        numberString = parseInt(exploded_number[0].replace(/[^,\d]/g, '')).toString();
        decimal = typeof exploded_number[1] !== 'undefined' ? exploded_number[1] : "";
    } else {
        numberString = parseInt(number.replace(/[^,\d]/g, '')).toString();
    }

    const split = numberString.split(',')
    const sisa = split[0].length % 3
    let rupiah = split[0].substr(0, sisa)
    const ribuan = split[0].substr(sisa).match(/\d{3}/gi)

    if (ribuan) {
        const separator = sisa ? '.' : ''
        rupiah = rupiah + separator + ribuan.join('.')
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
    let output = prefix ? prefix : ''

    if (with_decimal) {
        return rupiah ? `${output}${rupiah},${decimal}` : '';
    } else {
        return rupiah ? output + rupiah : ''
    }
}

export function convertPercentangeWithDecimal(number: string, max_number?: number) {
    if (typeof max_number === 'undefined') {
        max_number = 100;
    }

    if (number === "") {
        return "";
    }

    if ((number.match(/[.]/g) || []).length === 1) {
        if (number[number.length - 1] === '.') {
            const max_data = number.replace('.', '');

            if (parseInt(max_data) >= max_number) {
                return max_number;
            }
            return number;
        } else {
            const number_splitted = number.split('.');

            if (number_splitted[1].length === 1) {
                return parseFloat(number).toFixed(1);
            } else if (number_splitted[1].length === 2) {
                return parseFloat(number).toFixed(2);
            } else {
                return false;
            }
        }
    } else if ((number.match(/[.]/g) || []).length > 1) {
        return false;
    }

    if (isNaN(parseFloat(number))) {
        return false;
    } else {
        let new_value = parseFloat(number);
        new_value = new_value > max_number ? max_number : new_value;
        return new_value;
    }
}

export function inputNumber(number: string, min?: number) {
    const numberString = number.replace(/[^,\d]/g, '').toString()
    if (min !== undefined && min !== null && numberString) {
        if (Number(numberString) < min) {
            return min.toString()
        }
    }
    return numberString ? numberString : ''
}

export const inputLatOrLang = (coords: string) => {
    return coords.replace(/[^\d.-]/gi, '')
}

export function validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(String(email).toLowerCase());
}

export const checkNotification = () => new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_API_URL + '/notification'
    DefaultAxios.get(url)
        .then(res => res.data)
        .then(res => {
            let isUnread = false
            if (!isUnread) {
                isUnread = res.some((element: any) => element.read_at === null)
            }
            localStorage.setItem('isUnread', isUnread ? 'true' : 'false')
            resolve(isUnread)
        })
        .catch(err => {
            console.log(err)
            reject()
        })
})

export const getMonth = (monthNum: number) => {
    switch (monthNum) {
        case 0:
            return 'January'
        case 1:
            return 'February'
        case 2:
            return 'March'
        case 3:
            return 'April'
        case 4:
            return 'May'
        case 5:
            return 'June'
        case 6:
            return 'July'
        case 7:
            return 'August'
        case 8:
            return 'September'
        case 9:
            return 'October'
        case 10:
            return 'November'
        case 11:
            return 'December'
    }
}

export const getDay = (dayNum: number, shortText?: boolean) => {
    switch (dayNum) {
        case 0:
            return shortText ? 'Sun' : 'Sunday'
        case 1:
            return shortText ? 'Mon' : 'Monday'
        case 2:
            return shortText ? 'Tue' : 'Tuesday'
        case 3:
            return shortText ? 'Wed' : 'Wednesday'
        case 4:
            return shortText ? 'Thur' : 'Thursday'
        case 5:
            return shortText ? 'Fri' : 'Friday'
        case 6:
            return shortText ? 'Sat' : 'Saturday'
    }
}

export function currencyToNumber(currency: string) {
    return Number(currency.replace(/[^0-9]/g, ''));
}

export function cleanObject(object: any) {
    for (var propName in object) {
        if (object[propName] === null || object[propName] === undefined) {
            delete object[propName];
        }
    }
    return object
}

export function prettyFloat(num: number | string) {
    let numb = typeof num === "string" ? Number(num) : num

    if (numb % 1) {
        return numb.toFixed(2)
    }

    return numb
}

export const numberToCurrency = (number: number | string, prefix?: string): string => {
    if (number === null) {
        return `${prefix ? prefix : ''} -`;
    }

    const formatter = new Intl.NumberFormat('en-US');
    const newNumber: unknown = String(number).replace(/[.]\d{2}$/, '').replace(/[^0-9]/g, '');
    return `${prefix ? prefix : ''} ${formatter.format(newNumber as number)}`.trim();
}

export const renderToastSuccess = (title: string) =>
    Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: true,
        timer: 8000,
        timerProgressBar: true
    }).fire({
        icon: 'success',
        title: title
    })

export const renderSuccessButton = (label: string, onClose?: () => void) =>
    Swal.fire({
        title: label,
        icon: 'success',
        onAfterClose: onClose,
    })

export const renderInfoButton = (label: string, body?: string, onClose?: () => void) =>
    Swal.fire({
        title: label,
        html: body,
        icon: 'info',
        onAfterClose: onClose,
    })

export const renderWarningButton = (label?: string, title = 'Apakah Anda Yakin?') => {
    return Swal.fire({
        title: title,
        text: label,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya'
    })
}

export const renderQuestionButton = (label?: string, title = 'Apakah Anda Yakin?') => {
    return Swal.fire({
        title: title,
        text: label,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya'
    })
}

export const renderErrorButton = (label?: string, title = 'Data Error') => {
    return Swal.fire({
        title: title,
        text: label,
        icon: 'error',
        confirmButtonText: 'OK'
    })
}

export const generalErrorHandler = (error: any, extraParams?: { render400ErrorList: boolean }) => {
    if (typeof error.response === 'undefined') {
        Swal.fire({
            title: "Server Error",
            html: "Error pada Browser. Harap hubungi Admin",
            icon: 'error',
        });
        return;
    }

    let errorData = error.response.data;

    if (!(errorData instanceof Blob)) {
        errorData = new Blob([JSON.stringify(errorData)], { type: "application/json" });
    }

    let reader = new FileReader();

    reader.onload = () => {
        errorData = JSON.parse(String(reader.result));

        if (typeof error.response.status === 'undefined') {
            Swal.fire({
                title: "Script Error",
                html: "Error pada script. Harap hubungi Admin",
                icon: 'error',
            });
        } else if (error.response.status === 422) {
            let error_text = `<p>${errorData.message}</p>`;

            if (typeof errorData.errors !== 'undefined') {
                const error_lists = errorData.errors;
                for (const k in error_lists) {
                    error_text += `<p>${error_lists[k]}</p>`;
                }
            }

            Swal.fire({
                title: "Validasi Error",
                html: error_text,
                icon: 'error',
            });
        } else if (error.response.status === 400) {
            if (extraParams?.render400ErrorList) {
                let error_text = ``

                if (typeof errorData !== 'undefined') {
                    const error_lists = errorData;
                    for (const k in error_lists) {
                        error_text += `<p>${error_lists[k]}</p>`;
                    }
                }

                Swal.fire({
                    title: "Data Error",
                    html: error_text,
                    icon: 'error',
                });
            } else {
                Swal.fire({
                    title: "Data Error",
                    html: errorData.message,
                    icon: 'error',
                });
            }
        } else if (error.response.status === 404) {
            Swal.fire({
                title: "Data Error",
                html: errorData.message,
                icon: 'error',
            });
        } else if (error.response.status === 500) {
            Swal.fire({
                title: "Server Error",
                html: "Error pada server. Harap hubungi Admin",
                icon: 'error',
            });
        } else if (error.response.status === 412) {
            Swal.fire({
                title: "Data Error",
                html: errorData,
                icon: 'error',
            });
        }
    };

    reader.readAsText(errorData);
}

export const getBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = () => resolve(reader.result)

        reader.onerror = error => reject(error)
    })
}

export interface IFacilities {
    'atm': 'ATM'
    'grocery-store': 'Grocery Store'
    'cable-tv': 'TV Kabel'
    'internet': 'Internet'
    'kolam-renang': 'Kolam Renang'
    'laundry': 'Laundry'
    'security': 'Security'
    'basket-ball': 'Lapangan Basket'
    'function-room': 'Function Room'
    'gym': 'Gym'
    'jogging-track': 'Jogging Track'
    'lapangan-tenis': 'Lapangan Tenis'
    'restoran': 'Restoran'
    'taman-bermain': 'Taman Bermain'
    'ac': 'AC'
    'air-panas': 'Air Panas'
    'dining-set': 'Dining Set'
    'listrik': 'Listrik'
    'tempat-tidur': 'Tempat Tidur'
    'access-card': 'Access Card'
    'dapur': 'Dapur'
    'kulkas': 'Kulkas'
    'mesin-cuci': 'Mesin Cuci'
    'tv': 'TV'
    'futsal-court': 'Futsal Court'
}

export const parseFacility = (facility: keyof IFacilities) => {
    const facilities: IFacilities = {
        'atm': 'ATM',
        'grocery-store': 'Grocery Store',
        'cable-tv': 'TV Kabel',
        'internet': 'Internet',
        'kolam-renang': 'Kolam Renang',
        'laundry': 'Laundry',
        'security': 'Security',
        'basket-ball': 'Lapangan Basket',
        'function-room': 'Function Room',
        'gym': 'Gym',
        'jogging-track': 'Jogging Track',
        'lapangan-tenis': 'Lapangan Tenis',
        'restoran': 'Restoran',
        'taman-bermain': 'Taman Bermain',
        'ac': 'AC',
        'air-panas': 'Air Panas',
        'dining-set': 'Dining Set',
        'listrik': 'Listrik',
        'tempat-tidur': 'Tempat Tidur',
        'access-card': 'Access Card',
        'dapur': 'Dapur',
        'kulkas': 'Kulkas',
        'mesin-cuci': 'Mesin Cuci',
        'tv': 'TV',
        'futsal-court': 'Futsal Court'
    }

    return facility in facilities ? facilities[facility] : null
}


export const convertIndonesiaPhoneNumber = (number: string) => {
    const first_two_character = number.substring(0, 2);

    if (first_two_character === "08") {
        number = number.replace(/08/g, "628");
    }

    return number.replace(/[^\d]/, '');
}

export const convertNumberComaDecimal = (number: string) => {
    return number.replace(/[^\d|.|-]/, '');
}

export const dateFormat = (date: string | Date | moment.Moment, format = 'DD/MM/YYYY'): string => {
    if (date === '') {
        return '';
    }
    const d = moment(date).format(format);
    return d;
}

export const dateFormatRange = (date: string, format = 'DD/MM/YYYY'): string => {
    if (date === '') {
        return '';
    }
    let newDate = [];
    let dates = date.split(' - ');

    for (let d of dates) {
        let dd = moment(d);

        newDate.push(dd.isValid() ? dd.format(format) : '');
    }

    return newDate.join(' - ');
}

export const queryParamsToParams = (): any => {
    const param: any = {};
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.forEach((value: string, key: string) => {
        if (value.includes(' - ')) {
            let isDate = false;
            let split = value.split(' - ');

            for (let s of split) {
                let d = moment(s);

                if (d.isValid()) {
                    isDate = true;
                }
            }

            if (isDate) {
                param[key] = dateFormatRange(value);
            } else {
                param[key] = value;
            }
        } else {
            param[key] = value;
        }
    });

    return param;
}

export const isValidEmail = (email: string, multiple: boolean = false) => {
    // eslint-disable-next-line no-useless-escape
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (multiple) {
        // eslint-disable-next-line no-useless-escape
        re = /^([\w\.\-]+@[a-zA-Z0-9\-]+(\.[a-zA-Z0-9\-]{1,})*(\.[a-zA-Z]{2,4}){1,2}(;|,)?)+$/;
    }

    return re.test(email.toLowerCase());
}

export const isValidDate = (d: any) => {
    return d instanceof Date && !isNaN(d.getTime());
}

export const urlToFile = (url: string, filename: string, mimeType: string) => {
    return (
        fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
}

export const getOrientation = (file: any, callback: any) => {
    let reader = new FileReader();

    reader.onload = (event) => {
        var target = event.target;
        if (target) {
            var view = new DataView(target.result as any);

            if (view.getUint16(0, false) !== 0xFFD8) return callback(-2);

            var length = view.byteLength,
                offset = 2;

            while (offset < length) {
                var marker = view.getUint16(offset, false);
                offset += 2;

                if (marker === 0xFFE1) {
                    if (view.getUint32(offset += 2, false) !== 0x45786966) {
                        return callback(-1);
                    }
                    var little = view.getUint16(offset += 6, false) === 0x4949;
                    offset += view.getUint32(offset + 4, little);
                    var tags = view.getUint16(offset, little);
                    offset += 2;

                    for (var i = 0; i < tags; i++)
                        if (view.getUint16(offset + (i * 12), little) === 0x0112)
                            return callback(view.getUint16(offset + (i * 12) + 8, little));
                }
                else if ((marker & 0xFF00) !== 0xFF00) break;
                else offset += view.getUint16(offset, false);
            }
            return callback(-1);
        }
    }

    reader.readAsArrayBuffer(file);
}

export const resetOrientation = (srcBase64: any, srcOrientation: number, callback: any) => {
    var img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () => {
        var width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");

        if (ctx) {
            // set proper canvas dimensions before transform & export
            if (4 < srcOrientation && srcOrientation < 9) {
                canvas.width = height;
                canvas.height = width;
            } else {
                canvas.width = width;
                canvas.height = height;
            }

            // transform context before drawing image
            switch (srcOrientation) {
                case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
                case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
                case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
                case 7: ctx.transform(0, -1, -1, 0, height, width); break;
                case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                default: break;
            }

            // draw image
            ctx.drawImage(img, 0, 0);

            // export blob
            canvas.toBlob((blob: any) => {
                callback(blob);
            })
        }
    };

    img.src = srcBase64;
}

export const resizeAndResetOrientationImage = (file: File) => {
    return new Promise<File>((resolve, reject) => {
        getOrientation(file, (orientation: any) => {
            resetOrientation(URL.createObjectURL(file), orientation, (newImg: any) => {
                let img = document.createElement("img");
                let reader = new FileReader();
                reader.onload = (e) => {
                    if (e.target) {
                        img.src = e.target.result as string;
                        img.onload = () => {
                            let canvas = document.createElement("canvas");
                            let ctx = canvas.getContext("2d");
                            if (ctx) {
                                ctx.drawImage(img, 0, 0);
                                let MAX_WIDTH = 800;
                                let MAX_HEIGHT = 800;
                                let width = img.width;
                                let height = img.height;
                                if (width > height) {
                                    if (width > MAX_WIDTH) {
                                        height *= MAX_WIDTH / width;
                                        width = MAX_WIDTH;
                                    }
                                } else {
                                    if (height > MAX_HEIGHT) {
                                        width *= MAX_HEIGHT / height;
                                        height = MAX_HEIGHT;
                                    }
                                }
                                canvas.width = width;
                                canvas.height = height;
                                ctx.drawImage(img, 0, 0, width, height);
                                let dataurl = canvas.toDataURL("image/png");
                                let [mimeType] = dataurl.replace('data:', '').split(';');
                                resolve(urlToFile(dataurl, file.name, mimeType));
                            }
                        };
                        img.onerror = function () {
                            alert('Invalid file type: ' + file.type);
                        };
                    }
                }
                reader.readAsDataURL(newImg);
            })
        })
    })
}

export const resizeImage = (file: File) => {
    return new Promise<File>((resolve, reject) => {
        let img = document.createElement("img");
        let reader = new FileReader();
        reader.onload = (e) => {
            if (e.target) {
                img.src = e.target.result as string;
                img.onload = () => {
                    let canvas = document.createElement("canvas");
                    let ctx = canvas.getContext("2d");
                    if (ctx) {
                        ctx.drawImage(img, 0, 0);
                        let MAX_WIDTH = 800;
                        let MAX_HEIGHT = 800;
                        let width = img.width;
                        let height = img.height;
                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(img, 0, 0, width, height);
                        let dataurl = canvas.toDataURL("image/png");
                        let [mimeType] = dataurl.replace('data:', '').split(';');
                        resolve(urlToFile(dataurl, file.name, mimeType));
                    }
                };
                img.onerror = function () {
                    alert('Invalid file type: ' + file.type);
                };
            }
        }
        reader.readAsDataURL(file);
    })
}

export const rotateImageFile = (file: File, clockWise: boolean, callback: (file: File) => void, onError: (err: any) => void) => {
    let image = new Image()
    image.crossOrigin = 'anonymous'

    image.src = URL.createObjectURL(file)

    image.onload = () => {
        let width = image.width,
            height = image.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d')

        if (ctx) {
            canvas.width = width
            canvas.height = height

            ctx.translate(width / 2, height / 2)
            ctx.rotate(Math.PI / 2 * (clockWise ? 1 : -1))
            ctx.translate(-width / 2, -height / 2)

            ctx.drawImage(image, 0, 0)

            canvas.toBlob(blob => {
                if (blob) {
                    let finalImage = new Image()
                    let reader = new FileReader()

                    reader.readAsDataURL(blob)

                    reader.onload = e => {
                        if (e.target) {
                            finalImage.src = e.target.result as string

                            finalImage.onload = () => {
                                let finalCanvas = document.createElement('canvas'),
                                    finalCtx = finalCanvas.getContext('2d'),
                                    finalWidth = finalImage.width,
                                    finalheight = finalImage.height

                                if (finalCtx) {
                                    finalCanvas.width = finalWidth
                                    finalCanvas.height = finalheight

                                    finalCtx.drawImage(finalImage, 0, 0)

                                    const dataUrl = canvas.toDataURL('image/png')
                                    const [mimeType] = dataUrl.replace('data:', '').split(';');

                                    urlToFile(dataUrl, file.name, mimeType)
                                        .then(finalFile => {
                                            callback(finalFile)
                                        })
                                        .catch(err => {
                                            onError(err)
                                        })
                                }
                            }

                            finalImage.onerror = err => onError(err)
                        }
                    }

                    reader.onerror = err => onError(err)
                }
            })
        }
    }

    image.onerror = err => onError(err)
}

export const convertPrice = (price: string) => {
    return parseInt(price.split('.')[0]).toLocaleString()
}

export const capitalizeFirstCharacter = (text: string) => {
    if (text.length <= 1) return text.toUpperCase()
    return text[0].toUpperCase() + text.slice(1)
}

export const download = (url: string, fileName: string, onFinish?: () => void, onError?: (err: any) => void) => {
    DefaultAxios.get(url, {
        responseType: 'blob'
    })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(err => {
            if (err && err.response && err.response.status === 410) {
                // Do Nothing
            } else {
                generalErrorHandler(err)
                if (onError) onError(err)
            }
        })
        .finally(onFinish)
}

export const openExternalLink = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target', '_blank')
    link.setAttribute('rel', 'noopener noreferrer')
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const camelCaseWord = (text: string) => text[0].toUpperCase() + text.slice(1)

export const getFirstName = (name: string) => {
    const isPanggilan = (panggilan: string) => ['pak', 'bapak', 'bpk', 'ibu', 'bu', 'kak', 'mas', 'mba', 'mbak'].includes(panggilan)

    if (name.length === 1) return name.toUpperCase()

    if (name.slice(0, 2) === 'nn') return ''

    if (
        name[0] === '#' ||
        isPanggilan(name.split(' ')[0].toLowerCase()) ||
        name.split(' ')[0].length === 1
    ) {
        name = camelCaseWord(name.split(' ')[1])
    } else {
        name = camelCaseWord(name.split(' ')[0])
    }

    return name
}

export const _setCookie = (name: string, value: string, expiry: number = 180) => {
    return Cookies.set(name, value, { expires: expiry })
}

export const _getCookie = (name: string) => {
    return Cookies.get(name)
}

export const _removeCookie = (name: string) => {
    return Cookies.remove(name)
}

export const setBrowserId = () => {
    const { v4: uuidv4 } = require('uuid');
    const newId = uuidv4()
    const browserId = _getCookie('browser_id')

    if (!browserId) {
        _setCookie('browser_id', newId)
    }

    return browserId || newId
}

export const openLink = (url: string, isNewTab?: boolean) => {
    const link = document.createElement('a')
    link.href = url
    if (isNewTab) {
        link.setAttribute('target', `_blank`)
        link.setAttribute('rel', `noopener norefferrer`)
    }
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const processNumber = (price: number | null | undefined, is_dollar: number) => {
    return price ? (is_dollar ? '$ ' : "Rp. ") + price.toLocaleString() : '-'
}

export const parsePaidBy = (paidBy: string) => PAID_BY_OPTIONS.filter(pbo => pbo.key === paidBy)[0]?.value || '-'
import { Button, Grid, TextField } from '@mui/material'
import React, { Fragment, useState } from 'react'
import Swal from 'sweetalert2';
import LoadingScreen from '../../_components/LoadingScreen';

/* 
* Components
*/
import AsyncAutoComplete from '../../_components/_form/AsyncAutoComplete'

/* 
* Utils
*/
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

/* 
* Interfaces
*/
interface IState {
    bank_type_id: number;
    bank_number: string;
}

const BankVerifierForm = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<IState>({
        bank_type_id: 0,
        bank_number: ''
    })

    const handleAutocompleteBank = (name: string, value: any) => {
        setState({
            ...state as IState,
            [name]: value.id as number
        })
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setState({
            ...state as IState,
            [name]: null
        })
    }

    const handleChangeNumber = (evt: any) => {
        setState({
            ...state as IState,
            [evt.target.name]: evt.target.value.replace(/[^\d]/g, '')
        })
    }

    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/bank-name-verifier`, { ...state })
            .then(res => {
                Swal.fire({
                    title: "Bank Verified",
                    html: `Nama Pemilik Rekening: <br/><br/><br/> <b>${res.data.behalf_name}</b>`,
                    icon: 'success'
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                label="Bank Name"
                                name="bank_type_id"
                                initialQuery={''}
                                onChange={handleAutocompleteBank}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/admin/autocomplete/bank-type`}
                                placeholder="Bank Name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="bank_number"
                                label="Bank Number"
                                value={state?.bank_number}
                                onChange={handleChangeNumber}
                                placeholder="Bank Number"
                                fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default BankVerifierForm
import React, { useState } from 'react';
import { Grid, Button, Theme, InputAdornment, Card, CardContent } from '@mui/material';
import { addDays } from 'date-fns';

/**
 * Interfaces
 */
import { useStyles } from '../ClosingForm';
import { numberToCurrency } from '../../../_utils/Helper';
import MuiDatePicker from '../../../_components/MuiDatePicker';
import { generateStyle } from '../../../_utils/DefaultStyle';
import TextFieldND3 from '../../../_components/_formElements/_newDesign2/TextFieldND3';
import { Add } from '@mui/icons-material';

interface IProps {
    mtos: IMTO[];
    setParentMtoState: Function;
}

export interface IMTO {
    date: Date;
    transaction_amount: string;
    basic_price: string;
}

interface IErrorState {
    date: string;
    transaction_amount: string;
    basic_price: string;
}

const MTOForm = ({
    mtos,
    setParentMtoState,
}: IProps) => {
    const { Root, classes } = useStyles();
    const { Root: LocalRoot, classes: localClasses } = useLocalStyles()
    const [errorState, setErrorState] = useState<IErrorState[]>([]);

    const handleAddMto = () => {
        if (checkMtoValidation()) {
            let newDate;

            if (mtos.length > 0) {
                const lastMto = mtos[mtos.length - 1];
                console.log(lastMto)
                newDate = addDays(lastMto.date, 1);
            } else {
                newDate = addDays(new Date(), 1);
            }

            const oldMtos = [...mtos];
            oldMtos.push({
                date: newDate,
                transaction_amount: mtos.length ? numberToCurrency(mtos[mtos.length - 1].transaction_amount) : '',
                basic_price: mtos.length ? numberToCurrency(mtos[mtos.length - 1].basic_price) : '',
                // duration: '',
            });
            setParentMtoState(oldMtos);
        }
    }

    const handleDeleteMto = (key: number) => {
        const oldMtos = mtos.slice();
        oldMtos.splice(key, 1);
        setParentMtoState(oldMtos);
    }

    const checkMtoValidation = () => {
        let thisMtoIsValid = true;
        const listofErrors: IErrorState[] = [];

        for (let index = 0; index < mtos.length; index++) {
            const newError: IErrorState = {
                date: '',
                transaction_amount: '',
                basic_price: ''
            };

            if (mtos[index].transaction_amount === "") {
                newError.transaction_amount = 'Amount wajib diisi';
                thisMtoIsValid = false;
            }

            if (mtos[index].basic_price === "") {
                newError.basic_price = 'Basic Price wajib diisi';
                thisMtoIsValid = false;
            }
            listofErrors.push(newError);
        }

        setErrorState(listofErrors);
        return thisMtoIsValid;
    }

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>, key: number) => {
        const oldMtos = mtos.slice();

        const attribute = event.target.name;
        const value = event.target.value;

        if (['basic_price', 'transaction_amount'].includes(attribute)) {
            switch (attribute) {
                case 'transaction_amount':
                    oldMtos[key].transaction_amount = numberToCurrency(value);
                    break;
                case 'basic_price':
                    oldMtos[key].basic_price = numberToCurrency(value);
                    break;
                default:
                    break;
            }
        }
        setParentMtoState(oldMtos);
    }

    const handleDateChanged = (date: Date | null, name: string, key: number) => {
        const oldMtos = mtos.slice();
        oldMtos[key].date = date!;

        setParentMtoState(oldMtos);
    }

    return (
        <Root>
            <LocalRoot>
                <Card>
                    <CardContent className={localClasses.mtoContainer}>
                        <Grid container>
                            {
                                mtos.map((mto, key) => {
                                    let minDate = key === 0 ? undefined : addDays(new Date(mtos[key - 1].date), 1)

                                    return (
                                        <Grid item xs={12} key={key}>
                                            <Grid container spacing={2} className={classes.terminContainer}>
                                                <Grid item xs={12} className={localClasses.terminTitleContainer}>
                                                    <p className={localClasses.terminTitle}>
                                                        {`Mto ${key + 1}`}
                                                    </p>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <MuiDatePicker
                                                        label={`Payment ${key + 1}`}
                                                        value={mto.date}
                                                        minDate={mtos.length - 1 > key ? new Date("1900-01-01") : minDate}
                                                        onChange={(date: Date | null) => handleDateChanged(date, 'date', key)}
                                                        disabled={mtos.length - 1 > key}
                                                        newDesign3
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextFieldND3
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Amount"
                                                        name="transaction_amount"
                                                        value={mto.transaction_amount}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChanged(e, key)}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                                        }}
                                                        disabled={mtos.length - 1 > key}
                                                        error={typeof errorState[key] !== "undefined" && errorState[key].transaction_amount !== ""}
                                                        helperText={typeof errorState[key] !== "undefined" && errorState[key].transaction_amount !== "" ? errorState[key].transaction_amount : ""}
                                                        isGrey
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextFieldND3
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Basic Price"
                                                        name="basic_price"
                                                        value={mto.basic_price}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                                        }}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChanged(e, key)}
                                                        disabled={mtos.length - 1 > key}
                                                        error={typeof errorState[key] !== "undefined" && errorState[key].basic_price !== ""}
                                                        helperText={typeof errorState[key] !== "undefined" && errorState[key].basic_price !== "" ? errorState[key].basic_price : ""}
                                                        isGrey
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12} className={classes.deleteTerminBtn}>
                                                    {
                                                        mtos.length - 1 === key
                                                            ?
                                                            <Button
                                                                color="secondary"
                                                                variant='contained'
                                                                sx={{ float: 'right' }}
                                                                onClick={() => handleDeleteMto(key)}
                                                            >
                                                                Delete
                                                            </Button>
                                                            : null
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }

                            <Grid item xs={12} container justifyContent="center">
                                <Button
                                    color="inherit"
                                    variant="outlined"
                                    onClick={handleAddMto}
                                    startIcon={<Add />}
                                >
                                    Add Payment
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </LocalRoot>
        </Root>
    );
}

const useLocalStyles = generateStyle((theme: Theme) => ({
    terminTitleContainer: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    terminTitle: {
        fontSize: 16,
        fontWeight: 500,
        textAlign: 'center',
        textTransform: 'uppercase',
        marginTop: 4,
        marginBottom: 4,
    },
    mtoContainer: {
        background: '#FAFAFA',
        padding: '24px 16px'
    }
}), "MTO_Form"
)

export default MTOForm;
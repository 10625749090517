import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { generateStyle } from '../_utils/DefaultStyle';

export interface SimpleCardProps {
    title: string;
    subtitle: string;
    onClick: () => void
}

const useStyles = generateStyle(theme => ({
    card: {
        minWidth: 275,
        marginBottom: '20px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
        color: '#4a4a4a',
        marginTop: '0'
    },
    subtitle: {
        fontSize: 12,
        color: '#909090',
        margin: '0'
    },
    pos: {
        marginBottom: 12,
    },
    link: {
        textDecoration: 'none',
    }
}), "SimpleCard")

const SimpleCard = (props: SimpleCardProps) => {
    const { Root, classes } = useStyles();

    return (
        <Root>
            <Card className={classes.card} onClick={props.onClick}>
                <CardActionArea>
                    <CardContent>
                        <h3 className={classes.title}>{props.title}</h3>
                        <h4 className={classes.subtitle}>{props.subtitle}</h4>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Root>
    );
}

export default SimpleCard;
import React, { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Theme, Switch, IconButton } from '@mui/material';
import moment from 'moment';

import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';
// import DeleteIcon from '@mui/icons-material/Delete';

interface IProps {
    district_id?: string
}

const useStyles = generateStyle((theme: Theme) => ({
    actionContainer: {
        display: 'flex'
    },
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    notes_list: {
        maxWidth: '500px',
        lineBreak: 'anywhere',
        whiteSpace: 'pre-line'
    }
}), "District_List"
)


const DistrictList = (props: IProps) => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const DT_API_URL = process.env.REACT_APP_API_URL + '/district';

    // const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handlePublished = (e: React.ChangeEvent<{ checked: boolean }>, columnName: string, setRows: Function, rows: any[], id: string) => {
        let checked = e.target.checked
        let data = checked ? 1 : 0

        renderWarningButton()
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.put(`${DT_API_URL}/${id}/toggle`, { is_active: data })
                        .then(res => {
                            const newRows = rows.map((element: any) => {
                                if (element.id === id) element[columnName] = checked ? 1 : 0
                                return element
                            })
                            setRows(newRows)
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })

    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            {
                permissions['district.manage'] ?
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.addButton}
                        startIcon={<AddIcon />}
                        onClick={() => navigate(`/district/add`)}
                    >
                        Add District
                    </Button>
                    : null
            }
            <DataTable
                // reDraw={reDrawDatatable}
                url={DT_API_URL}
                columns={[
                    { name: 'name', label: 'Name', filterable: true, sortable: true },
                    { name: 'area_name', label: 'Area', filterable: true, sortable: true },
                    {
                        name: 'is_active',
                        label: 'Published',
                        filterable: true,
                        type: 'string',
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '0',
                                value: 'No'
                            },
                            {
                                key: '1',
                                value: 'Yes'
                            },
                        ],
                        render: (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
                            return (<Switch
                                checked={row[columnName] === 1}
                                onChange={(e) => handlePublished(e, columnName, setRows, rows, row.id)}
                                color="secondary"
                            />)
                        }
                    },
                    {
                        name: 'updated_at',
                        label: 'Last modified',
                        type: 'date',
                        filterable: true,
                        sortable: true,
                        // defaultOption: '23/10/2020 - 23/10/2020',
                        render: (data: any, row: any) => {
                            return moment(data).format('DD MMM YYYY')
                        }
                    },
                    {
                        name: 'EXTRA',
                        label: 'Action',
                        sortable: false,
                        render: (row: any) => {
                            return (
                                permissions['district.manage'] ?
                                    <div className={classes.actionContainer}>
                                        <IconButton
                                            color="primary"
                                            component="span"
                                            onClick={() => navigate(`/district/${row.id}`)}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        {/* <IconButton
                                            color="secondary"
                                            component="span"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton> */}
                                    </div>
                                    : <> </>
                            );
                        }
                    }
                ]}
            />
        </Root>
    );
}

export default DistrictList;
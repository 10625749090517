import React from 'react';
import moment from 'moment';

import DataTable from '../../_components/_dataTable/DataTable';

const LoginLogList = () => {
    const columns = [
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true,
            render: (data: any, row: any) => {
                return <span style={{ backgroundColor: +row.success === 1 ? 'none' : 'red' }}>{data}</span>
            }
        },
        {
            name: 'ip',
            label: 'IP',
            type: 'string',
            filterable: true,
        },
        {
            name: 'location',
            label: 'Location',
            type: 'string',
            filterable: true,
        },
        {
            name: 'created_at',
            label: 'Date',
            type: 'date',
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    `${moment(data).format("DD MMMM YYYY HH:mm:ss")}`
                )
            }
        },
    ];

    return (
        <DataTable 
            url={`${process.env.REACT_APP_API_URL}/login-log`}
            columns={columns}
        />
    );
}

export default LoginLogList;
import React, { useState, useReducer, useEffect } from 'react';
import { Theme, Grid, TextField, Button, MenuItem, Card, CardContent, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { useParams, useNavigate } from "react-router-dom";

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IParams extends Record<string, string | undefined> {
    bank_id?: string
}

interface IState {
    bank_type_id: string
    bank_type_label: string
    behalf_name: string
    number: string
    type: string
    description: string
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) => ({
    submit_button: {
        // marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        maxWidth: '750px',
        marginBottom: '70px',
        boxShadow: '0px 3px 15px #0000001A',
        '& .Mui-disabled': {
            backgroundColor: '#eee'
        }
    },
    title: {
        fontSize: '34px',
        borderBottom: '1px solid #0000004D',
        marginBottom: '12px'
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        // textAlign: 'right',
        marginBottom: '10px'
    }
}), "BankOther_Form"
)

const alias: IValidationAlias = {
    bank_type_id: 'Bank',
    behalf_name: 'Atas Nama',
    number: 'Nomor Rekening',
    type: 'Tipe',
    description: 'Deskripsi',
}

const BankOthersForm = () => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/bank-other';
    const params = useParams<IParams>()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<IValidationErrors>({})
    const rules: IValidationRules = {
        bank_type_id: 'required',
        behalf_name: 'required',
        number: 'required',
        type: 'required',
        description: 'required_if:type=others',
    }

    // const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    //     const { name, value } = e.target

    //     const { errors, isValid } = validateOne(name, value, rules, alias)

    //     if (isValid) {
    //         setError(prev => ({
    //             ...prev,
    //             [name]: '',
    //         }))
    //     } else {
    //         setError(prev => ({
    //             ...prev,
    //             ...errors,
    //         }))
    //     }
    // }

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                bank_type_id: '',
                bank_type_label: '',
                behalf_name: '',
                number: '',
                type: '',
                description: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        bank_type_id: '',
        bank_type_label: '',
        behalf_name: '',
        number: '',
        type: '',
        description: '',
    });

    useEffect(() => {
        if (typeof params.bank_id !== "undefined") {
            setLoading(true);
            DefaultAxios
                .get(`${API_URL}/${params.bank_id}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                    setLoading(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = '#eee';
        let timeout: any;
        return () => {
            clearTimeout(timeout);
            document.body.style.backgroundColor = '#fff';
        }
    }, [])

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
        }
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name: 'bank_type_id', value: String(value.id), type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name: 'bank_type_id', value: '', type: 'SET_ITEM' });

    }

    const submitForm = () => {
        const { errors, isValid } = validateData(inputState, rules, alias)
        setError(errors)
        if (isValid) {
            let axios;
            let payload: any = { ...inputState };

            if (typeof params.bank_id !== "undefined") {
                axios = DefaultAxios.patch(`${API_URL}/${params.bank_id}`, payload)
            } else {
                axios = DefaultAxios.post(`${API_URL}`, payload);
            }

            setLoading(true);
            axios
                .then(res => {
                    Swal.fire({
                        title: `${typeof params.bank_id !== "undefined" ? 'Bank Updated' : 'Bank Submitted'}`,
                        icon: 'success',
                        onAfterClose: () => {
                            navigate(`/bank-others`, {
                                state: { reload: true }
                            })
                        },
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        }
    }

    return (
        <Root>
            <Card className={classes.root}>
                <LoadingScreen open={loading} fullScreen />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>
                                {
                                    params.bank_id ? 'Edit Bank' : 'Add Bank'
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                url={process.env.REACT_APP_API_URL + '/admin/autocomplete/bank-type'}
                                label="Bank"
                                name="bank_type_id"
                                initialQuery={inputState.bank_type_label}
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                errorText={error.bank_type_id}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                error={!!error.behalf_name}
                                label="Atas Nama"
                                variant="outlined"
                                name="behalf_name"
                                value={inputState.behalf_name}
                                onChange={handleInputChanged}
                                fullWidth
                                helperText={error.behalf_name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                error={!!error.number}
                                label="Nomor Rekening"
                                variant="outlined"
                                name="number"
                                value={inputState.number}
                                onChange={handleInputChanged}
                                fullWidth
                                helperText={error.number}
                                type="number"
                            // placeholder="628xxxxxxxxxx"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                label="Tipe"
                                name="type"
                                value={inputState.type}
                                onChange={handleInputChanged}
                                error={!!error.type}
                                helperText={error.type}
                            >
                                <MenuItem value=''>Pilih Tipe</MenuItem>
                                <MenuItem value='deposit_jendela'>Deposit Jendela</MenuItem>
                                <MenuItem value='main_jendela'>Bank Utama Jendela</MenuItem>
                                <MenuItem value='cobroke_bank'>Bank Cobroke</MenuItem>
                                <MenuItem value='others'>Lainnya</MenuItem>
                            </TextField>
                        </Grid>

                        {
                            inputState.type === 'others' &&
                            <Grid item xs={12}>
                                <TextField
                                    error={!!error.description}
                                    label="Deskripsi"
                                    variant="outlined"
                                    name="description"
                                    value={inputState.description}
                                    onChange={handleInputChanged}
                                    fullWidth
                                    helperText={error.description}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} className={classes.gridSubmit}>
                            <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Root>
    );
}

export default BankOthersForm;
import { Paper, Theme, styled } from '@mui/material';

const CardND = styled(Paper)(({ theme }: { theme: Theme }) => ({
    '&.MuiPaper-root': {
        borderRadius: 20,
    },
    '&.MuiPaper-elevation1': {
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
    },
}));

export default CardND

import React, { Fragment, useState, useReducer, useEffect } from 'react';
import { Button, IconButton, Dialog, DialogContent, DialogTitle, Grid, TextField, MenuItem, DialogActions, Switch } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';

// Icons
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import { generalErrorHandler, isValidEmail } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IState {
    id: string;
    name: string;
    bedroom_type: string;
    property_id: string;
    property_label: string;
    apartment_id: string;
    apartment_label: string;
    landlord_id: string;
    landlord_label: string;
    is_new_landlord_data: boolean;
    landlord_name: string;
    landlord_phone: string;
    landlord_email: string;
    is_apartment: boolean;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IErrorState {
    name: string;
    bedroom_type: string;
    property_id: string;
    apartment_id: string;
    landlord_id: string;
    landlord_name: string;
    landlord_phone: string;
    landlord_email: string;
}

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex'
    },
    addButton: {
        marginBottom: '20px'
    },
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
}), "Stay360Unit");

const Stay360Unit = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/stay360-unit';
    const { Root, classes } = useStyles();

    const bedroomTypeLists = [
        {
            key: 'studio',
            value: 'Studio'
        },
        {
            key: '1br',
            value: '1 BR'
        },
        {
            key: '2br',
            value: '2 BR'
        },
        {
            key: '3br',
            value: '3 BR'
        },
        {
            key: '4br',
            value: '4 BR'
        },
        {
            key: '5br',
            value: '5 BR'
        },
        {
            key: '6br',
            value: '6 BR'
        },
        {
            key: '7br',
            value: '7 BR'
        },
        {
            key: '8br',
            value: '8 BR'
        },
        {
            key: '9br',
            value: '9 BR'
        },
        {
            key: '10br',
            value: '10 BR'
        },
        {
            key: 'asrama',
            value: 'Asrama'
        }
    ];

    const columns = [
        {
            name: 'is_apartment',
            label: 'Location',
            type: 'string',
            filterable: true,
            render: (data: boolean, row: any) => {
                return data ? row.apartment_label : row.property_label;
            }
        },
        {
            name: 'name',
            label: 'Nomor Unit',
            type: 'string',
            filterable: true
        },
        {
            name: 'bedroom_type',
            label: 'Bedroom Type',
            type: 'string',
            filterable: true,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...bedroomTypeLists
            ],
            defaultOption: '',
            render: (data: any) => {
                const found = bedroomTypeLists.find(bedroomTypeList => bedroomTypeList.key === data);
                return found ? found.value : '-';
            }
        },
        {
            name: 'landlord_label',
            label: 'Landlord',
            type: 'string',
            filterable: true
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        <IconButton
                            color="primary"
                            component="span"
                            onClick={() => handleEdit(row.id)}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </div>
                );
            }
        }
    ];

    const initialState = {
        id: '',
        name: '',
        bedroom_type: '',
        property_id: '',
        property_label: '',
        apartment_id: '',
        apartment_label: '',
        landlord_id: '',
        landlord_label: '',
        is_new_landlord_data: false,
        landlord_name: '',
        landlord_phone: '',
        landlord_email: '',
        is_apartment: false
    };

    const initialErrorState = {
        name: '',
        bedroom_type: '',
        property_id: '',
        apartment_id: '',
        landlord_id: '',
        landlord_name: '',
        landlord_phone: '',
        landlord_email: ''
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    useEffect(() => {
        if (!modalOpen) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [modalOpen]);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const name = target.name;
        let value: any = target.value;
        const newState: any = { ...inputState };

        if (name === 'is_apartment') {
            newState[name] = target.checked;
            newState['apartment_id'] = '';
            newState['property_id'] = '';
        } else if (name === 'is_new_landlord_data') {
            newState[name] = target.checked;
            newState['landlord_id'] = '';
            newState['landlord_name'] = '';
            newState['landlord_phone'] = '';
            newState['landlord_email'] = '';
        } else if (name === 'landlord_phone') {
            newState[name] = String(value).replace(/\D+/, '');
        } else {
            newState[name] = value;
        }

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const handleEdit = (id: string) => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL + '/' + id)
            .then(res => {
                const newState = {
                    ...initialState,
                    ...res.data
                };

                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                handleModalOpen();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const checkValidation = () => {
        let isValid = true;
        const newErrorState = { ...initialErrorState };

        if (!inputState.name) {
            newErrorState.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.bedroom_type) {
            newErrorState.bedroom_type = 'Bedroom Type wajib diisi';
            isValid = false;
        }

        if (inputState.is_apartment) {
            if (!inputState.apartment_id) {
                newErrorState.apartment_id = 'Apartemen wajib diisi';
                isValid = false;
            }
        } else {
            if (!inputState.property_id) {
                newErrorState.property_id = 'Property wajib diisi';
                isValid = false;
            }
        }

        if (inputState.is_new_landlord_data) {
            if (!inputState.landlord_name) {
                newErrorState.landlord_name = 'Nama Landlord wajib diisi';
                isValid = false;
            }

            if (!inputState.landlord_phone) {
                newErrorState.landlord_phone = 'No Hp Landlord wajib diisi';
                isValid = false;
            }

            if (inputState.landlord_email && !isValidEmail(inputState.landlord_email)) {
                newErrorState.landlord_email = 'Format email salah';
                isValid = false;
            }
        } else {
            if (!inputState.landlord_id) {
                newErrorState.landlord_id = 'Landlord wajib diisi';
                isValid = false;
            }
        }

        setErrorState({ name: '', value: newErrorState, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.patch(API_URL + '/' + inputState.id, inputState);
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Unit submitted",
                    icon: 'success',
                    onAfterClose: () => {
                        setReDrawDatatable(new Date().getTime());
                        handleModalClose();
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={handleModalOpen}
            >
                Add New Unit
            </Button>
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                fullWidth
                maxWidth="md"
            >
                <Root>
                    <DialogTitle>Unit Form</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className={classes.switchContainer}>
                                Apakah Unit Apartemen
                                <Switch
                                    color="primary"
                                    name="is_apartment"
                                    value={true}
                                    checked={inputState.is_apartment}
                                    onChange={handleChange}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                {
                                    inputState.is_apartment
                                        ? <AsyncAutoComplete
                                            key={0}
                                            label="Apartment"
                                            name="apartment_id"
                                            initialQuery={inputState.apartment_label}
                                            onChange={handleAutocomplete}
                                            onInputChange={handleAutocompleteInputChanged}
                                            url={`${process.env.REACT_APP_API_URL}/autocomplete/apartment`}
                                            errorText={errorState.apartment_id}
                                        />
                                        : <AsyncAutoComplete
                                            key={1}
                                            label="Property"
                                            name="property_id"
                                            initialQuery={inputState.property_label}
                                            onChange={handleAutocomplete}
                                            onInputChange={handleAutocompleteInputChanged}
                                            url={`${process.env.REACT_APP_API_URL}/autocomplete/stay360-property`}
                                            errorText={errorState.property_id}
                                        />
                                }
                            </Grid>

                            <Grid item xs={12} className={classes.switchContainer}>
                                Data Landlord Baru (?)
                                <Switch
                                    color="primary"
                                    name="is_new_landlord_data"
                                    value={true}
                                    checked={inputState.is_new_landlord_data}
                                    onChange={handleChange}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                            </Grid>

                            {
                                inputState.is_new_landlord_data
                                    ? <>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Nama Landlord"
                                                name="landlord_name"
                                                value={inputState.landlord_name}
                                                onChange={handleChange}
                                                error={!!errorState.landlord_name}
                                                helperText={errorState.landlord_name}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="No Hp Landlord"
                                                name="landlord_phone"
                                                value={inputState.landlord_phone}
                                                onChange={handleChange}
                                                error={!!errorState.landlord_phone}
                                                helperText={errorState.landlord_phone}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Email Landlord"
                                                name="landlord_email"
                                                value={inputState.landlord_email}
                                                onChange={handleChange}
                                                error={!!errorState.landlord_email}
                                                helperText={errorState.landlord_email}
                                            />
                                        </Grid>
                                    </>
                                    : <Grid item xs={12}>
                                        <AsyncAutoComplete
                                            label="Landlord"
                                            name="landlord_id"
                                            initialQuery={inputState.landlord_label}
                                            onChange={handleAutocomplete}
                                            onInputChange={handleAutocompleteInputChanged}
                                            url={`${process.env.REACT_APP_API_URL}/autocomplete/landlord`}
                                            errorText={errorState.landlord_id}
                                        />
                                    </Grid>
                            }

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Nomor Unit"
                                    name="name"
                                    value={inputState.name}
                                    onChange={handleChange}
                                    error={!!errorState.name}
                                    helperText={errorState.name}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    label="Bedroom Type"
                                    name="bedroom_type"
                                    value={inputState.bedroom_type}
                                    onChange={handleChange}
                                    error={!!errorState.bedroom_type}
                                    helperText={errorState.bedroom_type}
                                >
                                    {bedroomTypeLists.map(bedroomTypeList => <MenuItem value={bedroomTypeList.key} key={bedroomTypeList.key}>{bedroomTypeList.value}</MenuItem>)}
                                </TextField>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </DialogActions>\
                </Root>
            </Dialog>
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Root>
    );
}

export default Stay360Unit;
import React, { useState } from 'react';
import { IconButton, Button, Dialog, Slide, AppBar, Toolbar, Typography, Theme, Link } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

// Table
import DataTable from '../../_components/_dataTable/DataTable';

// Icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import ListingInventoryForm from './ListingInventoryForm';
import { numberToCurrency } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "ListingInventory_List"
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ListingInventoryList = () => {
    const API_URL = `${process.env.REACT_APP_API_URL}/listing-inventory`;
    const { Root, classes } = useStyles();

    const [selectedId, setSelectedId] = useState<null | number>(null);
    const [isAdd, setIsAdd] = useState(true);
    const [modalState, setModalState] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);

    const handleOpen = () => setModalState(true);
    const handleClose = () => setModalState(false);

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Listing Inventory
                </Typography>
            </Toolbar>
        )
    }

    const openModalCreate = () => {
        setSelectedId(null);
        setIsAdd(true);
        handleOpen();
    }

    const openModalEdit = (data: any) => {
        setSelectedId(data.id);
        setIsAdd(false);
        handleOpen();
    }

    const submitSuccess = () => {
        handleClose();
        setReDrawDatatable(new Date().getTime());
    }

    return (
        <Root>
            <Dialog
                fullScreen
                open={modalState}
                onClose={handleClose}
                closeAfterTransition
                TransitionComponent={Transition}
            >
                <Root>
                    <AppBar className={classes.appBar}>
                        <FormToolbar></FormToolbar>
                    </AppBar>
                    <ListingInventoryForm
                        mode={isAdd ? 'add' : 'edit'}
                        onSubmitSuccess={submitSuccess}
                        listingId={selectedId}
                    />
                </Root>
            </Dialog>

            <Dialog
                open={!!previewImage}
                onClose={() => { setPreviewImage(undefined) }}
                scroll="body"
                maxWidth={false}
            >
                <img src={previewImage} alt="preview" />
            </Dialog>

            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={openModalCreate}
            >
                Add Listing Inventory
            </Button>
            <DataTable
                reDraw={reDrawDatatable}
                url={API_URL}
                columns={[
                    {
                        name: 'name',
                        label: 'Nama',
                        type: 'string',
                        filterable: true,
                        render: (data: any, row: any) => {
                            return (
                                <Link
                                    href="#"
                                    onClick={() => { openModalEdit(row) }}
                                >
                                    {data}
                                </Link>
                            );
                        }
                    },
                    {
                        name: 'default_price',
                        label: 'Default Harga',
                        type: 'number',
                        filterable: true,
                        render: (data: any, row: any) => {
                            return numberToCurrency(data as number, 'Rp.');
                        }
                    },
                    { name: 'stock', label: 'Sisa Stock', type: 'number', filterable: true },
                    { name: 'notes', label: 'Keterangan', type: 'text', filterable: true, sortable: false },
                    {
                        name: 'filepath', label: 'Gambar', type: 'string', filterable: false, sortable: false, render: (data: string) => {
                            return data
                                ? <img
                                    alt="preview"
                                    src={data}
                                    width="100"
                                    height="auto"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => { setPreviewImage(data) }}
                                />
                                : null;
                        }
                    },
                ]}
            />
        </Root>
    );
}

export default ListingInventoryList;
import React, { useEffect, useState } from 'react'
import MenuBar from '../../_components/MenuBar';
import { Box, Button, ButtonGroup, Theme } from '@mui/material';

import InsertChartIcon from '@mui/icons-material/InsertChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SortIcon from '@mui/icons-material/Sort';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import LoadingScreen from '../../_components/LoadingScreen';
import TargetCard from './_components/TargetCard';
import { format } from 'date-fns';
import { generateStyle } from '../../_utils/DefaultStyle';

interface ISort {
    [key: string]: { label: string, value: string | number }[]
}

export interface IAdvisorData {
    activity_count: number | null;
    closing_count: number | null;
    commission: number;
    enquiry_count: number | null;
    id?: number;
    is_highend?: number;
    name?: string;
    on_track: boolean;
    photo?: string | null;
    profile_picture?: string | null;
    target: number;
}

const DashboardPMCaptain = () => {
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [advisors, setAdvisors] = useState<IAdvisorData[]>([])
    const [menuFilter, setMenuFilter] = useState('')
    const [filter, setFilter] = useState({
        type: 'monthly',
        month: 0,
        sort_by: 'none'
    })

    const handleChangeFilter = (type: string, value: string | number) => {
        if (type === 'sort_by') {
            setIsLoading(true)
        }
        setFilter(prev => ({
            ...prev,
            [type]: value
        }))

        setTimeout(() => {
            setMenuFilter('')
        }, 200)
    }

    const sortedAdvisor = (sort: string) => {
        let newAdvisor = [...advisors]
        switch (sort) {
            case "name":
                return newAdvisor.sort((a, b) => (a.name || '') < (b.name || '') ? -1 : 1)
            case "most_revenue":
                return newAdvisor.sort((a, b) => (b.commission || 0) - (a.commission || 0))
            case "least_revenue":
                return newAdvisor.sort((a, b) => (a.commission || 0) - (b.commission || 0))
            case "most_enquiry":
                return newAdvisor.sort((a, b) => (b.enquiry_count || 0) - (a.enquiry_count || 0))
            case "least_enquiry":
                return newAdvisor.sort((a, b) => (a.enquiry_count || 0) - (b.enquiry_count || 0))
            case "most_showing":
                return newAdvisor.sort((a, b) => (b.activity_count || 0) - (a.activity_count || 0))
            case "least_showing":
                return newAdvisor.sort((a, b) => (a.activity_count || 0) - (b.activity_count || 0))
            case "most_closing":
                return newAdvisor.sort((a, b) => (b.closing_count || 0) - (a.closing_count || 0))
            case "least_closing":
                return newAdvisor.sort((a, b) => (a.closing_count || 0) - (b.closing_count || 0))
            case "most_showing/enquiry":
                return newAdvisor.sort((a, b) => ((b.enquiry_count ? ((b.activity_count || 0) / b.enquiry_count) : 0) - (a.enquiry_count ? ((a.activity_count || 0) / a.enquiry_count) : 0)))
            case "most_closing/showing":
                return newAdvisor.sort((a, b) => ((b.activity_count ? ((b.closing_count || 0) / b.activity_count) : 0) - (a.activity_count ? ((a.closing_count || 0) / a.activity_count) : 0)))
            default:
                return advisors
        }
    }

    const generateOptionByButton = (type: string) => {
        if (type) {
            return sorts[type as keyof ISort].map((item) => (
                <Button
                    size='large'
                    key={item.value}
                    className={classes.button}
                    fullWidth
                    variant='outlined'
                    color="primary"
                    onClick={() => handleChangeFilter(type, item.value)}
                >
                    {item.label}
                </Button>
            ))
        }

        return null
    }

    const handleMenuFilter = (menu: string) => {
        if (menuFilter === menu) return setMenuFilter('')

        setMenuFilter(prev => menu)
    }

    const fetchDetail = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/captain-pm-dashboard/list`, {
            params: {
                type: filter.type,
                month: filter.month
            }
        })
            .then(res => res.data)
            .then(res => {
                setAdvisors(res)
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (menuFilter) {
            window.document.body.style.overflow = 'hidden'
        } else {
            window.document.body.style.overflow = 'auto'
        }
        const timeout = setTimeout(() => {
            setIsLoading(false)
        }, 200)

        return () => {
            clearTimeout(timeout)
        }
    }, [menuFilter, filter.sort_by])


    useEffect(() => {
        fetchDetail()
        // eslint-disable-next-line
    }, [filter.type, filter.month])

    return (
        <MenuBar
            title='Advisor Performance'
        >
            <Root>

                <LoadingScreen open={isLoading} fullScreen />
                <Box className={classes.buttonContainer}>
                    <ButtonGroup
                        disableElevation
                        size='large'
                        style={{ background: '#fff' }}
                    >
                        <Button
                            className={classes.button}
                            variant={menuFilter === 'type' ? 'contained' : 'outlined'}
                            onClick={() => handleMenuFilter('type')}
                            startIcon={<InsertChartIcon />}
                        >
                            {sorts['type'].find(item => item.value === filter.type)?.label}
                        </Button>
                        {
                            filter.type === 'monthly' ?
                                <Button
                                    className={classes.button}
                                    variant={menuFilter === 'month' ? 'contained' : 'outlined'}
                                    onClick={() => handleMenuFilter('month')}
                                    startIcon={<CalendarMonthIcon />}
                                >
                                    {sorts['month'].find(item => item.value === filter.month)?.label}
                                </Button>
                                : null
                        }
                        <Button
                            className={classes.button}
                            variant={menuFilter === 'sort_by' ? 'contained' : 'outlined'}
                            onClick={() => handleMenuFilter('sort_by')}
                            startIcon={<SortIcon />}
                        >
                            Sort
                        </Button>
                    </ButtonGroup>
                    {
                        !!menuFilter ?
                            <>
                                <ButtonGroup
                                    disableElevation
                                    fullWidth
                                    orientation='vertical'
                                    style={{ background: '#fff' }}

                                >
                                    {generateOptionByButton(menuFilter)}
                                </ButtonGroup>
                                <Box onClick={() => setMenuFilter('')} className={classes.overlay}>
                                </Box>
                            </>
                            : null
                    }
                </Box>
                <Box
                    className={`${classes.content} ${classes.firstContent}`}
                >
                    {
                        advisors && advisors.length ?
                            sortedAdvisor(filter.sort_by).map(advisor => (
                                <>
                                    <TargetCard
                                        key={advisor.id}
                                        advisorData={advisor}
                                        type={filter.type as 'monthly' | 'yearly'}
                                        isCaptain
                                        month={filter.type === 'monthly' ? sorts['month'].find(item => item.value === filter.month)?.label : ''}
                                    />
                                </>
                            ))
                            : null
                    }
                </Box>
            </Root>
        </MenuBar>
    )
}

const getMonths = (substract: number) => {
    const date = new Date().setMonth(new Date().getMonth() - substract)
    const newDate = new Date(date)
    return `${format(newDate, 'LLL yyyy')}`
}

const sorts: ISort = {
    'type': [
        { label: 'Bulanan', value: 'monthly' },
        { label: 'Tahunan', value: 'yearly' },
    ],
    'month': [
        { label: getMonths(0), value: 0 },
        { label: getMonths(1), value: 1 },
        { label: getMonths(2), value: 2 },
        { label: getMonths(3), value: 3 },
        { label: getMonths(4), value: 4 },
        { label: getMonths(5), value: 5 },
    ],
    'sort_by': [
        { "label": 'None', "value": "none" },
        { "label": "Name", "value": "name" },
        { "label": "Most Revenue", "value": "most_revenue" },
        // { "label": "Least Revenue", "value": "least_revenue" },
        { "label": "Most Enquiry", "value": "most_enquiry" },
        // { "label": "Least Enquiry", "value": "least_enquiry" },
        { "label": "Most Showing", "value": "most_showing" },
        // { "label": "Least Showing", "value": "least_showing" },
        { "label": "Most Closing", "value": "most_closing" },
        // { "label": "Least Closing", "value": "least_closing" },
        { "label": 'Most Showing Rate', "value": "most_showing/enquiry" },
        { "label": 'Most Closing Rate', "value": "most_closing/showing" },
    ]
}

const useStyles = generateStyle((theme: Theme) =>
({
    buttonContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 999,
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            top: 56,
            left: 0,
            right: 0,
        }
    },
    content: {
        paddingLeft: 12,
        paddingRight: 12
    },
    firstContent: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '70px'
        }
    },
    button: {
        padding: '7px 7px',
        flexGrow: 1,
        height: 50,
        textTransform: 'capitalize',
        fontWeight: '400',
        '&.MuiButton-outlinedPrimary': {
            backgroundColor: '#fff',
            color: '#484848',
            border: '2px solid #E7E7E7'
        },
        '.MuiButton-containedPrimary': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            border: `1px solid ${theme.palette.primary.main}`
        }
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.2)',
        position: 'relative',
        width: '100%',
        height: 3000,
        touchAction: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}), "DashboardPM_Captain"
)


export default DashboardPMCaptain
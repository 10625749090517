import React, { useReducer, useEffect, SyntheticEvent, useState } from 'react';
import Swal from 'sweetalert2';

// Input
import { Paper, Theme, Grid, TextField, Button } from '@mui/material';

// Components
import ButtonUpload from '../../_components/ButtonUpload';
import PhotoPreview from '../../_components/PhotoPreview';
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { numberToCurrency, renderWarningButton, resizeAndResetOrientationImage } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    mode: "add" | "edit",
    onSubmitSuccess: () => void,
    listingId: null | number
}

interface IErrorState {
    name: string,
    default_price: string,
    stock: string
}

interface IState {
    name: string,
    default_price: number | string,
    stock: number | string,
    notes: string
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

interface IErrorAction {
    name: string;
    value: object | Date | null | string | number;
}

interface IThumbnailState {
    url: string,
    path: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
        margin: theme.spacing(2),
        marginTop: theme.spacing(5)
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    paper_label: {
        position: "absolute",
        marginTop: "-45px",
        padding: "1px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)"
    },
    paper_label_text: {
        backgroundColor: "#0094ff",
        fontSize: "1.5em",
        padding: "13px",
        borderRadius: "4px",
        color: "white"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        paddingTop: '10px'
    }
}), "ListingInventory_Form"
)

const ListingInventoryForm = (props: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(false);
    const [thumbnail, setThumbnail] = useState<IThumbnailState>({} as IThumbnailState);

    const numbers = [
        'default_price'
    ]

    // Error State
    const errorReducer = (state: IErrorState, action: IErrorAction) => {
        return {
            ...state,
            [action.name]: action.value
        }
    };

    const [errorState, setErrorState] = useReducer(errorReducer, {
        name: '',
        default_price: '',
        stock: ''
    });

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                default_price: 0,
                stock: 0,
                notes: ''
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        name: '',
        default_price: 0,
        stock: 0,
        notes: ''
    });

    useEffect(() => {
        if (props.mode === 'edit') {
            DefaultAxios
                .get(`${API_URL}/listing-inventory/${props.listingId}`)
                .then(res => {
                    const data = res.data;
                    for (let i in data) {
                        if (numbers.findIndex(item => item === i) !== -1) {
                            data[i] = numberToCurrency(data[i]);
                        } else if (i === 'filepath' && data[i]) {
                            setThumbnail({ path: data[i], url: data.fileurl })
                        }
                    }
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            if (numbers.findIndex(item => item === target.name) !== -1) {
                value = numberToCurrency(value);
            }
            setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
        }
    }

    const checkValidation = (): boolean => {
        let isValid = true;

        if (!inputState.name) {
            setErrorState({ name: 'name', value: 'Nama harus diisi' });
            isValid = false;
        } else {
            setErrorState({ name: 'name', value: '' });
        }

        if (!inputState.default_price) {
            setErrorState({ name: 'default_price', value: 'Default harga harus diisi' });
            isValid = false;
        } else if (+inputState.default_price < 0) {
            setErrorState({ name: 'default_price', value: 'Minimal Default Harga 0' });
            isValid = false;
        } else {
            setErrorState({ name: 'default_price', value: '' });
        }

        if (/* !inputState.stock ||  */+inputState.stock < 0) {
            setErrorState({ name: 'stock', value: 'Minimal stock 0' });
            isValid = false;
        } else {
            setErrorState({ name: 'stock', value: '' });
        }

        return isValid;
    }

    const submitForm = () => {
        if (!checkValidation()) {
            return false;
        }

        const data = { ...inputState };
        for (let i in data) {
            if (numbers.findIndex(item => item === i) !== -1) {
                data[i as keyof IState] = String(data[i as keyof IState]).replace(/[^0-9]/g, '')
            }
        }

        let axios;

        if (props.mode === 'edit') {
            axios = DefaultAxios.patch(`${API_URL}/listing-inventory/${props.listingId}`, data)
        } else {
            axios = DefaultAxios.post(`${API_URL}/listing-inventory`, data);
        }

        setLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Listing Inventory Submitted",
                    // html: ,
                    icon: 'success',
                    onAfterClose: props.onSubmitSuccess,
                    timer: 1000
                });
            })
            .catch(error => {
                if (typeof error.response.status === 'undefined') {
                    Swal.fire({
                        title: "Script Error",
                        html: "Error pada script. Harap hubungi Admin",
                        icon: 'error',
                    });
                } else if (error.response.status === 422) {
                    let error_text = `<p>${error.response.data.message}</p>`;

                    if (typeof error.response.data.errors !== 'undefined') {
                        const error_lists = error.response.data.errors;
                        for (var k in error_lists) {
                            error_text += `<p>${error_lists[k]}</p>`;
                        }
                    }

                    Swal.fire({
                        title: "Validasi Error",
                        html: error_text,
                        icon: 'error',
                    });
                } else if (error.response.status === 500) {
                    Swal.fire({
                        title: "Server Error",
                        html: "Error pada server. Harap hubungi Admin",
                        icon: 'error',
                    });
                }
            })
    }

    const handleDelete = () => {
        renderWarningButton("You won't be able to revert this!")
            .then((result) => {
                if (result.value) {
                    DefaultAxios
                        .delete(`${API_URL}/listing-inventory/${props.listingId}`)
                        .then(_ => {
                            Swal.fire({
                                title: "Listing Inventory Deleted",
                                icon: 'success',
                                onAfterClose: props.onSubmitSuccess,
                                timer: 1000
                            });
                        })
                        .catch(error => {
                            if (+error.response.status === 422) {
                                Swal.fire({
                                    title: error.response.data,
                                    icon: 'error'
                                });
                            } else {
                                Swal.fire({
                                    title: 'Oops.. Something went wrong!!',
                                    icon: 'error'
                                });
                            }
                        });
                }
            })
    }

    const photoContainer = () => {
        if (props.mode === 'add') {
            return null;
        }

        return (
            <>
                <Grid item xs={12}>
                    <h3 style={{ marginBottom: '25px' }}>Tambah Foto:</h3>
                </Grid>
                <Grid item xs={12}>
                    {
                        typeof thumbnail.url !== 'undefined'
                            ? <PhotoPreview
                                index={0}
                                onRemoveFile={onRemoveFile}
                                src={thumbnail.url}
                                key={Math.random()}
                            />
                            : <ButtonUpload onChange={onFileAdd} />
                    }
                </Grid>
            </>
        );
    }

    const onFileAdd = (e: SyntheticEvent) => {
        setLoading(true);

        const target = e.target as HTMLInputElement;
        const files = { ...target.files as FileList };
        if (target && files) {
            if (files[0].size >= 5000000) {
                Swal.fire({
                    title: 'Error!',
                    text: `Failed: Max uploaded file is 5MB`,
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
                setLoading(false);
            } else {
                resizeAndResetOrientationImage(files[0])
                    .then((file: File) => {
                        const fd = new FormData();
                        fd.append('file', file);
                        DefaultAxios
                            .post(`${API_URL}/listing-inventory/${props.listingId}/submit-file`, fd)
                            .then(res => {
                                setThumbnail({ path: res.data, url: URL.createObjectURL(file) })
                                setLoading(false);
                            })
                            .catch(error => {
                                if (+error.response.status === 422) {
                                    Swal.fire({
                                        title: error.response.data,
                                        icon: 'error'
                                    });
                                } else {
                                    Swal.fire({
                                        title: 'Oops.. Something went wrong!!',
                                        icon: 'error'
                                    });
                                }
                                setLoading(false);
                            })

                    });
            }
        }
    }

    const onRemoveFile = () => {
        DefaultAxios
            .post(`${API_URL}/listing-inventory/${props.listingId}/remove-file`, { filepath: thumbnail.path })
            .then(res => {
                setThumbnail({} as IThumbnailState);
            })
            .catch(error => {
                if (+error.response.status === 422) {
                    Swal.fire({
                        title: error.response.data,
                        icon: 'error'
                    });
                } else {
                    Swal.fire({
                        title: 'Oops.. Something went wrong!!',
                        icon: 'error'
                    });
                }
            })
    }

    return (
        <Root>
            <Paper className={classes.root}>
                <LoadingScreen open={loading} fullScreen />
                <Grid container>
                    <Grid item xs={12} className={classes.grid}>
                        <TextField
                            error={!!errorState.name}
                            label="Nama"
                            variant="outlined"
                            name="name"
                            value={inputState.name}
                            onChange={handleInputChanged}
                            fullWidth
                            helperText={errorState.name}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.grid}>
                        <TextField
                            error={!!errorState.default_price}
                            label="Default Harga"
                            variant="outlined"
                            name="default_price"
                            value={inputState.default_price}
                            onChange={handleInputChanged}
                            fullWidth
                            helperText={errorState.default_price}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.grid}>
                        <TextField
                            error={!!errorState.stock}
                            label="Stock"
                            type="number"
                            variant="outlined"
                            name="stock"
                            value={inputState.stock}
                            onChange={handleInputChanged}
                            fullWidth
                            helperText={errorState.stock}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.grid}>
                        <TextField
                            label="Keterangan"
                            variant="outlined"
                            name="notes"
                            value={inputState.notes}
                            onChange={handleInputChanged}
                            fullWidth
                            multiline
                        />
                    </Grid>

                    {photoContainer()}

                    <Grid item xs={12} className={classes.gridSubmit}>
                        <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                            Submit
                        </Button>

                        {
                            props.mode === 'edit' &&
                            <Button variant="text" color="secondary" onClick={handleDelete} className={classes.delete_button}>
                                Delete
                            </Button>
                        }

                    </Grid>
                </Grid>
            </Paper>
        </Root>
    );
}

export default ListingInventoryForm;
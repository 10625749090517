import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, IconButton } from '@mui/material'
import React, { useState } from 'react'

/**
 * Components
 */
import TextArea from '../../../_components/_form/TextArea'
import DefaultAxios from '../../../_utils/DefaultAxios'

/**
 * Utils
 */
import { generalErrorHandler, renderSuccessButton } from '../../../_utils/Helper'

/**
 * Icons
 */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface IProps {
    open: boolean
    onClose: (refresh: boolean) => void
    id: number
}

export default function CancelReasonModal(props: IProps) {
    const [state, setState] = useState({
        id: props.id,
        canceled_reason: ''
    })

    const handleSubmit = () => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/cancel-activity`, state)
            .then(res => res.data)
            .then(res => res ? [renderSuccessButton('Activity Canceled'), props.onClose(true)] : null)
            .catch(err => generalErrorHandler(err))
    }

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                <IconButton
                    sx={{left: -10}}
                    onClick={() => props.onClose(false)}
                >
                    <ArrowBackIcon />
                </IconButton>
                Cancel Activity
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <TextArea
                            name='canceled_reason'
                            onChange={(e: any) => setState(prev => ({ ...prev, canceled_reason: e.target.value }))}
                            value={state.canceled_reason}
                            label='Cancel Reason'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color='primary'
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

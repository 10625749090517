import React, { useState, useReducer, useEffect } from 'react';
import { Container, Box, MenuItem, TextField, Paper, Dialog, AppBar, Slide, Toolbar, Typography, IconButton, Theme, Grid, Button } from '@mui/material';
import Swal from 'sweetalert2';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

// Components
import LoadingScreen from '../../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import { currencyToNumber, generalErrorHandler, numberToCurrency } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    closingId: string
    open: boolean;
    handleCloseModal: () => void
}

interface IState {
    transaction_amount: number | null,
    cogs: number | null,
    deposit_amount: number | null,
    deposit_type: number,
}

interface IErrorState {
    transaction_amount: string,
    cogs: string,
    deposit_amount: string,
    deposit_type: string,
}

interface IErrorAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative'
    },
    container: {
        backgroundColor: "#f3f3f3",
    },
    submit_button: {
        marginLeft: '8px'
    },
    headLabel: {
        fontWeight: "bold",
        padding: theme.spacing(0, 1),
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
        margin: theme.spacing(3, 1),
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
    paper_label: {
        position: "absolute",
        marginTop: "-45px",
        padding: "1px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)"
    },
    paper_label_text: {
        backgroundColor: "#0094ff",
        fontSize: "1.5em",
        padding: "13px",
        borderRadius: "4px",
        color: "white"
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'right',
        marginBottom: '10px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }

}), "CLosingModal_Edit"
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const ClosingModalEdit = ({ open, closingId, handleCloseModal }: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/new-closing';
    const [loading, setLoading] = useState(false);
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                transaction_amount: 0,
                cogs: 0,
                deposit_amount: 0,
                deposit_type: 0,
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IErrorAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                transaction_amount: '',
                cogs: '',
                deposit_amount: '',
                deposit_type: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [errorState, setErrorState] = useReducer(errorReducer, {
        transaction_amount: '',
        cogs: '',
        deposit_amount: '',
        deposit_type: '',
    });

    const [inputState, setInputState] = useReducer(inputReducer, {
        transaction_amount: null,
        cogs: null,
        deposit_amount: null,
        deposit_type: 0,
    });

    const checkValidation = () => {
        let isValid = true;
        let newError = { ...errorState };

        if (!inputState.transaction_amount) {
            newError.transaction_amount = 'Field wajib diisi';
            isValid = false;
        } else {
            newError.transaction_amount = '';
        }

        if (!inputState.cogs) {
            newError.cogs = 'Field wajib diisi';
            isValid = false;
        } else {
            newError.cogs = '';
        }

        if (!inputState.deposit_amount) {
            newError.deposit_amount = 'Field wajib diisi';
            isValid = false;
        } else {
            newError.deposit_amount = '';
        }

        setErrorState({ type: 'REPLACE_STATE', name: '', value: newError });
        return isValid;
    }

    useEffect(() => {
        if (open) {
            DefaultAxios
                .get(`${API_URL}/initial-form-old?old_closing_id=${closingId}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            if (e.target.name.includes('transaction_amount') || e.target.name.includes('cogs') || e.target.name.includes('deposit_amount')) {
                setInputState({ name: target.name, value: currencyToNumber(target.value.toString()), type: 'SET_ITEM' });
            } else {
                setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
            }
        }
    }

    const submitForm = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;
        const data = {
            old_closing_id: closingId,
            transaction_amount: inputState.transaction_amount,
            deposit_amount: inputState.deposit_amount,
            cogs: inputState.cogs,
            deposit_type: inputState.deposit_type
        }
        axios = DefaultAxios.post(`${API_URL}/old-update`, data)
        setLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Closing Updated",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        setInputState({ name: '', value: data, type: 'RESET_ITEM' });
                        setLoading(false);
                        handleCloseModal()
                    })
            })
            .catch(error => {
                generalErrorHandler(error);
                setLoading(false);
            });
    }

    return (
        <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth="md" closeAfterTransition TransitionComponent={Transition}>
            <Root>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseModal} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h6" className={classes.title}>Closing Edit</Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <LoadingScreen open={loading} fullScreen />
                <Container maxWidth={false} className={classes.container}>
                    <Box>
                        <Paper className={classes.paper}>
                            <div className={classes.root}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                label="Transaction Amount"
                                                name="transaction_amount"
                                                value={inputState.transaction_amount ? numberToCurrency(inputState.transaction_amount.toString()) : ''}
                                                onChange={handleChanged}
                                                error={!!errorState.transaction_amount}
                                                helperText={errorState.transaction_amount}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                label="Basic Price"
                                                name="cogs"
                                                value={inputState.cogs ? numberToCurrency(inputState.cogs.toString()) : ''}
                                                onChange={handleChanged}
                                                error={!!errorState.cogs}
                                                helperText={errorState.cogs}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                label="Deposit"
                                                name="deposit_amount"
                                                value={inputState.deposit_amount ? numberToCurrency(inputState.deposit_amount.toString()) : ''}
                                                onChange={handleChanged}
                                                error={!!errorState.deposit_amount}
                                                helperText={errorState.deposit_amount}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                select
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                label="Deposit Type"
                                                name="deposit_type"
                                                value={inputState.deposit_type}
                                                onChange={handleChanged}
                                            >
                                                <MenuItem value={0}>Full Owner</MenuItem>
                                                <MenuItem value={1}>50% Jendela 50% Owner</MenuItem>
                                                <MenuItem value={2}>Full Jendela</MenuItem>
                                                <MenuItem value={3}>50% Jendela 50% Agent</MenuItem>
                                                <MenuItem value={4}>Full Agent</MenuItem>
                                            </TextField>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} className={classes.gridSubmit}>
                                        <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>

                        </Paper>
                    </Box>
                </Container>
            </Root>
        </Dialog>
    );
}

export default ClosingModalEdit;
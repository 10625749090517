import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, Switch, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/**
 * Components
*/
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'
import ModalKeyUnit from './ModalKeyUnit'
import KeyLogList from '../../_unit/_components/KeyLogList'

/**
 * Utils
 */
import { isMobile } from '../../../_utils/Helper'
import { debounce } from 'lodash'
import { generateStyle } from '../../../_utils/DefaultStyle';

interface ExtendFormProps {
    open: boolean
    onClose: () => void
    isLoading: boolean
    setIsLoading: (isLoading: boolean) => void
}

const initialUnit = {
    apartment_name: '',
    is_entrusted_key: false,
    key_holder: '',
    unit_name: ''
}

const LogsForm = (props: ExtendFormProps) => {
    const [isScreenMobile, setIsScreenMobile] = useState(false)
    const [unitId, setUnitId] = useState<number | null>(null)
    const [unit, setUnit] = useState(initialUnit)

    const [refresh, setRefresh] = useState(new Date().getTime())

    const [keyUnitModal, setKeyUnitModal] = useState({
        open: false,
        type: ''
    })

    const { Root, classes } = useStyles()

    const handleChangeUnitCode = (name: string, value: any) => {
        setUnitId(value.id)
    }

    const handleChangeEntrustedKey = (e: any) => {
        setKeyUnitModal({
            open: true,
            type: e.target.checked ? 'Entrust' : 'Remove'
        })
    }

    const generateProcessData = (resData: any) => {
        if (resData) {
            setUnit(resData)
            return resData.logs
        } else {
            setUnit(initialUnit)
            return []
        }

    }

    useEffect(() => {
        const handleResize = debounce(() => {
            setIsScreenMobile(isMobile())
        }, 250)

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        if (!props.open) {
            setUnit(initialUnit)
            setUnitId(null)
        }
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="md"
            fullScreen={isScreenMobile}
            fullWidth
        >
            <Root>
                <DialogTitle>
                    <IconButton
                        sx={{ left: -10, p: 'relative' }}
                        onClick={props.onClose}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    Key Logs
                </DialogTitle>
                <DialogContent>
                    <Grid container className={classes.container}>
                        <Grid item xs={4} sx={{ mt: 1 }}>
                            <AsyncAutoComplete
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/unit`}
                                label='Unit Code'
                                name='unit_code'
                                onChange={handleChangeUnitCode}
                                clearable
                                onClear={() => setUnitId(null)}
                            />

                        </Grid>
                        <Grid item xs sx={{ ml: 1 }}>
                            {/* <Button
                            variant='contained'
                        // onClick={handleSearchUnitCode}
                        >
                            Search
                        </Button> */}
                        </Grid>
                        {
                            unitId &&
                            <Grid item xs={4}>
                                <FormControlLabel
                                    checked={unit.is_entrusted_key}
                                    control={<Switch />}
                                    onChange={handleChangeEntrustedKey}
                                    label={<strong>Toggle Titipkan Kunci</strong>}
                                    labelPlacement='top'
                                />
                            </Grid>
                        }
                    </Grid>

                    {
                        unitId ?
                            <>
                                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                <Grid container className={classes.unitContainer}>
                                    <Grid item xs={6} className={classes.unitDetail}>
                                        <Typography><strong>Apartment: </strong>{unit.apartment_name || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.unitDetail}>
                                        <Typography><strong>Key Status: </strong> {unit.is_entrusted_key ? unit.key_holder === 'Key Manager' ? 'Kunci di kantor' : "Kunci dipinjam" : "Tidak di Jendela"}</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.unitDetail}>
                                        <Typography><strong>Unit Detail: </strong> {unit.unit_name?.replace(/^Code:\s*\w+\s*\|\s*/, "") || "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.unitDetail}>
                                        <Typography><strong>Key Holder: </strong> {unit.is_entrusted_key ? unit.key_holder : "-"}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                            : null
                    }
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <KeyLogList
                                unitId={unitId}
                                API_URL={`${process.env.REACT_APP_API_URL}/unit-key-logs`}
                                processRes={generateProcessData}
                                withImage
                                refresh={refresh}
                            />
                        </Grid>
                    </Grid>
                    <ModalKeyUnit
                        open={keyUnitModal.open}
                        type={keyUnitModal.type}
                        id={unitId || 0}
                        onClose={(refresh) => {
                            setKeyUnitModal({
                                open: false,
                                type: ''
                            })

                            if (refresh) {
                                setRefresh(new Date().getTime())
                            }
                        }}
                    />
                </DialogContent>
            </Root>
        </Dialog>
    )
}

const useStyles = generateStyle(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    unitContainer: {
        display: 'flex',
    },
    unitDetail: {
        marginBottom: "10px"
    }
}), "Logs_Form")

export default LogsForm

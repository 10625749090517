import { Button, Dialog, DialogActions, DialogContent, Theme, DialogTitle, Grid, TextField, Tooltip, IconButton, AppBar, Toolbar, Typography, Slide } from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import Swal from 'sweetalert2';

/**
 * Transition
 */
import { TransitionProps } from '@mui/material/transitions';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/Group';
import CloseIcon from '@mui/icons-material/Close';
import MemberModal from './_components/MemberModal';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IState {
    id: string;
    name: string;
    captain_id: string;
    captain_name: string;
}

interface IErrorState {
    name: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "AdvisorGroup_List"
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AdvisorGroupList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/advisor-group';
    const { Root, classes } = useStyles();

    const initialState = {
        id: '',
        name: '',
        captain_id: '',
        captain_name: ''
    };

    const initialErrorState = {
        name: ''
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    const [modalState, setModalState] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [groupId, setGroupId] = useState('');

    const columns = [
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true,
            render: (data: string, row: any) => {
                return (
                    <>
                        {data}
                        <Tooltip title="Change Advisor">
                            <IconButton color="primary" onClick={() => handleEdit(row.id)} size="small">
                                <EditIcon style={{ fontSize: "inherit" }} />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        },
        {
            name: 'captain_name',
            label: 'Captain Name',
            type: 'string',
            filterable: true
        },
        {
            name: 'advisor_group_member',
            label: 'Group Member',
            filterable: true,
            render: (data: string, row: any) => {
                let text: any = "";
                if (data) {
                    if (data.includes(",")) {
                        let new_data = data.split(",");

                        text = new_data.map((advisor_data, key) => {
                            if (advisor_data.includes('IS_NOT_ACTIVE')) {
                                let advisor_name = advisor_data.replace('IS_NOT_ACTIVE', '');

                                return (
                                    <span style={{ textDecoration: "line-through", color: "red" }}>
                                        {advisor_name}{key + 1 === new_data.length ? "" : ","}
                                    </span>
                                )
                            } else {
                                return (
                                    <span>{advisor_data}{key + 1 === new_data.length ? "" : ","}</span>
                                )
                            }
                        })
                    } else {
                        if (data.includes('IS_NOT_ACTIVE')) {
                            const advisor_name = data.replace('IS_NOT_ACTIVE', '');
                            text = (
                                <span style={{ textDecoration: "line-through", color: "red" }}>
                                    {advisor_name}
                                </span>
                            )
                        } else {
                            text = <span>{data}</span>
                        }
                    }
                } else {
                    text = "-";
                }

                return (
                    <>
                        {text}
                        <Tooltip title="Edit Advisor Placement">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => showAdvisorPlacementModal(String(row.id))}
                            >
                                <GroupIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    useEffect(() => {
        if (!modalState) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [modalState]);

    const showAdvisorPlacementModal = (id: string) => {
        setGroupId(id);
        setModalState(2);
    }

    const handleCloseMember = () => {
        setReDrawDatatable(new Date().getTime());
        setModalState(0);
    }

    const handleEdit = (id: string) => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL + '/' + id)
            .then(res => {
                const newState = {
                    ...initialState,
                    ...res.data
                };

                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                setModalState(1);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    // const handleDelete = (id: string) => {
    //     Swal.fire({
    //         title: 'Apakah Anda Yakin?',
    //         text: "Apakah anda yakin ingin menghapus grup ini?",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Ya'
    //     }).then((result) => {
    //         if (result.value) {
    //             setIsLoading(true);
    //             DefaultAxios
    //                 .delete(`${API_URL}/${id}`)
    //                 .then(() => {
    //                     Swal.fire({
    //                         title: "Berhasil menghapus",
    //                         icon: 'success',
    //                         timer: 1000
    //                     })
    //                         .then(res => {
    //                             setReDrawDatatable(new Date().getTime());
    //                         })
    //                 })
    //                 .catch(error => {
    //                     generalErrorHandler(error)
    //                 })
    //                 .finally(() => {
    //                     setIsLoading(false);
    //                 })
    //         }
    //     })
    // }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputState({ name: e.target.name, value: e.target.value, type: 'SET_ITEM' });
    }

    const handleAutocompleteChange = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChange = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.patch(API_URL + '/' + inputState.id, inputState);
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Group submitted",
                    icon: 'success',
                    onAfterClose: () => {
                        setReDrawDatatable(new Date().getTime());
                        setModalState(0);
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                variant="contained"
                color="primary"
                style={{ marginBottom: '15px' }}
                onClick={() => setModalState(1)}
            >
                Add New Group
            </Button>
            <Dialog
                open={modalState === 1}
                onClose={() => setModalState(0)}
                maxWidth="md"
            >
                <Root>
                    <DialogTitle>Form Group</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} sx={{mt: 0}}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Name"
                                    name="name"
                                    value={inputState.name}
                                    onChange={handleChange}
                                    error={!!errorState.name}
                                    helperText={errorState.name}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <AsyncAutoComplete
                                    url={process.env.REACT_APP_API_URL + '/autocomplete/user/advisor-captain'}
                                    label="Captain Name"
                                    name="captain_id"
                                    initialQuery={inputState.captain_name}
                                    onChange={handleAutocompleteChange}
                                    onInputChange={handleAutocompleteInputChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Root>
            </Dialog>
            <Dialog fullScreen open={modalState === 2} onClose={handleCloseMember} TransitionComponent={Transition}>
                <Root>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleCloseMember} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Advisor Placement
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </Root>
                <MemberModal
                    groupId={groupId}
                />
            </Dialog>
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Root>
    );
}

export default AdvisorGroupList;
export const getTransactionType = (type: string) => {
    switch (type) {
        case 'rent_owner':
            return 'Sewa Owner'
        case 'rent_agent':
            return 'Sewa Agent'
        case 'refund_tenant':
            return 'Refund Tenant'
        case 'deposit_owner':
            return 'Deposit Owner'
        case 'deposit_jendela':
            return 'Deposit Jendela'
        case 'commission_jendela':
            return 'Komisi Jendela'
        case 'commission_cobroke':
            return 'Komisi Cobroke'
        case 'reimburse_client':
            return 'Reimburse Client'
        case 'fixing_cleaning':
            return 'Fixing / Cleaning'
        case 'utilities':
            return 'Utilities'
        case 'manual':
            return 'Manual Assign'
    }
}

export const ctaType = {
    "cta_hero": "CTA Hero",
    "cta_benefit": "CTA Benefit",
    "cta_bottom": "CTA Bottom",
    "cta_mobile_float": "CTA Mobile - Float",
    "cta_mobile_bottom": "CTA Mobile - Bottom",
}

export const sourceLists = [
    { key: "whatsapp", value: "Whatsapp" },
    { key: "direct-wa-ads", value: "Direct WA Ads" },
    { key: "marketplace", value: "Marketplace" },
    { key: "referral-tr", value: "Referral TR" },
    { key: "instagram-dm", value: "Instagram DM" },
    { key: "facebook-dm", value: "Facebook DM" },
    { key: "tiktok", value: "Tiktok" },
    { key: "call-wa", value: "Call Whatsapp" },
    { key: "call-gsm", value: "Call GSM" },
];

export const mtsLists = [
    { key: 'direct', value: 'Direct Web' },
    { key: "direct_wa_ads", value: "Direct WA Ads" },
    { key: 'facebook_ads', value: 'Facebook Ads' },
    { key: 'instagram', value: 'Instagram' },
    { key: 'google_ads', value: 'Google Ads' },
    { key: 'seo', value: 'SEO' },
    { key: 'tiktok', value: 'Tiktok' },
    { key: 'marketplace', value: 'Marketplace' },
    { key: 'others', value: 'Others' },
];

export const marketplaceFromOptions = [
    {
        value: '',
        label: 'Pilih Marketplace From'
    },
    {
        value: 'sewaapartemennet',
        label: 'sewa-apartemen.net'
    },
    {
        value: 'olx',
        label: 'OLX'
    },
    {
        value: 'rumah123',
        label: 'Rumah123'
    },
    {
        value: 'rumah.com',
        label: 'Rumah.com'
    },
    {
        value: 'other',
        label: 'Lainnya'
    },
];

export const PAID_BY_OPTIONS = [
    { key: '', value: 'All', },
    { value: 'Owner', key: 'owner' },
    { value: 'Tenant', key: 'tenant' },
    { value: 'Deposit', key: 'deposit' },
    { value: 'Jendela360', key: 'office' },
]

import React, { SyntheticEvent } from 'react';
import classes from '../Assets/CSS/com.module.css';
import uploadLogo from '../Assets/Images/svg/upload.svg';

export interface ButtonUploadProps {
    onChange: (e: SyntheticEvent) => void,
    multiple?: boolean
    style?: React.CSSProperties
}

export interface ButtonUploadState {
    btnUploadWidth: number,
    inputValue: string
}

class ButtonUpload extends React.Component<ButtonUploadProps, ButtonUploadState> {
    inputFile: any;
    btnUpload: any;

    constructor(props: ButtonUploadProps) {
        super(props);
        this.state = {
            btnUploadWidth: 0,
            inputValue: ""
        }
        this.inputFile = React.createRef();
        this.btnUpload = React.createRef();
    }

    componentDidMount() {
        this.setState({ btnUploadWidth: this.btnUpload.current.offsetWidth - 2 });
        window.addEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ btnUploadWidth: this.btnUpload.current.offsetWidth - 2 });
    }

    triggerInputFile = () => {
        this.inputFile.current.click()
    }

    onChangeFile = (e: SyntheticEvent) => {
        this.props.onChange(e);
        this.setState({ inputValue: "" })
    }

    render() {
        return (
            <div className={classes.upGr} style={this.props.style}>
                <button
                    ref={this.btnUpload}
                    onClick={this.triggerInputFile}
                    style={{ height: this.state.btnUploadWidth }}
                    className={classes.btnUp}
                >
                    <img src={uploadLogo} alt="upload logo" />
                    <br />
                    Upload More
                </button>
                <input
                    type="file"
                    name="upload[]"
                    ref={this.inputFile}
                    value={this.state.inputValue}
                    accept="image/x-png,image/jpeg"
                    onChange={this.onChangeFile}
                    multiple={typeof this.props.multiple === 'undefined' ? false : this.props.multiple} />
            </div>
        )
    }
}

export default ButtonUpload;
import React, { Fragment, useState, useEffect } from 'react';
import { Grid, Button, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, IconButton, ButtonGroup, CircularProgress } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../LoadingScreen';
import Photo360Form from './Form';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { generalErrorHandler, renderWarningButton } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    API_URL: string;
}

export interface IState {
    id: string;
    caption: string;
    alt: string;
    thumbnail: string;
    file: File | null;
    thumbnail_360: string;
    file_360: File | null;
    hotspot: any[];
    sort: number;
}

const useStyles = generateStyle(() => ({
    submitButton: {
        float: 'right'
    },
    progressContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        zIndex: 1,
    },
    progressIcon: {
        marginRight: 16,
    }
}), "Photo360"
);

const Photo360FormPage = ({ API_URL }: Props) => {
    const { Root, classes } = useStyles();

    const defaultState: IState = {
        id: '',
        caption: '',
        alt: '',
        thumbnail: '',
        file: null,
        thumbnail_360: '',
        file_360: null,
        hotspot: [],
        sort: 0
    };

    const [formStates, setFormStates] = useState<IState[]>([]);
    const [imageStates, setImageStates] = useState<IState[]>([]);
    const [mode, setMode] = useState<'add' | 'edit'>('add');
    const [isLoading, setIsLoading] = useState(false);
    const [hotspots, setHotspots] = useState<any[]>([]);

    const [isUploadLoading, setIsUploadLoading] = useState(false)
    const [finishedUploadCount, setFinishedUploadCount] = useState(0)

    useEffect(() => {
        loadImages();
        // eslint-disable-next-line
    }, []);

    const loadImages = () => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL)
            .then(res => {
                const imageGallery = res.data;
                const newImageStates = [];

                for (let key in imageGallery) {
                    newImageStates.push({
                        id: imageGallery[key].id,
                        caption: imageGallery[key].caption,
                        alt: imageGallery[key].alt,
                        thumbnail: imageGallery[key].thumbnail,
                        file: null,
                        thumbnail_360: imageGallery[key].thumbnail_360,
                        file_360: null,
                        hotspot: imageGallery[key].hotspot ? JSON.parse(imageGallery[key].hotspot) : [],
                        sort: imageGallery[key].sort
                    })
                }

                setImageStates(newImageStates);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleNewData = () => {
        setMode('add');
        setFormStates([
            {
                ...defaultState
            }
        ])
        document.querySelectorAll('[type="file"]').forEach(el => {
            (el as HTMLInputElement).value = '';
        })
    }

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const oldStates: any[] = formStates.slice();
        const target = event.target as HTMLInputElement

        if (target.name === 'file') {
            if (target.files && target.files[0]) {
                const file = target.files[0];
                oldStates[index]['thumbnail'] = URL.createObjectURL(file);
                oldStates[index]['file'] = file;
            }
        } else if (target.name === 'file_360') {
            if (target.files && target.files[0]) {
                const file = target.files[0];
                oldStates[index]['thumbnail_360'] = URL.createObjectURL(file);
                oldStates[index]['file_360'] = file;
            }
        } else {
            oldStates[index][target.name] = target.value;
        }
        setFormStates(oldStates);
    }

    const formAction = (action: string) => {
        const oldState = formStates.slice();
        switch (action) {
            case 'add':
                oldState.push({ ...defaultState });
                break;
            case 'remove':
                oldState.splice(oldState.length - 1, 1);
                break;
            default:
                break;
        }
        setFormStates(oldState);
    }

    const handleHotspotsChange = (updatedHotspots: any[]) => {
        return new Promise<void>((resolve, reject) => {
            setIsLoading(true);
            DefaultAxios
                .post(API_URL + '/' + formStates[0].id + '/update-hotspots', { hotspots: updatedHotspots })
                .then(res => {
                    resolve();
                    loadImages();
                })
                .catch(err => {
                    generalErrorHandler(err);
                    reject();
                })
                .finally(() => {
                    setIsLoading(false);
                })
        });
    }

    const renderForms = () => {
        const formsEl = formStates.map((state, key) => {

            return <Fragment key={key}>
                <Grid item xs={12}>
                    <Photo360Form
                        form={state}
                        images={imageStates}
                        onChange={handleChanged}
                        hotspots={hotspots}
                        setHotspots={setHotspots}
                        index={key}
                        onHotspotsChange={handleHotspotsChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <hr />
                </Grid>
            </Fragment>
        });

        const actionButtons = (
            <>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    {
                        mode === 'add'
                            ? <ButtonGroup disableElevation variant="contained">
                                <Button color="primary" onClick={() => formAction('add')}>+</Button>
                                <Button color="secondary" onClick={() => formAction('remove')}>-</Button>
                            </ButtonGroup>
                            : null
                    }
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.submitButton}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </>
        )

        return (
            <>
                {formsEl}
                {formStates.length ? actionButtons : null}
            </>
        );
    }

    const handleEditImage = (imageState: IState) => {
        setMode('edit');
        setFormStates([
            {
                ...imageState
            }
        ])
        document.querySelectorAll('[type="file"]').forEach(el => {
            (el as HTMLInputElement).value = '';
        })
    }

    const handleDeleteImage = (imageState: IState) => {
        renderWarningButton("Apakah anda yakin ingin menghapus photo ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${API_URL}/${imageState.id}`)
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(res => {
                                    loadImages();
                                })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const checkValidation = () => {
        let isValid = true;

        formStates.map(formState => {
            if (!formState.file_360 && mode === 'add') {
                isValid = false;
            }

            if (!formState.caption) {
                isValid = false;
            }
            return true;
        });

        return isValid;
    }

    const handleSubmit = async () => {
        if (!checkValidation()) {
            Swal.fire({
                title: "Error",
                text: 'Please fill caption and 360 file on every form',
                icon: 'error'
            });
            return;
        }

        let url;

        if (mode === 'add') {
            url = API_URL;
            setIsUploadLoading(true)
            let failedIndex: number[] = []
            let failedFileName: string[] = []

            for (let i in formStates) {
                const fd = new FormData();
                for (let key in formStates[i]) {
                    let value = formStates[i][key as keyof IState];
                    if (value) {
                        if (key === 'file_360') {
                            fd.append(`param[${i}][image_360]`, value as File);
                        } else if (key === 'file') {
                            fd.append(`param[${i}][image]`, value as File);
                        } else {
                            fd.append(`param[${i}][${key}]`, String(value));
                        }
                    }
                }

                try {
                    const { data } = await DefaultAxios.post(url, fd)
                    if (!data) throw new Error('upload failed')
                } catch (err) {
                    failedIndex.push(Number(i))
                    const fileName = formStates[i].file_360?.name
                    if (fileName) {
                        failedFileName.push(fileName)
                    }
                }

                setFinishedUploadCount(prev => prev + 1)
            }

            setIsUploadLoading(false)

            setFormStates(prev => {
                return prev.filter((val, index) => failedIndex.includes(index))
            })

            if (failedFileName.length > 0) {
                Swal.fire({
                    title: "Submit Image gagal",
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Retry',
                    cancelButtonText: 'Close',
                    html:
                        '<div>' +
                        '<p>File yang gagal disubmit:</p>' +
                        failedFileName.reduce((acc, fileName) => {
                            return acc + `<p>${fileName}</p>`
                        }, '') +
                        '</div>'
                })
                    .then((res) => {
                        if (res.value) {
                            handleSubmit()
                        }
                    })
            } else {
                Swal.fire({
                    title: "Submit image berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        setFormStates([]);
                    })
            }

            loadImages();
            setFinishedUploadCount(0)
        } else {
            const fd = new FormData();

            for (let i in formStates) {
                for (let key in formStates[i]) {
                    let value = formStates[i][key as keyof IState];
                    if (value) {
                        if (key === 'file_360') {
                            fd.append('image_360', value as File);
                        } else if (key === 'file') {
                            fd.append('image', value as File);
                        } else {
                            fd.append(`${key}`, String(value));
                        }
                    }
                }
            }

            url = API_URL + '/' + formStates[0].id;
            fd.append('_method', 'PATCH');

            setIsLoading(true);
            DefaultAxios.post(url, fd)
                .then(res => {
                    Swal.fire({
                        title: "Submit photo berhasil",
                        icon: 'success',
                        timer: 1000
                    })
                        .then(res => {
                            loadImages();
                            setFormStates([]);
                        })
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }

    const handleSortImage = (imageId: string, dir: 'up' | 'down', key: number) => {
        setIsLoading(true);
        DefaultAxios
            .post(API_URL + '/' + imageId + '/sort-image', { dir })
            .then(res => {
                loadImages();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <LoadingScreen open={isUploadLoading} fullScreen>
                <div className={classes.progressContainer}>
                    <CircularProgress className={classes.progressIcon} />
                    <p>Uploading...{finishedUploadCount + 1}/{formStates.length}</p>
                </div>
            </LoadingScreen>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNewData}
                >
                    Add Image
                </Button>
                <hr />
            </Grid>

            {renderForms()}

            <Grid item xs={12}>
                <hr />
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Caption</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {imageStates.map((imageState, key) => (
                                <TableRow key={imageState.id}>
                                    <TableCell component="th" scope="row">
                                        {imageState.caption}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton aria-label="delete" color="primary" onClick={() => handleEditImage(imageState)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton aria-label="delete" color="secondary" onClick={() => handleDeleteImage(imageState)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleSortImage(imageState.id, 'up', key)}
                                            disabled={key === 0}
                                        >
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleSortImage(imageState.id, 'down', key)}
                                            disabled={key === imageStates.length - 1}
                                        >
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Root>
    );
}

export default Photo360FormPage;

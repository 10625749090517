import React, { useState, useEffect, useContext, Fragment, useMemo } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
    Select,
    MenuItem,
    Dialog,
    Grid,
    FormControlLabel,
    Switch,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    FormControl,
    Theme,
    SelectChangeEvent,
    Tooltip,
    useTheme
} from '@mui/material';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { format } from 'date-fns';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import ListingSubmissionApartmentModalDetail from './ListingSubmissionApartmentModalDetail';
import ListingSubmissionApartmentModalFailed, { listingSubmissionApartmentFailedType } from './ListingSubmissionApartmentModalFailed';
import WhatsappPreviewModal from '../../_components/_modal/WhatsappPreviewModal';
import GeneralFormModal from '../../_components/_modal/GeneralFormModal';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';
import { WhatsApp } from '@mui/icons-material';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, isMobile, renderWarningButton, renderToastSuccess } from '../../_utils/Helper';
import { ctaType } from '../../_utils/ConstData';
import { generateStyle } from '../../_utils/DefaultStyle';
import { IChats } from '../../_components/WhatsappPreview';

interface IProps {
    rent_id?: string;
    reload?: boolean | undefined;
    closeModal?: boolean | undefined;
}

interface IParams extends Record<string, string | undefined> {
    submission_code?: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    root: {
        display: 'inline-flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    edit_button: {
        // backgroundColor: '#fff',
        color: '#fff'
    },
    link: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        }
    }
}), "RentApartmentlist"
);

const rentListSummaryData = [
    { key: 'new_submission', value: 'New Submission' },
    { key: 'on_process', value: 'On Process' },
    { key: 'listed', value: 'Listed' },
    { key: 'rejected', value: 'Rejected' },
    { key: 'cold', value: 'Cold' },
]

const initialChatsData = {
    chats: [],
    code: '',
    created_at: ''
}

export default function ListingSubmissionApartmentList(props?: IProps) {
    const permissions = useContext(PermissionContext)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const RENT_URL = process.env.REACT_APP_API_URL + '/rent-apartment';
    const [openDetail, setOpenDetail] = useState<boolean>(false)
    const [openFailed, setOpenFailed] = useState(false)
    const [openCold, setOpenCold] = useState(false)
    const [moreDetail, setMoreDetail] = useState(false)
    const { Root, classes } = useStyles();
    const theme = useTheme()
    const navigate = useNavigate();
    const [queryString, setQueryString] = useState('')
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [showPercentage, setShowPercentage] = useState(false)
    const [showBreakdown, setShowBreakdown] = useState(false)

    const defaultInformation = { total: 0, percentage: 0 }
    const initialState = {
        'new_submission': defaultInformation,
        'on_process': defaultInformation,
        'listed': defaultInformation,
        'rejected': defaultInformation,
        'cold': defaultInformation,
    }
    const [rentListInformation, setRentListInformation] = useState(initialState)

    const params = useParams<IParams>()

    const [rentId, setRentId] = useState<string>('');
    const [submissionCode, setSubmissionCode] = useState('')

    const [chatsData, setChatsData] = useState<{
        chats: IChats[],
        code: string | null,
        created_at: string
    }>(initialChatsData)

    const breakdownFilter = useMemo(() => ({
        is_breakdown: showBreakdown ? '1' : '0'
    }), [showBreakdown])


    const renderScoreSummary = () => {
        const capitalizeFirstLetter = (string: string) => {
            return string.replace("_", " ").toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
        }

        return (
            <>
                {rentListSummaryData.map((status, idx) => {
                    return (
                        <Fragment key={status.key}>
                            <Typography>
                                {capitalizeFirstLetter(status.value)}: {rentListInformation[status.key as keyof typeof initialState].total} {`${showPercentage ? `(${rentListInformation[status.key as keyof typeof initialState].percentage})%` : ""}`}
                            </Typography>
                            {
                                idx !== rentListSummaryData.length - 1 ?
                                    <Typography style={{ marginRight: '10px', marginLeft: "10px" }}>
                                        |
                                    </Typography> :
                                    null
                            }
                        </Fragment>
                    )
                })}
            </>
        )
    }

    const handleEdit = (data: any) => {
        navigate('/listing-submission-apartment/edit/' + data.id)
    }

    const handleCloseDetail = (isRefresh?: boolean) => {
        window.history.pushState([], 'Listing Submission', `/listing-submission-apartment/${queryString ? `?${queryString}` : ''}`);
        if (isRefresh) setReDrawDatatable(new Date().getTime())
        setOpenDetail(false)
        setRentId('')
        setSubmissionCode('')
    }

    useEffect(() => {
        if (typeof params.submission_code === 'string') {
            setOpenDetail(true);
            setSubmissionCode(params.submission_code)
            window.history.pushState([], 'Listing Submission Apartment', `/listing-submission-apartment/${params.submission_code}`);
        }
    }, [params]);

    const FormFailedToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleFailedClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Rent Failed
                </Typography>
            </Toolbar>
        )
    }

    const changeFollowUp = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[], type: number) => {
        setIsLoading(true);
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/rent-apartment/process`, { id: row.id })
            .then(res => {
                renderToastSuccess('Status berhasil diubah');

                const newRows = rows.map((element: any) => {
                    if (element.id === row.id) element[columnName] = type
                    return element
                })
                setRows(newRows)
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const changeStatus = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[], type: number) => {
        const newRows = rows.map((element: any) => {
            if (element.id === row.id) element[columnName] = type
            return element
        })
        setRows(newRows)
    }

    const failedComment = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[], type: number) => {
        setRentId(row.id);
        setOpenFailed(prev => !prev);
    }

    const changeColdWithComment = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[], type: number) => {
        setRentId(row.id);
        setOpenCold(true);
    }

    const handleFailedClose = () => {
        setOpenFailed(false)
    };

    const submitChangeStatus = (type: number, name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
        switch (type) {
            case 0:
                changeStatus(name, row, columnName, options, setRows, rows, type);
                break
            case 2:
                failedComment(name, row, columnName, options, setRows, rows, type);
                break
            case 3:
                changeFollowUp(name, row, columnName, options, setRows, rows, type);
                break
            case 4:
                changeColdWithComment(name, row, columnName, options, setRows, rows, type);
                break
        }
    }

    const handleChangeStatus = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => (event: SelectChangeEvent<any>, child: React.ReactNode) => {
        let type = event.target.value
        let text = ' | Do you want to proceed ?';
        let needConfirmation = true
        switch (type) {
            case 0:
                text = 'Changing to New Submission' + text;
                break;
            case 2:
                text = 'Changing to Rejected' + text;
                needConfirmation = false
                break;
            case 3:
                text = 'Changing to On Process' + text;
                break;
            case 4:
                text = 'Changing to Cold' + text;
                needConfirmation = false
                break;
            default:
                text = 'undefined';
                break;
        }

        if (needConfirmation) {
            renderWarningButton(text).then((res) => {
                if (res.value) {
                    submitChangeStatus(type, name, row, columnName, options, setRows, rows)
                }
            })
        } else {
            submitChangeStatus(type, name, row, columnName, options, setRows, rows)
        }
    }

    const renderWa = (data: any, row: any) => {
        if (!data) {
            return '-'
        }

        let regex = /^08/;
        if (data.match(regex)) {
            data = data.replace(regex, "628")
        }
        let phoneNumber = data;

        return <a href={`https://api.whatsapp.com/send?phone=${phoneNumber}`} target='_blank' rel="noreferrer">{phoneNumber}</a>
    }

    const renderStatus = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
        return (
            row[columnName] !== 1 ?
                <FormControl>
                    <Select
                        value={row[columnName]}
                        onChange={handleChangeStatus(name, row, columnName, options, setRows, rows)}
                    >
                        {
                            row[columnName] !== 4 ?
                                <MenuItem value={0} disabled>New Submission</MenuItem>
                                : null
                        }
                        <MenuItem value={3}>On Process</MenuItem>
                        <MenuItem value={2}>Rejected</MenuItem>
                        <MenuItem value={4} disabled={row[columnName] === 4}>Cold</MenuItem>
                    </Select>
                </FormControl>
                : <span>Listed</span>
        )
    }

    const handleWhatsappPreview = (row: any) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/unit`, { params: { id: row.id } })
            .then(res => res.data)
            .then(res => {
                setChatsData({
                    chats: res,
                    code: row.code,
                    created_at: row.created_at
                })
            })
            .catch(err => generalErrorHandler(err))
    }

    const column = [
        {
            name: 'submission_at',
            label: 'Submission Time',
            type: 'date',
            filterable: true,
            sortable: false,
            render: (data: any, row: any) => {
                return format(new Date(data), 'dd LLL yyyy HH:mm:ss')
            }
        },
        {
            name: 'idle_time',
            label: 'Idle Time',
            type: 'number',
            filterable: true,
            sortable: false,
            render: (data: string) => data ? `${data} Hari` : '-',
            columnStyle: (row: any) => ({ backgroundColor: (Number(row.idle_time) || 0) > 7 ? theme.palette.error.main : 'white', color: (Number(row.idle_time) || 0) > 7 ? 'white' : theme.palette.text.primary }),
        },
        ...(
            permissions['whatsapp.manage-listing-submission'] ?
                [{
                    name: 'wa_id',
                    label: 'Whatsapp Preview',
                    type: 'string',
                    filterable: true,
                    options: [
                        {
                            key: '',
                            value: 'All',
                        },
                        {
                            key: 1,
                            value: 'Yes',
                        },
                        {
                            key: 0,
                            value: 'No',
                        },
                    ],
                    render: (data: any, row: any) => {
                        if (data) {
                            return (
                                <>
                                    <Tooltip title="Preview Chat">
                                        <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreview(row)} color='primary'>
                                            <WhatsApp />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )

                        } else {
                            return '-'
                        }
                    }
                }]
                : []
        ),
        {
            name: 'submission_code',
            label: 'Code',
            type: 'string',
            filterable: true,
            sortable: false,
            render: (data: any, row: any) => {
                return (
                    <>
                        <a
                            href={`/listing-submission-apartment/${row.submission_code}`}
                            className={classes.link}
                            onClick={e => {
                                e.preventDefault()
                                setRentId(row.id)
                                setSubmissionCode(row.submission_code)
                                window.history.pushState([], 'Listing Submission', `/listing-submission-apartment/${row.submission_code}`);
                                setOpenDetail(true)
                            }}
                        >
                            {data}
                        </a>
                        <div>{row.status === 1 ? `[${row.code}]` : ''}</div>
                    </>
                );
            }
        },
        {
            name: 'apartment_name',
            label: 'Apartment Name',
            type: 'string',
            filterable: true,
            sortable: false,
            render: (data: string) => data || '-'
        },
        ...(
            permissions['submission.admin'] ?
                [
                    {
                        name: 'cta',
                        label: 'CTA',
                        filterable: true,
                        sortable: false,
                        type: 'string',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: 'cta_hero',
                                value: 'CTA Hero'
                            },
                            {
                                key: 'cta_benefit',
                                value: 'CTA Benefit'
                            },
                            {
                                key: 'cta_bottom',
                                value: 'CTA Bottom'
                            },
                            {
                                key: 'cta_mobile_float',
                                value: 'CTA Mobile - Float'
                            },
                            {
                                key: 'cta_mobile_bottom',
                                value: 'CTA Mobile - Bottom'
                            },
                        ],
                        render: (data: null | "cta_hero" | "cta_benefit" | "cta_bottom" | "cta_mobile_float" | "cta_mobile_bottom", row: any) => {
                            return data ? ctaType[data] : "-"
                        }
                    },
                ] : []
        ),
        {
            name: 'page_source',
            label: 'Page Source',
            type: 'string',
            filterable: true,
            sortable: false,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'Default'
                },
                {
                    key: '1',
                    value: 'Campaign'
                },
            ],
            render: (data: any) => data !== null ? data.toString() === '0' ? 'Default' : 'Campaign' : '-',
        },
        {
            name: 'status',
            label: 'Status',
            type: 'string',
            filterable: true,
            sortable: false,
            defaultOption: '',
            render: renderStatus,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'New Submission'
                },
                {
                    key: '3',
                    value: 'On Process'
                },
                {
                    key: '1',
                    value: 'Listed'
                },
                {
                    key: '2',
                    value: 'Rejected'
                },
                {
                    key: '4',
                    value: 'Cold'
                },
            ]
        },
        ...(queryString.includes('status=2') ? [
            {
                name: 'failed_type_label',
                label: 'Failed Type',
                type: 'string',
                filterable: true,
                sortable: false,
                defaultOption: '',
                options: [
                    {
                        key: '',
                        value: 'All'
                    },
                    ...listingSubmissionApartmentFailedType.map(failedType => ({
                        key: failedType.value,
                        value: failedType.label,
                    }))
                ]
            },
            {
                name: 'failed_reason',
                label: 'Failed Reason',
                type: 'string',
                filterable: true,
                sortable: false,
            },
        ] : []),
        ...(queryString.includes('status=4') ? [
            {
                name: 'cold_type_label',
                label: 'Cold Type',
                type: 'string',
                filterable: true,
                sortable: false,
                defaultOption: '',
                options: [
                    {
                        key: '',
                        value: 'All'
                    },
                    ...listingSubmissionApartmentColdType.map(coldType => ({
                        key: coldType.value,
                        value: coldType.label,
                    }))
                ],
                render: (data: string) => data || '-',
            },
            {
                name: 'cold_reason',
                label: 'Cold Reason',
                type: 'string',
                filterable: true,
                sortable: false,
                render: (data: string) => data || '-',
            },
        ] : []),
        // {
        //     name: 'name',
        //     label: 'Name',
        //     type: 'string',
        //     filterable: true,
        //     sortable: true,
        // },
        // {
        //     name: 'is_paid_listing',
        //     label: 'Paid Listing',
        //     type: 'string',
        //     filterable: true,
        //     sortable: false,
        //     defaultOption: '',
        //     options: [
        //         {
        //             key: '',
        //             value: 'All'
        //         },
        //         {
        //             key: '1',
        //             value: 'Yes'
        //         },
        //         {
        //             key: '0',
        //             value: 'No'
        //         }
        //     ],
        //     render: (data: any, row: any) => {
        //         return data === 0 ? 'NO' : 'YES';
        //     }
        // },
        {
            name: 'ownership_type',
            label: 'Ownership Type',
            type: 'string',
            filterable: true,
            sortable: false,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'Agent'
                },
                {
                    key: '1',
                    value: 'Owner'
                }
            ],
            render: (data: any) => {
                return +data === 0 ? 'Agent' : 'Owner';
            }
        },
        // {
        //     name: 'address',
        //     label: 'Apartment / Alamat',
        //     type: 'string',
        //     filterable: true,
        //     sortable: true,
        // },
        // {
        //     name: 'advisor_name',
        //     label: 'Assigned PM',
        //     type: 'string',
        //     filterable: true,
        //     sortable: true,
        //     render: (data: string, row: any) => {
        //         let text: any = "";
        //         if (data) {
        //             if (data.includes(",")) {
        //                 let new_data = data.split(",");

        //                 text = new_data.map((advisor_data, key) => {
        //                     if (advisor_data.includes('IS_NOT_ACTIVE')) {
        //                         let advisor_name = advisor_data.replace('IS_NOT_ACTIVE', '');

        //                         return (
        //                             <span style={{ textDecoration: "line-through", color: "red" }}>
        //                                 {advisor_name}{key + 1 === new_data.length ? "" : ","}
        //                             </span>
        //                         )
        //                     } else {
        //                         return (
        //                             <span>{advisor_data}{key + 1 === new_data.length ? "" : ","}</span>
        //                         )
        //                     }
        //                 })
        //             } else {
        //                 if (data.includes('IS_NOT_ACTIVE')) {
        //                     const advisor_name = data.replace('IS_NOT_ACTIVE', '');
        //                     text = (
        //                         <span style={{ textDecoration: "line-through", color: "red" }}>
        //                             {advisor_name}
        //                         </span>
        //                     )
        //                 } else {
        //                     text = <span>{data}</span>
        //                 }
        //             }
        //         } else {
        //             text = "-";
        //         }

        //         return text;
        //     }
        // },
        // {
        //     name: 'email',
        //     label: 'Email',
        //     type: 'string',
        //     filterable: true,
        //     sortable: false,
        // },
        {
            name: 'requester_name',
            label: 'Requester Name',
            type: 'string',
            filterable: true,
            sortable: false,
            render: (data: string) => data || '-'
        },
        {
            name: 'requester_phone',
            label: 'Requester Phone',
            type: 'string',
            render: (data: any, row: any) => {
                return renderWa(data, row)
            },
            filterable: true,
            sortable: false,
        },
        {
            name: 'assigned_pic_name',
            label: 'PIC',
            type: 'string',
            filterable: true,
            render: (data: string) => data || '-'
        },
        ...(
            moreDetail ? [
                {
                    name: 'utm_source',
                    label: 'UTM Source',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_medium',
                    label: 'UTM Medium',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_campaign',
                    label: 'UTM Campaign',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'adsetname',
                    label: 'Adsetname',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_content',
                    label: 'UTM Content',
                    type: 'string',
                    filterable: true
                },
                // {
                //     name: 'keyword',
                //     label: 'Keyword',
                //     type: 'string',
                //     filterable: true
                // },
                // {
                //     name: 'adsetname',
                //     label: 'Adsetname',
                //     type: 'string',
                //     filterable: true
                // },
                // {
                //     name: 'adgroupid',
                //     label: 'Adgroupid',
                //     type: 'string',
                //     filterable: true
                // },
                // {
                //     name: 'creative',
                //     label: 'Creative',
                //     type: 'string',
                //     filterable: true
                // },
            ] : []
        ),
        // {
        //     name: 'listing_type',
        //     label: 'Listing Type',
        //     type: 'string',
        //     filterable: true,
        //     sortable: false,
        //     defaultOption: '',
        //     render: (data: any, row: any) => {
        //         let text = "undefined";
        //         switch (data) {
        //             case 0:
        //                 text = 'Apartment'
        //                 break;
        //             case 2:
        //                 text = 'Rumah'
        //                 break;
        //             case 3:
        //                 text = 'Tanah'
        //                 break;
        //             case 4:
        //                 text = 'Ruko'
        //                 break;
        //             case 5:
        //                 text = 'Kantor'
        //                 break;
        //             default:
        //                 text = "undefined-missing";
        //                 break;
        //         }

        //         return text;
        //     },
        //     options: [
        //         {
        //             key: '',
        //             value: 'All'
        //         },
        //         {
        //             key: '0',
        //             value: 'Apartment'
        //         },
        //         {
        //             key: '2',
        //             value: 'Rumah'
        //         },
        //         {
        //             key: '3',
        //             value: 'Tanah'
        //         },
        //         {
        //             key: '4',
        //             value: 'Ruko'
        //         },
        //         {
        //             key: '5',
        //             value: 'Kantor'
        //         },
        //         {
        //             key: '6',
        //             value: 'Gudang'
        //         },
        //         {
        //             key: '6',
        //             value: 'Ruang Usaha'
        //         }
        //     ]
        // },
        // {
        //     name: 'bedroom',
        //     label: 'Bedroom',
        //     type: 'string',
        //     filterable: true,
        //     sortable: true,
        //     render: renderBedroom
        // },

    ]

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            {/* {openDetail &&
                <Dialog
                    fullScreen
                    open={openDetail}
                    onClose={handleCloseDetail}
                    closeAfterTransition
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <FormToolbar></FormToolbar>
                    </AppBar>
                    <RentDetail permissions={permissions} />
                </Dialog>
            } */}
            <ListingSubmissionApartmentModalDetail
                open={openDetail}
                onClose={handleCloseDetail}
                onEdit={handleEdit}
                rent_id={rentId || undefined}
                submissionCode={submissionCode || undefined}
                parentQueryString={queryString}
            />

            {openFailed ?
                <Dialog
                    open={openFailed}
                    closeAfterTransition
                    fullWidth
                    maxWidth="md"
                    fullScreen={isMobile() ? true : false}
                // onClose={handleFailedClose}
                // TransitionComponent={Transition}
                >
                    <Root>
                        <AppBar className={classes.appBar}>
                            <FormFailedToolbar></FormFailedToolbar>
                        </AppBar>
                        <ListingSubmissionApartmentModalFailed
                            rentDetail_id={rentId}
                            open={openFailed}
                            closeModal={() => setOpenFailed(false)}
                            successCallback={() => {
                                setReDrawDatatable(new Date().getTime())
                            }}
                        />
                    </Root>
                </Dialog>
                : <></>
            }

            <GeneralFormModal
                title='Change Status to Cold'
                open={openCold}
                onClose={() => {
                    setOpenCold(false)
                    setRentId('')
                }}
                onSubmit={(state, onFinal) => {
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/rent-apartment/cold`, {
                        id: rentId,
                        cold_type: state.cold_type,
                        cold_reason: state.cold_reason,
                    })
                        .then(() => {
                            renderToastSuccess('Status berhasil diubah');
                            setOpenCold(false)
                            setRentId('')
                            setReDrawDatatable(new Date().getTime())
                        })
                        .catch(generalErrorHandler)
                        .finally(onFinal)
                }}
                forms={[
                    {
                        name: 'cold_type',
                        type: 'select',
                        defaultValue: '',
                        label: 'Type',
                        required: true,
                        alias: 'Type',
                        options: [
                            {
                                value: '',
                                label: 'Pilih Type',
                                disabled: true,
                            },
                            ...listingSubmissionApartmentColdType,
                        ]
                    },
                    {
                        name: 'cold_reason',
                        type: 'textArea',
                        defaultValue: '',
                        label: 'Reason',
                        required: true,
                        alias: 'Reaason',
                    },
                ]}
            />

            {showBreakdown &&
                <Grid container>
                    <Grid item xs={12} display="flex" flexDirection="row" justifyContent="center">
                        {renderScoreSummary()}
                    </Grid>
                </Grid>
            }

            <Grid container justifyContent="flex-end">
                {
                    permissions['submission.see-utm-detail'] &&
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={moreDetail}
                                    onChange={() => { setMoreDetail(!moreDetail) }}
                                    color="primary"
                                />
                            }
                            label="More Detail"
                        />
                    </Grid>
                }
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showBreakdown}
                                onChange={() => {
                                    setShowBreakdown(prev => !prev)
                                }}
                                color="primary"
                            />
                        }
                        label="Show Summary"
                    />
                </Grid>
            </Grid>

            <DataTable
                reDraw={reDrawDatatable}
                setParentQueryString={setQueryString}
                url={RENT_URL}
                columns={column}
                responseCallback={(res: any, seeTotal: boolean) => {
                    if (res?.meta?.current_page === 1) {
                        const checkNominalPercentage = (nominal: number, total: number) => {
                            return (total && nominal) ? (nominal / total * 100).toFixed(2) : 0
                        }
                        const data: any = {};
                        rentListSummaryData.forEach((status) => {
                            data[status.key as keyof typeof initialState] = {
                                total: res[status.key as keyof typeof initialState] || 0,
                                percentage: checkNominalPercentage(res[status.key as keyof typeof initialState], res.total)
                            }
                        })
                        setRentListInformation(data);
                        setShowPercentage(seeTotal)
                    }
                }}
                customParamsFromFirstPage={breakdownFilter}
                // Remove failed_type_label and failed_reason if status is not rejected
                processParamsBeforeSendingToAPI={params => ({
                    ...params,
                    failed_type_label: params.status === '2' ? params.failed_type_label : '',
                    failed_reason: params.status === '2' ? params.failed_reason : '',
                })}
            // customParams={customParams}
            />

            <WhatsappPreviewModal
                open={!!chatsData.chats.length}
                chats={chatsData.chats}
                code={chatsData.code}
                created_at={chatsData.created_at}
                onClose={() => {
                    setChatsData(initialChatsData)
                }}
            />
        </Root>
    );
}

export const listingSubmissionApartmentColdType = [
    {
        value: 'no_response',
        label: 'Tidak Respon',
    },
    {
        value: 'owner_no_detail',
        label: 'Owner Belum Info Detail Unit',
    },
    {
        value: 'other',
        label: 'Other',
    },
]
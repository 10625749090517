import { Box, Dialog, DialogContent, DialogTitle, Theme } from '@mui/material'
import React, { useState } from 'react'

import slider from '../Assets/Images/svg/slider.svg'
import { generateStyle } from '../_utils/DefaultStyle'

interface IProps {
    open: boolean
    onClose: () => void
    onSubmit: () => void
    customTitle?: React.ReactNode
    customBody?: React.ReactNode
}

const SliderConfirmation = (props: IProps) => {
    const { Root, classes } = useStyles()

    const [sliderVal, setSliderVal] = useState(0)

    const handleChangeSlider = (e: any) => {
        setSliderVal(+e.target.value)
    }

    const handleUpSlider = () => {
        if (sliderVal === 100) {
            props.onSubmit()
            props.onClose()
        } else {
            setSliderVal(0)
        }
        setSliderVal(0)
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="sm"
            sx={{
                "& .MuiDialog-paper": {
                    borderRadius: "25px",
                }
            }}
            fullWidth
        >
            <Root>

                {typeof props.customTitle !== 'undefined' ? props.customTitle :
                    <DialogTitle style={{ textAlign: 'center' }}>
                        Warning
                    </DialogTitle>
                }
                <DialogContent>
                    {typeof props.customBody !== 'undefined' ? props.customBody : null}
                    <Box component="div" className={classes.slider}>
                        <span className={classes.placeholder} style={{ opacity: `${100 - sliderVal}%` }}>Slide to Confirm</span>
                        <input
                            type="range"
                            value={sliderVal}
                            className={classes.sliderInput}
                            onMouseUp={handleUpSlider}
                            onTouchEnd={handleUpSlider}
                            onChange={handleChangeSlider}
                            placeholder="Slide to Confirm"
                            min={0}
                            max={100}
                        />
                    </Box>
                </DialogContent>
            </Root>
        </Dialog>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    header: {
        position: 'relative'
    },
    icon: {
        position: 'absolute',
        right: 6,
        top: 6,
        color: theme.palette.grey[500],
    },
    image: {
        width: '100px'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    slider: {
        display: 'flex',
        width: '100%',
        height: '64px',
        borderRadius: '32px',
        position: 'relative',
        marginTop: '10px',
        background: "#EFEFEF 0% 0% no-repeat padding-box",
    },
    sliderInput: {
        position: 'absolute',
        top: 0,
        left: 0,
        padding: "5px",
        background: 'transparent',
        margin: 0,
        appearance: 'none',
        height: '64px',
        borderRadius: '32px',
        width: '100%',
        zIndex: 1,
        pointerEvents: 'none',
        '&::-webkit-slider-thumb': {
            pointerEvents: 'auto',
            zIndex: 3,
            appearance: 'none',
            cursor: "grab",
            background: `url('${slider}')`,
            width: '54px',
            height: '54px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        },
        '&::-moz-range-thumb': {
            pointerEvents: 'auto',
            zIndex: 3,
            appearance: 'none',
            cursor: "grab",
            background: `url('${slider}')`,
            width: '54px',
            height: '54px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            border: 'none'
        }
    },
    placeholder: {
        zIndex: 1,
        margin: 'auto',
        color: '#484848'
    }
}), "SlideConfirmation"
)

export default SliderConfirmation
import React, { useReducer, useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    ButtonGroup,
    Button,
    MenuItem,
    Card,
    CardContent,
    Switch,
} from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import MuiDatePicker from '../../_components/MuiDatePicker';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';

// Icon
import List from '@mui/icons-material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import { isValidEmail, generalErrorHandler, numberToCurrency, convertNumber, inputNumber } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

export const useStyles = generateStyle(() => ({
    root: {
        maxWidth: '800px',
        marginBottom: '70px',
        boxShadow: '0px 3px 15px #0000001A',
        '& .Mui-disabled': {
            backgroundColor: '#eee'
        }
    },
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    subtitle: {
        fontSize: '20px'
    },
    smallSubtitle: {
        fontSize: '16px'
    },
    infoText: {
        color: '#000000',
        fontSize: '14px',
        opacity: '40%'
    },
    title: {
        fontSize: '34px',
        borderBottom: '1px solid #0000004D'
    },
    subTitle: {
        width: '100%',
        fontSize: '20px',
        borderBottom: '1px solid #0000004D'
    },
    subField: {
        fontSize: '16px',
        padding: '10px',
    },
    button: {
        padding: '10px'
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            marginBottom: '15px'
        }
    },
    listItem: {
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    deleteButton: {
        margin: '0 10px 0 20px',
        cursor: 'pointer'
    },
    submitContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& button': {
            marginLeft: '15px'
        }
    }
}), "CreditMemo_Form"
);

type MorePayment = {
    title: string
    amount: string
}

interface IState {
    [key: string]: any
    id: string;
    name: string;
    email: string;
    type: 'enquiry' | 'closing';
    cc: string;
    up_to: string;
    include_maintenance: boolean;
    closing_label: string;
    closing_id: string;
    crm_label: string;
    crm_id: string;
    unit_code: string;
    unit_id: string;
    start_date: Date | null;
    duration: string;
    transaction_amount: string;
    deposit_amount: string;
    payment_type: string;
    rental_fee_duration: string;
    rental_fee_amount: string;
    down_payment_amount: string;
    other_title: string;
    other_amount: string;
    requests: string[];
    notes: string[];
    disable_unit: boolean;
    morePayments: MorePayment[]
    lang: 'id' | 'en',
    is_manual_unit_information: boolean,
    apartment_id: number
    apartment_label: string
    tower: string
    floor_level: string
    unit_number: string
}

interface IErrorState {
    [key: string]: any;
    name: string;
    email: string;
    closing_id: string;
    crm_id: string;
    unit_id: string;
    start_date: string;
    duration: string;
    transaction_amount: string;
    deposit_amount: string;
    payment_type: string;
    rental_fee_duration: string;
    rental_fee_amount: string;
    other_amount: string;
    down_payment_amount: string;
    requests: string[];
    notes: string[];
    morePayments: MorePayment[]
    cc: string
    apartment_id: string
    tower: string
    floor_level: string
    unit_number: string
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const CreditMemoForm = () => {
    const { Root, classes } = useStyles();

    const currencyAttributes = [
        'transaction_amount',
        'deposit_amount',
        'down_payment_amount',
        'rental_fee_amount',
        'other_amount'
    ];

    const initialState: IState = {
        id: '',
        name: '',
        email: '',
        type: 'enquiry',
        cc: '',
        up_to: '',
        include_maintenance: false,
        closing_label: '',
        closing_id: '',
        crm_label: '',
        crm_id: '',
        unit_code: '',
        unit_id: '',
        start_date: null,
        duration: '',
        transaction_amount: '',
        deposit_amount: '',
        payment_type: '',
        rental_fee_duration: '',
        rental_fee_amount: '',
        down_payment_amount: '',
        other_title: '',
        other_amount: '',
        requests: [],
        notes: [],
        disable_unit: false,
        morePayments: [],
        lang: 'id',
        is_manual_unit_information: false,
        apartment_id: 0,
        apartment_label: "",
        tower: "",
        floor_level: "",
        unit_number: "",
    };

    const initialErrorState: IErrorState = {
        name: '',
        email: '',
        closing_id: '',
        crm_id: '',
        unit_id: '',
        start_date: '',
        duration: '',
        transaction_amount: '',
        deposit_amount: '',
        payment_type: '',
        rental_fee_duration: '',
        rental_fee_amount: '',
        down_payment_amount: '',
        other_amount: '',
        requests: [],
        notes: [],
        morePayments: [],
        cc: '',
        apartment_id: "",
        tower: "",
        floor_level: "",
        unit_number: "",
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            if (currencyAttributes.includes(action.name)) {
                return {
                    ...state,
                    // eslint-disable-next-line
                    [action.name]: action.value.replace(/\D/g, '')
                }
            }

            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);
    const [isLoading, setIsLoading] = useState(false);
    const [alreadyPreview, setAlreadyPreview] = useState(false);

    useEffect(() => {
        setAlreadyPreview(false);
    }, [inputState]);

    useEffect(() => {
        const newState = { ...inputState };
        newState.down_payment_amount = '';
        newState.rental_fee_duration = '';
        newState.rental_fee_amount = '';
        newState.other_title = '';
        newState.other_amount = '';

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
        // eslint-disable-next-line
    }, [inputState.payment_type]);

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = (e.target as HTMLInputElement);
        let value: any = target.value;

        if (target.type === 'checkbox') {
            value = target.checked;
        }

        if (target.name === 'is_manual_unit_information') {
            let newState = { ...inputState }
            if (value) {
                newState['is_manual_unit_information'] = value
                newState['unit_code'] = ""
                newState['unit_id'] = ""
            } else {
                newState['is_manual_unit_information'] = value
                newState['apartment_id'] = 0
                newState['apartment_label'] = ""
                newState['tower'] = ""
                newState['floor_level'] = ""
                newState["unit_number"] = ""
            }

            return setInputState({ name: "", value: newState, type: 'REPLACE_STATE' });
        }

        setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
    }

    const handleChangeAutoComplete = (name: string, value: IAutoCompleteOption | null) => {
        let newState = { ...inputState }

        if (value) {
            newState[name as keyof IState] = value.id
            newState[name.replace('id', 'label') as keyof IState] = value.label
        } else {
            newState[name as keyof IState] = ""
            newState[name.replace('id', 'label') as keyof IState] = ""
        }

        setInputState({
            name,
            type: 'REPLACE_STATE',
            value: newState
        })
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        const oldState: any = { ...inputState };
        oldState[name] = value.id;
        oldState['name'] = value.customer_name || oldState.name || ''
        oldState['email'] = value.customer_email || oldState.email || ''

        if (name === 'closing_id') {
            oldState['unit_id'] = value.unit_id || '';
            oldState['disable_unit'] = !!value.unit_id;
            oldState['unit_code'] = value.unit_code || '';
            oldState['start_date'] = new Date(value.start_date);
            oldState['duration'] = value.duration;
            oldState['transaction_amount'] = value.gmv_transaction;
            oldState['deposit_amount'] = value.deposit_amount;
            oldState['duration'] = value.duration_in_months;
            oldState['include_maintenance'] = value.is_include_maintenance === 1 ? true : false;
        } else {
            oldState['disable_unit'] = false;
        }

        setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: '', type: 'SET_ITEM' });
    }

    const handleListChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: 'requests' | 'notes' | 'morePayments', index: number, secondIndex?: string) => {
        const lists = inputState[name].slice();

        if (name === 'morePayments' && secondIndex) {
            (lists[index] as MorePayment)[secondIndex as keyof MorePayment] = secondIndex === 'amount' ? convertNumber(e.target.value) : e.target.value
        } else {
            lists[index] = e.target.value;
        }

        setInputState({ name, value: lists, type: 'SET_ITEM' });
    }

    const handleListDeleted = (name: 'requests' | 'notes' | 'morePayments', index: number) => {
        const lists = inputState[name].slice();
        lists.splice(index, 1);

        setInputState({ name, value: lists, type: 'SET_ITEM' });
    }

    const handleRadio = (name: string, value: any) => {
        if (name === 'type') {
            const newState = { ...inputState };
            newState[name] = value;
            newState['closing_id'] = '';
            newState['crm_id'] = '';

            setInputState({ name, value: newState, type: 'REPLACE_STATE' });
        } else {
            setInputState({ name, value, type: 'SET_ITEM' });
        }
    }

    // TODO: might break
    const handleDateChanged = (name: string, date: Date | null) => {
        setInputState({ name, value: date, type: 'SET_ITEM' });
    }

    const handleAddList = (name: 'requests' | 'notes' | 'morePayments') => {
        const lists = inputState[name].slice();

        if (name === 'morePayments') {
            (lists as MorePayment[]).push({
                title: '',
                amount: '',
            });
        } else {
            (lists as string[]).push('');
        }

        setInputState({ name, value: lists, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.email) {
            newError.email = 'Email wajib diisi';
            isValid = false;
        } else if (!isValidEmail(inputState.email, true)) {
            newError.email = 'Format email salah';
            isValid = false;
        }

        if (inputState.cc) {
            inputState.cc.split(',').forEach(c => {
                if (!isValidEmail(c.trim(), true)) {
                    newError.cc = 'Format email ada yang salah'
                    isValid = false
                }
            })
        }

        if (inputState.type === 'enquiry' && !inputState.crm_id) {
            newError.crm_id = 'Enquiry wajib diisi';
            isValid = false;
        }

        if (inputState.type === 'closing' && !inputState.closing_id) {
            newError.closing_id = 'Closing wajib diisi';
            isValid = false;
        }

        if (!inputState.unit_id) {
            if ((inputState.type === 'enquiry' && !inputState.is_manual_unit_information) || inputState.type === "closing") {
                newError.unit_id = 'Unit Code wajib diisi';
                isValid = false;
            }
        }

        if (inputState.type !== 'closing' && !inputState.start_date) {
            newError.start_date = 'Start Date wajib diisi';
            isValid = false;
        }

        if (inputState.type !== 'closing' && !inputState.duration) {
            newError.duration = 'Duration wajib diisi';
            isValid = false;
        }

        if (inputState.type !== 'closing' && !inputState.transaction_amount) {
            newError.transaction_amount = 'Transaction Amount wajib diisi';
            isValid = false;
        }

        if (inputState.type !== 'closing' && !inputState.deposit_amount) {
            newError.deposit_amount = 'Deposit Amount wajib diisi';
            isValid = false;
        }

        if (!inputState.payment_type) {
            newError.payment_type = 'Payment Type wajib diisi';
            isValid = false;
        }

        if (+inputState.payment_type === 0 && !inputState.down_payment_amount) {
            newError.down_payment_amount = 'Down Payment Amount wajib diisi';
            isValid = false;
        }

        if ([1, 2].includes(+inputState.payment_type) && !inputState.rental_fee_duration) {
            newError.rental_fee_duration = 'Rental Fee Duration wajib diisi';
            isValid = false;
        }

        if ([1, 2].includes(+inputState.payment_type) && !inputState.rental_fee_amount) {
            newError.rental_fee_amount = 'Rental Fee Amount wajib diisi';
            isValid = false;
        }

        if (+inputState.payment_type === 3 && !inputState.other_amount) {
            newError.other_amount = 'Other Amount wajib diisi';
            isValid = false;
        }

        for (let key in inputState.morePayments) {
            if (!inputState.morePayments[key].title) {
                newError.morePayments[key] = {
                    title: 'Payment title wajib diisi apabila di tambahkan',
                    amount: newError.morePayments[key]?.amount || '',
                }
                isValid = false;
            } else {
                newError.morePayments[key] = {
                    title: '',
                    amount: newError.morePayments[key]?.amount || '',
                }
            }

            if (!inputState.morePayments[key].amount) {
                newError.morePayments[key] = {
                    title: newError.morePayments[key]?.title || '',
                    amount: 'Payment amount wajib diisi apabila di tambahkan',
                }
                isValid = false;
            } else {
                newError.morePayments[key] = {
                    title: newError.morePayments[key]?.title || '',
                    amount: '',
                }
            }
        }

        for (let key in inputState.requests) {
            if (!inputState.requests[key]) {
                newError.requests[key] = 'Request wajib diisi apabila di tambahkan';
                isValid = false;
            } else {
                newError.requests[key] = '';
            }
        }

        for (let key in inputState.notes) {
            if (!inputState.notes[key]) {
                newError.notes[key] = 'Notes wajib diisi apabila di tambahkan';
                isValid = false;
            } else {
                newError.notes[key] = '';
            }
        }

        if (inputState.is_manual_unit_information) {
            ['apartment_id', 'tower', 'unit_number', 'floor_level'].forEach((key) => {
                if (!inputState[key as keyof IState]) {
                    newError[key as keyof IErrorState] = "Field ini wajib diisi apabila isi manual"
                    isValid = false
                }
            })
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const mapInputState = () => {
        const data: any = {};

        for (let [key, value] of Object.entries(inputState)) {
            switch (key) {
                case 'morePayments': {
                    const tmp: MorePayment[] = []
                    inputState.morePayments.forEach(payment => {
                        tmp.push({
                            title: payment.title,
                            amount: inputNumber(payment.amount),
                        })
                    })
                    data.more_payments = tmp
                    break
                }
                case 'requests': {
                    const tmp = [];
                    for (let request of inputState.requests) {
                        tmp.push({ description: request });
                    }
                    data.requests = tmp;
                    break
                }
                case 'notes': {
                    const tmp = [];
                    for (let note of inputState.notes) {
                        tmp.push({ description: note });
                    }
                    data.notes = tmp;
                    break
                }
                case 'email': {
                    data['email'] = inputState.email;
                    break
                }
                case 'cc': {
                    const ccs = inputState.cc.split(',').map(item => item.trim());
                    data['cc'] = ccs;
                    break
                }
                case 'deposit_amount':
                case 'down_payment_amount':
                case 'transaction_amount':
                case 'rental_fee_amount':
                case 'other_amount': {
                    data[key] = Number(value);
                    break
                }
                case "apartment_id":
                case "tower":
                case "floor_level":
                case "unit_number": {
                    if (inputState.type === 'enquiry' && inputState.is_manual_unit_information) {
                        data[key] = value
                    } else {
                        delete data[key]
                    }
                    break
                }
                case 'unit_id':
                case 'unit_code': {
                    if ((inputState.type === "enquiry" && inputState.is_manual_unit_information)) {
                        delete data[key]
                    } else {
                        data[key] = value
                    }
                    break
                }
                default: {
                    data[key] = value;
                }
            }
        }

        return data;
    }

    const handlePreview = () => {
        if (!checkValidation()) {
            return;
        }

        setIsLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/credit-memo/preview`, mapInputState(), { responseType: 'blob' })
            .then(res => {
                //Build a URL from the file
                const fileURL = URL.createObjectURL(res.data);

                //Open the URL on new Window
                window.open(fileURL);

                setAlreadyPreview(true);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        if (!alreadyPreview) {
            Swal.fire({
                title: 'Error',
                text: "Harap preview credit memo terlebih dahulu sebelum submit",
                icon: 'warning',
            });
            return;
        }

        setIsLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/credit-memo`, mapInputState())
            .then(res => {
                Swal.fire({
                    title: "Credit memo Submitted",
                    icon: 'success',
                    onAfterClose: () => {
                        window.location.reload();
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {

        document.body.style.backgroundColor = '#eee';
        let timeout: any;

        return () => {
            clearTimeout(timeout);
            document.body.style.backgroundColor = '#fff';
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let newInitialState = { ...initialState, type: inputState.type }
        setInputState({ type: 'REPLACE_STATE', name: '', value: newInitialState })

        // eslint-disable-next-line
    }, [inputState.type])

    return (
        <Root>
            <Card className={classes.root}>
                <CardContent>
                    <LoadingScreen open={isLoading} fullScreen />
                    <Grid container spacing={3}>
                        {/* enquiry/closing */}
                        <Grid item xs={12} className={classes.switchContainer} style={{ paddingBottom: 0 }}>
                            <Typography className={classes.smallSubtitle}>
                                Use Enquiry / Closing
                            </Typography>
                            <ButtonGroup disableElevation className={classes.button} variant="contained">
                                <Button
                                    color={inputState.type === 'enquiry' ? 'primary' : undefined}
                                    variant={inputState.type === 'enquiry' ? 'contained' : 'outlined'}
                                    onClick={() => handleRadio('type', 'enquiry')}
                                >
                                    Enquiry
                                </Button>
                                <Button
                                    color={inputState.type === 'closing' ? 'primary' : undefined}
                                    variant={inputState.type === 'closing' ? 'contained' : 'outlined'}
                                    onClick={() => handleRadio('type', 'closing')}
                                >
                                    Closing
                                </Button>
                            </ButtonGroup>
                        </Grid>

                        {/* Language */}
                        <Grid item xs={12} className={classes.switchContainer} style={{ paddingBottom: 0 }}>
                            <Typography className={classes.smallSubtitle}>
                                Language
                            </Typography>
                            <ButtonGroup disableElevation className={classes.button} variant="contained">
                                <Button
                                    color={inputState.lang === 'id' ? 'primary' : undefined}
                                    variant={inputState.lang === 'id' ? 'contained' : 'outlined'}
                                    onClick={() => handleRadio('lang', 'id')}
                                >
                                    Indonesia
                                </Button>
                                <Button
                                    color={inputState.lang === 'en' ? 'primary' : undefined}
                                    variant={inputState.lang === 'en' ? 'contained' : 'outlined'}
                                    onClick={() => handleRadio('lang', 'en')}
                                >
                                    English
                                </Button>
                            </ButtonGroup>
                        </Grid>

                        {/* enquiry/closing form */}
                        <Grid item xs={12}>
                            {
                                inputState.type === 'enquiry'
                                    ? <AsyncAutoComplete
                                        key={0}
                                        label="Enquiry"
                                        name="crm_id"
                                        url={process.env.REACT_APP_API_URL + '/admin/autocomplete/enquiry'}
                                        initialQuery={inputState.crm_label}
                                        onChange={handleAutocomplete}
                                        onInputChange={handleAutocompleteInputChanged}
                                        errorText={errorState.crm_id}
                                    />
                                    : <AsyncAutoComplete
                                        key={1}
                                        label="Closing"
                                        name="closing_id"
                                        url={process.env.REACT_APP_API_URL + '/admin/autocomplete/closing/credit-memo'}
                                        initialQuery={inputState.closing_label}
                                        onChange={handleAutocomplete}
                                        onInputChange={handleAutocompleteInputChanged}
                                        errorText={errorState.closing_id}
                                    />
                            }
                        </Grid>

                        {/* name */}
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Name"
                                name="name"
                                value={inputState.name}
                                onChange={handleChanged}
                                variant="outlined"
                                error={!!errorState.name}
                                helperText={errorState.name}
                            />
                        </Grid>

                        {/* up to */}
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Up To"
                                name="up_to"
                                value={inputState.up_to}
                                onChange={handleChanged}
                                variant="outlined"
                            />
                        </Grid>

                        {/* email */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                value={inputState.email}
                                onChange={handleChanged}
                                variant="outlined"
                                error={!!errorState.email}
                                helperText={errorState.email}
                            />
                        </Grid>

                        {/* email cc */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email CC"
                                name="cc"
                                value={inputState.cc}
                                onChange={handleChanged}
                                variant="outlined"
                                placeholder="Pisahkan dengan comma C: admin@jendela360.com,advisor@jendela360.com"
                                error={!!errorState.cc}
                                helperText={errorState.cc}
                            />
                        </Grid>

                        {
                            inputState.type === 'enquiry' &&
                            <>
                                <Grid item xs={12} className={classes.switchContainer} style={{ paddingBottom: 0 }}>
                                    <Typography className={classes.smallSubtitle}>
                                        Isi Manual
                                    </Typography>
                                    <Switch
                                        color="primary"
                                        // value={1}
                                        name="is_manual_unit_information"
                                        checked={inputState.is_manual_unit_information}
                                        onChange={handleChanged}
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                </Grid>
                                {
                                    inputState.is_manual_unit_information &&
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <AsyncAutoComplete
                                                    name='apartment_id'
                                                    initialQuery={inputState.apartment_label}
                                                    onChange={handleChangeAutoComplete}
                                                    url={`${process.env.REACT_APP_API_URL}/autocomplete/apartment`}
                                                    clearable
                                                    onClear={() => handleChangeAutoComplete('apartment_id', null)}
                                                    label="Apartment"
                                                    errorText={errorState.apartment_id}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    name="tower"
                                                    value={inputState.tower}
                                                    label="Tower"
                                                    onChange={handleChanged}
                                                    error={!!errorState.tower}
                                                    helperText={errorState.tower}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    name="floor_level"
                                                    value={inputState.floor_level}
                                                    label="Floor"
                                                    onChange={handleChanged}
                                                    error={!!errorState.floor_level}
                                                    helperText={errorState.floor_level}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    name="unit_number"
                                                    value={inputState.unit_number}
                                                    label="Unit No."
                                                    onChange={handleChanged}
                                                    error={!!errorState.unit_number}
                                                    helperText={errorState.unit_number}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </>
                        }

                        {/* unit code */}
                        {
                            (inputState.type === "enquiry" && inputState.is_manual_unit_information) ?
                                null :
                                <Grid item xs={12}>
                                    <AsyncAutoComplete
                                        label="Unit Code"
                                        name="unit_id"
                                        url={process.env.REACT_APP_API_URL + '/autocomplete/listing'}
                                        initialQuery={inputState.unit_code}
                                        onChange={handleAutocomplete}
                                        onInputChange={handleAutocompleteInputChanged}
                                        errorText={errorState.unit_id}
                                        disabled={inputState.type === 'closing'}
                                    />
                                </Grid>

                        }

                        {/* start date */}
                        <Grid item md={6} xs={12}>
                            <MuiDatePicker
                                label="Start Date"
                                value={inputState.start_date}
                                onChange={(date: any) => handleDateChanged('start_date', date)}
                                error={!!errorState.start_date}
                                helperText={errorState.start_date}
                            // disabled={inputState.type === 'closing'}
                            />
                        </Grid>

                        {/* duration */}
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                type="number"
                                label="Duration"
                                name="duration"
                                value={inputState.duration}
                                onChange={handleChanged}
                                variant="outlined"
                                error={!!errorState.duration}
                                helperText={errorState.duration}
                                // disabled={inputState.type === 'closing'}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">Bulan</InputAdornment>,
                                }}
                            />
                        </Grid>

                        {/* maintenance */}
                        <Grid item xs={12} className={classes.switchContainer} style={{ paddingBottom: 0 }}>
                            <Typography className={classes.smallSubtitle}>
                                Include Maintenance
                            </Typography>
                            <Switch
                                color="primary"
                                // value={1}
                                name="include_maintenance"
                                checked={inputState.include_maintenance}
                                onChange={handleChanged}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </Grid>

                        {/* Transaction Ammount Field */}
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Transaction Amount"
                                name="transaction_amount"
                                value={inputState.transaction_amount ? numberToCurrency(inputState.transaction_amount) : ''}
                                onChange={handleChanged}
                                variant="outlined"
                                error={!!errorState.transaction_amount}
                                helperText={errorState.transaction_amount}
                                // disabled={inputState.type === 'closing'}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                }}
                            />
                        </Grid>

                        {/* Deposit Amount */}
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Deposit Amount"
                                name="deposit_amount"
                                value={inputState.deposit_amount ? numberToCurrency(inputState.deposit_amount) : ''}
                                onChange={handleChanged}
                                variant="outlined"
                                error={!!errorState.deposit_amount}
                                helperText={errorState.deposit_amount}
                                // disabled={inputState.type === 'closing'}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                }}
                            />
                        </Grid>

                        {/* Payment Select */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Payment Type"
                                name="payment_type"
                                select
                                value={inputState.payment_type}
                                onChange={handleChanged}
                                variant="outlined"
                                error={!!errorState.payment_type}
                                helperText={errorState.payment_type}
                            >
                                <MenuItem value='0'>Down Payment</MenuItem>
                                <MenuItem value='1'>Rental Fee</MenuItem>
                                <MenuItem value='2'>Rental Fee + Deposit</MenuItem>
                                <MenuItem value='3'>Other</MenuItem>
                            </TextField>
                        </Grid>

                        {
                            inputState.payment_type === '0' &&
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Down Payment Amount"
                                    name="down_payment_amount"
                                    value={inputState.down_payment_amount ? numberToCurrency(inputState.down_payment_amount) : ''}
                                    onChange={handleChanged}
                                    variant="outlined"
                                    error={!!errorState.down_payment_amount}
                                    helperText={errorState.down_payment_amount}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        }

                        {
                            [1, 2].includes(+inputState.payment_type) &&
                            <>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Rental Fee Amount"
                                        name="rental_fee_amount"
                                        value={inputState.rental_fee_amount ? numberToCurrency(inputState.rental_fee_amount) : ''}
                                        onChange={handleChanged}
                                        variant="outlined"
                                        error={!!errorState.rental_fee_amount}
                                        helperText={errorState.rental_fee_amount}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Rental Fee Duration"
                                        name="rental_fee_duration"
                                        value={inputState.rental_fee_duration}
                                        onChange={handleChanged}
                                        variant="outlined"
                                        error={!!errorState.rental_fee_duration}
                                        helperText={errorState.rental_fee_duration}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">Bulan</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </>
                        }

                        {
                            inputState.payment_type === '3' &&
                            <>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Other Title"
                                        name="other_title"
                                        value={inputState.other_title}
                                        onChange={handleChanged}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Other Amount"
                                        name="other_amount"
                                        value={inputState.other_amount ? numberToCurrency(inputState.other_amount) : ''}
                                        onChange={handleChanged}
                                        variant="outlined"
                                        error={!!errorState.other_amount}
                                        helperText={errorState.other_amount}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </>
                        }

                        {/* More Payments */}
                        <Grid item xs={12} className={classes.listContainer}>
                            <Typography className={classes.subTitle} style={{ marginBottom: 24 }}>
                                <List />{" More Payments"}
                            </Typography>
                            {
                                inputState.morePayments.map((item, key) => {
                                    return <div className={classes.listItem} key={key}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            value={item.title}
                                            onChange={(e) => handleListChanged(e, 'morePayments', key, 'title')}
                                            error={!!errorState.morePayments[key]?.title}
                                            helperText={errorState.morePayments[key]?.title}
                                            placeholder='Title'
                                            sx={{ marginRight: 1 }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            value={convertNumber(item.amount)}
                                            onChange={(e) => handleListChanged(e, 'morePayments', key, 'amount')}
                                            error={!!errorState.morePayments[key]?.amount}
                                            helperText={errorState.morePayments[key]?.amount}
                                            placeholder='Amount'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                            }}
                                            sx={{ marginLeft: 1 }}
                                        />
                                        <DeleteIcon color="secondary" onClick={() => handleListDeleted('morePayments', key)} className={classes.deleteButton} />
                                    </div>
                                })
                            }
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAddList('morePayments')}
                                style={{ width: 140 }}
                            >
                                Add Payment
                            </Button>
                        </Grid>
                        {/* Requests */}
                        <Grid item xs={12} className={classes.listContainer}>
                            <Typography className={classes.subTitle} style={{ marginBottom: 24 }}>
                                <List />{" Requests"}
                            </Typography>
                            {
                                inputState.requests.map((item, key) => {
                                    return <div className={classes.listItem} key={key}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            value={item}
                                            onChange={(e) => handleListChanged(e, 'requests', key)}
                                            error={!!errorState.requests[key]}
                                            helperText={errorState.requests[key]}
                                        />
                                        <DeleteIcon color="secondary" onClick={() => handleListDeleted('requests', key)} className={classes.deleteButton} />
                                    </div>
                                })
                            }
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAddList('requests')}
                                style={{ width: 140 }}
                            >
                                Add Requests
                            </Button>
                        </Grid>

                        {/* Requests */}
                        <Grid item xs={12} className={classes.listContainer}>
                            <Typography className={classes.subTitle} style={{ marginBottom: 24 }}>
                                <List />{" Notes"}
                            </Typography>
                            {
                                inputState.notes.map((item, key) => {
                                    return <div className={classes.listItem} key={key}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            value={item}
                                            onChange={(e) => handleListChanged(e, 'notes', key)}
                                            error={!!errorState.notes[key]}
                                            helperText={errorState.notes[key]}
                                        />
                                        <DeleteIcon color="secondary" onClick={() => handleListDeleted('notes', key)} className={classes.deleteButton} />
                                    </div>
                                })
                            }
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAddList('notes')}
                                style={{ width: 140 }}
                            >
                                Add Notes
                            </Button>
                        </Grid>

                        <Grid item xs={12} className={classes.submitContainer}>
                            <Button
                                variant="contained"
                                onClick={handlePreview}
                            >
                                Preview
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Root>
    );
}

export default CreditMemoForm;
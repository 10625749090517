import React, { useState, useReducer, useEffect } from 'react';
import {
    Theme, BottomNavigation,
    BottomNavigationAction, Grid, TextField, Button, Link
} from '@mui/material';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from "react-router-dom";
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import TextEditor from '../../_components/_form/TextEditor'

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertNumberComaDecimal, generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    // permissions: any;
    id?: string
}

interface IState {
    name: string;
    latitude: string
    longitude: string
    lang: string
    slug: string
    area_id: string
    area: string
    highlight: string
    highlight_en: string
    meta_title: string
    meta_description: string
    meta_title_en: string
    meta_description_en: string
    primary_image: {
        url: string;
        file: File | null;
    }
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IErrorState {
    name: string;
    latitude: string
    longitude: string
    slug: string
    area_id: string
    area: string
    highlight: string
    meta_title: string
    meta_description: string
    meta_title_en: string
    meta_description_en: string
}

interface IErrorAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) => ({
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
        margin: theme.spacing(0, 1),
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'right',
        marginBottom: '10px'
    },
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        marginBottom: theme.spacing(2),
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
        // fontSize: '0.8125rem',
        // border: '1px solid #006db3',
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
    fileList: {
        listStyle: 'none',
        padding: 0,
        '& li': {
            display: 'flex'
        }
    },
    filePreview: {
        width: '65px',
        height: '65px',
        overflow: 'hidden',
        float: 'left',
        '& img': {
            width: '100%'
        }
    },
    fileDetail: {
        width: '100%',
        marginLeft: '10px',
        '& p': {
            margin: 0
        }
    }
}), "BasicDistrict_Form"
)

const BasicDistrictForm = (props: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/district';
    const [lang, setLang] = React.useState(0);
    const [loading, setLoading] = useState(false);
    const params: any = useParams();
    const navigate = useNavigate();

    // Error State
    const errorReducer = (state: IErrorState, action: IErrorAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                latitude: '',
                longitude: '',
                slug: '',
                area_id: '',
                area: '',
                highlight: '',
                meta_title: '',
                meta_description: '',
                meta_title_en: '',
                meta_description_en: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [errorState, setErrorState] = useReducer(errorReducer, {
        name: '',
        latitude: '',
        longitude: '',
        slug: '',
        area_id: '',
        area: '',
        highlight: '',
        meta_title: '',
        meta_description: '',
        meta_title_en: '',
        meta_description_en: '',
    });

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                latitude: '',
                longitude: '',
                lang: 'id',
                slug: '',
                area_id: '',
                area: '',
                highlight: '',
                highlight_en: '',
                meta_title: '',
                meta_description: '',
                meta_title_en: '',
                meta_description_en: '',
                primary_image: {
                    url: '',
                    file: null
                },
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newStateData = action.value;
            return {
                name: newStateData.name || '',
                latitude: newStateData.latitude || '',
                longitude: newStateData.longitude || '',
                lang: 'id',
                slug: newStateData.slug || '',
                area_id: newStateData.area_id || '',
                area: newStateData.area || '',
                highlight: newStateData.highlight || '',
                highlight_en: newStateData.highlight_en || '',
                meta_title: newStateData.meta_title || '',
                meta_description: newStateData.meta_description || '',
                meta_title_en: newStateData.meta_title_en || '',
                meta_description_en: newStateData.meta_description_en || '',
                primary_image: {
                    url: newStateData.primary_image || '',
                    file: newStateData.file || ''
                },
            };
        }
        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        name: '',
        latitude: '',
        longitude: '',
        lang: 'id',
        slug: '',
        area_id: '',
        area: '',
        highlight: '',
        highlight_en: '',
        meta_title: '',
        meta_description: '',
        meta_title_en: '',
        meta_description_en: '',
        primary_image: {
            url: '',
            file: null
        },
    });

    const checkValidation = () => {
        let isValid = true;
        let newError = { ...errorState };
        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        } else {
            newError.longitude = '';
        }
        if (!inputState.longitude) {
            newError.longitude = 'Longitude wajib diisi';
            isValid = false;
        } else {
            newError.longitude = '';
        }
        if (!inputState.latitude) {
            newError.latitude = 'Latitude wajib diisi';
            isValid = false;
        } else {
            newError.latitude = '';
        }
        setErrorState({ type: 'REPLACE_STATE', name: '', value: newError });
        return isValid;
    }

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;

            if (target.name === 'latitude' || target.name === 'longitude') {
                setInputState({ name: target.name, value: convertNumberComaDecimal(value), type: 'SET_ITEM' });
            }

            if (target.name === 'primary_image') {
                if (target.files && target.files[0]) {
                    value = { file: target.files[0], url: '' };
                } else {
                    value = null;
                }
                target.name = 'primary_image';
            }

            setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
        }
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name: 'area_id', value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name: 'area_id', value: '', type: 'SET_ITEM' });
    }

    const tabValue = (name: string, value: string) => {
        setInputState({ name, value: value, type: 'SET_ITEM' });
    }

    const handleDeleteAsset = () => {
        setLoading(true);
        DefaultAxios.delete(`${API_URL}/file/delete`, {
            params: {
                // lang: inputState.lang,
                // listing_type: inputState.listing_type,
                // sold_type: inputState.sold_type,
                // location_type: inputState.location_type,
                district_id: params.district_id
            }
        })
            .then(res => {
                setInputState({ name: 'primary_image', value: '', type: 'SET_ITEM' })
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const submitForm = () => {

        if (!checkValidation()) {
            return;
        }

        let axios;

        const fd = new FormData();

        for (let key in inputState) {
            if (key === 'primary_image') {
                if (inputState.primary_image.file) {
                    fd.append('file', inputState.primary_image.file as File)
                }
            } else {
                fd.append(key, String(inputState[key as keyof IState]));
            }
        }

        if (params.district_id) {
            fd.append('_method', 'patch');
            axios = DefaultAxios.post(`${API_URL}/${params.district_id}`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setLoading(true);
            axios
                .then(res => {
                    Swal.fire({
                        title: "District Updated",
                        icon: 'success',
                        onAfterClose: () => {
                            navigate(`/district`)
                        },
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        } else {
            axios = DefaultAxios.post(`${API_URL}`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setLoading(true);
            axios
                .then(res => {
                    Swal.fire({
                        title: "District Submitted",
                        icon: 'success',
                        onAfterClose: () => {
                            navigate(`/district`)
                        },
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        if (params.district_id) {
            setLoading(true);
            DefaultAxios
                .get(`${API_URL}/${params.district_id}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });

                    // setInputState({ name: inputState.primary_image.url, value: data.primary_image || '', type: 'REPLACE_STATE' })
                    setLoading(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Root>
            <LoadingScreen open={loading} fullScreen />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        error={!!errorState.name}
                        label="Name"
                        variant="outlined"
                        name="name"
                        value={inputState.name}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={errorState.name}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        error={!!errorState.slug}
                        label="Slug"
                        variant="outlined"
                        name="slug"
                        value={inputState.slug}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={errorState.slug}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        label="Area"
                        name="area_id"
                        initialQuery={inputState.area}
                        onChange={handleAutocomplete}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/area`}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        error={!!errorState.longitude}
                        label="Longitude"
                        variant="outlined"
                        name="longitude"
                        // type="number"
                        value={inputState.longitude}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={errorState.longitude}
                        placeholder="For Jakarta, ex: 106.0253"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Latitude"
                        name="latitude"
                        value={inputState.latitude}
                        onChange={handleInputChanged}
                        variant="outlined"
                        // type="number"
                        fullWidth
                        error={!!errorState.latitude}
                        helperText={errorState.latitude}
                        placeholder="For Jakarta, ex: -6.1745"
                    />
                </Grid>
                <Grid item xs={12}>
                    <h3>Highlight</h3>
                    <Grid item xs={12} sm={6} md={2}>
                        <BottomNavigation
                            value={lang}
                            onChange={(event, newValue) => {
                                setLang(newValue);
                            }}
                            showLabels
                            className={classes.filter}
                        >
                            <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('lang', 'id')} label="Indonesia" />
                            <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} onClick={() => tabValue('lang', 'en')} label="Inggris" />
                        </BottomNavigation>
                    </Grid>
                    {
                        lang === 0 ?
                            <TextEditor
                                name="highlight"
                                value={inputState.highlight}
                                onChange={(name: string, value: string) => setInputState({ name: 'highlight', value: value, type: 'SET_ITEM' })}
                            />
                            : <TextEditor
                                name="highlight_en"
                                value={inputState.highlight_en}
                                onChange={(name: string, value: string) => setInputState({ name: 'highlight_en', value: value, type: 'SET_ITEM' })}
                            />
                    }
                </Grid>

                <Grid item xs={12} sm={12}>
                    <h3>Upload Gambar Utama</h3>
                    {
                        inputState.primary_image.url ?
                            <ul className={classes.fileList}>
                                <li>
                                    <img src={inputState.primary_image.url} alt={inputState.primary_image.url} height="200px" />
                                    <div className={classes.fileDetail}>
                                        <Link
                                            component="button"
                                            color="secondary"
                                            onClick={() => handleDeleteAsset()}
                                        >
                                            Delete
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                            : null
                    }
                    <input type="file" name="primary_image" onChange={handleInputChanged} style={{ marginBottom: '10px' }} />
                </Grid>

                <h2>Meta</h2>
                <Grid item xs={12}>
                    <h3>[ID] Meta</h3>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                error={!!errorState.meta_title}
                                label="Title"
                                variant="outlined"
                                name="meta_title"
                                value={inputState.meta_title}
                                onChange={handleInputChanged}
                                fullWidth
                                helperText={errorState.meta_title}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!errorState.meta_description}
                                label="Description"
                                variant="outlined"
                                name="meta_description"
                                value={inputState.meta_description}
                                onChange={handleInputChanged}
                                fullWidth
                                helperText={errorState.meta_description}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <h3>[EN] Meta</h3>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                error={!!errorState.meta_title_en}
                                label="Title"
                                variant="outlined"
                                name="meta_title_en"
                                value={inputState.meta_title_en}
                                onChange={handleInputChanged}
                                fullWidth
                                helperText={errorState.meta_title_en}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!errorState.meta_description_en}
                                label="Description"
                                variant="outlined"
                                name="meta_description_en"
                                value={inputState.meta_description_en}
                                onChange={handleInputChanged}
                                fullWidth
                                helperText={errorState.meta_description_en}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.gridSubmit}>
                    <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Root>
    );
}

export default BasicDistrictForm;
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import ModalDetail from '../../../../_components/_modalDetail/ModalDetail'
import { Button, capitalize, Grid, IconButton } from '@mui/material'
import SimpleDataTable from '../../../../_components/_dataTable/SimpleDataTable'
import ContractAddModal from './ContractAddModal'

/**
 * Utils
*/
import { generateStyle } from '../../../../_utils/DefaultStyle'
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { generalErrorHandler, openExternalLink, renderQuestionButton, renderSuccessButton } from '../../../../_utils/Helper'

/**
 * Icons
 */
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadContractModal from './DownloadContractModal'
import { format } from 'date-fns'
import DescriptionIcon from '@mui/icons-material/Description';
import PartialDocusignForm from './PartialDocusignForm'
import VoidResendReasonModal from './VoidResendReasonModal'

type Props = {
    open: boolean
    onClose: (refresh: boolean) => void
    closingId: string,
    closingCode: string,
    digitalContractData: any
}

const useStyles = generateStyle(theme => ({
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10
    }
}), 'ClosingContract_Modal')

export interface IContractList {
    id: number
    closing_code: string,
    token: string,
    type_label: string,
    status_label: string
    custom_pdf_url: string
    signer_status_label: string
    file_url: string
    created_at: string
    signed_at: string
    signed_at2: string
}


const ClosingContractModal = (props: Props) => {
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [redrawDataTable, setRedrawDataTable] = useState(0)
    const [list, setList] = useState<IContractList[]>([])
    const [addModal, setAddModal] = useState(false)
    const [downloadContractModal, setDownloadContractModal] = useState(false)
    const [partialDocusignModal, setPartialDocusignModal] = useState(false)
    const [voidReasonModal, setVoidReasonModal] = useState({
        open: false,
        customPdf: false,
        id: 0
    })

    const handleCloseModal = () => {
        props.onClose(false)
    }

    const handleActionSubmit = (type: string, id: number, data?: IContractList) => {
        if (type === 'void-resend-contract') {
            return setVoidReasonModal({
                open: true,
                customPdf: (data?.type_label === "Kontrak Owner" || data?.type_label === "Kontrak Tenant") ? true : false,
                id
            })
        }
        const message = {
            'send-contract-retry': 'retry',
            'resend-contract': 'resend',
            'void-resend-contract': 'void & resend'
        }
        renderQuestionButton(`Apakah anda yakin untuk me-${message[type as keyof typeof message] || ''} kontrak ini ?`)
            .then(res => {
                if (res.value) {

                    setIsLoading(true)
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/${type}`, {
                        id: id
                    })
                        .then(res => res.data)
                        .then(res => {
                            renderSuccessButton(`${capitalize(message[type as keyof typeof message] || '')} Berhasil!`)
                            fetchList()
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    const renderActionButton = (data: IContractList) => {
        const retryButton = <Button onClick={() => handleActionSubmit('send-contract-retry', data.id)} variant='contained'>Retry</Button>
        const voidResendButton = !(data.type_label === 'Surat Kuasa') ?
            <Button color='secondary' onClick={() => handleActionSubmit('void-resend-contract', data.id, data)} variant='contained'>Void & Resend</Button>
            : data.status_label === 'Unsigned' ?
                <Button color='secondary' onClick={() => handleActionSubmit('void-resend-contract', data.id, data)} variant='contained'>Void & Resend</Button>
                : null
        const resendEmailButton = <Button onClick={() => handleActionSubmit('resend-contract', data.id)} variant='contained'>Resend Email</Button>
        const fileLinkButton = <Button href={data.file_url} target='_blank' variant='contained'>Lihat File</Button>
        const contractLinkButton = data.type_label === 'Addendum Sewa' ?
            <>
                <Button href={`${process.env.REACT_APP_MAIN_URL}/contract/ADR-OWN-${data.closing_code}`} target='_blank' variant='contained'>Link Kontrak Owner</Button>
                <Button href={`${process.env.REACT_APP_MAIN_URL}/contract/ADR-TEN-${data.closing_code}`} target='_blank' variant='contained'>Link Kontrak Tenant</Button>
            </> :
            <Button href={`${process.env.REACT_APP_MAIN_URL}/contract/${data.type_label === 'Surat Kuasa' ? data.token : data.type_label === 'Kontrak Owner' ? `OWN-${data.closing_code}` : `TEN-${data.closing_code}`}`} target='_blank' variant='contained'>Link Kontrak</Button>

        switch (data.status_label) {
            case 'Unsigned':
                return [
                    resendEmailButton,
                    voidResendButton,
                    contractLinkButton
                ]
            case 'Signed':
                return [
                    voidResendButton,
                    fileLinkButton,
                ]
            case 'Owner Signed':
                return [
                    resendEmailButton,
                    voidResendButton,
                    contractLinkButton,
                ]
            case 'Tenant Signed':
                return [
                    resendEmailButton,
                    voidResendButton,
                    contractLinkButton
                ]
            case 'Generating Contract':
                return [
                    retryButton
                ]
            default:
                return null
        }
    }

    const formatDate = (data: string) => {
        return format(new Date(data), 'dd MMM yyyy - HH:mm')
    }

    const renderAddendumRentDate = (rows: IContractList) => {
        if (rows.signed_at || rows.signed_at2) {
            return (
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    {rows.signed_at ? <span>Owner : {formatDate(rows.signed_at)}</span> : null}
                    {rows.signed_at2 ? <span>Tenant : {formatDate(rows.signed_at2)}</span> : null}
                </div>
            )
        }

        return "-"
    }

    const columns = [
        { name: 'type_label', label: "Tipe Kontrak", columnStyle: { textAlign: 'left' } },
        { name: 'status_label', label: "Status", columnStyle: { textAlign: 'left' } },
        {
            name: 'custom_pdf_url', label: "Custom PDF", columnStyle: { textAlign: 'left' }, render: (data: string) => {
                if (data) {
                    return (
                        <IconButton
                            onClick={() => {
                                openExternalLink(data)
                            }}
                            color='primary'
                        >
                            <DescriptionIcon />
                        </IconButton>
                    )
                }

                return '-'
            }
        },
        {
            name: 'created_at', label: "Sent At", columnStyle: { textAlign: 'left' }, render: (data: string) => {
                return data ? formatDate(data) : '-'
            }
        },
        {
            name: 'signed_at', label: "Signed At", columnStyle: { textAlign: 'left' }, render: (data: string, rows: IContractList) => {
                if (rows.type_label === 'Addendum Sewa') {
                    return renderAddendumRentDate(rows)
                }

                return data ? formatDate(data) : '-'
            }
        },
        {
            name: 'EXTRA',
            label: "Action",
            render: (rows: IContractList) => {
                return (
                    <div className={classes.buttonContainer}>
                        {renderActionButton(rows)}
                    </div>
                )
            }
        },
    ]

    const fetchList = () => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/new-closing/list-contract`, {
            params: {
                closing_id: props.closingId
            }
        })
            .then(res => res.data)
            .then(res => {
                setList(res)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
                setRedrawDataTable(new Date().getTime())
            })
    }

    useEffect(() => {
        if (props.open && props.closingId) {
            fetchList()
        }

        // eslint-disable-next-line
    }, [props.open, props.closingId])

    return (
        <>
            <DownloadContractModal
                open={downloadContractModal}
                onClose={() => setDownloadContractModal(false)}
                closingId={props.closingId}
                closingCode={props.closingCode}
            />

            <PartialDocusignForm
                open={partialDocusignModal}
                onClose={(fetch) => {
                    setPartialDocusignModal(false)
                    if (fetch) {
                        fetchList()
                    }
                }}
                closingId={props.closingId}
                digitalContractData={props.digitalContractData}
            />

            <ModalDetail
                title={"Contract List"}
                isLoading={isLoading}
                onCloseModal={handleCloseModal}
                open={props.open}
                customRightButton={
                    <IconButton onClick={() => fetchList()}>
                        <RefreshIcon style={{ color: '#fff' }} />
                    </IconButton>
                }

            >
                <Grid item xs={12}>
                    <Root>
                        <Grid container spacing={2}>
                            <Grid item xs={12} display="flex">
                                <Button variant='contained' onClick={() => setAddModal(true)}>
                                    Add Contract
                                </Button>
                                <Button variant='contained' color='primary' sx={{ ml: 1 }} onClick={() => setDownloadContractModal(true)}>
                                    Download Contract
                                </Button>
                                <Button variant='contained' color='primary' sx={{ ml: "auto" }} onClick={() => setPartialDocusignModal(true)}>
                                    Edit Document Detail
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <SimpleDataTable
                                    columns={columns}
                                    customData={list}
                                    reDraw={redrawDataTable}
                                />
                            </Grid>
                        </Grid>
                    </Root>
                </Grid>
            </ModalDetail>

            <VoidResendReasonModal
                open={voidReasonModal.open}
                title='Void & Resend Contract'
                label='Reason'
                paramName='reason'
                url={`${process.env.REACT_APP_API_URL}/new-closing/void-resend-contract`}
                extraParams={{
                    id: voidReasonModal.id,
                }}
                required
                customPdf={voidReasonModal.customPdf}
                onClose={() => {
                    setVoidReasonModal({ open: false, id: 0, customPdf: false })
                }}
                onSuccess={() => {
                    renderSuccessButton('Void & Resend Berhasil!');
                    setVoidReasonModal({ open: false, id: 0, customPdf: false })
                    setRedrawDataTable(new Date().getTime())
                }}
            />

            <ContractAddModal
                open={addModal}
                onClose={(refresh) => {
                    setAddModal(false)

                    if (refresh) {
                        fetchList()
                    }
                }}
                contractList={list}
                closingId={props.closingId}
            />

        </>
    )
}

export default ClosingContractModal
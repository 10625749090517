import React, { useState } from 'react';
import { Button } from '@mui/material';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { dateFormat, generalErrorHandler } from '../../_utils/Helper';

const CreditMemoList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/credit-memo';

    const columns = [
        {
            name: 'created_at',
            label: 'Tanggal',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return dateFormat(data, 'DD MMM YYYY');
            }
        },
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true
        },
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: true,
            render: (data: any) => {
                return Array.isArray(data)
                    ? <ul>
                        {data.map((email: string) => <li>{email}</li>)}
                    </ul>
                    : (
                        data !== null
                            ? <ul>
                                {data.split(',').map((email: string) => <li>{email}</li>)}
                            </ul>
                            : null
                    )
            }
        },
        {
            name: 'EXTRA',
            label: 'Preview',
            filterable: false,
            render: (row: any) => {
                return <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handlePreview(row.id)}
                >
                    Preview
                </Button>
            }
        }
    ];

    const [isLoading, setIsLoading] = useState(false);

    const handlePreview = (id: string) => {
        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/credit-memo/${id}`, { responseType: 'blob' })
            .then(res => {
                //Build a URL from the file
                const fileURL = URL.createObjectURL(res.data);

                //Open the URL on new Window
                window.open(fileURL);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <DataTable 
                url={API_URL}
                columns={columns}
            />
        </>
    );
}

export default CreditMemoList;
import { Card, CardContent, Grid, Theme } from '@mui/material'
import React from 'react'
import { format } from 'date-fns'
import { ILeadAdvisor } from '../DashboardPM'
import { generateStyle } from '../../../_utils/DefaultStyle'

/* 
* Interfaces
*/
interface IProps {
    data: ILeadAdvisor
    onClick: () => void
}

export type DashboardLeadItemType = '' | 'greeting' | 'fu' | 'showing' | 'kyc' | 'closing' | 'fail'

const typeBackgroundColor = (type: DashboardLeadItemType) => {
    switch (type) {
        case 'closing': return '#3BA8C5'
        case 'showing': return '#343434'
        case 'fail': return '#EF8181'
        case 'fu': return '#035AA6'
        case 'greeting': return '#A6CE9E'
        case 'kyc': return '#F0A456'
        default: return '#A6CE9E'
    }
}

const typeLabel = (type: DashboardLeadItemType) => {
    switch (type) {
        case 'closing': return 'CLS'
        case 'showing': return 'SHW'
        case 'fail': return 'FAIL'
        case 'fu': return 'FU'
        case 'greeting': return 'NEW'
        case 'kyc': return 'KYC'
        // case 'cold': return 'COLD'
        default: return '?'
    }
}

const Items = ({ data, ...props }: IProps) => {
    const { Root, classes } = useStyles()

    return (
        <Card
            onClick={props.onClick}
            variant='outlined'
            sx={{ mb: 1 }}
        >
            <Root>
                <CardContent>
                    <Grid container
                        columnSpacing={2}
                        flexDirection='row'
                        display='flex'
                        alignItems='center'
                    >
                        <Grid item>
                            <div className={classes.profile} style={{ background: typeBackgroundColor(data.status as DashboardLeadItemType) }} >
                                {typeLabel(data.status as DashboardLeadItemType)}
                            </div>
                        </Grid>
                        <Grid item xs flex={1} className={classes.content}>
                            <p>{format(new Date(data.created_at), 'd MMMM yyyy')}</p>
                            <h2>{data.customer_name}</h2>
                            <p>{data.apartment_name}</p>
                        </Grid>
                    </Grid>
                </CardContent>
            </Root>
        </Card >
    )
}

export default Items

const useStyles = generateStyle((theme: Theme) => ({
    profile: {
        width: '60px',
        height: '60px',
        borderRadius: '10px',
        color: 'white',
        fontSize: 16,
        fontWeight: '700',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        "& > *": {
            margin: 0,
            lineHeight: 'initial'
        }
    }
}), "Utems"
)

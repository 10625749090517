import React, { useRef } from 'react'


const DragableHorizontal = (props: React.HTMLProps<HTMLDivElement>) => {
    const ref = useRef<HTMLDivElement | null>(null)
    const isMouseDown = useRef(false)
    const startX = useRef(0)
    const scrollLeft = useRef(0)

    const startDragging = (e: React.MouseEvent<HTMLDivElement>) => {
        isMouseDown.current = true;
        startX.current = e.pageX - (ref.current?.offsetLeft || 0);
        scrollLeft.current = ref.current?.scrollLeft || 0;
    }

    const stopDragging = (e: React.MouseEvent<HTMLDivElement>) => {
        isMouseDown.current = false;
    }

    const move = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!isMouseDown.current) { return; }
        const x = e.pageX - (ref.current?.offsetLeft || 0);
        const scroll = x - startX.current;
        if (ref.current) ref.current.scrollLeft = scrollLeft.current - scroll;
    }

    return (
        <div
            {...props}
            ref={ref}
            onMouseMove={move}
            onMouseDown={startDragging}
            onMouseUp={stopDragging}
            onMouseLeave={stopDragging}
        >
            {props.children || null}
        </div>
    )
}

export default DragableHorizontal

import React, { useState, useEffect } from 'react';
import { Typography, Theme, Chip, FormControl, Select, MenuItem } from '@mui/material';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

/**
 * Components
 */
import DataTable from '../../../_components/_dataTable/DataTable';
import ClosingModalDetail from './ClosingModalDetail';


/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import { numberToCurrency } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    permissions: any;
    personType?: string;
    landlordId?: string;
    agentId?: string;
    customerId?: string;
    withTitle?: boolean;
    url?: string;
}

interface ITRList {
    key: string,
    value: string
}

const useStyles = generateStyle((theme: Theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "100%",
    },
}), "TransactionHistory"
)

interface IParams extends Record<string, string | undefined> { code: string | undefined }

const TransactionHistory = (props: Props) => {
    const API_URL = props.url;
    const API_URL_Landlord = `${process.env.REACT_APP_API_URL}/new-closing?landlord_id=${props.landlordId}`;
    const API_URL_Agent = `${process.env.REACT_APP_API_URL}/new-closing?agent_id=${props.agentId}`;
    const API_URL_Customer = `${process.env.REACT_APP_API_URL}/new-closing?customer_id=${props.customerId}`;
    const params = useParams<IParams>();
    const { Root, classes } = useStyles();

    const [modalCode, setModalCode] = useState("");
    const [picTRList, setPICTRList] = useState<Array<ITRList>>([]);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    useEffect(() => {
        if (params.code) {
            setModalCode(params.code);
        } else {
            setModalCode("");
        }
    }, [params.code]);

    useEffect(() => {
        setReDrawDatatable(new Date().getTime());
    }, [props.landlordId]);

    /**
     * Initial when Page Accessed
     */
    useEffect(() => {
        if (props.permissions['closing.list-type'] === 'operation-manager') {
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/autocomplete/user/tenant-relation`)
                .then(response => {
                    const lists = response.data;
                    let listToPush: Array<ITRList> = [];

                    lists.map((data: { id: string, label: string }) => {
                        listToPush.push({
                            key: data.id,
                            value: data.label
                        })
                        return true;
                    });

                    setPICTRList([{ key: '', value: 'All' }, { key: 'none', value: 'Not Assigned' }, ...listToPush]);
                })
                .catch(error => {
                    alert('Kesalahan server, harap hubungi admin')
                });
        }
    }, [props.permissions]);

    const getColorCode = (status: string) => {
        switch (Number(status)) {
            case 0:
                return 'rgba(179, 173, 173, 0.87)';
            case 1:
                return 'black';
            case 2:
                return '#009be5';
            case 3:
                return 'red';
            case 4:
                return 'green';
            default:
                return '';
        }
    }

    const renderPIC = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
        if (!props.permissions['extend.admin']) {
            return row.extend_pic_id ? `${row.extend_pic_label}` : 'None Selected';
        }

        return (
            <FormControl className={classes.formControl}>
                <Select
                    value={row.extend_pic_id ?? 'none'}
                    onChange={(e) => handleChangePic(row.id, e.target.value as string)}
                    autoWidth
                >
                    <MenuItem value={'none'}>None Selected</MenuItem>
                    {picTRList.map(data => {
                        return (
                            <MenuItem value={data.key} key={data.key}>{data.value}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        )
    }

    const handleChangePic = (closing_id: string, extend_pic_id: string) => {
        DefaultAxios.patch(`${API_URL_Landlord}/change-pic`, {
            closing_id: closing_id,
            extend_pic_id: extend_pic_id
        })
            .then(response => {
                if (response.data.updated) {
                    Swal.fire({
                        html: "Success change data",
                        icon: 'info',
                        showCloseButton: true,
                        onClose: () => {
                            setReDrawDatatable(new Date().getTime());
                        }
                    })
                }
            })
            .catch(error => {
                alert('Kesalahan server, harap hubungi admin')
            });
    }

    const generateColumn = () => {
        /**
         * Available Columns
         */
        const columns = [
            {
                name: 'closing_date',
                label: 'Tanggal Input',
                filterable: true,
                sortable: true,
                type: 'date',
                render: (data: string, row: any) => {
                    if (data) {
                        if (row.status === 3) {
                            return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                        } else {
                            return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                        }
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'first_payment_date',
                label: 'Tanggal Pembayaran Pertama',
                filterable: true,
                sortable: true,
                type: 'date',
                render: (data: string, row: any) => {
                    if (data) {
                        if (row.status === 3) {
                            return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                        } else {
                            return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                        }
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'finished_payment_date',
                label: 'Tanggal Pelunasan Closing',
                filterable: true,
                sortable: true,
                type: 'date',
                render: (data: string, row: any) => {
                    if (data) {
                        if (row.status === 3) {
                            return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                        } else {
                            return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                        }
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'closing_code',
                label: 'Kode transaksi',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (data) {
                        return (
                            <Chip
                                variant="outlined"
                                label={row.closing_code} onClick={() => {
                                    setModalCode(row.closing_code);
                                    window.history.pushState('', '', `/closing/${row.closing_code}`)
                                }}
                            />
                        )
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'termin_code',
                label: 'Kode Termin',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (data) {
                        if (row.status === 3) {
                            return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                        } else {
                            return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                        }
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'code',
                label: 'Kode Unit',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (data) {
                        if (row.status === 3) {
                            return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                        } else {
                            return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                        }
                    } else {
                        return "-";
                    }
                }
            },
            {
                name: 'creator_name',
                label: 'Created By',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                    }
                }
            },
            {
                name: 'advisor_name',
                label: 'Advisor',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                    }
                }
            },
            {
                name: 'created_by',
                label: 'Created By',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                    }
                }
            },
            {
                name: 'customer_name',
                label: 'Nama Customer',
                filterable: true,
                sortable: true,
                type: 'string',
                render: (data: string, row: any) => {
                    if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                    }
                }
            },
            {
                name: 'start_date',
                label: 'Tanggal Masuk',
                filterable: true,
                sortable: true,
                type: 'date',
                render: (data: string, row: any) => {
                    if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>{data}</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{data}</div>);
                    }
                }
            },
            {
                name: 'gmv_transaction',
                label: 'GMV',
                filterable: true,
                sortable: true,
                type: 'number',
                render: (data: string, row: any) => {
                    if ([0, 1].includes(row.status)) {
                        return (<div style={{ color: getColorCode(row.status) }}>Rp. 0 ({numberToCurrency(data, 'Rp')})</div>);
                    } else if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>Rp. 0 ({numberToCurrency(data, 'Rp')})</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{numberToCurrency(data, 'Rp')}</div>);
                    }
                }
            },
            {
                name: 'revenue_jendela',
                label: 'Total Komisi',
                filterable: true,
                sortable: true,
                type: 'number',
                render: (data: string, row: any) => {
                    if ([0, 1].includes(row.status)) {
                        return (<div style={{ color: getColorCode(row.status) }}>Rp. 0 ({numberToCurrency(data, 'Rp')})</div>);
                    } else if (row.status === 3) {
                        return (<div style={{ color: getColorCode(row.status) }}><del>Rp. 0 ({numberToCurrency(data, 'Rp')})</del></div>);
                    } else {
                        return (<div style={{ color: getColorCode(row.status) }}>{numberToCurrency(data, 'Rp')}</div>);
                    }
                }
            },
            {
                name: 'status',
                label: 'Status',
                filterable: true,
                sortable: true,
                defaultOption: '',
                options: [
                    {
                        key: '',
                        value: 'All'
                    },
                    {
                        key: '0',
                        value: 'Draft'
                    },
                    // {
                    //     key: '1',
                    //     value: 'Confirmed'
                    // },
                    {
                        key: '2',
                        value: 'Verified'
                    },
                    {
                        key: '3',
                        value: 'Canceled'
                    },
                    {
                        key: '4',
                        value: 'Completed'
                    }
                ],
                render: (data: string, row: any) => {
                    switch (Number(data)) {
                        case 0:
                            return (<div style={{ color: getColorCode(data) }}>Draft</div>);
                        case 1:
                            return (<div style={{ color: getColorCode(data) }}>Confirmed</div>);
                        case 2:
                            return (<div style={{ color: getColorCode(data) }}>Verified</div>);
                        case 3:
                            return (<div style={{ color: getColorCode(data) }}><del>Canceled</del></div>);
                        case 4:
                            return (<div style={{ color: getColorCode(data) }}>Completed</div>);
                        default:
                            return '-';
                    }
                }
            },
            {
                name: 'extend_pic_id',
                label: 'Extend PIC',
                filterable: true,
                sortable: true,
                type: 'string',
                render: renderPIC,
                defaultOption: "",
                options: picTRList
            },
        ];

        /**
         * Build column
         */
        let listColumns;
        let columnsToRender: any = [];
        if (props.permissions['closing.list-type'] === 'advisor-captain' || props.permissions['closing.list-type'] === 'admin-data') {
            listColumns = ['closing_date', 'closing_code', 'code', 'start_date', 'gmv_transaction', 'revenue_jendela', 'advisor_name', 'status'];
        } else if (props.permissions['closing.list-type'] === 'operation-manager') {
            listColumns = ['closing_date', 'status', 'closing_code', 'code', 'customer_name', 'start_date', 'advisor_name', 'creator_name', 'extend_pic_id'];
        } else {
            listColumns = ['closing_date', 'first_payment_date', 'finished_payment_date', 'status', 'closing_code', 'termin_code', 'code', 'customer_name', 'advisor_name', 'creator_name', 'start_date', 'gmv_transaction', 'revenue_jendela'];
        }

        listColumns.map(key => {
            for (var i = 0; i < columns.length; i++) {
                if (columns[i].name === key) {
                    columnsToRender.push(columns[i]);
                    break;
                }
            }
            return true;
        });

        return columnsToRender;
    }

    return (
        <Root>
            {
                props.withTitle &&
                <div className={classes.buttonContainer}>
                    <Typography
                        variant="h5"
                        style={{ fontWeight: "bold" }}>
                        Transaction History
                    </Typography>
                </div>
            }

            <DataTable
                url={API_URL
                    ? API_URL
                    : props.personType === 'landlord'
                        ? API_URL_Landlord
                        : props.personType === 'agent'
                            ? API_URL_Agent
                            : API_URL_Customer}
                columns={generateColumn()}
                reDraw={reDrawDatatable}
            />

            {modalCode !== "" &&
                <ClosingModalDetail
                    code={modalCode}
                    open={modalCode !== ""}
                    permissions={props.permissions}
                    onClose={() => {
                        setModalCode("")
                        window.history.pushState('', '', `/landlord/${props.landlordId}`)
                    }}
                />
            }
        </Root>
    );
}

export default TransactionHistory;
import React, { useReducer, useEffect, useState } from 'react';
import Swal from 'sweetalert2';

// Input
import { Paper, Theme, Grid, TextField, Button, InputLabel } from '@mui/material';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

// Components
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { numberToCurrency } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    mode: "add" | "edit",
    onSubmitSuccess: () => void,
    detailListingId: null | number,
    apiUrl: string
}

interface IErrorState {
    name: string,
    price: string,
    qty: string,
    notes: string
}

interface IState {
    name: string,
    listing_inventory_id: null | number,
    item_name?: string,
    price: number | string,
    qty: number,
    stock: number,
    notes: string,
    listing_notes: string
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string
}

interface IActionError {
    name: string,
    value: object | Date | null | string | number,
}

const useStyles = generateStyle((theme: Theme) =>
({
    submit_button: {
        marginLeft: '8px'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
        margin: theme.spacing(2),
        marginTop: theme.spacing(5)
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    paper_label: {
        position: "absolute",
        marginTop: "-45px",
        padding: "1px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)"
    },
    paper_label_text: {
        backgroundColor: "#0094ff",
        fontSize: "1.5em",
        padding: "13px",
        borderRadius: "4px",
        color: "white"
    },
    labelStock: {
        marginLeft: '10px'
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        paddingTop: '10px'
    }
}), "DetailListingInventory_Form"
)

const DetailListingInventoryForm = (props: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(false);
    const [prevQty, setPrevQty] = useState(0);
    const [firstInit, setFirstInit] = useState(true);

    const numbers = [
        'price'
    ];

    // Error State
    const errorReducer = (state: IErrorState, action: IActionError) => {
        return {
            ...state,
            [action.name]: action.value
        }
    };

    const [errorState, setErrorState] = useReducer(errorReducer, {
        name: '',
        price: '',
        qty: '',
        notes: ''
    });

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                listing_inventory_id: null,
                price: 0,
                qty: 0,
                stock: 0,
                notes: '',
                listing_notes: ''
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        name: '',
        listing_inventory_id: null,
        price: '',
        qty: 0,
        stock: 0,
        notes: '',
        listing_notes: ''
    });

    useEffect(() => {
        if (props.mode === 'edit') {
            DefaultAxios
                .get(`${API_URL}/unit-listing-inventory/${props.detailListingId}`)
                .then(res => {
                    const response_data: any = res.data;
                    for (let i in response_data) {
                        if (numbers.findIndex(item => item === i) !== -1) {
                            response_data[i as keyof IState] = numberToCurrency(response_data[i as keyof IState]);
                        }
                    }
                    setInputState({ name: 'state', value: response_data, type: 'REPLACE_STATE' });
                    setPrevQty(response_data.qty as number);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (inputState.listing_inventory_id) {
            if (props.mode === 'edit' && firstInit) {
                setFirstInit(false);
            } else {
                DefaultAxios
                    .get(`${props.apiUrl}/${inputState.listing_inventory_id}`)
                    .then(res => {
                        const response_data = res.data;
                        for (let i in response_data) {
                            if (typeof inputState[i as keyof IState] !== 'undefined') {
                                if (numbers.findIndex(item => item === i) !== -1) {
                                    response_data[i as keyof IState] = numberToCurrency(response_data[i as keyof IState]);
                                }
                                setInputState({ name: i, value: response_data[i], type: 'SET_ITEM' });
                            }
                        }
                    })
                    .catch(error => {
                        if (+error.response.status === 422) {
                            setInputState({ name: '', value: '', type: 'RESET_ITEM' });
                            Swal.fire({
                                title: error.response.data,
                                icon: 'error'
                            });
                        }
                    })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputState.listing_inventory_id]);

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            if (numbers.findIndex(item => item === target.name) !== -1) {
                value = numberToCurrency(value);
            }
            setInputState({ name: e.target.name, value: value, type: 'SET_ITEM' });
        }
    }

    const checkValidation = (): boolean => {
        let isValid = true;

        if (!inputState.listing_inventory_id) {
            setErrorState({ name: 'name', value: 'Silahkan Pilih Inventory dari list dropdown' });
            isValid = false;
        } else {
            setErrorState({ name: 'name', value: '' });
        }

        if (!inputState.price) {
            setErrorState({ name: 'price', value: 'Harga harus diisi' });
            isValid = false;
        } else {
            setErrorState({ name: 'price', value: '' });
        }

        if (+inputState.qty < 0) {
            setErrorState({ name: 'qty', value: 'Minimal jumlah 0' });
            isValid = false;
        } else if (props.mode === 'add' && (+inputState.qty > +inputState.stock)) {
            setErrorState({ name: 'qty', value: 'Jumlah tidak boleh melebihi stock' });
            isValid = false;
        } else if (props.mode === 'edit' && (inputState.stock + prevQty < +inputState.qty)) {
            setErrorState({ name: 'qty', value: 'Jumlah tidak boleh melebihi stock' });
            isValid = false;
        } else {
            setErrorState({ name: 'qty', value: '' });
        }

        return isValid;
    }

    const submitForm = () => {
        if (!checkValidation()) {
            return false;
        }

        const data: any = { ...inputState, mode: props.mode };
        for (let i in data) {
            if (numbers.findIndex(item => item === i) !== -1) {
                data[i as keyof IState] = String(data[i as keyof IState]).replace(/[^0-9]/g, '')
            }
        }

        setLoading(true);
        DefaultAxios
            .post(`${props.apiUrl}/${inputState.listing_inventory_id}`, data)
            .then(_ => {
                Swal.fire({
                    title: "Listing Inventory Submitted",
                    // html: ,
                    icon: 'success',
                    onAfterClose: props.onSubmitSuccess,
                    timer: 1000
                });
            })
            .catch(error => {
                if (typeof error.response.status === 'undefined') {
                    Swal.fire({
                        title: "Script Error",
                        html: "Error pada script. Harap hubungi Admin",
                        icon: 'error',
                    });
                } else if (error.response.status === 422) {
                    let error_text = `<p>${error.response.data.message}</p>`;

                    if (typeof error.response.data.errors !== 'undefined') {
                        const error_lists = error.response.data.errors;
                        for (var k in error_lists) {
                            error_text += `<p>${error_lists[k]}</p>`;
                        }
                    }

                    Swal.fire({
                        title: "Validasi Error",
                        html: error_text,
                        icon: 'error',
                    });
                } else if (error.response.status === 500) {
                    Swal.fire({
                        title: "Server Error",
                        html: "Error pada server. Harap hubungi Admin",
                        icon: 'error',
                    });
                }
            })
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name: 'listing_inventory_id', value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any) => {
        setInputState({ name: 'listing_inventory_id', value: null, type: 'SET_ITEM' });
    }

    return (
        <Root>
            <Paper className={classes.root}>
                <LoadingScreen open={loading} fullScreen />
                <Grid container>
                    <Grid item xs={12} className={classes.grid}>
                        <AsyncAutoComplete
                            label="Nama"
                            name="name"
                            initialQuery={inputState.item_name}
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            url={`${process.env.REACT_APP_API_URL}/listing-inventory/autocomplete`}
                            disabled={props.mode === 'edit'}
                            errorText={errorState.name}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.grid}>
                        <TextField
                            label="Keterangan Listing(Read only)"
                            variant="outlined"
                            name="listing_notes"
                            value={inputState.listing_notes}
                            onChange={handleInputChanged}
                            fullWidth
                            multiline
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.grid}>
                        <TextField
                            error={!!errorState.price}
                            label="Harga"
                            variant="outlined"
                            name="price"
                            value={inputState.price}
                            onChange={handleInputChanged}
                            fullWidth
                            helperText={errorState.price}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.grid}>
                        <TextField
                            error={!!errorState.qty}
                            label="Jumlah"
                            type="number"
                            variant="outlined"
                            name="qty"
                            value={inputState.qty}
                            onChange={handleInputChanged}
                            fullWidth
                            helperText={errorState.qty}
                        />
                        {
                            inputState.stock >= 0 &&
                            <InputLabel className={classes.labelStock}>Sisa stock: {inputState.stock}</InputLabel>
                        }
                    </Grid>

                    <Grid item xs={12} className={classes.grid}>
                        <TextField
                            error={!!errorState.notes}
                            label="Keterangan"
                            variant="outlined"
                            name="notes"
                            value={inputState.notes}
                            onChange={handleInputChanged}
                            fullWidth
                            helperText={errorState.notes}
                            multiline
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.gridSubmit}>
                        <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Root>
    );
}

export default DetailListingInventoryForm;
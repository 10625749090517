import React, { useState, useEffect } from 'react';
import {
    Paper,
    Theme,
    Grid,
    Tabs,
    Tab,
} from '@mui/material';
/**
 * Components
 */
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';
import CustomerFileTable from './_components/CustomerFileTable';
import TransactionHistory from '../_closing/_components/TransactionHistory';
import CustomerLogs from './_components/CustomerLogs';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import BankTable from '../../_components/_dataTable/BankTable';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    open: boolean
    onClose: Function
    customerId: string
    permissions?: any
}

interface DetailState {
    id: string,
    name: string,
    phone: string,
    email: string,
    address: string,
    bank_type: string,
    bank_account_number: string,
    bank_behalf_name: string,
    bank_branch: string
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative'
    },
    container: {
        backgroundColor: "#f3f3f3",
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
        marginTop: theme.spacing(5),
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    paperTab: {
        margin: "0"
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        paddingTop: '10px'
    },
    box: {
        padding: theme.spacing(2)
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "Customer_detail"
)

const CustomerDetail = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/customer';
    const { Root, classes } = useStyles();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(0);
    const [detail, setDetail] = useState<DetailState>({
        id: "",
        name: "",
        phone: "",
        email: "",
        address: "",
        bank_type: "",
        bank_account_number: "",
        bank_behalf_name: "",
        bank_branch: ""
    });

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const loadData = () => {
        setLoading(true);
        DefaultAxios
            .get(`${API_URL}/${props.customerId}`)
            .then(res => {
                const data = res.data;
                setDetail(data);
            })
            .catch(err => {
                generalErrorHandler(err);
                props.onClose();
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const renderWa = (data: any) => {
        if (data) {
            let regex = /^08/;
            if (data.match(regex)) {
                data = data.replace(regex, "628")
            }
            let phoneNumber = data;
            let link = 'https://api.whatsapp.com/send?phone=' + phoneNumber;
            return <a href={link}>{data}</a>
        }

        return '-'
    }

    useEffect(() => {
        if (props.open) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    return (
        <ModalDetail
            open={props.open}
            isLoading={loading}
            onCloseModal={() => props.onClose()}
            title="Customer Detail"
            showEditButton={true}
            editButtonLink={`/customer/edit/${props.customerId}`}
        >
            <>
                <ModalSection
                    xs={12}
                    md={12}
                    title="Informasi Dasar"
                    content={[
                        ['Name', detail.name],
                        ['Email', detail.email],
                        ['HP', renderWa(detail.phone)],
                        ['Address', detail.address],
                        ['Bank', !!detail.bank_type ? (`${detail.bank_type} ${detail.bank_branch ?? ''} - ${detail.bank_account_number} an. ${detail.bank_behalf_name}`) : '-'],
                    ]}
                />

                <Grid item xs={12}>
                    <Root>
                        <Paper className={classes.root}>
                            <div className={classes.paperTab}>
                                <Tabs
                                    style={{ backgroundColor: 'white' }}
                                    textColor="primary"
                                    value={value} onChange={handleChangeTab}
                                    aria-label="simple tabs example"
                                >

                                    <Tab label="File" {...a11yProps(0)} />
                                    <Tab label="Logs" {...a11yProps(1)} />
                                    <Tab label="Bank" {...a11yProps(2)} />
                                </Tabs>
                            </div>
                            <TabPanel value={value} index={0}>
                                <br></br>
                                <CustomerFileTable
                                    customerId={props.customerId}
                                />
                            </TabPanel>
                            {props.permissions['customer.log'] &&
                                <TabPanel value={value} index={1}>
                                    <br></br>
                                    <CustomerLogs
                                        customerId={props.customerId}
                                    />
                                </TabPanel>
                            }
                            <TabPanel value={value} index={2}>
                                <BankTable
                                    id={props.customerId}
                                    component="customer"
                                />
                            </TabPanel>
                        </Paper>
                    </Root>
                </Grid>

                <ModalSection
                    xs={12}
                    md={12}
                    title="Transaction History"
                    content={[]}
                    additionalContent={
                        props.permissions['closing.view'] &&
                        <div style={{ marginTop: 25 }}>
                            <TransactionHistory
                                permissions={props.permissions}
                                personType={'customer'}
                                customerId={props.customerId}
                            />
                        </div>
                    }
                />
            </>
        </ModalDetail>
    );
}

export default CustomerDetail;
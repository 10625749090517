import React, { useMemo } from 'react'

import { Box } from '@mui/material';
import ButtonRadio from './ButtonRadio';

interface Props {
    name: string,
    value: any,
    options: any[][],
    onClick: (name: string, value: any) => void
    radioStyle?: 2
    containerStyle?: React.CSSProperties
    radioWith?: string
}

const ButtonRadioGroup = (props: Props) => {
    const renderButton = useMemo(() => {
        return (props.options.map((val, idx) => {
            return <ButtonRadio radioWith={props.radioWith} radioStyle={props.radioStyle} current={props.value} key={idx} value={val[0]} label={val[1]} name={props.name} onClick={props.onClick} />
        }))
    }, [props.options, props.radioWith, props.radioStyle, props.value, props.name, props.onClick])

    return (
        <Box style={{ marginBottom: '9px', marginTop: '9px', ...props.containerStyle }}>
            {renderButton}
        </Box>
    )
}

export default ButtonRadioGroup;
import { Grid, TextField, Button, MenuItem } from '@mui/material'
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

/* 
* Components
*/
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'
import { CustomDatePicker, CustomTimePicker } from './DateTimePicker';
import TextArea from '../../../_components/_form/TextArea'

/* 
* Utils
*/
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, queryParamsToParams, renderQuestionButton, renderSuccessButton } from '../../../_utils/Helper'

/* 
* Interfaces
*/
import { IDefaultState, IDetail, KCPOption } from '../JhsOrderForm'
import LoadingScreen from '../../../_components/LoadingScreen'

interface IProps {
    setFormChanged: Dispatch<SetStateAction<boolean>>
    isEdit: boolean
    editData: any
}
interface IState extends IDefaultState {
    unit_id: string
}

const initialState = {
    use_closing: false,
    closing_id: '',
    unit_id: '',
    key_collection_point: '',
    key_collection_point_option: '' as KCPOption,
    notes: "",
    activity: 'photo',
    request_date: null,
    request_time: null
}

const initialDetail = {
    start_date: "",
    end_date: "",
    unit_code: "",
    apartment_id: "",
    apartment_name: "",
    bedroom: null,
    tower: "",
    tenant_name: "",
    floor_level: "",
    unit_number: "",
    owner_name: "",
    customer_name: ""
}

const FotoUnitForm = (props: IProps) => {
    const navigate = useNavigate()
    const [state, setState] = useState<IState>(initialState)
    const [detail, setDetail] = useState<IDetail>(initialDetail)
    const [requestDateTime, setRequestDateTime] = useState({
        request_date: state.request_date ? new Date(state.request_date as string) : null,
        request_time: state.request_time ? new Date(`Wed Mar 25 2015 ${state.request_time}:00`) : null
    })
    const [loading, setLoading] = useState(false)
    const [queryParams, setQueryParams] = useState<{ [key: string]: string }>({})

    const fetchData = (id: string) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/activity/unit`, { params: { id } })
            .then(res => {
                setDetail(res.data)
            })
            .catch(err => generalErrorHandler(err))
    }

    const handleChange = (e: any) => {
        props.setFormChanged(true)
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleRequestDate = (value: Date) => {
        setRequestDateTime((prev: any) => {
            setState({
                ...state,
                request_date: value ? format(new Date(value), 'Y-MM-dd') : ''
            })
            return {
                ...prev,
                request_date: value
            }
        })
    }

    const handleRequestTime = (value: number) => {
        setRequestDateTime((prev: any) => {
            setState({
                ...state,
                request_time: value ? format(new Date(value), 'HH:mm') : ''
            })
            return {
                ...prev,
                request_time: value
            }
        })
    }

    const handleChangeUnit = (name: string, value: any) => {
        props.setFormChanged(true)
        setState(prev => {
            fetchData(value.id)
            return {
                ...prev,
                unit_id: value.id
            }
        })
    }

    const gotoPrevious = () => {
        if (queryParams && queryParams.ticket_id) {
            return navigate(`/ticket/list/${queryParams.ticket_id}`)
        }

        const queryString = localStorage.getItem('queryParams')
        navigate(queryString ? `/jhs-order?${queryString}` : '/jhs-order')
    }

    const handleSubmit = () => {
        renderQuestionButton('Pastikan data yang anda masukkan Benar.', 'Apakah anda yakin ingin submit?')
            .then(res => {
                if (res.value) {
                    setLoading(true)
                    DefaultAxios[props.isEdit ? 'patch' : 'post'](`${process.env.REACT_APP_API_URL}/activity${props.isEdit ? `/${props.editData.id}/jhs` : ''}`, {
                        ...state,
                        ...(
                            queryParams ?
                                queryParams :
                                {}
                        ),
                        key_collection_point_option: null,
                        key_collection_point: state.key_collection_point_option === 'Others' ? state.key_collection_point : state.key_collection_point_option,
                        closing_id: null
                    })
                        .then(res => {
                            if (res) {
                                renderSuccessButton('Submit Success!', () => {
                                    if (props.isEdit) {
                                        return navigate(`/jhs-order/${props.editData.id ? props.editData.id : ''}`)
                                    }
                                    gotoPrevious()
                                })
                            }
                        })
                        .catch(err => generalErrorHandler(err))
                        .finally(() => setLoading(false))
                }
                if (res.dismiss) {
                    return null
                }
            })
            .catch(err => generalErrorHandler(err))

    }

    const assignExistData = (prev: any, current: any) => {
        let result: any = {}
        for (let key in prev) {
            if (key === 'bedroom') {
                result[key] = current[key] ?? null
            } else {
                result[key] = !!current[key] ? current[key] : null
            }
        }
        result['activity'] = 'photo'
        return result
    }

    useEffect(() => {
        setQueryParams(queryParamsToParams())

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (props.isEdit) {
            setState(prev => assignExistData(prev, props.editData))
            setDetail(prev => assignExistData(prev, props.editData))
        }
    }, [props.editData, props.isEdit])

    useEffect(() => {
        if (props.isEdit) {
            if (state.request_date) {
                handleRequestDate(new Date(state.request_date as string))
            }
            if (state.request_time) {
                handleRequestTime(new Date(`Wed Mar 25 2015 ${state.request_time}:00`).getTime())
            }
        }
        // eslint-disable-next-line
    }, [state.request_date, state.request_time])

    return (
        <>
            {
                loading &&
                <LoadingScreen open={loading} fullScreen />
            }
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        name='unit_code'
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/unit`}
                        label="Unit Code"
                        initialQuery={props.isEdit ? detail.unit_code : ''}
                        onChange={handleChangeUnit}
                        clearable
                        onClear={() => [setState(prev => ({ ...prev, unit_id: '' })), setDetail(initialDetail)]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Apartment Name"
                        value={detail.apartment_name}
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Unit Detail"
                        value={`Tw. ${detail.tower || ''} / ${detail.floor_level} / ${detail.unit_number}`}
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Unit Type"
                        fullWidth
                        disabled
                        value={detail.bedroom === null ? '' : detail.bedroom ? `${detail.bedroom}BR` : 'Studio'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomDatePicker
                        value={requestDateTime.request_date}
                        onChange={(value: any) => handleRequestDate(value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomTimePicker
                        value={requestDateTime.request_time}
                        onChange={(value: any) => handleRequestTime(value)}
                    />
                </Grid>
                {
                    state.key_collection_point_option === 'Others' ?
                        <Grid item xs={12}>
                            <TextField
                                label="Key Collection Point"
                                fullWidth
                                name="key_collection_point"
                                placeholder='Lokasi - Nama - No. HP atau Jendela360'
                                value={state.key_collection_point}
                                onChange={handleChange}
                            />
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <TextField
                                select
                                label="Key Collection Point"
                                name="key_collection_point_option"
                                value={state.key_collection_point_option}
                                fullWidth
                                onChange={handleChange}
                            >
                                <MenuItem value="" disabled>-- Select Key Collection Point --</MenuItem>
                                <MenuItem value="Kantor Jendela360">Kantor Jendela360</MenuItem>
                                <MenuItem value="Others">Others</MenuItem>
                            </TextField>
                        </Grid>
                }
                <Grid item xs={12}>
                    <TextArea
                        label="Notes"
                        name='notes'
                        value={state.notes}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {props.isEdit ? 'Update Order' : 'Submit'}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default FotoUnitForm
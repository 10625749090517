import React, { useState, useReducer, useEffect } from 'react';
import { Theme, Grid, TextField, Button, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Components
import LoadingScreen from '../../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    onSubmitSuccess: () => void,
    unitId: string
    onCloseForm: (data: any) => void;
}

interface IState {
    keterangan: string
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

interface IErrorState {
    keterangan: string;
}

interface IErrorAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) =>
({
    submit_button: {
        width: '100%',
        borderRadius: '25px'
    },
    appBar: {
        position: "relative",
        overflow: 'hidden'
    },
    header: {
        flexGrow: 1
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        margin: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(5),
        },
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'center',
        bottom: '4px',
        left: 0,
        position: 'absolute',
        padding: '20px',
        width: '100%'
    },
    title: {
        marginBottom: theme.spacing(1)
    }
}), "AdvisorNotes_form"
)

const AdvisorNotesForm = (props: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/my-unit';

    const [loading, setLoading] = useState(false);

    // Error State
    const errorReducer = (state: IErrorState, action: IErrorAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                keterangan: ''
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [errorState, setErrorState] = useReducer(errorReducer, {
        keterangan: ''
    });

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                keterangan: ''
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        keterangan: ''
    });

    const checkValidation = () => {
        let isValid = true;
        let newError = { ...errorState };
        // if (!inputState.name) {
        //     newError.name = 'Nama wajib diisi';
        //     isValid = false;
        // } else {
        //     newError.longitude = '';
        // }
        // if (!inputState.longitude) {
        //     newError.longitude = 'Longitude wajib diisi';
        //     isValid = false;
        // } else {
        //     newError.longitude = '';
        // }
        // if (!inputState.latitude) {
        //     newError.latitude = 'Latitude wajib diisi';
        //     isValid = false;
        // } else {
        //     newError.latitude = '';
        // }
        setErrorState({ type: 'REPLACE_STATE', name: '', value: newError });
        return isValid;
    }

    const handleInputChanged = (e: any) => {
        const target = e.target;

        if (target) {
            let value = target.value;
            setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
        }
    }

    const submitForm = () => {

        if (!checkValidation()) {
            return;
        }

        let axios;
        let data = {
            keterangan: inputState.keterangan
        }
        axios = DefaultAxios.patch(`${API_URL}/${props.unitId}?update_keterangan=true`, data)
        setLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Notes Updated",
                    icon: 'success',
                    onAfterClose: props.onSubmitSuccess,
                    timer: 1000
                });
            })
            .catch(error => {
                generalErrorHandler(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        if (props.unitId) {
            setLoading(true);
            DefaultAxios
                .get(`${process.env.REACT_APP_API_URL}/my-unit/${props.unitId}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                    setLoading(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Root>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.onCloseForm}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.header}>
                        Edit Notes
                    </Typography>
                </Toolbar>
            </AppBar>
            <LoadingScreen open={loading} fullScreen />

            <Grid className={classes.root} >
                <Typography variant="h6" className={classes.title}>
                    Notes
                </Typography>
                <Grid item xs={12}>
                    <TextField
                        label="Notes"
                        name="keterangan"
                        value={inputState.keterangan}
                        onChange={handleInputChanged}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        error={!!errorState.keterangan}
                        helperText={errorState.keterangan}
                    />
                </Grid>

                <Grid item xs={12} className={classes.gridSubmit}>
                    <Button variant="contained" size="large" color="primary" onClick={submitForm} className={classes.submit_button}>
                        Simpan
                    </Button>
                </Grid>
            </Grid>
        </Root>
    );
}

export default AdvisorNotesForm;
import React, { ReactElement } from 'react';
import { Link } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Theme } from '@mui/material';
import { generateStyle } from '../_utils/DefaultStyle';

interface ILink {
    url: string;
    label: string;
    logo: ReactElement | null;
}

const useStyles = generateStyle((theme: Theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    blankIcon: {
        opacity: 0
    }
}), "Collapsible_List");

export default function CollapsibleList(props: any) {
    let isOpen = false;

    props.lists.map((link: ILink) => {
        if (typeof link === "undefined") {
            return false;
        } else {
            if (window.location.pathname === link.url) {
                isOpen = true;
            }
            return link;
        }
    })

    const { Root, classes } = useStyles();
    const [open, setOpen] = React.useState(isOpen);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Root>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    {props.logo}
                </ListItemIcon>
                <ListItemText primary={props.label} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {props.lists.map((link: any, key: number) => {
                    if (link) {
                        return (
                            <Link to={link.url} style={{ textDecoration: 'none', color: 'black' }} key={link.label}>
                                <ListItem selected={window.location.pathname === link.url}>
                                    {
                                        link.logo ?
                                            <ListItemIcon>
                                                {link.logo}
                                            </ListItemIcon> :
                                            <ListItemIcon>
                                                <CheckBoxOutlineBlankIcon className={classes.blankIcon} />
                                            </ListItemIcon>
                                    }
                                    <ListItemText primary={link.label} />
                                </ListItem>
                            </Link>
                        )
                    } else {
                        return null;
                    }
                })}
            </Collapse>
        </Root>

    )
}
import React, { useState, useEffect } from "react";
import {
    Grid,
    Card,
    Chip,
    CardContent,
    CardActions,
    Button,
    Typography,
    Theme
} from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';

/**
 * Icon
 */
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { generalErrorHandler, renderWarningButton } from "../../_utils/Helper";
import { generateStyle } from "../../_utils/DefaultStyle";

interface IRequest {
    id: string
    type: string
    units: Array<string>
    transfer_to: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    card_action: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'end',
    },
    card_info: {
        "& .MuiGrid-root": {
            marginBottom: theme.spacing(1)
        }
    },
    unit_info: {
        marginRight: "5px"
    },
    cardContainer: {
        "& .MuiPaper-root": {
            marginBottom: theme.spacing(2)
        },
        paddingBottom: theme.spacing(6)
    }
}), "UnitKeyRquest"
);

const ActiveKeyList = () => {
    const { Root, classes } = useStyles();
    const [loading, setLoading] = useState(false);
    const [requests, setRequests] = useState<Array<IRequest>>([]);

    useEffect(() => {
        getData();
    }, []);

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getData = () => {
        setLoading(true);
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/unit-key-manage/request`)
            .then(res => {
                const newDatas: Array<IRequest> = res.data;
                setRequests([...newDatas]);
                setLoading(false);
            })
    }

    const submitAcceptRequest = (id: string) => {
        renderWarningButton('Apakah anda yakin ingin meng-accept request ini?')
            .then((result) => {
                if (result.value) {
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-key-manage/accept-transfer`, { id: id })
                        .then(res => {
                            Swal.fire({
                                title: "Request di-accept",
                                icon: 'success',
                                timer: 1000
                            })
                            getData();
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                }
            })
    }

    const submitRejectRequest = (id: string) => {
        renderWarningButton('Apakah anda yakin ingin meng-reject request ini?')
            .then((result) => {
                if (result.value) {
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-key-manage/reject-transfer`, { id: id })
                        .then(res => {
                            Swal.fire({
                                title: "Request di-reject",
                                icon: 'success',
                                timer: 1000
                            })
                            getData();
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                }
            })
    }

    const RequestCard = ({ id, type, units, transfer_to }: { id: string, type: string, units: Array<string>, transfer_to: string }) => {
        return (
            <Card className={classes.card} key={id}>
                <CardContent>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid className={classes.card_info}>
                            <Grid container alignItems="flex-end">
                                <Grid item style={{ marginLeft: "5px" }}>
                                    {type === "transfer" && <SyncAltIcon />}
                                    {(type === "return" || type === "borrow") && <SwapVertIcon />}
                                </Grid>
                                <Grid item style={{ marginLeft: "5px" }}>
                                    <Typography variant="h5">
                                        {capitalizeFirstLetter(type)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid>
                                {type === 'transfer' &&
                                    <Typography variant="subtitle2">
                                        Penerima: {transfer_to}
                                    </Typography>
                                }
                            </Grid>
                            <Grid>
                                <div className={classes.unit_info}>
                                    {units.map(data => {
                                        return <Chip label={data} color="primary" size="small" style={{ marginRight: "5px" }} />
                                    })}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions className={classes.card_action}>
                    <Button variant="contained" color="primary" size="small" onClick={() => submitAcceptRequest(id)}>
                        Accept
                    </Button>
                    <Button variant="contained" color="secondary" size="small" onClick={() => submitRejectRequest(id)} style={{ marginRight: "5px" }}>
                        Cancel
                    </Button>
                </CardActions>
            </Card>
        )
    }

    return (
        <Root>
            {!loading &&
                <Grid className={classes.cardContainer}>
                    {requests.length > 0 ?
                        <>
                            {requests.map((data: IRequest) => {
                                return (
                                    <RequestCard
                                        id={data.id}
                                        type={data.type}
                                        units={data.units}
                                        transfer_to={data.transfer_to}
                                        key={data.id}
                                    />
                                )
                            })}
                        </>
                        :
                        <Grid container justifyContent="center" direction="column" alignItems="center" >
                            <img src="https://jendela360.com/assets/images/svg/key-warning.svg" alt="no-key-waring" />
                            <Typography variant="subtitle2" style={{ marginTop: "10px" }}>
                                Tidak ada pending request
                            </Typography>
                        </Grid>
                    }
                </Grid>
            }
        </Root>
    );
}

export default ActiveKeyList;
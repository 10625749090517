import { Button, Chip, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import VisuallyHiddenInput from '../../../_components/_form/VisuallyHiddenInput'
import LoadingScreen from '../../../_components/LoadingScreen';

/**
 * Icons
 */
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

type Props = {
    onSubmitPayment: (payment: File) => void
    loading: boolean
    open: boolean
    onClose: () => void
}

const PaymentModal = (props: Props) => {
    const [payment, setPayment] = useState<File | null>(null)

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPayment(prev => e.target.files ? e.target.files[0] : null)
    }

    const handleSubmit = () => {
        if (payment) {
            props.onSubmitPayment(payment)
        }
    }

    useEffect(() => {
        if (!props.open) {
            setPayment(null)
        }
    }, [props.open])
   
    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            maxWidth="xs"
            fullWidth
        >
            <LoadingScreen open={props.loading} fullScreen/>
            <DialogTitle>Bukti Pembayaran</DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} rowSpacing={2}>
                    <Grid item xs={12}>
                        {
                            payment ?
                                <Chip label={payment.name} onDelete={() => {
                                    setPayment(null)
                                }} /> :
                                <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                    Upload file
                                    <VisuallyHiddenInput
                                        type="file"
                                        multiple={false}
                                        accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf"
                                        onChange={handleChangeFile}
                                    />
                                </Button>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant='contained' disabled={!payment} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default PaymentModal
import React from "react";


import { Grid, Button, Theme } from "@mui/material";
import { generateStyle } from "../../../_utils/DefaultStyle";

const useStyles = generateStyle((theme: Theme) =>
({
  button: {
    fontSize: 16,
    textTransform: 'capitalize'
  }
}), "Button_Select"
)

interface IProps {
  list: object[]
  selected: Map<string, boolean>
  handleSelected: Function
  type: string
  mode: string
}

const ButtonSelect = (props: IProps) => {
  const { Root, classes } = useStyles()

  /**
   * Handle select for the button
   * @param key key for the map
   */
  const handleClick = (key: string) => {
    const value = props.selected.get(key) ? false : true
    props.handleSelected(props.type, props.mode, key, value)
  }

  /**
   * Render the button list
   */
  const render = props.list.map((element: any, idx: number) =>
    <Grid item xs={4} key={idx}>
      <Button
        variant={props.selected.get(element.id) ? 'contained' : 'outlined'}
        color={props.selected.get(element.id) ? 'primary' : undefined}
        className={classes.button}
        name={element.value}
        onClick={() => handleClick(element.id)}
        size="large"
        fullWidth={true}
      >
        {element.label}
      </Button>
    </Grid>
  )

  return (
    <Root>
      <Grid container spacing={2}>
        {render}
      </Grid>
    </Root>
  )
}

export default ButtonSelect

import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
    IconButton,
    Typography,
    Button,
    Theme,
    Grid,
    Switch,
    Paper,
    Tabs,
    Tab
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import VisibilityIcon from '@mui/icons-material/Visibility';

/**
 * Components
 */
import ImageFormPage from '../../_components/_form/ImageForm/Page';
import FileList from './_components/FileList';
import NewCommentList from '../../_components/NewCommentList';
import LogList from './_components/LogList';
import ClosingHistoryList from './_components/ClosingHistoryList';
import SwitchAvailableModal from './_components/_modals/SwitchAvailableModal';
import SwitchAvailableSellModal from './_components/_modals/SwitchAvailableSellModal';
import SwitchPublishModal from './_components/_modals/SwitchPublishModal';
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';
import VoidDocusignModal from './_components/VoidDocusignModal';
import ActivityLogList from './_components/ActivityLogList';
import ImagePreview from '../../_components/ImagePreview';
import KeyLogList from './_components/KeyLogList';
import SwitchEntrustKeyModal from './_components/_modals/SwitchEntrustKeyModal';

/**
 * Icons
 */
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

/**
 * Asset
 */
import { generalErrorHandler, dateFormat, numberToCurrency, renderWarningButton } from '../../_utils/Helper';

/**
 * Interface
 */
import { IHistory } from './_components/ClosingHistoryList';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import DOMPurify from 'dompurify';
import UnitActivityListModal from '../_closing/_components/Modal/UnitActivityListModal';
import BankTable from '../../_components/_dataTable/BankTable';
import { PermissionContext } from '../../_contexts/PermissionContext';
import ReasonModal from './_components/_modals/ReasonModal';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    open: boolean;
    onModalClose: Function;
    unitCode?: string
}

export interface IUnit {
    id: number;
    is_owner: number;
    apartment_name: string;
    name: string;
    code: string;
    category: number;
    tower: string;
    floor_level: number;
    unit_number: string;
    unit_area: number;
    view_direction: string;
    furnished: string,
    bedroom: number;
    bathroom: number;
    maid_room: number;
    is_available: number;
    is_available_sell: number,
    is_special_price: number,
    is_entrusted_key: number,
    is_active: number,
    available_date: string;
    signing_date: string;
    published_date: string;
    price_per_1_month: number;
    basic_price_1: number;
    price_per_3_months: number;
    basic_price_3: number;
    price_per_6_months: number;
    basic_price_6: number;
    price_per_12_months: number;
    pic_landlord_id: string;
    pic_landlord_name: string,
    pic_landlord_phone: string,
    basic_price_12: number;
    selling_price: number;
    basic_price_sell: number;
    service_charge: number | string;
    landlord_id: number;
    is_agreement_sent: number;
    is_agreement_finished: number;
    file_list: Array<any>;
    seekmi_agent_name: string;
    inventory_status: number;
    pic_name: string;
    pic_email: string;
    pic_handphone: string;
    assigned_advisor_label: string,
    pic_listing_name_label: string,
    current_holder: string,
    created_at: string;
    created_by_label: string;
    is_requested_change_availability: number,
    vacant_days: string;
    updated_at: string;
    keterangan: string;
    is_priority_sort: number
    closing_histories: IHistory[]
    request_deleted_by: string | null;
    deleted_reason: string | null;
    deleted_by: string | null;
    source_input_label?: string
}

export interface IInventoryDetail {
    category_id: number;
    name: string;
    type: string;
    quantity: string;
    description: string;
    files: any[];
    thumbnails: string[];
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paperTab: {
        margin: "0"
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
    paper_label: {
        position: "absolute",
        marginTop: "-45px",
        padding: "1px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)"
    },
    box: {
        padding: theme.spacing(2)
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2, 6, 2),
        marginTop: theme.spacing(5),
    },
    imageTab: {
        padding: theme.spacing(0, 2),
    },
    actionButtonContainer: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
        overflow: 'auto',
        margin: '0 0',
        '& button, a': {
            marginLeft: '10px'
        }
    },
}), "UnitModalDetail"
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{
                maxHeight: 400,
                overflow: 'auto',
            }}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

const a11yProps = (index: any) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const UnitModalDetail = (props: Props) => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const params: any = useParams();
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isInit, setIsInit] = useState(true)
    const [unit, setUnit] = useState<IUnit>({
        id: 0,
        is_owner: 0,
        apartment_name: "",
        name: "",
        code: "",
        category: 0,
        tower: "",
        floor_level: 0,
        unit_number: "",
        unit_area: 0,
        view_direction: "",
        furnished: "",
        bedroom: 0,
        bathroom: 0,
        maid_room: 0,
        is_available: 0,
        is_available_sell: 0,
        is_special_price: 0,
        is_entrusted_key: 0,
        is_active: 0,
        available_date: "",
        signing_date: "",
        published_date: "",
        price_per_1_month: 0,
        basic_price_1: 0,
        price_per_3_months: 0,
        basic_price_3: 0,
        price_per_6_months: 0,
        basic_price_6: 0,
        price_per_12_months: 0,
        basic_price_12: 0,
        selling_price: 0,
        basic_price_sell: 0,
        landlord_id: 0,
        service_charge: 0,
        is_agreement_sent: 0,
        is_agreement_finished: 0,
        file_list: [],
        seekmi_agent_name: "",
        inventory_status: 0,
        pic_name: "",
        pic_email: "",
        pic_handphone: "",
        pic_landlord_id: '',
        pic_landlord_name: '',
        pic_landlord_phone: '',
        assigned_advisor_label: "",
        pic_listing_name_label: "",
        current_holder: "",
        created_at: "",
        created_by_label: "",
        is_requested_change_availability: 0,
        updated_at: "",
        keterangan: "",
        vacant_days: "",
        is_priority_sort: 0,
        closing_histories: [],
        request_deleted_by: null,
        deleted_reason: null,
        deleted_by: null,
        source_input_label: '',
    });

    const [voidComment, setVoidComment] = useState('');
    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);
    // const [alreadyPreview, setAlreadyPreview] = useState(false);
    const [currency, setCurrency] = useState('Rp');

    const unitCode = useMemo(() => props.unitCode || params.id, [props.unitCode, params.id])

    /**
    * Modal State
    * 0 - Close
    * 1 - Modal Agent
    * 2 - Key Log
    * 3 - Void Docusign
    * 4 - Last Contact Log
    * 5 - Last Contact Update
    * 6 - Showing Log
    * 7 - See Inventory
    * 8 - Inventory List (Seekmi)
    * 9 - Inventory Detail
    * 10 - Modal Available
    * 11 - Modal Publish
     */
    const [modalState, setModalState] = useState(0);

    const handleModalClose = () => setModalState(0);

    useEffect(() => {
        if (props.open) {
            loadUnit();
        }
        // getTmpCode();
        // eslint-disable-next-line
    }, [props.open]);

    useEffect(() => {
        // setLastContactDesc('');
        // setkeyLogDesc('');
        setVoidComment('');
    }, [modalState]);

    useEffect(() => {
        if (value === 1) {
            loadFiles();
        }
        // eslint-disable-next-line
    }, [value]);

    const loadUnit = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/unit/detail-code`, { params: { code: unitCode } })
            .then(res => {
                setCurrency(res.data.is_dollar ? '$' : 'Rp');
                setUnit(res.data);
                // setComments(res.data.comments);
                // setReports(res.data.data.report);
            })
            .catch(err => {
                generalErrorHandler(err);
                props.onModalClose()
            })
            .finally(() => {
                setIsLoading(false);
                setIsInit(false)
            });
    }

    const renderImages = () => {
        return (
            <div className={classes.imageTab}>
                <ImageFormPage
                    API_URL={process.env.REACT_APP_API_URL + '/unit/' + unit.id + '/image'}
                    type={'unit'}
                    withoutAdd={true}
                />
            </div>
        )
    }

    const renderActivityLogs = () => {
        return (
            <ActivityLogList
                unitId={unit.id}
                API_URL={process.env.REACT_APP_API_URL + '/unit/activity'}
            />
        )
    }

    const renderClosingHistories = () => {
        return (
            <ClosingHistoryList
                histories={unit.closing_histories}
                permissions={permissions}
            />
        )
    }

    const renderKeyLogs = () => {
        return (
            <KeyLogList
                unitId={unit.id}
                API_URL={process.env.REACT_APP_API_URL + '/unit/key-log'}
            />
        )
    }

    const renderLogs = () => {
        return (
            <LogList
                API_URL={process.env.REACT_APP_API_URL + '/unit/' + unit.id + '/log'}
            />
        )
    }

    const renderFiles = () => {
        return <FileList
            unit_id={unit.id}
            permissions={permissions}
            files={unit.file_list || []}
            onUploadFile={handleUploadFile}
            onDeleteFile={loadFiles}
        />
    }

    const loadFiles = () => {
        setIsLoading(true);
        DefaultAxios
            .get(process.env.REACT_APP_API_URL + '/unit/' + unit.id + '/file')
            .then(res => {
                setUnit((prevUnit: any) => ({
                    ...prevUnit,
                    file_list: res.data
                }));
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleUploadFile = (fd: FormData) => {
        setIsLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/unit/${unit.id}/file`, fd)
            .then(res => {
                Swal.fire({
                    title: "Upload berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadFiles();
                    })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    // const renderSigningStatus = () => {
    //     if (unit.id) {
    //         if (+unit.is_agreement_finished === 1) {
    //             return <>
    //                 Done
    //                 {
    //                     permissions['docusign.void'] &&
    //                     <Button
    //                         color="secondary"
    //                         variant="contained"
    //                         style={{ marginLeft: '15px' }}
    //                         onClick={() => setModalState(3)}
    //                     >
    //                         Void Contract
    //                     </Button>
    //                 }
    //                 <Button
    //                     variant="contained"
    //                     style={{ marginLeft: '15px' }}
    //                     onClick={handlePreviewContract}
    //                 >
    //                     Preview
    //                 </Button>
    //             </>
    //         } else if (+unit.is_agreement_sent === 1) {
    //             return <>
    //                 Sent
    //                 {
    //                     permissions['docusign.void'] &&
    //                     <Button
    //                         color="secondary"
    //                         variant="contained"
    //                         style={{ marginLeft: '15px' }}
    //                         onClick={() => setModalState(3)}
    //                     >
    //                         Void Contract
    //                     </Button>
    //                 }
    //                 <Button
    //                     variant="contained"
    //                     style={{ marginLeft: '15px' }}
    //                     onClick={handlePreviewContract}
    //                 >
    //                     Preview
    //                 </Button>

    //             </>
    //         } else {
    //             return (
    //                 <>
    //                     On Process
    //                     <Button
    //                         color="primary"
    //                         variant="contained"
    //                         style={{ marginLeft: '15px' }}
    //                         onClick={handleSendContract}
    //                     >
    //                         Send Digital Contract
    //                     </Button>
    //                     <Button
    //                         variant="contained"
    //                         style={{ marginLeft: '15px' }}
    //                         onClick={handlePreviewContract}
    //                     >
    //                         Preview
    //                     </Button>
    //                 </>
    //             )
    //         }
    //     } else {
    //         return "-"
    //     }
    // }

    // const handlePreviewContract = () => {
    //     setIsLoading(true);
    //     DefaultAxios
    //         .get(`${process.env.REACT_APP_API_URL}/unit/preview/${unit.id}`, { responseType: 'blob' })
    //         .then(res => {
    //             //Build a URL from the file
    //             const fileURL = URL.createObjectURL(res.data);

    //             //Open the URL on new Window
    //             window.open(fileURL);

    //             setAlreadyPreview(true);
    //         })
    //         .catch(err => {
    //             generalErrorHandler(err);
    //         })
    //         .finally(() => {
    //             setIsLoading(false);
    //         })
    // }

    // const handleSendContract = () => {
    //     if (!alreadyPreview) {
    //         Swal.fire({
    //             title: "Error",
    //             html: "Silahkan preview terlebih dahulu sebelum mengirim contract",
    //             icon: 'error',
    //         });
    //         return;
    //     }

    //     setIsLoading(true);
    //     DefaultAxios
    //         .post(`${process.env.REACT_APP_API_URL}/unit/send-contract/${unit.id}`)
    //         .then(res => {
    //             Swal.fire({
    //                 html: "Contract Sent",
    //                 icon: 'success',
    //                 onAfterClose: () => {
    //                     loadUnit();
    //                 },
    //                 timer: 1000
    //             });
    //         })
    //         .catch(err => {
    //             generalErrorHandler(err);
    //         })
    //         .finally(() => {
    //             setIsLoading(false);
    //         })
    // }

    const handleSubmitVoid = () => {
        if (!voidComment) {
            return;
        }

        setIsLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/unit/void-contract/${unit.id}`, { comment: voidComment })
            .then(res => {
                Swal.fire({
                    html: "Contract Voided",
                    icon: 'success',
                    onAfterClose: () => {
                        loadUnit();
                        handleModalClose();
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    // const handleRejectChangeAvailability = () => {
    //     renderWarningButton('Apakah anda yakin ingin reject request ini?')
    //         .then((result) => {
    //             if (result.value) {
    //                 setIsLoading(true);
    //                 DefaultAxios
    //                     .post(`${process.env.REACT_APP_API_URL}/unit/reject-request-change`, { unit_id: unit.id })
    //                     .then(() => {
    //                         Swal.fire({
    //                             title: "Reject berhasil",
    //                             icon: 'success',
    //                             timer: 1000,
    //                             onAfterClose: loadUnit
    //                         });
    //                     })
    //                     .catch(error => {
    //                         generalErrorHandler(error)
    //                     })
    //                     .finally(() => {
    //                         setIsLoading(false);
    //                     })
    //             }
    //         })
    // }

    const handlePrioritySwitch = () => {
        renderWarningButton('Apakah anda yakin ingin mengubah status priority?')
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit/switch-priority-sort`, { id: unit.id })
                        .then(res => {
                            loadUnit();
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleSpecialPriceSwitch = () => {
        renderWarningButton('Apakah anda yakin ingin mengubah status special price?')
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit/switch-special-price`, { id: unit.id })
                        .then(res => {
                            loadUnit();
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    return (
        <ModalDetail
            open={props.open}
            isLoading={isLoading}
            useOverlayLoading={!isInit}
            title={(
                <>
                    Unit - [{unit.code}] {(unit.vacant_days !== "Rented" && unit.vacant_days !== "-1") && `(${unit.vacant_days} Days Vacant)`}
                    <IconButton style={{ color: 'white', marginLeft: 6, verticalAlign: 'sub', padding: 0 }} href={`${process.env.REACT_APP_MAIN_URL}/${unit.code}`} target="_blank">
                        <VisibilityIcon />
                    </IconButton>
                </>
            )}
            showEditButton
            editButtonLink={() => navigate(`/unit/edit/${unit.id}`)}
            onCloseModal={() => props.onModalClose()}
            stickyHeader
        >
            <>
                <ModalSection
                    xs={12}
                    md={6}
                    title="Informasi Dasar"
                    content={[
                        ['Sumber', unit.source_input_label],
                        ['Available Date', unit.available_date],
                        ['Signing Date', unit.signing_date],
                        ['Published Date', unit.published_date ? dateFormat(unit.published_date, 'DD MMM YYYY') : "-"],
                        ['Title', unit.name],
                        ['Apartment Name', unit.apartment_name],
                        ['Tower / Floor / Unit Number', `${unit.tower} / ${unit.floor_level} / ${unit.unit_number}`],
                        ['Unit View', unit.view_direction],
                        ['Unit Area', `${unit.unit_area} m2`],
                        ['Furnished', unit.furnished],
                        ['Bedroom / Bathroom / Maidroom', `${unit.bedroom ? `${unit.bedroom} Bedroom` : 'Studio'} / ${unit.bathroom} Bathroom / ${unit.maid_room} Maidroom`],
                        ['Created Date', `${dateFormat(unit.created_at, 'DD MMM YYYY HH:mm:ss')} by ${unit.created_by_label}`],
                        ['Last Modified Date', dateFormat(unit.updated_at, 'DD MMM YYYY HH:mm:ss')],
                        ['Keterangan', unit.keterangan ? (<Grid item xs={6} sm={8} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(unit.keterangan) }} />) : '-'],
                    ]}
                />

                <Grid item md={6} xs={12}>
                    <ModalSection
                        title="Informasi Harga"
                        content={[
                            // ['Sewa Harian', unit.price_per_day ? numberToCurrency(unit.price_per_day, `${currency}.`) : `${currency}. -`],
                            ['Sewa 12 Bulan', unit.price_per_12_months ? `Basic Price: ${numberToCurrency(unit.basic_price_12, `${currency}.`)} | Web Price: ${numberToCurrency(unit.price_per_12_months, `${currency}.`)}` : `${currency}. -`],
                            ['Sewa 6 Bulan', unit.price_per_6_months ? `Basic Price: ${numberToCurrency(unit.basic_price_6, `${currency}.`)} | Web Price: ${numberToCurrency(unit.price_per_6_months, `${currency}.`)}` : `${currency}. -`],
                            ['Sewa 3 Bulan', unit.price_per_3_months ? `Basic Price: ${numberToCurrency(unit.basic_price_3, `${currency}.`)} | Web Price: ${numberToCurrency(unit.price_per_3_months, `${currency}.`)}` : `${currency}. -`],
                            ['Sewa Bulanan', unit.price_per_1_month ? `Basic Price: ${numberToCurrency(unit.basic_price_1, `${currency}.`)} | Web Price: ${numberToCurrency(unit.price_per_1_month, `${currency}.`)}` : `${currency}. -`],
                            ['Harga Jual', unit.selling_price ? `Basic Price: ${numberToCurrency(unit.basic_price_sell, `${currency}.`)} | Web Price: ${numberToCurrency(unit.selling_price, `${currency}.`)}` : `${currency}. -`],
                            ['Service Charge', unit.service_charge]
                        ]}
                    />

                    <ModalSection
                        title="Informasi Lainnya"
                        content={[
                            ['PIC Listing', unit.pic_listing_name_label ? unit.pic_listing_name_label : '-'],
                            ['Ownership', unit.is_owner ? 'Owner' : 'Agent'],
                            ...(unit.is_owner ?
                                [[
                                    <>
                                        <span>PIC Owner Information</span>
                                        {
                                            permissions['pic-landlord.show'] && unit.pic_landlord_id ?
                                                <IconButton
                                                    title='Show PIC Detail Information'
                                                    onClick={() => window.open(`/pic-landlord/${unit.pic_landlord_id}`)}
                                                >
                                                    <VisibilityIcon color='primary' />
                                                </IconButton>
                                                : null
                                        }
                                    </>,
                                    unit.pic_landlord_name && unit.pic_landlord_phone ?
                                        <>
                                            <span>{unit.pic_landlord_name}</span> - <span>{unit.pic_landlord_phone}</span>
                                            <IconButton href={`tel:${unit.pic_landlord_phone}`} size="small" style={{ marginRight: "5px" }}>
                                                <PhoneIcon />
                                            </IconButton>
                                            <IconButton href={`https://wa.me/${unit.pic_landlord_phone}`} size="small" target="_blank" rel="noopener noreferrer">
                                                <WhatsAppIcon />
                                            </IconButton>
                                        </> :
                                        '-'
                                ]] : []
                            )
                            ,
                            [<>
                                Ownership Name
                                {unit.is_owner === 1 && <IconButton color="primary" style={{ marginLeft: 6, verticalAlign: 'middle', padding: 0 }} href={`/landlord/${unit.landlord_id}`} target="_blank">
                                    <VisibilityIcon />
                                </IconButton>}
                            </>, (
                                <>
                                    {unit.pic_name}
                                    {/* - {unit.pic_email && unit.pic_email !== "-" ? unit.pic_email : `[NO EMAIL]`} - {unit.pic_handphone ? <a href={`https://wa.me/${unit.pic_handphone}`} target="_blank" rel="noopener noreferrer">{unit.pic_handphone}</a> : `[NO PHONE]`} */}

                                </>
                            )],
                            ['Owner Email', unit.pic_email && unit.pic_email !== "-" ? unit.pic_email : `[NO EMAIL]`],
                            ['Owner Phone', unit.pic_handphone && unit.pic_handphone !== "-" ? <a href={`https://api.whatsapp.com/send/?phone=${unit.pic_handphone}`} target="_blank" rel="noopener noreferrer">{unit.pic_handphone}</a> : `[NO EMAIL]`],
                            // ['Assigned Property Manager', unit.assigned_advisor_label],
                            ['Key Holder', unit.current_holder],
                            // (typeof unit.id !== 'undefined' && ([1, 3].includes(unit.category))) && ['Docusign Information', renderSigningStatus()],
                            // unit.is_requested_change_availability === 1 && ['Request Change Available', (
                            //     <Button
                            //         variant="contained"
                            //         color="secondary"
                            //         size="small"
                            //         onClick={handleRejectChangeAvailability}
                            //     >
                            //         Reject
                            //     </Button>
                            // )]
                        ]}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Root>
                        <div className={classes.actionButtonContainer}>
                            {
                                permissions['unit.request-delete'] && !unit.request_deleted_by ?
                                    <Grid>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={() => {
                                                setModalState(15)
                                            }}
                                        >
                                            Request Delete
                                        </Button>
                                    </Grid>
                                    : null
                            }
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        setModalState(12)
                                    }}
                                >
                                    List Activity
                                </Button>
                            </Grid>
                        </div>
                    </Root>
                </Grid>

                <Grid item xs={12}>
                    <Root>
                        <Paper className={classes.root}>
                            <Typography variant="h6">
                                Unit Status
                            </Typography>
                            <Grid container justifyContent="space-between">
                                <Grid>
                                    <Typography variant="subtitle2">Available</Typography>
                                    <Switch
                                        checked={unit.is_available === 1}
                                        onChange={() => setModalState(10)}
                                        color="secondary"
                                    />
                                </Grid>
                                <Grid>
                                    <Typography variant="subtitle2">Available Sell</Typography>
                                    <Switch
                                        checked={unit.is_available_sell === 1}
                                        onChange={() => setModalState(14)}
                                        color="secondary"
                                    />
                                </Grid>
                                {permissions['unit.change-toggle-priority-sort'] &&
                                    <Grid>
                                        <Typography variant="subtitle2">Is Priority Sort</Typography>
                                        <Switch
                                            checked={unit.is_priority_sort === 1}
                                            onChange={() => handlePrioritySwitch()}
                                            color="secondary"
                                        />
                                    </Grid>
                                }
                                <Grid>
                                    <Typography variant="subtitle2">Published</Typography>
                                    <Switch
                                        checked={unit.is_active === 1}
                                        onChange={() => setModalState(11)}
                                        color="secondary"
                                    />
                                </Grid>
                                <Grid>
                                    <Typography variant="subtitle2">Titip Kunci</Typography>
                                    <Switch
                                        checked={unit.is_entrusted_key === 1}
                                        onChange={() => setModalState(13)}
                                        color="secondary"
                                    />
                                </Grid>
                                <Grid>
                                    <Typography variant="subtitle2">Special Price</Typography>
                                    <Switch
                                        checked={unit.is_special_price === 1}
                                        onChange={() => handleSpecialPriceSwitch()}
                                        color="secondary"
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Root>
                </Grid>

                <Grid item xs={12}>
                    <Root>
                        <Paper className={classes.root}>
                            <div className={classes.paperTab}>
                                <Tabs
                                    style={{ backgroundColor: 'white' }}
                                    textColor="primary"
                                    value={value} onChange={(e, newValue) => setValue(newValue)}
                                    aria-label="simple tabs example"
                                >

                                    <Tab label="Comments" value={0} {...a11yProps(0)} />
                                    {permissions['unit.file'] &&
                                        <Tab label="File" value={1} {...a11yProps(1)} />
                                    }

                                    <Tab label="Images" value={2} {...a11yProps(2)} />
                                    <Tab label="Key Logs" value={3} {...a11yProps(3)} />
                                    <Tab label="Activity Logs" value={4} {...a11yProps(4)} />

                                    {permissions['unit.see-log'] &&
                                        <Tab label="Logs" value={5} {...a11yProps(5)} />
                                    }

                                    {
                                        permissions['unit.bank-manage'] &&
                                        <Tab label="Bank" value={6} {...a11yProps(5)} />
                                    }
                                </Tabs>
                            </div>

                            <TabPanel value={value} index={0}>
                                <br></br>
                                <NewCommentList
                                    id={unit.id}
                                    type="unit"
                                    ignoreMaxHeight
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <br></br>
                                {renderFiles()}
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <br></br>
                                {renderImages()}
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <br></br>
                                {renderKeyLogs()}
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <br></br>
                                {renderActivityLogs()}
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <br></br>
                                {renderLogs()}
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <br></br>
                                {/* {renderTransactionHistory()} */}
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                <br></br>
                                <BankTable
                                    id={unit.id.toString()}
                                    component="unit"
                                    overWriteValue={{
                                        is_verified: 1,
                                    }}
                                    withoutVerify
                                    withoutEdit
                                />
                            </TabPanel>
                        </Paper>
                    </Root>
                </Grid>

                {
                    permissions['unit.closing-history'] ?
                        <ModalSection
                            title="Transaction History"
                            content={[]}
                            additionalContent={
                                permissions['closing.view'] &&
                                <div style={{ marginTop: 25 }}>
                                    {renderClosingHistories()}
                                </div>
                            }
                        />
                        : null
                }

                <SwitchAvailableModal
                    id={unit.id}
                    open={modalState === 10}
                    isAvailable={unit.is_available}
                    onClose={handleModalClose}
                    callBackSubmitSuccess={(value: any) => {
                        const newState = { ...unit };
                        newState.is_available = newState.is_available === 1 ? 0 : 1;
                        setUnit(newState)
                    }}
                    setIsLoading={(value: boolean) => setIsLoading(value)}
                    isLoading={isLoading}
                />

                <SwitchAvailableSellModal
                    id={unit.id}
                    open={modalState === 14}
                    isAvailable={unit.is_available_sell}
                    onClose={handleModalClose}
                    callBackSubmitSuccess={(value: any) => {
                        const newState = { ...unit };
                        newState.is_available_sell = newState.is_available_sell === 1 ? 0 : 1;
                        setUnit(newState)
                    }}
                    setIsLoading={(value: boolean) => setIsLoading(value)}
                    isLoading={isLoading}
                />

                <SwitchPublishModal
                    id={unit.id}
                    open={modalState === 11}
                    isActive={unit.is_active}
                    isOwner={unit.is_owner}
                    onClose={handleModalClose}
                    callBackSubmitSuccess={(value: any) => {
                        const newState = { ...unit };
                        newState.is_active = newState.is_active === 1 ? 0 : 1;
                        setUnit(newState)
                    }}
                    setIsLoading={(value: boolean) => setIsLoading(value)}
                    isLoading={isLoading}
                />

                <SwitchEntrustKeyModal
                    id={unit.id}
                    open={modalState === 13}
                    status={{
                        isEntrustedKey: unit.is_entrusted_key,
                        category: unit.category
                    }}
                    onClose={handleModalClose}
                    callBackSubmitSuccess={(isCategoryChanged) => {
                        const newState = { ...unit };
                        newState.is_entrusted_key = newState.is_entrusted_key === 1 ? 0 : 1;
                        if (isCategoryChanged) {
                            newState.category = newState.category === 1 ? 0 : 1;
                        }
                        loadUnit()
                        setUnit(newState)
                    }}
                    setIsLoading={(value: boolean) => setIsLoading(value)}
                    isLoading={isLoading}
                />

                <VoidDocusignModal
                    open={modalState === 3}
                    onClose={handleModalClose}
                    comment={voidComment}
                    onCommentChange={(e) => setVoidComment(e.target.value)}
                    onHandleSubmitVoid={handleSubmitVoid}
                />
                <ImagePreview
                    image={previewImage}
                    onClose={() => setPreviewImage(undefined)}
                />

                <UnitActivityListModal
                    unitId={unit.id}
                    open={modalState === 12}
                    onClose={() => {
                        setModalState(0)
                    }}
                />

                <ReasonModal
                    id={unit.id}
                    open={modalState === 15}
                    onClose={(refresh) => {
                        setModalState(0)

                        if (refresh) loadUnit()
                    }}
                />
            </>
        </ModalDetail>
    );
}

export default UnitModalDetail;
import React, { useEffect } from 'react';
import { Grid, TextField } from '@mui/material';

// Interfaces
import { IState } from './VideoTab';

interface Props {
    form: IState;
    onChange: Function;
    resetForm: Date;
}

const Form = (props: Props) => {
    const { form, resetForm } = props;


    useEffect(() => {
        document.querySelectorAll('[type="file"]').forEach(el => {
            (el as HTMLInputElement).value = '';
        })
        // eslint-disable-next-line
    }, [resetForm]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={2}>
                Thumbnail
            </Grid>
            <Grid item xs={10}>
                <img src={form.thumbnail ? form.thumbnail : "http://jendela.test/assets/admin/img/default-thumbnail.jpg"} width="72" alt={form.alt} />
                <br />
                <input type="file" name="file" onChange={(e) => props.onChange(e)} />
                <br />
                <i>Suggested dimension : 72 x 72</i>
            </Grid>

            <Grid item xs={2}>
                Link
            </Grid>
            <Grid item xs={10}>
                <TextField
                    fullWidth
                    name="link"
                    variant="outlined"
                    value={form.link}
                    onChange={(e) => props.onChange(e)}
                />
                <span>Example : https://www.youtube.com/embed/VnNTp5rFchQ</span>
            </Grid>

            <Grid item xs={2}>
                Caption
            </Grid>
            <Grid item xs={10}>
                <TextField
                    fullWidth
                    name="caption"
                    variant="outlined"
                    value={form.caption}
                    onChange={(e) => props.onChange(e)}
                />
            </Grid>
        </Grid>
    );
}

export default Form;
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Theme, Dialog, Slide, Toolbar, IconButton, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import DataTable from '../../_components/_dataTable/DataTable';
import ApartmentsNotesForm from './ApartmentNotesForm';
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { PermissionContext } from '../../_contexts/PermissionContext';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    note_id?: string
}
interface IParams extends Record<string, string | undefined> {
    note_id?: string
}

const useStyles = generateStyle((theme: Theme) => ({
    actionContainer: {
        display: 'flex'
    },
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    notes_list: {
        maxWidth: '500px',
        lineBreak: 'anywhere',
        whiteSpace: 'pre-line'
    }
}), "ApartmentNotesList"
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ApartmentNotesList = (props: IProps) => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const DT_API_URL = process.env.REACT_APP_API_URL + '/apartment-notes';

    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [selectedId, setSelectedId] = useState<string>('');
    const [modalState, setModalState] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams<IParams>()
    const navigate = useNavigate();
    const handleOpen = () => setModalState(true);
    const handleClose = () => {
        setSelectedId('');
        setModalState(false)
        window.history.pushState([], 'Apartment Notes', `/apartment-notes/`);
    }

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Apartment Notes Form
                </Typography>
            </Toolbar>
        )
    }

    const openModalCreate = () => {
        setSelectedId('');
        handleOpen();
    }


    const submitSuccess = () => {
        handleClose();
        setSelectedId('');
        navigate(`/apartment-notes/`)
        // window.history.pushState([], 'Apartment Notes', `/apartment-notes/`);
        setReDrawDatatable(new Date().getTime());
    }

    useEffect(() => {
        if (params.note_id && typeof params.note_id === 'string') {
            setSelectedId(params.note_id)
            handleOpen()
        }
    }, [params]);

    const handleDelete = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin menghapus data ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${DT_API_URL}/${id}`)
                        .then(res => {
                            setReDrawDatatable(new Date().getTime());
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                open={modalState}
                onClose={handleClose}
                closeAfterTransition
                TransitionComponent={Transition}
                fullWidth
                maxWidth="lg"
            >
                <Root>
                    <FormToolbar></FormToolbar>
                    <ApartmentsNotesForm
                        onSubmitSuccess={submitSuccess}
                        noteId={selectedId}
                    />
                </Root>
            </Dialog>
            {
                permissions['apartment.manage-note'] ?
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.addButton}
                        startIcon={<AddIcon />}
                        onClick={openModalCreate}
                    >
                        Add Data
                    </Button>
                    : null
            }
            <DataTable
                reDraw={reDrawDatatable}
                url={DT_API_URL}
                columns={[
                    { name: 'apartment_name', label: 'Apartment Name', filterable: true },
                    { name: 'title', label: 'Title', filterable: true },
                    {
                        name: 'notes',
                        label: 'Notes',
                        filterable: true,
                        render: (data: string, row: any) => {
                            return (<div className={classes.notes_list}>{data}</div>);
                        }
                    },
                    {
                        name: 'EXTRA',
                        label: 'Action',
                        sortable: false,
                        render: (row: any) => {
                            return (
                                permissions['apartment.manage-note'] ?
                                    <div className={classes.actionContainer}>
                                        <IconButton
                                            color="primary"
                                            component="span"
                                            onClick={() => navigate(`/apartment-notes/${row.id}`)}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            component="span"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                    : <> </>
                            );
                        }
                    }
                ]}
            />
        </Root>
    );
}

export default ApartmentNotesList;
import React, { Fragment, useEffect, useState } from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, MenuItem, Link } from '@mui/material';
import Swal from 'sweetalert2';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';

// Components
import LoadingScreen from '../../../_components/LoadingScreen';
import { generalErrorHandler, renderWarningButton } from '../../../_utils/Helper';

interface Props {
    customerId: string;
}

interface IFile {
    id: string;
    filename: string;
    filepath: string;
    type: string;
}

interface IState {
    name: string;
    type: string;
    file: File | null;
}

interface IErrorState {
    name: string;
    type: string;
    file: string;
}

/**
 * 
 * props nanti bisa d ganti langsung files ny aja tergantung kemauan ny gmna nanti
 */
const CustomerFileTable = ({ customerId }: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/customer/' + customerId + '/file';

    const initialState = {
        name: '',
        type: '',
        file: null
    };

    const initialErrorState = {
        name: '',
        type: '',
        file: ''
    };

    const types = {
        'ktp': 'KTP',
        'kk': 'Kartu Keluarga',
        'kitas': 'KITAS',
        'passport': 'Passport',
        'npwp': 'NPWP',
        'other': 'Other'
    };

    const [files, setFiles] = useState<IFile[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [state, setState] = useState<IState>(initialState);
    const [errorState, setErrorState] = useState<IErrorState>(initialErrorState);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploadLoading, setIsUploadLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => {
        if (!isUploadLoading) {
            setModalOpen(false)
        }
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!modalOpen) {
            resetForm();
        }
        // eslint-disable-next-line
    }, [modalOpen]);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(API_URL)
            .then(res => {
                setFiles(res.data);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const resetForm = () => {
        setState(initialState);
        document.querySelectorAll('[type="file"]').forEach(el => {
            (el as HTMLInputElement).value = '';
        });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (name === 'file' && target.files && target.files[0]) {
            value = target.files[0];
        }

        setState(prevState => (
            {
                ...prevState,
                [name]: value
            }
        ));
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!state.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!state.type) {
            newError.type = 'Type wajib diisi';
            isValid = false;
        }

        if (!state.file) {
            newError.file = 'File wajib diisi';
            isValid = false;
        }

        setErrorState(newError);
        return isValid;
    }

    const handleUpload = () => {
        if (!checkValidation()) {
            return;
        }

        const fd = new FormData();

        for (let [key, value] of Object.entries(state)) {
            fd.append(key, value);
        }

        setIsUploadLoading(true);
        DefaultAxios.post(API_URL, fd)
            .then(res => {
                Swal.fire({
                    title: "Upload success",
                    icon: 'success',
                    onAfterClose: () => {
                        handleModalClose();
                        loadData();
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsUploadLoading(false);
            })
    }

    const handleDelete = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin menghapus file ini?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true)
                DefaultAxios.delete(API_URL + '/' + id)
                    .then(res => {
                        Swal.fire({
                            title: "Delete file success",
                            icon: 'success',
                            onAfterClose: () => {
                                loadData();
                            },
                            timer: 1000
                        });
                    })
                    .catch(err => {
                        generalErrorHandler(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }
        });
    }

    const handlePreview = (filepath: string) => {
        const url = filepath.split('?')[0];
        const ext = url.split('.').pop();

        const isImage = ['jpg', 'jpeg', 'png'].includes(String(ext));

        if (isImage) {
            setPreviewImage(filepath);
        } else {
            window.open(filepath);
        }
    }
    
    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                color="primary"
                variant="contained"
                style={{ marginBottom: '25px', float: 'right' }}
                onClick={handleModalOpen}
            >
                Add File
            </Button>
            <Dialog
                open={!!previewImage}
                onClose={() => { setPreviewImage(undefined) }}
                scroll="body"
                maxWidth={false}
            >
                <img src={previewImage} alt="preview" />
            </Dialog>
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                maxWidth="md"
            >
                <LoadingScreen open={isUploadLoading} />
                <DialogTitle>Upload File</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Nama"
                                name="name"
                                value={state.name}
                                onChange={handleChange}
                                error={!!errorState.name}
                                helperText={errorState.name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                label="Type"
                                name="type"
                                value={state.type}
                                onChange={handleChange}
                                error={!!errorState.type}
                                helperText={errorState.type}
                            >
                                {Object.entries(types).map(([key, value]) => <MenuItem value={key} key={key}>{value}</MenuItem>)}
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <p>File</p>
                            <input type="file" name="file" onChange={handleChange} />
                            {errorState.file && <div style={{ color: 'red' }}>{errorState.file}</div>}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={handleUpload}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nama</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            files.length
                                ? files.map(file => (
                                    <TableRow key={file.id}>
                                        <TableCell>
                                            <Link
                                                onClick={() => handlePreview(file.filepath)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {file.filename}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{file.type}</TableCell>
                                        <TableCell>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => handleDelete(file.id)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                                : <TableRow>
                                    <TableCell colSpan={3}>No Files Available</TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    );
}

export default CustomerFileTable;
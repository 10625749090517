import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Box, Button, Dialog, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme, Tooltip, Typography } from '@mui/material'
import ModalSection from '../../../_components/_modalDetail/ModalSection'
import Transition from '../../../_components/Transition'

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generateStyle } from '../../../_utils/DefaultStyle'
import { generalErrorHandler, parsePaidBy, renderToastSuccess, renderWarningButton } from '../../../_utils/Helper'
import { format } from 'date-fns'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import { PAID_BY_OPTIONS } from '../../../_utils/ConstData'

/**
 * Icons
 */
import { Check, Delete, Edit } from '@mui/icons-material'

export type FixingItem = {
    id: string
    description: string
    paid_by: string
    done_at: string | null
}

type State = {
    id: string
    description: string
    paid_by: string
}

interface FixingListSectionProps {
    ticketId: string
    initialList: FixingItem[]
    setIsLoading: (value: boolean) => void
}

const FixingListSection = (props: FixingListSectionProps) => {
    const { Root, classes } = useStyles()
    const [isFormLoading, setIsFormLoading] = useState(false)

    const [items, setItems] = useState<FixingItem[]>([])
    const [state, setState] = useState<State>(INITIAL_STATE)
    const [error, setError] = useState<IValidationErrors<State>>({})

    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
        if (props.initialList) {
            setItems(props.initialList)
        } else if (props.ticketId) {
            loadData()
        }
        // eslint-disable-next-line
    }, [props.initialList, props.ticketId])

    const closeModal = () => {
        setIsModalOpen(false)
        setState(INITIAL_STATE)
    }

    const loadData = () => {
        props.setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/ticket-fixing`, { params: { ticket_id: props.ticketId } })
            .then(res => res.data)
            .then(data => {
                setItems(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                props.setIsLoading(false)
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleDone = (id: string) => {
        renderWarningButton('Apakah anda yakin ingin menandai done item ini?')
            .then(res => {
                if (res.value) {
                    props.setIsLoading(true)

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/ticket-fixing/${id}/done`)
                        .then(res => res.data)
                        .then(data => {
                            renderToastSuccess(`Berhasil menandai item fixing done`)
                            loadData()
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            props.setIsLoading(false)
                        })
                }
            })
    }

    const handleEdit = (item: FixingItem) => {
        setState(prev => ({
            ...prev,
            description: item.description,
            paid_by: item.paid_by,
            id: item.id,
        }))
        setIsModalOpen(true)
    }

    const handleDelete = (id: string) => {
        renderWarningButton('Apakah anda yakin ingin menghapus item ini?')
            .then(res => {
                if (res.value) {
                    props.setIsLoading(true)

                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/ticket-fixing/${id}`)
                        .then(res => res.data)
                        .then(data => {
                            renderToastSuccess(`Berhasil menghapus item fixing`)
                            loadData()
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            props.setIsLoading(false)
                        })
                }
            })
    }

    const handleSubmit = () => {
        const { errors, isValid } = validateData(state, rules, alias)
        setError(errors)

        if (isValid) {
            setIsFormLoading(true)

            DefaultAxios[state.id ? 'put' : 'post'](`${process.env.REACT_APP_API_URL}/ticket-fixing${state.id ? `/${state.id}` : ''}`, {
                ticket_id: state.id ? null : props.ticketId,
                description: state.description,
                paid_by: state.paid_by,
            })
                .then(res => res.data)
                .then(data => {
                    renderToastSuccess(`Berhasil ${state.id ? 'mengubah' : 'menambahkan'} item fixing`)
                    loadData()
                    closeModal()
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsFormLoading(false)
                })
        }
    }

    return (
        <Root>
            <Dialog
                open={isModalOpen}
                onClose={() => isFormLoading ? null : closeModal()}
                closeAfterTransition
                TransitionComponent={Transition}
                fullWidth
                maxWidth="xs"
            >
                <Root>
                    <Box sx={{ p: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    Form Fixing
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="Deskripsi"
                                    name='description'
                                    value={state.description}
                                    onChange={handleChange}
                                    fullWidth
                                    error={!!error.description}
                                    helperText={error.description}
                                    disabled={isFormLoading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="Paid By"
                                    name='paid_by'
                                    value={state.paid_by}
                                    onChange={handleChange}
                                    fullWidth
                                    error={!!error.paid_by}
                                    helperText={error.paid_by}
                                    disabled={isFormLoading}
                                    select
                                >
                                    {
                                        PAID_BY_OPTIONS.filter(pbo => pbo.key !== '' && pbo.key !== 'deposit').map((pbo, index) =>
                                            <MenuItem key={index} value={pbo.key}>{pbo.value}</MenuItem>
                                        )
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12} display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'}>
                                <Button
                                    variant='contained'
                                    onClick={handleSubmit}
                                    disabled={state.description === '' || state.paid_by === '' || isFormLoading}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Root>
            </Dialog>

            <ModalSection
                content={[]}
                title=''
                xs={12}
                md={12}
                additionalContent={
                    <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>
                                        Deskripsi
                                    </TableCell>
                                    <TableCell>
                                        Paid By
                                    </TableCell>
                                    <TableCell>
                                        Done At
                                    </TableCell>
                                    <TableCell align='right'>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    items.length ?
                                        items.map((item, index) =>
                                            <TableRow key={index}>
                                                <TableCell align='left'>
                                                    {item.description}
                                                </TableCell>
                                                <TableCell>
                                                    {parsePaidBy(item.paid_by)}
                                                </TableCell>
                                                <TableCell>
                                                    {item.done_at ? format(new Date(item.done_at), 'dd MMM yyyy') : '-'}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {
                                                        item.done_at ?
                                                            '-'
                                                            :
                                                            <>
                                                                <Tooltip title='Edit'>
                                                                    <IconButton onClick={() => handleEdit(item)} color='warning'>
                                                                        <Edit />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title='Mark done'>
                                                                    <IconButton onClick={() => handleDone(item.id)} color='primary'>
                                                                        <Check />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title='Delete'>
                                                                    <IconButton onClick={() => handleDelete(item.id)} color='error'>
                                                                        <Delete />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                        :
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Typography variant='body2'>No data available</Typography>
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </>
                }
                titleContent={
                    <Grid container>
                        <Grid item xs={12} className={classes.todoHeader}>
                            <Typography variant='h6' className={classes.h6_header}>List Fixing</Typography>
                            <div>
                                <Button
                                    size="small"
                                    variant='contained'
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    Add
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                }
            />
        </Root>
    )
}

const useStyles = generateStyle(
    (theme: Theme) =>
    ({
        todoHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: 10
        },
        h6_header: {
            color: theme.palette.primary.main,
        },
    }),
    "Fixing_List_Section"
)

const INITIAL_STATE = {
    id: '',
    description: '',
    paid_by: '',
}

const rules: IValidationRules = {
    description: 'required',
    paid_by: 'required',
}

const alias: IValidationAlias = {
    description: 'Deskripsi',
    paid_by: 'Paid By',
}

export default FixingListSection

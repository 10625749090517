import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, TextField } from '@mui/material';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../_utils/Helper';
import SearchPhoneModal from './SearchPhoneModal';

export interface ISearchResponse {
    id: number
    code: string
    unit_code: string
    landlord_name: string
    customer_name: string
}

const CustomerPhoneSearch = () => {
    const navigate = useNavigate();
    const [searchResponse, setSearchResponse] = useState<ISearchResponse[]>([])
    // Ref
    const searchRef = useRef<any>(null);

    const handleResponse = (data: ISearchResponse[]) => {
        if (data && data.length === 1) {
            navigate(`/closing/${data[0].code}`, { state: { prevPath: window.location.pathname } });
        } else {
            setSearchResponse(data)
        }
    }

    const handleSearch = () => {
        if (searchRef.current!.value) {
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/new-closing/search-by-phone`, { params: { search: searchRef.current!.value } })
                .then(res => {
                    handleResponse(res.data)
                })
                .catch(err => {
                    generalErrorHandler(err);
                });
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        const key = e.key
        if (key === 'Enter') {
            handleSearch();
        }
    }
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="search"
                        placeholder="Search by Closing Code or Unit Code or Customer Phone"
                        inputRef={searchRef}
                        onKeyDown={handleKeyDown}
                    />
                </Grid>

                <Grid item xs={8}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>

            <SearchPhoneModal
                open={searchResponse.length > 1}
                onClose={() => setSearchResponse([])}
                data={searchResponse}
            />
        </>
    );
}

export default CustomerPhoneSearch;
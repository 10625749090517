import * as React from "react";

const PricePerIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.41424 14H1.7907C1.31592 13.9995 0.860732 13.8107 0.525016 13.475C0.1893 13.1393 0.00048267 12.6841 0 12.2093V2.44185C0.00048267 1.96708 0.1893 1.51189 0.525016 1.17617C0.860732 0.840453 1.31592 0.651636 1.7907 0.651154H12.2093C12.6841 0.651636 13.1393 0.840453 13.475 1.17617C13.8107 1.51189 13.9995 1.96708 14 2.44185V8.41392C14.0006 8.64921 13.9546 8.88229 13.8646 9.09967C13.7745 9.31705 13.6422 9.51441 13.4754 9.68031L9.68032 13.4754C9.51446 13.6422 9.31716 13.7745 9.09983 13.8645C8.88251 13.9546 8.64948 14.0006 8.41424 14ZM1.7907 1.6279C1.57488 1.6281 1.36797 1.71392 1.21537 1.86652C1.06276 2.01912 0.976944 2.22604 0.976744 2.44185V12.2093C0.976944 12.4251 1.06276 12.632 1.21537 12.7846C1.36797 12.9372 1.57488 13.023 1.7907 13.0232H8.41424C8.63 13.0227 8.83683 12.937 8.98973 12.7848L12.7848 8.98972C12.8606 8.91428 12.9208 8.82455 12.9617 8.72571C13.0026 8.62687 13.0236 8.5209 13.0233 8.41392V2.44185C13.0231 2.22604 12.9372 2.01912 12.7846 1.86652C12.632 1.71392 12.4251 1.6281 12.2093 1.6279H1.7907Z"
            fill="currentColor"
        />
        <path
            d="M3.74405 2.93023C3.61453 2.9302 3.49034 2.87873 3.39876 2.78715C3.30718 2.69557 3.25571 2.57137 3.25568 2.44186V0.488372C3.25568 0.358848 3.30713 0.234628 3.39872 0.143041C3.4903 0.0514533 3.61452 0 3.74405 0C3.87357 0 3.99779 0.0514533 4.08938 0.143041C4.18097 0.234628 4.23242 0.358848 4.23242 0.488372V2.44186C4.23239 2.57137 4.18092 2.69557 4.08934 2.78715C3.99776 2.87873 3.87356 2.9302 3.74405 2.93023Z"
            fill="currentColor"
        />
        <path
            d="M6.99997 2.93023C6.87045 2.9302 6.74626 2.87873 6.65468 2.78715C6.5631 2.69557 6.51163 2.57137 6.5116 2.44186V0.488372C6.5116 0.358848 6.56305 0.234628 6.65464 0.143041C6.74623 0.0514533 6.87044 0 6.99997 0C7.12949 0 7.25371 0.0514533 7.3453 0.143041C7.43689 0.234628 7.48834 0.358848 7.48834 0.488372V2.44186C7.48831 2.57137 7.43684 2.69557 7.34526 2.78715C7.25368 2.87873 7.12948 2.9302 6.99997 2.93023Z"
            fill="currentColor"
        />
        <path
            d="M10.2556 2.93023C10.1261 2.9302 10.0019 2.87873 9.91035 2.78715C9.81877 2.69557 9.76731 2.57137 9.76727 2.44186V0.488372C9.76727 0.358848 9.81873 0.234628 9.91031 0.143041C10.0019 0.0514533 10.1261 0 10.2556 0C10.3852 0 10.5094 0.0514533 10.601 0.143041C10.6926 0.234628 10.744 0.358848 10.744 0.488372V2.44186C10.744 2.57137 10.6925 2.69557 10.6009 2.78715C10.5094 2.87873 10.3852 2.9302 10.2556 2.93023Z"
            fill="currentColor"
        />
        <path
            d="M13.5116 4.88373H0.488372C0.358848 4.88373 0.234628 4.83227 0.143041 4.74069C0.0514533 4.6491 0 4.52488 0 4.39535C0 4.26583 0.0514533 4.14161 0.143041 4.05002C0.234628 3.95844 0.358848 3.90698 0.488372 3.90698H13.5116C13.6411 3.90698 13.7654 3.95844 13.857 4.05002C13.9485 4.14161 14 4.26583 14 4.39535C14 4.52488 13.9485 4.6491 13.857 4.74069C13.7654 4.83227 13.6411 4.88373 13.5116 4.88373Z"
            fill="currentColor"
        />
        <path
            d="M8.95346 13.8827C8.82395 13.8826 8.69975 13.8312 8.60816 13.7396C8.51658 13.648 8.46512 13.5238 8.46509 13.3943V9.60465C8.46545 9.30254 8.58563 9.01291 8.79926 8.79929C9.01288 8.58566 9.30251 8.46549 9.60462 8.46512H13.3946C13.5241 8.46512 13.6483 8.51657 13.7399 8.60816C13.8315 8.69975 13.883 8.82397 13.883 8.95349C13.883 9.08301 13.8315 9.20723 13.7399 9.29882C13.6483 9.39041 13.5241 9.44186 13.3946 9.44186H9.60462C9.5616 9.44237 9.52049 9.45968 9.49007 9.4901C9.45965 9.52052 9.44234 9.56164 9.44183 9.60465V13.3943C9.4418 13.5238 9.39033 13.648 9.29875 13.7396C9.20717 13.8312 9.08297 13.8826 8.95346 13.8827Z"
            fill="currentColor"
        />
    </svg>
);
export default PricePerIcon;

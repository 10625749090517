import React, { useState } from 'react'
import AgentForm, { IAgentResponse } from '../../../_agent/components/AgentForm'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { generateStyle } from '../../../../_utils/DefaultStyle'

type Props = {
    open: boolean
    onClose: (data?: IAgentResponse) => void
}


const useStyles = generateStyle((theme) => ({
    header: {
        background: "#F3F5F7",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        "& > h5": {
            fontWeight: "600",
        }
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: "16px 24px",
        borderTop: '1px solid #ddd'
    }
}), "AddAgent_Modal")

const AddAgentModal = (props: Props) => {
    const { Root, classes } = useStyles()
    const [submitTrigger, setSubmitTrigger] = useState(0)

    return (
        <Dialog
            {...props}
            onClose={() => props.onClose()}
            maxWidth="md"
            fullWidth
        >
            <Root>
                <DialogTitle className={classes.header}>
                    <Typography variant='h5'>
                        Add Agent
                    </Typography>
                    <IconButton onClick={() => props.onClose()}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container sx={{ my: 1 }}>
                        <Grid item xs={12}>
                            <AgentForm
                                newDesign
                                onSuccessSubmit={(data) => {
                                    props.onClose(data)
                                    setSubmitTrigger(0)
                                }}
                                onSubmit={submitTrigger}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.footer}>
                    <Button onClick={() => props.onClose()}>
                        Batalkan
                    </Button>
                    <Button variant={"contained"} onClick={() => setSubmitTrigger(new Date().getTime())}>
                        Submit
                    </Button>
                </DialogActions>
            </Root>
        </Dialog>
    )
}

export default AddAgentModal
import React, { useEffect, useMemo, useRef, useState } from 'react'

/**
 * Components
 */
import { Typography, DialogContent, Dialog, AppBar, Toolbar, IconButton, Slide, Button, Theme, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Chip, Divider } from '@mui/material'
import LoadingScreen from '../../../_components/LoadingScreen'
import InventoryItemFormModal from './InventoryItemForm';
import Swal from 'sweetalert2';
import VideoPreview from '../../../_components/VideoPreview';

/**
 * Utils
 */
import { TransitionProps } from '@mui/material/transitions';
import { IInventoryItem, isExtraRooms } from './InventoryRoomModal';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler, isMobile, renderErrorButton, renderWarningButton } from '../../../_utils/Helper';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Delete, PlayArrow } from '@mui/icons-material';
import { generateStyle } from '../../../_utils/DefaultStyle';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    activity: string
    roomName: string
    roomId: number
    activityId: string
    isOpen: boolean
    onClose: () => void
}

const InventoryItemModal = (props: IProps) => {
    const uploadInputRef = useRef<null | HTMLInputElement>(null)
    const { Root, classes } = useStyles()

    const [isFormOpen, setIsFormOpen] = useState(false)
    const [selectedItemId, setSelectedItemId] = useState<null | number>(null)

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState<IInventoryItem[]>([])

    const [isInit, setIsInit] = useState(true)

    const [roomVideoUrl, setRoomVideoUrl] = useState('')
    const [selectedVideo, setSelectedVideo] = useState('')

    const [isMb, setIsMobile] = useState(true)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(isMobile())
        }

        handleResize()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        if (props.roomId && props.activityId) {
            fetchData()
            setIsInit(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.roomId, props.activityId])

    useEffect(() => {
        if (!props.isOpen) setData([])
    }, [props.isOpen])

    useEffect(() => {
        if (!isFormOpen && !isInit) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormOpen])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/activity/${props.activityId}/listing-inventory/${props.roomId}/item`)
            .then(res => res.data)
            .then(data => {
                setData(data.items)
                setRoomVideoUrl(data.video_url || '')
                setIsLoading(false)
            })
            .catch(err => {
                generalErrorHandler(err)
                setIsLoading(false)
            })
    }

    const openForm = (item?: IInventoryItem) => {
        if (item) setSelectedItemId(item.id)
        setIsFormOpen(true)
    }

    const closeForm = () => {
        setIsFormOpen(false)
        setSelectedItemId(null)
    }

    // ITEM
    const removeItem = (id: number) => {
        renderWarningButton('Apakah anda yakin ingin menghapus barang ini?', 'Hapus Barang?')
            .then(result => {
                if (result.value) {
                    setIsLoading(true)

                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/activity/${props.activityId}/listing-inventory/${props.roomId}/item/${id}`)
                        .then(res => res.data)
                        .then(data => {
                            setIsLoading(false)
                            Swal.fire({
                                title: 'Berhasil Menghapus Barang',
                                icon: 'success',
                                timer: 1000,
                            })
                            fetchData()
                        })
                        .catch(err => {
                            generalErrorHandler(err)
                            setIsLoading(false)
                        })
                }
            })
    }

    const finishRoom = () => {
        let canSubmit = true;
        if (data.length) {
            data.forEach(item => {
                if (!item.is_done) {
                    canSubmit = false;
                }
            })
        } else {
            canSubmit = false;
        }

        if (canSubmit) {
            renderWarningButton('Apakah anda yakin ingin finish ruangan ini?', 'Finish ruangan?')
                .then(result => {
                    if (result.value) {
                        setIsLoading(true)

                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/${props.activityId}/listing-inventory/${props.roomId}/finish`)
                            .then(() => {
                                setIsLoading(false)
                                Swal.fire({
                                    title: 'Berhasil Finish Ruangan',
                                    icon: 'success',
                                    timer: 1000,
                                })
                                props.onClose()
                            })
                            .catch(err => {
                                generalErrorHandler(err)
                                setIsLoading(false)
                            })
                    }
                })
        } else {
            Swal.fire({
                title: "Listing not Finished",
                html: "Harap selesaikan semua item terlebih dahulu.",
                icon: 'error',
            });
        }
    }

    const onUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target

        if (files && files.length) {
            if (files[0].size > 8000000) {
                renderErrorButton('File tidak bisa lebih dari 8MB', 'File terlalu besar')
                return
            }
            setIsLoading(true)

            const fd = new FormData()

            fd.append('id', props.roomId.toString())
            fd.append('video', files[0])

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/upload-video-activity-inventory`, fd)
                .then(res => res.data)
                .then(() => {
                    fetchData()
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const videoFileName = useMemo(() => {
        if (!roomVideoUrl) return ''

        const fileNameArray = roomVideoUrl.split('/')
        const fileName = fileNameArray[fileNameArray.length - 1]

        return fileName
    }, [roomVideoUrl])

    return (
        <Dialog
            fullScreen={isMb}
            TransitionComponent={Transition}
            open={props.isOpen}
            onClose={props.onClose}
            fullWidth={!isMb}
            maxWidth={!isMb ? 'md' : undefined}
        >
            <Root>

                <LoadingScreen open={isLoading} fullScreen />
                <input
                    ref={uploadInputRef}
                    type="file"
                    name="video"
                    accept="video/mp4,video/x-m4v,video/*"
                    onChange={onUploadFile}
                    style={{ display: 'none' }}
                />
                <VideoPreview video={selectedVideo} onClose={() => setSelectedVideo('')} />
                {
                    data &&
                    <InventoryItemFormModal
                        activityId={props.activityId}
                        roomId={props.roomId}
                        roomName={props.roomName}
                        itemId={selectedItemId}
                        isOpen={isFormOpen}
                        onClose={closeForm}
                        activity={props.activity}
                        isMobile={isMb}
                        isExtraRooms={isExtraRooms(props.roomName)}
                    />
                }
                <AppBar className={classes.appBar} color={'primary'}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {props.roomName}
                        </Typography>
                        {
                            isMb ?
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={finishRoom}
                                    aria-label="finish"
                                >
                                    <AssignmentTurnedInIcon />
                                </IconButton>
                                :
                                null
                        }
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div className={classes.bodyMobile}>
                        {
                            roomVideoUrl ?
                                <>
                                    <div className={`${classes.roomCard}`} onClick={() => setSelectedVideo(roomVideoUrl)}>
                                        <IconButton
                                            onClick={e => {
                                                setSelectedVideo(roomVideoUrl)
                                            }}
                                        >
                                            <PlayArrow color="primary" />
                                        </IconButton>
                                        <span style={{ fontSize: 14 }}>
                                            Lihat Video
                                        </span>
                                    </div>
                                </>
                                : null
                        }
                        <div className={`${classes.roomCard}`} style={{ cursor: 'auto' }}>
                            <Button
                                variant='contained'
                                size='small'
                                style={{
                                    textTransform: 'capitalize',
                                }}
                                onClick={() => uploadInputRef.current?.click()}
                            >
                                Choose File
                            </Button>
                        </div>
                        {
                            data.length ?
                                data.map(item =>
                                    <div onClick={() => openForm(item)} key={item.id} className={`${classes.roomCard} ${item.is_done === 1 ? classes.roomDone : ''}`}>
                                        <span className="title">
                                            {item.name}
                                        </span>
                                        <div style={{ minHeight: 40 }}>
                                            {
                                                !isExtraRooms(props.roomName) &&
                                                <IconButton
                                                    className="delete-item"
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        removeItem(item.id)
                                                    }}
                                                >
                                                    <CancelIcon color="error" />
                                                </IconButton>
                                            }
                                        </div>
                                    </div>
                                )
                                : !isLoading &&
                                <span className={classes.empty}>
                                    Maaf, belum ada data barang
                                </span>
                        }
                        {
                            !isExtraRooms(props.roomName) &&
                            <Button
                                onClick={() => openForm()}
                                color="primary"
                                fullWidth
                                variant="contained"
                                startIcon={<AddIcon />}
                            >
                                Add Item
                            </Button>
                        }
                    </div>
                    <div className={classes.bodyDesktop}>
                        {
                            !isExtraRooms(props.roomName) &&
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 12,
                                    }}
                                >
                                    {
                                        roomVideoUrl ?
                                            <>
                                                <IconButton
                                                    onClick={e => {
                                                        setSelectedVideo(roomVideoUrl)
                                                    }}
                                                >
                                                    <PlayArrow color="primary" />
                                                </IconButton>
                                                <span
                                                    onClick={() => setSelectedVideo(roomVideoUrl)}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {videoFileName}
                                                </span>
                                            </>
                                            :
                                            'No Video Uploaded'
                                    }
                                    <div
                                        style={{
                                            marginLeft: 'auto'
                                        }}
                                    >
                                        <Button
                                            variant='contained'
                                            size='small'
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => uploadInputRef.current?.click()}
                                        >
                                            Upload Video
                                        </Button>
                                    </div>
                                </div>
                                <Divider />
                            </>
                        }
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                marginBottom: 12,
                                marginTop: 12,
                            }}
                        >
                            <Button
                                onClick={() => openForm()}
                                color="primary"
                                variant="contained"
                                startIcon={<AddIcon />}
                                size='small'
                            >
                                Add Item
                            </Button>
                        </div>
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                Status
                                            </TableCell>
                                            <TableCell align='right'>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            data.length ?
                                                data.map(item =>
                                                    <TableRow key={item.id}>
                                                        <TableCell>
                                                            {item.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Chip
                                                                label={item.is_done ? 'Finished' : 'Unfinished'}
                                                                color={item.is_done ? 'success' : 'default'}
                                                            />
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            <Button
                                                                variant='contained'
                                                                size="small"
                                                                onClick={() => openForm(item)}
                                                            >
                                                                Edit
                                                            </Button>
                                                            {
                                                                !isExtraRooms(props.roomName) &&
                                                                <Button
                                                                    variant='contained'
                                                                    color='error'
                                                                    size="small"
                                                                    onClick={() => removeItem(item.id)}
                                                                    startIcon={<Delete />}
                                                                    style={{
                                                                        marginLeft: 8,
                                                                    }}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                                : isLoading ?
                                                    <TableRow>
                                                        <TableCell colSpan={3} align='center'>
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                    :
                                                    <TableRow>
                                                        <TableCell colSpan={3} align='center'>
                                                            Maaf, belum ada data barang
                                                        </TableCell>
                                                    </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </DialogContent>
            </Root>
        </Dialog>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bodyMobile: {
        padding: 16,
        display: 'grid',
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        gap: 20,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    bodyDesktop: {
        padding: 16,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    roomCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        boxShadow: '3px 5px 6px 0px rgba(0,0,0,0.1)',
        borderRadius: 6,
        padding: '4px 8px',
        cursor: 'pointer',

        '& > .title': {
            fontWeight: 500,
        },
    },
    roomDone: {
        backgroundColor: '#95FE95',
    },
    empty: {
        fontSize: 18,
        opacity: 0.85,
        textAlign: 'center',
    },
    videoCard: {
        width: '100%',
        paddingTop: '56.25%',
        position: 'relative',
    },
    video: {
        position: 'absolute',
        inset: 0,
        width: '100%',
        height: '100%',
    },
}), "InventoryItemsModal")

export default InventoryItemModal

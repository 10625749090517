import { Theme } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import CalendarSVG from '../../Assets/Images/svg/calendar.svg';
import ShieldSVG from '../../Assets/Images/svg/shield.svg';
import SandClockSVG from '../../Assets/Images/svg/sand-clock.svg';
import Placement1 from '../../Assets/Images/png/placement_1.png'
import Placement2 from '../../Assets/Images/png/placement_2.png'
import Placement3 from '../../Assets/Images/png/placement_3.png'
import JendelaLogo from '../../Assets/Images/Logo-Jendela-360-horizontal.png'
import QuoteSVG from '../../Assets/Images/svg/quote.svg'

import DefaultAxios from '../../_utils/DefaultAxios'

import {
    ArgumentAxis,
    ValueAxis,
    Chart,
    BarSeries,
} from '@devexpress/dx-react-chart-material-ui';
import LoadingScreen from '../../_components/LoadingScreen'
import { generalErrorHandler, getDay, getMonth } from '../../_utils/Helper';
import { ValueScale } from '@devexpress/dx-react-chart';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IData {
    [key: string]: IAreaData[]
}

interface IAreaData {
    advisor_id: number
    advisor_name: string
    total_gmv: number
    photo: string
}

interface ISelectedArea {
    index: number
    key: string
}

const AdvisorPlacement = () => {
    const { Root, classes } = useStyles()

    const [currentDate, setCurrentDate] = useState<Date>(new Date())

    const targetDate = useMemo(() => {
        return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
    }, [currentDate])

    const timerDate = useMemo(() => {
        const currentTime = currentDate.getTime()
        const targetTime = targetDate.getTime()

        const diff = targetTime - currentTime
        const days = Math.floor(diff / (1000 * 60 * 60 * 24))
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((diff % (1000 * 60)) / 1000)

        return {
            days: days >= 10 ? days.toString() : `0${days}`,
            hours: hours >= 10 ? hours.toString() : `0${hours}`,
            minutes: minutes >= 10 ? minutes.toString() : `0${minutes}`,
            seconds: seconds >= 10 ? seconds.toString() : `0${seconds}`,
        }

    }, [currentDate, targetDate])

    const [currentScreenWidth, setCurrentScreenWidth] = useState(0)
    const [isLoading, setIsLoading] = useState(true)

    const [data, setData] = useState<IData>()
    const availableArea = useMemo(() => {
        return data ? Object.keys(data) : null
    }, [data])
    const [changeAreaFlag, setChangeAreaFlag] = useState(false)
    const [selectedArea, setSelectedArea] = useState<ISelectedArea>()
    const selectedData = useMemo(() => {
        if (data && selectedArea) {
            return data[selectedArea.key]
        }
        return null
    }, [data, selectedArea])

    useEffect(() => {
        fetchData(true)

        const interval = setInterval(() => {
            setCurrentDate(new Date())
        }, 1000)

        const fetchInterval = setInterval(() => {
            fetchData()
        }, 300000) // 300000

        const areaInterval = setInterval(() => {
            setChangeAreaFlag(true)
        }, 20000) // 20000

        window.addEventListener('resize', handleResize)

        return () => {
            clearInterval(interval)
            clearInterval(fetchInterval)
            clearInterval(areaInterval)
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setChangeAreaFlag(true)
    }, [data])

    useEffect(() => {
        if (changeAreaFlag && availableArea) {
            const areasCount = availableArea ? availableArea.length : 0
            if (areasCount > 0) {
                const nextAreaIndex = selectedArea && (selectedArea.index + 1) < areasCount ? selectedArea.index + 1 : 0
                const nextAreaKey = availableArea[nextAreaIndex]

                setSelectedArea({
                    index: nextAreaIndex,
                    key: nextAreaKey,
                })
            }
            setChangeAreaFlag(false)
        }
    }, [changeAreaFlag, availableArea, selectedArea])

    const handleResize = () => {
        setCurrentScreenWidth(document.body.clientWidth)
    }

    useEffect(() => {
        if (selectedData && selectedData.length > 0) {
            setTimeout(() => {
                const container = document.querySelector('#center-axis-container')
                if (container) {
                    const defs = container.querySelector('defs')
                    if (defs && !defs.innerHTML.includes('linearGradient')) defs.innerHTML += `
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stop-color="#EC2F4B" />
                            <stop offset="70%" stop-color="#009FFF" />
                        </linearGradient>
                        <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stop-color="#0374b8" />
                            <stop offset="50%" stop-color="#009FFF" />
                        </linearGradient>
                        <linearGradient id="gradient3" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stop-color="#009FFF" />
                            <stop offset="50%" stop-color="#009FFF" />
                        </linearGradient>
                    `
                }
            }, 100)
        }
    }, [selectedData, currentScreenWidth, selectedArea])

    const fetchData = (withLoading?: boolean) => {
        if (withLoading) setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/advisor-placement`)
            .then(res => res.data)
            .then((data: IData) => {
                if (data) {
                    const clearedData: IData = {}

                    for (const [key, value] of Object.entries(data)) {
                        clearedData[key] = value.map((val) => ({
                            ...val,
                            advisor_name: val.advisor_name.split(' ')[0],
                            total_gmv: Math.floor((Number(val.total_gmv) / 1000000))
                        }))
                    }

                    setData(clearedData)
                }
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                if (withLoading) setIsLoading(false)
            })
    }

    const LabelComponent = useCallback((props: React.PropsWithChildren<ArgumentAxis.LabelProps>) => {
        if (!selectedData) return <></>

        const maxValue = selectedData.reduce((previous, current) => {
            const calculatedGmv = Math.floor(Number(current.total_gmv))
            return previous > calculatedGmv ? previous : calculatedGmv
        }, 0)

        if (maxValue < 1) return <></>

        return (
            <>
                <rect x="0" y="0" width="100%" height="156" fill="#F7F7F7" />
                <rect x="0" y="156" width="100%" height="2" fill="#1D2A49" />
            </>
        )
    }, [selectedData])

    const PointComponent = useCallback((props: React.PropsWithChildren<BarSeries.PointProps>) => {
        if (selectedData) {
            // Current data
            const currentData = selectedData[props.index]

            // Bar measurement
            const width = props.maxBarWidth * props.barWidth
            const height = (props.startVal - props.val) + 156
            const xPos = props.arg - width / 2
            const yPos = props.val

            // Photo measurement
            const imageWidth = 88
            const imageHeight = 88
            const xPosImage = (Number(xPos) - (imageWidth / 2) + (width ? Number(width) / 2 : 0)).toString()
            const yPosImage = (Number(yPos) - (imageHeight / 2)).toString()

            // Image frame measurement
            const imagePlacementWidth = 200
            const imagePlacementHeight = 200
            const imagePlacementXPosition = (Number(xPos) - (imagePlacementWidth / 2) + (width ? Number(width) / 2 : 0)).toString()
            const imagePlacementYPosition = (Number(yPos) - (imagePlacementHeight / 2)).toString()

            // Measure position and size for advisor name
            const textElement = document.createElement('div')
            textElement.className = 'chart-label-name'
            textElement.style.minWidth = `${(Number(width) + 20).toString()}px`
            textElement.style.position = 'absolute'
            textElement.style.visibility = 'hidden'
            textElement.id = 'calculate-element'
            textElement.innerHTML = `
                <span>
                    ${currentData?.advisor_name}
                </span>
            `

            document.body.appendChild(textElement)
            const textElementWidth = textElement.offsetWidth
            const textElementHeight = textElement.offsetHeight
            textElement.parentNode?.removeChild(textElement)

            const extraSpace = currentData.total_gmv >= 45 ? 40 : currentData.total_gmv >= 35 ? 36 : currentData.total_gmv >= 20 ? 16 : 8
            const textXPosition = (Number(xPos) - (textElementWidth / 2) + (width ? Number(width) / 2 : 0)).toString()
            const textYPosition = (Number(yPosImage) + imageHeight + extraSpace).toString()

            // Measure position and size for amount
            const amountElement = document.createElement('div')
            amountElement.className = 'chart-label-amount'
            amountElement.style.position = 'absolute'
            amountElement.style.visibility = 'hidden'
            amountElement.id = 'calculate-element'
            amountElement.innerHTML = `
                <span class="amount">
                    ${currentData.total_gmv}
                </span>
                <span class="mio">
                    Mio
                </span>
            `

            document.body.appendChild(amountElement)
            const amountElementWidth = amountElement.offsetWidth
            const amountElementHeight = amountElement.offsetHeight
            amountElement.parentNode?.removeChild(amountElement)

            const amountXPosition = (Number(xPos) - (amountElementWidth / 2) + (width ? Number(width) / 2 : 0)).toString()
            const amountYPosition = (Number(textYPosition) + textElementHeight).toString()

            return (
                <>
                    <rect
                        x={xPos}
                        y={yPos}
                        width={width}
                        height={height}
                        fill={`url(#${currentData.total_gmv >= 30 ? 'gradient' : currentData.total_gmv >= 20 ? 'gradient2' : 'gradient3'})`}
                        visibility="visible"
                    />
                    <rect
                        x={xPosImage}
                        y={yPosImage}
                        width={imageWidth}
                        height={imageHeight}
                        className="chartImages"
                        fill="#E5E5E5"
                        clipPath="circle(50% at 50% 50%)"
                    />
                    <image
                        x={xPosImage}
                        y={yPosImage}
                        href={currentData.photo}
                        width={imageWidth}
                        height={imageHeight}
                        className="chartImages"
                        clipPath="circle(50% at 50% 50%)"
                    />
                    {
                        currentData.total_gmv >= 25 &&
                        <image
                            x={imagePlacementXPosition}
                            y={imagePlacementYPosition}
                            href={currentData.total_gmv >= 45 ? Placement1 : currentData.total_gmv >= 35 ? Placement2 : currentData.total_gmv >= 25 ? Placement3 : ''}
                            width={imagePlacementWidth}
                            height={imagePlacementHeight}
                            className="chartImages"
                            clipPath="circle(50% at 50% 50%)"
                        />
                    }
                    <foreignObject
                        x={textXPosition}
                        y={textYPosition}
                        className="chartImages"
                        width={textElementWidth}
                        height={textElementHeight}
                    >
                        <div
                            className="chart-label-name"
                            style={{
                                minWidth: Number(width) + 20
                            }}
                        >
                            <span>
                                {currentData.advisor_name}
                            </span>
                        </div>

                    </foreignObject>
                    <foreignObject
                        x={amountXPosition}
                        y={amountYPosition}
                        className="chartImages"
                        width={amountElementWidth}
                        height={amountElementHeight}
                    >
                        <div
                            className="chart-label-amount"
                        >
                            <span className="amount">
                                {currentData.total_gmv}
                            </span>
                            <span className="mio">
                                Mio
                            </span>
                        </div>

                    </foreignObject>
                </>
            )
        } else {
            return (
                <>
                </>
            )
        }
    }, [selectedData])

    const modifyDomain = useCallback(() => {
        if (selectedData) {
            const maxValue = selectedData.reduce((prev, curr) => prev > curr.total_gmv ? prev : curr.total_gmv, 0)
            return [0, maxValue > 65 ? maxValue : 65]
        } else {
            return [0, 65]
        }
    }, [selectedData])

    const tickFormat = useCallback(() => (tick: string) => {
        if (selectedData) {
            const maxValue = selectedData.reduce((prev, curr) => prev > curr.total_gmv ? prev : curr.total_gmv, 0)
            return maxValue > 65 ? tick : [0, 25, 35, 45, 55].includes(Number(tick)) ? tick : ''
        } else {
            return tick
        }
    }, [selectedData])

    return (
        <Root>
            <LoadingScreen
                open={isLoading}
                fullScreen
            />
            <div className={classes.root}>
                <div className="left">
                    <img src={JendelaLogo} alt="Jendela360 Logo" />
                    <div className="time-title">
                        <img src={SandClockSVG} alt="time" width="25" />
                        <span>
                            Time Remaining
                        </span>
                    </div>
                    <div className="days-timer">
                        <span className="month-name">
                            {getMonth(new Date(new Date().setMonth(new Date().getMonth() + 1)).getMonth())}
                        </span>
                        <span className="days-counter">
                            {timerDate.days}
                        </span>
                        <span className="days-text">
                            Days Left
                        </span>
                    </div>
                    <div className="time-timer">
                        <div className="timer-container">
                            <span className="time">
                                {timerDate.hours}
                            </span>
                            <span className="text">
                                Hours
                            </span>
                        </div>
                        <div className="separator">
                            :
                        </div>
                        <div className="timer-container">
                            <span className="time">
                                {timerDate.minutes}
                            </span>
                            <span className="text">
                                Mins
                            </span>
                        </div>
                        <div className="separator">
                            :
                        </div>
                        <div className="timer-container">
                            <span className="time">
                                {timerDate.seconds}
                            </span>
                            <span className="text">
                                Secs
                            </span>
                        </div>
                    </div>
                    <div className="quote">
                        <img src={QuoteSVG} alt="quote" />
                        <span className="quote-text">
                            Talent willl take you to the top, but only character will keep you there.
                        </span>
                    </div>
                </div>
                <div className="right">
                    <div className="header">
                        <img src={ShieldSVG} alt="team" width="44" height="51" />
                        <span className="team">
                            {
                                selectedArea ?
                                    `${selectedArea.key} Team`
                                    :
                                    'Loading Team...'
                            }
                        </span>
                        <div className="right-header">
                            <img src={CalendarSVG} alt="team" width="39" height="39" />
                            <span className="date">{getDay(currentDate.getDay(), true)}, {currentDate.getDate() >= 10 ? currentDate.getDate() : `0${currentDate.getDate()}`} {getMonth(currentDate.getMonth())}</span>
                        </div>
                    </div>
                    {
                        selectedData &&
                        <Chart
                            data={selectedData}
                            rootComponent={RootComponent}
                        >
                            <ValueScale
                                name="value"
                                modifyDomain={modifyDomain}
                            />

                            <ArgumentAxis
                                showTicks={false}
                                labelComponent={LabelComponent}
                            />

                            <ValueAxis
                                scaleName="value"
                                tickFormat={tickFormat}
                                gridComponent={GridComponent}
                            />

                            <BarSeries
                                valueField="total_gmv"
                                argumentField="advisor_name"
                                barWidth={0.07148 * selectedData.length}
                                pointComponent={PointComponent}
                                scaleName="value"
                            />
                        </Chart>
                    }
                </div>
            </div>
        </Root>
    )
}

const RootComponent = ({ children }: Chart.RootProps) => {
    return (
        <div
            style={{
                height: '75vh',
                display: 'flex',
                padding: '10px',
                fontSize: '14px',
                boxSizing: 'border-box',
                fontWeight: 300,
                flexDirection: 'column',
            }}
        >
            {children}
        </div>
    )
}

const GridComponent = (props: ValueAxis.LineProps) => {
    return <ValueAxis.Grid {...props} strokeDasharray="15 10" />
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        minHeight: '100vh',
        '& .Component-root-20': {
            fill: 'rgba(0, 0, 0, 0.39)'
        },
        '& > .left': {
            width: 471,
            padding: '40px 30px',
            borderRight: '1px solid #D1D1D1',
            display: 'flex',
            flexDirection: 'column',
            '& > img': {
                width: '100%',
            },
            '& > .time-title': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 64,
                marginBottom: 54,
                '& > *:first-of-type': {
                    marginRight: 24,
                },
                '& > span': {
                    fontSize: 36,
                    lineHeight: '43px',
                }
            },
            '& > .days-timer': {
                backgroundColor: 'rgba(247, 247, 247, 0.8)',
                borderRadius: 20,
                padding: 24,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 16,
                '& > .month-name': {
                    textAlign: 'center',
                    fontWeight: '400',
                    letterSpacing: '2.16px',
                    color: 'rgba(72, 72, 72, 0.29)',
                    fontSize: 28,
                    lineHeight: '34px',
                    textTransform: 'uppercase',
                },
                '& > .days-counter': {
                    textAlign: 'center',
                    fontWeight: '400',
                    color: 'rgba(72, 72, 72, 1)',
                    fontSize: 170,
                    lineHeight: '155px',
                },
                '& > .days-text': {
                    textAlign: 'center',
                    fontWeight: '300',
                    letterSpacing: '1.24px',
                    color: 'rgba(72, 72, 72, 0.55)',
                    fontSize: 40,
                    lineHeight: '50px',
                },
            },
            '& > .time-timer': {
                padding: '13px 12px 20px',
                backgroundColor: 'rgba(247, 247, 247, 0.8)',
                borderRadius: 20,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'center',
                marginBottom: 'auto',
                '& > .timer-container': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& > .time': {
                        textAlign: 'center',
                        fontWeight: '400',
                        fontSize: 49,
                        lineHeight: '59px',
                        color: 'rgba(72, 72, 72, 1)',
                    },
                    '& > .text': {
                        textAlign: 'center',
                        fontWeight: '200',
                        fontSize: 20,
                        lineHeight: '22px',
                        color: 'rgba(0, 0, 0, 0.55)',
                    },
                },
                '& > .separator': {
                    fontWeight: '400',
                    fontSize: 49,
                    lineHeight: '59px',
                    color: 'rgba(72, 72, 72, 1)',
                    margin: '0px 6px'
                }
            },
            '& > .quote': {
                marginTop: 12,
                '& > .quote-text': {
                    marginTop: 13,
                    fontSize: 25,
                    lineHeight: '30px',
                    color: 'rgba(104, 104, 104, 1)',
                    display: 'block',
                },
            },
        },
        '& > .right': {
            padding: '40px 44px 44px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            '& > .header': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: 66,
                marginBottom: 'auto',
                marginTop: 24,
                maxHeight: '25vh',
                '& > .team': {
                    fontSize: 45,
                    lineHeight: '54px',
                    fontWeight: '500',
                    marginLeft: 25,
                },
                '& > .right-header': {
                    marginLeft: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    '& > .date': {
                        fontSize: 45,
                        lineHeight: '54px',
                        fontWeight: '200',
                        marginLeft: 24,
                    },
                },
            },
        },
    },
    chartContainer: {
        marginTop: 32,
    },
    '@global': {
        '.chart-label-name': {
            backgroundColor: '#1D2A49',
            padding: '4px 8px 3px',
            borderRadius: 3,
            boxShadow: '3px 3px 7px #0000002B',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: 24,
            lineHeight: '29px',
            fontWeight: '400',
            '& > span': {
                // overflow: 'hidden',
                whiteSpace: 'nowrap',
                // textOverflow: 'ellipsis',
                display: 'block',
                textAlign: 'center',
            }
        },
        '.chart-label-amount': {
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& > .amount': {
                fontSize: 34,
                lineHeight: '41px',
                letterSpacing: '-1.02px',
                fontWeight: '500',
            },
            '& > .mio': {
                fontSize: 21,
                lineHeight: '25px',
                letterSpacing: '-0.63px',
            }
        },
        '.Component-root-20': {
            fontSize: 25,
        }
    }
}), "Advisor Placement"
)

export default AdvisorPlacement

import React from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import moment from 'moment';
import { convertNumber } from '../../../_utils/Helper';

// Components

// Icons
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { format } from 'date-fns';
import { LandlordDetailUnit } from '../LandlordModalDetail';

interface Props {
    landlordId: string;
    units: LandlordDetailUnit[]
}

const getPrice = (price: number) => {
    if (!price) {
        return "-";
    } else {
        let currency = "Rp."
        if (price < 99000) {
            currency = "$"
        }

        return `${currency} ${convertNumber(price.toString())}`;
    }
}

const LandlordUnitTable = ({ units }: Props) => {
    return (
        <>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Apartment</TableCell>
                            <TableCell>Detail</TableCell>
                            <TableCell>PIC Listing</TableCell>
                            <TableCell>Available</TableCell>
                            <TableCell>Available At</TableCell>
                            <TableCell>Unit Type</TableCell>
                            <TableCell>Listing Price</TableCell>
                            <TableCell>Base Price</TableCell>
                            <TableCell>Last Update On</TableCell>
                            {/* <TableCell>Files</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            units.length
                                ? units.map(unit => (
                                    <TableRow key={unit.id}>
                                        <TableCell>
                                            <a href={`/unit/${unit.code}`} target="_blank" rel="noopener noreferrer">
                                                {unit.code}
                                            </a>
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                onClick={() => { window.open(`${process.env.REACT_APP_MAIN_URL}/${unit.code}`) }}
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            {unit.apartment_name}
                                        </TableCell>
                                        <TableCell>
                                            Tw. {unit.tower} | Lt. {unit.floor_level} | No. {unit.unit_number}
                                        </TableCell>
                                        <TableCell>
                                            {unit.pic_name || '-'}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                color={!!unit.is_available ? 'primary' : 'secondary'}
                                            >
                                                {
                                                    !!unit.is_available
                                                        ? <CheckIcon />
                                                        : <CloseIcon />
                                                }
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            {unit.rent_date ? format(new Date(unit.rent_date), 'dd-MM-yyyy') : "-"}
                                        </TableCell>
                                        <TableCell>
                                            <p>{unit.category === 1 ? 'Star Listing' : unit.category === 2 ? 'Verified' : unit.category === 0 ? 'Reguler' : '-'}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{getPrice(unit.price_per_12_months)}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{getPrice(unit.basic_price_12)}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{moment(unit.updated_at).format("DD MMMM YYYY")}</p>
                                        </TableCell>
                                        {/* <TableCell>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => window.open(`https://jendela360.com/admin-cms/unit/files/${unit.id}`)}
                                            >
                                                Files
                                        </Button>
                                        </TableCell> */}
                                    </TableRow>
                                ))
                                : <TableRow>
                                    <TableCell colSpan={3}>No Units Available</TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default LandlordUnitTable;
import { Button, Dialog, DialogContent, DialogTitle, FormHelperText, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

interface IProps {
    open: boolean
    onClose: () => void
    onSubmit: (state: IState) => void
}

interface IState {
    comment: string,
    attachments: File[] | null
}

const initialState = {
    comment: '',
    attachments: null
}

const initialErrorState = {
    comment: '',
    attachments: ''
}

const CompleteModal = (props: IProps) => {
    const [state, setState] = useState<IState>(initialState)
    const [errorState, setErrorState] = useState(initialErrorState)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value, files } = e.target

        if (name === 'attachments') {
            if (value) {
                return setState(prev => ({
                    ...prev,
                    [name]: files ? Array.from(files) : null
                }))
            } else {
                return setState(prev => ({
                    ...prev,
                    [name]: null
                }))
            }
        }

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const validate = (state: IState) => {
        let errState = { ...initialErrorState }
        let valid = true
        if (!state.comment) {
            errState.comment = "Please fill this comment"
            valid = false
        }

        if (state.attachments === null) {
            errState.attachments = 'Please add attachment at least 1 file'
            valid = false
        }

        setErrorState(errState)
        return valid
    }

    const handleSubmit = () => {
        let valid = validate(state)
        if (valid) {
            props.onSubmit(state)
        }
    }

    useEffect(() => {
        if (Object.values(errorState).some((value) => value !== '')) {
            validate(state)
        }

        // eslint-disable-next-line
    }, [state])

    useEffect(() => {
        return () => {
            setState(initialState)
            setErrorState(initialErrorState)
        }
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Complete Ticket</DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            name='comment'
                            onChange={handleChange}
                            label="Comment"
                            value={state.comment}
                            error={!!errorState.comment}
                            helperText={errorState.comment}
                            placeholder='Place comment here'
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                            rows={3}
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1' style={{ margin: '10px 0px', fontWeight: '500' }}>Upload Attachments</Typography>
                        <input
                            type="file"
                            multiple
                            name='attachments'
                            onChange={handleChange}
                        />
                        {
                            errorState.attachments ?
                                <FormHelperText error>{errorState.attachments}</FormHelperText> :
                                null
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ float: 'right' }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default CompleteModal
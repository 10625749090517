import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { generateStyle } from '../../../_utils/DefaultStyle'

type Props = {
    icon: React.ReactNode
    title: string
    value: string | number
    details: {
        label: React.ReactNode | string,
        value: string | number
    }[]
}

const useStyles = generateStyle((theme) => ({
    detailContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& p': {
            fontSize: 14
        }
    },
    splitter: {
        borderBottom: '2px solid #ddd'
    },
    value: {
        fontSize: 24,
        fontWeight: '700'
    }
}), "GeneralPerformance_Card")

const GeneralPerformanceCard = (props: Props) => {
    const { Root, classes } = useStyles()
    return (
        <Paper variant='elevation' elevation={2} sx={{ p: 2 }} component={Root}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {props.icon}
                </Grid>
                <Grid item xs={12}>
                    <Typography>{props.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.value}>{props.value}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.splitter}></div>
                </Grid>
                {
                    props.details.map((detail) => (
                        <Grid item xs={12} className={classes.detailContainer}>
                            <Typography>{detail.label}</Typography>
                            <Typography sx={{ ml: 'auto' }}><strong>{detail.value}</strong></Typography>
                        </Grid>
                    ))
                }
            </Grid>
        </Paper>
    )
}

export default GeneralPerformanceCard
import React, { useState, useEffect, useContext } from 'react';
import {
    Theme,
    Link,
    Dialog,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Switch,
    TextField,
} from '@mui/material';
import { Link as RouterLink, useParams } from "react-router-dom";

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import UserModal from '../_user/UserModal';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
*/
import { TransitionProps } from '@mui/material/transitions';
import { PermissionContext } from '../../_contexts/PermissionContext';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';

/**
 * Icons
 */
import { Close } from '@mui/icons-material';
import { generateStyle } from '../../_utils/DefaultStyle';


const useStyles = generateStyle((theme: Theme) => ({
    addButton: {
        marginBottom: '20px'
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "AgentList_Two"
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AgentListTwo = () => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const DT_API_URL = process.env.REACT_APP_API_URL + '/user/agent-list';
    const [open, setOpen] = useState<boolean>(false)
    const { agent_id } = useParams<any>();
    const [agentId, setAgentId] = useState("");
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (typeof agent_id !== "undefined") {
            setAgentId(agent_id);
            setOpen(true);
        }
    }, [agent_id]);

    const handleClose = () => {
        window.history.pushState([], 'Agent List', `/agent-list`);
        setReDrawDatatable(new Date().getTime())
        setOpen(false)
    }

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <Close />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    User Details
                </Typography>
            </Toolbar>
        )
    }

    const toggleActive = (id: string, setRows: Function, rows: any[], oldValue: boolean) => {
        renderWarningButton("Apakah anda yakin ingin mengubah status active agent ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)
                    DefaultAxios
                        .put(`${process.env.REACT_APP_API_URL}/user/switch-active`, { id, is_active: !oldValue ? 1 : 0 })
                        .then(res => {
                            const newRows = rows.map((element: any) => {
                                if (element.id === id) element['is_active'] = !oldValue ? 1 : 0
                                return element
                            })
                            setRows(newRows);
                            renderToastSuccess('Status active berhasil dirubah')
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    const togglePaused = (id: string, setRows: Function, rows: any[], oldValue: boolean) => {
        renderWarningButton("Apakah anda yakin ingin mengubah status paused agent ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)
                    DefaultAxios
                        .put(`${process.env.REACT_APP_API_URL}/user/pause`, { id })
                        .then(res => {
                            const newRows = rows.map((element: any) => {
                                if (element.id === id) element['is_paused'] = !oldValue ? 1 : 0
                                return element
                            })
                            setRows(newRows);
                            renderToastSuccess('Status paused berhasil dirubah')
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    const onPunishmentChange = (id: string, setRows: Function, rows: any[], value: string) => {
        renderWarningButton("Apakah anda yakin ingin mengubah punishment level agent ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/user/change-punishment-level`, {
                        id,
                        punishment_level: value,
                    })
                        .then(res => res.data)
                        .then(data => {
                            const newRows = rows.map((element: any) => {
                                if (element.id === id) element['punishment_level'] = value
                                return element
                            })
                            setRows(newRows);
                            renderToastSuccess('Punishment level berhasil dirubah')
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            {open &&
                <Dialog
                    fullScreen
                    open={open}
                    closeAfterTransition
                    TransitionComponent={Transition}
                >
                    <Root>
                        <AppBar className={classes.appBar}>
                            <FormToolbar></FormToolbar>
                        </AppBar>
                        <UserModal user_id={agentId} is_edit={true} permissions={permissions} successUrl='/agent-list' />
                    </Root>
                </Dialog>
            }

            <DataTable
                reDraw={reDrawDatatable}
                url={DT_API_URL}
                columns={[
                    {
                        name: 'name', label: 'Name', filterable: true, render: (data: any, row: any) => {
                            return (
                                <Link
                                    style={{ cursor: "pointer" }}
                                    component={RouterLink}
                                    to={`/agent-list/${row.id}`}
                                >
                                    {data}
                                </Link>
                            );
                        }
                    },
                    { name: 'email', label: 'Email', filterable: true },
                    { name: 'phone_number', label: 'Phone', filterable: true },
                    {
                        name: 'punishment_level',
                        label: 'Punishment Level',
                        type: 'string',
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            ...PunishmentLevels.map(punishment => ({
                                key: punishment.value,
                                value: punishment.label,
                            }))
                        ],
                        filterable: true,
                        columnStyle: {
                            minWidth: 250,
                        },
                        render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => (
                            permissions['user.change-punishment-level'] ?
                                <TextField
                                    name="punishment_level"
                                    select
                                    value={data.toString()}
                                    fullWidth
                                    onChange={e => onPunishmentChange(row.id, setRows, rows, e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    size="small"
                                    variant="outlined"
                                >
                                    {
                                        PunishmentLevels.map(punishment =>
                                            <option key={punishment.value} value={punishment.value}>{punishment.label}</option>
                                        )
                                    }
                                </TextField>
                                :
                                PunishmentLevels.filter(punishment => punishment.value === data.toString())[0]?.label || '-'
                        )
                    },
                    {
                        name: 'is_paused',
                        label: 'Paused',
                        filterable: true,
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '1',
                                value: 'Paused'
                            },
                            {
                                key: '0',
                                value: 'Not Paused'
                            }
                        ],
                        render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => (
                            permissions['user.pause'] ?
                                <Switch
                                    checked={+data === 1}
                                    onChange={() => togglePaused(row.id, setRows, rows, +data === 1)}
                                    color="secondary"
                                />
                                :
                                +data === 1 ? 'Paused' : 'Not Paused'
                        )
                    },
                    {
                        name: 'is_active',
                        label: 'Active',
                        filterable: true,
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '1',
                                value: 'Active'
                            },
                            {
                                key: '0',
                                value: 'In-Active'
                            }
                        ],
                        render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => (
                            permissions['user.active'] ?
                                <Switch
                                    checked={+data === 1}
                                    onChange={() => toggleActive(row.id, setRows, rows, +data === 1)}
                                    color="secondary"
                                />
                                :
                                +data === 1 ? 'Active' : 'In-Active'
                        )
                    }
                ]}
            />
        </Root>
    );
}

const PunishmentLevels = [
    {
        value: '0',
        label: 'None'
    },
    {
        value: '1',
        label: 'Level 1'
    },
    {
        value: '2',
        label: 'Level 2'
    },
    {
        value: '3',
        label: 'Level 3'
    },
]

export default AgentListTwo;
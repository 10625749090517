import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Button, Dialog, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from '@mui/material'
import LoadingScreen from '../../_components/LoadingScreen'
import TransactionDetail from '../_verifyTransaction/TransactionDetail'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { dateFormat, generalErrorHandler, numberToCurrency } from '../../_utils/Helper'
import Swal from 'sweetalert2'
import { generateStyle } from '../../_utils/DefaultStyle'

interface ClosingTransactionExportDetailProps {
    permissions: any
    isOpen: boolean
    onClose: () => void
    id: string
    code: string
}

interface IClosingTransactionExportDetailData {
    id: string
    created_at: string
    code: string
    amount: number
    transaction_type: string
    proposed_by: string
    approved_by: string
    processed_by: string
    behalf_name: string
    bank_name: string
    bank_number: string
}

const ClosingTransactionExportDetail = (props: ClosingTransactionExportDetailProps) => {
    const { Root, classes } = useStyles()

    const [data, setData] = useState<IClosingTransactionExportDetailData[]>([])
    const [selectedTransaction, setSelectedTransaction] = useState<number | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!props.id) return
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/closing-transaction-export/${props.id}`)
            .then(res => res.data)
            .then(data => {
                setData(data)
                console.log(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }, [props.id])

    function selectElementContents(el: HTMLTableElement) {
        var body = document.body as any, range, sel;
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            if (sel) {
                sel.removeAllRanges();
                try {
                    range.selectNodeContents(el);
                    sel.addRange(range);
                } catch (e) {
                    range.selectNode(el);
                    sel.addRange(range);
                }
                document.execCommand("copy");
                sel.removeAllRanges();

                Swal.fire({
                    title: "Table copied to clipboard",
                    icon: 'success',
                    timer: 1000
                })
            }
        } else if (body.createTextRange) {
            range = body.createTextRange();
            range.moveToElementText(el);
            range.select();
            range.execCommand("Copy");
            range.deselect()

            Swal.fire({
                title: "Table copied to clipboard",
                icon: 'success',
                timer: 1000
            })
        }
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <TransactionDetail
                permissions={props.permissions}
                isOpen={!!selectedTransaction}
                onClose={() => {
                    setSelectedTransaction(null)
                }}
                id={selectedTransaction}
            />
            <Dialog
                open={props.isOpen}
                onClose={props.onClose}
                fullWidth
                maxWidth="lg"
            >
                <Root>
                    <DialogTitle>
                        <div className={classes.header}>
                            <span>
                                Export - {props.code}
                            </span>
                            <span style={{ fontSize: 16 }}>
                                Total Transaksi: {numberToCurrency(data.reduce((prev, next) => prev + next.amount, 0), 'Rp')}
                            </span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <TableContainer component={Paper}>
                            <Table id="copyTable" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Tanggal</TableCell>
                                        <TableCell>Transaksi</TableCell>
                                        <TableCell>Nilai Transaksi</TableCell>
                                        <TableCell>Nomor Rekening Tujuan</TableCell>
                                        <TableCell>Nama Rekening Tujuan</TableCell>
                                        <TableCell>Diajukan Oleh</TableCell>
                                        <TableCell>Disetujui Oleh</TableCell>
                                        <TableCell>Diproses Oleh</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.map(item =>
                                            <TableRow key={item.id}>
                                                <TableCell>{dateFormat(item.created_at, 'DD MMM YYYY')}</TableCell>
                                                <TableCell>
                                                    <span className={classes.code} onClick={() => setSelectedTransaction(Number(item.id))}>
                                                        {item.code}
                                                    </span>
                                                </TableCell>
                                                <TableCell>{numberToCurrency(item.amount, 'Rp')}</TableCell>
                                                <TableCell>{`[${item.bank_name}] ${item.bank_number}`}</TableCell>
                                                <TableCell>{item.behalf_name}</TableCell>
                                                <TableCell>{item.proposed_by}</TableCell>
                                                <TableCell>{item.approved_by}</TableCell>
                                                <TableCell>{item.processed_by}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.buttonContainer}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    const tableElement = document.getElementById('copyTable')
                                    if (tableElement) selectElementContents(tableElement as HTMLTableElement)
                                }}
                            >
                                Copy Summary
                            </Button>
                        </div>
                    </DialogContent>
                </Root>
            </Dialog>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginTop: 24,
        marginBottom: 8,
    },
    code: {
        color: theme.palette.primary.main,
        transition: 'color 200ms linear',
        cursor: 'pointer',

        '&:hover': {
            color: theme.palette.primary.dark,
        }
    },
}), "ClosingTransactionExport_Detail")

export default ClosingTransactionExportDetail

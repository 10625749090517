import React, { useContext, useState } from 'react'

/**
 * Components
 */
import CardND from '../../../_components/_newDesignComponents/_card/CardND'
import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material'
import { AlarmOutlined, DeleteOutline, EventRepeat, MoreVert, WhatsApp, StickyNote2Outlined, FormatListBulleted, Check } from '@mui/icons-material'
import MenuND from '../../../_components/_newDesignComponents/_menu/MenuND'
import MenuItemND from '../../../_components/_newDesignComponents/_menu/MenuItemND'
import JHSScheduleDeleteModal from './JHSScheduleDeleteModal'
import JHSScheduleRescheduleModal, { JHSScheduleRescheduleData } from './JHSScheduleRescheduleModal'
import JHSScheduleJobsSchedule from './JHSScheduleJobsSchedule'
import DoneDetailListModal from './DoneDetailListModal'

/**
 * Utils
 */
import { format } from 'date-fns'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { convertIndonesiaPhoneNumber, generalErrorHandler, renderSuccessButton } from '../../../_utils/Helper'
import { PermissionContext } from '../../../_contexts/PermissionContext'
import { generateStyle } from '../../../_utils/DefaultStyle'


type IRoleSlug = 'jhs-fixing' | 'jhs-cleaning' | 'jhs-photo' | 'jhs-ac' | 'jhs-fixing-coordinator' | 'jhs-cleaning-coordinator' | 'jhs-ac-coordinator'

export type JHSScheduleCardItem = {
    id: string
    photo?: string
    name: string
    schedules: JHSScheduleSchedule[]
    phone_number?: string
    role_slug: IRoleSlug
}

export type JHSScheduleSchedule = {
    id: string
    activity_id: number
    apartment_name?: string
    unit_code?: string
    work_code: string
    requester_name?: string
    date?: string
    time?: string
    meeting_type?: 0 | 1 | 2
    pic_id?: number
    pic_name?: string
    done_at?: string | null
    division?: string
}

interface JHSScheduleCardProps {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    setRefetch: React.Dispatch<React.SetStateAction<number>>
    fullHeight?: boolean
}

const JHSScheduleCard = (props: JHSScheduleCardItem & JHSScheduleCardProps) => {
    const { Root, classes } = useStyles()
    const permissions = useContext(PermissionContext)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const [selectedData, setSelectedData] = useState<null | JHSScheduleSchedule>(null)
    const [modalState, setModalState] = useState<false | 'reschedule' | 'delete' | 'jobs' | 'done-detail'>(false)

    const openMenu = (event: React.MouseEvent<HTMLElement>, sch: JHSScheduleSchedule) => {
        setSelectedData(sch)
        setAnchorEl(event.currentTarget)
    }

    const seeJobs = (sch: JHSScheduleSchedule) => {
        setSelectedData(sch)
        setModalState('jobs')
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)

        // Wait for the animation to finish
        setTimeout(() => {
            setSelectedData(null)
        }, 200)
    }

    const handleOpenReschedule = () => {
        setAnchorEl(null)
        setModalState('reschedule')
    }

    const handleSeeJHSOrder = (id: number) => {
        setAnchorEl(null)
        window.open(`/jhs-order/${id}`)
    }

    const handleOpenDelete = () => {
        setAnchorEl(null)
        setModalState('delete')
    }

    const handleConfirmDelete = (sch: JHSScheduleSchedule) => {
        setAnchorEl(null)
        setSelectedData(null)
        setModalState(false)

        props.setIsLoading(true)
        DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/activity-schedule/${sch.id}`)
            .then(res => res.data)
            .then(data => {
                renderSuccessButton('Berhasil menghapus Schedule', () => {
                    props.setRefetch(prev => prev + 1)
                })
            })
            .catch(generalErrorHandler)
            .finally(() => {
                props.setIsLoading(false)
            })
    }

    const handleConfirmReschedule = (rescheduleData: JHSScheduleRescheduleData, scheduleData: JHSScheduleSchedule) => {
        setSelectedData(null)
        setModalState(false)

        props.setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity-schedule/change-datetime`, {
            id: scheduleData.activity_id,
            datetime: `${format(rescheduleData.date!, 'yyyy-MM-dd')} ${format(rescheduleData.time!, 'HH:mm')}`,
            pic_id: rescheduleData.pic_id,
        })
            .then(res => res.data)
            .then(data => {
                renderSuccessButton('Berhasil reschedule', () => {
                    props.setRefetch(prev => prev + 1)
                })
            })
            .catch(generalErrorHandler)
            .finally(() => {
                props.setIsLoading(false)
            })
    }

    const handleSeeDoneDetail = (sch: JHSScheduleSchedule) => {
        setAnchorEl(null)
        setSelectedData(sch)
        setModalState('done-detail')
    }

    return (
        <>
            <JHSScheduleDeleteModal
                isOpen={selectedData !== null && modalState === 'delete'}
                onClose={() => {
                    setSelectedData(null)
                    setModalState(false)
                }}
                data={selectedData}
                confirmDelete={handleConfirmDelete}
            />
            <DoneDetailListModal
                isOpen={selectedData !== null && modalState === 'done-detail'}
                onClose={() => {
                    setSelectedData(null)
                    setModalState(false)
                }}
                data={selectedData}
            />
            <Root>
                <JHSScheduleRescheduleModal
                    isOpen={selectedData !== null && modalState === 'reschedule'}
                    onClose={() => {
                        setSelectedData(null)
                        setModalState(false)
                    }}
                    data={selectedData}
                    confirmReschedule={handleConfirmReschedule}
                />
                <MenuND
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {
                        selectedData ?
                            <>
                                <MenuItemND
                                    onClick={() => handleOpenReschedule()}
                                >
                                    <EventRepeat fontSize='small' htmlColor='#141414' sx={{ mr: 1.25 }} />
                                    Reschedule
                                </MenuItemND>
                                <MenuItemND
                                    onClick={() => handleSeeJHSOrder(selectedData.activity_id)}
                                >
                                    <StickyNote2Outlined fontSize='small' htmlColor='#141414' sx={{ mr: 1.25 }} />
                                    See JHS Order
                                </MenuItemND>
                                {
                                    selectedData.done_at ?
                                        <MenuItemND
                                            onClick={() => handleSeeDoneDetail(selectedData)}
                                        >
                                            <Check fontSize='small' htmlColor='#141414' sx={{ mr: 1.25 }} />
                                            Done Detail
                                        </MenuItemND>
                                        : null
                                }
                                {
                                    permissions['order-jhs.admin'] ?
                                        <MenuItemND
                                            onClick={() => handleOpenDelete()}
                                        >
                                            <DeleteOutline fontSize='small' htmlColor='#141414' sx={{ mr: 1.25 }} />
                                            Delete
                                        </MenuItemND>
                                        : null
                                }
                            </>
                            : null
                    }
                </MenuND>
                <CardND
                    className={classes.card}
                    style={props.fullHeight ? { minHeight: 'calc((100vh - 64px - 62px - 20px - 3vh))' } : {}}
                >
                    <Box className='header'>
                        <Avatar className='avatar' alt={props.name} src={props.photo} />
                        <Box className="profile">
                            <Typography variant='subtitle2' fontWeight={500}>{props.name}</Typography>
                            <Typography variant='caption'>{roleSlugData.filter(item => item.name === props.role_slug)[0]?.label || ''}</Typography>
                        </Box>
                        {
                            props.phone_number ?
                                <IconButton
                                    color="primary"
                                    href={`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(props.phone_number)}`}
                                    target="_blank"
                                    rel="noopener norefferer"
                                    sx={{ marginLeft: 'auto' }}
                                    size='small'
                                >
                                    <WhatsApp fontSize='small' />
                                </IconButton>
                                : null
                        }
                    </Box>
                    <Box
                        className='list'
                        style={props.fullHeight ? { maxHeight: 'calc(((100vh - 64px - 62px - 40px - 3vh)) - 66px)' } : {}}
                    >
                        {
                            props.schedules.length ?
                                props.schedules.map((sch, index) =>
                                    <CardND
                                        key={sch.id}
                                        elevation={0}
                                        className='schedule-card'
                                        sx={{ backgroundColor: sch.done_at ? '#00e676' : '#F7F8FA' }}
                                    >
                                        <Grid container>
                                            <Grid item xs={9} display='flex' flexDirection='column' alignItems='flex-start'>
                                                <Typography variant='caption' fontWeight={600} sx={{ mb: 0.75 }} lineHeight={1.2}>
                                                    {sch.apartment_name || '-'}
                                                </Typography>
                                                <Typography variant='caption' color='#484848' lineHeight={1.2}>
                                                    {sch.unit_code ? sch.unit_code + ((sch.work_code || sch.meeting_type) ? ' - ' : '') : ''}{sch.work_code || ''}{(sch.unit_code || sch.work_code) && (sch.meeting_type) ? <span style={{ fontWeight: 500 }}>{' - Ketemu ' + (sch.meeting_type === 1 ? 'Owner' : 'Tenant')}</span> : ''}
                                                </Typography>
                                                {
                                                    sch.requester_name ?
                                                        <Typography variant='caption' sx={{ mt: 0.75 }} color='#484848' lineHeight={1.2}>
                                                            Ordered by: {sch.requester_name}
                                                        </Typography>
                                                        : null
                                                }
                                            </Grid>
                                            <Grid item xs={3} display='flex' flexDirection='column' alignItems='flex-end'>
                                                <Grid container direction={'row'}>
                                                    <Grid item xs={12} display='flex' flexDirection='row' justifyContent='flex-end'>
                                                        <IconButton
                                                            size='small'
                                                            onClick={() => seeJobs(sch)}
                                                            sx={{ fontSize: 18 }}
                                                            color='primary'
                                                        >
                                                            <FormatListBulleted fontSize="inherit" />
                                                        </IconButton>
                                                        <IconButton
                                                            size='small'
                                                            onClick={e => openMenu(e, sch)}
                                                            sx={{ fontSize: 18 }}
                                                        >
                                                            <MoreVert fontSize='inherit' />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                <Box sx={{ pr: 1.25, mt: 'auto' }} display='flex' alignItems='center'>
                                                    <AlarmOutlined sx={{ fontSize: 14 }} htmlColor={sch.time ? '#0080FE' : '#484848'} />
                                                    <Typography variant='caption' color='#484848' lineHeight={1.2} sx={{ pl: 0.6 }}>
                                                        {sch.time ? format(new Date(`${sch.date || format(new Date(), 'yyy-MM-dd')} ${sch.time}`), 'HH:mm') : '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardND>
                                )
                                :
                                <Typography variant='subtitle2' fontWeight={400} textAlign='center' sx={{ mt: 1 }}>No scheduled activity</Typography>
                        }
                    </Box>
                </CardND>
            </Root>

            <JHSScheduleJobsSchedule
                open={modalState === 'jobs'}
                onClose={() => setModalState(false)}
                scheduleId={selectedData ? +selectedData.id : 0}
            />
        </>
    )
}

const useStyles = generateStyle(() => ({
    card: {
        width: 400,
        minHeight: 'calc((100vh - 64px - 62px - 40px - 3vh) / 2)',
        overflow: 'hidden',
        marginBottom: 20,
        marginLeft: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        '& > .header': {
            display: 'flex',
            alignItems: 'center',
            paddingBottom: 10,
            '& > .profile': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start'
            },
            '& > .avatar': {
                width: 32,
                height: 32,
                borderRadius: 23,
                marginRight: 15,
            },
        },
        '& > .list': {
            maxHeight: 'calc(((100vh - 64px - 62px - 40px - 3vh) / 2) - 66px)',
            overflowY: 'auto',
            '& > .schedule-card': {
                padding: 10,
                paddingRight: 5,
                marginBottom: 10,
                borderRadius: 8,
                '& > .header': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                },
                '& .type-box': {
                    height: 20,
                    color: 'white',
                    fontSize: 12,
                    lineHeight: 1.2,
                    paddingLeft: 5.5,
                    paddingRight: 5.5,
                    borderRadius: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 10,
                },
            },
        },
        '&:nth-of-type(1), &:nth-of-type(2)': {
            marginLeft: 20,
        },
        '&:last-child, &:nth-last-of-type(1)': {
            marginRight: 20,
        },
    },
}), "JHSSchedule_Card")

const roleSlugData: { name: IRoleSlug, label: string }[] = [
    { name: 'jhs-fixing', label: 'Fixing' },
    { name: 'jhs-cleaning', label: 'Cleaning' },
    { name: 'jhs-photo', label: 'Foto' },
    { name: 'jhs-ac', label: 'AC' },
    { name: 'jhs-fixing-coordinator', label: 'Fixing Coordinator' },
    { name: 'jhs-cleaning-coordinator', label: 'Cleaning Coordinator' },
    { name: 'jhs-ac-coordinator', label: 'AC Coordinator' },
]

export default JHSScheduleCard

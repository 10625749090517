import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Theme } from '@mui/material';
import moment from 'moment';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import ClosingTransactionExportDetail from './ClosingTransactionExportDetail';
import TextArea from '../../_components/_form/TextArea';

/**
 * Utils
 */
import { download, generalErrorHandler, renderWarningButton, renderInfoButton, renderToastSuccess, renderErrorButton } from '../../_utils/Helper';
import DefaultAxios from '../../_utils/DefaultAxios';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';
import GeneralFormModal from '../../_components/_modal/GeneralFormModal';

interface ClosingTransactionExportProps {
    isDeposit?: boolean
}

const ClosingTransactionExport = (props: ClosingTransactionExportProps) => {
    const permissions = useContext(PermissionContext)
    const API_URL = process.env.REACT_APP_API_URL + '/closing-transaction-export';
    const { Root, classes } = useStyles()

    const [reDrawDatatable, setReDrawDatatable] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [selectedId, setSelectedId] = useState('')
    const [selectedCode, setSelectedCode] = useState('')

    const [selectedIdForReset, setSelectedIdForReset] = useState('')
    const [resetReason, setResetReason] = useState('')

    useEffect(() => {
        if (selectedIdForReset === '') {
            setResetReason('')
        }
    }, [selectedIdForReset])

    const columns = [
        {
            name: 'datetime',
            label: 'Tanggal',
            type: 'date',
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    `${moment(data).format("DD MMMM YYYY HH:mm:ss")}`
                )
            }
        },
        {
            name: 'code',
            label: 'Code',
            type: 'string',
            filterable: true,
            render: (data: any, row: any) => (
                <span
                    className={classes.code}
                    onClick={() => {
                        setSelectedId(row.id)
                        setSelectedCode(data)
                    }}
                >
                    {data ? data : '-'}
                </span>
            )
        },
        {
            name: 'status',
            label: 'Status',
            type: 'string',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'Unexported'
                },
                {
                    key: '1',
                    value: 'Exported'
                },
                {
                    key: '2',
                    value: 'Released'
                },
                {
                    key: '3',
                    value: 'Canceled'
                }
            ],
            render: (data: any, row: any) => {
                switch (data) {
                    case 0:
                        return "Unexported";
                    case 1:
                        return "Exported";
                    case 2:
                        return "Released";
                    case 3:
                        return "Canceled";
                }
            }
        },
        {
            name: 'exported_by_name',
            label: 'Exported By',
            type: 'string',
            filterable: true,
        },
        {
            name: 'EXTRA',
            label: 'Action',
            sortable: false,
            render: (row: any) => {
                return (
                    <>
                        <Button
                            variant="contained"
                            color={row.status !== 0 ? undefined : 'primary'}
                            size="small"
                            onClick={() => {
                                if (row.status !== 0 && !permissions['closing-transaction.reexport']) {
                                    renderInfoButton('Transaksi sudah diexport', 'Bila dibutuhkan kembali. Harap Kontak Admin')
                                } else {
                                    onExport(row.id, row.code, row.status)
                                }
                            }}
                        >
                            {getActionButtonLabel(row.status)}
                        </Button>
                        {
                            (permissions['closing-transaction.cancel-export'] && row.status < 2) &&
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => onCancel(row.id)}
                                style={{ marginLeft: 8 }}
                            >
                                Cancel
                            </Button>
                        }
                        {
                            (permissions['closing-transaction.reset-export'] && row.status === 1) &&
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => setSelectedIdForReset(row.id)}
                                style={{ marginLeft: 8 }}
                            >
                                Reset Export
                            </Button>
                        }


                        <input
                            key={row.id}
                            type="file"
                            name="file"
                            id={`file-${row.id}`}
                            accept='.txt'
                            value={''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const files = e.target.files;

                                if (files && files[0]) {
                                    onCompare(row.id, files[0])
                                }
                            }}
                            style={{
                                display: 'none',
                            }}
                        />
                    </>
                );
            }
        }
    ];

    const getActionButtonLabel = (status: number) => {
        switch (status) {
            case 0:
                return 'Download Data Export'
            case 1:
                return 'Transaction Exported'
            case 2:
                return 'Released'
            case 3:
                return 'Canceled'
            default:
                return 'UNDEFINED'
        }
    }

    const onExport = (id: string, code: string, showConfirm: number) => {
        setIsLoading(true)

        if (!showConfirm) {
            renderWarningButton('Data akan ditandai telah ter-export. Lanjutkan?', 'Exporting Data')
                .then((result) => {
                    if (result.value) {
                        downloadExport(id, code);
                        setReDrawDatatable(reDrawDatatable + 1);
                    } else {
                        setIsLoading(false)
                    }
                });
        } else {
            downloadExport(id, code);
        }
    }

    const downloadExport = (id: string, code: string) => {
        download(`${API_URL}/export/${id}`, `${code}.zip`, () => {
            setIsLoading(false)
        })
    }

    const onCancel = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin meng-cancel export closing transaction?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)

                    DefaultAxios.post(`${API_URL}/cancel`, { id })
                        .then(res => res.data)
                        .then(data => {
                            Swal.fire({
                                title: "Berhasil meng-cancel export closing transaction",
                                icon: 'success',
                                timer: 1000,
                                onClose: () => {
                                    setReDrawDatatable(reDrawDatatable + 1);
                                }
                            });
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            });
    }

    const onCompare = (id: string, file: File) => {
        setIsLoading(true)

        const fd = new FormData()
        fd.append('id', id)
        fd.append('file', file)

        DefaultAxios.post(`${API_URL}/compare`, fd)
            .then(res => res.data)
            .then(data => {
                Swal.fire({
                    title: "Data sesuai",
                    icon: 'success',
                });
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const onReset = () => {
        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-transaction-export/reset`, {
            id: selectedIdForReset,
            reason: resetReason,
        })
            .then(res => res.data)
            .then(data => {
                setSelectedIdForReset('')
                renderToastSuccess('Export di-reset')
                setReDrawDatatable(prev => prev + 1)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }



    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <ClosingTransactionExportDetail
                permissions={permissions}
                isOpen={selectedId !== ''}
                id={selectedId}
                code={selectedCode}
                onClose={() => {
                    setSelectedId('')
                }}
            />
            {
                (permissions['closing-transaction.reset-stuck']) &&
                <Grid display='flex' justifyContent='flex-end'>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => setShowModal(true)}
                        style={{ marginBottom: 30 }}
                    >
                        Reset Exported Transaction
                    </Button>
                </Grid>
            }
            <Dialog
                open={selectedIdForReset !== ''}
                onClose={() => setSelectedIdForReset('')}
                fullWidth
                maxWidth="xs"
            >
                <Root>
                    <DialogTitle>Reset Export</DialogTitle>
                    <DialogContent>
                        <div style={{ marginTop: 4 }}>
                            <TextArea
                                label="Alasan"
                                name="reason"
                                value={resetReason}
                                onChange={(e) => setResetReason(e.target.value)}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={onReset}
                            disabled={resetReason === ''}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Root>
            </Dialog>
            <GeneralFormModal
                title='Check Stuck Closing Transaction'
                open={showModal}
                onClose={() => setShowModal(false)}
                onSubmit={(state, onFinal) => {
                    setIsLoading(true)

                    DefaultAxios.get(`${process.env.REACT_APP_API_URL}/closing-transaction/check-stuck`, {
                        params: {
                            code: state.code
                        },
                    })
                        .then((response) => {
                            setIsLoading(false)
                            renderWarningButton(`Anda akan mereset transaksi ini ${state.code}. Lanjutkan?`)
                                .then((result) => {
                                    if (result.value) {
                                        setIsLoading(true)

                                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-transaction/reset-stuck`, {
                                            'id': response.data,
                                            'reason': state.reason,
                                        }).then(res => {
                                            setReDrawDatatable(reDrawDatatable + 1);
                                            renderToastSuccess('Transaksi berhasil di-reset.');
                                            setShowModal(false);
                                        }).catch((error) => {
                                            generalErrorHandler(error);
                                        })
                                        .finally(() => {
                                            setIsLoading(false)
                                        })
                                    }
                                });
                        })
                        .catch((error) => {
                            setIsLoading(false)
                            if (error.response.status === 404) {
                                renderErrorButton("Kode tidak ditemukan. Harap isi sesuai kode-nya")
                            } else {
                                generalErrorHandler(error);
                            }
                        })
                        .finally(onFinal)
                }}
                forms={[
                    {
                        name: 'code',
                        type: 'input',
                        defaultValue: '',
                        label: 'Isi Kode transaksi yang ingin di-reset',
                        required: true,
                        alias: 'Isi Kode transaksi yang ingin di-reset',
                    },
                    {
                        name: 'reason',
                        type: 'input',
                        defaultValue: '',
                        label: 'Isi alasan me-reset data',
                        required: true,
                        alias: 'Isi alasan me-reset data',
                    },
                ]}
            />
            <DataTable
                reDraw={reDrawDatatable}
                url={props.isDeposit ? `${process.env.REACT_APP_API_URL}/closing-transaction-export/deposit` : API_URL}
                columns={columns}
            />
        </Root>
    );
}

const useStyles = generateStyle((theme: Theme) => ({
    code: {
        color: theme.palette.primary.main,
        transition: 'color 200ms linear',
        cursor: 'pointer',

        '&:hover': {
            color: theme.palette.primary.dark,
        }
    },
}), "ClosingTransaction_Export")

export default ClosingTransactionExport;
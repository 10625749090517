import React, { useEffect, useState } from 'react'

/* 
* Components
*/
import PICFormMain from './PICFormMain'

/* 
* Style Icons
*/
import { Card, CardContent, Theme } from '@mui/material'
import { useParams } from 'react-router-dom'
import { generateStyle } from '../../_utils/DefaultStyle'

const useStyles = generateStyle((theme: Theme) =>
({
    rootContainer: {
        maxWidth: '750px',
        marginBottom: '70px',
        boxShadow: '0px 3px 15px #0000001A',
        '& .Mui-disabled': {
            backgroundColor: '#eee'
        }
    },
}), "PIC_Form"
)

interface IParams extends Record<string, string | undefined> {
    id: string
}

const PICForm = () => {
    const { Root, classes } = useStyles()
    const params = useParams<IParams>()

    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        if (params.id !== undefined) {
            setIsEdit(true)
        }
    }, [params])

    return (
        <Root>
            <Card className={classes.rootContainer}>
                <CardContent>
                    <PICFormMain
                        isEdit={isEdit}
                        picId={params.id ? params.id : undefined}
                    />
                </CardContent>
            </Card>
        </Root>
    )
}

export default PICForm
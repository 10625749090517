import { Card, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import React from 'react'
import { IOrderJobs } from '../../_jhsOrder/_components/FixingCleaningForm'

type Props = {
    open: boolean
    onClose: () => void
    jobs: IOrderJobs[]
}

const JobDetailModal = (props: Props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>Job Detail</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {props.jobs.map((job) => (
                        <Grid item xs={12}>
                            <Card variant='outlined' key={job.id}>
                                <Typography sx={{ p: 1}} variant='body2'>{job.description}</Typography>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default JobDetailModal
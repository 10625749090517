import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Theme, Typography, Box, Dialog, Button } from '@mui/material';

// Components
import UnitCard from '../../_components/_cards/UnitCard';
import LoadingCard from "../../_components/_cards/LoadingCard";
import ListingModal from "./ListingModal";
import FilterCard from './_components/FilterCard';
import CustomBadge from './_components/CustomBadge';
import CallButtonModal from '../../_components/_modal/CallButtonModal';

// Icons
import FilterListIcon from '@mui/icons-material/FilterList';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';

//Interface
import { useSnackbar } from "notistack";
import DataNA from "../../_components/DataNA";
import { currencyToNumber } from "../../_utils/Helper";
import { generateStyle } from "../../_utils/DefaultStyle";

export interface IListing {
  code: string;
  name: string;
  available_rent: number;
  available_sell: number;
  listing_type: string;
  property_area: string;
  land_area: string;
  location: string;
  landlord_name: string;
  landlord_phone: string;
  bedroom: null | number;
  bathroom: null | number;
  carport: null | number;
  notes: string;
  created_at: string;
  display_price: number | string;
  display_duration: string;
  photos: string[];
  is_active: boolean;
}

interface IFilterData {
  type: Map<string, boolean>;
  is_hot: boolean;
  only_inactive: boolean;
  requested_types: Map<string, boolean>;
  price_min: string;
  price_max: string;
  area: string;
  bedroom: number | null;
  bathroom: number | null;
  property_area_min: string;
  property_area_max: string;
  land_area_min: string;
  land_area_max: string;
  location: string
  entity: string
  id: number | null
}

interface IOptions {
  type: object[],
  requested_types: object[]
}


interface IInfiniteScroll {
  hasMore: boolean
  isLoading: boolean
}

interface ICallData {
  name: string;
  phoneNumber: string;
}

const useStyles = generateStyle((theme: Theme) =>
({
  listContainer: {
    display: "flex",
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '3.5rem'
  },
  appBar: {
    position: 'relative',
  },
  filterContainer: {
    zIndex: 1,
    position: 'fixed',
    bottom: '2rem',
    left: 0,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    '& .filter-button': {
      borderRadius: '2rem',
      padding: '0.4rem 1.5rem',
      backgroundColor: 'white',
      fontWeight: 400,
      '& *': {
        textTransform: 'capitalize'
      }
    }
  }

}), "Listing_List"
);

const initialFilterData = {
  type: new Map(),
  is_hot: false,
  only_inactive: false,
  requested_types: new Map(),
  price_min: '',
  price_max: '',
  area: '',
  bedroom: null,
  bathroom: null,
  property_area_min: '',
  property_area_max: '',
  land_area_min: '',
  land_area_max: '',
  location: '',
  entity: '',
  id: null
}

const ListingList = () => {
  const { Root, classes } = useStyles();
  const [init, setInit] = useState<boolean>(true)
  const navigate = useNavigate()
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const API_URL = process.env.REACT_APP_API_URL + '/listing';

  const [open, setOpen] = useState(false)
  const [selectedData, setSelected] = useState<IListing | null>(null)
  const [data, setData] = useState<IListing[]>([]);
  const [callModalOpen, setCallModalOpen] = useState(false);
  const [callData, setCallData] = useState<ICallData>({
    name: '',
    phoneNumber: ''
  });

  /**
   * Handle filter card open condition
   */
  const [filterOpen, setFilterOpen] = useState<boolean>(false)

  /**
   * Handle filterData
   */
  const [filterData, setFilterData] = useState<IFilterData>(initialFilterData)

  const resetFilter = () => {
    setFilterData({
      ...initialFilterData,
      type: new Map(),
      requested_types: new Map()
    })
  }

  /**
   * Options for type and requested_types
   */
  const [options, setOptions] = useState<IOptions>({
    type: [],
    requested_types: []
  })

  /**
   * Handle the page
   */
  const [page, setPage] = useState<number>(0)

  /**
   * Handle infinite scroll options
   */
  const [infiniteScroll, setInfiniteScroll] = useState<IInfiniteScroll>({
    hasMore: true,
    isLoading: true
  })

  useEffect(() => {
    if (typeof location.state !== "undefined") {
      if (location.state!.show_complete) {
        enqueueSnackbar('Data berhasil disimpan');
      }
    }

    window.scrollTo(0, 0)
    fetchOptions();
    fetchData(true);
    setInit(false)
    window.onhashchange = () => {
      if (window.location.hash !== '#detail') {
        setOpen(false)
      }
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Add listener for infinity scroll
   */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line
  }, [infiniteScroll, page])

  // useEffect(() => {
  //   if (!init) {
  //     window.scrollTo(0,0)
  //     fetchData(true)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.query_value])

  /**
   * Open filter card
   */
  const openFilter = () => {
    setFilterOpen(true)
  }

  const handleScroll = () => {
    // Check if user already scrolled to the bottom
    if (document.documentElement.scrollHeight - document.documentElement.scrollTop === document.documentElement.clientHeight && !infiniteScroll.isLoading && infiniteScroll.hasMore) {
      fetchData();
    }
  }

  const fetchData = (filtered?: boolean) => {
    // Reset all if it just got filtered
    if (filtered) {
      setInfiniteScroll({
        ...infiniteScroll,
        isLoading: true
      })
      setData([]);
      setPage(1)
    }

    // Determine the type
    let type = ''
    let typeCount = 0
    filterData.type.forEach((value, key) => {
      if (value) {
        type = key
        typeCount++
      }
    })
    if (typeCount === 2) type = ''

    // Determine the requested_types
    let requested_types_arr: string[] = []
    filterData.requested_types.forEach((value, key) => {
      if (value) {
        requested_types_arr.push(key)
      }
    })
    const requested_types = requested_types_arr.join(',')

    const params = {
      // query: props.query_value,
      page: filtered ? 1 : page,
      type: type !== '' ? type : null,
      is_hot: filterData.is_hot ? 1 : 0,
      is_inactive_only: filterData.only_inactive ? 1 : 0,
      requested_types: requested_types ? requested_types : null,
      price_min: filterData.price_min !== '' ? currencyToNumber(filterData.price_min) : null,
      price_max: filterData.price_max !== '' ? currencyToNumber(filterData.price_max) : null,
      bedroom: filterData.bedroom,
      bathroom: filterData.bathroom,
      area: filterData.area || null,
      property_area_min: filterData.property_area_min !== '' ? currencyToNumber(filterData.property_area_min) : null,
      property_area_max: filterData.property_area_max !== '' ? currencyToNumber(filterData.property_area_max) : null,
      land_area_min: filterData.land_area_min !== '' ? currencyToNumber(filterData.land_area_min) : null,
      land_area_max: filterData.land_area_max !== '' ? currencyToNumber(filterData.land_area_max) : null,
      district_id: filterData.id !== null && filterData.entity === 'district' ? filterData.id : null,
      city_id: filterData.id !== null && filterData.entity === 'city' ? filterData.id : null,
      province_id: filterData.id !== null && filterData.entity === 'province' ? filterData.id : null,
    }

    DefaultAxios.get(API_URL, { params })
      .then(res => {
        const resData = res.data;
        const listings = resData.items.slice();
        const hasMore = resData.more;

        setPage(prev => prev + 1)
        setData(filtered ? listings : data.concat(listings));
        setInfiniteScroll({
          ...infiniteScroll,
          isLoading: false,
          hasMore
        });
      })
      .catch(err => {
        setInfiniteScroll({
          ...infiniteScroll,
          isLoading: false,
        });
        enqueueSnackbar('Kesalahan server, harap hubungi admin', { variant: 'error' })
      })
  }

  /**
   * Fetch the options for type and selected_types on filter card
   */
  const fetchOptions = () => {
    DefaultAxios.get(`${process.env.REACT_APP_API_URL}/listing-type`)
      .then(response => response.data)
      .then(data => {
        const type = [
          {
            id: '0',
            label: 'Sewa'
          },
          {
            id: '1',
            label: 'Jual'
          }
        ]
        setOptions({
          type: type,
          requested_types: data
        })
      })
      .catch((error) => {
        enqueueSnackbar('Kesalahan server, harap hubungi admin', { variant: 'error' })
      })
  }

  const isOnlySell = () => {
    let flag = true
    flag = filterData.type.has('1')
    if (flag) {
      filterData.type.forEach((value, key) => {
        if ((key === '1' && !value) || (key === '0' && value)) {
          flag = false
        }
      })
    }
    return flag
  }

  const renderCards = () => {
    return (data.map((val: any, idx: number) => {
      return (
        <UnitCard data={val} onClick={handleOpen} onPhoneClick={() => { handleCallOpen(val.landlord_name, val.landlord_phone) }} key={idx} isOnlySell={isOnlySell()} />
      )
    }))
  };

  const handleOpen = (data: any) => {
    setSelected(data)
    setOpen(true)
  }

  const handleCallOpen = (name: string, phoneNumber: string) => {
    setCallData({
      name,
      phoneNumber
    });
    setCallModalOpen(true);
  }

  const handleClose = () => {
    setOpen(false)
  }

  const filterAmount = () => {
    let amount = 0
    // Handle dari is_hot
    if (filterData.is_hot) {
      amount++
    }

    // Count type
    filterData.type.forEach((value, key) => {
      if (value) {
        amount++
        return
      }
    })

    // Count requested_types 
    let flag = false
    filterData.requested_types.forEach((value, key) => {
      if (value) {
        flag = true
      }
    })

    if (flag) amount++

    // Count price
    if (filterData.price_min !== '' || filterData.price_max !== '') {
      amount++
    }

    // Count Area
    if (filterData.area !== '') {
      amount++
    }

    // Count Bedroom
    if (filterData.bedroom) {
      amount++
    }

    // Count Bathroom
    if (filterData.bathroom) {
      amount++
    }

    // Count Property Area
    if (filterData.property_area_min !== '' || filterData.property_area_max !== '') {
      amount++
    }

    // Count Land Area
    if (filterData.land_area_min !== '' || filterData.land_area_min !== '') {
      amount++
    }

    if (filterData.location !== '' || filterData.entity !== '' || filterData.id !== null) amount++

    return amount
  }

  useEffect(() => {
    if (open) {
      window.location.hash = '#detail'
    } else {
      window.history.pushState("", document.title, window.location.pathname + window.location.search)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Root>
    <Box className={classes.listContainer} >
      <Dialog fullScreen open={open} onClose={handleClose}>
        <ListingModal code={selectedData ? selectedData.code : ''} onClose={handleClose} />
      </Dialog>

      <FilterCard
        open={filterOpen}
        setFilterOpen={setFilterOpen}
        fetchData={fetchData}
        filterData={filterData}
        setFilterData={setFilterData}
        options={options}
        setOptions={setOptions}
        onReset={resetFilter}
        show_toggle_inactive={true}
      />

      <CallButtonModal
        open={callModalOpen}
        onClose={() => setCallModalOpen(false)}
        name={callData.name}
        phoneNumber={callData.phoneNumber}
      />

      {
        data.length > 0
          ?
          <>
            <Typography variant="caption" style={{ color: '#00000099', margin: 13 }}>Tap card to see details</Typography>
            {renderCards()}
          </>
          :
          !infiniteScroll.isLoading &&
          <DataNA
            type="Listing"
            navigate={() => navigate('/listing/add')}
            init={init}
          />
      }
      <div className={classes.filterContainer}>
        <Button
          variant="contained"
          className="filter-button"
          startIcon={<FilterListIcon />}
          onClick={openFilter}
        >
          Filter
          {filterAmount() !== 0 && <CustomBadge amount={filterAmount()} />}
        </Button>
      </div>

      {infiniteScroll.isLoading &&
        <div style={{ width: '100%' }}>
          <LoadingCard />
          <LoadingCard />
        </div>
      }
    </Box>
    </Root>
  );
};

export default ListingList;

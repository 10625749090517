import React from 'react';
import { Card, CardActionArea, CardMedia, CardActions, Button, CardContent, Typography } from '@mui/material';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';

// Interfaces
import { IState } from '../ProjectDeveloperImageForm';

interface Props {
    imageState: IState;
    onEditImage: Function;
    onDeleteImage: Function;
    onPrimaryImage: Function;
}

const ImageCard = (props: Props) => {
    return (
        <Card>
            <CardActionArea>
                <CardMedia
                    component="img"
                    alt={props.imageState.alt}
                    height="140"
                    image={props.imageState.thumbnail}
                    title={props.imageState.caption}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {props.imageState.caption}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button color="primary" onClick={() => props.onEditImage(props.imageState)}>
                    <EditIcon />
                </Button>
                <Button color="secondary" onClick={() => props.onDeleteImage(props.imageState)}>
                    <DeleteIcon />
                </Button>
                <Button onClick={() => props.onPrimaryImage(props.imageState)}>
                    {
                        props.imageState.is_primary
                            ? <StarIcon style={{ color: '#d47f00de' }} />
                            : 'Set As Primary'
                    }
                </Button>
            </CardActions>
        </Card>
    );
}

export default ImageCard;
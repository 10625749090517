import React from 'react'

/**
 * Components
 */
import { Box, Button, Typography, useTheme } from '@mui/material'

interface ButtonRadioNDProps {
    name: string
    label?: string
    value: string | number
    options: {
        value: string | number
        label: string
    }[]
    onChange: (name: string, value: string | number) => void
    error?: string
    disabled?: boolean
}

const ButtonRadioND2 = (props: ButtonRadioNDProps) => {
    const theme = useTheme()

    return (
        <Box>
            {
                props.label ?
                    <Typography
                        sx={{
                            marginBottom: 14 / 8,
                        }}
                        fontSize={14}
                        fontWeight={500}
                        color={!!props.error ? theme.palette.error.main : undefined}
                    >
                        {props.label}
                    </Typography>
                    : null
            }
            <Box style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }} >
                {
                    props.options.map((option, index) =>
                        <Button
                            key={index}
                            onClick={() => {
                                if (props.disabled) return
                                props.onChange(props.name, option.value)
                            }}
                            variant='contained'
                            disabled={props.disabled}
                            sx={{
                                backgroundColor: props.value === option.value ? theme.palette.primary.main : '#F5F5F5',
                                color: props.value === option.value ? 'white' : '#484848',
                                borderRadius: 6 / 4,
                                paddingTop: 12 / 8,
                                paddingBottom: 12 / 8,
                                paddingLeft: 16 / 8,
                                paddingRight: 16 / 8,
                                fontSize: 14,
                                fontWeight: '400',
                                boxShadow: 'none',
                                textTransform: 'none',
                                // marginLeft: index === 0 ? 0 : 14 / 8,
                                lineHeight: 1.4,
                                whiteSpace: 'nowrap',
                                "&:hover": {
                                    color: 'white',
                                    backgroundColor: props.value === option.value ? theme.palette.primary.main : theme.palette.primary.dark,
                                    boxShadow: 'none',
                                },
                                "&:disabled": {
                                    color: 'white',
                                    backgroundColor: theme.palette.grey[300],
                                    boxShadow: 'none',
                                    cursor: "not-allowed"
                                }
                            }}
                        >
                            {option.label}
                        </Button>
                    )
                }
            </Box>
            {
                props.error ?
                    <Typography
                        sx={{
                            marginTop: 4 / 8,
                            marginLeft: 2 / 8,
                        }}
                        fontSize={12}
                        fontWeight={400}
                        color={theme.palette.error.main}
                    >
                        {props.error}
                    </Typography>
                    : null
            }
        </Box>
    )
}

export default ButtonRadioND2

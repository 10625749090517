import React, { useState, useEffect } from 'react';

// Table
import DataTable from '../../_components/_dataTable/DataTable';
import { Switch } from '@mui/material';

import ManageUnitModal from './ManageUnitModal';
import DefaultAxios from '../../_utils/DefaultAxios';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const SwalContainer = withReactContent(Swal)

const ManageExtends = () => {
  const UNIT_URL = process.env.REACT_APP_API_URL + '/unit-manage'

  // const [state, setState] = useState<IState>({
  //   filter: new Map([['all', true]])
  // })

  /**
   * Handle modal state
   */
  const [modalStatus, setModalStatus] = useState<boolean>(false)

  const closeModal = () => {
    setModalStatus(false)
  }

  /**
   * Modal data state
   */
  const [modalData, setModalData] = useState<string>('')

  // Handle if the modal is closed
  useEffect(() => {
    if (!modalStatus) {
      setModalData('')
    }
  }, [modalStatus])

  // const filterOptions = [
  //   {
  //     id: 'all',
  //     label: 'All'
  //   },
  //   {
  //     id: 'rent',
  //     label: 'rent'
  //   },
  //   {
  //     id: 'sell',
  //     label: 'sell'
  //   }
  // ]

  // const handleSelected = (type: string, mode: string, key: string, value: boolean) => {
  //   let newMap = state.filter
  //   if (mode === 'radio') {
  //     newMap.clear()
  //     setState(prev => ({
  //       ...prev,
  //       filter: newMap.set(key, value)
  //     }))
  //   }
  // }

  const handleSwitch = (e: React.ChangeEvent<{ checked: boolean }>, columnName: string, setRows: Function, rows: any[], id: string) => {
    let checked = e.target.checked
    const data = {
      [columnName]: checked ? 1 : 0
    }
    const url = UNIT_URL + '/' + id
    DefaultAxios.put(url, data)
      .then(res => res.data)
      .then(res => {
        if (res.update) {
          const newRows = rows.map((element: any) => {
            if (element.id === id) element[columnName] = checked ? 1 : 0
            return element
          })
          setRows(newRows)
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          SwalContainer.fire({
            title: "Error",
            html: "Anda tidak memiliki akses ke unit ini",
            icon: 'error',
          })
        } else {
          SwalContainer.fire({
            title: "Error",
            html: "Kesalahan server, harap hubungi admin",
            icon: 'error',
          })
        }
      })
  }

  const renderSwitch = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
    return (
      <Switch
        checked={row[columnName] === 1}
        onChange={(e) => handleSwitch(e, columnName, setRows, rows, row.id)}
      />
    )
  }

  // Render for triggering the modal
  const renderModal = (name: string, row: any) => {
    const openModal = () => {
      setModalData(row.id)
      setModalStatus(true)
    }

    return (
      <div onClick={ openModal } style={{ color: '#428bca', display: 'flex', justifyContent: 'center', textAlign: 'center', cursor: 'pointer' }}>{ name }</div>
    )
  }

  /**
   * Placeholder data
   */
  // Columns for the bottom table
  const columnsTable = [
    {
      name: 'code',
      label: 'Unit Code',
      type: 'string',
      filterable: true,
      render: renderModal
    },
    {
      name: 'apartment_name',
      label: 'Apartment',
      type: 'string',
      filterable: true,
    },
    {
      name: 'tower',
      label: 'Tower',
      type: 'string',
      filterable: true,
    },
    {
      name: 'floor_level',
      label: 'Lantai',
      type: 'number',
      filterable: true
    },
    {
      name: 'unit_number',
      label: 'No Unit',
      type: 'string',
      filterable: true
    },
    {
      name: 'is_available',
      label: 'Available',
      type: 'string',
      filterable: true,
      render: renderSwitch,
      defaultOption: '',
      options: [
        {
          key: '',
          value: 'All'
        },
        {
          key: '1',
          value: 'Available'
        },
        {
          key: '0',
          value: 'Not Available'
        }
      ]
    },
    {
      name: 'is_available_sell',
      label: 'Available Sell',
      type: 'string',
      filterable: true,
      render: renderSwitch,
      defaultOption: '',
      options: [
        {
          key: '',
          value: 'All'
        },
        {
          key: '1',
          value: 'Available'
        },
        {
          key: '0',
          value: 'Not Available'
        }
      ]
    },
  ]

  // const testModal = () => {
  //   setModalData('1')
  //   setModalStatus(true)
  // }

  return (
    <div>
      {/* <button onClick={testModal}>test modal</button> */}
      <ManageUnitModal isOpen={modalStatus} handleClose={closeModal} id={modalData} />
      {/* <div className={classes.buttonSelect}>
        <ButtonSelect
          list={filterOptions}
          selected={state.filter}
          handleSelected={handleSelected}
          type="filter"
          mode="radio"
        />
      </div> */}
      <DataTable 
        url={UNIT_URL}
        columns={columnsTable}
        // customParams={customParams}
      />
    </div>
  );
}

export default ManageExtends
import React from 'react'

type Props = {}

const ExtendRateIcon = (props: Props) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill="#484848" />
            <path d="M29.3 11.1999H28.2V10.0999C28.2 9.43994 27.76 8.99994 27.1 8.99994C26.44 8.99994 26 9.43994 26 10.0999V11.1999H15V10.0999C15 9.43994 14.56 8.99994 13.9 8.99994C13.24 8.99994 12.8 9.43994 12.8 10.0999V11.1999H10.6C10.05 11.1999 9.5 11.6399 9.5 12.2999V27.6999C9.5 28.3599 10.05 28.7999 10.6 28.7999H17.31C16.54 27.4799 16.1 25.9399 16.1 24.3999C16.1 19.5599 20.06 15.5999 24.9 15.5999C26.99 15.5999 28.86 16.3699 30.4 17.5799V12.2999C30.4 11.7499 29.85 11.1999 29.3 11.1999Z" fill="white" />
            <path d="M24.9 17.8C21.27 17.8 18.3 20.77 18.3 24.3999C18.3 28.0299 21.27 30.9999 24.9 30.9999C28.53 30.9999 31.5 28.0299 31.5 24.3999C31.5 20.77 28.53 17.8 24.9 17.8ZM27.1 25.4999H24.9C24.24 25.4999 23.8 25.0599 23.8 24.3999V21.1C23.8 20.44 24.24 20 24.9 20C25.56 20 26 20.44 26 21.1V23.2999H27.1C27.76 23.2999 28.2 23.7399 28.2 24.3999C28.2 25.0599 27.76 25.4999 27.1 25.4999Z" fill="white" />
        </svg>

    )
}

export default ExtendRateIcon
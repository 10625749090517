import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

/**
 * components
 */
import LoadingScreen from '../../../../_components/LoadingScreen'

/**
 * Utils
*/
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../../_utils/Helper'

/**
 * Icons
 */
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface IProps {
    open: boolean,
    onClose: (refresh: boolean) => void
    id: number | null
    name: string
}

interface INote {
    id: number
    name: string
    notes: string
    title: string
    updated_by_name: string
}

export default function NoteModal(props: IProps) {
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<INote[]>([])

    const fetchData = (id: number) => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/apartment-notes/apartment-detail`, {
            params: { apartment_id: id }
        })
            .then(res => res.data)
            .then(res => {
                if (res?.length) {
                    setState(res)
                }
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (props.open && props.id) {
            fetchData(props.id)
        }

    }, [props.open, props.id])

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen={true} />
            <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="xs">
                <DialogTitle>
                    <IconButton
                        style={{ left: -10 }}
                        onClick={() => props.onClose(false)}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    {props.name} Notes 
                </DialogTitle>
                <Divider />
                <DialogContent
                    sx={{px:0}}
                >
                    {
                        state.length ?
                            <>
                                <Table
                                    size='small'
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Title
                                            </TableCell>
                                            <TableCell>
                                                Notes
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            state.map((note: INote) => (
                                                <TableRow>
                                                    <TableCell><strong>{note.title}</strong></TableCell >
                                                    <TableCell>
                                                        {note.notes}
                                                        <IconButton
                                                            sx={{ ml: 1 }}
                                                            color='primary'
                                                            onClick={() => window.open(`https://wa.me/${note.notes}`)}
                                                        >
                                                            <WhatsAppIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </>

                            :
                            <Grid container>
                                <Grid item xs={12}>Tidak ada Notes</Grid>
                            </Grid>
                    }
                </DialogContent>
            </Dialog >
        </>
    )
}

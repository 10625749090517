import { MenuItem, Theme, styled } from '@mui/material';

const MenuItemND = styled(MenuItem)(({ theme }: { theme: Theme }) => ({
    '&.MuiButtonBase-root': {
        height: 38,
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        color: '#141414',
        padding: 0,
        paddingLeft: 10,
        paddingRight: 10,
        minWidth: 194,
    },
}));

export default MenuItemND

import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography, useTheme } from '@mui/material'
import LoadingScreen from '../../../../_components/LoadingScreen'
import TextArea from '../../../../_components/_form/TextArea'
import ButtonWithLoading from '../../../../_components/_form/ButtonWithLoading'

/**
 * Utils
 */
import DefaultAxios from '../../../../_components/../_utils/DefaultAxios'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../../_components/../_utils/Validation'
import { generalErrorHandler } from '../../../../_components/../_utils/Helper'
import VisuallyHiddenInput from '../../../../_components/_form/VisuallyHiddenInput'

type Props = {
    url: string
    paramName: string
    // Modal title
    title: string
    label: string
    placeholder?: string
    paramAlias?: string
    extraParams?: { [key: string]: any }
    required?: boolean
    open: boolean
    onClose: () => void
    /**
     * need to close the modal from the parrent on this prop
     * @returns void
     */
    onSuccess: () => void
    onError?: (err: any) => void
    customPdf?: boolean
}

interface State {
    custom_pdf: null | File,
    reason: string
}

const initialState = {
    reason: '',
    custom_pdf: null
}

const VoidResendReasonModal = (props: Props) => {
    const theme = useTheme()
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<State>(initialState)
    const [error, setError] = useState<IValidationErrors<State>>({})

    useEffect(() => {
        if (!props.open) setState(initialState)
    }, [props.open])

    const validationRules: IValidationRules = useMemo(() => ({
        reason: props.required ? 'required' : '',
        // custom_pdf: props.customPdf ? 'required' : ''
    }), [props.required])

    const validationAlias: IValidationAlias = useMemo(() => ({
        reason: props.paramAlias || props.paramName,
    }), [props.paramName, props.paramAlias])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = () => {
        const { errors, isValid } = validateData(state, validationRules, validationAlias)
        setError(errors)

        if (isValid) {
            setIsLoading(true)

            let fd = new FormData()

            fd.append('reason', state.reason || "")

            if (props.extraParams) {
                for (let key in props.extraParams) {
                    fd.append(key, props.extraParams[key] || "")
                }
            }

            if (props.customPdf) {
                fd.append('custom_pdf', state.custom_pdf instanceof File ? state.custom_pdf : "")
            }

            DefaultAxios.post(props.url, fd)
                .then(res => {
                    props.onSuccess()
                })
                .catch(err => {
                    generalErrorHandler(err)
                    if (props.onError) props.onError(err)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length) {
            setState(prev => ({
                ...prev,
                custom_pdf: (e.target.files && e.target.files.length) ? e.target.files[0] : null
            }))
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            maxWidth="sm"
            fullWidth
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <TextArea
                            label={props.label}
                            name='reason'
                            placeholder={props.placeholder}
                            onChange={handleChange}
                            value={state.reason}
                            rows={3}
                            error={!!error.reason}
                            helperText={error.reason}
                        />
                    </Grid>
                    {
                        props.customPdf ?
                            <Grid item xs={12}>
                                <Typography variant='body2' sx={{ mb: 1 }}>Kontrak Custom (PDF Only,  Hanya upload bila ingin menggunakan kontrak custom)</Typography>
                                <div>
                                    <Button component="label" color='inherit' style={{ textTransform: "capitalize" }} variant='contained'>
                                        Upload File
                                        <VisuallyHiddenInput
                                            type="file"
                                            name="custom_pdf"
                                            accept='.pdf'
                                            onChange={handleChangeFile}
                                        />
                                    </Button>
                                    {
                                        state.custom_pdf ?
                                            <span style={{ marginLeft: 5 }}>{state.custom_pdf.name}</span>
                                            : null
                                    }
                                </div>
                                {
                                    error.custom_pdf &&
                                    <Typography variant='body2' style={{ color: theme.palette.error.main }}>{error.custom_pdf}</Typography>
                                }
                            </Grid>
                            : null
                    }
                    <Grid item xs={12}>
                        <ButtonWithLoading
                            isLoading={isLoading}
                            variant='contained'
                            onClick={handleSubmit}
                            sx={{ float: 'right' }}
                        >
                            Submit
                        </ButtonWithLoading>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default VoidResendReasonModal
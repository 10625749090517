import { Dialog, DialogContent, DialogTitle, Theme } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { numberToCurrency } from '../../../../_utils/Helper'
import { TransactionCredit } from './TransactionList'
import { generateStyle } from '../../../../_utils/DefaultStyle'

interface CreditDetailModalProps {
    credit: TransactionCredit | null
    onClose: () => void
}

const CreditDetailModal = ({ credit, onClose }: CreditDetailModalProps) => {
    const { Root, classes } = useStyles()

    return (
        <Dialog fullWidth maxWidth="xs" open={!!credit} onClose={onClose}>
            <Root>
                <DialogTitle>
                    {credit?.code}
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {
                        !!credit &&
                        <>
                            <span className={classes.label}>
                                Tipe
                            </span>
                            <span className={classes.value}>
                                Credit
                            </span>
                            <span className={classes.label}>
                                Tanggal
                            </span>
                            <span className={classes.value}>
                                {moment(credit.date).format("DD MMMM YYYY")}
                            </span>
                            <span className={classes.label}>
                                Deskripsi
                            </span>
                            <span className={classes.value}>
                                {credit.description || '-'}
                            </span>
                            <span className={classes.label}>
                                Nominal
                            </span>
                            <span className={classes.value}>
                                {numberToCurrency(credit.amount, 'Rp')}
                            </span>
                            <span className={classes.label}>
                                Assigned By
                            </span>
                            <span className={classes.value}>
                                {credit.mapped_by}
                            </span>
                        </>
                    }
                </DialogContent>
            </Root>
        </Dialog>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 8,
        fontSize: 16,
    },
    value: {
        marginBottom: 16,
        fontSize: 16,
    },
}), "CreditDetail_Modal")

export default CreditDetailModal

import React, { useReducer, useEffect, useState } from 'react';
import { Grid, TextField, Switch, Button } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

/**
 * Components
 */
import TextArea from '../../_components/_form/TextArea';
import LoadingScreen from '../../_components/LoadingScreen';
import MuiDatePicker from '../../_components/MuiDatePicker';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import Swal from 'sweetalert2';
import { currencyToNumber, dateFormat, generalErrorHandler, numberToCurrency } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IState {
    id: string;
    unit_id: string;
    unit_label: string;
    booking_id: string;
    ota: string;
    name: string;
    phone: string;
    check_in: Date | null;
    check_out: Date | null;
    rates: string[];
    is_same_rate: boolean;
    notes: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IErrorState {
    unit_id: string;
    booking_id: string;
    ota: string;
    name: string;
    phone: string;
    check_in: string;
    check_out: string;
    rates: string[];
}

const useStyles = generateStyle(theme => ({
    borderBottomLine: {
        borderBottom: '1px solid #eee'
    },
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
}), "Stay360Booking_Form");

const Stay360BookingForm = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/stay360-booking';
    const { Root, classes } = useStyles();
    const location = useLocation();
    const params: any = useParams();

    const initialState = {
        id: '',
        unit_id: '',
        unit_label: '',
        booking_id: '',
        ota: '',
        name: '',
        phone: '',
        check_in: null,
        check_out: null,
        rates: [],
        is_same_rate: false,
        notes: ''
    };

    const initialErrorState = {
        unit_id: '',
        booking_id: '',
        ota: '',
        name: '',
        phone: '',
        check_in: '',
        check_out: '',
        rates: []
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (params.id) {
            loadData();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        checkRates();
        // eslint-disable-next-line
    }, [inputState.check_in, inputState.check_out, inputState.is_same_rate]);

    const loadData = () => {
        setIsLoading(true);

        DefaultAxios.get(API_URL + '/' + params.id)
            .then(res => {
                setInputState({ name: '', value: res.data, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index?: number) => {
        const target = e.target;
        const name = target.name;
        let value: any = target.value;
        const newState: any = { ...inputState };

        if (name === 'is_same_rate') {
            newState[name] = target.checked;
        } else if (name === 'rates' && typeof index !== 'undefined') {
            const newRates = newState.rates.slice();
            newRates[index] = String(currencyToNumber(value));
            newState.rates = newRates;
        } else {
            newState[name] = value;
        }

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    // TODO: might break
    const handleDateChanged = (name: string, date: Date | null) => {
        setInputState({ name, value: date, type: 'SET_ITEM' });
        // handleTerminDates();
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const checkRates = () => {
        if (inputState.check_in && inputState.check_out) {
            const newState = { ...inputState };
            const oldRates = newState.rates.slice();
            const newRates = [];
            let diffDays;
            if (!inputState.is_same_rate) {
                const momentCheckIn = moment(inputState.check_in);
                const momentCheckOut = moment(inputState.check_out);
                diffDays = Math.abs(momentCheckIn.diff(momentCheckOut, 'days')) + 1;
            } else {
                diffDays = 1;
            }

            for (let i = 0; i < diffDays; i++) {
                if (typeof oldRates[i] !== 'undefined') {
                    newRates.push(oldRates[i]);
                } else {
                    newRates.push('');
                }
            }

            newState.rates = newRates;
            setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
        }
    }

    const checkValidation = () => {
        let isValid = true;
        const newErrorState: any = { ...initialErrorState };

        if (!inputState.name) {
            newErrorState.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.phone) {
            newErrorState.phone = 'No HP wajib diisi';
            isValid = false;
        }

        if (!inputState.unit_id) {
            newErrorState.unit_id = 'Nomor Unit wajib diisi';
            isValid = false;
        }

        if (!inputState.ota) {
            newErrorState.ota = 'OTA wajib diisi';
            isValid = false;
        }

        if (!inputState.booking_id) {
            newErrorState.booking_id = 'Booking ID wajib diisi';
            isValid = false;
        }

        if (!inputState.check_in) {
            newErrorState.check_in = 'Check In wajib diisi';
            isValid = false;
        } else if (inputState.check_out && moment(inputState.check_in).isAfter(moment(inputState.check_out))) {
            newErrorState.check_in = 'Check In tidak boleh lebih besar dari Check Out';
            isValid = false;
        }

        if (!inputState.check_out) {
            newErrorState.check_out = 'Check Out wajib diisi';
            isValid = false;
        } else if (inputState.check_in && moment(inputState.check_out).isBefore(moment(inputState.check_in))) {
            newErrorState.check_out = 'Check Out tidak boleh lebih kecil dari Check In';
            isValid = false;
        }

        const newRatesError = [];
        for (let i in inputState.rates) {
            if (!inputState.rates[i] || +inputState.rates[i] === 0) {
                newRatesError.push('Rates wajib diisi');
                isValid = false;
            } else {
                newRatesError.push('');
            }
        }
        newErrorState.rates = newRatesError;

        setErrorState({ name: '', value: newErrorState, type: 'REPLACE_STATE' });
        return isValid;
    }

    const mapInputState = (state: IState) => {
        const data: any = { ...state };

        data.check_in = dateFormat(state.check_in || '', 'YYYY-MM-DD');
        data.check_out = dateFormat(state.check_out || '', 'YYYY-MM-DD');

        return data;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;

        const data = mapInputState(inputState);

        if (inputState.id) {
            axios = DefaultAxios.patch(API_URL + '/' + inputState.id, data);
        } else {
            axios = DefaultAxios.post(API_URL, data);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Booking submitted",
                    icon: 'success',
                    onAfterClose: () => {
                        //
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <Grid container spacing={3}>
                <LoadingScreen open={isLoading} fullScreen />
                <Grid item xs={12}>
                    <h1 className={classes.borderBottomLine}>Stay360 Booking - {location.pathname === '/stay360-booking/add' ? 'Add' : 'EDIT'}</h1>
                </Grid>

                <Grid item xs={12}>
                    <AsyncAutoComplete
                        label="Nomor Unit"
                        name="unit_id"
                        initialQuery={inputState.unit_label}
                        onChange={handleAutocomplete}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/stay360-unit`}
                        errorText={errorState.unit_id}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Nama"
                        name="name"
                        value={inputState.name}
                        onChange={handleChange}
                        error={!!errorState.name}
                        helperText={errorState.name}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="No HP"
                        name="phone"
                        value={inputState.phone}
                        onChange={handleChange}
                        error={!!errorState.phone}
                        helperText={errorState.phone}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="OTA"
                        name="ota"
                        value={inputState.ota}
                        onChange={handleChange}
                        error={!!errorState.ota}
                        helperText={errorState.ota}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Booking ID"
                        name="booking_id"
                        value={inputState.booking_id}
                        onChange={handleChange}
                        error={!!errorState.booking_id}
                        helperText={errorState.booking_id}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MuiDatePicker
                        label="Check In"
                        value={inputState.check_in}
                        onChange={(date: any) => handleDateChanged('check_in', date)}
                        error={!!errorState.check_in}
                        helperText={errorState.check_in}
                        maxDate={inputState.check_out ? inputState.check_out : null}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MuiDatePicker
                        label="Check Out"
                        value={inputState.check_out}
                        onChange={(date: any) => handleDateChanged('check_out', date)}
                        error={!!errorState.check_out}
                        helperText={errorState.check_out}
                        minDate={inputState.check_in ? inputState.check_in : null}
                    />
                </Grid>

                <Grid item xs={12} className={classes.switchContainer}>
                    Rate Sama Semua Hari
                    <Switch
                        color="primary"
                        name="is_same_rate"
                        value={true}
                        checked={inputState.is_same_rate}
                        onChange={(e) => handleChange(e)}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                </Grid>

                {inputState.rates.map((rate, key) =>
                    <Grid item xs={12} key={key}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Rate"
                            name="rates"
                            value={numberToCurrency(rate)}
                            onChange={(e: any) => handleChange(e, key)}
                            error={!!errorState.rates[key]}
                            helperText={errorState.rates[key]}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <TextArea
                        label="Notes"
                        name="notes"
                        value={inputState.notes}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Root>
    );
}

export default Stay360BookingForm;
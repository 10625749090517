import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Theme } from '@mui/material'
import React from 'react'
import { generateStyle } from '../../../_utils/DefaultStyle'

interface IProps {
    isOpen: boolean
    onClose: () => void
    name: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onSubmit: () => void
    withVideo?: boolean
}

const InventoryRoomForm = (props: IProps) => {
    const { Root, classes } = useStyles()

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            closeAfterTransition
        >
            <Root>
                <DialogTitle id="form-dialog-title" fontWeight="bold">
                    New Room
                </DialogTitle>
                <DialogContent
                    className={classes.content}
                >
                    <Grid container justifyContent="space-between" spacing={2}>
                        <Grid item xs={12} sx={{ mt: 1 }}>
                            <TextField
                                autoFocus
                                id="name"
                                label="Room Name"
                                name="name"
                                type="text"
                                value={props.name}
                                onChange={props.onChange}
                                variant="outlined"
                                fullWidth
                                style={{ marginBottom: '10px' }}
                            />
                        </Grid>
                    </Grid>
                    {
                        props.withVideo ?
                            <Grid item xs={12}>
                                <h3>Upload a Video</h3>
                                <input
                                    type='file'
                                    name='video'
                                    accept='video/*'
                                    onChange={props.onChange}
                                />
                            </Grid>
                            : null

                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={props.onSubmit}
                        color="primary"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Root>

        </Dialog>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    content: {
        minWidth: '70vw',

        [theme.breakpoints.up('md')]: {
            minWidth: 350,
        }
    }
}), "InventoryRoomForm")

export default InventoryRoomForm

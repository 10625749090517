import React, { SVGProps } from 'react'

const TargetIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_1134_3458)">
                <path d="M10 14.1667C12.3012 14.1667 14.1667 12.3012 14.1667 10C14.1667 7.69885 12.3012 5.83337 10 5.83337C7.69885 5.83337 5.83337 7.69885 5.83337 10C5.83337 12.3012 7.69885 14.1667 10 14.1667Z" fill="currentColor" />
                <path d="M19.1667 9.16667H18.2908C17.8992 5.23583 14.7642 2.10083 10.8333 1.70917V0.833333C10.8333 0.373333 10.4608 0 10 0C9.53917 0 9.16667 0.373333 9.16667 0.833333V1.70917C5.23583 2.10083 2.10083 5.23583 1.70917 9.16667H0.833333C0.3725 9.16667 0 9.54 0 10C0 10.46 0.3725 10.8333 0.833333 10.8333H1.70917C2.10167 14.7642 5.23583 17.8992 9.16667 18.2908V19.1667C9.16667 19.6267 9.53917 20 10 20C10.4608 20 10.8333 19.6267 10.8333 19.1667V18.2908C14.7642 17.8983 17.8992 14.7642 18.2908 10.8333H19.1667C19.6275 10.8333 20 10.46 20 10C20 9.54 19.6275 9.16667 19.1667 9.16667ZM10.8217 16.6108C10.7917 16.1783 10.4408 15.8333 10 15.8333C9.55917 15.8333 9.20833 16.1783 9.17833 16.6108C6.16 16.2375 3.76333 13.8408 3.39 10.8225C3.8225 10.7917 4.16667 10.44 4.16667 10C4.16667 9.56 3.8225 9.20833 3.38917 9.17833C3.7625 6.16 6.15917 3.76333 9.1775 3.39C9.20833 3.82167 9.55917 4.16667 10 4.16667C10.4408 4.16667 10.7917 3.82167 10.8217 3.38917C13.84 3.7625 16.2367 6.15917 16.61 9.1775C16.1775 9.20833 15.8333 9.56 15.8333 10C15.8333 10.44 16.1775 10.7917 16.6108 10.8217C16.2367 13.8408 13.8408 16.2367 10.8217 16.6108Z" fill="currentColor" />
            </g>
        </svg>


    )
}

export default TargetIcon
import React, { useState } from 'react';
import { IconButton } from '@mui/material';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import AccountTypeModal from './_components/AccountTypeModal';

/**
 * Icons
 */
import { Edit, Visibility } from '@mui/icons-material';


const AccountList = () => {
    const DT_API_URL = process.env.REACT_APP_API_URL + '/account';

    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [selectedData, setSelectedData] = useState<null | any>(null);

    const onSuccess = () => {
        setReDrawDatatable(prev => prev + 1)
    }

    return (
        <>
            <AccountTypeModal
                isOpen={selectedData !== null}
                selectedData={selectedData}
                onClose={() => setSelectedData(null)}
                onSuccess={onSuccess}
            />

            <DataTable
                reDraw={reDrawDatatable}
                url={DT_API_URL}
                columns={[
                    { name: 'name', label: 'Name', filterable: true },
                    {
                        name: 'type', label: 'Type', filterable: true,
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: 'user',
                                value: 'User'
                            },
                            {
                                key: 'bm',
                                value: 'BM'
                            },
                        ],
                        defaultOption: '', render: (data: any, row: any) => {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>{data === 'user' ? 'User' : 'BM'}</span>
                                    <IconButton
                                        onClick={() => setSelectedData(row)}
                                        color='primary'
                                    >
                                        <Edit />
                                    </IconButton>
                                </div>
                            );
                        }
                    },
                    { name: 'email', label: 'Email', filterable: true },
                    { name: 'phone', label: 'Phone', filterable: true },
                    { name: 'provider', label: 'Provider', filterable: true },
                    {
                        name: 'landlord_id', label: 'Landlord ID', filterable: true, render: (data: any, row: any) => {
                            return (
                                row.landlord_id ?
                                    <IconButton
                                        href={`/landlord/${row.landlord_id}`}
                                        target='_blank'
                                        color='primary'
                                    >
                                        <Visibility />
                                    </IconButton>
                                    : '-'
                            );
                        }
                    },
                ]}
            />
        </>
    );
}

export default AccountList;
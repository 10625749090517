import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material'
import ButtonWithLoading from '../../../_components/_form/ButtonWithLoading'

/**
 * Utils
 */
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import { generalErrorHandler, renderToastSuccess } from '../../../_utils/Helper'
import DefaultAxios from '../../../_utils/DefaultAxios'

/**
 * Icons
 */
import { Edit } from '@mui/icons-material'

interface ListingTicketDetailModalProps {
    isOpen: boolean
    onClose: () => void
    onFinish: () => void
    data: {
        id: string | null
        detail: string | null
    }
}

type State = {
    detail: string
}

const ListingTicketDetailModal = (props: ListingTicketDetailModalProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<State>(INITIAL_STATE)
    const [error, setError] = useState<IValidationErrors<State>>({})
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        if (props.isOpen) {
            setState(prev => ({
                ...prev,
                detail: props.data.detail || '',
            }))
        }
    }, [props.data.detail, props.isOpen])

    useEffect(() => {
        if (!props.isOpen) {
            setState(INITIAL_STATE)
            setError({})
            setIsEditing(false)
        }
    }, [props.isOpen])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const onSubmit = () => {
        const { errors, isValid } = validateData(state, VALIDATION_RULES, VALIDATION_ALIAS)
        setError(errors)

        if (isValid) {
            setIsLoading(true)

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/listing-ticket/update-detail`, {
                id: props.data.id,
                detail: state.detail,
            })
                .then(() => {
                    renderToastSuccess('Berhasil mengubah detail')
                    props.onClose()
                    props.onFinish()
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <Dialog
            open={props.isOpen}
            fullWidth
            maxWidth='xs'
            onClose={isLoading ? undefined : props.onClose}
        >
            <DialogTitle>
                Detail
                {
                    !isEditing ?
                        <IconButton
                            color='primary'
                            onClick={() => setIsEditing(true)}
                            sx={{ float: 'right' }}
                        >
                            <Edit />
                        </IconButton>
                        : null
                }
            </DialogTitle>
            <DialogContent style={{ paddingTop: isEditing ? 8 : 0 }}>
                {
                    isEditing ?
                        <>
                            <TextField
                                fullWidth
                                label='Detail'
                                name='detail'
                                value={state.detail}
                                onChange={onChange}
                                placeholder='Isi detail'
                                error={!!error.detail}
                                helperText={error.detail}
                                disabled={isLoading}
                                multiline
                                rows={3}
                            />
                            <ButtonWithLoading
                                variant='contained'
                                color='primary'
                                sx={{ float: 'right', mt: 2 }}
                                onClick={onSubmit}
                                isLoading={isLoading}
                            >
                                Submit
                            </ButtonWithLoading>
                        </>
                        :
                        <>
                            <Typography sx={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                                {props.data.detail || '-'}
                            </Typography>
                        </>
                }
            </DialogContent>
        </Dialog>
    )
}

const VALIDATION_RULES: IValidationRules = {
    detail: 'required',
}

const VALIDATION_ALIAS: IValidationAlias = {
    detail: 'Detail',
}

const INITIAL_STATE: State = {
    detail: '',
}

export default ListingTicketDetailModal

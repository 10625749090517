import React, { useState } from 'react'

/**
 * Components
 */
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material'
import UnitReviewModalForm, { UnitReview } from './UnitReviewModalForm'
import DataTable from '../../_components/_dataTable/DataTable'
import LoadingScreen from '../../_components/LoadingScreen'

/**
 * Utils
 */
import { format } from 'date-fns'
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper'
import DefaultAxios from '../../_utils/DefaultAxios'

/**
 * Icons
 */
import { Delete, Edit } from '@mui/icons-material'


const UnitReviewList = () => {
    const [reDraw, setReDraw] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const [isFormOpen, setIsFormOpen] = useState(false)
    const [selectedData, setSelectedData] = useState<null | UnitReview>(null)

    const handleEdit = (data: any) => {
        setSelectedData({
            id: data.id,
            name: data.name,
            rating: data.rating,
            review: data.review,
            unit_id: data.unit_id,
            unit_label: data.unit_code,
            date: new Date(data.date || new Date())
        })
        setIsFormOpen(true)
    }

    const handleDelete = (id: string) => {
        renderWarningButton('Apakah anda yakin ingin menghapus review unit ini?')
            .then(res => res.value)
            .then(value => {
                if (value) {
                    setIsLoading(true)

                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/unit-review/${id}`)
                        .then(() => {
                            renderToastSuccess('Berhasil menghapus data review unit')
                            setReDraw(prev => prev + 1)
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <UnitReviewModalForm
                open={isFormOpen}
                onClose={() => {
                    setSelectedData(null)
                    setIsFormOpen(false)
                }}
                editData={selectedData}
                type={selectedData === null ? 'add' : 'edit'}
                refreshTable={() => {
                    setReDraw(prev => prev + 1)
                }}
            />
            <Grid container sx={{ mb: 2 }}>
                <Grid xs={12}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            setIsFormOpen(true)
                        }}
                    >
                        Add Review
                    </Button>
                </Grid>
            </Grid >
            <DataTable
                reDraw={reDraw}
                url={`${process.env.REACT_APP_API_URL}/unit-review`}
                columns={[
                    {
                        name: 'date',
                        label: 'Date',
                        type: 'date',
                        filterable: true,
                        sortable: false,
                        render: (data: string) => data ? format(new Date(data), 'dd MMM yyyy') : '-',
                    },
                    {
                        name: 'unit_code',
                        label: 'Unit Code',
                        filterable: true,
                        sortable: false,
                    },
                    {
                        name: 'apartment_name',
                        label: 'Apartment',
                        filterable: true,
                        sortable: false,
                    },
                    {
                        name: 'name',
                        label: 'Reviewer',
                        filterable: true,
                        sortable: false,
                    },
                    {
                        name: 'rating',
                        label: 'Rating',
                        filterable: true,
                        sortable: false,
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            ...[1, 2, 3, 4, 5].map(rating => ({
                                key: rating,
                                value: rating,
                            }))
                        ],
                    },
                    {
                        name: 'review',
                        label: 'Review',
                        filterable: true,
                        sortable: false,
                    },
                    {
                        name: 'EXTRA',
                        label: 'Action',
                        type: 'string',
                        filterable: false,
                        sortable: false,
                        render: (row: any) => {
                            return (
                                <Box>
                                    <Tooltip title="Edit">
                                        <IconButton
                                            color="primary"
                                            component="span"
                                            onClick={() => handleEdit(row)}
                                        >
                                            <Edit fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton
                                            color="secondary"
                                            component="span"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            );
                        }
                    },
                ]}
            />
        </>
    )
}

export default UnitReviewList

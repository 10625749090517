import React, { useReducer, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { Grid, ButtonGroup, Button, Theme } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ImageFormPage from '../../_components/_form/ImageForm/Page';

//Header
import MenuBar from '../../_components/MenuBar';

//Page
import Form from './_form/FormComponent';
// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertNumber, inputNumber } from '../../_utils/Helper';

// Interface
import { IFormState, IMapDataObject } from './_interfaces/ListingAdminInterface';
import LoadingScreen from '../../_components/LoadingScreen';
import VideoForm from '../../_components/_form/VideoForm/VideoForm';
import { generateStyle } from '../../_utils/DefaultStyle';

const SwalContainer = withReactContent(Swal);

interface IProps {
    id?: string,
    page?: number
    closeModal?: Function
}

interface ILandLord {
    landlord_name: string | null
    landlord_phone: string | null
    landlord_email: string | null
}

interface IAction {
    name: string,
    value?: object | Date | null | string | number | IMapDataObject | IFormState,
    checkbox?: IChecked
}

interface IChecked {
    [key: string]: string | boolean
    name: string
    checked: boolean
}

const listDataThatNeedToBeCleaned = [
    'sell_price', 'price_per_year', 'property_area', 'property_length', 'property_width',
    'land_area', 'land_length', 'land_width',
    'price_per_6_months', 'price_per_3_months', 'price_per_1_month', 'daily_price'
];

const useStyles = generateStyle((theme: Theme) =>
({
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    borderBottomLine: {
        borderBottom: '1px solid #eee'
    },
    fab: {
        position: 'fixed',
        bottom: '2rem',
        right: '2rem',
        display: 'none',
        zIndex: 1,
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    fabSpacing: {
        marginRight: '1rem',
    },
    container: {
        paddingBottom: '50px'
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    marker: {
        display: "block",
        position: "absolute",
        zIndex: 1,
        marginTop: "150px",
        marginLeft: "190px",
        width: "50px",
        height: "50px",
    },
    submit_btn: {
        borderRadius: "40px",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    }
}), "ListingForm_Admin"
);

export default function ListingFormAdmin(props: IProps) {
    const { Root, classes } = useStyles();
    const location = useLocation();
    const params: any = useParams();
    const navigate = useNavigate();
    const id = props.id || params.id
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const loadingForm = typeof props.id === 'undefined' ? false : true;
    // const [loadingForm, setLoadingForm] = useState(typeof props.id === 'undefined' ? false : true);
    const [type, setType] = useState(typeof params.type !== 'undefined' ? params.type : 'details');

    const handleNavigation = (route: string) => {
        if (route !== type) {
            if (route === 'details') {
                navigate(`/listingAdmin/edit/${params.id}`);
            } else {
                navigate(`/listingAdmin/edit/${params.id}/${route}`);
            }
            setType(route);
        }
    }

    useEffect(() => {
        if (typeof id !== 'undefined') {
            setIsLoading(true);
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/listing-admin/${id}`)
                .then(res => {
                    let data = res.data
                    setInputValue({ name: 'fetched-data', value: data });
                    // setRenderProvince(false);
                    // setListingDetail(data);
                })
                .finally(() => {
                    setIsLoading(false)
                })
            // eslint-disable-next-line
        }
    }, [id])

    const renderProvince = true;
    // const [renderProvince, setRenderProvince] = useState(true);

    // Input State
    const valueReducer = (state: IFormState, action: IAction) => {
        if (action.value != null || action.checkbox !== null) { //Need this or Typescript will complaint value will be possibly null
            if (action.name === 'fetched-data') {
                for (const key in state) {
                    const data_value = (action.value as IFormState)[key];

                    if (typeof data_value !== 'undefined') {
                        if (listDataThatNeedToBeCleaned.includes(key) && data_value !== null) {
                            state[key] = convertNumber(Number(data_value).toString());
                        } else {
                            state[key] = data_value === null ? "" : data_value;
                        }
                    }
                }
                const landlordData = action.value as ILandLord
                state.owner_name = landlordData.landlord_name === null ? '' : landlordData.landlord_name
                state.owner_email = landlordData.landlord_email === null ? '' : landlordData.landlord_email
                state.owner_phone = landlordData.landlord_phone === null ? '' : landlordData.landlord_phone

                return state;
            }

            if (action.name === 'map') {
                return {
                    ...state,
                    latitude: (action.value as IMapDataObject).lat,
                    longitude: (action.value as IMapDataObject).lng
                }
            }


            return {
                ...state,
                [action.name]: action.value
            }
        } else {
            return { ...state };
        }
    };

    const [formState, setInputValue] = useReducer(valueReducer, {
        is_hot: false,
        description: "",
        certificate: "",
        name: "",
        landlord: "",
        landlord_id: "",
        landlord_name: "",
        agent_id: '',
        pic: "",
        pic_name: "",
        listing_type_id: "",
        province_id: "",
        province_name: "",
        city_id: "",
        city_name: "",
        district_id: "",
        district_name: "",
        address: "",
        latitude: -6.17831000,
        longitude: 106.63200000,
        floor: '',
        number: '',
        land_area: '',
        land_length: '',
        land_width: '',
        property_area: '',
        property_length: '',
        property_width: '',
        bedroom: '',
        bathroom: '',
        carport: '',
        price_per_year: '',
        price_per_6_months: '',
        price_per_3_months: '',
        price_per_1_month: '',
        daily_price: '',
        sell_price: '',
        display_currency: 'IDR',
        building_finished: '',
        total_floor: '',
        furnish: '',
        electric_watt: '',
        view: '',
        direction: '',
        facilities: new Map(),
        notes: ''
    });


    const handleInputChange = (e: any) => {

        if (e.target.name && e.target.name === 'is_hot') {
            setInputValue({ name: e.target.name, value: e.target.checked });
        } else if (e.target.name === 'floor') {
            setInputValue({ name: e.target.name, value: convertNumber(e.target.value) });
        } else {
            setInputValue({ name: e.target.name, value: e.target.value });
        }
        if (e.target.name.includes('_area') || e.target.name.includes('_length') || e.target.name.includes('_width') || e.target.name === 'carport') {
            setInputValue({ name: e.target.name, value: convertNumber(e.target.value) });
        } else {
            setInputValue({ name: e.target.name, value: e.target.value });
        }
        if (e.target.name.includes('cost') || e.target.name.includes('price')) {
            setInputValue({ name: e.target.name, value: convertNumber(e.target.value) });
        } else {
            setInputValue({ name: e.target.name, value: e.target.value });
        }
        if (e.target.type === 'checkbox') {
            setInputValue({ name: 'facilities', checkbox: { name: e.target.name, checked: e.target.checked } })
        } else if (e.target.name === 'total_floor' || e.target.name === 'building_finished') {
            setInputValue({ name: e.target.name, value: inputNumber(e.target.value) });
        } else {
            setInputValue({ name: e.target.name, value: e.target.value });
        }

    }

    const handleSubmit = () => {
        setIsLoading(true)
        // FIXME: commission yang dikirim masih salah satunya
        const datas_to_submit = { ...formState };

        if (typeof id === 'undefined' || '') {
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/listing-admin`, { ...datas_to_submit })
                .then(res => {
                    Swal.fire({
                        title: "Listing Submitted",
                        icon: 'success',
                        timer: 1000
                    })
                        .then(res => {
                            setIsLoading(false)
                            navigate('/listingAdmin')
                        })
                })
                .catch(error => {
                    if (typeof error.response.status === 'undefined') {
                        SwalContainer.fire({
                            title: "Script Error",
                            html: "Error pada script. Harap hubungi Admin",
                            icon: 'error',
                        });
                    } else if (error.response.status === 422) {
                        let error_text = `<p>${error.response.data.message}</p>`;

                        if (typeof error.response.data.errors !== 'undefined') {
                            const error_lists = error.response.data.errors;
                            for (var k in error_lists) {
                                error_text += `<p>${error_lists[k]}</p>`;
                            }
                        }

                        SwalContainer.fire({
                            title: "Validasi Error",
                            html: error_text,
                            icon: 'error',
                        });
                    } else if (error.response.status === 500) {
                        SwalContainer.fire({
                            title: "Server Error",
                            html: "Error pada server. Harap hubungi Admin",
                            icon: 'error',
                        });
                    }
                    setIsLoading(false)
                })
        } else {
            DefaultAxios.put(`${process.env.REACT_APP_API_URL}/listing-admin/${id}`, { ...datas_to_submit })
                .then(res => {
                    Swal.fire({
                        title: "Listing Updated",
                        icon: 'success',
                        timer: 1000
                    })
                        .then(res => {
                            setIsLoading(false)
                            window.location.reload();
                        })
                })
                .catch(error => {
                    if (typeof error.response.status === 'undefined') {
                        SwalContainer.fire({
                            title: "Script Error",
                            html: "Error pada script. Harap hubungi Admin",
                            icon: 'error',
                        });
                    } else if (error.response.status === 422) {
                        let error_text = `<p>${error.response.data.message}</p>`;

                        if (typeof error.response.data.errors !== 'undefined') {
                            const error_lists = error.response.data.errors;
                            for (var k in error_lists) {
                                error_text += `<p>${error_lists[k]}</p>`;
                            }
                        }

                        SwalContainer.fire({
                            title: "Validasi Error",
                            html: error_text,
                            icon: 'error',
                        });
                    } else if (error.response.status === 500) {
                        SwalContainer.fire({
                            title: "Server Error",
                            html: "Error pada server. Harap hubungi Admin",
                            icon: 'error',
                        });
                    }
                    setIsLoading(false)
                })
        }
    }

    const renderView = () => {
        if (typeof params.id) {
            if (type === 'details') {
                return <Form
                    render_province={renderProvince}
                    states={formState}
                    setInputValue={setInputValue}
                    handleSubmit={handleSubmit}
                    handleInputChange={handleInputChange}
                />
            } else if (type === 'image') {
                return <ImageFormPage
                    API_URL={`${process.env.REACT_APP_API_URL}/listing-admin/${params.id}/image`}
                    type="listing"
                />
            } else if (type === 'video') {
                return (
                    <VideoForm
                        API_URL={`${process.env.REACT_APP_API_URL}/listing/${params.id}/videos`}
                    />
                )
            }
        } else {
            return <Form
                render_province={renderProvince}
                states={formState}
                setInputValue={setInputValue}
                handleSubmit={handleSubmit}
                handleInputChange={handleInputChange}
            />
        }
    }

    if (loadingForm) {
        return (
            <Root>
                <div className={classes.root}></div>
            </Root>
        )
    } else {
        return (
            <MenuBar title={typeof params.id !== "undefined" ? 'Edit Listing' : 'Add Listing'}>
                <Root>
                    <div className={classes.root}>
                        <Grid container>
                            {
                                location.pathname !== '/listingAdmin/add'
                                    ? <Grid item xs={12} style={{ marginBottom: '30px' }}>
                                        <ButtonGroup
                                            color="primary"
                                            aria-label="outlined primary button group"
                                            fullWidth
                                        >
                                            <Button
                                                variant={type === 'details' ? 'contained' : 'outlined'}
                                                onClick={() => handleNavigation('details')}
                                            >
                                                Details
                                            </Button>
                                            <Button
                                                variant={type === 'image' ? 'contained' : 'outlined'}
                                                onClick={() => handleNavigation('image')}
                                            >
                                                Images
                                            </Button>
                                            <Button
                                                variant={type === 'video' ? 'contained' : 'outlined'}
                                                onClick={() => handleNavigation('video')}
                                            >
                                                Videos
                                            </Button>
                                            {/* <Button
                                        variant={type === '360-photo' ? 'contained' : 'outlined'}
                                    // onClick={() => handleNavigation('360-photo')}
                                    >
                                        360 Photo
                                    </Button> */}
                                        </ButtonGroup>
                                    </Grid>
                                    : null
                            }
                            {renderView()}

                            {
                                isLoading &&
                                <LoadingScreen open={isLoading} fullScreen={true} />
                            }

                            {/* Debugging Only */}
                            {/* <button onClick={() => changePage(currentPage + 1)}>bypass</button> */}
                        </Grid>
                    </div>
                </Root>
            </MenuBar>
        )
    }
}
import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { Button, Theme, Link, Grid, TextField } from '@mui/material';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import PICModalDetail from './PICModalDetail';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';

/**
 * Utils
 */
import { PermissionContext } from '../../_contexts/PermissionContext';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IParams extends Record<string, string | undefined> {
    id?: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    addButton: {
        marginBottom: '20px'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    searchButton: {
        marginLeft: 10
    }
}), "PIC_List"
)

const PICList = () => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const param = useParams<IParams>()
    const navigate = useNavigate()
    const DT_API_URL = `${process.env.REACT_APP_API_URL}/pic-landlord`;

    const [picModal, setPICModal] = useState({
        modal: false,
        id: ''
    })

    const openPICModal = (row: any) => {
        setPICModal({
            modal: true,
            id: row.id
        })
    }

    const columns = [
        {
            name: 'name', label: 'Name', filterable: true, render: (data: any, row: any) => {
                return (
                    <Link
                        style={{ cursor: "pointer" }}
                        component={RouterLink}
                        to={`/pic-landlord/${row.id}`}
                        onClick={() => { openPICModal(row) }
                        }
                    >
                        {data}
                    </Link>
                );
            }
        },
        { name: 'phone', label: 'No. HP', filterable: true },
        { name: 'assigned_pic_label', label: 'Assigned PIC', filterable: true }
    ]

    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [search, setSearch] = useState('')

    const handleChangeSearch = (e: any) => {
        setSearch(prev => e.target.value)
    }

    const handleClosePICModal = (refresh: boolean) => {
        setPICModal({
            modal: false,
            id: ''
        })
        if (refresh) {
            setReDrawDatatable(new Date().getTime())
        }
        navigate('/pic-landlord')
    }

    const handleSearchPIC = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/pic-landlord/search-data`, {
            params: {
                search: search
            }
        })
            .then(res => res.data)
            .then(res => {
                if (res) {
                    setPICModal({
                        modal: true,
                        id: res
                    })
                }
            })
            .catch(err => generalErrorHandler(err))

    }

    useEffect(() => {
        if (param.id !== undefined) {
            setPICModal({
                modal: true,
                id: param.id
            })
        }
    }, [param])

    return (
        <Root>
            <PICModalDetail
                open={picModal.modal}
                onClose={handleClosePICModal}
                picId={picModal.id}
            />

            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => navigate('/pic-landlord/add')}
            >
                Add PIC
            </Button>

            <Grid container spacing={3} style={{ marginBottom: '10px' }}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Search PIC Landlord"
                        value={search}
                        placeholder="Masukan No HP PIC"
                        onChange={handleChangeSearch}
                    />
                </Grid>
                <Grid item xs={6} className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearchPIC}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>


            {permissions['pic-landlord.list'] &&
                <DataTable
                    reDraw={reDrawDatatable}
                    url={DT_API_URL}
                    columns={columns}
                />
            }
        </Root>
    );
}

export default PICList;
import React, { Fragment } from 'react'

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContentText, Theme } from '@mui/material';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) => ({
  dialog: {
    borderRadius: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  table_entry: {
    fontSize: '16px',
    color: '#707070',
    border: 'none'
  },
  dialog_title: {
    fontSize: '28px',
    color: '#707070',
    paddingLeft: '16px'
  },
  bold: {
    fontWeight: 600
  }
}), "AptDialong"
);

const AptDialog = (props: any) => {
  const { Root, classes } = useStyles()
  const tableHead = ['Jenis Unit', 'Session', 'Demand', 'Availability', 'Unit Needed']
  const tableData = ['session', 'demand', 'available', 'unit_needed']
  const roomType = ['STUDIO', '1BR', '2BR', '3BR']
  const roomLabel = ['Studio', '1 BR', '2 BR', '3 BR']

  const renderTable = () => {
    let rows = [<Fragment key={0}></Fragment>]
    roomType.map((type: string, idx) => {
      if (typeof props.data[props.selected] !== 'undefined' && typeof props.data[props.selected][type] !== 'undefined') {
        rows.push(
          <TableRow key={type + idx}>
            <TableCell className={`${classes.table_entry} ${classes.bold}`}>{roomLabel[idx]}</TableCell>
            {renderRow(props.data[props.selected][type], type)}
          </TableRow>
        )
      }
      return ''
    })
    return rows
  }
  const renderRow = (data: any, name: string) => {
    let entry = [<Fragment key={''}></Fragment>];
    tableData.map((val: any, idx) => {
      if (val !== 'unit_needed') {
        entry.push(
          <TableCell key={name + idx} align={'center'} className={classes.table_entry}>{data[val]}</TableCell>
        )
      } else {
        let demand = 0;
        if (data.demand > data.available) {
          demand = data.demand - data.available
        }
        entry.push(
          <TableCell key={name + idx} align={'center'} className={`${classes.table_entry} ${classes.bold}`}>{demand}</TableCell>
        )
      }
      return ''
    })
    return entry
  }
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='md'
      className={classes.dialog}
    >
      <Root>
        <DialogTitle id="alert-dialog-title">
          <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '10px 55px 70px 55px' }}>
          <DialogContentText className={classes.dialog_title}>
            {props.selected}
          </DialogContentText>
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  {tableHead.map((title: string, idx) => {
                    return (
                      <TableCell className={classes.table_entry} align={title === 'Jenis Unit' ? ('left') : ('center')} key={idx}>{title}</TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTable()}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Root>
    </Dialog>
  )
}

export default AptDialog;
import React from 'react'
import { Typography } from '@mui/material'

interface BasicUploadFormProps {
    label: string
    name: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    errorText?: string
    accept?: string
    disabled?: boolean
}

const BasicUploadForm = (props: BasicUploadFormProps) => {
    return (
        <>
            <Typography
                variant='subtitle2'
                component='p'
                sx={{ mb: 1.5 }}
            >
                {props.label}
            </Typography>
            <input
                type='file'
                name={props.name}
                onChange={props.onChange}
                accept={props.accept}
                disabled={props.disabled}
            />
            {
                props.errorText ?
                    <Typography
                        style={{
                            color: '#d32f2f',
                            fontSize: 12,
                            marginTop: 4,
                        }}
                    >
                        {props.errorText}
                    </Typography>
                    : null
            }
        </>
    )
}

export default BasicUploadForm

import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import LoadingScreen from '../../../_components/LoadingScreen'
import ImagePreview from '../../../_components/ImagePreview'

/**
 * Utils
 */
import { generateStyle } from '../../../_utils/DefaultStyle'
import { JHSScheduleSchedule } from './JHSScheduleCard'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper'
import { format } from 'date-fns'

interface DoneDetailListModalProps {
    isOpen: boolean
    onClose: () => void
    data: JHSScheduleSchedule | null
}

type State = {
    done_at: string
    files: Item[]
}

type Item = {
    file_url: string
}

const DoneDetailListModal = (props: DoneDetailListModalProps) => {
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(true)

    const [state, setState] = useState<State>(INITIAL_STATE)
    const [selectedImage, setSelectedImage] = useState('')

    useEffect(() => {
        if (props.isOpen && props.data?.activity_id) {
            loadData()
        } else {
            setState(INITIAL_STATE)
        }
        // eslint-disable-next-line
    }, [props.isOpen, props.data])

    const loadData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/schedule-overview-done-detail`, { params: { id: props.data?.id } })
            .then(res => res.data)
            .then((data: any) => {
                setState(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handlePreview = (url: string) => {
        const explode = url.split('?');
        const match = /(\.\w+$)/g.exec(explode[0]);
        let isImage = false

        if (match) {
            if (match[1] === '.pdf' || match[1] === '.PDF') {
                // PDF File
            } else if (match[1] === '.doc' || match[1] === '.docx') {
                // DOC File
            } else if (['.flv', '.mp4', '.m3u8', '.ts', '.3gp', '.mkv', '.mov', '.avi', '.wmv'].includes(match[1])) {
                // Video File
            } else {
                // Image File
                isImage = true;
            }
        }

        if (isImage) {
            setSelectedImage(url)
        } else {
            window.open(url)
        }
    }

    return (
        <>
            <ImagePreview
                image={selectedImage}
                onClose={() => setSelectedImage('')}
            />
            <Dialog
                open={props.isOpen}
                onClose={props.onClose}
                scroll="body"
                fullWidth
                maxWidth="xs"
            >
                <Root>
                    <LoadingScreen fullScreen open={isLoading} />
                    <DialogTitle>
                        Done At: {state.done_at ? format(new Date(state.done_at), 'dd MMMM yyyy') : '-'}
                    </DialogTitle>
                    <DialogContent>
                        <div className={classes.jobDetailListRoot}>
                            <Typography variant='subtitle1' sx={{ mb: 2, fontWeight: '500' }}>
                                List Files
                            </Typography>
                            {
                                state.files.length > 0 ?
                                    state.files.map((file, index) =>
                                        <div
                                            key={index}
                                            className={classes.jobDetailList}
                                            onClick={() => handlePreview(file.file_url)}
                                        >
                                            <span className={classes.fileName}>
                                                {'File ' + (index + 1)}
                                            </span>
                                        </div>
                                    )
                                    :
                                    <div style={{ textAlign: 'center' }}>
                                        <span>Tidak ada file</span>
                                    </div>
                            }
                        </div>
                    </DialogContent>
                </Root>
            </Dialog>
        </>
    )
}

const useStyles = generateStyle(() => ({
    jobDetailList: {
        padding: 12,
        borderRadius: 5,
        border: '1px solid lightgray ',
        marginBottom: 12,
        textAlign: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-word',
        cursor: 'pointer',
    },
}), "DoneDetailListModal")

const INITIAL_STATE: State = {
    done_at: '',
    files: [],
}

export default DoneDetailListModal

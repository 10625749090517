import React, { useEffect, useState } from 'react'
import { AppBar, Button, Dialog, DialogContent, Grid, IconButton, Slide, Toolbar, Typography, Divider } from '@mui/material'

/* 
* Component
*/
import NoteModal from './NoteModal';
import StatusModal from './StatusModal';
import { ILeadAdvisor } from '../DashboardPM';
import ActivityItem from './ActivityItem';
import { TransitionProps } from '@mui/material/transitions';

/* 
* Urils
*/
import { generalErrorHandler, renderToastSuccess } from '../../../_utils/Helper';
import DefaultAxios from '../../../_utils/DefaultAxios';

/* 
* Icons
*/
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { generateStyle } from '../../../_utils/DefaultStyle';

/* 
* interfaces
*/
interface IProps {
    open: boolean,
    onClose: (refetch: boolean) => void
    id: number
}

export type DashboardLeadItemType = '' | 'greeting' | 'fu' | 'showing' | 'kyc' | 'closing' | 'fail'

export const typeBackgroundColor = (type: DashboardLeadItemType) => {
    switch (type) {
        case 'closing': return '#3BA8C5'
        case 'showing': return '#343434'
        case 'fail': return '#EF8181'
        case 'fu': return '#035AA6'
        case 'greeting': return '#A6CE9E'
        case 'kyc': return '#F0A456'
        default: return '#A6CE9E'
    }
}



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DetailItems = (
    { length, label, children, sx }:
        { length: number, label: string, children: React.ReactChild, sx?: any }
) => {
    return (
        <Grid item xs={length} sx={sx}>
            <Typography variant='subtitle1' fontWeight="bold">{label}</Typography>
            {
                children
            }
        </Grid>
    )
}

const DetailEnquiryModal = (props: IProps) => {
    const { Root, classes } = useStyles()
    const [state, setState] = useState<ILeadAdvisor>(initialState)
    const [statusModal, setStatusModal] = useState(false)
    const [noteModal, setNoteModal] = useState(false)
    const [hasEdited, setHasEdited] = useState(false)

    const fetchData = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/enquiry/${props.id}`)
            .then((res) => setState(res.data))
            .catch(err => generalErrorHandler(err))
    }

    useEffect(() => {
        if (props.id !== 0 && props.open) {
            fetchData()
        }
        if (!props.open) {
            setHasEdited(false)
            setState(initialState)
        }
        // eslint-disable-next-line
    }, [props.open])

    const refetch = (value: string) => {
        fetchData()
        setHasEdited(true)
        renderToastSuccess(`${value} Updated!`)
    }

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(hasEdited)}
            fullScreen
            TransitionComponent={Transition}
        >
            <Root>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => props.onClose(hasEdited)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {state.customer_name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container>
                        <DetailItems
                            length={5}
                            label="STATUS"
                        >
                            <Button
                                variant='contained'
                                onClick={() => setStatusModal(true)}
                                style={{ background: typeBackgroundColor(state.status_value as DashboardLeadItemType) }}
                            >
                                {state.status}
                            </Button>

                        </DetailItems>
                        <DetailItems
                            length={7}
                            label="APARTMENT"
                        >
                            <>
                                <Typography>
                                    {state.apartment_name}
                                </Typography>
                                {
                                    state.unit_code &&
                                    state.unit_code.split(",").map((unitCode: string, idx: number) => (
                                        <Typography variant='subtitle2' key={idx} component="div" alignItems="center" display="flex">
                                            {unitCode}
                                            <IconButton
                                                style={{ padding: 0, margin: 0, marginLeft: '5px' }}
                                                size='small'
                                                onClick={() => {
                                                    window.open(`https://jendela360.com/${unitCode}`)
                                                }}
                                            >
                                                <VisibilityIcon
                                                    fontSize="small"
                                                />
                                            </IconButton>
                                        </Typography>
                                    ))
                                }
                            </>
                        </DetailItems>
                        <Divider variant='fullWidth' orientation='horizontal' />
                        <DetailItems
                            sx={{ mt: 3 }}
                            length={12}
                            label="DURATION"
                        >
                            <Typography>
                                {state.estimated_rent_period ? `${state.estimated_rent_period} Months` : '-'}
                            </Typography>
                        </DetailItems>
                        <DetailItems
                            sx={{ mt: 3 }}
                            length={12}
                            label="NOTE"
                        >
                            <Grid container display="flex" alignItems="center">
                                <Grid item xs={10}>
                                    <Typography>{state.keterangan}</Typography>
                                </Grid>
                                <Grid item xs={2} justifyContent="flex-end" display="flex">
                                    <IconButton
                                        onClick={() => setNoteModal(true)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DetailItems>
                        <DetailItems
                            sx={{ mt: 3, mb: 10 }}
                            length={12}
                            label="TIMELINE"
                        >
                            <>
                                {
                                    state.activities.length > 0 ?
                                        state.activities.map((activity, id) => (
                                            <ActivityItem activity={activity} key={id} />
                                        )) :
                                        <Typography variant="subtitle2">No Timeline found!</Typography>
                                }
                            </>
                        </DetailItems>
                        <Grid item xs={12} className={classes.footer}>
                            <Button
                                className={classes.actionButton}
                                variant='contained'
                                startIcon={<CallIcon />}
                                onClick={() => window.location.href = `tel:${state.customer_phone}`}
                            >
                                Call
                            </Button>
                            <Button
                                className={classes.actionButton}
                                variant="contained"
                                startIcon={<WhatsAppIcon />}
                                onClick={() => window.open(`https://wa.me/${state.customer_phone}`, '_blank')}
                            >
                                Whatsapp
                            </Button>
                            {/* <Fab
                            size='medium'
                            className={classes.actionButton}
                            onClick={() => window.location.href = `tel:${state.customer_phone}`}
                        >
                            <CallIcon
                                fontSize='medium'
                            />
                        </Fab>
                        <Fab
                            className={classes.actionButton}
                            size='medium'
                            onClick={() => window.open(`https://wa.me/${state.customer_phone}`, '_blank')}
                        >
                            <WhatsAppIcon
                                fontSize='medium'
                            />
                        </Fab> */}
                        </Grid>
                    </Grid>
                    {
                        statusModal &&
                        <StatusModal
                            id={state.id}
                            open={statusModal}
                            onClose={() => setStatusModal(false)}
                            statusChanged={() => refetch("Status")}
                        />
                    }

                    {
                        noteModal &&
                        <NoteModal
                            initialNote={state.keterangan}
                            id={state.id}
                            open={noteModal}
                            onClose={() => setNoteModal(false)}
                            noteChanged={() => refetch("Note")}
                        />
                    }
                </DialogContent>
            </Root>
        </Dialog>

    )
}

export default DetailEnquiryModal

const useStyles = generateStyle(theme => ({
    footer: {
        background: 'white',
        position: 'fixed',
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        padding: 10,
        gap: 10,
        alignItems: 'center',
        width: '100%'
    },
    actionButton: {
        flex: 1
    }
}), "DetailEnquiry_Modal")

const initialState = {
    activities: [],
    apartment_name: "",
    budget: "",
    budget_currency: "",
    created_at: "",
    customer_email: "",
    customer_name: "",
    customer_phone: "",
    estimated_rent_period: "",
    id: 0,
    is_buy: 0,
    keterangan: "",
    status: '',
    unit_code: "",
    code: "",
    status_value: '',
    failed_reason: '',
    date: '',
    notes: '',
    recent_followup: null,
    followup_overdue: false,
}
import React, { useEffect, useState, useRef, RefObject } from 'react';
import { Grid, FormHelperText, Divider, Button, Switch, Typography, TextField, Theme } from '@mui/material';
import DefaultAxios from '../../../_utils/DefaultAxios';

import RoomIcon from '@mui/icons-material/Room';

import GoogleMapReact from 'google-map-react';

// Interface
import { IPageOneState, IMapDataObject } from '../_interfaces/ListingInterface';
import { IListPlace } from '../../_interfaces/FormInterfaces';

import { validateEmail } from '../../../_utils/Helper';
import Autocomplete from '@mui/lab/Autocomplete';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface IProps {
    render_province: boolean;
    states: IPageOneState,
    setInputValue: Function,
    handleInputChange: Function,
    changePage: Function
}

interface IListObject {
    id: number,
    label: string
}

interface setMapData {
    mapApiLoaded: boolean
    mapInstances: google.maps.Map | null
    mapApi: null | object
    address: string
    open: boolean
}

interface IError {
    [key: string]: string
}

interface IRefs {
    [key: string]: RefObject<HTMLInputElement>
}

let cache_cities: Array<Array<IListPlace>> = []; //NOTE: cache_cities itu isinya array dari data yang udah di fetch, makanya interface Arraynya dua kali
let cache_districts: Array<Array<IListPlace>> = []; //NOTE: ini juga sama

const useStyles = generateStyle((theme: Theme) =>
({
    hotListingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1rem',
        '& h2': {
            color: '#363636',
            fontSize: 16,
            fontWeight: 500
        }
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    markerContainer: {
        display: 'flex',
        position: 'absolute',
        left: 0,
        right: 0,
        top: '50%',
        margin: 'auto',
        width: 'fit-content',
        transform: 'translateY(-50px)',
        zIndex: 1
    },
    marker: {
        // display: "block",
        // position: "absolute",
        zIndex: 1,
        // marginTop: "150px",
        // marginLeft: "190px",
        // width: "50px",
        // height: "50px",
        fontSize: 50
    },
    submit_btn: {
        borderRadius: "40px",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    twoInput: {
        display: 'flex'
    },
    twoInputLeft: {
        marginRight: '0.5rem'
    },
    twoInputRight: {
        marginLeft: '0.5rem'
    }
}), "Page_One"
);

export default function PageOne(props: IProps) {
    const { Root, classes } = useStyles();

    const refs: IRefs = {
        owner_name: useRef<HTMLInputElement>(null),
        owner_phone: useRef<HTMLInputElement>(null),
        owner_email: useRef<HTMLInputElement>(null),
        listing_type_id: useRef<HTMLInputElement>(null),
        province_id: useRef<HTMLInputElement>(null),
        city_id: useRef<HTMLInputElement>(null),
        district_id: useRef<HTMLInputElement>(null),
        address: useRef<HTMLInputElement>(null),
        apartment_id: useRef<HTMLInputElement>(null),
        tower: useRef<HTMLInputElement>(null),
        floor: useRef<HTMLInputElement>(null),
        number: useRef<HTMLInputElement>(null)
    }

    const scrollToRef = (ref: RefObject<HTMLInputElement>) => {
        window.scrollTo(0, ref.current!.offsetTop - 64)
    }

    // Error State
    const [errorList, setErrorList] = useState<any>({});

    // Change
    const changeInput = (e: any) => {
        props.handleInputChange(e);

        let newErrorList = errorList;

        delete newErrorList[e.target.name];
        setErrorList(newErrorList);
    }

    //List States
    const [areaAutoCompleteLoading, setAACL] = useState(props.render_province);
    const [apartmentAutoCompleteLoading, setAptACL] = useState(true)
    const [listListingTypes, setListingTypes] = useState<Array<IListObject>>([]);
    const [provinceList, setProvinceList] = useState<Array<IListPlace>>([]);
    const [cityList, setCityList] = useState<Array<IListPlace>>([]);
    const [districtList, setDistrictList] = useState<Array<IListPlace>>([]);
    const [apartmentList, setApartmentList] = useState<Array<IListObject>>([])

    const handleProvinceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setInputValue({ name: 'province_id', value: e.target.value });

        let newErrorList = errorList;

        delete newErrorList[e.target.name];

        if (e.target.value === "") {
            resetCityList();
        } else {
            let province_id: number = Number(e.target.value); //Explicitly set as number so Typescript will shut up

            if (province_id in cache_cities) {
                setCityList(cache_cities[province_id]);
            } else {
                resetCityList();
                setAACL(true);

                DefaultAxios.get(`${process.env.REACT_APP_API_URL}/admin/autocomplete/listing-form/area`, { params: { province_id: province_id } })
                    .then(res => {
                        let list_of_cities = res.data;
                        cache_cities[province_id] = list_of_cities;
                        reCenterMap('province', (province_id - 1)); // minus 1 to make it the same as the array key
                        setCityList(list_of_cities);
                        setAACL(false);
                    });
            }
        }
    }

    const resetCityList = () => {
        props.setInputValue({ name: 'city_id', value: "" });
        setCityList([]);
    }

    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setInputValue({ name: 'city_id', value: e.target.value });

        let newErrorList = errorList;

        delete newErrorList[e.target.name];

        if (e.target.value === "") {
            resetDistrictList();
        } else {
            let city_id: number = Number(e.target.value); // Explicitly set as number so Typescript will shut up

            if (city_id in cache_districts) {
                setDistrictList(cache_districts[city_id]);
                reCenterMap('city', city_id);
            } else {
                resetDistrictList();
                setAACL(true);

                DefaultAxios.get(`${process.env.REACT_APP_API_URL}/admin/autocomplete/listing-form/district`, { params: { city_id: city_id } })
                    .then(res => {
                        let list_of_cities = res.data;
                        cache_districts[city_id] = list_of_cities;

                        setDistrictList(list_of_cities);
                        reCenterMap('city', city_id);
                        setAACL(false);
                    });
            }
        }
    }

    const resetDistrictList = () => {
        props.setInputValue({ name: 'district_id', value: "" });
        setDistrictList([]);
    }

    const handleDistrictChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setInputValue({ name: e.target.name, value: e.target.value });

        let newErrorList = errorList;

        delete newErrorList[e.target.name];

        reCenterMap('district', Number(e.target.value));
    }

    const changeMap = (positionData: IMapDataObject) => {
        if (positionData.lat !== 0 && positionData.lng !== 0) {
            props.setInputValue({
                name: 'map',
                value: positionData
            });
        }
    }

    const reCenterMap = (type: string, id_to_search: number) => {
        if (type === 'province') {
            changeMap({ lat: Number(provinceList[id_to_search].latitude), lng: Number(provinceList[id_to_search].longitude) })
        } else if (type === 'city') {
            for (let index = 0; index < cityList.length; ++index) {
                if (cityList[index].id === id_to_search) {
                    changeMap({ lat: Number(cityList[index].latitude), lng: Number(cityList[index].longitude) })
                    break;
                }
            }
        } else if (type === 'district') {
            for (let index = 0; index < districtList.length; ++index) {
                if (districtList[index].id === id_to_search) {
                    changeMap({ lat: Number(districtList[index].latitude), lng: Number(districtList[index].longitude) })
                    break;
                }
            }
        } else if (type === 'reset_form') {
            changeMap({ lat: props.states.latitude, lng: props.states.longitude })
        }
    }

    const handleApartmentChange = (e: any, newValue: IListObject | null) => {
        if (newValue) {
            props.setInputValue({ name: 'apartment_id', value: newValue.id.toString() });
            props.setInputValue({ name: 'apartment_name', value: newValue.label.toString() });
        } else {
            props.setInputValue({ name: 'apartment_id', value: '' });
            props.setInputValue({ name: 'apartment_name', value: '' });
        }

        let newErrorList = errorList;

        delete newErrorList['apartment_id'];
        setErrorList(newErrorList);
    }

    useEffect(() => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/listing/form-data`)
            .then(res => {
                setListingTypes(res.data.types);
                setProvinceList(res.data.provinces);
                setAACL(false);

                if (props.render_province === false) {
                    const province_id = Number(props.states.province_id);

                    DefaultAxios.get(`${process.env.REACT_APP_API_URL}/admin/autocomplete/listing-form/area`, { params: { province_id: province_id } })
                        .then(res => {
                            let list_of_cities = res.data;
                            cache_cities[province_id] = list_of_cities;
                            setCityList(list_of_cities);
                            return true;
                        }).then(res => {
                            const city_id = Number(props.states.city_id);

                            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/admin/autocomplete/listing-form/district`, { params: { city_id: city_id } })
                                .then(res => {
                                    let list_of_cities = res.data;
                                    cache_districts[city_id] = list_of_cities;

                                    setDistrictList(list_of_cities);
                                })
                                .then(res => {
                                    reCenterMap('direct', 1);
                                })
                        })
                }
            });
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/apartment`)
            .then(res => res.data)
            .then(res => {
                setApartmentList(res)
                setAptACL(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Map State
    const [mapData, setMapData] = useState<setMapData>({
        mapApiLoaded: false,
        mapInstances: null,
        mapApi: null,
        address: '',
        open: true
    });

    useEffect(() => {
        if (mapData.mapInstances) {
            mapData.mapInstances!.addListener('dragend', () => {
                changeMap({
                    lat: mapData.mapInstances!.getCenter().lat(),
                    lng: mapData.mapInstances!.getCenter().lng()
                })
            })
            mapData.mapInstances!.addListener('zoom_changed', () => {
                changeMap({
                    lat: mapData.mapInstances!.getCenter().lat(),
                    lng: mapData.mapInstances!.getCenter().lng()
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapData.mapInstances])

    const handleApiLoaded = (map: google.maps.Map, maps: object) => {
        setMapData({
            ...mapData,
            mapApiLoaded: true,
            mapInstances: map,
            mapApi: maps
        })
    }

    const validateData = () => {
        let listOfErrors: IError = {};
        let firstError = ''

        for (const key in props.states) {
            const data_value = (props.states as IPageOneState)[key];

            if (key === 'apartment_id' || key === 'floor' || key === 'number' || key === 'apartment_name' || key === 'tower') {
                if (parseInt(props.states.listing_type_id) !== 1) {
                    continue
                }
            }

            if (data_value === "" && key !== 'owner_email') {
                if (firstError === '') firstError = key
                listOfErrors[key] = 'Harap isi form ini';
            }

            if (key === 'owner_phone') {
                if (data_value.toString().length < 10 || data_value.toString().length > 25) {
                    if (firstError === '') firstError = key
                    listOfErrors[key] = 'Nomor Telpon harus diisi antara 10 - 25 angka.';
                }
            }

            if (key === 'owner_email') {
                if (data_value === "" || !validateEmail(data_value.toString())) {
                    if (firstError === '') firstError = key
                    listOfErrors[key] = 'Harap isi email yang valid.';
                }
            }
        }

        if (firstError === 'apartment_name') firstError = 'apartment_id'
        if (firstError !== '') scrollToRef(refs[firstError])
        if (Object.keys(listOfErrors).length === 0 && listOfErrors.constructor === Object) {
            props.changePage(2);
        } else {
            setErrorList(listOfErrors);
        }
    }

    return (
        <Root>
            <div className={classes.hotListingContainer}>
                <div>
                    <h2>Hot Listing</h2>
                    <FormHelperText>Listing yang ditandai hot akan lebih mudah ditemukan agent lain cobroke. hanya top 20% dari listing anda yang bisa ditandai hot.</FormHelperText>
                </div>
                <Switch
                    checked={props.states.is_hot}
                    onChange={(e: any) => props.handleInputChange(e)}
                    name="is_hot"
                />
            </div>

            <TextField
                label="Nama Pemilik"
                name="owner_name"
                variant="outlined"
                value={props.states.owner_name}
                onChange={changeInput}
                fullWidth
                error={errorList.owner_name ? true : false}
                helperText={errorList.owner_name}
                ref={refs.owner_name}
            />

            <TextField
                label="Nomor Handphone Pemilik"
                name="owner_phone"
                variant="outlined"
                value={props.states.owner_phone}
                onChange={changeInput}
                fullWidth
                error={errorList.owner_phone ? true : false}
                helperText={errorList.owner_phone}
                ref={refs.owner_phone}
            />

            <TextField
                label="Email Pemilik"
                name="owner_email"
                variant="outlined"
                value={props.states.owner_email}
                onChange={changeInput}
                fullWidth
                error={errorList.owner_email ? true : false}
                helperText={errorList.owner_email}
                ref={refs.owner_email}
            />

            <TextField
                label="Jenis Properti"
                name="listing_type_id"
                value={props.states.listing_type_id}
                onChange={changeInput}
                error={errorList.listing_type_id ? true : false}
                helperText={errorList.listing_type_id}
                variant="outlined"
                select
                fullWidth
                SelectProps={{
                    native: true,
                }}
                InputLabelProps={{
                    shrink: true
                }}
                ref={refs.listing_type_id}
            >
                <option value="" key="default" disabled>Pilih Jenis Properti</option>
                {listListingTypes.map(data => {
                    return (
                        <option value={data.id} key={data.id}>{data.label}</option>
                    )
                })}
            </TextField>

            <Divider className={classes.divider}></Divider>

            <Typography variant="subtitle1" gutterBottom>
                Dimana lokasi properti anda?
            </Typography>

            <TextField
                label="Provinsi"
                name="province_id"
                value={props.states.province_id}
                onChange={handleProvinceChange}
                disabled={areaAutoCompleteLoading ? true : false}
                error={errorList.province_id ? true : false}
                helperText={errorList.province_id}
                variant="outlined"
                select
                fullWidth
                SelectProps={{
                    native: true,
                }}
                InputLabelProps={{
                    shrink: true
                }}
                ref={refs.province_id}
            >
                <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                {provinceList.map(data => {
                    return (
                        <option value={data.id} key={data.id}>{data.label}</option>
                    )
                })}
            </TextField>

            <TextField
                label="Kota"
                name="city_id"
                value={props.states.city_id}
                onChange={handleCityChange}
                disabled={areaAutoCompleteLoading ? true : (props.states.province_id ? false : true)}
                error={errorList.city_id ? true : false}
                helperText={errorList.city_id}
                variant="outlined"
                select
                fullWidth
                SelectProps={{
                    native: true,
                }}
                InputLabelProps={{
                    shrink: true
                }}
                ref={refs.city_id}
            >
                <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                {cityList.map(data => {
                    return (
                        <option value={data.id} key={data.id}>{data.label}</option>
                    )
                })}
            </TextField>

            <TextField
                label="Area"
                name="district_id"
                value={props.states.district_id}
                onChange={handleDistrictChange}
                disabled={areaAutoCompleteLoading ? true : (props.states.city_id ? false : true)}
                error={errorList.district_id ? true : false}
                helperText={errorList.district_id}
                variant="outlined"
                select
                fullWidth
                SelectProps={{
                    native: true,
                }}
                InputLabelProps={{
                    shrink: true
                }}
                ref={refs.district_id}
            >
                <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                {districtList.map(data => {
                    return (
                        <option value={data.id} key={data.id}>{data.label}</option>
                    )
                })}
            </TextField>

            {parseInt(props.states.listing_type_id) === 1 ?
                <>
                    <Autocomplete
                        options={apartmentList}
                        getOptionLabel={option => option.label}
                        renderInput=
                        {
                            params => <TextField {...params}
                                label="Nama Apartment"
                                variant="outlined"
                                fullWidth
                                name="apartment_id"
                                error={errorList.apartment_id ? true : false}
                                helperText={errorList.apartment_id}
                            />
                        }
                        // value={props.states.tower}
                        loading={apartmentAutoCompleteLoading}
                        onChange={handleApartmentChange}
                        isOptionEqualToValue={(option, value) => option.id === Number(value)}
                        value={{ id: props.states.id, label: props.states.apartment_name } as IListObject}
                        ref={refs.apartment_id}
                    />

                    <TextField
                        label="Tower"
                        name="tower"
                        variant="outlined"
                        value={props.states.tower}
                        onChange={changeInput}
                        fullWidth
                        error={errorList.tower ? true : false}
                        helperText={errorList.tower}
                        ref={refs.tower}
                    />

                    <div className={classes.twoInput}>
                        <TextField
                            label="Lantai"
                            name="floor"
                            variant="outlined"
                            value={props.states.floor}
                            onChange={changeInput}
                            fullWidth
                            error={errorList.floor ? true : false}
                            helperText={errorList.floor}
                            className={classes.twoInputLeft}
                            ref={refs.floor}
                        />
                        <TextField
                            label="Nomor"
                            name="number"
                            variant="outlined"
                            value={props.states.number}
                            onChange={changeInput}
                            fullWidth
                            error={errorList.number ? true : false}
                            helperText={errorList.number}
                            className={classes.twoInputRight}
                            ref={refs.number}
                        />
                    </div>
                </>
                : ''
            }

            <TextField
                label="Alamat Detail"
                name="address"
                variant="outlined"
                value={props.states.address}
                onChange={changeInput}
                multiline
                rows={3}
                fullWidth
                error={errorList.address ? true : false}
                helperText={errorList.address ? errorList.address : 'Masukkan nama jalan dan nomor'}
                ref={refs.address}
            />

            <Grid>
                <div style={{ height: '400px', width: '100%', maxWidth: 400, marginBottom: "32px", position: 'relative', marginTop: '1rem' }}>
                    <div className={classes.markerContainer}>
                        <RoomIcon className={classes.marker} />
                    </div>

                    {process.env.REACT_APP_ENV === 'production' ?
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: process.env!.REACT_APP_GOOGLE_MAP_API_KEY!,
                                libraries: ['places', 'geometry'],
                            }}
                            defaultZoom={11}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                            options={{ fullscreenControl: false }}
                            center={{ lat: props.states.latitude, lng: props.states.longitude }}
                        ></GoogleMapReact>
                        :
                        <GoogleMapReact
                            center={{ lat: props.states.latitude, lng: props.states.longitude }}
                            defaultZoom={11}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                            options={{ fullscreenControl: false }}
                        >
                        </GoogleMapReact>
                    }
                </div>
            </Grid>

            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Button
                        variant="contained"
                        className={classes.submit_btn}
                        color="primary"
                        onClick={validateData}
                        style={{ textTransform: 'initial' }}
                    >
                        Lanjutkan
                    </Button>
                </Grid>
            </Grid>
        </Root>
    )
}
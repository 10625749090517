import { Grid, Typography } from '@mui/material'
import React from 'react'

/* 
* Utils
*/
import { IActivityAdvisor } from '../DashboardPM'
import { format } from 'date-fns'
import { generateStyle } from '../../../_utils/DefaultStyle'

const renderTitle = (label: string) => {
    let text = ''
    if (label === 'showing') {
        text = 'Showing'
    } else if (label === 'call') {
        text = 'Phone Call'
    } else if (label === 'whatsapp') {
        text = 'Message Via Whatsapp'
    } else if (label === 'followup') {
        text = 'Follow Up'
    } else {
        text = label
    }

    return text
}
const renderIcon = (type: string) => {
    let url = require('../../../Assets/Images/png/leadAgentIcon/call.png')
    if (type === 'showing') {
        url = require('../../../Assets/Images/png/leadAgentIcon/showing.png')
    } else if (type === 'call') {
        url = require('../../../Assets/Images/png/leadAgentIcon/call.png')
    } else if (type === 'whatsapp') {
        url = require('../../../Assets/Images/png/leadAgentIcon/whatsapp.png')
    } else if (type === 'followup') {
        url = require('../../../Assets/Images/png/leadAgentIcon/showing-alt.png')
    } else {
        return url
    }
    return url
}

const useStyles = generateStyle(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    time: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto'
    },
    clock: {
        width: 50,
        textAlign: 'right'
    }
}), "Activity_Item")

const ActivityItem = ({ activity, key }: { activity: IActivityAdvisor, key: number | string }) => {
    const { Root, classes } = useStyles()
    return (
        <Root>
            <Grid container key={key} sx={{ my: 2 }} className={classes.container}>
                <Grid item>
                    <img style={{ width: 40, height: 40 }} src={renderIcon(activity.activity)} alt={activity.activity} />
                </Grid>
                <Grid item sx={{ ml: 2 }}>
                    <Typography variant='subtitle2'>
                        {
                            format(new Date(), 'dd MMMM') === format(new Date(activity.datetime), 'dd MMMM') ? 'Today' : format(new Date(activity.datetime), 'dd MMMM')
                        }
                    </Typography>
                    <Typography variant='subtitle1' fontWeight="700">
                        {
                            renderTitle(activity.activity)} {activity.activity === 'showing' && `- ${activity.apartment}`
                        }
                    </Typography>
                </Grid>
                <Grid item className={classes.time}>
                    <Typography variant='subtitle2' className={classes.clock}>
                        {format(new Date(activity.created_at), 'hh:mm aaa')}
                    </Typography>
                </Grid>
            </Grid>
        </Root>
    )
}

export default ActivityItem
import React, { useEffect, useReducer, useState } from 'react';
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import DOMPurify from 'dompurify';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import { currencyToNumber, generalErrorHandler, numberToCurrency, renderWarningButton } from '../../_utils/Helper';

interface IState {
    id: string;
    code: string;
    apartment_name: string;
    name: string;
    is_dollar: boolean;
    price_per_1_month: string;
    price_per_3_months: string;
    price_per_6_months: string;
    price_per_12_months: string;
    selling_price: string;
    rent_price_info: string;
    selling_price_info: string;
    tower: string;
    floor_level: string;
    view_direction: string;
    tfv_info: string;
    pic_name: string;
    pic_handphone: string;
    pic_info: string;
    photos: string[];
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const CleanScrapForm = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/clean-scrap-data';

    const initialState = {
        id: '',
        code: '',
        apartment_name: '',
        name: '',
        is_dollar: false,
        price_per_1_month: '',
        price_per_3_months: '',
        price_per_6_months: '',
        price_per_12_months: '',
        selling_price: '',
        rent_price_info: '',
        selling_price_info: '',
        tower: '',
        floor_level: '',
        view_direction: '',
        tfv_info: '',
        pic_name: '',
        pic_handphone: '',
        pic_info: '',
        photos: []
    };

    const currencyAttributes = [
        'price_per_1_month',
        'price_per_3_months',
        'price_per_6_months',
        'price_per_12_months',
        'selling_price'
    ];

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [isLoading, setIsLoading] = useState(false);

    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    useEffect(() => {
        fetchUnit();
        // eslint-disable-next-line
    }, []);

    const fetchUnit = () => {
        setIsLoading(true);
        DefaultAxios.get(API_URL)
            .then(res => {
                const newState = { ...initialState, ...res.data };
                newState.pic_handphone = newState.pic_handphone.replace(/\D+/g, '');
                
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (name === 'is_dollar') {
            value = value === 'true';
        } else if (currencyAttributes.includes(name)) {
            value = value ? String(currencyToNumber(value)) : '';
        } else if (name === 'pic_handphone') {
            value = value.replace(/\D+/g, '');
        }

        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleSubmit = () => {
        setIsLoading(true);
        DefaultAxios.post(API_URL, inputState)
            .then(res => {
                Swal.fire({
                    title: 'Submit berhasil',
                    icon: 'success',
                    timer: 1000,
                    onAfterClose: fetchUnit
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleSkip = () => {
        renderWarningButton("Apakah anda yakin ingin men-skip unit ini?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios
                    .post(`${API_URL}/skip`, { id: inputState.id })
                    .then(() => {
                        Swal.fire({
                            title: "Unit di skip",
                            icon: 'success',
                            timer: 1000,
                            onAfterClose: fetchUnit
                        })
                    })
                    .catch(error => {
                        generalErrorHandler(error)
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        });
    }
    
    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h1>{inputState.apartment_name} - {inputState.name} - {inputState.code}</h1>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {
                            inputState.photos.map((photo: string, key: number) => {
                                return <Grid item xs={3} key={key}>
                                    <a href={photo} target="_blank" rel="noopener noreferrer">
                                        <img src={photo} alt={photo} width="100%" />
                                    </a>
                                </Grid>
                            })
                        }
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Dollar</FormLabel>
                        <RadioGroup row name="is_dollar" value={inputState.is_dollar} onChange={handleChange}>
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <strong>Note: Bila hanya ada harga 1 bulan, harap isi 12x di harga 12 bulan</strong>
                </Grid>

                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                variant="outlined"
                                label="Harga per bulan"
                                name="price_per_1_month"
                                value={inputState.price_per_1_month ? numberToCurrency(inputState.price_per_1_month) : ''}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                variant="outlined"
                                label="Harga per 3 bulan"
                                name="price_per_3_months"
                                value={inputState.price_per_3_months ? numberToCurrency(inputState.price_per_3_months) : ''}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                variant="outlined"
                                label="Harga per 6 bulan"
                                name="price_per_6_months"
                                value={inputState.price_per_6_months ? numberToCurrency(inputState.price_per_6_months) : ''}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                variant="outlined"
                                label="Harga per 12 bulan"
                                name="price_per_12_months"
                                value={inputState.price_per_12_months ? numberToCurrency(inputState.price_per_12_months) : ''}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                variant="outlined"
                                label="Harga Jual"
                                name="selling_price"
                                value={inputState.selling_price ? numberToCurrency(inputState.selling_price) : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h3>Rent Price Info</h3>
                            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(inputState.rent_price_info || '-') }}></span>
                        </Grid>

                        <Grid item xs={12}>
                            <h3>Selling Price Info</h3>
                            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(inputState.selling_price_info || '-') }}></span>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={4}>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        label="Tower"
                        name="tower"
                        value={inputState.tower}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        label="Floor"
                        name="floor_level"
                        value={inputState.floor_level}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        label="View"
                        name="view_direction"
                        value={inputState.view_direction}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        label="TFV Info"
                        value={inputState.tfv_info}
                        disabled
                    />
                </Grid>

                <Grid item xs={12}>
                    <h3>Info Kontak</h3>
                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(inputState.pic_info || '-') }}></span>
                    
                </Grid>

                <Grid item xs={6}>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        label="PIC Name"
                        name="pic_name"
                        value={inputState.pic_name}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        label="PIC Handphone"
                        name="pic_handphone"
                        value={inputState.pic_handphone}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSkip}
                    >
                        Skip
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{ float: 'right' }}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default CleanScrapForm;
import { Button, ButtonGroup, Grid } from '@mui/material'
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import OrderDetailForm from './OrderDetailForm'
import JobDetail from './JobDetail'
import { useNavigate } from 'react-router-dom'
import LoadingScreen from '../../../_components/LoadingScreen'

/* 
* Utils
*/
import { generalErrorHandler, queryParamsToParams, renderQuestionButton, renderSuccessButton } from '../../../_utils/Helper'
import DefaultAxios from '../../../_utils/DefaultAxios'

/* 
* Interfaces
*/
import { IDefaultState, IDetail, KCPOption } from '../JhsOrderForm'
import Swal from 'sweetalert2'
import { validateData } from '../../../_utils/Validation'

interface IProps {
    setFormChanged: Dispatch<SetStateAction<boolean>>
    isEdit: boolean
    editData: any
}

type ITab = { label: string, value: string }
type IWorkType = 'check_in' | 'check_out' | 'other'
type IPaidBy = 'owner' | 'tenant' | 'deposit' | 'office'

export interface IOrderDetailState extends IDefaultState {
    use_closing: boolean,
    closing_id: string,
    unit_id: string,
    work_type: IWorkType | string,
    paid_by: IPaidBy | string,
    closing_code?: string
}

export interface IOrderJobs {
    id: number | null
    type: string
    done_at: string | null
    photos: File[]
    photos_api: { photo_url: string, id: number }[]
    description: string
}


const tabJobs: ITab[] = [
    { label: 'Order Detail', value: 'order_detail' },
    { label: 'Job Detail', value: 'job_detail' },
]

export const initialDetail = {
    start_date: "",
    end_date: "",
    unit_code: "",
    apartment_name: "",
    apartment_id: "",
    bedroom: null,
    tower: "",
    floor_level: "",
    unit_number: "",
    owner_name: "",
    tenant_name: "",
    customer_name: ""
}

const initialState = {
    use_closing: true,
    closing_id: '',
    unit_id: '',
    work_type: '',
    paid_by: '',
    key_collection_point_option: '' as KCPOption,
    key_collection_point: '',
    notes: "",
    activity: 'fixing_cleaning',
    request_date: null,
    request_time: null
}

const FixingCleaningForm = (props: IProps) => {
    const [activeTab, setActiveTab] = useState('order_detail')
    const [orderJobs, setOrderJobs] = useState<IOrderJobs[]>([{
        id: null,
        photos: [],
        done_at: null,
        type: '',
        photos_api: [],
        description: ''
    }
    ])
    const [state, setState] = useState<IOrderDetailState>(initialState)
    const [detail, setDetail] = useState<IDetail>(initialDetail)
    const [queryParams, setQueryParams] = useState<{ [key: string]: string }>({})
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const validationRules = {
        type: 'required',
        description: 'required'
    }

    const handleSubmit = (state: IOrderDetailState) => {
        const isErrorJobs = orderJobs.map(job => {
            const { isValid } = validateData(job, validationRules)
            return isValid
        })

        if (orderJobs.length === 0 || orderJobs === null || isErrorJobs.some(item => !item)) {
            Swal.fire({
                title: 'Error',
                html: 'Please fill order job fields <br />(type and description are required)',
                icon: 'error'
            })
            setActiveTab('job_detail')
        } else {
            renderQuestionButton('Pastikan data yang anda masukkan Benar.', 'Apakah anda yakin ingin submit?')
                .then((res) => {
                    if (res.value) {
                        handleSubmitFinal({ ...state, activity: 'fixing_cleaning', activity_jobs: orderJobs })
                    }
                    if (res.dismiss) {
                        return null
                    }
                })
                .catch(err => generalErrorHandler(err))
        }
    }

    const generateFormData = (data: any) => {
        let fd = new FormData()
        for (let key in data) {
            if (key === 'activity_jobs') {
                for (let index = 0; index < data[key].length; index++) {
                    for (let jobkey in data[key][index]) {
                        if (jobkey === 'photos') {
                            if (typeof data[key][index][jobkey] === 'string' || data[key][index][jobkey] === null) {
                                fd.append(`activity_jobs[${index}][${jobkey}]`, '')
                            } else {
                                data[key][index][jobkey].forEach((photo: File, idPhoto: number) => {
                                    fd.append(`activity_jobs[${index}][${jobkey}][${idPhoto}]`, photo)
                                })
                            }
                        } else {
                            if (data[key][index][jobkey] === null) {
                                fd.append(`activity_jobs[${index}][${jobkey}]`, '')
                            } else {
                                fd.append(`activity_jobs[${index}][${jobkey}]`, data[key][index][jobkey])
                            }
                        }
                    }
                }
            } else {
                if (data[key] === null) {
                    fd.append(key, '')
                } else {
                    switch (key) {
                        case 'key_collection_point_option':
                            break
                        case 'key_collection_point':
                            if (data.key_collection_point_option === 'Others') {
                                fd.append(key, data[key])
                            } else {
                                fd.append(key, data.key_collection_point_option)
                            }
                            break
                        default:
                            fd.append(key, data[key])
                            break

                    }
                }
            }
        }
        return fd
    }

    const gotoPrevious = () => {
        if (queryParams && queryParams.ticket_id) {
            return navigate(`/ticket/list/${queryParams.ticket_id}`)
        }

        const queryString = localStorage.getItem('queryParams')
        navigate(queryString ? `/jhs-order?${queryString}` : '/jhs-order')
    }

    const handleSubmitFinal = (data: any) => {
        setLoading(true)
        const fd = generateFormData(data)
        if (props.isEdit) {
            fd.append('_method', 'patch')
        }
        if (queryParams) {
            for (let key in queryParams) {
                fd.append(`${key}`, queryParams[key])
            }
        }
        DefaultAxios['post'](`${process.env.REACT_APP_API_URL}/activity${props.isEdit ? `/${props.editData.id}/jhs` : ''}`, fd)
            .then(res => {
                if (res) {
                    renderSuccessButton('Submit Success!', () => {
                        if (props.isEdit) {
                            return navigate(`/jhs-order/${props.editData.id ? props.editData.id : ''}`)
                        }
                        gotoPrevious()
                    })
                }
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => setLoading(false))
    }

    const assignExistData = (prev: any, current: any) => {
        let result: any = {}
        for (let key in prev) {
            if (key === 'use_closing') {
                result[key] = !!(current['closing_code'])
                if (!!current['closing_code']) {
                    result['closing_code'] = current['closing_code']
                }
            } else if (key === 'bedroom') {
                result[key] = current[key] ?? null
            } else if (key === 'key_collection_point') {
                result[key] = current[key] ?? null
                result['key_collection_point'] = current[key]
            }  else {
                result[key] = current[key] ? current[key] : null
            }
        }
        result['activity'] = 'fixing_cleaning'
        return result
    }

    useEffect(() => {
        setQueryParams(queryParamsToParams())

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (props.isEdit || queryParamsToParams()?.ticket_id) {
            setState(prev => assignExistData(prev, props.editData))
            setDetail(prev => assignExistData(prev, props.editData))
            setOrderJobs(prev => {
                if (props.editData.jobs && props.editData.jobs.length > 0) {
                    return props.editData.jobs.map((job: IOrderJobs) => ({
                        ...job,
                        photos: [],
                        photos_api: job.photos
                    }))
                } else {
                    return []
                }
            })
        }
    }, [props.editData, props.isEdit])

    return (
        <>
            {
                loading &&
                <LoadingScreen open={loading} fullScreen />
            }
            <Grid container sx={{ mb: 2 }}>
                <Grid item xs={12}>
                    <ButtonGroup
                        fullWidth
                    >
                        {
                            tabJobs.map((tabJob, index) => (
                                <Button
                                    variant={tabJob.value === activeTab ? 'contained' : 'outlined'}
                                    onClick={() => setActiveTab(tabJob.value)}
                                >
                                    {tabJob.label}
                                </Button>
                            ))
                        }
                    </ButtonGroup>
                </Grid>
            </Grid>
            {activeTab === 'order_detail' ?
                <OrderDetailForm
                    state={state}
                    setState={setState}
                    detail={detail}
                    setDetail={setDetail}
                    setFormChanged={props.setFormChanged}
                /> :
                <JobDetail
                    orderJobs={orderJobs}
                    setOrderJobs={setOrderJobs}
                    setFormChanged={props.setFormChanged} />
            }
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Button
                    variant="contained"
                    onClick={() => handleSubmit(state)}
                >
                    {props.isEdit ? 'Update Order' : 'Submit'}
                </Button>
            </Grid>
        </>
    )
}

export default FixingCleaningForm
import React, { useContext, useState } from 'react';
import {
    Button,
    Theme,
    Link,
    Switch,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';

/**
 * Types
 */
import { IColumn } from '../../_components/_dataTable/DataTable';
import { dateFormat, generalErrorHandler } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "AreaList"
)

const AreaList = () => {
    const permissions = useContext(PermissionContext)
    const API_URL = process.env.REACT_APP_API_URL + '/area';
    const { Root, classes } = useStyles();
    const navigate = useNavigate();

    const columns: IColumn[] = [
        {
            name: 'name',
            label: 'Name',
            filterable: true,
            type: 'string',
            render: (data: string, row: any) => {
                return (
                    <div>
                        <Link
                            onClick={() => navigate(`/area/edit/${row.id}`)}
                            component="button"
                        >
                            {data}
                        </Link>
                        {/* <IconButton color="primary" onClick={() => window.open(`${process.env.REACT_APP_MAIN_URL}/${row.code}`)}>
                            <VisibilityIcon />
                        </IconButton> */}
                    </div>
                )
            }
        },
        {
            name: 'title',
            label: 'Title',
            filterable: true,
            type: 'string'
        },
        {
            name: 'is_active',
            label: 'Published',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Published'
                },
                {
                    key: '0',
                    value: 'Unpublished'
                }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return permissions['unit.switch-active']
                    ? <Switch
                        checked={data === 1}
                        onChange={() => handlePublishSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                    : (data === 1 ? 'Yes' : 'No')
            }
        },
        {
            name: 'modified_by',
            label: 'Modified By',
            filterable: true,
            type: 'string'
        },
        {
            name: 'modified_at',
            label: 'Last Modified',
            filterable: true,
            type: 'date',
            render: (data: string) => {
                return dateFormat(data, 'DD MMM YYYY');
            }
        },
    ];

    const [isLoading, setIsLoading] = useState(false)

    const handlePublishSwitch = (unit: any, setRows: Function, rows: any[]) => {
        setIsLoading(true);
        DefaultAxios.post(`${API_URL}/${unit.id}/toggle-publish`)
            .then(res => {
                const newRows = rows.map((element: any) => {
                    if (+element.id === +unit.id) element['is_active'] = unit.is_active ? 0 : 1;
                    return element
                })
                setRows(newRows);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => navigate('/area/add')}
            >
                Add Area
            </Button>

            <DataTable
                // reDraw={redrawIndex}
                url={API_URL}
                columns={columns}
                rowCallback={(row: any) => {
                    if (+row.is_requested_change_availability === 1) {
                        return {
                            backgroundColor: 'yellow'
                        };
                    }

                    return null;
                }}
            />
        </Root>
    );
}

export default AreaList
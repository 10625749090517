import React, { useState, useReducer, useEffect, SyntheticEvent } from 'react';
import { Grid, Theme, TextField, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import PhotoPreview from '../../_components/PhotoPreview';
import ButtonUpload from '../../_components/ButtonUpload';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, resizeAndResetOrientationImage } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';
interface Props {
    itemId: number | null;
    unitClosingBreakdownId: number;
    onSubmitSuccess: () => void
}

interface IAction {
    type: string;
    name: string;
    value: any
}

interface IErrorState {
    caption: string;
    image_url: string;
}

interface IState {
    caption: string;
    type: number;
    notes: string;
    image_url: string | File;
}

const useStyles = generateStyle((theme: Theme) => ({
    inputRoot: {
        marginTop: '15px',
        marginBottom: '10px'
    },
    submitButton: {
        marginBottom: '20px'
    },
    gridContainer: {
        marginTop: theme.spacing(3)
    }
}), "HandoverUnit_form"
);

const HandoverUnitForm = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/unit-closing-breakdown-list';
    const { Root, classes } = useStyles();

    const [loading, setLoading] = useState(false);
    const [thumbnail, setThumbnail] = useState('');

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    }

    const [errorState, setErrorState] = useReducer(errorReducer, {
        caption: '',
        image_url: ''
    });

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                caption: '',
                type: 0,
                notes: '',
                image_url: ''
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    }

    const [inputState, setInputState] = useReducer(inputReducer, {
        caption: '',
        type: 0,
        notes: '',
        image_url: ''
    });

    useEffect(() => {
        if (props.itemId) {
            setLoading(true);

            DefaultAxios
                .get(`${API_URL}/${props.itemId}`)
                .then(res => {
                    const data = res.data;
                    setThumbnail(data.image_url);
                    setInputState({ type: 'REPLACE_STATE', value: data, name: '' });
                })
                .catch(error => {
                    generalErrorHandler(error);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
        // eslint-disable-next-line
    }, []);

    const onFileAdd = (e: SyntheticEvent) => {
        setLoading(true);

        const target = e.target as HTMLInputElement;
        const files = { ...target.files as FileList };
        if (target && files) {
            if (files[0].size >= 5000000) {
                Swal.fire({
                    title: 'Error!',
                    text: `Failed: Max uploaded file is 5MB`,
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
                setLoading(false);
            } else {
                resizeAndResetOrientationImage(files[0])
                    .then((file: File) => {
                        setThumbnail(URL.createObjectURL(file));
                        setInputState({ type: 'SET_ITEM', value: file, name: 'image_url' });
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            }
        }
    }

    const onRemoveFile = () => {
        setThumbnail('');
        setInputState({ type: 'SET_ITEM', value: '', name: 'image_url' });
    }

    const inputChangeHandler = (e: any) => {
        const target = e.target;
        if (target) {
            setInputState({ type: 'SET_ITEM', name: target.name, value: target.value });
        }
    }

    const checkValidation = () => {
        let isValid = true;
        let errorState = {} as IErrorState;

        if (!inputState.caption) {
            errorState.caption = 'Caption harus diisi';
            isValid = false;
        } else {
            errorState.caption = '';
        }

        if (!inputState.image_url) {
            errorState.image_url = 'Foto harus diupload';
            isValid = false;
        } else {
            errorState.image_url = '';
        }

        setErrorState({ type: 'REPLACE_STATE', value: errorState, name: '' });

        return isValid;
    }

    const onSubmit = () => {
        if (!checkValidation()) {
            return false;
        }

        const fd = new FormData();
        let url;
        if (props.itemId) {
            url = `${process.env.REACT_APP_API_URL}/unit-closing-breakdown-list/${props.itemId}`;
            fd.append('_method', 'patch');
        } else {
            url = `${process.env.REACT_APP_API_URL}/unit-closing-breakdown-list`;
        }

        for (let i in inputState) {
            if (inputState[i as keyof IState] instanceof File) {
                fd.append(i, inputState[i as keyof IState] as File);
            } else {
                fd.append(i, inputState[i as keyof IState] as string);
            }
        }

        fd.append('unit_closing_breakdown_id', String(props.unitClosingBreakdownId));

        setLoading(true);
        DefaultAxios
            .post(url, fd)
            .then(res => {
                Swal.fire({
                    title: "Form Submitted",
                    icon: 'success',
                    onAfterClose: props.onSubmitSuccess,
                    timer: 1000
                });
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const photoContainer = () => {
        return (
            <>
                <Grid item xs={12}>
                    <h3 style={{ marginBottom: '25px' }}>Tambah Foto:</h3>
                    {
                        !!errorState.image_url ?
                            <p style={{ color: 'red' }}>{errorState.image_url}</p> :
                            ''
                    }
                </Grid>
                <Grid item xs={12}>
                    {
                        !!thumbnail
                            ? <PhotoPreview
                                index={0}
                                onRemoveFile={onRemoveFile}
                                src={thumbnail}
                                key={Math.random()}
                            />
                            : <ButtonUpload onChange={onFileAdd} />
                    }
                </Grid>
            </>
        );
    }

    return (
        <Root>
            <Grid container spacing={0} className={classes.gridContainer}>
                <LoadingScreen open={loading} fullScreen />

                <Grid item xs />
                <Grid container spacing={3} item xs={10} md={6}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Caption"
                            name="caption"
                            value={inputState.caption}
                            onChange={inputChangeHandler}
                            error={!!errorState.caption}
                            helperText={errorState.caption}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select
                                autoWidth
                                name="type"
                                value={inputState.type}
                                onChange={inputChangeHandler}
                            >
                                <MenuItem value={0}>Keadaan Unit</MenuItem>
                                <MenuItem value={1}>Kerusakan Unit</MenuItem>
                                <MenuItem value={2}>Tagihan</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Catatan"
                            name="notes"
                            value={inputState.notes || ''}
                            onChange={inputChangeHandler}
                            multiline
                        />
                    </Grid>

                    {photoContainer()}

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={onSubmit}
                            className={classes.submitButton}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs />
            </Grid>
        </Root>
    );
}

export default HandoverUnitForm;
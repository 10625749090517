import React from "react";
import { Card, CardContent, Theme } from '@mui/material';

import loadingClass from "../../Assets/CSS/loading.module.css";
import { generateStyle } from "../../_utils/DefaultStyle";

const useStyles = generateStyle((theme: Theme) => ({
  card: {
    paddingBottom: '0.5rem',
    borderRadius: '0.5rem',
    marginBottom: '1rem',
    boxShadow: '12px 10px 30px #d9d9d9',
    width: '100%'
  },
  cardContent: {
    paddingBottom: '0.5rem !important',
    position: 'relative',
    paddingTop: '0.5rem !important'
  },
  date: {
    width: 85,
    height: 14,
    marginBottom: '0.3rem',
    marginTop: '0.2rem'
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.6rem',
    '& .name': {
      margin: 0,
      width: '100%',
      height: 20
    }
  },
  type: {
    display: 'flex',
    marginBottom: '0.3rem',
    '& .type-sold': {
      width: 50,
      height: 20
    },
    '& .type-property': {
      width: 80,
      height: 20,
      marginLeft: '0.5rem'
    }
  },
  middle: {
    '& .address': {
      height: 12,
      width: '70%',
      marginBottom: '0.6rem'
    }
  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    '& .price-container': {
      '& label': {
        color: '#0000004D',
        fontSize: '11px',
        fontWeight: 700
      },
      '& .price': {
        height: 24,
        width: 140
      }
    },
  }
}), "Loading_Card"
)

const LoadingCard = () => {
  const { Root, classes } = useStyles()

  return (
    <Root>
      <Card className={classes.card} elevation={2}>
        <CardContent className={classes.cardContent}>
          <div className={`${classes.date} ${loadingClass.loading}`}></div>
          <div className={classes.top}>
            <div className={`${loadingClass.loading} name`}></div>
          </div>
          <div className={classes.type}>
            <div className={`${loadingClass.loading} type-sold`}></div>
            <div className={`${loadingClass.loading} type-property`}></div>
          </div>
          <div className={classes.middle}>
            <div className={`${loadingClass.loading} address`}></div>
          </div>
          <div className={classes.bottom}>
            <div className="price-container">
              <div className={`${loadingClass.loading} price`}></div>
            </div>
          </div>
        </CardContent>
      </Card>
    </Root>
  )
}

export default LoadingCard
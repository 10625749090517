import { Theme, useTheme } from '@mui/material'
import React, { useMemo } from 'react'
import { generateStyle } from '../_utils/DefaultStyle'

export type ChipColor = 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'error' | undefined

interface IProps {
    label: string
    color?: ChipColor
}

const Chip = (props: IProps) => {
    const { Root, classes } = useStyles()
    const theme = useTheme()

    const chipColor = useMemo(() => {
        console.log(theme.palette.info.main)
        if (!props.color) {
            return {
                backgroundColor: theme.palette.grey[300],
                textColor: theme.palette.getContrastText(theme.palette.grey[300]),
            }
        }

        switch (props.color) {
            case 'primary':
                return {
                    backgroundColor: theme.palette.primary.main,
                    textColor: theme.palette.getContrastText(theme.palette.primary.main),
                }
            case 'secondary':
                return {
                    backgroundColor: theme.palette.secondary.main,
                    textColor: theme.palette.getContrastText(theme.palette.secondary.main),
                }
            case 'success':
                return {
                    backgroundColor: theme.palette.success.main,
                    textColor: theme.palette.common.white,
                }
            case 'warning':
                return {
                    backgroundColor: theme.palette.warning.main,
                    textColor: theme.palette.common.white,
                }
            case 'error':
                return {
                    backgroundColor: theme.palette.error.main,
                    textColor: theme.palette.getContrastText(theme.palette.error.main),
                }
            default:
                return {
                    backgroundColor: theme.palette.grey[300],
                    textColor: theme.palette.getContrastText(theme.palette.grey[300]),
                }
        }
    }, [props.color, theme.palette])

    return (
        <Root>
            <div
                className={classes.root}
                style={{
                    backgroundColor: chipColor.backgroundColor,
                    color: chipColor.textColor,
                }}
            >
                <span className={classes.label}>{props.label}</span>
            </div>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        border: 'none',
        cursor: 'default',
        height: 32,
        display: 'inline-flex',
        outline: 0,
        padding: 0,
        fontSize: 13,
        boxSizing: 'border-box',
        transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        borderRadius: 16,
        verticalAlign: 'middle',
        justifyContent: 'center',
        textDecoration: 'none',
    },
    label: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingLeft: 12,
        paddingRight: 12,
        textOverflow: 'ellipsis',
    }
}), "Chip_Gen")

export default React.memo(Chip)

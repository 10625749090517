import React, { useState } from 'react'
import { Theme } from '@mui/material';
import { generateStyle } from '../../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) => ({
  image: {
    height: '100%',
    width: '100%',
    flexShrink: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'transform 200ms ease',
    scrollSnapAlign: 'start',
    objectFit: 'cover'
  }
}), "ImageSlide"
);

interface Props {
  url: string,
  // x: number,
  idx: number,
  currentIdx: number,
  onImageSlide?: (index: number) => void
}

const ImageSlide = (props: Props) => {
  const { Root, classes } = useStyles()
  const [init, setInit] = useState(true)

  if (init) {
    if (props.idx + 1 === props.currentIdx) {
      setInit(false)
    }
  }
  return (
    <Root>
      <img alt={props.idx.toString()} src={props.url} className={classes.image}></img>
    </Root>
  )
}

export default ImageSlide;
import { Grid, Select, Button, IconButton, Tooltip, MenuItem, TextField, Chip } from '@mui/material'
import React, { useContext, useState } from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import moment, { Moment } from 'moment';

/* 
* Components
*/
import DataTable from '../../_components/_dataTable/DataTable'
import LoadingScreen from '../../_components/LoadingScreen';
import BillingDueDateModal from './_components/BillingDueDateModal';

/* 
* Utils
*/
import { convertNumber, generalErrorHandler, inputNumber } from '../../_utils/Helper';
import { format } from 'date-fns';
import DefaultAxios from '../../_utils/DefaultAxios';
import Swal from 'sweetalert2';

/* 
* Icons
*/
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

/* 
* Contexts
*/
import { PermissionContext } from '../../_contexts/PermissionContext';

interface IRow {
    [key: string]: number | string | null
    id: string
    customer_name: string | null
    customer_phone: string
    unit_information: string | null
    billing_due_date: string | null
    tr_phone: string | null
    apartment_name: string
    unit_code: string
    amount: number | null
    tr_name: string
    done_by: number
}

interface ICustomMonthProps {
    name: string,
    label: string,
    onChange: (name: string, value: string) => void,
    value: string
}

const toastMixin = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
});

const CustomMonths = (props: ICustomMonthProps) => {
    const [selectedMonth, setSelectedMonth] = useState(props.value)

    const handleChange = (evt: any) => {
        setSelectedMonth(evt.target.value)
        props.onChange(evt.target.name, evt.target.value)
    }

    const createMenuItem = (count: number, momentStart: Moment) => {
        let list = []
        for (let i = 0; i < count; i++) {
            const momentValue = moment(momentStart).subtract(i, 'month').startOf('month')
            list.push(
                <MenuItem
                    value={momentValue.format('YYYY-MM-DD')}
                >
                    {`${momentValue.format('MMMM')} ${momentValue.format('YYYY')}`}
                </MenuItem>
            )
        }
        return list
    }

    return (
        <Select
            name={props.name}
            label={props.label}
            value={selectedMonth}
            fullWidth
            onChange={handleChange}
        >
            {createMenuItem(3, moment())}
        </Select>
    )
}

const BillingCollectionList = () => {
    const permission = useContext(PermissionContext)
    const [isLoading, setIsLoading] = useState(false)
    const [reDrawDataTable, setReDrawDataTable] = useState(1)
    const [period, setPeriod] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [billingEdit, setBillingEdit] = useState<{ id: string, amount: number | null }>({
        id: "",
        amount: null
    })

    const [billingDueDate, setBillingDueDate] = useState({
        open: false,
        id: "",
        billing_due_date: ""
    })

    const handleEditBilling = (row: IRow) => {
        console.log(row)
        setBillingDueDate({
            open: true,
            id: row.apartment_id ? row.apartment_id.toString() : "",
            billing_due_date: row.billing_due_date as string
        })
    }

    const column = [
        {
            name: 'apartment_name',
            label: "Nama Apartment",
            filterable: true,
            sortable: false,
            render: (value: string, row: IRow) => {
                return (
                    <Grid container>
                        <Grid>{value}</Grid>
                        {
                            permission["apartment.change-billing-due-date"] ?
                                <Tooltip title="Change Billing">
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleEditBilling(row)}
                                        size="small"
                                    >
                                        <EditIcon style={{ fontSize: "inherit" }} />
                                    </IconButton>
                                </Tooltip> : null
                        }
                    </Grid>
                )
            }
        },
        {
            name: 'unit_code',
            label: "Kode Unit",
            filterable: true,
            sortable: false
        },
        {
            name: 'start_date',
            label: "Check-in Date",
            filterable: true,
            sortable: false,
            type: 'date',
            render: (data: any, row: any) => {
                return data ? format(new Date(data), 'dd MMM yyyy') : '-'
            }
        },
        {
            name: 'checkout_date',
            label: "Checkout Date",
            filterable: true,
            sortable: false,
            type: 'date',
            render: (data: any, row: any) => {
                return data ? format(new Date(data), 'dd MMM yyyy') : '-'
            }
        },
        {
            name: 'closing_code',
            label: "Kode Closing",
            filterable: true,
            sortable: false,
            render: (data: string, row: IRow) => {
                return <Chip
                    variant="outlined"
                    onClick={() => window.open(`/closing/${data}`)}
                    label={data} />
            }
        },
        {
            name: 'amount',
            label: "Nilai Tagihan",
            type: 'number',
            filterable: true,
            sortable: true,
            render: (value: number = 0, row: IRow) => (
                <>
                    <Grid container spacing={1} display="flex" alignItems="center" key={row.id}>
                        <Grid item xs>
                            <TextField
                                size='small'
                                value={billingEdit.amount !== null && billingEdit.id === row.id ? convertNumber(`${billingEdit.amount}`) : convertNumber(`${value}`)}
                                onChange={handleChangeBillingAmount}
                                disabled={billingEdit.id !== row.id}
                                focused={billingEdit.id === row.id}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {
                                permission["billing-collection.manage"] ? (
                                    billingEdit.id !== row.id ?
                                        <Tooltip title="Change Billing">
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleEdit(row)}
                                                size="small"
                                            >
                                                <EditIcon style={{ fontSize: "inherit" }} />
                                            </IconButton>
                                        </Tooltip> :
                                        <Grid display="flex" flexDirection="row">
                                            <Tooltip title="Submit Billing">
                                                <IconButton
                                                    color="primary"
                                                    onClick={handleSubmitBillingAmount}
                                                    size="small"
                                                >
                                                    <CheckIcon style={{ fontSize: "inherit" }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Cancel Edit Billing">
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => setBillingEdit({ id: "", amount: null })}
                                                    size="small"
                                                >
                                                    <CloseIcon style={{ fontSize: "inherit" }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                ) : null
                            }
                        </Grid>
                    </Grid>
                </>
            )
        },
        {
            name: 'tr_name',
            label: "TR",
            filterable: true,
            sortable: false
        },
        {
            name: 'done_by',
            label: "Done",
            filterable: true,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes',
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            sortable: false,
            render: (value: number) => (value && value !== undefined ? "Yes" : "No")
        },
        {
            name: 'EXTRA',
            label: 'Action',
            render: (row: any) => (permission["billing-collection.manage"] ?
                <Button
                    variant='contained'
                    color='success'
                    size='small'
                    onClick={() => handleSendNotify(row)}
                    startIcon={
                        <WhatsAppIcon
                            style={{ color: 'white' }}
                            fontSize="small"
                        />
                    }

                >
                    Send Notify
                </Button>
                : null
            )
        }
    ]

    const handleEdit = (row: IRow) => {
        setBillingEdit({
            id: row.id,
            amount: row.amount
        })
    }

    const handleChangeBillingAmount = (evt: any) => {
        setBillingEdit({
            ...billingEdit,
            amount: +inputNumber(evt.target.value)
        })
    }

    const handleCloseBilling = () => {
        setBillingDueDate({
            open: false,
            id: "",
            billing_due_date: ''
        })
    }

    const handleSubmitBillingAmount = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/billing-collection`, { ...billingEdit })
            .then(res => {
                toastMixin.fire({
                    icon: 'success',
                    title: 'Billing Amount Updated!'
                })
                setBillingEdit({ id: "", amount: null })
                setReDrawDataTable(reDrawDataTable + 1)
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => setIsLoading(false))
    }

    const isComplete = (row: IRow) => {
        if (row.amount === null) {
            Swal.fire('Error', `Harap isi Amount terlebih dahulu`, 'error')
            return false
        }
        return true
    }

    const handleSendNotify = (row: IRow) => {
        if (isComplete(row)) {
            let message =
                `Yth Bapak/Ibu ${row.customer_name}
selaku penghuni unit ${row.unit_information}
            
Anda memiliki tagihan *Utilities Invoice* ${moment(period).format("DD MMMM YYYY")} sebesar Rp ${convertNumber(`${row.amount}`)} yang akan jatuh tempo pada tanggal ${row.billing_due_date} ${moment(period).format('MMMM')} ${moment(period).format('YYYY')}. Jika sudah melakukan pembayaran, mohon untuk mengirimkan bukti pembayaran ke TR Jendela360 di wa.me/628111200360.
            
Untuk pertanyaan dan informasi lanjut, hubungi TR Jendela360. 
            
*Pesan ini adalah pesan otomatis. Harap untuk tidak membalas pesan ini.*
            
Regards,
Billing & Collection Jendela360`
            window.open(`https://api.whatsapp.com/send/?phone=${row.customer_phone}&text=${encodeURIComponent(message)}`)

            setIsLoading(true)
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/billing-collection/mark-notified`, { id: row.id })
                .then(res => {
                    toastMixin.fire({
                        icon: 'success',
                        title: 'Notify Sent!'
                    })
                    setReDrawDataTable(reDrawDataTable + 1)
                })
                .catch(err => generalErrorHandler(err))
                .finally(() => setIsLoading(false))
        }
    }
    return (
        <>
            <LoadingScreen open={isLoading} />
            <BillingDueDateModal
                onClose={(refresh: boolean = false) => {
                    if (refresh) {
                        setReDrawDataTable(reDrawDataTable + 1);
                    }
                    handleCloseBilling()
                }}
                open={billingDueDate.open}
                id={billingDueDate.id}
                billing_due_date={billingDueDate.billing_due_date}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} justifySelf="center">
                    <Grid display="flex" justifyContent="flex-end">
                        <Grid item xs={12} md={2}>
                            <CustomMonths
                                value={period}
                                name="period"
                                label=""
                                onChange={(name: string, value: string) => {
                                    setPeriod(value)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        url={`${process.env.REACT_APP_API_URL}/billing-collection`}
                        customParams={{
                            period: period
                        }}
                        columns={column}
                        reDraw={reDrawDataTable}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default BillingCollectionList
import React, { useState, Fragment } from 'react'

import { Grid, FormControl, Select, Theme, Typography, MenuItem, CircularProgress } from '@mui/material';

//Component
import ApartmentHighlights from './ApartmentHighlights';
import AptDialog from './AptDialog';
import AptTableSummary from './AptTableSummary';

//Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generateStyle } from '../../_utils/DefaultStyle';



const useStyles = generateStyle((theme: Theme) => ({
  root: {
    flexGrow: 1,
    color: '#505050'
  },
  highlight_container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  dash_title: {
    fontSize: '25px',
    margin: '20px 0 35px 0',
    fontWeight: 500,
    color: '#707070'
  },
  dash_text: {
    fontSize: '20px',
    fontWeight: 200,
    color: '#707070',
    marginBottom: '20px'
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '230px'
  }
}), "Demand_Supply"
);


const DemandSupply = () => {
  const { Root, classes } = useStyles();
  const [init, setInit] = useState(false);
  const [render, setRender] = useState(false);
  const [area, setArea] = useState(1);
  const [data, setData] = useState([{}]);
  const [open, setOpen] = useState(false);
  const [selectedApt, setSelectedApt] = useState('')
  const API_URL = `${process.env.REACT_APP_API_URL}/demand-supply`;

  const handleOpen = (apt: string) => {
    setOpen(true)
    setSelectedApt(apt)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onChangeArea = (e: any) => {
    let oldArea = Number(area + '');
    setArea(e.target.value)
    if (oldArea !== e.target.value) {
      setRender(false)
      reloadData(e.target.value)
    }
  }

  const reloadData = (query: any) => {
    DefaultAxios.get(API_URL, { params: { area: query } }).then(res => {
      setData(res.data)
    }).finally(() => {
      setRender(true)
    })
  }


  if (!init) {
    setInit(true)
    reloadData(1)
  }

  return (
    <Root>
      <AptDialog open={open} data={data} selected={selectedApt}
        onClose={handleClose} />
      <Grid container>
        <Grid item xs={12} className={classes.head}>
          <Typography variant='h4' gutterBottom className={classes.dash_title}>
            Apartment Performance
          </Typography>
          <FormControl>
            <Select value={area} onChange={onChangeArea} disableUnderline={true}>
              <MenuItem value="1">
                All Jakarta
              </MenuItem>
              <MenuItem value={2}>Jakarta Barat</MenuItem>
              <MenuItem value={3}>Jakarta Timur</MenuItem>
              <MenuItem value={4}>Jakarta Utara</MenuItem>
              <MenuItem value={5}>Jakarta Selatan</MenuItem>
              <MenuItem value={6}>Jakarta Pusat</MenuItem>
              <MenuItem value={7}>Lainnya</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom className={classes.dash_text}>
            Urgently Needed Acquiring
          </Typography>
        </Grid>
        {!render ? (
          <Grid item xs={12} className={classes.center}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} className={classes.highlight_container}>
              <ApartmentHighlights data={data} />
            </Grid>
            <Grid item xs={12}>
              <AptTableSummary url={API_URL} data={data} openDetail={handleOpen} />
            </Grid>
          </>
        )}
      </Grid>
    </Root>
  )
}

export default DemandSupply;
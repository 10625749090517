import React from 'react';
import { Button, Grid, Typography } from '@mui/material';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

const ButtonAndTitle = ({title, url}: {title: string, url: string}) => {
    const handleDownload = (url: string) => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/tools/download-report-support-supervisor/${url}`, { responseType: 'blob' })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${title.replace('Download', '').trim()}.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                generalErrorHandler(err);
            });
    }

    return (
        <>
            <Typography variant="h5" style={{ marginBottom: "10px" }}>
                {title}
            </Typography>
            <Grid container spacing={3} style={{ marginBottom: "50px" }}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDownload(url)}
                    >
                        Download
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

const DownloadReportSupportSupervisor = () => {
    return (
        <>
            <ButtonAndTitle 
                title="Download Active Tenant"
                url="active-tenant"
            />
        </>
    );
}

export default DownloadReportSupportSupervisor;
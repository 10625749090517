import React, { useState, useReducer, useEffect, SyntheticEvent } from 'react';
import {
    Navigate,
    useLocation
} from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button, TextField, IconButton, Switch, Theme } from '@mui/material';
import DefaultAxios from '../../_utils/DefaultAxios';
import CachedIcon from '@mui/icons-material/Cached';

import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import ButtonUpload from "../../_components/ButtonUpload";

// Interface
import PhotoPreview from '../../_components/PhotoPreview';

// Utils
import { converNumberFormat, resizeAndResetOrientationImage } from '../../_utils/Helper';

// Other Components
import ListingForm from './ListingForm';
import ModalContainer from '../../_components/_modal/ModalContainer';

import loadingClass from "../../Assets/CSS/loading-circle.module.css";
import { useSnackbar } from 'notistack';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    redirectId?: string
}

interface IState {
    [key: string]: string | string[] | File[] | boolean | IImage[]
    youtube_url: string,
    files: File[]
    photos: IImage[],
    sell_price: string,
    price_per_year: string,
    price_per_6_months: string,
    price_per_3_months: string,
    price_per_1_month: string,
    daily_price: string,
    property_area: string,
    land_area: string,
    bedroom: string
    bathroom: string,
    building_finished: string,
    description: string,
    display_currency: string,
    name: string,
    type_text: string,
    address: string,
    is_sell: boolean,
    is_rent: boolean,
    is_active: boolean
}

interface IAction {
    name: string,
    value?: object | Date | null | string | number | IFetch | boolean
}

interface IFetch {
    sell_price: string,
    price_per_year: string,
    price_per_6_months: string,
    price_per_3_months: string,
    price_per_1_month: string,
    daily_price: string,
    property_area: string,
    land_area: string,
    bedroom: string
    bathroom: string,
    building_finished: string,
    description: string,
    display_currency: string,
    name: string,
    address: string,
    youtube_url: string,
    type_text: string,
    is_sell: boolean,
    is_rent: boolean,
    is_active: boolean,
    photos: IImage[]
}

interface IImage {
    id: number
    url: string
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        padding: theme.spacing(1)
    },
    loading: {
        display: 'flex',
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        backgroundColor: 'rgba(128, 128, 128, 0.5)',
    },
    loadingIcon: {
        color: '#033a11',
        fontSize: '3.5rem',
        animation: '5s spinnow infinite linear'
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    title: {
        color: '#033a11',
        fontWeight: 500
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    submit_btn: {
        borderRadius: "40px",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    withSeparator: {
        borderBottom: '1px solid #0000001F',
        paddingBottom: '1.5rem'
    },
    type: {
        backgroundColor: '#EBFCEF',
        width: 'fit-content',
        padding: '0.3rem 1rem',
        fontSize: '12px',
        borderRadius: '1rem',
        marginTop: '1rem',
        marginLeft: '1rem'
    },
    location: {
        fontSize: 12,
        color: '#000000B3',
        marginTop: '0.4rem',
        display: 'flex'
    },
    apartmentTitle: {
        margin: 0,
        letterSpacing: 0,
        fontSize: 20,
        marginLeft: '1.5rem'
    },
    priceContainer: {
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1.5rem',
        display: 'flex',
        flexDirection: 'column'
    },
    price: {
        fontSize: 16,
        color: '#000000B3',
        fontWeight: 700,
        padding: '0.75rem 0 0 0',
        '& .per': {
            fontSize: 14,
            color: 'grey'
        }
    },
    containerEnd: {
        padding: '1rem 1.5rem',
        '& .hot-client-toggle': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '0.5rem',
            '& .label-container': {
                '& .label': {
                    color: '#363636',
                    fontSize: 16,
                    fontWeight: 700,
                    marginBottom: '0.25rem',
                    marginTop: 0,
                },
                '& .hint': {
                    fontSize: 13,
                    color: '#999999',
                    fontWeight: 400
                }
            }
        },
    }
}), "ListingPreview_Edit"
);

interface IParams extends Record<string, string | undefined> {
    id?: string
}

export default function ListingPreviewEdit(props: IProps) {
    const { Root, classes } = useStyles();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar()
    let { id } = useParams<IParams>();
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const firstFetch = () => {
        let previewId = ''
        if (!id) {
            if (props.redirectId) {
                previewId = props.redirectId
            }
        } else {
            previewId = id
        }
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/listing/preview-edit-data/${previewId}`)
            .then(res => {
                setFormState({ name: 'first-fetch', value: res.data });
            });
    }

    useEffect(() => {
        if (typeof location.state !== "undefined") {
            if (location.state!.message) {
                if (location.state!.variant === 'success') {
                    enqueueSnackbar(location.state!.message);
                }
            }
        }
        firstFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Show Edit Form
    const [showEditForm, setShowEditForm] = useState(false);

    // Page for Edit Listing
    const [editPage, setEditPage] = useState(1);

    const stateReducer = (state: IState, action: IAction) => {
        if (action.value != null) { //Need this or Typescript will complaint value will be possibly null
            if (action.name === 'first-fetch') {
                const fetched_data = action.value as IFetch;

                return {
                    ...state,
                    sell_price: fetched_data.sell_price,
                    price_per_year: fetched_data.price_per_year,
                    price_per_6_months: fetched_data.price_per_6_months,
                    price_per_3_months: fetched_data.price_per_3_months,
                    price_per_1_month: fetched_data.price_per_1_month,
                    daily_price: fetched_data.daily_price,
                    property_area: fetched_data.property_area,
                    land_area: fetched_data.land_area,
                    bedroom: fetched_data.bedroom,
                    bathroom: fetched_data.bathroom,
                    building_finished: fetched_data.building_finished,
                    description: fetched_data.description,
                    display_currency: fetched_data.display_currency,
                    name: fetched_data.name,
                    type_text: fetched_data.type_text,
                    address: fetched_data.address,
                    youtube_url: fetched_data.youtube_url !== null ? fetched_data.youtube_url : "",
                    is_sell: fetched_data.is_sell,
                    is_rent: fetched_data.is_rent,
                    is_active: fetched_data.is_active,
                    photos: fetched_data.photos,
                }
            }

            // if (action.name === 'photos') {
            //     let newArray: string[] = state.photos
            //     if (newArray.includes(action.value as string)) {
            //         return { ...state };
            //     }
            //     newArray.push(action.value as string)
            //     return {
            //         ...state,
            //         photos: newArray
            //     }
            // }

            if (action.name === 'files') {
                let newArray: File[] = state.files
                if (newArray.includes(action.value as File)) {
                    return { ...state }
                }
                newArray.push(action.value as File)
                return {
                    ...state,
                    files: newArray
                }
            }

            if (action.name === 'remove-files') {
                let newPhotos: IImage[] = state.photos
                let index: number = action.value as number
                if (index !== -1) newPhotos.splice(index, 1)
                return {
                    ...state,
                    photos: newPhotos as IImage[]
                }
            }

            return {
                ...state,
                [action.name]: action.value as string
            }
        } else {
            return { ...state };
        }
    };

    const [formState, setFormState] = useReducer(stateReducer, {
        youtube_url: '',
        files: [],
        photos: [],
        sell_price: "",
        price_per_year: "",
        price_per_6_months: "",
        price_per_3_months: "",
        price_per_1_month: "",
        daily_price: "",
        property_area: "",
        land_area: "",
        bedroom: '',
        bathroom: "",
        building_finished: "",
        description: "",
        display_currency: "",
        name: "",
        type_text: "",
        address: "",
        is_sell: false,
        is_rent: false,
        is_active: false,
    })

    //Redirect
    const [redirectPage, setRedirectPage] = useState(false);

    // Error State
    const [errorList, setErrorList] = useState<any>({});

    const handleInputChange = (e: any) => {
        setFormState({ name: e.target.name, value: e.target.value });

        let newErrorList = errorList;

        delete newErrorList[e.target.name];
        setErrorList(newErrorList);
    }

    const handleChangeActive = () => {
        setIsLoading(true)
        let previewId = '';
        if (!id) {
            if (props.redirectId) {
                previewId = props.redirectId
            }
        } else {
            previewId = id
        }

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/listing/toggle-active`, { listing_id: previewId })
            .then(res => {
                enqueueSnackbar(`Informasi Listing berhasil di-update`)
                setFormState({ name: "is_active", value: !formState.is_active });
                setIsLoading(false)
            })
            .catch(error => {
                if (typeof error.response.status === 'undefined') {
                    enqueueSnackbar('Kesalahan pada script, harap hubungi admin', { variant: 'error' })
                } else if (error.response.status === 422) {
                    enqueueSnackbar(error.response.data.errors.youtube_url, { variant: 'error' })
                } else if (error.response.status === 500) {
                    enqueueSnackbar('Kesalahan server, harap hubungi admin', { variant: 'error' })
                }
                setIsLoading(false)
            })
    }

    const handleSubmit = () => {
        setIsLoading(true)
        let previewId = ''
        if (!id) {
            if (props.redirectId) {
                previewId = props.redirectId
            }
        } else {
            previewId = id
        }
        DefaultAxios.put(`${process.env.REACT_APP_API_URL}/listing/youtube-url`, { id: previewId, youtube_url: formState.youtube_url })
            .then(res => {
                setRedirectPage(true);
                setIsLoading(false)
            })
            .catch(error => {
                if (typeof error.response.status === 'undefined') {
                    enqueueSnackbar('Kesalahan pada script, harap hubungi admin', { variant: 'error' })
                } else if (error.response.status === 422) {
                    enqueueSnackbar(error.response.data.errors.youtube_url, { variant: 'error' })
                } else if (error.response.status === 500) {
                    enqueueSnackbar('Kesalahan server, harap hubungi admin', { variant: 'error' })
                }
                setIsLoading(false)
            })
        // window.scrollTo(0, 0);
    }

    const onFileAdd = (e: SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        const files = target.files as FileList;
        let previewId = ''
        if (!id) {
            if (props.redirectId) {
                previewId = props.redirectId
            }
        } else {
            previewId = id
        }
        if (target && files) {
            const url = process.env.REACT_APP_API_URL + '/listing-photo'
            let promises = []
            setIsLoading(true)
            for (let i = 0; i < files.length; i++) {
                promises.push(new Promise<void>((resolve, reject) => {
                    if (files[i].size > 10000000) {
                        enqueueSnackbar('Ukuran file maksimum adalah 10MB', { variant: 'error' })
                        reject()
                    } else {
                        resizeAndResetOrientationImage(files[i])
                            .then((file: File) => {
                                let formData = new FormData()
                                formData.append('listing_id', previewId!)
                                formData.append('image', file)
                                DefaultAxios.post(url, formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                })
                                    .then(res => {
                                        resolve()
                                    })
                                    .catch(err => {
                                        enqueueSnackbar('Kesalahan server, harap hubungi admin', { variant: 'error' })
                                        reject()
                                    })
                            })
                    }
                }))
            }
            Promise.allSettled(promises)
                .then((results) => {
                    setIsLoading(false);
                    firstFetch();
                })
        }
    }

    const onRemoveFile = (index: number) => {
        setIsLoading(true)
        const url = process.env.REACT_APP_API_URL + '/listing-photo/' + formState.photos[index].id
        DefaultAxios.delete(url)
            .then(res => {
                setFormState({ name: 'remove-files', value: index })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const renderPreview = () => {
        return formState.photos.map((element: any, idx: number) => (
            <PhotoPreview
                index={idx}
                onRemoveFile={onRemoveFile}
                src={element.url}
                key={element.id}
                style={{ height: 100, width: 100, margin: 0, marginRight: '0.5rem' }}
            />
        ))
    }

    const remark = () => {
        if (formState.is_rent && formState.is_sell) {
            return 'Dijual dan Disewa '
        }

        if (formState.is_rent) {
            return 'Disewa '
        } else {
            return 'Dijual '
        }
    }

    const renderView = () => {
        return (
            <div className={classes.root}>
                <div className={classes.withSeparator}>
                    <h4 className={classes.title}>Add Media</h4>
                    <TextField
                        label="Youtube URL"
                        name="youtube_url"
                        value={formState.youtube_url}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        error={errorList.youtube_url ? true : false}
                        helperText={errorList.youtube_url}
                    />

                    <h4 className={classes.title}>Upload Photos</h4>
                    {renderPreview()}
                    <ButtonUpload
                        onChange={onFileAdd}
                        multiple={true}
                        style={{ height: 100, width: 100, margin: 0 }}
                    />
                </div>

                <div className={classes.withSeparator} style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className={classes.titleContainer}>
                        <h4 className={classes.title}>Judul, Lokasi & Harga</h4>
                        <IconButton size="small" onClick={() => {
                            setEditPage(3);
                            setShowEditForm(true);
                        }}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </div>
                    {/* <span className={classes.type}>{renderLabelType()}</span> */}
                    <span
                        className={classes.location}
                    >
                        <LocationOnIcon
                            style={{ fontSize: 16, marginRight: 6 }}
                        />
                        {`${remark()} ${formState.type_text} di `}
                        {formState.address}
                    </span>
                    <h2 className={classes.apartmentTitle}>{formState.name}</h2>
                    <div className={classes.priceContainer}>
                        {formState.sell_price !== null && <span className={classes.price}>{formState.display_currency === 'IDR' ? 'Rp.' : '$'} {converNumberFormat(formState.sell_price)} <span className="per">harga jual</span></span>}
                        {formState.price_per_year !== null && <span className={classes.price}>{formState.display_currency === 'IDR' ? 'Rp.' : '$'} {converNumberFormat(formState.price_per_year)} <span className="per">/ tahun</span></span>}
                        {formState.price_per_6_months !== null && <span className={classes.price}>{formState.display_currency === 'IDR' ? 'Rp.' : '$'} {converNumberFormat(formState.price_per_6_months)} <span className="per">/ 6 bulan</span></span>}
                        {formState.price_per_3_months !== null && <span className={classes.price}>{formState.display_currency === 'IDR' ? 'Rp.' : '$'} {converNumberFormat(formState.price_per_3_months)} <span className="per">/ 3 bulan</span></span>}
                        {formState.price_per_1_month !== null && <span className={classes.price}>{formState.display_currency === 'IDR' ? 'Rp.' : '$'} {converNumberFormat(formState.price_per_1_month)} <span className="per">/ bulan</span></span>}
                        {formState.daily_price !== null && <span className={classes.price}>{formState.display_currency === 'IDR' ? 'Rp.' : '$'} {converNumberFormat(formState.daily_price)} <span className="per">/ hari</span></span>}
                    </div>
                </div>

                <div className={classes.withSeparator}>
                    <div className={classes.titleContainer}>
                        <h4 className={classes.title}>Deskripsi</h4>
                        <IconButton size="small" onClick={() => {
                            setEditPage(3);
                            setShowEditForm(true);
                        }}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </div>
                    <p style={{ fontSize: 16, color: '#000000B3', margin: '0.5rem 0', wordBreak: 'break-word' }}>{formState.description}</p>
                </div>

                <div>
                    <div className={classes.titleContainer}>
                        <h4 className={classes.title}>Detail</h4>
                        <IconButton size="small" onClick={() => {
                            setEditPage(2);
                            setShowEditForm(true);
                        }}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </div>
                    {
                        formState.property_area && formState.property_area !== '' &&
                        <p style={{ fontSize: 16, color: '#000000B3', margin: '0.5rem 0 0.75rem 0' }}>Luas Bangunan: {formState.property_area} m<sup>2</sup></p>
                    }
                    {
                        formState.land_area && formState.land_area !== '' &&
                        <p style={{ fontSize: 16, color: '#000000B3', margin: '0.5rem 0 0.75rem 0' }}>Luas Tanah: {formState.land_area} m<sup>2</sup></p>
                    }
                    {
                        formState.bedroom && formState.bedroom !== '' &&
                        <p style={{ fontSize: 16, color: '#000000B3', margin: '0.5rem 0 0.75rem 0' }}>Kamar Tidur: {formState.bedroom}</p>
                    }
                    {
                        formState.bathroom && formState.bathroom !== '' &&
                        <p style={{ fontSize: 16, color: '#000000B3', margin: '0.5rem 0 0.75rem 0' }}>Kamar Mandi: {formState.bathroom}</p>
                    }
                    {
                        formState.building_finished && formState.building_finished !== '' &&
                        <p style={{ fontSize: 16, color: '#000000B3', margin: '0.5rem 0' }}>Tahun Dibangun: {formState.building_finished}</p>
                    }
                </div>
            </div>
        )
    }

    if (redirectPage) {
        return (
            <Navigate replace
                to={`/listing/list`}
                state={{
                    show_complete: true
                }} />
        )
    } else {
        return (
            <Root>
                {
                    isLoading &&
                    <div className={classes.loading}>
                        <CachedIcon className={loadingClass.loading} />
                        Processing...
                    </div>
                }
                <ModalContainer
                    isOpen={showEditForm}
                    handleOpen={() => setShowEditForm(true)}
                    handleClose={() => setShowEditForm(false)}
                    title={'Edit Listing'}
                >
                    <ListingForm
                        id={id ? id : props.redirectId ? props.redirectId : ''}
                        page={editPage}
                        closeModal={() => {
                            setShowEditForm(false);
                            firstFetch();
                            enqueueSnackbar('Data berhasil disimpan');
                        }}
                    ></ListingForm>
                </ModalContainer>
                {renderView()}

                <hr></hr>

                <div className={classes.containerEnd}>
                    <div className="hot-client-toggle">
                        <div className="label-container">
                            <h5 className="label">Listing Status: {formState.is_active ? 'Aktif' : 'Nonaktif'}</h5>
                            <span className="hint">
                                {formState.is_active ? 'Listing aktif dan dapat dicari di Listing Market' : 'Listing yang tidak aktif tidak akan dimunculkan di Listing Market.'}
                            </span>
                        </div>
                        <Switch
                            checked={formState.is_active}
                            onChange={handleChangeActive}
                        // checked={data.is_invalid_leads}
                        // onChange={handleChangeInvalid}
                        />
                    </div>
                </div>

                <Button
                    variant="contained"
                    className={classes.submit_btn}
                    color="primary"
                    onClick={handleSubmit}
                    fullWidth
                    style={{ marginTop: '2rem' }}
                >
                    Publish Listing
                </Button>
            </Root>
        )
    }
}
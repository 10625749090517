import React, { useContext, useState } from 'react';
import {
    Button,
    Theme,
    Switch,
    IconButton,
    TextField
} from '@mui/material';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import DefaultAxios from '../../_utils/DefaultAxios';
import LoadingScreen from '../../_components/LoadingScreen';
import BankVendorFormModal from './BankVendorFormModal';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import { Check, Edit } from '@mui/icons-material';

/**
 * Utils
 */
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    bank_id?: string
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        boxShadow: '0px 0px 0px #0000001A',
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    }
}), "BankVendor_list"
)

const BankVendorList = (props: IProps) => {
    const { Root, classes } = useStyles();
    const permissions = useContext(PermissionContext)
    const DT_API_URL = process.env.REACT_APP_API_URL + '/bank-vendor';
    const [reDraw, setReDraw] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false)

    const [selectedNotesRow, setSelectedNotesRow] = useState<null | any>(null)

    const handleVerified = (data: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengubah status active?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/bank-vendor/toggle-active`, { id: data.id })
                        .then(res => {
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +data.id) element['is_active'] = data.is_active ? 0 : 1;
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const onNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setSelectedNotesRow((prev: any) => ({
            ...prev,
            notes: value,
        }))
    }

    const saveNewNotes = (rows: any, setRows: any) => {
        if (!selectedNotesRow) return

        const data = {
            id: selectedNotesRow.id,
            notes: selectedNotesRow.notes,
        }

        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/bank-vendor/update-notes`, data)
            .then(res => res.data)
            .then(() => {
                setSelectedNotesRow(null)
                renderToastSuccess('Berhasil mengubah notes')

                const newRows = rows.map((element: any) => {
                    if (+element.id === +data.id) element['notes'] = data.notes;
                    return element
                })
                setRows(newRows);
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <BankVendorFormModal
                isOpen={isFormOpen}
                onClose={() => { setIsFormOpen(false) }}
                onSuccess={() => {
                    setIsFormOpen(false)
                    setReDraw(prev => prev + 1)
                }}
            />
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => { setIsFormOpen(true) }}
            >
                Add Bank
            </Button>
            <DataTable
                url={DT_API_URL}
                columns={[
                    { name: 'bank_type_name', label: 'Bank', filterable: true },
                    { name: 'behalf_name', label: 'Atas Nama', filterable: true },
                    { name: 'number', label: 'Nomor Rekening', filterable: true },
                    {
                        name: 'notes',
                        label: 'Notes',
                        filterable: true,
                        render: (data: string, row: any, rows: any, index: number, setRows: any) => {
                            return (
                                selectedNotesRow?.id === row.id ?
                                    <>
                                        <TextField
                                            value={selectedNotesRow?.notes || ''}
                                            onChange={onNotesChange}
                                            disabled={isLoading}
                                            size='small'
                                            variant='standard'
                                            style={{
                                                maxWidth: 'calc(100% - 30px)',
                                            }}
                                            multiline
                                        />
                                        <IconButton
                                            onClick={() => saveNewNotes(rows, setRows)}
                                            size='small'
                                            disabled={isLoading}
                                        >
                                            <Check fontSize='small' color='success' />
                                        </IconButton>
                                    </>
                                    :
                                    <>
                                        <span
                                            style={{
                                                whiteSpace: 'pre-wrap',
                                                maxWidth: 'calc(100% - 30px)',
                                                display: 'inline-block',
                                                marginTop: 5
                                            }}
                                        >
                                            {data || '-'}
                                        </span>
                                        {
                                            permissions['bank-vendor.edit-note'] &&
                                            <IconButton
                                                onClick={() => setSelectedNotesRow(row)}
                                                size='small'
                                                style={{ verticalAlign: 'top' }}
                                            >
                                                <Edit fontSize='small' color='primary' />
                                            </IconButton>
                                        }
                                    </>
                            );
                        }
                    },
                    {
                        name: 'is_active',
                        label: 'Active',
                        filterable: true,
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '1',
                                value: 'Yes'
                            },
                            {
                                key: '0',
                                value: 'No'
                            }
                        ],
                        render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                            return (
                                <Switch
                                    checked={data === 1}
                                    onChange={() => handleVerified(row, setRows, rows)}
                                    color="secondary"
                                />
                            );
                        }
                    }
                ]}
                reDraw={reDraw}
            />
        </Root>
    );
}

export default BankVendorList;
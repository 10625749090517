import React, { useCallback, useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { AppBar, Button, Chip, CircularProgress, Dialog, DialogContent, IconButton, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Toolbar, Typography } from '@mui/material'
import LoadingScreen from '../../../_components/LoadingScreen';
import InventoryItemModal from './InventoryItemModal';
import InventoryRoomForm from './InventoryRoomForm';
import Swal from 'sweetalert2';

/**
 * Utils
 */
import { useNavigate, useLocation, useParams } from 'react-router';
import { TransitionProps } from '@mui/material/transitions';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { convertIndonesiaPhoneNumber, generalErrorHandler, renderWarningButton } from '../../../_utils/Helper';
import { debounce } from 'lodash';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
// import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Delete } from '@mui/icons-material';
import VideoPreview from '../../../_components/VideoPreview';
import { generateStyle } from '../../../_utils/DefaultStyle';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    location?: any;
}

export type IInventoryItemPhoto = {
    id: number
    item_id: number
    photo_url: string
    photo_thumbnail_url: string
    file?: File
}

export type IInventoryItem = {
    id: number
    room_id: number
    name: string
    type: string
    quantity: number
    notes: string
    photos: IInventoryItemPhoto[]
    is_done: number

}
export type IInventoryRoom = {
    id: number
    activity_id: number
    name: string
    is_done: number
    closing_inventory_items: IInventoryItem[]
    video: string
    video_url: string
}

export type KeyAndAccessCard = {
    name: string
    notes: string
    is_done: number
    is_sent: number
    is_broken: number
    photos: IInventoryItemPhoto[]
}

export const EXTRA_ROOMS = [
    'Kunci dan Kartu Akses',
    'Akses Parkir',
    'Router Wifi',
    'Bukti Cut-off Tagihan Listrik',
    'Bukti Cut-off Tagihan Air',
]

export const isExtraRooms = (type: string) => EXTRA_ROOMS.includes(type)

const InventoryRoomModal = (props: IProps) => {
    const { Root, classes } = useStyles()

    const navigate = useNavigate();
    const location = useLocation();
    const params: any = useParams();
    const prevPath = useState(location.state?.prevPath);

    const [data, setData] = useState<IInventoryRoom[]>([])
    const [isLoading, setIsLoading] = useState(true)

    const [selectedRoomId, setSelectedRoomId] = useState<number | null>(null)

    const [roomState, setRoomState] = useState({
        name: '',
        video: null
    })
    const [isRoomFormOpen, setIsRoomFormOpen] = useState(false)

    const [editRoomNameId, setEditRoomNameId] = useState<null | number>(null)
    const [editRoomNameValue, setEditRoomNameValue] = useState('')
    const [editRoomNameError, setEditRoomNameError] = useState('')

    const [previewVideo, setPreviewVideo] = useState('')

    const [unitDetail, setUnitDetail] = useState({
        activity: '',
        unit_code: '',
        tower: '',
        floor_level: '',
        unit_number: '',
        customer_name: '',
        customer_phone: '',
        landlord_name: '',
        landlord_phone: '',
        advisor_name: '',
        advisor_phone: '',
        inventory_notes: '',
        tr_name: '',
        tr_phone: '',
    })

    const selectedRoom = useMemo(() => {
        if (selectedRoomId) {
            const filteredData = data.filter(room => room.id === selectedRoomId)
            if (filteredData.length) {
                return filteredData[0]
            }
        }

        return null
    }, [data, selectedRoomId])

    const [isEditNotesOpen, setIsEditNotesOpen] = useState(false)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/activity/${params.code}/listing-inventory`)
            .then(res => res.data)
            .then(data => {
                setUnitDetail(data.unit_information)
                setData(data.rooms)
                setIsLoading(false)
            })
            .catch(err => {
                generalErrorHandler(err)
                setIsLoading(false)
            })
    }

    const closeModal = () => {
        if (typeof prevPath[0] !== 'undefined') {
            navigate(`${prevPath[0]}`)
        } else {
            navigate(-1)
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = e.target
        let finalValue: any

        if (name === 'video') {
            if (files && files.length) {
                finalValue = files[0]
            }
        } else {
            finalValue = value
        }

        setRoomState(prev => ({
            ...prev,
            [name]: finalValue,
        }))
    }

    // ROOM
    const openRoomForm = () => {
        setIsRoomFormOpen(true)
    }

    const closeRoomForm = () => {
        setIsRoomFormOpen(false)
        setRoomState({
            name: '',
            video: null
        })
    }

    const openRoom = (id: number) => {
        setSelectedRoomId(id)
    }

    const closeRoom = () => {
        setSelectedRoomId(null)
        fetchData()
    }

    const addRoom = () => {
        let url = `${process.env.REACT_APP_API_URL}/activity/${params.code}/listing-inventory`
        if (unitDetail.activity === '9') {
            url = `${process.env.REACT_APP_API_URL}/activity/${params.code}/listing-inventory/ho-checkin-finish`
            if (!roomState.video) {
                return Swal.fire('Error', 'Video is required', 'error')
            }
        }

        let fd = new FormData()
        fd.append('name', roomState.name || '')
        fd.append('video', roomState.video || '')

        setIsLoading(true)
        DefaultAxios.post(url, fd)
            .then(res => res.data)
            .then(data => {
                setIsRoomFormOpen(false)
                setRoomState({
                    name: '',
                    video: null
                })
                fetchData()

                Swal.fire({
                    title: 'Berhasil Menambahkan Ruangan',
                    icon: 'success',
                    timer: 1000,
                })
            })
            .catch(err => {
                generalErrorHandler(err)
                setIsLoading(false)
            })
    }

    const removeRoom = (id: number) => {
        renderWarningButton('Apakah anda yakin ingin menghapus ruangan ini?', 'Hapus Ruangan?')
            .then(result => {
                if (result.value) {
                    setIsLoading(true)
                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/activity/${params.code}/listing-inventory/${id}`)
                        .then(res => res.data)
                        .then(data => {
                            setIsLoading(false)
                            Swal.fire({
                                title: 'Berhasil Menghapus Ruangan',
                                icon: 'success',
                                timer: 1000,
                            })
                            fetchData()
                        })
                        .catch(err => {
                            generalErrorHandler(err)
                            setIsLoading(false)
                        })
                }
            })
    }

    const editRoomName = (id: number) => {
        setEditRoomNameId(id)
        setEditRoomNameValue(data.filter(room => room.id === id)[0]!.name)
    }

    const saveRoomName = (id: number) => {
        if (editRoomNameValue !== '') {
            setIsLoading(true)
            DefaultAxios.patch(`${process.env.REACT_APP_API_URL}/activity/${params.code}/listing-inventory/${id}`, { name: editRoomNameValue })
                .then(res => res.data)
                .then(data => {
                    setEditRoomNameId(null)
                    setEditRoomNameValue('')
                    fetchData()

                    Swal.fire({
                        title: 'Berhasil Mengubah Nama Ruangan',
                        icon: 'success',
                        timer: 1000,
                    })
                })
                .catch(err => {
                    generalErrorHandler(err)
                    setIsLoading(false)
                })
        } else {
            setEditRoomNameError('Please fill the room name')
        }
    }

    // const finishListing = () => {
    //     let canSubmit = true;
    //     data.forEach(item => {
    //         if (!item.is_done) {
    //             canSubmit = false;
    //         }
    //     })

    //     if (canSubmit) {
    //         renderWarningButton('Apakah anda yakin ingin finish listing ini?', 'Finish listing?')
    //             .then(result => {
    //                 if (result.value) {
    //                     setIsLoading(true)

    //                     DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/${params.code}/listing-inventory/finish`)
    //                         .then(() => {
    //                             setIsLoading(false)
    //                             if (typeof prevPath[0] !== 'undefined') {
    //                                 navigate(`${prevPath[0]}`)
    //                             } else {
    //                                 navigate(-1)
    //                             }
    //                         })
    //                         .catch(err => {
    //                             generalErrorHandler(err)
    //                             setIsLoading(false)
    //                         })
    //                 }
    //             })
    //     } else {
    //         Swal.fire({
    //             title: "Listing not Finished",
    //             html: "Harap selesaikan semua kamar terlebih dahulu.",
    //             icon: 'error',
    //         });
    //     }

    // }

    const generatePDF = () => {
        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/${params.code}/listing-inventory/pdf`, {}, {
            responseType: 'blob',
        })
            .then(res => res.data)
            .then(data => {
                setIsLoading(false)
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `Inventory-${unitDetail.unit_code}.pdf`)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .catch(err => {
                console.log(err)
                generalErrorHandler(err)
                setIsLoading(false)
            })
    }

    const [isInventoryNotesLoading, setIsInventoryNotesLoading] = useState(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveInventoryNotes = useCallback(debounce((text: string) => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/update-inventory-notes`, {
            inventory_notes: text,
            id: params.code,
        })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsInventoryNotesLoading(false)
            })
    }, 1000), [params.code])

    const finishRoom = (room: IInventoryRoom) => {
        renderWarningButton('Apakah anda yakin ingin finish ruangan ini?', 'Finish ruangan?')
            .then(result => {
                if (result.value) {
                    setIsLoading(true)

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/${room.activity_id}/listing-inventory/${room.id}/finish`)
                        .then(() => {
                            setIsLoading(false)
                            Swal.fire({
                                title: 'Berhasil Finish Ruangan',
                                icon: 'success',
                                timer: 1000,
                            })
                            fetchData()
                        })
                        .catch(err => {
                            generalErrorHandler(err)
                            setIsLoading(false)
                        })
                }
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                fullScreen
                TransitionComponent={Transition}
                open={true}
                onClose={() => { typeof prevPath[0] !== 'undefined' ? navigate(`${prevPath[0]}`) : navigate(-1) }}
            >
                <Root>

                    <InventoryItemModal
                        roomName={selectedRoom?.name || ''}
                        roomId={selectedRoomId || 0}
                        activityId={params.code}
                        isOpen={!!selectedRoomId}
                        onClose={closeRoom}
                        activity={unitDetail.activity}
                    />

                    <VideoPreview
                        video={previewVideo}
                        onClose={() => setPreviewVideo('')}
                        key="video-preview"
                    />

                    <InventoryRoomForm
                        name={roomState.name}
                        onChange={onChange}
                        isOpen={isRoomFormOpen}
                        onClose={closeRoomForm}
                        onSubmit={addRoom}
                        withVideo={unitDetail.activity === '9'}
                    />

                    <AppBar className={classes.appBar} color={'primary'}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => closeModal()}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Inventory {unitDetail.unit_code}
                            </Typography>
                            {/* <IconButton
                            edge="start"
                            color="inherit"
                            onClick={finishListing}
                            aria-label="finish"
                        >
                            <AssignmentTurnedInIcon />
                        </IconButton> */}
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <div className={classes.bodyMobile}>
                            <div className={classes.roomCard}>
                                <div className={classes.unitDetail}>
                                    <span className="title">
                                        {unitDetail.unit_code}
                                    </span>
                                    <span className="subtitle">
                                        Tower {unitDetail.unit_code} | Lt. {unitDetail.floor_level} | No. {unitDetail.unit_number}
                                    </span>
                                    <span className="title">
                                        {unitDetail.activity === '2' ? 'TR' : 'PM'}
                                    </span>
                                    <span className="subtitle">
                                        {unitDetail.activity === '2' ? (unitDetail.tr_name || '-') : (unitDetail.advisor_name || '-')}<br />
                                        {
                                            (unitDetail.activity === '2' ? unitDetail.tr_phone : unitDetail.advisor_phone) ?
                                                <a href={`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(unitDetail.activity === '2' ? (unitDetail.tr_phone || '') : (unitDetail.advisor_phone || ""))}`} target="_blank" rel="noopener noreferrer" style={{ marginTop: 2, }}>
                                                    {unitDetail.activity === '2' ? unitDetail.tr_phone : unitDetail.advisor_phone}
                                                </a>
                                                :
                                                <span style={{ marginTop: 2, }}>
                                                    -
                                                </span>
                                        }
                                    </span>
                                    <span className="title">
                                        Owner
                                    </span>
                                    <span className="subtitle">
                                        {unitDetail.landlord_name || '-'}<br />
                                        {
                                            unitDetail.landlord_phone ?
                                                <a href={`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(unitDetail.landlord_phone ? unitDetail.landlord_phone : "")}`} target="_blank" rel="noopener noreferrer" style={{ marginTop: 2, }}>
                                                    {unitDetail.landlord_phone}
                                                </a>
                                                :
                                                <span style={{ marginTop: 2, }}>
                                                    -
                                                </span>
                                        }
                                    </span>
                                    <span className="title">
                                        Tenant
                                    </span>
                                    <span className="subtitle">
                                        {unitDetail.customer_name || '-'}<br />
                                        {
                                            unitDetail.customer_phone ?
                                                <a href={`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(unitDetail.customer_phone ? unitDetail.customer_phone : "")}`} target="_blank" rel="noopener noreferrer" style={{ marginTop: 2, }}>
                                                    {unitDetail.customer_phone}
                                                </a>
                                                :
                                                <span style={{ marginTop: 2, }}>
                                                    -
                                                </span>
                                        }
                                    </span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <TextField
                                    variant='outlined'
                                    multiline
                                    placeholder='Notes'
                                    rows={3}
                                    value={unitDetail.inventory_notes || ''}
                                    onChange={e => {
                                        const value = e.target.value
                                        setUnitDetail(prev => ({
                                            ...prev,
                                            inventory_notes: value,
                                        }))

                                        setIsInventoryNotesLoading(true)
                                        saveInventoryNotes(value)
                                    }}
                                />
                                <span style={{ fontSize: 10, textAlign: 'right', fontWeight: '500', opacity: .6 }}>
                                    {isInventoryNotesLoading ? 'Saving...' : 'Saved'}
                                </span>
                            </div>
                            <Button
                                onClick={generatePDF}
                                color="secondary"
                                fullWidth
                                variant="contained"
                            >
                                Generate PDF
                            </Button>
                            {
                                data.length ?
                                    data.map(room =>
                                        <div
                                            onClick={() => openRoom(room.id)}
                                            key={room.id}
                                            className={`${classes.roomCard} ${room.is_done === 1 ? classes.roomDone : ''}`}
                                        >
                                            {
                                                editRoomNameId !== room.id ?
                                                    <span className="title">
                                                        {room.name}
                                                    </span>
                                                    :
                                                    <TextField
                                                        value={editRoomNameValue}
                                                        onChange={e => {
                                                            setEditRoomNameValue(e.target.value)
                                                            setEditRoomNameError('')
                                                        }}
                                                        onClick={e => e.stopPropagation()}
                                                        variant="standard"
                                                        autoFocus
                                                        error={!!editRoomNameError}
                                                        helperText={editRoomNameError || undefined}
                                                    />
                                            }
                                            <div className="" style={{ minHeight: 40 }}>
                                                {
                                                    !isExtraRooms(room.name) ?
                                                        editRoomNameId !== room.id ?
                                                            <IconButton
                                                                className="edit-item"
                                                                onClick={e => {
                                                                    e.stopPropagation()
                                                                    editRoomName(room.id)
                                                                }}
                                                            >
                                                                <EditIcon style={{ color: '#F7A000' }} />
                                                            </IconButton>
                                                            :
                                                            <IconButton
                                                                className="edit-item"
                                                                onClick={e => {
                                                                    e.stopPropagation()
                                                                    saveRoomName(room.id)
                                                                }}
                                                            >
                                                                <CheckIcon style={{ color: '#57AF57' }} />
                                                            </IconButton>
                                                        :
                                                        null
                                                }
                                                {
                                                    !isExtraRooms(room.name) &&
                                                    <IconButton
                                                        className="delete-item"
                                                        onClick={e => {
                                                            e.stopPropagation()
                                                            removeRoom(room.id)
                                                        }}
                                                    >
                                                        <CancelIcon color="error" />
                                                    </IconButton>
                                                }
                                            </div>
                                        </div>
                                    )
                                    : !isLoading &&
                                    <span className={classes.empty}>
                                        Maaf, belum ada data ruangan
                                    </span>
                            }
                            <Button
                                onClick={openRoomForm}
                                color="primary"
                                fullWidth
                                variant="contained"
                                startIcon={<AddIcon />}
                            >
                                Add Room
                            </Button>
                        </div>
                        <div className={classes.bodyDesktop}>
                            <Paper>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={6} align='left'>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontWeight: '600',
                                                            }}
                                                        >
                                                            Inventory {unitDetail.unit_code}
                                                        </span>
                                                        {
                                                            unitDetail.activity !== '9' ?
                                                                <Button
                                                                    variant='contained'
                                                                    size='small'
                                                                    color='secondary'
                                                                    onClick={generatePDF}
                                                                >
                                                                    Generate PDF
                                                                </Button>
                                                                : null
                                                        }
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Unit Code
                                                </TableCell>
                                                <TableCell>
                                                    Unit Details
                                                </TableCell>
                                                <TableCell>
                                                    {unitDetail.activity === '2' ? 'TR' : 'PM'}
                                                </TableCell>
                                                <TableCell>
                                                    Owner
                                                </TableCell>
                                                <TableCell>
                                                    Tenant
                                                </TableCell>
                                                {
                                                    unitDetail.activity !== '9' ?
                                                        <TableCell>
                                                            Notes
                                                        </TableCell>
                                                        : null
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    {unitDetail.unit_code}
                                                </TableCell>
                                                <TableCell>
                                                    Tower {unitDetail.tower} | Lt. {unitDetail.floor_level} | No. {unitDetail.unit_number}
                                                </TableCell>
                                                <TableCell>
                                                    {unitDetail.activity === '2' ? (unitDetail.tr_name || '-') : (unitDetail.advisor_name || '-')}<br />
                                                    {
                                                        (unitDetail.activity === '2' ? unitDetail.tr_phone : unitDetail.advisor_phone) ?
                                                            <a href={`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(unitDetail.activity === '2' ? (unitDetail.tr_phone || '') : (unitDetail.advisor_phone || ""))}`} target="_blank" rel="noopener noreferrer" style={{ marginTop: 2, }}>
                                                                {unitDetail.activity === '2' ? unitDetail.tr_phone : unitDetail.advisor_phone}
                                                            </a>
                                                            :
                                                            <span style={{ marginTop: 2, }}>
                                                                -
                                                            </span>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {unitDetail.landlord_name || '-'}<br />
                                                    {
                                                        unitDetail.landlord_phone ?
                                                            <a href={`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(unitDetail.landlord_phone ? unitDetail.landlord_phone : "")}`} target="_blank" rel="noopener noreferrer" style={{ marginTop: 2, }}>
                                                                {unitDetail.landlord_phone}
                                                            </a>
                                                            :
                                                            <span style={{ marginTop: 2, }}>
                                                                -
                                                            </span>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {unitDetail.customer_name || '-'}<br />
                                                    {
                                                        unitDetail.customer_phone ?
                                                            <a href={`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(unitDetail.customer_phone ? unitDetail.customer_phone : "")}`} target="_blank" rel="noopener noreferrer" style={{ marginTop: 2, }}>
                                                                {unitDetail.customer_phone}
                                                            </a>
                                                            :
                                                            <span style={{ marginTop: 2, }}>
                                                                -
                                                            </span>
                                                    }
                                                </TableCell>
                                                {
                                                    unitDetail.activity !== '9' ?
                                                        <TableCell>
                                                            {
                                                                !isEditNotesOpen ?
                                                                    <>
                                                                        <span className="title">
                                                                            {unitDetail.inventory_notes || '-'}
                                                                        </span>
                                                                        <IconButton
                                                                            className="edit-item"
                                                                            onClick={e => {
                                                                                e.stopPropagation()
                                                                                setIsEditNotesOpen(true)
                                                                            }}
                                                                        >
                                                                            <EditIcon style={{ color: '#F7A000' }} />
                                                                        </IconButton>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <TextField
                                                                            variant='outlined'
                                                                            multiline
                                                                            placeholder='Notes'
                                                                            fullWidth
                                                                            rows={3}
                                                                            value={unitDetail.inventory_notes || ''}
                                                                            onChange={e => {
                                                                                const value = e.target.value
                                                                                setUnitDetail(prev => ({
                                                                                    ...prev,
                                                                                    inventory_notes: value,
                                                                                }))

                                                                                setIsInventoryNotesLoading(true)
                                                                                saveInventoryNotes(value)
                                                                            }}
                                                                            style={{
                                                                                width: 'calc(100% - 40px)',
                                                                            }}
                                                                        />
                                                                        <IconButton
                                                                            className="edit-item"
                                                                            onClick={e => {
                                                                                e.stopPropagation()
                                                                                setIsEditNotesOpen(false)
                                                                            }}
                                                                        >
                                                                            <CheckIcon style={{ color: '#57AF57' }} />
                                                                        </IconButton>
                                                                    </>
                                                            }
                                                        </TableCell>
                                                        : null
                                                }
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={3} align='left'>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontWeight: '600',
                                                            }}
                                                        >
                                                            Room List
                                                        </span>
                                                        <Button
                                                            onClick={openRoomForm}
                                                            color="primary"
                                                            size='small'
                                                            variant="contained"
                                                            startIcon={<AddIcon />}
                                                        >
                                                            Add Room
                                                        </Button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Name
                                                </TableCell>
                                                <TableCell align='center'>
                                                    Status
                                                </TableCell>
                                                <TableCell align='right'>
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                data.length ?
                                                    data.map(room =>
                                                        <TableRow key={room.id}>
                                                            <TableCell>
                                                                {
                                                                    editRoomNameId !== room.id ?
                                                                        <>
                                                                            <span className="title">
                                                                                {room.name}
                                                                            </span>
                                                                            {
                                                                                !isExtraRooms(room.name) &&
                                                                                <IconButton
                                                                                    className="edit-item"
                                                                                    onClick={e => {
                                                                                        e.stopPropagation()
                                                                                        editRoomName(room.id)
                                                                                    }}
                                                                                >
                                                                                    <EditIcon style={{ color: '#F7A000' }} />
                                                                                </IconButton>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <TextField
                                                                                value={editRoomNameValue}
                                                                                onChange={e => {
                                                                                    setEditRoomNameValue(e.target.value)
                                                                                    setEditRoomNameError('')
                                                                                }}
                                                                                onClick={e => e.stopPropagation()}
                                                                                variant="standard"
                                                                                autoFocus
                                                                                error={!!editRoomNameError}
                                                                                helperText={editRoomNameError || undefined}
                                                                            />
                                                                            <IconButton
                                                                                className="edit-item"
                                                                                onClick={e => {
                                                                                    e.stopPropagation()
                                                                                    saveRoomName(room.id)
                                                                                }}
                                                                            >
                                                                                <CheckIcon style={{ color: '#57AF57' }} />
                                                                            </IconButton>
                                                                        </>
                                                                }
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <Chip
                                                                    label={room.is_done ? 'Finished' : 'Unfinished'}
                                                                    color={room.is_done ? 'success' : 'default'}
                                                                />
                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                <Button
                                                                    variant='contained'
                                                                    size="small"
                                                                    onClick={() => {
                                                                        if (unitDetail.activity === '9') {
                                                                            return setPreviewVideo(room.video_url)
                                                                        }

                                                                        openRoom(room.id)
                                                                    }}
                                                                >
                                                                    {unitDetail.activity === '9' ? 'See Video' : 'See Items'}
                                                                </Button>
                                                                {
                                                                    !room.is_done &&
                                                                    <Button
                                                                        variant='contained'
                                                                        size="small"
                                                                        color='success'
                                                                        onClick={() => finishRoom(room)}
                                                                        style={{
                                                                            marginLeft: 8,
                                                                        }}
                                                                    >
                                                                        Finish
                                                                    </Button>
                                                                }
                                                                {
                                                                    !isExtraRooms(room.name) &&
                                                                    <Button
                                                                        variant='contained'
                                                                        color='error'
                                                                        size="small"
                                                                        onClick={() => removeRoom(room.id)}
                                                                        startIcon={<Delete />}
                                                                        style={{
                                                                            marginLeft: 8,
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                    : isLoading ?
                                                        <TableRow>
                                                            <TableCell colSpan={3} align='center'>
                                                                <CircularProgress />
                                                            </TableCell>
                                                        </TableRow>
                                                        :
                                                        <TableRow>
                                                            <TableCell colSpan={3} align='center'>
                                                                Maaf, belum ada data ruangan
                                                            </TableCell>
                                                        </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    </DialogContent>
                </Root>
            </Dialog>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bodyMobile: {
        padding: 16,
        display: 'grid',
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        gap: 20,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    bodyDesktop: {
        padding: 16,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    roomCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        boxShadow: '3px 5px 6px 0px rgba(0,0,0,0.1)',
        borderRadius: 6,
        padding: '4px 8px',
        cursor: 'pointer',

        '& > .title': {
            fontWeight: 500,
        },
    },
    roomDone: {
        backgroundColor: '#95FE95',
    },
    empty: {
        fontSize: 18,
        opacity: 0.85,
        textAlign: 'center',
    },
    unitDetail: {
        '& > .title': {
            marginBottom: 4,
            fontWeight: '600',
            width: '100%',
            display: 'block',
        },
        '& > .subtitle': {
            marginBottom: 8,
            width: '100%',
            display: 'block',
        },
    }
}), "InventoryRoomModal"
)

export default InventoryRoomModal

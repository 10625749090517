import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import LoadingScreen from '../../_components/LoadingScreen'

/**
 * Utils
 */
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation'
import { generalErrorHandler, inputNumber, renderToastSuccess } from '../../_utils/Helper'
import DefaultAxios from '../../_utils/DefaultAxios'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete'

interface BankVendorFormModalProps {
    isOpen: boolean
    onClose: () => void
    onSuccess: () => void
}

type State = {
    bank_type_id: string
    bank_type_label: string
    behalf_name: string
    number: string
    notes: string
}

const validationRules: IValidationRules = {
    bank_type_id: 'required',
    behalf_name: 'required',
    number: 'required',
}

const validationAlias: IValidationAlias = {
    bank_type_id: 'Bank',
    behalf_name: 'Atas Nama',
    number: 'Nomor Rekening',
}

const BankVendorFormModal = (props: BankVendorFormModalProps) => {
    const [state, setState] = useState<State>(initialState)
    const [error, setError] = useState<IValidationErrors<State>>({})

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!props.isOpen) {
            setState(initialState)
        }
    }, [props.isOpen])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = e.target
        const isNumber = ['number'].includes(name)

        setState(prev => ({
            ...prev,
            [name]: files ? files[0] : isNumber ? inputNumber(value) : value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const onSubmit = () => {
        const { isValid, errors } = validateData(state, validationRules, validationAlias)

        setError(errors)

        if (isValid) {
            setIsLoading(true)

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/bank-vendor`, state)
                .then(res => res.data)
                .then(data => {
                    props.onSuccess()
                    renderToastSuccess(`Berhasil tambah bank vendor`)
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            [name]: value.id,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            [name]: '',
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    return (
        <>
            <Dialog
                open={props.isOpen}
                onClose={props.onClose}
                closeAfterTransition
                fullWidth
                maxWidth="xs"
            >
                <LoadingScreen fullScreen open={isLoading} />
                <DialogTitle style={{ paddingBottom: 0 }}>
                    Tambah Bank
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3} sx={{ marginTop: 0 }}>
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                url={process.env.REACT_APP_API_URL + '/admin/autocomplete/bank-type'}
                                label="Bank"
                                name="bank_type_id"
                                initialQuery={state.bank_type_label}
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                errorText={error.bank_type_id}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                error={!!error.behalf_name}
                                label="Atas Nama"
                                variant="outlined"
                                name="behalf_name"
                                value={state.behalf_name}
                                onChange={onChange}
                                fullWidth
                                helperText={error.behalf_name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                error={!!error.number}
                                label="Nomor Rekening"
                                variant="outlined"
                                name="number"
                                value={inputNumber(state.number)}
                                onChange={onChange}
                                fullWidth
                                helperText={error.number}
                                type="number"
                            // placeholder="628xxxxxxxxxx"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!error.notes}
                                label="Notes"
                                variant="outlined"
                                name="notes"
                                value={state.notes}
                                onChange={onChange}
                                fullWidth
                                helperText={error.number}
                                multiline
                                minRows={3}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={props.onClose}
                                sx={{ marginLeft: 'auto' }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={onSubmit}
                                sx={{ marginLeft: 2 }}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

const initialState: State = {
    bank_type_id: '',
    bank_type_label: '',
    behalf_name: '',
    number: '',
    notes: '',
}

export default BankVendorFormModal

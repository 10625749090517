import { DialogTitle } from '@mui/material'
import React, { useCallback, useState } from 'react'
import SliderConfirmation from '../../../../_components/SliderConfirmation'

type Props = {
    open: boolean
    title: string
    content: React.ReactNode
    onSubmit: () => void
}

const useConfirm = () => {
    const [modalProps, setModalProps] = useState<Props>({
        open: false,
        title: '',
        content: <></>,
        onSubmit: () => { }
    });

    const confirm = useCallback((title: string, content: React.ReactNode) => {
        return new Promise<boolean>((resolve) => {
            setModalProps({
                open: true,
                title,
                content,
                onSubmit: () => {
                    setModalProps((prev) => ({ ...prev, open: false }));
                    resolve(true);
                }
            });
        });
    }, []);

    return { confirm, modalProps };
};

const ConfirmationModal = (props: Props) => {
    return (
        <SliderConfirmation
            onClose={() => { }}
            open={props.open}
            onSubmit={props.onSubmit}
            customTitle={
                <DialogTitle style={{ textAlign: 'center' }}>
                    {props.title}
                </DialogTitle>
            }
            customBody={props.content}
        />
    )
}

export { useConfirm }
export default ConfirmationModal
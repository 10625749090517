import React, { useEffect, useState, useReducer } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
    TextField
} from '@mui/material';
import Swal from 'sweetalert2';
import { format } from 'date-fns';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../../../_utils/DefaultAxios';
import { generalErrorHandler, renderErrorButton } from '../../../../_utils/Helper';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { TimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useTimeValidation';

interface IProp {
    closing_id: string;
    open: boolean;
    onClose?: any;
    type?: string
}

interface IState {
    due_date: Date | null;
    due_time: Date | null;
    notes: string | null;
    assigned_label: string;
    assigned_to: Number | null;
    key_collection_point: string
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const HandoverCheckInModal = (props: IProp) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorDateTime, setErrorDateTime] = useState<{
        due_date: DateValidationError,
        due_time: TimeValidationError
    }>({ due_date: null, due_time: null })

    const initialState = {
        notes: null,
        assigned_label: "",
        assigned_to: null,
        due_date: null,
        due_time: null,
        key_collection_point: '',
    }

    useEffect(() => {
        setInputState({ name: 'reset', value: 'reset', type: 'RESET_ITEM' });
    }, [props.open]);

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };
    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let value: any = target.value;
        let name = target.name;
        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const generateErrorDateTime = (err: DateValidationError | TimeValidationError) => {
        let errorText = ''
        switch (err) {
            case 'minDate':
                errorText = 'Tanggal berada dibawah batas minimal'
                break
            case 'invalidDate':
                errorText = 'Tanggal Waktu Tidak Valid'
                break
            default:
                return errorText
        }

        return errorText
    }

    const handleSubmit = (id: any) => {
        if (id) {
            setIsLoading(true);

            if (!inputState.due_date || !inputState.due_time) {
                renderErrorButton('Tanggal dan Time harus valid!')
                return setIsLoading(false)
            }

            if (inputState.key_collection_point === '') {
                renderErrorButton('Harap mengisi key collection point!')
                setIsLoading(false)
                return
            }

            const datas = {
                ...inputState,
                closing_id: props.closing_id,
                due_date: format(inputState.due_date as Date, "yyyy-MM-dd"),
                due_time: format(inputState.due_time as Date, "HH:mm"),
            };

            const api_url = '/closing-activity/handover-checkin'
            DefaultAxios.post(`${process.env.REACT_APP_API_URL + api_url}`, datas)
                .then(res => {
                    Swal.fire({
                        title: "Submit data berhasil",
                        icon: 'success',
                        timer: 1000
                    });
                    props.onClose(true)
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    };

    const handleDateTimeChange = (name: string) => (date: any, val: any) => {
        if (val) {
            if (date !== null) {
                setInputState({ name, value: date, type: 'SET_ITEM' })
            }
        } else {
            setInputState({ name, value: date, type: 'SET_ITEM' })
        }
    }

    const handleAutocompletePic = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.assigned_to = Number(value.id);
        newState.assigned_label = value.label;
        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog open={props.open} onClose={() => props.onClose(false)} aria-labelledby="form-dialog-title" closeAfterTransition>
                <DialogTitle id="form-dialog-title">Handover Checkin</DialogTitle>
                <DialogContent style={{ paddingTop: 5 }}>
                    <Grid container justifyContent="space-between" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                label="Tanggal"
                                inputFormat="dd/MM/yyyy"
                                minDate={new Date(new Date().setDate(new Date().getDate() - 7))}
                                value={inputState.due_date}
                                onChange={handleDateTimeChange('due_date')}
                                onError={(err) => setErrorDateTime(prev => ({ ...prev, due_date: err }))}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        variant="outlined"
                                        fullWidth
                                        label={props.label}
                                        placeholder={props.placeholder ? props.placeholder : "dd/MM/yyyy"}
                                        error={props.error ? props.error : false}
                                        helperText={props.error ? generateErrorDateTime(errorDateTime.due_date as DateValidationError) : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TimePicker
                                label="Time picker"
                                ampm={false}
                                value={inputState.due_time}
                                onChange={handleDateTimeChange('due_time')}
                                onError={(err) => setErrorDateTime(prev => ({ ...prev, due_time: err }))}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        variant="outlined"
                                        fullWidth
                                        label={props.label}
                                        placeholder={props.placeholder ? props.placeholder : "dd/MM/yyyy"}
                                        error={props.error ? props.error : false}
                                        helperText={props.error ? generateErrorDateTime(errorDateTime.due_time as TimeValidationError) : ''}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                label="Assign To"
                                name="assigned_to"
                                initialQuery={inputState.assigned_label}
                                onChange={handleAutocompletePic}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/user/field-assistant`}
                                placeholder="PIC Jendela"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Key Collection Point"
                                name="key_collection_point"
                                placeholder='Lokasi - Nama - No. HP atau Jendela360'
                                type="text"
                                value={inputState.key_collection_point}
                                onChange={handleChanged}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                id="notes"
                                label="Notes"
                                name="notes"
                                type="text"
                                value={inputState.notes}
                                multiline
                                rows={4}
                                onChange={handleChanged}
                                variant="outlined"
                                fullWidth
                                style={{ marginBottom: '10px' }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleSubmit(props.closing_id)
                        }}
                        color="primary"
                        disabled={!!errorDateTime.due_date || !!errorDateTime.due_time}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default HandoverCheckInModal;

import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
    Typography,
} from '@mui/material';

/**
 * Components
 */
import MuiDatePicker from '../../../../_components/MuiDatePicker';

/**
 * Utils
 */
import Swal from 'sweetalert2';
import DefaultAxios from '../../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../../_utils/Helper';

interface IProps {
    open: boolean;
    closingId: string;
    handleClose: Function;
}

const VerifyClosingModal = ({ open, closingId, handleClose }: IProps) => {
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const handleSubmit = () => {
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/new-closing/${closingId}/verify`, { first_payment_date: selectedDate })
            .then(res => {
                Swal.fire({
                    title: "Closing berhasil di-verify",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        handleClose();
                    })
            })
            .catch(error => {
                generalErrorHandler(error)
            })
    }

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={() => handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Verify Closing</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='body1' >
                            Masukkan tanggal pembayaran pertama closing ini.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 2}}>
                        <MuiDatePicker
                            disableToolbar
                            variant="inline"
                            margin="normal"
                            label="Tanggal Pembayaran Pertama"
                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default VerifyClosingModal
import React from 'react'

type Props = {}

const DeleteIcon = (props: Props) => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_431_6218)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M40 80C62.0308 80 80 62.0308 80 40C80 17.9692 62.0308 0 40 0C17.9692 0 0 17.9692 0 40C0 62.0308 17.9692 80 40 80Z" fill="#DE3333" />
                <path d="M57.2364 26.7825H22.7636C22.5689 26.7825 22.3833 26.8626 22.2494 27.0036C22.1145 27.1456 22.0445 27.3353 22.0547 27.53L23.8661 62.4815C23.9462 64.0364 25.2283 65.2545 26.7861 65.2545H53.2137C54.7717 65.2545 56.0538 64.0364 56.1338 62.4815L57.9452 27.53C57.9553 27.3353 57.8853 27.1456 57.7505 27.0036C57.6167 26.8626 57.4311 26.7825 57.2364 26.7825ZM28.7467 33.8286C28.7336 33.3529 28.907 32.8995 29.2356 32.5526C29.5642 32.2057 30.0075 32.007 30.4831 31.9928C31.473 31.9725 32.2945 32.7493 32.323 33.7312L32.9813 58.2662C32.9955 58.7409 32.822 59.1953 32.4923 59.5432C32.1638 59.8892 31.7195 60.0879 31.2428 60.1021H31.1902C30.7327 60.1021 30.2995 59.9287 29.9639 59.6103C29.6181 59.2817 29.4192 58.8384 29.4061 58.3637L28.7467 33.8286ZM38.2119 58.2875V33.7493C38.2119 32.7625 39.0142 31.9601 40 31.9601C40.9858 31.9601 41.7881 32.7625 41.7881 33.7493V58.2875C41.7881 59.2753 40.9858 60.0787 40 60.0787C39.0142 60.0787 38.2119 59.2754 38.2119 58.2875ZM50.5939 58.3615C50.5808 58.8382 50.3819 59.2815 50.0361 59.6101C49.7005 59.9286 49.2673 60.102 48.8098 60.102H48.7541C48.2784 60.0878 47.8352 59.889 47.5075 59.5422C47.1778 59.1943 47.0044 58.7409 47.0186 58.2642L47.6769 33.7332C47.7053 32.7504 48.5208 31.9684 49.5045 31.9928H49.5167C49.9923 32.007 50.4356 32.2057 50.7642 32.5526C51.0928 32.8995 51.2662 33.3529 51.2531 33.8286L50.5939 58.3615ZM60.7812 21.3145V23.197H19.2188V21.3145C19.2188 20.2759 20.0636 19.4311 21.1022 19.4311H34.7734C35.1355 19.4311 35.4398 19.1582 35.4794 18.7981C35.732 16.4876 37.6753 14.7451 40 14.7451C42.3247 14.7451 44.268 16.4876 44.5206 18.7981C44.5602 19.1581 44.8644 19.4311 45.2266 19.4311H58.8978C59.9364 19.4311 60.7812 20.2759 60.7812 21.3145Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_431_6218">
                    <rect width="80" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default DeleteIcon
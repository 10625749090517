import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Dialog, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material'
import ButtonWithLoading from '../../../_components/_form/ButtonWithLoading'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete'

/**
 * Utils
 */
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import { generalErrorHandler, renderToastSuccess } from '../../../_utils/Helper'
import DefaultAxios from '../../../_utils/DefaultAxios'

interface ListingTicketAddModalProps {
    isOpen: boolean
    onClose: () => void
    onFinish: () => void
}

type State = {
    type: string
    detail: string
    unit_id: string
    unit_label: string
    pic_id: string
    pic_label: string
    requester_id: string
    requester_label: string
}

const ListingTicketAddModal = (props: ListingTicketAddModalProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<State>(INITIAL_STATE)
    const [error, setError] = useState<IValidationErrors<State>>({})

    useEffect(() => {
        if (!props.isOpen) {
            setState(INITIAL_STATE)
            setError({})
        }
    }, [props.isOpen])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAutocomplete = (name: string, option: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            [`${name}_id`]: option.id.toString(),
            [`${name}_label`]: option.label,
        }))

        setError(prev => ({
            ...prev,
            [`${name}_id`]: '',
        }))
    }

    const handleAutocompleteInput = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            [`${name}_id`]: '',
            [`${name}_label`]: '',
        }))

        setError(prev => ({
            ...prev,
            [`${name}_id`]: '',
        }))
    }

    const onSubmit = () => {
        const { errors, isValid } = validateData(state, VALIDATION_RULES, VALIDATION_ALIAS)
        setError(errors)

        if (isValid) {
            setIsLoading(true)

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/listing-ticket`, {
                type: state.type,
                detail: state.detail,
                unit_id: state.unit_id,
                pic_id: state.pic_id,
                requested_by: state.requester_id,
            })
                .then(() => {
                    renderToastSuccess('Berhasil menambahkan data')
                    props.onClose()
                    props.onFinish()
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <Dialog
            open={props.isOpen}
            fullWidth
            maxWidth='xs'
            onClose={isLoading ? undefined : props.onClose}
        >
            <DialogTitle>Add Listing Ticket</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            label='Type'
                            name='type'
                            value={state.type}
                            onChange={onChange}
                            placeholder='Pilih type'
                            error={!!error.type}
                            helperText={error.type}
                            disabled={isLoading}
                        >
                            <MenuItem value='fu_new'>FU New Owner</MenuItem>
                            <MenuItem value='fu_exist'>FU Existing Owner</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/unit`}
                            initialQuery={state.unit_label}
                            label="Unit"
                            name='unit'
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInput}
                            errorText={error.unit_id}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user/active-listing-acquisition`}
                            initialQuery={state.pic_label}
                            label="PIC"
                            name='pic'
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInput}
                            errorText={error.pic_id}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user/active-staff`}
                            initialQuery={state.requester_label}
                            label="Requester"
                            name='requester'
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInput}
                            errorText={error.requester_id}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label='Detail'
                            name='detail'
                            value={state.detail}
                            onChange={onChange}
                            placeholder='Isi detail'
                            error={!!error.detail}
                            helperText={error.detail}
                            disabled={isLoading}
                            multiline
                            rows={3}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonWithLoading
                            variant='contained'
                            color='primary'
                            sx={{ float: 'right' }}
                            onClick={onSubmit}
                            isLoading={isLoading}
                        >
                            Submit
                        </ButtonWithLoading>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const VALIDATION_RULES: IValidationRules = {
    type: 'required',
    detail: 'required',
    unit_id: 'required_if:type=fu_exist',
    pic_id: 'required',
    requester_id: 'required',
}

const VALIDATION_ALIAS: IValidationAlias = {
    type: 'Type',
    detail: 'Detail',
    unit_id: 'Unit',
    pic_id: 'PIC',
    requester_id: 'Requester',
}

const INITIAL_STATE: State = {
    type: '',
    detail: '',
    unit_id: '',
    unit_label: '',
    pic_id: '',
    pic_label: '',
    requester_id: '',
    requester_label: '',
}

export default ListingTicketAddModal

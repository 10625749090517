import React from 'react'
import { Dialog, DialogContent } from '@mui/material'
import LandlordFormMain from '../../../_landlord/LandlordFormMain'

/* 
* Interfaces
*/
interface IProps {
    open: boolean
    onClose: (fetch: boolean) => void
    id: number
}


const LandlordEditModal = (props: IProps) => {
    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
        >
            <DialogContent>
                <LandlordFormMain
                    modal
                    id={props.id}
                    submitModal={() => props.onClose(true)}
                />
            </DialogContent>
        </Dialog>
    )
}

export default LandlordEditModal
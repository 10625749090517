import React, { useState, useReducer, useEffect, useContext } from 'react';
import {
    Paper,
    Box,
    Theme,
    Grid,
    Tabs,
    Tab,
    Button,
    Typography,
    IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

/**
 * Components
 */
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';
import LandlordPICModal from './_components/LandlordPICModal';
import LandlordFileTable from './_components/LandlordFileTable';
import LandlordUnitTable from './_components/LandlordUnitTable';
import TransactionHistory from '../_closing/_components/TransactionHistory';
import LandlordLogs from './_components/LandlordLogs';
import TextArea from '../../_components/_form/TextArea';
import BankTable from '../../_components/_dataTable/BankTable';
import LandlordEmailModal from './_components/LandlordEmailModal';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderErrorButton, renderSuccessButton, renderWarningButton } from '../../_utils/Helper';
import moment from 'moment';
import Swal from 'sweetalert2';

/** 
 * Icon & Style 
 */
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

/* 
* Contexts
*/
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';
import GeneralAutocompleteModal from '../../_components/_modal/GeneralAutocompleteModal';

interface IProps {
    permissions: any
    mode: "add" | "detail",
    onSubmitSuccess: () => void,
    landlordId: string
    open: boolean
    onClose: Function
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface IState {
    id: string,
    assigned_pic_name: string,
    name: string,
    // phone: string,
    pic_landlord_id: string,
    pic_landlord_name: string,
    pic_landlord_phone: string,
    alternative_contact: string,
    account_information_label: string,
    email: string,
    phone: string,
    notes: string,
    birth_date: string,
    religion: string,
    bank_type: string,
    bank_number: string,
    bank_behalf_name: string,
    address: string,
    ktp_number: string,
    npwp_number: string,
    kk_number: string,
    nik: string,
    profile: string,
    profile_last_author: string,
    profile_last_date: string,
    updated_at: string,
    author: any,
    created_at: string
    units_data: LandlordDetailUnit[]
    nationality: string
}

export interface LandlordDetailUnit {
    id: string;
    code: string;
    is_available: number;
    rent_date: string;
    apartment_name: string;
    category: number;
    basic_price_12: number;
    price_per_12_months: number;
    updated_at: string;
    tower: string
    floor_level: string
    unit_number: string
    pic_name: string
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative'
    },
    container: {
        backgroundColor: "#f3f3f3",
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
        marginTop: theme.spacing(5),
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    paperTab: {
        margin: "0"
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        paddingTop: '10px'
    },
    box: {
        padding: theme.spacing(2)
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "LandlordModal_Detail"
)

const LandlordModalDetail = (props: IProps) => {
    const [value, setValue] = React.useState(0);
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/landlord';
    const permissions = useContext(PermissionContext)
    const [loading, setLoading] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [modalState, setModalState] = useState(0);

    const [showFormProfile, setFormShowProfile] = useState(false);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                id: '',
                assigned_pic_name: '',
                name: '',
                // phone: '',
                alternative_contact: '',
                account_information_label: '',
                pic_landlord_name: '',
                pic_landlord_phone: '',
                pic_landlord_id: '',
                email: '',
                phone: '',
                notes: '',
                birth_date: '',
                religion: '',
                bank_type: '',
                bank_number: '',
                bank_behalf_name: '',
                address: '',
                ktp_number: '',
                npwp_number: '',
                kk_number: '',
                nik: '',
                profile: '',
                profile_last_author: '',
                profile_last_date: '',
                updated_at: '',
                author: '',
                created_at: '',
                units_data: [],
                nationality: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        id: '',
        assigned_pic_name: '',
        name: '',
        // phone: '',
        alternative_contact: '',
        account_information_label: '',
        pic_landlord_name: '',
        pic_landlord_phone: '',
        pic_landlord_id: '',
        email: '',
        phone: '',
        notes: '',
        birth_date: '',
        religion: '',
        bank_type: '',
        bank_number: '',
        bank_behalf_name: '',
        address: '',
        ktp_number: '',
        npwp_number: '',
        kk_number: '',
        nik: '',
        profile: '',
        profile_last_author: '',
        profile_last_date: '',
        updated_at: '',
        author: '',
        created_at: '',
        units_data: [],
        nationality: '',
    });

    const loadData = () => {
        setLoading(true);
        DefaultAxios
            .get(`${API_URL}/${props.landlordId}`)
            .then(res => {
                setInitialLoad(false);
                const data = res.data;
                setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
            })
            .catch((err) => {
                generalErrorHandler(err);
                props.onClose();
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (props.open) {
            setInitialLoad(true);
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
        }
    }

    const handleConnectAccountManual = () => {
        Swal.fire({
            title: 'Connect Manual',
            text: 'Account Information tidak ditemukan, apakah anda ingin men-set manual?',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Tidak',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya'
        })
            .then(res => {
                if (res.value) {
                    setModalState(3)
                }
            })
    }

    const handleConnectAccount = () => {
        renderWarningButton('Apakah anda yakin ingin mengkonek account landlord ini?')
            .then(res => {
                if (res.value) {
                    setLoading(true)
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/landlord/connect-account`, {
                        id: inputState.id
                    })
                        .then(res => {
                            renderSuccessButton('Connect Success!')
                            loadData()
                        })
                        .catch(err => {
                            if (err.response.status === 404) {
                                if (permissions['landlord.connect-account-manual']) {
                                    handleConnectAccountManual()
                                } else {
                                    renderErrorButton('Account tidak ditemukan')
                                }
                            } else {
                                generalErrorHandler(err)
                            }
                        })
                        .finally(() => setLoading(false))
                }
            })
    }

    const submitProfile = () => {
        let axios;
        const param = {
            profile: inputState.profile,
            id: inputState.id
        }
        axios = DefaultAxios.post(`${API_URL}/profile`, param)

        setFormShowProfile(false)
        axios
            .then(res => {
                loadData()
            })
    }

    return (
        <ModalDetail
            open={props.open}
            isLoading={loading}
            onCloseModal={() => props.onClose()}
            title={'Landlord Dashboard'}
            showEditButton={true}
            editButtonLink={`/landlord/edit/${props.landlordId}`}
        >
            <>
                <ModalSection
                    title="Informasi Dasar"
                    xs={12}
                    md={4}
                    content={[
                        ['Ownership Name', inputState.name],
                        [
                            <>
                                <span>PIC Information</span>
                                {
                                    permissions['pic-landlord.show'] && inputState.pic_landlord_id ?
                                        <IconButton
                                            title='Show PIC Detail Information'
                                            onClick={() => window.open(`/pic-landlord/${inputState.pic_landlord_id}`)}
                                        >
                                            <VisibilityIcon color='primary' />
                                        </IconButton>
                                        : null
                                }
                            </>,
                            inputState.pic_landlord_id ?
                                <>
                                    <span>{inputState.pic_landlord_name}</span> - <span>{inputState.pic_landlord_phone}</span>
                                    <IconButton href={`tel:${inputState.pic_landlord_phone}`} size="small" style={{ marginRight: "5px" }}>
                                        <PhoneIcon />
                                    </IconButton>
                                    <IconButton href={`https://wa.me/${inputState.pic_landlord_phone}`} size="small" target="_blank" rel="noopener noreferrer">
                                        <WhatsAppIcon />
                                    </IconButton>
                                </> :
                                '-'
                        ],
                        // ['Phone', inputState.phone],
                        ['Email', inputState.email],
                        ['Phone', inputState.phone],
                        ['Birth Date', inputState.birth_date ? moment(inputState.birth_date).format('DD MMM YYYY') : '-'],
                        ['Religion', inputState.religion ? inputState.religion : '-'],
                        ['Address', inputState.address],
                        ['Nationality', inputState.nationality],
                        ['Alternative Contact', inputState.alternative_contact],
                        [
                            <>
                                <span>Account</span>
                                {
                                    permissions['landlord.connect-account'] && !inputState.account_information_label ?
                                        <IconButton color='primary' onClick={handleConnectAccount} size="small" style={{ marginLeft: "5px" }}>
                                            <PersonAddIcon />
                                        </IconButton>
                                        : null
                                }
                            </>,
                            inputState.account_information_label ?
                                inputState.account_information_label :
                                "-"
                        ],
                        // ['Bank', inputState.bank_number === null ? '-' : (`${inputState.bank_type} - ${inputState.bank_number} an. ${inputState.bank_behalf_name}`)],
                    ]}
                />

                <ModalSection
                    title="Informasi Lainnya"
                    xs={12}
                    md={4}
                    content={[
                        ['KTP', inputState.ktp_number],
                        ['NPWP', inputState.npwp_number],
                        ['KK', inputState.kk_number],
                        // ['PIC',
                        //     <>
                        //         {inputState.assigned_pic_name === null ? '-' : inputState.assigned_pic_name}
                        //         {props.permissions['landlord.assign-pic'] &&
                        //             <IconButton
                        //                 color="primary"
                        //                 size="small"
                        //                 onClick={() => setModalState(2)}
                        //             >
                        //                 <EditIcon fontSize="inherit"></EditIcon>
                        //             </IconButton>
                        //         }
                        //     </>
                        // ],
                    ]}
                />

                {initialLoad === false &&
                    <>
                        {modalState === 2 &&
                            <LandlordPICModal
                                landlordId={inputState.id}
                                initialQuery={inputState.assigned_pic_name ? inputState.assigned_pic_name : ''}
                                handleClose={(reload = false) => {
                                    setModalState(0)

                                    if (reload) {
                                        loadData();
                                    }
                                }}
                            />
                        }
                    </>
                }

                <Grid item xs={12} md={4}>
                    <Root>
                        <Paper className={classes.paper}>
                            <Box className={classes.box} style={{ backgroundColor: "#009be5", borderRadius: "4px 4px 0px 0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={9}>
                                        <Typography
                                            variant="h5"
                                            style={{ fontWeight: "bold", color: "#fff" }}>
                                            Landlord Profile
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            style={{ width: '100%', color: "#fff", border: "none" }}
                                            onClick={() => setFormShowProfile(true)}
                                        >
                                            <EditIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.box}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        {showFormProfile ?
                                            <>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextArea
                                                            label="Note"
                                                            name="profile"
                                                            value={inputState.profile}
                                                            onChange={handleInputChanged}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} style={{ textAlign: 'right' }}>
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="primary"
                                                            onClick={submitProfile}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            :
                                            <>
                                                {inputState.author === null ?
                                                    <>
                                                        <Typography
                                                            variant="subtitle2"
                                                            style={{ fontWeight: "bold", whiteSpace: 'pre-line' }}>
                                                            {inputState.profile}
                                                        </Typography>
                                                        {/* <Typography
                                                                variant="subtitle2"
                                                            >
                                                                Updated at {moment(inputState.created_at).format('DD MMM YYYY')}
                                                            </Typography> */}
                                                    </>
                                                    :
                                                    <>
                                                        <Typography
                                                            variant="subtitle2"
                                                            style={{ fontWeight: "bold", whiteSpace: 'pre-line', marginBottom: "8px" }}>
                                                            {inputState.profile}
                                                        </Typography>
                                                        <Typography
                                                            variant="subtitle2"
                                                        >
                                                            Last modified by {inputState.author?.name} at {moment(inputState.updated_at).format('DD MMM YYYY')}
                                                        </Typography>
                                                    </>
                                                }
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Root>
                </Grid>
                {
                    permissions['pic-landlord.assign-pic-to-unit'] ?
                        <>
                            <Grid item xs={12} display='flex' flexDirection='row' alignItems='center' justifyContent='flex-end'>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        setIsAssignModalOpen(true)
                                    }}
                                >
                                    Assign PIC Listing to Units
                                </Button>
                            </Grid>
                            <GeneralAutocompleteModal
                                open={isAssignModalOpen}
                                label='PIC'
                                paramName='pic_listing_id'
                                title='Assign PIC Listing to Units'
                                extraParams={{
                                    id: props.landlordId
                                }}
                                url={`${process.env.REACT_APP_API_URL}/assign-pic-listing/assign-pic-units-landlord`}
                                autocompleteUrl={`${process.env.REACT_APP_API_URL}/autocomplete/user/active-listing-acquisition`}
                                onClose={() => {
                                    setIsAssignModalOpen(false)
                                }}
                                onSuccess={() => {
                                    setIsAssignModalOpen(false)
                                    renderSuccessButton('Berhasil assign pic listing to units')
                                    loadData()
                                }}
                            />
                        </>
                        : null
                }


                <Grid item xs={12}>
                    <Root>
                        <Paper className={classes.root}>
                            <div className={classes.paperTab}>
                                <Tabs
                                    style={{ backgroundColor: 'white' }}
                                    textColor="primary"
                                    value={value} onChange={handleChangeTab}
                                    aria-label="simple tabs example"
                                >

                                    <Tab label="Units" {...a11yProps(0)} />
                                    <Tab label="Bank" {...a11yProps(1)} />
                                    <Tab label="File" {...a11yProps(2)} />
                                    {props.permissions['landlord.log'] &&
                                        <Tab label="Logs" {...a11yProps(3)} />
                                    }
                                </Tabs>
                            </div>
                            <TabPanel value={value} index={0}>
                                <br></br>
                                <LandlordUnitTable
                                    units={inputState.units_data}
                                    landlordId={props.landlordId}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <br></br>
                                <BankTable
                                    id={props.landlordId}
                                    component="landlord"
                                />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <br></br>
                                <LandlordFileTable
                                    landlordId={props.landlordId}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <br></br>
                                <LandlordLogs
                                    landlordId={props.landlordId}
                                />
                            </TabPanel>
                        </Paper>
                    </Root>
                </Grid>
                <ModalSection
                    title="Transaction History"
                    content={[]}
                    additionalContent={
                        props.permissions['closing.view'] &&
                        <div style={{ marginTop: 25 }}>
                            <TransactionHistory
                                permissions={props.permissions}
                                personType={'landlord'}
                                landlordId={props.landlordId}
                            />
                        </div>
                    }
                />

                <LandlordEmailModal
                    open={modalState === 3}
                    onClose={(refresh: boolean) => {
                        setModalState(0)

                        if (refresh) {
                            loadData()
                        }
                    }}
                    id={inputState.id}
                />
            </>
        </ModalDetail>
    );
}

export default LandlordModalDetail;
import { Box, Divider, Grid, IconButton, Paper, Theme, Typography } from '@mui/material'
import React from 'react'

/**
 * Icons
 */
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import SimpleDataTable, { IColumn } from '../../../_components/_dataTable/SimpleDataTable';
import { generateStyle } from '../../../_utils/DefaultStyle';

type Props = {
    label: string,
    name: string,
    columnData: IColumn[],
    tableData: any,
    redraw: number
    onUpload?: (name: string) => void
    onDownload?: (name: string) => void
    customContent?: React.ReactElement
    customHeader?: React.ReactElement
    dashOnEmpty?: boolean
    disableButton?: boolean
}

const SectionCard = (props: Props) => {
    const { Root, classes } = useStyles()
    return (
        <Grid item xs={12} key="price_list">
            <Root>
                <Paper elevation={4} className={classes.paper}>
                    <div className={classes.titleContainer}>
                        <Typography variant='h5' sx={{ py: 1 }}>{props.label}</Typography>
                        {
                            typeof props.customHeader !== 'undefined' ?
                                props.customHeader :
                                props.disableButton ?
                                    null :
                                    <div>
                                        <IconButton size='large' color='primary' title='Export Data' onClick={() => props.onDownload && props.onDownload(props.name)}>
                                            <UploadIcon fontSize="large" />
                                        </IconButton>
                                        <IconButton size='large' color='primary' title='Import Data' onClick={() => props.onUpload && props.onUpload(props.name)}>
                                            <DownloadIcon fontSize="large" />
                                        </IconButton>
                                    </div>
                        }
                    </div>

                    <Divider></Divider>

                    {
                        typeof props.customContent !== 'undefined' ?
                            props.customContent :
                            <Box className={classes.content}>
                                <SimpleDataTable
                                    columns={props.columnData}
                                    customData={props.tableData}
                                    reDraw={props.redraw}
                                    dashOnEmpty={props.dashOnEmpty}
                                />
                            </Box>
                    }
                </Paper>
            </Root>
        </Grid>
    )
}

export default SectionCard

const useStyles = generateStyle((theme: Theme) => ({
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    box: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    content: {
        marginTop: theme.spacing(2),
    },
}), "SectionCard")
import React, { useEffect, useState } from 'react';
import { Button, TextField, Grid, Theme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2';
import { addDays } from 'date-fns';

/**
 * Components
 */
import { useStyles } from '../ClosingForm';
import MuiDatePicker from '../../../_components/MuiDatePicker';
import { ITermin } from './TerminForm';
import LoadingScreen from '../../../_components/LoadingScreen';

/**
 * Utils
 */
import { numberToCurrency } from '../../../_utils/Helper';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    open: boolean;
    onClose: (fetch: boolean) => void;
    step: number;
    closingId: string;
    terminId: string;
    termin: ITermin;
    minDate: Date;
}

interface IErrorState {
    start_date: string;
    end_date: string;
    amount: string;
    basic_price: string;
}

const useLocalStyles = generateStyle((theme: Theme) => ({
    terminTitleContainer: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    terminTitle: {
        fontSize: 16,
        fontWeight: 500,
        textAlign: 'center',
        textTransform: 'uppercase',
        marginTop: 4,
        marginBottom: 4,
    },
}), "TerminModal_Form"
)

const TerminModalForm = ({ open, onClose, step, termin, minDate, closingId, terminId }: Props) => {
    const { Root, classes } = useStyles();
    const { Root: LocalRoot, classes: localClasses } = useLocalStyles();

    const initialErrorState = {
        start_date: '',
        end_date: '',
        amount: '',
        basic_price: '',
    };

    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({ ...termin });
    const [errorState, setErrorState] = useState<IErrorState>(initialErrorState);

    useEffect(() => {
        setState({ ...termin });
    }, [termin]);

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const oldTermin = { ...state };

        const attribute = event.target.name;
        const value = event.target.value;

        if (['basic_price', 'amount'].includes(attribute)) {
            switch (attribute) {
                case 'amount':
                    oldTermin.amount = numberToCurrency(value);
                    break;
                case 'basic_price':
                    oldTermin.basic_price = numberToCurrency(value);
                    break;
                default:
                    break;
            }
        }
        setState(oldTermin);
    }

    // TODO: might break
    const handleDateChanged = (date: Date | null, name: string) => {
        const oldTermin = { ...state };
        if (name === 'start_date') {
            oldTermin.start_date = date!;
            oldTermin.end_date = addDays(date!, 1);
        } else {
            oldTermin.end_date = date!;
        }

        setState(oldTermin);
    }

    const checkValidation = () => {
        let thisTerminIsValid = true;
        const newError: IErrorState = { ...initialErrorState };

        if (state.amount === "") {
            newError.amount = 'Amount wajib diisi';
            thisTerminIsValid = false;
        }

        if (state.basic_price === "") {
            newError.basic_price = 'Basic Price wajib diisi';
            thisTerminIsValid = false;
        }

        setErrorState(newError);
        return thisTerminIsValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let data: any = { ...state };

        data.amount = data.amount.replace(/,/g, '');
        data.basic_price = data.basic_price.replace(/,/g, '');
        data.step = step;

        let axios;

        if (terminId) {
            axios = DefaultAxios.patch(`${process.env.REACT_APP_API_URL}/new-closing/${closingId}/termin/${terminId}`, data);
        } else {
            axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/${closingId}/termin`, data);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Termin Submitted",
                    icon: 'success',
                    onAfterClose: () => {
                        onClose(true);
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <>
            <Dialog open={open} onClose={() => onClose(false)} aria-labelledby="form-dialog-title">
                <Root>
                    <LocalRoot>
                        <LoadingScreen open={isLoading} fullScreen />
                        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} className={classes.terminContainer}>
                                <Grid item xs={12} className={localClasses.terminTitleContainer}>
                                    <p className={localClasses.terminTitle}>
                                        {`Termin ${step}`}
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MuiDatePicker
                                        label={`Termin ${step}`}
                                        value={state.start_date}
                                        minDate={minDate}
                                        onChange={(date: Date | null) => handleDateChanged(date, 'start_date')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MuiDatePicker
                                        label={`End Termin ${step}`}
                                        value={state.end_date}
                                        fullWidth
                                        minDate={addDays(state.start_date, 1)}
                                        onChange={(date: Date | null) => handleDateChanged(date, 'end_date')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Amount"
                                        name="amount"
                                        value={state.amount}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChanged(e)}
                                        className={classes.inputWithAdornment}
                                        error={!!errorState.amount}
                                        helperText={errorState.amount}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Basic Price"
                                        name="basic_price"
                                        value={state.basic_price}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChanged(e)}
                                        className={classes.inputWithAdornment}
                                        error={!!errorState.basic_price}
                                        helperText={errorState.basic_price}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onClose(false)} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit} color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </LocalRoot>
                </Root>
            </Dialog>
        </>
    );
}

export default TerminModalForm;
import React, { useCallback, useEffect, useRef } from 'react'

/**
 * Components
 */
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { renderErrorButton, renderSuccessButton } from '../../_utils/Helper'

interface UpdateApartmentPerformanceModalProps {
    isOpen: boolean
    onClose: () => void
    onFinish: () => void
}

const UpdateApartmentPerformanceModal = ({
    isOpen,
    onClose,
    onFinish,
}: UpdateApartmentPerformanceModalProps) => {
    const intervalRef = useRef<null | NodeJS.Timeout>(null)

    const sendData = useCallback(() => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/apartment-performance/generate`)
            .catch(error => {
                // ignore
            })
    }, [])

    const fetchData = useCallback(() => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/apartment-performance/status`)
            .then(res => res.data)
            .then(data => {
                // Close modal on process finished (data value is true)
                if (data) {
                    onClose()
                    renderSuccessButton('Process berhasil', onFinish)
                }
            })
            .catch(error => {
                // ignore
            })
    }, [onClose, onFinish])

    useEffect(() => {
        if (isOpen) {
            // Check API for the first time
            sendData()
            let counter = 1

            // Re-check API every 10s
            const interval = setInterval(() => {
                // Terminate interval on 5min (30 counter)
                if (counter >= 30) {
                    if (intervalRef.current) clearInterval(intervalRef.current)
                    onClose()
                    renderErrorButton('Performance Proses Gagal, Harap hubungi Admin', 'Proses Gagal')
                    return
                }

                fetchData()

                counter++
            }, 10000)

            intervalRef.current = interval
        }

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current)
            intervalRef.current = null
        }
    }, [isOpen, onClose, fetchData, sendData])

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={isOpen}
        >
            <DialogTitle>Performance sedang diproses</DialogTitle>
            <DialogContent>
                Modal akan tutup otomatis setelah proses selesai
                Bila setelah 5 menit belum tutup otomatis, harap hubungi admin
            </DialogContent>
        </Dialog>
    )
}

export default UpdateApartmentPerformanceModal
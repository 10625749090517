import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';

// Asset
import pdfPng from '../../../Assets/Images/png/pdf.png';
import { generalErrorHandler } from '../../../_utils/Helper';

interface Props {
    open: boolean;
    unitId: string;
    onClose: () => void
}

interface IState {
    file: File | null;
    url: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IErrorState {
    file: string;
}

const UploadOwnerDocumentModal = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/unit/' + props.unitId + '/file';

    const initialState = {
        file: null,
        url: ''
    };

    const initialErrorState = {
        file: ''
    };

    const [isLoading, setIsLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    useEffect(() => {
        if (props.open) {
            fetchFile();
        }
        // eslint-disable-next-line
    }, [props.open]);

    const fetchFile = () => {
        setIsLoading(true);
        DefaultAxios.get(API_URL)
            .then(res => {
                for (let file of res.data) {
                    if (file.notes === 'owner_document_ownership') {
                        setInputState({ name: 'url', value: file.url, type: 'SET_ITEM'});
                        break;
                    }
                }
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handlePreviewFile = (src: string, isImage: boolean) => {
        if (isImage) {
            setPreviewImage(src);
        } else {
            window.open(src);
        }
    }

    const renderImagePreview = () => {
        let src = '';
        let isImage = false;
        let filepath = inputState.url;

        let explode = filepath.split('?');
        const match = /(\.\w+$)/g.exec(explode[0]);

        if (match) {
            if (match[1] === '.pdf') {
                src = pdfPng;
            } else {
                src = filepath;
                isImage = true;
            }
        }

        return <img src={src} alt="owner_document_ownership" onClick={() => handlePreviewFile(filepath, isImage)} width="200px" style={{ cursor: 'pointer' }} />
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        if (target && target.files) {
            setInputState({ name: 'file', value: target.files[0], type: 'SET_ITEM' });
        } else {
            setInputState({ name: 'file', value: null, type: 'SET_ITEM' });
        }
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.file) {
            newError.file = 'File wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        const fd = new FormData();
        fd.append('file', inputState.file as File);

        setIsLoading(true);
        DefaultAxios.post(`${API_URL}/owner-document`, fd)
            .then(res => {
                Swal.fire({
                    title: "Berhasil mengupload",
                    icon: 'success',
                    timer: 1000,
                    onAfterClose: () => {
                        props.onClose();
                        setInputState({ name: '', value: '', type: 'RESET_ITEM' });
                    }
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                open={!!previewImage}
                onClose={() => { setPreviewImage(undefined) }}
                scroll="body"
                maxWidth={false}
            >
                <img src={previewImage} width="100%" alt="preview" />
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="md"
                open={props.open} 
                onClose={props.onClose}
            >
                <DialogTitle>Upload Owner Document Ownership</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {
                            inputState.url
                                ? <Grid item xs={12}>
                                    {renderImagePreview()}
                                </Grid>
                                : null
                        }
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                type="file"
                                variant="outlined"
                                name="file"
                                onChange={handleChange}
                                error={!!errorState.file}
                                helperText={errorState.file}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default UploadOwnerDocumentModal;
import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { Button, Dialog, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material'
import MuiDatePicker from '../../../../_components/MuiDatePicker'
import ButtonUploadSlim from '../../../../_components/ButtonUploadSlim'
import LoadingScreen from '../../../../_components/LoadingScreen'

/**
 * Utils
 */
import { DEPOSIT_TYPES, DepositItem } from './DepositListModal'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../../_utils/Validation'
import { convertNumber, generalErrorHandler, inputNumber, renderToastSuccess } from '../../../../_utils/Helper'
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { format } from 'date-fns'

interface DepositFormModalProps {
    data: 'add' | DepositItem | false
    onClose: () => void
    onSuccess: () => void
    closingId: string
}

type State = {
    id: number | null
    type: 'fixing_cleaning' | 'utilities' | 'maintenance_fee' | 'maintenance_fee_and_utilities' | 'internet' | 'cutoff' | 'fine' | 'electric_token' | 'other' | ''
    other_description: string
    start_period: Date | null
    end_period: Date | null
    amount: string
    notes: string
    proof_url: File | null
}

const DepositFormModal = (props: DepositFormModalProps) => {
    const [state, setState] = useState<State>(initialState)
    const [error, setError] = useState<IValidationErrors<State>>({})

    const [isLoading, setIsLoading] = useState(false)

    const validationRules: IValidationRules = useMemo(() => ({
        type: 'required',
        start_period: ['cutoff', 'other', 'fixing_cleaning', 'electric_token'].includes(state.type) ? '' : 'required',
        end_period: ['cutoff', 'other', 'fixing_cleaning', 'electric_token'].includes(state.type) ? '' : 'required',
        amount: 'required',
        other_description: ['other', 'fixing_cleaning'].includes(state.type) ? 'required' : '',
    }), [state])

    const validationAlias: IValidationAlias = {
        type: 'Tipe',
        start_period: 'Start Period',
        end_period: 'End Period',
        amount: 'Nominal',
        other_description: 'Deskripsi',
    }

    useEffect(() => {
        if (props.data && props.data !== 'add') {
            setState(prev => ({
                ...prev,
                ...(props.data as DepositItem),
                start_period: (props.data as DepositItem).start_period ? new Date((props.data as DepositItem).start_period) : prev.start_period,
                end_period: (props.data as DepositItem).end_period ? new Date((props.data as DepositItem).end_period) : prev.end_period,
                amount: convertNumber((props.data as DepositItem).amount.toString()),
                proof_url: null,
                notes: (props.data as DepositItem).notes || '',
                other_description: (props.data as DepositItem).other_description || '',
                type: (props.data as DepositItem).type || '',
            }))
        } else {
            setState(initialState)
        }
    }, [props.data])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = e.target
        const isNumber = ['amount'].includes(name)

        setState(prev => ({
            ...prev,
            [name]: files ? files[0] : isNumber ? convertNumber(value) : value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleDateChange = (name: string, date: Date | null) => {
        setState(prev => ({
            ...prev,
            [name]: date,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const onSubmit = () => {
        const { isValid, errors } = validateData(state, validationRules, validationAlias)

        setError(errors)

        if (isValid) {
            setIsLoading(true)

            const fd = new FormData()

            fd.append('closing_id', props.closingId)
            fd.append('type', state.type)
            fd.append('other_description', state.other_description)
            fd.append('amount', inputNumber(state.amount))
            if (state.start_period) fd.append('start_period', format(state.start_period, 'yyyy-MM-dd'))
            if (state.end_period) fd.append('end_period', format(state.end_period, 'yyyy-MM-dd'))
            fd.append('notes', state.notes)
            if (state.proof_url) fd.append('proof', state.proof_url)
            if (state.id) fd.append('_method', 'PATCH');

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-deposit${state.id ? `/${state.id}` : ''}`, fd)
                .then(res => res.data)
                .then(data => {
                    props.onSuccess()
                    renderToastSuccess(`Berhasil ${state.id ? 'ubah' : 'tambah'} data deposit`)
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <>
            <Dialog
                open={!!props.data}
                onClose={props.onClose}
                closeAfterTransition
                fullWidth
                maxWidth="xs"
            >
                <LoadingScreen fullScreen open={isLoading} />
                <DialogTitle style={{ paddingBottom: 0 }}>
                    {(!props.data || props.data === 'add') ? 'Tambah Data Baru' : (props.data as DepositItem).uploadOnly ? `Upload Bukti` : `Ubah Data ${props.data.id}`}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3} sx={{ marginTop: 0 }}>
                        {
                            (props.data === 'add' || !(props.data as DepositItem).uploadOnly) &&
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        label="Tipe"
                                        name="type"
                                        placeholder='Pilih Tipe Deposit'
                                        value={state.type}
                                        onChange={onChange}
                                        error={!!error.type}
                                        helperText={error.type}
                                    >
                                        {
                                            DEPOSIT_TYPES.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
                                        }
                                    </TextField>
                                </Grid>
                                {
                                    ['cutoff', 'other', 'fixing_cleaning', 'electric_token'].includes(state.type) ?
                                        null
                                        :
                                        <>
                                            <Grid item xs={12}>
                                                <MuiDatePicker
                                                    label="Start Period"
                                                    value={state.start_period}
                                                    onChange={(date: Date | null) => handleDateChange('start_period', date)}
                                                    error={!!error.start_period}
                                                    helperText={error.start_period}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MuiDatePicker
                                                    label="End Period"
                                                    value={state.end_period}
                                                    onChange={(date: Date | null) => handleDateChange('end_period', date)}
                                                    error={!!error.end_period}
                                                    helperText={error.end_period}
                                                    minDate={state.start_period}
                                                    disabled={state.start_period === null}
                                                />
                                            </Grid>
                                        </>
                                }
                                {
                                    ['other', 'fixing_cleaning'].includes(state.type) ?
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Deskripsi"
                                                name="other_description"
                                                placeholder='Masukkan Deskripsi'
                                                value={state.other_description}
                                                onChange={onChange}
                                                error={!!error.other_description}
                                                helperText={error.other_description}
                                            />
                                        </Grid>
                                        :
                                        null
                                }
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Nominal"
                                        name="amount"
                                        placeholder='Masukkan Nominal'
                                        value={convertNumber(state.amount)}
                                        onChange={onChange}
                                        error={!!error.amount}
                                        helperText={error.amount}
                                    />
                                </Grid>
                                {
                                    ['cutoff', 'electric_token'].includes(state.type) ?
                                        null
                                        :
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                variant="outlined"
                                                label="Notes"
                                                name="notes"
                                                placeholder='Masukkan Notes'
                                                value={state.notes}
                                                onChange={onChange}
                                                error={!!error.notes}
                                                helperText={error.notes}
                                            />
                                        </Grid>
                                }
                            </>
                        }
                        <Grid item xs={12}>
                            <ButtonUploadSlim
                                label={(props.data === 'add' || !(props.data as DepositItem).uploadOnly) ? 'Upload Bukti' : ''}
                                name="proof_url"
                                onChange={onChange}
                                error={!!error.proof_url}
                                helperText={error.proof_url}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={props.onClose}
                                sx={{ marginLeft: 'auto' }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={onSubmit}
                                sx={{ marginLeft: 2 }}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

const initialState: State = {
    id: null,
    other_description: '',
    end_period: null,
    start_period: null,
    amount: '',
    notes: '',
    proof_url: null,
    type: '',
}

export default DepositFormModal

import React from 'react';
import { TextField } from '@mui/material';

interface Props {
    label?: string;
    name: string;
    value: any;
    onChange: (event: any) => void;
    rowsMax?: number;
    placeholder?: string;
    rows?: number;
    error?: boolean;
    helperText?: string;
    disabled?: boolean
}

const TextArea = (props: Props) => {
    return (
        <TextField
            fullWidth
            multiline
            /* Suggested from MUI */
            // maxRows={props.rowsMax}
            rows={props.rows}
            variant="outlined"
            label={props.label}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            error={props.error}
            helperText={props.helperText}
            disabled={props.disabled}
        />
    );
}

TextArea.defaultProps = {
    rowsMax: 3,
    rows: 3
}

export default TextArea;
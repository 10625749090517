import React, { useState } from 'react';
import { Switch, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle(() =>
({
    actionContainer: {
        display: 'flex'
    },
    addButton: {
        marginBottom: '20px'
    }
}), "ProjectDevList"
)

const ProjectDeveloperList = () => {
    const API_URL = `${process.env.REACT_APP_API_URL}/project-developer`;
    const { Root, classes } = useStyles();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const toggleActive = (id: string, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/toggle-active`, { id })
                        .then(res => {
                            const checked = res.data;
                            const newRows = rows.map((element: any) => {
                                if (element.id === id) element['is_active'] = checked ? 1 : 0
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => navigate('project-developer/add')}
            >
                Add New Project
            </Button>
            <DataTable
                url={API_URL}
                columns={[
                    {
                        name: 'name',
                        label: 'Nama',
                        type: 'string',
                        filterable: true,
                        render: (data: any, row: any) => {
                            return (
                                <Link
                                    href="#"
                                    onClick={() => navigate(`/developer/project/${row.id}`)}
                                >
                                    {data}
                                </Link>
                            );
                        }
                    },
                    {
                        name: 'slug',
                        label: 'Link',
                        sortable: false,
                        filterable: false,
                        render: (data: any, row: any) => {
                            return (
                                <a href={`${process.env.REACT_APP_MAIN_URL}/projects/${row.slug}`} target="_blank" rel="noopener noreferrer">[LINK]</a>
                            )
                        }
                    },
                    {
                        name: 'developer_name',
                        label: 'Developer',
                        type: 'string',
                        filterable: true
                    },
                    {
                        name: 'pic_name',
                        label: 'PIC Name',
                        type: 'string',
                        filterable: true
                    },
                    {
                        name: 'pic_phone',
                        label: 'PIC Phone',
                        type: 'string',
                        filterable: true
                    },
                    {
                        name: 'pic_whatsapp',
                        label: 'PIC Whatsapp',
                        type: 'string',
                        filterable: true
                    },
                    {
                        name: 'is_active',
                        label: 'Toggle',
                        filterable: true,
                        sortable: false,
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '1',
                                value: 'Active'
                            },
                            {
                                key: '0',
                                value: 'In-Active'
                            }
                        ],
                        defaultOption: '',
                        render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                            return (
                                <Switch
                                    checked={+data === 1}
                                    onChange={() => toggleActive(row.id, setRows, rows)}
                                    color="secondary"
                                />
                            );
                        }
                    },
                    // {
                    //     name: 'EXTRA',
                    //     label: 'Action',
                    //     sortable: false,
                    //     render: (row: any) => {
                    //         return (
                    //             <div className={classes.actionContainer}>
                    //                 <IconButton
                    //                     color="primary"
                    //                     component="span"
                    //                     onClick={() => navigate(`project-developer/edit/${row.id}`)}
                    //                 >
                    //                     <EditIcon fontSize="small" />
                    //                 </IconButton>
                    //                 <IconButton
                    //                     color="secondary"
                    //                     component="span"
                    //                     onClick={() => handleDelete(row.id)}
                    //                 >
                    //                     <DeleteIcon fontSize="small" />
                    //                 </IconButton>
                    //             </div>
                    //         );
                    //     }
                    // }
                ]}
            />
        </Root>
    );
}

export default ProjectDeveloperList;
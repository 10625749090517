import React, { useState } from 'react'
import { Button, ButtonGroup, Grid, IconButton, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

/**
 * Components
 */
import MenuBar from '../../_components/MenuBar'
import LoadingScreen from '../../_components/LoadingScreen'
import DataTable from '../../_components/_dataTable/DataTable'

/**
 * Utils
*/
import { format } from 'date-fns'
import DefaultAxios from '../../_utils/DefaultAxios'
import id from 'date-fns/locale/id'
import { generalErrorHandler, renderSuccessButton } from '../../_utils/Helper'

/**
 * ICon
 */
import NotesIcon from '@mui/icons-material/Notes';
import NotesModal from '../_jhsOrder/_components/NotesModal'
import { PAID_BY_OPTIONS } from '../../_utils/ConstData'


/**
 * Interfaces
 */

interface IJHSOrder {
    id: number
    code: string
    apartment_name: string
    unit_code: string
    request_date: string
    request_time: string | null
    key_collection_point: string
    notes: string | null
    next_schedule: string
}

type Props = {}

const detailTypeOption = [
    { label: 'Cleaning', value: 'jhs_cleaning' },
    { label: 'Fixing', value: 'jhs_fixing' },
    { label: 'AC', value: 'jhs_ac' },
]

const DashboardJHS = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [selectedRow, setSelectedRow] = useState<IJHSOrder | null>(null)
    const [notesModal, setNotesModal] = useState(false)
    const [selectedDetail, setSelectedDetail] = useState('jhs_cleaning')
    const [redrawTable, setRedrawTable] = useState(0)

    const handleSeeNotes = (row: IJHSOrder) => {
        setSelectedRow(row)
        setNotesModal(true)
    }

    const jhsOrderColumn = [
        { name: 'created_at', type: 'date', label: 'Created Date', filterable: true, render: (data: any) => data ? format(new Date(data), 'dd MMMM yyyy', { locale: id }) : '-' },
        {
            name: 'code', label: 'Code', filterable: true, render: (data: string, row: IJHSOrder) => {
                return (
                    <Link
                        style={{ cursor: "pointer" }}
                        component={RouterLink}
                        to={`/jhs-order/${row.id}`}
                    >
                        {data ? data : '[KLIK]'}
                    </Link>
                )
            }
        },
        { name: 'apartment_name', label: 'Apartement', filterable: true },
        { name: 'unit_code', label: 'Unit', filterable: true },
        { name: 'request_date', type: 'date', label: 'Request Date', filterable: true, render: (data: any) => data ? format(new Date(data), 'dd MMMM yyyy', { locale: id }) : '-' },
        { name: 'request_time', label: 'Request Time', filterable: false, render: (data: any) => data ? format(new Date(`1990-01-01T${data}`), 'HH:mm', { locale: id }) : '-' },
        {
            name: 'requester_name', label: 'Requester', filterable: true, render: (data: any, row: any) => {
                return data;
            }
        },
        {
            name: 'paid_by',
            label: 'Paid By',
            filterable: true,
            options: PAID_BY_OPTIONS,
            defaultOption: '',
            render: (data: any, row: any) => {
                return (
                    PAID_BY_OPTIONS.filter(option => option.key === data)[0]?.value || '-'
                );
            }
        },
        { name: 'key_collection_point', label: 'Key Collection Point', filterable: true },
        { name: 'next_schedule', type: 'date', label: 'Next Schedule', filterable: true },
        {
            name: 'status_label',
            label: 'Status',
            filterable: true,
            options: [
                { key: '', value: 'All', },
                { value: 'New', key: 'new' },
                { value: 'On Process', key: 'on_process' },
                { value: 'Unpaid', key: 'unpaid' },
                { value: 'Done', key: 'done' },
                { value: 'Cancel', key: 'cancel' },

            ],
            defaultOption: '',
            render: (data: any, row: any) => {
                return (
                    row.status_label
                );
            }
        },
        {
            name: `notes`, label: 'Notes', filterable: true, render: (data: any, row: any) => {
                return (
                    <IconButton
                        color='primary'
                        onClick={() => handleSeeNotes(row)}
                    >
                        <NotesIcon />
                    </IconButton>
                );
            }
        },
    ]

    const handleSubmitNotes = (notes: string) => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/change-jhs-notes`, {
            id: selectedRow?.id,
            notes,
            type: selectedDetail.replace('jhs_', '')
        })
            .then(res => res.data)
            .then(res => {
                renderSuccessButton('Berhasil ubah notes!')
                setNotesModal(false)
                setSelectedRow(null)
                setRedrawTable(new Date().getTime())
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <MenuBar
            title='Dashboard JHS Admin'
            backButton={false}
        >
            <LoadingScreen fullScreen open={isLoading} />
            <NotesModal
                onClose={() => {
                    setNotesModal(false)
                }}
                onSubmit={(notes: string) => handleSubmitNotes(notes)}
                defaultNotes={selectedRow ? selectedRow.notes as string : ''}
                open={notesModal}

            />
            <Grid container direction="column" spacing={2}>
                <Grid item xs={12} md={4}>
                    <ButtonGroup
                        size='large'
                    >
                        {detailTypeOption.map(detail => (
                            <Button
                                variant={detail.value === selectedDetail ? 'contained' : 'outlined'}
                                onClick={() => setSelectedDetail(detail.value)}
                            >
                                {detail.label}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        reDraw={redrawTable}
                        customParams={{
                            detail_type: selectedDetail
                        }}
                        columns={jhsOrderColumn}
                        url={`${process.env.REACT_APP_API_URL}/dashboard-jhs-admin`}
                    />
                </Grid>
            </Grid>
        </MenuBar>
    )
}

export default DashboardJHS
import React, { useState, useReducer, useEffect, useContext } from 'react';
import {
    Button,
    Theme,
    Link,
    Switch,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    FormControlLabel,
    Tooltip,
    BottomNavigation,
    BottomNavigationAction,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import TextArea from '../../_components/_form/TextArea';
import LoadingScreen from '../../_components/LoadingScreen';
import MuiDatePicker from '../../_components/MuiDatePicker';
import UnitModalDetail from '../_unit/UnitModalDetail';
import AssignedAdvisorModal from '../_unit/_components/_modals/AssignedAdvisorModal';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { PermissionContext } from '../../_contexts/PermissionContext';

/**
 * Types
 */
import { IColumn } from '../../_components/_dataTable/DataTable';
import { dateFormat, generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IParams extends Record<string, string | undefined> {
    id?: string
}

interface IAvailableState {
    date: Date | null;
    comment: string;
    endOfAvailability: boolean;
}

interface IAction {
    name: string;
    value: any;
    type: string;
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    filterButton1: {
        color: '#fff',
        backgroundColor: '#f7f7f7'
    },
    filterButton2: {
        color: '#fff',
        backgroundColor: '#DFF2FF'
    },
    filterButton3: {
        color: '#fff',
        backgroundColor: '#c7f4ad'
    },
    filterButton4: {
        color: '#fff',
        backgroundColor: '#feda08'
    },
    filterButton5: {
        color: '#fff',
        backgroundColor: '#f59456'
    },
    filterButton6: {
        color: '#fff',
        backgroundColor: '#f56c6c'
    },
    filterButtonSelected: {
        color: '#fff!important',
        backgroundColor: '#00aeef'
    },
    filterButtonLabel1: {
        color: '#fff',
    },
    filterButtonLabel2: {
        color: '#00aeef',
    },
}), "EntrustkeyReport"
)

const EntrustedKeyReport = () => {
    const permissions = useContext(PermissionContext)
    const DT_API_URL = process.env.REACT_APP_API_URL + '/entrusted-key-report';
    const API_URL = process.env.REACT_APP_API_URL + '/unit';
    const { Root, classes } = useStyles();
    const navigate = useNavigate();
    const params = useParams<IParams>()
    const [modalState, setModalState] = useState(0);
    const [redrawIndex, setRedrawIndex] = useState(0);
    const [filter, setFilter] = useState('');
    const [vacantDays, setVacantDays] = useState({
        "rented": 0,
        "<=20": 0,
        ">20": 0,
        ">40": 0,
        ">60": 0,
        "total": 0
    });
    const [assignedAdvisorState, setAssignedAdvisorState] = useState({
        name: "",
        advisor_id: "",
        unit_id: "",
    })

    const handleCloseDetail = () => {
        setModalState(0);
        window.history.pushState([], 'Entrusted Key Report', `/entrusted-key-report`);
    };

    const columns: IColumn[] = [
        {
            name: 'code',
            label: 'Unit Code',
            filterable: true,
            type: 'string',
            width: "150",
            render: (data: string, row: any) => {
                return (
                    <div>
                        <Link
                            onClick={() => navigate(`/entrusted-key-report/${row.code}`)}
                            component="button"
                        >
                            {data}
                        </Link>
                        <IconButton color="primary" href={`${process.env.REACT_APP_MAIN_URL}/${row.code}`} target="_blank">
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                )
            }
        },
        {
            name: 'bedroom',
            label: 'Type',
            filterable: true,
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'Studio'
                },
                {
                    key: '1',
                    value: '1BR'
                },
                {
                    key: '2',
                    value: '2BR'
                },
                {
                    key: '3',
                    value: '3BR'
                },
            ],
            render: (data: number) => {
                return data > 0 ? data + 'BR' : 'Studio';
            }
        },
        {
            name: 'apartment_name',
            label: 'Apartemen',
            filterable: true,
            type: 'string'
        },
        {
            name: 'vacant_days',
            label: 'Vacant Days',
            filterable: false,
            type: 'string',
            render: (data: any) => {
                return +data === 0 ? 'Rented' : data;
            }
        },
        {
            name: 'pic_name',
            label: 'Owner Name',
            filterable: true,
            type: 'string'
        },
        {
            name: 'assigned_advisor_name',
            label: 'Assigned Property Manager',
            filterable: true,
            width: "200",
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return (
                    <>
                        {data}
                        {permissions['unit.change-assigned-advisor'] &&
                            <>
                                <Tooltip title="Change Advisor">
                                    <IconButton color="primary" onClick={() => {
                                        setAssignedAdvisorState({
                                            unit_id: row.id,
                                            name: row.assigned_advisor_name,
                                            advisor_id: row.assigned_advisor_id,
                                        })
                                        setModalState(2)
                                    }} size="small">
                                        <EditIcon style={{ fontSize: "inherit" }} />
                                    </IconButton>
                                </Tooltip>
                                {row.assigned_advisor_id &&
                                    <Tooltip title="Change Advisor">
                                        <IconButton color="secondary" onClick={() => handleUnassignAdvisor(row.id, setRows, rows)} size="small">
                                            <HighlightOffIcon style={{ fontSize: "inherit" }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </>
                        }
                    </>
                )
            }
        },
        {
            name: 'is_owner',
            label: 'Ownership',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Owner'
                },
                {
                    key: '0',
                    value: 'Agent'
                }
            ],
            render: (data: number) => {
                return data === 1 ? 'Owner' : 'Agent';
            }
        },
        {
            name: 'tower',
            label: 'Tower',
            filterable: true,
            type: 'string'
        },
        {
            name: 'floor_level',
            label: 'Lantai',
            filterable: true,
            type: 'string'
        },
        {
            name: 'unit_number',
            label: 'No. Unit',
            filterable: true,
            type: 'string'
        },
        {
            name: 'is_special_price',
            label: 'Special Price',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return permissions['unit.switch-special-price']
                    ? <Switch
                        checked={data === 1}
                        onChange={() => handleSpecialPriceSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                    : (data === 1 ? 'Yes' : 'No')
            }
        },
        {
            name: 'is_available',
            label: 'Available',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Available'
                },
                {
                    key: '0',
                    value: 'Unavailable'
                }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                let permission;

                if ([1, 3].includes(+row.category)) {
                    permission = permissions['unit.switch-available-star'];
                } else {
                    permission = permissions['unit.switch-available'];
                }

                return permission
                    ? <Switch
                        checked={data === 1}
                        onChange={() => handleAvailableSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                    : (data === 1 ? 'Yes' : 'No')
            }
        },
        {
            name: 'is_available_sell',
            label: 'Available Sell',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Available'
                },
                {
                    key: '0',
                    value: 'Unavailable'
                }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return permissions['unit.switch-available']
                    ? <Switch
                        checked={data === 1}
                        onChange={() => handleAvailableSellSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                    : (data === 1 ? 'Yes' : 'No')
            }
        },
        {
            name: 'is_active',
            label: 'Published',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Published'
                },
                {
                    key: '0',
                    value: 'Unpublished'
                }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return permissions['unit.switch-active']
                    ? <Switch
                        checked={data === 1}
                        onChange={() => handlePublishSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                    : (data === 1 ? 'Yes' : 'No')
            }
        },
        {
            name: 'is_entrusted_key',
            label: 'Titip Kunci',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                if (permissions['unit.switch-key']) {
                    return <Switch
                        checked={data === 1}
                        onChange={() => handleEntrustedKeySwitch(row, setRows, rows)}
                        color="secondary"
                    />
                } else {
                    return data === 1 ? "YES" : "NO"
                }
            }
        },
        {
            name: 'category',
            label: 'Category',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'Reguler'
                },
                {
                    key: '2',
                    value: 'Verified'
                },
                {
                    key: '1',
                    value: 'Star Listing'
                }
            ],
            render: (data: number) => {
                switch (data) {
                    case 0:
                        return 'Reguler';
                    case 1:
                        return 'Star Listing';
                    case 2:
                        return 'Verified';
                    case 3:
                        return 'SLOH'
                    default:
                        return 'N/A';
                }
            }
        },
        // {
        //     name: 'updated_at',
        //     label: 'Last Modified',
        //     filterable: false,
        //     type: 'string',
        //     render: (data: string, row: any) => {
        //         return <>
        //             {dateFormat(data, 'DD MMM YYYY')}
        //             <br />
        //             {row.modified_by_temp}
        //         </>
        //     }
        // },
        {
            name: 'created_at',
            label: 'Created Date',
            filterable: true,
            type: 'date',
            render: (data: string) => {
                return dateFormat(data, 'DD MMM YYYY');
            }
        },
        // {
        //     name: 'scrap_id',
        //     label: 'Special',
        //     filterable: true,
        //     defaultOption: '',
        //     options: [
        //         {
        //             key: '',
        //             value: 'All'
        //         },
        //         {
        //             key: '1',
        //             value: 'Yes'
        //         },
        //         {
        //             key: '0',
        //             value: 'No'
        //         }
        //     ],
        //     render: (data: string) => {
        //         return data ? 'Yes' : 'No';
        //     }
        // }
    ];

    const initialAvailableState: IAvailableState = {
        date: null,
        comment: '',
        endOfAvailability: false
    }

    const availableReducer = (state: IAvailableState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialAvailableState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IAvailableState };
        }

        return { ...state };
    };

    const [isLoading, setIsLoading] = useState(false);
    const [modalAvailableOpen, setModalAvailableOpen] = useState(false);
    const [modalPublishOpen, setModalPublishOpen] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState<any>({});
    const [tempDTRows, setTempDTRows] = useState<{ setRows: Function, rows: any[] }>({
        setRows: () => { },
        rows: []
    });
    const [availableState, setAvailableState] = useReducer(availableReducer, initialAvailableState);
    const [publishComment, setPublishComment] = useState('');

    useEffect(() => {
        setAvailableState({ name: '', value: initialAvailableState, type: 'REPLACE_STATE' });
        // eslint-disable-next-line
    }, [modalAvailableOpen]);

    useEffect(() => {
        setPublishComment('');
        // eslint-disable-next-line
    }, [modalPublishOpen]);

    useEffect(() => {
        if (typeof params.id !== "undefined") {
            setModalState(1);
        }
    }, [params]);

    const handleModalAvailableOpen = () => {
        setModalAvailableOpen(true);
    }

    const handleModalAvailableClose = () => {
        setModalAvailableOpen(false);
    }

    const handleModalPublishOpen = () => {
        setModalPublishOpen(true);
    }

    const handleModalPublishClose = () => {
        setModalPublishOpen(false);
    }

    const handleAvailableSwitch = (unit: any, setRows: Function, rows: any[]) => {
        setTempDTRows({
            setRows,
            rows
        });
        setSelectedUnit(unit);
        handleModalAvailableOpen();
    }

    const handleAvailableSellSwitch = (unit: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengubah status available sell?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${API_URL}/switch-active-sell`, { id: unit.id })
                        .then(res => {
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +unit.id) element['is_available_sell'] = unit.is_available_sell ? 0 : 1;
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handlePublishSwitch = (unit: any, setRows: Function, rows: any[]) => {
        if (!!unit.is_active) {
            setTempDTRows({
                setRows,
                rows
            });
            setSelectedUnit(unit);
            handleModalPublishOpen();
        } else if (!!unit.is_owner) {
            renderWarningButton('Owner akan dinotifikasi bahwa unitnya sudah dipublish')
                .then((result) => {
                    if (result.value) {
                        setIsLoading(true);
                        DefaultAxios.post(`${API_URL}/switch-active`, { id: unit.id })
                            .then(res => {
                                const newRows = rows.map((element: any) => {
                                    if (+element.id === +unit.id) element['is_active'] = unit.is_active ? 0 : 1;
                                    return element
                                })
                                setRows(newRows);
                            })
                            .catch(err => {
                                generalErrorHandler(err);
                            })
                            .finally(() => {
                                setIsLoading(false);
                            })
                    }
                })
        } else {
            setIsLoading(true);
            DefaultAxios.post(`${API_URL}/switch-active`, { id: unit.id })
                .then(res => {
                    const newRows = rows.map((element: any) => {
                        if (+element.id === +unit.id) element['is_active'] = unit.is_active ? 0 : 1;
                        return element
                    })
                    setRows(newRows);
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }

    const handleSpecialPriceSwitch = (unit: any, setRows: Function, rows: any[]) => {
        setIsLoading(true);
        DefaultAxios.post(`${API_URL}/switch-special-price`, { id: unit.id })
            .then(res => {
                const newRows = rows.map((element: any) => {
                    if (+element.id === +unit.id) element['is_special_price'] = unit.is_special_price ? 0 : 1;
                    return element
                })
                setRows(newRows);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleUnassignAdvisor = (id: string, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengubah status available sell?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/assign-advisor/unassign`, { id: id })
                        .then(res => {
                            setRedrawIndex(redrawIndex + 1);
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleEntrustedKeySwitch = (unit: any, setRows: Function, rows: any[]) => {
        setIsLoading(true);
        DefaultAxios.post(`${API_URL}/switch-entrusted-key`, { id: unit.id })
            .then(res => {
                const newRows = rows.map((element: any) => {
                    if (+element.id === +unit.id) element['is_entrusted_key'] = unit.is_entrusted_key ? 0 : 1;
                    return element
                })
                setRows(newRows);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleAvailableSubmit = () => {
        if (!!selectedUnit.is_available) {
            if (!availableState || (!availableState.endOfAvailability && !availableState.date)) {
                Swal.fire({
                    title: 'Validasi Error',
                    text: "Silahkan isi komentar dan tentukan tanggal available",
                    icon: 'warning',
                });
                return;
            }
        } else {
            if (!availableState.comment) {
                Swal.fire({
                    title: 'Validasi Error',
                    text: "Silahkan isi komentar",
                    icon: 'warning',
                });
                return;
            }
        }

        const data = {
            id: selectedUnit.id,
            comment: availableState.comment,
            endOfAvailability: availableState.endOfAvailability,
            date: availableState.date ? dateFormat(availableState.date, 'MM/DD/YYYY') : null
        };

        setIsLoading(true);
        DefaultAxios.post(`${API_URL}/change/rent-date`, data)
            .then(res => {
                const newRows = tempDTRows.rows.map((element: any) => {
                    if (+element.id === +selectedUnit.id) element['is_available'] = selectedUnit.is_available ? 0 : 1;
                    return element
                })
                tempDTRows.setRows(newRows);
                handleModalAvailableClose();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handlePublishSubmit = () => {
        if (!publishComment) {
            Swal.fire({
                title: 'Validasi Error',
                text: "Silahkan isi komentar",
                icon: 'warning',
            });
            return;
        }

        setIsLoading(true);
        DefaultAxios.post(`${API_URL}/switch-active`, { id: selectedUnit.id, comment: publishComment })
            .then(res => {
                const newRows = tempDTRows.rows.map((element: any) => {
                    if (+element.id === +selectedUnit.id) element['is_active'] = selectedUnit.is_active ? 0 : 1;
                    return element
                })
                tempDTRows.setRows(newRows);
                handleModalPublishClose();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={modalAvailableOpen}
                onClose={handleModalAvailableClose}
            >
                <Root>
                    <DialogTitle>{!!selectedUnit.is_available ? 'Unit Rented' : 'Available Reason'}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            {
                                !!selectedUnit.is_available
                                    ? <>
                                        <Grid item xs={12}>
                                            <MuiDatePicker
                                                label="Available Date"
                                                value={availableState.date}
                                                onChange={(date: Date | null) => setAvailableState({ name: 'date', value: date, type: 'SET_ITEM' })}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={<Switch
                                                    checked={availableState.endOfAvailability}
                                                    onChange={(e) => setAvailableState({ name: 'endOfAvailability', value: e.target.checked, type: 'SET_ITEM' })}
                                                    color="secondary"
                                                />}
                                                label="Unavailable Selamanya"
                                            />

                                        </Grid>
                                    </>
                                    : null
                            }
                            <Grid item xs={12}>
                                <TextArea
                                    label="Comment"
                                    name="comment"
                                    value={availableState.comment}
                                    onChange={(e) => setAvailableState({ name: 'comment', value: e.target.value, type: 'SET_ITEM' })}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAvailableSubmit} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Root>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={modalPublishOpen}
                onClose={handleModalPublishClose}
            >
                <Root>

                    <DialogTitle>Unpublish Unit</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextArea
                                    label="Comment"
                                    name="comment"
                                    value={publishComment}
                                    onChange={(e) => setPublishComment(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handlePublishSubmit} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Root>
            </Dialog>

            <UnitModalDetail
                open={modalState === 1}
                onModalClose={handleCloseDetail}
            />

            <AssignedAdvisorModal
                open={modalState === 2}
                unit_id={assignedAdvisorState.unit_id}
                advisor_label={assignedAdvisorState.name}
                advisor_id={assignedAdvisorState.advisor_id}
                handleCloseModal={(refresh = false) => {
                    setModalState(0)

                    if (refresh) {
                        setRedrawIndex(redrawIndex + 1)
                    }
                }}
                onlyMember={permissions['unit.change-assigned-advisor'] === "all" ? false : true}
            />
            <BottomNavigation
                value={filter}
                onChange={(event, newValue) => {
                    setFilter(newValue);
                }}
                style={{ marginBottom: '10px' }}
                showLabels
            >
                <BottomNavigationAction
                    classes={{ root: classes.filterButton1, label: classes.filterButtonLabel2, selected: classes.filterButtonSelected }}
                    label="All"
                    value=""
                />
                <BottomNavigationAction
                    classes={{ root: classes.filterButton2, label: classes.filterButtonLabel2, selected: classes.filterButtonSelected }}
                    label={
                        <>
                            Rented
                            <br />
                            {vacantDays["rented"]} ({((vacantDays["rented"] / vacantDays["total"]) * 100).toFixed(2)}%)
                        </>
                    }
                    value="0"
                />
                <BottomNavigationAction
                    classes={{ root: classes.filterButton3, label: classes.filterButtonLabel1, selected: classes.filterButtonSelected }}
                    label={
                        <>
                            {"Vacant <= 20D"}
                            <br />
                            {vacantDays["<=20"]} ({((vacantDays["<=20"] / vacantDays["total"]) * 100).toFixed(2)}%)
                        </>
                    }
                    value="20"
                />
                <BottomNavigationAction
                    classes={{ root: classes.filterButton4, label: classes.filterButtonLabel1, selected: classes.filterButtonSelected }}
                    label={
                        <>
                            {"Vacant > 20D"}
                            <br />
                            {vacantDays[">20"]} ({((vacantDays[">20"] / vacantDays["total"]) * 100).toFixed(2)}%)
                        </>
                    }
                    value="21"
                />
                <BottomNavigationAction
                    classes={{ root: classes.filterButton5, label: classes.filterButtonLabel1, selected: classes.filterButtonSelected }}
                    label={
                        <>
                            {"Vacant > 40D"}
                            <br />
                            {vacantDays[">40"]} ({((vacantDays[">40"] / vacantDays["total"]) * 100).toFixed(2)}%)
                        </>
                    }
                    value="41"
                />
                <BottomNavigationAction
                    classes={{ root: classes.filterButton6, label: classes.filterButtonLabel1, selected: classes.filterButtonSelected }}
                    label={
                        <>
                            {"Vacant > 60D"}
                            <br />
                            {vacantDays[">60"]} ({((vacantDays[">60"] / vacantDays["total"]) * 100).toFixed(2)}%)
                        </>
                    }
                    value="61"
                />
            </BottomNavigation>

            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => navigate('/unit/add')}
            >
                Add Key
            </Button>

            <DataTable
                reDraw={redrawIndex}
                url={DT_API_URL}
                columns={columns}
                customParams={{
                    vacant_days: filter
                }}
                rowCallback={(row: any) => {
                    if (+row.vacant_days > 60) {
                        return {
                            backgroundColor: '#f56c6c'
                        };
                    } else if (+row.vacant_days > 40) {
                        return {
                            backgroundColor: '#f59456'
                        };
                    } else if (+row.vacant_days > 20) {
                        return {
                            backgroundColor: '#feda08'
                        };
                    } else if (+row.vacant_days > 0) {
                        return {
                            backgroundColor: '#7f4ad'
                        };
                    } else {
                        return {
                            backgroundColor: '#DFF2FF'
                        };
                    }
                }}
                responseCallback={(res: any) => {
                    if (+vacantDays['total'] === 0) {
                        setVacantDays({
                            "rented": res.total_vacant_days["rented"],
                            "<=20": res.total_vacant_days["<=20"],
                            ">20": res.total_vacant_days[">20"],
                            ">40": res.total_vacant_days[">40"],
                            ">60": res.total_vacant_days[">60"],
                            "total": res.total_vacant_days["rented"] + res.total_vacant_days["<=20"] + res.total_vacant_days[">20"] + res.total_vacant_days[">40"] + res.total_vacant_days[">60"]
                        });
                    }
                }}
            />
        </Root>
    );
}

export default EntrustedKeyReport;
import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton } from '@mui/material'

/**
 * Icons
 */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/**
 * interfaces
 */
import { IActivity } from '../ActivitySchedule'

interface IProps {
    open: boolean,
    onClose: () => void,
    activity: IActivity | null
}

const initialDetail = {
    activity: '',
    apartment_name: '',
    client_name: '',
    created_by_name: '',
    id: 0,
    notes: '',
    time: '',
    unit_code: '',
}

export default function ScheduleDetailModal(props: IProps) {
    const [detail, setDetail] = useState<IActivity>(initialDetail)
    
    useEffect(() => {
        if (props.open && props.activity) {
            return setDetail(props.activity)
        }
        
        setDetail(initialDetail)
    }, [props.open, props.activity])

    return (
        <Dialog
            maxWidth="xs"
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>
                <IconButton
                    onClick={props.onClose}
                    style={{left: -10}}
                >
                    <ArrowBackIcon />
                </IconButton>
                Activity Details
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container rowSpacing={1}>
                    <Grid item xs={3}><strong>Unit Code</strong></Grid>
                    <Grid item xs={9}>: {detail.unit_code || '-'}</Grid>
                    <Grid item xs={3}><strong>Client Name</strong></Grid>
                    <Grid item xs={9}>: {detail.client_name || '-'}</Grid>
                    <Grid item xs={3}><strong>Created By</strong></Grid>
                    <Grid item xs={9}>: {detail.created_by_name || '-'}</Grid>
                    <Grid item xs={3}><strong>Notes</strong></Grid>
                    <Grid item xs={9} >: {detail.notes || '-'}</Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

import React, { useContext, useEffect, useState } from 'react';
import {
    BottomNavigation,
    BottomNavigationAction,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Theme
} from '@mui/material';
import Swal from 'sweetalert2';
import { format, subWeeks } from 'date-fns';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';
import ReimburseProcessModal from './ReimburseProcessModal';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { numberToCurrency, generalErrorHandler, renderWarningButton, queryParamsToParams } from '../../_utils/Helper';
import { RemoveRedEye } from '@mui/icons-material';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';

const USER_TYPE = [null, 'pm', 'jhs']
type TravelData = {
    is_jhs: 0 | 1
    sum_amount: number
    sum_overtime_amount: number
    sum_parking_amount: number
    travels: {
        origin: number
        destination: number
        distance: number
        amount: number
        type: string
        overtime_amount: number
        parking_amount: number
        activity_id: number
    }[]
}

const ReimbursementRecapDailyList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/reimbursement-recap-daily';
    const { Root, classes } = useStyles();
    const permissions = useContext(PermissionContext)

    const [isReimburseProcessModalOpen, setIsReimburseProcessModalOpen] = useState(false)

    const columns = [
        {
            name: 'datetime',
            label: 'Date',
            type: 'date',
            filterable: true,
            defaultOption: `${format(subWeeks(new Date(), 2), 'yyyy-MM-dd')} - ${format(new Date(), 'yyyy-MM-dd')}`,
            render: (data: any, row: any) => {
                return format(new Date(data), 'dd MMM yyyy')
            }
        },
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true
        },
        {
            name: 'amount',
            label: 'Amount',
            type: 'number',
            filterable: true,
            render: (data: any) => {
                return numberToCurrency(data, 'Rp. ');
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            filterable: false,
            render: (row: any) => {
                return <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleDetail(row.id)}
                >
                    Detail
                </Button>
            }
        }
    ];

    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [travelData, setTravelData] = useState<TravelData | null>(null);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const [userType, setUserType] = useState(0)

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    useEffect(() => {
        if (!modalOpen) {
            setTravelData(null)
        }
    }, [modalOpen])

    const handleDetail = (id: string) => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/reimbursement-recap-daily/${id}`)
            .then(res => res.data)
            .then(data => {
                setTravelData(data)
                handleModalOpen();
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleReset = () => {
        renderWarningButton('Apakah anda yakin ingin me-reset data 1 bulan ke belakang? (data reimburse akan dihapus)')
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/reset`)
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil me-reset data",
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: () => {
                                    setReDrawDatatable(new Date().getTime());
                                }
                            })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleExport = () => {
        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/reimbursement-recap-daily/export`, { ...queryParamsToParams(), user_type: userType })
            .then(res => {
                window.open(`${process.env.REACT_APP_API_URL}/reimbursement-recap-daily/export-file?code=${res.data}`, '_blank');
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <ReimburseProcessModal
                isOpen={isReimburseProcessModalOpen}
                onClose={() => setIsReimburseProcessModalOpen(false)}
                onFinish={() => setReDrawDatatable(new Date().getTime())}
            />
            <Dialog
                fullWidth
                maxWidth="md"
                open={modalOpen}
                onClose={handleModalClose}
            >
                <Root>
                    <DialogTitle>Detail</DialogTitle>
                    <DialogContent>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>From</TableCell>
                                        <TableCell>To</TableCell>
                                        <TableCell>Distance</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Activity</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        travelData?.travels.map((travelLog, key: number) => {
                                            return (
                                                <TableRow key={key}>
                                                    <TableCell>{travelLog.origin}</TableCell>
                                                    <TableCell>{travelLog.destination}</TableCell>
                                                    <TableCell>{travelLog.distance} KM</TableCell>
                                                    <TableCell>{numberToCurrency(travelLog.amount, 'Rp.')}</TableCell>
                                                    <TableCell>{travelLog.type}</TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            color="primary"
                                                            onClick={() => window.open((travelData?.is_jhs === 1 ? '/jhs-order/' : '/activity/') + travelLog.activity_id)}
                                                            component="span"
                                                        >
                                                            <RemoveRedEye />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                                <TableFooter>
                                    {
                                        (travelData?.travels.reduce((a, b) => (a + b.overtime_amount), 0) || 0) > 0
                                            ? <TableRow>
                                                <TableCell colSpan={3} align="right">Total Uang Lembur</TableCell>
                                                <TableCell colSpan={3}>{numberToCurrency(travelData?.travels.reduce((a, b) => (a + b.overtime_amount), 0) || 0, 'Rp.')}</TableCell>
                                            </TableRow>
                                            : null
                                    }
                                    <TableRow>
                                        <TableCell colSpan={3} align="right">Total Amount</TableCell>
                                        <TableCell colSpan={3}>{numberToCurrency(travelData?.travels.reduce((a, b) => (a + b.amount), 0) || 0, 'Rp.')}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={3} align="right">Total Parking Amount</TableCell>
                                        <TableCell colSpan={3}>{numberToCurrency(travelData?.travels.reduce((a, b) => (a + b.parking_amount), 0) || 0, 'Rp.')}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={3} align="right">All Total</TableCell>
                                        <TableCell colSpan={3}>{numberToCurrency(travelData?.travels.reduce((a, b) => (a + b.amount + b.parking_amount + b.overtime_amount), 0) || 0, 'Rp.')}</TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </Root>
            </Dialog>
            <Grid container>
                <Grid item xs={12}>
                    {
                        permissions['reimbursement.process-reimburse'] ?
                            <>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    style={{ marginBottom: '15px', marginRight: '15px' }}
                                    onClick={handleReset}
                                >
                                    Reset Data 8 Minggu ke belakang
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    style={{ marginBottom: '15px' }}
                                    onClick={() => setIsReimburseProcessModalOpen(true)}
                                >
                                    Ambil Ulang Data 8 Minggu ke belakang
                                </Button>
                            </>
                            : null
                    }
                    {
                        permissions['reimbursement.export'] ?
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                style={{ marginBottom: '15px', float: 'right' }}
                                onClick={handleExport}
                            >
                                Export
                            </Button>
                            : null
                    }
                </Grid>
                {
                    (permissions['reimbursement.recap-pm'] && permissions['reimbursement.recap-jhs']) ?
                        <Grid item xs={12}>
                            <BottomNavigation
                                value={userType}
                                onChange={(event, newValue) => {
                                    setUserType(newValue);
                                }}
                                showLabels
                                className={classes.filter}
                            >
                                <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} label="All" />
                                <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} label="PM" />
                                <BottomNavigationAction classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }} label="JHS" />
                            </BottomNavigation>
                        </Grid>
                        : null
                }
            </Grid>
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
                customParams={{ user_type: USER_TYPE[userType] }}
                extraFooterRow={[
                    null, null,
                    {
                        name: 'Total Amount',
                        label: 'Total Amount',
                        render: (items) => numberToCurrency(items.reduce((prev, current) => prev + (current['amount'] ? Number(current['amount'].split('.')[0]) : 0), 0), 'Rp. '),
                    },
                    null,
                ]}
            />
        </Root>
    );
}

const useStyles = generateStyle((theme: Theme) =>
({
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        margin: '5px',
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
        marginBottom: 16,
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
}), "ReimbursementRecapDailyList"
)

export default ReimbursementRecapDailyList;
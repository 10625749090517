import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';

/**
 * Components
 */
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, convertPrice } from '../../_utils/Helper';

interface Props {
    open: boolean,
    listing_id: string
    onClose: Function,
}

const ListingDetailModal = (props: Props) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/listing-admin`;
    const [loading, setLoading] = useState(false);

    const [inputState, setInputState] = useState({
        address: '',
        bathroom: '',
        bedroom: '',
        building_finished: '',
        carport: '',
        certificate: '',
        city_name: '',
        code: '',
        created_at: '',
        daily_price: '',
        description: '',
        direction: '',
        display_currency: '',
        display_daily_price: '',
        district_name: '',
        electric_watt: '',
        facilities: '',
        floor: '',
        furnish: '',
        id: '',
        is_active: '',
        is_published: '',
        land_area: '',
        land_length: '',
        land_width: '',
        landlord_email: '',
        landlord_id: '',
        landlord_name: '',
        landlord_phone: '',
        latitude: '',
        listing_type_name: '',
        listing_type_id: 0,
        longitude: '',
        name: '',
        notes: '',
        number: '',
        pic_name: '',
        price_per_1_month: '',
        price_per_3_months: '',
        price_per_6_months: '',
        price_per_year: '',
        property_area: '',
        property_length: '',
        property_width: '',
        province_name: '',
        sell_price: '',
        total_floor: '',
        updated_at: '',
        view: '',
    });

    const loadData = () => {
        setLoading(true);
        DefaultAxios
            .get(`${API_URL}/${props.listing_id}`)
            .then(res => {
                const data = res.data;
                setInputState(data);
            })
            .catch(error => {
                generalErrorHandler(error);
                props.onClose();
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (props.listing_id) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.listing_id]);

    const generateBasicInformation = () => {
        let content: Array<any> = [];
        content = content.concat([
            [`Nama`, inputState.name],
            [`PIC`, inputState.pic_name],
            [`Kode Listing`, inputState.code],
            [`Jenis Properti`, inputState.listing_type_name],
            [`Deskripsi`, inputState.description],
            [`Lokasi`, `${inputState.district_name}, ${inputState.city_name}. ${inputState.province_name}`],
            [`Alamat`, inputState.address ? inputState.address : '-'],
            [`Latitude`, inputState.latitude ? inputState.latitude : '-'],
            [`Longitude`, inputState.longitude ? inputState.longitude : '-'],
            [`Land Area`, inputState.land_area],
        ]);

        if (inputState.listing_type_id !== 3) {
            content = content.concat([
                [`Property Area`, inputState.property_area],
                [`Jumlah Lantai`, inputState.total_floor],
                [`Furnish`, inputState.furnish],
                [`Electric Watt`, inputState.electric_watt],
            ])
        }

        if (inputState.listing_type_id === 2) {
            content = content.concat([
                [`Bedroom`, inputState.bedroom],
            ])
        }

        if (inputState.listing_type_id === 6 || inputState.listing_type_id === 2) {
            content = content.concat([
                [`Bathroom`, inputState.bathroom],
            ])
        }
        if (inputState.listing_type_id === 2) {
            content = content.concat([
                [`Carport`, inputState.carport],
            ])
        }

        content = content.concat([
            [`Hadap`, inputState.view ? inputState.view : '-'],
            [`Arah`, inputState.direction ? inputState.direction : '-'],
            [`Tahun Dibangun`, inputState.building_finished ? inputState.building_finished : '-'],
        ])

        content = content.concat([
            [`Sertifikat`, inputState.certificate ? inputState.certificate : '-'],
        ])

        return content;
    }

    return (
        <>
            <ModalDetail
                open={props.open}
                title={`Listing Detail - ${inputState.code}`}
                onCloseModal={() => props.onClose()}
                isLoading={loading}
                showEditButton={true}
                editButtonLink={`/listingAdmin/edit/${inputState.id}`}
            >
                <>
                    <Grid item xs={12} md={6}>
                        <ModalSection
                            title="Informasi Dasar"
                            content={generateBasicInformation()}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <ModalSection
                            title="Harga Jual dan Sewa"
                            content={[
                                [`Harga Harian`, inputState.daily_price ? (inputState.display_currency === "USD" ? "$ " : "Rp. ") + convertPrice(inputState.daily_price) : '-'],
                                [`Harga Bulanan`, inputState.price_per_1_month ? (inputState.display_currency === "USD" ? "$ " : "Rp. ") + convertPrice(inputState.price_per_1_month) : '-'],
                                [`Harga 3 Bulan`, inputState.price_per_3_months ? (inputState.display_currency === "USD" ? "$ " : "Rp. ") + convertPrice(inputState.price_per_3_months) : '-'],
                                [`Harga 6 Bulan`, inputState.price_per_6_months ? (inputState.display_currency === "USD" ? "$ " : "Rp. ") + convertPrice(inputState.price_per_6_months) : '-'],
                                [`Harga Tahunan`, inputState.price_per_year ? (inputState.display_currency === "USD" ? "$ " : "Rp. ") + convertPrice(inputState.price_per_year) : '-'],
                                [`Harga Jual`, inputState.sell_price ? (inputState.display_currency === "USD" ? "$ " : "Rp. ") + convertPrice(inputState.sell_price) : '-'],
                            ]}
                        />

                        <ModalSection
                            title="Informasi Owner"
                            content={[
                                [`Nama`, inputState.landlord_name],
                                [`HP`, inputState.landlord_phone],
                                [`Email`, inputState.landlord_email ? inputState.landlord_email : "-"],
                            ]}
                        />

                        <ModalSection
                            title="Notes"
                            content={[
                                ['', inputState.notes ? inputState.notes : "-"]
                            ]}
                        />
                    </Grid>
                </>
            </ModalDetail>
        </>
    );
}

export default ListingDetailModal;

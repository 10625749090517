import React from 'react';
import { Grid } from '@mui/material';
import ArticleForm from '../../_components/_form/ArticleForm';

const LandingOtherListingForm = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <ArticleForm
                    url={process.env.REACT_APP_API_URL + '/landing-listing-article'}
                    withPrimaryImage
                    buttonGroupOne={{
                        key: 'listing_type',
                        default: '1',
                        list: [
                            {
                                id: '1',
                                label: 'Apartemen',
                            },
                            {
                                id: '2',
                                label: 'Rumah',
                            },
                            {
                                id: '3',
                                label: 'Tanah',
                            },
                            {
                                id: '4',
                                label: 'Ruko',
                            },
                            {
                                id: '5',
                                label: 'Kantor',
                            },
                            {
                                id: '6',
                                label: 'Gudang',
                            },
                            {
                                id: '7',
                                label: 'Ruang Usaha',
                            },
                        ]
                    }}
                    buttonGroupTwo={{
                        key: 'lang',
                        default: 'id',
                        list: [
                            {
                                id: 'id',
                                label: 'Indonesia',
                            },
                            {
                                id: 'en',
                                label: 'Inggris',
                            },
                        ]
                    }}
                    buttonGroupThree={{
                        key: 'sold_type',
                        default: 'rent',
                        list: [
                            {
                                id: 'rent',
                                label: 'Sewa',
                            },
                            {
                                id: 'sell',
                                label: 'Jual',
                            },
                        ]
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default LandingOtherListingForm;
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, TextField } from '@mui/material'
import Swal from 'sweetalert2';

// Components
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';
import TextEditor from '../../_components/_form/TextEditor';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';

// Icons
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import { generalErrorHandler, isValidEmail } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IState {
    id: string;
    subject: string;
    template: string;
    tags: string[];
}

interface IEmailState {
    title: string;
    name: string;
    from_email: string;
    sent_to: string;
}

interface IErrorState {
    subject: string;
    template: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const useStyles = generateStyle(theme => ({
    paperRoot: {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: '10px',
        margin: 0,
    },
    actionContainer: {
        display: 'flex'
    },
    addButton: {
        marginBottom: '20px'
    },
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
}), "BlasTemplate_List");

const BlastTemplateList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/blast-template';
    const { Root, classes } = useStyles();

    const columns = [
        {
            name: 'subject',
            label: 'Subject',
            type: 'string',
            filterable: true
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        <IconButton
                            color="primary"
                            component="span"
                            onClick={() => handleEdit(row.id)}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                            color="primary"
                            component="span"
                            onClick={() => handleEmailModal(row.id, row.tag)}
                        >
                            <SendIcon fontSize="small" />
                        </IconButton>
                    </div>
                );
            }
        }
    ];

    const initialState = {
        id: '',
        subject: '',
        template: '',
        tags: []
    };

    const initialEmailState = {
        title: '',
        name: '',
        from_email: '',
        sent_to: ''
    };

    const initialErrorState = {
        subject: '',
        template: ''
    };

    const initialEmailErrorState = {
        title: '',
        name: '',
        from_email: '',
        sent_to: ''
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const emailReducer = (state: IEmailState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialEmailState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IEmailState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const emailErrorReducer = (state: IEmailState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialEmailErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IEmailState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [emailState, setEmailState] = useReducer(emailReducer, initialEmailState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);
    const [emailErrorState, setEmailErrorState] = useReducer(emailErrorReducer, initialEmailErrorState);
    const [tag, setTag] = useState('');

    const [selectedId, setSelectedId] = useState('');
    const [selectedTag, setSelectedTag] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    /**
     * 1 = Form
     * 2 = Email test
     */
    const [modalState, setModalState] = useState(0);

    const handleModalClose = () => setModalState(0);

    useEffect(() => {
        if (!modalState) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [modalState]);

    const handleEdit = (id: string) => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL + '/' + id)
            .then(res => {
                const newState = {
                    ...initialState,
                    ...res.data,
                    tags: res.data.tags.map((tag: { tag: string }) => tag.tag)
                };

                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                setModalState(1);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleEmailModal = (id: string, tag: string) => {
        setSelectedId(id);
        setSelectedTag(tag);
        setModalState(2);
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.subject) {
            newError.subject = 'Wajib diisi';
            isValid = false;
        }

        if (!inputState.template) {
            newError.template = 'Wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });

        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.patch(API_URL + '/' + inputState.id, inputState);
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Template submitted",
                    icon: 'success',
                    onAfterClose: () => {
                        setReDrawDatatable(new Date().getTime());
                        handleModalClose();
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const checkSendEmailValidation = () => {
        let isValid = true;
        const newError = { ...initialEmailErrorState };

        if (!emailState.title) {
            newError.title = 'Wajib diisi';
            isValid = false;
        }

        if (!emailState.name) {
            newError.name = 'Wajib diisi';
            isValid = false;
        }

        if (!emailState.from_email) {
            newError.from_email = 'Wajib diisi';
            isValid = false;
        } else if (!isValidEmail(emailState.from_email)) {
            newError.from_email = 'Format email salah';
            isValid = false;
        }

        if (!emailState.sent_to) {
            newError.sent_to = 'Wajib diisi';
            isValid = false;
        } else if (!isValidEmail(emailState.sent_to)) {
            newError.sent_to = 'Format email salah';
            isValid = false;
        }

        setEmailErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });

        return isValid;
    }

    const handleSendEmail = () => {
        if (!checkSendEmailValidation()) {
            return;
        }

        setIsLoading(true);
        DefaultAxios
            .post(`${API_URL}/send-email`, { id: selectedId, ...emailState })
            .then(res => {
                Swal.fire({
                    title: "Email Sent",
                    html: `INFO SUMMARY<br />
                    Title: ${emailState.title}<br />
                    To (email): ${emailState.sent_to}<br />
                    From: ${emailState.from_email}<br />
                    Name: ${emailState.name}<br />
                    Tags: ${selectedTag}<br />
                    `,
                    icon: 'success',
                    onAfterClose: handleModalClose
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleChange = (e: React.ChangeEvent<any>, name?: string, value?: any) => {
        const target = e.target;
        name = String(name || target.name);
        value = value || target.value;

        if (name === 'tags') {
            const oldTags = inputState.tags.slice();
            if (!oldTags.map(tag => tag.toLowerCase()).includes(String(value).toLowerCase())) {
                oldTags.push(value);
            }
            value = oldTags;
            setTag('');
        }

        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value = target.value;

        setEmailState({ name, value, type: 'SET_ITEM' });
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        switch (event.key) {
            case "Tab":
                handleChange(event, 'tags', tag);
                break;
            default:
        }
    };

    const handleDeleteTag = (index: number) => {
        const newTags = inputState.tags.slice();
        newTags.splice(index, 1);

        setInputState({ name: 'tags', value: newTags, type: 'SET_ITEM' })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => setModalState(1)}
            >
                Add New Template
            </Button>
            <Dialog
                open={modalState === 1}
                onClose={handleModalClose}
                fullWidth
                maxWidth="md"
            >
                <Root>
                    <DialogTitle>Template Form</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Subject"
                                    name="subject"
                                    value={inputState.subject}
                                    onChange={handleChange}
                                    error={!!errorState.subject}
                                    helperText={errorState.subject}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Tags"
                                    name="tag"
                                    value={tag}
                                    onChange={(e) => setTag(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                />
                            </Grid>

                            {
                                inputState.tags.length > 0 &&
                                <Grid item xs={12}>
                                    <Paper component="ul" className={classes.paperRoot}>
                                        {inputState.tags.map((tag: string, key: number) => {

                                            return (
                                                <li key={tag}>
                                                    <Chip color="primary" size="small" onDelete={() => handleDeleteTag(key)} label={tag} />
                                                </li>
                                            );
                                        })}
                                    </Paper>
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <h3 style={{ marginBottom: 0 }}>Template</h3>
                            </Grid>

                            <Grid item xs={12}>
                                <TextEditor
                                    name="template"
                                    value={inputState.template}
                                    onChange={(name: string, value: string) => setInputState({ name: 'template', value: value, type: 'SET_ITEM' })}
                                />
                                {
                                    !!errorState.template &&
                                    <p style={{ color: 'red' }}>Wajib diisi</p>
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Root>
            </Dialog>
            <Dialog
                open={modalState === 2}
                onClose={handleModalClose}
                fullWidth
                maxWidth="md"
            >
                <Root>
                    <DialogTitle>Send Email</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Title"
                                    name="title"
                                    value={emailState.title}
                                    onChange={handleEmailChange}
                                    error={!!emailErrorState.title}
                                    helperText={emailErrorState.title}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Name"
                                    name="name"
                                    value={emailState.name}
                                    onChange={handleEmailChange}
                                    error={!!emailErrorState.name}
                                    helperText={emailErrorState.name}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="From Email"
                                    name="from_email"
                                    value={emailState.from_email}
                                    onChange={handleEmailChange}
                                    error={!!emailErrorState.from_email}
                                    helperText={emailErrorState.from_email}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Sent To"
                                    name="sent_to"
                                    value={emailState.sent_to}
                                    onChange={handleEmailChange}
                                    error={!!emailErrorState.sent_to}
                                    helperText={emailErrorState.sent_to}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={handleSendEmail}
                        >
                            Send
                        </Button>
                    </DialogActions>
                </Root>
            </Dialog>
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Root>
    );
}

export default BlastTemplateList;
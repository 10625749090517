import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";

/**
 * Table
 */
import DataTable from '../../_components/_dataTable/DataTable';
import { Button, IconButton, Link, Theme, Tooltip, Tabs, Tab, BottomNavigation, BottomNavigationAction, Grid } from '@mui/material';

/**
 * Components
*/
import TicketSystemDetail from './TicketSystemDetail';
import LoadingScreen from '../../_components/LoadingScreen';


/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

/**
 * Utils
 */
import { generalErrorHandler, renderSuccessButton } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { format } from 'date-fns/esm';
import DefaultAxios from '../../_utils/DefaultAxios';
import CompleteModal from './_components/CompleteModal';
import QiscusTemplateModal from '../_closing/_components/Modal/QiscusTemplateModal';
import { IQiscusData } from '../_closing/_components/ClosingModalDetail';
import { generateStyle } from '../../_utils/DefaultStyle';


const useStyles = generateStyle((theme: Theme) =>
({
    root: {
        display: 'inline-flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    actionContainer: {
        display: 'flex',
        '& > *': {
            marginLeft: 16,
        },
        '& > *:first-of-type': {
            marginLeft: 0,
        }
    },
    tabContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '1px solid #ddd',
        '& .DateInput_input__focused': {
            borderBottom: '0',
        },
        '& .date-container': {
            width: '25%',
            display: 'flex',
            alignItems: 'center',
        },
        '& .extend-status': {
            marginLeft: 'auto',
            padding: '0px 16px',
            borderLeft: '1px solid #ddd',
            fontSize: 12,
            display: 'flex',
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            '& p span': {
                color: 'red'
            }
        },
    },
    appBar: {
        position: 'relative'
    },
    addButton: {
        // marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    filter: {
        width: 'auto',
        height: 30,
        display: 'inline-flex',
        borderRadius: 4,
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: 50,
        whiteSpace: 'nowrap'
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: 4,
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
}), "TicketSystemList"
);

interface IProps {
    closing_id?: string
    closing_code?: string
}

interface IParams extends Record<string, string | undefined> {
    ticket_id?: string
}

const TicketSystemList = (props: IProps) => {
    const permissions = useContext(PermissionContext)
    const [isPersonal, setIsPersonal] = useState(permissions['ticket.admin'] ? 0 : 1)
    const { Root, classes } = useStyles();
    const navigate = useNavigate()
    const params = useParams<IParams>()
    // const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [detailModal, setDetailModal] = useState(false)
    const [role, setRole] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [reDraw, setReDraw] = useState(1)
    const [queryParams, setQueryParams] = useState<any>('')

    const [filter, setFilter] = useState({
        type_toggle: '',
    })

    const [qiscusData, setQiscusData] = useState<IQiscusData | null>(null)

    const [completeTicketId, setCompleteTicketId] = useState<number | null>(null)

    useEffect(() => {
        if (typeof params.ticket_id !== "undefined") {
            setDetailModal(true)
            if (localStorage.getItem('queryParamsTicket') !== '') {
                setQueryParams(localStorage.getItem('queryParamsTicket'))
            }
            // handleOpen();
        }

    }, [params]);

    const handleOpenQiscus = (phone: string, type: string, closing_id: string, data_id: string) => {
        setQiscusData({
            phone,
            data_id,
            type,
            closing_id
        })
    }


    const whatsappTenant = (data: any) => {
        handleOpenQiscus(data.customer_phone, 'greeting_customer', data.closing_id, data.customer_id)
        // const name = data.customer_name ? getFirstName(data.customer_name) : ''
        // const pretext = `Halo Kak ${name}\n\nPerkenalkan aku ${data.nickname}, Tenant Relation Jendela360.\n\nTerima kasih atas kepercayaannya telah memilih Jendela360 Kak ☺️\n\nSebelumnya kakak dibantu oleh tim Property Manager kami atas nama ${data.advisor_nickname} ya. Nah untuk kenyamanan kakak selama tinggal di ${data.apartment_name} / Tower ${data.tower} / Lt. ${data.floor_level} / No. ${data.unit_number}, aku yang akan bantu kakak kalau butuh bantuan. \n\nSemoga nyaman di tempat barunya ya kak. Nanti kalau selama masa sewa ada kendala, atau ada yang mau ditanyakan, bisa langsung info ke aku ya. Aku standby 24/7\n\nSalam kenal kak 🙏☺️`
        // openExternalLink(`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(data.customer_phone)}&text=${encodeURIComponent(pretext)}`)
    }

    const whatsappOwner = (data: any) => {
        handleOpenQiscus(data.owner_phone, 'greeting_pic_landlord', data.closing_id, data.landlord_id)
        // const name = data.owner_name ? getFirstName(data.owner_name) : ''
        // const pretext = `Halo Bapak/Ibu ${name}\n\nPerkenalkan aku ${data.nickname}, Tenant Relation Jendela360.\n\nTerima kasih atas kepercayaannya telah memilih Jendela360 Kak ☺️\n\nSebelumnya Bapak/Ibu dibantu oleh tim Property Manager kami atas nama ${data.advisor_nickname} ya. Nah untuk kenyamanan Bapak/Ibu selama masa sewa unit ${data.apartment_name} / Tower ${data.tower} / Lt. ${data.floor_level} / No. ${data.unit_number}, aku yang akan bantu Bapak/Ibu kalau butuh bantuan. Nanti kalau selama masa sewa ada kendala, atau ada yang mau ditanyakan, bisa hubungi aku ya Pak/Bu. Aku standby 24/7\n\nSalam kenal Pak/Bu 🙏☺️`
        // openExternalLink(`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(data.owner_phone)}&text=${encodeURIComponent(pretext)}`)
    }

    const submitComplete = (state: any) => {
        const API_URL = process.env.REACT_APP_API_URL + '/ticket';
        let axios;

        let fd = new FormData()

        fd.append('comment', state.comment || '')
        if (state.attachments.length) {
            state.attachments.forEach((file: File, index: number) => {
                fd.append(`attachments[${index}]`, file)
            })
        }

        axios = DefaultAxios.post(`${API_URL}/${completeTicketId}/complete`, fd);
        setIsLoading(true);
        axios
            .then(res => {
                renderSuccessButton("Ticket Completed")
                setCompleteTicketId(null)
                setReDraw(new Date().getTime())
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const markTicketDone = (id: number) => setCompleteTicketId(id)

    const handleQueryParams = (query: string) => {
        // console.log(typeof params.ticket_id)
        if (typeof params.ticket_id === 'undefined') {
            setQueryParams((prev: any) => {
                localStorage.setItem('queryParamsTicket', query)
                return query
            })
        }
    }

    const handleTabChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
        setIsPersonal(value)
    }

    const renderTabTable = () => {
        return (
            <div className={classes.tabContainer}>
                <Tabs
                    value={isPersonal}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                >
                    <Tab label="My Ticket" value={1} />
                    <Tab label="All Ticket" value={0} />
                </Tabs>
                <div className="extend-status">

                </div>
            </div>
        )
    }

    useEffect(() => {
        let localRole = window.localStorage.getItem('role')
        if (localRole) {
            setRole(localRole)

            if (localRole === 'collection') setIsPersonal(0)
        }

        return () => {
            setRole(localStorage.getItem('role') || '')
        }
    }, [])

    const allColumns = [
        {
            name: 'unit_code',
            label: 'Unit',
            filterable: true,
            sortable: false,
        },
        ...(isPersonal ?
            [] :
            [
                {
                    name: 'pic_name',
                    label: 'PIC',
                    filterable: true,
                    sortable: false,
                }
            ]
        ),
        {
            name: 'created_by_name',
            label: 'Created By',
            filterable: true,
            sortable: false,
        },
        {
            name: 'status',
            label: 'Status',
            filterable: true,
            sortable: false,
            render: (data: any, row: any) => {
                let return_text = "";
                switch (data) {
                    case 0:
                        return_text = "On Process";
                        break;
                    case 1:
                        return_text = "Done";
                        break;
                }
                return return_text;
            },
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'On Process'
                },
                {
                    key: '1',
                    value: 'Done'
                },
            ]
        },
        {
            name: 'updated_at',
            label: 'Last Updated',
            type: 'date',
            filterable: true,
            style: { minWidth: '200px' },
            render: (data: any, row: any) => {
                return data ? format(new Date(data), 'dd/MM/yyyy') : "-"
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            sortable: false,
            render: (row: any) => {
                return (
                    <>
                        <div className={classes.actionContainer}>
                            {
                                row.type === 'Greeting' &&
                                <>
                                    <Tooltip title="Whatsapp Tenant">
                                        <IconButton
                                            color="success"
                                            component="span"
                                            disabled={!row.customer_phone}
                                            onClick={() => whatsappTenant(row)}
                                        >
                                            <WhatsAppIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Whatsapp Owner">
                                        <IconButton
                                            color="success"
                                            component="span"
                                            disabled={!row.owner_phone}
                                            onClick={() => whatsappOwner(row)}
                                        >
                                            <WhatsAppIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                            {row.status === 0 &&
                                <Tooltip title="Mark Done">
                                    <IconButton
                                        color="primary"
                                        component="span"
                                        onClick={() => markTicketDone(row.id)}
                                    >
                                        <CheckIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                    </>
                )
            }
        }
    ]

    const generalColumns = [
        {
            name: 'created_at',
            label: 'Date',
            type: 'date',
            filterable: true,
            style: { minWidth: '200px' },
            render: (data: any, row: any) => {
                return data ? format(new Date(data), 'dd/MM/yyyy - HH:mm') : "-"
            }
        },
        {
            name: 'ticket_age',
            label: 'Ticket Age',
            type: 'number',
            filterable: true,
            render: (data: any, row: any) => {
                return `${data} days`
            }
        },
        {
            name: 'code',
            label: 'Code',
            filterable: true,
            sortable: false,
            render: (data: any, row: any) => {
                return (
                    <Link
                        style={{ cursor: "pointer" }}
                        component={RouterLink}
                        to={`/ticket/list/${row.id}`}
                        target='_blank'
                        rel='noopener norefferer'
                    >
                        {/* eslint-disable-next-line */}
                        {data}
                    </Link>
                );
            }
        },
        {
            name: 'title',
            label: 'Title',
            filterable: true,
            sortable: false,
            render: (data: any) => {
                return data || "-"
            }
        },
        {
            name: 'tenant_name',
            label: 'Tenant Name',
            filterable: true,
            sortable: false,
        },
        {
            name: 'type',
            label: 'Type',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: 'Collection',
                    value: 'Collection'
                },
                {
                    key: 'Renewal',
                    value: 'Renewal'
                },
                {
                    key: 'Greeting',
                    value: 'Greeting'
                },
                {
                    key: 'Lainnya',
                    value: 'Lainnya'
                },
            ]
        },
        ...allColumns,
    ]

    const depositColumns = [
        {
            name: 'checkout_date',
            label: 'Checkout Date',
            type: 'date',
            filterable: true,
            style: { minWidth: '200px' },
            render: (data: any, row: any) => {
                return data ? format(new Date(data), 'dd/MM/yyyy - HH:mm') : "-"
            }
        },
        {
            name: 'deposit_age',
            label: 'Deposit Age',
            type: 'number',
            filterable: true,
            render: (data: any, row: any) => {
                return data !== null ? `${data} days` : '-'
            }
        },
        {
            name: 'code',
            label: 'Ticket Code',
            filterable: true,
            sortable: false,
            render: (data: any, row: any) => {
                return (
                    <Link
                        style={{ cursor: "pointer" }}
                        component={RouterLink}
                        to={`/ticket/list/${row.id}`}
                        target='_blank'
                        rel='noopener norefferer'
                    >
                        {/* eslint-disable-next-line */}
                        {data}
                    </Link>
                );
            }
        },
        {
            name: 'tenant_name',
            label: 'Tenant Name',
            filterable: true,
            sortable: false,
        },
        ...allColumns,
    ]

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <CompleteModal
                onSubmit={submitComplete}
                onClose={() => setCompleteTicketId(null)}
                open={completeTicketId !== null}
            />

            <TicketSystemDetail
                open={detailModal}
                onClose={() => {
                    if (localStorage.getItem('from-ticket-add')) {
                        localStorage.removeItem('from-ticket-add')
                        setDetailModal(false)
                        navigate(queryParams ? `/ticket/list?${queryParams}` : '/ticket/list')
                    } else {
                        window.close()
                    }
                }}
                ticket_id={params.ticket_id ? params.ticket_id : ''}
                permissions={permissions}
                onReload={() => {
                    setReDraw(prev => prev + 1)
                }}
            />

            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.addButton}
                        startIcon={<AddIcon />}
                        onClick={() => navigate(props.closing_code ? `/ticket/add/${props.closing_code}` : `/ticket/add`)}
                    >
                        Add Ticket
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <BottomNavigation
                        value={filter.type_toggle}
                        onChange={(event, newValue) => {
                            setFilter(prev => ({
                                ...prev,
                                type_toggle: newValue,
                            }))
                        }}
                        showLabels
                        className={classes.filter}
                    >
                        <BottomNavigationAction
                            label="General"
                            value={''}
                            classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
                        />
                        <BottomNavigationAction
                            label="Deposit"
                            value={'deposit'}
                            classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
                        />
                    </BottomNavigation>
                </Grid>
            </Grid>

            <DataTable
                url={`${process.env.REACT_APP_API_URL}/ticket?closing_id=${props.closing_id ? props.closing_id : ''}`}
                setParentQueryString={(queryString) => handleQueryParams(queryString)}
                reDraw={reDraw}
                customParams={{ is_personal: isPersonal, type_toggle: filter.type_toggle }}
                tab={permissions['ticket.admin'] || role === 'collection' ? null : renderTabTable()}
                columns={filter.type_toggle === 'deposit' ? depositColumns : generalColumns}
                processParamsBeforeSendingToAPI={params => ({
                    ...params,
                    type: filter.type_toggle === 'deposit' ? '' : params.type,
                })}
            />

            <QiscusTemplateModal
                open={qiscusData !== null}
                onClose={() => setQiscusData(null)}
                onSubmitSuccess={() => setQiscusData(null)}
                data={qiscusData}
                type={qiscusData?.type}
            />
        </Root>
    )
}
export default TicketSystemList;
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Grid, Button, Dialog, Paper, Theme } from '@mui/material'
import React, { useCallback, useState } from 'react'

/**
 * Asset
 */
import pdfPng from '../../../Assets/Images/png/pdf.png';
import docPng from '../../../Assets/Images/png/doc.png';

import { dateFormat } from '../../../_utils/Helper';
import DeleteIcon from '@mui/icons-material/Delete';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface FileListProps {
    images: []
    onDeleteFile: (id: string) => void
}

const FileList = (props: FileListProps) => {
    const { Root, classes } = useStyles()

    const [previewImage, setPreviewImage] = useState<string | undefined>(undefined)

    const handlePreviewFile = (src: string, isImage: boolean) => {
        if (isImage) {
            setPreviewImage(src);
        } else {
            window.open(src);
        }
    }

    const renderFiles = useCallback(() => {
        return props.images.map((file: any, key) => {
            let src = '';
            let isImage = false;
            let filepath = file.asset_url;

            let explode = filepath.split('?');
            const match = /(\.\w+$)/g.exec(explode[0]);

            if (match) {
                if (match[1] === '.pdf' || match[1] === '.PDF') {
                    src = pdfPng;
                } else if (match[1] === '.doc' || match[1] === '.docx') {
                    src = docPng;
                } else {
                    src = filepath;
                    isImage = true;
                }
            }

            return (
                <TableRow key={file.id}>
                    <TableCell>
                        <img height="75" width="75" src={src} alt={file.filename} onClick={() => handlePreviewFile(filepath, isImage)} />
                    </TableCell>
                    <TableCell>
                        {dateFormat(file.created_at, 'DD/MM/YYYY HH:MM:ss')}
                    </TableCell>
                    <TableCell align="right">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<DeleteIcon />}
                                onClick={() => props.onDeleteFile(file.id)}
                                size={'small'}
                            >
                                Delete
                            </Button>
                        </Grid>
                    </TableCell>
                </TableRow>
            )
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.images])

    return (
        <>
            <Dialog
                open={!!previewImage}
                onClose={() => { setPreviewImage(undefined) }}
                scroll="body"
                maxWidth={false}
            >
                <img src={previewImage} width="100%" alt="preview" />
            </Dialog>
            <Root style={{ flexGrow: 1 }}>
                <TableContainer component={Paper} className={classes.fileListTable} elevation={0}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    File
                                </TableCell>
                                <TableCell>
                                    Created At
                                </TableCell>
                                <TableCell align="right">
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.images.length > 0 ?
                                    renderFiles()
                                    :
                                    <TableRow>
                                        <TableCell colSpan={5}>There is no file yet</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Root>
        </>
    )
}

export default FileList

const useStyles = generateStyle((theme: Theme) =>
({
    fileListTable: {
        '& > table': {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'table',
            },
        },
        '& > .mobile-list': {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
            '& > *': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            },
            '& > *:last-child': {
                borderBottom: 'none',
            },
        },
        '& > table > tbody > tr > td:first-of-type': {
            width: 75,
            '& > *': {
                objectFit: 'cover',
                cursor: 'pointer',
            }
        },
        '& .file-name': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.dark,
            }
        }
    },
    button: {
        margin: theme.spacing(1),
    },
}), "FileList"
)
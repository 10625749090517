import React, { useCallback, useEffect, useState } from 'react'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import LoadingScreen from '../../_components/LoadingScreen'
import { Paper, Theme } from '@mui/material'

import {
    ArgumentAxis,
    ValueAxis,
    Chart,
    LineSeries,
    Title,
    Legend,
} from '@devexpress/dx-react-chart-material-ui';
import DateRangePicker, { DateRange } from '../../_components/DateRangePicker'
import { format, subDays } from 'date-fns'
import { generateStyle } from '../../_utils/DefaultStyle'

interface IState {
    startDate: DateRange
    endDate: DateRange
}

interface IData {
    date: string
    apartment: number
    rumah: number
    tanah: number
    ruko: number
    kantor: number
}

const RentSubmissionChart = () => {
    const { Root, classes } = useStyles()

    const [isLoading, setIsLoading] = useState(true)
    const [state, setState] = useState<IState>({
        startDate: subDays(new Date(), 7),
        endDate: new Date()
    })

    const [data, setData] = useState<IData[]>([])

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    const fetchData = () => {
        setIsLoading(true)
        let date = state.startDate === null ? '' : format(state.startDate, 'dd/MM/yyyy')

        if (date || state.endDate) {
            date += '-'
            date += state.endDate === null ? '' : format(state.endDate, 'dd/MM/yyyy')
        }

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/listing-submission-chart`, {
            params: {
                date,
            }
        })
            .then(res => res.data)
            .then(data => {
                setData(data)
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const LegendRootComponent = useCallback((props: Legend.RootProps) => {
        return (
            <Legend.Root className={classes.LegendRoot}>
                {props.children}
            </Legend.Root>
        )
    }, [classes])

    const SeriesComponent = useCallback((props: LineSeries.SeriesProps) => {
        console.log(props.coordinates)
        return (
            <>
                <g>
                    {
                        props.coordinates.map((coord, index) => (
                            <g
                                key={index}
                            >
                                <text
                                    x={coord.arg.toString()}
                                    y={coord.val.toString()}
                                    fill={props.color}
                                    fontSize={14}
                                    fontWeight={500}
                                    style={{
                                        transform: `translate(${coord.value < 10 ? '-4' : coord.value < 100 ? '-7' : '-11'}px,-8px)`
                                    }}
                                >
                                    {coord.value}
                                </text>

                                <circle
                                    cx={coord.arg.toString()}
                                    cy={coord.val.toString()}
                                    fill={props.color}
                                    r="5"
                                />
                            </g>
                        ))
                    }
                </g>
                <LineSeries.Path {...props} />
            </>
        )
    }, [])

    return (
        <Root>
            <LoadingScreen
                open={isLoading}
                fullScreen
            />
            <div className={classes.datePicker}>
                <DateRangePicker
                    startDate={state.startDate}
                    endDate={state.endDate}
                    onDatesChange={dates => setState(dates)}
                    onFinished={fetchData}
                    format="dd/MM/yyyy"
                    fullWidth
                />
            </div>
            <Paper className={classes.chartContainer}>
                <Chart
                    data={data}
                >
                    <ArgumentAxis />
                    <ValueAxis />

                    <LineSeries
                        name="Apartment"
                        valueField="apartment"
                        argumentField="date"
                        seriesComponent={SeriesComponent}
                    />

                    <LineSeries
                        name="Rumah"
                        valueField="rumah"
                        argumentField="date"
                        seriesComponent={SeriesComponent}
                    />

                    <LineSeries
                        name="Tanah"
                        valueField="tanah"
                        argumentField="date"
                        seriesComponent={SeriesComponent}
                    />

                    <LineSeries
                        name="Ruko"
                        valueField="ruko"
                        argumentField="date"
                        seriesComponent={SeriesComponent}
                    />

                    <LineSeries
                        name="Kantor"
                        valueField="kantor"
                        argumentField="date"
                        seriesComponent={SeriesComponent}
                    />

                    <Legend
                        position="bottom"
                        rootComponent={LegendRootComponent}
                    />
                    <Title
                        text="Rent Submissions"
                    />
                </Chart>
            </Paper>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) =>
({
    chartContainer: {
        marginTop: 32,
    },
    datePicker: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
            maxWidth: 300,
            marginLeft: 'auto',
        }
    },
    LegendRoot: {
        display: 'flex',
    }
}), "RentSubmissionChart"
)

export default RentSubmissionChart
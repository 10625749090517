import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import TextArea from '../../../../_components/_form/TextArea';
import ButtonWithLoading from '../../../../_components/_form/ButtonWithLoading';

/**
 * Utils
 */
import DefaultAxios from '../../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../../_utils/Helper';

interface IProps {
    id: number;
    open: boolean;
    status: {
        isEntrustedKey: number,
        category: number
    };
    onClose: Function;
    callBackSubmitSuccess?: (isCategoryChanged: boolean) => void;
    setIsLoading: Function;
    isLoading?: boolean
}

const SwitchEntrustKeyModal = ({ id, open, onClose, setIsLoading, callBackSubmitSuccess, status: { isEntrustedKey, category }, isLoading }: IProps) => {
    useEffect(() => {
        setEntrustKeyComment('');
        // eslint-disable-next-line
    }, [open]);

    const [entrustKeyComment, setEntrustKeyComment] = useState('');
    const [categoryChanged, setCategoryChanged] = useState(false)

    const handlePublishSubmit = () => {
        if (!entrustKeyComment) {
            Swal.fire({
                title: 'Validasi Error',
                text: "Silahkan isi komentar",
                icon: 'warning',
            });
            return;
        }

        setIsLoading(true);
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit/switch-entrusted-key`, {
            id: id,
            comment: entrustKeyComment,
            change_category: categoryChanged ? 1 : 0
        })
            .then(res => {
                if (typeof callBackSubmitSuccess !== "undefined") {
                    if (categoryChanged) {
                        callBackSubmitSuccess(true)
                    } else {
                        callBackSubmitSuccess(false);
                    }
                }
                onClose();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={() => onClose()}
        >
            <DialogTitle>{isEntrustedKey ? 'Remove Key' : 'Entrust Key'} Unit</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <TextArea
                            label="Comment"
                            name="comment"
                            value={entrustKeyComment}
                            onChange={(e) => setEntrustKeyComment(e.target.value)}
                            disabled={isLoading}
                        />
                    </Grid>
                    {
                        isEntrustedKey === category ?
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={categoryChanged}
                                            onChange={() => setCategoryChanged(prev => !prev)}
                                            color="primary"
                                            disabled={isLoading}
                                        />
                                    }
                                    label={`Rubah unit menjadi ${category ? 'Reguler' : 'Star Listing'}`}
                                />
                            </Grid> : null

                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWithLoading onClick={handlePublishSubmit} color="primary" isLoading={isLoading}>
                    Submit
                </ButtonWithLoading>
            </DialogActions>
        </Dialog>
    )
}

export default SwitchEntrustKeyModal;
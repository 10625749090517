import React, { useState, useReducer, useEffect } from 'react';
import { Theme, Grid, TextField, Button } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertNumberComaDecimal, generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    onSubmitSuccess: () => void,
    provinceId: string
}

interface IState {
    name: string;
    latitude: string
    longitude: string
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

interface IErrorState {
    name: string;
    latitude: string
    longitude: string
}

interface IErrorAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) =>
({
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
        margin: theme.spacing(0, 1),
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'right',
        marginBottom: '10px'
    }
}), "ProvinceForm"
)

const ProvinceForm = (props: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/province';

    const [loading, setLoading] = useState(false);

    // Error State
    const errorReducer = (state: IErrorState, action: IErrorAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                latitude: '',
                longitude: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [errorState, setErrorState] = useReducer(errorReducer, {
        name: '',
        latitude: '',
        longitude: '',
    });

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                latitude: '',
                longitude: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        name: '',
        latitude: '',
        longitude: '',
    });

    const checkValidation = () => {
        let isValid = true;
        let newError = { ...errorState };
        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        } else {
            newError.longitude = '';
        }
        if (!inputState.longitude) {
            newError.longitude = 'Longitude wajib diisi';
            isValid = false;
        } else {
            newError.longitude = '';
        }
        if (!inputState.latitude) {
            newError.latitude = 'Latitude wajib diisi';
            isValid = false;
        } else {
            newError.latitude = '';
        }
        setErrorState({ type: 'REPLACE_STATE', name: '', value: newError });
        return isValid;
    }

    const handleInputChanged = (e: any) => {
        const target = e.target;

        if (target) {
            let value = target.value;
            if (target.name === 'latitude' || target.name === 'longitude') {
                setInputState({ name: target.name, value: convertNumberComaDecimal(value), type: 'SET_ITEM' });
            } else {
                setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
            }
        }
    }

    const submitForm = () => {

        if (!checkValidation()) {
            return;
        }

        let axios;

        if (props.provinceId) {
            axios = DefaultAxios.patch(`${API_URL}/${props.provinceId}`, inputState)
            setLoading(true);
            axios
                .then(res => {
                    Swal.fire({
                        title: "Province Updated",
                        icon: 'success',
                        onAfterClose: props.onSubmitSuccess,
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        } else {
            axios = DefaultAxios.post(`${API_URL}`, inputState);
            setLoading(true);
            axios
                .then(res => {
                    Swal.fire({
                        title: "Province Submitted",
                        icon: 'success',
                        onAfterClose: props.onSubmitSuccess,
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        if (props.provinceId) {
            setLoading(true);
            DefaultAxios
                .get(`${API_URL}/${props.provinceId}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                    setLoading(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Root>
            <div className={classes.root}>
                <LoadingScreen open={loading} fullScreen />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            error={!!errorState.name}
                            label="Name"
                            variant="outlined"
                            name="name"
                            value={inputState.name}
                            onChange={handleInputChanged}
                            fullWidth
                            helperText={errorState.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={!!errorState.longitude}
                            label="Longitude"
                            variant="outlined"
                            name="longitude"
                            // type="number"
                            value={inputState.longitude}
                            onChange={handleInputChanged}
                            fullWidth
                            helperText={errorState.longitude}
                            placeholder="For Jakarta, ex: 106.0253"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Latitude"
                            name="latitude"
                            value={inputState.latitude}
                            onChange={handleInputChanged}
                            variant="outlined"
                            // type="number"
                            fullWidth
                            error={!!errorState.latitude}
                            helperText={errorState.latitude}
                            placeholder="For Jakarta, ex: -6.1745"
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.gridSubmit}>
                        <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Root>
    );
}

export default ProvinceForm;
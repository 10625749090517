import React, { useState, useContext } from 'react';
import {
    Button,
    Tooltip,
    IconButton,
    Box
} from '@mui/material';

/**
 * Components
*/
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';
import ListingTicketAddModal from './_components/ListingTicketAddModal';
import ListingTicketPICModal from './_components/ListingTicketChangePICModal';
import ListingTicketCancelModal from './_components/ListingTicketCancelModal';
import ListingTicketDoneModal from './_components/ListingTicketDoneModal';
import ListingTicketDetailModal from './_components/ListingTicketDetailModal';
import ListingTicketInfoModal from './_components/ListingTicketInfoModal';

/**
 * Utils
*/
import { download, generalErrorHandler } from '../../_utils/Helper';
import { format } from 'date-fns';
import { PermissionContext } from '../../_contexts/PermissionContext';

/**
 * Icons
*/
import { Cancel, CheckCircle, ListAlt, Add, Group, Info } from '@mui/icons-material';

type ModalState = {
    type: null | 'add' | 'change-pic' | 'detail' | 'cancel' | 'done' | 'info'
    extraData?: { [key: string]: any }
}

const ListingTicketList = () => {
    const permissions = useContext(PermissionContext)

    const [reDraw, setReDraw] = useState(1)
    const [modalState, setModalState] = useState<ModalState>({
        type: null
    })
    const [isFormLoading, setIsFormLoading] = useState(false)

    const closeModal = () => setModalState({ type: null })

    const handleExport = () => {
        setIsFormLoading(true)

        let params = window.location.search
        const rx = /created_at=.*/
        const createdAt = rx.exec(params)

        // Handle converting date format from yyyy-MM-dd from query params to dd/MM/yyyy for API params
        if (createdAt?.length) {
            const createdAtList = createdAt[0].replace('created_at=', '').replace(/%20/g, ' ').split(' - ')
            const startDate = createdAtList[0] === '' ? '' : format(new Date(createdAtList[0]), 'dd/MM/yyyy')
            const endDate = createdAtList[1] === '' ? '' : format(new Date(createdAtList[1]), 'dd/MM/yyyy')
            params = params.replace(rx, `created_at=${startDate} - ${endDate}`)
        }

        download(
            `${process.env.REACT_APP_API_URL}/listing-ticket/export${params}`,
            'listing-ticket.xls',
            () => {
                setIsFormLoading(false)
            },
            err => {
                generalErrorHandler(err)
                setIsFormLoading(false)
            }
        )
    }

    return (
        <>
            <LoadingScreen open={isFormLoading} />

            <ListingTicketAddModal
                isOpen={modalState.type === 'add'}
                onClose={closeModal}
                onFinish={() => {
                    setReDraw(prev => prev + 1)
                }}
            />

            <ListingTicketPICModal
                isOpen={modalState.type === 'change-pic'}
                onClose={closeModal}
                onFinish={() => {
                    setReDraw(prev => prev + 1)
                }}
                data={{
                    id: modalState.extraData?.id || null,
                }}
            />

            <ListingTicketCancelModal
                isOpen={modalState.type === 'cancel'}
                onClose={closeModal}
                onFinish={() => {
                    setReDraw(prev => prev + 1)
                }}
                data={{
                    id: modalState.extraData?.id || null,
                }}
            />

            <ListingTicketDoneModal
                isOpen={modalState.type === 'done'}
                onClose={closeModal}
                onFinish={() => {
                    setReDraw(prev => prev + 1)
                }}
                data={{
                    id: modalState.extraData?.id || null,
                    unit_id: modalState.extraData?.unit_id || null,
                }}
            />

            <ListingTicketDetailModal
                isOpen={modalState.type === 'detail'}
                onClose={closeModal}
                onFinish={() => {
                    setReDraw(prev => prev + 1)
                }}
                data={{
                    id: modalState.extraData?.id || null,
                    detail: modalState.extraData?.detail || null,
                }}
            />

            <ListingTicketInfoModal
                isOpen={modalState.type === 'info'}
                onClose={closeModal}
                data={{
                    file: modalState.extraData?.file || '',
                    reason: modalState.extraData?.reason || '',
                }}
            />

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                {permissions['listing-ticket.add'] &&
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<Add />}
                        onClick={() => {
                            setModalState({ type: 'add' })
                        }}
                    >
                        Add Data
                    </Button>
                }

                {permissions['listing-ticket.export'] &&
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        sx={{ marginLeft: 'auto' }}
                        onClick={handleExport}
                    >
                        Export
                    </Button>
                }
            </Box>

            <DataTable
                reDraw={reDraw}
                url={`${process.env.REACT_APP_API_URL}/listing-ticket`}
                columns={[
                    {
                        name: 'created_at',
                        label: 'Created Date',
                        type: 'date',
                        filterable: true,
                        sortable: true,
                        render: (data: any, row: any) => {
                            return format(new Date(data), 'dd MMMM yyyy HH:mm')
                        }
                    },
                    {
                        name: 'ticket_age',
                        label: 'Ticket Age',
                        type: 'number',
                        filterable: true,
                        render: (data: any, row: any) => `${data} days`
                    },
                    {
                        name: 'code',
                        label: 'Code',
                        type: 'string',
                        filterable: true,
                    },
                    {
                        name: 'type',
                        label: 'Type',
                        type: 'string',
                        filterable: true,
                        options: [
                            {
                                key: '',
                                value: 'All',
                            },
                            {
                                key: 'fu_new',
                                value: 'FU New Owner',
                            },
                            {
                                key: 'fu_exist',
                                value: 'FU Existing Owner',
                            },
                        ],
                        render: (data: any) => TYPES[data as 'fu_new' | 'fu_exist'] || data || '-'
                    },
                    {
                        name: 'unit_code',
                        label: 'Unit Code',
                        type: 'string',
                        filterable: true,
                        render: (data: any) => data || '-'
                    },
                    {
                        name: 'pic_name',
                        label: 'PIC',
                        type: 'string',
                        filterable: true,
                        render: (data: any, row: any) => (
                            <>
                                {data || '-'}
                                {
                                    permissions['listing-ticket.admin'] ?
                                        <IconButton
                                            color="primary"
                                            component="span"
                                            onClick={() => {
                                                setModalState({
                                                    type: 'change-pic',
                                                    extraData: {
                                                        id: row.id,
                                                    }
                                                })
                                            }}
                                        >
                                            <Group fontSize="small" />
                                        </IconButton>
                                        : null
                                }
                            </>
                        )
                    },
                    ...(
                        permissions['listing-ticket.admin'] ?
                            [{
                                name: 'status_label',
                                label: 'Status',
                                type: 'string',
                                filterable: true,
                                options: [
                                    {
                                        key: '',
                                        value: 'All',
                                    },
                                    {
                                        key: '0',
                                        value: 'On Process',
                                    },
                                    {
                                        key: '1',
                                        value: 'Complete',
                                    },
                                    {
                                        key: '2',
                                        value: 'Canceled',
                                    },
                                ],
                                render: (data: any, row: any) => (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {data || '-'}
                                        {
                                            row.status !== 0 ?
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() => {
                                                        setModalState({
                                                            type: 'info',
                                                            extraData: {
                                                                reason: row.cancel_complete_reason,
                                                                file: row.cancel_complete_proof_url,
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <Info fontSize="small" />
                                                </IconButton>
                                                : null
                                        }
                                    </Box>
                                )
                            }]
                            : []
                    ),
                    {
                        name: 'requested_by_name',
                        label: 'Requested By',
                        type: 'string',
                        filterable: true,
                        render: (data: any) => data || '-'
                    },
                    // {
                    //     name: 'requested_by_role_name',
                    //     label: 'Division',
                    //     type: 'string',
                    //     filterable: true,
                    //     render: (data: any) => data || '-'
                    // },
                    {
                        name: 'detail',
                        label: 'Detail',
                        type: 'string',
                        filterable: false,
                        render: (data: any, row: any) => (
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => {
                                    setModalState({
                                        type: 'detail',
                                        extraData: {
                                            id: row.id,
                                            detail: data,
                                        }
                                    })
                                }}
                            >
                                <ListAlt fontSize="small" />
                            </IconButton>
                        )
                    },
                    {
                        name: 'created_by_name',
                        label: 'Created By',
                        type: 'string',
                        filterable: true,
                        render: (data: any) => data || '-'
                    },
                    {
                        name: 'EXTRA',
                        label: 'Action',
                        type: 'string',
                        filterable: false,
                        sortable: false,
                        render: (row: any) => {
                            return (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {
                                        row.status === 0 ?
                                            <>
                                                <Tooltip title='Done'>
                                                    <IconButton
                                                        color="primary"
                                                        component="span"
                                                        onClick={() => {
                                                            setModalState({
                                                                type: 'done',
                                                                extraData: {
                                                                    id: row.id,
                                                                    unit_id: row.unit_id,
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        <CheckCircle fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Cancel'>
                                                    <IconButton
                                                        color="error"
                                                        component="span"
                                                        onClick={() => {
                                                            setModalState({
                                                                type: 'cancel',
                                                                extraData: {
                                                                    id: row.id,
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        <Cancel fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                            : null
                                    }
                                </Box>
                            );
                        }
                    }
                ]}
            />
        </>
    );
}

const TYPES = {
    fu_new: 'FU New Owner',
    fu_exist: 'FU Existing Owner',
}

export default ListingTicketList;

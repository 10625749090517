import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// Components
import LoadingScreen from '../../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import { dateFormat } from '../../../_utils/Helper';

interface Props {
    API_URL: string;
}

const LogList = (props: Props) => {

    const [logs, setLogs] = useState<any[]>([]);
    const [selectedLog, setSelectedLog] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    useEffect(() => {
        loadLogs();
        // eslint-disable-next-line
    }, []);

    const loadLogs = () => {
        setIsLoading(true);
        DefaultAxios
            .get(props.API_URL)
            .then(res => {
                setLogs(res.data);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const renderLogs = () => {
        if (logs.length > 0) {
            return logs.map((log: any, key: number) => {
                return <TableRow>
                    <TableCell>{dateFormat(log.created_at, 'DD-MM-YYYY HH:mm:ss')}</TableCell>
                    <TableCell>{log.full_name}</TableCell>
                    <TableCell>{log.type}</TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                                setSelectedLog(JSON.parse(log.log));
                                handleModalOpen();
                            }}
                        >
                            See Log
                        </Button>
                    </TableCell>
                </TableRow>
            });
        } else {
            return <TableRow>
                <TableCell colSpan={4}>There is no logs yet</TableCell>
            </TableRow>
        }
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
            >
                <DialogContent>
                    <div>
                        <pre>
                            {selectedLog ? JSON.stringify(selectedLog, null, 2) : 'Tidak Ada  Log'}
                        </pre>
                    </div>
                </DialogContent>
            </Dialog>
            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Tanggal
                            </TableCell>
                            <TableCell>
                                User
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderLogs()}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default LogList;
import React, { useEffect, useState } from 'react';
import { Grid, Select, MenuItem, Theme, TextField, Button, Slide, Dialog, AppBar, Toolbar, IconButton, Typography, SelectChangeEvent } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

// Components
import SimpleCard from '../../_components/SimpleCard';
import HandoverUnitDetail from './HandoverUnitDetail';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import LoadingScreen from '../../_components/LoadingScreen';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import { generateStyle } from '../../_utils/DefaultStyle';

type Sort = 'recent' | 'name';

interface IUnit {
    unit_closing_breakdown_id: number;
    code: string;
    tower: string;
    floor_level: string;
    unit_number: string;
    apartment_name: string;
    status: number
}

interface IState {
    units: IUnit[];
    more: boolean;
    perPage: number;
    page: number;
    sort: Sort;
    search: string
}

const useStyles = generateStyle((theme: Theme) => ({
    selectRoot: {
        float: 'right'
    },
    gridUnitList: {
        marginTop: theme.spacing(2)
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "HandoverUnit_List"
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let typingTimer: any;

const HandoverUnitList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/unit-closing-breakdown';
    const { Root, classes } = useStyles();

    const initialState: IState = {
        units: [],
        more: false,
        perPage: 2,
        page: 1,
        sort: 'recent',
        search: ''
    };

    const [state, setState] = useState<IState>(initialState);
    const [loading, setLoading] = useState(false);
    const [firstInit, setFirstInit] = useState(true);
    const [modalState, setModalState] = useState(false);
    const [selectedId, setSelectedId] = useState<number | null>(null);

    const handleOpen = () => setModalState(true);
    const handleClose = () => setModalState(false);

    const loadUnit = () => {
        setLoading(true);

        DefaultAxios
            .get(API_URL, {
                params: {
                    perPage: state.perPage,
                    page: state.page,
                    sort: state.sort,
                    s: state.search
                }
            })
            .then(res => {
                setState({
                    ...state,
                    units: res.data.units,
                    more: res.data.more
                });
            })
            .finally(() => {
                if (firstInit) {
                    setFirstInit(false);
                }
                setLoading(false);
            })
    };

    useEffect(() => {
        if (firstInit) {
            loadUnit();
        } else {
            let doneTypingInterval = 1500;
            clearTimeout(typingTimer);
            const doneTyping = () => loadUnit()
            typingTimer = setTimeout(doneTyping, doneTypingInterval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.search]);

    useEffect(() => {
        if (!firstInit) {
            loadUnit();
        }
        // eslint-disable-next-line
    }, [state.sort, state.page]);

    const sortHandler = (e: SelectChangeEvent<"name" | "recent">, child: React.ReactNode) => {
        setState({ ...state, sort: e.target.value as Sort });
    }

    const searchHandler = (e: React.ChangeEvent<{ value: unknown }>) => {
        setState({ ...state, search: e.target.value as string });
    }

    const viewMore = () => {
        setState({ ...state, page: state.page + 1 });
    }

    const openUnitDetailModal = (id: number) => {
        setSelectedId(id);
        handleOpen();
    }

    const unitList = state.units.map((unit: IUnit, key: number) => {
        return <SimpleCard
            title={`${unit.code} - ${unit.tower} / ${unit.floor_level} / ${unit.unit_number} ${+unit.status === 1 ? '(FINISHED)' : ''}`}
            subtitle={unit.apartment_name}
            key={key}
            onClick={() => openUnitDetailModal(unit.unit_closing_breakdown_id)}
        />
    })

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Detail Unit
                </Typography>
            </Toolbar>
        )
    }

    const finishHandler = (closeModal: boolean = true) => {
        loadUnit();
        if (closeModal) {
            handleClose();
        }
    };

    return (
        <Root>
            <Grid container spacing={0}>
                <LoadingScreen open={loading} fullScreen />
                <Dialog
                    fullScreen
                    open={modalState}
                    onClose={handleClose}
                    closeAfterTransition
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <FormToolbar />
                    </AppBar>
                    <HandoverUnitDetail
                        selectedId={selectedId as number}
                        onFinish={finishHandler}
                    />
                </Dialog>
                <Grid item xs={6}>
                    <TextField
                        variant="standard"
                        placeholder="Search"
                        value={state.search}
                        onChange={searchHandler}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Select
                        className={classes.selectRoot}
                        value={state.sort}
                        onChange={sortHandler}
                        disableUnderline
                    >
                        <MenuItem value="recent">Terbaru</MenuItem>
                        <MenuItem value="name">A-Z</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} className={classes.gridUnitList}>
                    {unitList}
                </Grid>
                {
                    state.more
                        ? <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={viewMore}
                            >
                                View more
                            </Button>
                        </Grid>
                        : ''

                }
            </Grid>
        </Root>
    );
}

export default HandoverUnitList;
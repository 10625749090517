import React, { useState, useEffect, useReducer } from 'react';
import { Grid } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import ImagePreview from '../../_components/ImagePreview';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';

/**
 * Assets
 */
import pdfPng from '../../Assets/Images/png/pdf.png';
import docPng from '../../Assets/Images/png/doc.png';

interface IState {
    id: string;
    name: string,
    phone: string,
    alternative_contact: string;
    birth_date: string;
    religion: string;
    email: string,
    notes: string
    bank_type: string,
    bank_number: string,
    bank_behalf_name: string,
    bank_branch: string,
    address: string,
    ktp_number: string,
    npwp_number: string,
    kk_number: string
    nik: string,
    files: IFile[];
}

interface IFile {
    filename: string;
    filepath: string;
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const LandlordCleanDataForm = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/clean-landlord-data';

    const initialState = {
        id: '',
        name: '',
        phone: '',
        alternative_contact: '',
        birth_date: '',
        religion: '',
        email: '',
        notes: '',
        bank_type: '',
        bank_number: '',
        bank_behalf_name: '',
        bank_branch: '',
        address: '',
        ktp_number: '',
        npwp_number: '',
        kk_number: '',
        nik: '',
        files: []
    }

    const [isLoading, setIsLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(API_URL)
            .then(res => {
                const data = { ...res.data };

                for (let key in data) {
                    if (data[key] === null) {
                        data[key] = '';
                    }
                }

                setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (['ktp_number', 'npwp_number', 'kk_number', 'bank_number'].includes(name)) {
            value = String(value).replace(/\D+/g, '');
        }

        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handlePreviewFile = (src: string, isImage: boolean) => {
        if (isImage) {
            setPreviewImage(src);
        } else {
            window.open(src);
        }
    }

    const renderFiles = () => {
        if (!inputState.files.length) {
            return <h3>No file found</h3>
        }

        return inputState.files.map((file: IFile, key: number) => {
            let src = '';
            let isImage = false;
            let filepath = file.filepath;

            let explode = filepath.split('?');
            const match = /(\.\w+$)/g.exec(explode[0]);

            if (match) {
                if (match[1] === '.pdf' || match[1] === '.PDF') {
                    src = pdfPng;
                } else if (match[1] === '.doc' || match[1] === '.docx') {
                    src = docPng;
                } else {
                    src = filepath;
                    isImage = true;
                }
            }

            return <Grid item xs={3} key={key}>
                <img 
                    src={src} 
                    alt={file.filename} 
                    title={file.filename} 
                    width="100%" 
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={() => handlePreviewFile(filepath, isImage)}
                />
            </Grid>
        })
    }

    const handleSubmit = () => {
        renderWarningButton("Apakah anda yakin ingin mengubah data landlord ini?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios
                    .post(API_URL, inputState)
                    .then(() => {
                        Swal.fire({
                            title: "Berhasil mengubah data",
                            icon: 'success',
                            timer: 1000,
                            onAfterClose: () => {
                                window.location.reload();
                            }
                        })
                    })
                    .catch(error => {
                        generalErrorHandler(error)
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        });
    }

    const handleSkip = () => {
        renderWarningButton("Apakah anda yakin ingin meng-skip data landlord ini?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios
                    .post(API_URL + '/skip', { id: inputState.id })
                    .then(() => {
                        Swal.fire({
                            title: "Berhasil meng-skip data",
                            icon: 'success',
                            timer: 1000,
                            onAfterClose: () => {
                                window.location.reload();
                            }
                        })
                    })
                    .catch(error => {
                        generalErrorHandler(error)
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        });
    }

    return (
        <>
            <ImagePreview 
                image={previewImage}
                onClose={() => setPreviewImage(undefined)}
            />
            <LoadingScreen open={isLoading} fullScreen />
            <h1 style={{ marginLeft: '10px' }}>Landlord Clean Data Form</h1>

            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={{ marginLeft: '10px' }}>
                            <p>Name</p>
                            <input 
                                type="text" 
                                name="name" 
                                value={inputState.name} 
                                onChange={handleChange}
                            />

                            <p>Phone Number</p>
                            <input 
                                type="text" 
                                name="phone" 
                                value={inputState.phone} 
                                onChange={handleChange}
                            />

                            <p>Alternative Contact Number</p>
                            <textarea 
                                name="alternative_contact" 
                                value={inputState.alternative_contact}
                                onChange={handleChange}
                            />

                            <p>Birth Date</p>
                            <input 
                                type="date" 
                                name="birth_date" 
                                value={inputState.birth_date} 
                                onChange={handleChange}
                            />

                            <p>Religion</p>
                            <input 
                                type="text" 
                                name="religion" 
                                value={inputState.religion} 
                                onChange={handleChange}
                            />

                            <p>Email</p>
                            <input 
                                type="text" 
                                name="email" 
                                value={inputState.email} 
                                onChange={handleChange}
                            />

                            <p>Address</p>
                            <input 
                                type="text" 
                                name="address" 
                                value={inputState.address} 
                                onChange={handleChange}
                            />

                            <p>KTP Number</p>
                            <input 
                                type="text" 
                                name="ktp_number" 
                                value={inputState.ktp_number} 
                                onChange={handleChange}
                            />

                            <p>NPWP Number</p>
                            <input 
                                type="text" 
                                name="npwp_number" 
                                value={inputState.npwp_number} 
                                onChange={handleChange}
                            />

                            <p>KK Number</p>
                            <input 
                                type="text" 
                                name="kk_number" 
                                value={inputState.kk_number} 
                                onChange={handleChange}
                            />

                            <p>Bank Number</p>
                            <input 
                                type="text" 
                                name="bank_number" 
                                value={inputState.bank_number} 
                                onChange={handleChange}
                            />

                            <p>Bank Name</p>
                            <input 
                                type="text" 
                                name="bank_type" 
                                value={inputState.bank_type} 
                                onChange={handleChange}
                            />

                            <p>Bank Account Name</p>
                            <input 
                                type="text" 
                                name="bank_behalf_name" 
                                value={inputState.bank_behalf_name} 
                                onChange={handleChange}
                            />

                            <p>Bank KCP</p>
                            <input 
                                type="text" 
                                name="bank_branch" 
                                value={inputState.bank_branch} 
                                onChange={handleChange}
                            />

                            <br />
                            <br />

                            <button
                                type="button"
                                onClick={handleSubmit}
                                style={{
                                    marginRight: '105px'
                                }}
                            >
                                Submit
                            </button>

                            <button
                                type="button"
                                onClick={handleSkip}
                            >
                                Skip
                            </button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        {renderFiles()}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default LandlordCleanDataForm;
import React, { useState, useEffect, CSSProperties } from 'react';
import { TableFooter, TablePagination, Theme } from '@mui/material';

// Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// Utilities
import SortableTableHeader from './SortableTableHeader';

// Component
import LoadingScreen from '../LoadingScreen';

// Type
// import { Order } from './SortableTableHeader';

//Utils

// Styles
import './CSS/datatable.css';
import { generateStyle } from '../../_utils/DefaultStyle';
import FilterTableHeader from './FilterTableHeader';

interface Props {
    columns: IColumn[];
    customData: any[]
    reDraw?: number;
    noCenter?: boolean
    withPagination?: boolean
    customHeader?: React.ReactElement
    dashOnEmpty?: boolean
    limitHeight?: number
    filterable?: boolean
    onFilter?: (filter: Record<string, string>) => void
}

export interface IColumn {
    name: string;
    label: string | React.ReactElement;
    type?: string;
    render?: Function;
    filterable?: boolean;
    style?: CSSProperties | any;
    options?: any[] | Object;
    defaultOption?: string;
    columnStyle?: CSSProperties | any
    columnProps?: TableCellProps
}

const useStyles = generateStyle((theme: Theme) => ({
    table: {
        // position: 'relative'
    },
    tableContainer: {
        position: 'relative',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "50%"
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    refresh_btn: {
        marginTop: theme.spacing(2)
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    noData: {
        textAlign: 'left'
    },
    loadingBody: {
        textAlign: 'center',
        position: 'relative',
        height: '100px'
    }
}), "SimpleDataTable"
);


export default function SimpleDataTable(props: Props) {
    const { Root, classes } = useStyles();
    const columns = props.columns;

    const [rows, setRows] = useState<any[]>([]);
    const [pagination, setPagination] = useState({
        page: 0,
        rowsPerPage: 10
    })
    const [loading, setLoading] = React.useState(true);
    const [filter, setFilter] = useState<Record<string, any>>({})

    useEffect(() => {
        setRows(props.customData || [])
        setLoading(false)
        // eslint-disable-next-line
    }, []);

    const loadTable = () => {
        setRows([])
        setLoading(true);
        setPagination({ page: 0, rowsPerPage: 10 })
        setRows(props.customData || [])
        setLoading(false)
    }

    useEffect(() => {
        loadTable()
        // eslint-disable-next-line
    }, [props.reDraw])

    const handlePageChanged = (value: number, name: string) => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)

            if (name === 'rowsPerPage') {
                return setPagination({
                    page: 0,
                    rowsPerPage: value
                })
            }

            setPagination(prev => ({
                ...prev,
                [name]: value
            }))
        }, 200)
    }

    const getTableBody = () => {
        if (loading) {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell
                            className={classes.loadingBody}
                            colSpan={props.columns.length}
                        >
                            <LoadingScreen open={loading} fullScreen={false} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            );
        } else if (rows.length > 0) {
            return (
                <TableBody>
                    {
                        rows.map((row, i: number) => {
                            let index = i;
                            const tableRow = columns.map((column: any, i: number) => {
                                let value = null;
                                // let style = {};
                                if (typeof row[column.name] !== 'undefined') {
                                    if (column.render && typeof column.render === 'function') {
                                        if (column.options && column.options !== '') {
                                            value = column.render(row[column.name], row, column.name, column.options, setRows, rows);
                                        } else {
                                            value = column.render(row[column.name], row, rows, index);
                                        }
                                    } else {
                                        value = row[column.name];
                                    }
                                } else if (column.name === 'EXTRA' && column.render && typeof column.render === 'function') {
                                    value = column.render(row);
                                }

                                return value === 'NONE' ? null : value === 'Off Day' ? <TableCell colSpan={2} style={{ textAlign: 'center', ...column.columnStyle }} {...column.columnProps} key={i}>{props.dashOnEmpty ? value || '-' : value}</TableCell> : <TableCell style={{ textAlign: props.noCenter ? 'left' : 'center', ...column.columnStyle }} {...column.columnProps} key={i}>{props.dashOnEmpty ? value || '-' : value}</TableCell>
                            });
                            return <TableRow style={{ zIndex: 9999 }} key={i}>{tableRow}</TableRow>
                        }).filter((rows, idx) => {
                            if (typeof props.withPagination !== 'undefined') {
                                return idx >= ((pagination.page) * pagination.rowsPerPage) && idx < ((pagination.page + 1) * pagination.rowsPerPage)
                            } else {
                                return rows
                            }
                        })
                    }
                </TableBody>
            )
        } else {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell
                            className={classes.noData}
                            colSpan={props.columns.length}
                        >
                            No data available
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        }
    }

    const renderFooter = () => {
        if (typeof props.withPagination !== 'undefined') {
            return (
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={columns.length}                                                        >
                            <TablePagination
                                component="div"
                                sx={{ float: 'right' }}
                                count={rows.length}
                                page={pagination.page}
                                rowsPerPage={pagination.rowsPerPage}
                                onPageChange={(_, page) => handlePageChanged(page, 'page')}
                                onRowsPerPageChange={(e: any) => handlePageChanged(e.target.value, 'rowsPerPage')}
                                rowsPerPageOptions={[
                                    { label: '2', value: 2 },
                                    { label: '10', value: 10 },
                                    { label: '25', value: 25 },
                                    { label: '50', value: 50 },
                                    { label: '100', value: 100 },
                                ]}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            )
        }
    }

    useEffect(() => {
        if (typeof props.onFilter !== 'undefined' && props.filterable) {
            props.onFilter(filter)
        }

        // eslint-disable-next-line
    }, [filter])

    return (
        <Root>
            <TableContainer
                component={Paper}
                className={classes.tableContainer}
                sx={{ maxHeight: props.limitHeight || undefined }}
            >
                <Table stickyHeader={!!props.limitHeight} className={`${classes.table}`} size="small" aria-label="a dense table">
                    {
                        typeof props.customHeader !== 'undefined' ?
                            props.customHeader :
                            <TableHead>
                                <SortableTableHeader
                                    sorting={false}
                                    headCells={props.columns.slice()}
                                    order={false}
                                    orderBy={''}
                                    onRequestSort={() => { }}
                                />
                                {
                                    props.filterable ?
                                        <FilterTableHeader
                                            filterSearch={filter}
                                            onRequestFilter={(filter) => setFilter(filter)}
                                            filterable
                                            headCells={props.columns.slice()}
                                        /> : null
                                }
                            </TableHead>
                    }
                    {getTableBody()}
                    {typeof props.withPagination !== 'undefined' ?
                        renderFooter()
                        : null
                    }
                </Table>
            </TableContainer>
        </Root>
    );
}
import React, { useState, useEffect, useContext } from 'react';
import {
    Link,
    Switch,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    Box,
    DialogContent,
    Theme
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Link as RouterLink, useParams } from "react-router-dom";

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import UserModal from './UserModal';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { dateFormat, generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IOptions {
    role_list: object[]
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {
                        typeof children === 'object' ?
                            children :
                            <Typography>{children}</Typography>
                    }
                </Box>
            )}
        </div>
    );
}

const useStyles = generateStyle((theme: Theme) =>
({
    root: {
        display: 'inline-flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "UserList"
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserList() {
    const permissions = useContext(PermissionContext)
    const USER_URL = process.env.REACT_APP_API_URL + '/user';
    const LOG_URL = process.env.REACT_APP_API_URL + '/log-user';
    const [open, setOpen] = useState<boolean>(false)
    const { Root, classes } = useStyles();

    const { user_id } = useParams<any>();

    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const [userId, setUserId] = useState("");
    const [is_edit, setEdit] = useState<boolean>(true)
    const [options, setOptions] = useState<IOptions>({
        role_list: []
    })

    const [tab, setTab] = React.useState(0);

    const [selectedLog, setSelectedLog] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleClose = () => {
        window.history.pushState([], 'User List', `/user-list`);
        setReDrawDatatable(new Date().getTime())
        setOpen(false)
    }

    const handleAddUser = () => {
        setOpen(true);
        setEdit(false)
        setUserId('');
        window.history.pushState([], 'User List', `/user/add`);
    }

    useEffect(() => {
        if (permissions['user.manage']) {
            setTab(0);
        } else if (permissions['user.pm-manage']) {
            setTab(1);
        }
    }, [permissions]);

    useEffect(() => {
        fetchOptions();
        if (typeof user_id !== "undefined") {
            setUserId(user_id);
            setOpen(true);
        }
    }, [user_id]);

    const fetchOptions = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/role-list`)
            .then(response => response.data)
            .then(data => {
                var i;
                var newdata = { key: '', value: 'All' };

                for (i = 0; i < data.length; i++) {
                    data[i].key = String(data[i]['id']);
                    data[i].value = data[i]['name'];
                    delete data[i].id;
                    delete data[i].name;
                }
                data.splice(0, 0, newdata);
                setOptions({
                    role_list: data
                })
            })
            .catch(error => {
                alert('Kesalahan server, harap hubungi admin')
            });
    }

    const handleSwitch = (e: React.ChangeEvent<{ checked: boolean }>, columnName: string, setRows: Function, rows: any[], id: string) => {
        let checked = e.target.checked

        const data = {
            [columnName]: checked ? 1 : 0,
            id: id
        }

        renderWarningButton("Are you sure you want to change this user status?")
            .then(res => {
                if (res.value) {
                    DefaultAxios.put(`${USER_URL}/switch-active`, data)
                        .then(res => res.data)
                        .then(res => {
                            if (res.update) {
                                setNewRows(rows, data.id, columnName, checked, setRows);
                                renderToastSuccess('Status berhasil dirubah')
                                setReDrawDatatable(new Date().getTime())
                            }
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                }
            })
    }

    const setNewRows = (rows: any, id: string, columnName: string, checked: boolean, setRows: Function) => {
        const newRows = rows.map((element: any) => {
            if (element.id === id) element[columnName] = checked ? 1 : 0
            return element
        })
        setRows(newRows)
    }

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    User Details
                </Typography>
            </Toolbar>
        )
    }

    const handlePause = (e: React.MouseEvent<unknown>, pause: any, columnName: string, setRows: Function, rows: any[], id: string) => {
        renderWarningButton('Apakah anda yakin ingin mengganti status pause advisor ini?')
            .then(res => {
                if (res.value) {
                    DefaultAxios.put(`${USER_URL}/pause`, { id: id })
                        .then(res => res.data)
                        .then(res => {
                            setNewRows(rows, id, columnName, pause, setRows);
                            renderToastSuccess('Status berhasil dirubah');
                            setReDrawDatatable(new Date().getTime())
                        })
                        .catch(err => generalErrorHandler(err))
                }
            })
    }

    const renderSwitch = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
        return (
            <Switch
                checked={row[columnName] === 1}
                onChange={(e) => handleSwitch(e, columnName, setRows, rows, row.id)}
            />
        )
    }

    const renderEditButton = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
        let pause = row.is_paused
        return (
            <>
                <div className={classes.root}>
                    {['advisor', 'advisor-captain', 'telesales', 'tenant-relation', 'tenant-relation-deposit', 'tenant-relation-support', 'listing-acquisition', 'tenant-relation-renewal'].includes(row.role_slug_label) ?
                        <Button
                            variant="contained"
                            color={pause === 1 ? undefined : `secondary`}
                            onClick={(e) => handlePause(e, pause, columnName, setRows, rows, row.id)}
                        >
                            {pause === 1 ? `Unpause` : `Pause`}
                        </Button>
                        :
                        <></>
                    }
                </div>
            </>
        )
    }

    /**
     * Placeholder data
     */
    // Columns for the bottom table
    const columnsTable = [
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: true,
            sortable: false,
        },
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true,
            sortable: false,
            render: (data: any, row: any) => {
                return (
                    <Link
                        style={{ cursor: "pointer" }}
                        component={RouterLink}
                        to={`/user-list/${row.id}`}
                    >
                        {row.name}
                    </Link>
                )
            }
        },
        {
            name: 'role_name',
            label: 'Role',
            type: 'string',
            filterable: true,
            sortable: false,
            defaultOption: '',
            options: options.role_list
        },
        {
            name: 'is_active',
            label: 'Status',
            type: 'string',
            filterable: true,
            render: renderSwitch,
            defaultOption: '',
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Active'
                },
                {
                    key: '0',
                    value: 'Inactive'
                },
            ]
        },
        {
            name: 'is_paused',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: renderEditButton,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Activated'
                },
                {
                    key: '0',
                    value: 'Inactive'
                }
            ]
        },
    ]

    const columnsTablePriority = [
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: true,
            sortable: false,
        },
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true,
            sortable: false,
            render: (data: any, row: any) => {
                return (
                    <Link
                        style={{ cursor: "pointer" }}
                        component={RouterLink}
                        to={`/user-list/${row.id}`}
                    >
                        {row.name}
                    </Link>
                )
            }
        },
        {
            name: 'is_active',
            label: 'Status',
            type: 'string',
            filterable: true,
            render: renderSwitch,
            defaultOption: '',
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Active'
                },
                {
                    key: '0',
                    value: 'Inactive'
                },
            ]
        },
        {
            name: 'is_paused',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: renderEditButton,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Activated'
                },
                {
                    key: '0',
                    value: 'Inactive'
                }
            ]
        },
    ]

    const columnsTableLog = [
        {
            name: 'created_at',
            label: 'Date',
            type: 'date',
            filterable: true,
            sortable: false,
            render: (data: any) => {
                return dateFormat(data, 'DD-MM-YYYY HH:mm:ss');
            }
        },
        {
            name: 'user_id',
            label: 'User ID',
            type: 'string',
            filterable: true,
            sortable: false,
        },
        {
            name: 'type',
            label: 'Type',
            type: 'string',
            filterable: true,
            sortable: false,
        },
        {
            name: 'editor_name',
            label: 'Created By',
            type: 'string',
            filterable: true,
            sortable: false,
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                        setSelectedLog(row.log);
                        handleModalOpen();
                    }}
                >
                    Log
                </Button>
            }
        },
    ];

    return (
        <Root>
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
            >
                <DialogContent>
                    <div>
                        <pre>
                            {selectedLog ? JSON.stringify(selectedLog, null, 2) : 'Tidak Ada  Log'}
                        </pre>
                    </div>
                </DialogContent>
            </Dialog>
            <div>
                {(permissions['user.manage'] || permissions['user.pm-manage'] || permissions['user.hr-manage'] || permissions['user.supervisor-manage'] || permissions['user.operation-manager-manage']) &&
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginBottom: 24 }}
                        onClick={handleAddUser}
                    >
                        Add User
                    </Button>
                }
                {open &&
                    <Dialog
                        fullScreen
                        open={open}
                        closeAfterTransition
                        TransitionComponent={Transition}
                    >
                        <Root>
                            <AppBar className={classes.appBar}>
                                <FormToolbar></FormToolbar>
                            </AppBar>
                            <UserModal user_id={userId} is_edit={is_edit} permissions={permissions} />
                        </Root>
                    </Dialog>
                }
                <AppBar position="static">

                    {/* {permissions['user.manage'] &&
            <Tabs value={tab} onChange={handleChangeTab} aria-label="simple tabs example">
              <Tab label="All" />
              <Tab label="Priority" />
              {permissions['user-log.view'] && <Tab label="Logs" />}
            </Tabs>
          } */}

                </AppBar>
                <TabPanel value={tab} index={0}>
                    <DataTable
                        reDraw={reDrawDatatable}
                        url={USER_URL}
                        columns={columnsTable}
                    // customParams={customParams}
                    />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <DataTable
                        reDraw={reDrawDatatable}
                        url={`${USER_URL}?round_robin=1`}
                        columns={columnsTablePriority}
                    // customParams={customParams}
                    />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    <DataTable
                        reDraw={reDrawDatatable}
                        url={LOG_URL}
                        columns={columnsTableLog}
                    />
                </TabPanel>
            </div>
        </Root>
    );
}
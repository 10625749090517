import React from 'react'

/**
 * Components
 */
import { TextField, TextFieldProps } from '@mui/material'

interface TextFieldSelectProps {
    children: React.ReactNode
}

const TextFieldSelect = ({ children, ...props }: TextFieldSelectProps & TextFieldProps) => {
    return (
        <TextField
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{
                native: true,
            }}
            {...props}
        >
            {children}
        </TextField>
    )
}

export default TextFieldSelect

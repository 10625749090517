import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TextField, ClickAwayListener } from '@mui/material';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// Interfaces
import { ICategory } from './ProjectDeveloperCategoryList';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    category: ICategory;
    onAddPropertyModalOpen: Function;
    onDeleteProperty: Function;
    isTop: boolean;
    isBottom: boolean;
    onSortCategory: Function;
    onEditCategory: Function;
    onDeleteCategory: Function;
}

const useStyles = generateStyle(theme => ({
    root: {
        backgroundColor: '#eee',
        marginBottom: '25px'
    },
    categoryName: {
        margin: 0
    },
    containerButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& button': {
            marginLeft: '15px'
        }
    },
    inputContainer: {
        display: 'flex',
        '& button': {
            marginLeft: '15px'
        }
    }
}), "ProjectDevCategoryTable")

const ProjectDeveloperCategoryTable = (props: Props) => {
    const { Root, classes } = useStyles();

    const [isEdit, setIsEdit] = useState(false);
    const [editCategoryName, setEditCategoryName] = useState(props.category.name);

    useEffect(() => {
        setEditCategoryName(props.category.name);
        setIsEdit(false);
    }, [props.category.name]);

    const renderProperties = () => {
        if (props.category.properties.length) {
            return props.category.properties.map((property) => (
                <TableRow key={property.id}>
                    <TableCell component="th" scope="row">
                        {property.name}
                    </TableCell>
                    <TableCell>
                        <IconButton aria-label="delete" color="secondary" onClick={() => props.onDeleteProperty(props.category.id, property.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            ));
        } else {
            return (
                <TableRow>
                    <TableCell component="th" scope="row" colSpan={2} style={{ textAlign: 'center' }}>
                        No Property Available
                    </TableCell>
                </TableRow>
            );
        }
    }

    const handleEditMode = () => {
        setEditCategoryName(props.category.name);
        setIsEdit(true);
    }

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditCategoryName(e.target.value);
    }

    const handleSubmitEditCategory = () => {
        props.onEditCategory(props.category.id, editCategoryName);
    }

    return (
        <Root>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={6}>
                    {
                        isEdit
                            ? <ClickAwayListener onClickAway={() => setIsEdit(false)}>
                                <div className={classes.inputContainer}>
                                    <TextField
                                        variant="outlined"
                                        value={editCategoryName}
                                        onChange={handleChanged}
                                    />
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSubmitEditCategory}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </ClickAwayListener>
                            : <h3 className={classes.categoryName}>
                                {props.category.name}
                                <IconButton color="primary" component="span" onClick={handleEditMode}>
                                    <EditIcon />
                                </IconButton>
                            </h3>
                    }
                </Grid>
                <Grid item xs={6} className={classes.containerButtons}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => props.onDeleteCategory(props.category.id)}
                    >
                        <IconButton component="span" size="small">
                            <DeleteIcon />
                        </IconButton>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.onAddPropertyModalOpen(props.category.id)}
                    >
                        Add Property
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.onSortCategory(props.category.id, 'up')}
                        disabled={props.isTop}
                    >
                        <IconButton component="span" size="small">
                            <ArrowUpwardIcon />
                        </IconButton>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.onSortCategory(props.category.id, 'down')}
                        disabled={props.isBottom}
                    >
                        <IconButton component="span" size="small">
                            <ArrowDownwardIcon />
                        </IconButton>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nama Property</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderProperties()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Root>
    );
}

export default ProjectDeveloperCategoryTable;
import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete';
import DefaultAxios from '../../../_utils/DefaultAxios';
import Swal from 'sweetalert2';

import { generalErrorHandler } from '../../../_utils/Helper';

interface IProps {
    landlordId: string
    initialQuery: string
    handleClose: Function
}

export default function LandlordPICModal({ landlordId, handleClose, initialQuery }: IProps) {
    const [value, setState] = useState('');

    const handleSubmit = () => {
        if (value === '') {
            Swal.fire({
                html: "PIC yang akan diganti tidak boleh sama dengan sebelumnya",
                icon: 'error',
            });
        } else {
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/landlord/change-pic`, { landlord_id: landlordId, assigned_pic_id: value })
                .then(() => {
                    Swal.fire({
                        html: "PIC berhasil dirubah",
                        icon: 'success',
                    })
                        .then(() => {
                            handleClose(true)
                        });
                })
                .catch(error => {
                    generalErrorHandler(error);
                })
        }
    }

    return (
        <Dialog onClose={() => handleClose()} aria-labelledby="pic-modal-dialog" open={true} fullWidth>
            <DialogTitle id="pic-modal-dialog">Ganti PIC Jendela</DialogTitle>
            <DialogContent>
                <AsyncAutoComplete
                    label="PIC Jendela"
                    name="pic_id"
                    initialQuery={initialQuery}
                    onChange={(name, value) => {
                        setState(value.id.toString());
                    }}
                    url={`${process.env.REACT_APP_API_URL}/autocomplete/user/active-staff`}
                    placeholder="PIC Jendela"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Change
                </Button>
            </DialogActions>
        </Dialog>
    );
}
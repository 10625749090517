import React, { useEffect } from 'react'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import { generateStyle } from '../_utils/DefaultStyle'

interface MiniButtonFilterProps {
    value: string | null
    onChange: (newValue: string | null) => void
    isApp?: boolean
    items: {
        value: string | null
        label: string
    }[]
    containerStyle?: React.CSSProperties
}

const MiniButtonFilter = (props: MiniButtonFilterProps) => {
    const { Root, classes } = useStyles()

    useEffect(() => {
        if (props.value === '') {
            props.onChange(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Root>
            <BottomNavigation
                value={props.value}
                onChange={(_, newValue) => {
                    props.onChange(newValue)
                }}
                showLabels
                className={classes.filter}
                style={props.isApp ? { width: '100%', height: '40px', ...props.containerStyle } : { ...props.containerStyle }}
            >
                {
                    props.items.map((item, index) =>
                        <BottomNavigationAction
                            key={index}
                            label={item.label}
                            value={item.value === '' ? null : item?.value}
                            classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
                            style={props.isApp ? { flex: 1 } : {}}
                        />
                    )
                }
            </BottomNavigation>
        </Root>
    )
}

const useStyles = generateStyle(theme => ({
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
        marginBottom: "24px",
        marginTop: '10px'
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
}), "MiniButtonFilter")

export default MiniButtonFilter

import React, { useEffect, useState } from 'react'

/**
 * Components
*/
import Dropzone from 'react-dropzone';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import TextFieldND2 from '../../../../_components/_formElements/_newDesign2/TextFieldND2';

/**
 * Utils
 */
import { generateStyle } from '../../../../_utils/DefaultStyle'
import { renderErrorButton, resizeAndResetOrientationImage } from '../../../../_utils/Helper';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

type Props = {
    open: boolean
    onClose: () => void
    onSubmit: (galleries: IUploadedImage[]) => void
    type?: string;
}

export const roomTypes = [
    { type: "bedroom", title: "Bedroom" },
    { type: "bedroom-1", title: "Bedroom 1" },
    { type: "bedroom-2", title: "Bedroom 2" },
    { type: "bedroom-3", title: "Bedroom 3" },
    { type: "living-room", title: "Living Room" },
    { type: "kitchen", title: "Kitchen" },
    { type: "dining-room", title: "Dining Room" },
    { type: "bathroom", title: "Bathroom" },
    { type: "bathroom-2", title: "Bathroom 2" },
    { type: "bathroom-3", title: "Bathroom 3" },
    { type: "maid-room", title: "Maid Room" },
    { type: "balcony", title: "Balcony" },
    { type: "private-lift", title: "Private Lift" },
    { type: "other", title: "Other" },
]

const useStyles = generateStyle((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center",
        fontSize: 22
    },
    title: {
        fontSize: 22,
        fontWeight: '700'
    },
    subtitle: {
        fontSize: 14,
        color: '#727272',
        textDecoration: 'italic',
        display: 'block'
    },
    dropzone: {
        backgroundColor: '#F5F5F5',
        width: '100%',
        minHeight: 400,
        border: '1px dashed #B2B2B2',
        display: 'flex',
        borderRadius: 12
    },
    dropzoneMainContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& > p': {
            margin: '5px 0'
        },
        '& > button': {
            marginTop: 20
        },
        margin: 'auto'
    },
    footer: {
        border: '1px 0px 0px solid #DDDDDD',
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    imageContainer: {
        position: 'relative'
    },
    image: {
        borderRadius: '10px',
        aspectRatio: '1/1',
        objectFit: 'cover',
        objectPosition: 'center',
        width: '100%'
    },
}), "Add_Landlord_Modal")


export interface IUploadedImage {
    file: File,
    type: string,
    caption: string
    thumbnail?: string
}


const ImageUploaderModal = (props: Props) => {
    const { Root, classes } = useStyles()
    const [files, setFiles] = useState<IUploadedImage[]>([])

    const captionsOptions = [
        'Master Bedroom',
        '2nd Bedroom',
        '3rd Bedroom',
        'Living Room',
        'Kitchen',
        'Balcony',
        'Swimming Pool',
        'Jogging Track',
        'Masterplan Project',
        'Denah Ruko',
        'Siteplan Cluster',
        'Dining Room',
        'Master Bathroom',
        "2nd Bathroom",
        "3rd Bathroom",
        "Backyard",
        "Frontyard",
        "Balcony",
        "Living Room 1st Floor",
        "Living Room 2nd Floor"
    ]


    const handleDrop = async (files: File[]) => {
        const result: IUploadedImage[] = []

        for (let index = 0; index < files.length; index++) {
            const resizedFile: File = await resizeAndResetOrientationImage(files[index])
            result.push({
                file: resizedFile,
                type: roomTypes[0].type,
                caption: props.type === 'apartment' ? captionsOptions[0] : roomTypes[0].title,
            })
        }

        setFiles(result)
    }

    const handleChangeFiles = (name: string, value: any, index: number) => {
        const newFiles = [...files]
        newFiles[index][name as keyof IUploadedImage] = value
        if (name === 'type' && value !== 'other') {
            newFiles[index].caption = roomTypes.filter(roomType => roomType.type === value)[0].title || ''
        }
        setFiles(newFiles)
    }

    const handleSubmit = () => {
        if (files.some(file => file.caption === '')) {
            renderErrorButton('Caption wajib diisi')
        } else {
            props.onSubmit(files)
            props.onClose()
        }
    }

    useEffect(() => {
        if (!props.open) {
            setFiles([])
        }
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: {
                    borderRadius: 20
                }
            }}
        >
            <Root>
                <DialogTitle className={classes.header}>
                    <div>
                        <Typography component={'p'} className={classes.title}>Add Image</Typography>
                        <Typography component={'p'} className={classes.subtitle}>Recomended Dimension: 1500 x 1000 or larger with the same ratio</Typography>
                    </div>

                    <IconButton
                        onClick={props.onClose}
                        size='medium'
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
                        {
                            files.length ?
                                files.map((file, idx) => {
                                    const imagePreview = URL.createObjectURL(file.file)
                                    return (
                                        <Grid item xs={2.4} key={idx}>
                                            <div className={classes.imageContainer}>
                                                <img
                                                    src={imagePreview}
                                                    alt={file.file.name}
                                                    className={classes.image}
                                                />
                                            </div>
                                            <Grid container>
                                                <Grid item xs={12} marginTop={{ md: 1, xs: 1 }}>
                                                    {props.type === "apartment" ?
                                                        <Autocomplete
                                                            forcePopupIcon={false}
                                                            freeSolo
                                                            value={file.caption}
                                                            options={captionsOptions}
                                                            onChange={(e: React.ChangeEvent<{}>, newValue: string | null) => handleChangeFiles('caption', newValue || "", idx)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    fullWidth
                                                                    name="caption"
                                                                    variant="standard"
                                                                    onChange={(e) => handleChangeFiles("caption", e.target.value, idx)}
                                                                    placeholder="Max 50 karakter"
                                                                    label="Caption"
                                                                />
                                                            )}
                                                        /> :
                                                        <TextFieldND2
                                                            name='type'
                                                            size='small'
                                                            select
                                                            value={file.type}
                                                            onChange={(e) => {
                                                                handleChangeFiles('type', e.target.value, idx)
                                                            }}
                                                        >
                                                            {
                                                                roomTypes.map((roomType) => (
                                                                    <MenuItem value={roomType.type}>{roomType.title}</MenuItem>
                                                                ))
                                                            }
                                                        </TextFieldND2>}

                                                </Grid>
                                                <Grid item xs={12}>
                                                    {props.type === "apartment" ? null :
                                                        <TextFieldND2
                                                            name='caption'
                                                            size='small'
                                                            placeholder='Caption'
                                                            value={file.caption}
                                                            onChange={(e) => {
                                                                handleChangeFiles('caption', e.target.value, idx)
                                                            }}
                                                            error={file.caption === ''}
                                                            helperText={file.caption === '' ? 'Caption wajib diisi' : ''}
                                                        />}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }) :
                                <Grid item xs={12}>
                                    <Dropzone
                                        onDrop={handleDrop}
                                        accept={{
                                            'image/jpeg': [],
                                            'image/png': [],
                                            'image/webp': [],
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div className={classes.dropzone}>
                                                <div {...getRootProps()} className={classes.dropzoneMainContainer}>
                                                    <input {...getInputProps()} />
                                                    <AddIcon style={{ width: 60, height: 60 }} />
                                                    <p className={classes.title}>Drag and Drop Image Here</p>
                                                    <p>or browse to select and image</p>
                                                    <Button variant='contained'>
                                                        Browse
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions style={{ borderTop: '1px solid #ddd', padding: '20px 40px' }}>
                    <Grid container>
                        <Grid item xs={12} className={classes.footer}>
                            <Button style={{ textTransform: 'capitalize' }} color='info' onClick={props.onClose}>
                                Batalkan
                            </Button>
                            <Button style={{ textTransform: 'capitalize' }} variant='contained' onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Root>
        </Dialog >
    )
}

export default ImageUploaderModal
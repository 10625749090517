import React, { useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Theme } from '@mui/material';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface IProps {
  page: number
  children: React.ReactNode
  changePage: Function
  title?: string
}

const useStyles = generateStyle((theme: Theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& .MuiFormControl-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    margin: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    },
  },
}), "Dialog_Page"
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DialogPage(props: IProps) {
  const { Root, classes } = useStyles();
  const dialogBodyRef = useRef<HTMLDivElement>(null);
  const open = true;

  // Refocus when changing page
  useEffect(() => {
    if (dialogBodyRef !== null && dialogBodyRef.current !== null) {
      dialogBodyRef.current.scrollIntoView()
    }
  }, [props.page]);

  // const [open, setOpen] = React.useState(true);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    // setOpen(false);
    props.changePage(props.page - 1);
  };

  return (
    <Root>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar} ref={dialogBodyRef}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.title || 'Listing'}
            </Typography>
          </Toolbar>
        </AppBar>

        <div className={classes.root}>
          {props.children}
        </div>
      </Dialog>
    </Root>
  );
}
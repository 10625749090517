import React, { useState } from 'react'
import { Add, Replay } from '@mui/icons-material'
import { Button, FormControlLabel, Grid, Link, Switch } from '@mui/material'

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen'
import MenuBar from '../../_components/MenuBar'
import DataTable from '../../_components/_dataTable/DataTable'
import BorrowForm from './_components/BorrowForm'
import TransferForm from './_components/TransferForm'
import ExtendForm from './_components/ExtendForm'
import SingleReturnForm from './_components/SingleReturnForm'
import BulkReturnForm from './_components/BulkReturnForm'
import LogsForm from './_components/LogsForm'
import UnitModalDetail from '../_unit/UnitModalDetail'
import { generateStyle } from '../../_utils/DefaultStyle'

export interface Key {
    id: number
    code: string
    borrow_date: string
    return_key_date: string
    borrower_name: string
}

interface ModalState {
    type: false | 'borrow' | 'transfer' | 'extend' | 'return' | 'bulk-return' | 'logs'
    data: null | Key
}

const DashboardKunciCS = () => {
    const { Root, classes } = useStyles()
    const [reDrawDatatable, setReDrawDatatable] = useState(0)

    const [isLoading, setIsLoading] = useState(false)
    const [modalState, setModalState] = useState<ModalState>({
        type: false,
        data: null,
    })
    const [selectedUnitCode, setSelectedUnitCode] = useState('')
    const [isHandover, setIsHandover] = useState(0)

    const onModalClose = () => {
        setModalState({
            type: false,
            data: null,
        })
    }

    const handleChangeHandover = () => {
        setIsHandover(prev => {
            if (prev === 0) {
                return 1
            }
            return 0
        })
    }


    return (
        <MenuBar
            title="Manage Keys"
            backButton={false}
        >
            <Root>

                <LoadingScreen open={isLoading} fullScreen />
                <UnitModalDetail
                    open={selectedUnitCode !== ''}
                    unitCode={selectedUnitCode}
                    onModalClose={() => setSelectedUnitCode('')}
                />
                <BorrowForm
                    isOpen={modalState.type === 'borrow'}
                    onClose={onModalClose}
                    onFinish={() => setReDrawDatatable(new Date().getTime())}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
                <TransferForm
                    data={modalState.type === 'transfer' ? modalState.data : null}
                    onClose={onModalClose}
                    onFinish={() => setReDrawDatatable(new Date().getTime())}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
                <ExtendForm
                    data={modalState.type === 'extend' ? modalState.data : null}
                    onClose={onModalClose}
                    onFinish={() => setReDrawDatatable(new Date().getTime())}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
                <SingleReturnForm
                    data={modalState.type === 'return' ? modalState.data : null}
                    onClose={onModalClose}
                    onFinish={() => setReDrawDatatable(new Date().getTime())}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
                <BulkReturnForm
                    isOpen={modalState.type === 'bulk-return'}
                    onClose={onModalClose}
                    onFinish={() => setReDrawDatatable(new Date().getTime())}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
                <LogsForm
                    onClose={onModalClose}
                    open={modalState.type === 'logs'}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
                <Grid display="flex" alignItems="center" gap={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setModalState({
                            type: 'borrow',
                            data: null,
                        })}
                        startIcon={<Add />}
                    >
                        Add Borrow
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setModalState({
                            type: 'bulk-return',
                            data: null,
                        })}
                        startIcon={<Replay />}
                    >
                        Bulk Return
                    </Button>
                    <Button
                        variant='contained'
                        color="inherit"
                        className={classes.button}
                        onClick={() => {
                            setModalState({
                                type: 'logs',
                                data: null,
                            })
                        }}
                    >
                        Check Logs
                    </Button>
                    <FormControlLabel
                        sx={{ ml: "auto" }}
                        control={<Switch
                            checked={!!isHandover}
                            onChange={handleChangeHandover}
                        />}
                        label="Show Handover"
                    />
                </Grid>
                <DataTable
                    url={`${process.env.REACT_APP_API_URL}/unit-key/unit-list`}
                    reDraw={reDrawDatatable}
                    customParams={{ "is_handover": isHandover }}
                    columns={[
                        {
                            name: 'code',
                            label: 'Unit',
                            filterable: true,
                            render: (data: string) => {
                                return (
                                    <div>
                                        <Link
                                            onClick={() => setSelectedUnitCode(data)}
                                            component="button"
                                        >
                                            {data}
                                        </Link>
                                    </div>
                                )
                            }
                        },
                        {
                            name: 'borrow_date',
                            label: 'Borrow Date',
                            filterable: true,
                            sortable: true,
                            type: 'date',
                            render: (data: string, row: any) => data || '-',
                        },
                        {
                            name: 'return_key_date',
                            label: 'Return Date',
                            filterable: true,
                            sortable: true,
                            type: 'date',
                            render: (data: string, row: any) => data || '-',
                        },
                        ...(
                            !isHandover ?
                                [{
                                    name: 'borrower_name',
                                    label: 'Key Holder',
                                    filterable: true
                                }] :
                                []
                        )
                        ,
                        {
                            name: 'EXTRA',
                            label: 'Action',
                            type: 'string',
                            filterable: false,
                            sortable: false,
                            render: (row: any) => {
                                return (
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                                setModalState({
                                                    type: 'return',
                                                    data: row,
                                                })
                                            }}
                                            style={{
                                                marginRight: 8,
                                                marginBottom: 4,
                                                marginTop: 4,
                                            }}
                                        >
                                            Return
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            size="small"
                                            onClick={() => {
                                                setModalState({
                                                    type: 'extend',
                                                    data: row,
                                                })
                                            }}
                                            style={{
                                                marginRight: 8,
                                                marginBottom: 4,
                                                marginTop: 4,
                                            }}
                                        >
                                            Extend
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                                setModalState({
                                                    type: 'transfer',
                                                    data: row,
                                                })
                                            }}
                                            style={{
                                                marginRight: 8,
                                                marginBottom: 4,
                                                marginTop: 4,
                                            }}
                                        >
                                            Transfer
                                        </Button>
                                        {/* <Button
                                        variant="contained"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setModalState({
                                                type: 'logs',
                                                data: row,
                                            })
                                        }}
                                        style={{
                                            marginBottom: 4,
                                            marginTop: 4,
                                        }}
                                    >
                                        Logs
                                    </Button> */}
                                    </div>
                                )
                            }
                        },
                    ]}
                />
            </Root>
        </MenuBar>
    )
}

const useStyles = generateStyle(theme => ({
    button: {
        '&:hover': {
            backgroundColor: '#ababab',
            color: 'white'
        }
    }
}), "DashboardKunci_CS")

export default DashboardKunciCS

import React, { useState, useReducer, useEffect } from 'react';
import { Theme, Grid, MenuItem, TextField, Button } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import MuiDatePicker from '../../_components/MuiDatePicker';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    onSubmitSuccess: () => void,
    reviewId: string
}

interface IState {
    name: string,
    rate: string,
    review: string,
    // TODO: might break
    date: Date | null
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

interface IErrorState {
    name: string,
    rate: string,
    review: string,
    date: string
}

interface IErrorAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) => ({
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
        margin: theme.spacing(0, 1),
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'right',
        marginBottom: '10px'
    }
}), "ApartmentReview_Form"
)

const ApartmentReviewForm = (props: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/apartment-review';

    const [loading, setLoading] = useState(false);

    // Error State
    const errorReducer = (state: IErrorState, action: IErrorAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                rate: '',
                review: '',
                date: ''
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [errorState, setErrorState] = useReducer(errorReducer, {
        name: '',
        rate: '',
        review: '',
        date: ''
    });

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                rate: '',
                review: '',
                date: null,
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        name: '',
        rate: '',
        review: '',
        date: null,
    });

    const checkValidation = () => {
        let isValid = true;
        let newError = { ...errorState };
        // if (!inputState.title) {
        //     newError.title = 'Field wajib diisi';
        //     isValid = false;
        // } else {
        //     newError.title = '';
        // }
        // if (!inputState.notes) {
        //     newError.notes = 'Field wajib diisi';
        //     isValid = false;
        // } else {
        //     newError.notes = '';
        // }
        setErrorState({ type: 'REPLACE_STATE', name: '', value: newError });
        return isValid;
    }

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
        }
    }

    // TODO: might break
    const handleDateChanged = (name: string, date: Date | null) => {
        setInputState({ name, value: date, type: 'SET_ITEM' });
    }

    const submitForm = () => {

        if (!checkValidation()) {
            return;
        }

        let axios;

        if (props.reviewId) {
            axios = DefaultAxios.patch(`${API_URL}/${props.reviewId}`, inputState)
            setLoading(true);
            axios
                .then(res => {
                    Swal.fire({
                        title: "Review Updated",
                        icon: 'success',
                        onAfterClose: props.onSubmitSuccess,
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        } else {
            axios = DefaultAxios.post(`${API_URL}`, inputState);
            setLoading(true);
            axios
                .then(res => {
                    Swal.fire({
                        title: "Review Submitted",
                        icon: 'success',
                        onAfterClose: props.onSubmitSuccess,
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        if (props.reviewId) {
            setLoading(true);
            DefaultAxios
                .get(`${API_URL}/${props.reviewId}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                    setLoading(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Root>
            <div className={classes.root}>
                <LoadingScreen open={loading} fullScreen />
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            error={!!errorState.name}
                            label="Reviewer Name"
                            variant="outlined"
                            name="name"
                            value={inputState.name}
                            onChange={handleInputChanged}
                            fullWidth
                            helperText={errorState.name}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            name="rate"
                            value={inputState.rate}
                            select
                            variant="outlined"
                            fullWidth
                            onChange={handleInputChanged}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>

                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Review"
                            name="review"
                            value={inputState.review}
                            onChange={handleInputChanged}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            error={!!errorState.review}
                            helperText={errorState.review}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MuiDatePicker
                            label="Date"
                            value={inputState.date}
                            onChange={(date: any) => handleDateChanged('date', date)}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.gridSubmit}>
                        <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Root>
    );
}

export default ApartmentReviewForm;

import React, { useEffect, useState, useRef } from 'react';
import {
    Grid,
    Select,
    MenuItem,
    Theme,
    Button,
    InputLabel,
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    SelectChangeEvent
} from '@mui/material';
import moment from 'moment';

/**
 * Other Components
 */
import BookingForm from './_components/BookingForm';
import BookingDetail from './_components/BookingDetail';
import AsycMultipleAutoComplete from '../../_components/_form/AsycMultipleAutoComplete';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IAutoCompleteOption {
    label: string;
    id: any;
    is_apartment: any;
}

interface IBookingList {
    kode_unit: string
    bedroom_type: string;
    location: string;
    bookings: [{
        booking_id: number;
        date: string;
    }]
}

const useStyles = generateStyle((theme: Theme) => ({
    buttonContainer: {
        marginBottom: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(1),
        width: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    table: {
        Width: "2000px",
        '& .head': {
            whiteSpace: 'nowrap',
            left: 'unset'
        },
        '& .headChild': {
            whiteSpace: 'nowrap',
            left: 'unset',
            top: '29px'
        },
        maxHeight: '750px',
        overflowY: 'auto'
    },
    cellBooked: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        backgroundColor: "#f48181",
        border: "solid 0.5px",
        cursor: "pointer"
    },
    cellUnbooked: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        cursor: "pointer"
    }
}), "CalendarBooking"
);

const CalendarBooking = () => {
    const { Root, classes } = useStyles();
    const [bookingData, setBookingData] = useState({
        month: moment().format('M') as unknown as number,
        year: moment().format('y') as unknown as number,
        monthYear: moment().format('YYYY-MM'),
        label: 0,
        labelFull: moment().format('MMMM y  '),
        properties: [] as IAutoCompleteOption[]
    })
    const [bookingList, setBookingList] = useState<IBookingList[]>([])
    const [modalState, setModalState] = useState(0);
    const bookingID = useRef(0);

    // const [flags, setFlag] = useState({
    //     init: true,
    // })

    const MONTHS = () => {
        const monthsAfter = []
        const monthsBefore = []
        const dateStart = moment()
        const dateBefore = moment().add(-6, 'month')
        const dateEnd = moment().add(4, 'month')
        while (dateEnd.diff(dateStart, 'months') >= 0) {
            monthsAfter.push(dateStart.format('MMMM y'))
            dateStart.add(1, 'month')
        }
        while (dateStart.diff(dateBefore, 'months') >= 0) {
            monthsBefore.push({ label: dateBefore.format('MMMM y'), month: dateBefore.format('M'), year: dateBefore.format('y') })
            dateBefore.add(1, 'month')
        }
        return monthsBefore

    }
    const months = MONTHS()

    const handleChange = (event: SelectChangeEvent<string>, child: any) => {
        let monthArr: string[] = []
        const val = event.target.value
        monthArr = val.split('-')
        setBookingData(prev => ({
            ...prev,
            month: monthArr[0] as unknown as number,
            year: monthArr[1] as unknown as number,
            label: child.key,
            labelFull: child.key
        }))
    };

    const handleAutocomplete = (name: string, value: IAutoCompleteOption[]) => {
        setBookingData(prev => ({
            ...prev,
            properties: value
        }))
    }

    // useEffect(() => {
    //     setFlag({ init: false })
    // }, [])

    useEffect(() => {
        if (modalState === 0) {
            const data = {
                ...bookingData,
                properties: bookingData.properties.map(property => {
                    return {
                        property_id: property.id,
                        is_apartment: property.is_apartment
                    }
                })
            }
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/stay360-booking/calendar?`, data)
                .then(res => {
                    const bookingList: IBookingList[] = res.data
                    setBookingList(bookingList)
                })



            // if (bookingData.properties.length === 0) {
            //     setBookingList([])
            // } else {
            //     const data = {
            //         ...bookingData,
            //         properties: bookingData.properties.map(property => {
            //             return {
            //                 property_id: property.id,
            //                 is_apartment: property.is_apartment
            //             }
            //         })
            //     }
            //     DefaultAxios.post(`${process.env.REACT_APP_API_URL}/stay360-booking/calendar?`, data)
            //         .then(res => {
            //             const bookingList: IBookingList[] = res.data
            //             setBookingList(bookingList)
            //         })
            // }
        }
        // eslint-disable-next-line
    }, [bookingData, modalState])

    const renderCell = (list: any) => {
        const cellChildren = []
        const dayLength = moment(bookingData.label, 'MMMM y').daysInMonth()

        for (let i = 1; i <= dayLength; i++) {
            let data_id = 0;
            list.bookings.map((data: { id: number, date: number }) => {
                if (i === data.date) {
                    data_id = data.id;
                }
                return data;
            });


            if (data_id !== 0) {
                cellChildren.push(
                    <TableCell key={i} className={classes.cellBooked} align="center" onClick={() => {
                        bookingID.current = data_id;
                        setModalState(2);
                    }}>
                        Booked
                    </TableCell>
                )
            } else {
                cellChildren.push(
                    <TableCell key={i} className={classes.cellUnbooked} align="center">
                        Open
                    </TableCell>
                )
            }
        }
        return (
            cellChildren
        )
    }

    const renderCellParent = () => {
        const cellParent = []
        const dayLength = moment(bookingData.label, 'MMMM y').daysInMonth()
        for (let i = 1; i <= dayLength; i++) {
            cellParent.push(<TableCell key={i} style={{ paddingTop: '2px', paddingBottom: '2px', borderBottom: 'none' }} className="headChild head" align="center">{i}</TableCell>)
        }
        return (
            cellParent
        )
    }

    return (
        <Root>
            <BookingDetail booking_id={bookingID.current} open={modalState === 2} onClose={() => setModalState(0)} onEdit={() => setModalState(1)} />
            <BookingForm open={modalState === 1} onClose={() => setModalState(0)} booking_id={bookingID.current} />

            <Grid container className={classes.buttonContainer}>
                <Grid item md={12}>
                    <Button variant="contained" color="primary" onClick={() => {
                        setModalState(1);
                        bookingID.current = 0;
                    }}>
                        Add new Booking
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={0}>
                <Grid item md={6}>
                    <FormControl style={{ width: "95%" }} className={classes.formControl}>
                        <AsycMultipleAutoComplete
                            value={bookingData.properties}
                            label="Properti"
                            name="booking"
                            onChange={handleAutocomplete}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/stay360-property?with_apartment=1`}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-age-native-simple">Durasi Waktu</InputLabel>
                        <Select
                            defaultValue={`${months[6].month}-${months[6].year}`}
                            onChange={(event, child) => handleChange(event, child)}
                            label="Durasi Waktu"
                        >
                            {months.map((name) => (
                                <MenuItem key={name.label} value={`${name.month}-${name.year}`} >
                                    {name.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br></br>
            <TableContainer className={classes.table} component={Paper}>
                <Table size="medium" aria-label="a dense table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ paddingTop: '2px', paddingBottom: '2px', left: '0', zIndex: 99 }} align="center" className="head" rowSpan={2}>Lokasi</TableCell>
                            <TableCell style={{ paddingTop: '2px', paddingBottom: '2px', left: '109px', zIndex: 99 }} align="center" className="head" rowSpan={2}>Tipe Unit</TableCell>
                            <TableCell style={{ paddingTop: '2px', paddingBottom: '2px', left: '199px', zIndex: 99 }} align="center" className="head" rowSpan={2}>Nomor Unit</TableCell>
                            <TableCell style={{ paddingTop: '2px', paddingBottom: '2px' }} align="center" className="head" colSpan={31}>
                                {bookingData.labelFull}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {
                                renderCellParent()
                            }
                        </TableRow>
                    </TableHead>
                    {bookingList.length > 0 ?
                        <TableBody>
                            {bookingList.map((list) => (
                                <TableRow key={list.kode_unit}>
                                    <TableCell align="center" style={{ position: 'sticky', backgroundColor: '#fff', left: '0' }} className="head">{list.location}</TableCell>
                                    <TableCell align="center" style={{ position: 'sticky', backgroundColor: '#fff', left: '109px' }} className="head">{list.bedroom_type}</TableCell>
                                    <TableCell align="center" style={{ position: 'sticky', backgroundColor: '#fff', left: '199px' }} className="head" component="th">
                                        {list.kode_unit}
                                    </TableCell>
                                    {
                                        renderCell(list)
                                    }
                                </TableRow>
                            ))}
                        </TableBody>

                        :
                        <TableBody>
                            <TableRow>
                                <TableCell align="center" className="head" colSpan={31}>No Data Available</TableCell>
                            </TableRow>
                        </TableBody>
                    }

                </Table>
            </TableContainer>
        </Root>
    );
}

export default CalendarBooking;
import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, Grid, TextField, Theme } from '@mui/material'
import { generateStyle } from '../../_utils/DefaultStyle'
import DeleteIcon from '../_icons/DeleteIcon'

/**
 * Styles 
 * 
 */


interface IProps {
    open: boolean,
    onSubmit: (refresh: boolean) => void
}

const useStyles = generateStyle((theme: Theme) => ({
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textCenter: {
        textAlign: 'center',
        fontSize: 18,
        marginBottom: 10,
    },
    textTitle: {
        fontSize: 24,
        marginBottom: 20,
        marginTop: 20,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}), "DeleteAllImage_Modal")

const TEXT_VALIDATE = 'ya saya ingin menghapus'

const DeleteAllImageModal = (props: IProps) => {
    const [text, setText] = useState('')
    const { Root, classes } = useStyles()

    useEffect(() => {
        if (props.open) {
            setText('')
        }
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onSubmit(false)}
            maxWidth="sm"
            fullWidth
        >
            <Root>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} className={classes.center}>
                            <DeleteIcon />
                        </Grid>
                        <Grid item xs={12} className={`${classes.center} ${classes.textTitle}`}>
                            <strong>DELETE ALL IMAGE</strong>
                        </Grid>
                        <Grid item xs={12} className={classes.textCenter}>
                            Anda akan <strong>MENGHAPUS SEMUA GAMBAR</strong>. Proses ini tidak bisa di Rollback. Harap isi “<strong>ya saya ingin menghapus</strong>” ke form lalu klik submit untuk melanjutkan
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container className={classes.container}>
                                <Grid item xs>
                                    <TextField
                                        onChange={(e: any) => setText(e.target.value)}
                                        value={text}
                                        fullWidth
                                        size='small'
                                        placeholder='Isi konfirmasi disini'
                                    />
                                </Grid>
                                <Grid item xs={2} sx={{ marginLeft: '15px' }}>
                                    <Button
                                        variant='contained'
                                        disabled={text !== TEXT_VALIDATE}
                                        onClick={() => props.onSubmit(true)}
                                        color='error'
                                        fullWidth
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Root>
        </Dialog>
    )
}

export default DeleteAllImageModal
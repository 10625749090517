import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button
} from '@mui/material';
import { renderWarningButton } from '../../../_utils/Helper';

interface Props {
    open: boolean;
    onClose: () => void;
    inputRef: any;
    onRevertStatus: () => void;
}

const RevertClosingModal = (props: Props) => {
    const handleSubmit = () => {
        renderWarningButton('Apakah anda yakin ingin men-revert status closing?', 'Revert Status Closing')
        .then((result) => {
            if (result.value) {
                props.onRevertStatus();
            }
        })
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Revert Status Closing</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Harap masukkan alasan meng-revert status closing ini.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Isi alasan disini"
                    type="text"
                    fullWidth
                    inputRef={props.inputRef}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Batal
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RevertClosingModal;
import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, Grid, TextField, Button } from '@mui/material'

/**
 * Components
 */
import LoadingScreen from '../../../../_components/LoadingScreen'

/**
 * Utils
 */
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { generalErrorHandler, renderToastSuccess } from '../../../../_utils/Helper'
import TextArea from '../../../../_components/_form/TextArea'

interface IProps {
    open: boolean,
    onClose: (refresh: boolean) => void
    id: number | string
    onSubmitSuccess?: () => void
}

interface IState {
    name: string,
    email: string,
    address: string
}

const initialState = { name: '', email: '', address: '' }
const initialErrorState = { name: '', email: '', address: '' }

const AddCustomerModal = (props: IProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<IState>(initialState)
    const [errorState, setErrorState] = useState<IState>(initialErrorState)

    const validate = (name: string, value: string) => {
        let newError = {...initialErrorState}
        let isValid = true
        if (name === 'email') {
            if (!(value === '' || /^$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value))){
                newError.email = "Please insert correct email"
                isValid = false
            }
        }

        if (name === 'name') {
            if (!value) {
                newError.name = "Please insert name"
                isValid = false
            }
        }

        setErrorState(newError)
        return isValid
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value} = e.target

        validate(name, value)

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/customer/store-cobroke`, {
            closing_cobroke_id: props.id,
            ...state
        })
            .then(res => res.data)
            .then(res => {
                renderToastSuccess('Customer Added!')

                if (typeof props.onSubmitSuccess !== 'undefined') {
                    props.onSubmitSuccess()
                }
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (!props.open) {
            setState(initialState)
            setErrorState(initialErrorState)
        }

    }, [props.open, props.id])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
        >
            <LoadingScreen open={isLoading} />
            <DialogTitle>
                Assign Customer
            </DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="name"
                            label="Name"
                            helperText={errorState.name}
                            error={!!errorState.name}
                            value={state.name}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="email"
                            label="Email"
                            helperText={errorState.email}
                            error={!!errorState.email}
                            value={state.email}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextArea 
                            name='address'
                            error={!!errorState.address}
                            helperText={errorState.address}
                            onChange={handleChange}
                            value={state.address}
                            label='Address'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            onClick={handleSubmit}
                            sx={{ float: 'right' }}
                            disabled={!state.name}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default AddCustomerModal
import React from 'react'
import { Button, Dialog, DialogContent, Grid, Theme } from '@mui/material'
import { generateStyle } from '../../_utils/DefaultStyle'
import DeleteIcon from '../_icons/DeleteIcon'

/**
 * Styles 
 * 
 */


interface IProps {
    open: boolean,
    onSubmit: (value: boolean) => void
}

const useStyles = generateStyle((theme: Theme) => ({
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textCenter: {
        textAlign: 'center',
        fontSize: 18,
        marginBottom: 10
    },
    textTitle: {
        fontSize: 24,
        marginBottom: 20,
        marginTop: 20,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10
    }
}), "DeleteQuestion_Modal")

const DeleteQuestionModal = (props: IProps) => {
    const { Root, classes } = useStyles()

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onSubmit(false)}
            maxWidth="xs"
            fullWidth
        >
            <Root>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} className={classes.center}>
                            <DeleteIcon />
                        </Grid>
                        <Grid item xs={12} className={`${classes.textCenter} ${classes.textTitle}`}>
                            <strong>Apakah Anda Yakin ?</strong>
                        </Grid>
                        <Grid item xs={12} className={classes.textCenter}>
                            Apakah anda yakin ingin menghapus image ini?
                        </Grid>
                        <Grid item xs={12} className={classes.buttonContainer}>
                            <Button variant='outlined' onClick={() => props.onSubmit(true)}>Yes</Button>
                            <Button variant='contained' color='error' onClick={() => props.onSubmit(false)}>Cancel</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Root>
        </Dialog>
    )
}

export default DeleteQuestionModal
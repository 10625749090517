import React, { useEffect, useState, useReducer } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
} from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../_utils/Helper';

interface IProp {
    closing: any;
    open: boolean;
    onClose?: any;
}

interface IState {
    extend_pic_label: string;
    extend_pic_id: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const ChangePicModal = (props: IProp) => {
    const [isLoading, setIsLoading] = useState(false);

    const initialState = {
        extend_pic_label: props.closing.extend_pic_label || '',
        extend_pic_id: props.closing.extend_pic_id || '',
    }

    useEffect(() => {
        setInputState({ name: 'reset', value: 'reset', type: 'RESET_ITEM' });
    }, [props.open]);

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };
    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    const handleSubmit = () => {
        setIsLoading(true);
        DefaultAxios.patch(`${process.env.REACT_APP_API_URL}/manage-extend/change-pic`, { closing_id: props.closing.id, extend_pic_id: inputState.extend_pic_id })
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    timer: 1000,
                    onAfterClose: () => {
                        props.onClose(true);
                    }
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const handleAutocompletePic = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.extend_pic_id = String(value.id);
        newState.extend_pic_label = value.label;
        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                fullWidth
                maxWidth="sm"
                open={props.open}
                onClose={() => props.onClose(false)}
                aria-labelledby="form-dialog-title"
                closeAfterTransition
            >
                <DialogTitle id="form-dialog-title">PIC Extend</DialogTitle>
                <DialogContent>
                    <Grid container justifyContent="space-between" sx={{ mt: 0 }} spacing={2}>
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                label="PIC"
                                name="extend_pic_id"
                                initialQuery={inputState.extend_pic_label}
                                onChange={handleAutocompletePic}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/user/tenant-relation`}
                                placeholder="PIC TR Jendela"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ChangePicModal;
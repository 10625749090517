import React, { useState } from 'react'

import { Box, CircularProgress } from '@mui/material';
import DefaultAxios from '../../_utils/DefaultAxios';
import DemandSupplyTable from './DemandSupplyTable';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle(theme => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    padding: '21px',
    paddingTop: '0',
    boxSizing: 'border-box'
  },
  table: {
    flexGrow: 1,
    margin: 21
  },
  loading: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    justifyContent: 'center'
  },
  c_load: {
    width: '50px',
    height: 'auto'
  },
  loading_text: {
    fontSize: '20px'
  }
}), "Demand_Supply_TV");

const DemandSupplyTV = () => {
  const { Root, classes } = useStyles()
  const [data, setData] = useState([{}]);
  const [init, setInit] = useState(false)
  const [render, setRender] = useState(false);
  const tableHead = ['', 'Nama Apartemen', 'Studio', '1 BR', '2 BR', '3 BR']
  const API_URL = `${process.env.REACT_APP_API_URL}/demand-supply`;


  if (!init) {
    setInit(true)
    DefaultAxios.get(API_URL).then(res => {
      setData(res.data)
    }).finally(() => {
      setRender(true)
    })
  }

  const refreshData = () => {
    DefaultAxios.get(API_URL).then(res => {
      setData(res.data)
    })
  }

  if (render) {
    setTimeout(() => {
      refreshData();
    }, 600000);
  }
  if (!render) {
    return (
      <Root>
        <div className={classes.loading}>
          <CircularProgress className={classes.c_load} />
          <p style={{ fontSize: '20px' }}>Fetching Data...</p>
        </div>
      </Root>
    )
  } else {
    return (
      <Root>
        <Box className={classes.container}>
          <Box className={classes.table}>
            <DemandSupplyTable data={data} start={1} limit={9} head={tableHead} />
          </Box>
          <Box className={classes.table}>
            <DemandSupplyTable data={data} start={10} limit={18} head={tableHead} />
          </Box>
        </Box>
      </Root>
    )
  }
}

export default DemandSupplyTV;
import { Theme } from '@mui/material'
import React, { useMemo } from 'react'

/**
 * Utils
 */
import { format, isToday, isYesterday } from 'date-fns'

/**
 * Images
 */
import background from '../Assets/Images/png/whatsappbackground.png'
import noimage from '../Assets/Images/jpg/noimage.jpg'
import CallIcon from '@mui/icons-material/Call';
import { generateStyle } from '../_utils/DefaultStyle'
import { openLink } from '../_utils/Helper'
import { Description } from '@mui/icons-material'

export interface IChats {
    id: string,
    chat_id: string,
    whatsapp_id: string,
    from_agent: boolean,
    replied_id: string,
    replied_text: string,
    type: string,
    text: string,
    datetime: string,
    file_url: string | null,
    file_type: string | null
}

interface IProps {
    data: IChats[]
}

const WhatsappPreview = (props: IProps) => {
    const { Root, classes } = useStyles()

    const handleScrollEffect = (id: string) => {
        let currentChat = window.document.getElementById(id)
        if (currentChat) {
            currentChat.scrollIntoView({ behavior: 'smooth', block: 'end' });
            currentChat.classList.toggle(classes.dimAnimation)

            let timeout = setTimeout(() => {
                if (currentChat) {
                    currentChat.classList.toggle(classes.dimAnimation)
                    clearTimeout(timeout)
                }
            }, 2000)
        }
    }

    const renderAttachment = (type: string | null, url: string | null) => {
        if (!type && !url) {
            return
        }

        switch (type) {
            case 'image':
                return (
                    <div className={classes.attachment}>
                        <img src={url as string} alt='preview' />
                    </div>
                )
            case 'video':
                return (
                    <div className={classes.attachment}>
                        <video controls>
                            <source src={url as string} type='video/mp4' />
                        </video>
                    </div>
                )
            case 'voice_note':
                return (
                    <div className={classes.audioAttachment}>
                        <audio controls>
                            <source src={url as string} type='audio/mpeg' />
                            <source src={url as string} type='audio/ogg' />
                        </audio>
                    </div>
                )
            case 'other':
                return (
                    <div className={classes.attachment}>
                        <img src={noimage} alt="noimage" />
                    </div>
                )
            case 'document':
                return (
                    <div className={classes.attachment}>
                        <div
                            className='document'
                            onClick={() => {
                                openLink(url as string, true)
                            }}
                        >
                            <Description
                                fontSize='large'
                                color='primary'
                                sx={{ fontSize: 128 }}
                            />
                        </div>
                    </div>
                )
            default:
                return <></>
        }
    }

    const renderTime = (time: string, from_agent: boolean) => {
        return (
            <div className={classes.datetime} style={{ background: from_agent ? '#d9fdd3' : 'white' }}>
                {format(new Date(time), 'HH:mm')}
            </div>
        )
    }

    const formatText = (text: string) => {
        if (text) {
            let formattedText = '';
            let charFound = 0;

            for (var i = 0; i < text.length; i++) {
                if (text[i] === '*') {
                    if (charFound % 2 === 0) {
                        formattedText += '<strong>';
                    } else {
                        formattedText += '</strong>';
                    }

                    charFound++
                } else {
                    formattedText += text[i];
                }
            }

            return formattedText;
        } else {
            return text
        }
    }

    const renderText = (text: string) => {
        if (text) {
            return (
                <div className={classes.textContainer} dangerouslySetInnerHTML={{ __html: formatText(text) }} />
            )
        }

        return null
    }

    const renderSticker = (url: string) => {
        return (
            <img src={url} style={{ width: '100%' }} alt="Your browser supports animated WebP images." />
        )
    }

    const renderCall = (status: string, time: string) => {
        switch (status) {
            case 'accept':
                return (
                    <>
                        <CallIcon fontSize={'small'} color="action" />
                        <span>
                            Client call accepted at {format(new Date(time), 'HH:mm')}
                        </span>
                    </>
                )
            case 'reject':
                return (
                    <>
                        <CallIcon fontSize={'small'} color="error" />
                        <span style={{ color: '#d32f2f' }}>
                            Client call rejected at {format(new Date(time), 'HH:mm')}
                        </span>
                    </>
                )
            case 'canceled':
                return (
                    <>
                        <CallIcon fontSize={'small'} color="disabled" />
                        <span style={{ color: '#ababab' }}>
                            Client call Canceled by Client at {format(new Date(time), 'HH:mm')}
                        </span>
                    </>
                )
            default:
                return null
        }

    }

    const renderBubble = useMemo(() => {
        return (data: IChats[]) => {
            if (data.length) {
                return data.map((chat, idx) => {
                    if (chat.replied_id) {
                        if (chat.file_type === 'sticker') {
                            return (
                                <>
                                    <div id={chat.chat_id} key={idx} className={`${classes.bubble} ${chat.from_agent ? classes.bubbleRight : classes.bubbleLeft}`}>
                                        <div onClick={() => handleScrollEffect(chat.replied_id)} className={classes.bubbleReplied}>
                                            {chat.replied_text ? chat.replied_text : <em>Click to see chat</em>}
                                        </div>
                                        {renderSticker(chat.file_url as string)}
                                        {renderTime(chat.datetime, chat.from_agent)}
                                    </div>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <div id={chat.chat_id} key={idx} className={`${classes.bubble} ${chat.from_agent ? classes.bubbleRight : classes.bubbleLeft}`}>
                                        <div onClick={() => handleScrollEffect(chat.replied_id)} className={classes.bubbleReplied}>
                                            {chat.replied_text ? <p>{chat.replied_text}</p> : <em>Click to see chat</em>}
                                        </div>
                                        {renderAttachment(chat.file_type, chat.file_url)}
                                        {renderText(chat.text)}
                                        {renderTime(chat.datetime, chat.from_agent)}
                                    </div>
                                </>
                            )
                        }
                    } else if (chat.file_type === 'sticker') {
                        return (
                            <div id={chat.chat_id} key={idx} className={`${classes.sticker} ${chat.from_agent ? classes.stikerRight : ''}`}>
                                {renderSticker(chat.file_url as string)}
                                {renderTime(chat.datetime, chat.from_agent)}
                            </div>
                        )
                    } else if (chat.type === 'date') {
                        return (
                            <div key={idx} className={`${classes.bubble} ${classes.dateText}`}>
                                {renderText(chat.text)}
                            </div>
                        )
                    } else if (chat.type?.includes('call')) {
                        let status = chat.type?.split('-')[1]
                        return (
                            <div id={chat.chat_id} key={idx} className={`${classes.bubble} ${classes.smallText}`}>
                                {renderCall(status, chat.datetime)}
                            </div>
                        )
                    } else {
                        return (
                            <>
                                <div id={chat.chat_id} key={idx} className={`${classes.bubble} ${chat.from_agent ? classes.bubbleRight : classes.bubbleLeft}`}>
                                    {renderAttachment(chat.file_type, chat.file_url)}
                                    {renderText(chat.text)}
                                    {renderTime(chat.datetime, chat.from_agent)}
                                </div>
                            </>
                        )
                    }
                })
            }
        }

        // eslint-disable-next-line
    }, [props.data])

    const renderChatWithDate = useMemo(() => {
        let newData: IChats[] = []
        props.data.forEach((chat, idx) => {
            if (new Date(newData[newData.length - 1]?.datetime).getDate() !== new Date(chat.datetime).getDate()) {
                newData.push(...[{
                    ...chat,
                    type: "date",
                    text:
                        isToday(new Date(chat.datetime)) ? 'Today'
                            : isYesterday(new Date(chat.datetime)) ? 'Yesterday'
                                : format(new Date(chat.datetime), 'dd/MM/yyyy')
                }, chat])
            } else {
                newData.push(chat)
            }
        })

        return newData

    }, [props.data])

    return (
        <Root style={{ width: '100%', height: '100%' }}>
            <div className={classes.container}>
                {/* { JSON.stringify(renderChatWithDate)} */}
                {/* {renderBubble(props.data)} */}
                {renderBubble(renderChatWithDate)}
            </div>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    container: {
        width: '100%',
        padding: '30px',
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'center',
        backgroundRepeat: 'repeat-y',
        display: 'flex',
        gap: 10,
        flexDirection: 'column'
    },
    dateText: {
        margin: '0 auto',
        backgroundColor: 'white !important',
        width: 'auto !important',
        marginBottom: '10px',
        alignSelf: 'center',
        textAlign: 'center',
        padding: '5px 10px !important',
        "&::after": {
            borderTopColor: 'transparent !important'
        },
        "& > div": {
            padding: 0,
            fontSize: '10pt'
        },
        dateText: {
            margin: '0 auto',
            backgroundColor: 'white !important',
            width: 'auto !important',
            marginBottom: '10px',
            alignSelf: 'center',
            textAlign: 'center',
            padding: '5px 10px !important',
            "&::after": {
                borderTopColor: 'transparent !important'
            },
            "& > div": {
                padding: 0,
                fontSize: '10pt'
            }
        },
        sticker: {
            maxWidth: '50%',
            padding: '20px 0px',
            position: 'relative',
        },
        stikerRight: {
            alignSelf: 'flex-end'
        },
        textContainer: {
            width: '100%',
            paddingRight: '30px',
            whiteSpace: 'pre-line'
        },
        bubble: {
            maxWidth: "70%",
            width: 'max-content',
            padding: '10px',
            borderRadius: '10px',
            background: 'white',
            position: 'relative',
            "&::after": {
                content: "''",
                display: 'block',
                position: 'absolute',
                top: 0,
                width: 0,
                height: 0,
                background: 'transparent',
                boxSizing: 'border-box',
                borderTop: '15px solid',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent'
            }
        },
        bubbleReplied: {
            borderRadius: 4,
            padding: 10,
            overflow: 'hidden',
            marginBottom: 10,
            borderLeft: "4px solid rgba(255,255,255,0.8)",
            backgroundColor: 'rgba(0,0,0,0.2)',
            cursor: 'pointer',
            filter: 'contrast(1) brightness(0.7)',
            blockOverflow: 'ellipsis',
            "& > p": {
                margin: 0,
                padding: 0,
                display: '-webkit-box',
                '-webkit-line-clamp': 2,
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
            }
        },
        bubbleLeft: {
            background: 'white',
            "&::after": {
                left: '-10px',
                borderTopColor: 'white'
            }
        },
        bubbleRight: {
            background: '#d9fdd3',
            alignSelf: 'flex-end',
            "&::after": {
                right: '-10px',
                borderTopColor: '#d9fdd3'
            }
        },
        smallText: {
            margin: '0 auto',
            backgroundColor: 'white !important',
            width: 'auto !important',
            marginBottom: '10px',
            alignSelf: 'center',
            textAlign: 'center',
            padding: '5px 10px !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            "&::after": {
                borderTopColor: 'transparent !important'
            },
            "& > div": {
                padding: 0,
                fontSize: '10pt'
            }
        },
        dimAnimation: {
            animation: `$myDimAnimation 1000ms ${theme.transitions.easing.easeInOut} infinite`,
        },
        "@keyframes myDimAnimation": {
            "0%": {
                filter: 'brightness(1) ;  contrast(1)',
                '-webkit-filter': 'brightness(1); contrast(1)',
            },
            "50%": {
                filter: 'brightness(1.4); contrast(1.4)',
                '-webkit-filter': 'brightness(1.4); contrast(1.4)',
            },
            "100%": {
                filter: 'brightness(1) ;  contrast(1)',
                '-webkit-filter': 'brightness(1); contrast(1)',
            }
        },
        attachment: {
            width: '100%',
            minHeight: '150px',
            borderRadius: '10px',
            overflow: 'hidden',
            "& > img": {
                width: '100%'
            },
            "& > video": {
                width: '100%'
            },
            "& > audio": {
                width: '100%',
                minHeight: '20px',
                maxHeight: '50px',
                background: 'transparent'
            },
            '& > .document': {
                width: '100%',
            }
        },
        audioAttachment: {
            width: '100%',
            minHeight: '0',
            borderRadius: '10px',
            overflow: 'hidden',
            "& > audio": {
                width: '100%',
                minHeight: '20px',
                maxHeight: '50px',
                background: 'transparent'
            },
            "&::-webkit-media-controls-panel": {
                background: 'transparent !important'
            }
        },
        datetime: {
            padding: '4px 7px',
            borderRadius: '5px',
            position: 'absolute',
            bottom: '0px',
            right: '0px',
            color: '#222',
            fontSize: '10px',
            textAlign: 'right'
        }
    },
    sticker: {
        maxWidth: '50%',
        padding: '20px 0px',
        position: 'relative',
    },
    stikerRight: {
        alignSelf: 'flex-end'
    },
    textContainer: {
        width: '100%',
        paddingRight: '30px',
        whiteSpace: 'pre-line'
    },
    bubble: {
        maxWidth: "70%",
        width: 'max-content',
        padding: '10px',
        borderRadius: '10px',
        background: 'white',
        position: 'relative',
        "&::after": {
            content: "''",
            display: 'block',
            position: 'absolute',
            top: 0,
            width: 0,
            height: 0,
            background: 'transparent',
            boxSizing: 'border-box',
            borderTop: '15px solid',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent'
        }
    },
    bubbleReplied: {
        borderRadius: 4,
        padding: 10,
        overflow: 'hidden',
        marginBottom: 10,
        borderLeft: "4px solid rgba(255,255,255,0.8)",
        backgroundColor: 'rgba(0,0,0,0.2)',
        cursor: 'pointer',
        filter: 'contrast(1) brightness(0.7)',
        blockOverflow: 'ellipsis',
        "& > p": {
            margin: 0,
            padding: 0,
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        }
    },
    bubbleLeft: {
        background: 'white',
        "&::after": {
            left: '-10px',
            borderTopColor: 'white'
        }
    },
    bubbleRight: {
        background: '#d9fdd3',
        alignSelf: 'flex-end',
        "&::after": {
            right: '-10px',
            borderTopColor: '#d9fdd3'
        }
    },
    smallText: {
        margin: '0 auto',
        backgroundColor: 'white !important',
        width: 'auto !important',
        marginBottom: '10px',
        alignSelf: 'center',
        textAlign: 'center',
        padding: '5px 10px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        "&::after": {
            borderTopColor: 'transparent !important'
        },
        "& > div": {
            padding: 0,
            fontSize: '10pt'
        }
    },
    dimAnimation: {
        animation: `$myDimAnimation 1000ms ${theme.transitions.easing.easeInOut} infinite`,
    },
    "@keyframes myDimAnimation": {
        "0%": {
            filter: 'brightness(1) ;  contrast(1)',
            '-webkit-filter': 'brightness(1); contrast(1)',
        },
        "50%": {
            filter: 'brightness(1.4); contrast(1.4)',
            '-webkit-filter': 'brightness(1.4); contrast(1.4)',
        },
        "100%": {
            filter: 'brightness(1) ;  contrast(1)',
            '-webkit-filter': 'brightness(1); contrast(1)',
        }
    },
    attachment: {
        width: '100%',
        minHeight: '150px',
        borderRadius: '10px',
        overflow: 'hidden',
        "& > img": {
            width: '100%'
        },
        "& > video": {
            width: '100%'
        },
        "& > audio": {
            width: '100%',
            minHeight: '20px',
            maxHeight: '50px',
            background: 'transparent'
        }
    },
    audioAttachment: {
        width: '100%',
        minHeight: '0',
        borderRadius: '10px',
        overflow: 'hidden',
        "& > audio": {
            width: '100%',
            minHeight: '20px',
            maxHeight: '50px',
            background: 'transparent'
        },
        "&::-webkit-media-controls-panel": {
            background: 'transparent !important'
        }
    },
    datetime: {
        padding: '4px 7px',
        borderRadius: '5px',
        position: 'absolute',
        bottom: '0px',
        right: '0px',
        color: '#222',
        fontSize: '10px',
        textAlign: 'right'
    }
}), "Whatsapp_Preview"
)

export default WhatsappPreview
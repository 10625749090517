import { Button, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../../../_utils/Helper'
import LoadingScreen from '../../../../_components/LoadingScreen'
import { IContractList } from './ClosingContractModal'
import VisuallyHiddenInput from '../../../../_components/_form/VisuallyHiddenInput'

type Props = {
    open: boolean
    onClose: (refresh: boolean) => void
    closingId: string
    contractList: IContractList[]
}

const signerTypeOptions = [
    { value: 'owner', label: 'Owner' },
    { value: 'tenant', label: 'Tenant' },
]

interface IClosingContract {
    type: string,
    additional_text: string
    signer_type: string
    custom_pdf: File | null
}

const initialState = {
    type: "",
    additional_text: "",
    signer_type: "",
    custom_pdf: null
}

const ContractAddModal = (props: Props) => {
    const [state, setState] = useState<IClosingContract>(initialState)
    const [isLoading, setIsLoading] = useState(false)

    const typeOptions = useMemo(() => {
        let defaultOptions = [
            { value: 'owner', label: 'Kontrak Owner' },
            { value: 'tenant', label: 'Kontrak Tenant' },
            { value: 'power_of_attorney', label: 'Surat Kuasa' },
            { value: 'addendum_rent', label: 'Addendum Sewa' },
        ]

        props.contractList.forEach((contract) => {
            defaultOptions = defaultOptions.filter((option) => {
                if (contract.type_label === 'Surat Kuasa') return true

                return option.label !== contract.type_label
            })
        })

        return defaultOptions
    }, [props.contractList])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files && files.length) {
            setState(prev => ({
                ...prev,
                [e.target.name]: files[0]
            }))
        }
        handleClickInput()
    }

    const handleClickInput = (event?: React.MouseEventHandler<HTMLInputElement>) => {

    }

    const handleSubmit = () => {
        setIsLoading(true)
        let fd = new FormData()

        Object.entries(state).forEach(([key, value]) => {
            fd.append(key, value || '')
        })

        fd.append('closing_id', props.closingId || '')

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/create-contract`, fd)
            .then(res => res.data)
            .then(res => {
                renderSuccessButton('Kontrak berhasil dibuat!')
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (!props.open) {
            setState(initialState)
        }
    }, [props.open])

    useEffect(() => {
        setState(prev => ({
            ...initialState,
            type: state.type
        }))
    }, [state.type])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>Add Contract</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <TextField
                            label="Tipe"
                            name='type'
                            onChange={handleChange}
                            fullWidth
                            value={state.type || "default"}
                            select
                        >
                            <MenuItem value="default" disabled>-- Pilih Tipe Kontrak --</MenuItem>
                            {
                                typeOptions.map((type) => (
                                    <MenuItem value={type.value}>{type.label}</MenuItem>
                                ))
                            }

                        </TextField>
                    </Grid>
                    {
                        state.type === 'power_of_attorney' ?
                            <>
                                <Grid item xs={12}>
                                    <Paper variant='outlined' sx={{ p: 2 }}>
                                        <Typography variant='body1'>
                                            <strong style={{ overflowWrap: "break-word" }}>
                                                Dengan Surat Kuasa ini, menjelaskan bahwa Pihak Pertama memberikan kuasa kepada Pihak Kedua {state.additional_text}
                                            </strong>
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Text"
                                        name='additional_text'
                                        onChange={handleChange}
                                        fullWidth
                                        value={state.additional_text}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Penanda Tangan"
                                        name='signer_type'
                                        onChange={handleChange}
                                        fullWidth
                                        value={state.signer_type}
                                        select
                                    >
                                        {
                                            signerTypeOptions.map((type) => (
                                                <MenuItem value={type.value}>{type.label}</MenuItem>
                                            ))
                                        }

                                    </TextField>
                                </Grid>
                            </> :
                            null
                    }
                    {
                        ['owner', 'tenant'].includes(state.type) ?
                            <Grid item xs={12}>
                                <Typography variant='body2' sx={{ mb: 1 }}>Kontrak Custom (PDF Only)</Typography>
                                <div>
                                    <Button component="label" color='inherit' style={{ textTransform: "capitalize" }} variant='contained'>
                                        Upload File
                                        <VisuallyHiddenInput
                                            key={state.type}
                                            type="file"
                                            name="custom_pdf"
                                            accept='.pdf'
                                            onChange={(e) => handleChangeFile(e)}
                                        />
                                    </Button>
                                    {
                                        state.custom_pdf ?
                                            <span style={{ marginLeft: 5 }}>{state.custom_pdf.name}</span>
                                            : null
                                    }
                                </div>
                            </Grid> :
                            null
                    }
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            sx={{ float: 'right' }}
                            onClick={handleSubmit}
                            disabled={!state.type}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ContractAddModal
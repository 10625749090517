import React from 'react'
import { generateStyle } from '../../../_utils/DefaultStyle'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ITRPerformance } from '../TRPerformance'

type Props = {
    data: ITRPerformance['active_tickets']
}

const useStyles = generateStyle(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
    },
    headerCell: {
        color: '#fff'
    },
    cell: {
        textAlign: 'center'
    }
}), "TRPerformanceActiveTicket_Table")

const TRPerformanceActiveTicketTable = (props: Props) => {
    const { Root, classes } = useStyles()

    const renderContent = (age: string, data: ITRPerformance['active_tickets']['tickets_1']) => {
        return (
            <TableRow>
                <TableCell className={`${classes.cell} `}>{age}</TableCell>
                <TableCell className={`${classes.cell} `}>{data.Greeting}</TableCell>
                <TableCell className={`${classes.cell} `}>{data.Renewal}</TableCell>
                <TableCell className={`${classes.cell} `}>{data.Lainnya}</TableCell>
            </TableRow>
        )
    }

    return (
        <Root style={{ width: '100%' }}>
            <TableContainer>
                <Table>
                    <TableHead className={classes.header}>
                        <TableRow>
                            <TableCell className={`${classes.cell} ${classes.headerCell}`}>Ticket Age</TableCell>
                            <TableCell className={`${classes.cell} ${classes.headerCell}`}>Greeting</TableCell>
                            <TableCell className={`${classes.cell} ${classes.headerCell}`}>Renewal</TableCell>
                            <TableCell className={`${classes.cell} ${classes.headerCell}`}>Lainnya</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderContent('<3 Days', props.data.tickets_1)}
                        {renderContent('3-7 Days', props.data.tickets_2)}
                        {renderContent('7-14 Days', props.data.tickets_3)}
                        {renderContent('>14 Days', props.data.tickets_4)}
                    </TableBody>
                </Table>
            </TableContainer>
        </Root>
    )
}

export default TRPerformanceActiveTicketTable
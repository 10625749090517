import React, { useState, useEffect } from "react";
import { AppBar, Tabs, Tab, Theme } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import LoadingScreen from '../../_components/LoadingScreen';
import DefaultAxios from '../../_utils/DefaultAxios';
import { dateFormat } from '../../_utils/Helper';
import { generateStyle } from "../../_utils/DefaultStyle";

const useStyles = generateStyle((theme: Theme) =>
({
    root: {
        width: '100%',
        //   maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
}), "Notification"
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

const Notification = () => {
    const { Root, classes } = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [tab, setTab] = React.useState(0);
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        let notification_type = null;
        if (newValue === 1) {
            notification_type = 'action';
        };
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/notification`, { params: { notification_type: notification_type } })
            .then(res => {
                let data = res.data
                setNotifications(data);
                // setDeveloperDetail(data);
            })
            .finally(() => {
                setIsLoading(false)
            })
        setTab(newValue);
    };

    const [notifications, setNotifications] = React.useState([]);

    useEffect(() => {
        setIsLoading(true);
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/notification`)
            .then(res => {
                let data = res.data
                setNotifications(data);
                // setDeveloperDetail(data);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    const handleRead = (id: string, redirect: string) => {
        if (window.getSelection()?.toString().length === 0) {
            let url = process.env.REACT_APP_API_URL + '/notification/' + id
            DefaultAxios.put(url)
                .then(res => res.data)
                .then(res => {
                    if (res.updated) {
                        //
                    } else {
                        //
                    }
                })
                .catch(err => {
                    //
                })
                .finally(() => {
                    window.location.href = redirect;
                });
        }
    }

    return (
        <Root>
            <LoadingScreen
                open={isLoading}
                fullScreen={true}
            />
            <AppBar position="static">
                <Tabs value={tab} onChange={handleChangeTab} aria-label="simple tabs example">
                    {/* <Tab label="Key" /> */}
                    <Tab label="All" />
                    <Tab label="Activity" />
                </Tabs>
            </AppBar>

            <TabPanel value={tab} index={0}>
                <List className={classes.root}>
                    {
                        notifications.map((data: any, idx: number) => {
                            return (
                                <ListItem
                                    key={idx}
                                    alignItems="flex-start"
                                    button
                                    divider
                                    onClick={() => handleRead(data.id, data.url)}
                                    style={
                                        !!data.read ? {} : { backgroundColor: "rgba(0, 0, 0, 0.10)" }
                                    }
                                >
                                    <ListItemText
                                        style={{
                                            userSelect: 'text'
                                        }}
                                        // primary={dateFormat(data.created_at, 'DD-MM-YYYY HH:mm') + ' - ' + data.notification_text}
                                        primary={(
                                            <span>
                                                {dateFormat(data.created_at, 'DD-MM-YYYY HH:mm')}
                                                <span> - </span>
                                                <div style={{display: 'inline'}} dangerouslySetInnerHTML={{"__html": "<strong>Hello</strong> World"}}/>
                                            </span>
                                        )}
                                    />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <List className={classes.root}>
                    {
                        notifications.map((data: any, idx: number) => {
                            return (
                                <ListItem key={idx} alignItems="flex-start" button divider onClick={() => handleRead(data.id, data.url)}>
                                    <ListItemText
                                        style={{
                                            userSelect: 'text'
                                        }}
                                        primary={dateFormat(data.created_at, 'DD-MM-YYYY HH:mm') + ' - ' + data.notification_text}
                                    />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </TabPanel>
        </Root>
    )
}

export default Notification;
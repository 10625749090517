import { Button, CircularProgress, Dialog, DialogContent, Grid, TextField, Theme } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../../_components/_form/AsyncAutoComplete'
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { convertNumber, generalErrorHandler, inputNumber, numberToCurrency } from '../../../../_utils/Helper'
import { IValidationErrors, validateData } from '../../../../_utils/Validation'
import { generateStyle } from '../../../../_utils/DefaultStyle'

interface ProposeTransactionFormProps {
    isOpen: boolean
    onClose: () => void
    availableFunds: number
    basicPrice: number
    closingId: string
    fetchData: () => void
    generateTransactionInformation: () => any[]
    LIMIT_SEWA_OWNER: number
    LIMIT_SEWA_AGENT: number
    LIMIT_DEPOSIT_OWNER: number
    LIMIT_DEPOSIT_JENDELA: number
    LIMIT_KOMISI_JENDELA: number
    LIMIT_KOMISI_COBROKE: number
    LIMIT_DEPOSIT_AGENT: number
    closingData: any
    isDeposit?: boolean
}

const ProposeTransactionForm = ({
    isOpen,
    onClose,
    availableFunds,
    basicPrice,
    closingId,
    fetchData,
    generateTransactionInformation,
    LIMIT_SEWA_OWNER,
    LIMIT_SEWA_AGENT,
    LIMIT_DEPOSIT_OWNER,
    LIMIT_DEPOSIT_JENDELA,
    LIMIT_KOMISI_JENDELA,
    LIMIT_KOMISI_COBROKE,
    LIMIT_DEPOSIT_AGENT,
    closingData,
    isDeposit,
}: ProposeTransactionFormProps) => {
    const { Root, classes } = useStyles()

    const [state, setState] = useState({
        transaction_type: '',
        other_transaction_type_label: '',
        bank_data_id: '',
        bank_data_label: '',
        bank_data_type: '',
        amount: '',
        notes: '',
    })

    const [error, setError] = useState<IValidationErrors>({})

    const [isFormLoading, setIsFormLoading] = useState(false)

    useEffect(() => {
        if (!isOpen) {
            setState({
                transaction_type: '',
                other_transaction_type_label: '',
                bank_data_id: '',
                bank_data_label: '',
                bank_data_type: '',
                amount: '',
                notes: '',
            })

            setError({})
        }
    }, [isOpen])

    useEffect(() => {
        if (['deposit_jendela', 'commission_jendela'].includes(state.transaction_type)) {
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/admin/autocomplete/bank-other`, {
                params: {
                    type: state.transaction_type === 'commission_jendela' ? 'main_jendela' : state.transaction_type,
                }
            })
                .then(res => res.data)
                .then(data => {
                    if (data.length) {
                        setState(prev => ({
                            ...prev,
                            bank_data_id: data[0].id.toString(),
                            bank_data_label: data[0].label,
                        }))
                    }
                })
        } else {
            setState(prev => ({
                ...prev,
                bank_data_id: '',
                bank_data_label: '',
            }))
        }
    }, [state.transaction_type])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        if (name === 'amount') {
            let newValue = value
            let numberValue = Number(inputNumber(value)) || 0

            if (numberValue > availableFunds) {
                newValue = availableFunds.toString()
                numberValue = availableFunds
            }

            if (['rent_owner', 'rent_agent', 'deposit_agent'].includes(state.transaction_type) && basicPrice) {
                if (numberValue > basicPrice) {
                    newValue = basicPrice.toString()
                    numberValue = basicPrice
                }
            }

            switch (state.transaction_type) {
                case 'rent_owner':
                    if (numberValue > LIMIT_SEWA_OWNER) {
                        newValue = LIMIT_SEWA_OWNER.toString()
                        numberValue = LIMIT_SEWA_OWNER
                    }
                    break
                case 'rent_agent':
                    if (numberValue > LIMIT_SEWA_AGENT) {
                        newValue = LIMIT_SEWA_AGENT.toString()
                        numberValue = LIMIT_SEWA_AGENT
                    }
                    break
                case 'deposit_owner':
                    if (numberValue > LIMIT_DEPOSIT_OWNER) {
                        newValue = LIMIT_DEPOSIT_OWNER.toString()
                        numberValue = LIMIT_DEPOSIT_OWNER
                    }
                    break
                case 'deposit_jendela':
                    if (numberValue > LIMIT_DEPOSIT_JENDELA) {
                        newValue = LIMIT_DEPOSIT_JENDELA.toString()
                        numberValue = LIMIT_DEPOSIT_JENDELA
                    }
                    break
                case 'deposit_agent':
                    if (numberValue > LIMIT_DEPOSIT_AGENT) {
                        newValue = LIMIT_DEPOSIT_AGENT.toString()
                        numberValue = LIMIT_DEPOSIT_AGENT
                    }
                    break
                case 'commission_jendela':
                    if (numberValue > LIMIT_KOMISI_JENDELA) {
                        newValue = LIMIT_KOMISI_JENDELA.toString()
                        numberValue = LIMIT_KOMISI_JENDELA
                    }
                    break
                case 'commission_cobroke':
                    if (numberValue > LIMIT_KOMISI_COBROKE) {
                        newValue = LIMIT_KOMISI_COBROKE.toString()
                        numberValue = LIMIT_KOMISI_COBROKE
                    }
                    break
            }

            setState(prev => ({
                ...prev,
                [name]: inputNumber(newValue),
            }))
        } else {
            setState(prev => ({
                ...prev,
                [name]: value,
            }))

            if (name === 'transaction_type') {
                setState(prev => ({
                    ...prev,
                    amount: '',
                }))
            }
        }

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const onAutocompleteChange = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            bank_data_id: value.id.toString(),
            bank_data_label: value.label,
            bank_data_type: value.type,
        }))

        setError(prev => ({
            ...prev,
            bank_data_id: '',
        }))
    }

    const onAutocompleteInputChanged = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            bank_data_id: '',
            bank_data_label: '',
            bank_data_type: '',
        }))

        setError(prev => ({
            ...prev,
            bank_data_id: '',
        }))
    }

    const submit = () => {
        const { isValid, errors } = validateData(state, validationRules, validationAlias)
        setError(errors)

        if (isValid) {
            setIsFormLoading(true)

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-transaction-propose`, {
                closing_id: closingId,
                description: state.notes,
                ...state,
                transaction_type: (isDeposit && state.transaction_type === 'other') ? 'other_deposit' : state.transaction_type,
            })
                .then(res => res.data)
                .then(data => {
                    onClose()
                    Swal.fire({
                        title: "Transaction submitted",
                        icon: 'success',
                        timer: 1000
                    })
                        .then(() => {
                            fetchData()
                        })
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsFormLoading(false)
                })
        }
    }

    const parsedTransactionType = useMemo(() => {
        switch (state.transaction_type) {
            case 'commission_jendela':
                return 'main_jendela'
            case 'commission_cobroke':
                return 'cobroke_bank'
            default:
                return state.transaction_type
        }
    }, [state.transaction_type])

    return (
        <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
            <Root>
                <DialogContent>
                    <Grid container spacing={3} style={{ padding: '8px 0px', }}>
                        <Grid item xs={12} className={classes.dialogContent}>
                            <Grid container>
                                <Grid item xs={12} md={3} className={classes.dialogContent}>
                                    <span className={classes.label}>
                                        Deposit Owner
                                    </span>
                                    <span className={classes.value}>
                                        {closingData.holded_deposit_owner ? numberToCurrency(closingData.holded_deposit_owner, 'Rp') : '-'}
                                    </span>
                                    <br />
                                    <span className={classes.label}>
                                        Deposit Jendela
                                    </span>
                                    <span className={classes.value}>
                                        {closingData.holded_deposit_office ? numberToCurrency(closingData.holded_deposit_office, 'Rp') : '-'}
                                    </span>
                                </Grid>
                                <Grid item xs={12} md={3} className={classes.dialogContent}>
                                    <span className={classes.label}>
                                        GMV Transaction
                                    </span>
                                    <span className={classes.value}>
                                        {closingData.gmv_transaction ? numberToCurrency(closingData.gmv_transaction, 'Rp') : '-'}
                                    </span>
                                    <br />
                                    <span className={classes.label}>
                                        GMV Financing
                                    </span>
                                    <span className={classes.value}>
                                        {closingData.gmv_financing ? numberToCurrency(closingData.gmv_financing, 'Rp') : '-'}
                                    </span>
                                </Grid>
                                <Grid item xs={12} md={3} className={classes.dialogContent}>
                                    <span className={classes.label}>
                                        Basic Price
                                    </span>
                                    <span className={classes.value}>
                                        {closingData.basic_price ? numberToCurrency(closingData.basic_price, 'Rp') : '-'}
                                    </span>
                                </Grid>
                                <Grid item xs={12} md={3} className={classes.dialogContent}>
                                    <span className={classes.label}>
                                        Komisi
                                    </span>
                                    <span className={classes.value}>
                                        {closingData.revenue_jendela ? numberToCurrency(closingData.revenue_jendela, 'Rp') : '-'}
                                    </span>
                                    <br />
                                    <span className={classes.label}>
                                        Komisi Referal
                                    </span>
                                    <span className={classes.value}>
                                        {closingData.referral_commission ? numberToCurrency(closingData.referral_commission, 'Rp') : '-'}
                                    </span>
                                    <br />
                                    <span className={classes.label}>
                                        Komisi Cobroke
                                    </span>
                                    <span className={classes.value}>
                                        {closingData.cobroke_fee ? numberToCurrency(closingData.cobroke_fee, 'Rp') : '-'}
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Jenis Transaksi"
                                        name="transaction_type"
                                        value={state.transaction_type}
                                        onChange={onChange}
                                        variant="outlined"
                                        select
                                        fullWidth
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        error={!!error.transaction_type}
                                        helperText={error.transaction_type}
                                    >
                                        <option value="" key="default" disabled>Pilih Jenis Transaksi</option>
                                        {
                                            !isDeposit ?
                                                <>
                                                    {LIMIT_SEWA_OWNER > 0 &&
                                                        <option value="rent_owner">Sewa Owner</option>
                                                    }
                                                    {LIMIT_SEWA_AGENT > 0 &&
                                                        <option value="rent_agent">Sewa Agent</option>
                                                    }
                                                    {LIMIT_DEPOSIT_OWNER > 0 &&
                                                        <option value="deposit_owner">Deposit Owner</option>
                                                    }
                                                    {LIMIT_DEPOSIT_JENDELA > 0 &&
                                                        <option value="deposit_jendela">Deposit Jendela</option>
                                                    }
                                                    {LIMIT_DEPOSIT_AGENT > 0 &&
                                                        <option value="deposit_agent">Deposit Agent</option>
                                                    }
                                                    {LIMIT_KOMISI_JENDELA > 0 &&
                                                        <option value="commission_jendela">Komisi Jendela</option>
                                                    }
                                                    {LIMIT_KOMISI_COBROKE > 0 &&
                                                        <option value="commission_cobroke">Komisi Cobroke</option>
                                                    }
                                                    <option value="utilities_escrow">Utilities Escrow</option>
                                                    <option value="refund_tenant">Refund Tenant</option>
                                                    <option value="other">Lainnya</option>
                                                </>
                                                :
                                                <>
                                                    <option value="reimburse_client">Reimburse Client</option>
                                                    <option value="fixing_cleaning">Fixing / Cleaning</option>
                                                    <option value="utilities">Utilities</option>
                                                    <option value="other">Lainnya</option>
                                                </>
                                        }
                                    </TextField>
                                </Grid>
                                {
                                    state.transaction_type === 'other' &&
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Other Transaction Type"
                                            variant="outlined"
                                            name="other_transaction_type_label"
                                            value={state.other_transaction_type_label}
                                            onChange={onChange}
                                            fullWidth
                                            error={!!error.other_transaction_type_label}
                                            helperText={error.other_transaction_type_label}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} sm={12}>
                                    <AsyncAutoComplete
                                        label="Bank"
                                        disabled={['', 'deposit_jendela', 'commission_jendela'].includes(state.transaction_type)}
                                        name="bank_data_id"
                                        initialQuery={state.bank_data_label}
                                        onChange={onAutocompleteChange}
                                        onInputChange={onAutocompleteInputChanged}
                                        extraParams={
                                            ['rent_owner', 'rent_agent', 'deposit_owner'].includes(state.transaction_type) ? {
                                                closing_id: closingId,
                                            }
                                                : {
                                                    transaction_type: parsedTransactionType,
                                                    closing_id: closingId,
                                                    type: parsedTransactionType,
                                                }
                                            // isDeposit ?
                                            //     {
                                            //         transaction_type: parsedTransactionType,
                                            //         closing_id: closingId,
                                            //     }
                                            //     :
                                            //     {
                                            //         type: parsedTransactionType,
                                            //     }
                                        }
                                        url={
                                            ['rent_owner', 'deposit_owner'].includes(state.transaction_type) ?
                                                `${process.env.REACT_APP_API_URL}/admin/autocomplete/landlord-bank`
                                                : ['deposit_agent', 'rent_agent'].includes(state.transaction_type) ?
                                                    `${process.env.REACT_APP_API_URL}/admin/autocomplete/agent-bank`
                                                    :
                                                    state.transaction_type === 'refund_tenant' ?
                                                        `${process.env.REACT_APP_API_URL}/admin/autocomplete/customer-bank`
                                                        :
                                                        `${process.env.REACT_APP_API_URL}/admin/autocomplete/bank-transaction`
                                            // isDeposit ?
                                            //     `${process.env.REACT_APP_API_URL}/admin/autocomplete/bank-transaction`
                                            //     :
                                            //     `${process.env.REACT_APP_API_URL}/admin/autocomplete/bank-other`

                                        }
                                        placeholder="Bank"
                                        errorText={error.bank_data_id}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Nilai Transfer"
                                        variant="outlined"
                                        name="amount"
                                        value={convertNumber(state.amount)}
                                        onChange={onChange}
                                        fullWidth
                                        error={!!error.amount}
                                        helperText={error.amount}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Notes"
                                        variant="outlined"
                                        name="notes"
                                        value={state.notes}
                                        onChange={onChange}
                                        fullWidth
                                        error={!!error.notes}
                                        helperText={error.notes}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <br />
                                    <div style={{ display: 'flex', alignItems: "flex-end", justifyContent: 'flex-end' }}>
                                        <Button variant='contained' color="primary" onClick={submit} disabled={isFormLoading}>
                                            {
                                                isFormLoading ?
                                                    <CircularProgress size={22} />
                                                    :
                                                    'Propose'
                                            }
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Root>
        </Dialog>
    )
}

export default ProposeTransactionForm

const useStyles = generateStyle((theme: Theme) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 8,
        fontSize: 16,
    },
    value: {
        marginBottom: 16,
        fontSize: 16,
    },
}), "ProposeTransaction_Form")

const validationRules = {
    transaction_type: 'required',
    other_transaction_type_label: 'required_if:transaction_type=other',
    bank_data_id: 'required',
    amount: 'required',
}

const validationAlias = {
    transaction_type: 'Jenis Transaksi',
    other_transaction_type_label: 'Other Transaction Type',
    bank_data_id: 'Bank',
    amount: 'Nilai Transfer',
}
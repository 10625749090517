import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import LoadingScreen from '../../../_components/LoadingScreen';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler, renderSuccessButton } from '../../../_utils/Helper';

type Props = {
    id: string
    open: boolean,
    onClose: (refresh: boolean) => void
    rules: string[]
}

const FormHouseRules = (props: Props) => {
    const [rules, setRules] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const handleChangeRules = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        let newRules = [...rules]
        newRules[id] = e.target.value
        setRules(newRules)
    }

    const handleDeleteRule = (id: number) => {
        let newRules = [...rules]
        newRules = newRules.filter((_, idx) => idx !== id)
        setRules(newRules)
    }

    const handleSubmit = () => {
        setIsLoading(true)
        let postRules = [...rules]
        postRules = postRules.filter(rule => !!rule)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/apartment-product-knowledge/house-rules`, {
            id: props.id,
            rules: postRules
        })
            .then(res => {
                renderSuccessButton('Rules berhasil berubah')
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (!props.open) {
            return setRules([])
        }

        setRules(props.rules)
    }, [props.rules, props.open])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                House Rules
            </DialogTitle>
            <DialogContent>
                <LoadingScreen open={isLoading} fullScreen />
                <Grid container spacing={1} sx={{ mt: 0 }}>
                    {
                        rules.map((rule, idx) => {
                            return (
                                <Grid key={idx} item xs={12}>
                                    <Grid container rowSpacing={1} display="flex" alignItems="center">
                                        <Grid item xs>
                                            <TextField
                                                value={rule}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeRules(e, idx)}
                                                placeholder='Tuliskan Rule disini'
                                                autoFocus={rules.length === idx + 1}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                sx={{ float: 'right' }}
                                                onClick={() => handleDeleteRule(idx)}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    <Grid item xs={12}>

                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='outlined'
                            onClick={() => setRules(prev => ([...prev, '']))}
                        >
                            Add Rules
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            sx={{ float: 'right', mt: 2 }}
                            onClick={handleSubmit}

                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default FormHouseRules
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'

/**
 * Utils
 */
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import { generalErrorHandler, isMobile, renderSuccessButton } from '../../../_utils/Helper'
import { debounce } from 'lodash'
import { Key } from '../DashboardKunciCS'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { format } from 'date-fns'

interface ExtendFormProps {
    data: null | Key
    onClose: () => void
    onFinish: () => void
    isLoading: boolean
    setIsLoading: (isLoading: boolean) => void
}

interface State {
    return_key_date: null | Date
}

const ExtendForm = (props: ExtendFormProps) => {
    const [isScreenMobile, setIsScreenMobile] = useState(false)

    const [state, setState] = useState<State>({
        return_key_date: null,
    })
    const [error, setError] = useState<IValidationErrors>({})

    useEffect(() => {
        const handleResize = debounce(() => {
            setIsScreenMobile(isMobile())
        }, 250)

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        if (!props.data) {
            setState({
                return_key_date: null,
            })

            setError({})
        }
    }, [props.data])

    const onChangeDate = (date: Date) => {
        setState(prev => ({
            ...prev,
            return_key_date: date,
        }))

        setError(prev => ({
            ...prev,
            return_key_date: '',
        }))
    }

    const onSubmit = () => {
        const { isValid, errors } = validateData(state, validationRules, validationAlias)
        setError(errors)
        
        if (isValid) {
            props.setIsLoading(true)

            const fd = new FormData()
    
            fd.append('id', props.data?.id.toString() || '')
            fd.append('datetime', format(state.return_key_date!, 'yyyy-MM-dd HH:mm'))
    
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-key/extend`, fd)
                .then(() => {
                    renderSuccessButton('Berhasil Extend Kunci', () => {
                        props.onFinish()
                        props.onClose()
                    })
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    props.setIsLoading(false)
                })
        }
    }

    return (
        <Dialog
            open={props.data !== null}
            onClose={props.onClose}
            maxWidth="sm"
            fullScreen={isScreenMobile}
            fullWidth
        >
            <DialogTitle>Extend Form - {props.data?.code || ''}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{marginTop: 4}}>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                key={props.data?.id || 'default'}
                                ampm={false}
                                label="New Return Date"
                                inputFormat="dd/MM/yyyy HH:mm"
                                value={state.return_key_date}
                                onChange={(date: any) => onChangeDate(date)}
                                minDate={props.data?.return_key_date ? new Date(props.data.return_key_date).setDate(new Date(props.data.return_key_date).getDate() + 1) : undefined}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        variant="outlined"
                                        fullWidth
                                        label={props.label}
                                        placeholder={props.placeholder ? props.placeholder : "dd/MM/yyyy HH:mm"}
                                        error={!!error.return_key_date}
                                        helperText={error.return_key_date}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant='contained'
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant='contained'
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const validationRules: IValidationRules = {
    return_key_date: 'required',
}

const validationAlias: IValidationAlias = {
    return_key_date: 'Return Date',
}

export default ExtendForm

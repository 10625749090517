import React, { useReducer, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

//Header
import MenuBar from '../../_components/MenuBar';
import DialogPage from './_components/DialogPage';

//Page
import PageOne from './_form/PageOne';
import PageTwo from './_form/PageTwo';
import PageThree from './_form/PageThree';
import PageFour from './_form/PageFour';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertNumber, inputNumber, convertPercentangeWithDecimal } from '../../_utils/Helper';

// Interface
import { IPageOneState, IMapDataObject, IPageTwoState, IPageThreeState, IPageFourState } from './_interfaces/ListingInterface';
import LoadingScreen from '../../_components/LoadingScreen';
import ListingPreviewEdit from './ListingPreviewEdit';
import { Theme } from '@mui/material';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    id?: string,
    page?: number
    closeModal?: Function
}

interface ILandLord {
    landlord_name: string | null
    landlord_phone: string | null
    landlord_email: string | null
}

interface IAction {
    name: string,
    value?: object | Date | null | string | number | IMapDataObject | IPageOneState | IFacilityBreakDown,
    checkbox?: IChecked
}

interface IFacilityBreakDown {
    facilities_breakdown: Array<string>
}

interface IChecked {
    [key: string]: string | boolean
    name: string
    checked: boolean
}

interface IListingType {
    [key: string]: string | number
    id: number
    label: string
}

const listDataThatNeedToBeCleaned = [
    'sell_price', 'price_per_year', 'property_area', 'property_length', 'property_width',
    'land_area', 'land_length', 'land_width',
    'price_per_6_months', 'price_per_3_months', 'price_per_1_month', 'daily_price'
];

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        margin: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(5),
        },
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    marker: {
        display: "block",
        position: "absolute",
        zIndex: 1,
        marginTop: "150px",
        marginLeft: "190px",
        width: "50px",
        height: "50px",
    },
    submit_btn: {
        borderRadius: "40px",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    }
}), "Listing_Form"
);

export default function ListingForm(props: IProps) {
    const { Root, classes } = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [redirectID, setRedirectID] = useState(null);
    const [loadingForm, setLoadingForm] = useState(typeof props.id === 'undefined' ? false : true);
    const [listingType, setListingType] = useState<IListingType[]>([])
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        window.scrollTo(0, 0)
        if (typeof props.id !== 'undefined') {
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/listing/${props.id}`)
                .then(res => {
                    //Set Datas
                    setInputValue({ name: 'fetched-data', value: res.data });
                    setInputValueTwo({ name: 'fetched-data', value: res.data });
                    setInputValueThree({ name: 'fetched-data', value: res.data });
                    setInputValueFour({ name: 'fetched-data', value: res.data });

                    // Set Loading and Render Province
                    setRenderProvince(false);
                    setLoadingForm(false);
                })
        }
        fetchListingType()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [currentPage, setCurrentPage] = useState(typeof props.page !== 'undefined' ? props.page : 1);
    const [renderProvince, setRenderProvince] = useState(true);

    const changePage = (page: number) => {
        if (currentPage === 2 && currentPage > page) {
            setRenderProvince(false);
        }

        setCurrentPage(page);
    }

    // Input State
    const valueReducer = (state: IPageOneState, action: IAction) => {
        if (action.value != null) { //Need this or Typescript will complaint value will be possibly null
            if (action.name === 'fetched-data') {
                for (const key in state) {
                    const data_value = (action.value as IPageOneState)[key];

                    if (typeof data_value !== 'undefined') {
                        if (listDataThatNeedToBeCleaned.includes(key) && data_value !== null) {
                            state[key] = convertNumber(Number(data_value).toString());
                        } else {
                            state[key] = data_value === null ? "" : data_value;
                        }
                    }
                }
                const landlordData = action.value as ILandLord
                state.owner_name = landlordData.landlord_name === null ? '' : landlordData.landlord_name
                state.owner_email = landlordData.landlord_email === null ? '' : landlordData.landlord_email
                state.owner_phone = landlordData.landlord_phone === null ? '' : landlordData.landlord_phone

                return state;
            }

            if (action.name === 'owner_phone') {
                return {
                    ...state,
                    [action.name]: inputNumber(action.value.toString())
                }
            }

            if (action.name === 'map') {
                return {
                    ...state,
                    latitude: (action.value as IMapDataObject).lat,
                    longitude: (action.value as IMapDataObject).lng
                }
            }

            return {
                ...state,
                [action.name]: action.value
            }
        } else {
            return { ...state };
        }
    };

    // Input State
    const valueReducerTwo = (state: IPageTwoState, action: IAction) => {
        if (action.value != null) { //Need this or Typescript will complaint value will be possibly null
            if (action.name === 'fetched-data') {
                for (const key in state) {
                    const data_value = (action.value as IPageTwoState)[key];

                    if (typeof data_value !== 'undefined') {
                        if (listDataThatNeedToBeCleaned.includes(key) && data_value !== null) {
                            state[key] = convertNumber(Number(data_value).toString());
                        } else {
                            state[key] = data_value === null ? "" : data_value;
                        }
                    }
                }

                return state;
            } else {
                return {
                    ...state,
                    [action.name]: action.value as string
                }
            }
        } else {
            return { ...state };
        }
    };

    // Input State
    const valueReducerThree = (state: IPageThreeState, action: IAction) => {
        if (action.value != null) { //Need this or Typescript will complaint value will be possibly null
            if (action.name === 'fetched-data') {
                for (const key in state) {
                    const data_value = (action.value as IPageTwoState)[key];

                    if (typeof data_value !== 'undefined') {
                        if (listDataThatNeedToBeCleaned.includes(key) && data_value !== null) {
                            state[key] = convertNumber(Number(data_value).toString());
                        } else {
                            state[key] = data_value === null ? "" : data_value;
                        }
                    }
                }

                return state;
            } else if (action.name === 'rent_commission' || action.name === 'sell_commission') {
                const comm_number = action.value;

                if (convertPercentangeWithDecimal(comm_number.toString()) !== false) {
                    const new_value = convertPercentangeWithDecimal(comm_number.toString(), 50);
                    return {
                        ...state,
                        [action.name]: new_value.toString()
                    }
                } else {
                    return { ...state };
                }
            } else {
                return {
                    ...state,
                    [action.name]: action.value as string
                }
            }
        } else {
            return { ...state };
        }
    };

    // Input State
    const valueReducerFour = (state: IPageFourState, action: IAction) => {
        if (action.value != null || action.checkbox !== null) { //Need this or Typescript will complaint value will be possibly null
            if (action.name === 'fetched-data') {
                for (const key in state) {
                    const data_value = (action.value as IPageTwoState)[key];

                    if (listDataThatNeedToBeCleaned.includes(key) && data_value !== null) {
                        state[key] = convertNumber(Number(data_value).toString());
                    } else {
                        state[key] = data_value === null ? "" : data_value;
                    }

                    if (key === 'facilities') {
                        let newMap = new Map();

                        if (data_value !== "" && data_value !== null) {
                            const map_value = (action.value as IFacilityBreakDown);
                            state['facilities'] = newMap;

                            for (const key in map_value.facilities_breakdown) {
                                newMap.set(map_value.facilities_breakdown[key], true);
                                state['facilities'] = newMap;
                            }
                        } else {
                            state['facilities'] = newMap;
                        }
                    }
                }
                return state;
            } else if (action.name === 'facilities' && action.checkbox) {
                let newMap = state.facilities
                let data: IChecked = action.checkbox
                newMap.set(data.name as string, data.checked)
                return {
                    ...state,
                    [action.name]: newMap
                }
            } else {
                return {
                    ...state,
                    [action.name]: action.value as string
                }
            }
        } else {
            return { ...state };
        }
    };

    const [pageOneState, setInputValue] = useReducer(valueReducer, {
        is_hot: false,
        owner_name: "",
        owner_phone: "",
        owner_email: "",
        listing_type_id: "",
        province_id: "",
        city_id: "",
        district_id: "",
        address: "",
        latitude: -6.17831000,
        longitude: 106.63200000,
        apartment_name: '',
        apartment_id: '',
        tower: '',
        floor: '',
        number: ''
    });

    const [pageTwoState, setInputValueTwo] = useReducer(valueReducerTwo, {
        land_area: '',
        land_length: '',
        land_width: '',
        property_area: '',
        property_length: '',
        property_width: '',
        bedroom: '',
        bathroom: '',
        carport: ''
    });

    const [pageThreeState, setInputValueThree] = useReducer(valueReducerThree, {
        name: '',
        description: '',
        rent_commission: '',
        sell_commission: '',
        price_per_year: '',
        price_per_6_months: '',
        price_per_3_months: '',
        price_per_1_month: '',
        daily_price: '',
        sell_price: '',
        display_currency: 'IDR'
    });

    const [pageFourState, setInputValueFour] = useReducer(valueReducerFour, {
        certificate: '',
        building_finished: '',
        total_floor: '',
        furnish: '',
        electric_watt: '',
        view: '',
        direction: '',
        facilities: new Map(),
        notes: ''
    });

    const handleInputChange = (e: any) => {
        switch (currentPage) {
            case 1:
                if (e.target.name && e.target.name === 'is_hot') {
                    setInputValue({ name: e.target.name, value: e.target.checked });
                } else if (e.target.name === 'floor') {
                    setInputValue({ name: e.target.name, value: convertNumber(e.target.value) });
                } else {
                    setInputValue({ name: e.target.name, value: e.target.value });
                }
                break;
            case 2:
                if (e.target.name.includes('_area') || e.target.name.includes('_length') || e.target.name.includes('_width') || e.target.name === 'carport') {
                    setInputValueTwo({ name: e.target.name, value: convertNumber(e.target.value) });
                } else {
                    setInputValueTwo({ name: e.target.name, value: e.target.value });
                }
                break;
            case 3:
                if (e.target.name.includes('cost') || e.target.name.includes('price')) {
                    setInputValueThree({ name: e.target.name, value: convertNumber(e.target.value) });
                } else {
                    setInputValueThree({ name: e.target.name, value: e.target.value });
                }
                break;
            case 4:
                if (e.target.type === 'checkbox') {
                    setInputValueFour({ name: 'facilities', checkbox: { name: e.target.name, checked: e.target.checked } })
                } else if (e.target.name === 'total_floor' || e.target.name === 'building_finished') {
                    setInputValueFour({ name: e.target.name, value: inputNumber(e.target.value) });
                } else {
                    setInputValueFour({ name: e.target.name, value: e.target.value });
                }
                break;
            default:
                break;
        }
    }

    const handleSubmit = () => {
        setIsLoading(true)
        // FIXME: commission yang dikirim masih salah satunya
        const datas_to_submit = { ...pageOneState, ...pageTwoState, ...pageThreeState, ...pageFourState };

        let facilities: Array<string> = [];
        pageFourState.facilities.forEach((checked: boolean, key: string) => {
            if (checked === true) {
                facilities.push(key);
            }
        });

        if (typeof props.id === 'undefined' && !redirectID) {
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/listing`, { ...datas_to_submit, facilities: facilities })
                .then(res => {
                    setIsLoading(false)
                    setRedirectID(res.data.id);
                    changePage(5);
                })
                .catch(error => {
                    enqueueSnackbar('Kesalahan server, harap hubungi admin', { variant: 'error' })
                    setIsLoading(false)
                })
        } else if (redirectID) {
            DefaultAxios.put(`${process.env.REACT_APP_API_URL}/listing/${redirectID}`, { ...datas_to_submit, facilities: facilities })
                .then(res => {
                    setIsLoading(false)
                    changePage(5);
                })
                .catch(error => {
                    enqueueSnackbar('Kesalahan server, harap hubungi admin', { variant: 'error' })
                    setIsLoading(false)
                })
        } else {
            DefaultAxios.put(`${process.env.REACT_APP_API_URL}/listing/${props.id}`, { ...datas_to_submit, facilities: facilities })
                .then(res => {
                    setIsLoading(false)
                    if (typeof props.closeModal !== "undefined") {
                        props.closeModal()
                    }
                })
                .catch(error => {
                    enqueueSnackbar('Kesalahan server, harap hubungi admin', { variant: 'error' })
                    setIsLoading(false)
                })
        }
    }

    const fetchListingType = () => {
        const url = process.env.REACT_APP_API_URL + '/listing-type'
        DefaultAxios.get(url)
            .then(res => res.data)
            .then(res => {
                setListingType(res)
            })
    }

    const initTitle = () => {
        if (pageThreeState.name === '') {
            if (pageOneState.listing_type_id !== '') {
                let listingTypeName = listingType.filter((element) => element.id.toString() === pageOneState.listing_type_id)[0].label
                const title = pageOneState.listing_type_id === '1'
                    ? pageOneState.apartment_name + ' ' + pageTwoState.bedroom + 'BR'
                    : listingTypeName
                setInputValueThree({
                    name: 'name',
                    value: title
                })
            }
        }
    }

    const renderView = () => {
        switch (currentPage) {
            case 1:
                return (
                    <MenuBar title={typeof props.id !== "undefined" ? 'Edit Listing' : 'Add Listing'}>
                        <PageOne
                            render_province={renderProvince}
                            states={pageOneState}
                            setInputValue={setInputValue}
                            handleInputChange={handleInputChange}
                            changePage={changePage}
                        />
                    </MenuBar>
                )
            case 2:
                return (
                    <DialogPage changePage={changePage} page={currentPage}>
                        <PageTwo
                            listing_type_id={pageOneState.listing_type_id}
                            states={pageTwoState}
                            handleInputChange={handleInputChange}
                            changePage={changePage}
                        />
                    </DialogPage>
                )
            case 3:
                return (
                    <DialogPage changePage={changePage} page={currentPage}>
                        <PageThree
                            states={pageThreeState}
                            handleInputChange={handleInputChange}
                            changePage={changePage}
                            initTitle={initTitle}
                        />
                    </DialogPage>
                )
            case 4:
                return (
                    <DialogPage changePage={changePage} page={currentPage}>
                        <PageFour
                            listing_type_id={pageOneState.listing_type_id}
                            states={pageFourState}
                            handleInputChange={handleInputChange}
                            handleSubmit={handleSubmit}
                            changePage={changePage}
                        />
                    </DialogPage>
                )
            case 5:
                return (
                    <DialogPage changePage={changePage} page={currentPage} title="Preview">
                        <ListingPreviewEdit redirectId={redirectID!} />
                    </DialogPage>
                )
            default:
                return 'salah halaman'
        }
    }

    if (loadingForm) {
        return (
            <Root>
                <div className={classes.root}></div>
            </Root>
        )
    } else {
        return (
            <Root>
                <div className={classes.root}>
                    {renderView()}
                    {
                        isLoading &&
                        <LoadingScreen open={isLoading} fullScreen={true} />
                    }
                    {/* Debugging Only */}
                    {/* <button onClick={() => changePage(currentPage + 1)}>bypass</button> */}
                </div>
            </Root>
        )
    }
}

import React, { useState, useReducer, useEffect } from 'react';
import { Card, CardActions, CardContent, Box, Theme, Grid, Slide, Button, Typography, Dialog, } from '@mui/material';
import Swal from 'sweetalert2';
import { TransitionProps } from '@mui/material/transitions';

// Components
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    mode: "checkIn" | "checkOut",
    onSubmitSuccess: () => void,
    id: string
}

interface IState {
    name: string,
    phone: string,
    email: string,
    notes: string,
    ota: string,
    booking_id: string,
    ktp_url: string,
    is_checked_in: number
    is_checked_out: number
}

interface IFileState {
    file: File | null
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    cardRoot: {
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    cardActRoot: {
        display: 'inherit',
        paddingBottom: '16px'
    },
    container: {
        backgroundColor: "#f3f3f3",
    },
    submit_button: {
        // marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
        marginTop: theme.spacing(5),
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    paperTab: {
        margin: "0"
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
    paper_label: {
        position: "absolute",
        marginTop: "-45px",
        padding: "1px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)"
    },
    paper_label_text: {
        backgroundColor: "#0094ff",
        fontSize: "1.5em",
        padding: "13px",
        borderRadius: "4px",
        color: "white"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        paddingTop: '2rem',
        textAlign: 'center'
    },
    box: {
        padding: theme.spacing(2),
        color: 'rgba(0, 0, 0, 0.54)'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "Stay360PmsDetail"
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const Stay360PmsDetail = (props: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/pms-booking';
    const [modalConfirmation, setModalConfirmation] = useState(false);
    const [modalConfirmationDirectly, setModalConfirmationDirectly] = useState(false);
    const [modalImage, setModalImage] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleOpenImg = () => setModalImage(true);
    const handleOpen = () => setModalConfirmation(true);
    const handleOpenDirectly = () => setModalConfirmationDirectly(true);
    const handleClose = () => {
        setModalConfirmationDirectly(false)
        setModalConfirmation(false)
        setModalImage(false)
        // loadData();
    };

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                phone: '',
                email: '',
                notes: '',
                ota: '',
                booking_id: '',
                ktp_url: '',
                is_checked_in: 0,
                is_checked_out: 0
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const fileReducer = (state: IFileState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                file: null
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IFileState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        name: '',
        phone: '',
        email: '',
        notes: '',
        ota: '',
        booking_id: '',
        ktp_url: '',
        is_checked_in: 0,
        is_checked_out: 0
    });

    const fileInitialState = {
        file: null
    }

    const [fileState, setFileState] = useReducer(fileReducer, fileInitialState);

    useEffect(() => {
        DefaultAxios
            .get(`${API_URL}/${props.id}`)
            .then(res => {
                const data = res.data;
                setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = () => {
        const fd = new FormData();
        // fd.append('brochure_filepath', fileUpload?.file ?);
        fd.append('id', props.id);

        for (let key in fileState) {
            if (key === 'file') {
                if (fileState.file) {
                    fd.append('ktp_file', fileState.file);
                }
            }
        }

        let axios;
        setLoading(true);
        if (props.mode === 'checkIn') {
            axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/pms-booking/approve-check-in`, fd);
            axios
                .then(res => {
                    Swal.fire({
                        title: "Data berhasil di check in",
                        icon: 'success',
                        onAfterClose: props.onSubmitSuccess,
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        } else {
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/pms-booking/approve-check-out`, fd)
                .then(res => {
                    Swal.fire({
                        title: "Data berhasil di check out",
                        icon: 'success',
                        onAfterClose: props.onSubmitSuccess,
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        }

    }

    const upload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let value: any = target.value;
        let name = target.name;
        if (name === 'file') {
            // const oldFile = { ...fileInitialState.file };
            if (target.files && target.files[0]) {
                value = target.files[0];
            } else {
                value = null;
            }
            name = 'file';
        }
        setFileState({ name, value, type: 'SET_ITEM' });
    }

    const renderWa = (data: any) => {
        let regex = /^08/;
        if (data.match(regex)) {
            data = data.replace(regex, "628")
        }
        let phoneNumber = data;
        let link = 'https://api.whatsapp.com/send?phone=' + phoneNumber;
        return <a href={link}>{data}</a>
    }

    return (
        <Root>
            <LoadingScreen open={loading} fullScreen />

            <Dialog
                open={modalConfirmationDirectly}
                onClose={handleClose}
                closeAfterTransition
                TransitionComponent={Transition}
                fullWidth
                maxWidth="sm"
            >
                <Root>
                    <LoadingScreen open={loading} fullScreen />
                    <Card className={classes.cardRoot} variant="outlined">
                        <CardContent style={{ paddingBottom: 0 }}>
                            <Typography
                                variant="h6"
                                style={{ fontWeight: "bold" }}>
                                Apakah anda yakin ?
                            </Typography>
                        </CardContent>
                        <CardActions className={classes.cardActRoot}>
                            <Button onClick={submitForm} variant="contained" color="primary" className={classes.submit_button}>
                                Yes
                            </Button>
                            <Button onClick={handleClose} variant="contained" color="secondary" className={classes.submit_button}>
                                No
                            </Button>
                        </CardActions>
                    </Card>
                </Root>
            </Dialog>

            <Dialog
                open={modalConfirmation}
                onClose={handleClose}
                closeAfterTransition
                TransitionComponent={Transition}
                fullWidth
                maxWidth="sm"
            >
                <Root>
                    <LoadingScreen open={loading} fullScreen />
                    <Card className={classes.cardRoot} variant="outlined">
                        {props.mode === 'checkIn' ?
                            <>
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        style={{ fontWeight: "bold", marginBottom: '8px' }}>
                                        Upload KTP
                                    </Typography>
                                    <input
                                        accept="image/*"
                                        multiple
                                        type="file"
                                        name="file"
                                        style={{ textAlignLast: 'center' }}
                                        onChange={upload}
                                    />
                                </CardContent>
                                <CardActions className={classes.cardActRoot}>
                                    <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                                        Submit
                                    </Button>
                                </CardActions>
                            </>
                            : <>
                                <CardContent style={{ paddingBottom: 0 }}>
                                    <Typography
                                        variant="h6"
                                        style={{ fontWeight: "bold" }}>
                                        Apakah anda yakin ?
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.cardActRoot}>
                                    <Button onClick={submitForm} variant="contained" color="primary" className={classes.submit_button}>
                                        Yes
                                    </Button>
                                    <Button onClick={handleClose} variant="contained" color="secondary" className={classes.submit_button}>
                                        No
                                    </Button>
                                </CardActions>
                            </>
                        }

                    </Card>
                </Root>
            </Dialog>
            <Dialog
                open={modalImage}
                onClose={handleClose}
                closeAfterTransition
                TransitionComponent={Transition}
                fullWidth
                maxWidth="sm"
            >
                <Root>
                    <Card style={{ padding: 0 }} variant="outlined">
                        <CardContent style={{ padding: 0 }} >
                            <img src={inputState.ktp_url} alt="" width="100%" />
                        </CardContent>
                    </Card>
                </Root>
            </Dialog>
            <LoadingScreen open={loading} fullScreen />
            <Box className={classes.box}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sm={2}>
                                    Name
                                    <span style={{ float: "right" }}>:</span>
                                </Grid>
                                <Grid item xs={9} sm={10}>
                                    {inputState.name}
                                </Grid>
                            </Grid>
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sm={2}>
                                    Phone
                                    <span style={{ float: "right" }}>:</span>
                                </Grid>
                                <Grid item xs={9} sm={10}>
                                    {renderWa(inputState.phone)}
                                </Grid>
                            </Grid>
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sm={2}>
                                    OTA
                                    <span style={{ float: "right" }}>:</span>
                                </Grid>
                                <Grid item xs={9} sm={10}>
                                    {inputState.ota} - {inputState.booking_id}
                                </Grid>
                            </Grid>
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sm={2}>
                                    Notes
                                    <span style={{ float: "right" }}>:</span>
                                </Grid>
                                <Grid item xs={9} sm={10} style={{ whiteSpace: 'pre-line' }}>
                                    {inputState.notes}
                                </Grid>
                            </Grid>
                        </Typography>
                        {inputState.ktp_url ?
                            <Typography
                                variant="subtitle1"
                                style={{ fontWeight: "bold" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} sm={2}>
                                        KTP
                                        <span style={{ float: "right" }}>:</span>
                                    </Grid>
                                    <Grid item xs={9} sm={10}>
                                        <img onClick={handleOpenImg} src={inputState.ktp_url} alt="" width="200px" />
                                    </Grid>
                                </Grid>
                            </Typography>
                            : <></>
                        }
                    </Grid>
                </Grid>
                {props.mode === 'checkIn' ?
                    <>
                        {inputState.is_checked_in === 0 ?
                            <Grid item xs={12} className={classes.gridSubmit}>
                                {inputState.ktp_url ?
                                    <Button variant="contained" color="primary" onClick={handleOpenDirectly} className={classes.submit_button}>
                                        Check In Done
                                    </Button>
                                    : <Button variant="contained" color="primary" onClick={handleOpen} className={classes.submit_button}>
                                        Check In Done
                                    </Button>
                                }
                            </Grid>
                            : <></>
                        }
                    </>
                    :
                    <>
                        {inputState.is_checked_out === 0 ?
                            <Grid item xs={12} className={classes.gridSubmit}>
                                <Button variant="contained" color="primary" onClick={handleOpen} className={classes.submit_button}>
                                    Check Out Done
                                </Button>
                            </Grid>
                            : <></>
                        }
                    </>
                }

                {/* {props.mode === 'checkIn' ?
                    <Grid item xs={12} className={classes.gridSubmit}>
                        {inputState.ktp_url ?
                            <Button variant="contained" color="primary" onClick={handleOpenDirectly} className={classes.submit_button}>
                                Check In Done
                            </Button>
                            : <Button variant="contained" color="primary" onClick={handleOpen} className={classes.submit_button}>
                                Check In Done
                            </Button>
                        }
                    </Grid>
                    : <Grid item xs={12} className={classes.gridSubmit}>
                        <Button variant="contained" color="primary" onClick={handleOpen} className={classes.submit_button}>
                            Check Out Done
                        </Button>
                    </Grid>
                } */}

            </Box>
        </Root>
    );
}

export default Stay360PmsDetail;
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import ButtonWithLoading from '../../../_components/_form/ButtonWithLoading'
import BasicUploadForm from '../../../_components/_form/BasicUploadForm'

/**
 * Utils
 */
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import { generalErrorHandler, renderToastSuccess } from '../../../_utils/Helper'
import DefaultAxios from '../../../_utils/DefaultAxios'

interface ListingTicketCancelModalProps {
    isOpen: boolean
    onClose: () => void
    onFinish: () => void
    data: {
        id: string | null
    }
}

type State = {
    cancel_complete_reason: string
    cancel_complete_proof: null | File
}

const ListingTicketCancelModal = (props: ListingTicketCancelModalProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<State>(INITIAL_STATE)
    const [error, setError] = useState<IValidationErrors<State>>({})

    useEffect(() => {
        if (!props.isOpen) {
            setState(INITIAL_STATE)
            setError({})
        }
    }, [props.isOpen])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = e.target

        if (files?.length) {
            setState(prev => ({
                ...prev,
                [name]: files[0],
            }))
        } else {
            setState(prev => ({
                ...prev,
                [name]: value,
            }))
        }

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const onSubmit = () => {
        const { errors, isValid } = validateData(state, VALIDATION_RULES, VALIDATION_ALIAS)
        setError(errors)

        if (isValid) {
            setIsLoading(true)
            const fd = new FormData()

            fd.append('id', props.data.id!)
            fd.append('cancel_complete_reason', state.cancel_complete_reason)
            fd.append('cancel_complete_proof', state.cancel_complete_proof!)

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/listing-ticket/cancel`, fd)
                .then(() => {
                    renderToastSuccess('Berhasil cancel listing ticket')
                    props.onClose()
                    props.onFinish()
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <Dialog
            open={props.isOpen}
            fullWidth
            maxWidth='xs'
            onClose={isLoading ? undefined : props.onClose}
        >
            <DialogTitle>Cancel</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label='Alasan'
                            name='cancel_complete_reason'
                            value={state.cancel_complete_reason}
                            onChange={onChange}
                            placeholder='Isi Alasan'
                            multiline
                            rows={3}
                            error={!!error.cancel_complete_reason}
                            helperText={error.cancel_complete_reason}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BasicUploadForm
                            label='Upload Bukti'
                            name='cancel_complete_proof'
                            onChange={onChange}
                            accept='image/*,.pdf'
                            errorText={error.cancel_complete_proof}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonWithLoading
                            variant='contained'
                            color='primary'
                            sx={{ float: 'right' }}
                            onClick={onSubmit}
                            isLoading={isLoading}
                        >
                            Submit
                        </ButtonWithLoading>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const VALIDATION_RULES: IValidationRules = {
    cancel_complete_reason: 'required',
    cancel_complete_proof: 'required',
}

const VALIDATION_ALIAS: IValidationAlias = {
    cancel_complete_reason: 'Alasan',
    cancel_complete_proof: 'Bukti',
}

const INITIAL_STATE: State = {
    cancel_complete_reason: '',
    cancel_complete_proof: null,
}

export default ListingTicketCancelModal

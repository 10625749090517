import { Button, Dialog, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';

interface Props {
    closingId: string;
    logs: LogBroadcast[];
}

export interface LogBroadcast {
    datetime: string
    type: string
    user_name: string
    closing_code: string
    unit_code: string
    template_name: string
    broadcast_text: string
}

const LogBroadcastList = (props: Props) => {

    const [broadcastText, setBroadcastText] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const renderLogs = () => {
        if (props.logs.length > 0) {
            return props.logs.map((log: LogBroadcast, index: number) => {
                return <TableRow key={index}>
                    <TableCell>
                        {format(new Date(log.datetime), 'dd/MM/yyyy - HH:mm:ss')}
                    </TableCell>
                    <TableCell>{log.type}</TableCell>
                    <TableCell>{log.user_name || '-'}</TableCell>
                    <TableCell>{log.unit_code}</TableCell>
                    <TableCell>{log.closing_code}</TableCell>
                    <TableCell>{log.template_name}</TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                                setBroadcastText(log.broadcast_text);
                                handleModalOpen();
                            }}
                        >
                            Show Broadcast
                        </Button>
                    </TableCell>
                </TableRow>
            });
        } else {
            return <TableRow>
                <TableCell colSpan={6}>There is no logs yet</TableCell>
            </TableRow>
        }
    }

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
            >
                <DialogContent>
                    <div>
                        <pre>
                            {broadcastText || 'Tidak Ada  Log'}
                        </pre>
                    </div>
                </DialogContent>
            </Dialog>
            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Tanggal
                            </TableCell>
                            <TableCell>
                                Send To
                            </TableCell>
                            <TableCell>
                                Send By
                            </TableCell>
                            <TableCell>
                                Kode Unit
                            </TableCell>
                            <TableCell>
                                Kode Closing
                            </TableCell>
                            <TableCell>
                                Nama Template
                            </TableCell>
                            <TableCell>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderLogs()}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default LogBroadcastList;
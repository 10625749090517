import React, { useEffect, useState } from 'react'
import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, TextField, Toolbar, Typography } from '@mui/material'
import Swal from 'sweetalert2';
import download from 'downloadjs'

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddBoxIcon from '@mui/icons-material/AddBox';
import GetAppIcon from '@mui/icons-material/GetApp';
import pdfPng from '../../../Assets/Images/png/pdf.png';
import docPng from '../../../Assets/Images/png/doc.png';

import LoadingScreen from '../../../_components/LoadingScreen';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface IProps {
    unitId: string
    unitCode: string
    onClose: () => void
    permissions: any
}

interface IState {
    id: null | string | number
    file: null | File
    type: string
    notes: string
}

interface IFile {
    id: number
    filename: string
    type: string
    notes: string
    url: string
}

const AdvisorFileList = (props: IProps) => {
    const { Root, classes } = useStyles()

    const [isLoading, setIsLoading] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [formState, setFormState] = useState<IState>({
        id: null,
        file: null,
        type: '',
        notes: '',
    })
    const [files, setFiles] = useState<IFile[]>([])
    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    const fetchData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/my-unit/${props.unitId}/file`)
            .then(res => res.data)
            .then((data: IFile[]) => {
                setFiles(data)
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (!isModalOpen) {
            resetFormState()
        }
    }, [isModalOpen])

    const resetFormState = () => {
        setFormState({
            id: null,
            file: null,
            notes: '',
            type: '',
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name
        const value = e.target.value

        if (name === 'file') {
            const files = e.target.files
            if (files && files[0]) {
                setFormState(prev => ({
                    ...prev,
                    file: files[0],
                }))
            }
        } else {
            setFormState(prev => ({
                ...prev,
                [name]: value
            }))
        }
    }

    const handleUpload = () => {
        if (formState.file === null || formState.type === '') {
            let errors = ''
            if (formState.file === null) errors += '<p>Harap memilih file terlebih dahulu</p>'
            if (formState.type === '') errors += '<p>Harap mengisi tipe file</p>'

            Swal.fire({
                title: "Validasi Error",
                html: errors,
                icon: 'error',
            })
            return
        }

        const clonedState = { ...formState }
        const formData = new FormData()

        formData.append('file', formState.file)
        formData.append('type', formState.type)
        formData.append('notes', formState.notes)
        if (formState.id) {
            formData.append('_method', 'put')
        }

        setIsLoading(true)
        setIsModalOpen(false)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/my-unit/${props.unitId}/file${formState.id ? `/${formState.id}` : ''}`, formData)
            .then(res => res.data)
            .then(data => {
                Swal.fire({
                    title: 'Submit berhasil',
                    icon: 'success',
                    timer: 1000,
                })

                fetchData()
                resetFormState()
            })
            .catch(error => {
                generalErrorHandler(error)
                setFormState(clonedState)
                if (!clonedState.id) setIsModalOpen(true)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleChangeFile = (id: number, file: IFile) => {
        setFormState(prev => ({
            ...prev,
            id,
            notes: file.notes,
            type: file.type,
        }))
    }

    useEffect(() => {
        if (formState.id) setIsModalOpen(true)
    }, [formState.id])

    const handleDeleteFile = (id: number) => {
        renderWarningButton("Apakah anda yakin ingin menghapus file ini?")
            .then(result => {
                if (result.value) {
                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/my-unit/${props.unitId}/file/${id}`)
                        .then(res => res.data)
                        .then(data => {
                            Swal.fire({
                                title: "File berhasil dihapus",
                                icon: 'success'
                            }).then(() => {
                                fetchData()
                            })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                }
            })
    }

    const handlePreviewFile = (src: string, isImage: boolean) => {
        if (isImage) {
            setPreviewImage(src);
        } else {
            window.open(src);
        }
    }

    const renderList = () => {
        const canDelete = props.permissions['unit-files.delete']

        return files.map(file => {
            let src = ''
            let isImage = false
            let filepath = file.url

            let explode = filepath.split('?')
            const match = /(\.\w+$)/g.exec(explode[0])

            if (match) {
                if (match[1] === '.pdf' || match[1] === '.PDF') {
                    src = pdfPng
                } else if (match[1] === '.doc' || match[1] === '.docx') {
                    src = docPng
                } else {
                    src = filepath
                    isImage = true
                }
            }

            const selectedFileTypes = fileTypes.filter(fileType => fileType.value === file.type)

            return (
                <Grid
                    key={file.id}
                    item
                    xs={12}
                    className={classes.fileContainer}
                >
                    <Paper>
                        <div className="fileBody">
                            <img height="82" width="82" src={src} alt={file.filename} onClick={() => handlePreviewFile(filepath, isImage)} />
                            <div className="fileDetail">
                                <p>{file.filename}</p>
                                {
                                    selectedFileTypes.length > 0 &&
                                    <p>{selectedFileTypes[0].label}</p>
                                }
                                {
                                    file.notes &&
                                    <p className="notes">"{file.notes}"</p>
                                }
                                <div className="buttonsContainer">
                                    <Button
                                        variant="contained"
                                        size='small'
                                        type="submit"
                                        onClick={() => download(file.url)}
                                        className="downloadButton"
                                    >
                                        <GetAppIcon fontSize="small" />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() => handleChangeFile(file.id, file)}
                                        size='small'
                                    >
                                        Change
                                    </Button>
                                    {
                                        canDelete &&
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={() => handleDeleteFile(file.id)}
                                            size='small'
                                        >
                                            Delete
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            )
        })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                open={!!previewImage}
                onClose={() => { setPreviewImage(undefined) }}
                scroll="body"
                maxWidth={false}
            >
                <img src={previewImage} width="100%" alt="preview" />
            </Dialog>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.header}>
                        Unit Files
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setIsModalOpen(true)}
                        aria-label="files"
                    >
                        <AddBoxIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Dialog
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <Root>
                    <DialogTitle>Upload file</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="file"
                                    name="file"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    select
                                    name="type"
                                    value={formState.type}
                                    onChange={handleChange}
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    <option value="">-- Type --</option>
                                    {
                                        fileTypes.map(type =>
                                            <option value={type.value} key={type.value}>{type.label}</option>
                                        )
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Notes"
                                    name="notes"
                                    value={formState.notes}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsModalOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleUpload} color="primary">
                            Upload
                        </Button>
                    </DialogActions>
                </Root>
            </Dialog>
            <Grid
                container
                className={classes.root}

            >
                {renderList()}
            </Grid>
        </Root>
    )
}

const useStyles = generateStyle((theme) => ({
    appBar: {
        position: "relative",
        overflow: 'hidden'
    },
    header: {
        flexGrow: 1
    },
    root: {
        padding: '8px 16px',
    },
    fileContainer: {
        marginBottom: 16,
        '& .fileBody': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: '16px 16px 4px',
            '& img': {
                height: 82,
                width: 82,
                marginRight: 16,
                objectFit: 'cover',
            },
        },
        '& .fileDetail': {
            width: '100%',
            '& > *': {
                marginBottom: 8,
                marginTop: 0,
            },
            '& > *:first-of-type': {
                marginBottom: 4,
                color: theme.palette.primary.main
            },
            '& > *:last-child': {
                marginBottom: 0,
            },
            '& > p': {
                overflowWrap: 'anywhere'
            },
            '& > .notes': {
                fontSize: 12,
                fontStyle: 'italic'
            }
        },
        '& .buttonsContainer': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            '& > *': {
                marginRight: 12,
                marginBottom: 8
            },
            '& > *:last-child': {
                marginRight: 0,
            },
            '& > .downloadButton': {
                minWidth: 0,
            },
        }
    }
}), "AdvisorFileList")

const fileTypes = [
    {
        value: 'list_inventory',
        label: 'List Inventory'
    },
    {
        value: 'owner_agreement_star_listing',
        label: 'Bukti Perjanjian Star Listing'
    },
    {
        value: 'owner_document_ownership',
        label: 'Bukti Kepemilikan Unit'
    },
    {
        value: 'surat_izin_memasarkan',
        label: 'Surat Izin Memasarkan'
    },
    {
        value: 'surat_kuasa',
        label: 'Surat Kuasa'
    },
    {
        value: 'receipt',
        label: 'Tanda Terima'
    },
    {
        value: 'other',
        label: 'Lainnya'
    }
]

export default AdvisorFileList

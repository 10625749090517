import React, { useCallback, useRef, useState } from "react"

const useLongPress = (
    onLongPress: (e?: React.MouseEvent) => void,
    onClick: (e?: React.MouseEvent) => void,
    { shouldPreventDefault = true, delay = 500 } = {}
) => {
    const [longPressTriggered, setLongPressTriggered] = useState(false)
    const timeout = useRef<null | ReturnType<typeof setTimeout>>(null)
    const target = useRef<null | EventTarget>(null)

    const start = useCallback((event: any) => {
        if (shouldPreventDefault && event.target) {
            event.target.addEventListener("touchend", preventDefault, {
                passive: false
            })
            target.current = event.target
        }
        timeout.current = setTimeout(() => {
            onLongPress(event)
            setLongPressTriggered(true)
        }, delay)
    },
        [onLongPress, delay, shouldPreventDefault]
    )

    const clear = useCallback((event: any, shouldTriggerClick = true) => {
        timeout.current && clearTimeout(timeout.current)
        shouldTriggerClick && !longPressTriggered && onClick(event)
        setLongPressTriggered(false)
        if (shouldPreventDefault && target.current) {
            target.current.removeEventListener("touchend", preventDefault)
        }
    },
        [shouldPreventDefault, onClick, longPressTriggered]
    )

    return {
        onMouseDown: (e: React.MouseEvent) => start(e),
        onTouchStart: (e: React.TouchEvent<HTMLDivElement>) => start(e),
        onMouseUp: (e: React.MouseEvent) => clear(e),
        onMouseLeave: (e: React.MouseEvent) => clear(e, false),
        onTouchEnd: (e: React.TouchEvent<HTMLDivElement>) => clear(e)
    }
}

const isTouchEvent = (event: React.MouseEvent | React.TouchEvent | Event) => {
    return "touches" in event
}

const preventDefault = (event: React.MouseEvent | React.TouchEvent | Event) => {
    if (!isTouchEvent(event)) return

    if ('touches' in event && event.touches.length < 2 && event.preventDefault) {
        event.preventDefault()
    }
}

export default useLongPress
import React, { useContext, useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { Badge, Box, Divider, IconButton, Popover, Typography, useTheme } from '@mui/material'

/**
 * Utils
 */
import { PermissionContext } from '../_contexts/PermissionContext'
import { format, isToday } from 'date-fns'
import Swal from 'sweetalert2'
import axios from 'axios'

/**
 * Icons
 */
import { FormatListBulleted } from '@mui/icons-material'
import { openExternalLink } from '../_utils/Helper'


type Todo = {
    client_name: string
    description: string
    due_date: string
    due_time: string
    id: number
    is_notified: number
    ticket_code: string
    ticket_id: number
    unit_code: string
}

const ToDoPopOver = () => {
    const theme = useTheme()
    const permissions = useContext(PermissionContext)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const [time, setTime] = useState('')

    const [todos, setTodos] = useState<Todo[]>([])

    const isRed = useMemo(() => {
        if (todos.length === 0) {
            return false
        }

        return todos.filter(td => time >= td.due_time).length > 0
    }, [todos, time])

    useEffect(() => {
        const fetchData = () => {
            const todoList: (0 | Todo[]) = JSON.parse(localStorage.getItem('todo-list') || '0')

            if (todoList && todoList.length > 0) {
                if (!isToday(new Date(todoList[0].due_date))) {
                    setTodos([])
                    localStorage.removeItem('todo-list')
                    return
                }

                setTodos(todoList)

                let currentTodo: null | Todo = null
                const currentTime = format(new Date(), 'HH:mm:ss')
                setTime(currentTime)

                todoList.forEach(todo => {
                    if (todo.due_time && todo.is_notified === 0 && currentTime >= todo.due_time) {
                        if (currentTodo?.due_time) {
                            if (todo.due_time < currentTodo.due_time) {
                                currentTodo = todo
                            }
                        } else {
                            currentTodo = todo
                        }
                    }
                })

                if (currentTodo !== null) {
                    const ctd = currentTodo as Todo
                    Swal.fire({
                        title: 'Reminder To-Do',
                        html: `${ctd.description} - ${ctd.unit_code}<br/>Tenant: ${ctd.client_name}`,
                        icon: 'info',
                        confirmButtonText: 'Lihat Ticket',
                        showCancelButton: true,
                        cancelButtonText: 'Dismiss',
                        reverseButtons: true,
                        allowOutsideClick: false,
                    })
                        .then(res => {
                            const newTodos = todoList.map(td => ({ ...td, is_notified: td.id === currentTodo!.id ? 1 : td.is_notified }))
                            localStorage.setItem('todo-list', JSON.stringify(newTodos))
                            setTodos(newTodos)

                            if (res.value) {
                                openExternalLink(`/ticket/list/${ctd.ticket_id}`)
                            }
                        })
                }
            } else {
                setTodos([])
            }
        }

        fetchData()

        const interval = setInterval(() => {
            fetchData()
        }, 60000)

        axios.interceptors.response.use(res => {
            if (res.config.url?.includes('tr-dashboard')) {
                setTimeout(() => {
                    fetchData()
                }, 1000);
            }

            return res
        })

        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        permissions['dashboard-type'] !== 'tenant-relation' ? null :
            <>
                <IconButton
                    color="inherit"
                    edge="end"
                    onClick={e => setAnchorEl(e.currentTarget)}
                >
                    <Badge badgeContent={todos.length} color={isRed ? "error" : "warning"} overlap="circular" invisible={todos.length === 0}>
                        <FormatListBulleted />
                    </Badge>
                </IconButton>
                <Popover
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box
                        sx={{
                            maxHeight: 219,
                            overflow: 'auto',
                        }}
                    >
                        {
                            todos.length ?
                                todos.map((td, index) => (
                                    <React.Fragment key={td.id}>
                                        {index !== 0 ? <Divider /> : null}
                                        <Box
                                            sx={{
                                                display: 'block',
                                                minWidth: 300,
                                                maxWidth: 300,
                                                backgroundColor: time > td.due_time ? theme.palette.error.light : theme.palette.background.paper,
                                                px: 1,
                                                py: 0.5,
                                                color: time > td.due_time ? 'white' : 'black',
                                                textDecoration: 'none',
                                                '&:hover': {
                                                    backgroundColor: time > td.due_time ? theme.palette.error.main : theme.palette.grey[200],
                                                }
                                            }}
                                            component={'a'}
                                            href={`/ticket/list/${td.ticket_id}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <Box display={'flex'} flexDirection={'row'} alignItems={'start'} justifyContent={'space-between'}>
                                                <Typography variant='subtitle2'>
                                                    {td.description} - {td.unit_code}
                                                </Typography>
                                                <Typography variant='caption' sx={{ ml: 1.5 }}>
                                                    {td.due_time.split(':').slice(0, 2).join(':')}
                                                </Typography>
                                            </Box>
                                            <Typography variant='body2' sx={{ mt: 0.5 }}>
                                                {td.client_name}
                                            </Typography>
                                        </Box>
                                    </React.Fragment>
                                ))
                                :
                                <Box
                                    sx={{
                                        display: 'block',
                                        minWidth: 300,
                                        backgroundColor: theme.palette.background.paper,
                                        px: 1,
                                        py: 0.5,
                                        color: 'black',
                                        textDecoration: 'none',
                                    }}
                                >
                                    <Typography variant='subtitle2'>
                                        Belum ada To-Do
                                    </Typography>
                                </Box>
                        }
                    </Box>
                </Popover>
            </>
    )
}

export default ToDoPopOver

import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import TextArea from '../../../_components/_form/TextArea'

type Props = {
    open: boolean
    onClose: () => void
    onSubmit: (note: string) => void
    defaultNotes?: string
}

const NotesModal = (props: Props) => {
    const [notes, setNotes] = useState('')

    useEffect(() => {
        if (!props.open) { setNotes('') }
        
        if (props.open && props.defaultNotes) {
            setNotes(props.defaultNotes)
        }
    }, [props.open, props.defaultNotes])


    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                Notes
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextArea
                            name='notes'
                            placeholder='Silahkan isi notes (bisa dikosongkan)'
                            onChange={(e) => setNotes(e.target.value)}
                            value={notes}
                            rows={3}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            onClick={() => props.onSubmit(notes)}
                            sx={{ float: 'right' }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default NotesModal
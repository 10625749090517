import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

/**
 * Utils
 */
import { dateFormat, numberToCurrency } from '../../../_utils/Helper';

export interface IHistory {
    created_at: string
    code: string
    customer_name: string
    start_date: string
    end_date: string
    advisor_name: string
    extend_pic_name: string
}

interface Props {
    histories: IHistory[];
    permissions: any
}

const ClosingHistoryList = ({ histories, permissions }: Props) => {
    const renderHistories = () => {
        if (histories.length > 0) {
            return histories.map((log: any, key: number) => {
                return <TableRow>
                    <TableCell>{dateFormat(log.created_at, 'DD-MM-YYYY HH:mm:ss')}</TableCell>
                    <TableCell>
                        {
                            permissions['closing.view'] ?
                                <a href={`/closing/${log.code}`} target="_blank" rel="noopener noreferrer">
                                    {log.code}
                                </a> :
                                log.code
                        }
                    </TableCell>
                    <TableCell>{log.gmv_transaction ? numberToCurrency(log.gmv_transaction, "Rp. ") : '-'}</TableCell>
                    <TableCell>{log.basic_price ? numberToCurrency(log.basic_price, "Rp. ") : '-'}</TableCell>
                    <TableCell>{`${log.duration ? Math.round(log.duration / 30) : '0'} bulan (${log.duration} hari)`}</TableCell>
                    <TableCell>{log.customer_name}</TableCell>
                    <TableCell>{dateFormat(log.start_date, 'DD-MM-YYYY')}</TableCell>
                    <TableCell>{dateFormat(log.end_date, 'DD-MM-YYYY')}</TableCell>
                    <TableCell>{log.advisor_name ? log.advisor_name : "-"}</TableCell>
                    <TableCell>{log.extend_pic_name ? log.extend_pic_name : "-"}</TableCell>
                </TableRow>
            });
        } else {
            return <TableRow>
                <TableCell colSpan={10}>There is no logs yet</TableCell>
            </TableRow>
        }
    }

    return (
        <>
            <TableContainer component={Paper} elevation={0} sx={{ maxHeight: 400 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tanggal Input</TableCell>
                            <TableCell>Kode</TableCell>
                            <TableCell>GMV</TableCell>
                            <TableCell>Basic Price</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Tanggal Masuk</TableCell>
                            <TableCell>Tanggal Checkout</TableCell>
                            <TableCell>Advisor</TableCell>
                            <TableCell>PIC Extend</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderHistories()}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ClosingHistoryList;
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AsyncAutoComplete from '../../../../_components/_form/AsyncAutoComplete'
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../../../_utils/Helper'
import LoadingScreen from '../../../../_components/LoadingScreen'

type Props = {
    open: boolean,
    onClose: (refresh: boolean) => void
    closingId: string
    initialAgent: string
}

const ChangeAgentModal = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [agent, setAgent] = useState({
        id: 0,
        label: ''
    })
    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/change-agent`, {
            id: props.closingId,
            agent_id: agent.id
        })
            .then(res => {
                renderSuccessButton('Agent berhasil diubah!')
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    const handleChangeAutoComplete = (name: string, value: any) => {
        setAgent({
            id: value.id,
            label: value.label
        })
    }

    useEffect(() => {
        if (!props.open) {
            return setAgent({ id: 0, label: '' })
        }

    }, [props.open])
    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <LoadingScreen open={isLoading} fullScreen/>
            <DialogTitle>
                Change Agent
            </DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            name='agent_id'
                            onChange={handleChangeAutoComplete}
                            onInputChange={() => setAgent({ id: 0, label: '' })}
                            initialQuery={props.initialAgent || ''}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/agent`}
                            clearable
                            onClear={() => setAgent({ id: 0, label: '' })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            onClick={handleSubmit}
                            sx={{ float: 'right' }}
                            disabled={!agent.id}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ChangeAgentModal
import React, { useState, useEffect } from 'react'
import { Button, Dialog, DialogContent, Grid, MenuItem, TextField, Typography } from '@mui/material'
import Swal from 'sweetalert2';

/* 
* Utils
*/
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper';

/* 
* Interfaces
*/

interface IBillingModalProps {
    open: boolean,
    onClose: (refresh: boolean) => void,
    id: string,
    billing_due_date: string
}

const toastMixin = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
});


const BillingDueDateModal = (props: IBillingModalProps) => {
    const [billingDueDate, setBillingDueDate] = useState(props.billing_due_date)
    const createMenuList = (count: number) => {
        let list = []
        for (let i = 1; i <= count; i++) {
            list.push(<MenuItem value={i}>{i}</MenuItem>)
        }
        return list
    }

    const handleChange = (evt: any) => {
        setBillingDueDate(evt.target.value)
    }

    useEffect(() => {
        setBillingDueDate(props.billing_due_date)
    }, [props.open, props.billing_due_date])

    const handleSubmit = () => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/apartment/change-billing-due-date`, {
            id: props.id,
            billing_due_date: billingDueDate
        })
            .then(res => {
                toastMixin.fire({
                    icon: 'success',
                    title: 'Billing Changed!'
                })

                props.onClose(true)
            })
            .catch(err => {
                generalErrorHandler(err)
            })
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="xs"
        >
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography fontWeight="semibold" sx={{ mb: 2 }}>Billing Due Date</Typography>
                    </Grid>
                    <Grid xs={12} sx={{ mb: 2 }}>
                        <TextField
                            select
                            name="billing_due_date"
                            label=""
                            value={billingDueDate}
                            onChange={handleChange}
                            fullWidth
                        >
                            {createMenuList(31)}
                        </TextField>
                    </Grid>
                    <Button
                        variant='contained'
                        onClick={handleSubmit}
                    >
                        Change billing
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default BillingDueDateModal
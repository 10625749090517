import React from 'react'
import { Paper, Theme, Avatar, Box, Divider, LinearProgress, Chip, linearProgressClasses, tooltipClasses, Typography } from '@mui/material'

/**
 * Utils
 */
import { numberToCurrency } from '../../../_utils/Helper';
import { IAdvisorData } from '../DashboardPMCaptain';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface IProps {
    type: 'monthly' | 'yearly'
    advisorData: IAdvisorData
    isCaptain?: boolean | undefined
    month?: string
}

const TargetCard = (props: IProps) => {
    const { Root, classes } = useStyles()
    // const [seeDetail, setSeeDetail] = useState(false)

    // const handleSeeDetail = () => {
    //     setSeeDetail(!seeDetail)
    // }

    const getPercentage = (target: number, commision: number) => {
        if (!commision || !target) return 0
        const percentage = Math.floor((commision / target) * 100)
        if (percentage > 100) return 100

        return percentage
    }

    function formatValue(value: number) {
        if (value >= 1000000) {
            return (value / 1000000).toFixed(0) + " Juta";
        } else if (value >= 1000) {
            return (value / 1000).toFixed(0) + "rb";
        } else {
            return value.toString();
        }
    }

    // const getDetail = (target: number, commision: number, minimum: number, isOnTrack: boolean) => {
    //     if (isOnTrack) return 'Kamu sudah On Track dalam mencapati Target. Keep up the good work!'

    //     let calculatedLack = minimum - commision

    //     return `Kamu butuh ${formatValue(calculatedLack)} untuk dapat On Track. Ayo semangat!`

    // }

    const getRevenueDetail = (showing: number, enquiry: number, closing: number) => {
        const showingPercentage = enquiry === 0 ? '0%' : Math.floor((showing / enquiry) * 100) + '%'
        const closingPercentage = enquiry === 0 ? '0%' : Math.floor((closing / enquiry) * 100) + '%'

        return {
            closingPercentage,
            showingPercentage
        }
    }

    const renderRevenueDetail = (icon: string, value: string | number) => {
        return (
            <Box className={`${classes.revenueValue}`}>
                <Chip className={classes.iconChip} color="primary" variant='filled' label={icon} />
                <Typography variant='body2'>{value}</Typography>
            </Box>
        )
    }

    return (
        <Root>
            <Paper elevation={1} className={classes.card}>
                {
                    props.isCaptain ?
                        <>
                            <Box className={classes.profileBox}>
                                <Box className={classes.box} style={{ justifyContent: 'flex-start', alignItems: 'center', marginBottom: '8px' }}>
                                    <Avatar alt={''} src={props.advisorData.profile_picture ? props.advisorData.profile_picture : ''} sx={{ width: 50, height: 50 }} />
                                    <Box>
                                        <Typography variant='h6'>{props.advisorData.name}</Typography>
                                        <Typography variant='body2' >Property Manager</Typography>
                                    </Box>
                                </Box>
                                <Typography variant='body2'>{props.month}</Typography>
                            </Box>
                        </> :
                        <Typography>
                            {props.type === 'monthly' ? format(new Date(), 'LLLL yyyy', { locale: id }) : `Tahun ${format(new Date(), 'yyyy')}`}
                        </Typography>
                }
                <Box className={classes.box}>
                    <Typography variant="body2">
                        Target {props.type === 'monthly' ? 'Bulanan: ' : 'Tahunan: '}
                        <strong>
                            Rp. {formatValue(props.advisorData.target)}
                        </strong>
                    </Typography>
                    <Chip
                        {...(
                            props.advisorData.on_track ? {
                                label: 'On Track',
                                color: 'success'
                            } : {
                                label: 'Off Track',
                                color: 'error'
                            }
                        )}
                    />
                </Box>
                <Box className={classes.box} sx={{ mt: 2 }}>
                    <LinearProgress variant="determinate" sx={(theme) => ({
                        flex: 1,
                        height: 10,
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: '#BFBFBF',
                        [`&.${linearProgressClasses.colorPrimary}`]: {
                            backgroundColor: theme.palette.grey[200],
                        },
                        [`& .${linearProgressClasses.bar}`]: {
                            borderRadius: 5,
                            backgroundColor: props.advisorData.on_track ? theme.palette.success.dark : theme.palette.error.dark,
                        },
                    })} value={getPercentage(props.advisorData.target, props.advisorData.commission)} />
                    <Typography>
                        {getPercentage(props.advisorData.target, props.advisorData.commission)} %
                    </Typography>
                </Box>
                <Box className={`${classes.revenue}`}>
                    <Box className={classes.revenueValue} style={{ padding: '15px' }}>
                        <Typography fontSize={12}>
                            Revenue saat ini
                        </Typography>
                        <Typography fontSize={14}>
                            <strong>
                                {numberToCurrency(props.advisorData.commission, 'Rp. ')}
                            </strong>
                        </Typography>
                    </Box>
                    <Divider />
                    <Box className={classes.revenueValue}>
                        {renderRevenueDetail('E', props.advisorData.enquiry_count || '0')}
                        {renderRevenueDetail('S', `${props.advisorData.activity_count || '0'} (${getRevenueDetail(props.advisorData.activity_count || 0, props.advisorData.enquiry_count || 0, props.advisorData.closing_count || 0).showingPercentage})`)}
                        {renderRevenueDetail('C', `${props.advisorData.closing_count || '0'} (${getRevenueDetail(props.advisorData.activity_count || 0, props.advisorData.enquiry_count || 0, props.advisorData.closing_count || 0).closingPercentage})`)}
                    </Box>
                    {/* <Box
                    className={classes.tooltipMessage}
                >
                    <Tooltip
                        open={seeDetail}
                        onClose={handleSeeDetail}
                        arrow
                        PopperProps={{
                            disablePortal: true
                        }}
                        color='white'
                        placement='bottom'
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={renderMessage(getDetail(props.target, props.commision, props.onTrackMininum, props.isOnTrack))}
                    >
                        <Chip variant='outlined' color='primary' label="Detail" onClick={handleSeeDetail} />
                    </Tooltip>
                </Box> */}
                </Box>
                {
                    props.isCaptain ?
                        <Box style={{ textAlign: 'center', padding: '15px 0' }}>
                            <Link className={classes.link} to={`/advisor-performance/${props.advisorData.id}`}>
                                Performance History
                            </Link>
                        </Box> : null
                }
            </Paper>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    card: {
        marginTop: 15,
        padding: 15,
        borderRadius: 10,
        border: '1px solid #E7E7E7'
    },
    link: {
        color: '#484848',
        fontWeight: '700',
    },
    profileBox: {
        marginTop: 7,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    box: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 20
    },
    revenue: {
        marginTop: 10,
        borderRadius: 15,
        background: '#F0F6FC'
    },
    revenueValue: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        padding: '8px 0'
    },
    iconChip: {
        '&.MuiChip-root': {
            borderRadius: 5
        }
    },
    tooltipMessage: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        [`& .${tooltipClasses.arrow}`]: {
            "&:before": {
                border: "1px solid #ababab"
            },
            color: theme.palette.common.white,
            fontSize: 20
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'white',
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[5],
            border: "1px solid #ababab",
            fontSize: 11,
            maxWidth: '90%',
            margin: '0 auto',
        },
    }
}), "TargetCard"
)

export default TargetCard
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useState } from 'react'
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'
import LoadingScreen from '../../../_components/LoadingScreen'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../../_utils/Helper'

type Props = {
    id: number
    open: boolean
    onClose: (refresh: boolean) => void
    initialQuery: string
}

const initialPIC = {
    id: 0,
    label: ''
}

const PICModal = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [pic, setPIC] = useState<{ id: number, label: string }>(initialPIC)

    const handleSubmit = () => {
        setIsLoading(true)
        if (pic.id) {
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/assign-pic`, {
                id: props.id,
                pic_id: pic.id
            })
                .then(res => res.data)
                .then(res => {
                    renderSuccessButton('Berhasil ubah PIC!')
                    props.onClose(true)
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }
    

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <LoadingScreen open={isLoading} fullScreen/>
            <DialogTitle>Change PIC</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{mt: 0}}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            name='pic'
                            initialQuery={props.initialQuery || ''}
                            onChange={(name: string, value: any) => {
                                setPIC({
                                    id: value.id,
                                    label: value.label
                                })
                            }}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/pic-jhs-coordinator`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button disabled={!pic.id} sx={{float: 'right'}} variant='contained' onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default PICModal
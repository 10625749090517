import React, { useState, useContext } from 'react';
import {
    Switch,
    IconButton,
} from '@mui/material';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import { PermissionContext } from '../../_contexts/PermissionContext';

/**
 * Types
 */
import { IColumn } from '../../_components/_dataTable/DataTable';
import {
    converNumberFormat,
} from '../../_utils/Helper';
import { WhatsApp } from '@mui/icons-material';
import SwitchAvailableSellModal from '../_unit/_components/_modals/SwitchAvailableSellModal';


const UnitSellList = () => {
    const permissions = useContext(PermissionContext)
    const API_URL = process.env.REACT_APP_API_URL + '/unit-sell';

    const [modalAvailableSellOpen, setModalAvailableSellOpen] = useState(false)
    const [selectedUnit, setSelectedUnit] = useState<any>({});
    const [tempDTRows, setTempDTRows] = useState<{ setRows: Function, rows: any[] }>({
        setRows: () => { },
        rows: []
    });

    const handleAvailableSellSwitch = (unit: any, setRows: Function, rows: any[]) => {
        setTempDTRows({
            setRows,
            rows
        });
        setSelectedUnit(unit)
        setModalAvailableSellOpen(true)
    }

    const columns: IColumn[] = [
        {
            name: 'code',
            label: 'Kode',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'owner_name',
            label: 'Nama Owner',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'owner_phone',
            label: 'Nomor Owner',
            filterable: true,
            type: 'string',
            render: (data: string) => data ?
                <div>
                    <span>
                        {data}
                    </span>
                    <IconButton color="primary" href={`https://api.whatsapp.com/send?phone=${data}`} target="_blank" rel='noopener noreferrer'>
                        <WhatsApp />
                    </IconButton>
                </div>
                : '-',
        },
        {
            name: 'apartment_name',
            label: 'Apartment',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'unit_detail',
            label: 'Unit Detail',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'selling_price',
            label: 'Harga Jual',
            filterable: true,
            type: 'number',
            render: (data: string) => data ? converNumberFormat(data) : '-',
        },
        {
            name: 'basic_price_sell',
            label: 'Harga Basic',
            filterable: true,
            type: 'number',
            render: (data: string) => data ? converNumberFormat(data) : '-',
        },
        {
            name: 'is_available_sell',
            label: 'Available Sell',
            filterable: true,
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: '1', value: 'Available' },
                { key: '0', value: 'Unavailable' }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return permissions['unit.switch-available']
                    ? <Switch
                        checked={data === 1}
                        onChange={() => handleAvailableSellSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                    : (data === 1 ? 'Yes' : 'No')
            }
        },
    ];

    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <SwitchAvailableSellModal
                id={selectedUnit.id}
                isAvailable={selectedUnit.is_available_sell}
                onClose={() => setModalAvailableSellOpen(false)}
                open={modalAvailableSellOpen}
                callBackSubmitSuccess={() => {
                    const newRows = tempDTRows.rows.map((element: any) => {
                        if (+element.id === +selectedUnit.id) element['is_available_sell'] = selectedUnit.is_available_sell ? 0 : 1;
                        return element
                    })
                    tempDTRows.setRows(newRows);
                }}
                setIsLoading={(value: boolean) => setIsLoading(value)}
            />

            <DataTable
                url={API_URL}
                columns={columns}
            />
        </>
    );
}

export default UnitSellList;
import React, { Fragment } from 'react'

import { Paper, Divider, Box, Zoom, Theme } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { generateStyle } from '../../_utils/DefaultStyle';


const useStyles = generateStyle((theme: Theme) => ({
  root: {
    flexGrow: 1,
    color: '#505050'
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    flexGrow: 1,
    flexBasis: 0,
    margin: '0 5px 0 5px',
    display: 'flex',
    flexFlow: 'column'
  },
  apt_title: {
    display: 'flex',
    flexFlow: 'column'
  }
  ,
  apt_name: {
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: '67px'
  },
  title_text: {
    textAlign: 'left',
    fontWeight: 500
  },
  bar: {
    height: '6px',
    borderRadius: '3px',
    opacity: '0.3',
    width: '100%'
  },
  bar_balance: {
    height: '6px',
    borderRadius: '3px',
    width: '100%'
  },
  bg_bar1: {
    background: 'rgba(0, 155, 255, 1)'
  },
  bg_bar2: {
    background: 'rgba(255, 90, 95, 1)'
  },
  bg_balance: {
    background: '#F0F0F0'
  },
  balance: {
    color: '#f0f0f0'
  },
  midDivider: {
    height: '7px',
    width: '1px',
    backgroundColor: '#707070',
    marginTop: '-7px'
  },
  bar_container: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    marginTop: '5px'
  },
  center: {
    justifyContent: 'space-around'
  },
  around: {
    justifyContent: 'space-between'
  }
  ,
  demand_info: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    marginTop: '20px'
  },
  desc_text: {
    fontSize: '13px',
    fontWeight: 400,
    margin: 0
  },
  na_text: {
    fontWeight: 500,
    marginTop: '-5px',
    marginBottom: '-7px',
  },
  na_unit: {
    fontSize: '18px',
    margin: 0,
    marginBottom: '17px',
    opacity: 0
  }
  ,
  demand_number: {
    fontSize: '11px',
    margin: 0,
  },
  demand_unit: {
    fontSize: '15px',
    fontWeight: 500,
    margin: 0,
  },
  summ_unit: {
    fontSize: '18px',
    fontWeight: 600,
    margin: 0,
    minWidth: '20px',
    textAlign: 'end'
  }, summ_type: {
    fontSize: '14px',
    textAlign: 'left',
    margin: '0 0 18px 8px'
  },
  line_group: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'baseline'
  },
  none: {
    color: '#f0f0f0'
  },
  summ_group: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#505050'
  }
}), "ApartementCard"
);

const ApartmentCard = (props: any) => {
  const { Root, classes } = useStyles();
  const roomType = ['STUDIO', '1BR', '2BR', '3BR'];
  const roomLabel = ['Studio', '1 BR', '2 BR', '3 BR'];

  const valueConverter = (demand: number, supply: number) => {
    let val = 50 + (((demand - supply) / demand) / 2) * 100;
    return val
  }



  const renderBars = () => {
    let bars = [<Fragment key={5}></Fragment>];
    roomType.map((type: string, idx) => {
      if (typeof props.aptData[type] === 'undefined') {
        bars.push(
          <Fragment key={idx}>
            <div className={`${classes.bar_container} ${classes.balance}`}>
              <div className={`${classes.demand_info} ${classes.center}`}>
                <p className={classes.demand_unit}>{roomLabel[idx]}</p>
              </div>
              <p className={`${classes.desc_text} ${classes.na_text}`}>Not Available</p>
            </div>
          </Fragment>
        )
      } else if (props.aptData[type].demand <= props.aptData[type].available) {
        bars.push(
          <Fragment key={idx}>
            <div className={`${classes.bar_container} ${classes.balance}`}>
              <div className={`${classes.demand_info} ${classes.around}`}>
                <p className={classes.demand_number}>{props.aptData[type].demand}</p>
                <p className={classes.demand_unit}>{roomLabel[idx]}</p>
                <p className={classes.demand_number}>{props.aptData[type].available}</p>
              </div>
              <LinearProgress variant="determinate" value={100} className={classes.bar_balance} classes={{ barColorPrimary: classes.bg_balance }} />
            </div>
          </Fragment>
        )
      } else {
        bars.push(
          <div className={`${classes.bar_container}`} key={idx}>
            <div className={`${classes.demand_info} ${classes.around}`}>
              <p className={classes.demand_number}>{props.aptData[type].demand}</p>
              <p className={classes.demand_unit}>{roomLabel[idx]}</p>
              <p className={classes.demand_number}>{props.aptData[type].available}</p>
            </div>
            <LinearProgress variant="determinate" value={valueConverter(props.aptData[type].demand, props.aptData[type].available)} color="primary" className={classes.bar} classes={{ barColorPrimary: classes.bg_bar1, colorPrimary: classes.bg_bar2 }} />
            <div className={classes.midDivider}></div>
          </div>
        )
      }
      return ''
    }
    )
    return bars;
  }

  const renderSummary = (part: string) => {
    let start = 0;
    let limit = 3;
    part === 'left' ? (limit = 1) : (start = 2);
    let unit = [<Fragment key={limit + 3}></Fragment>];
    roomType.map((type: string, idx) => {
      if (idx >= start && idx <= limit) {
        if (typeof props.aptData[type] === 'undefined') {
          unit.push(
            <Box className={classes.line_group} key={idx}>
              <p className={classes.na_unit}>NA</p>
            </Box>
          )
        } else {
          unit.push(renderUnit(roomLabel[idx], props.aptData[type].demand, props.aptData[type].available));
        }
      }
      return ''
    })
    return unit
  }

  const renderUnit = (type: string, demand: number, available: number) => {
    return (
      <Box className={demand <= available ? (`${classes.line_group} ${classes.none}`) : (classes.line_group)} key={type}>
        <p className={classes.summ_unit}>{demand <= available ? (0) : (demand - available)}</p>
        <p className={classes.summ_type}>{type}</p>
      </Box>
    )
  }

  return (
    <Zoom in={true} style={{ transitionDelay: props.delay }}>
      <Root>
        <Paper className={classes.paper}>
          <Box>
            <div className={classes.apt_title}>
              <div className={classes.apt_name}>
                <p className={classes.title_text} style={{ marginRight: '10px' }}>{props.index}</p>
                <p className={classes.title_text} style={{ color: '#707070' }}>{props.title}</p>
              </div>
              <Divider />
            </div>
            <div className={`${classes.demand_info} ${classes.around}`}>
              <p className={classes.desc_text}>Demand</p>
              <p className={classes.desc_text}>Availability</p>
            </div>
            {renderBars()}
            <h3 style={{
              fontSize: '14px', fontWeight: 500,
              marginBottom: '8px', color: '#505050',
              marginTop: '22px'
            }}>Unit Needed</h3>
            <Box className={classes.summ_group}>
              <Box>
                {renderSummary('left')}
              </Box>
              <Box>
                {renderSummary('right')}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Root>
    </Zoom>
  )
}

export default ApartmentCard;
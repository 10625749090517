import React, { useEffect, useState, useReducer, SyntheticEvent, useRef } from 'react';
import { Theme, Button, Dialog, Grid, IconButton, DialogTitle, DialogContent, TextField, MenuItem, Checkbox, Typography } from '@mui/material';


// Components
import LoadingScreen from '../../../_components/LoadingScreen';
import ImagePreview from '../../../_components/ImagePreview';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import SimpleDataTable from '../../../_components/_dataTable/SimpleDataTable';

// Utils
import Swal from 'sweetalert2';
import { IValidationAlias, IValidationErrors, IValidationRules, validateData, validateOne } from '../../../_utils/Validation';
import { generalErrorHandler, renderErrorButton, renderSuccessButton, renderWarningButton } from '../../../_utils/Helper';
import { format } from 'date-fns';
import DefaultAxios from '../../../_utils/DefaultAxios';

// Icons
import CheckIcon from '@mui/icons-material/Check';
import ListIcon from '@mui/icons-material/List';
import ImageIcon from '@mui/icons-material/Image';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import NotesIcon from '@mui/icons-material/Notes';
import NewImageSlider from '../../../_components/_cards/_imageSlider/NewImageSlider';
import NotesModal from './NotesModal';
import { IJob } from './JobDetailList';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    id: number
    data: ISchedule[]
    jobList: IJob[]
    loadData: Function
    permissions: any;
}

export interface ISchedule {
    activity_id: number
    date: string
    time: string
    division: string
    pic_id: number
    pic_name: string
    pic_phone: string
    status: number
    meeting_type: number
    jobs: any[]
    files: any[]
    notes: string
    id: number
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        width: '100%',
        '& .MuiTextField-root': {
            backgroundColor: '#fff'
        }
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    jobDetailListRoot: {

    },
    jobDetailList: {
        padding: 12,
        borderRadius: 5,
        border: '1px solid lightgray ',
        marginBottom: 12,
        textAlign: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-word'
    },
    fileName: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    modalFileJob: {
        padding: 12,
        marginRight: 20,
        marginLeft: 20
    }
}), "Schedule_list"
);

const divisionsOption = [
    { label: 'Cleaning', value: 'jhs_cleaning' },
    { label: 'Fixing', value: 'jhs_fixing' },
    { label: 'AC', value: 'jhs_ac' },

]

interface IState {
    date: Date | null
    time: Date | null
    pic_id: number | null
    pic_label: string
    meeting_id: string
    meeting_label: string
    division: string
    notes: string
    jobActivity: number[] | null
    id: number | null
}

interface IAction {
    name: string
    value: any
    type: string
}

const ScheduleList = (props: Props) => {

    const validationRules: IValidationRules = {
        date: 'required',
        meeting_id: 'required',
        division: 'required',
        jobActivity: 'required',
    }

    const validationAlias: IValidationAlias = {
        date: 'Tanggal',
        meeting_id: 'Ketemuan',
        division: 'Divisi',
        jobActivity: 'Pekerjaan',
    }

    const { Root, classes } = useStyles();
    const [isLoading, setIsLoading] = useState(false)
    const [jobListModal, setJobListModal] = useState(false)
    const [filesModal, setFilesModal] = useState(false)
    const [jobList, setJobList] = useState<IJob[]>([])
    const [jobListData, setJobListData] = useState<any[]>([])
    const [fileListData, setFileListData] = useState<any[]>([])
    const [fileId, setFileId] = useState('')
    const [hideComplete, setHideComplete] = useState(false)
    const [redrawDataTable, setRedrawDataTable] = useState(new Date().getTime())
    const [time, setTime] = useState('');
    const [formModal, setFormModal] = useState(false)
    const [error, setError] = useState<IValidationErrors>({})
    const fileInput = useRef<HTMLInputElement>(null)
    const [noteModal, setNoteModal] = useState('')
    const [scheduleNotes, setScheduleNotes] = useState('')
    const [listJobFile, setListJobFile] = useState({
        show: false,
        id: ''
    })
    const [preview, setPreview] = useState({
        modal: false,
        url: ''
    })
    const initialState = {
        date: null,
        time: null,
        pic_id: null,
        pic_label: '',
        meeting_id: '',
        division: '',
        meeting_label: '',
        notes: '',
        jobActivity: null,
        id: null
    }

    useEffect(() => {
        if (props.jobList) {
            setJobList(props.jobList)
        }
    }, [props.jobList]);

    useEffect(() => {
        setRedrawDataTable(new Date().getTime())
    }, [props.data, hideComplete])

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [formState, setFormState] = useReducer(inputReducer, initialState);

    const handlePreview = (attachment: string) => {

        let filepath = attachment
        let explode = filepath.split('?');
        const match = /(\.\w+$)/g.exec(explode[0]);
        let isImage = false

        if (match) {
            if (match[1] === '.pdf' || match[1] === '.PDF') {
                // PDF File
            } else if (match[1] === '.doc' || match[1] === '.docx') {
                // DOC File
            } else if (['.flv', '.mp4', '.m3u8', '.ts', '.3gp', '.mkv', '.mov', '.avi', '.wmv'].includes(match[1])) {
                // Video File
            } else {
                // Image File
                isImage = true;
            }
        }

        if (isImage) {
            setPreview({
                modal: true,
                url: attachment
            })
        } else {
            window.open(attachment)
        }
    }

    const handleAddForm = () => {
        setFormState({ name: '', value: initialState, type: 'REPLACE_STATE' });
        setTime('')
        setError(prev => ({
            ...prev,
            date: '',
            time: '',
            division: '',
            meeting_id: '',
            jobActivity: '',
            pic_id: '',
        }))
        setFormModal(true)
    }

    const handleJobListModal = (open: boolean, data: any[]) => {
        setJobListModal(open)
        setJobListData(data)
    }

    const handleCloseFormModal = () => {
        setFormModal(false)
    }

    const handleFilesModal = (open: boolean, id: string, files: any[]) => {
        setFilesModal(open)
        setFileListData(files)
        setFileId(id)
    }

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let value: any = target.value;
        let name = target.name;
        setFormState({ name, value, type: 'SET_ITEM' });

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        const { isValid, errors } = validateOne(name, value, validationRules, validationAlias)

        if (isValid) {
            setError(prev => ({
                ...prev,
                [name]: '',
            }))
        } else {
            setError(prev => ({
                ...prev,
                ...errors,
            }))
        }
    }

    const handleDateChange = (date: any, val: any) => {
        let name = "date";
        let value = date
        setFormState({ name, value, type: 'SET_ITEM' });

        setError(prev => ({
            ...prev,
            date: '',
        }))
    }

    const handleTimeChange = (date: any, val: any) => {
        let name = "time";
        let value = date
        setTime(date ? date : '');
        setFormState({ name, value, type: 'SET_ITEM' });

        setError(prev => ({
            ...prev,
            time: '',
        }))
    }

    const handleCheckbox = (id: number) => {
        const jobActivity = formState.jobActivity || [];
        if (jobActivity.includes(id)) {
            setFormState({ name: 'jobActivity', value: jobActivity.filter((num) => num !== id), type: 'SET_ITEM' });
        } else {
            setFormState({ name: 'jobActivity', value: [...jobActivity, id], type: 'SET_ITEM' });
        }

        setError(prev => ({
            ...prev,
            jobActivity: '',
        }))
    }

    const handleDone = (note: string) => {
        renderWarningButton("Apakah anda yakin ingin menyelesaikan Schedule?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity-schedule/done`, { id: noteModal, done_notes: note })
                        .then(res => {
                            renderSuccessButton('Berhasil menyelesaikan Schedule', () => {
                                props.loadData();
                                setNoteModal('')
                            })
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            });
    }

    const handleDelete = (id: number) => {
        renderWarningButton("Apakah anda yakin ingin menghapus Schedule?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)
                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/activity-schedule/${id}`)
                        .then(res => {
                            renderSuccessButton('Berhasil menghapus Schedule', () => {
                                props.loadData();
                            })
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            });
    }

    const handleDeleteFile = (id: number) => {
        renderWarningButton("Apakah anda yakin ingin menghapus File?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity-schedule/delete-file?id=${id}`)
                        .then(res => {
                            renderSuccessButton('Berhasil menghapus File', () => {
                                props.loadData();
                            })
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            });
    }

    const getDivisionLabel = (division: string) => {
        const div = divisionsOption.find(item => item.value === division)
        if (div) return div.label

        return '-'
    }

    const handleEditSchedule = (data: ISchedule) => {
        const newState = { ...formState };
        const [hours, minutes, seconds] = data.time ? data.time.split(":").map(Number) : []
        const dateTime = new Date()

        newState.id = data.id
        newState.date = new Date(data.date)
        newState.time = data.time ? new Date(dateTime.setHours(hours, minutes, seconds, 0)) : null;
        newState.pic_id = data.pic_id
        newState.pic_label = data.pic_name
        newState.meeting_id = data.meeting_type.toString()
        newState.division = data.division
        newState.notes = data.notes
        newState.jobActivity = data.jobs.map(item => item.id);

        setTime(data.time ? `Tue Aug 08 2023 ${data.time} GMT+0700 (Western Indonesia Time) ` : '')
        setFormState({ name: '', value: newState, type: 'REPLACE_STATE' });
        setError(prev => ({
            ...prev,
            date: '',
            time: '',
            meeting_id: '',
            jobActivity: '',
            pic_id: '',
        }))
        setFormModal(true)
    }

    const handleSubmitSchedule = () => {
        const { isValid, errors } = validateData(formState, validationRules, validationAlias)
        console.log(errors)
        setError(errors)

        if (isValid) {
            setFormModal(false)
            setIsLoading(true);

            const datas = {
                date: formState.date ? format(formState.date, "yyyy-MM-dd") : null,
                time: formState.time ? format(formState.time, "HH:mm") : null,
                activity_id: props.id,
                division: formState.division,
                meeting_type: formState.meeting_id,
                notes: formState.notes,
                jobs: formState.jobActivity
            };

            if (formState.id) {
                DefaultAxios.patch(`${process.env.REACT_APP_API_URL}/activity-schedule/${formState.id}`, datas)
                    .then(res => {
                        renderSuccessButton('Berhasil mengganti data')
                        setFormModal(false)
                        props.loadData()
                    })
                    .catch(err => {
                        generalErrorHandler(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            } else {
                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity-schedule`, datas)
                    .then(res => {
                        renderSuccessButton('Submit data berhasil')
                        setFormModal(false)
                        props.loadData()
                    })
                    .catch(err => {
                        generalErrorHandler(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }
        }
    }

    const handleUploadFile = (e: SyntheticEvent) => {
        setIsLoading(true);

        const target = e.target as HTMLInputElement;
        const files = { ...target.files as FileList };
        if (target && files) {
            if (files[0].size >= 8000000) {
                renderErrorButton('Failed: Max uploaded file is 8MB')
                setIsLoading(false);
            } else {
                const fd = new FormData();
                fd.append('id', fileId);
                fd.append('file', files[0]);
                DefaultAxios
                    .post(`${process.env.REACT_APP_API_URL}/activity-schedule/submit-file`, fd)
                    .then(res => {
                        renderSuccessButton('Berhasil Upload bukti', () => props.loadData())
                        setIsLoading(false);
                        setFilesModal(false)
                    })
                    .catch(error => {
                        if (+error.response.status === 422) {
                            renderErrorButton(error.response.data)
                            Swal.fire({
                                title: error.response.data,
                                icon: 'error'
                            });
                        } else {
                            renderErrorButton('Oops.. Something went wrong!!')
                        }
                        setIsLoading(false);
                    })
            }
        }
    }

    return (
        <>
            <Dialog
                open={jobListModal}
                onClose={() => { handleJobListModal(false, []) }}
                scroll="body"
                fullWidth
            >
                <Root>
                    <DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{}}>List Job Detail</span>
                    </DialogTitle>
                    <DialogContent>
                        <div className={classes.jobDetailListRoot}>
                            {
                                jobListData.map((data, idx) =>
                                    <div key={idx} className={classes.jobDetailList}>
                                        {data.description}
                                    </div>
                                )
                            }
                        </div>
                    </DialogContent>
                </Root>
            </Dialog>
            <Dialog
                open={filesModal}
                onClose={() => { handleFilesModal(false, '', []) }}
                scroll="body"
                fullWidth
            // maxWidth="md"
            >
                <Root>
                    <LoadingScreen fullScreen open={isLoading} />
                    <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span style={{}}>List File Bukti</span>
                        {
                            props.permissions['order-jhs.admin'] &&
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ float: 'right' }}
                                disabled={isLoading}
                                onClick={() => fileInput.current?.click()}
                            >
                                Tambah File
                            </Button>
                        }

                        <input
                            ref={fileInput}
                            onChange={handleUploadFile}
                            type="file"
                            accept=".jpg,.jpeg,.png,video/mp4"
                            style={{ display: 'none' }}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <div className={classes.jobDetailListRoot}>
                            {
                                fileListData.length > 0 ?
                                    fileListData.map((data, idx) =>
                                        <div key={idx} className={classes.jobDetailList}>
                                            <span onClick={() => handlePreview(data.file_url)} className={classes.fileName}>{'File ' + (idx + 1)}</span>
                                            {
                                                props.permissions['order-jhs.admin'] &&
                                                <IconButton
                                                    color="secondary"
                                                    component="span"
                                                    onClick={() => { handleDeleteFile(data.id) }}
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            }
                                        </div>
                                    )
                                    :
                                    <div style={{ textAlign: 'center' }}>
                                        <span>Tidak ada file bukti</span>
                                    </div>
                            }
                        </div>
                    </DialogContent>
                </Root>
            </Dialog>
            <Dialog
                open={formModal}
                onClose={handleCloseFormModal}
                scroll="body"
            >
                <Root>
                    <DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{}}>Schedule Form</span>
                    </DialogTitle>
                    <DialogContent style={{ paddingTop: 5 }}>
                        <Grid container justifyContent="space-between" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <DatePicker
                                    label="Tanggal"
                                    inputFormat="dd/MM/yyyy"
                                    minDate={new Date(new Date().setDate(new Date().getDate() - 7))}
                                    value={formState.date}
                                    onChange={handleDateChange}
                                    renderInput={(props) => (
                                        <TextField
                                            {...props}
                                            variant="outlined"
                                            fullWidth
                                            label={props.label}
                                            placeholder={props.placeholder ? props.placeholder : "dd/MM/yyyy"}
                                            error={!!error.date}
                                            helperText={error.date}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TimePicker
                                    label="Waktu"
                                    ampm={false}
                                    value={time}
                                    onChange={handleTimeChange}
                                    renderInput={(props) => (
                                        <TextField
                                            {...props}
                                            variant="outlined"
                                            fullWidth
                                            label={props.label}
                                            placeholder={props.placeholder ? props.placeholder : "dd/MM/yyyy"}
                                            error={!!error.time}
                                            helperText={error.time}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    name="division"
                                    label="Divisi"
                                    value={formState.division}
                                    placeholder={"Pilih divisi"}
                                    error={!!error.division}
                                    helperText={error.division}
                                    onChange={handleChanged}
                                    fullWidth
                                >
                                    {divisionsOption.map(division => (
                                        <MenuItem value={division.value}>{division.label}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    label="Ketemuan"
                                    name="meeting_id"
                                    value={formState.meeting_id}
                                    onChange={handleChanged}
                                    onBlur={handleBlur}
                                    error={!!error.meeting_id}
                                    helperText={error.meeting_id}
                                >
                                    <MenuItem value={'0'} key={'0'} >Tidak Ketemuan</MenuItem>
                                    <MenuItem value={'1'} key={'1'} >Owner</MenuItem>
                                    <MenuItem value={'2'} key={'2'} >Tenant</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    id="notes"
                                    label="Catatan Pekerjaan"
                                    name="notes"
                                    type="text"
                                    value={formState.notes}
                                    multiline
                                    rows={4}
                                    onChange={handleChanged}
                                    variant="outlined"
                                    fullWidth
                                    style={{ marginBottom: '10px' }}
                                />
                            </Grid>
                            {

                            }
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <p>Pilih pekerjaan yang akan dilakukan</p>
                                {
                                    jobList.length && jobList.filter(job => !job.done_at).length ?
                                        jobList.filter(job => !job.done_at).map((data, idx) =>
                                            <Grid key={idx}>
                                                <Grid container style={{ alignItems: 'center' }}>
                                                    <div>
                                                        <Checkbox
                                                            checked={formState.jobActivity?.includes(data.id)}
                                                            onChange={() => { handleCheckbox(data.id) }}
                                                            color="primary"
                                                            style={{ padding: 0 }}
                                                        />
                                                    </div>
                                                    {
                                                        data.photos.length > 0 &&
                                                        <div>
                                                            <IconButton color="primary" onClick={() => {
                                                                setListJobFile({
                                                                    show: true,
                                                                    id: data.id.toString()
                                                                })
                                                            }}>
                                                                <ImageIcon />
                                                            </IconButton>
                                                        </div>
                                                    }
                                                    <span style={{ marginLeft: data.photos.length > 0 ? 'unset' : 12, paddingTop: 8, paddingBottom: 8, wordBreak: 'break-word', maxWidth: '75%' }}>{data.description}</span>
                                                </Grid>
                                                <NewImageSlider
                                                    open={listJobFile.show && (Number(listJobFile.id) === data.id)}
                                                    onClose={() => {
                                                        setListJobFile({
                                                            show: false,
                                                            id: ''
                                                        })
                                                    }}
                                                    img={data.photos.map((photo) => photo.photo_url)} />
                                            </Grid>
                                        )
                                        : <em style={{ fontSize: 14 }}>Tidak ada pekerjaan yang tersedia</em>
                                }
                                {
                                    error.jobActivity &&
                                    <p style={{ color: '#d32f2f' }}>Harap pilih pekerjaan yang dilakukan</p>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{ float: 'right' }}
                                    onClick={() => { handleSubmitSchedule() }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Root>
            </Dialog>
            <Root>
                <div className={classes.root}>
                    <LoadingScreen fullScreen open={isLoading} />
                    <ImagePreview
                        image={preview.url}
                        onClose={() => setPreview({ modal: false, url: '' })}
                    />
                    <Grid sx={{ pb: 6, mb: 2 }}>
                        <Typography variant='h5' sx={{ float: 'left' }}>Order Schedules</Typography>
                        {
                            props.permissions['order-jhs.admin'] &&
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                style={{ float: 'right' }}
                                onClick={() => { handleAddForm() }}
                            >
                                Add
                            </Button>
                        }
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            style={{ float: 'right', marginRight: 5 }}
                            onClick={() => setHideComplete(!hideComplete)}
                        >
                            {hideComplete ? 'Show ' : 'Hide '}
                            Complete Items
                            {hideComplete ? ` (${props.data.reduce((total, item) => total + (item.status === 1 ? 1 : 0), 0)})` : ''}
                        </Button>
                    </Grid>
                    <Grid>
                        <SimpleDataTable
                            customData={hideComplete ? props.data.filter((item) => item.status === 0) : props.data}
                            noCenter
                            reDraw={redrawDataTable}
                            columns={[
                                {
                                    name: 'date',
                                    label: 'Order Date',
                                    type: 'date',
                                    render: (data: any, row: any) => {
                                        return format(new Date(data), 'dd MMM yyyy')
                                    }
                                },
                                {
                                    name: 'division',
                                    label: 'Divisi',
                                    type: 'date',
                                    render: (data: any, row: any) => {
                                        return getDivisionLabel(data)
                                    }
                                },
                                {
                                    name: 'time',
                                    label: 'Waktu',
                                    type: 'string',
                                    style: { minWidth: '120px' },
                                    render: (data: any, row: any) => {
                                        return (
                                            data ? `${data.slice(0, data.length - 3)}` : '-'
                                        )
                                    }
                                },
                                {
                                    name: 'notes', label: 'Catatan Pekerjaan', render: (data: any, row: any) => {
                                        if (data) {
                                            return (
                                                <div className={classes.actionContainer}>
                                                    <IconButton
                                                        color="primary"
                                                        component="span"
                                                        onClick={() => { setScheduleNotes(data) }}
                                                    >
                                                        <NotesIcon />
                                                    </IconButton>
                                                </div>
                                            )
                                        }

                                        return <div style={{ width: 40, textAlign: 'center' }}>
                                            <span>-</span>
                                        </div>
                                    }
                                },
                                {
                                    name: 'jobs',
                                    label: 'Job Detail',
                                    render: (data: any, row: any) => {
                                        return (
                                            <div className={classes.actionContainer}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() => { handleJobListModal(true, data) }}
                                                >
                                                    <ListIcon fontSize="small" />
                                                </IconButton>
                                            </div>
                                        );
                                    }
                                },
                                {
                                    name: 'meeting_type',
                                    label: 'Ketemuan',
                                    render: (data: any, row: any) => {
                                        return data === 1 ? 'Owner' : data === 2 ? 'Tenant' : '-'
                                    }
                                },
                                {
                                    name: 'pic_name',
                                    label: 'JHS',
                                    render: (data: any, row: any) => {
                                        return (
                                            data ?
                                                <div>
                                                    <span>{data}</span>
                                                    <IconButton
                                                        color="primary"
                                                        component="span"
                                                        onClick={() => {
                                                            window.open(`https://wa.me/${row.pic_phone}`)
                                                        }}
                                                    >
                                                        <WhatsAppIcon fontSize="small" />
                                                    </IconButton>
                                                </div> :
                                                '-'
                                        )
                                    }
                                },
                                {
                                    name: 'status',
                                    label: 'Status',
                                    render: (data: any, row: any) => {
                                        return data === 1 ? 'Done' : 'On Process'
                                    }
                                },
                                {
                                    name: 'done_notes',
                                    label: 'Done Notes',
                                    render: (data: any, row: any) => {
                                        if (data) {
                                            return (
                                                <div className={classes.actionContainer}>
                                                    <IconButton
                                                        color="primary"
                                                        component="span"
                                                        onClick={() => { setScheduleNotes(data) }}
                                                    >
                                                        <NotesIcon />
                                                    </IconButton>
                                                </div>
                                            )
                                        }

                                        return <div style={{ width: 40, textAlign: 'center' }}>
                                            <span>-</span>
                                        </div>
                                    }
                                },
                                {
                                    name: 'EXTRA',
                                    label: 'Action',
                                    type: 'string',
                                    style: { textAlign: 'right', paddingRight: 26 },
                                    columnStyle: { display: 'flex', justifyContent: 'flex-end' },
                                    render: (row: any) => {
                                        return (
                                            row.status === 0 ?
                                                <div className={classes.actionContainer}>
                                                    {
                                                        props.permissions['order-jhs.admin'] &&
                                                        <>
                                                            <IconButton
                                                                color="primary"
                                                                component="span"
                                                                onClick={() => { handleEditSchedule(row) }}
                                                            >
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton
                                                                color="primary"
                                                                component="span"
                                                                onClick={() => { setNoteModal(row.id) }}
                                                            >
                                                                <CheckIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton
                                                                color="secondary"
                                                                component="span"
                                                                onClick={() => { handleDelete(row.id) }}
                                                            >
                                                                <DeleteIcon fontSize="small" />
                                                            </IconButton>
                                                        </>
                                                    }
                                                </div>
                                                :
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() => { handleFilesModal(true, row.id, row.files) }}
                                                >
                                                    <ImageIcon fontSize="small" />
                                                </IconButton>
                                        );
                                    }
                                }
                            ]}
                        />
                    </Grid >

                    <NotesModal
                        onClose={() => setNoteModal('')}
                        onSubmit={handleDone}
                        open={!!noteModal}
                    />

                    <Dialog
                        open={!!scheduleNotes}
                        onClose={() => setScheduleNotes('')}
                    >
                        <DialogContent>
                            <span style={{ wordBreak: 'break-word' }}>
                                {scheduleNotes}
                            </span>
                        </DialogContent>
                    </Dialog>
                </div >
            </Root>
        </>
    );
}

export default ScheduleList;

import React from 'react'
import { generateStyle } from '../../../../_utils/DefaultStyle'
import { Button, ButtonGroup, Chip, IconButton, Popover } from '@mui/material'

/**
 * Icons
 */
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ZoomOutMap } from '@mui/icons-material';

type Props = {
    index: number
    caption: string
    type: string
    containerStyle?: React.CSSProperties
    onChange: (name: string) => void
    onPreview: (src: string) => void
    src: string
    sorting?: boolean
    onSort?: (arrow: 'up' | 'down') => void
    disablePrev?: boolean
    disableNext?: boolean
    disableCoverOption?: boolean
    /**
     * Determine if the card is dragable or not, only affect the icons
     * 
     * The actual drag logic is on DndCard.tsx
     */
    dragable?: boolean
}

const useStyles = generateStyle(theme => ({
    imageContainer: {
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '10px',
        aspectRatio: '1/1',
        display: 'flex',
        cursor: 'pointer',
        '& .option-toggle': {
            transition: 'ease 150ms opacity',
            opacity: 0,
            pointerEvents: 'none',
            position: 'absolute',
            top: 7,
            right: 7,
            background: 'rgba(255,255,255,0.9)'
        },
        '&:hover .option-toggle, & .option-toggle.option-active': {
            opacity: 1,
            pointerEvents: 'auto',
        },
        '& .arrow-button': {
            width: 36,
            height: 36,
            borderRadius: '50%',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(255,255,255,0.9)',
            boxShadow: '0px 0px 5px rgba(0,0,0,0.25)'
        }
    },
    image: {
        objectFit: 'cover',
        objectPosition: 'center',
        width: '100%'
    },
    thumbnail: {
        position: 'absolute',
        top: 7,
        left: 7,
        background: 'rgba(255,255,255,0.9)',
        color: '#484848',
        fontWeight: 600,
        fontSize: 12,
    },
    overlay: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 'none',
        background: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1))',
        color: '#fff',
        borderRadius: '10px 10px 10px 10px',
        padding: '7px 10px',
        '& > p': {
            margin: 0
        }
    },
    sortContainer: {
        position: 'absolute',
        inset: 0,
        zIndex: 3,
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}), 'UnitImagePreview_Card')

const UnitImagePreviewCard = (props: Props) => {

    const { Root, classes } = useStyles()

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const onClickToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleCloseToggle = () => {
        setAnchorEl(null);
    };


    return (
        <Root>
            <div className={classes.imageContainer} style={{ ...props.containerStyle, cursor: props.sorting && props.dragable ? 'move' : undefined }}>
                <img
                    src={props.src}
                    alt={props.caption}
                    className={classes.image}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        props.onPreview(props.src)
                    }}
                />
                {
                    props.index === 0 ?
                        <Chip label='Foto Sampul' className={classes.thumbnail} />
                        : null
                }
                {
                    props.sorting ?
                        <div className={classes.sortContainer}>
                            {
                                (props.disablePrev || props.dragable) ?
                                    null :
                                    <div
                                        onClick={() => typeof props.onSort !== 'undefined' ? props.onSort('up') : {}}
                                        className={`arrow-button`}
                                    >
                                        <ChevronLeftIcon />
                                    </div>
                            }
                            {
                                (props.disableNext || props.dragable) ?
                                    null :
                                    <div
                                        onClick={() => typeof props.onSort !== 'undefined' ? props.onSort('down') : {}}
                                        className={`arrow-button`}
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        <ChevronRightIcon />
                                    </div>
                            }
                            {
                                props.dragable ?
                                    <div
                                        className={`arrow-button`}
                                        style={{ marginLeft: 'auto', marginRight: 'auto', transform: 'rotate(45deg)', cursor: 'move' }}
                                    >
                                        <ZoomOutMap />
                                    </div>
                                    : null
                            }
                        </div> :
                        null
                }
                <div className={classes.overlay}>
                    <p>{props.caption}</p>
                </div>
                {
                    !props.sorting ?
                        <IconButton
                            size='small'
                            id={`toggle-action-${props.index}`}
                            className={`option-toggle ${Boolean(anchorEl) && anchorEl?.id === `toggle-action-${props.index}` ? 'option-active' : ''}`}
                            onClick={onClickToggle}
                        >
                            <MoreHorizIcon />
                        </IconButton> :
                        null
                }
                <Popover
                    id={`popover-action-${props.index}`}
                    open={Boolean(anchorEl) && anchorEl?.id === `toggle-action-${props.index}`}
                    anchorEl={anchorEl}
                    onClose={handleCloseToggle}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <ButtonGroup size='small' orientation="vertical" aria-label="Vertical button group">
                        <Button color='inherit' variant='text' sx={{ px: 2, color: '#484848' }} style={{ textTransform: 'capitalize', justifyContent: "flex-start" }} onClick={() => [props.onChange('edit'), handleCloseToggle()]}>Edit</Button>
                        {props.disableCoverOption ? null : <Button color='inherit' variant='text' sx={{ px: 2, color: '#484848' }} style={{ textTransform: 'capitalize', justifyContent: "flex-start" }} onClick={() => [props.onChange('sampul'), handleCloseToggle()]}>Buat foto sampul</Button>}
                        <Button color='inherit' variant='text' sx={{ px: 2, color: '#484848' }} style={{ textTransform: 'capitalize', justifyContent: "flex-start" }} onClick={() => [props.onChange('delete'), handleCloseToggle()]}>Hapus</Button>
                    </ButtonGroup>
                </Popover>
            </div>
        </Root>
    )
}

export default UnitImagePreviewCard
import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

// Table
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

interface EnhancedTableProps {
    onRequestFilter: (filter: Record<string, any>) => void
    filterSearch: Record<string, any>
    headCells: any;
    filterable: boolean;
}

function FilterTableHeader(props: EnhancedTableProps) {
    const [filter, setFilter] = useState<Record<string, any>>({})
    const { onRequestFilter, headCells } = props;

    const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            onRequestFilter(filter)
        }, 300)

        return () => {
            clearTimeout(timeout)
        }

        // eslint-disable-next-line
    }, [filter])

    return (
        <TableRow>
            {headCells.map((headCell: any) => (
                <TableCell
                    key={headCell.name}
                    style={headCell.style}
                >
                    <TextField
                        name={headCell.name}
                        onChange={handleChangeFilter}
                        value={filter[headCell.name] || ''}
                        size='small'
                        disabled={!headCell.filterable}
                        style={!headCell.filterable ? { backgroundColor: "rgba(0, 0, 0, 0.12)" } : {}}
                        fullWidth
                    />
                </TableCell>
            ))}
        </TableRow>
    );
}

export default FilterTableHeader;
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, TextField } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import MuiDatePicker from '../../_components/MuiDatePicker';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { dateFormat, generalErrorHandler } from '../../_utils/Helper';

interface Props {
    open: boolean
    onClose: Function
    onSubmitSuccess: () => void,
    holidayId: string
}

interface IState {
    start_date: Date | null;
    end_date: Date | null;
    label: string;
}

interface IErrorState {
    start_date: string;
    end_date: string;
    label: string;
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HolidayForm = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/holiday';

    const initialState: IState = {
        start_date: null,
        end_date: null,
        label: ''
    }

    const initialErrorState = {
        start_date: '',
        end_date: '',
        label: ''
    }

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [isLoading, setIsLoading] = useState(false);
    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    useEffect(() => {
        setInputState({ name: '', value: '', type: 'RESET_ITEM' });
        if (props.holidayId !== '') {
            loadData();
        }
        // eslint-disable-next-line
    }, [props.open])

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${props.holidayId}`)
            .then(res => {
                const newState: any = { ...initialState };

                for (let [key, value] of Object.entries(res.data)) {
                    if (['start_date', 'end_date'].includes(key)) {
                        newState[key] = new Date(value as string);
                    } else {
                        newState[key] = value;
                    }
                }

                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;

        setInputState({ name: target.name, value: target.value, type: 'SET_ITEM' });
    }

    const onChangeDate = (date: Date | null, name: 'start_date' | 'end_date') => {
        setInputState({ name, value: date, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.start_date) {
            newError.start_date = 'Start Date wajib diisi';
            isValid = false;
        }

        if (!inputState.end_date) {
            newError.end_date = 'End Date wajib diisi';
            isValid = false;
        }

        if (!inputState.label) {
            newError.label = 'Label wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        const data: any = {};

        for (let [key, value] of Object.entries(inputState)) {
            if (['start_date', 'end_date'].includes(key)) {
                data[key] = dateFormat(value as Date, 'YYYY-MM-DD');
            } else {
                data[key] = value
            }
        }

        let axios;

        if (props.holidayId !== '') {
            axios = DefaultAxios.patch(`${API_URL}/${props.holidayId}`, data)
        } else {
            axios = DefaultAxios.post(API_URL, data);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: 'Submit Hari Libur Berhasil',
                    icon: 'success',
                    timer: 2000,
                    onAfterClose: () => {
                        props.onSubmitSuccess();
                    }
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            closeAfterTransition
            TransitionComponent={Transition}
            fullWidth
            maxWidth="lg"
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>Holiday Form</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <MuiDatePicker
                            margin="none"
                            label="Start Date"
                            value={inputState.start_date}
                            onChange={(date: Date | null) => onChangeDate(date, 'start_date')}
                            error={!!errorState.start_date}
                            helperText={errorState.start_date}
                            maxDate={inputState.end_date || undefined}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <MuiDatePicker
                            margin="none"
                            label="End Date"
                            value={inputState.end_date}
                            onChange={(date: Date | null) => onChangeDate(date, 'end_date')}
                            error={!!errorState.end_date}
                            helperText={errorState.end_date}
                            minDate={inputState.start_date || undefined}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Label"
                            name="label"
                            value={inputState.label}
                            onChange={handleChange}
                            helperText={errorState.label}
                            error={!!errorState.label}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default HolidayForm;
import React from 'react';
import DataTable from '../../_components/_dataTable/DataTable';

const UnitMarketplaceLogList = () => {
    const columns = [
        {
            name: 'code',
            label: 'Unit Code',
            type: 'string',
            filterable: false,
            sortable: false
        },
        {
            name: 'type',
            label: 'Uploaded To',
            type: 'string',
            filterable: false,
            sortable: false
        },
        {
            name: 'status',
            label: 'Status',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (data: number) => {
                return data === 1 ? 'Success' : (data === 2 ? 'Failed' : 'On Process');
            }
        },
        {
            name: 'counter_fail',
            label: 'Fail Counter',
            type: 'string',
            filterable: false,
            sortable: false
        }
    ];

    return (
        <DataTable 
            url={`${process.env.REACT_APP_API_URL}/unit-marketplace`}
            columns={columns}
        />
    );
}

export default UnitMarketplaceLogList;
import React, { useEffect, useState, useReducer } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    IconButton,
    Grid,
} from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../_utils/Helper';

/**
 * Icons
 */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface IProp {
    data: any;
    open: boolean;
    onClose?: any;
}

interface IState {
    assigned_name: string;
    assigned_id: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const StaffFormModal = (props: IProp) => {
    const [isLoading, setIsLoading] = useState(false);

    const initialState = {
        assigned_name: props.data.assigned_name || '',
        assigned_id: props.data.assigned_id || '',
    }

    useEffect(() => {
        setInputState({ name: 'reset', value: 'reset', type: 'RESET_ITEM' });
    }, [props.open]);

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };
    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    const handleSubmit = () => {
        setIsLoading(true);
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/assign`, { id: props.data.id, user_id: inputState.assigned_id })
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    timer: 1000,
                    onAfterClose: () => {
                        props.onClose(true);
                    }
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const handleAutocompletePic = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.assigned_id = String(value.id);
        newState.assigned_name = value.label;
        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog 
                fullWidth
                maxWidth="sm"
                open={props.open} 
                onClose={() => props.onClose(false)} 
                aria-labelledby="form-dialog-title" 
                closeAfterTransition
            >
                <DialogTitle id="form-dialog-title">
                    <IconButton
                        sx={{left: -10}}
                        onClick={() => props.onClose(false)}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    JHS Staff
                </DialogTitle>
                <DialogContent>
                    <Grid container justifyContent="space-between" spacing={2}>
                        <Grid item xs={12} sx={{mt: 1}}>
                            <AsyncAutoComplete
                                label="JHS"
                                name="extend_pic_id"
                                initialQuery={inputState.assigned_name}
                                onChange={handleAutocompletePic}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/user/jhs`}
                                placeholder="JHS Staff"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default StaffFormModal;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, IconButton, Chip } from '@mui/material';

/**
 * Components
 */
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';
import SimplePreview from '../../_components/SimplePreview';

/**
 * Icon
 */
 import VisibilityIcon from '@mui/icons-material/Visibility';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, numberToCurrency, renderWarningButton } from '../../_utils/Helper';
import Swal from 'sweetalert2';
import moment from 'moment';
import './BookingDetail.css'




interface Props {
    open: boolean;
    permissions: any;
    onCloseModal: () => void
}

interface IBookingDetail {
    id: number
    status: '' | '0' | '1' | '2'
    start_date: string
    duration: number
    unique_code: string
    name: string
    phone: string
    email: string
    address: string
    ktp_number: string
    // unit_id: number
    // unit_code: string
    payment_proof_filepath: string
    unit: {
        code: string
    }
    expired_at: string
    account: {
        email: string
        phone: string
    }
    total: string
}

interface IParams extends Record<string, string | undefined> {
    id?: string
}

const BookingDetail = ({ permissions, open, onCloseModal }: Props) => {
    const params = useParams<IParams>();

    const [data, setData] = useState<IBookingDetail>({
        id: 0,
        status: '0',
        start_date: '',
        duration: 0,
        unique_code: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        ktp_number: '',
        // unit_id: 0,
        // unit_code: '',
        unit: {
            code: ''
        },
        payment_proof_filepath: 'https://via.placeholder.com/3000x1500',
        expired_at: '',
        account: {
            email: '',
            phone: ''
        },
        total: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)

    useEffect(() => {
        if (params.id) {
            // loadChart(params.id)
            loadBooking();
        }
        // eslint-disable-next-line
    }, [params.id]);

    const loadBooking = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/bookings/${params.id}`)
            .then(res => {
                setData(res.data);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const resetExpiredBooking = () => {
        renderWarningButton("Apakah anda yakin ingin mereset expired booking?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/bookings/reset-expired`, { id: params.id })
                    .then(() => {
                        Swal.fire({
                            title: "Booking Tereset",
                            icon: 'success',
                            timer: 1000
                        })

                        loadBooking()
                    })
                    .catch(err => {
                        generalErrorHandler(err);
                        setIsLoading(false);
                    })
            }
        })
    }

    const confirmClosing = () => {
        renderWarningButton("Apakah anda yakin ingin confirm booking?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/bookings/confirm`, { id: params.id })
                    .then(() => {
                        Swal.fire({
                            title: "Booking Confirmed",
                            icon: 'success',
                            timer: 1000
                        })

                        loadBooking()
                    })
                    .catch(err => {
                        generalErrorHandler(err);
                        setIsLoading(false);
                    })
            }
        })
    }

    const cancelClosing = () => {
        renderWarningButton("Apakah anda yakin ingin mereset cancel booking?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/bookings/cancel`, { id: params.id })
                    .then(() => {
                        Swal.fire({
                            title: "Booking Canceled",
                            icon: 'success',
                            timer: 1000
                        })

                        loadBooking()
                    })
                    .catch(err => {
                        generalErrorHandler(err);
                        setIsLoading(false);
                    })
            }
        })
    }

    const renderWa = (data: string) => {
        let regex = /^08/;
        if (data.match(regex)) {
            data = data.replace(regex, "628")
        }
        let phoneNumber = data;
        let link = 'https://api.whatsapp.com/send?phone=' + phoneNumber;
        return <a href={link}>{data}</a>
    }

    const getStatusChip = (status: '0' | '1' | '2', date: string) => {
        let statusLabel = "[UNDEFINED]";
        let chipColor: "primary" | "secondary" | "default" = "default";
        switch (status.toString()) {
            case '0':
                const parsedDate = date ? new Date(date) : null
                if (parsedDate && (Date.now() > parsedDate.getTime())) {
                    statusLabel = "Expired"
                }
                statusLabel = "On Process"
                break;
            case '1':
                statusLabel = "Confirmed";
                chipColor = "primary";
                break;
            case '2':
                statusLabel = "Canceled";
                chipColor = "secondary";
                break;
        }

        if (chipColor === "default") {
            return <Chip label={statusLabel} />
        } else {
            return <Chip color={chipColor} label={statusLabel} />
        }
    }

    return (
        <>
            <SimplePreview
                isPreviewOpen={isPreviewOpen}
                url={data.payment_proof_filepath}
                onClose={() => setIsPreviewOpen(false)}
            />
            <ModalDetail
                open={open}
                title="Booking Detail"
                onCloseModal={onCloseModal}
                isLoading={isLoading}
                showEditButton={true}
                editButtonLink={`/booking/edit/${data.id}`}
            >
                <>
                    <ModalSection
                        xs={12}
                        md={6}
                        title="Informasi Booking"
                        content={[
                            ['Status', data.status !== null ? getStatusChip(data.status as '0' | '1' | '2', data.expired_at) : '-'],
                            ['Start Date', moment(data.start_date).format("DD-MM-YYYY HH:mm")],
                            ['Expired Date', data.expired_at ? moment(data.expired_at).format("DD-MM-YYYY HH:mm") : '-'],
                            ['Duration', `${data.duration} Month${data.duration > 1 ? 's' : ''}`],
                            // ['Unique Code', data.unique_code],
                            ['Unit Code', <>{data.unit.code}<IconButton
                                color="primary"
                                size="small"
                                onClick={() => { window.open(`/unit/${data.unit.code}`) }}
                            >
                                <VisibilityIcon />
                            </IconButton></>],
                            ['Total', <span>Rp {numberToCurrency(data.total)}</span>],
                        ]}
                    />
                    <ModalSection
                        xs={12}
                        md={6}
                        title="Informasi Customer"
                        content={[
                            ['Name', data.name],
                            ['Phone', renderWa(data.account.phone)],
                            ['Email', data.account.email],
                            ['Address', data.address],
                            ['KTP', data.ktp_number],
                        ]}
                    />
                    <ModalSection
                        xs={12}
                        md={6}
                        title="Bukti Pembayaran"
                        content={[]}
                        additionalContent={(
                            data.payment_proof_filepath ?
                                <div onClick={() => setIsPreviewOpen(true)}>
                                    <img
                                        src={data.payment_proof_filepath}
                                        alt="bukti pembayaran"
                                        height="250"
                                        width="250"
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                        }}
                                    />
                                </div>
                                :
                                <p>
                                    Belum ada bukti pembayaran
                                </p>
                        )}
                    />
                    <Grid item xs={12}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            {
                                data.status.toString() === '0' ?
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => resetExpiredBooking()}
                                    >
                                        Extend Expired Booking
                                    </Button>
                                    :
                                    null
                            }
                            {
                                (data.status.toString() !== '2' && data.status.toString() !== '1') ?
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => confirmClosing()}
                                        style={{
                                            marginLeft: 12,
                                        }}
                                    >
                                        Confirm Booking
                                    </Button>
                                    :
                                    null
                            }
                            {
                                (data.status.toString() !== '2' && data.status.toString() !== '1') ?
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        onClick={() => cancelClosing()}
                                        style={{
                                            marginLeft: 12,
                                        }}
                                    >
                                        Cancel Booking
                                    </Button>
                                    :
                                    null
                            }
                        </div>
                    </Grid>
                </>
            </ModalDetail>
        </>
    );
}

export default BookingDetail;
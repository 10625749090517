import React, { useEffect, useState } from 'react';

import ModalContainer from '../../_components/_modal/ModalContainer'
import { Grid } from '@mui/material';
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertNumber } from '../../_utils/Helper'

interface IState {
  name: string
  code: string
  furnished: string
  view_direction: string
  unit_area: string
  bedroom: number
  bathroom: number
  is_dollar: number
  rent_url?: string
  sell_url?: string
  price_per_1_month?: string | null
  price_per_1_month_in_dollar?: string | null
  price_per_3_months?: string | null
  price_per_6_months?: string | null
  price_per_12_months?: string | null
  selling_price?: string | null
}

interface IProps {
  id: string
  isOpen: boolean
  handleClose: Function
}

const initialState = {
  name: '',
  code: '',
  furnished: '',
  view_direction: '',
  unit_area: '',
  bedroom: 0,
  bathroom: 0,
  is_dollar: 0,
  rent_url: '',
  sell_url: '',
  price_per_1_month: null,
  price_per_1_month_in_dollar: null,
  price_per_3_months: null,
  price_per_6_months: null,
  price_per_12_months: null,
  selling_price: null,
}

const ManageUnitModal = (props: IProps) => {
  const [state, setState] = useState<IState>(initialState)

  useEffect(() => {
    // TODO: fetch data dari API
    const url = process.env.REACT_APP_API_URL + '/unit-manage/' + props.id
    DefaultAxios.get(url)
      .then(res => res.data)
      .then(res => {
        setState(res)
      })
      .catch(err => {
        console.log(err)
      })
  }, [props.id])

  useEffect(() => {
    if (props.isOpen) {
      setState(initialState)
    }
  }, [props.isOpen])

  return (
    <>
      <ModalContainer
        isOpen={props.isOpen}
        handleClose={() => props.handleClose()}
        title="Detail Unit"
      >
        <div>
          {/* Name */}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              Name
            </Grid>
            <Grid item xs={7}>
              {state.name}
            </Grid>
          </Grid>
          {/* Code */}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              Code
            </Grid>
            <Grid item xs={7}>
              {state.code}
            </Grid>
          </Grid>
          {/* Furnished */}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              Furnished
            </Grid>
            <Grid item xs={7}>
              {state.furnished}
            </Grid>
          </Grid>
          {/* View Direction */}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              View Direction
            </Grid>
            <Grid item xs={7}>
              {state.view_direction}
            </Grid>
          </Grid>
          {/* Unit Area */}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              Unit Area
            </Grid>
            <Grid item xs={7}>
              {state.unit_area} m<sup>2</sup>
            </Grid>
          </Grid>
          {/* Bedroom */}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              Bedroom
            </Grid>
            <Grid item xs={7}>
              {state.bedroom === 0 ? 'Studio' : state.bedroom}
            </Grid>
          </Grid>
          {/* Name */}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              Bathroom
            </Grid>
            <Grid item xs={7}>
              {state.bathroom}
            </Grid>
          </Grid>
          {/* Prices */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h4>Daftar Harga</h4>
            </Grid>
          </Grid>
          {/* Selling Price */}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              Selling Price
            </Grid>
            <Grid item xs={7}>
              {(state.selling_price && state.selling_price !== '0.00') ? 'IDR ' + convertNumber(state.selling_price, '', true) : '-'}
            </Grid>
          </Grid>
          {/* 1 Month */}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              Price Per 1 Month
            </Grid>
            <Grid item xs={7}>
              {(state.price_per_1_month_in_dollar && state.price_per_1_month_in_dollar !== '0.00') ? 'USD ' + convertNumber(state.price_per_1_month_in_dollar!, '', true) : (state.price_per_1_month && state.price_per_1_month !== '0.00') ? 'IDR ' + convertNumber(state.price_per_1_month!, '', true) : '-'}
            </Grid>
          </Grid>
          {/* 3 Month */}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              Price Per 3 Months
            </Grid>
            <Grid item xs={7}>
              {(state.price_per_3_months && state.price_per_3_months !== '0.00') ? 'IDR ' + convertNumber(state.price_per_3_months, '', true) : '-'}
            </Grid>
          </Grid>
          {/* 6 Month */}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              Price Per 6 Months
            </Grid>
            <Grid item xs={7}>
              {(state.price_per_6_months && state.price_per_6_months !== '0.00') ? 'IDR ' + convertNumber(state.price_per_6_months, '', true) : '-'}
            </Grid>
          </Grid>
          {/* 12 Month */}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              Price Per 12 Months
            </Grid>
            <Grid item xs={7}>
              {(state.price_per_12_months && state.price_per_12_months !== '0.00') ? 'IDR ' + convertNumber(state.price_per_12_months, '', true) : '-'}
            </Grid>
          </Grid>
          {/* URL */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h4>Link</h4>
            </Grid>
          </Grid>
          {/* Rent url */}
          {
            state.rent_url && state.rent_url !== '' ?
            <Grid container spacing={2}>
              <Grid item xs={5}>
                Rent URL
              </Grid>
              <Grid item xs={7}>
                <a href={state.rent_url} target="__blank">{state.rent_url}</a>
              </Grid>
            </Grid>
            : null
          }
          {/* Sell url */}
          {
            state.sell_url && state.sell_url !== '' ?
            <Grid container spacing={2}>
              <Grid item xs={5}>
                Sell URL
              </Grid>
              <Grid item xs={7}>
                <a href={state.sell_url} target="__blank">{state.sell_url}</a>
              </Grid>
            </Grid>
            : null
          }
        </div>
      </ModalContainer>
    </>
  );
}

export default ManageUnitModal
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Theme,
    Link,
    IconButton,
    Tabs,
    Tab
} from '@mui/material';
import { format } from 'date-fns';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import { convertIndonesiaPhoneNumber, openExternalLink } from '../../_utils/Helper';
import { initialRequestType } from './JhsOrderForm';
import { generateStyle } from '../../_utils/DefaultStyle';
import { PAID_BY_OPTIONS } from '../../_utils/ConstData';

/**
 * Utils
 */

interface IProps {
    id?: string
}
interface IJHSList {
    id: number;
    status: string;
    billing_nominal: number | null;
    invoice_code: null | string;
    code: string;
    ticket_id: null;
    closing_deposit_id: null;
    work_code: null | string;
    work_type: string;
    closing_id: null | string;
    crm_id: null;
    datetime: null;
    request_date: Date;
    request_time: string;
    checkin_at: Date | null;
    date: null;
    time: null;
    activity: string;
    requester_id: number;
    assigner_id: number | null;
    pic_id: number | null;
    paid_by: string;
    pic_name: null | string;
    apartment_id: string;
    unit_id: number;
    latitude: null;
    longitude: null;
    package_duration: null;
    key_collection_point: string;
    notes: string;
    old_information: null;
    inventory_notes: null;
    selfie: null;
    billing_invoice_file: null | string;
    payment_invoice_file: null | string;
    created_at: Date;
    updated_at: Date;
    deleted_at: null;
    verified_at: Date | null;
    canceled_at: null;
    canceled_by: null;
    canceled_reason: null;
    created_by: string;
    modified_by: string;
    deleted_by: null;
    deleted_reason: null;
    verified_by: number | null;
    is_finished: number;
    reminder_notification_flag: number;
    is_reimbursement_transport_done: number;
    group_id: null;
    created_by_phone: string;
    closing_code: null | string;
    unit_code: string;
    apartment_name: null | string;
    client_name: null | string;
    requester_name: string;
    requester_phone: string;
    assigner_name: string | null;
    status_label: string;
    billing_invoice_file_url: null | string;
    payment_invoice_file_url: null | string;
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    actionContainer: {
        display: 'flex'
    },
    tabContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '1px solid #ddd',
        '& .DateInput_input__focused': {
            borderBottom: '0',
        },
        '& .date-container': {
            width: '25%',
            display: 'flex',
            alignItems: 'center',
        },
        '& .extend-status': {
            marginLeft: 'auto',
            padding: '0px 16px',
            borderLeft: '1px solid #ddd',
            fontSize: 12,
            display: 'flex',
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            '& p span': {
                color: 'red'
            }
        },
    },
}), "JHSOrder_List"
)

const JhsOrderList = (props: IProps) => {
    const { Root, classes } = useStyles();
    const navigate = useNavigate();
    const [isPersonal, setIsPersonal] = useState(0)
    const [role, setRole] = useState('')
    const params = useParams()

    const handleWa = (row: IJHSList) => {
        if (row.status === 'unpaid' && row.activity === 'fixing_cleaning') {
            return openExternalLink(`https://api.whatsapp.com/send/?phone=${row.created_by_phone}&text=Halo+kak.+Mau+mengingatkan+kembali+bahwa+tagihan+JHS+${row.work_code}+belum+dibayarkan+ya.+Mohon+untuk+upload+bukti+bayar+jika+sudah+dibayarkan%0A%0A${row.billing_invoice_file_url}&type=phone_number&app_absent=0`)
        }

        openExternalLink(`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(row.requester_phone)}`);
    }

    // const handleDoneStatus = (id: string) => {
    //     renderWarningButton("Apakah anda yakin ingin menyelesaikan order ini?")
    //         .then((result) => {
    //             if (result.value) {
    //                 DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/${id}/verify`)
    //                     .then(res => {
    //                         renderSuccessButton('Berhasil menyelesaikan order')
    //                     })
    //                     .catch(error => {
    //                         generalErrorHandler(error)
    //                     })
    //                     .finally(() => {
    //                         setRedrawCounter(redrawCounter + 1);
    //                     })
    //             }
    //         });
    // }

    // const handleQueryParams = (queryString: string) => {
    //     if (typeof params.id === "undefined") {
    //         setQueryParams((prev: any) => {
    //             localStorage.setItem('queryParams', queryString)
    //             return queryString
    //         })
    //     }
    // }

    const handleTabChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
        setIsPersonal(value)
    }

    const renderTabTable = () => {
        return (
            <Root>
                <div className={classes.tabContainer}>
                    <Tabs
                        value={isPersonal}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                    >
                        <Tab label="My Order" value={1} />
                        <Tab label="All Order" value={0} />
                    </Tabs>
                    <div className="extend-status">

                    </div>
                </div>
            </Root>
        )
    }

    useEffect(() => {
        let localRole = window.localStorage.getItem('role')
        if (localRole) {
            setRole(localRole)

            if (localRole === 'tenant-relation') setIsPersonal(1)
        }

        return () => {
            setRole(localStorage.getItem('role') || '')
        }
    }, [])

    return (
        <Root>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => navigate('/jhs-order/add')}
            >
                Add JHS Order
            </Button>
            <DataTable
                disableFetch={typeof params.id !== "undefined"}
                url={`${process.env.REACT_APP_API_URL}/activity/jhs`}
                customParams={{ is_personal: isPersonal }}
                // setParentQueryString={(queryString: any) => handleQueryParams(queryString)}
                tab={role === 'tenant-relation' ? renderTabTable() : null}
                columns={[
                    {
                        name: 'created_at',
                        label: 'Order Date',
                        type: 'date',
                        filterable: true,
                        render: (data: any, row: any) => {
                            return format(new Date(data), 'dd MMM yyyy')
                        }
                    },
                    {
                        name: 'code',
                        label: 'Code',
                        type: 'string',
                        filterable: true,
                        render: (data: any, row: any) => {
                            return (
                                <Link
                                    style={{ cursor: "pointer" }}
                                    component={RouterLink}
                                    to={`/jhs-order/${row.id}`}
                                >
                                    {data ? data : '[KLIK]'}
                                </Link>
                            )
                        }
                    },
                    {
                        name: 'activity',
                        label: 'Request',
                        filterable: true,
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            ...initialRequestType.map(activity => ({
                                key: activity.key,
                                value: activity.label
                            }))
                        ],
                        defaultOption: '',
                        render: (data: any, row: any) => {
                            if (Object.keys(initialRequestType.reduce((obj: any, item) => Object.assign(obj, { [item.key as string]: item.label }), {})).includes(data)) {
                                return initialRequestType.reduce((obj: any, item) => Object.assign(obj, { [item.key as string]: item.label }), {})[data]
                            }
                            return "-"
                        }
                    },
                    { name: 'invoice_code', label: 'Invoice Code', filterable: true, render: (data: string) => data ? data : '-' },
                    { name: 'closing_code', label: 'Closing Code', filterable: true, render: (data: string) => data ? data : '-' },
                    { name: 'apartment_name', label: 'Apartment', filterable: true },
                    { name: 'unit_code', label: 'Unit Code', filterable: true },
                    {
                        name: 'request_date',
                        label: 'Request Work Date',
                        type: 'date',
                        filterable: true,
                        render: (data: any, row: any) => {
                            return !data ? '-' : format(new Date(data), 'dd MMM yyyy')
                        }
                    },
                    {
                        name: 'request_time',
                        label: 'Request Work Time',
                        type: 'string',
                        filterable: false,
                        sortable: false,
                        style: { minWidth: '120px' },
                        render: (data: any, row: any) => {
                            return (
                                data ? `${data.slice(0, data.length - 3)}` : '-'
                            )
                        }
                    },
                    // { name: 'apartment', label: 'Requester', filterable: true },
                    ...(
                        isPersonal !== 1 ?
                            [
                                {
                                    name: 'requester_name', label: 'Requester', filterable: true, render: (data: any, row: any) => {
                                        return data;
                                    }
                                },
                            ]
                            : []
                    ),
                    {
                        name: 'paid_by',
                        label: 'Paid By',
                        filterable: true,
                        options: PAID_BY_OPTIONS,
                        defaultOption: '',
                        render: (data: any, row: any) => {
                            return (
                                PAID_BY_OPTIONS.filter(option => option.key === data)[0]?.value || '-'
                            );
                        }
                    },
                    {
                        name: 'status_label',
                        label: 'Status',
                        filterable: true,
                        options: [
                            { key: '', value: 'All', },
                            { value: 'New', key: 'new' },
                            { value: 'On Process', key: 'on_process' },
                            { value: 'Unpaid', key: 'unpaid' },
                            { value: 'Done', key: 'done' },
                            { value: 'Cancel', key: 'cancel' },

                        ],
                        defaultOption: '',
                        render: (data: any, row: any) => {
                            return (
                                row.status_label
                            );
                        }
                    },
                    {
                        name: 'verified_date',
                        label: 'Done Date',
                        type: 'date',
                        filterable: true,
                        render: (data: any, row: any) => {
                            return data ? format(new Date(data), 'dd MMM yyyy') : '-'
                        }
                    },
                    {
                        name: 'EXTRA',
                        label: 'Action',
                        type: 'string',
                        filterable: false,
                        sortable: false,
                        render: (row: any) => {
                            return (
                                <div className={classes.actionContainer}>
                                    <IconButton
                                        color="success"
                                        component="span"
                                        title='Whatsapp Requester'
                                        onClick={() => { handleWa(row) }}
                                    >
                                        <WhatsappIcon />
                                    </IconButton>
                                    {/* {
                                        row.status_label === 'On Process' &&
                                        <IconButton
                                            color="primary"
                                            component="span"
                                            onClick={() => { handleDoneStatus(row.id) }}
                                        >
                                            <CheckIcon fontSize="small" />
                                        </IconButton>
                                    } */}
                                </div>
                            );
                        }
                    }
                ]}
            />
        </Root>
    );
}

export default JhsOrderList;
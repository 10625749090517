import React, { useEffect, useState, useReducer } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
    TextField,
    Typography,
    Switch
} from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../../../_components/LoadingScreen';
import { useStyles } from '../../ClosingForm';

/**
 * Utils
 */
import DefaultAxios from '../../../../_utils/DefaultAxios';
import { generalErrorHandler, numberToCurrency, isValidEmail } from '../../../../_utils/Helper';

interface IProps {
    closingId: string;
    digitalContractData: any;
    open: boolean;
    onClose: (fetch: boolean) => void;
}

interface IState {
    is_english: boolean;
    is_doc_owner_custom: boolean;
    custom_owner_email: string;
    custom_owner_deposit: string;
    custom_owner_deposit_percentage: string;
    is_doc_customer_custom: boolean;
    custom_customer_email: string;
    custom_customer_deposit: string;
}

interface IErrorState {
    custom_owner_email: string;
    custom_customer_email: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const PartialDocusignForm = (props: IProps) => {
    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(false);

    const initialState = {
        is_english: false,
        is_doc_owner_custom: false,
        custom_owner_email: '',
        custom_owner_deposit: '',
        custom_owner_deposit_percentage: '',
        is_doc_customer_custom: false,
        custom_customer_email: '',
        custom_customer_deposit: ''
    }

    const initialErrorState = {
        custom_owner_email: '',
        custom_customer_email: '',
    }

    const currencyAttribute = [
        'custom_owner_deposit',
        'custom_customer_deposit'
    ];

    useEffect(() => {
        let state;
        if (typeof props.digitalContractData !== 'undefined') {
            state = {
                is_english: props.digitalContractData.is_english,
                is_doc_owner_custom: props.digitalContractData.is_doc_owner_custom,
                custom_owner_email: props.digitalContractData.custom_owner_email,
                custom_owner_deposit: props.digitalContractData.custom_owner_deposit,
                custom_owner_deposit_percentage: props.digitalContractData.custom_owner_deposit_percentage,
                is_doc_customer_custom: props.digitalContractData.is_doc_customer_custom,
                custom_customer_email: props.digitalContractData.custom_customer_email,
                custom_customer_deposit: props.digitalContractData.custom_customer_deposit,
            };
        } else {
            state = { ...initialState };
        }

        setInputState({ name: 'reset', value: state, type: 'REPLACE_STATE' });
        setErrorState({ name: 'reset', value: 'reset', type: 'RESET_ITEM' });

        // eslint-disable-next-line
    }, [props.open]);

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            if (currencyAttribute.includes(action.name)) {
                return {
                    ...state,
                    [action.name]: action.value.replace(/,/g, '')
                }
            } else if (action.name === "custom_owner_deposit_percentage") {
                let number = action.value.replace(/,/g, '');

                if (number > 100) {
                    number = 100;
                }

                return {
                    ...state,
                    [action.name]: number
                }
            }

            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };
    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let value: any = target.value;
        let name = target.name;

        if (target.type === 'checkbox') {
            value = target.checked;
        }

        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleDocOwnerSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const oldState = { ...inputState };
        oldState.is_doc_owner_custom = event.target.checked;
        setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
    };

    const handleDocCustomerSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const oldState = { ...inputState };
        oldState.is_doc_customer_custom = event.target.checked;
        setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
    };

    const checkValidation = () => {
        let isValid = true
        const errorState = { ...initialErrorState };

        if (inputState.custom_owner_email && !isValidEmail(inputState.custom_owner_email)) {
            errorState.custom_owner_email = 'Format email tidak valid';
            isValid = false;
        }

        if (inputState.custom_customer_email && !isValidEmail(inputState.custom_customer_email)) {
            errorState.custom_customer_email = 'Format email tidak valid';
            isValid = false;
        }

        setErrorState({ name: '', value: errorState, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = (id: any) => {
        if (checkValidation() && id) {
            const data: any = { ...inputState };

            for (let key in data) {
                if (currencyAttribute.includes(key)) {
                    data[key] = Number(data[key]) > 0 ? Number(data[key]) : null;
                }
            }

            setIsLoading(true);
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/${props.closingId}/partial-docusign-update`, data)
                .then(res => {
                    Swal.fire({
                        title: "Submit data berhasil",
                        icon: 'success',
                        timer: 1000
                    });
                    props.onClose(true)
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    };

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog maxWidth="md" fullWidth open={props.open} onClose={() => props.onClose(false)} aria-labelledby="form-dialog-title" closeAfterTransition>
                <Root>
                    <DialogTitle id="form-dialog-title">Partial Docusign Form</DialogTitle>
                    <DialogContent>
                        <Grid container justifyContent="space-between" spacing={2}>
                            <Grid item xs={12}>
                                <div className={classes.detailsContainer}>
                                    <Typography className={classes.subtitle}>
                                        Owner
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item xs={12} className={classes.switchContainer}>
                                <Typography className={classes.digitalContractSubtitle}>
                                    Custom Document Owner
                                </Typography>
                                <Switch
                                    color="primary"
                                    value={true}
                                    checked={inputState.is_doc_owner_custom}
                                    onChange={handleDocOwnerSwitch}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                            </Grid>

                            {
                                inputState.is_doc_owner_custom &&
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ textAlign: "center" }}>
                                        Silahkan Upload file PDF di Menu Upload File dan upload file tipe <br /> "Custom Contract Owner"
                                    </Typography>
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Custom Email"
                                    name="custom_owner_email"
                                    value={inputState.custom_owner_email}
                                    onChange={handleChanged}
                                    error={!!errorState.custom_owner_email}
                                    helperText={errorState.custom_owner_email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Custom Deposit"
                                    name="custom_owner_deposit"
                                    value={inputState.custom_owner_deposit ? numberToCurrency(Number(inputState.custom_owner_deposit)) : ""}
                                    onChange={handleChanged}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Deposit Percentage Owner"
                                    name="custom_owner_deposit_percentage"
                                    value={inputState.custom_owner_deposit_percentage ? inputState.custom_owner_deposit_percentage : ""}
                                    onChange={handleChanged}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <div className={classes.detailsContainer}>
                                    <Typography className={classes.subtitle}>
                                        Customer
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item xs={12} className={classes.switchContainer}>
                                <Typography className={classes.subtitle}>
                                    English Contract Tenant
                                </Typography>
                                <Switch
                                    color="primary"
                                    name="is_english"
                                    value={true}
                                    checked={inputState.is_english}
                                    onChange={handleChanged}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.switchContainer}>
                                <Typography className={classes.digitalContractSubtitle}>
                                    Custom Document Customer
                                </Typography>
                                <Switch
                                    color="primary"
                                    value={true}
                                    checked={inputState.is_doc_customer_custom}
                                    onChange={handleDocCustomerSwitch}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                            </Grid>

                            {
                                inputState.is_doc_customer_custom &&

                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ textAlign: "center" }}>
                                        Silahkan Upload file PDF di Menu Upload File dan upload file tipe  <br /> "Custom Contract Tenant"
                                    </Typography>
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Custom Email"
                                    name="custom_customer_email"
                                    value={inputState.custom_customer_email}
                                    onChange={handleChanged}
                                    error={!!errorState.custom_customer_email}
                                    helperText={errorState.custom_customer_email}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Custom Deposit"
                                name="custom_customer_deposit"
                                value={inputState.custom_customer_deposit !== "0" ? numberToCurrency(Number(inputState.custom_customer_deposit)) : ""}
                                onChange={handleChanged}
                            />
                        </Grid> */}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => props.onClose(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleSubmit(props.closingId)
                            }}
                            color="primary"
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Root>
            </Dialog>
        </Root>
    )
}

export default PartialDocusignForm;
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Box, Card, CardContent, Grid, Tabs, Tab, Theme, Dialog, Slide, Switch, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
// Components
import Stay360PmsDetail from './Stay360PmsDetail';
import DefaultAxios from '../../_utils/DefaultAxios';
import LoadingScreen from '../../_components/LoadingScreen';
import moment from 'moment';
// Icons
import CloseIcon from '@mui/icons-material/Close';
import { generateStyle } from '../../_utils/DefaultStyle';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface IProps {
    location_id?: any
    is_apartment?: any
}

interface IParams extends Record<string, string | undefined> {
    location_id?: any
    is_apartment?: any
    label?: any
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    submitButton: {
        marginBottom: '20px'
    },
    cardRoot: {
        margin: '0 10rem',
        padding: '0 1rem',
        color: 'rgba(0, 0, 0, 0.87)',
        [theme.breakpoints.down('sm')]: {
            margin: '0 0',
            border: 'none',
            padding: '0 1rem',
            color: 'rgba(0, 0, 0, 0.87)',
        },
    },
    cardActRoot: {
        display: 'inherit',
    },
    tabsRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabsContent: {
        '& .MuiTabs-centered': {
            justifyContent: 'space-around'
        },
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(2, 2),
        marginTop: theme.spacing(2),
    },
    paperTab: {
        margin: "0"
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
    paper_label: {
        position: "absolute",
        marginTop: "-45px",
        padding: "1px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)"
    },
    paper_label_text: {
        backgroundColor: "#0094ff",
        fontSize: "1.5em",
        padding: "13px",
        borderRadius: "4px",
        color: "white"
    },
}), "Stay360PmsList"
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Stay360PmsHome = (props?: IProps) => {
    const [value, setValue] = React.useState(0);
    const { Root, classes } = useStyles();
    const params = useParams<IParams>()
    const [loading, setLoading] = useState(false);
    const [selectedIsCheckedIn, setSelectedIsCheckedIn] = useState<number>(0);
    const [selectedIsCheckedOut, setSelectedIsCheckedOut] = useState<number>(0);
    const [selectedIsPaySpot, setSelectedIsPaySpot] = useState<number>(0);
    const [selectedId, setSelectedId] = useState<string>('');
    const [selectedName, setSelectedName] = useState<string>('');
    const [payOn, setPayOn] = useState<number>(0);
    const [checkedIn, setCheckedIn] = useState<number>(0);
    const [checkedOut, setCheckedOut] = useState<number>(0);
    const [isCheckIn, setIsCheckIn] = useState(true);
    const [modalState, setModalState] = useState(false);
    const handleOpen = () => setModalState(true);
    const handleClose = () => {
        // setCheckedIn(0)
        // setCheckedOut(0)
        setModalState(false)
        loadData();
    };
    const [bookingList, setBookingList] = useState({
        checkIn: [{
            id: '',
            name: '',
            ota: '',
            kode_unit: '',
            is_checked_in: 0,
            is_pay_on_spot: 0,
            stay_period: 0
        }],
        checkOut: [{
            id: '',
            name: '',
            ota: '',
            kode_unit: '',
            is_checked_out: 0,
            is_pay_on_spot: 0,
            stay_period: 0
        }]
    })

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {selectedName}
                </Typography>
            </Toolbar>
        )
    }

    const openModalCheckin = (data: any) => {
        setSelectedId(data.id);
        setSelectedName(data.name)
        setSelectedIsCheckedIn(data.is_checked_in)
        setSelectedIsPaySpot(data.is_pay_on_spot)
        setIsCheckIn(true);
        handleOpen();
    }

    const openModalCheckout = (data: any) => {
        setSelectedId(data.id);
        setSelectedName(data.name)
        setSelectedIsCheckedOut(data.is_checked_out)
        setIsCheckIn(false);
        handleOpen();
    }

    const submitSuccess = () => {
        handleClose();
    }

    const handlePayOnSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const result = e.target.checked ? 1 : 0
        setPayOn(result);
    }

    const handleCheckedInSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const result = e.target.checked ? 1 : 0
        setCheckedIn(result);
    }

    const handleCheckedOutSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const result = e.target.checked ? 1 : 0
        setCheckedOut(result);
    }

    const loadData = (() => {
        setLoading(true);
        // const data = {
        //     location_id: 1,
        //     is_apartment: 0,
        //     is_pay_on_spot: 0
        // }
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/pms-booking?location_id=${params.location_id}&is_apartment=${params.is_apartment}&is_pay_on_spot=${payOn}&is_checked_in=${checkedIn}&is_checked_out=${checkedOut}`)
            .then(res => {
                setBookingList(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    })

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, payOn, checkedIn, checkedOut]);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        setSelectedIsCheckedIn(0)
        setSelectedIsCheckedOut(0)
        setCheckedIn(0)
        setCheckedOut(0)
        setPayOn(0);
    };

    return (
        <Root>
            <Dialog
                open={modalState}
                onClose={handleClose}
                closeAfterTransition
                TransitionComponent={Transition}
                fullScreen
            >
                <Root>
                    <AppBar style={{ backgroundColor: selectedIsCheckedIn !== 1 && (selectedIsPaySpot !== 0) ? '#f7b100' : (selectedIsCheckedIn || selectedIsCheckedOut) ? '#00ce1b' : '#009BE5', position: 'sticky' }} className={classes.appBar}>
                        <FormToolbar></FormToolbar>
                    </AppBar>
                    <Stay360PmsDetail
                        mode={isCheckIn ? 'checkIn' : 'checkOut'}
                        onSubmitSuccess={submitSuccess}
                        id={selectedId}
                    />
                </Root>
            </Dialog>
            <LoadingScreen open={loading} fullScreen />
            <Card className={classes.cardRoot} variant="outlined">
                <CardContent>
                    <Typography
                    >
                        {moment().format('DD MMM yyyy')} - {params.label}
                    </Typography>
                    <br></br>
                    <div className={classes.tabsRoot}>
                        <AppBar position="static">
                            <Tabs
                                value={value} onChange={handleChangeTab}
                                aria-label="simple tabs example"
                                style={{ backgroundColor: 'white' }}
                                className={classes.tabsContent}
                                textColor="primary"
                                centered
                            >

                                <Tab label="Check In" {...a11yProps(0)} />
                                <Tab label="Check Out" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                    </div>
                    <TabPanel value={value} index={0}>
                        <Box display="flex" flexDirection="row-reverse" style={{ marginTop: '10px', alignItems: 'center' }}>
                            <Switch
                                // checked={payOn}
                                value={true}
                                onChange={handlePayOnSwitch}
                                color="secondary"
                            />
                            <Typography
                                variant="subtitle2"
                                style={{ fontWeight: "bold" }}>
                                Pay on the spot
                            </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row-reverse" style={{ marginBottom: '10px 0', alignItems: 'center' }}>
                            <Switch
                                // checked={payOn}
                                value={true}
                                onChange={handleCheckedInSwitch}
                                color="secondary"
                            />
                            <Typography
                                variant="subtitle2"
                                style={{ fontWeight: "bold" }}>
                                Checked In
                            </Typography>
                        </Box>
                        <Grid container spacing={2}>
                            {bookingList.checkIn.map((list) => (
                                <Grid item xs={12} sm={12} key={list.id}>
                                    <Card onClick={() => { openModalCheckin(list) }} style={{ marginBottom: '1rem', cursor: 'pointer', color: 'rgba(0, 0, 0, 0.87)' }}>
                                        <CardContent style={{ backgroundColor: (list.is_checked_in !== 1) && (list.is_pay_on_spot !== 0) ? '#f7b100' : (list.is_checked_in) ? '#00ce1b' : '#009BE5' }} >
                                            <Typography
                                                variant="h5"
                                                style={{ color: '#fff' }}>
                                                {list.name}
                                            </Typography>
                                        </CardContent>
                                        <CardContent>
                                            <Typography
                                                variant="subtitle2"
                                                style={{ textAlign: 'center' }}>
                                                {`${list.kode_unit} - ${list.ota} - ${list.stay_period} Nights`}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box display="flex" flexDirection="row-reverse" style={{ margin: '10px 0', alignItems: 'center' }}>
                            <Switch
                                // checked={payOn}
                                value={true}
                                onChange={handleCheckedOutSwitch}
                                color="secondary"
                            />
                            <Typography
                                variant="subtitle2"
                                style={{ fontWeight: "bold" }}>
                                Checked Out
                            </Typography>
                        </Box>
                        <Grid container spacing={2}>
                            {bookingList.checkOut.map((list) => (
                                <Grid item xs={12} sm={12} key={list.id}>
                                    <Card onClick={() => { openModalCheckout(list) }} style={{ margin: '1rem 0', cursor: 'pointer', color: 'rgba(0, 0, 0, 0.87)' }}>
                                        <CardContent style={{ backgroundColor: list.is_checked_out ? '#00ce1b' : '#009BE5' }} >
                                            <Typography
                                                variant="h5"
                                                style={{ color: '#fff' }}>
                                                {list.name}
                                            </Typography>
                                        </CardContent>
                                        <CardContent>
                                            <Typography
                                                variant="subtitle2"
                                                style={{ textAlign: 'center' }}>
                                                {`${list.kode_unit} - ${list.ota} - ${list.stay_period} Nights`}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </TabPanel>
                </CardContent>
            </Card>
        </Root>
    );
}

export default Stay360PmsHome;
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Grid, TextField } from '@mui/material'
import GeneralModal from '../../_components/_modal/GeneralModal'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete'
import MuiDatePicker from '../../_components/MuiDatePicker'
import TextFieldSelect from '../../_components/_form/TextFieldSelect'

/**
 * Utils
 */
import { capitalizeFirstCharacter, generalErrorHandler, renderToastSuccess } from '../../_utils/Helper'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation'
import { format } from 'date-fns'
import DefaultAxios from '../../_utils/DefaultAxios'

interface UnitReviewModalFormProps {
    open: boolean
    type: 'add' | 'edit'
    editData?: UnitReview | null
    onClose: () => void
    refreshTable: () => void
}

export type UnitReview = {
    id?: string
    name: string
    date: Date | null
    unit_id: string
    unit_label: string
    review: string
    rating: '' | '1' | '2' | '3' | '4' | '5'
}

const UnitReviewModalForm = (props: UnitReviewModalFormProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<UnitReview>(INITIAL_STATE)
    const [error, setError] = useState<IValidationErrors<UnitReview>>({})

    // Reset state and error on modal close
    useEffect(() => {
        if (!props.open) {
            setState(INITIAL_STATE)
            setError({})
        }
    }, [props.open])

    useEffect(() => {
        if (props.editData && props.type === 'edit') {
            setState(props.editData)
        }
    }, [props.editData, props.type])

    const handleDateChange = (date: Date | null) => {
        setState(prev => ({
            ...prev,
            date,
        }))

        setError(prev => ({
            ...prev,
            date: '',
        }))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAutocompleteChange = (name: string, option: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            unit_id: option.id.toString(),
            unit_label: option.label,
        }))

        setError(prev => ({
            ...prev,
            unit_id: '',
        }))
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            unit_id: '',
            unit_label: '',
        }))

        setError(prev => ({
            ...prev,
            unit_id: '',
        }))
    }

    const handleSubmit = () => {
        // Validate data before sending to API
        const { errors, isValid } = validateData(state, VALIDATION_RULES, VALIDATION_ALIAS)
        setError(errors)

        if (isValid) {
            setIsLoading(true)

            const data: Partial<UnitReview> = { ...state }
            delete data.unit_label

            DefaultAxios.request({
                url: `${process.env.REACT_APP_API_URL}/unit-review${props.type === 'edit' ? `/${state.id}` : ''}`,
                method: props.type === 'edit' ? 'PUT' : 'POST',
                data: {
                    ...data,
                    date: format(state.date!, 'yyyy-MM-dd')
                },
            })
                .then(() => {
                    renderToastSuccess(`Berhasil ${props.type === 'add' ? 'menambahkan' : 'mengubah'} data`)
                    props.refreshTable()
                    props.onClose()
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <GeneralModal
            title={`${capitalizeFirstCharacter(props.type)} Review`}
            open={props.open}
            onClose={props.onClose}
            buttons={[
                {
                    text: 'Submit',
                    color: 'primary',
                    onClick: handleSubmit,
                    isLoading,
                }
            ]}
            maxWidth='xs'
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MuiDatePicker
                        label='Date'
                        value={state.date}
                        onChange={handleDateChange}
                        error={!!error.date}
                        helperText={error.date}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        label="Unit Code"
                        name="unit_id"
                        initialQuery={state.unit_label}
                        onChange={handleAutocompleteChange}
                        onInputChange={handleAutocompleteInputChanged}
                        errorText={error.unit_id}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/unit`}
                        placeholder="Choose Unit"
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='Reviewer'
                        name='name'
                        value={state.name}
                        onChange={handleChange}
                        error={!!error.name}
                        helperText={error.name}
                        disabled={isLoading}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldSelect
                        label='Rating'
                        name='rating'
                        value={state.rating}
                        onChange={handleChange}
                        error={!!error.rating}
                        helperText={error.rating}
                        disabled={isLoading}
                        fullWidth
                    >
                        <option value="" disabled>Choose Rating</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </TextFieldSelect>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='Review'
                        name='review'
                        value={state.review}
                        onChange={handleChange}
                        error={!!error.review}
                        helperText={error.review}
                        disabled={isLoading}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </GeneralModal>
    )
}

const INITIAL_STATE: UnitReview = {
    name: '',
    date: null,
    unit_id: '',
    unit_label: '',
    review: '',
    rating: '',
}

const VALIDATION_RULES: IValidationRules = {
    date: 'required',
    unit_id: 'required',
    name: 'required',
    review: 'required',
    rating: 'required',
}

const VALIDATION_ALIAS: IValidationAlias = {
    date: 'Date',
    unit_id: 'Unit Code',
    name: 'Reviewer',
    review: 'Review',
    rating: 'Rating',
}

export default UnitReviewModalForm

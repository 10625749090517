import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
    Paper,
    Theme,
    Grid,
    Button,
    Tabs,
    Tab,
    Box,
    Typography
} from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";

/**
 * Components
 */
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';

/**
 * Icons
 */
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';

import { generalErrorHandler, numberToCurrency, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';
import Swal from 'sweetalert2';
import { useSnackbar } from 'notistack';
import FileList from './_components/FileList';
import { ctaType } from '../../_utils/ConstData';
import NewCommentList from '../../_components/NewCommentList';
import { generateStyle } from '../../_utils/DefaultStyle';

export interface ImageState {
    id: number | null;
    asset_url: string;
}
interface Props {
    open: boolean
    onClose: (isRefresh?: boolean) => void
    onEdit: (data: any) => void
    rent_id?: string
    parentQueryString: string,
}
interface IParams extends Record<string, string | undefined> {
    rent_id?: string
}
interface DetailState {
    cta: null | "cta_hero" | "cta_benefit" | "cta_bottom" | "cta_mobile_float" | "cta_mobile_bottom",
    status: number
    address: string,
    apartment_name: string,
    bathroom: number,
    bedroom: number,
    id: string,
    building_finished: string,
    created_at: string,
    district_id: number,
    electric_watt: number,
    email: string,
    facilities: string,
    floor: string,
    furnish: string,
    is_dollar: number,
    know_from: string,
    land_area: string,
    latitude: number,
    listing_type: number,
    longitude: number,
    name: string,
    ownership_type: number,
    phone: string,
    basic_price_1: string
    basic_price_3: string
    basic_price_6: string
    basic_price_12: string
    basic_price_sell: string
    price_per_1_month: string,
    price_per_3_months: string,
    price_per_6_months: string,
    price_per_12_months: string,
    property_area: string,
    maid_room: number | null,
    rent_submission_assets: [],
    payment_proof_image_url: string,
    request_unit_type: number,
    selling_price: string,
    total_floor: number,
    tower: string,
    is_paid_listing_label: string,
    is_paid_listing: number,
    unit_for: string,
    unit_number: string,
    view: string,
    listing_name: string,
    description: string,
    unit: {
        code: string
    } | null,
    listing: {
        code: string
    } | null
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    container: {
        backgroundColor: "#f3f3f3",
        // height: '100%'
    },
    bottom_button: {
        marginBottom: '16px',
        justifyContent: 'flex-end'
    },
    edit_button: {
        // backgroundColor: '#fff',
        color: '#fff'
    },
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        // float: 'right'
        color: '#f50057'

    },
    listTitle: {
        fontWeight: 600
    },
    formContainer: {
        marginTop: theme.spacing(3),
        display: "flex"
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(2, 2),
        marginTop: theme.spacing(5),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3, 2),
        }
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    paperTab: {
        margin: "0"
    },
    paper: {
        // marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "97%",
        color: theme.palette.text.secondary,
    },
    paperFooter: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    paper_label: {
        position: "absolute",
        marginTop: "-45px",
        padding: "1px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)"
    },
    paper_label_text: {
        backgroundColor: "#0094ff",
        fontSize: "1.5em",
        padding: "13px",
        borderRadius: "4px",
        color: "white"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        paddingTop: '10px'
    },
    listBox: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    box: {
        padding: theme.spacing(2)
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    imageBox: {
        display: "flex",
        flexFlow: "column",
        marginTop: '15px'
    },
    head_title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    head_title_top: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1)
    },
    footer: {
        display: 'flex',
        width: '100%',
        minHeight: '500px',
        backgroundColor: '#eee',
        padding: '10px',
    },
    body: {
        wordBreak: 'break-word',
    },
    fileListTable: {
        '& > table': {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'table',
            },
        },
        '& > .mobile-list': {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
            '& > *': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            },
            '& > *:last-child': {
                borderBottom: 'none',
            },
        },
        '& > table > tbody > tr > td:first-of-type': {
            width: 75,
            '& > *': {
                objectFit: 'cover',
                cursor: 'pointer',
            }
        },
        '& .file-name': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.dark,
            }
        }
    },
    button: {
        margin: theme.spacing(1),
    },
    'button-list': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > button': {
            marginRight: 16,
            paddingRight: 12,
            paddingLeft: 12,
            marginBottom: 16,
        },

        '& > button:last-child': {
            marginRight: 0,
        },

        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',

            '& > button': {
                marginBottom: 0,
            }
        }
    }
}), "RentDetail"
)

const RentDetail = (props: Props) => {
    const navigate = useNavigate();
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/rent';
    const [detail, setDetail] = useState<DetailState>({
        cta: null,
        status: 0,
        address: "",
        apartment_name: "",
        bathroom: 0,
        bedroom: 0,
        id: "",
        // rentDetail_id: 0,
        building_finished: "",
        created_at: "",
        district_id: 0,
        electric_watt: 0,
        email: "",
        facilities: "",
        floor: "",
        furnish: "",
        is_dollar: 0,
        know_from: "",
        land_area: "",
        latitude: 0,
        listing_type: 0,
        longitude: 0,
        name: "",
        ownership_type: 0,
        phone: "",
        basic_price_1: "",
        basic_price_3: "",
        basic_price_6: "",
        basic_price_12: "",
        basic_price_sell: "",
        price_per_1_month: "",
        price_per_3_months: "",
        price_per_6_months: "",
        price_per_12_months: "",
        property_area: "",
        maid_room: null,
        rent_submission_assets: [],
        request_unit_type: 0,
        payment_proof_image_url: "",
        selling_price: "",
        total_floor: 0,
        tower: "",
        is_paid_listing_label: "",
        is_paid_listing: 0,
        unit_for: "",
        unit_number: "",
        view: "",
        listing_name: '',
        description: '',
        unit: {
            code: ""
        },
        listing: {
            code: ""
        }
    });
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState<string>('')
    const [imageStates, setImageStates] = useState<ImageState[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const [isEdited, setIsEdited] = useState(false)
    const params = useParams<IParams>()

    const rentId = useMemo(() => props.rent_id || params.rent_id, [props.rent_id, params.rent_id])

    const [activeTab, setActiveTab] = useState(0)

    const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    }

    const loadData = () => {
        setLoading(true);
        DefaultAxios
            .get(`${API_URL}/${rentId}`)
            .then(res => {
                const imageGallery = res.data.rent_submission_assets;
                const newImageStates = [];
                for (let key in imageGallery) {
                    newImageStates.push({
                        id: imageGallery[key].id,
                        asset_url: imageGallery[key].asset_url,
                    })
                }
                setImageStates(newImageStates);
                setDetail(res.data);
                setPhone(res.data.phone);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (rentId) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rentId]);

    const renderWa = (data: string) => {
        let regex = /^08/;
        if (data?.match(regex)) {
            data = data.replace(regex, "628")
        }
        let phoneNumber = data;
        let link = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
        return <a href={link}>{data}</a>
    }

    const submitUnit = () => {
        renderWarningButton("Are you sure you want to list this unit?")
            .then(result => {
                if (result.value) {
                    setLoading(true)

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/rent/submit-unit/${rentId}`, detail)
                        .then((res) => {
                            renderToastSuccess('Data berhasil dikirim');

                            loadData();
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setLoading(false)
                            setIsEdited(true)
                        })
                }
            })
    }

    const copyData = () => {
        let bedroom = '';
        let currency = detail.is_dollar === 1 ? "$ " : "Rp. "
        switch (detail.bedroom) {
            case 0:
                bedroom = "Studio";
                break;
            default:
                bedroom = `${detail.bedroom} Bedroom`
        }

        // Please don't lint this text
        let text: string = `Apartment Name: ${detail.apartment_name}
        Name: ${detail.name}
        Email: ${detail.email}
        Phone / Whatsapp: ${detail.phone}
        Ownership: ${detail.ownership_type === 1 ? 'Owner' : 'Agent'}
        Listing Type: ${detail.listing_type === 0 ? 'Apartment' : detail.listing_type === 2 ? 'Rumah' : detail.listing_type === 3 ? 'Tanah' : detail.listing_type === 4 ? 'Ruko' : detail.listing_type === 5 ? 'Kantor' : ''}
        Price per year: ${currency}${numberToCurrency(detail.price_per_12_months)}
        Price per 6 months: ${currency}${numberToCurrency(detail.price_per_6_months)}
        Price per 3 months: ${currency}${numberToCurrency(detail.price_per_3_months)}
        Sell Price: ${currency}${numberToCurrency(detail.selling_price)}
        Address: ${detail.address}
        Latitude: ${detail.latitude}
        Longitude: ${detail.longitude}
        Bedroom: ${bedroom}
        Bathroom: ${detail.bathroom} Bathroom
        Unit Number: ${detail.unit_number ?? '-'}
        View: ${detail.view}
        Floor: ${detail.floor}
        Total Floor: ${detail.total_floor}
        Building Finished: ${detail.building_finished}
        Electrical Watt: ${detail.electric_watt}
        Area: ${detail.property_area}
        Tower: ${detail.tower}
        Condition: ${detail.furnish}
        Amenities: ${detail.facilities}
        Unit For: ${detail.unit_for}
        Facilities: ${detail.facilities}
        Source: ${detail.know_from}
        listing_name: ${detail.listing_name}
        description: ${detail.description}`;

        navigator.clipboard.writeText(text).then(function () {
            enqueueSnackbar('Copied to clipboard', { variant: 'success' })
        }, function (err) {
            enqueueSnackbar('Tidak berhasil copydata', { variant: 'success' })
        });
    }

    const deleteFile = useCallback((id: string) => {
        renderWarningButton('Apakah anda yakin ingin menghapus file ini?')
            .then((result) => {
                if (result.value) {
                    DefaultAxios
                        .delete(`${process.env.REACT_APP_API_URL}/rent/image`, { params: { id: id } })
                        .then(() => {
                            Swal.fire({
                                title: "File berhasil dihapus",
                                icon: 'success'
                            }).then(() => {
                                loadData();
                            });
                        })
                        .catch((res) => {
                            Swal.fire({
                                title: "Error",
                                text: res.data.message,
                                icon: 'error'
                            });
                        })
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [commentData, setCommentData] = useState({
        comments: [],
        users: [],
    })

    const [isFooterLoading, setIsFooterLoading] = useState(true)

    const fetchComment = useCallback(() => {
        if (rentId) {
            setIsFooterLoading(true)
            const url = `/rent/${rentId}/comment`

            DefaultAxios.get(`${process.env.REACT_APP_API_URL}${url}`)
                .then(res => res.data)
                .then(data => {
                    setCommentData(prev => ({
                        ...prev,
                        ...data
                    }))
                })
                .catch(error => {
                    generalErrorHandler(error)
                })
                .finally(() => {
                    setIsFooterLoading(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rentId])

    useEffect(() => {
        if (activeTab === 0) {
            fetchComment()
        }
    }, [activeTab, fetchComment])

    // const handleReply = (value: string, mentionData: any) => {
    //     setIsFooterLoading(true);
    //     const url = `/rent/${rentId}/comment`

    //     DefaultAxios
    //         .post(`${process.env.REACT_APP_API_URL}${url}`, { comment: value, mentionData })
    //         .then(() => {
    //             fetchComment()
    //         })
    //         .catch(err => {
    //             generalErrorHandler(err);
    //         })
    //         .finally(() => {
    //             setIsFooterLoading(false);
    //         })
    // }

    const footer = useMemo(() => {

        if (rentId) {
            switch (activeTab) {
                case 0:
                    return (
                        <NewCommentList
                            id={rentId}
                            type="rent"
                        />
                    )
                case 1:
                    return (
                        <FileList
                            images={detail.rent_submission_assets}
                            onDeleteFile={deleteFile}
                        />
                    )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, imageStates, deleteFile, rentId, detail, commentData])

    const getCode = () => {
        if (detail.listing_type === 0) {
            return detail.unit && detail.unit.code ? `- ${detail.unit.code}` : ``;
        } else {
            return detail.listing && detail.listing.code ? `- ${detail.listing.code}` : ``;
        }
    }

    const handleEdit = () => {
        navigate('/listing-submission/edit/' + rentId)
    }

    useEffect(() => {
        if (props.open) {
            setIsEdited(false)
        }
    }, [props.open])

    const generateCustomListModalSection = (title: string, data: string) => {
        return (
            <Root>
                <Box className={classes.listBox} key={title}>
                    <Grid>
                        <Typography variant="body1" className={classes.listTitle}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid style={{ marginTop: "7px" }}>
                        <Typography variant="body2" className={classes.body}>
                            {typeof data === 'string' || data === null ? (data ? data : "-") : data}
                        </Typography>
                    </Grid>
                </Box>
            </Root>
        )
    }

    return (
        <>
            <ModalDetail
                open={props.open}
                isLoading={loading}
                onCloseModal={() => props.onClose(isEdited)}
                title={`Listing Submission ${getCode()}`}
                showEditButton={true}
                editButtonLink={handleEdit}
            >
                <>
                    <ModalSection
                        title="Informasi Dasar"
                        xs={12}
                        md={6}
                        content={[
                            ["Name", !!detail.name ? (`${detail.name}`) : '-'],
                            ["Email", !!detail.email ? (`${detail.email}`) : '-'],
                            ["Phone", renderWa(phone)],
                            ["Listing Type", detail.listing_type === 0 ? 'Apartment' : detail.listing_type === 2 ? 'Rumah' : detail.listing_type === 3 ? 'Tanah' : detail.listing_type === 4 ? 'Ruko' : detail.listing_type === 5 ? 'Kantor' : ''],
                            ...detail.listing_type === 0 ?
                                [
                                    ['Apartment Name', !!detail.apartment_name ? (`${detail.apartment_name} `) : '-'],
                                    ['Tower', !!detail.tower ? (`${detail.tower} `) : '-'],
                                    ['Unit Number', !!detail.unit_number ? (`${detail.unit_number} `) : '-'],
                                    ['Floor', !!detail.floor ? (`${detail.floor} `) : '-'],
                                ]
                                :
                                [['Listing Name', !!detail.listing_name ? (`${detail.listing_name} `) : '-'],],
                            ["Description", !!detail.description ? (<span style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>{detail.description}</span>) : '-'],
                            ["Paid Listing", detail.is_paid_listing_label],
                            ["Listing Sewa / Jual", !!detail.unit_for ? (`${detail.unit_for} `) : '-'],
                            ["Request Listing Type", detail.request_unit_type === 0 ? 'Reguler' : 'Star Listing'],
                            ["CTA", detail.cta ? ctaType[detail.cta] : '-'],
                            ["Ownership", detail.ownership_type === 1 ? 'Owner' : 'Agent'],
                            ...detail.listing_type !== 0 ?
                                [
                                    ["Alamat", !!detail.address ? (<span style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>{detail.address}</span>) : '-'],
                                    ["Latitude", !!detail.latitude ? (`${detail.latitude} `) : '-'],
                                    ["Longitude", !!detail.longitude ? (`${detail.longitude} `) : '-'],
                                ]
                                :
                                [],
                        ]}
                    />

                    <Grid item xs={12} md={6}>
                        <Grid container>
                            <ModalSection
                                title="Sell & Rent Price"
                                xs={12}
                                md={12}
                                content={
                                    // [
                                    //     ...detail.price_per_1_month ?
                                    //         [["Price per 1 month", !!detail.price_per_1_month ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.price_per_1_month)} `) : '-']]
                                    //         :
                                    //         [],
                                    //     ...detail.price_per_3_months ?
                                    //         [["Price per 3 months", !!detail.price_per_3_months ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.price_per_3_months)} `) : '-']]
                                    //         :
                                    //         [],
                                    //     ...detail.price_per_6_months ?
                                    //         [["Price per 6 months", !!detail.price_per_6_months ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.price_per_6_months)} `) : '-']]
                                    //         :
                                    //         [],
                                    //     ...detail.price_per_12_months ?
                                    //         [["Price per 12 months", !!detail.price_per_12_months ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.price_per_12_months)} `) : '-']]
                                    //         :
                                    //         [],
                                    //     ...detail.selling_price ?
                                    //         [["Selling Price", !!detail.selling_price ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.selling_price)} `) : '-']]
                                    //         :
                                    //         [],
                                    // ] 
                                    []
                                }
                                additionalContent={(
                                    <>
                                        <Grid container sx={{ m: 0, p: 0 }}>
                                            {
                                                detail.unit_for === 'sewa' || detail.unit_for === 'sewa-jual' ?
                                                    <>
                                                        <Grid item xs={detail.listing_type === 0 ? 6 : 12}>
                                                            {detail.price_per_1_month ? generateCustomListModalSection('Price per 1 Month', !!detail.price_per_1_month ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.price_per_1_month)}`) : '-') : null}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {detail.basic_price_1 ? generateCustomListModalSection('Basic Price per 1 Month', !!detail.basic_price_1 ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.basic_price_1)}`) : '-') : null}
                                                        </Grid>
                                                        <Grid item xs={detail.listing_type === 0 ? 6 : 12}>
                                                            {detail.price_per_3_months ? generateCustomListModalSection('Price per 3 Months', !!detail.price_per_3_months ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.price_per_3_months)}`) : '-') : null}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {detail.basic_price_3 ? generateCustomListModalSection('Basic Price per 3 Months', !!detail.basic_price_3 ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.basic_price_3)}`) : '-') : null}
                                                        </Grid>
                                                        <Grid item xs={detail.listing_type === 0 ? 6 : 12}>
                                                            {detail.price_per_6_months ? generateCustomListModalSection('Price per 6 Months', !!detail.price_per_6_months ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.price_per_6_months)}`) : '-') : null}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {detail.basic_price_6 ? generateCustomListModalSection('Basic Price per 6 Months', !!detail.basic_price_6 ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.basic_price_6)}`) : '-') : null}
                                                        </Grid>
                                                        <Grid item xs={detail.listing_type === 0 ? 6 : 12}>
                                                            {detail.price_per_12_months ? generateCustomListModalSection('Price per 12 Months', !!detail.price_per_12_months ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.price_per_12_months)}`) : '-') : null}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {detail.basic_price_12 ? generateCustomListModalSection('Basic Price per 12 Months', !!detail.basic_price_12 ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.basic_price_12)}`) : '-') : null}
                                                        </Grid>
                                                    </>
                                                    : null
                                            }
                                            {
                                                detail.unit_for === 'jual' || detail.unit_for === 'sewa-jual' ?
                                                    <>
                                                        <Grid item xs={detail.listing_type === 0 ? 6 : 12}>
                                                            {detail.selling_price ? generateCustomListModalSection('Selling Price', !!detail.selling_price ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.selling_price)}`) : '-') : null}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {detail.basic_price_sell ? generateCustomListModalSection('Basic selling price', !!detail.basic_price_sell ? (detail.is_dollar === 0 ? 'Rp. ' : '$ ') + (`${numberToCurrency(detail.basic_price_sell)}`) : '-') : null}
                                                        </Grid>
                                                    </> : null
                                            }
                                        </Grid>
                                    </>
                                )}
                            />

                            <ModalSection
                                title="Size & Dimension"
                                xs={12}
                                md={12}
                                content={[
                                    ...detail.listing_type !== 3 ?
                                        [["Property Area", !!detail.property_area ? <>{detail.property_area} m<sup>2</sup></> : '-']]
                                        :
                                        [],
                                    ...detail.listing_type !== 0 ?
                                        [["Land Area", !!detail.land_area ? <>{detail.land_area} m<sup>2</sup></> : '-']]
                                        :
                                        [],
                                ]}
                            />


                            <ModalSection
                                title="Other Information"
                                xs={12}
                                md={12}
                                content={[
                                    ...detail.electric_watt ?
                                        [
                                            ["Electrical Watt", !!detail.electric_watt ? (`${detail.electric_watt} watt`) : '-'],
                                        ]
                                        :
                                        [],
                                    ...detail.listing_type !== 3 ?
                                        [

                                            ["Total Floor", !!detail.total_floor ? (`${detail.total_floor} `) : '-'],
                                        ]
                                        :
                                        [],
                                    ...(detail.listing_type === 0 || detail.listing_type === 2) ?
                                        [
                                            // ["Unit Number", !!detail.unit_number ? (`${detail.unit_number} `) : '-'],
                                            ["Bedroom", detail.bedroom === 0 ? 'Studio' : detail.bedroom],
                                        ]
                                        :
                                        [],
                                    ...detail.listing_type !== 3 ?
                                        [
                                            ["Bathroom", !!detail.bathroom ? (`${detail.bathroom} `) : '-'],
                                            ["Maid Room", detail.maid_room !== null ? (`${detail.maid_room || 'None'} `) : '-'],
                                        ]
                                        :
                                        [],
                                    ...detail.building_finished ?
                                        [
                                            ["Building Finished", !!detail.building_finished ? (`${detail.building_finished} `) : '-'],
                                        ]
                                        :
                                        [],

                                    ...detail.listing_type === 0 || detail.listing_type === 2 ?
                                        [
                                            ["Furnish", !!detail.furnish ? (`${detail.furnish} `) : '-'],
                                            ["Facilities", !!detail.facilities ? (`${detail.facilities} `) : '-'],
                                        ]
                                        :
                                        [],
                                    ...detail.listing_type === 0 || detail.listing_type === 2 ?
                                        [
                                            ["View", !!detail.view ? (`${detail.view} `) : '-'],
                                        ]
                                        :
                                        [],
                                    ["Know From", !!detail.know_from ? (`${detail.know_from} `) : '-'],
                                    ...detail.payment_proof_image_url !== null ?
                                        [
                                            ["Payment Proof", <a href={detail.payment_proof_image_url} target="_blank" rel="noopener noreferrer">
                                                <img src={detail.payment_proof_image_url} alt="" width="200px" />
                                            </a>],
                                        ]
                                        :
                                        [],
                                ]}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Root>
                            <div className={classes['button-list']}>
                                {detail.status !== 1 &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        disabled={detail.is_paid_listing === 1 && detail.payment_proof_image_url === null ? true : false}
                                        onClick={() => submitUnit()}
                                    >
                                        Submit Listing
                                    </Button>
                                }

                                <Button
                                    variant="contained"
                                    // color="primary"
                                    size="small"
                                    onClick={() => copyData()}
                                >
                                    Copy Data
                                </Button>
                                {/* <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                // className={classes.delete_button}
                                onClick={() => {
                                    if (window.confirm("Are you sure you want to delete?")) {
                                        DefaultAxios.delete(`${process.env.REACT_APP_API_URL} /rent/${params.rent_id} `)
                                        navigate({
                                            pathname: '/listing-submission',
                                            state: { reload: true }
                                        })
                                    }
                                }}
                            >
                                Reject
                            </Button> */}
                            </div>
                        </Root>
                    </Grid>

                    <Grid item xs={12}>
                        <Root>
                            <Paper className={classes.paperFooter}>
                                <LoadingScreen open={isFooterLoading} />
                                <Tabs
                                    value={activeTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleTab}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab label="Comment" value={0} />
                                    <Tab label="Image" value={1} />
                                </Tabs>
                                <div className={classes.footer}>
                                    {footer}
                                </div>
                            </Paper>
                        </Root>
                    </Grid>
                </>
            </ModalDetail>
        </>
    );
}

export default RentDetail;
import { Button, capitalize, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../../../_utils/Helper'
import LoadingScreen from '../../../../_components/LoadingScreen'

type Props = {
    open: boolean
    onClose: () => void
    closingId: string
    closingCode: string
}

const signerTypeOptions = [
    { value: 'owner', label: 'Owner' },
    { value: 'tenant', label: 'Tenant' },
]

let defaultOptions = [
    { value: 'owner', label: 'Kontrak Owner' },
    { value: 'tenant', label: 'Kontrak Tenant' },
    { value: 'power_of_attorney', label: 'Surat Kuasa' },
    { value: 'addendum_rent', label: 'Addendum Sewa' },
]

const DownloadContractModal = (props: Props) => {
    const [type, setType] = useState('')
    const [signerType, setSignerType] = useState('owner')
    const [text, setText] = useState('')
    const [isLoading, setIsLoading] = useState(false)


    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/download-document`, {
            type,
            closing_id: props.closingId,
            ...(type === 'power_of_attorney' ? {
                additional_text: text,
                signer_type: signerType
            } : {})
        }, { responseType: 'blob' })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${defaultOptions.find((item) => item.value === type)?.label || ''}${type === 'power_of_attorney' ? ` ${capitalize(signerType)}` : ''} [${props.closingCode}].pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                renderSuccessButton(`File ${defaultOptions.find((option) => option.value === type)?.label || ""} Berhasil di-Download!`)
                props.onClose()
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Dialog
            {...props}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>
                Download Kontrak
            </DialogTitle>
            <DialogContent>
                <LoadingScreen open={isLoading} fullScreen />
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label="Tipe"
                            name='type'
                            value={type || "default"}
                            onChange={(e) => setType(e.target.value)}
                            fullWidth
                        >
                            <MenuItem value="default" disabled>-- Pilih Tipe --</MenuItem>
                            {
                                defaultOptions.map((option) => (
                                    <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    {
                        type === 'power_of_attorney' ?
                            <>
                                <Grid item xs={12}>
                                    <Paper variant='outlined' sx={{ p: 2 }}>
                                        <Typography variant='body1'>
                                            <strong style={{ overflowWrap: "break-word" }}>
                                                Dengan Surat Kuasa ini, menjelaskan bahwa Pihak Pertama memberikan kuasa kepada Pihak Kedua {text}
                                            </strong>
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Text"
                                        name='additional_text'
                                        onChange={(e) => setText(e.target.value)}
                                        fullWidth
                                        value={text}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Penanda Tangan"
                                        name='signer_type'
                                        onChange={(e) => setSignerType(e.target.value)}
                                        fullWidth
                                        value={signerType}
                                        select
                                    >
                                        {
                                            signerTypeOptions.map((type) => (
                                                <MenuItem value={type.value}>{type.label}</MenuItem>
                                            ))
                                        }

                                    </TextField>
                                </Grid>
                            </> :
                            null
                    }
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={!type}
                            sx={{ float: 'right' }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default DownloadContractModal
import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Button, TextField, ButtonGroup, Theme } from '@mui/material'

import ButtonUpload from '../../../_components/ButtonUpload'
import PhotoPreview from '../../../_components/PhotoPreview'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import { useNavigate } from 'react-router-dom'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, getBase64, resizeAndResetOrientationImage } from '../../../_utils/Helper'
import Swal from 'sweetalert2'
import LoadingScreen from '../../../_components/LoadingScreen'
import TextEditor from '../../../_components/_form/TextEditor'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete'
import { generateStyle } from '../../../_utils/DefaultStyle'

interface IProps {
    id?: string
}

interface IAreaFormState {
    name: string
    name_en: string
    slug: string
    title: string
    title_en: string
    latitude: string
    longitude: string
    highlight: string
    highlight_en: string
    province_id: number | null
    province_label: string
    primary_image: File | null
    meta_title: string
    meta_title_en: string
    meta_description: string
    meta_description_en: string
}

const BasicAreaForm = (props: IProps) => {
    const navigate = useNavigate()
    const { Root, classes } = useStyles()

    const [isLoading, setIsLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState('')
    const [lang, setLang] = useState<'en' | 'id'>('id')

    const [state, setState] = useState<IAreaFormState>(initialState)
    const [error, setError] = useState<IValidationErrors>({})


    const validationRules: IValidationRules = useMemo(() => ({
        name: 'required',
        title: 'required',
        title_en: 'required',
        latitude: 'required|latitude',
        longitude: 'required|longitude',
        primary_image: (props.id && imageUrl) ? '' : 'required|File',
        province_id: 'required'
    }), [props.id, imageUrl])

    useEffect(() => {
        if (props.id) {
            setIsLoading(true)
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/area/${props.id}`)
                .then(res => res.data)
                .then(data => {
                    setState({
                        ...initialState,
                        ...data,
                        primary_image: null,
                    })

                    if (data.primary_image) {
                        setImageUrl(data.primary_image)
                    }
                })
                .catch(error => {
                    generalErrorHandler(error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [props.id])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = e.target

        if (files && files.length > 0) {
            if (files[0].size >= 5000000) {
                Swal.fire({
                    title: 'Error!',
                    text: `Failed: Max uploaded file is 5MB`,
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            } else {
                resizeAndResetOrientationImage(files[0])
                    .then((file: File) => {
                        setState(prev => ({
                            ...prev,
                            primary_image: file
                        }))

                        getBase64(file)
                            .then(base64 => {
                                setImageUrl(base64 as string)
                            })

                        setError(prev => ({
                            ...prev,
                            primary_image: '',
                        }))
                    })
            }
        } else {
            setState(prev => ({
                ...prev,
                [name]: value
            }))
        }

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        const { errors } = validateData({ [name]: value }, validationRules, validationAlias)

        setError(errors)
    }

    const handleEditorChange = (name: string, value: string) => {
        setState(prev => ({
            ...prev,
            [name]: value
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleRemoveImage = () => {
        setState(prev => ({
            ...prev,
            primary_image: null,
        }))

        setImageUrl('')
    }

    const submit = () => {
        const { isValid, errors } = validateData(state, validationRules, validationAlias)
        setError(errors)

        if (isValid) {
            setIsLoading(true)

            const formData = new FormData()
            for (let key in state) {
                const value = state[key as keyof IAreaFormState] as string | File
                if (value) {
                    formData.append(key, value)
                }
            }

            if (props.id) {
                formData.append('_method', 'PUT')
            }

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/area${props.id ? `/${props.id}` : ''}`, formData)
                .then(res => res.data)
                .then(data => {
                    Swal.fire({
                        title: "Submit area berhasil",
                        icon: 'success',
                        timer: 1000
                    })
                        .then(() => {
                            navigate(`/area?name=${state.name}`)
                        })
                })
                .catch(error => {
                    generalErrorHandler(error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            window.scrollTo({
                top: 0,
            })
        }
    }

    const handleAutoCompleteChange = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            province_id: value.id,
            province_label: value.label
        }))

        setError(prev => ({
            ...prev,
            province_id: ''
        }))
    }

    const handleAutoCompleteInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setState(prev => ({
            ...prev,
            province_label: e.target.value
        }))

        setError(prev => ({
            ...prev,
            province_id: ''
        }))
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Name"
                        label="Name"
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.name}
                        helperText={error.name}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Name (EN)"
                        label="Name (EN)"
                        name="name_en"
                        value={state.name_en}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.name_en}
                        helperText={error.name_en}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AsyncAutoComplete
                        label="Province"
                        name="province"
                        initialQuery={state.province_label}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteInputChange}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/province`}
                        placeholder="Province"
                        errorText={error.province_id}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Slug"
                        label="Slug"
                        name="slug"
                        value={state.slug}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.slug}
                        helperText={error.slug}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Title"
                        label="Title"
                        name="title"
                        value={state.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.title}
                        helperText={error.title}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Title (EN)"
                        label="Title (EN)"
                        name="title_en"
                        value={state.title_en}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.title_en}
                        helperText={error.title_en}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Latitude"
                        label="Latitude"
                        name="latitude"
                        value={state.latitude}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.latitude}
                        helperText={error.latitude}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Longitude"
                        label="Longitude"
                        name="longitude"
                        value={state.longitude}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.longitude}
                        helperText={error.longitude}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.highlightContainer}>
                        <span className="label">
                            Highlight
                        </span>
                        <ButtonGroup
                            color="primary"
                            style={{
                                marginBottom: 16,
                            }}
                        >
                            <Button
                                variant={lang === 'id' ? 'contained' : 'outlined'}
                                onClick={() => setLang('id')}
                            >
                                Indonesia
                            </Button>
                            <Button
                                variant={lang === 'en' ? 'contained' : 'outlined'}
                                onClick={() => setLang('en')}
                            >
                                English
                            </Button>
                        </ButtonGroup>
                        <TextEditor
                            name={lang === 'en' ? 'highlight_en' : 'highlight'}
                            value={lang === 'en' ? state.highlight_en : state.highlight}
                            onChange={handleEditorChange}
                        />
                        {
                            (error.highlight) &&
                            <span className="error">
                                {error.highlight}
                            </span>
                        }
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.imageContainer}>
                        <span className="label">
                            Primary Image
                        </span>
                        {
                            imageUrl ?
                                <PhotoPreview
                                    index={0}
                                    onRemoveFile={handleRemoveImage}
                                    src={imageUrl}
                                    style={{
                                        margin: 0,
                                    }}
                                />
                                :
                                <ButtonUpload
                                    onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>)}
                                    style={{
                                        margin: 0,
                                    }}
                                />
                        }
                        {
                            error.primary_image &&
                            <span className="error">
                                Please choose the image
                            </span>
                        }
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <span className={classes.line} />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={4}
                className={classes.metaContainer}
            >
                <Grid item xs={12}>
                    <span className="title">
                        Meta
                    </span>
                </Grid>
                <Grid item xs={12}>
                    <span className="subtitle">
                        [ID]
                    </span>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        placeholder="Meta Title"
                        label="Meta Title"
                        name="meta_title"
                        value={state.meta_title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.meta_title}
                        helperText={error.meta_title}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        placeholder="Meta Description"
                        label="Meta Description"
                        name="meta_description"
                        value={state.meta_description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.meta_description}
                        helperText={error.meta_description}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <span className="subtitle">
                        [EN]
                    </span>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        placeholder="Meta Title"
                        label="Meta Title"
                        name="meta_title_en"
                        value={state.meta_title_en}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.meta_title_en}
                        helperText={error.meta_title_en}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        placeholder="Meta Description"
                        label="Meta Description"
                        name="meta_description_en"
                        value={state.meta_description_en}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.meta_description_en}
                        helperText={error.meta_description_en}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submit}
                        disabled={isLoading}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > .label': {
            fontSize: 16,
            color: '#484848',
            marginBottom: 12,
        },
        '& > .error': {
            color: theme.palette.error.main,
            marginTop: 12,
        }
    },
    line: {
        display: 'block',
        width: '100%',
        height: 1,
        backgroundColor: '#cacaca',
        margin: '16px 0px 16px',
    },
    metaContainer: {
        '& .title': {
            fontSize: 16,
            fontWeight: '500',
        },
        '& .subtitle': {
            fontWeight: '500',
        }
    },
    highlightContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > .label': {
            fontSize: 16,
            color: '#484848',
            marginBottom: 12,
        },
        '& > .error': {
            color: theme.palette.error.main,
            marginTop: 12,
        }
    }
}), "BasicArea_Form"
)

const validationAlias: IValidationAlias = {
    name: 'Name',
    title: 'Title',
    title_en: 'English Title',
    latitude: 'Latitude',
    longitude: 'Longitude',
    primary_image: 'File',
    province_id: 'Province'
}

const initialState: IAreaFormState = {
    name: '',
    name_en: '',
    slug: '',
    title: '',
    title_en: '',
    latitude: '',
    longitude: '',
    highlight: '',
    highlight_en: '',
    province_id: null,
    province_label: '',
    primary_image: null,
    meta_title: '',
    meta_title_en: '',
    meta_description: '',
    meta_description_en: '',
}

export default BasicAreaForm

import React from "react";
import { MenuItem, TextField, Grid } from "@mui/material";
import { MonthYearOption } from "../EnquiryBreakdown";

interface FilterMonthListingProps {
    selectedMonth: MonthYearOption;
    handleMonthChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    monthlyOptions: MonthYearOption[];
    sort: string | null;
    handleSort: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterMonthListing: React.FC<FilterMonthListingProps> = ({
    selectedMonth,
    handleMonthChange,
    monthlyOptions,
    sort,
    handleSort,
}) => {
    return (
        <Grid container spacing={2} marginTop={{ xs: 1, sm: 2, md: -1, lg: -1 }} marginBottom={4}>
            <Grid item xs={12} sm={2}>
                <TextField
                    fullWidth
                    select
                    label="Sort"
                    variant="outlined"
                    name="sort"
                    value={sort}
                    onChange={handleSort}
                >
                    <MenuItem value="default" defaultChecked>Default</MenuItem>
                    <MenuItem value="highest-new-key">Highest New Key</MenuItem>
                    <MenuItem value="lowest-new-key">Lowest New Key</MenuItem>
                    <MenuItem value="highest-new-listing">Highest New Listing</MenuItem>
                    <MenuItem value="lowest-new-listing">Lowest New Listing</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={false} sm={8} />
            <Grid item xs={12} sm={2} marginTop={{ xs: -2, sm: 0, md: 0, lg: 0 }}>
                <TextField
                    fullWidth
                    select
                    label="Bulan"
                    variant="outlined"
                    name="selectedMonth"
                    value={selectedMonth.display}
                    onChange={handleMonthChange}
                >
                    {monthlyOptions.map((dateYear) => (
                        <MenuItem key={dateYear.display} value={dateYear.display}>
                            {dateYear.display}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    );
};

export default FilterMonthListing;

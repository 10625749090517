import React, { useReducer } from 'react';

import { Dialog, DialogTitle, OutlinedInput, FormControl, InputLabel, Grid, Button, DialogContent, TextField, Theme } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';



import DefaultAxios from '../../../_utils/DefaultAxios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { generateStyle } from '../../../_utils/DefaultStyle';

const SwalContainer = withReactContent(Swal)

interface IAction {
    name: string;
    value: string | IState;
}

interface IState {
    unit_code: string,
    showing_result: string,
    recommendation: string
}

interface IProps {
    id?: number;
    unitCode: string;
    isOpen: boolean;
    handleClose: Function;
}

const useStyles = generateStyle((theme: Theme) =>
({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mainModal: {
        padding: "20px",
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    buttonContainer: {
        marginBottom: theme.spacing(2),
        "& button": {
            marginLeft: theme.spacing(1)
        }
    }
}), "ShowingReview_Modal"
);

const initialState = {
    unit_code: "",
    showing_result: "",
    recommendation: ""
}

export default function ShowingReviewModal(props: IProps) {
    const { Root, classes } = useStyles();
    const API_URL: string = process.env.REACT_APP_API_URL!;

    const handleClose = () => {
        props.handleClose();
    };

    const inputReducer = (state: IState, action: IAction) => {
        return {
            ...state,
            [action.name]: action.value
        }
    }

    const [inputState, setInputValue] = useReducer(inputReducer, initialState);
    const handleInputChange = (e: any) => setInputValue({ name: e.target.name, value: e.target.value });

    const handleSubmit = () => {
        DefaultAxios.post(`${API_URL}/manage-showing`, {
            id: props.id,
            showing_result: inputState.showing_result,
            recommendation: inputState.recommendation,
        }).then(res => {
            SwalContainer.fire({
                title: "Success",
                html: "Data berhasil ditambahkan",
                icon: 'success',
                onClose: () => {
                    handleClose();
                }
            })
        })
            .catch(error => {
                if (typeof error.response.status === 'undefined') {
                    SwalContainer.fire({
                        title: "Script Error",
                        html: "Error pada script. Harap hubungi Admin",
                        icon: 'error',
                    });
                } else if (error.response.status === 422) {
                    let error_text = `<p>${error.response.data.message}</p>`;

                    if (typeof error.response.data.errors !== 'undefined') {
                        const error_lists = error.response.data.errors;
                        for (var k in error_lists) {
                            error_text += `<p>${error_lists[k]}</p>`;
                        }
                    }

                    SwalContainer.fire({
                        title: "Validasi Error",
                        html: error_text,
                        icon: 'error',
                    });
                } else if (error.response.status === 500) {
                    SwalContainer.fire({
                        title: "Server Error",
                        html: "Error pada server. Harap hubungi Admin",
                        icon: 'error',
                    });
                }

                return false;
            });
    }

    return (
        <Root>
            <div>
                <Dialog
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={props.isOpen}
                    onClose={handleClose}
                    fullWidth={true}
                    maxWidth="lg"
                    scroll="body"
                >
                    <Root>
                        <DialogTitle>
                            Review Showing - {props.unitCode}
                        </DialogTitle>

                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined">
                                        <Autocomplete
                                            freeSolo
                                            disableClearable
                                            onChange={(event: any, newValue: string | null) => {
                                                setInputValue({ name: 'showing_result', value: newValue !== null ? newValue : "" })
                                            }}
                                            options={[
                                                'Ukuran unit terlalu kecil / besar',
                                                'Harga kemahalan',
                                                'Lokasi tidak sesuai harapan',
                                                'Client masih membanding-bandingkan dengan yg lain',
                                                'Cocok tapi mau nego termin pembayaran',
                                                'Cocok tapi mau nego jangka waktu sewa',
                                                'Layout unit tidak cocok',
                                                'Dapur terlalu kecil / besar',
                                                'Kamar utama terlalu kecil / besar',
                                                'Kamar anak terlalu kecil / besar',
                                                'View kurang bagus',
                                                'Nomor Lantai terlalu tinggi / rendah',
                                                'Sukes (Berhasil Closing)'
                                            ].map((option) => option)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Hasil Showing"
                                                    margin="normal"
                                                    variant="outlined"
                                                    name="showing_result"
                                                    value={inputState.showing_result}
                                                    onChange={handleInputChange}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>Rekomendasi / Saran Untuk Advisor</InputLabel>
                                        <OutlinedInput
                                            name="recommendation"
                                            value={inputState.recommendation}
                                            onChange={handleInputChange}
                                        // TODO: might break
                                        // labelWidth={60}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
                                    <Grid>
                                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid>
                                        <Button variant="contained" color="secondary" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Root>
                </Dialog>
            </div>
        </Root>
    );
}
import React, { useState } from "react";

/**
 * Components
 */
import ActiveKeyList from './_components/ActiveKeyList';
import PendingList from './_components/PendingList';

export interface IUnit {
    id: string
    code: string
    apartment_name: string
    detail: string
    is_checked: boolean
}

const UnitKeyManageList = () => {
    const [menu, setMenu] = useState(0);

    return (
        <>
            {menu === 0 &&
                <ActiveKeyList changeMenu={setMenu} />
            }

            {menu === 1 &&
                <PendingList changeMenu={setMenu} />
            }
        </>
    );
}

export default UnitKeyManageList;
import React, { useState } from 'react';
import { Button } from '@mui/material';

// Components
import ShowingReviewModal from './_components/ShowingReviewModal';
import DataTable from '../../_components/_dataTable/DataTable';

const ManageShowing = () => {

    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [selectedId, setSelectedId] = useState<number>(0);
    const [unitCode, setUnitCode] = useState<string>("");

    const onCloseModal = () => {
        setSelectedId(0);
        setReDrawDatatable(new Date().getTime());
    }

    return (
        <>
            <ShowingReviewModal
                id={selectedId}
                unitCode={unitCode}
                isOpen={selectedId !== 0}
                handleClose={onCloseModal}
            />

            <DataTable
                reDraw={reDrawDatatable}
                url={`${process.env.REACT_APP_API_URL}/manage-showing`}
                columns={[
                    { name: 'date', label: "Date", type: 'date', filterable: true },
                    { name: 'customer_name', label: 'Nama Prospek', filterable: true },
                    { name: 'customer_phone', label: 'No HP', filterable: true },
                    { name: 'customer_email', label: 'Email', filterable: true },
                    { name: 'advisor_name', label: 'Advisor', filterable: true },
                    { name: 'unit_code', label: 'Unit Code', filterable: true },
                    { name: 'apartment_name', label: 'Apartment Name', filterable: true },
                    {
                        name: 'tower', label: 'Unit Detail', filterable: true, render: (data: any, row: any) => {
                            return `Tw. ${row.tower} | Floor ${row.floor_level} | Number ${row.unit_number}`;
                        }
                    },
                    {
                        name: 'showing_result', label: 'Hasil Showing', filterable: false, render: (data: any, row: any) => {
                            if (row.is_reviewed) {
                                return data;
                            } else {
                                return (
                                    <Button variant="text" color="primary" size="small"
                                        onClick={() => {
                                            setUnitCode(row.unit_code);
                                            setSelectedId(row.id);
                                        }}>
                                        Review
                                    </Button>
                                )
                            }
                        }
                    },
                    {
                        name: 'recommendation', label: 'Rekomendasi ke Advisor', filterable: false, render: (data: any, row: any) => {
                            if (row.is_reviewed) {
                                return data;
                            } else {
                                return "-";
                            }
                        }
                    },
                ]}
            />
        </>
    );
}

export default ManageShowing;
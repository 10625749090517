import { FormControlLabel, Grid, Switch, TextField, MenuItem } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import TextArea from '../../../_components/_form/TextArea'

import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete';

/* 
* Utils
*/
import DefaultAxios from '../../../_utils/DefaultAxios';
import { capitalizeFirstCharacter, generalErrorHandler, queryParamsToParams } from '../../../_utils/Helper';
import { format } from 'date-fns';

/* 
* Interfaces
*/
import { IOrderDetailState, initialDetail } from './FixingCleaningForm';
import { IDetail } from '../JhsOrderForm';
import { CustomDatePicker, CustomTimePicker } from './DateTimePicker';
import { useParams } from 'react-router-dom';

interface IProps {
    state: IOrderDetailState,
    setState: Dispatch<SetStateAction<IOrderDetailState>>
    detail: IDetail,
    setDetail: Dispatch<SetStateAction<IDetail>>
    setFormChanged: Dispatch<SetStateAction<boolean>>
}


const OrderDetailForm = ({ setState, state, detail, setDetail, setFormChanged }: IProps) => {
    const params = useParams<{ id: string }>()
    const [requestDateTime, setRequestDateTime] = useState({
        request_date: state.request_date ? new Date(state.request_date as string) : null,
        request_time: state.request_time ? new Date(`Wed Mar 25 2015 ${state.request_time}:00`) : null
    })

    const fetchData = (type: string, id: string) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/activity/${type === 'closing' ? 'closing' : 'unit'}`, { params: { id } })
            .then(res => {
                setDetail(res.data)
            })
            .catch(err => generalErrorHandler(err))
    }

    const handleSwitch = (e: any) => {
        if (e.target.checked) {
            setState({
                ...state,
                use_closing: e.target.checked
            })
        } else {
            setState({
                ...state,
                use_closing: e.target.checked,
                closing_id: ''
            })
        }
    }

    const handleChangeClosing = (name: string, value: any) => {
        setFormChanged(true)
        setState(prev => {
            fetchData('closing', value.id)
            return {
                ...prev,
                closing_id: value.id,
                closing_code: value.label,
                unit_id: value.unit_id
            }
        })
    }

    const handleChangeUnit = (name: string, value: any) => {
        setFormChanged(true)
        setState(prev => {
            fetchData('unit', value.id)
            return {
                ...prev,
                unit_id: value.id
            }
        })
    }

    const handleChange = (e: any) => {
        setFormChanged(true)
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleRequestDate = (value: Date) => {
        setRequestDateTime((prev: any) => {
            setState({
                ...state,
                request_date: value ? format(new Date(value), 'Y-MM-dd') : ''
            })
            return {
                ...prev,
                request_date: value
            }
        })
    }

    const handleRequestTime = (value: number) => {
        setRequestDateTime((prev: any) => {
            setState({
                ...state,
                request_time: value ? format(new Date(value), 'HH:mm') : ''
            })
            return {
                ...prev,
                request_time: value
            }
        })
    }

    useEffect(() => {
        if (params.id) {
            if (state.request_date) {
                handleRequestDate(new Date(state.request_date as string))
            }
            if (state.request_time) {
                handleRequestTime(new Date(`Wed Mar 25 2015 ${state.request_time}:00`).getTime())
            }
        }
        // eslint-disable-next-line
    }, [state.request_date, state.request_time])

    return (
        <Grid container spacing={2}>
            {
                queryParamsToParams()?.ticket_id ?
                    null :
                    <Grid item xs={12}>
                        <FormControlLabel
                            style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 0 }}
                            label="Gunakan Closing"
                            labelPlacement="start"
                            onChange={handleSwitch}
                            checked={state.use_closing}
                            control={<Switch />}
                        />
                    </Grid>
            }
            {
                state.use_closing &&
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        disabled={queryParamsToParams()?.ticket_id}
                        name='closing_code'
                        url={`${process.env.REACT_APP_API_URL}/admin/autocomplete/closing`}
                        label="Closing Code"
                        onChange={handleChangeClosing}
                        initialQuery={state.closing_code || ''}
                        clearable
                        onClear={() => [setState(prev => ({ ...prev, closing_id: '', closing_code: '', unit_id: '' })), setDetail(initialDetail)]}
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <AsyncAutoComplete
                    name='unit_code'
                    url={`${process.env.REACT_APP_API_URL}/autocomplete/unit`}
                    label="Unit Code"
                    initialQuery={detail.unit_code}
                    disabled={state.use_closing}
                    onChange={handleChangeUnit}
                    clearable={!state.use_closing}
                    onClear={() => [setState(prev => ({ ...prev, unit_id: '' })), setDetail(initialDetail)]}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Apartment Name"
                    value={detail.apartment_name}
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Unit Detail"
                    value={`Tw. ${detail.tower || ''} / ${detail.floor_level} / ${detail.unit_number}`}
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Unit Type"
                    fullWidth
                    disabled
                    value={detail.bedroom === null ? '' : detail.bedroom ? `${detail.bedroom}BR` : 'Studio'}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    select
                    label="Type of Work"
                    fullWidth
                    name="work_type"
                    value={state.work_type}
                    onChange={handleChange}
                >
                    <MenuItem value="" disabled>-- Select Type --</MenuItem>
                    <MenuItem value="check_in">Check In</MenuItem>
                    <MenuItem value="check_out">Check Out</MenuItem>
                    <MenuItem value="others">Other</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    select
                    label="Paid By"
                    name="paid_by"
                    value={state.paid_by}
                    fullWidth
                    onChange={handleChange}
                >
                    <MenuItem value="" disabled>-- Select Paid By --</MenuItem>
                    <MenuItem value="owner">Owner</MenuItem>
                    {
                        state.use_closing &&
                        <MenuItem value="tenant">Tenant</MenuItem>
                    }
                    {
                        state.use_closing &&
                        <MenuItem value="deposit">Deposit</MenuItem>
                    }
                    <MenuItem value="office">Jendela360</MenuItem>
                </TextField>
            </Grid>
            {
                state.paid_by ?
                    state.paid_by !== 'office' &&
                    <Grid item xs={12}>
                        <TextField
                            label={state.paid_by === 'deposit' ? 'Tenant Name' : `${capitalizeFirstCharacter(state.paid_by || "")} Name`}
                            value={state.paid_by === 'owner' ? detail.owner_name : detail.tenant_name || detail.customer_name}
                            disabled
                            fullWidth
                        />
                    </Grid> : null

            }
            <Grid item xs={6}>
                <CustomDatePicker
                    value={requestDateTime.request_date}
                    onChange={(value: any) => handleRequestDate(value)}
                />
            </Grid>
            <Grid item xs={6}>
                <CustomTimePicker
                    value={requestDateTime.request_time}
                    onChange={(value: any) => handleRequestTime(value)}
                />
            </Grid>
            {
                state.key_collection_point_option === 'Others' ?
                    <Grid item xs={12}>
                        <TextField
                            label="Key Collection Point"
                            fullWidth
                            name="key_collection_point"
                            placeholder='Lokasi - Nama - No. HP atau Jendela360'
                            value={state.key_collection_point}
                            onChange={handleChange}
                        />
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <TextField
                            select
                            label="Key Collection Point"
                            name="key_collection_point_option"
                            value={state.key_collection_point_option}
                            fullWidth
                            onChange={handleChange}
                        >
                            <MenuItem value="" disabled>-- Select Key Collection Point --</MenuItem>
                            <MenuItem value="Kantor Jendela360">Kantor Jendela360</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                        </TextField>
                    </Grid>
            }
            <Grid item xs={12}>
                <TextArea
                    label="Notes"
                    name='notes'
                    value={state.notes}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    )
}

export default OrderDetailForm
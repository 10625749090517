import React, { useEffect, useRef, useState } from 'react'
import { TextField, InputAdornment, Grid, Theme, CircularProgress, Box, Dialog, Typography, MenuItem, Button } from '@mui/material'

// Components
import AsyncMultipleAutoComplete from '../../_components/_form/AsycMultipleAutoComplete';
import UnitAdvisorDetailModalNew, { IUnitAdvisorDetailData } from '../_unitAdvisor/UnitAdvisorDetailModalNew';

/* 
* Utils
*/
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertIndonesiaPhoneNumber, generalErrorHandler, isMobile } from '../../_utils/Helper';
import { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

/* 
* Icons
*/
import SearchIcon from '@mui/icons-material/Search';
import Items from './components/Items';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { generateStyle } from '../../_utils/DefaultStyle';



export type UnitManagementListType = '' | 'new-available' | 'available' | 'unavailable' | 'rented'

export interface UnitManagementListData {
    apartment_name: string,
    basic_price_12: number,
    category: number,
    code: string,
    floor_level: number,
    furnished: string,
    id: number,
    last_owner_contacted_at: Date | null,
    pic_landlord_phone: number,
    rent_date: Date | null,
    tower: string,
    unit_number: number,
    extend_status_label: string
    start_date: Date | null
    end_date: Date | null
    customer_name: string
    duration: number
    updated_at: Date | null
    is_dollar: number
    available_at: Date | null
}

export interface IUnitManagementFilterData {
    bedroom: string
    furnished: string
    apartment_ids: IAutoCompleteOption[]
}

const buttons = [
    { value: 'new-available', label: 'New' },
    { value: 'available', label: 'Available' },
    { value: 'unavailable', label: 'Unavailable' },
    { value: 'rented', label: 'Rented' },
]

const UnitManagementList = () => {
    const { Root, classes } = useStyles()
    const ListRef = useRef<any>(null)
    const [status, setStatus] = useState<string>('new-available')
    const [items, setItems] = useState<UnitManagementListData[]>([])
    const [currentPage, setCurrentPage] = useState(1)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [query, setQuery] = useState('')
    const [modalDetail, setModalDetail] = useState(false);
    const [unit, setUnit] = useState<IUnitAdvisorDetailData>({} as IUnitAdvisorDetailData);
    const [openedData, setOpenedData] = useState<any[]>([])
    const [flags, setFlags] = useState({
        isInit: true,
        hasMore: true,
        isLoading: true,
        isRefreshing: false,
        isChanged: false,
    })

    const handleOpenDetail = () => setModalDetail(true);
    const handleCloseDetail = () => setModalDetail(false);

    const [filterData, setFilterData] = useState<IUnitManagementFilterData>(initialFilterData)

    useEffect(() => {
        document.body.style.backgroundColor = '#eee';

        return () => {
            document.body.style.backgroundColor = '#fff';
        }
    }, []);

    useEffect(() => {
        fetchData(true)
        setFlags(prev => ({ ...prev, isInit: false }))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!flags.isInit) {
            const timeOutFetch = setTimeout(() => {
                fetchData(true)
            }, 500)
            return () => {
                clearTimeout(timeOutFetch)
            }
        }
        // eslint-disable-next-line
    }, [query])

    useEffect(() => {
        if (!flags.isInit) {
            resetFilter()
            setQuery('')
            fetchData(true, true, true)
        }
        // eslint-disable-next-line
    }, [status])

    useEffect(() => {
        if (!isFilterOpen && !flags.isInit) {
            if (flags.isChanged) {
                fetchData(true)
            }
        }
        // eslint-disable-next-line
    }, [isFilterOpen, flags.isChanged])

    useEffect(() => {
        if (!flags.isInit) {
            setFlags(prev => ({ ...prev, isChanged: true }))
        }
        // eslint-disable-next-line
    }, [filterData])

    const trackActivity = (param: Object) => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-search-agent/track-activity`, param)
    }

    const fetchDetail = (unitId: string, track: boolean = false) => {
        if (!unitId) {
            return false;
        }

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-search-agent/detail`, { unitId })
            .then(res => {
                setUnit(res.data);
                handleOpenDetail();
                if (track) {
                    trackActivity({ unitId: unitId });
                }
            })
            .catch(err => {
                generalErrorHandler(err);
            })
    }

    const filterAmount = () => {
        let amount = 0

        if (filterData.bedroom) amount++
        if (filterData.furnished) amount++
        if (filterData.apartment_ids.length) amount++

        return amount
    }

    const handleChangeSearch = (e: any) => {
        setQuery(e.target.value)
    }

    const handleChangeStatus = (value: string) => {
        setStatus(value)
    }

    const resetFilter = () => {
        if (filterAmount() > 0) {
            setFilterData({
                ...initialFilterData,
            })
        }
    }

    const checkCookie = (cname: string) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    const handleDetail = (id: string) => {
        let seen = checkCookie("pm_seen_unit")
        if (!seen.includes(id)) {
            if (openedData) {
                setOpenedData(openedData.concat([id]))
            } else {
                setOpenedData([id])
            }

            var expired = new Date();
            expired.setHours(23, 59, 59, 0);

            document.cookie = `pm_seen_unit=${openedData.concat([id])};expires=${expired};`
        }
        fetchDetail(id, true)
    }

    const fetchData = (isFiltered?: boolean, resetSearch?: boolean, resetFilter?: boolean) => {
        setFlags(prev => ({ ...prev, isLoading: true }))

        if (isFiltered) {
            ListRef.current?.scrollTo({ top: 0 })
            setItems([])
        } else {
            window.scrollTo(0, document.body.scrollHeight)
        }

        const dataParam = {
            page: isFiltered ? 1 : currentPage,
            bedroom: resetFilter ? null : filterData.bedroom || '',
            furnished: resetFilter ? null : filterData.furnished || '',
            search: resetSearch ? null : query,
            apartment_id: resetFilter ? null : filterData.apartment_ids.map(apartment_id => apartment_id.id)
        }

        DefaultAxios?.get(`${process.env.REACT_APP_API_URL}/unit-management/${status}`, { params: dataParam })
            .then(res => res.data)
            .then(data => {
                const hasMore = data.meta.current_page < data.meta.last_page
                setItems(prev => isFiltered ? data.data : prev.concat(data.data))
                setCurrentPage(prev => isFiltered ? 2 : prev + 1)
                setFlags(prev => ({ ...prev, isLoading: false, isRefreshing: false, hasMore }))
            })
            .catch((err) => generalErrorHandler(err))
            .finally(() => {
                setFlags(prev => ({ ...prev, isLoading: false, isRefreshing: false }))
            })
    }

    const contactOwner = (phone: string, id: string) => {
        const cleanedPhoneNumber = convertIndonesiaPhoneNumber(phone.replace(/[^0-9]/, ''))

        window.open(`https://api.whatsapp.com/send?phone=${cleanedPhoneNumber}`)
        updateContactOwner(id)
    }

    const updateContactOwner = (id: string) => {
        DefaultAxios?.post(`${process.env.REACT_APP_API_URL}/unit-management/contact-owner`, { id })
            .then(res => res.data)
            .then(data => {
                fetchData(true)
            })
            .catch((err) => generalErrorHandler(err))
            .finally(() => {
                setFlags(prev => ({ ...prev, isLoading: false, isRefreshing: false }))
            })
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption[]) => {
        setFilterData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        let value: any = target.value;

        setFilterData(prev => ({
            ...prev,
            [target.name]: value,
        }))
    }

    return (
        <Root>
            <Dialog
                fullScreen
                open={modalDetail}
                onClose={handleCloseDetail}
            >
                <UnitAdvisorDetailModalNew
                    data={unit}
                    fetchDetail={fetchDetail}
                    onClose={handleCloseDetail}
                />
            </Dialog>
            <Dialog
                open={isFilterOpen}
                onClose={() => { setIsFilterOpen(false) }}
                scroll="body"
                fullScreen
            >
                <Root>
                    <Grid sx={{ p: 2 }}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography fontWeight={'bold'} fontSize={18} alignSelf='center'>Filter</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container alignItems='center' justifyContent='right'>
                                    <Typography color='#00AEEF' fontSize={16} style={{ alignSelf: 'center', marginRight: 12 }} onClick={resetFilter}>Reset</Typography>
                                    <CloseIcon
                                        fontSize='large'
                                        onClick={() => setIsFilterOpen(false)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <hr></hr>
                        <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
                            <AsyncMultipleAutoComplete
                                value={filterData.apartment_ids}
                                label="Cari nama apartemen"
                                name="apartment_ids"
                                onChange={handleAutocomplete}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/apartment`}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 3 }}>
                            <TextField
                                select
                                fullWidth
                                label="Tipe Bedroom"
                                name="bedroom"
                                value={filterData.bedroom}
                                onChange={handleFilterChange}
                                variant="outlined"
                            >
                                <MenuItem value={''}>Semua</MenuItem>
                                <MenuItem value={'0'}>Studio</MenuItem>
                                <MenuItem value={'1'}>1 Bedroom</MenuItem>
                                <MenuItem value={'2'}>2 Bedrooms</MenuItem>
                                <MenuItem value={'3'}>3 Bedrooms</MenuItem>
                                <MenuItem value={'4'}>4+ Bedrooms</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 3 }}>
                            <TextField
                                select
                                fullWidth
                                label="Tipe Furnish"
                                name="furnished"
                                value={filterData.furnished}
                                onChange={handleFilterChange}
                                variant="outlined"
                            >
                                <MenuItem value={''}>Semua</MenuItem>
                                <MenuItem value={'full'}>Full Furnished</MenuItem>
                                <MenuItem value={'semi'}>Semi Furnished</MenuItem>
                                <MenuItem value={'non'}>Non Furnished</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} style={{ position: 'absolute', bottom: 20, left: 0, width: '100%', padding: '0px 16px' }}>
                            <Button
                                style={{ width: '100%' }}
                                variant="contained"
                                color="primary"
                                onClick={() => setIsFilterOpen(false)}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Root>
            </Dialog>
            <Grid container>
                <Grid item xs={12}>
                    <div
                        className={classes.buttonGroup} style={{ position: isMobile() ? 'fixed' : 'unset' }}
                    >
                        {
                            buttons.map((button) => (
                                <button
                                    key={button.value}
                                    className={classes.button}
                                    disabled={status === button.value}
                                    onClick={() => handleChangeStatus(button.value)}
                                >
                                    {button.label}
                                </button>
                            ))
                        }
                    </div>
                </Grid>
                {
                    status !== 'new-available' ?
                        <Grid container sx={{ py: 2, mt: 4 }}>
                            <Grid item xs={10}>
                                <TextField
                                    sx={{ backgroundColor: '#fff' }}
                                    name='query'
                                    fullWidth
                                    value={query}
                                    placeholder={`Masukkan kode unit ${status === 'rented' ? '/ nama tenant' : ''}`}
                                    onChange={handleChangeSearch}
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position='start'>
                                                <SearchIcon />
                                            </InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <div className={classes.filter} onClick={() => { setIsFilterOpen(true) }}>
                                    <FilterAltIcon
                                        fontSize='medium'
                                    />
                                    {
                                        filterAmount() ?
                                            <div className={classes.countBadge}>
                                                <span className={classes.countBadgeText}>{filterAmount()}</span>
                                            </div>
                                            : null
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        : <Grid container sx={{ py: 2, mt: 2 }}></Grid>
                }

                <Grid ref={ListRef} item xs={12}>
                    {
                        items.length > 0 ?
                            items.map((data: any) => (
                                <Items
                                    {...data}
                                    key={data.id}
                                    data={data}
                                    activeTab={status}
                                    onClick={() => handleDetail(data.id)}
                                    onWaPress={(e: any) => {
                                        e.stopPropagation();
                                        contactOwner(data.pic_landlord_phone.toString(), data.id.toString())
                                    }} />
                            )) :
                            <Box className={classes.loader}>
                                No List Found!
                            </Box>
                    }
                    {flags.isLoading ?
                        <Box sx={{ display: 'flex' }} className={classes.loader}>
                            <CircularProgress />
                        </Box>
                        : null
                    }
                    {
                        flags.hasMore ?
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => fetchData()}
                                >
                                    Load more
                                </Button>
                            </div>
                            : null
                    }
                </Grid>
            </Grid>
        </Root>
    )
}

export default UnitManagementList

const useStyles = generateStyle((theme: Theme) =>
({
    loader: {
        width: '100%',
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonGroup: {
        position: 'fixed',
        height: '40px',
        top: '56px',
        width: "100%",
        left: 0,
        display: 'flex',
        zIndex: 1101,
    },
    button: {
        flex: 'auto',
        outline: 0,
        border: 0,
        fontWeight: '600',
        color: '#484848',
        background: '#fff',
        borderBottom: '1px solid #B8B8B8',
        boxSizing: 'content-box',
        "&:disabled": {
            borderBottom: '3px solid #009be5 !important',
        }
    },
    filter: {
        padding: 8,
        backgroundColor: '#fff',
        border: '1px solid #0000001F',
        borderRadius: 6,
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        marginLeft: 10,
        position: 'relative'
    },
    countBadge: {
        backgroundColor: '#e8effa',
        borderRadius: 16,
        margin: 2,
        position: 'absolute',
        width: 18,
        height: 18,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        right: 0,
        top: 0,
        display: 'flex',
    },
    countBadgeText: {
        color: '#003595',
        fontSize: 11,
    },
}), "UnitManagementList"
)

const initialFilterData = {
    bedroom: '',
    furnished: '',
    apartment_ids: []
}


import { CircularProgress, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import DefaultAxios from '../../../../_utils/DefaultAxios'
import {
    generalErrorHandler,
    numberToCurrency,
    // openExternalLink, 
    renderWarningButton
} from '../../../../_utils/Helper'
import CreditDetailModal from './CreditDetailModal'
import DebitDetailModal from './DebitDetailModal'
import ProposeTransactionForm from './ProposeTransactionForm'
import { useInView } from 'react-intersection-observer'
import { generateStyle } from '../../../../_utils/DefaultStyle'
// import Swal from 'sweetalert2';

interface IProps {
    closingData: any
    setIsLoading: (isLoading: boolean) => void
    permissions: any
    generateTransactionInformation: () => any[]
    refreshFlag: number
}

export interface TransactionCredit {
    id: number
    is_manual_assign: number
    date: string
    code: string
    description: string
    amount: number
    mapped_by: string
    transaction_type: string
    created_at: string
}

export interface TransactionDebit {
    id: number
    is_manual_assign: number
    status: string
    date: string
    code: string
    transaction_type: string
    bank_information: string
    amount: number
    proposed_by: string
    description: string
    other_transaction_type_label: string
    created_at: string
}

const DepositTransactionList = (props: IProps) => {
    const { Root, classes } = useStyles()
    const [data, setData] = useState<{
        CR_DEPOSIT: TransactionCredit[]
        DB_DEPOSIT: TransactionDebit[]
    }>({
        CR_DEPOSIT: [],
        DB_DEPOSIT: [],
    })

    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: '100px 0px 100px 0px'
    })

    const [hasBeenInView, setHasBeenInView] = useState(false)

    useEffect(() => {
        if (inView) setHasBeenInView(true)
    }, [inView])

    const [isProposeOpen, setIsProposeOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isCancelLoading, setIsCancelLoading] = useState(false)

    const [selectedCreditId, setSelectedCreditId] = useState<number | null>(null)
    const [selectedDebitId, setSelectedDebitId] = useState<number | null>(null)

    const [selectedCredit, setSelectedCredit] = useState<TransactionCredit | null>(null)
    const [selectedDebit, setSelectedDebit] = useState<TransactionDebit | null>(null)

    useEffect(() => {
        if (props.closingData.id && hasBeenInView) {
            fetchData()
        }
        // eslint-disable-next-line
    }, [hasBeenInView, props.closingData.id])

    useEffect(() => {
        if (props.closingData.id && hasBeenInView && props.refreshFlag) {
            fetchData()
        }
        // eslint-disable-next-line
    }, [hasBeenInView, props.refreshFlag, props.closingData.id])

    useEffect(() => {
        if (selectedCreditId) {
            // props.setIsLoading(true)

            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/closing-transaction-propose/${selectedCreditId}`)
                .then(res => res.data)
                .then(data => {
                    setSelectedCredit(data)
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    // props.setIsLoading(false)
                })
        }
        // eslint-disable-next-line
    }, [selectedCreditId])

    useEffect(() => {
        if (selectedDebitId) {
            // props.setIsLoading(true)

            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/closing-transaction-propose/${selectedDebitId}`)
                .then(res => res.data)
                .then(data => {
                    setSelectedDebit(data)
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    // props.setIsLoading(false)
                })
        }
        // eslint-disable-next-line
    }, [selectedDebitId])

    const totalCredit = useMemo(() => data.CR_DEPOSIT.reduce((prev, current) => prev + current.amount, 0), [data.CR_DEPOSIT])
    const totalDebit = useMemo(() => data.DB_DEPOSIT.filter(db => ['processed', 'released'].includes(db.status)).reduce((prev, current) => prev + current.amount, 0), [data.DB_DEPOSIT])
    const totalProposedDebit = useMemo(() => data.DB_DEPOSIT.filter(db => !['processed', 'released'].includes(db.status)).reduce((prev, current) => prev + current.amount, 0), [data.DB_DEPOSIT])
    const availableFunds = useMemo(() => Math.max(totalCredit - (totalDebit + totalProposedDebit), 0), [totalCredit, totalDebit, totalProposedDebit])

    const LIMIT_SEWA_OWNER = props.closingData.basic_price - data.DB_DEPOSIT.filter(db => db.transaction_type === 'rent_owner').reduce((prev, next) => prev + next.amount, 0)
    const LIMIT_DEPOSIT_OWNER = props.closingData.holded_deposit_owner - data.DB_DEPOSIT.filter(db => db.transaction_type === 'deposit_owner').reduce((prev, next) => prev + next.amount, 0)
    const LIMIT_DEPOSIT_JENDELA = props.closingData.holded_deposit_office - data.DB_DEPOSIT.filter(db => db.transaction_type === 'deposit_jendela').reduce((prev, next) => prev + next.amount, 0)
    const LIMIT_KOMISI_JENDELA = props.closingData.revenue_jendela - data.DB_DEPOSIT.filter(db => db.transaction_type === 'commission_jendela').reduce((prev, next) => prev + next.amount, 0)
    const LIMIT_KOMISI_COBROKE = props.closingData.cobroke_fee - data.DB_DEPOSIT.filter(db => db.transaction_type === 'commission_cobroke').reduce((prev, next) => prev + next.amount, 0)

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/closing-transaction-propose`, {
            params: {
                id: props.closingData.id,
            }
        })
            .then(res => res.data)
            .then(data => setData(data))
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const cancelTransaction = () => {
        renderWarningButton("Apakah anda yakin ingin me-cancel transaksi ini?")
            .then((result) => {
                if (result.value) {
                    setIsCancelLoading(true)

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-transaction-propose/cancel`, {
                        id: selectedDebitId,
                    })
                        .then(res => res.data)
                        .then(data => {
                            setSelectedDebit(null)
                            setSelectedDebitId(null)
                            fetchData()
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsCancelLoading(false)
                        })
                }
            })
    }

    return (
        <Root>
            <ProposeTransactionForm
                isOpen={isProposeOpen}
                onClose={() => setIsProposeOpen(false)}
                availableFunds={availableFunds}
                // basicPrice={2000000}
                basicPrice={props.closingData.basic_price}
                closingId={props.closingData.id}
                fetchData={fetchData}
                generateTransactionInformation={props.generateTransactionInformation}
                LIMIT_SEWA_OWNER={LIMIT_SEWA_OWNER}
                LIMIT_DEPOSIT_OWNER={LIMIT_DEPOSIT_OWNER}
                LIMIT_DEPOSIT_JENDELA={LIMIT_DEPOSIT_JENDELA}
                LIMIT_KOMISI_JENDELA={LIMIT_KOMISI_JENDELA}
                LIMIT_KOMISI_COBROKE={LIMIT_KOMISI_COBROKE}
                LIMIT_DEPOSIT_AGENT={0}
                LIMIT_SEWA_AGENT={0}
                closingData={props.closingData}
                isDeposit
            />
            <CreditDetailModal
                credit={selectedCredit}
                onClose={() => {
                    setSelectedCredit(null)
                    setSelectedCreditId(null)
                }}
            />
            <DebitDetailModal
                debit={selectedDebit}
                onClose={() => {
                    setSelectedDebit(null)
                    setSelectedDebitId(null)
                }}
                onCancel={cancelTransaction}
                isCancelLoading={isCancelLoading}
                closingData={props.closingData}
            />
            <Paper ref={ref}>
                <div className={classes.root}>
                    <div className={classes.cardTitleContainer}>
                        <span className={classes.cardTitle}>
                            Transaksi Deposit
                        </span>
                        {/* {
                            !!props.permissions['closing-transaction.propose-deposit'] &&
                            <div className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size='small'
                                    onClick={() => {
                                        // if (availableFunds <= 0) {
                                        //     Swal.fire({
                                        //         title: 'WARNING',
                                        //         text: 'Nilai debit bermasalah, harap hubungi admin',
                                        //         icon: 'warning',
                                        //         showCancelButton: false,
                                        //         confirmButtonColor: '#3085d6',
                                        //         cancelButtonColor: '#d33',
                                        //         confirmButtonText: 'Hubungi Admin'
                                        //     })
                                        //         .then(result => {
                                        //             if (result.value) {
                                        //                 openExternalLink('https://wa.me/6285719391749')
                                        //             }
                                        //         })
                                        // } else {
                                        //     setIsProposeOpen(true)
                                        // }

                                        setIsProposeOpen(true)

                                    }}
                                >
                                    Propose Transaksi
                                </Button>
                            </div>
                        } */}
                    </div>
                    <Divider className={classes.divider} />
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <span className={classes.sectionTitle}>
                                            Credit
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    isLoading ?
                                        <TableRow>
                                            <TableCell colSpan={3} align='center'>
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                        :
                                        data.CR_DEPOSIT.map((credit, index) =>
                                            <TableRow key={credit.code}>
                                                <TableCell className={`${(index === data.CR_DEPOSIT.length - 1) ? '' : classes.tdBody} ${classes.td20}`}>
                                                    {moment(credit.created_at).format("DD MMMM YYYY")}
                                                </TableCell>
                                                <TableCell className={(index === data.CR_DEPOSIT.length - 1) ? '' : classes.tdBody}>
                                                    <span className={classes.code} onClick={() => setSelectedCreditId(credit.id)}>
                                                        {credit.code || '-'}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={`${(index === data.CR_DEPOSIT.length - 1) ? '' : classes.tdBody} ${classes.td20}`}>
                                                    {numberToCurrency(credit.amount, 'Rp')}
                                                </TableCell>
                                            </TableRow>
                                        )
                                }
                                <TableRow>
                                    <TableCell className={classes.tdBody} />
                                    <TableCell className={`${classes.tdBody} ${classes.totalText}`}>
                                        Total
                                    </TableCell>
                                    <TableCell className={`${classes.tdBody} ${classes.totalText}`}>
                                        {numberToCurrency(totalCredit, 'Rp')}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br /><br />
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <span className={classes.sectionTitle}>
                                            Debit
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    isLoading ?
                                        <TableRow>
                                            <TableCell colSpan={3} align='center'>
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                        :
                                        data.DB_DEPOSIT.map((debit, index) =>
                                            <TableRow key={debit.code}>
                                                <TableCell className={`${(index === data.DB_DEPOSIT.length - 1) ? '' : classes.tdBody} ${classes.td20}`}>
                                                    {moment(debit.created_at).format("DD MMMM YYYY")}
                                                </TableCell>
                                                <TableCell className={(index === data.DB_DEPOSIT.length - 1) ? '' : classes.tdBody}>
                                                    <span className={classes.code} onClick={() => setSelectedDebitId(debit.id)}>
                                                        {debit.code || '-'} {debit.is_manual_assign ? '[Manual Assign]' : ''}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={`${(index === data.DB_DEPOSIT.length - 1) ? '' : classes.tdBody} ${classes.td20}`}>
                                                    {numberToCurrency(debit.amount, 'Rp')}
                                                </TableCell>
                                            </TableRow>
                                        )
                                }
                                <TableRow>
                                    <TableCell className={classes.tdBody} />
                                    <TableCell className={`${classes.tdBody} ${classes.totalText}`}>
                                        Total
                                    </TableCell>
                                    <TableCell className={`${classes.tdBody} ${classes.totalText}`}>
                                        {numberToCurrency(totalDebit, 'Rp')}{totalProposedDebit > 0 ? <span style={{ opacity: 0.4 }}>&nbsp;&nbsp;&nbsp;({numberToCurrency(totalProposedDebit, 'Rp')})</span> : null}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
    },
    buttonContainer: {
        // alignSelf: 'flex-end',
    },
    sectionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        // color: theme.palette.primary.main,
    },
    tdBody: {
        borderColor: 'transparent',
    },
    totalText: {
        fontWeight: 'bold',
    },
    code: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        transition: '150ms color linear',

        '&:hover': {
            color: theme.palette.primary.dark,
        }
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 8,
        fontSize: 16,
    },
    value: {
        marginBottom: 16,
        fontSize: 16,
    },
    td20: {
        width: '20%',
    },
    cardTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 8,
        marginBottom: 12,
    },
    cardTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        marginTop: 4,
    },
    divider: {
        marginBottom: 24,
    },
}), "DepositTransaction_List")

export default DepositTransactionList

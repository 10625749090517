import React, { useState, useEffect, useReducer } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tabs, Tab, Paper, Box, Theme, TextField } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';

// Data
import { options, templates as mainTemplates, alternativeTemplates, ITemplate } from '../../_data/inquiryData';
import { generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface Props {
    closing: any;
    isOpen: boolean;
    handleClose: Function;
}

interface IState {
    extra_param1: string | number;
    extra_param2: string | number;
    extra_param3: string | number;
    extra_param4: string | number;
    extra_param5: string | number;
}

interface IErrorState {
    extra_param1: string;
    extra_param2: string;
    extra_param3: string;
    extra_param4: string;
    extra_param5: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const useStyles = generateStyle((theme: Theme) => ({
    modalBody: {
        '& > div': {
            marginBottom: theme.spacing(2)
        },
        '& .MuiBox-root': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiBox-root > span': {
            width: '100px'
        }
    },
    well: {
        padding: theme.spacing(1),
        backgroundColor: '#eee'
    }
}), "SendInquiry_Modal"
);

const SendInquiryModal = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/send-inquiry';
    const { Root, classes } = useStyles();

    const initialState = {
        extra_param1: '',
        extra_param2: '',
        extra_param3: '',
        extra_param4: '',
        extra_param5: ''
    }

    const initialErrorState = {
        extra_param1: '',
        extra_param2: '',
        extra_param3: '',
        extra_param4: '',
        extra_param5: ''
    }

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialErrorState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputValue] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    const [activeTab, setActiveTab] = useState(0);
    const [templateValue, setTemplateValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [templates, setTemplates] = useState<ITemplate>({});

    useEffect(() => {
        setInputValue({ name: '', value: initialState, type: 'REPLACE_STATE' });
        // eslint-disable-next-line
    }, [templateValue]);

    useEffect(() => {
        setTemplates(+props.closing.cobroke_type === 1 ? alternativeTemplates : mainTemplates);
        setTemplateValue('');
        setInputValue({ name: '', value: initialState, type: 'REPLACE_STATE' });
        setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        // eslint-disable-next-line
    }, [props.isOpen]);

    const handleClose = () => {
        props.handleClose();
    };

    const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTemplateValue('');
        setActiveTab(newValue);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue({ name: e.target.name, value: e.target.value, type: 'SET_ITEM' });
    }

    const showPhoneNumber = () => {
        let phone = '-';
        switch (props.closing.cobroke_type) {
            case 0:
                if (activeTab === 0) {
                    phone = props.closing.owner_phone_label;
                } else {
                    phone = props.closing.customer ? props.closing.customer.phone : '-';
                }
                break;
            case 1:
                if (activeTab === 0) {
                    phone = props.closing.agent ? props.closing.agent.phone : '-';
                } else {
                    phone = props.closing.customer ? props.closing.customer.phone : '-';
                }
                break
            case 2:
                if (activeTab === 0) {
                    phone = props.closing.owner_phone_label;
                } else {
                    phone = props.closing.agent ? props.closing.agent.phone : '-';
                }
                break
            default:
                break;
        }

        return phone;
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!templateValue) {
            isValid = false;
        } else {
            const template = templates[templateValue];

            if (template.extraParam1 && !inputState.extra_param1) {
                newError.extra_param1 = 'Extra param wajib diisi';
                isValid = false;
            }

            if (template.extraParam2 && !inputState.extra_param2) {
                newError.extra_param2 = 'Extra param 2 wajib diisi';
                isValid = false;
            }

            if (templateValue === 'owner_renewal') {
                if (+props.closing.cobroke_type === 1) {
                    if (isNaN(inputState.extra_param2 as number)) {
                        newError.extra_param2 = 'Extra param 2 harus berupa angka!';
                        isValid = false;
                    }
                } else {
                    if (isNaN(inputState.extra_param1 as number)) {
                        newError.extra_param1 = 'Extra param harus berupa angka!';
                        isValid = false;
                    }
                }
            } else if (templateValue === 'price_update_to_tenant') {
                if (isNaN(inputState.extra_param1 as number)) {
                    newError.extra_param1 = 'Extra param harus berupa angka!';
                    isValid = false;
                }

                if (isNaN(inputState.extra_param2 as number)) {
                    newError.extra_param2 = 'Extra param 2 harus berupa angka!';
                    isValid = false;
                }
            }
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return false;
        }

        const data = {
            id: props.closing.id,
            type: activeTab === 0 ? 1 : 2,
            template: templateValue,
            template_text: templates[templateValue].template,
            extra_param: inputState.extra_param1,
            extra_param2: inputState.extra_param2,
            extra_param3: inputState.extra_param3,
            extra_param4: inputState.extra_param4,
            extra_param5: inputState.extra_param5
        }

        setIsLoading(true);
        DefaultAxios.post(API_URL, data)
            .then(res => {
                Swal.fire({
                    title: "Inquiry Sent",
                    icon: 'success',
                    onAfterClose: handleClose,
                    timer: 1000
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            scroll="body"
        >
            <LoadingScreen open={isLoading} fullScreen />
            <Root>
                <DialogTitle>Select Inquiry Template</DialogTitle>
                <DialogContent className={classes.modalBody}>
                    <Tabs
                        value={activeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTab}
                    >
                        <Tab label="Owner" />
                        <Tab label="Tenant" />
                    </Tabs>
                    {
                        templateValue !== '' &&
                        <Paper className={classes.well} dangerouslySetInnerHTML={{ __html: templates[templateValue].template }}></Paper>
                    }
                    <Box>
                        <span>Phone</span>
                        <div>
                            <span>{showPhoneNumber()}</span>
                        </div>
                    </Box>
                    <Box>
                        <span>Template</span>
                        <TextField
                            select
                            size="small"
                            variant="outlined"
                            value={templateValue}
                            onChange={(e) => setTemplateValue(e.target.value)}
                            SelectProps={{
                                native: true
                            }}
                        >
                            <option value="">-- Select Template --</option>
                            {options[activeTab].map((item: any) => <option value={item.key} key={item.key}>{item.label}</option>)}
                        </TextField>
                    </Box>
                    {
                        (templateValue !== '' && templates[templateValue].extraParam1) &&
                        <Box>
                            <span>Extra Param</span>
                            <TextField
                                size="small"
                                variant="outlined"
                                name="extra_param1"
                                value={inputState.extra_param1}
                                onChange={handleChange}
                                error={!!errorState.extra_param1}
                                helperText={errorState.extra_param1}
                            />
                        </Box>
                    }
                    {
                        (templateValue !== '' && templates[templateValue].extraParam2) &&
                        <Box>
                            <span>Extra Param 2</span>
                            <TextField
                                size="small"
                                variant="outlined"
                                name="extra_param2"
                                value={inputState.extra_param2}
                                onChange={handleChange}
                                error={!!errorState.extra_param2}
                                helperText={errorState.extra_param2}
                            />
                        </Box>
                    }
                    {
                        (templateValue !== '' && templates[templateValue].extraParam3) &&
                        <Box>
                            <span>Extra Param 3</span>
                            <TextField
                                size="small"
                                variant="outlined"
                                name="extra_param3"
                                value={inputState.extra_param3}
                                onChange={handleChange}
                                error={!!errorState.extra_param3}
                                helperText={errorState.extra_param3}
                            />
                        </Box>
                    }
                    {
                        (templateValue !== '' && templates[templateValue].extraParam4) &&
                        <Box>
                            <span>Extra Param 4</span>
                            <TextField
                                size="small"
                                variant="outlined"
                                name="extra_param4"
                                value={inputState.extra_param4}
                                onChange={handleChange}
                                error={!!errorState.extra_param4}
                                helperText={errorState.extra_param4}
                            />
                        </Box>
                    }
                    {
                        (templateValue !== '' && templates[templateValue].extraParam5) &&
                        <Box>
                            <span>Extra Param 5</span>
                            <TextField
                                size="small"
                                variant="outlined"
                                name="extra_param5"
                                value={inputState.extra_param5}
                                onChange={handleChange}
                                error={!!errorState.extra_param5}
                                helperText={errorState.extra_param5}
                            />
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Send
                    </Button>
                </DialogActions>
            </Root>
        </Dialog>
    );
}

export default SendInquiryModal;

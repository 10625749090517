import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { Button, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';

// Icons
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/Delete';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';

interface Props {
    unitId: string | null;
}

interface IState {
    file: File | null;
    type: string;
    notes: string;
}

interface IErrorState {
    file: string;
    type: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface UnitFile {
    id: string;
    filename: string;
    url: string;
    type: string;
    notes: string;
}

const UnitFileForm = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/unit/' + props.unitId + '/file';

    const initialState = {
        file: null,
        type: '',
        notes: ''
    };

    const initialErrorState = {
        file: '',
        type: ''
    };

    const fileTypes = [
        {
            value: 'list_inventory',
            label: 'List Inventory'
        },
        {
            value: 'payment_proof',
            label: 'Bukti Pembayaran'
        },
        {
            value: 'owner_agreement_star_listing',
            label: 'Bukti Perjanjian Star Listing'
        },
        {
            value: 'owner_document_ownership',
            label: 'Bukti Kepemilikan Unit'
        },
        {
            value: 'surat_izin_memasarkan',
            label: 'Surat Izin Memasarkan'
        },
        {
            value: 'surat_kuasa',
            label: 'Surat Kuasa'
        },
        {
            value: 'receipt',
            label: 'Tanda Terima'
        },
        {
            value: 'other',
            label: 'Lainnya'
        }
    ];

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [isLoading, setIsLoading] = useState(false);
    const [unitFiles, setUnitFiles] = useState<UnitFile[]>([]);

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    useEffect(() => {
        loadFiles();
        // eslint-disable-next-line
    }, []);

    const loadFiles = () => {
        setIsLoading(true);
        DefaultAxios.get(API_URL)
            .then(res => {
                setUnitFiles(res.data);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (name === 'file') {
            if (target.files && target.files[0]) {
                value = target.files[0];
            } else {
                value = null;
            }
        }

        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.file) {
            newError.file = 'File wajib diisi';
            isValid = false;
        }

        if (!inputState.type) {
            newError.type = 'Type wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        const fd = new FormData();

        fd.append('file', inputState.file || '');
        fd.append('type', inputState.type);
        fd.append('notes', inputState.notes);

        setIsLoading(true);
        DefaultAxios.post(API_URL, fd)
            .then(res => {
                Swal.fire({
                    title: 'Submit berhasil',
                    icon: 'success',
                    timer: 1000,
                    onAfterClose: loadFiles
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleDeleteFile = (id: string) => {
        renderWarningButton('Apakah anda yakin ingin menghapus file ini?')
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${API_URL}/${id}`)
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: loadFiles
                            });
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <h3>File</h3>
                    <br />
                    <input type="file" name="file" onChange={handleChange} />
                    {
                        !!errorState.file &&
                        <>
                            <br />
                            <span style={{ color: 'red' }}>{errorState.file}</span>
                        </>
                    }
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        select
                        label="Type"
                        name="type"
                        value={inputState.type}
                        onChange={handleChange}
                    >
                        {fileTypes.map(fileType => <MenuItem value={fileType.value} key={fileType.value}>{fileType.label}</MenuItem>)}
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Notes"
                        name="notes"
                        value={inputState.notes}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <h1>List Files</h1>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>File</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    unitFiles.map(unitFile =>
                                        <TableRow key={unitFile.id}>
                                            <TableCell>{unitFile.filename}</TableCell>
                                            <TableCell>{unitFile.type || '-'}</TableCell>
                                            <TableCell>
                                                <Button
                                                    color="primary"
                                                    href={unitFile.url}
                                                    download={unitFile.filename}
                                                    startIcon={<GetAppIcon />}
                                                    target="_blank"
                                                >
                                                    Download
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    startIcon={<DeleteIcon />}
                                                    onClick={() => handleDeleteFile(unitFile.id)}
                                                >
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default UnitFileForm;
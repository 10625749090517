import React, { useState, useEffect } from "react";
import { Theme, Grid, Typography, Button, Card, CardContent } from '@mui/material';

/**
 * Components
 */
import DOMPurify from 'dompurify';

/**
 * Icons
 */
import VisibilityIcon from '@mui/icons-material/Visibility';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import LoadingScreen from '../../../_components/LoadingScreen';
import { format } from 'date-fns';
import { converNumberFormat } from "../../../_utils/Helper";
import { generateStyle } from "../../../_utils/DefaultStyle";

const useStyles = generateStyle((theme: Theme) =>
({
    card: {
        paddingBottom: '0.5rem',
        borderRadius: '0.5rem',
        marginBottom: '1rem',
        boxShadow: '12px 10px 30px #d9d9d9'
    },
    cardContent: {
        paddingBottom: '0.5rem !important',
        position: 'relative',
        paddingTop: '0.5rem !important'
    },
    priceGrid: {
        padding: '8px 8px 0px 8px',
        fontSize: '12px'
    }
}), "UnitAdvisorListCard"
)

interface IProps {
    apartment_id: string;
    category: string;
    basic_price_1: string;
    is_entrusted_key: string;
    rent_or_sell: string;
    display_price: string;
    floor_level: string;
    is_available: string;
    bedroom: string;
    bathroom: string;
    maid_room: string;
    furnished: string;
    tower: string;
    openDetail: Function
    openedData: any
}

interface IList {
    category_name: string
    code: string
    floor_level: number
    id: string
    is_owner: number
    is_sell: number
    display_price: number
    price_per_1_month: number
    price_per_1_month_in_dollar: number
    price_per_3_months: number
    price_per_6_months: number
    price_per_12_months: number
    tower: string
    unit_number: string
    starlisting_prices: number
    is_available: number
    rent_date: string
    updated_at: string
}

const UnitAdvisorListCard = (props: IProps) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/unit-search-agent`;

    const [page, setPage] = useState<number>(1)
    const [moreData, setMoreData] = useState(false)
    const { Root, classes } = useStyles()
    const [dataList, setDataList] = useState<IList[]>([])
    const [loading, setLoading] = useState(false);
    const DetailCell = ({ label, value, isOpened }: { label: string, value: string | number | JSX.Element, isOpened: boolean }) => {
        return (
            <Typography
                variant="subtitle2">
                <Grid container spacing={2}>
                    <Grid item xs={5} style={{ color: isOpened ? '#8E8E8E' : '#484848', fontWeight: '400' }}>
                        {label}
                    </Grid>
                    {
                        (typeof value === 'string' || typeof value === 'number') ?
                            <Grid item xs={7} alignItems={'flex-end'} style={{ color: isOpened ? '#8E8E8E' : '#252525', textAlign: 'right' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.toString()) }} />
                            :
                            <Grid item xs={7} alignItems={'flex-end'} style={{ color: isOpened ? '#8E8E8E' : '#252525', textAlign: 'right' }}>
                                {value}
                            </Grid>
                    }
                </Grid>
            </Typography>
        )
    }

    const fetchList = (loadMore?: boolean) => {
        setLoading(true)

        if (!loadMore) {
            setDataList([])
            setPage(1)
        }

        DefaultAxios.get(API_URL, {
            params: {
                ...props,
                rowsPerPage: 10,
                page: !loadMore ? 1 : page
            }
        })
            .then(res => {
                let items: IList[] = res.data.data
                const totalData = res.data.total
                const hasMore = !loadMore ? items.length < totalData : (dataList.length + items.length) < totalData

                setPage(prev => prev + 1)
                setDataList(!loadMore ? items : dataList.concat(items))
                setMoreData(hasMore)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const moreList = () => {
        fetchList(true)
    }

    const renderList = () => {
        let output: React.ReactNode = []

        if (dataList.length) {
            output = dataList.map((data, idx) => {
                let availablePrices = 'Not Available'
                let pricePerMonth = ''

                if ((data.starlisting_prices > 0 || data.price_per_1_month > 0 || data.price_per_3_months > 0 || data.price_per_6_months > 0 || data.price_per_12_months > 0)) {
                    let availablePricesArray = []

                    if (data.price_per_12_months > 0) {
                        availablePricesArray.push('1 Tahun')
                        pricePerMonth = (data.price_per_12_months / 12).toFixed(0)
                    }

                    if (data.price_per_6_months > 0) {
                        availablePricesArray.push('6 Bulan')
                        pricePerMonth = (data.price_per_6_months / 6).toFixed(0)
                    }

                    if (data.price_per_3_months > 0) {
                        availablePricesArray.push('3 Bulan')
                        pricePerMonth = (data.price_per_3_months / 3).toFixed(0)
                    }

                    if (data.price_per_1_month > 0) {
                        availablePricesArray.push('1 Bulan')
                        pricePerMonth = (data.price_per_1_month).toFixed(0)
                    }

                    if (data.starlisting_prices > 0) {
                        availablePricesArray.push('Star Listing')
                    }

                    availablePrices = availablePricesArray.join(' | ')
                }

                return (
                    <Card key={data.code} className={classes.card}>
                        <CardContent className={classes.cardContent} onClick={() => props.openDetail(data.id)}>
                            <Grid container justifyContent="space-between" style={{ alignItems: 'center', marginBottom: 8 }}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6" style={{ fontWeight: 'bold', fontSize: 18, color: props.openedData.includes(data.id) ? '#8E8E8E' : '#252525' }}>
                                        {data.code}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" style={{ color: props.openedData.includes(data.id) ? '#8E8E8E' : '#484848' }}>
                                        Tw. {data.tower ? data.tower : '-'} | Lt. {data.floor_level ? data.floor_level : '-'} | No. {data.unit_number ? data.unit_number : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{ backgroundColor: props.openedData.includes(data.id) ? '#F9F9F9' : '#ECF1F5', borderRadius: 4, padding: 10 }}>
                                <Grid item xs={12} sm={12}>
                                    <DetailCell
                                        label="Type"
                                        value={data.category_name}
                                        isOpened={props.openedData.includes(data.id)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <DetailCell
                                        label="Ownership"
                                        value={data.is_owner !== null ? data.is_owner === 1 ? 'Owner' : 'Agent' : '-'}
                                        isOpened={props.openedData.includes(data.id)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <DetailCell
                                        label="Available"
                                        value={data.is_available ? 'Ya' : 'Tidak'}
                                        isOpened={props.openedData.includes(data.id)}
                                    />
                                </Grid>
                                {!data.is_available &&
                                    <Grid item xs={12} sm={12}>
                                        <DetailCell
                                            label="Available Date"
                                            value={data.rent_date ? format(new Date(data.rent_date), 'dd MMMM yyyy') : 'No Information'}
                                            isOpened={props.openedData.includes(data.id)}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} sm={12}>
                                    <DetailCell
                                        label="Last Update"
                                        value={format(new Date(data.updated_at), 'dd MMMM yyyy')}
                                        isOpened={props.openedData.includes(data.id)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <DetailCell
                                        label="Supported Price"
                                        value={availablePrices}
                                        isOpened={props.openedData.includes(data.id)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} style={{ marginTop: 12 }}>
                                <Grid item xs={6} style={{ fontSize: 14, fontWeight: '700', color: props.openedData.includes(data.id) ? '#8E8E8E' : '#252525', textAlign: 'left' }}>
                                    {
                                        pricePerMonth !== '' ?
                                            <>
                                                Rp. {converNumberFormat(data.display_price.toString())} <span style={{ fontWeight: '400', color: props.openedData.includes(data.id) ? '#8E8E8E' : '#727272' }}>/Bulan</span>
                                            </>
                                            : 'Not Available'
                                    }
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        aria-label="close"
                                        href={`https://jendela360.com/${data.code}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={e => e.stopPropagation()}
                                        style={{
                                            backgroundColor: props.openedData.includes(data.id) ? '#F9F9F9' : undefined,
                                            color: props.openedData.includes(data.id) ? '#8E8E8E' : undefined,
                                        }}
                                    >
                                        <VisibilityIcon fontSize="small" />&nbsp;<span style={{ fontSize: 12, textTransform: 'capitalize' }}>View Unit</span>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )
            }
            )
            return output
        } else {
            return (
                <Grid container justifyContent="center">
                    <Typography
                        variant="subtitle2" style={{ marginTop: '22px' }}>
                        No data Found
                    </Typography>
                </Grid>
            )
        }
    }

    useEffect(() => {
        fetchList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Root>
            <LoadingScreen open={loading} fullScreen />
            <Grid>
                {renderList()}
            </Grid>
            {
                (dataList && moreData) &&
                <Grid container justifyContent="center">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => moreList()}
                    >
                        More Data
                    </Button>
                </Grid>
            }
        </Root>
    )
}

export default UnitAdvisorListCard
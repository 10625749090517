import React, { useState, useReducer, useEffect, useRef } from 'react';
import { Button, Grid, IconButton, Stack, Chip, Theme } from '@mui/material';
import { MentionsInput, Mention } from 'react-mentions'

/**
 * Components
 */
import LoadingScreen from './LoadingScreen';
import ImagePreview from './ImagePreview';

/**
 * Icon
 */
import defaultPhoto from '../Assets/Images/png/default-photo.png'

/**
 * CSS
 */
import './css/mention-style.css';

/**
 * Utils
 */
import DefaultAxios from '../_utils/DefaultAxios';
import { dateFormat, generalErrorHandler, renderToastSuccess, renderWarningButton } from '../_utils/Helper';

/**
 * Icons
 */
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { generateStyle } from '../_utils/DefaultStyle';

interface IProps {
    id: number | string;
    type: 'closing' | 'ticket' | 'unit' | 'activity' | 'enquiry' | 'rent';
    warnAttachment?: boolean
    initialData?: {
        comments: IComment[]
        mentions: IMentionList[]
    }
    ignoreMaxHeight?: boolean
}

export interface IMentionList {
    id: string;
    display: string;
}

export interface IMentionData {
    id: string;
    display: string;
    index: number;
    plainTextIndex: number;
    type: string;
}

interface IState {
    value: string;
    plainValue: string;
    mentionData: IMentionData[];
}

interface IAction {
    name: string,
    value: any,
    type: string
}

export interface IAttachment {
    comment_id: number
    created_at: string
    deleted_at: string
    filename: string
    filepath: string
    filepath_url: string
    id: number
    updated_at: string
}
export interface IComment {
    avatar: string;
    comment: string;
    activity_id: string;
    name: string;
    created_at: string;
    attachments: IAttachment[]
}

const NewCommentList = ({ id, type, warnAttachment = false, initialData, ignoreMaxHeight }: IProps) => {
    const { classes, Root } = useStyles();
    const [comments, setComments] = useState<IComment[]>([]);
    const [mentions, setMentions] = useState<IMentionList[]>();
    const [attachments, setAttachments] = useState<File[]>()
    const [isLoading, setIsLoading] = useState(false)
    const [preview, setPreview] = useState({
        modal: false,
        url: ''
    })

    const inputRef = useRef(null)

    const initialState = {
        value: '',
        plainValue: '',
        mentionData: []
    };

    const [initialLoad, setInitialLoad] = useState(true)

    /**
     * Input State
     */
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    useEffect(() => {
        if (initialData) {
            setComments(initialData.comments)
            setMentions(initialData.mentions)
        } else {
            fetchComments()
        }

        setInitialLoad(false)
        // eslint-disable-next-line
    }, [initialData, initialData?.comments, initialData?.mentions])

    useEffect(() => {
        return () => {
            setInitialLoad(true)
        }
    }, [])

    useEffect(() => {
        if (!initialLoad) fetchComments()
        // eslint-disable-next-line
    }, [id])

    const fetchComments = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/comment/${type}/${id}`)
            .then(res => {
                setComments(res.data.comments);
                setMentions(res.data.mentions);
            })
    }

    const handleChange = (e: any, newValue: string, newPlainTextValue: string, mentions: IMentionData[]) => {
        const newState = { ...inputState };

        newState.value = newValue;
        newState.plainValue = newPlainTextValue;
        newState.mentionData = mentions;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const submitData = (fd: FormData) => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/comment/${type}/${id}`, fd)
            .then(() => {
                renderToastSuccess('Comment ditambah');
                fetchComments();
                setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
                setAttachments([])
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => setIsLoading(false))
    }

    const handleReply = () => {
        if (!inputState.value.trim()) {
            return;
        }

        const mentionData = [];

        for (let data of inputState.mentionData) {
            mentionData.push({
                name: data.display,
                uid: data.id
            });
        }

        let fd = new FormData()
        fd.append('comment', inputState.plainValue)

        if (mentionData && mentionData.length) {
            for (let index = 0; index < mentionData.length; index++) {
                fd.append(`mentions[${index}][name]`, mentionData[index]['name'])
                fd.append(`mentions[${index}][uid]`, mentionData[index]['uid'])
            }
        }

        if (attachments && attachments.length) {
            attachments.forEach((attachment, idx) => {
                fd.append(`attachments[${idx}]`, attachment)
            })
        }

        if (attachments && attachments.length) {
            submitData(fd)
        } else {
            if (warnAttachment) {
                renderWarningButton('Tidak ada Attachment, Lanjutkan ?')
                    .then(res => res.value && submitData(fd));
            } else {
                submitData(fd)
            }
        }

    }

    const handlePreview = (attachment: IAttachment) => {

        let filepath = attachment.filepath
        let explode = filepath.split('?');
        const match = /(\.\w+$)/g.exec(explode[0]);
        let isImage = false

        if (match) {
            if (match[1] === '.pdf' || match[1] === '.PDF') {
                // PDF File
            } else if (match[1] === '.doc' || match[1] === '.docx') {
                // DOC File
            } else if (['.flv', '.mp4', '.m3u8', '.ts', '.3gp', '.mkv', '.mov', '.avi', '.wmv'].includes(match[1])) {
                // Video File
            } else {
                // Image File
                isImage = true;
            }
        }

        if (isImage) {
            setPreview({
                modal: true,
                url: attachment.filepath_url
            })
        } else {
            window.open(attachment.filepath_url)
        }
    }

    const renderComments = () => {
        return comments.map((comment, key: number) =>
            <div key={key} className={classes.commentRoot}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <div className={classes.imageContainer}>
                        <img src={comment.avatar ? comment.avatar : defaultPhoto} alt="pic" />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div className={classes.comments}>
                            <div className={classes.commentContainer}>
                                <div className={classes.title}>
                                    <label>
                                        <strong>
                                            {comment.name}
                                        </strong>
                                        <span>{dateFormat(comment.created_at, 'DD MMM YYYY [at] HH:mm')}</span>
                                    </label>
                                    {
                                        comment.activity_id ?
                                            <Chip
                                                component="a"
                                                href={`/jhs-order/${comment.activity_id}`}
                                                label="See Activity"
                                                variant='outlined'
                                                color="primary"
                                                target="_blank"
                                                className={classes.chipLink}
                                                size='small'
                                            />
                                            : null
                                    }
                                </div>
                                <div className={classes.commentText} dangerouslySetInnerHTML={{ __html: comment.comment }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        comment.attachments && comment.attachments.length ?
                            (
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    rowGap={1}
                                    flexWrap="wrap"
                                    className={classes.stack}
                                >
                                    {
                                        comment.attachments.map((attachment) => (
                                            <Chip
                                                onClick={() => handlePreview(attachment)}
                                                label={attachment.filename}
                                                className={classes.chip}
                                            />
                                        ))
                                    }

                                </Stack>
                            ) : null
                    }

                </div>
            </div>
        )
    }

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files && files.length) {
            setAttachments(prev => prev && prev.length ? prev.concat(Array.from(files)) : Array.from(files))
        }
    }

    const handleDeleteFile = (index: number) => {
        let newAttchment = Array.from(attachments ? attachments : [])
        newAttchment = newAttchment.filter((data, idx) => idx !== index)
        setAttachments(newAttchment)
    }

    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        const items = event.clipboardData?.items;
        if (items?.length) {
            const dataTransferItems: DataTransferItemList = items;
            const files: File[] = Array.from(dataTransferItems)
                .filter(item => item.kind === 'file')
                .map(item => item.getAsFile()) as File[];
            if (files) {
                setAttachments(prev => prev && prev.length ? prev.concat(Array.from(files)) : Array.from(files))
            }
        }
    };

    return (
        <Root style={{
            flexGrow: 1,
            minHeight: '500px'
        }}>
            <div className={classes.root}>
                <LoadingScreen open={isLoading} />
                <div className={classes.mainContainer}>
                    <div className={classes.mainCommentContainer} style={{ maxHeight: ignoreMaxHeight ? undefined : 500 }}>
                        {renderComments()}
                    </div>
                    <div className={classes.mainInputContainer}>
                        <Grid container className={classes.inputContainer} columnSpacing={1}>
                            <div className={classes.mainInput}>
                                <div className={classes.mainField} onPaste={handlePaste}>
                                    <MentionsInput
                                        value={inputState.value}
                                        onChange={handleChange}
                                        markup="@{{__type__||__id__||__display__}}"
                                        placeholder="Write comment"
                                        className="mentions"
                                        style={{ backgroundColor: '#fff', margin: 0, width: 'auto' }}
                                    >
                                        <Mention
                                            type="user"
                                            trigger="@"
                                            data={mentions}
                                            className="mentions__mention"
                                        />
                                    </MentionsInput>
                                    <input
                                        type='file'
                                        multiple
                                        ref={inputRef}
                                        id="file-attachment"
                                        onClick={(evt: any) => evt.target.value = null}
                                        onChange={handleChangeFile}
                                        style={{ display: 'none' }}
                                    />
                                    <label htmlFor="file-attachment" className={classes.attach} >
                                        <IconButton component="span" title="Attach File">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </div>
                                <div className={classes.buttonSend}>
                                    <Button
                                        style={{ flex: 'none' }}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleReply}
                                        disabled={!inputState.value.trim()}
                                    >
                                        Reply
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                        <Stack
                            direction="row"
                            spacing={1}
                            rowGap={1}
                            flexWrap="wrap">
                            {
                                attachments && attachments.length ?
                                    Array.from(attachments).map((file, index) => (
                                        <Chip
                                            onDelete={() => handleDeleteFile(index)}
                                            label={file.name}
                                            className={classes.chip}
                                        />
                                    ))
                                    : null
                            }
                        </Stack>
                    </div>
                </div>

                <ImagePreview
                    image={preview.url}
                    onClose={() => setPreview({ modal: false, url: '' })}
                />
            </div>
        </Root>
    );
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: "#eceff1",
        padding: "10px 10px 0",
        height: '100%'
    },
    mainContainer: {
        height: '100%',
        boxSizing: 'content-box',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
    },
    mainCommentContainer: {
        overflow: 'auto',
        marginBottom: '80px'
    },
    mainInputContainer: {
        paddingBottom: '15px',
        bottom: 0,
        position: 'absolute',
        width: '100%',
        backgroundColor: '#eceff1',
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }
    },
    comments: {
        // flex: 1,
        flex: '0 1 auto',
        display: 'flex',
        flexDirection: 'column'
    },
    commentRoot: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        backgroundColor: "white",
        borderRadius: '3px',
        border: '2px solid #eceff1',
        margin: '10px 0',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            display: 'flex'
        }
    },
    chipLink: {
        cursor: 'pointer',
        padding: 0,
        margin: '0 10px',
        [theme.breakpoints.down('sm')]: {
            width: '70px',
            margin: '0 3px'
        }
    },
    stack: {
        padding: '10px 0',
        width: 'calc(100% - 50px)',
        position: 'relative',
        left: '50px',
        [theme.breakpoints.down('sm')]: {
            left: 0,
            position: 'relative',
        }
    },
    mentions: {
        margin: 0
    },
    chipPreview: {
        maxWidth: '20%'
    },
    chip: {
        maxWidth: '20%',
        [theme.breakpoints.down('sm')]: {
            minWidth: '20%',
            maxWidth: '30%'
        }
    },
    mainInput: {
        padding: 10,
        display: 'flex',
        width: '100%',
        flexDiretion: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        position: 'relative',
        boxSizing: 'border-box'
    },
    mainField: {
        flexBasis: 0,
        flexGrow: 1,
        minWidth: 0,
        position: 'relative',
        marginRight: 10,
        '&: textarea': {
            width: '70%'
        }
    },
    buttonSend: {
        width: 'auto'
    },
    attach: {
        bottom: 5,
        right: 5,
        position: 'absolute'
    },
    inputContainer: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    imageContainer: {
        // display: 'inline-block',
        minWidth: '50px',
        '& img': {
            width: '35px',
            borderRadius: '50%'
        },
        marginBottom: 'auto'
    },
    commentContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
        },
        '& label': {
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: '700',
            fontSize: '14px',
            textTransform: 'capitalize',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flex: '0 1 auto',
                flexDirection: 'column',
                justifyContent: 'flex-start'
            },
            '& span': {
                textTransform: 'initial',
                fontWeight: '400',
                fontSize: '10px',
                color: '#989898',
                marginLeft: '5px',
                [theme.breakpoints.down('sm')]: {
                    margin: 0
                },
            }
        },
    },
    commentText: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: "0.900rem",
        flex: 1,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            left: '-50px',
            top: 10,
            flex: '0 1 auto',
            marginBottom: 10,
            width: 'calc(100% + 50px)'
        }
    },
}), 'NewComment_List'
)

export default NewCommentList;
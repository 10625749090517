import React, { useState } from 'react'
import { Theme, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import ImageSlider from './ImageSlider'
import { generateStyle } from '../../../_utils/DefaultStyle';

interface IProps {
  close: Function
  img: any,
  index?: boolean
}

const ImagePreview = (props: IProps) => {
  const { Root, classes } = useStyles()
  const [scale, setScale] = useState(1)

  const zoomIn = () => {
    if (scale < 2) setScale(prev => (prev + 0.25))
  }

  const zoomOut = () => {
    if (scale > 1) setScale(prev => (prev - 0.25))
  }

  const resetZoom = () => {
    setScale(1)
  }

  const disabled = (type: string) => {
    switch (type) {
      case 'zoom-in':
        if (scale >= 2) return {
          color: 'rgba(255,255,255,0.4)'
        }
        else return {}
      case 'zoom-out':
        if (scale <= 1) return {
          color: 'rgba(255,255,255,0.4)'
        }
        else return {}
    }
  }

  return (
    <Root>
      <div className={classes.root}>
        <ImageSlider
          img={props.img}
          index={props.index}
          preview={true}
          scale={scale}
          resetZoom={resetZoom}
        />
        <div className={classes.control}>
          <IconButton
            onClick={() => zoomIn()}
          >
            <ZoomInIcon className={classes.icon} style={{ ...disabled('zoom-in') }} />
          </IconButton>
          <IconButton
            onClick={() => zoomOut()}
          >
            <ZoomOutIcon className={classes.icon} style={{ ...disabled('zoom-out') }} />
          </IconButton>
          <IconButton
            onClick={() => props.close()}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        </div>
      </div>
    </Root>
  )
}

const useStyles = generateStyle((theme: Theme) => ({
  root: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  control: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  icon: {
    color: 'rgba(255,255,255,0.8)',
    fontSize: '2.5rem',
  }
}), "Image_Preview"
)

export default ImagePreview
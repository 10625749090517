import React, { useState } from 'react'
import { Button, Grid, Dialog, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material'
import Swal from 'sweetalert2'

interface IProps {
    open: boolean
    onClose: (reason: string) => void
}

const FailReasonModal = (props: IProps) => {
    const [reason, setReason] = useState('')

    const handleSubmitReason = (reason: string) => {
        if (reason === '') {
            Swal.fire('Warning', 'Please choose fail reason!!', 'warning')
        }else {
            props.onClose(reason)
        }
    }

    return (
        <Dialog
            fullWidth
            open={props.open}
            onClose={() => props.onClose('')}
        >
            <DialogTitle>
                Fail Reason
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} sx={{mb: 2}}>
                        <TextField
                            fullWidth
                            select
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        >
                            {
                                failReasons.map((reason: any) => (
                                    <MenuItem key={reason.key} value={reason.key}>{reason.value}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Button
                        variant="contained"
                        onClick={() => handleSubmitReason(reason)}
                    >
                        Submit
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default FailReasonModal

const failReasons = [
    {
        key: '',
        value: 'Pilih alasan gagal'
    },
    {
        key: 'daily_or_weekly',
        value: 'Harian / Mingguan',
    },
    {
        key: 'low_budget',
        value: 'Budget Rendah',
    },
    {
        key: 'client_no_response',
        value: 'Client No Response',
    },
    {
        key: 'closing_other_agent',
        value: 'Sudah Closing Agent Lain',
    },
    {
        key: 'cancel_search_unit',
        value: 'Cancel Cari Unit',
    },
    // {
    //     key: 'pm_slow_response',
    //     value: 'PM Lambat Respon',
    // },
    // {
    //     key: 'pm_not_redirect_to_other_unit',
    //     value: 'PM tidak mengarahkan ke Unit Lain',
    // },
    // {
    //     key: 'pm_not_helpful',
    //     value: 'PM kurang membantu client',
    // },
    {
        key: 'invalid_number',
        value: 'Nomor tidak bisa dihubungi',
    },
    {
        key: 'duplicate',
        value: 'Duplicate Leads',
    },
    {
        key: 'cancel_closing',
        value: 'Cancel Closing',
    },
]
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Grid
} from '@mui/material';

/**
 * Components
 */
import LoadingScreen from '../../../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../../../_utils/DefaultAxios';
import { generalErrorHandler, renderSuccessButton } from '../../../../_utils/Helper';

interface Props {
    open: boolean;
    code: string
    id: string
    onClose: () => void;
    onSubmitSuccess: () => void;
}

const DeleteClosingModal = (props: Props) => {
    const [code, setCode] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/new-closing/${props.id}`)
            .then(res => {
                renderSuccessButton('Closing Deleted!')
                if (typeof props.onSubmitSuccess !== 'undefined') {
                    props.onSubmitSuccess()
                }
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))

    }

    useEffect(() => {
        if (!props.open) {
            setCode('')
        }

    }, [props.open])

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <LoadingScreen fullScreen open={isLoading} />
            <DialogTitle id="form-dialog-title">Delete Closing</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        Data closing akan dihapus. Proses ini tidak dapat <strong>dikembalikan lagi.</strong>
                        <p>Ketik kode closingnya dibawah untuk konfirmasi delete</p>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id=""
                            value={code ? code.toUpperCase() : ''}
                            onChange={(e: any) => setCode(e.target.value)}
                            label="Kode"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            sx={{ float: 'right', mt: 2 }}
                            variant='contained'
                            onClick={handleSubmit}
                            disabled={props.code.toLowerCase() !== code.toLowerCase().trim()} color="primary"
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default DeleteClosingModal;
import React, { useEffect, useState } from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// Components
import LoadingScreen from '../../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import { dateFormat } from '../../../_utils/Helper';

interface Props {
    unitId: number;
    API_URL: string;
}

const ActivityLogList = (props: Props) => {
    const [logs, setLogs] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadLogs();
        // eslint-disable-next-line
    }, []);

    const loadLogs = () => {
        setIsLoading(true);
        DefaultAxios
            .get(props.API_URL, { params: { unit_id: props.unitId } })
            .then(res => {
                setLogs(res.data);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const renderLogs = () => {
        if (logs.length > 0) {
            return logs.map((log: any, key: number) => {
                return <TableRow>
                    <TableCell>{dateFormat(log.datetime, 'DD-MM-YYYY HH:mm:ss')}</TableCell>
                    <TableCell>{log.activity_name}</TableCell>
                    <TableCell>{log.handled_by}</TableCell>
                    <TableCell>{log.notes}</TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => window.open(`/activity/${log.id}`)}
                        >
                            View
                        </Button>
                    </TableCell>
                </TableRow>
            });
        } else {
            return <TableRow>
                <TableCell colSpan={5}>There is no logs yet</TableCell>
            </TableRow>
        }
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Tanggal
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell>
                                Handled By
                            </TableCell>
                            <TableCell>
                                Notes
                            </TableCell>
                            <TableCell>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderLogs()}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ActivityLogList;
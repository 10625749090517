import React, { useEffect, useState, useMemo } from 'react';
import { Theme, Dialog, Grid, TableContainer, IconButton, Paper, Table, TableHead, TableCell, TableBody, TableRow, DialogTitle, DialogContent, Typography } from '@mui/material';
// import Swal from 'sweetalert2';

// Utils
// import DefaultAxios from '../../../_utils/DefaultAxios';

// import DeleteIcon from '@mui/icons-material/Delete';
// import { renderWarningButton } from '../../../_utils/Helper';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ImageIcon from '@mui/icons-material/Image';
import CheckIcon from '@mui/icons-material/Check';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    id: string;
    permissions: any;
    jobs: IJob[];
    onUploadFile?: Function;
    onDeleteFile?: Function;
    onMarkedDone?: (id: number | string) => void;
    isSmall?: boolean
}

export interface IJob {
    id: number;
    description: string;
    photos: IPhoto[];
    type_label: string,
    type: string
    done_at: string | null;
    done_notes: string | null
}

interface IPhoto {
    id: number
    job_id: number
    photo: string
    photo_url: string
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        width: '100%',
        '& .MuiTextField-root': {
            backgroundColor: '#fff'
        }
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    inputContainer: {
        display: 'flex',
        '& div:nth-of-type(1)': {
            flex: '4'
        },
        '& div:nth-of-type(2)': {
            flex: '2'
        },
        '& div:nth-of-type(3)': {
            flex: '4'
        },
        '& div + div': {
            marginLeft: '10px'
        }
    },
    buttonContainer: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '16px',
    },
    buttonContainerDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    buttonContainerMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    fileContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '10px',
    },
    thumbnail: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '25%',
        marginTop: '10px',
        '& img': {
            alignSelf: 'center',
            cursor: 'pointer'
        },
        '& span': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: '10px'
        }
    },
    fileListTable: {
        '& > table': {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'table',
            },
        },
        '& > .mobile-list': {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
            '& > *': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            },
            '& > *:last-child': {
                borderBottom: 'none',
            },
        },
        '& > table > tbody > tr > td:first-of-type': {
            width: 75,
            '& > *': {
                objectFit: 'cover',
                cursor: 'pointer',
            }
        },
        '& .file-name': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.dark,
            }
        }
    },
}), "JobDetail_List"
);

const JobDetailList = (props: Props) => {
    const { jobs } = props;
    const { Root, classes } = useStyles();
    const IMAGE_SIZE = useMemo(() => props.isSmall ? '50' : '75', [props.isSmall])
    const [job, setJob] = useState<IJob[]>([]);
    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);
    const [photoModal, setPhotoModal] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)

    useEffect(() => {
        setJob(jobs.slice());
        // eslint-disable-next-line
    }, [jobs]);

    // const renderDeleteButton = (file: IFile) => {
    //     return (
    //         <Grid item>
    //             <Button
    //                 variant="contained"
    //                 color="secondary"
    //                 className={classes.button}
    //                 startIcon={<DeleteIcon />}
    //                 onClick={() => deleteFile(file.id)}
    //             >
    //                 Delete
    //             </Button>
    //         </Grid>
    //     )
    // }

    const renderViewButton = (index: number) => {
        return (
            <Grid item>
                <IconButton
                    color="primary"
                    title="See Photos"
                    onClick={() => handleViewPhoto(index)}
                >
                    <ImageIcon />
                </IconButton>
            </Grid>
        )
    }

    const handleViewPhoto = (index: number) => {
        setSelectedIndex(index)
        setPhotoModal(true)
    }

    /* const deleteFile = (id: string) => {
        if (!props.onDeleteFile) return

        renderWarningButton("Apakah anda yakin ingin menghapus job ini?")
            .then((result) => {
                if (result.value) {
                    let API_URL = `${process.env.REACT_APP_API_URL}/activity/delete-job?id=${id}`;

                    DefaultAxios
                        .post(API_URL)
                        .then(() => {
                            Swal.fire({
                                title: "Job berhasil dihapus",
                                icon: 'success'
                            }).then(() => {
                                if (props.onDeleteFile) props.onDeleteFile();
                            });
                        })
                        .catch((res) => {
                            Swal.fire({
                                title: "Error",
                                text: res.data.message,
                                icon: 'error'
                            });
                        })
                }
            })
    } */

    const handlePreviewFile = (src: string, isImage: boolean) => {
        if (isImage) {
            setPreviewImage(src);
        } else {
            window.open(src);
        }
    }

    const renderFiles = () => {
        return job.map((file, index) => {
            return (
                <TableRow key={file.id}>
                    <TableCell>
                        {
                            file.photos[0]?.photo_url ?
                                <img style={{ cursor: 'default' }} height={IMAGE_SIZE} width={IMAGE_SIZE} src={file.photos[0].photo_url} alt={`${file.id}`} />
                                : 'No Photo'
                        }
                    </TableCell>
                    <TableCell>
                        {file.type_label || '-'}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                        <span
                        >
                            {file.description}
                        </span>
                    </TableCell>
                    <TableCell>
                        <span>
                            {
                                file.done_at ?
                                    'Yes' : 'No'
                            }
                        </span>
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                        <span>
                            {
                                file.done_notes ? file.done_notes : '-'
                            }
                        </span>
                    </TableCell>
                    <TableCell align="right">
                        <Grid container style={{ display: 'flex', minWidth: 200, flexDirection: 'row', justifyContent: 'flex-end', gap: 5, alignItems: 'center' }}>
                            {
                                file.photos && file.photos.length ?
                                    <Grid >
                                        {
                                            renderViewButton(index)
                                        }
                                    </Grid>
                                    : null
                            }
                            {
                                props.permissions['order-jhs.admin'] && !file.done_at ?
                                    <Grid>
                                        <IconButton
                                            color='success'
                                            title='Mark Done'
                                            onClick={() => typeof props.onMarkedDone !== 'undefined' && props.onMarkedDone(file.id)}
                                        >
                                            <CheckIcon />
                                        </IconButton>
                                    </Grid>
                                    : null
                            }
                            {
                                (props.permissions['order-jhs.admin'] && !file.done_at) || (file.photos && file.photos.length) ?
                                    null
                                    : <span>-</span>
                            }
                            {/* <Grid>
                                {renderDeleteButton(file)}
                            </Grid> */}
                        </Grid>
                    </TableCell>
                </TableRow>
            )
        })
    }

    const handleClosePhotoModal = () => {
        setPhotoModal(false)
        setSelectedIndex(0)
    }

    return (
        <>
            <Dialog
                open={photoModal}
                onClose={handleClosePhotoModal}
                scroll="body"
            // maxWidth="md"
            >
                <Root>
                    <DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            onClick={handleClosePhotoModal}
                            sx={{ float: 'left', position: 'absolute ' }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={{ margin: '0 auto', left: -10 }}>All Photos</span>
                    </DialogTitle>
                    <hr />
                    <DialogContent style={{ padding: 0 }}>
                        {
                            job.length ?
                                job[selectedIndex].photos.map((photo, index) =>
                                    <>
                                        <Grid key={index} container style={{ justifyContent: 'center', cursor: 'pointer' }} onClick={() => handlePreviewFile(photo.photo_url, true)} >
                                            <img src={photo.photo_url} width="50%" alt="job" />
                                        </Grid>
                                        <hr />
                                    </>
                                )
                                : null
                        }
                    </DialogContent>
                </Root>
            </Dialog>
            <Dialog
                open={!!previewImage}
                onClose={() => { setPreviewImage(undefined) }}
                scroll="body"
                maxWidth={false}
            >
                <img src={previewImage} width="100%" alt="preview" />
            </Dialog>
            <Root>
                <div className={classes.root}>
                    <Root>
                        <TableContainer component={Paper} className={classes.fileListTable} elevation={0}>
                            <Typography variant='h5' sx={{ mb: 2 }}>Order Details</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Photo
                                        </TableCell>
                                        <TableCell>
                                            Type
                                        </TableCell>
                                        <TableCell>
                                            Job Description
                                        </TableCell>
                                        <TableCell>
                                            Done
                                        </TableCell>
                                        <TableCell>
                                            Done Notes
                                        </TableCell>
                                        <TableCell align="right">
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        job.length > 0 ?
                                            renderFiles()
                                            :
                                            <TableRow>
                                                <TableCell style={{ textAlign: 'center' }} colSpan={props.onDeleteFile ? 6 : 5}>No Data Available</TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Root>
                </div>
            </Root>
        </>
    );
}

export default JobDetailList;
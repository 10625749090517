import React, { useEffect, useState } from 'react';

/**
 * Components
 */
import {
    Box,
    Button,
    Theme,
    TextField,
} from "@mui/material";
import TextArea from '../../_components/_form/TextArea';
import Swal from 'sweetalert2';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from "../../_utils/Helper";
import { generateStyle } from "../../_utils/DefaultStyle";
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from "../../_utils/Validation";

interface IProps {
    open: boolean
    rentDetail_id?: string;
    successCallback?: () => void
    closeModal: Function;
    is_edit?: boolean;
}

type State = {
    failed_type: string
    failed_reason: string
}

export const listingSubmissionApartmentFailedType = [
    {
        value: 'double_listing',
        label: 'Double Listing',
    },
    {
        value: 'double_input',
        label: 'Double Input',
    },
    {
        value: 'invalid_contact',
        label: 'Kontak Invalid',
    },
    {
        value: 'spam',
        label: 'Spam',
    },
    {
        value: 'other',
        label: 'Lainnya',
    },
]

const ListingSubmissionApartmentModalFailed = (props: IProps) => {
    const { Root, classes } = useStyles();

    const rentDetail_id = props.rentDetail_id

    const [state, setState] = useState<State>(INITIAL_STATE)
    const [error, setError] = useState<IValidationErrors<State>>({})

    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!props.open) {
            setState(INITIAL_STATE)
        }
    }, [props.open])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    };

    const onSubmit = () => {
        const { errors, isValid } = validateData(state, validationRules, validationAlias)
        setError(errors)

        if (isValid) {
            setIsLoading(true)

            let data = {
                id: rentDetail_id,
                ...state,
            }

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/rent-apartment/reject`, data)
                .then(() => {
                    if (typeof props.successCallback !== 'undefined') props.successCallback()

                    Swal.fire({
                        title: "Status Changed",
                        icon: 'success',
                        onAfterClose: () => {
                            props.closeModal()
                        },
                        timer: 1000
                    })
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Box className={classes.main_container} style={{ display: "flex", flexFlow: "column" }}>
                <div className={classes.container}>
                    <Box>
                        <TextField
                            select
                            label="Type"
                            name="failed_type"
                            value={state.failed_type}
                            error={!!error.failed_type}
                            helperText={error.failed_type}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            <option value="" disabled>Pilih Type</option>
                            {
                                listingSubmissionApartmentFailedType.map((failedType, index) =>
                                    <option key={index} value={failedType.value}>{failedType.label}</option>
                                )
                            }
                        </TextField>
                    </Box>
                    <Box className={classes.formContainer}>
                        <TextArea
                            label="Comment"
                            name="failed_reason"
                            value={state.failed_reason}
                            error={!!error.failed_reason}
                            helperText={error.failed_reason}
                            onChange={handleChange}
                        />
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonSubmit}
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </Box>
        </Root>
    );
};

const INITIAL_STATE: State = {
    failed_type: '',
    failed_reason: '',
}

const validationRules: IValidationRules = {
    failed_type: 'required',
    failed_reason: 'required',
}

const validationAlias: IValidationAlias = {
    failed_type: 'Type',
    failed_reason: 'Comment',
}

const useStyles = generateStyle((theme: Theme) =>
({
    main_container: {
        margin: theme.spacing(2),
    },
    container: {
        padding: '0 0.5rem',
        display: 'flex',
        flexDirection: 'column',
        // marginBottom: theme.spacing(10),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabSpacing: {
        marginRight: '1rem',
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(1)
    },
    formContainer: {
        marginTop: theme.spacing(2),
        display: "flex"
    },
    inputText: {
        flexGrow: 1
    },
    buttonSubmit: {
        fontSize: "14px",
        marginTop: theme.spacing(3),
        alignSelf: 'flex-end',
        // flexGrow: 1,
        // borderRadius: 25
    },
    buttonText: {
        color: '#033A11',
        fontSize: 14,
        textAlign: 'left',
        whiteSpace: 'nowrap'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    appBar: {
        position: 'relative'
    },
}), "ListingSubmissionApartmentModalFailed"
);

export default ListingSubmissionApartmentModalFailed;

import React, { useState } from 'react';
import { Slide, Toolbar, IconButton, Typography, Theme, Dialog, AppBar, Link } from '@mui/material';

// Table
import DataTable from '../../_components/_dataTable/DataTable';
import { TransitionProps } from '@mui/material/transitions';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import DetailListingInventory from './DetailListingInventory';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) =>
({
    link: {
        textDecoration: 'none'
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "Unit_List"
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UnitList = () => {
    const { Root, classes } = useStyles();
    const API_URL = `${process.env.REACT_APP_API_URL}/listing-inventory/unit-list`;

    const [modalState, setModalState] = useState(false);
    const [reDrawDatatable] = useState(new Date().getTime());
    const [selectedId, setSelectedId] = useState<null | number>(null);
    const [selectedUnit, setSelectedUnit] = useState<null | number>(null);

    const handleOpen = () => setModalState(true);
    const handleClose = () => setModalState(false);

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Listing Inventory Unit {selectedUnit}
                </Typography>
            </Toolbar>
        )
    }

    const handleOpenModal = (data: any) => {
        setSelectedId(data.id);
        setSelectedUnit(data.code);
        handleOpen();
    }

    return (
        <Root>
            <Dialog
                fullScreen
                open={modalState}
                onClose={handleClose}
                closeAfterTransition
                TransitionComponent={Transition}
            >
                <Root>
                    <AppBar className={classes.appBar}>
                        <FormToolbar></FormToolbar>
                    </AppBar>
                    <DetailListingInventory listingInventoryId={selectedId} />
                </Root>
            </Dialog>
            <DataTable
                reDraw={reDrawDatatable}
                url={API_URL}
                columns={[
                    {
                        name: 'code',
                        label: 'Kode',
                        type: 'string',
                        filterable: true,
                        render: (data: any, row: any) => {
                            return (
                                <Link
                                    href="#"
                                    onClick={() => { handleOpenModal(row) }}
                                >
                                    {data}
                                </Link>
                            );
                        }
                    }
                ]}
            />
        </Root>
    );
}

export default UnitList;
import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Theme, IconButton } from '@mui/material';
import moment from 'moment';

import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { PermissionContext } from '../../_contexts/PermissionContext';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    career_id?: string
}

const useStyles = generateStyle((theme: Theme) => ({
    actionContainer: {
        display: 'flex'
    },
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    id_list: {
        maxWidth: '20px'
    },
    notes_list: {
        minWidth: '500px',
        lineBreak: 'anywhere',
        whiteSpace: 'pre-line'
    }
}), "CareerList"
)

const CareerList = (props: IProps) => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const DT_API_URL = process.env.REACT_APP_API_URL + '/career';

    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const openModalCreate = () => {
        navigate('/career/add')
    }

    const handleDelete = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin menghapus data ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${DT_API_URL}/${id}`)
                        .then(res => {
                            setReDrawDatatable(new Date().getTime());
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={openModalCreate}
            >
                Add Career
            </Button>
            <DataTable
                reDraw={reDrawDatatable}
                url={DT_API_URL}
                columns={[
                    {
                        name: 'id', label: 'ID',
                        filterable: true,
                        style: { width: '20px' },
                    },
                    {
                        name: 'title',
                        label: 'Title',
                        filterable: true,
                        style: { minWidth: '200px' },
                    },
                    {
                        name: 'description',
                        label: 'Description',
                        filterable: true,
                        style: { minWidth: '500px' },
                        render: (data: string, row: any) => {
                            return (<div className={classes.notes_list} dangerouslySetInnerHTML={{ __html: data }}></div>);
                        }
                    },
                    {
                        name: 'created_at',
                        label: 'Tanggal',
                        type: 'date',
                        filterable: false,
                        render: (data: any, row: any) => {
                            return (
                                `${moment(data).format("DD MMMM YYYY")}`
                            )
                        }
                    },
                    {
                        name: 'EXTRA',
                        label: 'Action',
                        sortable: false,
                        render: (row: any) => {
                            return (
                                permissions['apartment.manage-note'] ?
                                    <div className={classes.actionContainer}>
                                        <IconButton
                                            color="primary"
                                            component="span"
                                            onClick={() => navigate(`/career/${row.id}`)}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            component="span"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                    : <> </>
                            );
                        }
                    }
                ]}
            />
        </Root>
    );
}

export default CareerList;
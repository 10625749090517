import React, { useEffect, useState } from 'react'
import { TextField, InputAdornment, Grid, Theme, CircularProgress, Box } from '@mui/material'

// Components
import MenuBar from '../../_components/MenuBar'

/* 
* Utils
*/
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

/* 
* Icons
*/
import SearchIcon from '@mui/icons-material/Search';
import Items from './components/Items';
import DetailEnquiryModal from './components/DetailEnquiryModal';
import { generateStyle } from '../../_utils/DefaultStyle';

/* 
* Interfaces
*/

interface IParam {
    status: string | "greeting" | 'fu' | 'showing' | '',
    page?: string | number,
    search?: string
}

export interface IActivity {
    id: number
    date: string
    datetime: string,
    time: string
    activity: string
    apartment: string
    client: string
    phone: string
    created_by: string
    pic_name: string
    activity_name: string
}

export interface IActivityAdvisor {
    id: number
    date: string
    datetime: string,
    time: string
    activity: string
    apartment: string
    client: string
    phone: string
    created_by: string
    pic_name: string
    activity_name: string
    notes: string,
    unit_code: string
    created_at: string
    verified_at: string
}

export interface ILeadAdvisor {
    activities: IActivityAdvisor[]
    id: number
    code: string
    is_buy: number
    status: string
    unit_code: string
    apartment_name: string
    created_at: string
    customer_name: string
    customer_phone: string
    estimated_rent_period: string
    date: string
    status_value: string
    keterangan: string
    followup_overdue: boolean
    recent_followup: IActivity | null
}

const buttons = [
    { value: 'greeting', label: 'New' },
    { value: 'fu', label: 'FU' },
    { value: 'showing', label: 'Showing' },
    { value: 'kyc', label: 'KYC' },
    { value: '', label: 'All' },
]

const DashboardPM = () => {
    const { Root, classes } = useStyles()
    const [status, setStatus] = useState<string>('greeting')
    const [enquiry, setEnquiry] = useState([])
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)
    const [selectedId, setSelectedId] = useState(0)

    const fetchData = (param: IParam) => {
        setLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/enquiry`, { params: param })
            .then((res: any) => {
                setEnquiry(res.data.data)
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        const timeOutFetch = setTimeout(() => {
            fetchData({ status, search })
        }, 500)
        return () => {
            clearTimeout(timeOutFetch)
        }
        // eslint-disable-next-line
    }, [search])

    const handleChangeSearch = (e: any) => {
        setSearch(e.target.value)
    }

    const handleChangeStatus = (value: string) => {
        setStatus((prev: string) => {
            if (prev === value) {
                return prev
            } else {
                fetchData({ status: value, search })
            }
            return value
        })
    }

    const handleClickItem = (id: number) => {
        setSelectedId(id)
        setOpenDetail(true)
    }

    const handleClose = (refetch: boolean) => {
        setOpenDetail(false)
        if (refetch) {
            fetchData({ search, status })
        }
    }

    return (
        <MenuBar title="Dashboard">
            <Root>
                <Grid container>
                    <Grid item xs={12}>
                        <div
                            className={classes.buttonGroup}
                        >
                            {
                                buttons.map((button) => (
                                    <button
                                        key={button.value}
                                        className={classes.button}
                                        disabled={status === button.value}
                                        onClick={() => handleChangeStatus(button.value)}
                                    >
                                        {button.label}
                                    </button>
                                ))
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ py: 2, mt: 4 }}>
                        <TextField
                            name='search'
                            fullWidth
                            value={search}
                            placeholder='Search client or apartment'
                            onChange={handleChangeSearch}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position='start'>
                                        <SearchIcon />
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {loading ?
                            <Box sx={{ display: 'flex' }} className={classes.loader}>
                                <CircularProgress />
                            </Box>
                            :
                            <>
                                {
                                    enquiry.length > 0 ?
                                        enquiry.map((data: any) => (
                                            <Items key={data.id} data={data} onClick={() => handleClickItem(data.id)} />
                                        )) :
                                        <Box className={classes.loader}>
                                            No Leads Found!
                                        </Box>
                                }
                            </>
                        }
                    </Grid>
                </Grid>

                <DetailEnquiryModal
                    id={selectedId}
                    open={openDetail}
                    onClose={handleClose}
                />
            </Root>
        </MenuBar>
    )
}

export default DashboardPM

const useStyles = generateStyle((theme: Theme) => ({
    loader: {
        width: '100%',
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonGroup: {
        position: 'fixed',
        height: '40px',
        top: '56px',
        width: "100%",
        left: 0,
        display: 'flex',
        zIndex: 1101,
    },
    button: {
        flex: 'auto',
        outline: 0,
        border: 0,
        fontWeight: '600',
        color: 'white',
        background: '#009be5',
        borderBottom: '3px solid #009be5',
        boxSizing: 'content-box',
        borderTop: '2px solid #009be5',
        "&:disabled": {
            borderBottom: '3px solid white !important',
        }
    }
}), "Dashboard_PM"
)


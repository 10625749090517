import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, Grid, IconButton } from '@mui/material'
import WhatsappPreview, { IChats } from '../WhatsappPreview';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { generateStyle } from '../../_utils/DefaultStyle';


interface IProps {
    open: boolean,
    chats?: IChats[]
    code: string | null
    created_at: string
    onClose: () => void
}

const WhatsappPreviewModal = (props: IProps) => {
    const { Root, classes } = useStyles()
    const [chats, setChats] = useState<IChats[]>([])
    const theme: Theme = useTheme()

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (props.open) {
            if (props.chats && props.chats.length) {
                setChats(props.chats)
            }
        }

    }, [props.open, props.chats])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
            fullScreen={isMobile}
        >
            <Root>
                <DialogTitle className={classes.titleContainer}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                            {props.code ? `${props.code} - ` : ''}Whatsapp Preview
                        </span>
                        {
                            props.created_at ?
                                <em style={{ fontSize: 14 }}>Created At : {format(new Date(props.created_at), "dd MMMM yyyy HH:mm:ss", { locale: id })}</em>
                                : null
                        }
                    </div>
                    <IconButton
                        className={classes.close}
                        onClick={props.onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    <Grid container className={classes.container}>
                        <WhatsappPreview data={chats} />
                    </Grid>
                </DialogContent>
            </Root>
        </Dialog>
    )
}


const useStyles = generateStyle(theme => ({
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        minHeight: '100%'
    },
    close: {
        marginLeft: 'auto',
        padding: 0
    }
}), "WhatsappPreview_Modal")

export default WhatsappPreviewModal
import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Theme,
    Grid,
    TextField,
    Link
} from '@mui/material';
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import CustomerDetail from './CustomerDetail';
import DefaultAxios from '../../_utils/DefaultAxios';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';

/**
 * Utils
 */
import { generalErrorHandler } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    customer_id?: string
}
interface IParams extends Record<string, string | undefined> {
    customer_id?: string
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        boxShadow: '0px 0px 0px #0000001A',
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    }
}), "Customer_List"
)



const CustomerList = (props: IProps) => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const DT_API_URL = process.env.REACT_APP_API_URL + '/customer';
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [selectedId, setSelectedId] = useState<string>('');
    const [modalDetailState, setModalDetailState] = useState(false);
    const params = useParams<IParams>()
    const [searchValue, setSearchValue] = useState('');

    const handleOpenDetail = () => setModalDetailState(true);

    const handleCloseDetail = () => {
        setModalDetailState(false)
        window.history.pushState([], 'Customer List', `/customer`);
    };

    useEffect(() => {
        if (typeof params.customer_id !== "undefined") {
            setSelectedId(params.customer_id);
            handleOpenDetail();
        }
    }, [params]);

    const handleSearch = () => {
        if (searchValue) {
            setIsLoading(true);
            DefaultAxios.get(`${DT_API_URL}/search-data`, { params: { search: searchValue } })
                .then(res => {
                    navigate(`/customer/${res.data}`);
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }


    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <CustomerDetail
                open={modalDetailState}
                onClose={handleCloseDetail}
                customerId={selectedId}
                permissions={permissions}
            />

            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => navigate('/customer/add')}
            >
                Add Customer
            </Button>

            {
                permissions['customer.admin'] ?
                    <DataTable
                        url={DT_API_URL}
                        columns={[
                            {
                                name: 'name', label: 'Name', filterable: true, render: (data: any, row: any) => {
                                    return (
                                        <Link
                                            style={{ cursor: "pointer" }}
                                            component={RouterLink}
                                            to={`/customer/${row.id}`}
                                        // onClick={() => { openModalDetail(row); console.log(row) }
                                        // }
                                        >
                                            {data}
                                        </Link>
                                    );
                                }
                            },
                            { name: 'email', label: 'Email', filterable: true },
                            { name: 'phone', label: 'Phone', filterable: true },
                        ]}
                    />
                    :
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Search"
                                value={searchValue}
                                placeholder="Email / Phone"
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
            }

        </Root>
    );
}

export default CustomerList;
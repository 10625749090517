import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import LoadingScreen from '../../../../_components/LoadingScreen'
import { Button, Dialog, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material'

/**
 * Utils
 */
import { DepositItem } from './DepositListModal'
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { convertNumber, generalErrorHandler, renderToastSuccess } from '../../../../_utils/Helper'
import { IValidationErrors } from '../../../../_utils/Validation'
import { validateData } from '../../../../_utils/Validation'
import { inputNumber } from '../../../../_utils/Helper'

interface DepositTargetModalProps {
    data: 'reimburse' | DepositItem | false
    sisaDepositTenant: number
    closingId: string
    onClose: () => void
    onSuccess: () => void
    setDepositTransactionRefreshFlag: React.Dispatch<React.SetStateAction<number>>
    maxForReimburseClient: number
}

type Bank = { id: number, label: string, type: string }

type State = {
    selectedBank: null | Bank
    nominal: string
    notes: string
}

const DepositTargetModal = (props: DepositTargetModalProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<State>(initialState)
    const [error, setError] = useState<IValidationErrors<State>>({})

    const [bankList, setBankList] = useState<Bank[]>([])

    const validationRules = useMemo(() => ({
        selectedBank: () => {
            if (state.selectedBank === null) {
                return {
                    isValid: false,
                    errorMessage: 'Harap memilih no rekening tujuan'
                }
            }

            return {
                isValid: true,
                errorMessage: ''
            }
        },
        nominal: props.data === 'reimburse' ? 'required' : '',
    }), [props.data, state.selectedBank])

    useEffect(() => {
        setState(initialState)
        setError({})

        fetchBankList()
        // eslint-disable-next-line
    }, [props.data])

    const fetchBankList = () => {
        if (!props.data) return
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/closing-deposit/bank-list`, {
            params: {
                id: props.closingId,
                type: props.data ? typeof props.data !== 'string' ? props.data.type : 'reimburse_client' : ''
            }
        })
            .then(res => res.data)
            .then(data => {
                setBankList(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target

        if (name === 'nominal') {
            let finalValue = convertNumber(value)
            const number = Number(inputNumber(value))
            if (number > props.maxForReimburseClient) {
                finalValue = convertNumber(props.maxForReimburseClient.toString())
            }

            setState(prev => ({
                ...prev,
                [name]: finalValue,
            }))
        } else {
            setState(prev => ({
                ...prev,
                [name]: name === 'selectedBank' ? bankList.find(bank => `${bank.type}-${bank.id}` === value) || null : value
            }))
        }

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const onSubmit = () => {
        const { isValid, errors } = validateData(state, validationRules)

        if (!isValid) {
            setError(errors)
            return
        }

        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-deposit/propose-${props.data === 'reimburse' ? 'reimburse' : 'deposit'}`, {
            id: props.data === 'reimburse' ? props.closingId : (props.data as DepositItem).id,
            bank_type: state.selectedBank!.type,
            bank_id: state.selectedBank!.id,
            amount: props.data === 'reimburse' ? inputNumber(state.nominal) : null,
            notes: props.data === 'reimburse' ? state.notes : null,
        })
            .then(res => res.data)
            .then(data => {
                props.onSuccess()
                renderToastSuccess(props.data === 'reimburse' ? 'Berhasil reimburse client' : 'Berhasil propose transaksi')

                props.setDepositTransactionRefreshFlag(prev => prev + 1)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })

        setTimeout(() => {
            setIsLoading(false)
        }, 1000);
    }

    return (
        <Dialog
            open={!!props.data}
            onClose={props.onClose}
            closeAfterTransition
            fullWidth
            maxWidth="xs"
        >
            <LoadingScreen fullScreen open={isLoading} />
            <DialogTitle style={{ paddingBottom: 0 }}>
                Pilih No Rekening Tujuan
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3} sx={{ marginTop: 0 }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label="Rekening"
                            name="selectedBank"
                            placeholder='Pilih Nomor Rekening'
                            value={state.selectedBank?.id}
                            onChange={onChange}
                            error={!!error.selectedBank}
                            helperText={error.selectedBank}
                        >
                            {
                                bankList.map(bank => <MenuItem key={bank.id} value={`${bank.type}-${bank.id}`}>{bank.label}</MenuItem>)
                            }
                        </TextField>
                    </Grid>
                    {
                        props.data === 'reimburse' &&
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Nominal"
                                    name="nominal"
                                    placeholder='Masukkan Nominal'
                                    value={convertNumber(state.nominal)}
                                    onChange={onChange}
                                    error={!!error.nominal}
                                    helperText={error.nominal ? error.nominal : `Max: Rp. ${convertNumber(props.maxForReimburseClient.toString())}`}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Notes"
                                    name="notes"
                                    placeholder='Masukkan Notes'
                                    value={state.notes}
                                    onChange={onChange}
                                    error={!!error.notes}
                                    helperText={error.notes}
                                />
                            </Grid>
                        </>
                    }
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={props.onClose}
                            sx={{ marginLeft: 'auto' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={onSubmit}
                            sx={{ marginLeft: 2 }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const initialState: State = {
    selectedBank: null,
    nominal: '',
    notes: '',
}

export default DepositTargetModal

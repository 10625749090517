import { Grid, IconButton } from '@mui/material'
import React, { useState } from 'react'
/**
 * Components
*/
import ChangeAreaModal from './_components/ChangeAreaModal';
import DataTable from '../../_components/_dataTable/DataTable'

/**
 * Icons
 */
import EditIcon from '@mui/icons-material/Edit';



type Props = {}

export interface ITelesalesArea {
    id: string
    name: string
    area_id: string
    area_name: string
}

const TelesalesAreaList = (props: Props) => {
    const [selectedRow, setSelectedRow] = useState<ITelesalesArea | null>(null)
    const [redraw, setRedraw] = useState(new Date().getTime())

    const handleChangeArea = (rows: ITelesalesArea) => {
        setSelectedRow(rows)
    }

    const column = [
        { name: 'name', label: 'Name' },
        {
            name: 'is_paused',
            label: 'Paused',
            render: (data: number) => (
                <span style={{ color: data ? 'red' : 'rgba(0, 0, 0, 0.87)' }}>
                    {data ? 'True' : 'False'}
                </span>
            )
        },
        {
            name: 'EXTRA',
            label: 'Area',
            render: (row: ITelesalesArea) => (
                <span>
                    {row.area_name ? row.area_name : ''}
                    <IconButton onClick={() => handleChangeArea(row)}>
                        <EditIcon />
                    </IconButton>
                </span>
            )
        },
    ]

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <DataTable
                        columns={column}
                        url={`${process.env.REACT_APP_API_URL}/area-telesales`}
                        reDraw={redraw}
                    />
                </Grid>
            </Grid>
            <ChangeAreaModal
                type="telesales"
                open={!!selectedRow}
                onClose={(refresh) => {
                    if (refresh) {
                        setRedraw(new Date().getTime())
                    }

                    setTimeout(() => {
                        setSelectedRow(null)
                    }, 200)
                }}
                telesalesData={selectedRow}
            />
        </>
    )
}

export default TelesalesAreaList
import { Dialog, DialogContent, DialogTitle, Grid, TextField, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import LoadingScreen from '../../../_components/LoadingScreen'

/**
 * Utils
 */
import { validateData } from '../../../_utils/Validation'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../../_utils/Helper'

type Props = {
    open: boolean,
    onClose: (refresh: boolean) => void
    id: string
}

const LandlordEmailModal = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState({
        email: ''
    })
    const [errorState, setErrorState] = useState({
        email: ''
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target
        const validation = validateData({ email: value }, { email: 'email' },)
        if (!validation.isValid) {
            let newErrors = { ...errorState }
            newErrors = { ...newErrors, ...validation.errors }
            setErrorState(newErrors)
        } else {
            setErrorState({ email: '' })
        }

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/landlord/connect-account-manual`, { id: props.id, email: state.email })
            .then(res => {
                renderSuccessButton('Connect Account Manual Success!')
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (!props.open) {
            setState({ email: '' })
            setErrorState({ email: '' })
        }
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>Connect Account Manual</DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name='email'
                            onChange={handleChange}
                            label="Email"
                            error={!!errorState.email}
                            helperText={errorState.email}
                            value={state.email}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            sx={{ float: 'right' }}
                            disabled={!!errorState.email || !state.email}
                            onClick={handleSubmit}
                            variant='contained'
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default LandlordEmailModal
import React, { useState, useEffect } from 'react';
import { Dialog, AppBar, Theme, Toolbar, IconButton, Typography, DialogContent, Grid } from '@mui/material';

// Components
import LoadingScreen from '../../_components/LoadingScreen';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, convertPrice } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IState {
    id: string
    date: string,
    items: {
        id: string
        amount: string
        destination: string
        distance: string
        origin: string
        parking_amount: string
    }[]
    total_overtime_amount: number
    total_amount: string
}

const initialState = {
    id: "",
    date: "",
    items: [],
    total_overtime_amount: 0,
    total_amount: "",
}

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    box: {
        padding: theme.spacing(2)
    },
    paper: {
        margin: theme.spacing(2),
        height: "100%",
        color: theme.palette.text.secondary,
    },
    root: {
        flex: 1,
        paddingTop: 12,
        width: '100%',
    },
    card: {
        border: '1px solid #cacaca',
        padding: '10px 20px',
        borderRadius: 20,
        backgroundColor: 'white',
        marginBottom: 16,
        position: 'relative',
        cursor: 'pointer'
    },
    cardSection: {
        border: '1px solid #cacaca',
        borderRadius: 5,
        padding: '14px 22px',
        marginBottom: 16,
        position: 'relative',
    },
    cardTitleContainer: {
        alignItems: 'center',
        textAlign: 'center'
    },
    cardTitleContainerSection: {
        marginBottom: 16,
        flexShrink: 1,
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    cardTitleContainerBox: {
        // width: '45%'
    },
    cardTitle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    priceContainerSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexFlow: 'row-reverse'
    },
    totalPriceSection: {
        padding: '0px 22px',
        marginTop: 20,
        textAlign: 'right'
    },
}), "TransportReimbursementDetail"
)

const TransportReimbursementDetail = ({ id, open, onClose }: { id: string, open: boolean, onClose: Function }) => {
    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [detail, setDetail] = useState<IState>(initialState);
    const [totalAmount, setTotalAmount] = useState("")
    const [role, setRole] = useState('')

    const renderData = () => {
        return detail.items.map(data => {
            return (
                <div key={data.id} className={classes.cardSection}>
                    {/* <div className={classes.cardTitleContainerSection}>
                        <div className={classes.cardTitleContainerBox}>
                            {data.origin}
                        </div>
                        <div>
                            <ArrowRightAltIcon />
                        </div>
                        <div className={classes.cardTitleContainerBox}>
                            {data.destination}
                        </div>
                    </div> */}
                    <Grid container style={{ marginBottom: 12 }}>
                        <Grid item xs={5}>
                            {data.origin}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <ArrowRightAltIcon />
                        </Grid>
                        <Grid item xs={5} style={{ textAlign: 'right' }}>
                            {data.destination}
                        </Grid>
                    </Grid>
                    <div className={classes.priceContainerSection}>
                        <span className={classes.cardTitle}>Rp. {convertPrice(data.amount)}</span>
                        <span className={classes.cardTitle}>Distance: {convertPrice(data.distance)} KM</span>
                    </div>
                </div>
            )
        })
    }

    useEffect(() => {
        if (id && open) {
            loadData();
        }
        // eslint-disable-next-line
    }, [id, open]);

    const loadData = () => {
        const role = localStorage.getItem('role')
        if (role) setRole(role)

        setIsLoading(true);
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/transport-reimbursement/${id}`)
            .then(res => {
                const data = res.data
                const itemAmount = data.items.reduce((a: string, b: any) => (parseInt(a) + parseInt(b.amount)), 0)
                const totalParkir = data.total_parking_amount;
                const totalAmount = data.total_overtime_amount + totalParkir + itemAmount;
                setTotalAmount(totalAmount)
                setDetail(data)
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose()}
            fullScreen
            maxWidth="lg"
        >
            <Root>
                <LoadingScreen open={isLoading} fullScreen />
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit"
                            onClick={() => { onClose() }}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Reimbursement Detail
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Root>
                        <div className={classes.root}>
                            <div className={classes.card}>
                                <div className={classes.cardTitleContainer}>
                                    <p className={classes.cardTitle}>NOTE</p>
                                    <p >Rate = Rp. 1,000 / KM</p>
                                    <p >Parkir = Rp. {['advisor', 'advisor-captain'].includes(role) ? '5,000' : '3,000'} / Apartemen</p>
                                </div>
                                {
                                    detail.items &&
                                    renderData()
                                }
                                <div className={classes.cardSection}>
                                    <div className={classes.cardTitleContainerSection}>
                                        Total Parkir
                                    </div>
                                    <div className={classes.priceContainerSection}>
                                        <span className={classes.cardTitle}>Rp. {convertPrice((5000 * detail.items.length).toString())}</span>
                                    </div>
                                </div>
                                {
                                    detail.total_overtime_amount > 0 && detail.total_overtime_amount !== null &&
                                    <div className={classes.cardSection}>
                                        <div className={classes.cardTitleContainerSection}>
                                            <span>Lembur</span>
                                        </div>
                                        <div className={classes.priceContainerSection}>
                                            <span className={classes.cardTitle}>Rp. {detail.total_overtime_amount ? convertPrice(detail.total_overtime_amount.toString()) : `-`}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div>
                                <div className={classes.totalPriceSection}>
                                    <span className={classes.cardTitle}>Total:     Rp. {totalAmount ? convertPrice(totalAmount.toString()) : '0'}</span>
                                </div>
                            </div>
                        </div>
                    </Root>
                </DialogContent>
            </Root>
        </Dialog>
    );
}

export default TransportReimbursementDetail;
import React, { useState, useEffect } from 'react'
import { Button, Grid, MenuItem, Paper, TextField } from '@mui/material'

/**
 * Components
 */
import AsyncAutoComplete from '../../_components/_form/AsyncAutoComplete'
import TextArea from '../../_components/_form/TextArea'
import LoadingScreen from '../../_components/LoadingScreen'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, numberToCurrency, renderSuccessButton, renderWarningButton } from '../../_utils/Helper'
import { validateData } from '../../_utils/Validation'
import { generateStyle } from '../../_utils/DefaultStyle'

type Props = {}

interface IState {
    apartment_id: number | string,
    apartment_label: string
    type: string
    text: string
}

interface IDetail {
    total_broadcast: number,
    total_cost: number
}

interface ITemplate {
    id: number;
    qiscus_template_id: number;
    name: string;
    language: string;
    category: string;
    template_type: string;
    body: string;
    variables: string[];
    predefined_variables: (string | undefined)[];
}

const initialState = {
    apartment_id: 0,
    apartment_label: '',
    type: '',
    text: ''
}

const initialErrorSate = {
    apartment_id: '',
    apartment_label: '',
    type: '',
    text: ''
}

const initialDetail = {
    total_broadcast: 0,
    total_cost: 0
}

const initialTemplate = {
    id: 0,
    qiscus_template_id: 0,
    name: "",
    language: "",
    category: "",
    template_type: "",
    body: "",
    variables: [],
    predefined_variables: []
};

const BroadcastQiscus = (props: Props) => {
    const { Root, classes } = useStyles()
    const API_URL = `${process.env.REACT_APP_API_URL}/qiscus-mass-broadcast`
    const [inputState, setInputState] = useState<IState>(initialState)
    const [detailState, setDetailState] = useState<IDetail>(initialDetail)
    const [templateState, setTemplateState] = useState<ITemplate>(initialTemplate)
    const [errorState, setErrorState] = useState<Omit<IState, "apartment_id">>(initialErrorSate)
    const [reWriteAutoComplete, setReWriteAutocomplete] = useState(new Date().getTime())
    const [isLoading, setIsLoading] = useState(false)

    const handleChangeAutoComplete = (name: string, value: any) => {
        setInputState(prev => ({
            ...prev,
            [`${name}_id`]: value.id,
            [`${name}_label`]: value.label,
        }))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, autoCompleteName?: string) => {
        let { name, value } = e.target

        console.log(name, value)

        if (['apartment'].includes(autoCompleteName as string)) {
            return setInputState(prev => ({
                ...prev,
                [`${autoCompleteName}_id`]: 0,
                [`${autoCompleteName}_label`]: '',
            }))
        }

        setInputState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const fetchTemplateInformation = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/qiscus-broadcast/template-detail-broadcast`)
            .then(res => {
                setTemplateState(res.data)
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    const handleCheckBroadcast = () => {
        setIsLoading(true)
        const validation = validateData(inputState, {
            text: 'required'
        })

        if (!validation.isValid) {
            let newError = { ...errorState }
            newError = { ...newError, ...validation.errors }
            setErrorState(newError)
            setIsLoading(false)
        } else {
            setErrorState(initialErrorSate)
            DefaultAxios.get(API_URL, {
                params: inputState
            })
                .then(res => {
                    setDetailState(res.data)
                })
                .catch(generalErrorHandler)
                .finally(() => setIsLoading(false))
        }
    }

    const handleSendBroadcast = () => {
        setIsLoading(true)

        DefaultAxios.post(API_URL, inputState)
            .then(res => {
                renderSuccessButton('Broadcast Terkirim!')
                setInputState(initialState)
                setDetailState(initialDetail)
                setErrorState(initialErrorSate)
                setReWriteAutocomplete(new Date().getTime())
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))

    }

    useEffect(() => {
        fetchTemplateInformation()
    }, [])

    useEffect(() => {
        if (detailState.total_broadcast && detailState.total_cost) {
            renderWarningButton(`Apakah anda ingin mengirim ke ${detailState.total_broadcast} nomor, \nBiaya broadcast ± ${numberToCurrency(detailState.total_cost, 'Rp.')} \n Lanjutkan ?`)
                .then(res => {
                    if (res.value) {
                        handleSendBroadcast()
                    }
                })
        }

        // eslint-disable-next-line
    }, [detailState])

    return (
        <Root>
            <Grid container spacing={2}>
                <LoadingScreen open={isLoading} fullScreen />
                <Grid item sm={12} md={6}>
                    <Paper elevation={2} className={classes.card}>
                        <Grid container sx={{ mt: 0 }} spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name='text'
                                    disabled
                                    fullWidth
                                    value={templateState.body ? templateState.body : ''}
                                    multiline
                                    minRows={5}
                                    maxRows={5}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <AsyncAutoComplete
                                    label='Apartemen'
                                    name='apartment'
                                    clearable
                                    onClear={() => setInputState(prev => ({ ...prev, apartment_id: 0, apartment_label: '' }))}
                                    onChange={handleChangeAutoComplete}
                                    onInputChange={handleChange}
                                    initialQuery={inputState.apartment_label ? inputState.apartment_label : ''}
                                    key={reWriteAutoComplete}
                                    url={`${process.env.REACT_APP_API_URL}/autocomplete/apartment`}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    name='type'
                                    value={inputState.type}
                                    onChange={handleChange}
                                    label="Type"
                                    fullWidth
                                >
                                    <MenuItem value="" disabled>-- Pilih Type --</MenuItem>
                                    <MenuItem value="pic_landlord">PIC Landlord</MenuItem>
                                    <MenuItem value="customer">Tenant</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name='text'
                                    label="Content 1"
                                    disabled
                                    value="NAMA APARTEMEN"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextArea
                                    name='text'
                                    onChange={handleChange}
                                    value={inputState.text}
                                    label='Content 2'
                                    error={!!errorState.text}
                                    helperText={errorState.text}
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    sx={{ float: 'right' }}
                                    variant="contained"
                                    onClick={handleCheckBroadcast}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Root>
    )
}

const useStyles = generateStyle(theme => ({
    card: {
        border: '1px solid #eaeaea',
        padding: '20px',
    },
    horizontalLine: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    text: {
        textAlign: 'center',
        fontSize: '18px',
    }
}), "BroadcastQiscus")

export default BroadcastQiscus
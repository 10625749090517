import React, { useState } from 'react'
import { Grid, IconButton, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

/**
 * Components
 */
import MenuBar from '../../_components/MenuBar'
import DataTable from '../../_components/_dataTable/DataTable'

import JobDetailModal from './_components/JobDetailModal'
import { IOrderJobs } from '../_jhsOrder/_components/FixingCleaningForm'

/**
 * Utils
*/
import { format } from 'date-fns'
import id from 'date-fns/locale/id'

/**
 * ICon
 */
import ListIcon from '@mui/icons-material/List';
import PICModal from './_components/PICModal'
import EditIcon from '@mui/icons-material/Edit';
import { PAID_BY_OPTIONS } from '../../_utils/ConstData'


/**
 * Interfaces
 */

interface IJHSOrder {
    activity_id: number
    id: number
    request_date: string
    request_time: string
    work_code: string
    apartment_name: string
    unit_code: string
    bedroom: string
    key_collection_point: string
    pic_name: string
    job_details: IOrderJobs[]
}

type Props = {}

const DashboardJHSCoordinator = (props: Props) => {
    const [selectedRow, setSelectedRow] = useState<IJHSOrder | null>(null)
    const [jobModal, setJobModal] = useState(false)
    const [picModal, setPICModal] = useState(false)
    const [redraw, setRedraw] = useState(0)

    const handleSeeJobDetail = (row: IJHSOrder) => {
        setSelectedRow(row)
        setJobModal(true)
    }

    const handleEditPIC = (row: IJHSOrder) => {
        setSelectedRow(row)
        setPICModal(true)
    }

    const jhsOrderColumn = [
        { name: 'date', type: 'date', label: 'Request Date', filterable: true, render: (data: any) => data ? format(new Date(data), 'dd MMMM yyyy', { locale: id }) : '-' },
        { name: 'time', label: 'Request Time', filterable: false, render: (data: any) => data ? format(new Date(`1990-01-01T${data}`), 'HH:mm', { locale: id }) : '-' },
        {
            name: 'code', label: 'Kode Order', filterable: true, render: (data: string, row: IJHSOrder) => {
                return (
                    <Link
                        style={{ cursor: "pointer" }}
                        component={RouterLink}
                        to={`/jhs-order/${row.activity_id}`}
                    >
                        {data ? data : '[KLIK]'}
                    </Link>
                )
            }
        },
        { name: 'apartment_name', label: 'Apartement', filterable: true },
        { name: 'unit_code', label: 'Unit Code', filterable: true },
        {
            name: 'bedroom', label: 'Type', filterable: true,
            options: [
                { key: '', value: 'All' },
                { key: '0', value: 'Studio' },
                { key: '1', value: '1 Bedroom' },
                { key: '2', value: '2 Bedroom' },
                { key: '3', value: '3 Bedroom' },
                { key: '4', value: '4 Bedroom' },
                { key: '5', value: '5 Bedroom' },
                { key: '6', value: '6 Bedroom' },
                { key: '7', value: '7 Bedroom' },
                { key: '8', value: '8 Bedroom' },
                { key: '9', value: '9 Bedroom' },
                { key: '10', value: '10 Bedroom' },
            ],
            defaultOption: '',
            render: (row: number) => (
                row !== 0 ?
                    `${row} BR` :
                    `Studio`
            )
        },
        { name: 'key_collection_point', label: 'Key Collection Point', filterable: true },
        {
            name: 'paid_by',
            label: 'Paid By',
            filterable: true,
            options: PAID_BY_OPTIONS,
            defaultOption: '',
            render: (data: any, row: any) => {
                return (
                    PAID_BY_OPTIONS.filter(option => option.key === data)[0]?.value || '-'
                );
            }
        },
        {
            name: 'job_details', label: 'Job Detail', filterable: true, render: (data: any, row: any) => {
                return (
                    row.job_details && row.job_details.length ?
                        <IconButton
                            color='primary'
                            onClick={() => handleSeeJobDetail(row)}
                        >
                            <ListIcon />
                        </IconButton>
                        : '-'
                );
            }
        },
        {
            name: 'pic_name', label: 'PIC', filterable: true, render: (data: any, row: any) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ whiteSpace: 'nowrap', marginRight: 5 }}>{row.pic_name ? row.pic_name : '-'}</span>
                        <IconButton
                            color='primary'
                            onClick={() => handleEditPIC(row)}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                );
            }
        },
    ]

    const handleClosePICModal = (refresh: boolean) => {
        setPICModal(false)
        setSelectedRow(null)

        if (refresh) {
            setRedraw(new Date().getTime())
        }
    }

    const handleCloseJobModal = () => {
        setJobModal(false)
    }

    return (
        <MenuBar
            title='Dashboard JHS Coordinator'
            backButton={false}
        >

            <PICModal
                open={picModal}
                onClose={handleClosePICModal}
                id={selectedRow?.id || 0}
                initialQuery={selectedRow?.pic_name || ''}
            />

            <JobDetailModal
                open={jobModal}
                jobs={selectedRow?.job_details || []}
                onClose={handleCloseJobModal}
            />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DataTable
                        reDraw={redraw}
                        columns={jhsOrderColumn}
                        url={`${process.env.REACT_APP_API_URL}/dashboard-jhs-coordinator`}
                        rowsPerPage={100}
                    />
                </Grid>
            </Grid>
        </MenuBar>
    )
}

export default DashboardJHSCoordinator
import React, { useReducer, useState, useEffect, Fragment } from 'react';
import {
    useParams,
    useNavigate
} from "react-router-dom";
import {
    Grid,
    TextField,
    MenuItem,
    Button,
    InputAdornment
} from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Utils
// import { convertPercentangeWithDecimal } from '../../_utils/Helper';
import DefaultAxios from '../../_utils/DefaultAxios';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import TextEditor from '../../_components/_form/TextEditor';
import { numberToCurrency } from '../../_utils/Helper';

const SwalContainer = withReactContent(Swal);

interface Props {
    developerPropertyId: string | null;
    developerProjectId?: string | null;
    mode: 'add' | 'edit';
}

interface IState {
    name: string;
    price_start_from: string;
    property_area: string;
    land_area: string;
    bedroom: string;
    bathroom: string;
    carport: string;
    description: string;
}

interface IErrorState {
    name: string;
    price_start_from: string;
    property_area: string;
    land_area: string;
    bedroom: string;
    bathroom: string;
    carport: string;
    description: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const DeveloperPropertyDetailForm = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/project-developer/' + props.developerProjectId + '/property';
    // const classes = useStyles();
    const params: any = useParams();

    const [loading, setLoading] = useState(false);
    // const [firstInit, setFirstInit] = useState(true);

    const navigate =  useNavigate();

    const initialState = {
        project_developer_id: props.developerProjectId,
        name: '',
        price_start_from: '',
        property_area: '',
        land_area: '',
        bedroom: '0',
        bathroom: '0',
        carport: '0',
        description: '',
    };

    const initialErrorState = {
        name: '',
        price_start_from: '',
        property_area: '',
        land_area: '',
        bedroom: '',
        bathroom: '',
        carport: '',
        description: '',
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {

        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    useEffect(() => {
        if (props.mode === 'edit') {
            setLoading(true);
            DefaultAxios.get(`${API_URL}/${params.property_id}`)
                .then(res => {
                    const resData = res.data;
                    let data = resData
                    for (const [key, val] of Object.entries(data)) {
                        let name: string = key;
                        let value: any = val;
                        if (name === 'price_start_from') {
                            value = numberToCurrency(val as string);
                        }
                        if (name === 'land_area' && !value) {
                            value = '';
                        }
                        setInputState({ name, value, type: 'SET_ITEM' });
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    // eslint-disable-next-line
    }, [])

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        
        let value: any = target.value;
        let name = target.name;
        if (target.name === 'price_start_from') {
            value = numberToCurrency(value);
        }
        
        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleChangeNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ([69, 187, 189].includes(e.keyCode)) {
            e.preventDefault();
        }
    }

    const handleEditorChanged = (name: string, value: string) => {
        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newErrorState = { ...errorState };

        if (!inputState.name) {
            newErrorState.name = 'Nama harus diisi';
            isValid = false;
        } else {
            newErrorState.name = '';
        }

        if (!inputState.price_start_from) {
            newErrorState.price_start_from = 'Price Start From harus diisi';
            isValid = false;
        } else {
            newErrorState.price_start_from = '';
        }

        setErrorState({ name: '', value: newErrorState, type: 'REPLACE_STATE'});
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }
        
        if (props.mode === 'add') {
            DefaultAxios.post(`${API_URL}`, inputState)
                .then(res => {
                    SwalContainer.fire({
                        title: "Success",
                        html: "Success create data",
                        icon: 'success',
                    }).then(res => {
                        navigate(-1);
                        // location.push(`/developer/project/`)
                    })
                })
                .catch(error => {
                    if (typeof error.response.status === 'undefined') {
                        SwalContainer.fire({
                            title: "Error",
                            html: "Error pada script, harap hubungi admin",
                            icon: 'error',
                        })
                    } else if (error.response.status === 422) {
                        let errorJoin: any = [];
                        // eslint-disable-next-line
                        for (let [key, value] of Object.entries(error.response.data.errors)) {
                            // setErrorState({ name: key.toLowerCase() as string, value: value as string });
                            let valobj = new Array(value);
                            var text = valobj.join(', ');
                            errorJoin.push(text as string)
                        }
                        SwalContainer.fire({
                            title: "Error",
                            html: "Data tidak valid: <ul><li style='text-align:left'>" + errorJoin.join("</li><li style='text-align:left'>") + "</li></ul>",
                            icon: 'error',
                        })
                    } else if (error.response.status === 500) {
                        SwalContainer.fire({
                            title: "Error",
                            html: "Terjadi kesalahan pada server, harap hubungi admin",
                            icon: 'error',
                        })
                    }
                });
        } else {
            DefaultAxios.put(`${API_URL}/${params.property_id}`, inputState)
                .then(res => {
                    SwalContainer.fire({
                        title: "Success",
                        html: "Success update data",
                        icon: 'success',
                    })
                })
                .catch(error => {
                    if (typeof error.response.status === 'undefined') {
                        SwalContainer.fire({
                            title: "Error",
                            html: "Error pada script, harap hubungi admin",
                            icon: 'error',
                        })
                    } else if (error.response.status === 422) {
                        let errorJoin: any = [];
                        // eslint-disable-next-line
                        for (let [key, value] of Object.entries(error.response.data.errors)) {
                            // setErrorState({ name: key.toLowerCase() as string, value: value as string });
                            let valobj = new Array(value);
                            var text = valobj.join(', ');
                            errorJoin.push(text as string)
                        }
                        SwalContainer.fire({
                            title: "Error",
                            html: "Data tidak valid: <ul><li style='text-align:left'>" + errorJoin.join("</li><li style='text-align:left'>") + "</li></ul>",
                            icon: 'error',
                        })
                    } else if (error.response.status === 500) {
                        SwalContainer.fire({
                            title: "Error",
                            html: "Terjadi kesalahan pada server, harap hubungi admin",
                            icon: 'error',
                        })
                    }
                });
        }
    }

    return (
        <Fragment>
            <LoadingScreen open={loading} fullScreen />
            <Grid item xs={12}>
                <TextField
                    label="Nama"
                    name="name"
                    value={inputState.name}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!errorState.name}
                    helperText={errorState.name}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Property Area"
                    name="property_area"
                    type="number"
                    value={inputState.property_area}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!errorState.property_area}
                    helperText={errorState.property_area}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m2</InputAdornment>,
                    }}
                    inputProps = {{
                        onKeyDown: handleChangeNumber,
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Land Area"
                    name="land_area"
                    type="number"
                    value={inputState.land_area}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!errorState.land_area}
                    helperText={errorState.land_area}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m2</InputAdornment>,
                    }}
                    inputProps = {{
                        onKeyDown: handleChangeNumber,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Price Start From"
                    name="price_start_from"
                    value={inputState.price_start_from}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!errorState.price_start_from}
                    helperText={errorState.price_start_from}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    select
                    label="Bedroom"
                    name="bedroom"
                    value={inputState.bedroom}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!errorState.bedroom}
                    helperText={errorState.bedroom}
                >
                    <MenuItem value="0">&nbsp;</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    select
                    label="Bathroom"
                    name="bathroom"
                    value={inputState.bathroom}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!errorState.bathroom}
                    helperText={errorState.bathroom}
                >
                    <MenuItem value="0">&nbsp;</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    select
                    label="Carport"
                    name="carport"
                    value={inputState.carport}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    error={!!errorState.carport}
                    helperText={errorState.carport}
                >
                    <MenuItem value="0">&nbsp;</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <h3>Description</h3>
                <TextEditor
                    name="description"
                    value={inputState.description}
                    onChange={handleEditorChanged}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Grid>
        </Fragment>
    );
}

export default DeveloperPropertyDetailForm;
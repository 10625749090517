import React, { useState } from 'react';
import { Button, TextField, FormControlLabel, Checkbox, Theme } from '@mui/material';

// Interface
import { IPageFourState } from '../_interfaces/ListingInterface';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface IProps {
    states: IPageFourState,
    handleSubmit: Function,
    handleInputChange: Function,
    changePage: Function
    listing_type_id: string
}

interface IError {
    [key: string]: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    title: {
        color: '#033a11',
        fontWeight: 500
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    marker: {
        display: "block",
        position: "absolute",
        zIndex: 1,
        marginTop: "150px",
        marginLeft: "190px",
        width: "50px",
        height: "50px",
    },
    submit_btn: {
        borderRadius: "40px",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    FourInput: {
        display: 'flex'
    },
    FourInputLeft: {
        marginRight: '0.5rem'
    },
    FourInputRight: {
        marginLeft: '0.5rem'
    },
    checkBoxContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
}), "Page_Four"
);

const facilitiesList = ['Taman', 'Swimming Pool', 'AC', 'Microware', 'Kulkas', 'Water Heater', 'Balkon', 'Mesin Cuci', 'TV', 'Internet'];

const certificateList = ['Sertifikat Hak Milik', 'Hak Guna Bangunan', 'Hak Pakai', 'Hak Sewa', 'PPJB', 'Lainnya (Girk, Adat, dll)'];

const furnishList = [
    {
        value: 'non',
        label: 'Non Furnished'
    },
    {
        value: 'semi',
        label: 'Semi Furnished'
    },
    {
        value: 'full',
        label: 'Full Furnished'
    },
]

const viewList = ['Garden', 'Swimming Pool', 'Lake', 'River', 'Mountain', 'City'];

const directionList = ['Utara', 'Selatan', 'Timur', 'Barat', 'Timur Laut', 'Tenggara', 'Barat Laut', 'Barat Daya'];

const electricsList = [450, 1300, 2200, 3300, 3500, 4400, 5500, 6600, 7600, 7700, 8000, 9500, 10000,
    10600, 11000, 12700, 13200, 16500, 17600, 19000, 22000, 23000, 24000, 30500, 33000, 38100, 41500, 47500, 53000, 61000, 66000,
    76000, 82500, 85000, 95000, 0
];


export default function PageFour(props: IProps) {
    const { Root, classes } = useStyles();

    // Error State
    const [errorList, setErrorList] = useState<any>({});

    // Change
    const changeInput = (e: any) => {
        props.handleInputChange(e);

        let newErrorList = errorList;

        delete newErrorList[e.target.name];
        setErrorList(newErrorList);
    }

    const validateData = () => {
        let listOfErrors: IError = {};

        let except = ['notes', 'view', 'direction'];
        switch (parseInt(props.listing_type_id)) {
            case 1:
                /* Nothing */
                break;
            case 2:
                except.push('view', 'direction');
                break;
            case 3:
                except.push('building_finished', 'total_floor', 'furnish', 'electric_watt', 'facilities', 'view', 'direction');
                break;
            default:
                except.push('certificate', 'building_finished', 'total_floor', 'furnish', 'electric_watt', 'facilities', 'view', 'direction');
                break;
        }

        for (const key in props.states) {
            const data_value = (props.states as IPageFourState)[key];

            if (except.includes(key)) {
                continue;
            }

            if (data_value === "") {
                listOfErrors[key] = 'Harap isi form ini';
            }
        }

        if (Object.keys(listOfErrors).length === 0 && listOfErrors.constructor === Object) {
            props.handleSubmit();
        } else {
            window.scrollTo(0, 0);
            setErrorList(listOfErrors);
        }
    }

    const renderFacilities = facilitiesList.map((element, idx) => (
        <FormControlLabel
            key={idx}
            label={element}
            control={<Checkbox color="primary" checked={props.states.facilities.get(element) ? props.states.facilities.get(element) : false} onChange={changeInput} name={element} />}
        />
    ))

    const renderCertificates = certificateList.map((element, idx) => (<option key={idx} value={element}>{element}</option>))

    const renderFurnish = furnishList.map((element, idx) => (<option key={idx} value={element.value}>{element.label}</option>))

    const renderViews = viewList.map((element, idx) => (<option key={idx} value={element}>{element}</option>))

    const renderDirection = directionList.map((element, idx) => (<option key={idx} value={element}>{element}</option>))

    const renderElectric = electricsList.map((element, idx) => (<option key={idx} value={element}>{element === 0 ? 'Lainnya' : element}</option>))

    const renderView = () => {
        return (
            <>
                <TextField
                    label="Sertifikat"
                    name="certificate"
                    variant="outlined"
                    value={props.states.certificate}
                    onChange={changeInput}
                    fullWidth
                    error={errorList.certificate ? true : false}
                    helperText={errorList.certificate}
                    select
                    SelectProps={{
                        native: true,
                    }}
                    InputLabelProps={{
                        shrink: !!props.states.certificate
                    }}
                >
                    <option aria-label="None" value="" />
                    {renderCertificates}
                </TextField>

                {
                    parseInt(props.listing_type_id) !== 3 &&
                    <>
                        <TextField
                            label="Tahun Dibangun"
                            name="building_finished"
                            value={props.states.building_finished}
                            onChange={changeInput}
                            variant="outlined"
                            fullWidth
                            error={errorList.building_finished ? true : false}
                            helperText={errorList.building_finished}
                        />

                        <TextField
                            label="Jumlah Lantai"
                            name="total_floor"
                            value={props.states.total_floor}
                            onChange={changeInput}
                            variant="outlined"
                            fullWidth
                            error={errorList.total_floor ? true : false}
                            helperText={errorList.total_floor}
                        />

                        <TextField
                            label="Interior"
                            name="furnish"
                            variant="outlined"
                            value={props.states.furnish}
                            onChange={changeInput}
                            fullWidth
                            error={errorList.furnish ? true : false}
                            helperText={errorList.furnish}
                            select
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{
                                shrink: !!props.states.furnish
                            }}
                        >
                            <option aria-label="None" value="" />
                            {renderFurnish}
                        </TextField>

                        <TextField
                            label="Daya Listrik"
                            name="electric_watt"
                            variant="outlined"
                            value={props.states.electric_watt}
                            onChange={changeInput}
                            fullWidth
                            error={errorList.electric_watt ? true : false}
                            helperText={errorList.electric_watt}
                            select
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{
                                shrink: !!props.states.electric_watt
                            }}
                        >
                            <option aria-label="None" value="" />
                            {renderElectric}
                        </TextField>

                        {parseInt(props.listing_type_id) === 1 &&
                            <>
                                <TextField
                                    label="Hadap"
                                    name="view"
                                    variant="outlined"
                                    value={props.states.view}
                                    onChange={changeInput}
                                    fullWidth
                                    error={errorList.view ? true : false}
                                    helperText={errorList.view}
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: !!props.states.view
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {renderViews}
                                </TextField>

                                <TextField
                                    label="Arah"
                                    name="direction"
                                    variant="outlined"
                                    value={props.states.direction}
                                    onChange={changeInput}
                                    fullWidth
                                    error={errorList.direction ? true : false}
                                    helperText={errorList.direction}
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: !!props.states.direction
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {renderDirection}
                                </TextField>
                            </>
                        }


                        <h4 className={classes.title}>Fasilitas</h4>

                        <div className={classes.checkBoxContainer}>
                            {renderFacilities}
                        </div>
                    </>
                }

                <TextField
                    label="Catatan Pribadi"
                    name="notes"
                    value={props.states.notes}
                    onChange={changeInput}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    error={errorList.notes ? true : false}
                    helperText={errorList.notes ? errorList.notes : 'Tulisan ini tidak akan ditampilkan di iklan listing dan hanya bisa dilihat oleh anda'}
                    style={{ marginTop: '1.5rem' }}
                />
            </>
        )
    }

    return (
        <Root>
            <h4 className={classes.title}>Informasi Tambahan</h4>

            {renderView()}

            <Button
                variant="contained"
                className={classes.submit_btn}
                color="primary"
                onClick={validateData}
                fullWidth
                style={{ marginTop: '2rem', textTransform: 'initial' }}
            >
                Simpan dan Lihat Preview
            </Button>
        </Root>
    )
}
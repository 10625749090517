import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import AsyncMultipleAutoComplete from '../../_components/_form/AsycMultipleAutoComplete';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

const UnitKeyBorrowForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [units, setUnits] = useState<IAutoCompleteOption[]>([]);

    const handleAutoCompleteMultipleChange = (name: string, value: IAutoCompleteOption[]) => {
        setUnits(value);
    }

    const handleRequest = () => {
        setIsLoading(true);

        const ids = [];
        for (let index = 0; index < units.length; index++) {
            ids.push(units[index].id);
        }

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-key-manage/request-borrow`, {ids: ids})
        .then(res => {
            Swal.fire({
                title: "Request Disubmit",
                icon: 'success',
                timer: 1000
            })
                .then(() => {
                    window.location.reload();
                })
        })
        .catch(error => {
            setIsLoading(false);
            generalErrorHandler(error);
        });
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <AsyncMultipleAutoComplete
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/unit?entrusted_key_only=true`}
                        label="Unit"
                        name="unit_ids"
                        value={units}
                        onChange={handleAutoCompleteMultipleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleRequest}
                    >
                        Borrow Key
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default UnitKeyBorrowForm;

import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';


/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

const UnitKeyHistoryList = () => {
    const [isLoading, setIsLoading] = useState(false);

    const DetailCell = ({ label, value }: { label: string, value: string | number | JSX.Element }) => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={4} md={3} lg={2}>
                    <Grid container justifyContent="space-between">
                        <Grid><Typography variant="subtitle2">{label}</Typography></Grid>
                        <Grid><Typography variant="subtitle2">:</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} md={9} lg={10}>
                    <Typography variant="subtitle2">
                        {value}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const [units, setUnits] = useState({
        id: '',
        label: ''
    });

    const [detailKey, setDetailKey] = useState({
        apartment_name: '',
        code: '',
        floor: '',
        tower: '',
        unit_number: '',
        is_borrowed: false,
        logs: [{
            name: ''
        }],
        key_holder: ''
    })

    const handleAutoCompleteMultipleChange = (name: string, value: IAutoCompleteOption) => {
        setUnits({
            id: value.id.toString(),
            label: value.label
        })
    }

    const handleRequest = () => {
        setIsLoading(true);

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/advisor-unit-key?unit_id=${units.id}`)
            .then(res => {
                setDetailKey(res.data)
                setIsLoading(false);
            })
            .catch(error => {
                generalErrorHandler(error);
            });
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/unit`}
                        label="Unit"
                        name="unit_ids"
                        initialQuery={units.label}
                        onChange={handleAutoCompleteMultipleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleRequest}
                    >
                        Check History
                    </Button>
                </Grid>
                {
                    detailKey.code ?
                        <Grid item xs={12}>
                            <DetailCell
                                label="Apartemen"
                                value={!!detailKey.apartment_name ? (`${detailKey.apartment_name}`) : '-'}
                            />
                            <DetailCell
                                label="Unit"
                                value={!!detailKey.code ? (`Code: ${detailKey.code} | Tower: ${detailKey.tower} | Lantai: ${detailKey.floor} | No: ${detailKey.unit_number}`) : '-'}
                            />
                            <DetailCell
                                label="Kunci Dititipkan"
                                value={detailKey.is_borrowed === false ? 'Tidak' : 'Ya'}
                            />
                            <DetailCell
                                label="Key Holder"
                                value={!!detailKey.key_holder ? (`${detailKey.key_holder}`) : '-'}
                            />
                        </Grid>
                        : null
                }
            </Grid>
        </>
    );
}

export default UnitKeyHistoryList;
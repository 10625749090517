import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import AsyncMultipleAutoComplete from '../../../_components/_form/AsycMultipleAutoComplete'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete'

/**
 * Utils
 */
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import { generalErrorHandler, isMobile, renderSuccessButton } from '../../../_utils/Helper'
import { debounce } from 'lodash'
import DefaultAxios from '../../../_utils/DefaultAxios'

interface BulkReturnFormProps {
    isOpen: boolean
    onClose: () => void
    onFinish: () => void
    isLoading: boolean
    setIsLoading: (isLoading: boolean) => void
}

interface State {
    units: IAutoCompleteOption[]
    user: null | IAutoCompleteOption
    image: null | File
}

const BulkReturnForm = (props: BulkReturnFormProps) => {
    const [isScreenMobile, setIsScreenMobile] = useState(false)

    const [state, setState] = useState<State>({
        units: [],
        user: null,
        image: null,
    })
    const [error, setError] = useState<IValidationErrors>({})

    useEffect(() => {
        const handleResize = debounce(() => {
            setIsScreenMobile(isMobile())
        }, 250)

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        if (!props.isOpen) {
            setState({
                units: [],
                user: null,
                image: null,
            })

            setError({})
        }
    }, [props.isOpen])

    const handleMultipleAutocomplete = (name: string, value: IAutoCompleteOption[]) => {
        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, files} = e.target

        if (files && files.length > 0) {
            setState(prev => ({
                ...prev,
                [name]: files[0]
            }))
        }
    }

    const onSubmit = () => {
        const { isValid, errors } = validateData(state, validationRules, validationAlias)
        setError(errors)
        
        if (isValid) {
            props.setIsLoading(true)

            const fd = new FormData()

            state.units.forEach(unit => {
                fd.append('unit_ids[]', unit.id.toString())
            })

            fd.append('user_id', state.user!.id.toString())
            
            if (state.image) {
                fd.append('image', state.image)
            }

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-key/return-bulk`, fd)
                .then(() => {
                    renderSuccessButton('Berhasil Bulk Return Kunci', () => {
                        props.onFinish()
                        props.onClose()
                    })
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    props.setIsLoading(false)
                })
        }
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth="sm"
            fullScreen={isScreenMobile}
            fullWidth
        >
            <DialogTitle>Bulk Return Form</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{marginTop: 4}}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user`}
                            label="Key Holder"
                            name="user"
                            onChange={handleAutocomplete}
                            onInputChange={() => {}}
                            errorText={error.user}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AsyncMultipleAutoComplete
                            value={state.units}
                            label="Unit"
                            name="units"
                            onChange={handleMultipleAutocomplete}
                            url={`${process.env.REACT_APP_API_URL}/admin/autocomplete/unit-key/by-agent?agent_id=${state.user?.id}`}
                            error={!!error.units}
                            helperText={error.units}
                            disabled={state.user === null}
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <h5 style={{marginTop: 0}}>
                            Upload File
                        </h5>
                        <input type="file" name="image" onChange={onChangeFile} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant='contained'
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant='contained'
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const validationRules: IValidationRules = {
    units: 'required',
    user: 'required',
}

const validationAlias: IValidationAlias = {
    units: 'Unit',
    user: 'Borrower',
}

export default BulkReturnForm

import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../_components/LoadingScreen';
import {
    Grid, Card, CardContent, Typography, TextField, Button, Theme, MenuItem,
} from '@mui/material';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        maxWidth: '750px',
        marginBottom: '70px',
        boxShadow: '0px 3px 15px #0000001A',
        '& .Mui-disabled': {
            backgroundColor: '#eee'
        }
    },
    submitContainer: {
        marginTop: '25px'
    },
    // title: {}
}), "ActivityForm_Mobile"
)

interface IState {
    activity: string
    apartment_id: string
    apartment_data: IApartmentData[]
    enquiry_id: string
    enquiry_label: string
    unit_id: string
    unit_label: string
    closing_id: string
    closing_label: string
    /** Not required */
    notes: string
}

interface IApartmentData {
    name: string
    id: string
}

const ActivityFormMobile = () => {
    const { Root, classes } = useStyles();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isLoadingGPS, setIsLoadingGPS] = useState(true)
    const [errorGps, setErrorGps] = useState(false)

    const initialState = {
        activity: '',
        apartment_id: '',
        apartment_data: [] as IApartmentData[],
        enquiry_id: '',
        enquiry_label: '',
        unit_id: '',
        unit_label: '',
        closing_id: '',
        closing_label: '',
        notes: ''
    };

    const [inputState, setInputState] = useState<IState>(initialState)
    const [latLong, setLatLong] = useState({
        latitude: 0,
        longitude: 0
    })

    const getLocation = () => {
        setIsLoadingGPS(true)
        const location = window.navigator && window.navigator.geolocation
        if (location) {
            location.getCurrentPosition((position) => {
                fetchClosestApartment(position.coords.latitude, position.coords.longitude)
                setLatLong({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                })
            }, (error) => {
                setErrorGps(true)
            })
        }
    }

    const fetchClosestApartment = (latitude: number, longitude: number) => {
        setIsLoadingGPS(true)
        const data = {
            latitude: latitude,
            longitude: longitude
        }

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/showing/closest-apartment`, { params: data })
            .then(res => res.data)
            .then(data => {
                setInputState(prev => ({
                    ...prev,
                    apartment_data: data
                }))
                if (data.length === 1) {
                    setInputState(prev => ({
                        ...prev,
                        apartment_id: data[0].id
                    }))
                }
                setIsLoadingGPS(false)
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (name === 'apartment_id' && inputState.apartment_data.length !== 1) {
            setInputState(prev => ({
                ...prev,
                unit_id: '',
            }))
        }

        if (name === 'activity' && inputState.apartment_data.length !== 1) {
            setInputState(prev => ({
                ...prev,
                apartment_id: '',
                enquiry_id: '',
                unit_id: '',
                closing_id: '',
                notes: ''
            }))
        }

        setInputState(prev => ({
            ...prev,
            [name]: value,
        }))

    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        // setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        if (name === 'enquiry_id') {
            setInputState(prev => ({
                ...prev,
                enquiry_id: value.id.toString(),
                enquiry_label: value.label
            }))
        } else if (name === 'unit_id') {
            setInputState(prev => ({
                ...prev,
                unit_id: value.id.toString(),
                unit_label: value.label
            }))
        } else {
            setInputState(prev => ({
                ...prev,
                closing_id: value.id.toString(),
                closing_label: value.label
            }))
        }
    }

    const apartmentOptions =
        inputState.apartment_data?.map(data => ({ label: data.name, value: data.id }))
        ;

    const handleSubmit = () => {
        setLoading(true)
        const data = {
            is_mobile: 1,
            latitude: latLong.latitude,
            longitude: latLong.longitude,
            activity: inputState.activity,
            apartment_id: inputState.activity !== '9' ? inputState.apartment_id : null,
            unit_id: inputState.activity !== '9' ? inputState.unit_id : null,
            enquiry_id: inputState.activity === '1' ? inputState.enquiry_id : null,
            closing_id: inputState.activity === '9' ? inputState.closing_id : null,
            notes: inputState.notes
        }
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity`, data)
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        navigate('/');
                    })
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getLocation()
        // eslint-disable-next-line
    }, [])

    return (
        <Root>
            <LoadingScreen open={loading} fullScreen />
            <Card className={classes.root}>
                <CardContent>
                    {
                        errorGps ?
                            <>
                                <Typography variant="h5" align='center'>
                                    Harap aktifkan fitur lokasi
                                </Typography>
                                <Grid item xs={12} className={classes.submitContainer} style={{ textAlign: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => window.location.reload()}
                                    >
                                        Refresh Halaman
                                    </Button>
                                </Grid>
                            </>
                            :
                            (inputState.apartment_data.length === 0 && !isLoadingGPS) ?
                                <>
                                    <Typography variant="h5" align='center'>
                                        Apartemen Tidak Ditemukan
                                    </Typography>
                                    <Typography variant="subtitle2" align='center'>
                                        Bila ini suatu kesalahan, hubungi Admin
                                    </Typography>
                                    <Typography variant="subtitle2" align='center' color='primary' style={{ cursor: 'pointer' }}>
                                        <a href="https://wa.me/+6285719391749" target="_blank" rel="noopener noreferrer">
                                            +62 857-1939-1749
                                        </a>
                                    </Typography>
                                </>
                                :
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            fullWidth
                                            variant="outlined"
                                            label="Activity"
                                            name="activity"
                                            value={inputState.activity}
                                            onChange={handleChange}
                                        >
                                            {activityOptions.map(item => <MenuItem value={item.value} key={item.value} >{item.label}</MenuItem>)}
                                        </TextField>
                                    </Grid>
                                    {
                                        inputState.activity &&
                                        <>
                                            {
                                                inputState.activity === '1' &&
                                                <Grid item xs={12}>
                                                    <AsyncAutoComplete
                                                        label="Enquiry"
                                                        name="enquiry_id"
                                                        initialQuery={inputState.enquiry_label}
                                                        onChange={handleAutocomplete}
                                                        onInputChange={handleAutocompleteInputChanged}
                                                        url={`${process.env.REACT_APP_API_URL}/admin/autocomplete/enquiry/activity`}
                                                        placeholder="Enquiry"
                                                    // errorText={errorState.enquiry_id}
                                                    />
                                                </Grid>
                                            }
                                            {
                                                inputState.activity !== '9' &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            select
                                                            fullWidth
                                                            variant="outlined"
                                                            label="Apartment"
                                                            name="apartment_id"
                                                            value={inputState.apartment_id}
                                                            onChange={handleChange}
                                                        >
                                                            {apartmentOptions.map(item => <MenuItem value={item.value} key={item.value} >{item.label}</MenuItem>)}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <AsyncAutoComplete
                                                            label="Unit"
                                                            name="unit_id"
                                                            initialQuery={inputState.unit_label}
                                                            onChange={handleAutocomplete}
                                                            onInputChange={handleAutocompleteInputChanged}
                                                            url={inputState.activity !== '1' ? `${process.env.REACT_APP_API_URL}/autocomplete/unit?apartment_id=${inputState.apartment_id}` : `${process.env.REACT_APP_API_URL}/autocomplete/unit?show_available_only=1&apartment_id=${inputState.apartment_id}`}
                                                            placeholder="Unit"
                                                        // errorText={errorState.enquiry_id}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                inputState.activity === '9' &&
                                                <Grid item xs={12}>
                                                    <AsyncAutoComplete
                                                        label="Closing"
                                                        name="closing_id"
                                                        initialQuery={inputState.closing_label}
                                                        onChange={handleAutocomplete}
                                                        onInputChange={handleAutocompleteInputChanged}
                                                        url={`${process.env.REACT_APP_API_URL}/admin/autocomplete/closing`}
                                                        placeholder="Closing"
                                                    // errorText={errorState.enquiry_id}
                                                    />
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Notes"
                                                    multiline
                                                    name="notes"
                                                    value={inputState.notes}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.submitContainer}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                    }
                </CardContent>
            </Card>
        </Root>
    )
}

const activityOptions = [
    {
        value: '1',
        label: 'Showing'
    },
    {
        value: '3',
        label: 'Maintenance'
    },
    {
        value: '7',
        label: 'Visit Owner'
    },
    {
        value: '9',
        label: 'Handover Checkin'
    },
];

export default ActivityFormMobile;
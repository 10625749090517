import React from 'react';
import { Dialog, DialogContent } from '@mui/material';

import CustomerFormMain from '../../../_customer/CustomerFormMain';

interface IProps {
    open: boolean
    onClose: (fetch: boolean) => void
    id: number
}


const TenatEditModal = (props: IProps) => {
    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
        >
            <DialogContent>
                <CustomerFormMain
                    modal
                    id={props.id}
                    submitModal={() => props.onClose(true)}
                />
            </DialogContent>
        </Dialog>
    )
}

export default TenatEditModal
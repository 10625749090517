import React, { Suspense } from 'react';
import { Theme } from '@mui/material';

// Table
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { generateStyle } from '../../_utils/DefaultStyle';
// import TableSortLabel from '@mui/material/TableSortLabel';

const TableSortLabel = React.lazy(() => import('@mui/material/TableSortLabel'))

export type Order = 'asc' | 'desc' | false;

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string;
    headCells: any;
    sorting: boolean;
}

const useStyles = generateStyle((theme: Theme) => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}), "SortableTableHeader")

function SortableTableHeader(props: EnhancedTableProps) {
    const { Root, classes } = useStyles();
    const { order, orderBy, onRequestSort, headCells, sorting } = props;

    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableRow>
            {headCells.map((headCell: any) => (
                <TableCell
                    key={headCell.name}
                    sortDirection={orderBy === headCell.name ? order : false}
                    style={headCell.style}
                    {...headCell.columnProps}
                >
                    <Root>
                        {
                            sorting &&
                                (typeof headCell.sortable !== 'undefined' ? headCell.sortable : true)
                                ? (
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <TableSortLabel
                                            active={orderBy === headCell.name}
                                            direction={order && orderBy === headCell.name ? order : 'asc'}
                                            onClick={createSortHandler(headCell.name)}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.name ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </Suspense>
                                )
                                : headCell.label
                        }
                    </Root>
                </TableCell>
            ))}
        </TableRow>
    );
}

export default SortableTableHeader;
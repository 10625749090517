import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// Components
import LoadingScreen from '../../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import { dateFormat } from '../../../_utils/Helper';
import ImagePreview from '../../../_components/ImagePreview';

interface Props {
    unitId: number | null;
    API_URL: string;
    processRes?: (resData: any) => any
    withImage?: boolean
    refresh?: number
}

const KeyLogList = (props: Props) => {
    const [logs, setLogs] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState('')

    const keyLogStatus = [
        'Dikembalikan',
        'Dipinjam',
        'Ditransfer',
        'Direlease',
        'Custom'
    ];

    useEffect(() => {
        if (props.unitId) {
            loadLogs();
        } else {
            props.processRes && props.processRes(null)
            setLogs([])
        }
        // eslint-disable-next-line
    }, [props.unitId]);

    const loadLogs = () => {
        setIsLoading(true);
        DefaultAxios
            .get(props.API_URL, { params: { unit_id: props.unitId } })
            .then(res => {
                const data = props.processRes ? props.processRes(res.data) : res.data
                setLogs(data);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        if (props.refresh && props.unitId) {
            loadLogs()
        }
        // eslint-disable-next-line
    }, [props.refresh])

    const renderLogs = () => {
        if (logs.length > 0) {
            return logs.map((log: any, key: number) => {
                return <TableRow>
                    <TableCell>{dateFormat(log.date, 'DD-MM-YYYY HH:mm:ss')}</TableCell>
                    <TableCell>{log.user_name || log.name}</TableCell>
                    <TableCell>{log.key_manager_name}</TableCell>
                    <TableCell>{keyLogStatus[log.status]}</TableCell>
                    {
                        props.withImage &&
                        <TableCell>
                            {
                                log.image_url ?
                                    <img
                                        src={log.image_url}
                                        alt=""
                                        style={{ width: 100, height: 100, objectFit: 'cover', objectPosition: 'center', cursor: 'pointer' }}
                                        onClick={() => setSelectedImage(log.image_url)}
                                    />
                                    :
                                    '-'
                            }
                        </TableCell>
                    }
                    <TableCell>{log.description || '-'}</TableCell>
                </TableRow>
            });
        } else {
            return <TableRow>
                <TableCell colSpan={5}>There is no logs yet</TableCell>
            </TableRow>
        }
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <ImagePreview
                image={selectedImage}
                onClose={() => setSelectedImage('')}
            />
            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Tanggal
                            </TableCell>
                            <TableCell>
                                Nama
                            </TableCell>
                            <TableCell>
                                Key Manager
                            </TableCell>
                            <TableCell>
                                Action
                            </TableCell>
                            {
                                props.withImage &&
                                <TableCell>
                                    Gambar
                                </TableCell>
                            }
                            <TableCell>
                                Keterangan
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderLogs()}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default KeyLogList;
import React, { Fragment, ReactNode } from 'react'
import { Box, Theme } from '@mui/material';

import ApartmentCard from './ApartmentCard';
import { generateStyle } from '../../_utils/DefaultStyle';


const useStyles = generateStyle((theme: Theme) => ({
  root: {
    flexGrow: 1,
    color: '#505050'
  },
  highlight_container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  }
}), "ApartmentHighlight"
);

const ApartmentHighlights = (props: any) => {
  const { Root, classes } = useStyles();

  const renderSummary = (): ReactNode => {
    let idx = 0;
    let list = [<Fragment key={idx}></Fragment>]
    for (let i in props.data) {
      idx += 1
      if (idx <= 5) {
        list.push(<ApartmentCard aptData={props.data[i]} title={i} index={idx} key={idx} delay={(idx - 1) * 15 + 'ms'} />)
      }
    }
    return <>{list}</>;
  }

  return (
    <Root>
      <Box className={classes.highlight_container}>
        {renderSummary()}
      </Box>
    </Root>
  )
}

export default ApartmentHighlights;
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import DialogND from '../../../_components/_newDesignComponents/_modal/DialogND'
import { JHSScheduleSchedule } from './JHSScheduleCard'
import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete'
import MuiTimePicker from '../../../_components/MuiTimePicker'
import MuiDatePicker from '../../../_components/MuiDatePicker'

/**
 * Utils
 */
import { IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import { format } from 'date-fns'

/**
 * Icons
 */
import { Close } from '@mui/icons-material'
import { generateStyle } from '../../../_utils/DefaultStyle'

interface JHSScheduleRescheduleModalProps {
    isOpen: boolean
    onClose: () => void
    data: JHSScheduleSchedule | null
    confirmReschedule: (rescheduleData: JHSScheduleRescheduleData, scheduleData: JHSScheduleSchedule) => void
}

export type JHSScheduleRescheduleData = {
    pic_name: string
    pic_id: string
    date: Date | null
    time: Date | null
}

const JHSScheduleRescheduleModal = (props: JHSScheduleRescheduleModalProps) => {
    const { Root, classes } = useStyles()

    const [state, setState] = useState<JHSScheduleRescheduleData>(initialState)

    const [error, setError] = useState<IValidationErrors>({})

    useEffect(() => {
        if (props.data && props.data.pic_id && props.data.pic_name) {
            setState(prev => ({
                ...prev,
                pic_id: props.data!.pic_id!.toString(),
                pic_name: props.data!.pic_name!,
                date: props.data?.date ? new Date(props.data.date) : prev.date,
                time: props.data?.time ? new Date(`${props.data?.date || format(new Date(), 'yyy-MM-dd')} ${props.data.time}`) : prev.time,
            }))
        }
    }, [props.data])

    useEffect(() => {
        if (!props.isOpen) {
            setState(initialState)
        }
    }, [props.isOpen])

    const handleAutocompletePic = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            pic_name: value.label,
            pic_id: value.id.toString(),
        }))
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            pic_name: '',
            pic_id: '',
        }))
    }

    const onDateChange = (date: Date | null) => {
        setState(prev => ({
            ...prev,
            date,
        }))

        setError(prev => ({
            ...prev,
            date: '',
        }))
    }

    const onTimeChange = (time: Date | null) => {
        setState(prev => ({
            ...prev,
            time,
        }))

        setError(prev => ({
            ...prev,
            time: '',
        }))
    }

    const onSubmit = () => {
        const { isValid, errors } = validateData(state, validationRules)

        setError(errors)
        if (isValid) {
            props.confirmReschedule(state, props.data!)
        }
    }

    return (
        <DialogND
            open={props.isOpen}
            onClose={props.onClose}
        >
            <Root>
                <Box className={classes.root}>
                    <Box display='flex' alignItems='center' justifyContent='space-between' sx={{ mb: 5, px: 1.25 }}>
                        <Typography variant='subtitle1' fontWeight={500}>
                            Reschedule
                        </Typography>
                        <IconButton onClick={props.onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box>
                        <AsyncAutoComplete
                            label="Nama PIC"
                            name="pic_id"
                            initialQuery={state.pic_name}
                            onChange={handleAutocompletePic}
                            onInputChange={handleAutocompleteInputChanged}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user/jhs`}
                            placeholder="PIC JHS"
                            newDesign
                        />
                        <Grid container sx={{ mt: 2.5 }}>
                            <Grid item xs={6} sx={{ pr: 1 }}>
                                <MuiDatePicker
                                    label='Tanggal'
                                    onChange={onDateChange}
                                    value={state.date}
                                    clearable
                                    onClear={() => onDateChange(null)}
                                    newDesign
                                    withoutIcon
                                    minDate={new Date()}
                                    error={!!error.date}
                                    helperText={error.date}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ pl: 1 }}>
                                <MuiTimePicker
                                    label='Jam'
                                    onChange={onTimeChange}
                                    value={state.time}
                                    clearable
                                    onClear={() => onTimeChange(null)}
                                    newDesign
                                    withoutIcon
                                    error={!!error.time}
                                    helperText={error.time}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mt: 5 }} display='flex' alignItems='center' justifyContent='space-between'>
                        <Button
                            variant='contained'
                            fullWidth
                            onClick={onSubmit}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Root>
        </DialogND>
    )
}

const useStyles = generateStyle(() => ({
    root: {
        padding: 20,
        maxWidth: 360,
        width: '100%',
    },
}), "JHSRescheduleModal")

const validationRules: IValidationRules = {
    date: 'required',
    time: 'required',
}

const initialState = {
    pic_name: '',
    pic_id: '',
    date: null,
    time: null,
}

export default JHSScheduleRescheduleModal

import React from "react";
import {
    BottomNavigation,
    BottomNavigationAction,
} from '@mui/material';

/**
 * Icon
 */
import ListIcon from '@mui/icons-material/List';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';

interface IProps {
    menuValue: number;
    changeMenu: Function
}

const MainBottomNavigation = ({ menuValue, changeMenu }: IProps) => {
    return (
        <BottomNavigation
                value={menuValue}
                showLabels
                onChange={(_, newValue) => {
                    changeMenu(newValue);
                }}
                style={{
                    width: '100%',
                    position: 'fixed',
                    bottom: 0,
                }}
            >
                <BottomNavigationAction label="Active" icon={<ListIcon />} />
                <BottomNavigationAction label="Pending" icon={<PauseCircleFilledIcon />} />
            </BottomNavigation>
    )
}

export default MainBottomNavigation;
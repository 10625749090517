import React, { useReducer, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
} from '@mui/material';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../../_components/_form/AsyncAutoComplete';

/**
 * Utils
 */
import DefaultAxios from '../../../../_utils/DefaultAxios';
import { generalErrorHandler, renderToastSuccess } from '../../../../_utils/Helper';

interface IProps {
    unitId: number;
    picId: number;
    name: string;
    open: boolean;
    onClose: Function;
    callBackSubmitSuccess: Function;
}

interface IState {
    id: number;
    name: string;
}

interface IAction {
    name: string;
    value: any;
    type: string;
}

const initialState: IState = {
    id: 0,
    name: ""
}

const AssignPICListingModal = ({ unitId, picId, name, open, onClose, callBackSubmitSuccess }: IProps) => {
    const stateReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    }

    const [state, setState] = useReducer(stateReducer, initialState);

    useEffect(() => {
        setState({
            name: '', value: {
                id: picId,
                name: name
            }, type: 'REPLACE_STATE'
        });
    }, [open, picId, name])

    const handleSubmit = () => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/assign-pic-listing/change`, {
            id: unitId,
            pic_listing_id: state.id
        }).then(() => {
            renderToastSuccess('PIC Listing dirubah')
            callBackSubmitSuccess()
            onClose()
        }).catch(err => {
            generalErrorHandler(err)
        })
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={() => onClose()}
        >
            <DialogTitle>Change Listing PIC</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ marginTop: 0 }}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            label="PIC Listing"
                            name="unit_id"

                            initialQuery={name}
                            onChange={(name: string, value: IAutoCompleteOption) => {
                                const newState = { ...state };
                                newState.id = value.id;
                                newState.name = value.label;

                                setState({ name: '', value: newState, type: 'REPLACE_STATE' });
                            }}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user/active-listing-admin`}
                            placeholder="PIC Listing"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default AssignPICListingModal;
import React from 'react';
import { CircularProgress } from '@mui/material';
import { generateStyle } from '../_utils/DefaultStyle';

interface Props {
    open: boolean;
    fullScreen?: boolean;
    children?: React.ReactNode
}

const useStyles = generateStyle(theme => ({
    backdropRoot: {
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.3)',
    },
    circularRoot: {
        top: '50%',
        position: 'absolute',
        left: 'calc(50% - 20px)',
        opacity: 0.3,
        zIndex: 1,
    }
}), "Loading_Screen"
)

const LoadingScreen = (props: Props) => {
    const { Root, classes } = useStyles();

    return (
        <Root>
            <div
                className={classes.backdropRoot}
                style={{
                    display: props.open ? 'block' : 'none',
                    position: props.fullScreen ? 'fixed' : 'absolute',
                    zIndex: props.fullScreen ? 1301 : 1099
                }}
            >
                {
                    props.children ?
                        props.children
                        :
                        <CircularProgress className={classes.circularRoot} />
                }
            </div>
        </Root>
    );
}

LoadingScreen.defaultProps = {
    fullScreen: false
};

export default LoadingScreen;
import React from 'react'

/**
 * Components
 */
import { Box, Button, useTheme } from '@mui/material'

interface ButtonMultipleND2Props {
    name: string
    value: string | string[]
    options: {
        label: string
        value: string
    }[]
    onChange: (name: string, value: string) => void
}

const ButtonMultipleND2 = (props: ButtonMultipleND2Props) => {
    const theme = useTheme()

    return (
        <Box>
            {
                props.options.map((option, index) =>
                    <Button
                        key={index}
                        onClick={() => {
                            props.onChange(props.name, option.value)
                        }}
                        variant='contained'
                        sx={{
                            backgroundColor: (props.value === option.value) || (props.value.includes(option.value)) ? theme.palette.primary.main : 'white',
                            color: (props.value === option.value) || (props.value.includes(option.value)) ? 'white' : '#484848',
                            borderRadius: 100 / 4,
                            paddingTop: 10 / 8,
                            paddingBottom: 10 / 8,
                            paddingLeft: 20 / 8,
                            paddingRight: 20 / 8,
                            marginRight: 14 / 8,
                            marginBottom: 14 / 8,
                            fontSize: 14,
                            fontWeight: '500',
                            boxShadow: 'none',
                            textTransform: 'none',
                            lineHeight: 1.4,
                            border: `1px solid ${(props.value === option.value) || (props.value.includes(option.value)) ? theme.palette.primary.main : '#DDDDDD'}`,
                            "&:hover": {
                                color: 'white',
                                backgroundColor: (props.value === option.value) || (props.value.includes(option.value)) ? theme.palette.primary.main : theme.palette.primary.dark,
                                boxShadow: 'none',
                                border: `1px solid ${(props.value === option.value) || (props.value.includes(option.value)) ? theme.palette.primary.main : theme.palette.primary.dark}`,
                            },
                        }}
                    >
                        {option.label}
                    </Button>
                )
            }
        </Box>
    )
}

export default ButtonMultipleND2

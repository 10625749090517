import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Autocomplete, Button, Dialog, DialogContent, Grid, MenuItem, TextField } from '@mui/material'
import TextFieldND2 from '../../../../_components/_formElements/_newDesign2/TextFieldND2'

/**
 * Utils
 */
import { roomTypes, IUploadedImage } from './ImageUploaderModal'
import { generateStyle } from '../../../../_utils/DefaultStyle'

type Props = {
    index: number
    open: boolean
    onClose: () => void
    data: IUploadedImage
    onSubmit: (newData: IUploadedImage) => void
    type?: string;
}

const useStyles = generateStyle(theme => ({
    imageContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
    },
    image: {
        borderRadius: '10px',
        objectFit: 'cover',
        objectPosition: 'center',
        width: '100%',
        height: '100%'
    },
    thumbnail: {
        position: 'absolute',
        top: 7,
        left: 7,
        background: 'rgba(255,255,255,0.9)'
    },
    buttonActionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: 10,
        alignItems: 'center'
    }
}), 'ImageEdit_Modal')


const ImageEditModal = (props: Props) => {
    const { Root, classes } = useStyles()
    const [inputState, setInputState] = useState<{
        type: string,
        caption: string
    }>({ type: '', caption: '' })

    const captionsOptions = [
        'Master Bedroom',
        '2nd Bedroom',
        '3rd Bedroom',
        'Living Room',
        'Kitchen',
        'Balcony',
        'Swimming Pool',
        'Jogging Track',
        'Masterplan Project',
        'Denah Ruko',
        'Siteplan Cluster',
        'Dining Room',
        'Master Bathroom',
        "2nd Bathroom",
        "3rd Bathroom",
        "Backyard",
        "Frontyard",
        "Balcony",
        "Living Room 1st Floor",
        "Living Room 2nd Floor"
    ]



    useEffect(() => {
        if (props.data !== undefined) {
            setInputState({
                type: props.data.type,
                caption: props.data.caption
            })
        }
    }, [props.data])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const extraData: { [key: string]: string } = {}
        const { name, value } = e.target

        if (name === 'type' && value !== 'other') {
            extraData['caption'] = roomTypes.filter(roomType => roomType.type === value)[0].title || ''
        }

        setInputState(prev => ({
            ...prev,
            [e.target.name]: e.target.value,
            ...extraData,
        }))
    }

    const handleSubmit = () => {
        props.onSubmit({
            file: props.data.file,
            caption: inputState.caption,
            type: inputState.type
        })
        props.onClose()
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                style: {
                    borderRadius: 20
                }
            }}
        >
            <DialogContent>
                <Root>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <img
                                src={
                                    props.data.thumbnail ?
                                        props.data.thumbnail :
                                        props.data.file ? URL.createObjectURL(props.data.file)
                                            : ''
                                }
                                alt={props.data.caption}
                                className={classes.image}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} marginTop={{ md: 1, xs: 1 }}>
                                    {props.type === "apartment" ?
                                        <Autocomplete
                                            forcePopupIcon={false}
                                            freeSolo
                                            value={inputState.caption}
                                            options={captionsOptions}
                                            onChange={(event, newValue) => {
                                                handleChange({
                                                    target: {
                                                        name: 'caption',
                                                        value: newValue || ''
                                                    }
                                                } as React.ChangeEvent<HTMLInputElement>);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    name="caption"
                                                    variant="standard"
                                                    onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>)}
                                                    placeholder="Max 50 karakter"
                                                    label="Caption"
                                                />
                                            )}
                                        /> :
                                        <TextFieldND2
                                            name='type'
                                            size='small'
                                            select
                                            value={inputState.type || ''}
                                            onChange={handleChange}
                                        >
                                            {
                                                roomTypes.map((roomType) => (
                                                    <MenuItem value={roomType.type}>{roomType.title}</MenuItem>
                                                ))
                                            }
                                        </TextFieldND2>}
                                </Grid>
                                <Grid item xs={12}>
                                    {props.type === "apartment" ? null :
                                        <TextFieldND2
                                            name='caption'
                                            size='small'
                                            placeholder='Caption'
                                            value={inputState.caption || ''}
                                            onChange={handleChange}
                                            error={inputState.caption === ''}
                                            helperText={inputState.caption === '' ? 'Caption wajib diisi' : ''}
                                        />}
                                </Grid>
                                <Grid item xs={12} className={classes.buttonActionContainer}>
                                    <Button onClick={props.onClose}>
                                        Cancel
                                    </Button>
                                    <Button variant='contained' onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Root>
            </DialogContent>
        </Dialog >
    )
}

export default ImageEditModal
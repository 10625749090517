import React, { useEffect, useMemo, useState } from 'react';
import {
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    FormControlLabel,
} from '@mui/material';

/**
 * Components
 */
import TextArea from '../../../../_components/_form/TextArea';
import MuiDatePicker from '../../../../_components/MuiDatePicker';
import ButtonWithLoading from '../../../../_components/_form/ButtonWithLoading';
import TextFieldSelect from '../../../../_components/_form/TextFieldSelect';

/**
 * Utils
 */
import DefaultAxios from '../../../../_utils/DefaultAxios';
import { dateFormat, generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../../../_utils/Helper';
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../../_utils/Validation';

interface IProps {
    id: number;
    open: boolean;
    isAvailable: number;
    onClose: Function;
    callBackSubmitSuccess?: Function;
    setIsLoading: Function;
    isLoading?: boolean
}

interface State {
    date: Date | null;
    comment: string;
    endOfAvailability: boolean;
    unavailable_type: string
}

const INITIAL_STATE: State = {
    date: null,
    comment: '',
    endOfAvailability: false,
    unavailable_type: '',
}

const SwitchAvailableModal = ({ id, open, isAvailable, onClose, setIsLoading, callBackSubmitSuccess, isLoading }: IProps) => {
    const [state, setState] = useState<State>(INITIAL_STATE)
    const [error, setError] = useState<IValidationErrors<State>>({})

    useEffect(() => {
        if (!open) {
            setState(INITIAL_STATE)
            setError({})
        }
    }, [open])

    const validationRules: IValidationRules = useMemo(() => ({
        date: !!isAvailable && !state.endOfAvailability ? 'required' : '',
        unavailable_type: !!isAvailable ? 'required' : '',
        comment: 'required',
    }), [isAvailable, state.endOfAvailability])

    const handleChange = (name: string, value: string | null | Date | boolean) => {
        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAvailableSubmit = () => {
        const { errors, isValid } = validateData(state, validationRules, VALIDATION_ALIAS)
        setError(errors)

        if (isValid) {
            const sendToAPI = () => {
                setIsLoading(true);

                const data = {
                    id: id,
                    comment: state.comment,
                    endOfAvailability: state.endOfAvailability,
                    date: state.date ? dateFormat(state.date, 'MM/DD/YYYY') : null,
                    unavailable_type: !!isAvailable ? state.unavailable_type : null,
                };

                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit/change/rent-date`, data)
                    .then((res: any) => {
                        if (typeof callBackSubmitSuccess !== "undefined") {
                            callBackSubmitSuccess();
                        }
                        renderToastSuccess('Status Available berhasil dirubah');
                        onClose();
                    })
                    .catch((err: any) => {
                        generalErrorHandler(err);
                    })
                    .finally(() => {
                        setIsLoading(false)
                    });
            }

            if (state.endOfAvailability) {
                renderWarningButton('Untuk menandai unit ini unavailable selamanya?')
                    .then(res => {
                        if (res.value) {
                            sendToAPI()
                        }
                    })
            } else {
                sendToAPI()
            }
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={() => onClose()}
        >
            <DialogTitle>{!!isAvailable ? 'Unit Rented' : 'Available Reason'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ marginTop: 0 }}>
                    {
                        !!isAvailable
                            ? <>
                                {
                                    !state.endOfAvailability ?
                                        <Grid item xs={12}>
                                            <MuiDatePicker
                                                label="Available Date"
                                                value={state.date}
                                                onChange={(date: Date | null) => handleChange('date', date)}
                                                error={!!error.date}
                                                helperText={error.date}
                                                disabled={isLoading}
                                            />
                                        </Grid>
                                        : null
                                }
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Switch
                                            checked={state.endOfAvailability}
                                            onChange={e => handleChange('endOfAvailability', e.target.checked)}
                                            color="secondary"
                                        />}
                                        label="Unavailable Selamanya"
                                        disabled={isLoading}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldSelect
                                        label='Type'
                                        name='unavailable_type'
                                        value={state.unavailable_type}
                                        onChange={e => handleChange('unavailable_type', e.target.value)}
                                        error={!!error.unavailable_type}
                                        helperText={error.unavailable_type}
                                        fullWidth
                                        disabled={isLoading}
                                    >
                                        <option value="">Pilih Type</option>
                                        <option value="rented_by_jendela">Unit Tersewa oleh Jendela360</option>
                                        <option value="rented_by_owner">Unit Tersewa oleh Owner</option>
                                        <option value="sold_by_owner">Unit Terjual oleh Owner</option>
                                        <option value="blacklisted_owner">Owner Di-blacklist</option>
                                        <option value="personal_use">Unit Digunakan Pribadi</option>
                                        <option value="invalid_contact">Kontak Invalid</option>
                                        <option value="other">Lainnya</option>
                                    </TextFieldSelect>
                                </Grid>
                            </>
                            : null
                    }
                    <Grid item xs={12}>
                        <TextArea
                            label="Comment"
                            name="comment"
                            value={state.comment}
                            onChange={e => handleChange('comment', e.target.value)}
                            error={!!error.comment}
                            helperText={error.comment}
                            disabled={isLoading}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWithLoading onClick={handleAvailableSubmit} color="primary" isLoading={isLoading}>
                    Submit
                </ButtonWithLoading>
            </DialogActions>
        </Dialog>

    )
}

const VALIDATION_ALIAS: IValidationAlias = {
    date: 'Available date',
    comment: 'Comment',
    unavailable_type: 'Type',
}

export default SwitchAvailableModal;
import React, { useState } from 'react';
import { Button, Link, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';

const UnitSpecialList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/unit-special';
    const navigate =  useNavigate();

    const columns = [
        {
            name: 'code',
            label: 'Unit Code',
            type: 'string',
            filterable: true,
            render: (data: string, row: any) => {
                return <Link
                    onClick={() => navigate(`/unit/${row.code}`)}
                    component="button"
                >
                    {data}
                </Link>
            }
        },
        {
            name: 'is_rent',
            label: 'Is Rent',
            type: 'string',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return <Switch
                    checked={data === 1}
                    onChange={() => handleSwitch(row, setRows, rows, 'is_rent')}
                    color="secondary"
                />
            }
        },
        {
            name: 'is_sell',
            label: 'Is Sell',
            type: 'string',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return <Switch
                    checked={data === 1}
                    onChange={() => handleSwitch(row, setRows, rows, 'is_sell')}
                    color="secondary"
                />
            }
        },
        {
            name: 'is_active',
            label: 'Is Published',
            type: 'string',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Published'
                },
                {
                    key: '0',
                    value: 'Unpublished'
                }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return <Switch
                    checked={data === 1}
                    onChange={() => handleSwitch(row, setRows, rows, 'is_active')}
                    color="secondary"
                />
            }
        },
        {
            name: 'is_cleaned_by_pic',
            label: 'Is Cleaned By PIC',
            type: 'string',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return data === 1 ? 'Yes' : 'No';
            }
        }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const handleSwitch = (unit: any, setRows: Function, rows: any[], type: string) => {
        renderWarningButton('Apakah anda yakin ingin mengganti status ini?')
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios.post(`${API_URL}/switch`, { id: unit.id, type })
                    .then(res => {
                        const newRows = rows.map((element: any) => {
                            if (+element.id === +unit.id) element[type] = unit[type] ? 0 : 1;
                            return element
                        })
                        setRows(newRows);
                    })
                    .catch(err => {
                        generalErrorHandler(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }
        })
    }

    const handlePublishAll = () => {
        renderWarningButton('Apakah anda yakin ingin mempublish semua?')
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios.post(`${API_URL}/publish-all`)
                    .then(res => {
                        setReDrawDatatable(new Date().getTime());
                    })
                    .catch(err => {
                        generalErrorHandler(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }
        })
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                variant="contained"
                color="primary"
                style={{ marginBottom: '15px' }}
                onClick={handlePublishAll}
            >
                Publish Cleaned
            </Button>
            <DataTable 
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </>
    );
}

export default UnitSpecialList;
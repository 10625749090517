import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import TextFieldSelect from './TextFieldSelect'
import LoadingScreen from '../LoadingScreen'
import { Box, Button, Fab, Grid, IconButton, Paper, TextField, Tooltip, Typography } from '@mui/material'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation'
import { generalErrorHandler, inputLatOrLang, inputNumber, renderToastSuccess } from '../../_utils/Helper'

/**
 * Icons
 */
import { ArrowDownward, ArrowUpward, Delete, Save, VerticalAlignBottom, VerticalAlignTop } from '@mui/icons-material'

interface PopularDestinationsFormProps {
    url: string
}

type Destination = {
    [key: string]: string
    name: string
    type: string
    distance: string
    latitude: string
    longitude: string
}

const PopularDestinationsForm = (props: PopularDestinationsFormProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const [list, setList] = useState<Destination[]>([])
    const [errorList, setErrorList] = useState<IValidationErrors<Destination>[]>([])

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.url])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(props.url)
            .then(res => res.data)
            .then((data: Destination[]) => {
                setList(data.map(dest => ({
                    name: dest.name || '',
                    distance: dest.distance?.toString() || '',
                    type: dest.type || '',
                    latitude: dest.latitude || '',
                    longitude: dest.longitude || '',
                })))
                // Initialize error list based on data length
                setErrorList(data.map(() => ({})))
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    // Format the value before saving it to state
    const formatValue = (name: string, value: string) => {
        const isNumber = name === 'distance'
        const isLatOrLang = ['latitude', 'longitude'].includes(name)

        if (isNumber) {
            return inputNumber(value)
        }

        if (isLatOrLang) {
            return inputLatOrLang(value)
        }

        return value
    }

    const handleChange = (destinationIndex: number, name: string, value: string) => {
        setList(prev => prev.map((dest, index) => ({
            ...dest,
            [name]: index === destinationIndex ? formatValue(name, value) : dest[name],
        })))

        setErrorList(prev => prev.map((err, index) => ({
            ...err,
            [name]: index === destinationIndex ? '' : err[name],
        })))
    }

    const handleAdd = () => {
        setList(prev => prev.concat(EMPTY_DESTINATION))
        setErrorList(prev => prev.concat({}))
    }

    const handleSort = (destinationIndex: number, direction: 'top' | 'up' | 'down' | 'bottom') => {
        let newIndex = 0

        switch (direction) {
            case 'top':
                newIndex = 0
                break
            case 'up':
                newIndex = destinationIndex - 1
                break
            case 'down':
                newIndex = destinationIndex + 1
                break
            case 'bottom':
                newIndex = list.length
                break
        }

        setList(prev => {
            const arr = [...prev]
            const destination = arr[destinationIndex]
            arr.splice(destinationIndex, 1)
            arr.splice(newIndex, 0, destination)
            return arr
        })

        setErrorList(prev => {
            const arr = [...prev]
            const error = arr[destinationIndex]
            arr.splice(destinationIndex, 1)
            arr.splice(newIndex, 0, error)
            return arr
        })
    }

    const handleDelete = (destinationIndex: number) => {
        setList(prev => prev.filter((_, index) => index !== destinationIndex))
        setErrorList(prev => prev.filter((_, index) => index !== destinationIndex))
    }

    const handleSubmit = () => {
        setIsLoading(true)

        let finalIsValid = true
        let newErrorList: IValidationErrors<Destination>[] = []

        list.forEach(destination => {
            const { errors, isValid } = validateData(destination, VALIDATION_RULES)
            newErrorList.push(errors)

            finalIsValid = finalIsValid && isValid
        })

        setErrorList(newErrorList)

        if (finalIsValid) {
            const fd = new FormData()

            list.forEach((destination, index) => {
                fd.append(`facilities[${index}][name]`, destination.name)
                fd.append(`facilities[${index}][type]`, destination.type)
                fd.append(`facilities[${index}][distance]`, destination.distance)
                fd.append(`facilities[${index}][latitude]`, destination.latitude)
                fd.append(`facilities[${index}][longitude]`, destination.longitude)
            })

            DefaultAxios.post(props.url, fd)
                .then(() => {
                    renderToastSuccess('Berhasil menyimpan data destinasi populer')
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            setIsLoading(false)
        }
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    {
                        list.length > 0 ?
                            list.map((destination, index) =>
                                <React.Fragment key={index}>
                                    <Grid item xs={12} display='flex' alignItems='center' flexDirection={{ xs: 'column', md: 'row' }}>
                                        <Paper sx={{ px: 3, pb: 3, pt: 2.5 }}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={4}>
                                                    <TextFieldSelect
                                                        name='type'
                                                        label='Type'
                                                        value={destination.type}
                                                        error={!!errorList[index]?.type}
                                                        helperText={errorList[index]?.type}
                                                        onChange={e => handleChange(index, e.target.name, e.target.value)}
                                                        fullWidth
                                                    >
                                                        <option value="" disabled>Pilih Tipe</option>
                                                        {TYPE_LIST.map(type => <option key={type} value={type}>{type}</option>)}
                                                    </TextFieldSelect>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        name='name'
                                                        label='Name'
                                                        value={destination.name}
                                                        error={!!errorList[index]?.name}
                                                        helperText={errorList[index]?.name}
                                                        onChange={e => handleChange(index, e.target.name, e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        name='distance'
                                                        label='KM'
                                                        value={formatValue('distance', destination.distance)}
                                                        error={!!errorList[index]?.distance}
                                                        helperText={errorList[index]?.distance}
                                                        onChange={e => handleChange(index, e.target.name, e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        name='latitude'
                                                        label='Latitude'
                                                        value={formatValue('latitude', destination.latitude)}
                                                        error={!!errorList[index]?.latitude}
                                                        helperText={errorList[index]?.latitude}
                                                        onChange={e => handleChange(index, e.target.name, e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        name='longitude'
                                                        label='Longitude'
                                                        value={formatValue('longitude', destination.longitude)}
                                                        error={!!errorList[index]?.longitude}
                                                        helperText={errorList[index]?.longitude}
                                                        onChange={e => handleChange(index, e.target.name, e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        <Box display='flex' alignItems='center' sx={{ ml: 3 }}>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleSort(index, 'top')}
                                                disabled={index === 0}
                                            >
                                                <VerticalAlignTop />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleSort(index, 'up')}
                                                disabled={index === 0}
                                            >
                                                <ArrowUpward />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleSort(index, 'down')}
                                                disabled={index === list.length - 1}
                                            >
                                                <ArrowDownward />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleSort(index, 'bottom')}
                                                disabled={index === list.length - 1}
                                            >
                                                <VerticalAlignBottom />
                                            </IconButton>
                                            <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(index)}>
                                                <Delete />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                </React.Fragment>
                            )
                            :
                            <Grid item xs={12} display='flex' justifyContent='center'>
                                <Typography>
                                    Belum ada destinasi
                                </Typography>
                            </Grid>
                    }
                    <Grid item xs={12} display='flex' justifyContent='center'>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleAdd}
                        >
                            Tambah Destinasi
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: '2rem',
                    right: '2rem',
                    zIndex: 1,
                }}
            >
                <Tooltip title="Save" placement="top" arrow>
                    <Fab aria-label="Save" color="primary" onClick={handleSubmit}>
                        <Save />
                    </Fab>
                </Tooltip>
            </Box>
        </>
    )
}

const EMPTY_DESTINATION: Destination = {
    name: '',
    type: '',
    distance: '',
    latitude: '',
    longitude: '',
}

const TYPE_LIST = ['Mall', 'Rumah Sakit', 'Sekolah', 'Restaurant', 'Grocery Store', 'Convenience Store', 'Gerbang Tol Terdekat', 'Tempat Ibadah']

const VALIDATION_RULES: IValidationRules = {
    name: 'required',
    type: 'required',
    distance: 'required',
    latitude: 'required',
    longitude: 'required',
}

export default PopularDestinationsForm

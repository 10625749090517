import { BottomNavigation, BottomNavigationAction, Button, Checkbox, Collapse, FormControlLabel, Grid, InputAdornment, MenuItem, TextField, Theme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AsyncMultipleAutoComplete from '../../../_components/_form/AsycMultipleAutoComplete'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { cleanObject, convertNumber, generalErrorHandler, inputNumber } from '../../../_utils/Helper'
import { CustomRulesOuput, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import TextEditor from '../../../_components/_form/TextEditor'
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'
import { generateStyle } from '../../../_utils/DefaultStyle'

interface IProps {
    id?: string
}

export type IAutocompleteData = {
    id: number
    label: string
}

export interface IBasicInfo {
    name: string
    code: string
    slug: string
    // tmp_area_id: number | null
    // tmp_area_name: string
    area_data: IAutocompleteData,
    billing_due_date: number,
    // tmp_district_id: number | null
    // tmp_district_name: string
    district_data: IAutocompleteData[]
    address: string
    latitude: string
    longitude: string
    is_free_sc: boolean
    service_charge: string
    service_charge_type: number
    is_deposit_manual: boolean
    deposit_charge_studio: string
    deposit_charge_1br: string
    deposit_charge_2br: string
    deposit_charge_3br: string
    facilities: {
        [key: string]: boolean
    }
    meta_title: string
    meta_description: string
    meta_title_sell: string
    meta_description_sell: string
    meta_title_en: string
    meta_description_en: string
    meta_title_sell_en: string
    meta_description_sell_en: string
    paragraph: string
    paragraph_en: string
    paragraph_2: string
    paragraph_2_en: string
}

const ApartmentBasicInfoForm = (props: IProps) => {
    const { Root, classes } = useStyles()
    const navigate = useNavigate()

    const [basicInfoError, setBasicInfoError] = useState<IValidationErrors>({})
    const [basicInfo, setBasicInfo] = useState<IBasicInfo>({
        name: '',
        code: '',
        slug: '',
        // tmp_area_id: null,
        // tmp_area_name: '',
        area_data: {
            id: 0,
            label: '',
        },
        billing_due_date: 0,
        // tmp_district_id: null,
        // tmp_district_name: '',
        district_data: [],
        address: '',
        latitude: '',
        longitude: '',
        is_free_sc: false,
        service_charge: '',
        is_deposit_manual: false,
        deposit_charge_studio: '',
        deposit_charge_1br: '',
        deposit_charge_2br: '',
        deposit_charge_3br: '',
        facilities: {},
        meta_title: '',
        meta_description: '',
        meta_title_sell: '',
        meta_description_sell: '',
        meta_title_en: '',
        meta_description_en: '',
        meta_title_sell_en: '',
        meta_description_sell_en: '',
        paragraph: '',
        paragraph_en: '',
        paragraph_2: '',
        paragraph_2_en: '',
        service_charge_type: 0
    })
    const isNumber = ['service_charge', 'deposit_charge_studio', 'deposit_charge_1br', 'deposit_charge_2br', 'deposit_charge_3br']

    const [langSwitch, setLangSwitch] = useState('id')

    useEffect(() => {
        if (props.id) {
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/apartment/initial-form/${props.id}`)
                .then(res => res.data)
                .then(res => cleanObject(res))
                .then(res => {
                    const facilities: { [key: string]: boolean } = {}
                    if (res.facilities) {
                        res.facilities.forEach((facility: string) => {
                            facilities[facility] = true
                        })
                    }

                    let district_data: IAutocompleteData[] = []
                    if (res.district_ids && res.district_label && res.district_ids.length > 0 && res.district_label.length > 0) {
                        res.district_ids.forEach((district: number, index: number) => {
                            district_data.push({
                                id: Number(district),
                                label: res.district_label[index],
                            })
                        })
                    }

                    setBasicInfo(prev => ({
                        ...prev,
                        ...res,
                        is_free_sc: res.is_free_sc === 1 ? true : false,
                        is_deposit_manual: res.is_deposit_manual === 1 ? true : false,
                        area_data: res.area_id && res.area_label ? {
                            id: res.area_id,
                            label: res.area_label,
                        } : prev.area_data,
                        district_data: district_data,
                        service_charge: res.service_charge ? convertNumber(res.service_charge.split('.')[0]) : prev.service_charge,
                        deposit_charge_studio: res.deposit_charge_studio ? convertNumber(res.deposit_charge_studio.split('.')[0]) : prev.deposit_charge_studio,
                        deposit_charge_1br: res.deposit_charge_1br ? convertNumber(res.deposit_charge_1br.split('.')[0]) : prev.deposit_charge_1br,
                        deposit_charge_2br: res.deposit_charge_2br ? convertNumber(res.deposit_charge_2br.split('.')[0]) : prev.deposit_charge_2br,
                        deposit_charge_3br: res.deposit_charge_3br ? convertNumber(res.deposit_charge_3br.split('.')[0]) : prev.deposit_charge_3br,
                        facilities,
                    }))
                })
                .catch(err => {
                    generalErrorHandler(err)
                })
        }
    }, [props.id])

    const customAreaValidation = (): CustomRulesOuput => {
        let isValid = true
        if (!basicInfo.area_data.id) {
            isValid = false
        }
        return { isValid, errorMessage: 'Area cannot be empty' }
    }

    const customDistrictValidation = (): CustomRulesOuput => {
        let isValid = true
        if (basicInfo.district_data.length === 0) {
            isValid = false
        }
        return { isValid, errorMessage: 'District cannot be empty' }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name
        const value = e.target.value
        const checked = e.target.checked

        setBasicInfoError(prev => ({
            ...prev,
            [name]: '',
        }))

        if (name.includes('facilities')) {
            let facName = name.split('.')

            if (facName.length > 0) {
                const newFacilities = { ...basicInfo.facilities }
                newFacilities[facName[1]] = checked

                setBasicInfo(prev => ({
                    ...prev,
                    facilities: newFacilities,
                }))
            }
        } else if (name in basicInfo) {
            setBasicInfo(prev => ({
                ...prev,
                [name]: isNumber.includes(name) ? convertNumber(value) : value
            }))
        }
    }

    const handleEditorChanged = (name: string, value: string) => {
        setBasicInfo(prev => ({
            ...prev,
            [name]: value
        }))

        setBasicInfoError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const name = e.target.name
        const value = e.target.value

        if (name in basicInfo) {
            const { isValid, errors } = validateData({ [name]: value }, basicInfoRules(customAreaValidation, customDistrictValidation), basicInfoAlias)

            if (!isValid) {
                setBasicInfoError(prev => ({
                    ...prev,
                    ...errors,
                }))
            } else {
                setBasicInfoError(prev => ({
                    ...prev,
                    [name]: '',
                }))
            }
        }
    }

    const handleAutocompleteChange = (name: string, value: IAutocompleteData[] | IAutocompleteData) => {
        let errorMessage = ''
        switch (name) {
            // case 'tmp_area_id':
            //     setBasicInfo(prev => ({
            //         ...prev,
            //         tmp_area_id: value.id === null ? null : Number(value.id),
            //         tmp_area_name: value.label,
            //     }))
            //     break
            // case 'tmp_district_id':
            //     setBasicInfo(prev => ({
            //         ...prev,
            //         tmp_district_id: value.id === null ? null : Number(value.id),
            //         tmp_district_name: value.label,
            //     }))
            //     break
            case 'area_data':
                errorMessage = 'Area cannot be empty'

                setBasicInfo(prev => ({
                    ...prev,
                    area_data: value as IAutocompleteData
                }))
                break
            case 'district_data':
                errorMessage = 'District cannot be empty'

                setBasicInfo(prev => ({
                    ...prev,
                    district_data: value as IAutocompleteData[]
                }))
                break
        }

        if (!!value || ((value as IAutocompleteData[]).length > 0)) {
            setBasicInfoError(prev => ({
                ...prev,
                [name]: ''
            }))
        } else {
            setBasicInfoError(prev => ({
                ...prev,
                [name]: errorMessage
            }))
        }
    }

    const handleAutocompleteInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const value = e.target.value

        switch (name) {
            case 'area_data':
                setBasicInfo(prev => ({
                    ...prev,
                    area_data: {
                        ...prev.area_data,
                        label: value,
                    },
                }))
                break
        }
    }

    const createMenuList = (count: number) => {
        let list = []
        for (let i = 1; i <= count; i++) {
            list.push(<MenuItem value={i}>{i}</MenuItem>)
        }
        return list
    }

    const toggleButton = (type: string, value: string) => {
        let newValue: boolean = false
        switch (type) {
            case 'service':
                newValue = value === 'free' ? true : false
                if (newValue !== basicInfo.is_free_sc) {
                    setBasicInfo(prev => ({
                        ...prev,
                        is_free_sc: newValue
                    }))
                }
                break
            case 'manual_deposit':
                newValue = value === 'on' ? true : false
                if (newValue !== basicInfo.is_deposit_manual) {
                    setBasicInfo(prev => ({
                        ...prev,
                        is_deposit_manual: newValue
                    }))
                }
                break
        }
    }

    const submit = () => {
        const { isValid, errors } = validateData(basicInfo, basicInfoRules(customAreaValidation, customDistrictValidation), basicInfoAlias)
        setBasicInfoError(errors)

        if (isValid) {
            let facilities = []

            for (let key in basicInfo.facilities) {
                if (basicInfo.facilities[key]) {
                    facilities.push(key)
                }
            }

            const data = {
                ...basicInfo,
                is_free_sc: basicInfo.is_free_sc ? 1 : 0,
                is_deposit_manual: basicInfo.is_deposit_manual ? 1 : 0,
                area_id: basicInfo.area_data.id,
                district_ids: basicInfo.district_data.map(district => district.id),
                service_charge: Number(inputNumber(basicInfo.service_charge)),
                deposit_charge_studio: Number(inputNumber(basicInfo.deposit_charge_studio)),
                deposit_charge_1br: Number(inputNumber(basicInfo.deposit_charge_1br)),
                deposit_charge_2br: Number(inputNumber(basicInfo.deposit_charge_2br)),
                deposit_charge_3br: Number(inputNumber(basicInfo.deposit_charge_3br)),
                facilities,
                district_data: null,
                area_data: null,
            }

            if (props.id) {
                DefaultAxios.put(`${process.env.REACT_APP_API_URL}/apartment/${props.id}`, data)
                    .then(res => res.data)
                    .then(res => {
                        Swal.fire({
                            title: "Apartment updated",
                            icon: 'success',
                            timer: 1000
                        })

                        navigate('/apartment')
                    })
                    .catch(err => {
                        generalErrorHandler(err)
                    })
            } else {
                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/apartment`, data)
                    .then(res => res.data)
                    .then(res => {
                        Swal.fire({
                            title: "Apartment created",
                            icon: 'success',
                            timer: 1000
                        })

                        navigate('/apartment')
                    })
                    .catch(err => {
                        generalErrorHandler(err)
                    })
            }
        }
    }

    return (
        <Grid item xs={12}>
            <Root>

                <h2>Informasi Dasar</h2>
                <div className={classes.section}>
                    <TextField
                        name="name"
                        label="Name"
                        value={basicInfo.name}
                        error={!!basicInfoError.name}
                        helperText={basicInfoError.name || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                    />
                    <div className="split">
                        <TextField
                            name="code"
                            label="Code"
                            value={basicInfo.code}
                            error={!!basicInfoError.code}
                            helperText={basicInfoError.code || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            fullWidth
                        />
                        <TextField
                            name="slug"
                            label="Slug"
                            value={basicInfo.slug}
                            error={!!basicInfoError.slug}
                            helperText={basicInfoError.slug || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div className="split">
                        <AsyncAutoComplete
                            label="Area"
                            placeholder="Please Choose Area"
                            name="area_data"
                            initialQuery={basicInfo.area_data.label}
                            // value={basicInfo.area_data}

                            onChange={handleAutocompleteChange}
                            onInputChange={handleAutocompleteInputChange}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/area`}
                            errorText={basicInfoError.area_data}
                        />
                        <AsyncMultipleAutoComplete
                            label="District"
                            // placeholder="Please Choose Area"
                            name="district_data"
                            // initialQuery={basicInfo.tmp_area_name}
                            value={basicInfo.district_data}
                            onChange={handleAutocompleteChange}
                            // onInputChange={props.handleAutocompleteInputChange}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/district`}
                            error={!!basicInfoError.district_data}
                            helperText={basicInfoError.district_data}
                        />
                        {/* <AsyncMultipleAutoComplete
                        label="District"
                        placeholder="Please Choose District"
                        name="tmp_district_id"
                        initialQuery={basicInfo.tmp_district_name}
                        onChange={handleAutocompleteChange}
                        onInputChange={props.handleAutocompleteInputChange}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/landlord`}
                        disabled={basicInfo.tmp_area_id === null}
                    /> */}
                    </div>
                    <TextField
                        name="address"
                        label="Address"
                        value={basicInfo.address}
                        error={!!basicInfoError.address}
                        helperText={basicInfoError.address || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                    />
                    <div className="split">
                        <TextField
                            name="latitude"
                            label="Latitude"
                            value={basicInfo.latitude}
                            error={!!basicInfoError.latitude}
                            helperText={basicInfoError.latitude || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            fullWidth
                        />
                        <TextField
                            name="longitude"
                            label="Longitude"
                            value={basicInfo.longitude}
                            error={!!basicInfoError.longitude}
                            helperText={basicInfoError.longitude || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <h3>Billing Due Date</h3>
                    <div>
                        <Grid xs={12} md={6} lg={4}>
                            <TextField
                                select
                                name="billing_due_date"
                                label=""
                                value={basicInfo.billing_due_date}
                                onChange={handleChange}
                                fullWidth
                            >
                                {createMenuList(31)}
                            </TextField>
                        </Grid>
                    </div>
                    <h3>Biaya Service</h3>
                    <div className="toggle-button">
                        <Button
                            color={basicInfo.is_free_sc ? undefined : 'primary'}
                            variant="contained"
                            onClick={() => toggleButton('service', 'normal')}
                        >
                            Normal
                        </Button>
                        <Button
                            color={basicInfo.is_free_sc ? 'primary' : undefined}
                            variant="contained"
                            onClick={() => toggleButton('service', 'free')}
                        >
                            Free
                        </Button>
                    </div>
                    <Collapse in={!basicInfo.is_free_sc}>
                        <TextField
                            name="service_charge"
                            placeholder="C: 1,000,000"
                            value={convertNumber(basicInfo.service_charge)}
                            error={!!basicInfoError.service_charge}
                            helperText={basicInfoError.service_charge || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>m2</InputAdornment>
                            }}
                            variant="outlined"
                            className="half-width"
                            fullWidth
                        />
                        <TextField
                            name="service_charge_type"
                            label="Service Charge Type"
                            onChange={handleChange}
                            value={basicInfo.service_charge_type}
                            error={!!basicInfoError.service_charge_type}
                            helperText={basicInfoError.service_charge_type || ''}
                            className="half-width"
                            style={{ marginTop: 20 }}
                            fullWidth
                            select
                        >
                            <MenuItem value={0}>Unspecified</MenuItem>
                            <MenuItem value={1}>Bulanan</MenuItem>
                            <MenuItem value={2}>2 Bulan</MenuItem>
                            <MenuItem value={3}>3 Bulan</MenuItem>
                            <MenuItem value={6}>6 Bulan</MenuItem>
                            <MenuItem value={10}>10 Bulan</MenuItem>
                            <MenuItem value={12}>12 Bulan</MenuItem>
                        </TextField>
                    </Collapse>
                    <h3>Setting Biaya Deposit Manual</h3>
                    <div className="toggle-button">
                        <Button
                            color={basicInfo.is_deposit_manual ? 'primary' : undefined}
                            variant="contained"
                            onClick={() => toggleButton('manual_deposit', 'on')}
                        >
                            On
                        </Button>
                        <Button
                            color={basicInfo.is_deposit_manual ? undefined : 'primary'}
                            variant="contained"
                            onClick={() => toggleButton('manual_deposit', 'off')}
                        >
                            Off
                        </Button>
                    </div>
                    <Collapse in={basicInfo.is_deposit_manual} className="collapse">
                        <TextField
                            name="deposit_charge_studio"
                            label="Deposit Studio"
                            value={convertNumber(basicInfo.deposit_charge_studio)}
                            error={!!basicInfoError.deposit_charge_studio}
                            helperText={basicInfoError.deposit_charge_studio || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            className="half-width"
                            fullWidth
                        />
                        <TextField
                            name="deposit_charge_1br"
                            label="Deposit 1BR"
                            value={convertNumber(basicInfo.deposit_charge_1br)}
                            error={!!basicInfoError.deposit_charge_1br}
                            helperText={basicInfoError.deposit_charge_1br || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            className="half-width"
                            fullWidth
                        />
                        <TextField
                            name="deposit_charge_2br"
                            label="Deposit 2BR"
                            value={convertNumber(basicInfo.deposit_charge_2br)}
                            error={!!basicInfoError.deposit_charge_2br}
                            helperText={basicInfoError.deposit_charge_2br || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            className="half-width"
                            fullWidth
                        />
                        <TextField
                            name="deposit_charge_3br"
                            label="Deposit 3BR+"
                            value={convertNumber(basicInfo.deposit_charge_3br)}
                            error={!!basicInfoError.deposit_charge_3br}
                            helperText={basicInfoError.deposit_charge_3br || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            className="half-width"
                            fullWidth
                        />
                    </Collapse>
                    <h3>Fasilitas</h3>
                    <div className="grid">
                        {
                            facilitiesData.map(facility =>
                                <FormControlLabel
                                    key={facility.name}
                                    control={
                                        <Checkbox
                                            checked={!!basicInfo.facilities[facility.name]}
                                            onChange={handleChange}
                                            name={`facilities.${facility.name}`}
                                            color="primary"
                                        />
                                    }
                                    label={facility.label}
                                />
                            )
                        }
                    </div>
                </div>
                <h2>Meta</h2>
                <div className={classes.section}>
                    <h3>[ID] Meta</h3>
                    <TextField
                        name="meta_title"
                        label="Title"
                        value={basicInfo.meta_title}
                        error={!!basicInfoError.meta_title}
                        helperText={basicInfoError.meta_title || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        name="meta_description"
                        label="Description"
                        value={basicInfo.meta_description}
                        error={!!basicInfoError.meta_description}
                        helperText={basicInfoError.meta_description || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        name="meta_title_sell"
                        label="Title Sell"
                        value={basicInfo.meta_title_sell}
                        error={!!basicInfoError.meta_title_sell}
                        helperText={basicInfoError.meta_title_sell || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        name="meta_description_sell"
                        label="Description Sell"
                        value={basicInfo.meta_description_sell}
                        error={!!basicInfoError.meta_description_sell}
                        helperText={basicInfoError.meta_description_sell || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                    />
                    <h3>[EN] Meta</h3>
                    <TextField
                        name="meta_title_en"
                        label="Title"
                        value={basicInfo.meta_title_en}
                        error={!!basicInfoError.meta_title_en}
                        helperText={basicInfoError.meta_title_en || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        name="meta_description_en"
                        label="Description"
                        value={basicInfo.meta_description_en}
                        error={!!basicInfoError.meta_description_en}
                        helperText={basicInfoError.meta_description_en || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        name="meta_title_sell_en"
                        label="Title Sell"
                        value={basicInfo.meta_title_sell_en}
                        error={!!basicInfoError.meta_title_sell_en}
                        helperText={basicInfoError.meta_title_sell_en || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        name="meta_description_sell_en"
                        label="Description Sell"
                        value={basicInfo.meta_description_sell_en}
                        error={!!basicInfoError.meta_description_sell_en}
                        helperText={basicInfoError.meta_description_sell_en || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                    />
                </div>
                <h2>Paragraphs</h2>
                <Grid item xs={12} sm={6} md={2}>
                    <BottomNavigation
                        value={langSwitch}
                        onChange={(e, newValue) => setLangSwitch(newValue)}
                        showLabels
                        className={classes.filter}
                    >
                        <BottomNavigationAction
                            classes={{
                                root: classes.filterButton,
                                label: classes.filterButtonLabel,
                                selected: classes.filterButtonSelected
                            }}
                            value={'id'}
                            label={'Indonesia'}
                        />
                        <BottomNavigationAction
                            classes={{
                                root: classes.filterButton,
                                label: classes.filterButtonLabel,
                                selected: classes.filterButtonSelected
                            }}
                            value={'en'}
                            label={'English'}
                        />
                    </BottomNavigation>
                </Grid>
                <div className={classes.section}>
                    <h3>Paragraph</h3>
                    <TextEditor
                        name={langSwitch === 'id' ? 'paragraph' : 'paragraph_en'}
                        value={langSwitch === 'id' ? basicInfo.paragraph : basicInfo.paragraph_en}
                        onChange={handleEditorChanged}
                    />
                    <h3>Paragraph 2</h3>
                    <TextEditor
                        name={langSwitch === 'id' ? 'paragraph_2' : 'paragraph_2_en'}
                        value={langSwitch === 'id' ? basicInfo.paragraph_2 : basicInfo.paragraph_2_en}
                        onChange={handleEditorChanged}
                    />
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={submit}
                >
                    Submit
                </Button>
            </Root>
        </Grid>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    section: {
        paddingBottom: 24,
        borderBottom: '1px solid #ddd',
        '&:nth-last-child(2)': {
            borderBottom: 'none',
        },
        '& > *, & .collapse > div > div > *': {
            marginBottom: 16,
        },
        '& > .split': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            [theme.breakpoints.up('sm')]: {
                flexWrap: 'nowrap',
            },
            '& > *': {
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    width: '50%',
                    flexShrink: 1,
                },
            },
            [theme.breakpoints.up('md')]: {
                '& > div:first-of-type': {
                    marginRight: 8,
                },
                '& > div:last-child': {
                    marginLeft: 8,
                },
            },
        },
        '& > h3': {
            fontWeight: 500,
        },
        '& > .toggle-button': {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 24,
            '& > *': {
                minWidth: '100px',
                margin: 0,
                textTransform: 'capitalize',
            },
            '& > button:first-of-type': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            },
            '& > button:last-child': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            },
        },
        '& .half-width > *': {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '50%',
                marginRight: 'auto',
            },
        },
        '& > .grid': {
            display: 'grid',
            gridTemplateColumns: 'repeat(1,minmax(0,1fr))',
            gap: 16,
            [theme.breakpoints.up('md')]: {
                gridTemplateColumns: 'repeat(3,minmax(0,1fr))',
            }
        },
    },
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
        // fontSize: '0.8125rem',
        // border: '1px solid #006db3',
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
}), "ApartmetnBasicInfo_form"
)

const basicInfoRules = (
    areaValidation: () => CustomRulesOuput,
    districtValidation: () => CustomRulesOuput
): IValidationRules => ({
    name: 'required',
    code: 'required',
    slug: 'required',
    area_data: areaValidation,
    district_data: districtValidation,
    // address: 'required',
    latitude: 'required',
    longitude: 'required',
    service_charge: 'required_if:is_free_sc=false',
    // deposit_charge_studio: 'required_if:is_deposit_manual=true',
    // deposit_charge_1br: 'required_if:is_deposit_manual=true',
    // deposit_charge_2br: 'required_if:is_deposit_manual=true',
    // deposit_charge_3br: 'required_if:is_deposit_manual=true',
    // meta_title: 'required',
    // meta_description: 'required',
    // meta_title_sell: 'required',
    // meta_description_sell: 'required',
    // meta_title_en: 'required',
    // meta_description_en: 'required',
    // meta_title_sell_en: 'required',
    // meta_description_sell_en: 'required',
})

const basicInfoAlias: { [key: string]: string } = {
    name: 'Apartment name',
    code: 'Apartment code',
    slug: 'Apartment slug',
    // tmp_area_id: null,
    // tmp_district_id: null,
    address: 'Apartment address',
    latitude: 'Latitude',
    longitude: 'Longitude',
    service_charge: 'Biaya servis',
    // deposit_charge_studio: 'Deposit studio',
    // deposit_charge_1br: 'Deposit 1BR',
    // deposit_charge_2br: 'Deposit 2BR',
    // deposit_charge_3br: 'Deposit 3BR+',
    // meta_title: 'Meta title',
    // meta_description: 'Meta description',
    // meta_title_sell: 'Meta title sell',
    // meta_description_sell: 'Meta description sell',
    // meta_title_en: 'Meta title',
    // meta_description_en: 'Meta description',
    // meta_title_sell_en: 'Meta title sell',
    // meta_description_sell_en: 'Meta description sell',
}

const facilitiesData = [
    {
        name: 'atm',
        label: 'ATM',
    },
    {
        name: 'gym',
        label: 'Gym',
    },
    {
        name: 'jogging-track',
        label: 'Jogging Track',
    },
    {
        name: 'basket-ball',
        label: 'Lapangan Basket',
    },
    {
        name: 'laundry',
        label: 'Laundry',
    },
    {
        name: 'restoran',
        label: 'Restoran',
    },
    {
        name: 'security',
        label: 'Security',
    },
    {
        name: 'grocery-store',
        label: 'Grocery Store',
    },
    {
        name: 'internet',
        label: 'Internet & TV Kabel',
    },
    {
        name: 'kolam-renang',
        label: 'Kolam Renang',
    },
    {
        name: 'lapangan-tenis',
        label: 'Lapangan Tenis',
    },
    {
        name: 'pet',
        label: 'Pet',
    },
    {
        name: 'function-room',
        label: 'Ruang Serbaguna',
    },
    {
        name: 'taman-bermain',
        label: 'Taman Bermain',
    },
    {
        name: 'futsal-court',
        label: 'Futsal Court',
    },
    {
        name: 'jacuzzi',
        label: 'Jacuzzi',
    },
    {
        name: 'sauna',
        label: 'Sauna',
    },
]

export default ApartmentBasicInfoForm

import { Theme, styled, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

export type StyleObject<T extends string> = {
    [key: string]: React.CSSProperties | Record<T, React.CSSProperties>
}

interface IOptions {
    CustomParent?: any,
    depedency?: any[]
}

export function generateStyle(callback: (theme: Theme) => StyleObject<string>, prefix: string, { CustomParent, depedency }: IOptions = {}) {
    return function () {
        const theme = useTheme();
        const [refreshHead, setRefreshHead] = useState(new Date().getTime())
        const className = (name: string) => `${name}-${prefix}`;

        useEffect(() => {
            return () => {
                setRefreshHead(new Date().getTime())
            }
        }, [])

        const data = callback(theme);
        type Classes = Record<string, string>

        const classes: Classes = Object.keys(data).reduce((acc, item) => {
            acc[item] = className(item);
            return acc;
        }, {} as Classes);

        const generateStyle = (theme: Theme, classes: Classes, data: StyleObject<string>) => {
            let allStyles: StyleObject<string> = {};
            Object.entries(data).forEach(([name, style]) => {
                Object.assign(allStyles, {
                    [`& .${classes[name]}`]: style
                })
            })

            return allStyles;
        }

        const Root = useMemo(() => {
            return styled(typeof CustomParent !== 'undefined' ? CustomParent : 'div')(({ theme }) => generateStyle(theme, classes, data));

            // eslint-disable-next-line
        }, [refreshHead, ...(depedency ? depedency : [])])

        return {
            classes,
            Root
        };
    }
}

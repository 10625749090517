import React, { useEffect, useState } from 'react'
import { Button, Chip, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'

/**
 * Components
*/
import VisuallyHiddenInput from '../../../_components/_form/VisuallyHiddenInput';
import LoadingScreen from '../../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler, numberToCurrency, openExternalLink, renderSuccessButton } from '../../../_utils/Helper';

/**
 * Icons
 */
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

type Props = {
    id: number | string
    open: boolean
    onClose: (refresh: boolean) => void
    editData?: null | {
        amount: string
        invoiceCode?: string | null
    }
}

interface IState {
    invoice_code: string
    billing_nominal: number | string
    billing_invoice_file: File | null
}

export type IUnpaidResponse = {
    work_code: string;
    created_by_phone: string;
    billing_invoice_file_url: string;
}


const initialState = {
    invoice_code: '',
    billing_invoice_file: null,
    billing_nominal: ''
}

const SetUnpaidModal = (props: Props) => {
    const [billingState, setBillingState] = useState<IState>(initialState)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (props.editData && props.open) {
            setBillingState(prev => ({
                ...prev,
                billing_nominal: props.editData?.amount || '',
                invoice_code: props.editData?.invoiceCode || '',
            }))
        }
    }, [props.editData, props.open])

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.name === 'billing_nominal') {
            return setBillingState(prev => ({
                ...prev,
                billing_nominal: e.target.value.replace(/[^\d]/g, '')
            }))
        }

        return setBillingState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBillingState(prev => ({
            ...prev,
            billing_invoice_file: e.target.files ? e.target.files[0] : null
        }))
    }

    const handleSubmit = () => {
        setIsLoading(true)
        const fd = new FormData()
        fd.append('id', String(props.id) || '')
        fd.append('invoice_code', billingState.invoice_code || '')
        fd.append('billing_nominal', String(billingState.billing_nominal).replace(/\D+/g, '') || '')
        fd.append('billing_invoice_file', billingState.billing_invoice_file || '')

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/${props.editData ? 'change-unpaid-information' : 'set-unpaid'}`, fd)
            .then(res => res.data)
            .then((res: IUnpaidResponse) => {
                renderSuccessButton(props.editData ? 'Berhasil mengubah data unpaid' : 'Berhasil diubah ke Unpaid!')
                openExternalLink(`https://api.whatsapp.com/send/?phone=${res.created_by_phone}&text=Halo+kak.+Untuk+orderan+${res.work_code}+sudah+selesai+yaa.+Terlampir+invoicenya%2C+mohon+upload+bukti+bayar+jika+sudah+dibayarkan+%0A%0A+${res.billing_invoice_file_url}%0A%0AThank+you&type=phone_number&app_absent=0`)
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (!props.open) {
            setBillingState(initialState)
        }
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>Unpaid Billing</DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} rowSpacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <span style={{ paddingRight: 5 }}>Rp.</span>
                                )
                            }}
                            name='billing_nominal'
                            label="Billing Nominal"
                            onChange={(e) => onChange(e)}
                            value={numberToCurrency(billingState.billing_nominal)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='invoice_code'
                            label="Invoice Code"
                            onChange={(e) => onChange(e)}
                            value={billingState.invoice_code}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {
                            billingState.billing_invoice_file ?
                                <Chip label={billingState.billing_invoice_file.name} onDelete={() => {
                                    setBillingState((prev) => ({
                                        ...prev,
                                        billing_invoice_file: null
                                    }))
                                }} /> :
                                <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                    Upload file
                                    <VisuallyHiddenInput
                                        type="file"
                                        multiple={false}
                                        accept='application/pdf'
                                        onChange={handleChangeFile}
                                    />
                                </Button>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            disabled={Object.values({ ...billingState, billing_invoice_file: props.editData ? 'SKIP CHECKING' : billingState.billing_invoice_file }).some(item => !item)}
                            variant='contained'
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default SetUnpaidModal
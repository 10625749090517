import { Button, Theme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { capitalizeFirstCharacter, generalErrorHandler, numberToCurrency, renderSuccessButton, renderWarningButton } from '../../_utils/Helper';

// Icons
import moment from 'moment';
import TransactionDetail from './TransactionDetail';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    isDeposit?: boolean
}

interface IParams extends Record<string, string | undefined> {
    id?: string,
}

export interface VerifyTransactionState {
    amount: number
    approved_at: null | string
    approved_by: null | string
    bank_data_id: number
    bank_data_type: string
    bank_information: string
    canceled_at: null | string
    canceled_by: null | string
    canceled_reason: null | string
    closing_id: string
    code: string
    created_at: string
    created_by: null | string
    date: string
    deleted_at: null | string
    id: number
    notes: null | string
    other_transaction_type_label: null | string
    processed_at: null | string
    processed_by: null | string
    proposed_at: null | string
    proposed_by: null | string
    released_at: null | string
    released_by: null | string
    owner_name_label: string
    remark1: null | string
    remark2: null | string
    statement_id: null | string
    status: string
    transaction_type: string
    transaction_type_label: string
    type: string
    updated_at: string
    closing: {
        basic_price: null | number
        cobroke_fee: null | number
        gmv_transaction: null | number
        holded_deposit_office: null | number
        holded_deposit_owner: null | number
        referral_commission: null | number
        revenue_jendela: null | number
        gmv_financing: null | number
        code: string
    }
    unit_code: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    code: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        transition: '150ms color linear',

        '&:hover': {
            color: theme.palette.primary.dark,
        }
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 8,
        fontSize: 16,
    },
    value: {
        fontSize: 16,
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 16,
    }
}), "VarifyTransactionlist"
);

const VerifyTransactionList = (props: IProps) => {
    const permissions = useContext(PermissionContext)
    const API_URL = `${process.env.REACT_APP_API_URL}/closing-transaction`;
    const { Root, classes } = useStyles();
    const params = useParams<IParams>();

    const [selectedTransaction, setSelectedTransaction] = useState<number | null>(null)

    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const columns = [
        {
            name: 'date',
            label: 'Tanggal',
            type: 'date',
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    `${moment(data).format("DD MMMM YYYY")}`
                )
            }
        },
        {
            name: 'code',
            label: 'Kode',
            type: 'string',
            filterable: true,
            render: (data: any, row: any) => (
                <span
                    className={classes.code}
                    onClick={() => {
                        setSelectedTransaction(row.id)
                        window.history.pushState('', '', `/verify-transaction${props.isDeposit ? '-deposit' : ''}/${row.id}`)
                    }}
                >
                    {data ? data : '-'}
                </span>
            )
        },
        {
            name: 'status',
            label: 'Status',
            type: 'string',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...statusOptions,
            ],
            render: (data: any) => capitalizeFirstCharacter(data)
        },
        {
            name: 'type',
            label: 'Type',
            type: 'string',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...(props.isDeposit ? typeDepositOptions : typeOptions),
            ],
        },
        {
            name: 'amount',
            label: 'Amount',
            type: 'number',
            filterable: false,
            render: (data: string, row: any) => (
                numberToCurrency(data, 'Rp')
            )
        },
        {
            name: 'proposed_by',
            label: 'Proposed By',
            type: 'string',
            filterable: true
        },
        {
            name: 'approved_by',
            label: 'Approved By',
            type: 'string',
            filterable: true
        },
        {
            name: 'processed_by',
            label: 'Processed By',
            type: 'string',
            filterable: true
        },
        {
            name: 'released_by',
            label: 'Released By',
            type: 'string',
            filterable: true
        },
    ];

    useEffect(() => {
        if (typeof params.id !== "undefined") {
            setSelectedTransaction(Number(params.id));
        }
    }, [params]);

    const onExport = () => {
        renderWarningButton('Apakah anda yakin ingin meng-export closing transaction?')
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)

                    DefaultAxios.post(`${API_URL}/confirm-processed/${props.isDeposit ? 'deposit' : ''}`)
                        .then(() => {
                            renderSuccessButton('Data Exported')
                            setReDrawDatatable(reDrawDatatable + 1);
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            });
    }

    const renderExtraFooter = (rows: any[]) => {
        return (
            <div>Total Amount: {numberToCurrency(rows.reduce((prev, curr) => prev + curr.amount, 0), 'Rp')}</div>
        )
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <TransactionDetail
                permissions={permissions}
                isOpen={!!selectedTransaction}
                onClose={() => {
                    setSelectedTransaction(null)
                    window.history.pushState([], 'Verify Transaction', `/verify-transaction${props.isDeposit ? '-deposit' : ''}`);
                }}
                id={selectedTransaction}
                reDrawTable={setReDrawDatatable}
            />
            {
                permissions['closing-transaction.export'] &&
                <div className={classes.buttonContainer}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onExport}
                    >
                        Export Processed Transaction
                    </Button>
                </div>
            }

            <DataTable
                url={props.isDeposit ? `${process.env.REACT_APP_API_URL}/closing-transaction/deposit` : API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
                renderExtraFooter={renderExtraFooter}
            />
        </Root>
    );
}

export default VerifyTransactionList;

const statusOptions = [
    {
        key: 'proposed',
        value: 'Proposed',
    },
    {
        key: 'approved',
        value: 'Approved',
    },
    {
        key: 'processed',
        value: 'Processed',
    },
    {
        key: 'exported',
        value: 'Exported',
    },
    {
        key: 'released',
        value: 'Released',
    },
    {
        key: 'canceled',
        value: 'Canceled',
    },
]

const typeOptions = [
    { key: 'rent_owner', value: 'Sewa Owner' },
    { key: 'rent_agent', value: 'Sewa Agent' },
    { key: 'refund_tenant', value: 'Refund Tenant' },
    { key: 'deposit_owner', value: 'Deposit Owner' },
    { key: 'deposit_jendela', value: 'Deposit Jendela' },
    { key: 'commission_jendela', value: 'Komisi Jendela' },
    { key: 'commission_cobroke', value: 'Komisi Cobroke' },
]

const typeDepositOptions = [
    { key: 'reimburse_client', value: 'Reimburse Client' },
    { key: 'fixing_cleaning', value: 'Fixing / Cleaning' },
    { key: 'utilities', value: 'Utilities' },
    { key: 'other_deposit', value: 'Other' },
]
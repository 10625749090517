import React from 'react'

import { TextField, TextFieldProps, styled } from '@mui/material';

const DDTFND2 = styled(TextField)({
    "& .MuiInputBase-root.Mui-disabled": {
        backgroundColor: '#eee'
    },
    // '&.MuiTextField-root:has(label)': {
    //     marginTop: 24,
    // },
    // '&.MuiTextField-root:has(label) .MuiOutlinedInput-notchedOutline': {
    //     top: 0,
    // },
    '& .MuiFormLabel-root': {
        marginTop: -16,
        color: '#484848',
        fontSize: 16,
        fontWeight: 500,
        marginLeft: -14,
    },
    '& legend': {
        display: 'none',
    },
    '& .MuiAutocomplete-inputRoot': {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '& .MuiInputBase-root': {
        borderRadius: 0,
        paddingLeft: 20,
        backgroundColor: 'white',
        '&:has(.MuiIconButton-root)': {
            paddingRight: 0,
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#D8D8D8',
        top: 0,
    },
    '& .MuiSelect-select': {
        minHeight: 'inherit'
    },
    '& .MuiInputBase-input, & .MuiOutlinedInput-root .MuiAutocomplete-input': {
        fontSize: 14,
        color: '#484848',
        paddingLeft: 0,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 13,
        lineHeight: '17px',
        height: 'auto',
        '&:has(+ .MuiIconButton-root)': {
            paddingRight: 0,
        },
        '&::-webkit-input-placeholder': {
            color: '#727272',
            opacity: 1,
        },
        '&:-moz-placeholder': {
            color: '#727272',
            opacity: 1,
        },
        '&::-moz-placeholder': {
            color: '#727272',
            opacity: 1,
        },
        '&:-ms-input-placeholder': {
            color: '#727272',
            opacity: 1,
        },
        '&::-ms-input-placeholder': {
            color: '#727272',
            opacity: 1,
        },
        '&::placeholder': {
            color: '#727272',
            opacity: 1,
        },
    },
    '& .MuiFormHelperText-root': {
        marginLeft: 0,
    },
    '& .MuiInputAdornment-root > p': {
        fontSize: 14,
        color: '#484848',
    },
});

const DarkerDisabledTextFieldND2 = (props: TextFieldProps) => (<DDTFND2 {...props} InputLabelProps={{ shrink: true, ...props.InputLabelProps }} sx={{ marginTop: props.label ? 3 : undefined }} />)

export default DarkerDisabledTextFieldND2
import React from 'react';

/**
 * Components
 */
import { DatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import TextFieldND from './_newDesignComponents/_form/TextFieldND';
import TextFieldND2 from './_formElements/_newDesign2/TextFieldND2';

/**
 * Utils
 */
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

/**
 * Icons
 */
import ClearIcon from '@mui/icons-material/Clear';
import TextFieldND3 from './_formElements/_newDesign2/TextFieldND3';

interface IProps {
    label?: string
    value: Date | null,

    /**
     * To make this props work, need to call onClear props 
     */
    clearable?: boolean
    size?: "medium" | "small"
    inputVariant?: "standard" | "outlined" | "filled"
    variant?: "dialog" | "inline" | "static"
    margin?: "none" | "dense" | "normal"
    disableToolbar?: boolean
    format?: string
    onChange: (date: Date | null) => void,
    fullWidth?: boolean
    placeholder?: string
    error?: boolean
    helperText?: string
    disabled?: boolean
    maxDate?: Date | null
    minDate?: Date | null
    onClear?: () => void
    newDesign?: boolean
    newDesign2?: boolean
    newDesign3?: boolean
    withoutIcon?: boolean
}

const MuiDatePicker = ({
    label,
    value,
    clearable,
    size,
    inputVariant,
    variant,
    margin,
    disableToolbar,
    format,
    onChange,
    fullWidth,
    placeholder,
    error,
    helperText,
    disabled,
    maxDate,
    minDate,
    onClear,
    newDesign,
    newDesign2,
    newDesign3,
    withoutIcon,
}: IProps) => {
    const handleDateChanged = (date: Date | null) => {
        /**
         * Setting Hour is for workaround because if its not set like this, the time will be changed.
         */
        if (date === null) {
            date = null;
        } else {
            if (date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0) {
                date.setHours(17);
            }
        }
        onChange(date);
    }

    const handleClear = () => {
        onClear && onClear()
    }

    const renderInput = (props: TextFieldProps) => {
        const Input = newDesign3 ? TextFieldND3 : newDesign2 ? TextFieldND2 : newDesign ? TextFieldND : TextField

        return (
            <Input
                {...props}
                variant={inputVariant ? inputVariant : "outlined"}
                fullWidth={typeof fullWidth !== "undefined" ? fullWidth : true}
                label={props.label}
                // KeyboardButtonProps={{
                //     'aria-label': 'change date',
                // }}
                placeholder={placeholder ? placeholder.toString() : "dd/MM/yyyy"}
                error={error ? error : false}
                helperText={helperText}
                disabled={disabled ? disabled : false}
            />
        )
    }

    const render = () => {
        const Picker = withoutIcon ? MobileDatePicker : DatePicker

        return (
            <Picker
                // clearable={clearable}
                label={label}
                value={value}
                // size={size}
                // disableToolbar={disableToolbar ? disableToolbar : false}
                inputFormat={format ? format : "dd/MM/yyyy"}
                // inputVariant={inputVariant ? inputVariant : "outlined"}
                // fullWidth={typeof fullWidth !== "undefined" ? fullWidth : true}
                // KeyboardButtonProps={{
                //     'aria-label': 'change date',
                // }}
                // variant={variant}
                // margin={margin}
                InputAdornmentProps={{ position: "start" }}
                onChange={(date: Date | null) => handleDateChanged(date)}
                // placeholder={placeholder ? placeholder.toString() : "dd/MM/yyyy"}
                // error={error ? error : false}
                // helperText={helperText}
                disabled={disabled ? disabled : false}
                minDate={minDate || undefined}
                maxDate={maxDate || undefined}

                InputProps={{
                    ...(clearable && value ? {
                        endAdornment: (
                            <IconButton
                                onClick={handleClear}
                            >
                                <ClearIcon />
                            </IconButton>
                        )
                    } : {})
                }}

                renderInput={renderInput}
            />
        )
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {/* TODO: might break */}
            {render()}
        </LocalizationProvider>
    )
}

export default MuiDatePicker
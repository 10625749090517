import React, { useState, useEffect } from 'react';
import { TextField, Typography, Grid } from '@mui/material';
import { format } from 'date-fns'

// Components
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, getMonth, convertPrice } from '../../_utils/Helper';
import TransportReimbursementDetail from './TransportReimbursementDetail';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IReimbursement {
    created_at: string,
    date: string,
    overtime_amount: string,
    total_amount: string,
    id: string
}

const useStyles = generateStyle(theme => ({
    card: {
        border: '1px solid #cacaca',
        padding: '10px 20px',
        borderRadius: 5,
        backgroundColor: 'white',
        marginBottom: 16,
        position: 'relative',
        cursor: 'pointer'
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}), "TransportRembursementList");

const TransportReimbursementList = () => {
    const { Root, classes } = useStyles();
    const [dataReimbursement, setDataReimbursement] = useState<IReimbursement[]>([])
    const [detailOpen, setDetailOpen] = useState(false)
    const [detailId, setDetailId] = useState('')
    const handleModalOpen = () => setDetailOpen(true);
    const handleModalClose = () => setDetailOpen(false);
    const [isLoading, setIsLoading] = useState(false)
    const [monthFilter, setMonthFilter] = useState({
        label: format(new Date(new Date()), 'MMMM - yyyy'),
        value: format(new Date(new Date()), 'MM/yyyy')
    })
    const [totalAmount, setTotalAmount] = useState("")

    const fetchData = (isFiltered?: boolean) => {
        setIsLoading(true)

        setDataReimbursement([])

        const data = {
            month: Number(monthFilter.value.substring(0, monthFilter.value.indexOf('/'))),
            year: Number(monthFilter.value.split('/').pop())
        }

        DefaultAxios?.get(`${process.env.REACT_APP_API_URL}/transport-reimbursement`, { params: data })
            .then(res => res.data)
            .then((res: any) => {
                let items: IReimbursement[] = res
                setTotalAmount(res.reduce((a: string, b: any) => (parseInt(a) + parseInt(b.total_amount)), 0))
                setDataReimbursement(items)

                setIsLoading(false)
            })
            .catch((err) => {
                generalErrorHandler(err);
            })
    }

    const handleChangeMonth = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            var month = (value.substring(0, value.indexOf('/')))
            var year = value.substring(value.indexOf('/') + 1)
            const fullLabel = `${getMonth(Number(month) - 1)} - ${year.toString()}`
            setMonthFilter(prev => ({
                ...prev,
                value: value,
                label: fullLabel
            }))
        }
        // setMonthFilter(month)
        // setYearFilter(year)

    }

    const renderList = () => {
        return (
            dataReimbursement.map((data, idx) =>
                <div key={data.id} onClick={() => openDetail(data.id)}>
                    <div className={classes.card}>
                        <div className={classes.cardContainer}>
                            <div>
                                {data.date}
                            </div>
                            <div>
                                Rp. {convertPrice(data.total_amount)}
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }

    const openDetail = (id: any) => {
        setDetailId(id);
        handleModalOpen();
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [monthFilter])


    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <TransportReimbursementDetail id={detailId} open={detailOpen} onClose={handleModalClose} />
            <Grid container spacing={2} style={{ marginTop: 8 }}>
                <Grid item xs={12} md={2.5} style={{ marginBottom: 24 }}>
                    <TextField
                        label="Jenis Properti"
                        name="listing_type_id"
                        value={monthFilter.value}
                        onChange={handleChangeMonth}
                        // error={errorList.listing_type_id ? true : false}
                        // helperText={errorList.listing_type_id}
                        variant="outlined"
                        select
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    >
                        <option value="" key="default" disabled>Pilih Bulan</option>
                        <option value={format(new Date(new Date()), 'MM/yyyy')}>{format(new Date(new Date()), 'MMMM - yyyy')}</option>
                        <option value={format(new Date(new Date().setMonth(new Date().getMonth() - 1)), 'MM/yyyy')}>{format(new Date(new Date().setMonth(new Date().getMonth() - 1)), 'MMMM - yyyy')}</option>
                        <option value={format(new Date(new Date().setMonth(new Date().getMonth() - 2)), 'MM/yyyy')}>{format(new Date(new Date().setMonth(new Date().getMonth() - 2)), 'MMMM - yyyy')}</option>
                    </TextField>
                </Grid>
                {
                    dataReimbursement.length ?
                        <Grid item xs={12} md={8} lg={6}>
                            {renderList()}
                            <Grid >
                                <Grid>
                                    <Grid style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 20 }}>Total:     Rp. {convertPrice(totalAmount.toString())}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid container justifyContent="center" direction="column" alignItems="center" style={{ marginTop: 32 }} >
                            <img src="https://pintu-cdn.sgp1.cdn.digitaloceanspaces.com/assets/img/dataNA.png" alt="no-data" />
                            <Typography variant="subtitle2" style={{ marginTop: "10px" }}>
                                Tidak ada data
                            </Typography>
                        </Grid>
                }
            </Grid>
        </Root>
    );
}

export default TransportReimbursementList;
import { Grid } from '@mui/material'
import React from 'react'
import GeneralPerformance from '../_generalPerformance/GeneralPerformance'
import TRPerformance from '../_trPerformance/TRPerformance'
import MenuBar from '../../_components/MenuBar'

type Props = {}

const DashboardOperationManager = (props: Props) => {
    return (
        <MenuBar title='Dashboard Operation Manager'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <GeneralPerformance
                        renderMiddleSection={
                            <Grid container>
                                <Grid item xs={12} sx={{ mt: 3}}>
                                    <TRPerformance />
                                </Grid>
                            </Grid>
                        }
                    />
                </Grid>
            </Grid>
        </MenuBar>
    )
}

export default DashboardOperationManager
import React, { useState, useReducer, useEffect } from 'react';
import {
    Theme,
    Grid,
    Button,
    Slide,
    Dialog,
    Toolbar,
    Typography,
    AppBar,
    IconButton
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

// Components
import LoadingScreen from '../../../_components/LoadingScreen';
import AgentOfficeForm from './AgentOfficeForm';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';

// Icon
import CloseIcon from '@mui/icons-material/Close';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    open: boolean;
    onClose: () => void,
    onCloseEdited: () => void,
    officeId?: string
}

interface IState {
    name: string,
    phone: string,
    address: string,
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = generateStyle((theme: Theme) => ({
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(3, 2),
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        // paddingBottom: '20px'
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "AgentOffice_Detail"
)

const DetailCell = ({ label, value }: { label: string, value: string | number | JSX.Element }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
                {label}
                <span style={{ float: "right" }}>:</span>
            </Grid>
            <Grid item xs={6} sm={8} style={{ whiteSpace: 'pre-line' }}>
                {value}
            </Grid>
        </Grid>
    )
}

const AgentOfficeDetail = (props: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = `${process.env.REACT_APP_API_URL}/agent-office`;
    const [modalState, setModalState] = useState(false);
    const [isEdited, setIsEdited] = useState(false)
    const handleOpen = () => setModalState(true);
    const handleClose = () => setModalState(false);
    const [loading, setLoading] = useState(false);

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={isEdited === true ? props.onCloseEdited : props.onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Agent Office Detail
                </Typography>
                <Button autoFocus color="inherit" onClick={() => handleOpen()} >
                    Edit
                </Button>
            </Toolbar>
        )
    }

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                phone: '',
                address: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        name: '',
        phone: '',
        address: '',
    });

    const loadData = () => {
        setLoading(true)
        DefaultAxios
            .get(`${API_URL}/${props.officeId}`)
            .then(res => {
                const data = res.data;
                setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                setLoading(false)
            })
    }

    const submitSuccess = () => {
        handleClose();
        loadData();
        setIsEdited(true)
    }

    useEffect(() => {
        if (props.officeId) {
            loadData()
            setIsEdited(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.officeId]);

    return (

        <Dialog
            open={props.open}
            onClose={props.onClose}
            closeAfterTransition
            TransitionComponent={Transition}
            fullWidth
            maxWidth="sm"
        >
            <Root>
                <AgentOfficeForm
                    open={modalState}
                    onClose={handleClose}
                    mode={'edit'}
                    onSubmitSuccess={submitSuccess}
                    officeId={props.officeId}
                />
                <AppBar className={classes.appBar}>
                    <FormToolbar></FormToolbar>
                </AppBar>
                <Grid className={classes.root}>
                    <LoadingScreen open={loading} fullScreen />
                    <Grid item xs={12} className={classes.grid}>
                        <DetailCell
                            label="Name"
                            value={inputState.name === null ? '-' : (`${inputState.name}`)}
                        />
                        <DetailCell
                            label="Phone"
                            value={inputState.phone === null ? '-' : (`${inputState.phone}`)}
                        />
                        <DetailCell
                            label="Address"
                            value={inputState.address === null ? '-' : (`${inputState.address}`)}
                        />
                    </Grid>
                </Grid>
            </Root>
        </Dialog>
    );
}

export default AgentOfficeDetail;
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React from 'react'
import { IValidationErrors } from '../../../_utils/Validation'
import { StatementFormState } from '../StatementList'

interface StatementCancelFormProps {
    isOpen: boolean
    onClose: () => void
    state: StatementFormState
    error: IValidationErrors
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleSubmitCancel: () => void
}

const StatementCancelForm = ({
    state,
    error,
    handleChange,
    handleSubmitCancel,
    ...props
}: StatementCancelFormProps) => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Form Cancel</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Isi Alasan"
                            name="reason"
                            value={state.reason}
                            multiline
                            onChange={handleChange}
                            error={!!error.reason}
                            helperText={error.reason}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleSubmitCancel}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default StatementCancelForm

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
    Select,
    MenuItem,
    Dialog,
    Grid,
    FormControlLabel,
    Switch,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    FormControl,
    Theme,
    SelectChangeEvent
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { format } from 'date-fns';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import RentModal from './ListingSubmissionModalDetail';
import DataTable from '../../_components/_dataTable/DataTable';
import RentModalFailed from './RentModalFailed';
import RentDetail from './RentDetail';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, isMobile, renderWarningButton, renderToastSuccess } from '../../_utils/Helper';
import { ctaType } from '../../_utils/ConstData';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    rent_id?: string;
    reload?: boolean | undefined;
    closeModal?: boolean | undefined;
}

interface IParams extends Record<string, string | undefined> {
    rent_id?: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    root: {
        display: 'inline-flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    edit_button: {
        // backgroundColor: '#fff',
        color: '#fff'
    },
    link: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        }
    }
}), "RentList"
);

export default function RentList(props?: IProps) {
    const permissions = useContext(PermissionContext)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const RENT_URL = process.env.REACT_APP_API_URL + '/rent';
    const [openDetail, setOpenDetail] = useState<boolean>(false)
    const [openEdit, setOpenEdit] = useState<boolean>(false)
    const [openFailed, setOpenFailed] = useState(false)
    const [moreDetail, setMoreDetail] = useState(false)
    const { Root, classes } = useStyles();
    const navigate = useNavigate();
    const [queryString, setQueryString] = useState('')
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    // const { rent_id } = useParams();
    // const { reload } = useParams();

    // const [listingType, setListingType] = useState<string>();
    // const [propertyId, setPropertyId] = useState("");

    // const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const params = useParams<IParams>()

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & { children: React.ReactElement },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const [rentId, setRentId] = useState<string>('');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [successStatus, setSuccessStatus] = useState<boolean>(false)


    const handleCloseEdit = () => {
        setOpenEdit(false)
    };

    const handleEdit = (data: any) => {
        navigate('/listing-submission/edit/' + rentId)
    }

    // const openModalDetail = (data: any) => {
    //     setOpenDetail(true)
    //     window.history.pushState([], 'Listing Submission', `/listing-submission/${data}`);
    // }

    const handleCloseDetail = (isRefresh?: boolean) => {
        window.history.pushState([], 'Listing Submission', `/listing-submission/${queryString ? `?${queryString}` : ''}`);
        if (isRefresh) setReDrawDatatable(new Date().getTime())
        setOpenDetail(false)
        setRentId('')
    }

    useEffect(() => {
        if (typeof params.rent_id === 'string') {
            setOpenDetail(true);
        }
    }, [params]);

    const FormToolbarEdit = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleCloseEdit} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Rent Edit
                </Typography>
            </Toolbar>
        )
    }

    // const handleShowProperty = (listingType?: any, propertyId?: any) => {
    //     window.location.assign('https://jendela360.com/admin-cms/unit/edit/' + propertyId);
    // }

    const FormFailedToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleFailedClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Rent Failed
                </Typography>
            </Toolbar>
        )
    }

    const changeFollowUp = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[], type: number) => {
        setIsLoading(true);
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/rent/follow-up/${row.id}`)
            .then(res => {
                renderToastSuccess('Status berhasil diubah');

                const newRows = rows.map((element: any) => {
                    if (element.id === row.id) element[columnName] = type
                    return element
                })
                setRows(newRows)
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const changeStatus = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[], type: number) => {
        const newRows = rows.map((element: any) => {
            if (element.id === row.id) element[columnName] = type
            return element
        })
        setRows(newRows)
    }

    const failedComment = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[], type: number) => {
        setRentId(row.id);
        setOpenFailed(!openFailed);
        const newRows = rows.map((element: any) => {
            if (element.id === row.id) element[columnName] = type
            return element
        })
        setRows(newRows)
    }

    const handleFailedClose = () => {
        setOpenFailed(false)
    };

    const handleChangeStatus = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => (event: SelectChangeEvent<any>, child: React.ReactNode) => {
        let type = event.target.value
        let text = ' | Do you want to proceed ?';
        switch (type) {
            case 0: text = 'Changing to New Submission' + text; break;
            case 2: text = 'Changing to Rejected' + text; break;
            case 3: text = 'Changing to On Process' + text; break;
            default: text = 'undefined'; break;
        }

        renderWarningButton(text).then((res) => {
            if (res.value) {
                if (type === 0) {
                    changeStatus(name, row, columnName, options, setRows, rows, type);
                } else if (type === 2) {
                    failedComment(name, row, columnName, options, setRows, rows, type);
                } else if (type === 3) {
                    changeFollowUp(name, row, columnName, options, setRows, rows, type);
                }
                if (successStatus) {
                    const newRows = rows.map((element: any) => {
                        if (element.id === row.id) element[columnName] = type
                        return element
                    })
                    setRows(newRows)
                }
            }
        })
    }

    const renderWa = (data: any, row: any) => {
        let regex = /^08/;
        if (data.match(regex)) {
            data = data.replace(regex, "628")
        }
        let phoneNumber = data;

        return <a href={`https://api.whatsapp.com/send?phone=${phoneNumber}`}>{phoneNumber}</a>
    }

    const renderStatus = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
        return (
            row[columnName] !== 1 ?
                <FormControl disabled={row[columnName] === 2 ? true : false}>
                    <Select
                        value={row[columnName]}
                        onChange={handleChangeStatus(name, row, columnName, options, setRows, rows)}
                    >
                        <MenuItem value={0}>New Submission</MenuItem>
                        <MenuItem value={3}>On Process</MenuItem>
                        <MenuItem value={2}>Rejected</MenuItem>
                    </Select>
                </FormControl>
                : <span>Listed</span>
        )
    }


    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            {/* {openDetail &&
                <Dialog
                    fullScreen
                    open={openDetail}
                    onClose={handleCloseDetail}
                    closeAfterTransition
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <FormToolbar></FormToolbar>
                    </AppBar>
                    <RentDetail permissions={permissions} />
                </Dialog>
            } */}
            <RentDetail
                open={openDetail}
                onClose={handleCloseDetail}
                onEdit={handleEdit}
                rent_id={rentId || undefined}
                parentQueryString={queryString}
            />

            {openEdit &&
                <Dialog
                    fullScreen
                    open={openEdit}
                    closeAfterTransition
                    TransitionComponent={Transition}
                >
                    <Root>
                        <AppBar className={classes.appBar}>
                            <FormToolbarEdit></FormToolbarEdit>
                        </AppBar>
                        <RentModal permissions={permissions} is_edit={true} />
                    </Root>
                </Dialog>
            }

            {openFailed ?
                <Dialog
                    open={openFailed}
                    closeAfterTransition
                    fullWidth
                    maxWidth="md"
                    fullScreen={isMobile() ? true : false}
                // onClose={handleFailedClose}
                // TransitionComponent={Transition}
                >
                    <Root>
                        <AppBar className={classes.appBar}>
                            <FormFailedToolbar></FormFailedToolbar>
                        </AppBar>
                        <RentModalFailed rentDetail_id={rentId} closeModal={() => setOpenFailed(false)} />
                    </Root>
                </Dialog>
                : <></>
            }

            {
                permissions['submission.see-utm-detail'] &&
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={moreDetail}
                                    onChange={() => { setMoreDetail(!moreDetail) }}
                                    color="primary"
                                />
                            }
                            label="More Detail"
                        />
                    </Grid>
                </Grid>
            }

            <DataTable
                reDraw={reDrawDatatable}
                setParentQueryString={setQueryString}
                url={RENT_URL}
                columns={[
                    {
                        name: 'code',
                        label: 'Code',
                        type: 'string',
                        filterable: true,
                        sortable: false,
                        render: (data: any, row: any) => {
                            return (
                                <>
                                    <a
                                        href={`/listing-submission/${row.id}`}
                                        className={classes.link}
                                        onClick={e => {
                                            e.preventDefault()
                                            setRentId(row.id)
                                            window.history.pushState([], 'Listing Submission', `/listing-submission/${row.id}`);
                                            setOpenDetail(true)
                                        }}
                                    >
                                        {data}
                                    </a>
                                    <div>{row.status === 1 ? `[${row.property_code}]` : ''}</div>
                                </>
                            );
                        }
                    },
                    {
                        name: 'created_at',
                        label: 'Submission Time',
                        type: 'date',
                        filterable: true,
                        sortable: false,
                        render: (data: any, row: any) => {
                            return format(new Date(data), 'dd LLL yyyy HH:mm:ss')
                        }
                    },
                    {
                        name: 'listing_type',
                        label: 'Listing Type',
                        type: 'string',
                        filterable: true,
                        sortable: false,
                        defaultOption: '',
                        render: (data: any, row: any) => {
                            let text = "undefined";
                            switch (data) {
                                case 0:
                                    text = 'Apartment'
                                    break;
                                case 2:
                                    text = 'Rumah'
                                    break;
                                case 3:
                                    text = 'Tanah'
                                    break;
                                case 4:
                                    text = 'Ruko'
                                    break;
                                case 5:
                                    text = 'Kantor'
                                    break;
                                default:
                                    text = "undefined-missing";
                                    break;
                            }

                            return text;
                        },
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '0',
                                value: 'Apartment'
                            },
                            {
                                key: '2',
                                value: 'Rumah'
                            },
                            {
                                key: '3',
                                value: 'Tanah'
                            },
                            {
                                key: '4',
                                value: 'Ruko'
                            },
                            {
                                key: '5',
                                value: 'Kantor'
                            },
                            {
                                key: '6',
                                value: 'Gudang'
                            },
                            {
                                key: '6',
                                value: 'Ruang Usaha'
                            }
                        ]
                    },
                    {
                        name: 'cta',
                        label: 'CTA',
                        filterable: true,
                        sortable: false,
                        type: 'string',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: 'cta_hero',
                                value: 'CTA Hero'
                            },
                            {
                                key: 'cta_benefit',
                                value: 'CTA Benefit'
                            },
                            {
                                key: 'cta_bottom',
                                value: 'CTA Bottom'
                            },
                            {
                                key: 'cta_mobile_float',
                                value: 'CTA Mobile - Float'
                            },
                            {
                                key: 'cta_mobile_bottom',
                                value: 'CTA Mobile - Bottom'
                            },
                        ],
                        render: (data: null | "cta_hero" | "cta_benefit" | "cta_bottom" | "cta_mobile_float" | "cta_mobile_bottom", row: any) => {
                            return data ? ctaType[data] : "-"
                        }
                    },
                    {
                        name: 'status',
                        label: 'Status',
                        type: 'string',
                        filterable: true,
                        sortable: false,
                        defaultOption: '',
                        render: renderStatus,
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '0',
                                value: 'New Submission'
                            },
                            {
                                key: '3',
                                value: 'On Process'
                            },
                            {
                                key: '1',
                                value: 'Listed'
                            },
                            {
                                key: '2',
                                value: 'Rejected'
                            },
                        ]
                    },
                    {
                        name: 'name',
                        label: 'Name',
                        type: 'string',
                        filterable: true,
                        sortable: true,
                    },
                    {
                        name: 'is_paid_listing',
                        label: 'Paid Listing',
                        type: 'string',
                        filterable: true,
                        sortable: false,
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '1',
                                value: 'Yes'
                            },
                            {
                                key: '0',
                                value: 'No'
                            }
                        ],
                        render: (data: any, row: any) => {
                            return data === 0 ? 'NO' : 'YES';
                        }
                    },
                    {
                        name: 'unit_for',
                        label: 'Sewa / Jual',
                        type: 'string',
                        filterable: true,
                        sortable: false,
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: 'sewa',
                                value: 'Sewa'
                            },
                            {
                                key: 'jual',
                                value: 'Jual'
                            },
                            {
                                key: 'sewa-jual',
                                value: 'Sewa & Jual'
                            }
                        ],
                        render: (data: any) => {
                            switch (data) {
                                case 'sewa': return 'Sewa';
                                case 'jual': return 'Jual';
                                case 'sewa-jual': return 'Sewa & Jual';
                                default: return '[UNDEFINED]';
                            }
                        }
                    },
                    {
                        name: 'ownership_type',
                        label: 'Ownership Type',
                        type: 'string',
                        filterable: true,
                        sortable: false,
                        defaultOption: '',
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: '0',
                                value: 'Agent'
                            },
                            {
                                key: '1',
                                value: 'Owner'
                            }
                        ],
                        render: (data: any) => {
                            return +data === 0 ? 'Agent' : 'Owner';
                        }
                    },
                    {
                        name: 'address',
                        label: 'Apartment / Alamat',
                        type: 'string',
                        filterable: true,
                        sortable: true,
                    },
                    {
                        name: 'email',
                        label: 'Email',
                        type: 'string',
                        filterable: true,
                        sortable: false,
                    },
                    {
                        name: 'phone',
                        label: 'Phone',
                        type: 'string',
                        render: (data: any, row: any) => {
                            return renderWa(data, row)
                        },
                        filterable: true,
                        sortable: false,
                    },
                    ...(
                        moreDetail ? [
                            {
                                name: 'utm_source',
                                label: 'UTM Source',
                                type: 'string',
                                filterable: true
                            },
                            {
                                name: 'utm_medium',
                                label: 'UTM Medium',
                                type: 'string',
                                filterable: true
                            },
                            {
                                name: 'utm_campaign',
                                label: 'UTM Campaign',
                                type: 'string',
                                filterable: true
                            },
                            {
                                name: 'utm_content',
                                label: 'UTM Content',
                                type: 'string',
                                filterable: true
                            },
                            // {
                            //     name: 'keyword',
                            //     label: 'Keyword',
                            //     type: 'string',
                            //     filterable: true
                            // },
                            // {
                            //     name: 'adsetname',
                            //     label: 'Adsetname',
                            //     type: 'string',
                            //     filterable: true
                            // },
                            // {
                            //     name: 'adgroupid',
                            //     label: 'Adgroupid',
                            //     type: 'string',
                            //     filterable: true
                            // },
                            // {
                            //     name: 'creative',
                            //     label: 'Creative',
                            //     type: 'string',
                            //     filterable: true
                            // },
                        ] : []
                    ),
                    // {
                    //     name: 'bedroom',
                    //     label: 'Bedroom',
                    //     type: 'string',
                    //     filterable: true,
                    //     sortable: true,
                    //     render: renderBedroom
                    // },

                ]}
            // customParams={customParams}
            />
        </Root>
    );
}
import React, { useState } from 'react';
import { Theme, Dialog, Grid, Divider, TableContainer, Paper, Chip } from '@mui/material';

/**
 * Asset
 */
import pdfPng from '../../../Assets/Images/png/pdf.png';
import docPng from '../../../Assets/Images/png/doc.png';
import videoPng from '../../../Assets/Images/png/video.png';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    closing_id: string;
    permissions: any;
    files: IFile[];
    onUploadFile?: Function;
    onDeleteFile?: Function;
    isSmall?: boolean
    transactionType?: string
}

export interface IFile {
    id: string;
    created_at: string;
    filepath: string;
    name: string;
    type: string;
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        width: '100%',
        '& .MuiTextField-root': {
            backgroundColor: '#fff'
        }
    },
}), "CompactFile_List"
);

const fileTypes = [
    {
        value: 'payment_file',
        label: 'Bukti Pembayaran'
    },
    {
        value: 'customer_ktp',
        label: 'KTP Tenant'
    },
    {
        value: 'customer_kitas',
        label: 'KITAS Tenant'
    },
    {
        value: 'customer_passport',
        label: 'Paspor Tenant'
    },
    {
        value: 'customer_selfie',
        label: 'Selfie Tenant'
    },
    {
        value: 'owner_ktp',
        label: 'KTP Owner'
    },
    {
        value: 'owner_kk',
        label: 'Kartu Keluarga Owner'
    },
    {
        value: 'owner_other',
        label: 'File Owner Lainnya'
    },
    {
        value: 'completed_document',
        label: 'Kontrak Sewa untuk Owner'
    },
    {
        value: 'completed_document_rent',
        label: 'Kontrak Sewa untuk Tenant'
    },
    {
        value: 'deposit_detail',
        label: 'Rincian Deposit'
    },
    {
        value: 'maintenance_detail',
        label: 'Rincian Perbaikan'
    },
    {
        value: 'owner_document_ownership',
        label: 'Bukti Kepemilikan Unit'
    },
    {
        value: 'owner_custom_doc',
        label: 'Custom Contract Owner'
    },
    {
        value: 'customer_custom_doc',
        label: 'Custom Contract Tenant'
    },
    {
        value: 'checkout_video',
        label: 'Video Checkout'
    },
    {
        value: 'proof',
        label: 'Bukti'
    },
    {
        value: 'other',
        label: 'Lainnya'
    }
];

const FileList = (props: Props) => {
    const { files } = props;
    const { Root, classes } = useStyles();

    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);

    const getLabel = (value: string) => {
        if (value === 'proof') return props.transactionType
        for (let index = 0; index < fileTypes.length; index++) {
            if (fileTypes[index].value === value) {
                return fileTypes[index].label;
            }
        }
    }

    const handlePreviewFile = (src: string, isImage: boolean) => {
        if (isImage) {
            setPreviewImage(src);
        } else {
            window.open(src);
        }
    }

    const renderFilesNew = () => {
        return files.map((file, key) => {
            let src = '';
            let isImage = false;
            let filepath = file.filepath;

            let explode = filepath.split('?');
            const match = /(\.\w+$)/g.exec(explode[0]);

            if (match) {
                if (match[1] === '.pdf' || match[1] === '.PDF') {
                    src = pdfPng;
                } else if (match[1] === '.doc' || match[1] === '.docx') {
                    src = docPng;
                } else if (['.flv', '.mp4', '.m3u8', '.ts', '.3gp', '.mkv', '.mov', '.avi', '.wmv'].includes(match[1])) {
                    src = videoPng;
                } else {
                    src = filepath;
                    isImage = true;
                }
            }

            return (
                <>
                    <Grid container>
                        <Grid item md={2}>
                            <img height={50} width={50} src={src} alt="file" onClick={() => handlePreviewFile(filepath, isImage)} />
                        </Grid>
                        <Grid item md={10}>
                            <span
                                className="file-name"
                                onClick={() => handlePreviewFile(filepath, isImage)}
                            >
                                {file.type === 'proof' ? props.transactionType || 'Bukti Request Deposit' : file.name}
                                <br />
                                <Chip color="primary" label={getLabel(file.type)} size="small" />
                            </span>
                        </Grid>
                    </Grid>

                    <Divider />
                </>
            )
        })
    }

    return (
        <Root>
            <div className={classes.root}>
                <Dialog
                    open={!!previewImage}
                    onClose={() => { setPreviewImage(undefined) }}
                    scroll="body"
                    maxWidth={false}
                >
                    <img src={previewImage} width="100%" alt="preview" />
                </Dialog>

                <TableContainer component={Paper} elevation={0}>
                    {renderFilesNew()}
                </TableContainer>
            </div>
        </Root>
    );
}

export default FileList;
import React, { useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
// import ImageTab from './_gallery/_tabs/_images/ImageTab';
// import PhotoTab from './_gallery/_tabs/_360Photos/PhotoTab';
import VideoTab from './_gallery/_tabs/_360Videos/VideoTab';
import ImageFormPage from '../../_components/_form/ImageForm/Page';
import Photo360FormPage from '../../_components/_form/Photo360Form/Page';
import VideoForm from '../../_components/_form/VideoForm/VideoForm';
// import ImageFormPageForApartment from '../../_components/_form/ImageForm/PageForApartment';

interface Props {
    id: string
    apiUrlImage: string
    apiUrl360: string
    with360Videos?: boolean
    type?: 'unit' | 'apartment'
}

const UnitGalleryForm = (props: Props) => {
    const [type, setType] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleTabChanged = (event: React.ChangeEvent<{}>, newValue: number) => {
        setType(newValue);
    };

    const handleLoading = (isLoading: boolean) => {
        setLoading(isLoading);
    }

    const render = () => {
        // Images
        if (type === 0) {
            // if (props.type === 'apartment') {
            //     return <ImageFormPageForApartment
            //         API_URL={props.apiUrlImage}
            //         type={props.type || 'unit'}
            //     />
            // }

            return <ImageFormPage
                API_URL={props.apiUrlImage}
                type={props.type || 'unit'}
                dataId={props.id}
            />
        } else if (type === 1) {
            return <Photo360FormPage
                API_URL={props.apiUrl360}
            />;
        } else if (type === 2 && props.with360Videos) {
            return <VideoTab
                id={props.id}
                handleLoading={handleLoading}
            />;
        } else if ((type === 2 && !props.with360Videos) || type === 3) {
            return <VideoForm
                API_URL={`${process.env.REACT_APP_API_URL}/${props.type || 'unit'}/${props.id}/videos`}
            />
        }
    }

    return (
        <>
            <LoadingScreen open={loading} fullScreen />
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Tabs
                            value={type}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChanged}
                            aria-label="disabled tabs example"
                            variant="fullWidth"
                        >
                            <Tab label="Images" />
                            <Tab label="360 Photos" />
                            {
                                props.with360Videos &&
                                <Tab label="360 Videos" />
                            }
                            <Tab label="Videos" />
                        </Tabs>
                    </Grid>

                    <Grid item xs={12}>
                        {render()}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default UnitGalleryForm;
import React from 'react';
import {
    Grid,
    Typography,
} from '@mui/material';

export default function Dashboard() {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="h4">
                        Hello, Welcome To Jendela CMS
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}
import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, Grid, TextField, Button, MenuItem, Typography } from '@mui/material'

/**
 * Components
 */
import LoadingScreen from '../../../../_components/LoadingScreen'
import AsyncAutoComplete from '../../../../_components/_form/AsyncAutoComplete'

/**
 * Utils
 */
import { IQiscusData } from '../ClosingModalDetail'
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../../../_utils/Helper'
import { format } from 'date-fns'
import { id } from 'date-fns/locale'

interface IProps {
    open: boolean,
    onClose: () => void
    data: IQiscusData | null
    onSubmitSuccess: () => void
    type?: 'greeting_customer' | 'greeting_landlord' | string
}

interface ITemplate {
    body: string
    category: string
    id: number
    name: string
    label: string
    qiscus_template_id: number
    variables: number
    predefined_variables: (string | null)[]
    variable: string[]
}

interface IBroadcast {
    broadcaster: string
    datetime: string
    lang: string
    template_name: string
    closing_code: string
    unit_code: string
}

const initialCustomParam = {
    lang: '',
    template_type: ''
}

const QiscusTemplateModal = (props: IProps) => {
    const [state, setState] = useState<ITemplate | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [customParamAutocomplete, setCustomParamAutoComplete] = useState(initialCustomParam)
    const [isGreeting, setIsGreeting] = useState('')
    const [log, setLog] = useState<IBroadcast | null>(null)

    const handleChangeContent = (index: number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (state) {
            let oldVariable = [...state.variable]
            oldVariable[index] = e.target.value

            setState(prev => ({
                ...prev as ITemplate,
                variable: oldVariable
            }))
        }

    }

    const renderContentField = () => {
        if (state && state.predefined_variables) {
            return state.predefined_variables.map((varr, index) => {
                return (
                    <Grid item xs={12} key={`${state.id}+${index}`}>
                        <TextField
                            name='content'
                            InputLabelProps={{ shrink: true }}
                            label={`Content ${index + 1}`}
                            disabled={varr !== null}
                            value={state.variable[index]}
                            onChange={handleChangeContent(index)}
                            fullWidth
                        />
                    </Grid>
                )
            })
        }
    }

    const getLangByName = (name: string) => {
        if (name.startsWith('en_')) {
            return 'en'
        }

        return 'id'
    }

    const fetchDetail = (name: string, lang: string) => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/qiscus-broadcast/template-detail`, {
            params: {
                name,
                lang,
                closing_id: props.data?.closing_id
            }
        })
            .then(res => res.data)
            .then(res => {
                setState(prev => {
                    return {
                        ...res,
                        variable: res.predefined_variables
                    }
                })
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    const handleChangeTemplate = (name: string, lang: string) => {
        fetchDetail(name, lang)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target
        setCustomParamAutoComplete(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleChangeInputAutocomplete = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(null)
    }

    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/qiscus-broadcast`, {
            template_id: state?.id,
            closing_id: props.data?.closing_id,
            type: isGreeting ? props.type?.split('_')[1] : props.data?.type,
            phone: props.data?.phone,
            variables: state?.variable
        })
            .then(res => res.data)
            .then(res => res && [renderSuccessButton('Broadcast Sent'), props.onSubmitSuccess()])
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    const fetchLastBroadCast = (type: string) => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/qiscus-broadcast/last-broadcast`, {
            params: {
                closing_id: props.data?.closing_id,
                data_id: props.data?.data_id,
                type: type,
            }
        })
            .then(res => setLog(res.data))
            .catch(err => setLog(null))
            .finally(() => setIsLoading(false))
    }

    const renderTitle = () => {
        let type = ''
        if (isGreeting) {
            type = props.type?.split('_')[1] || ''
        } else {
            type = props.data?.type || ''
        }

        return (
            `Send to ${type === 'pic_landlord' ? 'Owner' :
                type === 'customer' ? 'Tenant' :
                    type === 'agent' ? 'Agent' :
                        ''
            }`
        )
    }

    const renderSubtitle = () => {
        if (log) {
            return (
                <>
                    <Typography variant='body2' sx={{mt: 1}}>Last broadcast: {format(new Date(log.datetime), 'dd MMMM yyyy', { locale: id })}</Typography>
                    <Typography variant="body2">{`${log.closing_code} - ${log.unit_code} - ${log.template_name} - ${log.broadcaster}`}</Typography>
                </>
            )
        }

        return (
            <>
                 <Typography variant='body2' sx={{mt: 1}}>Last broadcast: -</Typography>
            </>
        )
    }

    useEffect(() => {
        if (props.open) {
            if (typeof props.type !== 'undefined') {
                setIsGreeting(props.type)
                fetchLastBroadCast(props.type.split('_')[1])

            } else {
                if (props.data) fetchLastBroadCast(props.data.type)
            }
        } else {
            setState(null)
            setIsGreeting('')
            setLog(null)
            setCustomParamAutoComplete(initialCustomParam)
        }

        // eslint-disable-next-line
    }, [props.open, props.type, props.data])

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                maxWidth="sm"
                fullWidth
            >
                <LoadingScreen open={isLoading} />
                <DialogTitle>
                    {renderTitle()}
                    <br />
                    {renderSubtitle()}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name='text'
                                disabled
                                fullWidth
                                value={state ? state.body : ''}
                                multiline
                                minRows={5}
                                maxRows={5}
                            />
                        </Grid>
                        {
                            isGreeting ?
                                <Grid item xs={12}>
                                    <TextField
                                        name="name"
                                        label="Template"
                                        fullWidth
                                        onChange={(e) => handleChangeTemplate(e.target.value, getLangByName(e.target.value))}
                                        value={state ? state.name : ''}
                                        select
                                    >
                                        <MenuItem value="" disabled>-- Select Type --</MenuItem>
                                        {

                                            props.type === 'greeting_landlord' ?
                                                <MenuItem value="checkin_greeting_owner">checkin_greeting_owner</MenuItem>
                                                : props.type === 'greeting_customer' ?
                                                    [
                                                        <MenuItem key="checkin_greeting_tenant" value="checkin_greeting_tenant">checkin_greeting_tenant</MenuItem>,
                                                        <MenuItem key="en_checkin_greeting_tenant" value="en_checkin_greeting_tenant">en_checkin_greeting_tenant</MenuItem>
                                                    ]
                                                    : null
                                        }
                                    </TextField>
                                </Grid> :
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            name='lang'
                                            label="Language"
                                            fullWidth
                                            onChange={handleChange}
                                            value={customParamAutocomplete.lang}
                                            select
                                        >
                                            <MenuItem value="" disabled>-- Select Language --</MenuItem>
                                            <MenuItem value="id">Indonesia</MenuItem>
                                            <MenuItem value="en">English</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="template_type"
                                            label="Type"
                                            fullWidth
                                            onChange={handleChange}
                                            value={customParamAutocomplete.template_type}
                                            select
                                        >
                                            <MenuItem value="" disabled>-- Select Type --</MenuItem>
                                            <MenuItem value="renewal">Renewal</MenuItem>
                                            <MenuItem value="checkout">Checkout</MenuItem>
                                            <MenuItem value="general">General</MenuItem>
                                            <MenuItem value="greeting">Greeting</MenuItem>
                                            <MenuItem value="other">Other</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AsyncAutoComplete
                                            label='Template'
                                            name='template_id'
                                            onChange={(name: string, value: any) => handleChangeTemplate(value.name, value.language)}
                                            extraParams={customParamAutocomplete}
                                            onInputChange={handleChangeInputAutocomplete}
                                            placeholder='-- Select Template --'
                                            url={`${process.env.REACT_APP_API_URL}/autocomplete/qiscus-template`}
                                        />
                                    </Grid>
                                </>
                        }
                        {renderContentField()}
                    </Grid>

                    <Grid container sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                style={{ float: 'right' }}
                                onClick={handleSubmit}
                                disabled={state === null}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}


export default QiscusTemplateModal
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiDatePicker from '../../../_components/MuiDatePicker'
import TextArea from '../../../_components/_form/TextArea'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, renderErrorButton, renderToastSuccess } from '../../../_utils/Helper'
import LoadingScreen from '../../../_components/LoadingScreen'
import { format, isValid } from 'date-fns'
import MuiTimePicker from '../../../_components/MuiTimePicker'

interface IState {
    description: string,
    due_date: Date | null
    due_time: Date | null
}

const initialState = {
    description: '',
    due_date: new Date(),
    due_time: null
}

interface IProps {
    open: boolean,
    onClose: (refresh: boolean) => void
    ticketId?: string | null
    id?: number | null
    defaultState?: IState
}

const TodoListModal = (props: IProps) => {
    const [state, setState] = useState<IState>(initialState)
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    const handleChangeDate = (date: Date | null, name: string) => {
        setState(prev => ({
            ...prev,
            [name]: date
        }))
    }

    const handleChangedescription = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(prev => ({
            ...prev,
            description: e.target.value
        }))
    }

    const validateDateTime = (date: Date | null, time: Date | null) => {
        let errors: string[] = []
        if (date !== null) {
            if (!isValid(date)) {
                errors.push('Date')
            }
        }

        if (time !== null) {
            if (!isValid(time)) {
                errors.push('Time')
            }
        }

        if (errors.length) {
            renderErrorButton(`${errors.join(' & ')} Tidak Valid`, 'Error Data')
        }

        return !errors.length
    }

    const handleSubmit = () => {
        const isValid = validateDateTime(state.due_date, state.due_time)
        if (isValid) {
            setIsLoading(true)
            let axios;

            if (isEdit) {
                axios = DefaultAxios.patch(`${process.env.REACT_APP_API_URL}/ticket-todolist/${props.id}`, {
                    ...state,
                    due_date: state.due_date ? format(new Date(state.due_date as Date), 'yyyy-MM-dd') : null,
                    due_time: state.due_time ? format(new Date(state.due_time as Date), 'HH:mm') : null,
                })
            } else {
                axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/ticket-todolist`, {
                    ...state,
                    due_date: state.due_date ? format(new Date(state.due_date), 'yyyy-MM-dd') : null,
                    due_time: state.due_time ? format(new Date(state.due_time), 'HH:mm') : null,
                    ticket_id: props.ticketId,
                    ...(props.id ? {
                        id: props.id
                    } : {})
                })
            }
            axios
                .then(res => res.data)
                .then(res => res && [renderToastSuccess(isEdit ? 'Todo Changed' : 'Todo Added'), props.onClose(true)])
                .catch(generalErrorHandler)
                .finally(() => setIsLoading(false))
        }
    }

    useEffect(() => {
        if (props.open && props.id) {
            if (props.defaultState) setState({
                ...props.defaultState,
                due_date: new Date(`${props.defaultState.due_date}`),
                due_time: props.defaultState.due_time ? new Date(`${props.defaultState.due_date}T${props.defaultState.due_time}`) : null
            })
            setIsEdit(true)
        }

        if (!props.open && !props.id) {
            setState(initialState)
            setIsEdit(false)
        }
    }, [props.open, props.id, props.defaultState])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
        >
            <DialogTitle>
                {
                    props.id ? 'Edit To-Do List' : 'Create To-Do List'
                }
            </DialogTitle>
            <DialogContent>
                <LoadingScreen open={isLoading} />
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <MuiDatePicker
                            label='Due Date'
                            value={state.due_date as Date}
                            clearable
                            fullWidth
                            onChange={(date: Date | null) => handleChangeDate(date, 'due_date')}
                            onClear={() => handleChangeDate(null, 'due_date')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MuiTimePicker
                            label='Due Time'
                            value={state.due_time as Date}
                            clearable
                            fullWidth
                            onChange={(date: Date | null) => handleChangeDate(date, 'due_time')}
                            onClear={() => handleChangeDate(null, 'due_time')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextArea
                            name='description'
                            label='Deskripsi'
                            onChange={handleChangedescription}
                            value={state.description}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            onClick={handleSubmit}
                            sx={{ float: 'right' }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default TodoListModal
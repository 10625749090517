import React, { useContext, useEffect, useState } from 'react'

/* 
* Components
*/
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';
import LandlordTable from './components/LandlordTable';

/* 
* Interfaces
*/
import { IState, initialState } from './PICFormMain';

/* 
* Utils
*/
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderSuccessButton } from '../../_utils/Helper';
import { Button, Grid } from '@mui/material';
import { PermissionContext } from '../../_contexts/PermissionContext';
import GeneralAutocompleteModal from '../../_components/_modal/GeneralAutocompleteModal';

interface IProps {
    open: boolean,
    onClose: (refresh: boolean) => void
    picId: string
}

function PICModalDetail(props: IProps) {
    const [isLoading, setIsLoading] = useState(false)
    const [picLandlord, setPICLandlord] = useState<IState>(initialState)
    const [landLords, setLandlords] = useState([])
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
    const permissions = useContext(PermissionContext)

    useEffect(() => {
        if (props.open && props.picId) {
            loadData()
        }
        // eslint-disable-next-line
    }, [props, props.open, props.picId])

    const loadData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/pic-landlord/${props.picId}`)
            .then(res => res.data)
            .then(res => res && [setPICLandlord(res), setLandlords(res.landlords)])
            .catch(err => {
                generalErrorHandler(err)
                props.onClose(false)
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <ModalDetail
                open={props.open}
                isLoading={isLoading}
                onCloseModal={() => props.onClose(false)}
                title={'PIC Landlord Detail'}
                showEditButton={true}
                editButtonLink={`/pic-landlord/edit/${props.picId}`}
            >
                <>
                    <ModalSection
                        title="Informasi Dasar"
                        xs={12}
                        md={12}
                        content={[
                            ['Name', picLandlord.name],
                            ['Phone', picLandlord.phone],
                            ['Assigned PIC', picLandlord.assigned_pic_label],
                            ['Notes', picLandlord.notes],
                        ]}
                    />
                    {
                        permissions['pic-landlord.assign-pic-to-unit'] ?
                            <>
                                <Grid item xs={12} display='flex' flexDirection='row' alignItems='center' justifyContent='flex-end'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={() => {
                                            setIsAssignModalOpen(true)
                                        }}
                                    >
                                        Assign PIC Listing to Units
                                    </Button>
                                </Grid>
                                <GeneralAutocompleteModal
                                    open={isAssignModalOpen}
                                    label='PIC'
                                    paramName='pic_listing_id'
                                    title='Assign PIC Listing to Units'
                                    extraParams={{
                                        id: props.picId
                                    }}
                                    url={`${process.env.REACT_APP_API_URL}/assign-pic-listing/assign-to-pic-landlord`}
                                    autocompleteUrl={`${process.env.REACT_APP_API_URL}/autocomplete/user/active-listing-acquisition`}
                                    onClose={() => {
                                        setIsAssignModalOpen(false)
                                    }}
                                    onSuccess={() => {
                                        setIsAssignModalOpen(false)
                                        renderSuccessButton('Berhasil assign pic listing to units')
                                        loadData()
                                    }}
                                />
                            </>
                            : null
                    }
                    <ModalSection
                        title="Landlord"
                        xs={12}
                        md={12}
                        content={[]}
                        additionalContent={<LandlordTable data={landLords} />}
                    />
                </>
            </ModalDetail>
        </>
    )
}

export default PICModalDetail
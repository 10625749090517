import React, { useState, useRef } from 'react'

import { Theme, Typography } from '@mui/material';
import { Box } from '@mui/material';

// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ImageSlide from './ImageSlide';
import { generateStyle } from '../../../_utils/DefaultStyle';
//import NavDots from './NavDots';

const useStyles = generateStyle((theme: Theme) => ({
  imageContainer: {
    display: "flex",
    flexFlow: 'row',
    // width: '100%',
    height: 198,
    overflow: 'scroll',
    scrollbarWidth: 'none',
    scrollSnapType: 'x mandatory',
    scrollSnapPointsX: 'repeat(100%)',
    // width: 'fit-content',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
      background: 'transparent'
    },
  },
  imageContainerSmall: {
    display: "flex",
    flexFlow: 'row',
    // width: '100%',
    height: 150,
    overflow: 'scroll',
    scrollbarWidth: 'none',
    scrollSnapType: 'x mandatory',
    scrollSnapPointsX: 'repeat(100%)',
    // width: 'fit-content',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
      background: 'transparent'
    },
  },
  image: {
    height: '100%',
    width: '100%',
    flexShrink: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    position: 'absolute',
    marginTop: -125
  },
  button: {
    margin: theme.spacing(2),
    padding: theme.spacing(1 / 2),
    borderRadius: '50%',
    backgroundColor: 'white',
    opacity: 0.7,
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  navDotContainer: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    width: '100%',
    marginTop: -22
  },
  imgIndex: {
    color: 'white',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: -22,
  },
  placeholderContainer: {
    width: '100%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .placeholder': {
      width: '50%',
      filter: 'brightness(0) saturate(100%) invert(95%) sepia(5%) saturate(0%) hue-rotate(29deg) brightness(105%) contrast(107%)'
    }
  }
}), "ImageSlider"
);

interface Props {
  img: any,
  index?: boolean
  openPreview?: () => void
  preview?: boolean
  scale?: number
  resetZoom?: Function
  isSmall?: boolean
}

const ImageSlider = (props: Props) => {
  const { Root, classes } = useStyles()
  // const [x, setX] = useState(0)
  const [index, setIndex] = useState(1)
  const imageContainer = useRef<HTMLDivElement>(null)

  // const change = () => {
  //   if(x === (props.img.length-1)*-100){
  //     setX(0)
  //     setIndex(1)
  //   }else{
  //     setX(x-100)
  //     setIndex(index+1)
  //   }
  // }
  // const changeP = () => {
  //   if(x === 0){
  //     setX((props.img.length-1)*-100)
  //     setIndex(props.img.length)
  //   }else{
  //     setX(x+100)
  //     setIndex(index-1)
  //   }
  // }

  const handleScroll = (element: React.UIEvent<HTMLDivElement>) => {
    if (element.target) {
      const div = element.target as HTMLDivElement
      setIndex(Math.round(div.scrollLeft / div.clientWidth) + 1)
    }
  }

  // useEffect(() => {
  //   if (props.resetZoom) props.resetZoom()
  //   // eslint-disable-next-line
  // }, [index])

  const renderImage = () => {
    if (props.img.length) {
      return (props.img.map((val: string, idx: number) => {
        return (<ImageSlide url={val} key={idx} idx={idx} currentIdx={index} />)
      }))
    } else {
      return (
        <div className={classes.placeholderContainer}>
          <img src="https://pintu-cdn.sgp1.cdn.digitaloceanspaces.com/assets/img/pintu-properti-placeholder.png" alt="pintu logo" className="placeholder" />
        </div>)
    }
  }

  const previewStyle = () => {
    let style = {}
    if (props.preview) {
      style = {
        position: 'fixed',
        bottom: 0,
        justifyContent: 'center'
      }
    }
    return style
  }

  const previewStyleText = () => {
    let style = {}
    if (props.preview) {
      style = {
        margin: 0,
        marginBottom: '1rem',
        padding: 0
      }
    }
    return style
  }

  const imageContainerPreviewStyle = () => {
    let style = {}
    if (props.preview) {
      style = {
        height: 'fit-content',
      }
    }
    return style
  }

  const scaling = {
    transform: `scale(${props.scale || 1})`
  }

  return (
    <Root>
      <div style={{ position: 'relative' }}>
        {
          props.isSmall ?
            <div onScroll={handleScroll} className={classes.imageContainerSmall} ref={imageContainer} onClick={() => (props.openPreview && props.img.length) ? props.openPreview() : null} style={{ ...imageContainerPreviewStyle(), ...scaling }}>
              {renderImage()}
            </div>
            : <div onScroll={handleScroll} className={classes.imageContainer} ref={imageContainer} onClick={() => (props.openPreview && props.img.length) ? props.openPreview() : null} style={{ ...imageContainerPreviewStyle(), ...scaling }}>
              {renderImage()}
            </div>
        }
        {
          props.img.length ?
            <Box className={classes.imgIndex} style={{ ...previewStyle() }}>
              <Typography variant='caption' style={{ backgroundColor: 'rgba(14,14,14,0.8)', marginRight: 10, padding: '0 8px', borderRadius: 4, ...previewStyleText() }}>{`${index} / ${props.img.length || 1}`}</Typography>
            </Box>
            : null
        }
      </div>
    </Root>
  )
}

export default ImageSlider;
export interface IOption {
    key: string;
    label: string;
}

export interface ITemplate {
    [k: string]: {
        template: string;
        extraParam1: boolean;
        extraParam2: boolean;
        extraParam3: boolean;
        extraParam4: boolean;
        extraParam5: boolean;
    }
}

export const options: IOption[][] = [
    // Owner
    [
        {
            key: 'tenantproblem_in_unit_to_ownergeneral',
            label: 'Tenant Problem to Owner General',
        },
        {
            key: 'tenantproblem_in_unit_to_ownerspecific',
            label: 'Tenant Problem to Owner Specific',
        },
        {
            key: 'owner_renewal',
            label: 'Owner Renewal',
        },
        {
            key: 'owner_checkout_1',
            label: 'Owner Checkout 1',
        },
        {
            key: 'owner_checkout_2',
            label: 'Owner Checkout 2',
        },
        {
            key: 'followupproblem_to_owner_satu',
            label: 'Follow Up Problem to Owner',
        },
        {
            key: 'problem_update_to_owner',
            label: 'Problem Update to Owner',
        },
        {
            key: 'notify_owner_sixtydays',
            label: 'Notify Owner 60 days',
        },
        {
            key: 'follow_up_chat_timeout',
            label: 'Follow Up Chat Timeout',
        },
        {
            key: 'update_renewal_owner',
            label: 'Update Renewal Owner',
        },
        {
            key: 'status_renewal_owner',
            label: 'Status Renewal Owner',
        },
        {
            key: 'chat_general_owner',
            label: 'Chat General Owner',
        },
        {
            key: 'chat_expired_owner',
            label: 'Chat Expired Owner',
        },
    ],
    // Tenant
    [
        {
            key: 'renewal_tenant_60days',
            label: 'Renewal Tenant 60 days',
        },
        {
            key: 'renewal_tenant_6weeks',
            label: 'Renewal Tenant 6 weeks',
        },
        {
            key: 'renewal_tenant_4weeks',
            label: 'Renewal Tenant 4 weeks',
        },
        {
            key: 'renewal_tenant_3weeks',
            label: 'Renewal Tenant 3 weeks',
        },
        {
            key: 'checkout_tenant_1week_1',
            label: 'Checkout Tenant 1 week (1)',
        },
        {
            key: 'checkout_tenant_1week_2',
            label: 'Checkout Tenant 1 week (2)',
        },
        {
            key: 'monthly_charge_to_tenant',
            label: 'Monthly Charge to Tenant',
        },
        {
            key: 'price_update_to_tenant',
            label: 'Price Update to Tenant',
        },
        {
            key: 'problem_update_to_tenant',
            label: 'Problem Update to Tenant',
        },
        {
            key: 'tenant_checkin',
            label: 'Tenant Checkin',
        },
        {
            key: 'ownerproblem_in_unit_to_tenant_satu',
            label: 'Owner Problem Unit to Tenant',
        },
        {
            key: 'deposit_question_inquiry',
            label: 'Deposit Question Inquiry',
        },
        {
            key: 'checkout_deposit_notification',
            label: 'Checkout Deposit Notification',
        },
        {
            key: 'final_monthly_utilities_reminder',
            label: 'Final Monthly Utilities Reminder',
        },
        {
            key: 'second_monthly_utilities_reminder',
            label: 'Second Monthly Utilities Reminder',
        },
        {
            key: 'monthly_utilities_reminder_renewal',
            label: 'Monthly Utilities Reminder Renewal',
        },
        {
            key: 'monthly_utilities_reminder',
            label: 'Monthly Utilities Reminder',
        },
        {
            key: 'complaint_form_information_2',
            label: 'Compaint Form Information',
        },
        {
            key: 'confirm_payment_3',
            label: 'Confirm Payment 3',
        },
        {
            key: 'follow_up_chat_timeout',
            label: 'Follow Up Chat Timeout',

        },
        {
            key: 'monthly_bill',
            label: 'Monthly Bill',

        },
        {
            key: 'confirm_checkout1',
            label: 'Confirm Checkout (1)',

        },
        {
            key: 'confirm_checkout2',
            label: 'Confirm Checkout (2)',

        },
        {
            key: 'chat_general_tenant',
            label: 'Chat General Tenant',

        },
        {
            key: 'chat_expired_tenant',
            label: 'Chat Expired Tenant',

        },
        {
            key: 'contract_expired_3weeks',
            label: 'Contract Expired 3 Weeks',

        },
        {
            key: 'contract_expired_4weeks',
            label: 'Contract Expired 4 Weeks',

        },
        {
            key: 'contract_expired_6weeks',
            label: 'Contract Expired 6 Weeks',

        },
        {
            key: 'contract_expired_8weeks',
            label: 'Contract Expired 8 Weeks',

        }
    ]
];

export const templates: ITemplate = {
    // Owner
    'tenantproblem_in_unit_to_ownergeneral': {
        template: `
                Hi Pak/Bu {OWNER_NAME},<br />
                Saya Customer Care dari tim Jendela360<br />
                ingin menginformasikan bahwa tenant mengalami kendala di unit {UNIT_NAME}.<br />
                boleh minta waktunya sebentar?
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'tenantproblem_in_unit_to_ownerspecific': {
        template: `
                Hi Pak/Bu {OWNER_NAME}, <br />
                Saya Customer Care dari tim Jendela360 <br />
                ingin menginformasikan bahwa tenant mengalami kendala {EXTRA_PARAM} di unit {UNIT_NAME}.<br />
                boleh minta waktunya sebentar?
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'owner_renewal': {
        template: `
                Hi Pak/Bu {OWNER_NAME}<br /><br />

                Ini Customer Care dari Jendela360 yang menangani perpanjangan dan checkout unit Pak/Bu.<br /><br />

                mau info aja, untuk tenant di {EMPTY}{UNIT_NAME} jadinya ada rencana perpanjang sewa selama {EXTRA_PARAM} bulan ya Pak/Bu. untuk harga masih sama kan Pak/Bu dgn kemarin? 😊
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'owner_checkout_1': {
        template: `
                Hi Pak/Bu,<br /><br />

                Saya Customer Care dari Jendela360 yang menangani perpanjangan dan checkout unit.<br /><br />

                mau info aja, untuk tenant di apartemen {APARTMENT_NAME} unit {UNIT_NAME} statusnya {EXTRA_PARAM} ya. <br /><br />

                namun tidak perlu khawatir karena kita akan bantu utk proses checkout nya agar unit bisa segera dipasarkan lagi 🙏
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'owner_checkout_2': {
        template: `
                Hi Pak/Bu,<br /><br />

                ingin menginformasikan, untuk tenant di apartemen Pak/Bu, statusnya tidak lanjut perpanjang ya. <br /><br />

                namun tidak perlu khawatir karena kita akan bantu utk proses checkout nya agar unit bisa segera dipasarkan lagi 🙏
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'followupproblem_to_owner_satu': {
        template: `
                Hi Pak/Bu {OWNER_NAME}, <br />
                Terkait yang saya sampaikan sebelumnya, bagaimana ya Pak/Bu? <br />
                Karena pihak tenant sudah menanyakan kembali kepada kami. Mohon informasinya ya Pak/Bu
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'problem_update_to_owner': {
        template: `
                Hi Pak/Bu {OWNER_NAME}<br /><br />

                Untuk kendala di unit, saya sudah dapat kabar dari tenant Pak/Bu.<br />
                Bisa minta waktunya sebentar?
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'notify_owner_sixtydays': {
        template: `
                Halo Pak/Bu<br /><br />

                Saya Customer Care dari Jendela360 yang menangani perpanjangan dan checkout.<br /><br />

                Mau info aja, masa sewa tenant di unit {UNIT_NAME} akan selesai pada {END_DATE}<br /><br />

                Untuk perpanjangan kami sudah tanyakan ke tenantnya, tapi untuk saat ini belum ada kabar akan lanjut sewa atau tidak. Nanti begitu ada info langsung saya kabari ya.
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'update_renewal_owner': {
        template: `
                Hi Pak/Bu {OWNER_NAME}<br /><br /><br />


                Tenant sudah konfirmasi untuk {EXTRA_PARAM} sewa unit {EMPTY} {UNIT_NAME}. PIC kami akan segera menghubungi Bapak/Ibu, atau bisa hubungi langsung PIC kami di {PIC_PHONE}. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'status_renewal_owner': {
        template: `
                [AUTO NOTIFICATION]<br /><br />

                Hi Pak/Bu {OWNER_NAME}<br /><br /><br />


                Terkait unit {EMPTY} {UNIT_NAME}, kami belum mendapatkan konfirmasi dari tenant mengenai perpanjang atau tidaknya. Kami akan menghubungi Bapak/Ibu jika sudah ada konfirmasi dari tenant<br /><br /><br />


                Untuk pertanyaan lebih lanjut, hubungi PIC perpanjangan unit Bapak/Ibu di {PIC_PHONE}. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'chat_general_owner': {
        template: `
                Hi Pak/Bu {OWNER_NAME}<br /><br /><br />


                Boleh minta waktunya sebentar terkait unit {EMPTY} {UNIT_NAME} mengenai {EXTRA_PARAM}?
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'chat_expired_owner': {
        template: `
                Hi Pak/Bu {OWNER_NAME}, karena keterbatasan sistem kami dimana chat akan non-aktif otomatis jika melebihi 24 jam, kami ingin melanjutkan pembicaraan terakhir terkait {EXTRA_PARAM}, boleh minta waktunya sebentar? Terima kasih
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    // General
    'follow_up_chat_timeout': {
        template: `
                Hi kak {TENANT_NAME/OWNER_NAME}, karena keterbatasan sistem kami dimana chat dari kakak akan non-aktif otomatis jika melebihi 24 jam, kami ingin menanyakan kembali terkait {EXTRA_PARAM}, boleh minta waktunya sebentar? Terima kasih
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    // Tenant
    'renewal_tenant_60days': {
        template: `
                Hi Kak {TENANT_NAME},<br /><br />

                Ini Customer Care dari Jendela360 yang menangani perpanjangan dan checkout unit apartemen kakak.<br /><br />

                Bagaimana kak ada kendala atau masalah selama sewa di {EMPTY} {UNIT_NAME} kak?<br /><br />

                oiya untuk informasi saja kak, masa sewa kakak akan berakhir di {END_DATE}, Apakah ada rencana perpanjangan kak?
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'renewal_tenant_6weeks': {
        template: `
                Hi Kak {TENANT_NAME},<br /><br />

                Friendly reminder, untuk unit yang kakak tinggali sekarang sewanya akan berakhir {END_DATE}. Rencananya kakak akan perpanjang atau tidak ya kak?<br /><br />

                Sebagai informasi saja, untuk 30 hari sebelum sewa berakhir unitnya sudah mulai kita pasarkan lagi ya kak sesuai permintaan owner. Jadi tolong dibantu ya kak untuk info jadi perpanjang atau tidaknya, soalnya sayang jika infonya terlambat dan unit sudah tersewa oleh orang lain, nanti kakak harus repot2 pindahan lagi kak
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'renewal_tenant_4weeks': {
        template: `
                Hi Kak {TENANT_NAME},<br /><br />

                Gimana kak, mau follow-up untuk perpanjang sewa ni, kira-kira jadi mau perpanjang atau tidak ya kak?<br /><br />

                Friendly reminder saja kak, untuk unit kakak saat ini sudah mulai kita pasarkan ulang ya kak. semisal kakak mau perpanjang tolong kabari segera ya kak, soalnya sayang banget kalo infonya terlambat dan unit sudah tersewa oleh orang lain, nanti kakak harus repot2 pindahan lagi kak<br /><br />

                Kami tunggu konfirmasinya ya kak :)
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'renewal_tenant_3weeks': {
        template: `
                Hi Kak {TENANT_NAME},<br /><br />

                Apa kabar? kak cuma mau konfirm aja ni untuk unit yg kakak sewa jadinya mau diperpanjang atau tidak kak?<br /><br />

                Kebetulan unit yang kakak tinggali sudah ada yang berminat juga ni kak, jadi kalau kakak mau perpanjang tolong segera dikabari ya. Takutnya kalau keduluan orang lain, nanti kakak jg yg repot kalo harus beres2 untuk pindahan ni.<br /><br />

                Terima kasih kak
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'checkout_tenant_1week_1': {
        template: `
                Hi Kak {TENANT_NAME},<br />
                mau mengingatkan kembali untuk jadwal checkout nanti di tanggal {DATE} hari {DAY} jam {TIME} ya kak.<br />
                mohon disiapkan untuk hal yang sudah di informasikan sebelumnya.
                checkout akan di bantu oleh tim kami atas nama {PIC_NAME} <br />
                Jika ada perubahan waktu, mohon informasikan ke kami H-2 sebelum jadwal checkout.<br />
                Terimakasih.
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'checkout_tenant_1week_2': {
        template: `
                Hi Kak {TENANT_NAME},<br /><br />

                Ingin mengingatkan kembali untuk jadwal checkout tidak ada perubahan waktu kan ya?<br /><br />

                Jika ada perubahan waktu, mohon informasikan ke kami H-2 sebelum jadwal checkout.<br />
                mohon disiapkan untuk hal yang sudah di informasikan sebelumnya.<br /><br />

                Terimakasih.
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'monthly_charge_to_tenant': {
        template: `
                Hi Kak {TENANT_NAME},<br />
                Sekedar informasi untuk tagihan bulanan biasanya sudah di terbitkan di awal bulan ini kak.<br />
                jika kakak belum menerima tagihan bulanan tersebut, mohon bisa di cek kembali ke building management langsung ya kak :)<br /><br />

                Terima kasih
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'price_update_to_tenant': {
        template: `
                Hi Kak {TENANT_NAME}<br /><br />

                Saya sudah dapat informasi harga terbaru dari owner yakni {EXTRA_PARAM} juta untuk {EXTRA_PARAM2} bulan.<br /><br />

                Bagaimana kak apakah jadi lanjut perpanjangan sewa?
            `,
        extraParam1: true,
        extraParam2: true,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'problem_update_to_tenant': {
        template: `
                Hi Kak {TENANT_NAME}<br /><br />

                Untuk kendala di unit, saya sudah dapat kabar dari owner kak.<br />
                bisa minta waktunya sebentar kak?
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'tenant_checkin': {
        template: `
                Hi Kak {TENANT_NAME}<br />
                Ini adalah layanan Customer Care Jendela360.Terima kasih telah menggunakan jasa Jendela360. <br />
                Berikut adalah informasi Kakak<br /><br />

                Nama: {UNIT_NAME}<br />
                Apartemen: {APARTMENT_NAME}<br />
                Tower/Lt/No unit: {TOWER}/{FLOOR}/{NO_UNIT}<br />
                Masa sewa: {START_DATE} – {END_DATE}<br /><br />

                Apabila ada kendala di unit, bisa langsung menghubungi saya ya Kak. <br />
                Terima kasih
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'ownerproblem_in_unit_to_tenant_satu': {
        template: `
                Hi Kak {TENANT_NAME}<br />
                Saya dapat kabar dari owner terkait kendala di {EMPTY} {UNIT_NAME} Kak. Boleh minta waktunya sebentar?
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'deposit_question_inquiry': {
        template: `
                Hi kak {TENANT_NAME}, kami baru saja selesai melakukan perhitungan terkait tagihan kakak.
                Boleh minta waktunya sebentar kak? Terima kasih. 
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'checkout_deposit_notification': {
        template: `
                Terima kasih atas kepercayaan kakak telah menggunakan jasa Jendela360.
                Saya dari divisi aftersales ingin menginfokan terkait pengembalian deposit sewa akan kami proses dalam 30 hari.
                Saat ini tim kami sedang melakukan perhitungan tagihan terakhir unit kakak dan juga melakukan pengecekan kondisi unit.
                Deposit kakak akan kembali secara utuh jika tidak terdapat tunggakan tagihan dan kerusakan pada unit.
                Sekian yang dapat kami infokan untuk saat ini, terima kasih.
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'final_monthly_utilities_reminder': {
        template: `
                Hi kak {TENANT_NAME}, saya ingin mengkonfirmasi terkait tunggakan tagihan apartemen kakak.
                Berhubung sudah mendekati jatuh tempo untuk pelunasan tagihannya kak, apakah sudah clear semua?
                Jika sudah mohon untuk mengirimkan bukti pelunasannya agar dapat kami follow up. Terima kasih.
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'second_monthly_utilities_reminder': {
        template: `
                Hi kak {TENANT_NAME}, menyambung pembicaraan terakhir tekait tunggakan tagihan {EXTRA_PARAM}.
                Saya ingin mengkonfirmasi kembali apakah kakak sudah melakukan pembayaran atau belum?
                Jika sudah mohon untuk mengirimkan bukti bayarnya ke kami ya kak, agar dapat kami follow up ke pengelola.
                Terima kasih.
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'monthly_utilities_reminder_renewal': {
        template: `
                Hi kak {TENANT_NAME}, Saya mendapat informasi dari {EXTRA_PARAM}, bahwa terdapat tagihan {EXTRA_PARAM2} untuk unit apartmen kakak.
                Mohon untuk segera di lunasi ya kak. Jika kakak merasa sudah membayar tagihan tersebut bisa tolong untuk kirimkan bukti bayarnya kepada kami agar dapat kami follow up ke {EXTRA_PARAM}.
                Terima kasih.
            `,
        extraParam1: true,
        extraParam2: true,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'monthly_utilities_reminder': {
        template: `
                Hi kak {TENANT_NAME} saya dari divisi aftersales Jendela360.
                Saya mendapat informasi dari {EXTRA_PARAM}, bahwa terdapat tagihan {EXTRA_PARAM2} untuk unit apartmen kakak.
                Mohon untuk segera di lunasi ya kak. Jika kakak merasa sudah membayar tagihan tersebut bisa tolong untuk kirimkan bukti bayarnya kepada kami agar dapat kami follow up ke {EXTRA_PARAM}. Terima kasih.
            `,
        extraParam1: true,
        extraParam2: true,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'complaint_form_information_2': {
        template: `
                Hi kak {TENANT_NAME}, untuk informasi complain/kendala dapat dikirimkan melalui Whatsapp ke Customer Care kami di nomor 081250001360, dengan format sebagai berikut:<br />
                <strong>Nama pelanggan</strong>:<br />
                <strong>Kode pelanggan/unit apartmen</strong>:<br />
                <strong>Kendala</strong>:<br />
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'confirm_payment_3': {
        template: `
                Hi kak {TENANT_NAME}, saya ingin menanyakan terkait pembayaran perpanjangan sewa kakak, apakah ada kendala sehingga kakak belum dapat memproses pembayaran sampai hari ini? Terima kasih
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'monthly_bill': {
        template: `
                [AUTO NOTIFICATION]<br /><br /><br />


                Hi Kak {TENANT_NAME},<br /><br /><br />


                Jangan lupa untuk melakukan pembayaran tagihan bulanan ya Kak. Karena jika pembayaran dilakukan setelah jatuh tempo, biasanya akan ada denda untuk keterlambatan pembayaran. Untuk detail pembayarannya, bisa langsung ditanyakan ke pihak Pengelola Apartemen ya Kak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'confirm_checkout1': {
        template: `
                Hi Kak {TENANT_NAME}<br /><br /><br />


                Apakah kakak jadi melakukan check-out unit {EMPTY} {UNIT_NAME} pada tanggal {DATE}?<br /><br /><br />


                Mohon konfirmasi ke PIC kami di {PIC_PHONE} ya kak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'confirm_checkout2': {
        template: `
                [AUTO REMINDER]<br /><br />

                Hi Kak {TENANT_NAME}<br /><br /><br />


                Sekedar konfirmasi, besok kakak jadi melakukan check-out pada pukul {TIME}?<br /><br /><br />


                Mohon konfirmasi ke PIC kami di {PIC_PHONE} ya kak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'chat_general_tenant': {
        template: `
                Hi Pak/Bu {TENANT_NAME}<br /><br /><br />


                Boleh minta waktunya sebentar terkait unit {EMPTY} {UNIT_NAME} mengenai {EXTRA_PARAM}?
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'chat_expired_tenant': {
        template: `
                Hi Pak/Bu {TENANT_NAME}, karena keterbatasan sistem kami dimana chat akan non-aktif otomatis jika melebihi 24 jam, kami ingin melanjutkan pembicaraan terakhir terkait {EXTRA_PARAM}, boleh minta waktunya sebentar? Terima kasih
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'contract_expired_3weeks': {
        template: `
                [AUTO NOTIFICATION]<br /><br />

                Hi Kak {TENANT_NAME},<br /><br />

                Apa kabar? Mau konfirmasi aja nih untuk unit yg kakak sewa jadinya mau diperpanjang atau tidak kak? Kebetulan unit yang kakak tinggali sudah ada yang berminat juga ni kak, jadi kalau kakak mau perpanjang tolong segera dikabari ya.<br /><br /><br />


                Untuk konfirmasi perpanjang atau tidaknya, harap hubungi tim perpanjangan kami di {PIC_PHONE} ya Kak. Atau kakak bisa tunggu tim perpanjangan kami untuk menghubungi Kakak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'contract_expired_4weeks': {
        template: `
                [AUTO NOTIFICATION]<br /><br />

                Hi Kak {TENANT_NAME},<br /><br />

                Gimana kak, kira-kira jadi mau perpanjang atau tidak ya kak? Friendly reminder saja kak, untuk unit kakak saat ini sudah mulai kita pasarkan ulang ya kak. semisal kakak mau perpanjang tolong kabari segera ya kak, soalnya sayang banget kalo infonya terlambat dan unit sudah tersewa oleh orang lain, nanti kakak harus repot2 pindahan lagi kak<br /><br /><br />


                Untuk konfirmasi perpanjang atau tidaknya, harap hubungi tim perpanjangan kami di {PIC_PHONE} ya Kak. Atau kakak bisa tunggu tim perpanjangan kami untuk menghubungi Kakak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360"
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'contract_expired_6weeks': {
        template: `
                [AUTO NOTIFICATION]<br /><br />

                Hi Kak {TENANT_NAME},<br /><br />

                Friendly reminder, untuk unit yang kakak tinggali sekarang sewanya akan berakhir {EXPIRED_DATE}. Sebagai informasi saja, 30 hari sebelum sewa berakhir unitnya sudah mulai kita pasarkan lagi ya kak sesuai permintaan owner. <br /><br /><br />


                Untuk konfirmasi perpanjang atau tidaknya, harap hubungi tim perpanjangan kami di {PIC_PHONE} ya Kak. Atau kakak bisa tunggu tim perpanjangan kami untuk menghubungi Kakak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'contract_expired_8weeks': {
        template: `
                Hi Kak {TENANT_NAME}, mau kasih kabar bahwa masa sewa kakak di apartemen {APARTMENT_NAME} akan berakhir per tanggal {DATE}.<br /><br /><br />


                Untuk konfirmasi perpanjang atau tidaknya, harap hubungi tim perpanjangan kami di {PIC_PHONE} ya Kak. Atau kakak bisa tunggu tim perpanjangan kami untuk menghubungi Kakak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    }
}

export const alternativeTemplates: ITemplate = {
    // Owner
    'tenantproblem_in_unit_to_ownergeneral': {
        template: `
                Hi Pak/Bu {OWNER_NAME},<br />
                Saya Customer Care dari tim Jendela360<br />
                ingin menginformasikan bahwa tenant mengalami kendala di unit {EXTRA_PARAM}.<br />
                boleh minta waktunya sebentar?
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'tenantproblem_in_unit_to_ownerspecific': {
        template: `
                Hi Pak/Bu {OWNER_NAME}, <br />
                Saya Customer Care dari tim Jendela360 <br />
                ingin menginformasikan bahwa tenant mengalami kendala {EXTRA_PARAM} di unit {EXTRA_PARAM2}.<br />
                boleh minta waktunya sebentar?
            `,
        extraParam1: true,
        extraParam2: true,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'owner_renewal': {
        template: `
                Hi Pak/Bu {OWNER_NAME}<br /><br />

                Ini Customer Care dari Jendela360 yang menangani perpanjangan dan checkout unit Pak/Bu.<br /><br />

                mau info aja, untuk tenant di {EMPTY}{EXTRA_PARAM} jadinya ada rencana perpanjang sewa selama {EXTRA_PARAM2} bulan ya Pak/Bu. untuk harga masih sama kan Pak/Bu dgn kemarin? 😊
            `,
        extraParam1: true,
        extraParam2: true,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'owner_checkout_1': {
        template: `
                Hi Pak/Bu,<br /><br />

                Saya Customer Care dari Jendela360 yang menangani perpanjangan dan checkout unit.<br /><br />

                mau info aja, untuk tenant di apartemen {EMPTY} unit {EXTRA_PARAM} statusnya {EXTRA_PARAM2} ya. <br /><br />

                namun tidak perlu khawatir karena kita akan bantu utk proses checkout nya agar unit bisa segera dipasarkan lagi 🙏
            `,
        extraParam1: true,
        extraParam2: true,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'owner_checkout_2': {
        template: `
                Hi Pak/Bu,<br /><br />

                ingin menginformasikan, untuk tenant di apartemen Pak/Bu, statusnya tidak lanjut perpanjang ya. <br /><br />

                namun tidak perlu khawatir karena kita akan bantu utk proses checkout nya agar unit bisa segera dipasarkan lagi 🙏
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'followupproblem_to_owner_satu': {
        template: `
                Hi Pak/Bu {OWNER_NAME}, <br />
                Terkait yang saya sampaikan sebelumnya, bagaimana ya Pak/Bu? <br />
                Karena pihak tenant sudah menanyakan kembali kepada kami. Mohon informasinya ya Pak/Bu
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'problem_update_to_owner': {
        template: `
                Hi Pak/Bu {OWNER_NAME}<br /><br />

                Untuk kendala di unit, saya sudah dapat kabar dari tenant Pak/Bu.<br />
                Bisa minta waktunya sebentar?
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'notify_owner_sixtydays': {
        template: `
                Halo Pak/Bu<br /><br />

                Saya Customer Care dari Jendela360 yang menangani perpanjangan dan checkout.<br /><br />

                Mau info aja, masa sewa tenant di unit {EXTRA_PARAM} akan selesai pada {END_DATE}<br /><br />

                Untuk perpanjangan kami sudah tanyakan ke tenantnya, tapi untuk saat ini belum ada kabar akan lanjut sewa atau tidak. Nanti begitu ada info langsung saya kabari ya.
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'update_renewal_owner': {
        template: `
                Hi Pak/Bu {OWNER_NAME}<br /><br /><br />


                Tenant sudah konfirmasi untuk {EXTRA_PARAM} sewa unit {EMPTY} {EXTRA_PARAM2}. PIC kami akan segera menghubungi Bapak/Ibu, atau bisa hubungi langsung PIC kami di {PIC_PHONE}. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: true,
        extraParam2: true,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'status_renewal_owner': {
        template: `
                [AUTO NOTIFICATION]<br /><br />

                Hi Pak/Bu {OWNER_NAME}<br /><br /><br />


                Terkait unit {EMPTY} {EXTRA_PARAM}, kami belum mendapatkan konfirmasi dari tenant mengenai perpanjang atau tidaknya. Kami akan menghubungi Bapak/Ibu jika sudah ada konfirmasi dari tenant<br /><br /><br />


                Untuk pertanyaan lebih lanjut, hubungi PIC perpanjangan unit Bapak/Ibu di {PIC_PHONE}. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'chat_general_owner': {
        template: `
                Hi Pak/Bu {OWNER_NAME}<br /><br /><br />


                Boleh minta waktunya sebentar terkait unit {EMPTY} {EXTRA_PARAM} mengenai {EXTRA_PARAM2}?
            `,
        extraParam1: true,
        extraParam2: true,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'chat_expired_owner': {
        template: `
                Hi Pak/Bu {OWNER_NAME}, karena keterbatasan sistem kami dimana chat akan non-aktif otomatis jika melebihi 24 jam, kami ingin melanjutkan pembicaraan terakhir terkait {EXTRA_PARAM}, boleh minta waktunya sebentar? Terima kasih
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    // General
    'follow_up_chat_timeout': {
        template: `
                Hi kak {TENANT_NAME/OWNER_NAME}, karena keterbatasan sistem kami dimana chat dari kakak akan non-aktif otomatis jika melebihi 24 jam, kami ingin menanyakan kembali terkait {EXTRA_PARAM}, boleh minta waktunya sebentar? Terima kasih
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    // Tenant
    'renewal_tenant_60days': {
        template: `
                Hi Kak {TENANT_NAME},<br /><br />

                Ini Customer Care dari Jendela360 yang menangani perpanjangan dan checkout unit apartemen kakak.<br /><br />

                Bagaimana kak ada kendala atau masalah selama sewa di {EMPTY} {EXTRA_PARAM} kak?<br /><br />

                oiya untuk informasi saja kak, masa sewa kakak akan berakhir di {END_DATE}, Apakah ada rencana perpanjangan kak?
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'renewal_tenant_6weeks': {
        template: `
                Hi Kak {TENANT_NAME},<br /><br />

                Friendly reminder, untuk unit yang kakak tinggali sekarang sewanya akan berakhir {END_DATE}. Rencananya kakak akan perpanjang atau tidak ya kak?<br /><br />

                Sebagai informasi saja, untuk 30 hari sebelum sewa berakhir unitnya sudah mulai kita pasarkan lagi ya kak sesuai permintaan owner. Jadi tolong dibantu ya kak untuk info jadi perpanjang atau tidaknya, soalnya sayang jika infonya terlambat dan unit sudah tersewa oleh orang lain, nanti kakak harus repot2 pindahan lagi kak
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'renewal_tenant_4weeks': {
        template: `
                Hi Kak {TENANT_NAME},<br /><br />

                Gimana kak, mau follow-up untuk perpanjang sewa ni, kira-kira jadi mau perpanjang atau tidak ya kak?<br /><br />

                Friendly reminder saja kak, untuk unit kakak saat ini sudah mulai kita pasarkan ulang ya kak. semisal kakak mau perpanjang tolong kabari segera ya kak, soalnya sayang banget kalo infonya terlambat dan unit sudah tersewa oleh orang lain, nanti kakak harus repot2 pindahan lagi kak<br /><br />

                Kami tunggu konfirmasinya ya kak :)
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'renewal_tenant_3weeks': {
        template: `
                Hi Kak {TENANT_NAME},<br /><br />

                Apa kabar? kak cuma mau konfirm aja ni untuk unit yg kakak sewa jadinya mau diperpanjang atau tidak kak?<br /><br />

                Kebetulan unit yang kakak tinggali sudah ada yang berminat juga ni kak, jadi kalau kakak mau perpanjang tolong segera dikabari ya. Takutnya kalau keduluan orang lain, nanti kakak jg yg repot kalo harus beres2 untuk pindahan ni.<br /><br />

                Terima kasih kak
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'checkout_tenant_1week_1': {
        template: `
                Hi Kak {TENANT_NAME},<br />
                mau mengingatkan kembali untuk jadwal checkout nanti di tanggal {DATE} hari {DAY} jam {TIME} ya kak.<br />
                mohon disiapkan untuk hal yang sudah di informasikan sebelumnya.
                checkout akan di bantu oleh tim kami atas nama {PIC_NAME} <br />
                Jika ada perubahan waktu, mohon informasikan ke kami H-2 sebelum jadwal checkout.<br />
                Terimakasih.
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'checkout_tenant_1week_2': {
        template: `
                Hi Kak {TENANT_NAME},<br /><br />

                Ingin mengingatkan kembali untuk jadwal checkout tidak ada perubahan waktu kan ya?<br /><br />

                Jika ada perubahan waktu, mohon informasikan ke kami H-2 sebelum jadwal checkout.<br />
                mohon disiapkan untuk hal yang sudah di informasikan sebelumnya.<br /><br />

                Terimakasih.
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'monthly_charge_to_tenant': {
        template: `
                Hi Kak {TENANT_NAME},<br />
                Sekedar informasi untuk tagihan bulanan biasanya sudah di terbitkan di awal bulan ini kak.<br />
                jika kakak belum menerima tagihan bulanan tersebut, mohon bisa di cek kembali ke building management langsung ya kak :)<br /><br />

                Terima kasih
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'price_update_to_tenant': {
        template: `
                Hi Kak {TENANT_NAME}<br /><br />

                Saya sudah dapat informasi harga terbaru dari owner yakni {EXTRA_PARAM} juta untuk {EXTRA_PARAM2} bulan.<br /><br />

                Bagaimana kak apakah jadi lanjut perpanjangan sewa?
            `,
        extraParam1: true,
        extraParam2: true,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'problem_update_to_tenant': {
        template: `
                Hi Kak {TENANT_NAME}<br /><br />

                Untuk kendala di unit, saya sudah dapat kabar dari owner kak.<br />
                bisa minta waktunya sebentar kak?
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'tenant_checkin': {
        template: `
                Hi Kak {TENANT_NAME}<br />
                Ini adalah layanan Customer Care Jendela360.Terima kasih telah menggunakan jasa Jendela360. <br />
                Berikut adalah informasi Kakak<br /><br />

                Nama: {EXTRA_PARAM}<br />
                Apartemen: {EXTRA_PARAM2}<br />
                Tower/Lt/No unit: {EXTRA_PARAM3}/{EXTRA_PARAM4}/{EXTRA_PARAM5}<br />
                Masa sewa: {START_DATE} – {END_DATE}<br /><br />

                Apabila ada kendala di unit, bisa langsung menghubungi saya ya Kak. <br />
                Terima kasih
            `,
        extraParam1: true,
        extraParam2: true,
        extraParam3: true,
        extraParam4: true,
        extraParam5: true
    },
    'ownerproblem_in_unit_to_tenant_satu': {
        template: `
                Hi Kak {TENANT_NAME}<br />
                Saya dapat kabar dari owner terkait kendala di {EMPTY} {EXTRA_PARAM} Kak. Boleh minta waktunya sebentar?
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'deposit_question_inquiry': {
        template: `
                Hi kak {TENANT_NAME}, kami baru saja selesai melakukan perhitungan terkait tagihan kakak.
                Boleh minta waktunya sebentar kak? Terima kasih. 
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'checkout_deposit_notification': {
        template: `
                Terima kasih atas kepercayaan kakak telah menggunakan jasa Jendela360.
                Saya dari divisi aftersales ingin menginfokan terkait pengembalian deposit sewa akan kami proses dalam 30 hari.
                Saat ini tim kami sedang melakukan perhitungan tagihan terakhir unit kakak dan juga melakukan pengecekan kondisi unit.
                Deposit kakak akan kembali secara utuh jika tidak terdapat tunggakan tagihan dan kerusakan pada unit.
                Sekian yang dapat kami infokan untuk saat ini, terima kasih.
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'final_monthly_utilities_reminder': {
        template: `
                Hi kak {TENANT_NAME}, saya ingin mengkonfirmasi terkait tunggakan tagihan apartemen kakak.
                Berhubung sudah mendekati jatuh tempo untuk pelunasan tagihannya kak, apakah sudah clear semua?
                Jika sudah mohon untuk mengirimkan bukti pelunasannya agar dapat kami follow up. Terima kasih.
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'second_monthly_utilities_reminder': {
        template: `
                Hi kak {TENANT_NAME}, menyambung pembicaraan terakhir tekait tunggakan tagihan {EXTRA_PARAM}.
                Saya ingin mengkonfirmasi kembali apakah kakak sudah melakukan pembayaran atau belum?
                Jika sudah mohon untuk mengirimkan bukti bayarnya ke kami ya kak, agar dapat kami follow up ke pengelola.
                Terima kasih.
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'monthly_utilities_reminder_renewal': {
        template: `
                Hi kak {TENANT_NAME}, Saya mendapat informasi dari {EXTRA_PARAM}, bahwa terdapat tagihan {EXTRA_PARAM2} untuk unit apartmen kakak.
                Mohon untuk segera di lunasi ya kak. Jika kakak merasa sudah membayar tagihan tersebut bisa tolong untuk kirimkan bukti bayarnya kepada kami agar dapat kami follow up ke {EXTRA_PARAM}.
                Terima kasih.
            `,
        extraParam1: true,
        extraParam2: true,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'monthly_utilities_reminder': {
        template: `
                Hi kak {TENANT_NAME} saya dari divisi aftersales Jendela360.
                Saya mendapat informasi dari {EXTRA_PARAM}, bahwa terdapat tagihan {EXTRA_PARAM2} untuk unit apartmen kakak.
                Mohon untuk segera di lunasi ya kak. Jika kakak merasa sudah membayar tagihan tersebut bisa tolong untuk kirimkan bukti bayarnya kepada kami agar dapat kami follow up ke {EXTRA_PARAM}. Terima kasih.
            `,
        extraParam1: true,
        extraParam2: true,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'complaint_form_information_2': {
        template: `
                Hi kak {TENANT_NAME}, untuk informasi complain/kendala dapat dikirimkan melalui Whatsapp ke Customer Care kami di nomor 081250001360, dengan format sebagai berikut:<br />
                <strong>Nama pelanggan</strong>:<br />
                <strong>Kode pelanggan/unit apartmen</strong>:<br />
                <strong>Kendala</strong>:<br />
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'confirm_payment_3': {
        template: `
                Hi kak {TENANT_NAME}, saya ingin menanyakan terkait pembayaran perpanjangan sewa kakak, apakah ada kendala sehingga kakak belum dapat memproses pembayaran sampai hari ini? Terima kasih
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'monthly_bill': {
        template: `
                [AUTO NOTIFICATION]<br /><br /><br />


                Hi Kak {TENANT_NAME},<br /><br /><br />


                Jangan lupa untuk melakukan pembayaran tagihan bulanan ya Kak. Karena jika pembayaran dilakukan setelah jatuh tempo, biasanya akan ada denda untuk keterlambatan pembayaran. Untuk detail pembayarannya, bisa langsung ditanyakan ke pihak Pengelola Apartemen ya Kak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'confirm_checkout1': {
        template: `
                Hi Kak {TENANT_NAME}<br /><br /><br />


                Apakah kakak jadi melakukan check-out unit {EMPTY} {EXTRA_PARAM} pada tanggal {DATE}?<br /><br /><br />


                Mohon konfirmasi ke PIC kami di {PIC_PHONE} ya kak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'confirm_checkout2': {
        template: `
                [AUTO REMINDER]<br /><br />

                Hi Kak {TENANT_NAME}<br /><br /><br />


                Sekedar konfirmasi, besok kakak jadi melakukan check-out pada pukul {TIME}?<br /><br /><br />


                Mohon konfirmasi ke PIC kami di {PIC_PHONE} ya kak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'chat_general_tenant': {
        template: `
                Hi Pak/Bu {TENANT_NAME}<br /><br /><br />


                Boleh minta waktunya sebentar terkait unit {EMPTY} {EXTRA_PARAM} mengenai {EXTRA_PARAM2}?
            `,
        extraParam1: true,
        extraParam2: true,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'chat_expired_tenant': {
        template: `
                Hi Pak/Bu {TENANT_NAME}, karena keterbatasan sistem kami dimana chat akan non-aktif otomatis jika melebihi 24 jam, kami ingin melanjutkan pembicaraan terakhir terkait {EXTRA_PARAM}, boleh minta waktunya sebentar? Terima kasih
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'contract_expired_3weeks': {
        template: `
                [AUTO NOTIFICATION]<br /><br />

                Hi Kak {TENANT_NAME},<br /><br />

                Apa kabar? Mau konfirmasi aja nih untuk unit yg kakak sewa jadinya mau diperpanjang atau tidak kak? Kebetulan unit yang kakak tinggali sudah ada yang berminat juga ni kak, jadi kalau kakak mau perpanjang tolong segera dikabari ya.<br /><br /><br />


                Untuk konfirmasi perpanjang atau tidaknya, harap hubungi tim perpanjangan kami di {PIC_PHONE} ya Kak. Atau kakak bisa tunggu tim perpanjangan kami untuk menghubungi Kakak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'contract_expired_4weeks': {
        template: `
                [AUTO NOTIFICATION]<br /><br />

                Hi Kak {TENANT_NAME},<br /><br />

                Gimana kak, kira-kira jadi mau perpanjang atau tidak ya kak? Friendly reminder saja kak, untuk unit kakak saat ini sudah mulai kita pasarkan ulang ya kak. semisal kakak mau perpanjang tolong kabari segera ya kak, soalnya sayang banget kalo infonya terlambat dan unit sudah tersewa oleh orang lain, nanti kakak harus repot2 pindahan lagi kak<br /><br /><br />


                Untuk konfirmasi perpanjang atau tidaknya, harap hubungi tim perpanjangan kami di {PIC_PHONE} ya Kak. Atau kakak bisa tunggu tim perpanjangan kami untuk menghubungi Kakak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360"
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'contract_expired_6weeks': {
        template: `
                [AUTO NOTIFICATION]<br /><br />

                Hi Kak {TENANT_NAME},<br /><br />

                Friendly reminder, untuk unit yang kakak tinggali sekarang sewanya akan berakhir {EXPIRED_DATE}. Sebagai informasi saja, 30 hari sebelum sewa berakhir unitnya sudah mulai kita pasarkan lagi ya kak sesuai permintaan owner. <br /><br /><br />


                Untuk konfirmasi perpanjang atau tidaknya, harap hubungi tim perpanjangan kami di {PIC_PHONE} ya Kak. Atau kakak bisa tunggu tim perpanjangan kami untuk menghubungi Kakak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: false,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    },
    'contract_expired_8weeks': {
        template: `
                Hi Kak {TENANT_NAME}, mau kasih kabar bahwa masa sewa kakak di apartemen {EXTRA_PARAM} akan berakhir per tanggal {DATE}.<br /><br /><br />


                Untuk konfirmasi perpanjang atau tidaknya, harap hubungi tim perpanjangan kami di {PIC_PHONE} ya Kak. Atau kakak bisa tunggu tim perpanjangan kami untuk menghubungi Kakak. Terima kasih<br /><br /><br />


                Harap untuk tidak membalas melalui chat ini karena ini adalah layanan pesan otomatis Jendela360
            `,
        extraParam1: true,
        extraParam2: false,
        extraParam3: false,
        extraParam4: false,
        extraParam5: false
    }
}
import React, { useState, useReducer, useEffect } from 'react';
import { Theme, Grid, TextField, Button, InputAdornment, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Components
import LoadingScreen from '../../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import { currencyToNumber, generalErrorHandler, numberToCurrency } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';

interface Props {
    onSubmitSuccess: () => void,
    unitId: string
    onCloseForm: (data: any) => void;
    priceFor: string
}

interface IState {
    basic_price_1: string
    basic_price_3: string
    basic_price_6: string
    basic_price_12: string
    basic_price_sell: string
    selling_price: string;
    price_per_12_months: string;
    price_per_6_months: string;
    price_per_3_months: string;
    price_per_1_month: string;
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

interface IErrorState {
    basic_price_1: string
    basic_price_3: string
    basic_price_6: string
    basic_price_12: string
    basic_price_sell: string
    selling_price: string;
    price_per_12_months: string;
    price_per_6_months: string;
    price_per_3_months: string;
    price_per_1_month: string;
}

interface IErrorAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) =>
({
    submit_button: {
        width: '100%',
        borderRadius: '25px'
    },
    appBar: {
        position: "relative",
        overflow: 'hidden'
    },
    header: {
        flexGrow: 1
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        margin: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(5),
        },
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'center',
        bottom: '4px',
        left: 0,
        position: 'absolute',
        padding: '20px',
        width: '100%'
    },
    title: {
        marginBottom: theme.spacing(1)
    }
}), "AdvisorPrice_Form"
)

const AdvisorPriceForm = (props: Props) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/my-unit';

    const [loading, setLoading] = useState(false);

    // Error State
    const errorReducer = (state: IErrorState, action: IErrorAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                basic_price_1: '',
                basic_price_3: '',
                basic_price_6: '',
                basic_price_12: '',
                basic_price_sell: '',
                selling_price: '',
                price_per_12_months: '',
                price_per_6_months: '',
                price_per_3_months: '',
                price_per_1_month: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [errorState, setErrorState] = useReducer(errorReducer, {
        basic_price_1: '',
        basic_price_3: '',
        basic_price_6: '',
        basic_price_12: '',
        basic_price_sell: '',
        selling_price: '',
        price_per_12_months: '',
        price_per_6_months: '',
        price_per_3_months: '',
        price_per_1_month: '',
    });

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                basic_price_1: '',
                basic_price_3: '',
                basic_price_6: '',
                basic_price_12: '',
                basic_price_sell: '',
                selling_price: '',
                price_per_12_months: '',
                price_per_6_months: '',
                price_per_3_months: '',
                price_per_1_month: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        basic_price_1: '',
        basic_price_3: '',
        basic_price_6: '',
        basic_price_12: '',
        basic_price_sell: '',
        selling_price: '',
        price_per_12_months: '',
        price_per_6_months: '',
        price_per_3_months: '',
        price_per_1_month: '',
    });

    // const convertDataToNumber = [
    //     'price_per_12_months', 'price_per_6_months', 'price_per_1_month', 'selling_price', 'basic_price_1',
    //     'basic_price_3', 'basic_price_6', 'basic_price_12', 'basic_sell_price'
    //     // 'land_area', 'property_area'
    // ];

    const checkValidation = () => {
        let isValid = true;
        let newError = { ...errorState };
        // if (!inputState.name) {
        //     newError.name = 'Nama wajib diisi';
        //     isValid = false;
        // } else {
        //     newError.longitude = '';
        // }
        // if (!inputState.longitude) {
        //     newError.longitude = 'Longitude wajib diisi';
        //     isValid = false;
        // } else {
        //     newError.longitude = '';
        // }
        // if (!inputState.latitude) {
        //     newError.latitude = 'Latitude wajib diisi';
        //     isValid = false;
        // } else {
        //     newError.latitude = '';
        // }
        setErrorState({ type: 'REPLACE_STATE', name: '', value: newError });
        return isValid;
    }

    const handleInputChanged = (e: any) => {
        const target = e.target;

        if (target) {
            let value = target.value;
            setInputState({ name: target.name, value: currencyToNumber(value as string), type: 'SET_ITEM' });
        }
    }

    const submitForm = () => {

        if (!checkValidation()) {
            return;
        }

        let axios;
        if (props.priceFor === 'base') {
            axios = DefaultAxios.patch(`${API_URL}/${props.unitId}?base_price=true`, inputState)
            setLoading(true);
            axios
                .then(res => {
                    Swal.fire({
                        title: "Base Price Updated",
                        icon: 'success',
                        onAfterClose: props.onSubmitSuccess,
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        } else {
            axios = DefaultAxios.patch(`${API_URL}/${props.unitId}?web_price=true`, inputState)
            setLoading(true);
            axios
                .then(res => {
                    Swal.fire({
                        title: "Web Price Updated",
                        icon: 'success',
                        onAfterClose: props.onSubmitSuccess,
                        timer: 1000
                    });
                })
                .catch(error => {
                    generalErrorHandler(error);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        if (props.unitId) {
            setLoading(true);
            DefaultAxios
                .get(`${process.env.REACT_APP_API_URL}/my-unit/${props.unitId}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                    setLoading(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Root>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.onCloseForm}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.header}>
                        Edit Price
                    </Typography>
                </Toolbar>
            </AppBar>
            <LoadingScreen open={loading} fullScreen />

            <Grid className={classes.root} >
                <Typography variant="h6" className={classes.title}>
                    {props.priceFor === 'base' ? 'Base Price' : 'Web Price'}
                </Typography>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        error={props.priceFor === 'base' ? !!errorState.basic_price_sell : !!errorState.selling_price}
                        helperText={props.priceFor === 'base' ? errorState.basic_price_sell : errorState.selling_price}
                        label="Harga Jual"
                        name={props.priceFor === 'base' ? "basic_price_sell" : "selling_price"}
                        variant="outlined"
                        value={numberToCurrency(props.priceFor === 'base' ? inputState.basic_price_sell : inputState.selling_price)}
                        onChange={handleInputChanged}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        error={props.priceFor === 'base' ? !!errorState.basic_price_12 : !!errorState.price_per_12_months}
                        helperText={props.priceFor === 'base' ? errorState.basic_price_12 : errorState.price_per_12_months}
                        label="Harga Sewa Per Tahun"
                        name={props.priceFor === 'base' ? "basic_price_12" : "price_per_12_months"}
                        variant="outlined"
                        value={numberToCurrency(props.priceFor === 'base' ? inputState.basic_price_12 : inputState.price_per_12_months)}
                        onChange={handleInputChanged}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        error={props.priceFor === 'base' ? !!errorState.basic_price_6 : !!errorState.price_per_6_months}
                        helperText={props.priceFor === 'base' ? errorState.basic_price_6 : errorState.price_per_6_months}
                        label="Harga Sewa Per 6 Bulan"
                        name={props.priceFor === 'base' ? "basic_price_6" : "price_per_6_months"}
                        variant="outlined"
                        value={numberToCurrency(props.priceFor === 'base' ? inputState.basic_price_6 : inputState.price_per_6_months)}
                        onChange={handleInputChanged}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        error={props.priceFor === 'base' ? !!errorState.basic_price_3 : !!errorState.price_per_3_months}
                        helperText={props.priceFor === 'base' ? errorState.basic_price_3 : errorState.price_per_3_months}
                        label="Harga Sewa Per 3 Bulan"
                        name={props.priceFor === 'base' ? "basic_price_3" : "price_per_3_months"}
                        variant="outlined"
                        value={numberToCurrency(props.priceFor === 'base' ? inputState.basic_price_3 : inputState.price_per_3_months)}
                        onChange={handleInputChanged}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        error={props.priceFor === 'base' ? !!errorState.basic_price_1 : !!errorState.price_per_1_month}
                        helperText={props.priceFor === 'base' ? errorState.basic_price_1 : errorState.price_per_1_month}
                        label="Harga Sewa Per Bulan"
                        name={props.priceFor === 'base' ? "basic_price_1" : "price_per_1_month"}
                        variant="outlined"
                        value={numberToCurrency(props.priceFor === 'base' ? inputState.basic_price_1 : inputState.price_per_1_month)}
                        onChange={handleInputChanged}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>

                <Grid item xs={12}>
                    {/* <TextField
                        label="Latitude"
                        name="latitude"
                        value={inputState.latitude}
                        onChange={handleInputChanged}
                        variant="outlined"
                        // type="number"
                        fullWidth
                        error={!!errorState.latitude}
                        helperText={errorState.latitude}
                        placeholder="For Jakarta, ex: -6.1745"
                    /> */}
                </Grid>
                <Grid item xs={12} className={classes.gridSubmit}>
                    <Button variant="contained" size="large" color="primary" onClick={submitForm} className={classes.submit_button}>
                        Simpan
                    </Button>
                </Grid>
            </Grid>
        </Root>
    );
}

export default AdvisorPriceForm;
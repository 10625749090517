import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from '@mui/material'
import { Link as LinkDom } from 'react-router-dom';
import LoadingScreen from '../../../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { dateFormat, generalErrorHandler } from '../../../../_utils/Helper'
import { generateStyle } from '../../../../_utils/DefaultStyle';

export interface UnitActivityListModalProps {
    open: boolean
    onClose: () => void
    unitId: number
}

interface ActivityItem {
    activity: string
    activity_type_label: string
    datetime: string
    verified_at: string
    has_inventory: boolean
    id: number
    pic_name: string
}

const UnitActivityListModal = (props: UnitActivityListModalProps) => {
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(true)
    const [isDownloadLoading, setIsDownloadLoading] = useState(false)

    const [data, setData] = useState<ActivityItem[]>([])

    useEffect(() => {
        if (props.open) {
            fetchData()
        }
        // eslint-disable-next-line
    }, [props.open, props.unitId])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/new-closing/activity-list`, {
            params: {
                unit_id: props.unitId,
            }
        })
            .then(res => res.data)
            .then(data => {
                setData(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const downloadPDF = (activity: ActivityItem) => {
        setIsDownloadLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/${activity.id}/listing-inventory/pdf`, {}, {
            responseType: 'blob',
        })
            .then(res => res.data)
            .then(data => {
                setIsDownloadLoading(false)
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${activity.activity_type_label}.pdf`)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .catch(err => {
                console.log(err)
                generalErrorHandler(err)
                setIsDownloadLoading(false)
            })
    }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                closeAfterTransition
                fullWidth
                maxWidth="md"
            >
                <Root>
                    <LoadingScreen fullScreen open={isDownloadLoading} />
                    <DialogTitle id="form-dialog-title">Activity List</DialogTitle>
                    <DialogContent>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Tanggal</TableCell>
                                        <TableCell>Activity</TableCell>
                                        <TableCell>PIC</TableCell>
                                        <TableCell>Tanggal Finish</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        isLoading ?
                                            <TableRow>
                                                <TableCell colSpan={4} align='center'>
                                                    <CircularProgress />
                                                </TableCell>
                                            </TableRow>
                                            :
                                            data.length ?
                                                data.map(item =>
                                                    <TableRow key={item.id.toString()}>
                                                        <TableCell>
                                                            {item.datetime ? dateFormat(item.datetime, 'DD/MM/YYYY') : "-"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.activity_type_label}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.pic_name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.verified_at ? dateFormat(item.verified_at, 'DD/MM/YYYY') : "-"}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className={classes.actionsContainer}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="small"
                                                                    component={LinkDom}
                                                                    target="_blank"
                                                                    rel='noopener norefferer'
                                                                    to={{ pathname: `/activity/${item.id}` }}
                                                                >
                                                                    Detail
                                                                </Button>
                                                                {
                                                                    item.has_inventory &&
                                                                    <>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            size="small"
                                                                            component={LinkDom}
                                                                            style={{
                                                                                marginLeft: 8,
                                                                                whiteSpace: 'nowrap',
                                                                            }}
                                                                            to={`/activity/${item.id}/inventory`}
                                                                            state={{ prevPath: window.location.pathname }}
                                                                        >
                                                                            List Inventory
                                                                        </Button>
                                                                        {
                                                                            item.activity !== '9' ?
                                                                                <Button
                                                                                    onClick={() => downloadPDF(item)}
                                                                                    color="secondary"
                                                                                    size="small"
                                                                                    variant="contained"
                                                                                    style={{
                                                                                        marginLeft: 8,
                                                                                        whiteSpace: 'nowrap',
                                                                                    }}
                                                                                >
                                                                                    Download PDF
                                                                                </Button>
                                                                                : null
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={4} align='center'>
                                                        Belum ada activity
                                                    </TableCell>
                                                </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>

                    </DialogActions>
                </Root>
            </Dialog>
        </>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'nowrap',
    }
}), "UnitActivityList_Modal"
)

export default UnitActivityListModal

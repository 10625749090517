import React, { useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import { IChats } from '../../_components/WhatsappPreview';
import WhatsappPreviewModal from '../../_components/_modal/WhatsappPreviewModal';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderInfoButton } from '../../_utils/Helper';

interface IState {
    id: number | null,
    phone: string
}

const initialState = {
    id: null,
    phone: ''
}

const WhatsappChatChecker = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState<IState>(initialState)
    const [chats, setChats] = useState<IChats[]>([])

    const handleAutocompleteChange = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({ ...prev, id: value.id }));
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setState(prev => ({ ...prev, id: null }));
    }

    const checkWhatsapp = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/phone`, {
            params: {
                ...state,
            }
        })
            .then(res => res.data)
            .then(res => {
                setChats(res)
            })
            .catch((err) => {
                if (err?.response?.status === 404) {
                    renderInfoButton('', 'Chat tidak ditemukan untuk nomor tersebut')
                } else {
                    generalErrorHandler(err)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            <WhatsappPreviewModal
                code={null}
                created_at=''
                onClose={() => setChats([])}
                open={!!chats.length}
                chats={chats}
            />
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item xs={12} md={3}>
                    <AsyncAutoComplete
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/user/whatsapp-checker`}
                        label="User"
                        name="id"
                        onChange={handleAutocompleteChange}
                        onInputChange={handleAutocompleteInputChanged}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        name="phone"
                        label="Phone"
                        onChange={(e) => setState(prev => ({ ...prev, phone: e.target.value }))}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!state.id || !state.phone}
                        onClick={checkWhatsapp}
                        fullWidth
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default WhatsappChatChecker;
import React from 'react'

/**
 * Utils
 */
import { Theme } from '@mui/material'
import { generateStyle } from '../_utils/DefaultStyle'

interface ButtonUploadSlimProps {
    name: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    label?: string
    error?: boolean
    helperText?: string
}

const ButtonUploadSlim = (props: ButtonUploadSlimProps) => {
    const { Root, classes } = useStyles()

    return (
        <Root>
            <div className={classes.root}>
                {
                    !!props.label ?
                        <label className={classes.label}>{props.label}</label>
                        :
                        null
                }
                <input type="file" name={props.name} onChange={props.onChange} style={{ marginBottom: 4 }} />
                {
                    props.helperText &&
                    <small className={`${props.error ? classes.errorText : classes.helperText} ${classes.span}`}>
                        {props.helperText}
                    </small>
                }
            </div>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    label: {
        fontSize: 14,
        fontWeight: '500',
        paddingBottom: 12,
    },
    errorText: {
        color: theme.palette.error.main,
    },
    helperText: {
        color: theme.palette.grey[600],
    },
    span: {
        fontSize: 12,
        display: 'block',
    },
}), "ButtonUpload_Slim"
)

export default ButtonUploadSlim

import { Paper, Theme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DefaultAxios from '../../_utils/DefaultAxios'

import {
    ArgumentAxis,
    ValueAxis,
    Chart,
    LineSeries,
    BarSeries,
    Title,
    Tooltip,
    Legend,
} from '@devexpress/dx-react-chart-material-ui';
import { EventTracker, ArgumentScale, Stack } from '@devexpress/dx-react-chart';
import { scaleBand } from '@devexpress/dx-chart-core';
import LoadingScreen from '../../_components/LoadingScreen'
import DateRangePicker, { DateRange } from '../../_components/DateRangePicker';
import { format, subDays } from 'date-fns';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IProps {
    monthly?: boolean
}

interface IState {
    startDate: DateRange
    endDate: DateRange
}

interface IData {
    total: {
        closing_date: string
        label: string
        total: number
    }[]
    breakdown: {
        closing_date: string
        label: string
        closing_baru: number
        closing_extend: number
        closing_wings: number
        closing_pintu: number
    }[]
}

const ClosingChart = (props: IProps) => {
    const { Root, classes } = useStyles()

    const [isLoading, setIsLoading] = useState(true)
    const [state, setState] = useState<IState>({
        startDate: subDays(new Date(), 7),
        endDate: new Date(),
    })

    const [targetItem, setTargetItem] = useState({
        distance: 0,
        order: 0,
        point: 0,
        series: ''
    });

    const [data, setData] = useState<IData>({
        total: [],
        breakdown: []
    })

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    const fetchData = () => {
        setIsLoading(true)
        const URL = props.monthly ? `${process.env.REACT_APP_API_URL}/closing-chart/monthly` : `${process.env.REACT_APP_API_URL}/closing-chart`
        let closing_date = state.startDate === null ? '' : format(state.startDate, 'dd/MM/yyyy')

        if (closing_date || state.endDate) {
            closing_date += '-'
            closing_date += state.endDate === null ? '' : format(state.endDate, 'dd/MM/yyyy')
        }

        DefaultAxios.get(URL, {
            params: {
                closing_date: props.monthly ? null : closing_date
            }
        })
            .then(res => res.data)
            .then(data => {
                const total = data.total
                const breakdown = data.breakdown

                setData({
                    total: total && total.length > 0 ? total : [{ closing_date: 'No Data Available', total: 0 }],
                    breakdown: breakdown && breakdown.length > 0 ? breakdown : [{ closing_date: 'No Data Available', closing_baru: 0, closing_extend: 0, closing_pintu: 0, closing_wings: 0 }],
                })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const changeTargetItem = (targetItem: any) => {
        setTargetItem(targetItem)
    }

    const renderTooltip = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                <p>{data.total[targetItem?.point].label}</p>
                <p>Data: {data.total[targetItem?.point].total}</p>
            </div>
        )
    }

    const renderTooltipMore = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                <p>{data.breakdown[targetItem?.point].label}</p>
                {
                    targetItem.series === 'Closing Baru' ? <p>Data: {data.breakdown[targetItem?.point].closing_baru}</p>
                        : null
                }
                {
                    targetItem.series === 'Closing Extend' ? <p>Data: {data.breakdown[targetItem?.point].closing_extend}</p>
                        : null
                }
                {
                    targetItem.series === 'Closing Wings' ? <p>Data: {data.breakdown[targetItem?.point].closing_wings}</p>
                        : null
                }
                {
                    targetItem.series === 'Closing Pintu' ? <p>Data: {data.breakdown[targetItem?.point].closing_pintu}</p>
                        : null
                }
            </div>
        )
    }

    return (
        <Root>
            <LoadingScreen
                open={isLoading}
                fullScreen
            />
            {
                !props.monthly &&
                <div className={classes.datePicker}>
                    <DateRangePicker
                        startDate={state.startDate}
                        endDate={state.endDate}
                        onDatesChange={dates => setState(dates)}
                        onFinished={fetchData}
                        format="dd/MM/yyyy"
                        fullWidth
                    />
                </div>
            }
            <Paper className={classes.chartContainer}>
                <Chart
                    data={data.total}
                >
                    <ArgumentAxis />
                    <ValueAxis />

                    <LineSeries
                        valueField="total"
                        argumentField="closing_date"
                    />

                    <Title
                        text="Total Closing"
                    />
                    <EventTracker />
                    <Tooltip contentComponent={renderTooltip} onTargetItemChange={changeTargetItem} />
                </Chart>
            </Paper>
            <Paper className={classes.chartContainer}>
                <Chart
                    data={data.breakdown}
                >
                    <ArgumentScale factory={scaleBand} />
                    <ArgumentAxis />
                    <ValueAxis />

                    <BarSeries
                        name="Closing Baru"
                        valueField="closing_baru"
                        argumentField="closing_date"
                    />
                    <BarSeries
                        name="Closing Extend"
                        valueField="closing_extend"
                        argumentField="closing_date"
                    />
                    <BarSeries
                        name="Closing Wings"
                        valueField="closing_wings"
                        argumentField="closing_date"
                    />
                    <BarSeries
                        name="Closing Pintu"
                        valueField="closing_pintu"
                        argumentField="closing_date"
                    />

                    <Title
                        text="Breakdown Closing"
                    />
                    <EventTracker />
                    <Tooltip contentComponent={renderTooltipMore} onTargetItemChange={changeTargetItem} />
                    <Legend />
                    <Stack />
                </Chart>
            </Paper>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    chartContainer: {
        marginTop: 32,
    },
    datePicker: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
            maxWidth: 300,
            marginLeft: 'auto',
        }
    }
}), "ClosingCHart"
)

export default ClosingChart

import React, { useState, useEffect } from 'react';
import { Grid, Theme, Card, CardContent } from '@mui/material';
import LoadingScreen from '../../_components/LoadingScreen';
import {
    ArgumentAxis,
    ValueAxis,
    Chart,
    LineSeries,
    BarSeries,
    Legend,
    ZoomAndPan,
    Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { ValueScale, EventTracker } from '@devexpress/dx-react-chart';
import { scaleBand } from '@devexpress/dx-chart-core';
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart';
import DefaultAxios from '../../_utils/DefaultAxios';
import DateRangePicker, { DateRange } from '../../_components/DateRangePicker';
import { format, subWeeks } from 'date-fns';
import { generateStyle } from '../../_utils/DefaultStyle';

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        margin: '5px',
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
        // fontSize: '0.8125rem',
        // border: '1px solid #006db3',
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    date: {
        marginBottom: theme.spacing(2)
    },
    chart: {
        marginBottom: theme.spacing(4)
    }
}), "Enquiry_Chart"
)

const formatDate = (date: DateRange) => {
    if (!date) return ''

    return format(date, 'dd/MM/yyyy')
}

const EnquiryChart = () => {

    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/enquiry-chart';
    const initialDateFilter = {
        created_at_start: subWeeks(new Date(), 2),
        created_at_end: new Date()
    };
    const [isLoading, setIsLoading] = useState(false);
    const [dateFilter, setDateFilter] = useState<{ created_at_start: DateRange, created_at_end: DateRange }>(initialDateFilter);


    const [chartData, setChartData] = useState<any[]>([]);
    const [chartMoreData, setChartMoreData] = useState<any[]>([]);

    // const [targetItem, setTargetItem] = useState({
    //     chartData: [],
    //     targetItem: undefined
    // });

    const [targetItem, setTargetItem] = useState({
        distance: 0,
        order: 0,
        point: 0,
        series: ''
    });

    const changeTargetItem = (targetItem: any) => {
        setTargetItem(targetItem)
    }

    const modifyValueDomain = () => [0, 200];

    const handleDateChanged = (dates: { startDate: DateRange, endDate: DateRange }) => {
        let newFilter = {
            created_at_start: dates.startDate,
            created_at_end: dates.endDate
        }
        setDateFilter(newFilter);
    }

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${API_URL}`, { params: { created_at_start: formatDate(dateFilter.created_at_start), created_at_end: formatDate(dateFilter.created_at_end) } })
            .then(res => {
                const data = res.data;
                setChartData(data.basic_chart);
                setChartMoreData(data.more_chart);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const renderTooltip = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                <p>{chartData[targetItem?.point].label}</p>
                <p>Data: {chartData[targetItem?.point].value}</p>
            </div>
        )
    }

    const renderTooltipMore = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                <p>{chartMoreData[targetItem?.point].label}</p>
                {
                    targetItem.series === 'Enquiry Advisor' ? <p>Data: {chartMoreData[targetItem?.point].enquiry_advisor}</p>
                        : null
                }
                {
                    targetItem.series === 'Enquiry CS' ? <p>Data: {chartMoreData[targetItem?.point].enquiry_cs}</p>
                        : null
                }
                {
                    targetItem.series === 'Enquiry JR' ? <p>Data: {chartMoreData[targetItem?.point].enquiry_jr}</p>
                        : null
                }
                {
                    targetItem.series === 'Enquiry Owner / Agent' ? <p>Data: {chartMoreData[targetItem?.point].enquiry_owner_agent}</p>
                        : null
                }
                {
                    targetItem.series === 'Enquiry Wings' ? <p>Data: {chartMoreData[targetItem?.point].enquiry_wings}</p>
                        : null
                }
            </div>
        )
    }

    useEffect(() => {
        loadData()
        // eslint-disable-next-line
    }, [])

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <div className={classes.date}>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item lg={3} >
                        <DateRangePicker
                            startDate={dateFilter.created_at_start}
                            endDate={dateFilter.created_at_end}
                            onDatesChange={handleDateChanged}
                            onFinished={loadData}
                            format="dd/MM/yyyy"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.chart}>
                {
                    chartData ?
                        <Card variant="outlined">
                            <CardContent>
                                <Chart
                                    data={chartData}
                                >
                                    {/* <ValueScale factory={scaleBand} /> */}
                                    <ArgumentScale />
                                    {/* <ArgumentScale factory={scaleBand} /> */}
                                    <ValueScale modifyDomain={modifyValueDomain} />
                                    <ValueAxis />
                                    <ArgumentAxis />
                                    <LineSeries name="value" valueField="value" argumentField="date" />
                                    <EventTracker />
                                    <Tooltip contentComponent={renderTooltip} onTargetItemChange={changeTargetItem} />
                                    <ZoomAndPan />
                                </Chart>
                            </CardContent>
                        </Card>
                        : null
                }
            </div>

            <div>
                {
                    chartMoreData ?
                        <Card variant="outlined">
                            <CardContent>
                                <Chart
                                    data={chartMoreData}
                                >
                                    {/* <ValueScale factory={scaleBand} /> */}
                                    <ValueScale name="enquiry_advisor" factory={scaleBand} modifyDomain={modifyValueDomain} />
                                    <ArgumentScale factory={scaleBand} />
                                    <ArgumentAxis />
                                    <ValueAxis />

                                    <BarSeries
                                        valueField="enquiry_advisor"
                                        argumentField="tanggal"
                                        name="Enquiry Advisor"
                                    />
                                    <BarSeries
                                        valueField="enquiry_jr"
                                        argumentField="tanggal"
                                        name="Enquiry JR"
                                    />
                                    <BarSeries
                                        valueField="enquiry_wings"
                                        argumentField="tanggal"
                                        name="Enquiry Wings"
                                    />
                                    <BarSeries
                                        valueField="enquiry_owner_agent"
                                        argumentField="tanggal"
                                        name="Enquiry Owner / Agent"
                                    />
                                    <BarSeries
                                        valueField="enquiry_cs"
                                        argumentField="tanggal"
                                        name="Enquiry CS"
                                    />
                                    <Stack />
                                    <EventTracker />
                                    <Tooltip contentComponent={renderTooltipMore} onTargetItemChange={changeTargetItem} />
                                    <ZoomAndPan />
                                    <Legend
                                        position="bottom"
                                    />
                                </Chart>
                            </CardContent>
                        </Card>
                        : null
                }
            </div>


        </Root>
    );
}

export default EnquiryChart;
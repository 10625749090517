import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'

type Props = {
    open: boolean,
    onClose: () => void
    onSubmit: (parkingValue: string) => void
    initialState: string
}

const ParkingModal = (props: Props) => {
    const [parkingValue, setParkingValue] = useState('')

    useEffect(() => {
        if (!props.open) {
            setParkingValue('')
        }

        if (props.initialState) {
            setParkingValue(props.initialState)
        }
    }, [props.open, props.initialState ])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Lokasi Parkir Member</DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            value={parkingValue}
                            onChange={(e) => setParkingValue(e.target.value)}
                            fullWidth
                            label="Location"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            sx={{ float: 'right' }}
                            onClick={() => props.onSubmit(parkingValue)}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog>
    )
}

export default ParkingModal
import React, { useContext, useState } from "react";
import {
    Tabs,
    Tab,
    Chip,
    Theme,
} from '@mui/material';

import DataTable from '../../_components/_dataTable/DataTable';

/**
 * Component
 */
import ClosingModalDetail from '../_closing/_components/ClosingModalDetail';
import CustomerPhoneSearch from "./_components/CustomerPhoneSearch";


/**
 * Utils
 */
import { getMonth, numberToCurrency } from '../../_utils/Helper'
import { PermissionContext } from '../../_contexts/PermissionContext';
import { format } from "date-fns";
import { generateStyle } from "../../_utils/DefaultStyle";

// interface ITRList {
//     key: string,
//     value: string
// }

const useStyles = generateStyle((theme: Theme) =>
({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    happyCell: {
        textAlign: "center"
    },
    happyCellIcon: {
        fontSize: "5em",
        color: "#009be5"
    },
    tabContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '1px solid #ddd',
        '& .DateInput_input__focused': {
            borderBottom: '0',
        },
        '& .date-container': {
            width: '25%',
            display: 'flex',
            alignItems: 'center',
        },
        '& .extend-status': {
            marginLeft: 'auto',
            padding: '0px 16px',
            borderLeft: '1px solid #ddd',
            fontSize: 12,
            display: 'flex',
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            '& p span': {
                color: 'red'
            }
        },
    },
    actionContainer: {
        display: 'flex',
        '& > *': {
            marginLeft: 16,
        },
        '& > *:first-of-type': {
            marginLeft: 0,
        }
    },
}), "ManageExtendList"
);

const ManageExtendList = () => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();

    const MANAGE_EXTEND_URL = process.env.REACT_APP_API_URL + '/manage-extend';

    const reDrawDatatable = React.useMemo(() => new Date().getTime(), []);

    // const [picTRList, setPICTRList] = useState<Array<ITRList>>([])
    const [constraintMonth, setConstraintMonth] = useState<number | boolean>(0)
    const tableExtraParams = React.useMemo(() => ({
        constraintMonth: constraintMonth !== 3 ? constraintMonth : null
    }), [constraintMonth])
    const [modalCode, setModalCode] = useState("");

    /**
     * Initial when Page Accessed
     */
    // useEffect(() => {
    //     if (permissions['extend.admin']) {
    //         DefaultAxios.get(`${process.env.REACT_APP_API_URL}/autocomplete/user/tenant-relation`)
    //             .then(response => {
    //                 const lists = response.data;
    //                 let listToPush: Array<ITRList> = [];

    //                 lists.map((data: { id: string, label: string }) => {
    //                     listToPush.push({
    //                         key: data.id,
    //                         value: data.label
    //                     })
    //                     return true;
    //                 });

    //                 setPICTRList([{ key: 'none', value: 'Not Assigned' }, ...listToPush]);
    //             })
    //             .catch(error => {
    //                 generalErrorHandler(error)
    //             });
    //     } else {
    //         setPICTRList([
    //             { key: '', value: 'All' },
    //             { key: 'my-closing', value: 'My Closing' },
    //             { key: 'termin', value: 'Termin Closing' }
    //         ]);
    //     }
    // }, [permissions]);

    // const handleChangePic = (closing_id: string, extend_pic_id: string) => {
    //     DefaultAxios.patch(`${MANAGE_EXTEND_URL}/change-pic`, {
    //         closing_id: closing_id,
    //         extend_pic_id: extend_pic_id
    //     })
    //         .then(response => {
    //             if (response.data.updated) {
    //                 renderToastSuccess('PIC berhasil diganti')
    //             }
    //         })
    //         .catch(error => {
    //             generalErrorHandler(error)
    //         });
    // }

    const renderPIC = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
        return row.pic_ticket_name || '-'
        // if (!permissions['extend.admin']) {
        // }

        // return (
        //     <FormControl className={classes.formControl}>
        //         <Select
        //             value={row.pic_ticket_name || 'none'}
        //             onChange={(e) => handleChangePic(row.id, e.target.value as string)}
        //             autoWidth
        //         >
        //             {picTRList.map(data => {
        //                 return (
        //                     <MenuItem value={data.key} key={data.key}>{data.value}</MenuItem>
        //                 )
        //             })}
        //         </Select></FormControl>
        // )
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setConstraintMonth(newValue)
    }

    const renderTabTable = () => {
        return (
            <div className={classes.tabContainer}>
                <Tabs
                    value={constraintMonth}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                >
                    <Tab label={getMonth(new Date().getMonth()) === 'December' ? `${getMonth(new Date().getMonth())} + ${getMonth(new Date().getMonth() - 11)}` : `${getMonth(new Date().getMonth())} + ${getMonth(new Date().getMonth() + 1)}`} />
                    <Tab label={getMonth(new Date().getMonth())} />
                    <Tab label={getMonth(new Date().getMonth()) === 'December' ? getMonth(new Date().getMonth() - 11) : getMonth(new Date().getMonth() + 1)} />

                    <Tab label='Custom Date' />
                </Tabs>
                <div className="extend-status">

                </div>
            </div>
        )
    }

    return (
        <Root>
            <CustomerPhoneSearch />

            <br></br>

            <DataTable
                reDraw={reDrawDatatable}
                url={MANAGE_EXTEND_URL}
                columns={[
                    {
                        name: 'end_date',
                        label: 'Expired Date',
                        type: 'date',
                        filterable: true,
                        sortable: true,
                        render: (data: any, row: any) => {
                            return format(new Date(data), 'dd MMMM yyyy')
                        }
                    },
                    {
                        name: 'unit_code',
                        label: 'Kode Unit',
                        type: 'string',
                        filterable: true,
                        sortable: false,
                        render: (data: string, row: any) => {
                            return (
                                <Chip variant="outlined" label={data} onClick={() => setModalCode(row.code)} style={{ cursor: "pointer" }} />
                            )
                        }
                    },
                    {
                        name: 'customer_name',
                        label: 'Nama Customer',
                        type: 'string',
                        filterable: true,
                        sortable: false,
                    },
                    {
                        name: 'termin_code',
                        label: 'Termin Code',
                        type: 'string',
                        filterable: true,
                        sortable: false,
                    },
                    {
                        name: 'apartment_name',
                        label: 'Apartment',
                        type: 'string',
                        filterable: true,
                        sortable: false,
                        render: (data: any, row: any) => {
                            return row.apartment_name;
                        }
                    },
                    {
                        name: 'duration',
                        label: 'Durasi',
                        type: 'string',
                        filterable: true,
                        sortable: true,
                    },
                    {
                        name: 'revenue_jendela',
                        label: 'Nilai Komisi',
                        filterable: false,
                        sortable: true,
                        type: 'number',
                        render: (data: string, row: any) => {
                            if (data) {
                                return numberToCurrency(data, 'Rp');
                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        name: 'pic_ticket_name',
                        label: 'PIC',
                        type: 'string',
                        filterable: true,
                        sortable: false,
                        render: renderPIC,
                    },
                    {
                        name: 'extend_status',
                        label: 'Status',
                        type: 'string',
                        filterable: true,
                        sortable: false,
                        options: [
                            {
                                key: '',
                                value: 'All'
                            },
                            {
                                key: 0,
                                value: 'Sedang Sewa'
                            },
                            {
                                key: 20,
                                value: 'All Extend'
                            },
                            {
                                key: 21,
                                value: 'All Checkout'
                            },
                            {
                                key: 20,
                                value: 'Extend',
                                type: 'parent'
                            },
                            {
                                key: 1,
                                value: 'Konfirmasi Extend (Belum Bayar)'
                            },
                            {
                                key: 6,
                                value: 'Extend Dibayar (Menunggu Kontrak di ttd.)'
                            },
                            {
                                key: 9,
                                value: 'Settled (Extend Selesai)'
                            },
                            {
                                key: 21,
                                value: 'Checkout',
                                type: 'parent'
                            },
                            {
                                key: 7,
                                value: 'Confirm checkout'
                            },
                            {
                                key: 8,
                                value: 'Sudah Checkout'
                            },
                            {
                                key: 11,
                                value: 'Processing Deposit'
                            },
                            {
                                key: 12,
                                value: 'Menunggu Konfirm Finance'
                            },
                            {
                                key: 10,
                                value: 'Settled (Deposit Dikembalikan)'
                            },
                        ],
                        render: (data: any, row: any) => {
                            return row.extend_status_label
                        }
                    },
                    {
                        name: 'last_qiscus_broadcast_customer',
                        label: 'Last Broadcast Tenant',
                        type: 'date',
                        filterable: true,
                        sortable: true,
                        render: (data: any, row: any) => {
                            return data ? format(new Date(data), 'dd MMMM yyyy') : "-"
                        }
                    },
                ]}
                // orders={['end_date', 'desc']}
                tab={renderTabTable()}
                customParams={tableExtraParams}
                renderExtraFooter={rows => (
                    <div>Total Komisi: {numberToCurrency(rows.reduce((total, currentValue) => total + currentValue.revenue_jendela, 0), 'Rp')}</div>
                )}
            />

            {modalCode !== "" &&
                <ClosingModalDetail
                    code={modalCode}
                    open={modalCode !== ""}
                    permissions={permissions}
                    onClose={() => {
                        setModalCode("")
                        // window.history.pushState('', '', `/manage-extend`)
                    }}
                />
            }
        </Root>
    )
}

export default ManageExtendList;
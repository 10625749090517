import React, { useEffect, useState } from "react"
import { Paper, Button, Grid, ButtonGroup, Theme } from '@mui/material';

// Components

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Utils
import AsyncAutoComplete from "../../../_components/_form/AsyncAutoComplete";
import { generateStyle } from "../../../_utils/DefaultStyle";

const useStyles = generateStyle((theme: Theme) =>
({
    filterCard: {
        zIndex: 1200,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        transform: 'translateY(100%)',
        padding: '0.5rem 0.5rem 3rem',
        transition: '0.4s ease-in-out',
        overflowY: 'hidden',
        '& .header': {
            position: 'fixed',
            top: '0.5rem',
            left: 0,
            right: 0,
            overflow: 'hidden',
            borderBottom: '1px solid #E5E5E5',
            zIndex: 1,
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem 0',
            marginBottom: '1rem',
            '& .close-button': {
                position: "absolute",
                left: '1rem'
            },
            '& .header-title': {
                fontSize: 17,
                color: '#000',
                fontWeight: 500
            },
            '& .header-reset': {
                textTransform: 'capitalize',
                position: 'absolute',
                right: '1rem'
            }
        },
        '& .body': {
            marginTop: '4rem',
            overflowY: 'auto',
            height: '100%',
            paddingBottom: '6rem',
            padding: '0 1rem',
            marginBottom: '2.5rem',
            '& .hot-client-toggle': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '0.5rem',
                '& .label-container': {
                    '& .label': {
                        color: '#363636',
                        fontSize: 16,
                        fontWeight: 700,
                        marginBottom: '0.25rem'
                    },
                    '& .hint': {
                        fontSize: 14,
                        color: '#4C4C4C',
                        fontWeight: 500
                    }
                }
            },
            '& .peruntukan': {
                marginBottom: '2rem',
                '& h2': {
                    fontSize: 16
                }
            },
            '& .searchfor': {
                marginBottom: '2rem',
                '& h2': {
                    fontSize: 16
                }
            },
            '& .area': {
                marginBottom: '2rem',
                '& h2': {
                    fontSize: 16
                }
            },
            '& .room': {
                marginBottom: '2rem',
                '& h2': {
                    fontSize: 15
                }
            },
            '& .budget': {
                marginBottom: '2rem',
                '& h2': {
                    fontSize: 16
                },
                '& .dash': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& span ': {
                        fontSize: 24,
                        fontWeight: 100,
                        transform: 'scaleX(2)',
                        color: '#707070',
                        paddingTop: '1rem'
                    }
                }
            }
        }
    },
    open: {
        transform: 'translateY(0)',
    },
    applyContainer: {
        position: 'fixed',
        backgroundColor: '#fff',
        overflowY: 'hidden',
        bottom: 0,
        padding: '1rem 0',
        borderTop: '1px solid #E5E5E5',
        left: 0,
        right: 0,
        display: 'flex',
        '& button': {
            width: '90%',
            margin: 'auto',
            textTransform: 'capitalize',
            fontWeight: 500,
            padding: '0.75rem 0',
            borderRadius: '2rem'
        }
    }
}), "AdvisorFilterCard"
)

interface IFilterData {
    apartment: string;
    code: string;
    bedroom: string;
}

interface IOptions {
    type: object[],
    requested_types: object[]
}

interface IProps {
    open: boolean
    setFilterOpen: Function
    fetchData: Function
    filterData: IFilterData
    setFilterData: Function
    options: IOptions
    setOptions: Function
    onReset: Function
    show_toggle_inactive: boolean
}

const AdvisorFilterCard = (props: IProps) => {
    const { Root, classes } = useStyles()

    const [changed, setChanged] = useState<boolean>(false)

    const [resetKey, setResetKey] = useState<number>(0)

    const handleApartment = (name: string, value: any) => {
        if (value !== '') {
            props.setFilterData({
                ...props.filterData,
                apartment: value.id
            })
        }
        setChanged(true)
    }

    const handleApartmentInput = (e: React.ChangeEvent<{ name: string, value: string }>, name: string) => {
        props.setFilterData({
            ...props.filterData,
            apartment: e.target.value
        })
        setChanged(true)
    }

    // const handleChanged = (e: React.ChangeEvent<{ name: string, value: string }>) => {
    //     props.setFilterData({
    //         ...props.filterData,
    //         [e.target.name]: e.target.value
    //     })
    //     setChanged(true)
    // }

    const handleChangedBedroom = (value: string) => {
        props.setFilterData({
            ...props.filterData,
            bedroom: value
        })
        setChanged(true)
    }

    /**
     * Close filter card
     */
    const closeFilter = () => {
        if (changed) {
            props.fetchData(true)
            window.scrollTo(0, 0)
        }
        props.setFilterOpen(false)
    }

    /**
     * Handle overflow of the body if the filter card is open
     */
    useEffect(() => {
        if (props.open) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
        setChanged(false)
    }, [props.open])

    const reset = () => {
        setChanged(true)
        props.onReset()
        setResetKey(resetKey + 1)
    }

    return (
        <Root>
            <Paper className={`${classes.filterCard} ${props.open ? classes.open : ''}`}>
                <div className="header">
                    <CloseIcon className="close-button" onClick={closeFilter} />
                    <span className="header-title">
                        Filter
                    </span>
                    <Button size="small" color="primary" className="header-reset" onClick={reset}>
                        Reset
                    </Button>
                </div>
                <div className="body">
                    {/* <div className="area">
                    <h2>Code</h2>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <TextField
                                variant="outlined"
                                value={props.filterData.code || ''}
                                name="code"
                                onChange={handleChanged}
                                fullWidth
                                placeholder="Masukkan Kode Unit"
                            >
                            </TextField>
                        </Grid>
                    </Grid>
                </div> */}
                    <div className="area">
                        <h2>Apartment</h2>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <AsyncAutoComplete
                                    key={resetKey}
                                    label=""
                                    url={process.env.REACT_APP_API_URL + '/autocomplete/apartment'}
                                    name="apartment"
                                    initialQuery={""}
                                    onChange={handleApartment}
                                    onInputChange={handleApartmentInput}
                                    placeholder="Masukkan nama Apartemen"
                                    param="query"
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className="area">
                        <h2>Bedroom</h2>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <ButtonGroup
                                    color="primary"
                                    style={{
                                        marginBottom: 16,
                                    }}
                                >
                                    <Button
                                        variant={props.filterData.bedroom ? 'outlined' : 'contained'}
                                        onClick={() => handleChangedBedroom('')}
                                    >
                                        All
                                    </Button>
                                    <Button
                                        variant={props.filterData.bedroom === '0' ? 'contained' : 'outlined'}
                                        onClick={() => handleChangedBedroom('0')}
                                    >
                                        Studio
                                    </Button>
                                    <Button
                                        variant={props.filterData.bedroom === '1' ? 'contained' : 'outlined'}
                                        onClick={() => handleChangedBedroom('1')}
                                    >
                                        1
                                    </Button>
                                    <Button
                                        variant={props.filterData.bedroom === '2' ? 'contained' : 'outlined'}
                                        onClick={() => handleChangedBedroom('2')}
                                    >
                                        2
                                    </Button>
                                    <Button
                                        variant={props.filterData.bedroom === '3' ? 'contained' : 'outlined'}
                                        onClick={() => handleChangedBedroom('3')}
                                    >
                                        3+
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className={classes.applyContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={closeFilter}
                    >
                        Apply Filter
                    </Button>
                </div>
            </Paper>
        </Root>
    )
}

export default AdvisorFilterCard

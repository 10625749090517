import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import {
  Theme,
  Grid,
  Dialog,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';

/**
 * Components
 */
import UnitAdvisorCard from '../../_components/_cards/UnitAdvisorCard';
import LoadingCard from "../../_components/_cards/LoadingCard";
import MyUnitDetail from "./MyUnitDetail";
import AdvisorFilterCard from './_components/AdvisorFilterCard';
import CustomBadge from '../_listingList/_components/CustomBadge';

/**
 * Icons
 */
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generateStyle } from "../../_utils/DefaultStyle";

/**
 * Interface
 */
// import DataNA from "../../_components/DataNA";

interface IProps {
  permissions: any
  search: any
}

interface IGroupApartment {
  name: string;
  expanded: false;
  units: Array<IListing[]>;
}

export interface IListing {
  id: string;
  code: string;
  bedroom: number;
  bathroom: number;
  apartment_name: string;
  assigned_to_advisor_at: string;
  last_reported_by_advisor_at: string;
  basic_price_12: number | null;
  tower: string;
  floor_level: string;
  unit_number: string;
}

interface IFilterData {
  apartment: string
  code: string
  bedroom: string
}

interface IOptions {
  type: object[],
  requested_types: object[]
}

const useStyles = generateStyle((theme: Theme) =>
({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  listContainer: {
    display: "flex",
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '3.5rem'
  },
  appBar: {
    position: 'relative',
  },
  filterContainer: {
    zIndex: 1,
    position: 'fixed',
    bottom: '2rem',
    left: 0,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    '& .filter-button': {
      borderRadius: '2rem',
      padding: '0.4rem 1.5rem',
      backgroundColor: 'white',
      fontWeight: 400,
      '& *': {
        textTransform: 'capitalize'
      }
    }
  },
  infoBox: {
    backgroundColor: '#F56C6C1A'
  },
  infoEmpty: {
    margin: 0,
    fontSize: '12px',
    padding: '8px'
  },
  infoTotal: {
    textAlign: 'center',
    // marginBottom: '0px'
  }
}), "MyUnitList"
);

const initialFilterData = {
  apartment: '',
  code: '',
  bedroom: ''
}

const MyUnitList = (props: IProps) => {
  const { Root, classes } = useStyles();
  // const [init, setInit] = useState<boolean>(true)
  // const init = true;
  // const navigate =  useNavigate()

  const API_URL = `${process.env.REACT_APP_API_URL}/my-unit`;

  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [selectedData, setSelected] = useState<IListing | null>(null)
  const [groupApartment, setGroupApartment] = useState<IGroupApartment[]>([]);
  const [dataNeedReport, setDataNeedReport] = useState(0);
  const [total, setTotal] = useState(0);

  /**
   * Handle filter card open condition
   */
  const [filterOpen, setFilterOpen] = useState<boolean>(false)

  /**
   * Handle filterData
   */
  const [filterData, setFilterData] = useState<IFilterData>(initialFilterData)

  const resetFilter = () => {
    setFilterData({
      ...initialFilterData,
    })
  }

  /**
   * Options for type and requested_types
   */
  const [options, setOptions] = useState<IOptions>({
    type: [],
    requested_types: []
  })

  useEffect(() => {
    fetchData(true);
    // eslint-disable-next-line
  }, []);

  /**
   * Add listener for infinity scroll
   */
  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll)
  //   return () => window.removeEventListener('scroll', handleScroll)
  //   // eslint-disable-next-line
  // }, [infiniteScroll, page])

  // useEffect(() => {
  //   if (!init) {
  //     window.scrollTo(0,0)
  //     fetchData(true)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.query_value])

  /**
   * Open filter card
   */
  const openFilter = () => {
    setFilterOpen(true)
  }

  const fetchData = (filtered?: boolean) => {

    // if (filtered) {
    //   setInfiniteScroll({
    //     ...infiniteScroll,
    //     isLoading: true
    //   })
    //   setData([]);
    //   setPage(1)
    // }

    const params = {
      apartment_id: filterData.apartment || null,
      code: props.search || null,
      bedroom: filterData.bedroom || null,
    }


    DefaultAxios.get(API_URL, { params })
      .then((res) => {
        const groups: IGroupApartment[] = res.data.apartment_groups;
        setGroupApartment(groups);
        setDataNeedReport(res.data.needReport);
        setTotal(res.data.total);
        setIsLoading(false);
      });
  }

  const filterAmount = () => {
    let amount = 0

    if (filterData.apartment !== '') {
      amount++
    }

    if (filterData.code !== '') {
      amount++
    }

    return amount
  }

  useEffect(() => {
    if (open) {
      window.location.hash = '#detail'
    } else {
      window.history.pushState("", document.title, window.location.pathname + window.location.search)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    fetchData(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.search])

  const handleOpenDetail = (data: IListing) => {
    setSelected(data)
    setOpen(true)
  }

  const handleCloseDetail = () => {
    setOpen(false)
  }

  if (isLoading) {
    return (
      <>
        <div style={{ width: '100%' }}>
          <LoadingCard />
          <LoadingCard />
        </div>
      </>
    )
  } else {
    return (
      <Root>
        <AdvisorFilterCard
          open={filterOpen}
          setFilterOpen={setFilterOpen}
          fetchData={fetchData}
          filterData={filterData}
          setFilterData={setFilterData}
          options={options}
          setOptions={setOptions}
          onReset={resetFilter}
          show_toggle_inactive={true}
        />
        <Dialog fullScreen open={open} onClose={handleCloseDetail}>
          <MyUnitDetail
            code={selectedData ? selectedData.code : ''}
            fetchData={fetchData}
            onClose={handleCloseDetail}
            permissions={props.permissions}
          />
        </Dialog>

        {dataNeedReport ?
          <div className={classes.infoBox}>
            <p className={classes.infoEmpty}>Ada {dataNeedReport} unit yg sudah kosong {'>'}14 hari. Silahkan tap kartu berwarna merah {'&'} berikan alasan.</p>
          </div>
          : null
        }
        {total ?
          <p className={classes.infoTotal}>{total} Total Unit</p>
          :
          <p className={classes.infoTotal}>Tidak ada unit</p>
        }

        {/* {groupApartment.length === 0 &&
          <DataNA
            type="Listing"
            navigate={() => navigate('/listing/add')}
            init={init}
          />
        } */}

        {groupApartment.length > 0 &&
          <>
            {groupApartment.map((apartment: IGroupApartment) => {
              return (
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"

                  >
                    <Typography className={classes.heading}>{apartment.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    {apartment.units.map((unit_groups, index: number) => {
                      if (unit_groups.length > 0) {
                        return (
                          <Accordion TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"

                            >
                              <Typography className={classes.heading}>{index === 0 ? "Studio" : `${index}BR`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: "block" }}>
                              {unit_groups.map((unit, index: number) => {
                                return (
                                  <>
                                    <Grid container>
                                      <UnitAdvisorCard key={unit.code} number={index + 1} data={unit} onClick={handleOpenDetail} />
                                    </Grid>
                                    <br />
                                  </>
                                )
                              })}
                            </AccordionDetails>
                          </Accordion>
                        )
                      } else {
                        return ("")
                      }
                    })}
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </>
        }
        {/* {"// this is hidden for now"} */}
        <div className={classes.filterContainer}>
          <Button
            variant="contained"
            className="filter-button"
            startIcon={<FilterListIcon />}
            onClick={openFilter}
          >
            Filter
            {filterAmount() !== 0 && <CustomBadge amount={filterAmount()} />}
          </Button>
        </div>
      </Root>
    )
  }
};

export default MyUnitList;

import React from "react";
import {
    Theme,
    Paper,
    Typography,
    Grid,
    Button,
    IconButton
} from "@mui/material";

/**
 * Utils
 */
import { differenceInDays } from 'date-fns';
import { convertNumber } from "../../_utils/Helper";
import { IListing } from "../../_pages/_myUnit/MyUnitList";

import VisibilityIcon from '@mui/icons-material/Visibility';
import { generateStyle } from "../../_utils/DefaultStyle";

const useStyles = generateStyle((theme: Theme) => ({
    cardContainer: {
        display: "flex",
        flexFlow: "column",
        width: "100%",
        marginBottom: theme.spacing(2.5),
        position: 'relative',
        overflow: 'hidden',
        color: "#444444",
        // padding: theme.spacing(1.5),
        boxShadow: '0px 5px 25px #0000003d',
    },
    cardContent: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        "& .MuiGrid-root": {
            marginBottom: theme.spacing(0.4)
        },
    },
    dayEmptyCard: {
        backgroundColor: '#EEA9A9'
    },
}), "UnitAdvisor_Card"
);

interface Props {
    data: IListing;
    onClick: (data: IListing) => void;
    number: number;
}

const UnitAdvisorCard = ({ data, onClick, number }: Props) => {
    const { Root, classes } = useStyles();

    const handleClick = () => {
        onClick(data)
    }

    const price_to_display = () => {
        let number = '-';
        if (data.basic_price_12 === null || data.basic_price_12 === 0) {
            return number;
        } else {
            number = convertNumber(data.basic_price_12.toString(), '');
        }
        return number;
    }

    const renderDayEmpty = () => {
        let result = differenceInDays(new Date(), new Date(data.last_reported_by_advisor_at))

        let dayEmpty = differenceInDays(new Date(), new Date(data.assigned_to_advisor_at))

        return (
            <Root>
                <Paper className={`${classes.cardContainer} ${data.last_reported_by_advisor_at && result > 14 ? classes.dayEmptyCard : ""}`}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container direction="column" className={classes.cardContent}>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="h5">
                                            {data.code}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="primary" style={{ marginTop: "5px" }} size="small" href={`https://jendela360.com/${data.code}`} target="_blank">
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="subtitle2">
                                            {data.apartment_name}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    {`Rp ${price_to_display()}`} /tahun
                                </Grid>

                                <Grid item>
                                    <Typography variant="subtitle2">
                                        {data.bedroom === 0 ? "Studio" : `${data.bedroom} Bedroom`} | {data.bathroom === 0 ? "" : `${data.bathroom} Bathroom`}
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Typography variant="subtitle2">
                                        Tw. {data.tower} | Lt. {data.floor_level} | No. {data.unit_number}
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Typography variant="subtitle2">
                                        Lama Kosong: {result > 20 ?
                                            <span style={{ color: "#f56c6c", fontWeight: 500 }}>{result} </span>
                                            :
                                            <span>{dayEmpty} </span>
                                        }
                                        Hari sejak diassign
                                    </Typography>
                                </Grid>

                                <Grid container direction="row-reverse">
                                    <Grid item>
                                        <Button color="primary" variant="contained" onClick={handleClick} size="small">
                                            Detail
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Root>
        )
    }

    return (
        <>
            {renderDayEmpty()}
        </>
    );
};

export default UnitAdvisorCard;
